import React, {useRef} from 'react';
import useOutsideClick from "../../../../messenger/hooks/useOutsideClick/useOutsideClick.js";
import ClientAvatar from "../../../shared/Avatar.jsx";
import useLang from "../../../../../../src_shared/hooks/useLang";

const ClientHintDropdown = ({
								onChange,
								customer,
								hints,
								setHints,
								hintsVisible,
								hintItem,
								updateProductOnNameChange,
								customerId,
							}) => {
	const hintRef = useRef();
	const {getLangText} = useLang();

	const onKeyPress = (e, hint) => {
		if (e.code === 'Enter') {
			onHintSelect(hint)
		}
	}

	const onKeyDown = (event) => {
		const focusableInputElements = document.querySelectorAll(`.select-custom-option`);

		const focusable = [...focusableInputElements];

		const index = focusable.indexOf(document.activeElement);

		let nextIndex = 0;
		if (event.keyCode === 40) {
			event.preventDefault();
			nextIndex = index + 1 < focusable.length ? index + 1 : index;
			focusableInputElements[nextIndex].focus();
		}
		if (event.keyCode === 38) {
			event.preventDefault();
			nextIndex = index > 0 ? index - 1 : 0;
			focusableInputElements[nextIndex].focus();
		}
	}

	const onHintSelect = (hint) => {
		onChange('client_id', hint.facebook_id)
		onChange('name', hint.name)
		onChange('photo', hint.photo)
		onChange('facebook_id', hint.facebook_id)
		onChange('shop_client_id', hint.shop_client_id)
		onChange('shop_id', hint.shop_id)
		onChange('new_id', hint.id)
		onChange('user_id', hint.user_id)
		onChange('social_type', hint.social_type)
		setHints([])

		updateProductOnNameChange();
	}

	useOutsideClick(hintRef, () => {
		if (hintsVisible || !!hints.length) {
			setHints([])
		}
	});

	return (
		<div
			className={`custom-dropdown ${!!hints.length ? 'opened-custom-dropdown' : ''}`}
			ref={hintRef}
		>
			{hintsVisible === customerId && hints.map((hint) => (
				<div
					onKeyPress={(e) => onKeyPress(e, hint)}
					onKeyDown={onKeyDown}
					className="select-custom-option"
					key={hint.id}
					tabIndex="0"
				>
					<div
						className="name"
						onClick={() => onHintSelect(hint)}
					>
						{hintItem(hint, getLangText)}
					</div>
				</div>
			))}
		</div>
	);
};

ClientHintDropdown.defaultProps = {
	hintItem: (hint, getLangText) => (
		<>
			<ClientAvatar
				photo={hint.photo}
				type={hint.social_type}
			/>
			<div>
				{hint.name}
				{hint.shop_client_id && <div className="client-number">{getLangText('clientNumberFullLabel')}: {hint.shop_client_id}</div>}
			</div>
		</>
	),
}

export default ClientHintDropdown;
