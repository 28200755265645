import Model from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

class SendNotificationsModel extends Model {
	getModel() {
		return {
			message: '',
			url: '',
		};
	}

	getValidators() {
		return {
			message: ValidationError.notEmpty,
			url: ValidationError.skip,
		};
	}
}

export default new SendNotificationsModel();
