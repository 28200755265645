import React, {useState} from 'react';
import {Link, NavLink, useHistory, useLocation} from "react-router-dom";
import {RouterPaths} from "../../../../routes/RouterPath";
import {services} from "../../../../RestServices";
import useLang from "../../../../../src_shared/hooks/useLang";
import Loader from "../../../../app/shared/Loader";
import Wrapper from "../Wrapper";
import useCallbackUrl from "../../../../../src_shared/hooks/useCallbackUrl";
import {parseParams} from "../../../../../src_shared/list/ListActions";
import ComponentLoader from "../../../../../src_shared/components/ui/componentLoader/ComponentLoader";
import {Helmet} from "react-helmet";

const ShopifyConnecting = () => {
    const {getLangText} = useLang();
    const {search} = useLocation();
    const history = useHistory();
    const query = parseParams(search);

    const afterSubmit =  () => window.location.href = 'https://admin.shopify.com/admin/apps/538a3863191a201e72eeccdda0d290c6/'

    useCallbackUrl({
        queryName: 'code',
        rest: `${services.API_SETTINGS_CALLBACK_SHOPIFY}?code=${query.code}&shop=${query.shop}`,
        afterSubmit,
    })

    return (
        <>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <Link to={RouterPaths.Settings}>
                            {getLangText('asideSettingsLabel')}
                        </Link>
                    </li>
                    <li>
                        <Link to={RouterPaths.SettingsIntegration}>{getLangText('settings_nav_integrations')}</Link>
                    </li>
                    <li className="active">
                        Shopify
                    </li>
                </ul>
            </div>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Shopify - Selmo</title>
            </Helmet>
            <div className="row small-padd-row">
                <div className="col-lg-7">
                    <div className="settings-page bg-[#fff] p-6 rounded-[5px]">
                        <div className="loader-parent text-center mt-5">
                            <img style={{width: 'auto', height: '30px'}}
                                 className="mx-auto mt-3"
                                 src="/assets/images/svg/shopify.svg"
                                 alt="Shopify"/>
                            <div className="flex items-center justify-center mt-6">
                                <Loader
                                    style={{width: 'auto'}}
                                    className="static-loader extra-small-laoder w-auto" isLoading/>
                                <div className="text-desc font-semibold text-sm ml-3 -mt-3">
                                    {getLangText('accountConnecting')}...
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ShopifyConnecting;
