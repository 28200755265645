import React from 'react';
import useLang from "../../../../../../src_shared/hooks/useLang";
import {useUnitPrice} from "../../../../shared/helpers/Price";

const Variants = ({product}) => {
    const {getLangText} = useLang();

    return (
        <div className="products-list">
            <div className="products-header">
                <div className="name">{getLangText('variantNameLabel')}</div>
                <div className="count">{getLangText('availableLabel')}</div>
            </div>
            {!!product.sizes?.length ?
                <div className="products-body">
                    {product.sizes?.map((i) => (
                        <div key={i.id}>
                            <div className="name">
                                {i.size}
                            </div>
                            <div className="count">{i.squ}</div>
                        </div>
                    ))}
                </div> :
                <div className="products-body">
                    <div>
                        <div className="name">
                            {product.name}
                        </div>
                        <div className="count">{product.squ}</div>
                    </div>
                </div>
            }
        </div>
    );
};

export default Variants;
