import {combineReducers} from "redux";
import formReducer from "./edit/formReducer";
import variantsSquFormReducer from "./variantsSquModal/formReducer";
import currentCartFormReducer from "./currentCart/listReducer";
import reserveListFormReducer from "./reserveList/listReducer";
import activityHistoryReducer from "./activityHistory/activityHistoryReducer";
import finishedCartFormReducer from "./finishedCart/listReducer";
import expendituresListReducer from "./expenditures/listReducer";
import removeListReducer from "./removed/listReducer";
import sharedFormReducer from "./shared/sharedEditProductReducer";
import filtersReducer from "./filters/filtersReducer";

export default combineReducers({
	edit: formReducer,
	variantsSqu: variantsSquFormReducer,
	currentCart: currentCartFormReducer,
	reserveList: reserveListFormReducer,
	expendituresList: expendituresListReducer,
	removedList: removeListReducer,
	finishedCart: finishedCartFormReducer,
	filters: filtersReducer,
	activityHistory: activityHistoryReducer,
	shared: sharedFormReducer,
});
