import React from 'react';
import {useHistory} from "react-router-dom";
import EmptyList from "../../../shared/EmptyList";
import useLang from "../../../../../src_shared/hooks/useLang";
import moment from "../../../messenger/helpers/moment";
import {RouterPaths} from "../../../../routes/RouterPath";
import {convertToUserTimeZone} from "../../../shared/helpers/dateHelpers";

const Table = ({updateSortOptions, isWaiting, getSortClass, items}) => {
	const history = useHistory();
	const {getLangText} = useLang();

	return (
		<EmptyList
			items={items}
			isLoading={isWaiting}
			className="with-padd functionality-purchase"
			imgSrc="/assets/images/default/shop-logo.svg"
			title={getLangText('emptyHereLabel')}
			text={getLangText('changeSearchOrAddManufacturerLabel')}
		>
			<div className="table-wrapper">
				<div className="table-responsive">
					<table className="table live-table">
						<thead>
						<tr>
							<th
								name="name"
								onClick={updateSortOptions}
								className={getSortClass('name')}
							>
							<span name="name">
								{getLangText('nameLabel')}
							</span>
							</th>
							<th
								name="date"
								onClick={updateSortOptions}
								className={`${getSortClass('date')} text-right`}
							>
							<span name="date">
								{getLangText('dateLabel')}
							</span>
							</th>
						</tr>
						</thead>
						<tbody>
						{items.map((item) => (
							<tr
								onClick={() => history.push(`${RouterPaths.ManufacturersList}/${item.id}`)}
								key={item.id}
							>
								<td className="name-td">
									{item.name}
								</td>
								<td className="date-td date text-right">
									<div>{convertToUserTimeZone(item.date).format('DD.MM.YYYY HH:mm')}</div>
								</td>
							</tr>
						))}
						</tbody>
					</table>
				</div>
			</div>
		</EmptyList>
	);
};

export default Table;
