import React from 'react';
import ShippingForm from "../components/ShippingForm";
import {RouterPaths} from "../../../routes/RouterPath";
import {Link, useParams} from "react-router-dom";
import useLang from "../../../../src_shared/hooks/useLang";
import useFormLocal from "../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../RestServices";
import ComponentLoader from "../../../app/shared/components/componentLoader/ComponentLoader";
import SettingsShippingModel from "../../../../modules/models/settings/SettingsShippingModel";
import ConfirmModal from "../../../app/live/details/default/summary/export/ConfirmModal";
import useRemoveData from "../../../../src_shared/hooks/useRemoveData";

const ShippingFormPage = () => {
	const {getLangText} = useLang();
	const {id} = useParams();
	const [{
		onSave,
		isWaiting,
		getFieldProps,
		isLoading,
		values,
	}] = useFormLocal({
		method: 'put',
		rest: `${services.API_SETTINGS_SHIPPING}/${id}`,
		model: SettingsShippingModel,
	});

	const [{
		openModal: openRemoveModal,
		hideModal: hideRemoveModal,
		confirmModalVisible,
		itemToRemove,
		isDeleting,
		removeItem,
	}] = useRemoveData({
		rest: `${services.API_SETTINGS_SHIPPING}/${id}`,
		redirectRoute: RouterPaths.SettingsShipping
	});

	if (isLoading) return <ComponentLoader/>;

	return (
		<>
			<div className="header-bar breadcrumbs-in-react-app">
				<ul className="breadcrumbs">
					<li>
						<Link to={RouterPaths.Settings}>
							{getLangText('asideSettingsLabel')}
						</Link>
					</li>
					<li>
						<Link to={RouterPaths.SettingsShipping}>
							{getLangText('settings_nav_shipping')}
						</Link>
					</li>
					<li className="active">
						<div>{getLangText('personalPickupLabel')}</div>
					</li>
				</ul>
				<Link
					to={RouterPaths.SettingsShipping}
					className="button back-to-panels mb-3"
				>
					<i className="icon-arrow-left"/>
					{getLangText('button_back_to_shipment')}
				</Link>
			</div>
			<div className="settings-page sub-settings-page">
				<div className="header-wrapper">
					<h1 className="mb-0">
						{getLangText('settings_shipping_data')}
					</h1>
				</div>
				<div className="settings-form">
					<ShippingForm
						onSave={onSave}
						isWaiting={isWaiting}
						getFieldProps={getFieldProps}
						values={values}
					/>
					<div className="remove-delivery-section">
						<button
							type="button"
							onClick={() => openRemoveModal(values)}
							className="button border-button left-icon big-size"
						>
							<i className="icon-bin"/>
							{getLangText('button_delete_shipping_method')}
						</button>
					</div>
				</div>
			</div>
			{confirmModalVisible &&
				<ConfirmModal
					dialogClassName="w-380"
					title={getLangText('delete_modal_accept')}
					SubTitle={() => <span dangerouslySetInnerHTML={{
						__html: getLangText({
							key: 'wantToRemoveLabel',
							data: [itemToRemove.name]
						})
					}}/>}
					saveButton={getLangText('removeButton')}
					show={confirmModalVisible}
					isWaiting={isDeleting}
					onSubmit={removeItem}
					hide={hideRemoveModal}
					saveButtonClass="danger"
				/>
			}
		</>
	);
};

export default ShippingFormPage;
