import Model, {convertObjectProperties} from "../../../src_shared/modules/model/Model";
import ValidationError, {smallerOrEqualThan} from "../../../src_shared/form/validation/ValidationError";

class SettingsBlueMediaModel extends Model {
	getModel() {
		return {
			bm_service_id: '',
			bm_shared_key: '',
			bm_active: '0',
			bm_price_type: '2',
			bm_price: '',
			bm_price_active: '0',
			bm_transfer_active: '0'
		};
	}

	getValidators() {
		return {
			bm_service_id: ValidationError.notEmpty,
			bm_shared_key: ValidationError.notEmpty,
			bm_active: ValidationError.skip,
			bm_price_type: ValidationError.skip,
			bm_price: (value, data) => !!+data.bm_price_active ? ValidationError.smallerOrEqualThan(1.69, 'Maksymalna wartość wynosi 1.69%')(value) : ValidationError.skip(),
			bm_price_active: ValidationError.skip,
			bm_transfer_active: ValidationError.skip,
		};
	}

	buildDTO(data) {
		return {
			...data,
			bm_price_type: '2',
		}
	}
}

export default new SettingsBlueMediaModel();
