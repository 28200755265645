import React from 'react';
import EmptyList from "../../../../shared/EmptyList";
import useTableRowClick from "../../../../shared/hooks/useTableRowClick";
import {RouterPaths} from "../../../../../routes/RouterPath";
import moment from "moment";
import useLang from "../../../../../../src_shared/hooks/useLang";
import CustomDropdown from "../../../../shared/components/dropdown/CustomDropdown";
import {usePrintFile} from "../../../../../../src_shared/hooks/usePrintFile";
import {services} from "../../../../../RestServices";
import Loader from "../../../../../../src_shared/components/loader/Loader";
import {convertToUserTimeZone} from "../../../../shared/helpers/dateHelpers";

const Table = ({
                   items,
                   isWaiting,
                   updateSortOptions,
                   getSortClass,
                   askForRemoveItem,
               }) => {

    const {getLangText} = useLang();
    const onTableRowClick = useTableRowClick(RouterPaths.Storage);

    const {print, waiting: waitingForQRCode} = usePrintFile({
        rest: services.API_WAREHOUSE_SHELF_QR_CODE,
    });

    const remove = (e, item) => {
        e.stopPropagation()
        askForRemoveItem(item)
    }


    return (
        <EmptyList
            items={items}
            isLoading={isWaiting}
            className="with-padd"
            imgSrc="/assets/images/empty/products-empty.svg"
            title={getLangText('emptyHereLabel')}
            text={getLangText('changeSearchOrAddProductsLabel')}
        >
            <div className="table-wrapper">
                <div className="table-responsive">
                    <table className="table live-table smaller-padd">
                        <thead>
                        <tr>
                            <th>
                                {getLangText('nameLabel')}
                            </th>
                            <th
                                name="date"
                                onClick={updateSortOptions}
                                className={`text-right ${getSortClass('date')}`}
                            >
                                    <span name="date">
                                        {getLangText('dateLabel')}
                                    </span>
                            </th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {items.map((item) => (
                            <tr {...onTableRowClick(item.id)}>
                                <td
                                    data-thead={`${getLangText('nameLabel')}:`}
                                    style={{color: 'black'}}
                                    className="w-100 with-mobile-thead inline-thead text-black sm:mb-0 mb-1"
                                >
                                    {item.shelf}
                                </td>
                                <td
                                    data-thead={`${getLangText('createdLabel')}`}
                                    className="text-sm-right date w-lg-auto w-100 with-mobile-thead mb-lg-0 text-nowrap"
                                >
                                    {convertToUserTimeZone(item.date).format('DD.MM.YYYY HH:mm')}
                                </td>
                                <td className="text-right absolute-dropdown-dots max-w-[70px] w-[70px]">
                                    <CustomDropdown
                                        icon="icon-dots"
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <button
                                            onClick={(e) => remove(e, item)}
                                            type="button"
                                            className="custom-option"
                                        >
                                            {getLangText('removeButton')}
                                        </button>
                                        <button
                                            onClick={(e) => print(e, item.id)}
                                            type="button"
                                            className="custom-option relative"
                                        >
                                            {waitingForQRCode && <Loader circleClassName="m-0" className="small-loader" isLoading />}
                                            {getLangText('generateQRCodeLabel')}
                                        </button>
                                    </CustomDropdown>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </EmptyList>
    );
};

export default Table;
