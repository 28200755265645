import React from 'react';
import {IntercomProvider} from 'react-use-intercom';

const IntercomWrapper = ({children}) => {

	return (
		<IntercomProvider
			apiBase="https://api-iam.intercom.io"
			appId="hryhh020"
		>
			{children}
		</IntercomProvider>
	);
};

export default IntercomWrapper;
