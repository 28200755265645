import React from "react";
import useForm from "../../../../../src_shared/form/useForm";
import FormActions from "./FormActions";
import Loader from "../../../shared/Loader";
import {useParams} from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import PaymentTypesSelect from "../../../../../src_shared/components/form/paymentTypesSelect/PaymentTypesSelect";
import useLang from "../../../../../src_shared/hooks/useLang";

const PaymentTypeForm = ({data}) => {
	const params = useParams()
	const {getLangText} = useLang();

	const {
		data: {values, setValue, updateValues},
		form: {isWaiting, onSave},
		modal: {hideModal, modalVisible},
	} = useForm({
		FormActions,
		params,
	});


	return (
		<Modal
			show={modalVisible}
			className="add-order-modal"
			dialogClassName="default-modal sm-size"
			onHide={hideModal}
		>
			<Modal.Header>
				<Modal.Title>{getLangText('editPaymentMethodLabel')}</Modal.Title>
				<button
					onClick={hideModal}
					type="button"
					className="button"
				>
					<i className="icon-cross"/>
				</button>
			</Modal.Header>
			<Modal.Body>
				<div className="loader-parent">
					<Loader isLoading={isWaiting}/>
					<PaymentTypesSelect
						setValue={setValue}
						updateValues={updateValues}
						values={values}
						data={data}
						finalPrice={data.final_price}
						currency={data.currency.text}
					/>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<button
					onClick={hideModal}
					type="button"
					className="button border-button"
				>
					{getLangText('cancelButton')}
				</button>
				<button
					type="button"
					className="button primary"
					onClick={onSave}
				>
					{isWaiting && <Loader isLoading/>}
					{getLangText('saveButton')}
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default PaymentTypeForm;



