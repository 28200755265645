import React from 'react';
import Modal from "react-bootstrap/Modal";
import Field from "../../../../../../src_shared/components/form/field/Field";
import Loader from "../../../../shared/Loader";
import useEnterSubmitForm from "../../../../../../src_shared/hooks/useEnterFormSubmit";
import useLang from "../../../../../../src_shared/hooks/useLang";

const CustomSizeModal = ({modalVisible, hideModal, getFieldProps, setValue, onSave, waiting, className = '', backdropClassName = ''}) => {
	const {getLangText} = useLang();
	useEnterSubmitForm(onSave);

	return (
		<Modal
			show={modalVisible}
			className={className}
			dialogClassName="default-modal sm-size"
			backdropClassName={backdropClassName}
			onHide={hideModal}
		>
			<Modal.Header className="with-border">
				<Modal.Title>
					{getLangText('customSizeLabel')}
					<div className="sub-title">
						{getLangText('addOwnShipmentSizeLabel')}
					</div>
				</Modal.Title>
				<button
					onClick={hideModal}
					type="button"
					className="button"
				>
					<i className="icon-cross"/>
				</button>
			</Modal.Header>
			<Modal.Body>
				<div className="row">
					<div className="col-md-6">
						<Field
							{...getFieldProps('length')}
							setValue={setValue}
							label={getLangText('longLabel')}
							addon="CM"
							type="number"
							placeholder="0"
						/>
					</div>
					<div className="col-md-6">
						<Field
							{...getFieldProps('width')}
							setValue={setValue}
							label={getLangText('widthLabel')}
							addon="CM"
							type="number"
							placeholder="0"
						/>
					</div>
					<div className="col-md-6">
						<Field
							{...getFieldProps('height')}
							setValue={setValue}
							label={getLangText('heightLabel')}
							addon="CM"
							type="number"
							placeholder="0"
						/>
					</div>
					<div className="col-md-6">
						<Field
							{...getFieldProps('weight')}
							setValue={setValue}
							label={getLangText('weightLabel')}
							addon="KG"
							type="number"
							placeholder="0"
						/>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<button
					onClick={hideModal}
					type="button"
					className="button border-button"
				>
					{getLangText('cancelButton')}
				</button>
				<button
					onClick={onSave}
					type="button"
					className="button primary"
				>
					{waiting && <Loader/>}
					{getLangText('saveButton')}
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default CustomSizeModal;
