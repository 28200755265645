import React from 'react';
import Modal from "react-bootstrap/Modal";
import ImageGallery from 'react-image-gallery';
import {useDispatch, useSelector} from "react-redux";
import {galleryModalToggleVisibility} from "./GalleryModalActions";

const GalleryModal = () => {
    const {isOpen, images, startIndex, isLoading} = useSelector((state) => state.messenger.galleryModal)
    const dispatch = useDispatch();
	const hideModal = () => dispatch(galleryModalToggleVisibility(false));

    if (isLoading) {
        return false;
    }

    const preparedImages = (
        images.map((singlePhoto) => {
            return {
                original: singlePhoto.image_data.url,
                thumbnail: singlePhoto.image_data.preview_url,
            }
        }))

    const renderLeftNav = (onClick, disabled) => {
        return (
            <button
                className='image-gallery-icon image-gallery-left-nav'
                disabled={disabled}
                onClick={onClick}
            >
                <i className="icon-arrows left"/>
            </button>
        )
    }
    const renderRightNav = (onClick, disabled) => {
        return (
            <button
                className='image-gallery-icon image-gallery-right-nav'
                disabled={disabled}
                onClick={onClick}
            >
                <i className="icon-arrows right"/>
            </button>
        )
    }

    return (
        <Modal
            className="confirm-modal gallery-modal"
            show={isOpen}
            onHide={hideModal}
        >
			<Modal.Header>
				<button
					onClick={hideModal}
					type="button"
				>
					<i className="icon-cross"/>
				</button>
			</Modal.Header>
            <ImageGallery
                items={preparedImages}
                showPlayButton={false}
                showFullscreenButton={false}
                startIndex={startIndex}
                renderLeftNav={renderLeftNav}
                renderRightNav={renderRightNav}
            />
        </Modal>
    );
};

export default GalleryModal;
