import React, {useEffect, useState} from 'react';
import Aside from "./shared/aside/Aside";
import Header from "./shared/header/Header";
import ExtraNotification from "./shared/components/notification/CountDownNotification";
import Alert from "./shared/components/alert/Alert";
import {useDispatch, useSelector} from "react-redux";
import FunctionalityBoxes from "../../src_shared/components/functionalityBoxes/FunctionalityBoxes";
import ConfettiComponent from "./shared/components/confetti/ConfettiComponent";
import useWindowDimensions from "./messenger/hooks/useWindowDimensions/useWindowDimensions";
import {useHistory, useLocation} from "react-router-dom";
import {parseParams} from "../../src_shared/list/ListActions";
import {showAlert} from "./shared/components/alert/AlertActions";
import ReactPixel from "react-facebook-pixel";
import TopInfoBars from "./shared/header/TopInfoBars";
import {removeFromLS} from "./shared/helpers/ls";
import GlobalAlert from "./shared/components/GlobarAlert/GlobalAlert";
import FilesModal from "./shared/header/files/filesModal/FilesModal";
import InstagramAutomationAside from "./shared/aside/InstagramAutomationAside";
import GenerateLabelModal from "./shared/generateLabelModal/GenerateLabelModal";
import {asideSubMenuSetVisible} from "./shared/aside/AsideActions";
import SelmoPaczkaSuccessDepositModal from "./shared/SelmoPaczkaSuccessDepositModal/SelmoPaczkaSuccessDepositModal";
import {
    selmoPaczkaSuccessDepositModalSetVisible
} from "./shared/SelmoPaczkaSuccessDepositModal/SelmoPaczkaSuccessDepositModalActions";
import SummerPromoModal from "./shared/components/SummerPromoModal/ExtendPlanModal";
import {convertToUserTimeZone} from "./shared/helpers/dateHelpers";
import {hotjar} from "react-hotjar";

const Layout = ({children}) => {
    const {userData} = useSelector((state) => state.session);
    const {isModalOpen} = useSelector((state) => state.shared.files);
    const {isOpen} = useSelector((state) => state.shared.generateLabelModal);
    const {subMenuOpen, subMenuWidth} = useSelector((state) => state.shared.aside);
    const {isOpen: isOpenSelmoPaczkaSuccessDepositModal} = useSelector((state) => state.shared.selmoPaczkaSuccessDepositModal);
    const [isSummerPromoModalVisible, setIsSummerPromoModalVisible] = useState(false);
    const hideSubMenu = () => dispatch(asideSubMenuSetVisible(false))

    const {width} = useWindowDimensions();
    const dispatch = useDispatch();
    const {search} = useLocation();
    const query = parseParams(search);
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        if (userData.hotjar_id) {
            hotjar.initialize(userData.hotjar_id, 6);
        }
    }, [userData.hotjar_id])

    useEffect(() => {
        if (!!+query.success_payment) {
            dispatch(showAlert('paymentSuccessLabel', ''))
            setTimeout(() => ReactPixel.track('Purchase', {currency: query.currency, value: query.price / 100}))
        }
        removeFromLS('lang_auth')
    }, [])

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const spOrdersArray = query.getAll('orders[]') || [];

        if (!!+query.get('sp') && !spOrdersArray.length) {
            dispatch(selmoPaczkaSuccessDepositModalSetVisible(true));

            query.delete('sp');
            query.delete('amount');

            history.replace({
                pathname: location.pathname,
                search: query.toString(),
            });
        }
    }, [location.search, dispatch, history]);


    useEffect(() => {
        const lastShownDate = localStorage.getItem('lastSummerPromoModalShownDate');
        const today = new Date().toISOString().split('T')[0];

        if (
            !+userData.selmo_pro_active &&
            convertToUserTimeZone(userData.user_info.premium_date).toDate() < convertToUserTimeZone('23.12.2024', 'DD.MM.YYYY').toDate() &&
            lastShownDate !== today
        ) {
            setIsSummerPromoModalVisible(true);
            localStorage.setItem('lastSummerPromoModalShownDate', today);
        }
    }, []);

    return (
        <>
            {/*<ExtendPlan/>*/}
            {/*{isSummerPromoModalVisible &&*/}
            {/*    <SummerPromoModal*/}
            {/*        hideModal={() => setIsSummerPromoModalVisible(false)}*/}
            {/*        modalVisible={isSummerPromoModalVisible}*/}
            {/*    />*/}
            {/*}*/}
            <GlobalAlert/>
            <ConfettiComponent/>
            <TopInfoBars/>
            <div className="main-container">
                {width < 991 &&
                    <FunctionalityBoxes/>
                }
                <div className="wrapper-section  loader-parent">
                    {(!!+userData.instagram_automation && +userData.client_type === 2) ?
                        <InstagramAutomationAside userData={userData}/> :
                        <Aside userData={userData}/>
                    }
                    <div className="grow flex flex-col max-w-[1050px]">
                        <main>
                            <Header userData={userData}/>
                            {children}
                        </main>
                    </div>
                    <ExtraNotification/>
                    <Alert/>
                </div>
            </div>
            {isModalOpen && <FilesModal/>}
            {isOpen && <GenerateLabelModal/>}
            {subMenuOpen !== null &&
                <div>
                    <div
                        onClick={hideSubMenu}
                        className={`right-0 lg:block hidden fixed top-0 left-0 bg-[#B2B8C0] bottom-0 z-10 ${subMenuOpen ? 'opacity-0 animate-[subMenuIn_.3s_ease_forwards]' : 'opacity-90 animate-[subMenuOut_.3s_ease_forwards_.1s] pointer-events-none'}`}/>
                    <div
                        style={{width: width > 999 ? subMenuWidth : '100%'}}
                        className={`bg-[#fff] transition-all fixed top-0 left-0 bottom-0 z-10 ${subMenuOpen ? 'opacity-0 animate-[fadeIn_.3s_ease_forwards]' : 'opacity-100 animate-[fadeOut_.4s_ease_forwards_.1s] pointer-events-none'}`}/>

                </div>
            }
            {isOpenSelmoPaczkaSuccessDepositModal &&
                <SelmoPaczkaSuccessDepositModal/>
            }
        </>
    );
};

export default Layout;
