import React from 'react';
import {useSelector} from "react-redux";
import useList from "../../../../../src_shared/list/useList.js";
import ListActions from "./ListActions.js";
import {getStateRoot} from "./listReducer.js";
import useLang from "../../../../../src_shared/hooks/useLang.js";
import {Helmet} from "react-helmet";
import FirstStepPage from "../../../../../src_shared/components/ui/FirstStepPage/FirstStepPage";
import ComponentLoader from "../../../shared/components/componentLoader/ComponentLoader";
import {FIRST_PAGE_LIVES} from "../../../shared/enums/TutorialVideoStatsTypes";
import InteractionsTable from "../../shared/interactionsTable/InteractionsTable";
import FiltersActions from '../filters/FiltersActions';
import {getStateRoot as getFiltersStateRoot} from '../filters/filtersReducer';

export const firstPageBenefits = [
    {id: 0, title: 'sendNotificationsOneClickLabel', desc: 'sendNotificationsOneClickSubLabel'},
    {id: 1, title: 'displayCommentsLabel', desc: 'displayCommentsSubLabel'},
    {id: 1, title: 'addProductsFromStorageOrWithoutLabel', desc: 'addProductsFromStorageOrWithoutSubLabel'},
]

const AutomationInteractionsList = () => {

    const {getLangText} = useLang();
    const {first_step_live} = useSelector((state) => state.session.userData);
    const {
        pagination,
        list: {items, isWaiting, isFirstStepPage},
        search,
        sort: {updateSortOptions, getSortClass},
    } = useList({
        ListActions,
        getStateRoot,
        FiltersActions
    });

    if (isWaiting && !!+first_step_live) return <ComponentLoader />

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getLangText('interactionsLabel')} - Selmo</title>
            </Helmet>
            {isFirstStepPage ?
                <FirstStepPage
                    title="liveListsTitle"
                    shortDesc="sendNotificationsOneClickLabel"
                    icon="broadcast-icon"
                    fullDesc="createOrdersDuringTheLiveBroadcastLabel"
                    buttonClick={() => {}}
                    headerButtonTitle="liveListAddLiveButton"
                    mainButtonTitle="createFirstLiveLabel"
                    benefits={firstPageBenefits}
                    videoStatsName={FIRST_PAGE_LIVES}
                /> :
               <InteractionsTable
                   isWaiting={isWaiting}
                   items={items}
                   updateSortOptions={updateSortOptions}
                   getSortClass={getSortClass}
                   pagination={pagination}
                   search={search}
                   ListActions={ListActions}
                   getFiltersStateRoot={getFiltersStateRoot}
                   FiltersActions={FiltersActions}
               />
            }
        </>
    );
};

export default AutomationInteractionsList;
