import React, {useState} from 'react';
import StylesLoader from "../../../../shared/Loader";
import useLang from "../../../../../../src_shared/hooks/useLang";
import axios from "axios";
import {selmoUrl} from "../../../../../../src_shared/api/api";
import {services} from "../../../../../RestServices";
import {useDispatch, useSelector} from "react-redux";
import {
	getLiveDetailsInfo,
	resetAndUpdateClients,
} from "../LiveDetailsActions";
import {showAlert} from "../../../../shared/components/alert/AlertActions";
import api from "../../../../../../services/axios/axios";

const WaitingForSent = ({title, type, rest = services.API_LIVE_SEND_BATCH_STATUS, successAlertTitle = 'stillSendingMessagesLabel'}) => {
	const {getLangText} = useLang();
	const dispatch = useDispatch();
	const {liveInfo} = useSelector((state) => state.lives.details)
	const [waiting, setWaiting] = useState(false);

	const checkStatus = async () => {
		setWaiting(true)
		try {
			const {data} = await api.get(`${selmoUrl}/${rest}?id=${liveInfo.id}&type=${type}`)
			if (!data.waitingForSend) {
				dispatch(resetAndUpdateClients(liveInfo.id))
				dispatch(getLiveDetailsInfo(liveInfo.shop_live_id))
			} else {
				dispatch(showAlert(successAlertTitle, 'tryAgainInWhileLabel', 'alert-info'))
			}

		} catch (e) {
			console.warn('Cannot check status')
		} finally {
			setWaiting(false)
		}
	}

	return (
		<div className="default-shadow-box summary-box mb-3 loading-box">
			<div className="d-flex align-items-center w-100">
				<StylesLoader className="static-loader w-auto h-auto mr-3" isLoading={true}/>
				<div>
					<div className="title small-size mb-0">{title}</div>
					<div className="sub-title extra-small-text">{getLangText('itMakeTakeUpFiveMinLabel')}</div>
				</div>
				<button
					onClick={checkStatus}
					type="button"
					className={`button border-button small-size ml-auto ${waiting ? 'loading-button' : ''}`}
				>
					<StylesLoader isLoading={waiting}/>
					{getLangText('refreshLabel')}
				</button>
			</div>
		</div>
	);
};

export default WaitingForSent;
