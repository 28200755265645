import React, {useEffect, useRef, useState} from 'react';
import Field from "../../../../src_shared/components/form/field/Field";
import CheckboxField from "../../../../src_shared/components/form/checkboxField/CheckboxField";
import ReCAPTCHA from "react-google-recaptcha";
import Button from "../../../../src_shared/components/ui/Button/Button";
import SocialMediaButtons from "../components/socialMediaButtons/SocialMediaButtons";
import {Link, useHistory, useLocation} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import SignupModel from "../../../../modules/models/login/SignupModel";
import api from "../../../../services/axios/axios";
import {selmoUrl} from "../../../../src_shared/api/api";
import {services} from "../../../RestServices";
import {showAlert} from "../../shared/components/alert/AlertActions";
import {batch, useDispatch} from "react-redux";
import {parseParams} from "../../../../src_shared/list/ListActions";
import useFormLocal from "../../../../src_shared/hooks/useFormLocal";
import SessionActions from "../../shared/session/SessionActions";

const SignupForm = ({getLangText, lang, withSwitchButton = true, afterSubmit, afterSocialSubmit = () => {}}) => {
    const dispatch = useDispatch();
    const {search} = useLocation();
    const query = parseParams(search);
    const [passwordContentVisible, setPasswordContentVisible] = useState(false);
    const captchaRef = useRef(null);
    const [isWaiting, setIsWaiting] = useState(false);

    const [{getFieldProps, values, setIsValidationActive, setResponse, updateValues}] = useFormLocal({
        rest: services.API_REGISTER,
        model: SignupModel,
        afterSubmit,
        initialAutoLoad: false,
    });
    const allFieldValidate = () => SignupModel.validateData(values);

    const checkboxLabel = () => {
        return (
            <>
                {getLangText('acceptButton')}&nbsp;
                <a href={`https://www.selmo.io/${lang}/terms/`} target="_blank"
                   rel="noreferrer">{getLangText('termsButton')}</a>
                &nbsp;{getLangText('separatorAndLabel')}
                <a href={`https://www.selmo.io/${lang}/politic`} target="_blank" rel="noreferrer">
                    &nbsp;{getLangText('policyButton')}
                </a>
            </>
        )
    }

    const onSave = async (e) => {
        e?.preventDefault();

        const token = captchaRef?.current?.getValue();

        if (!token && lang === 'ph') {
            setIsValidationActive(true);
            console.error('Validation active')
            return;
        }

        const preparedData = SignupModel.buildDTO(values)
        if (!SignupModel.validateData(preparedData)) {
            setIsValidationActive(true);
            console.error('Validation active')
            return;
        }

        setIsWaiting(true);

        try {
            const {data} = await api.post(`${selmoUrl}/${services.API_REGISTER}`, preparedData)
            setResponse(data?.item || data?.items);
            afterSubmit(data?.item || data?.items || '');
            if (captchaRef) {
                captchaRef?.current?.reset();
            }

        } catch (e) {
            dispatch(showAlert(e.response?.data?.message || 'sthWentWrongWarning', '', 'alert-fail'))
        } finally {
            setIsWaiting(false);
        }
    };

    useEffect(() => {
        const utms = Object.keys(query)
            .filter(key => key.includes('utm'))
            .reduce((obj, key) => {
                return {
                    ...obj,
                    [key]: query[key]
                };
            }, {});

        updateValues({
            utm: utms,
            fromInstagram: query.fromInstagram,
            campaign_id: query.campaign_id,
            partner: query.partner,
        })
    }, [])

    return (
        <>
            <form id="registration-form" onSubmit={onSave} method="post">
                <Field
                    {...getFieldProps('email')}
                    label={getLangText('emailLabel')}
                    type="email"
                    extraProps={{
                        required: true,
                        onFocus: () => setPasswordContentVisible(true)
                    }}
                    className="big-size"
                    floatedLabel
                />
                {passwordContentVisible &&
                    <div className="animate-slideAndShow">
                        <Field
                            {...getFieldProps('password')}
                            label={getLangText('passwordLabel')}
                            className="big-size mb-3"
                            type="password"
                            extraProps={{required: true}}
                            floatedLabel
                        />
                        <CheckboxField
                            {...getFieldProps('agreement')}
                            LabelComponent={checkboxLabel()}
                            className="mb-3"
                            extraProps={{required: true}}
                        />
                        {lang === 'ph' &&
                            <ReCAPTCHA
                                ref={captchaRef}
                                sitekey={process.env.REACT_APP_SITE_KEY}
                            />
                        }
                    </div>
                }
                <div className="button-place mt-2">
                    <Button
                        getLangText={getLangText}
                        type="submit"
                        isWaiting={isWaiting}
                        title="registerForFreeLabel"
                        className={`button w-full ${allFieldValidate() ? 'primary-login' : 'grey-button'}`}
                    />
                </div>
            </form>
            <div className="relative border-t border-[#E5E7EB] mt-8 mb-8">
                        <span
                            className="text-lowercase bg-white px-2 absolute left-1/2 top-1/2 -mt-0.5 -translate-y-1/2 -translate-x-1/2 text-[#9CA3AF] text-[10px] font-bold">{getLangText('separatorOrLabel')}</span>
            </div>
            <SocialMediaButtons
                afterSubmit={afterSocialSubmit}
                getLangText={getLangText}
            />
            {withSwitchButton &&
                <div className="change-page">
                    {getLangText('haveAnAccount')}
                    <Link to={RouterPaths.Login} className="special-link">{getLangText('loginButton')}</Link>
                </div>
            }
        </>
    );
};

export default SignupForm;
