import React from 'react';
import Group from "../group/Group";

const CheckboxField = (props) => {

	const {
		name,
		setValue,
		value,
		disabled,
		extraProps,
		placeholder,
		label,
		LabelComponent,
		subLabel,
		onClick,
		className,
	} = props;

	const onChangeClick = (e) => {
		if (e.target.checked) {
			setValue(name, '1')
		} else {
			setValue(name, '0')
		}
	}

	return (
		<Group
			{...props}
			showLabel={false}
			className={`checkbox-form ${className}`}
		>
			<input
				className={`checkbox-input form-control ${subLabel ? 'with-sub-label' : ''}`}
				type="checkbox"
				checked={+value}
				onClick={onClick}
				onChange={onChangeClick}
				disabled={disabled}
				placeholder={placeholder}
				{...extraProps}
			/>
			<span className="checkbox-checkmark"/>
			<label className="control-label">
				{LabelComponent || <div dangerouslySetInnerHTML={{__html: label}}></div>}
				{subLabel &&
					<div className="sub-label">
						{subLabel}
					</div>
				}
			</label>
		</Group>
	);
};

CheckboxField.defaultProps = {
	onClick: () => {},
	LabelComponent: null,
	className: '',
}

export default CheckboxField;
