import React, {useState} from 'react';
import useLang from "../../../../../../src_shared/hooks/useLang";
import ConfirmModal from "../../../list/components/export/ConfirmModal";
import useSubmitData from "../../../../../../src_shared/hooks/useSubmitData";
import {services} from "../../../../../RestServices";

const MrAutomateStopped = ({loadData, id, values}) => {
    const {getLangText} = useLang();
    const [showModal, setShowModal] = useState(false);

    const [{onSave, isWaiting}] = useSubmitData({
        rest: `${services.API_UNPAUSE_ORDER}/${id}`,
        afterSubmit: () => {
            loadData();
            setShowModal(false);
        },
    });

    return (
        <div className="form-info-box items-center w-100 border flex-wrap flex-sm-nowrap border-[#5fa5fa]">
            <div className="flex mr-2">
                <div>
                    <i className="icon-info-c"/>
                </div>
                <div>
                    <div className="title">
                        {getLangText('mrAutomateDoesNotIncludeThisOrder')}
                    </div>
                    <div className="desc">
                        {getLangText(values.mr_automate_paused_type === 'CLOSE_OPEN_PACKAGE' ? 'clientPausedCloseOpenCart' : 'clientPausedCancelOrder')}
                    </div>
                </div>
            </div>
            <button
                style={{
                    padding: 12,
                }}
                onClick={() => setShowModal(true)}
                type="button"
                className="button primary whitespace-nowrap sm:ml-auto sm:mt-0 mt-2.5 sm:w-auto w-full">
                {getLangText('cancelStop')}
            </button>
            {showModal &&
                <ConfirmModal
                    dialogClassName="w-450 small-modal"
                    title={getLangText('wantToCancelPause')}
                    SubTitle={() => <>{getLangText('mrAutomateWillBeIncludeThisOrder')}</>}
                    saveButton={getLangText('yesButton')}
                    show={showModal}
                    isWaiting={isWaiting}
                    onSubmit={onSave}
                    hide={() => setShowModal(false)}
                />
            }
        </div>
    );
};

export default MrAutomateStopped;
