import React, {memo} from 'react';
import {NavLink} from "react-router-dom";
import {Helmet} from "react-helmet";
import useLang from "../../../../src_shared/hooks/useLang";
import {RouterPaths} from "../../../routes/RouterPath";

const OrlenSettingsWrapper = ({children}) => {
    const {getLangText} = useLang()

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Orlen Paczka - Selmo</title>
            </Helmet>
            <div className="row small-padd-row">
                <div className="col-lg-7">
                    <div className="settings-page sub-settings-page export-sub-settings">
                        <div className="header-wrapper no-border pb-0">
                            <h1>
                                <img style={{width: 'auto', height: '30px'}} src="/assets/images/svg/orlen-paczka.svg"
                                     alt="Orlen Paczka"/>
                                Orlen Paczka
                            </h1>
                            <div className="description">
                                {getLangText('realizeOrdersFasterLabel')}
                            </div>
                        </div>
                        <div className="tabs-wrapper">
                            <NavLink
                                exact
                                activeClassName="active"
                                to={RouterPaths.SettingsIntegrationOrlen}
                            >
                                {getLangText('configurationLabel')}
                            </NavLink>
                            <NavLink
                                activeClassName="active"
                                to={RouterPaths.SettingsIntegrationOrlenData}
                            >
                                {getLangText('senderDataLabel')}
                            </NavLink>
                        </div>
                        {children}
                    </div>
                </div>
                <div className="col-md-5 mt-md-0 mt-3">
                    <div className="default-shadow-box bigger-y-padd payment-benefits-wrapper">
                        <div className="label">{getLangText('paymentsHowItWorksLabel')}</div>
                        <ul className="benefits-list">
                            <li>
                                <i className="icon-tick-c"/>
                                {getLangText('oneCLickGenerateLabelsLabel')}
                            </li>
                            <li>
                                <i className="icon-tick-c"/>
                                {getLangText('saveTimeLabel')}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default memo(OrlenSettingsWrapper);
