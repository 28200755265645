import React, {useState} from 'react';
import Modal from "react-bootstrap/Modal";
import Product from "./components/Product";
import useLang from "../../../../../../../../../../src_shared/hooks/useLang";
import Loader from "../../../../../../../../shared/Loader";
import SearchInput from "../../../../../../../../shared/components/form/SearchInput";
import {services} from "../../../../../../../../../RestServices";
import useFetchData from "../../../../../../../../../../src_shared/hooks/useFetchData";
import {BUTTON_DESTINATION_PRODUCT} from "../../ButtonDestination";
import {USER_PERMISSIONS_PRODUCTS_ADD} from "../../../../../../../../shared/enums/UserPermissionsTypes";
import UserPermissionsWrapper from "../../../../../../../../../routes/UserPermissionsWrapper";

const AssignProductsModal = ({modalVisible, hideModal, updateValues, addNewProductModal, values}) => {

    const {getLangText} = useLang();
    const [activeProduct, setActiveProduct] = useState(values.selectedProduct);

    const [{isLoading, search, data}] = useFetchData({
        rest: services.API_STORAGE_CATEGORY_PRODUCTS,
        initialData: [],
        extraParams: {
            limit: 5,
        },
    });

    const onModalSave = () => {
        updateValues({
            selectedProduct: activeProduct,
            buttonDestination: BUTTON_DESTINATION_PRODUCT
        });
        hideModal();
    }

    return (
        <Modal
            dialogClassName="default-modal"
            show={modalVisible}
            onHide={hideModal}
        >
            <Modal.Header className="d-block">
                <div className="flex items-center">
                    <Modal.Title>
                        {getLangText('selectProductLabel')}
                    </Modal.Title>
                    <button
                        onClick={hideModal}
                        type="button"
                        className="button text-[9px] text-[#6B7280] ml-auto"
                    >
                        <i className="icon-cross"/>
                    </button>
                </div>
                <UserPermissionsWrapper allowedPermissions={[USER_PERMISSIONS_PRODUCTS_ADD]}>
                    <div className="text-desc text-sm">
                        <span className="mr-1">Jeśli chcesz dodać nowy produkt kliknij</span>
                        <button
                            type="button"
                            className="underline"
                            onClick={addNewProductModal}
                        >
                            {getLangText('addProductLabel')}
                        </button>
                    </div>
                </UserPermissionsWrapper>
            </Modal.Header>
            <Modal.Body className="pt-3 pb-5 assign-products-body">
                <SearchInput
                    className="mb-2"
                    placeholder={getLangText('searchPlaceholder')}
                    search={search}
                />
                <div className="loader-parent">
                    <Loader isLoading={isLoading}/>
                    {!data?.length ?
                        <div className="flex items-center justify-center mt-4">
                            <div className="text-center max-w-[200px]">
                                <div className="font-bold">{getLangText('noProductsLabel')}</div>
                                <div className="text-desc font-medium">
                                    <div>{getLangText('changeSearchOrAddProductsLabel')}</div>
                                    <UserPermissionsWrapper allowedPermissions={[USER_PERMISSIONS_PRODUCTS_ADD]}>
                                        <button
                                            type="button"
                                            className="button primary small-size mt-3"
                                            onClick={addNewProductModal}
                                        >
                                            {getLangText('addProductLabel')}
                                        </button>
                                    </UserPermissionsWrapper>
                                </div>
                            </div>
                        </div> :
                        <div className="data-boxes-wrapper small-padd products-boxes">
                            {data.map((item) => (
                                <Product
                                    key={item.id}
                                    item={item}
                                    activeProduct={activeProduct}
                                    setActiveProduct={setActiveProduct}
                                />
                            ))}
                        </div>
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button border-button"
                >
                    {getLangText('cancelButton')}
                </button>
                <button
                    onClick={onModalSave}
                    type="submit"
                    className={`button primary px-4 ${!activeProduct ? 'not-active' : ''}`}
                    disabled={!activeProduct}
                >
                    {getLangText('selectProductLabel')}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default AssignProductsModal;
