import Model, {convertObjectProperties} from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

class SettingsGeneralModel extends Model {
	getModel() {
		return {
			logo: null,
			name: '',
			time_to_pay: '',
			time_to_pay_active: '0',
			free_shipping_price: '',
			free_shipping_active: '0',
			currency: '',
			show_payment_status: '0',
			show_payment_received: '0',
			open_cart_enabled: '0',
			edit_open_cart_enabled: '0',
			open_cart_pickup: '0',
			edit_cart_enabled: '0',
			extra_input_enabled: '',
			currencies: [],
		};
	}

	parseDTO(data) {
		return convertObjectProperties(
			data,
			{
				edit_cart_enabled_time_value: (edit_cart_enabled_time_value) => !+edit_cart_enabled_time_value ? '' : edit_cart_enabled_time_value,
			},
			{},
		);
	}

	getValidators() {
		return {
			name: ValidationError.notEmpty,
		};
	}
}

export default new SettingsGeneralModel();
