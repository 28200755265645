import React from 'react';
import useFormLocal from "../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../RestServices";
import useLang from "../../../../src_shared/hooks/useLang";
import Field from "../../../../src_shared/components/form/field/Field";
import {useDispatch, useSelector} from "react-redux";
import Loader from "../../../app/shared/Loader";
import ComponentLoader from "../../../app/shared/components/componentLoader/ComponentLoader";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import SettingsChangePasswordModel from "../../../../modules/models/settings/SettingsChangePasswordModel";
import {showAlert} from "../../../app/shared/components/alert/AlertActions";
import {Helmet} from "react-helmet";

const PasswordForm = () => {
    const {getLangText} = useLang();
    const {userData} = useSelector((state) => state.session);
    const dispatch = useDispatch();

    const [{
        onSave,
        isWaiting,
        getFieldProps,
        isLoading,
    }] = useFormLocal({
        rest: services.API_SETTINGS_CHANGE_PASSWORD,
        model: SettingsChangePasswordModel,
        initialAutoLoad: false,
        afterSubmit: () => dispatch(showAlert()),
        onError: (e) => dispatch(showAlert(e.response.data.message, '', 'alert-fail')),
    });

    if (isLoading) return <ComponentLoader/>;

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getLangText('settings_nav_password')} - Selmo</title>
            </Helmet>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <Link to={RouterPaths.Settings}>{getLangText('asideSettingsLabel')}</Link>
                    </li>
                    <li>
                        <Link to={RouterPaths.SettingsAccount}>{getLangText('settings_nav_profile_settings')}</Link>
                    </li>
                    <li className="active">
                        <div>{getLangText('settings_nav_password')}</div>
                    </li>
                </ul>
            </div>
            <div className="settings-page sub-settings-page">
                <div className="header-wrapper">
                    <h1>
                        <img src="/assets/images/settings/preferences.svg" alt="preferences"/>
                        {getLangText('settings_nav_password')}
                    </h1>
                    <div className="description">{getLangText('settings_nav_preferences_description')}</div>
                </div>
                {userData.social_account ?
                    <div className="settings-form">
                        <div className="form-info-box w-100 small-line-height border border-[#D7EAFE] mb-3">
                            <div>
                                <i className="icon-info-c"/>
                            </div>
                            <div>
                                <div className="title">
                                    <span
                                        className="text-sm">{getLangText('usedTheLoginMethodWithoutEnteringPasswordLabel')}</span>
                                </div>
                                <div className="text-desc text-sm font-medium">
                                    {getLangText('usedTheLoginMethodWithoutEnteringPasswordSubLabel')}
                                </div>
                            </div>
                        </div>
                    </div> :
                    <div className="settings-form">
                        <section>
                            <div className="left-side">
                                <h2 className="label">{getLangText('input_label_email')}</h2>
                            </div>
                            <div className="form-col">
                                <div className="form-info-box w-100">
                                    <div>
                                        <div className="title">{getLangText('input_label_active_email')}</div>
                                        <div className="desc">
                                            {userData.email}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section>
                            <div className="left-side">
                                <h2 className="label">{getLangText('settings_nav_password')}</h2>
                            </div>
                            <div className="form-col">
                                <Field
                                    {...getFieldProps('password')}
                                    label={getLangText('input_label_active_password')}
                                    placeholder={getLangText('input_placeholder_active_password')}
                                    type="password"
                                />
                                <Field
                                    {...getFieldProps('newPassword')}
                                    label={getLangText('input_label_new_password')}
                                    placeholder={getLangText('input_placeholder_new_password')}
                                    type="password"
                                />
                                <Field
                                    {...getFieldProps('repeatPassword')}
                                    label={getLangText('input_label_confirm_password')}
                                    placeholder={getLangText('input_label_confirm_password')}
                                    type="password"
                                />
                            </div>
                        </section>
                        <div className="button-place">
                            <button
                                type="button"
                                onClick={onSave}
                                className={`button primary ${isWaiting ? 'loading-button' : ''}`}
                            >
                                <Loader isLoading={isWaiting}/>
                                {getLangText('saveButton')}
                            </button>
                        </div>
                    </div>
                }
            </div>
        </>
    );
};

export default PasswordForm;
