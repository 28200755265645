import Model from "../../../src_shared/modules/model/Model";
import {INPOST_LABEL_CUSTOM, INPOST_LABEL_SMALL} from "../../../components/app/shared/enums/InpostLabelsTypes";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

class GenerateLabelsInpostModel extends Model {
	getModel() {
		return {
			template: INPOST_LABEL_SMALL,
			length: '',
			width: '',
			height: '',
			weight: '',
		};
	}

	getValidators() {
		return {
			length: ValidationError.notEmpty,
			width: ValidationError.notEmpty,
			height: ValidationError.notEmpty,
			weight: ValidationError.notEmpty,
		};
	}
}

export default new GenerateLabelsInpostModel();
