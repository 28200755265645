import React, {useMemo} from 'react';
import SwitchField from "../../../../../../src_shared/components/form/switchField/SwitchField";
import useLang from "../../../../../../src_shared/hooks/useLang";

const AllSelectSwitch = ({values, groups, updateValues}) => {
    const {getLangText} = useLang();

    const totalPermissionsCount = useMemo(() => {
        return groups?.reduce((acc, group) => acc + group.permissions.length, 0);
    }, [groups]);

    const totalSelectedPermissionsCount = useMemo(() => {
        return values.selectedOptions?.reduce((acc, group) => acc + group.permissions.length, 0);
    }, [values.selectedOptions]);

    const isAllSelected = +totalPermissionsCount === +totalSelectedPermissionsCount;

    const setValue = () => updateValues({
        selectedOptions: isAllSelected ? groups.map((group) => ({...group, permissions: []})) : groups
    })

    return (
        <SwitchField
            name="add_button"
            setValue={setValue}
            value={isAllSelected}
            className="switch-form inline-label big-label blue-version mb-3"
            label={getLangText('selectAllLabel')}
        />
    );
};

export default AllSelectSwitch;
