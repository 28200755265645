import React from 'react';

const Skeleton = () => {
    return (
        <div>
            <div className="animate-pulse -mx-6 -mt-6 relative">
                <div
                    className="sm:h-[260px] h-[240px] bg-gray-200 rounded-[12px] dark:bg-gray-700 w-full mb-3"/>
                <div
                    className="absolute left-1/2 border-[4px] border-[#fff] bottom-[-25px] p-[4px] rounded-full -translate-x-1/2 w-[88px] h-[88px] bg-gray-200 dark:bg-gray-700"
                />
            </div>
            <div className="animate-pulse relative mt-8 space-y-7">
                <div className="text-center">
                    <div className="h-7 bg-gray-200 rounded-md dark:bg-gray-700 w-36 mb-2 mx-auto"/>
                    <div className="h-7 bg-gray-200 rounded-md dark:bg-gray-700 w-50 mb-2 mx-auto"/>
                    <div className="h-7 bg-gray-200 rounded-md dark:bg-gray-700 w-20 mb-2 mx-auto"/>
                </div>
                <div>
                    <div className="h-7 bg-gray-200 rounded-md dark:bg-gray-700 w-56 mb-3"/>
                    <div className="h-12 bg-gray-200 rounded-[12px] dark:bg-gray-700 w-full mt-2"/>
                    <div className="h-12 bg-gray-200 rounded-[12px] dark:bg-gray-700 w-full mt-2"/>
                    <div className="h-12 bg-gray-200 rounded-[12px] dark:bg-gray-700 w-full mt-2"/>
                </div>
                <div>
                    <div className="h-7 bg-gray-200 rounded-md dark:bg-gray-700 w-56 mb-3"/>
                    <div className="h-24 bg-gray-200 rounded-[12px] dark:bg-gray-700 w-full mt-2"/>
                    <div className="h-24 bg-gray-200 rounded-[12px] dark:bg-gray-700 w-full mt-2"/>
                    <div className="h-24 bg-gray-200 rounded-[12px] dark:bg-gray-700 w-full mt-2"/>
                </div>
            </div>
        </div>
    );
};

export default Skeleton;
