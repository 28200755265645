import React from 'react';
import Modal from "react-bootstrap/Modal";
import {useDispatch, useSelector} from "react-redux";
import FormActions from "./FormActions";
import Loader from "../../../shared/Loader";
import useForm from "../../../../../src_shared/form/useForm";
import {useParams} from "react-router-dom";
import Field from "../../../../../src_shared/components/form/field/Field";
import ImageUpload from "../../../../../src_shared/components/form/imageUpload/ImageUpload";
import useLang from "../../../../../src_shared/hooks/useLang";
import SwitchField from "../../../../../src_shared/components/form/switchField/SwitchField";
import AsyncSelectField from "../../../../../src_shared/components/form/selectField/AsyncSelectField";
import {services} from "../../../../RestServices";
import {TEST_ACCOUNT_SHOP_ID} from "../../../../../src_shared/enums/TrustedShopsIds";

const EditClientModal = ({isModalVisible}) => {
    const {userData} = useSelector((state) => state.session);
    const {id} = useParams();
    const {getLangText} = useLang();

    const dispatch = useDispatch();

    const {
        data: {values, setValue, updateValues},
        form: {isWaiting, onSave, getFieldProps},
    } = useForm({
        FormActions,
        params: {
            id,
        },
    });

    const hideModal = () => dispatch(FormActions.modal.hideModal())

    return (
        <Modal
            show={isModalVisible}
            dialogClassName="default-modal"
            onHide={hideModal}
        >
            <Modal.Header className="with-border">
                <Modal.Title>{getLangText('clientsEditModalTitle')}</Modal.Title>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            <Modal.Body className="">
                <ImageUpload
                    values={values}
                    updateValues={updateValues}
                    FormActions={FormActions}
                    imagePath="users"
                    defaultImage="fb-default.jpeg"
                    circleImage
                />
                <Field
                    {...getFieldProps('name')}
                    setValue={setValue}
                    className="mt-3"
                    label={getLangText('firstNameLabel')}
                />
                <div className="row">
                    <div className="col-md-6 pr-md-1">
                        <Field
                            {...getFieldProps('email')}
                            setValue={setValue}
                            label={getLangText('emailLabel')}
                        />
                    </div>
                    <div className="col-md-6 pl-md-1">
                        <Field
                            {...getFieldProps('phone')}
                            setValue={setValue}
                            label={getLangText('phoneLabel')}
                        />
                    </div>
                </div>
                {!!+userData.assigned_transfer_enabled &&
                    <AsyncSelectField
                        {...getFieldProps('transfer_account_id')}
                        label={getLangText('accountNumberLabel')}
                        setValue={setValue}
                        rest={services.API_TRANSFER_ACCOUNTS}
                        defaultValue={{
                            id: values.transfer_account_id,
                            name: values.transfer_account_name,
                        }}
                        optionsParser={(options) => options?.map((i) => ({
                            value: i.id,
                            label: i.transfer_account_name || i.name
                        }))}
                    />
                }
                {!!+userData.block_users_enabled &&
                    <SwitchField
                        {...getFieldProps('is_deleted')}
                        setValue={setValue}
                        className="switch-form inline-label light-version mt-3"
                        label={getLangText('blockedLabel')}
                    />
                }
            </Modal.Body>
            <Modal.Footer>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button border-button"
                >
                    {getLangText('cancelButton')}
                </button>
                <button
                    onClick={onSave}
                    type="button"
                    className="button primary"
                >
                    {<Loader isLoading={isWaiting}/>}
                    {getLangText('saveButton')}
                </button>
            </Modal.Footer>
        </Modal>);
};

export default EditClientModal;
