import createReducer from "../../../shared/helpers/createReducer.js";
import Composition from "../../../shared/helpers/Composition.js";
import FormReducer from "../../../../../src_shared/form/FormReducer.js";

export const getStateRoot = (state) => state.products.categories.seoForm;
export const prefix = 'CATEGORIES_SEO_FORM_';

const getInitState = () => FormReducer.getInitState();

const getReduceMap = () => new Composition(
	FormReducer.getReduceMap(),
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

