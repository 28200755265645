import React, {useState} from "react";
import {services} from "../../../../RestServices";
import ConfirmModal from "../../list/components/export/ConfirmModal";
import {selmoUrl} from "../../../../../src_shared/api/api";
import {batch, useDispatch, useSelector} from "react-redux";
import {showAlert} from "../../../shared/components/alert/AlertActions";
import EditOrderFormActions from "../form/FormActions";
import moment from "moment";
import CustomDropdown from "../../../shared/components/dropdown/CustomDropdown";
import {useModal} from "../../../shared/hooks/useModal";
import useLang from "../../../../../src_shared/hooks/useLang";
import api from "../../../../../services/axios/axios";
import Stages from "./components/stages/Stages";
import AfterCreatedButtons from "./components/AfterCreatedButtons";
import {
    ANGEL_GLADYS_SHOP_ID,
    BUTIK_MADAME_SHOP_ID,
    TEST_ACCOUNT_SHOP_ID
} from "../../../../../src_shared/enums/TrustedShopsIds";
import {SHOP_ORIGIN_FR} from "../../../shared/enums/ShopOrigins";
import {convertToUserTimeZone} from "../../../shared/helpers/dateHelpers";
import {RouterPaths} from "../../../../routes/RouterPath";

const FakturowniaLabelForm = ({data}) => {

    const dispatch = useDispatch();
    const {getLangText} = useLang();
    const {userData} = useSelector((state) => state.session);
    const [isWaitingForGenerate, setIsWaitingForGenerate] = useState(false);
    const [openConfirmInvoiceModal, setOpenConfirmInvoiceModal] = useModal(false);

    const generateDocument = async () => {
        setIsWaitingForGenerate(true);
        try {
            await api.post(`${selmoUrl}/${services.API_FAKTUROWNIA_ORDER_INVOICE}/${data.id}`)
            batch(() => {
                dispatch(showAlert('successfulInvoiceGeneratedLabel'))
                dispatch(EditOrderFormActions.loadData(data.shop_order_id))
            })
        } catch (e) {
            dispatch(EditOrderFormActions.loadData(data.shop_order_id))
            dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
            console.error('Error submitEditForm')
        } finally {
            setOpenConfirmInvoiceModal(false);
            setIsWaitingForGenerate(false);
        }
    }

    const emailSent = data.shippingData.fakturownia_invoice_email;
    const messengerSent = data.shippingData.fakturownia_invoice_messenger;

    const sentHistory = [
        {
            date: messengerSent,
            label: getLangText('messageSentLabel')
        },
        {
            date: emailSent,
            label: getLangText('emailSentLabel'),
        },
    ]

    const sortedHistory = sentHistory.sort(function (a, b) {
        return new Date(a.date) - new Date(b.date);
    });

    if (data.shippingData.shop_fakturownia_active === '0') {
        return null
    }

    if (data.shippingData.shop_fakturownia_active === null) {
        return (
            <section className="summary-section mb-3">
                <div className="section-header mb-3">
                    <div className="flex-grow-1">
                        <h2>{getLangText('configureInvoiceLabel')}</h2>
                    </div>
                    <div>
                        {(userData.origin === SHOP_ORIGIN_FR && +userData.id !== ANGEL_GLADYS_SHOP_ID) ?
                            <img
                                style={{height: '24px'}}
                                src="/assets/images/fakturownia-logo-fr.png" alt="VosFactures"/> :
                            <img
                                style={{height: '24px'}}
                                src="/assets/images/fakturownia-logo.svg" alt="Fakturownia"/>
                        }
                    </div>
                    <CustomDropdown className="ml-3">
                        <button
                            type="button"
                            className="custom-option"
                            onClick={() => dispatch(EditOrderFormActions.removeIntegrationBox('fakturownia'))}
                        >
                            {getLangText('hideLabel')}
                        </button>
                    </CustomDropdown>
                </div>
                <a
                    className="button border-button big-width w-100"
                    href={RouterPaths.SettingsIntegrationFakturownia}
                    target="_blank"
                >
                    {getLangText('configureLabel')}
                </a>
            </section>
        )
    }

    return (
        <>
            {!data.shippingData.fakturownia_invoice &&
                <section className="summary-section mt-3 mb-3">
                    <div className="section-header mb-3">
                        <div className="flex-grow-1">
                            {(userData.origin === SHOP_ORIGIN_FR && +userData.id !== ANGEL_GLADYS_SHOP_ID) ?
                                <h2>VosFactures</h2> :
                                <h2>Fakturownia</h2>
                            }
                            <div className="section-header__description">
                                {getLangText('manageSalesDocumentLabel')}
                            </div>
                        </div>
                        <div>
                            {(userData.origin === SHOP_ORIGIN_FR && +userData.id !== ANGEL_GLADYS_SHOP_ID) ?
                                <img
                                    style={{height: '24px'}}
                                    src="/assets/images/fakturownia-logo-fr.png" alt="VosFactures"/> :
                                <img
                                    style={{height: '24px'}}
                                    src="/assets/images/fakturownia-logo.svg" alt="Fakturownia"/>
                            }
                        </div>
                    </div>
                    {!data.shipping_address_exist ?
                        <div
                            className="form-info-box align-items-center w-100 small-line-height">
                            <div>
                                <i className="icon-info-c"/>
                            </div>
                            <div>
                                <div className="title">{getLangText('noDeliveryAddressLabel')}</div>
                            </div>
                        </div> :
                        <div>
                            <button
                                type="button"
                                onClick={() => setOpenConfirmInvoiceModal(true)}
                                className="button border-button justify-content-between big-width w-100 print-label-btn position-relative"
                            >
                                {getLangText('generateSalesDocumentLabel')}
                                <i className="icon-generate"/>
                            </button>
                            {(+userData.id === TEST_ACCOUNT_SHOP_ID || +userData.id === BUTIK_MADAME_SHOP_ID) &&
                                <Stages data={data}/>
                            }
                        </div>
                    }
                </section>
            }
            {!!data.shippingData.fakturownia_invoice &&
                <section className="summary-section mb-3">
                    <div className="section-header mb-3">
                        <div className="flex-grow-1">
                            {(userData.origin === SHOP_ORIGIN_FR && +userData.id !== ANGEL_GLADYS_SHOP_ID) ?
                                <h2>VosFactures</h2> :
                                <h2>Fakturownia</h2>
                            }
                            <div className="section-header__description">
                                {getLangText('manageSalesDocumentLabel')}
                            </div>
                        </div>
                        <div>
                            {(userData.origin === SHOP_ORIGIN_FR && +userData.id !== ANGEL_GLADYS_SHOP_ID) ?
                                <a href="https://vosfactures.fr/?partner=kaaubv"
                                   target="_blank"
                                   rel="nofollow"
                                >
                                    <img
                                        style={{height: '24px'}}
                                        src="/assets/images/fakturownia-logo-fr.png"
                                        alt="VosFactures"/>
                                </a>
                                :
                                <a href="https://fakturownia.pl/?partner=kaaubv"
                                   target="_blank"
                                   rel="nofollow"
                                >
                                    <img
                                        style={{height: '24px'}}
                                        src="/assets/images/fakturownia-logo.svg"
                                        alt="Fakturownia"/>
                                </a>
                            }
                        </div>
                    </div>
                    <AfterCreatedButtons data={data}/>
                    {(+userData.id === TEST_ACCOUNT_SHOP_ID || +userData.id === BUTIK_MADAME_SHOP_ID) &&
                        <Stages data={data}/>
                    }
                    <hr/>
                    <h2>{getLangText('historyLabel')}</h2>
                    <div className="histories-info">
                        <div className="info-label blue-icon">
                            <i className="icon-created-c"/>
                            {getLangText('createdDocumentLabel')}
                            <div className="date-box">
                                <span>{convertToUserTimeZone(data.shippingData.fakturownia_invoice_date).format('DD.MM.YYYY')}</span>
                                <span>{convertToUserTimeZone(data.shippingData.fakturownia_invoice_date).format('HH:mm:ss')}</span>
                            </div>
                        </div>
                        {!!data.shippingData.fakturownia_fiscal_print_date &&
                            <div className="info-label blue-icon">
                                <i className="icon-created-c"/>
                                {getLangText('createdFiscalPrintLabel')}
                                <div className="date-box">
                                    <span>{convertToUserTimeZone(data.shippingData.fakturownia_fiscal_print_date).format('DD.MM.YYYY')}</span>
                                    <span>{convertToUserTimeZone(data.shippingData.fakturownia_fiscal_print_date).format('HH:mm:ss')}</span>
                                </div>
                            </div>
                        }
                        {sortedHistory.map((i) => (
                            i.date &&
                            <div
                                key={i.date}
                                className="info-label blue-icon">
                                <i className="icon-sent-c"/>
                                {i.label}
                                <div className="date-box">
                                    <span>{convertToUserTimeZone(i.date).format('DD.MM.YYYY')}</span>
                                    <span>{convertToUserTimeZone(i.date).format('HH:mm:ss')}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </section>
            }
            {openConfirmInvoiceModal &&
                <ConfirmModal
                    title={getLangText('askForInvoiceGenerateLabel')}
                    saveButton={getLangText('yesButton')}
                    show={openConfirmInvoiceModal}
                    isWaiting={isWaitingForGenerate}
                    onSubmit={generateDocument}
                    hide={() => setOpenConfirmInvoiceModal(false)}
                />
            }
        </>
    );
};

export default FakturowniaLabelForm;



