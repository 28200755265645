import {combineReducers} from "redux";
import changelogReducer from './changeLog/changeLogReducer'
import listReducer from './list/listReducer'
import filtersReducer from "./filters/filtersReducer";

export default combineReducers({
	list: listReducer,
	filters: filtersReducer,
	changelog: changelogReducer,
});
