let urlVariable = '';

if (localStorage.getItem('selmo_api_dev')) {
	urlVariable = localStorage.getItem('selmo_api_dev');
}

if (!localStorage.getItem('selmo_api_dev') && window.location.hostname.includes('panel.')) {
	urlVariable = 'https://app.selmo.io';
}

if (!localStorage.getItem('selmo_api_dev') && window.location.hostname.includes('cart.')) {
	urlVariable = 'https://app.selmo.io';
}

if (!localStorage.getItem('selmo_api_dev') && window.location.hostname.includes('.vercel.app')) {
	urlVariable = 'https://app.selmo.io';
}
//
// if (!localStorage.getItem('selmo_api_dev') && window.location.hostname.includes('192.168.0.155')) {
// 	urlVariable = 'https://dev.selmo.io';
// }


export const selmoUrl = urlVariable;
export const selmoAPI2Url = 'https://api2.selmo.io';

