import React, {useCallback} from 'react';
import debounce from 'lodash.debounce';

const SearchInput = ({search, placeholder, withIcon = true, className = '', autoFocus = false, searchRef, callSearch}) => {
	const onChange = (e) => {
		search.updateSearchText(e.target.value)
		debouncedSave(e)
	}

	const debouncedSave = useCallback(
		debounce((e) => callSearch(e.target.value), 500),
		[]
	);

	return (
		<div className={`form-group search-form ${className}`}>
			<input
				ref={searchRef}
				className="form-control"
				type="text"
				onChange={onChange}
				value={search.searchText}
				placeholder={placeholder}
				autoFocus={autoFocus}
			/>
			{withIcon && <i className="icon-magnifier"/>}
		</div>
	);
};

export default SearchInput;
