import React, {useState} from 'react';
import ListActions from "./ListActions.js";
import {getStateRoot} from "./listReducer.js";
import Table from "./components/Table";
import Pagination from "../../shared/components/pagination/Pagination";
import useLang from "../../../../src_shared/hooks/useLang";
import useList from "../../../../src_shared/list/useList";
import SelmoPaczkaPageWrapper from "../SelmoPaczkaPage";
import BalanceModal from "../../orders/details/selmoPaczka/components/shared/BalanceModal";
import ExportModal from "../../../../src_shared/components/exportModal/ExportModal";
import {services} from "../../../RestServices";
import {getStateRoot as getFiltersStateRoot} from "../filters/filtersReducer";
import FiltersActions from "../filters/FiltersActions";
import DateRangePicker from "../../shared/components/form/DateRangePicker";

const TransactionsHistoryList = () => {

    const {getLangText} = useLang();
    const [showBalanceModal, setShowBalanceModal] = useState(false);
    const [openExportModal, setOpenExportModal] = useState(false);

    const {
        pagination: {
            changePage,
            getPagination,
            itemsPerPage,
            nextPage,
            prevPage,
            totalItems,
            updateItemsPerPage
        },
        list: {items, isWaiting},
        sort: {updateSortOptions, getSortClass},
    } = useList({
        ListActions,
        getStateRoot,
    });


    return (
        <SelmoPaczkaPageWrapper>
            <div className="table-list-wrapper mt-3">
                <div className="default-shadow-box pb-0">
                    <div className="table-header">
                        <div className="top-part mb-sm-0 mb-2 flex-wrap">
                            <h1
                                style={{width: 'auto'}}
                                className="title grow">
                                {getLangText('balanceHistory')}
                                {totalItems > 0 &&
                                    <div className="badge">{totalItems}</div>
                                }
                            </h1>
                            <button
                                type="button"
                                onClick={() => setShowBalanceModal(true)}
                                className="button primary sm:hidden small-size ml-0"
                            >
                                Doładuj saldo
                            </button>
                            <div className="flex items-center sm:w-auto w-full sm:mt-0 mt-2.5">
                                <button
                                    type="button"
                                    onClick={() => setOpenExportModal(true)}
                                    className="icon-button lgrey mr-3"
                                >
                                    <span className="d-md-none">{getLangText('exportLabel')}</span>
                                    <i className="icon-print"/>
                                </button>
                                <button
                                    type="button"
                                    onClick={() => setShowBalanceModal(true)}
                                    className="button primary sm:block hidden small-size ml-0"
                                >
                                    Doładuj saldo
                                </button>
                                <DateRangePicker
                                    actions={ListActions}
                                    getFiltersStateRoot={getFiltersStateRoot}
                                    FiltersActions={FiltersActions}
                                    className="ml-sm-2 ml-auto"
                                />
                            </div>
                        </div>
                    </div>
                    <Table
                        getSortClass={getSortClass}
                        updateSortOptions={updateSortOptions}
                        items={items}
                        isWaiting={isWaiting}
                    />
                </div>
                <Pagination
                    getLangText={getLangText}
                    pages={getPagination()}
                    items={items}
                    itemsPerPage={itemsPerPage}
                    totalItems={totalItems}
                    onPrevPage={prevPage}
                    onChangePage={changePage}
                    onNextPage={nextPage}
                    onChangeItemsPerPage={updateItemsPerPage}
                    classes={{
                        prevIcon: 'icon-chevron left',
                        nextIcon: 'icon-chevron right',
                    }}
                    showItemsPerPageSelect
                />
            </div>
            {showBalanceModal &&
                <BalanceModal
                    show={showBalanceModal}
                    hideModal={() => setShowBalanceModal(false)}
                />
            }
            {openExportModal &&
                <ExportModal
                    title={getLangText('exportBalanceHistory')}
                    subTitle={getLangText('receivedFileInXLSFormatLabel')}
                    saveButton={getLangText('exportLabel')}
                    rest={services.API_EXPORT_SELMO_PACZKA_BALANCE_XLSX}
                    fileName={getLangText('asideOrdersLabel')}
                    fileType="xlsx"
                    show={openExportModal}
                    hide={() => setOpenExportModal(false)}
                />
            }
        </SelmoPaczkaPageWrapper>
    );
};

export default TransactionsHistoryList;
