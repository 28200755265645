import React from 'react';
import {SHOP_PERMISSION} from "../../../shared/enums/SelmoPermissions";
import useLang from "../../../../../src_shared/hooks/useLang";
import {checkRoles} from "../../../../routes/PermissionRoute";
import {useSelector} from "react-redux";
import SwitchField from "../../../../../src_shared/components/form/switchField/SwitchField";

const MobileApp = ({getFieldProps, setValue}) => {
    const {getLangText} = useLang();

    const {permissions} = useSelector((state) => state.session.userData);
    const permissionsMatched = checkRoles(permissions, [SHOP_PERMISSION]);

    // if (!permissionsMatched) {
    //     return null;
    // }

    return (
        <section className="default-shadow-box big-padd mb-3">
            <div className="left-side">
                <h2 className="font-bold mb-3.5">{getLangText('visibleInMobileAppLabel')}</h2>
            </div>
            <div className="form-col flex">
                <SwitchField
                    {...getFieldProps('is_mobile_app_active')}
                    setValue={setValue}
                    label={getLangText('visibleInMobileAppLabel')}
                    subLabel={getLangText('visibleInMobileAppSubLabel')}
                    id="is_mobile_app_active"
                    className="switch-form inline-label big-label light-version mb-0"
                />
            </div>
        </section>
    );
};

export default MobileApp;
