import React, {memo} from 'react';
import {NavLink} from "react-router-dom";
import {Helmet} from "react-helmet";
import useLang from "../../../../src_shared/hooks/useLang";
import {RouterPaths} from "../../../routes/RouterPath";

const Wrapper = ({children, setShowModal}) => {
    const {getLangText} = useLang()

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>PrestaShop - Selmo</title>
            </Helmet>
            <div className="row small-padd-row">
                <div className="col-lg-7">
                    <div className="settings-page sub-settings-page export-sub-settings">
                        <div className="header-wrapper no-border pb-0 flex items-center">
                            <img style={{width: 'auto', height: '12px'}} src="/assets/images/svg/presta-logo.svg"
                                 alt="PrestaShop"/>
                            <button
                                className={`button border-button small-size ml-auto`}
                                onClick={() => setShowModal(true)}
                                type="button"
                            >
                                <i className="icon-refresh mr-1"/>
                                {getLangText('syncProducts')}
                            </button>
                        </div>
                        <div className="tabs-wrapper">
                            <NavLink
                                exact
                                activeClassName="active"
                                to={RouterPaths.SettingsIntegrationPrestaShop}
                            >
                                {getLangText('configurationLabel')}
                            </NavLink>
                        </div>
                        {children}
                    </div>
                </div>
                <div className="col-md-5 mt-md-0 mt-3">
                    {/*<div className="default-shadow-box bigger-y-padd payment-benefits-wrapper">*/}
                    {/*    <div className="label">{getLangText('paymentsHowItWorksLabel')}</div>*/}
                    {/*    <ul className="benefits-list">*/}
                    {/*        <li>*/}
                    {/*            <i className="icon-tick-c"/>*/}
                    {/*            {getLangText('oneCLickGenerateLabelsLabel')}*/}
                    {/*        </li>*/}
                    {/*        <li>*/}
                    {/*            <i className="icon-tick-c"/>*/}
                    {/*            {getLangText('saveTimeLabel')}*/}
                    {/*        </li>*/}
                    {/*    </ul>*/}
                    {/*</div>*/}
                </div>
            </div>
        </>
    );
};

export default memo(Wrapper);
