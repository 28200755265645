import createReducer from "../../shared/helpers/createReducer";
import DataReducer from "../../../../src_shared/form/data/DataReducer";
import {getReduceMap} from "../../../../src_shared/filters/filterReducer";
import history from "../../../history";
import {parseParams} from "../../../../src_shared/list/ListActions";
import moment from "moment";
import {ALL_ORDERS_VIEW, INTERVAL} from "../../shared/enums/OrderViewTypes";
import {convertToUserTimeZone} from "../../shared/helpers/dateHelpers";

const queryParams = history.location.search;
const parsedParams = parseParams(queryParams)

const preparedViewType = !!queryParams ? (parsedParams.dateFrom ? INTERVAL : ALL_ORDERS_VIEW) : INTERVAL;
const preparedIsRealized = parsedParams.isRealized || '0';
const prepareCurrency = parsedParams.currency;

const preparedDate = (initialState, paramsDate) => {
	if (!!queryParams) {
		if (parsedParams[paramsDate]) {
			return moment(parsedParams[paramsDate]).toDate()
		} else {
			return null;
		}
	} else {
		return initialState;
	}
}

export const getStateRoot = (state) => state.statsNew.filters;

export const getInitState = () => DataReducer.getInitState({
	fromDate: preparedDate(moment(new Date()).subtract(1, 'M').toDate(), 'dateFrom'),
	toDate: preparedDate(new Date(), 'dateTo'),
	viewType: preparedViewType,
	isRealized: preparedIsRealized,
	currency: prepareCurrency,
}, {
	fromDate: moment(new Date()).subtract(1, 'M').toDate(),
	toDate: moment().toDate(),
	viewType: INTERVAL,
	isRealized: '0',
	currency: null,
})

export const prefix = 'STATS_FILTERS_NEW_';

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();
