import ListReducer from "../../../../../src_shared/list/ListReducer";
import createReducer from "../../../shared/helpers/createReducer";
import Composition from "../../../shared/helpers/Composition";
import CheckboxMultiselectReducer
	from "../../../../../src_shared/list/checkboxMultiselect/CheckboxMultiselectReducer";

const multiSelectReducer = CheckboxMultiselectReducer
	.buildReducer('selectedItems', 'selectedItems');

export const getStateRoot = (state) => state.products.form.reserveList;
export const prefix = 'PRODUCTS_FORM_RESERVE_LIST_';

const defaultState = {
	selectedItems: [],
	waitingForSend: false,
}

const getInitState = () => ListReducer.getInitState(defaultState);

const getReduceMap = () => new Composition(
	ListReducer.getReduceMap(),
	multiSelectReducer.reduceMap,
	{
		SET_TOTAL_PRICE: (state, action) => ({...state, totalPrice: action.totalPrice}),
	},
	{
		SET_QUANTITY: (state, action) => ({...state, quantity: action.quantity})
	},
	{
		SET_WAITING_FOR_SEND: (state, action) => ({...state, waitingForSend: action.toggle}),
	}
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

