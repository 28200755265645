import DataReducer from "../../../../../../src_shared/form/data/DataReducer";
import {getReduceMap} from "../../../../../../src_shared/filters/filterReducer";
import moment from "moment/moment";
import {INTERVAL} from "../../../../../app/shared/enums/OrderViewTypes";
import createReducer from "../../../../../app/shared/helpers/createReducer";
import {convertToUserTimeZone} from "../../../../../app/shared/helpers/dateHelpers";

export const getStateRoot = (state) => state.settings.automations.automaticReserveList.filters;

export const getInitState = () => DataReducer.getInitState(
    {
        fromDate: moment(new Date()).subtract(1, 'M').toDate(),
        toDate: moment(),
        viewType: INTERVAL,
    }
)

export const prefix = 'SETTINGS_AUTOMATIONS_AUTOMATIC_RESERVE_LIST_FILTERS';

export const getInstance = () => createReducer(
    getInitState(),
    getReduceMap(),
    prefix,
);

export default getInstance();
