import ListActions, {getGETParamsUrl} from "../../../../../src_shared/list/ListActions.js";
import {getStateRoot, prefix} from './listReducer.js';
import {RouterPaths} from "../../../../routes/RouterPath.js";
import {services} from "../../../../RestServices.js";
import {getStateRoot as getFiltersStateRoot} from "../../filters/filtersReducer.js";
import history from "../../../../history.js";
import {convertDate} from "../../../shared/helpers/dateHelpers";
import {selmoUrl} from "../../../../../src_shared/api/api";
import SessionActions from "../../../shared/session/SessionActions";
import {batch} from "react-redux";
import api from "../../../../../services/axios/axios";

export class ProductsListActions extends ListActions {
	getLoadParams(state) {
		const params = super.getLoadParams(state);
		const dateFrom = getFiltersStateRoot(state).data.fromDate;
		const dateTo = getFiltersStateRoot(state).data.toDate;
		const source = getFiltersStateRoot(state).data.source;
		const statuses = getFiltersStateRoot(state).data.statuses;
		const selmo_booster = getFiltersStateRoot(state).data.selmo_booster;

		if (dateFrom) {
			params.dateFrom = convertDate(dateFrom);
		}

		if (dateTo) {
			params.dateTo = convertDate(dateTo);
		}

		if (source.length) {
			params['source[]'] = source;
		}

		if (statuses.length) {
			params['status[]'] = statuses
		}

		if (selmo_booster && selmo_booster !== 'all') {
			params.selmo_booster = selmo_booster
		}


		history.replace({
			pathname: this.pathname,
			search: getGETParamsUrl(params)
		});

		return params;
	}

	setTotalPrice(totalPrice) {
		return {
			type: `${this.prefix}SET_TOTAL_PRICE`,
			totalPrice,
		}
	}

	setQuantity(quantity) {
		return {
			type: `${this.prefix}SET_QUANTITY`,
			quantity,
		}
	}

	loadData(id) {
		return async (dispatch, getState) => {
			const {firstInit} = getState().session;
			dispatch(this.waiting.startWaiting())
			if (firstInit) {
				dispatch(this.onFirstInit());
			}
			const params = this.getLoadParams(getState(), id)

			const restUrl = +id ? `/${id}${getGETParamsUrl(params)}` : getGETParamsUrl(params);
			try {
				const response = await api.get(`${selmoUrl}/${this.restService}${restUrl}`)
				const {items, total, total_price, total_quantity} = response.data;
				batch(() => {
					dispatch(this.table.updateAllItems(items))
					dispatch(this.pagination.updateTotalItems(total))
					dispatch(this.setTotalPrice(total_price))
					dispatch(this.setQuantity(total_quantity))
				})
			} catch (e) {
				dispatch(this.table.getListError(e))
				console.error(e)
			} finally {
				if (firstInit) {
					dispatch(SessionActions.setFirstInit());
				}
				dispatch(this.waiting.stopWaiting())
			}
		}
	}
}

export const getInstance = () => new ProductsListActions({
	getStateRoot,
	restService: services.API_PRODUCTS_LATEST,
	pathname: RouterPaths.ProductsLatestList,
	preventPushParams: true,
	prefix,
});

export default getInstance();
