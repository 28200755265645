import React from 'react';
import useLang from "../../../../../../src_shared/hooks/useLang";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../../routes/RouterPath";

const EmptyFanpages = ({type = 'ig'}) => {
    const {getLangText} = useLang();
    return (
        <div>
            <div className="form-info-box w-100 mb-3">
                <div>
                    <i className="icon-info-c"/>
                </div>
                <div>
                    <div className="title">
                        <span className="text-sm">{getLangText('itSeemsYouDontHaveCreatedPageLabel')}</span>
                    </div>
                    <div className="desc">
                        {getLangText('createFanpageToConnectToSelmoLabel')}
                    </div>
                </div>
            </div>
            {type === 'fb' ?
                <a
                    className="button primary facebook-button w-full text-center"
                    href="https://www.facebook.com/pages/create"
                    target="_blank">
                    <div className="py-0.5">{getLangText('createFanpageLabel')}</div>
                </a> :
                <a
                    className="button primary facebook-button w-full text-center"
                    href="https://business.instagram.com/getting-started"
                    target="_blank">
                    <div className="py-0.5">{getLangText('createInstagramFanpageLabel')}</div>
                </a>
            }
            <div className="relative border-t border-[#E5E7EB] mt-8 mb-8">
                <div
                    className="text-lowercase bg-white px-2 absolute left-1/2 top-1/2 -mt-0.5 -translate-y-1/2 -translate-x-1/2 text-[#9CA3AF] text-[10px] font-bold">
                    {getLangText('separatorOrLabel')}
                </div>
            </div>
            <Link
                to={`${RouterPaths.TutorialSocials}?connectAgain=1`}
                type="button"
                className="button border-button google-button w-full big-size"
            >
                {getLangText('connectAgainLabel')}
            </Link>
        </div>
    );
};

export default EmptyFanpages;
