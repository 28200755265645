import React from 'react';
import Modal from "react-bootstrap/Modal";
import ServerImage from "../../../../components/app/shared/components/serverImage/ServerImage";

const PhotoPreview = ({show, hide, src}) => {
    if (!show) {
        return null;
    }
    return (
        <Modal
            dialogClassName="default-modal preview-img-modal"
            show={show}
            onHide={hide}
            contentClassName="bg-transparent"
        >
            <button
                onClick={hide}
                type="button"
                className="fixed animate-[fadeIn_.3s_ease_forwards_.4s] opacity-0 top-3 right-3 w-[40px] h-[40px] text-[10px] rounded-full bg-white text-[#3C61EA] flex items-center justify-center"
            >
                <i className="icon-cross"/>
            </button>
            <ServerImage
                className="sm:w-auto w-full max-w-[700px] mx-auto max-h-[calc(100vh-60px)] sm:rounded-xl"
                src={src || show}
                alt="preview"
            />
        </Modal>
    );
};

export default PhotoPreview;
