import React, {useEffect} from 'react';
import {services} from "../../../../RestServices";
import Loader from "../../../../app/shared/Loader";
import useLang from "../../../../../src_shared/hooks/useLang";
import useFormLocal from "../../../../../src_shared/hooks/useFormLocal";
import AsyncSelectField from "../../../../../src_shared/components/form/selectField/AsyncSelectField";
import Model from "../../../../../src_shared/modules/model/Model";

const AccountTypeSelect = ({fieldName = 'paypal_merchant_id', data, type = 'paypal', label = 'paypalAccount'}) => {

    const {getLangText} = useLang();

    const [{
        onSave,
        isWaiting,
        getFieldProps,
        setValue,
    }] = useFormLocal({
        rest: services.API_SETTINGS_ADDITIONAL_PAYMENTS,
        model: new Model(),
        initialAutoLoad: false,
        afterSubmit: () => {},
        method: 'put'
    });

    useEffect(() => {
        if (data[fieldName]) {
            setValue(fieldName, data[fieldName])
        }
    }, [data[fieldName]])

    if (!data.account_description) return null;

    return (
        <div className="flex items-center border-t pt-3">
            <div className="sm:max-w-[300px]">
                <div className="form-label">{getLangText(label)}</div>
                <AsyncSelectField
                    className="mb-0 min-w-[180px] default-size"
                    optionsParser={(options) => options?.map((i) => ({
                        value: i.account,
                        label: i.description,
                    }))}
                    name={fieldName}
                    {...getFieldProps(fieldName)}
                    showLabel={false}
                    rest={`${services.API_SETTINGS_ADDITIONAL_PAYMENTS}?type=${type}`}
                    defaultValue={{
                        id: data[fieldName],
                        name: data['account_description'],
                    }}
                    withLackOption={false}
                    extraProps={{
                        isSearchable: false,
                    }}
                />
            </div>
            <button
                type="button"
                onClick={onSave}
                className={`button small-size ml-auto primary ${isWaiting ? 'loading-button' : ''}`}
            >
                {isWaiting && <Loader isLoading/>}
                {getLangText('saveButton')}
            </button>
        </div>
    );
};

export default AccountTypeSelect;
