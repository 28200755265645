import {INVOICE_DATA_COMPANY} from "../../../components/app/shared/enums/InvoiceDataEnums";
import ValidationError from "../../../src_shared/form/validation/ValidationError";
import Model, {convertObjectProperties} from "../../../src_shared/modules/model/Model";

export const validateInvoiceData = (value, data) => {
    if (data.invoice !== INVOICE_DATA_COMPANY) {
        return false;
    }
    return ValidationError.notEmpty(value, data)
}
export const validateInvoiceDataNip = (value, data) => {
    if (data.invoice !== INVOICE_DATA_COMPANY) {
        return false;
    }

    if (+data.country_id !== 103) {
        return ValidationError.combine(
            ValidationError.notEmpty,
            ValidationError.minLength(5),
        )(value, data)
    }
    return ValidationError.combine(
        ValidationError.validateNip,
        ValidationError.minLength(5),
    )(value, data)
}

export const validateInvoiceDataZipCode = (value, data) => {
    if (data.invoice !== INVOICE_DATA_COMPANY) {
        return false;
    }
    if (+data.country_id !== 103) {
        return ValidationError.notEmpty(value, data);
    }
    return ValidationError.combine(
        ValidationError.notEmpty,
        ValidationError.validateZipCode,
    )(value, data)
}

export class SummerPromoModel extends Model {
    getModel() {
        return {
            invoice: INVOICE_DATA_COMPANY,
            country_id: 0,
            nip: '',
            zip_code: '',
            company_name: '',
            city: '',
            country_data: {},
        };
    }

    getValidators() {
        return {
            country_id: ValidationError.notEmpty,
            nip: validateInvoiceDataNip,
            company_name: validateInvoiceData,
            address: validateInvoiceData,
            zip_code: validateInvoiceDataZipCode,
            city: ValidationError.notEmpty,
        };
    }

    parseDTO(data) {
        return convertObjectProperties(
            data,
            {

            },
            {
                country: {
                    name: data?.country_data?.name,
                    id: data?.country_data?.id,
                },
                invoice: INVOICE_DATA_COMPANY,
                company_name: data?.name,
                country_id: data?.country_id,
            },
        );
    }

    buildDTO(data) {
        const invoice_data = {
            country_id: data.country_id || data.country?.id,
            nip: data.nip,
            name: data.company_name,
            address: data.address,
            city: data.city,
            zip_code: data.zip_code,
        }

        if (data.invoice === INVOICE_DATA_COMPANY) {
            return {
                invoice_data,
                country_id: data.country_id || data.country?.id,
            }
        }

        return {
            country_id: data.country_id || data.country?.id,
            city: data.city,
        };
    }
}

export default new SummerPromoModel();
