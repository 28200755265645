import Model from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

class SettingsGLSModel extends Model {
    getModel() {
        return {
            email: '',
            client_number: '',
            password: '',
            name: '',
            street: '',
            house_number: '',
            city: '',
            zip_code: '',
            country: '',
            phone: '',
            active: '0',
        };
    }

    getValidators() {
        return {
            email: ValidationError.combine(
                ValidationError.notEmpty,
                ValidationError.validateEmail,
            ),
            client_number: ValidationError.notEmpty,
            password: ValidationError.notEmpty,
            name: ValidationError.notEmpty,
            street: ValidationError.notEmpty,
            house_number: ValidationError.notEmpty,
            city: ValidationError.notEmpty,
            zip_code: ValidationError.notEmpty,
            country: ValidationError.notEmpty,
            phone: ValidationError.notEmpty,
            active: ValidationError.skip,
        };
    }

    buildDTO(data) {
        return {
            email: data.email,
            client_number: data.client_number,
            password: data.password,
            name: data.name,
            street: data.street,
            house_number: data.house_number,
            city: data.city,
            zip_code: data.zip_code,
            country: data.country,
            phone: data.phone,
            active: data.active,
        }
    }
}

export default new SettingsGLSModel();
