import {useCallback, useState} from 'react';
import { useDispatch } from 'react-redux';

export const useCheckboxMultiselect = (MultiCheckboxActions, selectedItems, items) => {
    const dispatch = useDispatch();
    const [isAllSelected, setIsAllSelected] = useState(false);

	const checkIfSomeExist = useCallback(() => {
		if (!selectedItems.length) {
			return false;
		}

		return items.some((item) => selectedItems.some((selectedItem) => selectedItem.id === item.id));
	}, [selectedItems, items]);

    const isSelected = useCallback((id) => {
        if (!selectedItems) {
            return false;
        }

        return !!selectedItems.find((item) => item.id === id);
    }, [selectedItems]);

    const toggleSelection = useCallback((item, name, value) => {
        setIsAllSelected(false);
        if (!!+value) {
            dispatch(MultiCheckboxActions.selectItem(item));
            return;
        }

        dispatch(MultiCheckboxActions.deselectItem(item.id));
    }, [MultiCheckboxActions, dispatch]);

    const selectAllItems = useCallback((field, toggleOn) => {
        setIsAllSelected(false);
        items.map((item) => toggleSelection(item, 'selected', toggleOn));
    }, [items, toggleSelection]);

    const updateSelection = useCallback(
        (selected) => {
            setIsAllSelected(false);
            dispatch(MultiCheckboxActions.updateSelection(selected))
        },
        [MultiCheckboxActions, dispatch],
    );

    const setIsAllSelectedItems = useCallback(() => {
        setIsAllSelected(true);
        dispatch(MultiCheckboxActions.updateSelection(items))
    }, [items, toggleSelection]);

    return {
		checkIfSomeExist,
        isSelected,
        selectAllItems,
        toggleSelection,
        updateSelection,
        isAllSelected,
        setIsAllSelectedItems,
        setIsAllSelected
    };
};

export default useCheckboxMultiselect;
