import {getStateRoot, prefix} from './listReducer.js';
import {RouterPaths} from "../../../../routes/RouterPath.js";
import {services} from "../../../../RestServices.js";
import ListActions, {getGETParamsUrl} from "../../../../../src_shared/list/ListActions";
import {getStateRoot as getFiltersStateRoot} from "../filters/filtersReducer";
import {convertDate} from "../../../../app/shared/helpers/dateHelpers";
import history from "../../../../history";
import api from "../../../../../services/axios/axios";
import {selmoUrl} from "../../../../../src_shared/api/api";
import {batch} from "react-redux";
import SessionActions from "../../../../app/shared/session/SessionActions";

export class TeamStatsListActions extends ListActions {
	getLoadParams(state) {
		const params = super.getLoadParams(state);
		const dateFrom = getFiltersStateRoot(state).data.fromDate;
		const dateTo = getFiltersStateRoot(state).data.toDate;
		const types = getFiltersStateRoot(state).data.types;
		const employees = getFiltersStateRoot(state).data.employees;

		if (dateFrom) {
			params.dateFrom = convertDate(dateFrom);
		}

		if (dateTo) {
			params.dateTo = convertDate(dateTo);
		}

		if (types.length) {
			params['types[]'] = types
		}

		if (employees.length) {
			params['employees[]'] = employees
		}

		history.replace({
			pathname: this.pathname,
			search: getGETParamsUrl(params)
		});

		return params;
	}

	loadData(id) {
		return async (dispatch, getState) => {
			const {firstInit} = getState().session;
			dispatch(this.waiting.startWaiting())
			if (firstInit) {
				dispatch(this.onFirstInit());
			}
			const params = this.getLoadParams(getState(), id)

			const restUrl = +id ?
				`/${id}${getGETParamsUrl(params)}` :
				getGETParamsUrl(params);
			try {
				const response = await api.get(`${selmoUrl}/${this.restService}${restUrl}`, )
				const {item, total} = response.data;

				batch(() => {
					dispatch(this.table.updateAllItems(item))
					dispatch(this.pagination.updateTotalItems(total))
				})

			} catch (e) {
				dispatch(this.table.getListError(e))
				console.error(e)
			} finally {
				if (firstInit) {
					dispatch(SessionActions.setFirstInit());
				}
				dispatch(this.waiting.stopWaiting())
			}
		}
	}

}
export const getInstance = () => new TeamStatsListActions({
	getStateRoot,
	restService: services.API_PERMISSIONS_OVERALL_STATS,
	pathname: RouterPaths.SettingsTeamStatistics,
	prefix,
});

export default getInstance();
