import Model from "../../../src_shared/modules/model/Model";

class CurrencyTypesModel extends Model {
	getModel() {
		return {
			currency: '',
			currencies: [],
		};
	}

	buildDTO(data) {
		return {
			currency: data.currency,
		};
	}
}

export default new CurrencyTypesModel();
