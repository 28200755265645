import React from 'react';
import useOrderProductsForm from "./useOrderProductsForm";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import NameInput from "./components/NameInput";
import SelectSizesModal from "../../modal/SelectSizesModal";
import {useSelector} from "react-redux";
import {createSelector} from "reselect";
import CountInput from "./components/CountInput";
import useLang from "../../../../../../src_shared/hooks/useLang";

const OrderProductsForm = ({actions, getStateRoot, dropdownPortalTarget, nameInputAutoFocus = true}) => {
	const {getLangText} = useLang();

	const {
		manualProducts,
		addRowTabIndex,
		setManualFieldValue,
		onAddNewRowClick,
		removeManualRow,
		updateFieldsInProduct,
	} = useOrderProductsForm(actions, getStateRoot);

	const {data} = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));
	const uniqueOrdersStage = manualProducts.filter((i, key) => i.order_stage && i.order_stage !== manualProducts[key - 1]?.order_stage).map((i, key) => ({
		...i,
		displayNumber: key + 1
	}));

	const preparedProducts = manualProducts.map((x) => ({
		...x,
		displayNumber: uniqueOrdersStage.find((y) => y.order_stage === x.order_stage)?.displayNumber
	}))

	const hasError = (product) => data?.productActiveValidation && product.product_name && product.product_price === '';

	const getProductStage = (product, index) => {
		if (data.is_open_cart !== '1') {
			return null;
		}
		if (!product.order_stage || (index !== 0 && product.order_stage === manualProducts[index - 1]?.order_stage)) {
			return null;
		}
		return (
			<h3 className="stage-separator">{getLangText('purchaseLabel')} <span>{product.displayNumber}</span></h3>
		)
	}

	const onCountInputChange = (value, product) => {
		if (+product?.product_max_count < +value) {
			setManualFieldValue('quantity', product?.product_max_count, product.helperId)
			return;
		}
		setManualFieldValue('quantity', value, product.helperId)
	}

	return (
		<section>
			<div className="inputs-row-section">
				{preparedProducts.map((product, index) => (
					<React.Fragment key={product.helperId}>
						{getProductStage(product, index)}
						<div className="inputs-row form-row">
							<NameInput
								dropdownPortalTarget={dropdownPortalTarget}
								product={product}
								actions={actions}
								autofocus={nameInputAutoFocus}
							/>
							<div>
								<div className={`form-group ${hasError(product) ? 'error-group' : ''}`}>
									<label className="control-label">{getLangText('priceLabel')}</label>
									<input
										tabIndex={product.tabIndex}
										type="number"
										className="form-control"
										name="product_price"
										onChange={(e) => setManualFieldValue('product_price', e.target.value, product.helperId)}
										value={product.product_price}
										required
									/>
								</div>
							</div>
							<div>
								<CountInput
									product={product}
									onChange={onCountInputChange}
								/>
							</div>
							<div className="button-col sm:mt-0 -mt-3">
								<OverlayTrigger
									placement='top'
									overlay={
										<Tooltip id={`tooltip-remove-products`}>
											{getLangText('removeProductLabel')}
										</Tooltip>
									}
								>
									<button
										type="button"
										tabIndex={-1}
										className="button remove-row-button mb-sm-0 mb-4"
										onClick={() => removeManualRow(product.helperId)}
									>
										<i className="icon-cross sm:text-xs text-[10px]"/>
										<span className="sm:hidden opacity-80">{getLangText('removeProductLabel')}</span>
									</button>
								</OverlayTrigger>
							</div>
						</div>
						{product.is_final === '1' &&
							<div className="form-group checkbox-form">
								<input
									className="checkbox-input form-control"
									type="checkbox"
									onChange={(e) => setManualFieldValue('to_repay', e.target.checked, product.helperId)}
									checked={product.to_repay}
								/>
								<span className="checkbox-checkmark"/>
								<label className="control-label mb-0">{getLangText('againToPayLabel')}</label>
							</div>
						}
					</React.Fragment>
				))}

			</div>
			{data?.productActiveValidation &&
				<div className="form-info-box alert-style red w-100">
					<div>
						<i className="icon-info-c" />
					</div>
					<div className="title">
						{getLangText('fillRequireFieldWarning')}
					</div>
				</div>
			}
			<div>
				<button
					tabIndex={addRowTabIndex}
					type="button"
					className="button text-blue-button add-new-row"
					onClick={onAddNewRowClick}
				>
					<i className="icon-plus"/>
					{getLangText('addNextProductLabel')}
				</button>
			</div>
			<SelectSizesModal
				updateFieldsInProduct={updateFieldsInProduct}
			/>
		</section>
	);
};

export default OrderProductsForm;
