import React from 'react';
import Modal from "react-bootstrap/Modal";
import useLang from "../../hooks/useLang";
import Loader from "../loader/Loader";

const ConfirmModal = ({show, hide, dialogClassName = 'small-modal', submitButtonClass='primary', headerClassName = '', isWaiting, title, SubTitle, saveButton, onSubmit, children, backdropClassName, modalClasName, disabledSubmitButton = false}) => {
	const {getLangText} = useLang();

	return (
		<Modal
			dialogClassName={`default-modal ${dialogClassName}`}
			show={show}
			onHide={hide}
			backdropClassName={backdropClassName}
			className={modalClasName}
		>
			<Modal.Header className={headerClassName}>
				<Modal.Title>
					{title}
					<div className="description">
						{SubTitle && <SubTitle />}
					</div>
				</Modal.Title>
				<button
					onClick={hide}
					type="button"
					className="button"
				>
					<i className="icon-cross"/>
				</button>
			</Modal.Header>
			<Modal.Body>
				{children}
			</Modal.Body>
			<Modal.Footer>
				<button
					onClick={hide}
					type="button"
					className="button border-button"
				>
					{getLangText('cancelButton')}
				</button>
				<button
					disabled={disabledSubmitButton}
					onClick={onSubmit}
					type="button"
					className={`button px-0 ${submitButtonClass} ${isWaiting ? 'loading-button' : ''}`}
				>
					{isWaiting && <Loader isLoading/>}
					{saveButton}
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default ConfirmModal;
