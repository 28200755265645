import React from 'react';
import Table from "./components/Table";
import Pagination from "../../../../app/shared/components/pagination/Pagination";
import useLang from "../../../../../src_shared/hooks/useLang";
import useList from "../../../../../src_shared/list/useList";
import {getStateRoot} from "./listReducer";
import ListActions from "./ListActions";
import FiltersActions from "../../filters/FiltersActions";
import {getStateRoot as getFiltersStateRoot} from "../../filters/filtersReducer";
import DateRangePicker from "../../../../app/shared/components/form/DateRangePicker";

const MemberHistory = ({match}) => {
    const {params} = match;
    const {getLangText} = useLang();

    const {
        list: {items, isWaiting, loadData},
        pagination: {
            changePage, getPagination, itemsPerPage, nextPage, prevPage, totalItems, updateItemsPerPage
        },
        sort: {updateSortOptions, getSortClass},
    } = useList({
        ListActions,
        getStateRoot,
        FiltersActions,
        hasParentId: true,
        params,
    });

    return (
        <>
            <div className="table-list-wrapper mt-3">
                <div className="default-shadow-box">
                    <div className="table-header">
                        <div className="filters-part d-block mt-0">
                            <div className="top-part">
                                <h1 className="title">
                                    {getLangText('activityHistory')}
                                    {/*{totalItems > 0 && <div className="badge">{totalItems}</div>}*/}
                                </h1>
                                <DateRangePicker
                                    actions={ListActions}
                                    getFiltersStateRoot={getFiltersStateRoot}
                                    FiltersActions={FiltersActions}
                                    className="ml-auto"
                                    loadData={loadData}
                                    extraProps={{
                                        id: params.id,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <Table
                        isWaiting={isWaiting}
                        items={items}
                        updateSortOptions={updateSortOptions}
                        getSortClass={getSortClass}
                        loadData={loadData}
                    />
                </div>
            </div>
            <Pagination
                getLangText={getLangText}
                pages={getPagination()}
                items={items}
                itemsPerPage={itemsPerPage}
                totalItems={totalItems}
                onPrevPage={prevPage}
                onChangePage={changePage}
                onNextPage={nextPage}
                onChangeItemsPerPage={updateItemsPerPage}
                classes={{
                    prevIcon: 'icon-chevron left', nextIcon: 'icon-chevron right',
                }}
            />
        </>
    );
};

export default MemberHistory;
