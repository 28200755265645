import {getStateRoot, prefix} from './listReducer';
import {services} from "../../../../RestServices";
import ListActions, {getGETParamsUrl} from "../../../../../src_shared/list/ListActions";
import {RouterPaths} from "../../../../routes/RouterPath";
import {getStateRoot as getFiltersStateRoot} from "../filters/filtersReducer";
import {convertDate} from "../../../shared/helpers/dateHelpers";
import history from "../../../../history";

export class AutomationNewsletterListListActions extends ListActions {
	getLoadParams(state, id) {
		const params = super.getLoadParams(state);
		const dateFrom = getFiltersStateRoot(state).data.fromDate
		const dateTo = getFiltersStateRoot(state).data.toDate

		if (dateFrom) {
			params.dateFrom = convertDate(dateFrom);
		}

		if (dateTo) {
			params.dateTo = convertDate(dateTo);
		}

		if (!this.preventPushParams) {
			history.replace({
				pathname: id ? `${this.pathname.replace(':id', id)}` : this.pathname,
				search: getGETParamsUrl(params)
			});
		}

		return params;
	}
}

export const getInstance = () => new AutomationNewsletterListListActions({
	getStateRoot,
	restService: services.API_STORE_NEWSLETTER,
	pathname: RouterPaths.AutomationNewsletterFull,
	prefix,
});

export default getInstance();
