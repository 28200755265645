import React from 'react';
import {useDispatch} from "react-redux";
import SessionActions from "../session/SessionActions";
import {selmoUrl} from "../../../../src_shared/api/api";
import {services} from "../../../RestServices";
import {showAlert} from "../components/alert/AlertActions";
import {useHistory} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import api from "../../../../services/axios/axios";

const useLogout = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const logout = async () => {
        try {
            await api.post(`${selmoUrl}/${services.API_LOGOUT}`)
            dispatch(SessionActions.logout())
            dispatch(showAlert('successfullyLogoutLabel', ''));
            history.push({
                pathname: RouterPaths.Login,
                search: '',
            });

        } catch (e) {
            dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
        }
    };

    return {
        logout,
    }
};

export default useLogout;
