import createReducer from "../../shared/helpers/createReducer";
import FormReducer from "../../../../src_shared/form/FormReducer";
import Composition from "../../shared/helpers/Composition";
import ProductCodeModel from "../../../../modules/models/settings/ProductCodeModel";

export const getStateRoot = (state) => state.settings.productCodes;
export const prefix = 'PRODUCT_CODES_FORM_';

export const getInitState = () => FormReducer.getInitState(ProductCodeModel.getModel())

export const getReduceMap = () => new Composition(
	FormReducer.getReduceMap(),
);

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();
