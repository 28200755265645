import React, {useEffect, useState} from 'react';
import {SortableContainer, SortableElement} from "react-sortable-hoc";
import {arrayMoveImmutable as arrayMove} from 'array-move';
import Photo from "./Photo";
import Gallery from "react-photo-gallery";
import ConfirmRemoveModal from "../../modal/ConfirmRemoveModal.jsx";
import {selmoUrl} from "../../../api/api";
import {services} from "../../../../components/RestServices";
import {useDispatch} from "react-redux";
import useLang from "../../../hooks/useLang";
import {showAlert} from "../../../../components/app/shared/components/alert/AlertActions";
import api from "../../../../services/axios/axios";

const SortablePhoto = SortableElement(props => <Photo {...props} />);
const SortableGallery = SortableContainer(({items, setSelectedGalleryPhoto, selectedPhotos, setModalVisible}) => (
	<Gallery
		photos={items}
		renderImage={props => (
			<>
				<SortablePhoto
					setSelectedGalleryPhoto={setSelectedGalleryPhoto}
					selectedPhotos={selectedPhotos}
					setModalVisible={setModalVisible}
					{...props}
				/>
			</>
		)}
	/>
));
const PhotosGallery = ({
						   photos,
						   updateAllItemsOnServer,
						   removeRest = services.API_MEDIA_DELETE,
						   imagePath,
						   loadData,
						   selectedPhotos,
						   setSelectedGalleryPhoto,
						   modalVisible,
						   hideModal,
						   setSelectedGalleryPhotos,
						   setModalVisible,
					   }) => {

	const dispatch = useDispatch();
	const {getLangText} = useLang();

	const [items, setItems] = useState(photos || []);
	const [waitingForRemove, setWaitingForRemove] = useState(null);
	const onSortEnd = ({oldIndex, newIndex}) => {
		const updatedItems = arrayMove(items, oldIndex, newIndex);
		setItems(updatedItems);
		updateAllItemsOnServer(updatedItems)
	};

	const handleRemove = async () => {
		setWaitingForRemove(true)
		try {
			await api.post(`${selmoUrl}/${removeRest}`, {
				path: imagePath,
				ids: selectedPhotos,
			})
			loadData();
			hideModal()
			dispatch(showAlert('yuhuLabel', 'successfulRemovedLabel'))
		} catch (e) {
			console.warn('Cannot remove photo from gallery')
		} finally {
			setWaitingForRemove(false)
		}
	}


	useEffect(() => {
		setItems(photos)
	}, [photos])

	return (
		<div className={`selected-gallery-box`}>
			<SortableGallery
				items={items}
				onSortEnd={onSortEnd}
				axis={"xy"}
				selectedPhotos={selectedPhotos}
				setSelectedGalleryPhoto={setSelectedGalleryPhoto}
				setModalVisible={setModalVisible}
			/>
			{modalVisible &&
				<ConfirmRemoveModal
					dialogClassName="small-modal"
					show={modalVisible}
					title={getLangText('askForRemoveSelectedPhotosLabel')}
					hide={hideModal}
					confirm={handleRemove}
					isWaiting={waitingForRemove}
				/>
			}
		</div>
	);
};

export default PhotosGallery;
