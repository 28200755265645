import ListReducer from "../../../../src_shared/list/ListReducer";
import createReducer from "../../shared/helpers/createReducer";
import Composition from "../../shared/helpers/Composition";
export const getStateRoot = (state) => state.mrAutomate.page;
export const prefix = 'MR_AUTOMATE_PAGE_';

const getInitState = () => ListReducer.getInitState({
	items: {
		activities: {},
	},
});

const getReduceMap = () =>  new Composition(
	ListReducer.getReduceMap(),
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

