import React from 'react';
import useFetchData from "../../../../src_shared/hooks/useFetchData";
import {services} from "../../../RestServices";

export const TutorialFinalStepsContext = React.createContext({
    data: {
        photo: '',
    },
    isLoading: false,
});
const FinalStepsWrapper = ({children}) => {

    const [{isLoading, data}] = useFetchData({
        rest: services.API_TUTORIAL_INFO,
        initialData: {
            shop_id: null,
            isFacebook: true,
            isInstagram: false,
            name: '',
            photo: '',
        }

    });

    return (
        <TutorialFinalStepsContext.Provider
            value={{data, isLoading}}
        >
            {children}
        </TutorialFinalStepsContext.Provider>
    );
};

export default FinalStepsWrapper;
