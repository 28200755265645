import React from 'react';
import Chart from "react-apexcharts";
import EmptyList from "../../../shared/EmptyList";
import useLang from "../../../../../src_shared/hooks/useLang";

const ShippingChart = ({items}) => {
	const {getLangText} = useLang();

	const preparedArrayToDisplay = items.shippingInfo?.slice(0, 5) || [];

	const displayedPercentSum = preparedArrayToDisplay?.reduce((prev, curr) => +prev + +curr.percent, [])
	const displayedSum = preparedArrayToDisplay?.reduce((prev, curr) => +prev + +curr.sum, [])

	const displayedTotalSum = items.shippingInfo?.reduce((prev, curr) => +prev + +curr.sum, [])

	const options = {
		chart: {
			id: "payments-donuts",
			type: 'donut',
		},
		colors: ['#1C4ED8', '#5FA5FA', '#90E8EE', '#FF8100', '#FFD133', '#3B29CC'],
		opacity: 1,
		stroke: {
			width: 0,
		},
		legend: {
			show: false
		},
		dataLabels: {
			enabled: false
		},
		labels: [...preparedArrayToDisplay?.map((i) => i.name), getLangText('otherEmojiLabel')],
		tooltip: {
			custom: function ({series, seriesIndex, w}) {
				return `<div class="chart-tooltip">
<div class="label">${w.config.labels[seriesIndex]}</div>
<div class="value">${series[seriesIndex]} %</div>
</div>`
			}
		}
	};

	return (
		<div className="default-shadow-box mb-3">
			<div className="donuts-chart-wrapper">
				<div className="title">{getLangText('shipmentsLabel')}</div>
				<EmptyList
					className="full-size"
					imgSrc="/assets/images/empty/chart-3.svg"
					items={items.shippingInfo}
					title=""
				>
					<div className="donuts-chart">
						<Chart
							options={options}
							series={[...preparedArrayToDisplay?.map((i) => +i.percent), 100 - displayedPercentSum]}
							type="donut"
							width="100%"
						/>
					</div>
				</EmptyList>
				<div className="custom-legend">
					{preparedArrayToDisplay?.map((i) => (
						<div key={i.id}>
							<div className="label">{i.name}</div>
							<div className="value">
								{Math.round(i.percent)} %<strong>{i.sum}</strong>
							</div>
						</div>
					))}
					{!!items.shippingInfo?.length && displayedTotalSum - displayedSum > 0 &&
						<div>
							<div className="label">{getLangText('otherEmojiLabel')}</div>
							<div className="value">
								{100 - displayedPercentSum} %<strong>{displayedTotalSum - displayedSum}</strong>
							</div>
						</div>
					}
				</div>
			</div>
		</div>
	);
};

export default ShippingChart;
