export const PRODUCTS_TYPE_ACTIVE_ALL = 'all';
export const PRODUCTS_TYPE_ACTIVE = '1';
export const PRODUCTS_TYPE_NOT_ACTIVE = '0';


export const PRODUCTS_ACTIVE_TYPES = [
	{
		value: PRODUCTS_TYPE_ACTIVE_ALL, label: 'allLabel',
	},
	{
		value: PRODUCTS_TYPE_ACTIVE, label: 'activeLabel',
	},
	{
		value: PRODUCTS_TYPE_NOT_ACTIVE, label: 'notActiveLabel',
	},
]
