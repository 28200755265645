import React, {useEffect} from 'react';
import Button from "../../../../../src_shared/components/ui/Button/Button";
import useFetchData from "../../../../../src_shared/hooks/useFetchData";
import {services} from "../../../../RestServices";
import {useLocation} from "react-router-dom";
import {getGETParamsUrl, parseParams} from "../../../../../src_shared/list/ListActions";
import Cookies from 'js-cookie'
import _ from "lodash";

const SocialMediaButtons = ({
                                getLangText, afterSubmit = () => {
    }
                            }) => {
    const {search} = useLocation();

    const query = parseParams(search);

    const fromInstagramParam = query.fromInstagram;
    const campaignId = query.campaign_id;
    const partner = query.partner;

    const utms = Object.keys(parseParams(search))
        .filter(key => key.includes('utm'))
        .reduce((obj, key) => {
            return {
                ...obj,
                [key]: parseParams(search)[key]
            };
        }, {});

    const rest = (type) => `${services.API_REGISTER_SOCIALS}${getGETParamsUrl({...utms, type})}`

    const afterSubmitPrepared = (response) => {
        afterSubmit();
        window.location.href = response.link
    };

    const [{isLoading: isWaitingForFacebook, fetchData: getFacebookLink}] = useFetchData({
        rest: rest('fb'),
        afterSubmit: afterSubmitPrepared,
        initialAutoLoad: false,
    })

    const [{isLoading: isWaitingForGoogle, fetchData: getGoogleLink}] = useFetchData({
        rest: rest('google'),
        afterSubmit: afterSubmitPrepared,
        initialAutoLoad: false,
    })

    useEffect(() => {
        Cookies.set('fromInstagramParam', fromInstagramParam)
        Cookies.set('campaignId', campaignId)
        Cookies.set('partner', partner)
        if (!_.isEmpty(utms)) {
            Cookies.set('utms', JSON.stringify(utms))
        }
    }, [utms, query.fromInstagram, campaignId, partner])

    return (
        <div className="button-place">
            <Button
                getLangText={getLangText}
                onClick={() => getFacebookLink()}
                isWaiting={isWaitingForFacebook}
                title="continueViaFacebookLabel"
                className="button primary facebook-button w-full mb-2 overflow-hidden"
                Icon={<img width={18} height={18} className="mr-1" alt="facebook"
                           src="/assets/images/svg/facebook-icon.svg"/>}
            />
            <Button
                getLangText={getLangText}
                onClick={() => getGoogleLink()}
                isWaiting={isWaitingForGoogle}
                title="continueViaGoogleLabel"
                className="button border-button google-button w-full overflow-hidden"
                Icon={<img width={18} height={18} className="mr-1" alt="google"
                           src="/assets/images/google-logo-c.png"/>}
            />
        </div>
    );
};

export default SocialMediaButtons;
