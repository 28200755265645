import React from 'react';
import {RouterPaths} from "../../../../../routes/RouterPath";
import ShippyProWrapper from "../Form";
import Loader from "../../../../shared/Loader";
import Field from "../../../../../../src_shared/components/form/field/Field";
import {services} from "../../../../../RestServices";
import SwitchField from "../../../../../../src_shared/components/form/switchField/SwitchField";
import useFormLocal from "../../../../../../src_shared/hooks/useFormLocal";
import useLang from "../../../../../../src_shared/hooks/useLang";
import ShippyProConfigurationModel from "../../../../../../modules/models/settings/ShippyProConfigurationModel";
import {Link} from "react-router-dom";

const Configuration = () => {
	const {getLangText} = useLang()

	const rest = services.API_SETTINGS_SHIPPYPRO;

	const [{onSave, isWaiting, getFieldProps, isLoading}] = useFormLocal({
		rest,
		method: 'post',
		model: ShippyProConfigurationModel,
	});

	return (
		<>
			<div className="header-bar breadcrumbs-in-react-app">
				<ul className="breadcrumbs">
					<li>
						<Link to={RouterPaths.Settings}>
							{getLangText('asideSettingsLabel')}
						</Link>
					</li>
					<li className="active">
						<div>ShippyPro</div>
					</li>
				</ul>
			</div>
			<ShippyProWrapper>
				<div className="loader-parent">
					<Loader isLoading={isLoading}/>
					<div className="settings-form pt-3">
						<div className="steps-wrapper">
							<div className="step border-step">
								<div className="step-header">
									<div className="number">
										1
									</div>
									<div className="title d-block">
										<a href="https://www.shippypro.com/register.html"
										   target="_blank">
											<img style={{marginLeft: 0, height: '18px', width: 'auto'}}
												 src="/assets/images/shippypro.png" alt="shippypro"/>
										</a>
										<div>
											{getLangText('settings_integrations_furgonetka_login_1')}
										</div>
									</div>
									<a target="_blank"
									   href="https://www.shippypro.com/register.html"
									   className="button primary small-size ml-auto text-center">
										{getLangText('settings_integrations_fakturownia_description_4')}
									</a>
								</div>
							</div>
							<div className="step border-step mt-3 pb-lg-5 pb-3">
								<div className="step-header align-items-start">
									<div>
										<div className="number">
											2
										</div>
									</div>
									<div className="title d-block">
										<div>{getLangText('copApiKeyLabel')}</div>
										<div style={{fontWeight: '500'}} className="grey-txt small-text small-line-height">
											{getLangText('copApiKeySubLabel')}
										</div>
										<img className="w-100 h-auto mx-0 mt-2 mb-4" src="/assets/images/shippypro/01.webp" alt="shippy pro 1"/>
										<div style={{fontWeight: '500'}} className="grey-txt small-text small-line-height">
											{getLangText('shippyProImageTwoInstructionLabel')}
										</div>
										<img className="w-100 h-auto mx-0 mt-2 mb-4" src="/assets/images/shippypro/02.webp" alt="shippy pro 2"/>
										<div style={{fontWeight: '500'}} className="grey-txt small-text small-line-height">
											{getLangText('shippyProImageThreeInstructionLabel')}
										</div>
										<img className="w-100 h-auto mx-0 mt-2" src="/assets/images/shippypro/03.webp" alt="shippy pro 2"/>
									</div>
								</div>
							</div>
							<div className="step border-step mt-3 pb-lg-4 pb-3">
								<div className="step-header align-items-start">
									<div className="number">
										3
									</div>
									<div className="title d-block flex-grow-1 mt-2">
										<div>{getLangText('pasteApiKeyBelowLabel')}</div>
										<Field
											{...getFieldProps('api_key')}
											label={getLangText('apiKeyLabel')}
											placeholder={getLangText('pasteApiKey')}
											className="mt-3"
										/>
									</div>
								</div>
							</div>
							<div className="d-flex align-items-center mt-2">
								<SwitchField
									{...getFieldProps('active')}
									label={getLangText('active')}
									className="switch-form inline-label align-items-start big-label light-version mb-0"
								/>
								<div className="button-place ml-auto">
									<button
										type="button"
										onClick={onSave}
										className={`button primary ${isWaiting ? 'loading-button' : ''}`}
									>
										<Loader isLoading={isWaiting}/>
										{getLangText('saveButton')}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</ShippyProWrapper>
		</>
	);
};

export default Configuration;
