import ListReducer from "../../../../src_shared/list/ListReducer";
import createReducer from "../../shared/helpers/createReducer";
import Composition from "../../shared/helpers/Composition";

export const getStateRoot = (state) => state.news.list;
export const prefix = 'NEWS_LIST_';

const getInitState = () => ListReducer.getInitState({
	itemsPerPage: 4,
	scrollPage: 1,
	hasMore: true,
});

const getReduceMap = () =>  new Composition(
	ListReducer.getReduceMap(),
	{
		SET_SCROLL_PAGE: (state, action) => ({...state, scrollPage: action.scrollPage}),
	},
	{
		SET_HAS_MORE_PAGES: (state, action) => ({...state, hasMore: action.toggle}),
	},
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

