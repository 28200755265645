import React, {useContext, useState} from 'react';
import useLang from "../../../src_shared/hooks/useLang";
import useFetchData from "../../../src_shared/hooks/useFetchData";
import {services} from "../../RestServices";
import {useSelector} from "react-redux";
import SelmoProBenefits from "./components/SelmoProBenefits";
import BlackConfirmModal from "./components/BlackConfirmModal";
import SelmoProSignUpModal from "./components/SelmoProSignUpModal";
import {SelmoProLandingContext} from "../shared/components/SelmoProBox/SelmoProLandingWrapper";

const SelmoPro = () => {
    const {getLangText} = useLang();
    const {userData} = useSelector((state) => state.session);
    const {lang} = useSelector((state) => state.i18n);
    const [showServiceFeeModal, setShowServiceFeeModal] = useState(false);

    const {data, isLoading, isSubmittedToQualified, setShowModal} = useContext(SelmoProLandingContext);

    const CheckIfYouQualifyButton = ({className = ''}) => (
        <div className={`text-center ${className}`}>
            <button
                onClick={() => setShowModal(true)}
                type="button"
                className={`block button white rounded-[5px] hover:no-underline w-full duration-300 relative py-3 transition-all bg-[white] font-bold text-[#000] shadow-2xl hover:shadow-[#5f87f3] hover:text-[#000] ${isSubmittedToQualified ? 'pointer-events-none' : ''}`}
            >
                {getLangText(isSubmittedToQualified ? 'yourApplicationHasBeenSubmitted' : 'checkIfYouQualify')}
            </button>
            <div className="text-[#919190] text-sm mt-[12px] font-medium">{getLangText('ourSpecialistWillConcat')}</div>
        </div>
    )

    const ActivateButton = ({className = ''}) => (
        <div className={`text-center ${className}`}>
            <a
                href={data?.payment_link}
                className="block rounded-[5px] hover:no-underline w-full duration-300 relative py-3 transition-all bg-[white] font-bold text-[#000] shadow-2xl hover:shadow-[#5f87f3] hover:text-[#000]"
            >
                {getLangText('activeSelmoPro')}
            </a>
            <a
                className="inline-block text-[#919190] text-xs font-medium hover:no-underline mt-3 hover:text-[#fff] transition-all"
                href={`/assets/pdf/${lang === 'pl' ? 'selmo-pro.pdf' : 'selmo-pro-en.pdf'}`}
                target="_blank"
                dangerouslySetInnerHTML={{__html: getLangText('byClickingAcceptRegulation')}}
            />
        </div>
    )
    return (
        <div
            className="bg-[#000000] sm:rounded-[5px] shadow-default md:min-h-[calc(100vh-75px)] min-h-[calc(100vh-57px)]">
            <div className="text-center py-16 sm:py-24 sm:px-0 px-6 z-[1]">
                <div className="max-w-[650px] mx-auto mb-3">
                    <img
                        src="/assets/images/robo-landing.webp"
                        className={`min-w-[80px] mx-auto w-[80px] sm:hidden ${isLoading ? 'animate-[bounce_1s_ease_infinite]' : 'animate-[bounce_4s_ease_infinite]'}`}
                        alt="Selmo Robo"
                    />
                    <img className="w-[140px] mx-auto mb-3" src="/assets/images/svg/selmo-pro-logo.svg"
                         alt="Selmo Pro"/>
                    <h1 className="text-2xl sm:text-[32px] text-[#C0C0C0] sm:leading-[44px] font-light whitespace-pre-wrap"
                        dangerouslySetInnerHTML={{__html: getLangText('selmoProTitle')}}/>
                </div>
                <div className="relative">
                    <div
                        className={`absolute z-[1] sm:block hidden transition-all duration-1000 ${isLoading ? 'left-1/2 top-0 -translate-x-1/2 scale-75' : 'left-[calc(100%-230px)] top-[50px] scale-100'}`}>
                        <img
                            src="/assets/images/robo-landing.webp"
                            className={`min-w-[210px] w-[210px] ${isLoading ? 'animate-[bounce_1s_ease_infinite]' : 'animate-[bounce_4s_ease_infinite]'}`}
                            alt="Selmo Robo"
                        />
                    </div>
                    {!isLoading &&
                        <div className="max-w-[475px] mx-auto animate-[fadeIn_.3s_ease_forwards]">
                            <div className="opacity-0 animate-tutorialSlideAndFadeInDelay1 mt-5">
                                <div className="bg-[#161617] border border-[#2A2A2B] rounded-[12px] p-4 text-left">
                                    <div className="flex items-center text-[white] text-lg font-medium mb-3 sm:mb-3">
                                        {getLangText({
                                            key: 'offerFor',
                                            data: [userData.name]
                                        })}
                                        <img className="w-[112px] ml-auto" src="/assets/images/svg/selmo-pro-logo.svg"
                                             alt="Selmo Pro"/>
                                    </div>
                                    <div className="text-2xl sm:text-[32px] font-bold text-[#fff]">
                                        {data?.price}&nbsp;{data?.currency}&nbsp;
                                        <span
                                            className="text-sm text-[#C0C0C0] font-medium">{getLangText('monthly')}</span>
                                        <div>
                                            {+data?.service_fee > 10 ?
                                                <div
                                                    className="inline-block text-left text-sm text-[#C0C0C0] font-medium whitespace-pre-wrap mt-2 relative">
                                                    +{data?.service_fee} zł {getLangText('serviceFee')}
                                                </div> :
                                                <div
                                                    className="inline-block text-left text-sm text-[#C0C0C0] font-medium whitespace-pre-wrap mt-2 relative">
                                                    +{data?.service_fee}% {getLangText('serviceFeeFromTransaction')}
                                                    <img className="absolute ml-2 left-[100%] w-[20px] bottom-[-10px]"
                                                         src="/assets/images/svg/arrow.svg" alt=""/>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    {+data?.service_fee < 10 &&
                                        <div className="-mx-3">
                                            <button
                                                type="button"
                                                onClick={() => setShowServiceFeeModal(true)}
                                                className="flex w-full text-left bg-[#28282A] -mb-3 text-sm rounded-[8px] border text-[#C0C0C0] border-[#39393B] p-[12px] mt-4 whitespace-pre-wrap relative hover:bg-[#373737] transition-all"
                                            >
                                                <div className="mr-[6px] -mt-[1px]">
                                                    <i className="icon-info-c text-[#C0C0C0]"/>
                                                </div>
                                                <div>
                                                    <div>
                                                        {getLangText('serviceFeeFromTransactionSubLabel1')}
                                                    </div>
                                                    <div className="text-[#fff]">
                                                        {getLangText('seeHowItWorks')}
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                    }
                                </div>
                                {!!+data?.selmo_pro_qualification ?
                                    <ActivateButton className="mt-5"/> :
                                    <CheckIfYouQualifyButton className="mt-4"/>
                                }
                            </div>
                            <div className="opacity-0 animate-tutorialSlideAndFadeInDelay2">
                                <h2 className="text-2xl sm:text-[32px] text-[white] font-bold mt-16 mb-4">{getLangText('whatDoYouGain')}</h2>
                                <SelmoProBenefits/>
                                {!!+data?.selmo_pro_qualification ?
                                    <ActivateButton className="mt-5"/> :
                                    <CheckIfYouQualifyButton className="mt-5"/>
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
            {showServiceFeeModal &&
                <BlackConfirmModal
                    withModalFooter={false}
                    dialogClassName="sm-size"
                    show={showServiceFeeModal}
                    hide={() => setShowServiceFeeModal(false)}
                    title="whatIsTransactionFeeForClient"
                    subTitle="whatIsTransactionFeeForClientDesc"
                >
                    <img
                        className="rounded-[8px] mb-3"
                        src={`/assets/images/multiLanguage/${lang}/fee.webp`}
                        alt="fee"
                    />
                </BlackConfirmModal>
            }
        </div>
    );
};

export default SelmoPro;
