import React from 'react';
import useForm from "../../../../src_shared/form/useForm";
import FormActions from "./FormActions";
import TopSummary from "./components/TopSummary";
import LiveSale from "./components/liveSale/LiveSale";
import ComponentLoader from "../../shared/components/componentLoader/ComponentLoader";
import SalesPostWrapper from "../SalesPostWrapper";
import SelmoProBoxWrapper from "../../shared/components/SelmoProBox/SelmoProBox";

const SalesPostsStatsForm = () => {
    const {
        data: {values},
        form: {isWaiting},
    } = useForm({
        FormActions,
        requireParentId: false,
        params: {

        }
    });

    return (
        <SalesPostWrapper title="salesStatistics">
            {isWaiting ?
                <ComponentLoader/> :
                <div className="grid sm:grid-cols-5 gap-5">
                    <div className="sm:col-span-3">
                        <TopSummary
                            currency={values.currency}
                            values={values}
                            hasStats={true}
                        />
                        <LiveSale
                            allStats={true}
                            live_views={values?.live_views}
                            currency={values.currency}
                            items={values.last_purchases}
                            hasStats={true}
                        />
                    </div>
                    <div className="sm:col-span-2">
                        <SelmoProBoxWrapper
                            title="detailedStatistics"
                            variant={1}
                        />
                    </div>
                </div>
            }
        </SalesPostWrapper>
    );
};

export default SalesPostsStatsForm;
