import React from 'react';
import Modal from "react-bootstrap/Modal";
import {useDispatch, useSelector} from "react-redux";
import {setConfirmModalToggleVisibility} from "../../LiveDetailsActions";
import Loader from "../../../../../messenger/loader/Loader";
import useLang from "../../../../../../../src_shared/hooks/useLang";

const ConfirmModal = ({
						  onSave,
						  modalType,
						  title,
						  desc,
						  saveButton,
						  saveButtonClass = 'primary',
						  isLoading,
						  dialogClassName = ''
					  }) => {

	const dispatch = useDispatch();
	const {clientsConfirmModal} = useSelector((state) => state.lives.details)
	const {getLangText} = useLang();

	return (
		<Modal
			dialogClassName={`default-modal ${dialogClassName}`}
			show={clientsConfirmModal.modalType === modalType}
			onHide={() => dispatch(setConfirmModalToggleVisibility(null))}
		>
			<Modal.Header>
				<Modal.Title>{title}</Modal.Title>
				<button
					onClick={() => dispatch(setConfirmModalToggleVisibility(null))}
					type="button"
					className="button"
				>
					<i className="icon-cross"/>
				</button>
			</Modal.Header>
			<Modal.Body className="pt-0">
				{desc}
			</Modal.Body>
			<Modal.Footer>
				<button
					onClick={() => dispatch(setConfirmModalToggleVisibility(null))}
					type="button"
					className="button border-button"
				>
					{getLangText('cancelButton')}
				</button>
				<button
					onClick={onSave}
					type="button"
					className={`button small-size ${saveButtonClass} ${(isLoading || clientsConfirmModal.isLoading) ? 'loading-button' : ''}`}
				>
					{(isLoading || clientsConfirmModal.isLoading) &&
						<Loader/>
					}
					{saveButton}
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default ConfirmModal;
