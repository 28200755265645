import React from 'react';

const SelmoPaczkaTerms = () => {
    return (
        <div
            className={`bg-[#eff2f6] sm:py-0 py-6 sm:px-0 px-6 sm:min-h-auto min-h-full sm:h-[100vh] w-[100vw] flex sm:items-center sm:justify-center`}>
            <div
                className={`w-full p-12 my-auto rounded-[12px] shadow-default bg-white md:w-[800px] min-h-[450px] md:min-h-[600px] animate-tutorialSlideAndFadeIn`}>

                <div className={`animate-tutorialSlideAndFadeInDelay1 opacity-0 m-auto`}>
                    <img
                        className="mb-4 block w-[100px]"
                        src="/assets/images/svg/selmo-paczka-logo.svg"
                        alt="selmo paczka"
                    />
                    <div className="space-y-5">
                        <div
                            className="border rounded-[8px] p-3 flex items-center">
                            <div>
                                <div className="font-bold">Selmo Paczka</div>
                                <a
                                    href="/assets/pdf/selmo-paczka/20240422-SelmoPaczka-Regulamin_Usługi_PL.pdf"
                                    target="_blank"
                                    className="underline block font-medium text-desc">
                                    20240422 - Selmo Paczka - Regulamin Usługi [PL].pdf
                                    <i className="text-[8px] ml-2 icon-arrows inline-block rotate-[-90deg] text-desc"/>
                                </a>
                            </div>
                        </div>
                        <div>
                            <div className="font-bold mb-2">
                                Lista dostawców
                            </div>
                            <div
                                className="border rounded-[8px] mb-2 p-3 flex items-center">
                                <div>
                                    <div className="font-bold">DPD</div>
                                    <a
                                        href="/assets/pdf/selmo-paczka/20240101-DPD-Regulamin_Świadczenia_Usług_w_Obrocie_Krajowym.pdf"
                                        target="_blank"
                                        className="underline block mb-1 font-medium text-desc">
                                        20240101 - DPD - Regulamin Świadczenia Usług w Obrocie Krajowym.pdf
                                        <i className="text-[8px] ml-2 icon-arrows inline-block rotate-[-90deg] text-desc"/>
                                    </a>
                                    <a
                                        href="/assets/pdf/selmo-paczka/202403-DPD-Szczegółowe_Warunki_Świadczenia_Usług.pdf"
                                        target="_blank"
                                        className="underline block font-medium text-desc">
                                        202403 - DPD - Szczegółowe Warunki Świadczenia Usług.pdf
                                        <i className="text-[8px] ml-2 icon-arrows inline-block rotate-[-90deg] text-desc"/>
                                    </a>
                                </div>
                            </div>
                            <div
                                className="border rounded-[8px] p-3 flex items-center">
                                <div>
                                    <div className="font-bold">Orlen Paczka</div>
                                    <a
                                        href="/assets/pdf/selmo-paczka/20240415-OrlenPaczka-Regulamin_Dla_Klientów_Indywidualnych.pdf"
                                        target="_blank"
                                        className="underline font-medium text-desc">
                                        20240415 - Orlen Paczka - Regulamin Dla Klientów Indywidualnych.pdf
                                        <i className="text-[8px] ml-2 icon-arrows inline-block rotate-[-90deg] text-desc"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SelmoPaczkaTerms;
