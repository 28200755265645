import React from 'react';
import useLang from "../../../../../../src_shared/hooks/useLang";
import LiveSaleItem from "./Item";
import NoDataPlaceholder from "../../../list/components/NoDataPlaceholder";

const LiveSale = ({items = [], currency, live_views, allStats = false, hasStats, setShowNoStatsModal}) => {
    const {getLangText} = useLang();

    return (
        <div className="default-shadow-box summary-box loader-parent mt-3">
            <div className="flex items-center">
                <div>
                    <div className="font-bold">
                        {getLangText('liveSale')}
                    </div>
                    <div className="text-desc text-sm">
                        {getLangText('bellowFindCurrentPurchases')}
                    </div>
                </div>
                {!!+live_views &&
                    <div
                        className="ml-auto px-2 py-1 rounded-sm bg-[rgba(0,0,0,0.8)] text-[#fff] font-bold text-xs flex items-center">
                        <div
                            className="relative animate-showAndHide flex h-[3px] w-[3px] min-w-[3px] mr-0.5">
                            <div className="absolute inline-flex h-full w-full rounded-full bg-[#fff]"/>
                        </div>
                        <i className="icon-clients mr-1"/>
                        {live_views}
                    </div>
                }
            </div>
            {hasStats ?
                <div className="mt-3 space-y-2">
                    {!!items.length ?
                        items.map((item) => (
                            <LiveSaleItem
                                allStats={allStats}
                                key={item.id}
                                item={item}
                                currency={currency}
                            />
                        )) :
                        <div className="text-center py-3">
                            <div className="w-[80px] mx-auto">
                                <img
                                    className="w-full"
                                    src="/assets/images/empty/orders-empty.svg"
                                    alt=""
                                />
                            </div>
                            <div
                                className="font-bold text-sm whitespace-pre-wrap mt-2"
                                dangerouslySetInnerHTML={{__html: getLangText('hereWillBeSoldProducts')}}
                            />
                        </div>
                    }
                </div> :
                <div className="relative mt-3">
                    <img src="/assets/images/svg/placeholders-page-02.svg" alt=""/>
                    <div>
                        <NoDataPlaceholder
                            setShowNoStatsModal={setShowNoStatsModal}/>
                    </div>
                </div>
            }
        </div>
    );
};

export default LiveSale;
