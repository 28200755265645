import React from 'react';
import FunctionalityPurchasePage from "../../app/shared/FunctionalityPurchasePage";
import FunctionalityModal from "../../app/payments/functionalityModal/Form";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {getStateRoot as getFunctionalityModalStateRoot} from "../../app/payments/functionalityModal/formReducer";
import FunctionalityModalActions from "../../app/payments/functionalityModal/FormActions";
import {ORDER_NOTIFICATIONS_PERMISSION} from "../../app/shared/enums/SelmoPermissions";
import {checkRoles} from "../../routes/PermissionRoute";
import useForm from "../../../src_shared/form/useForm";
import FormActions from "./FormActions";
import TextArea from "../../../src_shared/components/form/textarea/TextArea";
import SwitchField from "../../../src_shared/components/form/switchField/SwitchField";
import useLang from "../../../src_shared/hooks/useLang";
import CharactersCounter from "../../../src_shared/components/ui/CharactersCounter";
import Loader from "../../../src_shared/components/loader/Loader";
import {Helmet} from "react-helmet";
import {convertToUserTimeZone} from "../../app/shared/helpers/dateHelpers";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../routes/RouterPath";

const AutomaticallyNotifications = () => {
    const dispatch = useDispatch();
    const {getLangText} = useLang();
    const {permissions, id} = useSelector((state) => state.session.userData);
    const {waitingForResponse} = useSelector((state) => state.session);
    const {modalVisible} = useSelector(createSelector(getFunctionalityModalStateRoot, (stateRoot) => stateRoot));
    const permissionsMatched = checkRoles(permissions, [ORDER_NOTIFICATIONS_PERMISSION]);

    const {
        data: {values, setValue},
        form: {isWaiting, onSave, getFieldProps},
    } = useForm({
        FormActions,
        params: {},
        requireParentId: false,
    });

    const showModal = () => dispatch(FunctionalityModalActions.modal.showModal());

    return (
        <>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <Link to={RouterPaths.Settings}>{getLangText('asideSettingsLabel')}</Link>
                    </li>
                    <li className="active">
                        <div>{getLangText('automaticallyNotificationsLabel')}</div>
                    </li>
                </ul>
            </div>
            <div className="row small-padd-row">
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>{getLangText('automaticallyNotificationsLabel')} - Selmo</title>
                </Helmet>
                <div className="col-md-7">
                    <div className="loader-parent">
                        <Loader isLoading={waitingForResponse || isWaiting}/>
                        {permissionsMatched ?
                            <div className="settings-page sub-settings-page">
                                <div className="header-wrapper">
                                    <h1>
                                        <img src="/assets/images/svg/codes.svg"
                                             alt="overview"/>
                                        {getLangText('automaticallyNotificationsLabel')}
                                    </h1>
                                    <div className="description">
                                        {getLangText('automaticallyNotificationsSubLabel')}
                                    </div>
                                </div>
                                <div className="settings-form">
                                    <section className="d-block">
                                        <div className="mb-3 border-switch-content">
                                            <SwitchField
                                                {...getFieldProps('first_message_active')}
                                                label={getLangText('messageAfterOneDay')}
                                                setValue={setValue}
                                                className="switch-form inline-label big-label light-version d-flex mb-0"
                                            />
                                            {!!+values.first_message_active &&
                                                <div className="characters-counter-parent">
                                                    <TextArea
                                                        {...getFieldProps('first_message_text')}
                                                        showLabel={false}
                                                        setValue={setValue}
                                                        disabled={!+values.first_message_active}
                                                        rows={5}
                                                        className="mt-3"
                                                        extraProps={{
                                                            maxLength: 640,
                                                        }}
                                                    />
                                                    <CharactersCounter value={values.first_message_text} max={640}/>
                                                </div>
                                            }
                                        </div>
                                        <div className="mb-3 border-switch-content">
                                            <SwitchField
                                                {...getFieldProps('second_message_active')}
                                                label={getLangText({
                                                    key: 'messageAfterFewDays',
                                                    data: [2]
                                                })}
                                                setValue={setValue}
                                                className="switch-form inline-label big-label light-version d-flex mb-0"
                                            />
                                            {!!+values.second_message_active &&
                                                <div className="characters-counter-parent">
                                                    <TextArea
                                                        {...getFieldProps('second_message_text')}
                                                        showLabel={false}
                                                        setValue={setValue}
                                                        rows={5}
                                                        className="mt-3"
                                                        extraProps={{
                                                            maxLength: 640,
                                                        }}
                                                    />
                                                    <CharactersCounter value={values.second_message_text} max={640}/>
                                                </div>
                                            }
                                        </div>
                                        <div className="mb-3 border-switch-content">
                                            <SwitchField
                                                {...getFieldProps('third_message_active')}
                                                label={getLangText({
                                                    key: 'messageAfterFewDays',
                                                    data: [3]
                                                })}
                                                setValue={setValue}
                                                className="switch-form inline-label big-label light-version d-flex mb-0"
                                            />
                                            {!!+values.third_message_active &&
                                                <div className="characters-counter-parent">
                                                    <TextArea
                                                        {...getFieldProps('third_message_text')}
                                                        showLabel={false}
                                                        setValue={setValue}
                                                        rows={5}
                                                        className="mt-3"
                                                        extraProps={{
                                                            maxLength: 640,
                                                        }}
                                                    />
                                                    <CharactersCounter value={values.third_message_text} max={640}/>
                                                </div>
                                            }
                                        </div>
                                        <div className="mb-3 border-switch-content">
                                            <SwitchField
                                                {...getFieldProps('fourth_message_active')}
                                                label={getLangText({
                                                    key: 'messageAfterFewDays',
                                                    data: [4]
                                                })}
                                                setValue={setValue}
                                                className="switch-form inline-label big-label light-version d-flex mb-0"
                                            />
                                            {!!+values.fourth_message_active &&
                                                <div className="characters-counter-parent">
                                                    <TextArea
                                                        {...getFieldProps('fourth_message_text')}
                                                        showLabel={false}
                                                        setValue={setValue}
                                                        rows={5}
                                                        className="mt-3"
                                                        extraProps={{
                                                            maxLength: 640,
                                                        }}
                                                    />
                                                    <CharactersCounter value={values.fourth_message_text} max={640}/>
                                                </div>
                                            }
                                        </div>
                                        <div className="mb-3 border-switch-content">
                                            <SwitchField
                                                {...getFieldProps('fifth_message_active')}
                                                label={getLangText({
                                                    key: 'messageAfterFewDays',
                                                    data: [5]
                                                })}
                                                setValue={setValue}
                                                className="switch-form inline-label big-label light-version d-flex mb-0"
                                            />
                                            {!!+values.fifth_message_active &&
                                                <div className="characters-counter-parent">
                                                    <TextArea
                                                        {...getFieldProps('fifth_message_text')}
                                                        showLabel={false}
                                                        setValue={setValue}
                                                        rows={5}
                                                        className="mt-3"
                                                        extraProps={{
                                                            maxLength: 640,
                                                        }}
                                                    />
                                                    <CharactersCounter value={values.fifth_message_text} max={640}/>
                                                </div>
                                            }
                                        </div>
                                        <div className="button-place ml-3">
                                            <button
                                                onClick={onSave}
                                                className="button primary"
                                                type="button"
                                            >
                                                {getLangText('saveButton')}
                                            </button>
                                        </div>
                                    </section>
                                </div>
                            </div> :
                            <FunctionalityPurchasePage
                                imgSrc="/assets/images/svg/codes.svg"
                                title={getLangText('automaticallyNotificationsLabel')}
                                onClick={showModal}
                            />
                        }
                        {modalVisible &&
                            <FunctionalityModal
                                title={getLangText('automaticallyNotificationsLabel')}
                                desc={getLangText('automaticallyNotificationsSubLabel')}
                                functionalityName={ORDER_NOTIFICATIONS_PERMISSION}
                            />
                        }
                    </div>
                </div>
                <div className="col-md-5 mt-md-0 mt-3">
                    <div className="default-shadow-box bigger-y-padd payment-benefits-wrapper">
                        <div className="label">{getLangText('paymentsHowItWorksLabel')}</div>
                        <ul className="benefits-list">
                            <li>
                                <i className="icon-tick-c"/>
                                {getLangText('automaticallyNotificationsBenefitsLabel1')}
                            </li>
                            <li>
                                <i className="icon-tick-c"/>
                                {getLangText('automaticallyNotificationsBenefitsLabel2')}
                            </li>
                            <li>
                                <i className="icon-tick-c"/>
                                {getLangText('automaticallyNotificationsBenefitsLabel3')}
                            </li>
                        </ul>
                        {(permissionsMatched && values.order_notifications_date) &&
                            <div className="mt-3">
                                <h2 className="font-bold text-[16px] mb-2">
                                    {getLangText('subscriptionStatusLabel')}
                                </h2>
                                <div className="active-plan-date d-flex align-items-center">
                                    <div
                                        className="date px-3 py-2.5 rounded-md bg-gray-100 font-medium text-gray-600 text-sm">{getLangText('paymentsActivePlanToLabel')}
                                        <strong
                                            className="ml-1">{convertToUserTimeZone(values.order_notifications_date).format('DD.MM.YYYY')}</strong>
                                    </div>
                                    <button
                                        style={{paddingTop: 0, paddingBottom: 0}}
                                        type="button"
                                        onClick={showModal}
                                        className="button primary small-size ml-2"
                                    >
                                        {getLangText('extendLabel')}
                                    </button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default AutomaticallyNotifications;
