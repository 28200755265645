export const initState = {
    validationActive: false,
};

export const reduceMap = {
    VALIDATION_ACTIVATE: (state) => ({...state, validationActive: true}),
    VALIDATION_DEACTIVATE: (state) => ({...state, validationActive: false})
};

export default {
    initState,
    reduceMap,
};
