import React from 'react';
import useLang from "../../../../src_shared/hooks/useLang";
import {useSelector} from "react-redux";
import SwitchField from "../../../../src_shared/components/form/switchField/SwitchField";
import useFormLocal from "../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../RestServices";
import Loader from "../../../app/shared/Loader";
import SettingsFacebookModel from "../../../../modules/models/settings/SettingsFacebookModel";
import ComponentLoader from "../../../app/shared/components/componentLoader/ComponentLoader";

const Connected = () => {
    const {getLangText} = useLang();
    const {userData} = useSelector((state) => state.session);

    const [{
        onSave,
        isWaiting,
        getFieldProps,
        isLoading,
    }] = useFormLocal({
        rest: services.API_SETTINGS_FACEBOOK,
        model: SettingsFacebookModel,
    });

    if (isLoading) return <ComponentLoader/>;

    return (
        <div className="settings-page sub-settings-page mb-sm-4"
             style={{borderTopLeftRadius: 0, borderTopRightRadius: 0}}>
            <div className="settings-form">
                <section>
                    <div className="left-side">
                        <h2 className="label">Facebook</h2>
                    </div>
                    <div className="form-col">
                        <div className="shop-info-wrapper">
                            <figure>
                                <img
                                    src={`https://graph.facebook.com/${userData.facebook_id}/picture?height=100&width=100`}
                                    alt="{userData.facebook_name}"/>
                            </figure>
                            <div className="content">
                                <div>
                                    <div className="name">{userData.facebook_name}</div>
                                    <div className="grey-txt">
                                        <i className="icon-tick-c"></i>
                                        {getLangText('settings_facebook_text_10')}
                                        <strong>{userData.facebook_date}</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="left-side"/>
                    <div className="form-col">
                        <div>
                            <SwitchField
                                {...getFieldProps('notification_status')}
                                label={getLangText('settings_facebook_text_11')}
                                subLabel={`${getLangText('settings_general_counting_on_description')}"${userData.product_code} ${getLangText('settings_facebook_text_13')}`}
                                className="switch-form inline-label align-items-start big-label light-version mb-0 mt-0"
                            />
                            <div
                                style={{position: 'relative', zIndex: 9}}
                                className="form-info-box  mt-2 mb-4 ml-5 align-items-baseline">
                                <div>
                                    <i className="icon-info-c"/>
                                </div>
                                <div>
                                    <div className="title">{getLangText('settings_facebook_text_16')}</div>
                                </div>
                            </div>
                            <SwitchField
                                {...getFieldProps('import_status')}
                                label={getLangText('settings_facebook_text_17')}
                                subLabel={getLangText('settings_facebook_text_18')}
                                className="switch-form inline-label align-items-start big-label light-version"
                            />
                            {true ?
                                <SwitchField
                                    {...getFieldProps('feed_status')}
                                    label={getLangText('settings_facebook_text_19')}
                                    subLabel={getLangText('settings_facebook_text_20')}
                                    className="switch-form inline-label align-items-start big-label light-version"
                                /> :
                                <div className="switcher-text-inline-wrapper">
                                    <div>
                                        <div>
                                            <div className="title">
                                                {getLangText('settings_facebook_text_19')}
                                            </div>
                                            <div className="desc">
                                                {getLangText('settings_facebook_text_20')}
                                                {userData.product_code}{' '}{getLangText('settings_facebook_text_21')}
                                            </div>
                                            <a href="{base_url()}facebook_accounts/edit" class="button primary mt-3">
                                                {getLangText('settings_facebook_text_22')}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            }
                            <SwitchField
                                {...getFieldProps('buy_message_status')}
                                label={getLangText('settings_facebook_text_23')}
                                subLabel={getLangText('settings_facebook_text_24')}
                                className="switch-form inline-label align-items-start big-label light-version"
                            />
                        </div>
                        <div className="button-place mt-4">
                            <button
                                type="button"
                                onClick={onSave}
                                className={`button primary ${isWaiting ? 'loading-button' : ''}`}
                            >
                                <Loader isLoading={isWaiting}/>
                                {getLangText('saveButton')}
                            </button>
                        </div>
                    </div>
                </section>
            </div>
            {/*    <div className="switcher-text-inline-wrapper">*/}
            {/*        <div>*/}
            {/*            <div className="form-group switch-form">*/}
            {/*                <input type="checkbox" className="import-status-checkbox"*/}
            {/*                    {if userData.import_status == 1} checked="checked"{/if}>*/}
            {/*                    <label className="switch">*/}
            {/*                    <span className="slider"></span>*/}
            {/*                    </label>*/}
            {/*                    </div>*/}
            {/*                    <div>*/}
            {/*                    <div className="title">*/}
            {/*                {getLangText('settings_facebook_text_17')}*/}
            {/*            </div>*/}
            {/*            <div className="desc">*/}
            {/*                {getLangText('settings_facebook_text_18')}*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*    {if $hasPermission}*/}
            {/*    <div className="switcher-text-inline-wrapper">*/}
            {/*        <div>*/}
            {/*            <div className="form-group switch-form">*/}
            {/*                <input type="checkbox" className="post-status-checkbox"*/}
            {/*                    {if userData.feed_status == 1} checked="checked"{/if}>*/}
            {/*                    <label className="switch">*/}
            {/*                    <span className="slider"></span>*/}
            {/*                    </label>*/}
            {/*                    </div>*/}
            {/*                    <div>*/}
            {/*                    <div className="title">*/}
            {/*                {getLangText('settings_facebook_text_19')}*/}
            {/*            </div>*/}
            {/*            <div className="desc">*/}
            {/*                {getLangText('settings_facebook_text_20')}*/}
            {/*                {if $productCode !== null}"{$productCode}"{else}"Selmo"{/if} {getLangText('settings_facebook_text_21')}*/}
            {/*                </div>*/}
            {/*                </div>*/}
            {/*                </div>*/}
            {/*                </div>*/}
            {/*            {else}*/}
            {/*                <div className="switcher-text-inline-wrapper">*/}
            {/*                <div>*/}
            {/*                <div>*/}
            {/*                <div className="title">*/}
            {/*            {getLangText('settings_facebook_text_19')}*/}
            {/*            </div>*/}
            {/*            <div className="desc">*/}
            {/*                {getLangText('settings_facebook_text_20')}*/}
            {/*                {if $productCode !== null}"{$productCode}"{else}"Selmo"{/if} {getLangText('settings_facebook_text_21')}*/}
            {/*                </div>*/}
            {/*                <a href="{base_url()}facebook_accounts/edit" className="button primary mt-3">*/}
            {/*            {getLangText('settings_facebook_text_22')}*/}
            {/*            </a>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*    {/if}*/}
            {/*        <div className="switcher-text-inline-wrapper">*/}
            {/*            <div>*/}
            {/*                <div className="form-group switch-form">*/}
            {/*                    <input type="checkbox" className="messenger-status-checkbox"*/}
            {/*                        {if userData.buy_message_status == 1} checked="checked"{/if}>*/}
            {/*                        <label className="switch">*/}
            {/*                        <span className="slider"></span>*/}
            {/*                        </label>*/}
            {/*                        </div>*/}
            {/*                        <div>*/}
            {/*                        <div className="title">*/}
            {/*                    {getLangText('settings_facebook_text_23')}*/}
            {/*                </div>*/}
            {/*                <div className="desc">*/}
            {/*                    {getLangText('settings_facebook_text_24')} {if $productCode !== null}"{$productCode}"{else}"Selmo"{/if} {getLangText('settings_facebook_text_25')}*/}
            {/*                    </div>*/}
            {/*                    </div>*/}
            {/*                    </div>*/}
            {/*                    </div>*/}
            {/*                    </div>*/}
            {/*                    </section>*/}

        </div>

    );
};

export default Connected;
