import {combineReducers} from "redux";
import listReducer from './list/listReducer'
import formReducer from "./form/formReducer";
import formStatsReducer from "./form/stats/formReducer";
import filtersReducer from "./filters/filtersReducer";
import statsReducer from "./stats/formReducer";

export default combineReducers({
	list: listReducer,
	filters: filtersReducer,
	stats: statsReducer,
	formStats: formStatsReducer,
	form: formReducer,
});
