import React, {useEffect, useState} from 'react';
import Field from "../../../../../../../src_shared/components/form/field/Field";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import FiltersActions from "../../../../filters/FiltersActions";
import ListActions from "../../../ListActions";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {getStateRoot as getFiltersPanelStateRoot} from "../formReducer";
import useWindowDimensions from "../../../../../messenger/hooks/useWindowDimensions/useWindowDimensions";
import OrderListFiltersPanelActions from "../../form/FormActions";

const PriceFromToFilter = ({getFieldProps, setValue, getFiltersStateRoot}) => {
    const {getLangText} = useLang();
    const [visible, setVisible] = useState(false);
    const dispatch = useDispatch();
    const {width} = useWindowDimensions();

    const [fromPrice, toPrice, fromPriceLocally, toPriceLocally] = useSelector(createSelector(getFiltersStateRoot, getFiltersPanelStateRoot, (stateRoot, filtersPanelStateRoot) => [
        stateRoot.data.priceFrom,
        stateRoot.data.priceTo,
        filtersPanelStateRoot.data.priceFrom,
        filtersPanelStateRoot.data.priceTo,
    ]));

    const FiltersCount = () => {
        if (fromPrice || toPrice)
            return (
                <div className="badge">
                    <span>1</span>
                </div>
            )
        return null;
    }

    const saveFilters = () => {
        dispatch(FiltersActions.data.updateValues({
            priceFrom: fromPriceLocally,
            priceTo: toPriceLocally,

        }))
        dispatch(ListActions.loadPage(1));
        if (width < 767) {
            dispatch(OrderListFiltersPanelActions.modal.hideModal());
        }
    };

    const clearFilter = () => {
        dispatch(OrderListFiltersPanelActions.data.updateValues({
            priceFrom: '',
            priceTo: '',
        }))
    };


    useEffect(() => {
        if (fromPrice || toPrice) {
            dispatch(OrderListFiltersPanelActions.data.updateValues({
                priceFrom: fromPrice,
                priceTo: toPrice,

            }))
            setVisible(true);
        }
    }, [])

    return (
        <div className="toggle-filter-wrapper">
            <button
                type="button"
                className={`toggle-filter-button ${visible ? 'open' : ''}`}
                onClick={() => setVisible(!visible)}
            >
                {getLangText('orderPrice')}
                <FiltersCount/>
                <i className="icon-arrows"/>
            </button>
            {visible &&
                <>
                    <div className="toggle-filter-body">
                        <div className="grid grid-cols-2 gap-3">
                            <Field
                                {...getFieldProps('priceFrom')}
                                label={getLangText('fromLabel')}
                                setValue={setValue}
                                type="number"
                                extraProps={{
                                    autoFocus: true
                                }}
                            />
                            <Field
                                {...getFieldProps('priceTo')}
                                label={getLangText('toLabel')}
                                type="number"
                                setValue={setValue}
                            />
                        </div>
                    </div>
                    <div className="toggle-filter-footer">
                        <button
                            className="clear-button button text-only"
                            onClick={clearFilter}
                        >
                            <span>{getLangText('clearLabel')}</span>
                        </button>
                        <button
                            className="button primary"
                            type="button"
                            onClick={saveFilters}
                        >
                            {getLangText('filterButton')}
                        </button>
                    </div>
                </>
            }
        </div>
    );
};

export default PriceFromToFilter;
