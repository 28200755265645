import * as React from "react"
const SelmoCircle = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        viewBox="0 0 24 24"
        {...props}
    >
        <g data-name="Selmo-\u2013-posts-|-adding-05-\u2013-desktop">
            <path
                d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0Z"
                style={{
                    strokeWidth: 0,
                }}
            />
            <path
                d="M16.456 13.321a1.42 1.42 0 0 0-.103-.427c-.008-.02-.011-.042-.02-.06-.009-.018-.025-.042-.036-.061a1.25 1.25 0 0 0-.081-.136 1.097 1.097 0 0 0-.081-.112 1.426 1.426 0 0 0-.442-.373c-.02-.011-.037-.026-.058-.036l-3.265-1.581 3.271-1.582c.466-.225.78-.69.824-1.22s-.188-1.043-.61-1.348a1.401 1.401 0 0 0-1.434-.128L8.362 9.186c-.022.011-.04.027-.062.038a1.488 1.488 0 0 0-.342.259 1.284 1.284 0 0 0-.097.111c-.028.035-.054.072-.081.11a1.7 1.7 0 0 0-.081.137c-.011.021-.025.039-.036.061s-.013.042-.02.06a1.666 1.666 0 0 0-.084.283 1.315 1.315 0 0 0-.019.144c-.004.048-.008.095-.008.143s.004.095.008.143a1.364 1.364 0 0 0 .103.426c.008.02.012.042.021.061.015.032.033.06.049.09.012.022.023.044.036.065.044.074.094.144.15.209l.004.004c.056.063.118.121.184.173l.053.04c.062.045.126.085.194.12.009.004.016.011.025.015l3.27 1.581-3.27 1.581a1.49 1.49 0 0 0-.824 1.222 1.51 1.51 0 0 0 .611 1.349c.422.304.969.353 1.436.127l6.057-2.93c.009-.004.017-.011.025-.015a1.472 1.472 0 0 0 .429-.332l.006-.007c.055-.065.105-.135.149-.209.013-.022.025-.046.037-.069.016-.03.034-.057.048-.088.009-.02.012-.042.02-.06a1.61 1.61 0 0 0 .103-.427c.004-.048.008-.095.008-.143s-.004-.093-.008-.141Z"
                style={{
                    fill: '#fff',
                    strokeWidth: 0,
                }}
            />
        </g>
    </svg>
)
export default SelmoCircle
