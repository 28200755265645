import React from 'react';
import {Link, useParams} from "react-router-dom";
import useRemoveData from "../../../../src_shared/hooks/useRemoveData";
import {services} from "../../../RestServices";
import {RouterPaths} from "../../../routes/RouterPath";
import useFormLocal from "../../../../src_shared/hooks/useFormLocal";
import useLang from "../../../../src_shared/hooks/useLang";
import ComponentLoader from "../../../app/shared/components/componentLoader/ComponentLoader";
import VoiceMessagesForm from "../components/VoiceMessagesForm";
import ConfirmModal from "../../../app/live/details/default/summary/export/ConfirmModal";
import SettingsVoiceMessagesModel from "../../../../modules/models/settings/SettingsVoiceMessagesModel";
import {Helmet} from "react-helmet";

const VoiceMessagesFormPage = () => {
	const {getLangText} = useLang();
	const {id} = useParams();

	const rest = services.API_SETTINGS_VOICE_MESSAGES

	const [{
		onSave,
		isWaiting,
		getFieldProps,
		isLoading,
		values,
		updateValues,
	}] = useFormLocal({
		rest: `${rest}/${id}`,
		method: 'put',
		model: SettingsVoiceMessagesModel,
	});

	const [{
		openModal: openRemoveModal,
		hideModal: hideRemoveModal,
		confirmModalVisible,
		itemToRemove,
		isDeleting,
		removeItem,
	}] = useRemoveData({
		rest,
		redirectRoute: RouterPaths.SettingsVoiceMessage
	});

	if (isLoading) return <ComponentLoader/>;

	return (
		<>
			<Helmet>
				<meta charSet="utf-8"/>
				<title>{getLangText('settings_nav_audio_messages')} - Selmo</title>
			</Helmet>
			<div className="header-bar breadcrumbs-in-react-app">
				<ul className="breadcrumbs">
					<li>
						<Link to={RouterPaths.Settings}>
							{getLangText('asideSettingsLabel')}
						</Link>
					</li>
					<li>
						<Link to={RouterPaths.SettingsShop}>{getLangText('settings_nav_shop_settings')}</Link>
					</li>
					<li>
						<Link to={RouterPaths.SettingsVoiceMessage}>
							{getLangText('settings_nav_audio_messages')}
						</Link>
					</li>
					<li className="active">
						<div>{getLangText('editionLabel')}</div>
					</li>
				</ul>
				<Link
					to={RouterPaths.SettingsVoiceMessage}
					className="button back-to-panels mb-3">
					<i className="icon-arrow-left"/>
					{getLangText('button_back_to_audio_messages')}
				</Link>
			</div>
			<div className="settings-page sub-settings-page export-sub-settings">
				<div className="header-wrapper no-border pb-0">
					<h1>
						{getLangText('settings_nav_audio_messages')}
					</h1>
				</div>
				<div className="settings-form pt-3">
					<VoiceMessagesForm
						getFieldProps={getFieldProps}
						isWaiting={isWaiting}
						onSave={onSave}
						values={values}
						updateValues={updateValues}
						restService={services.API_SETTINGS_VOICE_MEDIA}
					/>
					<div className="remove-delivery-section">
						<button
							type="button"
							onClick={() => openRemoveModal(values)}
							className="button border-button left-icon big-size"
						>
							<i className="icon-bin"/>
							{getLangText('button_delete_audio_message')}
						</button>
					</div>
				</div>
			</div>
			{confirmModalVisible &&
				<ConfirmModal
					dialogClassName="w-380"
					title={getLangText('delete_modal_accept')}
					SubTitle={() => <span dangerouslySetInnerHTML={{
						__html: getLangText({
							key: 'wantToRemoveLabel',
							data: [itemToRemove.name]
						})
					}}/>}
					saveButton={getLangText('removeButton')}
					show={confirmModalVisible}
					isWaiting={isDeleting}
					onSubmit={removeItem}
					hide={hideRemoveModal}
					saveButtonClass="danger"
				/>
			}
		</>
	);
};

export default VoiceMessagesFormPage;
