import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import {RouterPaths} from "./RouterPath";
import ShelvesList from "../app/storage/shelves/list/List";
import Scanner from "../app/storage/scanner/Scanner";
import ProductCard from "../app/storage/scanner/card/product/ProductCard";
import OrderCard from "../app/storage/scanner/card/order/OrderCard";
import Shelf from "../app/storage/shelf/Shelf";
import SelmoProRoute from "./SelmoProRoute";
import {SELMO_APP_PERMISSION} from "../app/shared/enums/SelmoPermissions";
import PermissionRoute from "./PermissionRoute";

const StorageRoute = ({path}) => {

    return (
        <PermissionRoute
            path={path}
            allowedPermissions={[SELMO_APP_PERMISSION]}
        >
            <Switch>
                <SelmoProRoute
                    exact
                    allowField="depot_system"
                    path={RouterPaths.StorageScannerOrderCard}
                    component={OrderCard}
                />
                <SelmoProRoute
                    exact
                    allowField="depot_system"
                    path={[RouterPaths.StorageScannerProductCard, RouterPaths.StorageScannerProductVariantCard]}
                    component={ProductCard}
                />
                <SelmoProRoute
                    exact
                    allowField="depot_system"
                    path={RouterPaths.StorageScanner}
                    component={Scanner}
                />
                <SelmoProRoute
                    exact
                    allowField="depot_system"
                    path={RouterPaths.StorageSingle}
                    component={Shelf}
                />
                <Route
                    exact
                    path={RouterPaths.Storage}
                    component={ShelvesList}
                />
                <Redirect to={RouterPaths.Storage}/>
            </Switch>
        </PermissionRoute>
    );
};

export default StorageRoute;
