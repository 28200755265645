import {
	FAKTUROWNIA_PERMISSION,
	ORDER_NOTIFICATIONS_PERMISSION,
	PRODUCT_CODE_PERMISSION,
	SHOP_PERMISSION
} from "./SelmoPermissions";

export const FUNCTIONALITY_BENEFITS = [
	{
		id: FAKTUROWNIA_PERMISSION, title: 'fakturowniaIntegrationLabel', benefits: ['functionalitiesBenefitsFakturownia1', 'functionalitiesBenefitsFakturownia2', 'functionalitiesBenefitsFakturownia3', 'functionalitiesBenefitsFakturownia4'], media: 'fakturownia.svg',
	},
	{
		id: PRODUCT_CODE_PERMISSION, title: 'productsCodeLabel', benefits: ['functionalitiesBenefitsCode1', 'functionalitiesBenefitsCode2'], media: `own_codes.webp`
	},
	{
		id: SHOP_PERMISSION, title: 'productSourceShopLabel', benefits: ['shopSubscriptionBenefitsLabel1', 'shopSubscriptionBenefitsLabel2', 'shopSubscriptionBenefitsLabel3', 'shopSubscriptionBenefitsLabel4', 'shopSubscriptionBenefitsLabel5', 'shopSubscriptionBenefitsLabel6', 'shopSubscriptionBenefitsLabel7']
	},
	{
		id: ORDER_NOTIFICATIONS_PERMISSION, title: 'automaticallyNotificationsShortLabel', benefits: ['automaticallyNotificationsBenefitsLabel1', 'automaticallyNotificationsBenefitsLabel2', 'automaticallyNotificationsBenefitsLabel3']
	},
]
