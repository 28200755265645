import Model from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

class ForgetPasswordModel extends Model {
	getModel() {
		return {
			newPassword: '',
			repeatPassword: '',
		};
	}

	getValidators() {
		return {
			newPassword: ValidationError.notEmpty,
			repeatPassword: ValidationError.validateRepeatPassword
		};
	}
}

export default new ForgetPasswordModel();
