import { useCallback, useEffect, useState } from 'react';

const useDropdown = (buttonRef, dropdownRef, callBack) => {
    const [open, setOpen] = useState(false);
    const handleClickOutside = useCallback((e) => {
        if (open
            && buttonRef
            && dropdownRef
            && buttonRef.current !== e.target
            && dropdownRef.current !== e.target
            && !buttonRef.current?.contains(e.target)
            && !dropdownRef.current?.contains(e.target)) {
            callBack();
        }
    }, [open]);

    useEffect(() => {
        document.body.addEventListener('click', handleClickOutside);
        return () => {
            document.body.removeEventListener('click', handleClickOutside);
        };
    }, [handleClickOutside]);

    return {
        open,
        setOpen,
    };
};

export default useDropdown;
