import React, {useState} from 'react';
import TemplatesMessages from "./TemplatesMessages";
import useFetchData from "../../../../../../../src_shared/hooks/useFetchData";
import EditTemplatesModal from "../EditTemplatesModal";
import {services} from "../../../../../../RestServices";
import ComponentLoader from "../../../../../shared/components/componentLoader/ComponentLoader";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import SearchInput from "../../../../../shared/components/form/SearchInput";

const TemplatesDropdown = ({hide, setInputValue, textAreaRef}) => {
    const [showModal, setShowModal] = useState(null);
    const {getLangText} = useLang();

    const [{isLoading, data, fetchData, search}] = useFetchData({
        rest: services.API_MESSENGER_TEMPLATE,
        initialData: [],
    })

    return (
        <div
            className="fixed sm:absolute bottom-[50px] sm:bottom-[30px] sm:left-auto left-0 right-0 bg-[white] sm:rounded-[10px] border sm:w-auto w-full sm:min-w-[400px] opacity-0 animate-[fadeIn_.5s_ease_forwards]">
            <div className="flex items-center p-3 border-b">
                <div className="font-bold grow text-sm">
                    {getLangText('savedTemplates')}
                </div>
                <button
                    type="button"
                    onClick={() => setShowModal(0)}
                    className="button text-only with-icon lgrey add-order-table"
                >
                    <i className="icon-plus"/>
                    {getLangText('settings_package_types_text_1')}
                </button>
            </div>
            <div className="px-[15px] pt-[15px] pb-[5px]">
                <SearchInput
                    className="mb-0 full-width-input"
                    placeholder={getLangText('searchPlaceholder')}
                    search={search}
                    autoFocus
                />
            </div>
            {isLoading ?
                <ComponentLoader height={200}/> :
                <TemplatesMessages
                    fetchData={fetchData}
                    data={data}
                    setShowModal={setShowModal}
                    setInputValue={setInputValue}
                    hideDropdown={hide}
                    textAreaRef={textAreaRef}
                />
            }
            {showModal !== null &&
                <EditTemplatesModal
                    fetchData={fetchData}
                    show={showModal}
                    hide={() => setShowModal(null)}
                />
            }
        </div>
    );
};

export default TemplatesDropdown;
