import React, {useEffect} from 'react';
import ComponentLoader from "../../shared/components/componentLoader/ComponentLoader";
import useLogout from "../../shared/hooks/useLogout";
import {LottieLoading} from "../../../../App";

const LogoutRoute = () => {

    const {logout} = useLogout();

    useEffect(() => {
        logout();
    }, [])

    return <LottieLoading className="higher-index"/>
};

export default LogoutRoute;