import createReducer from "../../../../shared/helpers/createReducer";
import {PRODUCTS_TYPE_ACTIVE_ALL} from "../../../../shared/enums/ProductsActiveType";
import {parseParams} from "../../../../../../src_shared/list/ListActions";
import {getReduceMap} from "../../../../../../src_shared/form/FormReducer";
import DataReducer from "../../../../../../src_shared/form/data/DataReducer";
import history from "../../../../../history";

const queryParams = history.location.search;
const parsedParams = parseParams(queryParams)

const preparedActiveType = parsedParams.active || PRODUCTS_TYPE_ACTIVE_ALL;
export const getStateRoot = (state) => state.products.categories.products.filters;
export const prefix = 'SUB_CATEGORIES_PRODUCTS_FILTERS_';

export const getInitState = () => DataReducer.getInitState({
	activeType: preparedActiveType,
}, {
	activeType: PRODUCTS_TYPE_ACTIVE_ALL,
})


export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();
