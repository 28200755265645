import {useState} from "react";
import api from "../../services/axios/axios";
import {selmoUrl} from "../api/api";
import moment from "moment";
import {useDispatch} from "react-redux";
import {showAlert} from "../../components/app/shared/components/alert/AlertActions";
import {convertToUserTimeZone} from "../../components/app/shared/helpers/dateHelpers";

export const useDownloadFile = ({
                                    rest,
                                    fileName,
                                    fileType = 'pdf',
                                    afterSubmit = () => {
                                    },
                                    onError = (e) => {
                                    },
                                }) => {
    const [waiting, setWaiting] = useState(false);
    const dispatch = useDispatch();
    const download = async () => {
        setWaiting(true);
        try {
            const {data} = await api.get(`${selmoUrl}/${rest}`, {
                responseType: "blob",
            });
            const blob = new Blob([data], {type: `application/${fileType}`});

            const url = URL.createObjectURL(blob);

            if (fileType !== 'pdf') {
                const a = document.createElement('a');
                a.href = url;
                a.download = `${fileName}-Selmo-${convertToUserTimeZone().format('DD.MM.YYYY')}.${fileType}`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            } else {
                setTimeout(() => {
                    window.open(url, "_blank", 'noopener,noreferrer');
                }, 300)
            }
            afterSubmit();
            dispatch(showAlert('yuhuLabel', 'successfullyExportedFile'))
        } catch (error) {
            onError(error)
            dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
        } finally {
            setWaiting(false);
        }
    };

    return {download, waiting};
};
