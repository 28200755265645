import React from 'react';
import ListActions from "./ListActions";
import {getStateRoot} from "./listReducer";
import useList from "../../../../../src_shared/list/useList";
import Pagination from "../../../shared/components/pagination/Pagination";
import {Link, useParams} from "react-router-dom";
import moment from "moment";
import {findOrderStatusById} from "../../../shared/enums/OrderStatuses";
import EmptyList from "../../../shared/EmptyList";
import {findOrderPaymentStatusById} from "../../../shared/enums/PaymentStatuses";
import {shippingLang} from "../../../orders/list/components/table/TableBody";
import {RouterPaths} from "../../../../routes/RouterPath";
import ProductsModal from "./productsModal/ProductsModal";
import {useUnitPrice} from "../../../shared/helpers/Price";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import useLang from "../../../../../src_shared/hooks/useLang";
import SearchInput from "../../../shared/components/form/SearchInput";
import {convertToUserTimeZone} from "../../../shared/helpers/dateHelpers";

const ClientOrders = () => {
	const params = useParams();
	const {getLangText} = useLang();

	const {
		pagination: {
			changePage,
			getPagination,
			itemsPerPage,
			nextPage,
			prevPage,
			totalItems,
			updateItemsPerPage
		},
		list: {items, isWaiting},
		remove: {askForRemoveItem, isModalVisible, cancelItemRemoval, markedToRemove},
		search,
	} = useList({
		ListActions,
		getStateRoot,
		hasParentId: true,
		params: {
			id: params.id
		}
	});


	const onProductsClick = (e, item) => {
		e.preventDefault()
		askForRemoveItem(item);
	}

	const {getPrice} = useUnitPrice();
	const getOrderPrice = (amountPrice, currency) => getPrice(amountPrice, currency)

	return (
		<div className="table-box-wrapper">
			<div
				style={{borderTopLeftRadius: 0, borderTopRightRadius: 0}}
				className="default-shadow-box bigger-x-padd">
				<div className="filters-part">
					<h1 className="title flex-grow-1">
						{getLangText('asideOrdersLabel')}
						{totalItems > 0 &&
							<div className="badge">{totalItems}</div>
						}
					</h1>
					<SearchInput
						className="ml-auto"
						placeholder={getLangText('searchPlaceholder')}
						search={search}
					/>
				</div>
				<div className="boxes-wrapper">
					<EmptyList
						items={items}
						isLoading={isWaiting}
						className="with-padd with-bg"
						imgSrc="/assets/images/empty/orders-empty.svg"
						title={`<strong>${getLangText('noOrdersLabel')}</strong>`}
					>
						{items.map((item) => (
							<Link
								key={item.id}
								to={`${RouterPaths.OrdersList}/${item.shop_order_id}/`}
								className="box"
							>
								<div className="left-side">
									<div className="title">{getLangText('customerBasketOrderLabel')}&nbsp;{item.shop_order_id}</div>
									<div className="bottom-text">
										<span>{convertToUserTimeZone(item.date).format('DD.MM.YYYY HH:mm')}</span>
										{item.shipping_name &&
											<span>{shippingLang(item, getLangText)}</span>
										}
										{item.payment &&
											<span><div>{getLangText(findOrderPaymentStatusById(item.payment)?.label)}</div></span>
										}
									</div>
								</div>
								<div className="right-side">
									<div className={`status-bg ${findOrderStatusById(item.status)?.color}`}>
										{getLangText(findOrderStatusById(item.status)?.label)}
									</div>
									<div className="price">{getOrderPrice(item.total_price, item.currency_text)}</div>
									<OverlayTrigger
										placement='top'
										overlay={
											<Tooltip
												id="client-products">
												{getLangText('seeProductsLabel')}
											</Tooltip>
										}
									>
									<button
										type="button"
										onClick={(e) => onProductsClick(e, item)}
										className="icon-button grey circle-icon ml-2 position-relative"
									>
										<div className="circle-count">
											<span>{item.products_count}</span>
										</div>
										<i className="icon-products-1"/>
									</button>
									</OverlayTrigger>
								</div>
							</Link>
						))}
					</EmptyList>
				</div>
			</div>
			<Pagination
				getLangText={getLangText}
				pages={getPagination()}
				items={items}
				itemsPerPage={itemsPerPage}
				totalItems={totalItems}
				onPrevPage={prevPage}
				onChangePage={changePage}
				onNextPage={nextPage}
				onChangeItemsPerPage={updateItemsPerPage}
				classes={{
					prevIcon: 'icon-chevron left',
					nextIcon: 'icon-chevron right',
				}}
				showItemsPerPageSelect
			/>
			{isModalVisible &&
				<ProductsModal
					modalVisible={isModalVisible}
					hideModal={cancelItemRemoval}
					item={markedToRemove}
					getPrice={getOrderPrice}
				/>
			}
		</div>
	);
};

export default ClientOrders;
