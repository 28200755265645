import React from 'react';
import ConfirmModal from "../../../../app/orders/list/components/export/ConfirmModal";
import useLang from "../../../../../src_shared/hooks/useLang";
import useFormLocal from "../../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../../RestServices";
import SettingsShopifyModel from "../../../../../modules/models/settings/SettingsShopifyModel";
import {showAlert} from "../../../../app/shared/components/alert/AlertActions";
import Field from "../../../../../src_shared/components/form/field/Field";
import SwitchField from "../../../../../src_shared/components/form/switchField/SwitchField";
import {useDispatch} from "react-redux";

const ConnectModal = ({hide}) => {
    const {getLangText} = useLang();
    const dispatch = useDispatch();

    const [{onSave, isWaiting, getFieldProps, isLoading, values}] = useFormLocal({
        rest: services.API_SETTINGS_SHOPIFY,
        model: SettingsShopifyModel,
        initialAutoLoad: false,
        afterSubmit: (response) => {
            if (response.url) {
                window.location.href = response.url
            } else {
                dispatch(showAlert())
            }
        }
    });

    return (
        <ConfirmModal
            dialogClassName="small-modal w-420"
            saveButton={getLangText('connectWithShopify')}
            show={true}
            isWaiting={isWaiting}
            onSubmit={onSave}
            hide={hide}
            submitButtonClass="w-auto px-2 primary"
        >
            <Field
                {...getFieldProps('shop')}
                disabled={!!+values.is_connected}
                label={getLangText('shopUrl')}
                className="-mt-6"
                placeholder="yourstore.myshopify.com"
            />
            {/*<SwitchField*/}
            {/*    placeholder="yourstore.myshopify.com"*/}
            {/*    {...getFieldProps('active')}*/}
            {/*    label={getLangText('realizeOrdersInMyShop')}*/}
            {/*    className="switch-form inline-label big-label light-version mt-0 mb-0"*/}
            {/*/>*/}
        </ConfirmModal>
    );
};

export default ConnectModal;