import {Redirect, useLocation} from "react-router-dom";
import {getGETParamsUrl, parseParams} from "../list/ListActions";
import React from "react";

const RedirectWithQuery = ({path}) => {
    const {search} = useLocation();
    const query = parseParams(search);

    return <Redirect to={`${path}${getGETParamsUrl(query)}`} />;
}

export default RedirectWithQuery;