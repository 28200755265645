import Composition from "../../../shared/helpers/Composition";
import createReducer from "../../../shared/helpers/createReducer";
import FormReducer from "../../../../../src_shared/form/FormReducer";
import ShippyProModel from "../../../../../modules/models/settings/ShippyProModel";

export const getStateRoot = (state) => state.settings.integrations.shippyPro;
export const prefix = 'SETTINGS_INTEGRATIONS_SHIPPYPRO_FORM';

export const getInitState = () => FormReducer.getInitState(ShippyProModel.getModel(), {
	waiting: true,
})

export const getReduceMap = () => new Composition(
	FormReducer.getReduceMap(),
);

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();
