import React from 'react';
import {Link, NavLink, useLocation} from "react-router-dom";
import {RouterPaths} from "../../../../routes/RouterPath";
import SubMenu from "../SubMenu";
import {checkRoles} from "../../../../routes/PermissionRoute";
import {SHOP_PERMISSION} from "../../enums/SelmoPermissions";
import {useSelector} from "react-redux";
import useLang from "../../../../../src_shared/hooks/useLang";
import {USER_PERMISSIONS_SHOP_MENU_VISIBILITY} from "../../enums/UserPermissionsTypes";
import UserPermissionsWrapper from "../../../../routes/UserPermissionsWrapper";

const ShopMenu = ({closeMobileMenu}) => {
    const {getLangText} = useLang();
    const {permissions} = useSelector((state) => state.session.userData);
    const shopPermissionsMatched = checkRoles(permissions, [SHOP_PERMISSION]);
    const {pathname} = useLocation();

    return (
        <UserPermissionsWrapper allowedPermissions={[USER_PERMISSIONS_SHOP_MENU_VISIBILITY]}>
            {!shopPermissionsMatched ?
                <li>
                    <NavLink
                        exact
                        onClick={closeMobileMenu}
                        activeClassName="active"
                        to={RouterPaths.ShopDesktop}
                    >
                        <i className="icon-cart"/>
                        <span>{getLangText('shopLabel')}</span>
                    </NavLink>
                </li> :
                <SubMenu
                    includeRoute={RouterPaths.Shop}
                    route={RouterPaths.Shop}
                    name={getLangText('shopLabel')}
                    icon="icon-cart"
                >
                    <li>
                        <NavLink
                            exact
                            onClick={closeMobileMenu}
                            activeClassName="active"
                            to={RouterPaths.ShopDesktop}
                        >
                            <span>{getLangText('dashboardLabel')}</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            exact
                            onClick={closeMobileMenu}
                            activeClassName="active"
                            to={RouterPaths.ShopGeneral}
                        >
                            <span>{getLangText('mainSettingsLabel')}</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            exact
                            onClick={closeMobileMenu}
                            activeClassName="active"
                            to={RouterPaths.ShopDomain}
                        >
                            <span>{getLangText('domainLabel')}</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            exact
                            onClick={closeMobileMenu}
                            activeClassName="active"
                            to={RouterPaths.ShopMainPage}
                        >
                            <span>{getLangText('mainPageLabel')}</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            activeClassName="active"
                            onClick={closeMobileMenu}
                            to={RouterPaths.ShopPromotionsMessages}
                        >
                            <span>{getLangText('asidePromotionLabel')}</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            activeClassName="active"
                            onClick={closeMobileMenu}
                            to={RouterPaths.ShopPromotionsNewsletter}
                        >
                            <span>{getLangText('newsletterLabel')}</span>
                        </NavLink>
                    </li>
                    <li>
                        <Link
                            className={(RouterPaths.ShopRegulation === pathname || RouterPaths.ShopPrivacy === pathname) ? 'active' : ''}
                            onClick={closeMobileMenu}
                            to={RouterPaths.ShopRegulation}
                        >
                            <span>{getLangText('termsHelmetTitle')}</span>
                        </Link>
                    </li>
                    <li>
                        <NavLink
                            activeClassName="active"
                            onClick={closeMobileMenu}
                            to={RouterPaths.ShopPages}
                        >
                            <span>{getLangText('asidePagesLabel')}</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            activeClassName="active"
                            onClick={closeMobileMenu}
                            to={RouterPaths.ShopIntegrations}
                        >
                            <span>{getLangText('asideIntegrationLabel')}</span>
                        </NavLink>
                    </li>
                </SubMenu>
            }
        </UserPermissionsWrapper>
    );
};

export default ShopMenu;
