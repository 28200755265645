import React from 'react';
import useLang from "../../../../../../../src_shared/hooks/useLang";
import Variant from "./Variant";
import MoreActions from "./MoreActions";

const Variants = ({values}) => {
    const {getLangText} = useLang();

    return (
        <div>
            <div className="text-right">
                <MoreActions/>
            </div>
            <div className="flex items-center mb-1 text-xs text-desc">
                <div className="grow">
                    {getLangText('nameLabel')}
                </div>
                <div className="">{getLangText('localizationLabel')}</div>
                <div className="w-[36px]"/>
            </div>
            <div className="mt-1 last:border-0">
                {values.sizes_locations?.map((size) => (
                    <Variant values={values} size={size} key={size.id}/>
                ))}
            </div>
        </div>
    );
};

export default Variants;
