import React, {useState} from 'react';

const QuestionsToggle = ({children, title}) => {

	const [active, setActive] = useState(false);

	return (
		<div className={`faq-box ${active ? 'active' : ''}`}>
			<div
				className="faq-box__title"
				onClick={() => setActive(!active)}
			>
				{title}
			</div>
			<div className="faq-box__description">
				{children}
			</div>
		</div>
	);
};

export default QuestionsToggle;
