import React from 'react';
import SelmoProLandingIntroBox from "../components/SelmoProLandingIntroBox";
import SelmoProLandingTwoColumnBox from "../components/SelmoProLandingTwoColumnBox";
import SelmoProLandingBenefitsBox from "../components/SelmoProLandingBenefitsBox";
import SelmoProLandingMoreFunctionsBox from "../components/SelmoProLandingMoreFunctionsBox";

const benefits = [
    {value: '32%', label: 'averageIncreaseInSales',},
    {value: '288h', label: 'timeSavedOnManualActivities'},
]

const DepotSystemLanding = () => {
    return (
        <div>
            <SelmoProLandingIntroBox
                imgSrc="/assets/images/multiLanguage/pl/selmoProLanding/warehouse-big.webp"
                title="manageStorageByQrCodes"
                imgClassName="sm:-mb-12 -mb-6 sm:mt-0 mt-10 sm:h-[416px]"
            />
            <div className="sm:mt-[80px] mt-[50px]">
                <SelmoProLandingTwoColumnBox
                    label="storageLabel"
                    title="youControlOrderByQrCodes"
                    desc="youControlOrderByQrCodesSubLabel"
                    imgSrc="/assets/images/multiLanguage/pl/selmoProLanding/warehouse-big-02.webp"
                />
            </div>
            <SelmoProLandingTwoColumnBox
                label="storageLabel"
                title="avoidMistakes"
                desc="avoidMistakesSubLabel"
                rightText
                imgSrc="/assets/images/multiLanguage/pl/selmoProLanding/warehouse-big-03.webp"
            />
            <SelmoProLandingTwoColumnBox
                label="storageLabel"
                title="youKnowWhatActivitiesMadeYourTeam"
                desc="youKnowWhatActivitiesMadeYourTeamSubLabel"
                imgSrc="/assets/images/multiLanguage/pl/selmoProLanding/warehouse-big-04.webp"
            />
            {/*<SelmoProLandingBenefitsBox*/}
            {/*    benefits={benefits}*/}
            {/*    imgSrc='/assets/images/multiLanguage/pl/selmoProLanding/chart.svg'*/}
            {/*/>*/}
            <SelmoProLandingMoreFunctionsBox
                currentPage="depositSystem"
            />
        </div>
    );
};

export default DepotSystemLanding;
