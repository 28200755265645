import Model from "../../../../src_shared/modules/model/Model";
import ValidationError from "../../../../src_shared/form/validation/ValidationError";

export class PricingModel extends Model {
	getModel() {
		return {
			id: 0,
			name: '',
			active: '1',
			price: '',
			old_price: '',
			description: '',
			months: 1,
			lang: 'pl',
		};
	}

	getValidators() {
		return {
			name: ValidationError.notEmpty,
			description: ValidationError.notEmpty,
			lang: ValidationError.notEmpty,
			price: ValidationError.notEmpty,
			old_price: ValidationError.skip,
			months: ValidationError.notEmpty,
			active: ValidationError.skip,
		};
	}
}

export default new PricingModel();
