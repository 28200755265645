import {useState} from "react";
import api from "../../services/axios/axios";
import {useDispatch} from "react-redux";
import {showAlert} from "../../components/app/shared/components/alert/AlertActions";
import {filesSetModalVisibility} from "../../components/app/shared/header/files/filesDropdown/FilesActions";

export const useExportFile = ({
                                    rest,
                                    afterSubmit = () => {
                                    },
                                    onError = (e) => {
                                    },
                                }) => {
    const [waiting, setWaiting] = useState(false);
    const dispatch = useDispatch();
    const exportFile = async () => {
        dispatch(filesSetModalVisibility(true))
        try {
            await api.post(`/${rest}`);
            afterSubmit();
        } catch (error) {
            onError(error)
            dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
            dispatch(filesSetModalVisibility(false))
        } finally {
            setWaiting(false);
        }
    };

    return {exportFile, waiting};
};
