import Composition from "../../components/app/shared/helpers/Composition.js";
import paginationReducer from './pagination/PaginationReducer.js';
import tableReducer from './table/TableReducer.js';
import waitingReducer from './waiting/WaitingReducer.js';
import modalReducer from "../modal/ModalReducer";

export const getInitState = (defaultStateValues) => ({
	...paginationReducer.initState,
	...tableReducer.initState,
	...waitingReducer.initState,
	...modalReducer.initState,
	...defaultStateValues,
});

export const getReduceMap = () => new Composition(
	paginationReducer.reduceMap,
	tableReducer.reduceMap,
	waitingReducer.reduceMap,
	modalReducer.reduceMap,
	{
		SET_FIRST_STEP_PAGE: (state, action) => ({...state, isFirstStepPage: action.toggle}),
	}

);

export default {
	getInitState,
	getReduceMap,
};
