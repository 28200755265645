import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import _ from "lodash";

export const getLangTextWithArguments = (data, langData) => {
    const { key } = data;
    const textData = data.data;
    let i = 0;
    let text = langData[key] || '';

    while (text.indexOf('%s') !== -1) {
        text = text.replace('%s', textData[i]);
        ++i;
    }

    return text;
};

export const getLangText = (lang) => (data) => {
    if (!data) {
        return '';
    }

    if (typeof data !== 'string') {
        return getLangTextWithArguments(data, lang);
    }

	return lang[data] || '';
};

export const langKeyExists = (lang) => (key) => (
    !!lang[key]
);

const langSelector = (state) => state.i18nAuthSinglePage.translations;
const langShallowEqual = (nextState, state) => _.isEqual(nextState, state);

export default () => {
    const lang = useSelector(langSelector, langShallowEqual);
    const memoizedGetLangText = useMemo(() => getLangText(lang), [lang]);
    const memoizedLangKeyExists = useMemo(() => langKeyExists(lang), [lang]);

    return {
        getLangText: memoizedGetLangText,
        langKeyExists: memoizedLangKeyExists,
    };
};
