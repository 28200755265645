import Model from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

export class DomainModel extends Model {
	getModel() {
		return {
			id: 0,
			url: '',
			domain: '',
		};
	}

	getValidators() {
		return {
			url: ValidationError.skip,
			domain: ValidationError.skip,
		};
	}
}

export default new DomainModel();
