import React from 'react';
import {useSelector} from "react-redux";
import useLang from "../../hooks/useLang";
import {variants} from "../../helpers/helper";
import {dniVariants} from "../../../components/app/payments/Form";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../components/routes/RouterPath";

const FunctionalityBoxes = () => {
    const {userData} = useSelector((state) => state.session);
    const {getLangText} = useLang();

    const minDateObject = userData?.functionalities?.length > 0
        ? userData.functionalities.reduce((minObj, currentObj) => {
            const currentDate = new Date(currentObj.expires_in);
            const minDate = new Date(minObj.expires_in);
            return currentDate < minDate ? currentObj : minObj;
        })
        : {};

    const today = new Date();
    const expiresDate = new Date(minDateObject.expires_in);

    const timeDiff = expiresDate.getTime() - today.getTime();
    const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

    if (!userData?.functionalities?.length) {
        return null;
    }

    return (
        <>
            <div className="functionalities-wrapper sm:ml-auto sm:mr-2">
                <Link
                    to={RouterPaths.Payments}
                    className="hover:text-[#000] flex bg-[white] px-2 py-2 items-center justify-center border border-[#F87171] sm:rounded-md text-xs hover:no-underline">
                    <div className="mr-1 -mt-0.5">
                        <i className="icon-alert-c text-sm text-[#F87171]"/>
                    </div>
                    <div dangerouslySetInnerHTML={{
                        __html: getLangText({
                            key: 'inDaysEndOfServiceLabel',
                            data: [daysDiff, getLangText(variants(daysDiff, dniVariants))]
                        })
                    }}
                    />
                    <div className="text-xs text-[white] font-semibold bg-[#F87171] px-1.5 py-1 rounded-sm ml-2">
                        {getLangText('extendLabel')}
                    </div>
                </Link>
            </div>
        </>
    );
};

export default FunctionalityBoxes;
