import React from 'react';
import useLang from "../../../../src_shared/hooks/useLang";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import useLogout from "../hooks/useLogout";
import LanguagePicker from "../languagePicker/LanguagePicker";
import {Menu} from "@headlessui/react";
import cn from "clsx";
import Dropdown from "../../../../src_shared/components/ui/Dropdown/Dropdown";
import {
    USER_PERMISSIONS_SETTINGS_MENU_VISIBILITY,
} from "../enums/UserPermissionsTypes";
import UserPermissionsWrapper from "../../../routes/UserPermissionsWrapper";
import ServerImage from "../components/serverImage/ServerImage";

const Profile = ({userData}) => {

    const {getLangText} = useLang();
    const {logout} = useLogout();

    const HeaderButton = () => (
        (userData.photo && userData.photo !== '0') ?
            <div className="header-profile-btn">
                <div className="w-[30px] min-w-[30px] h-[30px] rounded-full overflow-hidden">
                    <ServerImage className="w-full h-full object-cover" src={userData.photo}/>
                </div>
                <i className="icon-arrows"/>
            </div> :
            <div className="header-profile-btn">
                <i className="icon-profile"/>
                <i className="icon-arrows"/>
            </div>
    );

    return (
        <Dropdown Button={HeaderButton}>
            <Menu.Item>
                {({active}) => (
                    <Link
                        to={RouterPaths.SettingsAccountChangePassword}
                        className={cn(
                            active ? 'bg-gray-100' : '',
                            'p-2.5 rounded-sm px-2 py-[6px] transition-all flex items-center text-xs text-[#1F2937] hover:text-[#1F2937] font-bold hover:no-underline'
                        )}
                    >
                        <div>
                            <div className="font-medium">{getLangText('emailLabel')}</div>
                            <div>
                                <strong>{userData.employee_email || userData.email}</strong>
                            </div>
                        </div>
                    </Link>
                )}
            </Menu.Item>
            <Menu.Item>
                {({active}) => (
                    <div
                        className={cn(
                            active ? 'bg-gray-100' : '',
                            'w-full p-2.5 rounded-sm px-2 py-[6px] transition-all flex items-center text-xs text-[#1F2937] hover:text-[#1F2937] font-bold hover:no-underline'
                        )}
                    >
                        <LanguagePicker
                            withCountryName
                            buttonClassName="d-flex items-center custom-hover w-full"
                        />
                    </div>
                )}
            </Menu.Item>
            <Menu.Item>
                {({active}) => (
                    <Link
                        to={RouterPaths.Payments}
                        className={cn(
                            active ? 'bg-gray-100' : '',
                            'p-2.5 rounded-sm px-2 py-[6px] transition-all flex items-center text-xs text-[#1F2937] hover:text-[#1F2937] font-bold hover:no-underline'
                        )}
                    >
                        <i className="icon-profile w-[18px] inline-block text-base text-[#6b7280] mr-[7px]"/>
                        {getLangText('subscriptionLabel')}
                    </Link>
                )}
            </Menu.Item>
            <UserPermissionsWrapper
                allowedPermissions={[USER_PERMISSIONS_SETTINGS_MENU_VISIBILITY]}>
                {!!+userData.employees_accounts &&
                    <Menu.Item>
                        {({active}) => (
                            <Link
                                to={RouterPaths.SettingsTeam}
                                className={cn(
                                    active ? 'bg-gray-100' : '',
                                    'p-2.5 rounded-sm px-2 py-[6px] transition-all flex items-center text-xs text-[#1F2937] hover:text-[#1F2937] font-bold hover:no-underline'
                                )}
                            >
                                <i className="icon-team w-[18px] inline-block text-base text-[#6b7280] mr-[7px]"/>
                                {getLangText('teamLabel')}
                            </Link>
                        )}
                    </Menu.Item>
                }
            </UserPermissionsWrapper>
            <UserPermissionsWrapper allowedPermissions={[USER_PERMISSIONS_SETTINGS_MENU_VISIBILITY]}>
                <Menu.Item>
                    {({active}) => (
                        <Link
                            to={RouterPaths.Settings}
                            className={cn(
                                active ? 'bg-gray-100' : '',
                                'p-2.5 rounded-sm px-2 py-[6px] transition-all flex items-center text-xs text-[#1F2937] hover:text-[#1F2937] font-bold hover:no-underline'
                            )}
                        >
                            <i className="icon-settings w-[18px] inline-block text-base text-[#6b7280] mr-[7px]"/>
                            {getLangText('asideSettingsLabel')}
                        </Link>
                    )}
                </Menu.Item>
            </UserPermissionsWrapper>
            <Menu.Item>
                {({active}) => (
                    <button
                        type="button"
                        onClick={logout}
                        className={cn(
                            active ? 'bg-gray-100' : '',
                            'w-full p-2.5 rounded-sm px-2 py-[6px] transition-all flex items-center text-xs text-[#1F2937] hover:text-[#1F2937] font-bold hover:no-underline'
                        )}
                    >
                        <i className="icon-sign-out w-[18px] inline-block text-base text-[#6b7280] mr-[7px]"/>
                        {getLangText('logoutLabel')}
                    </button>
                )}
            </Menu.Item>
        </Dropdown>
    );
};

export default Profile;
