import React, {useContext, useState} from 'react';
import SortableList from "../../../../../../src_shared/components/form/sortableList/SortableList";
import useDataApi from "../../../../../../src_shared/hooks/useDataApi";
import {services} from "../../../../../RestServices";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../../routes/RouterPath";
import AssignCategoriesModal from "./assignCategories/Form";
import axios from "axios";
import {selmoUrl} from "../../../../../../src_shared/api/api";
import {showAlert} from "../../../../shared/components/alert/AlertActions";
import {useDispatch} from "react-redux";
import ConfirmRemoveModal from "../../../../../../src_shared/components/modal/ConfirmRemoveModal";
import useRemoveData from "../../../../../../src_shared/hooks/useRemoveData";
import useLang from "../../../../../../src_shared/hooks/useLang";
import {ShopSettingsContext} from "../../../../../routes/shopSettingsRoute/ShopSettingsRoute";
import api from "../../../../../../services/axios/axios";

const Categories = () => {
	const dispatch = useDispatch();
	const {getLangText} = useLang();
	const {setIsChanging} = useContext(ShopSettingsContext);

	const [{
		data,
		isLoading,
		setData,
		fetchData
	}] = useDataApi(`${services.API_STORE_HOMEPAGE_CATEGORIES}?homepage_active=1&sortBy=order_number&sortDir=ASC`, []);

	const [{
		openModal,
		hideModal,
		confirmModalVisible,
		itemToRemove,
		setIsDeleting,
		isDeleting,
	}] = useRemoveData({
		rest: services.API_STORE_HOMEPAGE_CATEGORIES,
	});

	const [modalVisible, setModalVisible] = useState(false);

	const ItemComponent = (item) => (
		<div className="category-row">
			<div className="sort-button">
				<i className="icon-drag"/>
			</div>
			<div className="d-flex align-items-center flex-grow-1">
				<Link to={`${RouterPaths.CategoriesList}/${item.id}/edytuj`}>
					{item.name}
				</Link>
				<button
					type="button"
					className="button text-only small-icon ml-auto py-1 lgrey d-inline-flex align-items-center"
					onClick={() => openModal(item)}
				>
					<i className="icon-cross mr-1"/>
					<div className="d-sm-none">{getLangText('hideLabel')}</div>
					<div className="d-sm-block d-none text-decoration-none">{getLangText('hideOnMainPageLabel')}</div>
				</button>
			</div>
		</div>
	)

	const updateAllItemsOnServer = async (items) => {
		try {
			await api.post(`${selmoUrl}/${services.API_STORE_HOMEPAGE_CATEGORIES_ORDER}`, {items})
			dispatch(showAlert('yuhuLabel', 'successfulSavedOrderLabel'))
			setIsChanging(new Date());
		} catch (e) {
			dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
			console.error(e)
		}
	}

	const removeItem = async () => {
		setIsDeleting(true);
		try {
			await api.post(`${selmoUrl}/${services.API_STORE_HOMEPAGE_CATEGORIES}`, {
				homepage_active: '0',
				categories: [itemToRemove.id]
			})
			await fetchData();
			hideModal();
			dispatch(showAlert());
			setIsChanging(new Date());

		} catch (e) {
			dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
			console.error(e)
		} finally {
			setIsDeleting(false);
		}
	}

	const updateAllItems = async (updatedItems) => {
		setData(updatedItems);
		await updateAllItemsOnServer(updatedItems);
	}

	const ExtraComponent = () => (
		<button
			onClick={() => setModalVisible(true)}
			type="button"
			className="button primary small-size mt-3"
		>
			{getLangText('assignCategoriesLabel')}
		</button>
	)

	return (
		<section>
			<div className="left-side">
				<h2 className="label">
					{getLangText('visibleCategoriesLabel')}
				</h2>
			</div>
			<div className="form-col">
				<SortableList
					isWaiting={isLoading}
					items={data}
					updateAllItemsOnServer={updateAllItems}
					ItemComponent={ItemComponent}
					noResultsLabel="noVisibleCategoriesLabel"
					noResultsSubLabel="noVisibleCategoriesSubLabel"
					ExtraEmptyComponent={ExtraComponent}
				/>
				{!!data.length &&
					<button
						type="button"
						onClick={() => setModalVisible(true)}
						className="button text-blue-button"
					>
						<i className="icon-plus"/>
						{getLangText('assignCategoryLabel')}
					</button>
				}
			</div>
			{modalVisible &&
				<AssignCategoriesModal
					modalVisible={modalVisible}
					hideModal={() => setModalVisible(false)}
					afterSubmit={fetchData}
				/>
			}
			{confirmModalVisible &&
				<ConfirmRemoveModal
					show={confirmModalVisible}
					subTitle={itemToRemove.name}
					dialogClassName="small-modal"
					title={getLangText('wantToHideCategoryLabel')}
					hide={hideModal}
					confirm={removeItem}
					isWaiting={isDeleting}
					confirmButton={getLangText('hideLabel')}
				/>
			}
		</section>
	);
};

export default Categories;
