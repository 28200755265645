import React from 'react';
import SettingsBox from "../../components/SettingsBox";
import useLang from "../../../../src_shared/hooks/useLang";
import {RouterPaths} from "../../../routes/RouterPath";

export const SETTINGS_PAYMENTS_SHIPMENTS = [
    {
        id: 0,
        title: 'settings_nav_payment',
        desc: 'settings_nav_payment_description',
        imgSrc: '/assets/images/settings/payments.svg',
        path: RouterPaths.SettingsPayments
    },
    {
        id: 1,
        title: 'settings_nav_shipping',
        desc: 'settings_nav_shipping_description',
        imgSrc: '/assets/images/settings/delivery.svg',
        path: RouterPaths.SettingsShipping
    },
]
const PaymentShipmentBoxes = () => {
    const {getLangText} = useLang();

    return (
        <section>
            <div className="section-title">{getLangText('settings_nav_payment_shipping')}</div>
            <div className="settings-box-wrapper border-boxes">
                {SETTINGS_PAYMENTS_SHIPMENTS.map((item) => (
                    <SettingsBox
                        key={item.id}
                        item={item}
                    />
                ))}
            </div>
        </section>
    );
};

export default PaymentShipmentBoxes;
