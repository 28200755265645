import React, {useState} from 'react';
import useLang from "../../../../src_shared/hooks/useLang";
import ClientOrders from "./list/ClientOrders";
import ClientReserveList from "./reserveList/List";
import ClientsRemovedList from "./removedList/List";
import {useSelector} from "react-redux";
import {CHIQUE_SHOP_ID} from "../../../../src_shared/enums/TrustedShopsIds";

const CLIENTS_LIST_ORDERS_VIEW = 'CLIENTS_LIST_ORDERS_VIEW'
const CLIENTS_LIST_RESERVE_LIST_VIEW = 'CLIENTS_LIST_RESERVE_LIST_VIEW'
const CLIENTS_LIST_REMOVED_LIST_VIEW = 'CLIENTS_LIST_REMOVED_LIST_VIEW'
const ClientsTabs = () => {
    const {getLangText} = useLang();
    const [activeView, setActiveView] = useState(CLIENTS_LIST_ORDERS_VIEW);
    const {edit_cart_enabled, id} = useSelector((state) => state.session.userData);

    return (
        <>
            <div className="tabs-wrapper bg-[white] rounded-t-[5px] shadow-default">
                <button
                    type="button"
                    onClick={() => setActiveView(CLIENTS_LIST_ORDERS_VIEW)}
                    className={activeView === CLIENTS_LIST_ORDERS_VIEW ? 'active' : ''}
                >
                    {getLangText('asideOrdersLabel')}
                </button>
                <button
                    type="button"
                    onClick={() => setActiveView(CLIENTS_LIST_RESERVE_LIST_VIEW)}
                    className={activeView === CLIENTS_LIST_RESERVE_LIST_VIEW ? 'active' : ''}
                >
                    {getLangText('reserveListLabel')}
                </button>
                {(!!+edit_cart_enabled || +id === CHIQUE_SHOP_ID) &&
                    <button
                        type="button"
                        onClick={() => setActiveView(CLIENTS_LIST_REMOVED_LIST_VIEW)}
                        className={activeView === CLIENTS_LIST_REMOVED_LIST_VIEW ? 'active' : ''}
                    >
                        {getLangText('removedProductsLabel')}
                    </button>
                }
            </div>
            {activeView === CLIENTS_LIST_ORDERS_VIEW &&
                <ClientOrders/>
            }
            {activeView === CLIENTS_LIST_RESERVE_LIST_VIEW &&
                <ClientReserveList/>
            }
            {activeView === CLIENTS_LIST_REMOVED_LIST_VIEW &&
                <ClientsRemovedList/>
            }
        </>
    );
};

export default ClientsTabs;
