import React, {useEffect, useRef, useState} from 'react';
import * as ReactDOM from 'react-dom'
import useWindowDimensions from "../../../components/app/shared/hooks/useWindowDimensions";

const DropdownPortal = ({
                            children,
                            basedOn,
                            dropdownClassName,
                            offsetY = 0,
                            target = document.body,
                            rightPosition = false
                        }) => {

    const windowDimensions = useWindowDimensions();

    if (!basedOn?.current) {
        return null;
    }

    const {top, left, width, bottom, height, right} = basedOn?.current.getBoundingClientRect();
    const maxHeight = windowDimensions.height - bottom;

    const preparedTop = () => {
        if (target?.scrollTop > 0) {
            return (top + height) + target?.scrollTop;
        }
        return top + height;
    }

    return ReactDOM.createPortal(
        <div
            style={{
                top: preparedTop() + offsetY,
                left: !rightPosition ? left + 1 : left,
                minWidth: width,
                maxHeight: maxHeight,
            }}
            className={`dropdown-menu custom-dropdown-menu show ${dropdownClassName}`}
        >
            {children}
        </div>, target
    )
}

export default DropdownPortal;
