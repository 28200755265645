import React, {useEffect, useMemo, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import Loader from "../../../../messenger/loader/Loader";
import useLang from "../../../../../../src_shared/hooks/useLang";
import SwitchBoxes from "../../../../../../src_shared/components/form/switchBoxes/SwitchBoxes";
import useFormLocal from "../../../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../../../RestServices";
import ComponentLoader from "../../../../../../src_shared/components/ui/componentLoader/ComponentLoader";
import {showAlert} from "../../../../shared/components/alert/AlertActions";
import {useDispatch} from "react-redux";
import useDataApi from "../../../../../../src_shared/hooks/useDataApi";
import Summary from "../../../list/components/selectedRow/generateLabelsModal/summary/Summary";
import {useDownloadFile} from "../../../../../../src_shared/hooks/useDownloadFile";
import {getGETParamsUrl} from "../../../../../../src_shared/list/ListActions";

const SelectOptionForm = ({hide, selectedCountry, loadData, submitRest = '', optionsModel, selectedItems = null}) => {
    const {getLangText} = useLang();
    const dispatch = useDispatch();
    const [successGeneratedData, setSuccessGeneratedData] = useState(null);

    const {download, waiting} = useDownloadFile({
        rest: `${services.API_PRINT_LABELS}${getGETParamsUrl({'orders[]': selectedItems?.map((i) => i.id)})}`,
        fileName: 'Etykieta',
        afterSubmit: hide,
    })

    const hideSummaryModal = () => {
        loadData();
        hide()
    }

    const afterSubmit = (response) => {
        if (response.all_orders) {
            setSuccessGeneratedData(response)
            return;
        }
        loadData();
        hide()
        dispatch(showAlert('labelHasBennGeneratedLabel', ''))
    }

    const [{data}] = useDataApi(`${services.API_PAXY_OPTIONS}?selected_country=${selectedCountry}`,
        {
            options: [],
        })

    const [{onSave, isWaiting, getFieldProps, isLoading, values, updateValues}] = useFormLocal({
        rest: submitRest,
        model: optionsModel,
        afterSubmit,
        initialAutoLoad: false,
    });

    useEffect(() => {
        if (data?.options) {
            updateValues(data)
        }
    }, [data])

    const options = useMemo(() => values.options.map((i) => ({
        id: i.id,
        label: i.name,
    })), [values.options])

    if (successGeneratedData) {
        return (
            <Modal.Body>
                <Summary
                    waitingForDocument={waiting}
                    hideModal={hideSummaryModal}
                    generatedOrdersCount={successGeneratedData}
                    onPrint={download}
                    withCloseIcon={false}
                />
            </Modal.Body>
        )
    }

    if (isLoading) return <ComponentLoader/>

    return (
        <>
            {!successGeneratedData &&
                <Modal.Header>
                    <Modal.Title>
                        <img
                            style={{height: '22px'}}
                            src="/assets/images/paxy-logo.png"
                            alt="Paxy"
                        />
                        <div className="description">{getLangText('realizeOrdersFasterLabel')}</div>
                    </Modal.Title>
                    <button
                        onClick={hide}
                        type="button"
                        className="button"
                    >
                        <i className="icon-cross"/>
                    </button>
                </Modal.Header>
            }
            <Modal.Body>
                {!options.length ?
                    <div className="form-info-box w-100">
                        <div>
                            <i className="icon-info-c"/>
                        </div>
                        <div>
                            <div className="title">{getLangText('noCouriersLabel')}</div>
                        </div>
                    </div> :
                    <>
                        <div className="form-label">
                            {getLangText('selectCourierLabel')}
                        </div>
                        <SwitchBoxes
                            {...getFieldProps('option')}
                            options={options}
                            label={getLangText('optionLabel')}
                            className="separated-boxes small-padd"
                            ErrorComponent={() => (
                                <div className="form-info-box alert-style red w-100 mb-2">
                                    <div>
                                        <i className="icon-info-c"/>
                                    </div>
                                    <div className="title">
                                        {getLangText('fillRequiredFieldWarning')}
                                    </div>
                                </div>
                            )}
                        />
                    </>

                }
            </Modal.Body>
            <Modal.Footer>
                <button
                    onClick={hide}
                    type="button"
                    className="button border-button"
                >
                    {getLangText('cancelButton')}
                </button>
                <button
                    type="button"
                    className={`button primary small-size ${values.option ? '' : 'not-active'} ${isWaiting ? 'loading-button' : ''}`}
                    onClick={onSave}
                >
                    {isWaiting && <Loader/>}
                    {getLangText('generateLabel')}
                </button>
            </Modal.Footer>
        </>
    );
};

export default SelectOptionForm;
