import React from 'react';
import useLang from "../../../../src_shared/hooks/useLang";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import NotConnected from "./NotConnected";
import useDataApi from "../../../../src_shared/hooks/useDataApi";
import {services} from "../../../RestServices";
import ComponentLoader from "../../../app/shared/components/componentLoader/ComponentLoader";
import Connected from "./Connected";
import {Helmet} from "react-helmet";

const FacebookSettings = () => {
    const {getLangText} = useLang();
    const [{data, isLoading}] = useDataApi(services.API_SETTINGS_FACEBOOK, {
        facebookLoginUrl: '',
    })

    if (isLoading) return <ComponentLoader/>;

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Facebook - Selmo</title>
            </Helmet>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <Link to={RouterPaths.Settings}>{getLangText('asideSettingsLabel')}</Link>
                    </li>
                    <li>
                        <Link to={RouterPaths.SettingsMeta}>Meta</Link>
                    </li>
                    <li className="active">
                        <div>Facebook</div>
                    </li>
                </ul>
            </div>
            <div className="settings-page sub-settings-page">
                <div className="header-wrapper">
                    <h1>
                        <img src="/assets/images/svg/facebook-logo-c.svg" alt="facebook"/>
                        Facebook
                    </h1>
                    <div className="description">
                        {getLangText('settings_facebook_description')}
                    </div>
                </div>
            </div>
            {!data?.facebookLoginUrl ?
                <Connected data={data}/> :
                <NotConnected data={data}/>
            }
        </>
    );
};

export default FacebookSettings;
