import React from 'react';
import SwitchField from "../../../../../src_shared/components/form/switchField/SwitchField";
import Field from "../../../../../src_shared/components/form/field/Field";
import useLang from "../../../../../src_shared/hooks/useLang";

const Item = ({getFieldProps, name, label, values, sizeName}) => {
    const {getLangText} = useLang()

    return (
        <div className="border flex items-center p-[12px] rounded-[5px]">
            <SwitchField
                {...getFieldProps(name)}
                label={getLangText(label)}
                className="switch-form inline-label big-label light-version d-flex mb-0"
            />
            {!!+values[name] &&
                <div className="ml-auto">
                    <Field
                        {...getFieldProps(sizeName)}
                        label={getLangText('sizeLabel')}
                        className="mb-0 ml-auto small-size w-[100px] hidden-arrows"
                        type="number"
                        floatedLabel
                        extraProps={{autoFocus: true}}
                    />
                </div>
            }
        </div>
    );
};

export default Item;
