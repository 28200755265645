import Model from "../../../src_shared/modules/model/Model";

class NotificationsModel extends Model {
	getModel() {
		return {
			history: [],
			total: 0,
			mobile_app: '1',
			waitingForSend: false,
			mobile_app_orders: '0',
			mobile_app_reserve_list: '0',
			releasing_active: '0',
			releasing_minutes: '60',
			notification_minutes: '30',
		};
	}

	buildDTO(data) {
		return {
			mobile_app: data.mobile_app,
			mobile_app_orders: data.mobile_app_orders,
			mobile_app_reserve_list: data.mobile_app_reserve_list,
			releasing_active: data.releasing_active,
			releasing_minutes: data.releasing_minutes,
			notification_minutes: data.notification_minutes,
		}
	}
}

export default new NotificationsModel();
