import React, {useState} from 'react';
import {Helmet} from "react-helmet";
import ListActions from "./ListActions";
import {getStateRoot} from "./listReducer";
import useList from "../../../../../src_shared/list/useList";
import Pagination from "../../../shared/components/pagination/Pagination";
import {useParams} from "react-router-dom";
import Header from "../components/header/Header";
import useLang from "../../../../../src_shared/hooks/useLang";
import Table from "./components/Table";
import {EXPENDITURES_LIST_PRODUCT_VIEW} from "../../../shared/enums/EditProductViews";
import ConfirmModal from "../../../live/details/default/summary/export/ConfirmModal";
import ExpendituresModal from "./components/ExpendituresModal";

const ExpendituresList = ({data}) => {
    const params = useParams();
    const {getLangText} = useLang();
    const [showEditModal, setShowEditModal] = useState({
        visible: false,
        id: null,
    });

    const {
        pagination: {
            changePage,
            getPagination,
            itemsPerPage,
            nextPage,
            prevPage,
            totalItems,
            updateItemsPerPage
        },
        list: {items, isWaiting, loadData},
        search,
        sort: {updateSortOptions, getSortClass},
        remove: {askForRemoveItem, isModalVisible, cancelItemRemoval, markedToRemove, confirmItemRemoval},
    } = useList({
        ListActions,
        getStateRoot,
        hasParentId: true,
        params,
    });

    return (
        <div className="table-list-wrapper">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getLangText('expendituresLabel')} - Selmo</title>
            </Helmet>
            <div className={`default-shadow-box pb-0`}>
                <Header
                    ListActions={ListActions}
                    params={params}
                    search={search}
                    totalItems={totalItems}
                    data={data}
                    title={getLangText('expendituresLabel')}
                    type={EXPENDITURES_LIST_PRODUCT_VIEW}
                />
                <Table
                    getSortClass={getSortClass}
                    updateSortOptions={updateSortOptions}
                    items={items}
                    isWaiting={isWaiting}
                    loadData={loadData}
                    askForRemoveItem={askForRemoveItem}
                    setShowEditModal={(id) => setShowEditModal({id, visible: true})}
                />
            </div>
            {totalItems > itemsPerPage &&
                <Pagination
                    getLangText={getLangText}
                    pages={getPagination()}
                    items={items}
                    itemsPerPage={itemsPerPage}
                    totalItems={totalItems}
                    onPrevPage={prevPage}
                    onChangePage={changePage}
                    onNextPage={nextPage}
                    onChangeItemsPerPage={updateItemsPerPage}
                    classes={{
                        prevIcon: 'icon-chevron left',
                        nextIcon: 'icon-chevron right',
                    }}
                    showItemsPerPageSelect
                />
            }
            {isModalVisible &&
                <ConfirmModal
                    dialogClassName="w-380"
                    title={getLangText('wantToRemoveExpenditureLabel')}
                    SubTitle={() => getLangText('afterRemoveExpenditureLabel')}
                    saveButton={getLangText('removeButton')}
                    show={isModalVisible}
                    isWaiting={isWaiting}
                    onSubmit={confirmItemRemoval}
                    hide={cancelItemRemoval}
                    saveButtonClass="danger"
                />
            }
            {showEditModal.visible &&
                <ExpendituresModal
                    modalVisible={showEditModal.visible}
                    hideModal={() => setShowEditModal({id: null, visible: false})}
                    fetchData={() => loadData(params.id)}
                    id={showEditModal.id}
                />
            }
        </div>
    );
};

export default ExpendituresList;
