import {fetchTranslations} from "../../../../services/i18n/i18nAPI";
import {getFromLS, saveToLS} from "../helpers/ls";
import {batch} from "react-redux";
import moment from "moment/moment";
import {supportedLangs} from "../../../../services/i18nAuthSInglePage/i18n";

export class LangsActions {
	setWaiting(toggle) {
		return {
			type: 'LANGS_AUTH_SINGLE_PAGE_SET_WAITING',
			toggle
		};
	}

	setTranslations(translations) {
		return {
			type: 'LANGS_AUTH_SINGLE_PAGE_SET_TRANSLATIONS',
			translations
		};
	}

	getBrowserLocales() {
		const browserLocales =
			navigator.languages === undefined
				? [navigator.language]
				: navigator.languages;

		if (!browserLocales) {
			return false;
		}
		return browserLocales.map(locale => {
			const trimmedLocale = locale?.trim();
			return trimmedLocale?.split(/-|_/)[0];
		})[0];
	}

	getLang() {
		return () => {
			const browserLocales = this.getBrowserLocales();
			const LSLang = getFromLS('lang_auth');

			const preparedBrowserLocales = supportedLangs[browserLocales] ? browserLocales : 'en'

			return LSLang || preparedBrowserLocales;
		}
	}

	setServerLanguage(lang) {
		return async (dispatch) => {

		}
	}

	setAsyncLang(lang) {
		return async (dispatch) => {
			dispatch(this.setWaiting(true));

			const resolvedLang = lang || dispatch(this.getLang())

			try {
				const translations = await fetchTranslations(resolvedLang, "/authLocales/{lang}.json");
				batch(() => {
					dispatch(this.setLang(resolvedLang))
					dispatch(this.setTranslations(translations))

				})
				moment.locale(resolvedLang)
				saveToLS('lang_auth', resolvedLang);

			} catch (e) {
				console.error('Cannot set server lang')
			} finally {
				dispatch(this.setWaiting(false));
			}
		}
	}

	setLang(lang) {
		return {
			type: 'LANGS_AUTH_SINGLE_PAGE_SET_LANG',
			lang
		};
	}
}

export const getInstance = () => new LangsActions();

export default getInstance();
