import React from 'react';
import {onlinePaymentsIdArray} from "../../../../../shared/components/paymentMethods/PaymentMethodsForm";
import {
    ORDER_PAYMENT_BLIK, ORDER_PAYMENT_BLUEMEDIA,
    ORDER_PAYMENT_BM_TRANSFER, ORDER_PAYMENT_MB_WAY,
    ORDER_PAYMENT_MOBILEPAY, ORDER_PAYMENT_PAYPAL,
    ORDER_PAYMENT_STRIPE_CARD,
    ORDER_PAYMENT_TRANSFER
} from "../../../../../shared/enums/PaymentStatuses";
import CardDetails from "../../../../../shared/components/paymentMethods/components/CardDetails";
import AdditionalTransferAccounts
    from "../../../../../shared/components/paymentMethods/components/AdditionalTransferAccounts";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import AutoPayBanks from "./AutoPayBanks";

const Payment = ({
                     item,
                     values,
                     onPaymentChange,
                     updateValues,
                     paymentValidationActive,
                     onTransferAccountChange,
                     shopInfo,
                     cardDetailsVisible,
                     getPreparedPaymentLabel
                 }) => {
    const {getLangText} = useLang();

    return (
        <React.Fragment key={item.id}>
            {!!+item.active &&
                <div
                    className={`content p-0 payment-content with-left-border ${onlinePaymentsIdArray.includes(item.id) ? 'grey-border' : ''} ${onlinePaymentsIdArray.includes(item.id) && values.payment === item.id ? 'blue-border' : ''} ${values.payment === item.id ? 'active' : ''}`}
                >
                    <div
                        style={{
                            position: item.id === ORDER_PAYMENT_BLUEMEDIA ? 'static' : 'relative'
                        }}
                        className={`content__top-part`}>
                        <input
                            checked={values.payment === item.id}
                            onChange={() => onPaymentChange('payment', item.id)}
                            type="radio"
                            name="payment"
                            required
                        />
                        <span
                            className="checkmark"/>
                        <div className="flex-grow-1">
                            <div className="label">
                                {item.id === ORDER_PAYMENT_MOBILEPAY ?
                                    <figure>
                                        <img
                                            src="/assets/images/payments/mobilepay.svg"
                                            style={{height: '18px'}}
                                        />
                                    </figure> :
                                    getPreparedPaymentLabel(item)
                                }
                            </div>
                            <div className="data">
                                {item.description &&
                                <div className={(onlinePaymentsIdArray.includes(item.id) && item.description.includes(getLangText('freeLabel'))) ? 'text-green-700' : ''}>{getLangText(item.description) || item.description}</div>
                                }
                            </div>
                        </div>
                        {item.id === ORDER_PAYMENT_BLIK &&
                            <figure
                                className="bg-[#fff] rounded-[5px] flex items-center justify-center border min-w-[48px] w-[48px] h-[34px] p-1">
                                <img
                                    className="w-full object-contain"
                                    src="/assets/images/payments/blik.svg"
                                    style={{width: '100%', height: 'auto'}}
                                />
                            </figure>
                        }
                        {item.id === ORDER_PAYMENT_STRIPE_CARD &&
                            <figure
                                className="bg-[#fff] rounded-[5px] flex items-center justify-center border min-w-[48px] w-[48px] h-[34px] p-1">
                                <img
                                    className="w-full object-contain"
                                    src="/assets/images/payments/cards.svg"
                                    style={{width: '100%', height: 'auto'}}
                                />
                            </figure>
                        }
                        {item.id?.includes(ORDER_PAYMENT_PAYPAL) &&
                            <figure
                                className="bg-[#fff] rounded-[5px] flex items-center justify-center border min-w-[48px] w-[48px] h-[34px] p-1">
                                <img
                                    src="/assets/images/payments/paypal.png"
                                    className="w-full object-contain"
                                    style={{width: '100%', height: 'auto'}}
                                />
                            </figure>
                        }
                        {item.id === ORDER_PAYMENT_MB_WAY &&
                            <figure
                                className="bg-[#fff] rounded-[5px] flex items-center justify-center border min-w-[48px] w-[48px] h-[34px] p-1">
                                <img
                                    src="/assets/images/payments/mb-way.png"
                                    className="w-full object-contain"
                                    style={{width: '100%', height: 'auto'}}
                                />
                            </figure>
                        }
                        {item.id === ORDER_PAYMENT_TRANSFER &&
                            <figure
                                className="bg-[#fff] rounded-[5px] flex items-center justify-center border min-w-[48px] w-[48px] h-[34px] p-1">
                                <img
                                    src="/assets/images/payments/standard.svg"
                                    className="w-full object-contain"
                                    style={{width: '100%', height: 'auto'}}
                                />
                            </figure>
                        }
                    </div>
                    {cardDetailsVisible && item.id === ORDER_PAYMENT_STRIPE_CARD &&
                        <div className="-mt-8 p-[20px]">
                            <CardDetails
                                values={values}
                                updateValues={updateValues}
                                paymentValidationActive={paymentValidationActive}
                            />
                        </div>
                    }
                    {item.id === ORDER_PAYMENT_BLUEMEDIA &&
                        <AutoPayBanks/>
                    }
                </div>
            }
            {item.id === ORDER_PAYMENT_TRANSFER && !!item.additional_transfer_accounts?.length &&
                item.additional_transfer_accounts?.map((account) => (
                    <AdditionalTransferAccounts
                        key={account.id}
                        account={account}
                        isPopularBoxActive={false}
                        onTransferAccountChange={onTransferAccountChange}
                        shopInfo={shopInfo}
                        values={values}
                    />
                ))
            }
        </React.Fragment>
    );
};

export default Payment;
