import Model from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

export class AssignProductsModel extends Model {
	getModel() {
		return {
			products: [],
			category_id: null,
		};
	}

	getValidators() {
		return {
			category_id: ValidationError.notEmpty,
			products: ValidationError.arrayNotEmpty,
		};
	}
}

export default new AssignProductsModel();
