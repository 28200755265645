import Model, {convertObjectProperties} from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";
import {
    findOrderStatusById,
    ORDER_STATUS_NOT_PAID,
    ORDER_STATUS_OPEN_CART,
    ORDER_STATUS_TO_SEND
} from "../../../components/app/shared/enums/OrderStatuses";

class OrderPaymentReminderModel extends Model {
    getModel() {
        return {
            days: null,
            active: '0',
            message_active: '0',
            message_text: '0',
            warning_active: '0',
            warning_message: '',
            warning_days: null,
        };
    }

    parseDTO(data) {
        return convertObjectProperties(
            data,
            {
                statuses: (statuses) => statuses.map((status) => ({value: status, label: 'test'}))
            },
            {
                statuses: [
                    {
                        value: ORDER_STATUS_NOT_PAID, label: 'statusNotPaidLabel', color: 'red',
                    },
                    {
                        value: ORDER_STATUS_OPEN_CART, label: 'statusOpenCartLabel', color: 'light-blue',
                    },
                ],
            },
        );
    }

    getValidators() {
        return {
            days: ValidationError.combine(
                ValidationError.notEmpty,
                ValidationError.greaterThan(0),
            ),
            active: ValidationError.skip,
            message_active: ValidationError.skip,
            message_text: ValidationError.skip,
            warning_active: ValidationError.skip,
            warning_days: (value, data) => !!+data.warning_active ? ValidationError.smallerThan(data.days, 'amountHaveToBeSmallerThenAmountDaysAfterOrderWillBeCancelled')(value, data) : false,
            warning_message: (value, data) => !!+data.warning_active ? ValidationError.notEmpty(value) : false
        };
    }

    buildDTO(data) {
        return {
            ...data,
            statuses: data.statuses.map((i) => i.value)
        }
    }
}

export default new OrderPaymentReminderModel();
