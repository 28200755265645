import React from 'react';
import Group from "../group/Group";

const TextArea = (props) => {

	const {
		name,
		setValue,
		value,
		disabled,
		extraProps,
		placeholder,
		rows,
		style,
	} = props;

	return (
		<Group {...props}>
			<textarea
				style={style}
				rows={rows}
				className="form-control"
				onChange={(e) => setValue(name, e.target.value)}
				value={value}
				disabled={disabled}
				placeholder={placeholder}
				{...extraProps}
			/>
		</Group>
	);
}

TextArea.defaultProps = {
	setValue: () => {
	},
	value: '',
	required: false,
	name: '',
	disabled: false,
	extraProps: {},
	rows: '4',
}

export default TextArea;
