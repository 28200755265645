import Composition from "../../shared/helpers/Composition";
import createReducer from "../../shared/helpers/createReducer";
import FormReducer from "../../../../src_shared/form/FormReducer";
import BasketDeliveryMethodModel from "../../../../modules/models/basket/BasketDeliveryMethodModel";

export const getStateRoot = (state) => state.basket.deliveryMethods;

export const prefix = 'BASKET_DELIVERY_METHODS_FORM_';

export const getInitState = () => FormReducer.getInitState(BasketDeliveryMethodModel.getModel(), {
	paymentsMethodsVisible: false,
	deliveryMethodsModalVisible: false,
	deliveryMethodsVisible: false,
	waitingForSubmit: false,
	waitingForValueOnServer: false,
	showAutomaticTransfersModal: false,
})

const getReduceMap = () => new Composition(
	FormReducer.getReduceMap(),
	{
		SET_PAYMENT_METHODS_VISIBLE: (state, action) => ({...state, paymentsMethodsVisible: action.toggle}),
		SET_DELIVERY_METHODS_MODAL_VISIBLE: (state, action) => ({...state, deliveryMethodsModalVisible: action.toggle}),
		SET_DELIVERY_METHODS_VISIBLE: (state, action) => ({...state, deliveryMethodsVisible: action.toggle}),
		WAITING_FOR_SUBMIT: (state, action) => ({...state, waitingForSubmit: action.toggle}),
		SET_WAITING_FOR_VALUE_ON_SERVER: (state, action) => ({...state, waitingForValueOnServer: action.toggle}),
		SET_SHOW_AUTOMATIC_TRANSFER_MODAL: (state, action) => ({...state, showAutomaticTransfersModal: action.toggle}),
	}
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();
