import {getStateRoot, prefix} from "./formReducer.js";
import FormActions from "../../../../../src_shared/form/FormActions.js";
import {services} from "../../../../RestServices.js";
import VariantsSquModalModel from "../../../../../modules/models/VariantsSquModalModel";
import EditProductFormActions from "../edit/FormActions";

export class VariantsSquModalFormActions extends FormActions {
	afterSubmit(id, data) {
		return (dispatch) => {
			dispatch(this.modal.hideModal());
			dispatch(EditProductFormActions.loadData(id))
		};
	}
}

export const getInstance = () => new VariantsSquModalFormActions({
	getStateRoot,
	prefix,
	model: VariantsSquModalModel,
	restService: services.API_STORAGE_PRODUCT_SIZE_STOCK,
});

export default getInstance();
