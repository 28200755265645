import React from 'react';
import useLang from "../../../../src_shared/hooks/useLang";
import Modal from "react-bootstrap/Modal";
import Loader from "../../shared/Loader";

const AfterCopyView = ({hideModal, redirectToMessenger, waitingForRedirect}) => {
    const {getLangText} = useLang();
    return (
        <>
            <Modal.Header className="justify-content-center pt-sm-5 pt-0">
                <Modal.Title className="text-center">
                    <i className="icon-tick-c text-[#3CD9A0] text-[28px]" />
                    <div className="text-sm font-bold leading-[26px] text-[#3CD9A0]">{getLangText('copiedLabel')}!</div>
                </Modal.Title>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            <Modal.Body className="pb-sm-5 pb-4 pt-4 text-center">
                <div className="text-[22px] font-bold leading-[30px]" dangerouslySetInnerHTML={{__html: getLangText('sendCopiedTextOnMessengerLabel')}} />
            </Modal.Body>
            <Modal.Footer>
                <button
                    onClick={redirectToMessenger}
                    type="button"
                    className={`button primary w-auto m-sm-0 mx-auto ${waitingForRedirect ? 'loading-button' : ''}`}
                >
                    {waitingForRedirect && <Loader isLoading={true}/>}
                    <i className="icon-messenger-2-f mr-2"/>
                    {getLangText('goToMessengerLabel')}
                </button>
            </Modal.Footer>
        </>
    );
};

export default AfterCopyView;