import React, {useEffect} from 'react';
import Confetti from "react-confetti";
import {useDispatch, useSelector} from "react-redux";
import useWindowDimensions from "../../../messenger/hooks/useWindowDimensions/useWindowDimensions";
import {confettiSetVisibleActions} from "./ConfettiActions";

const ConfettiComponent = () => {
    const {isVisible} = useSelector((state) => state.shared.confetti);
    const {width, height} = useWindowDimensions();
    const dispatch = useDispatch();

    useEffect(() => {
        if (isVisible) {
            setTimeout(() => {
                dispatch(confettiSetVisibleActions(false));
            }, 15000)
        }
    }, [isVisible])

    if (!isVisible) {
        return;
    }

    return (
        <Confetti
            style={{width: '100vw', height: '100vh', position: 'fixed', left: 0, top: 0}}
            run={isVisible}
            width={width}
            height={height}
            recycle={false}
            numberOfPieces={500}
        />
    );
};

export default ConfettiComponent;
