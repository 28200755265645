import React from 'react';
import Modal from "react-bootstrap/Modal";
import {useDispatch, useSelector} from "react-redux";
import useLang from "../../../../src_shared/hooks/useLang";
import Loader from "../Loader";
import {setGenerateLabelModalVisibility} from "./generateLabelModalActions";
import {getGETParamsUrl} from "../../../../src_shared/list/ListActions";
import {filesSetModalVisibility} from "../header/files/filesDropdown/FilesActions";
import useFetchData from "../../../../src_shared/hooks/useFetchData";

const GenerateLabelModal = () => {
    const {getLangText} = useLang();
    const dispatch = useDispatch();
    const {isOpen, data, isSuccess} = useSelector((state) => state.shared.generateLabelModal);

    const hideModal = () => dispatch(setGenerateLabelModalVisibility(false));

    const afterSubmit = () => {
        hideModal();
        dispatch(filesSetModalVisibility(true));
    }

    const [{isLoading, fetchData: downloadFile}] = useFetchData({
        rest: `${data.rest}${getGETParamsUrl({'orders[]': data.items.map((i) => i.id)})}`,
        initialAutoLoad: false,
        afterSubmit
    })

    return (
        <Modal
            dialogClassName={`default-modal w-380`}
            show={isOpen}
            onHide={hideModal}
        >
            <Modal.Header>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            <Modal.Body className="mb-3 pt-0">
                {!isSuccess ?
                    <div className="flex items-center justify-center flex-col">
                        <Loader
                            isLoading={true}
                            className="w-[12px] static-loader small-loader mt-[6px] transform-none"
                        />
                        <div className="font-bold text-sm mt-3">
                            {getLangText('generatingFiles')}
                        </div>
                    </div> :
                    <div>
                        <div className="flex items-center justify-center flex-col">
                            <img
                                className="w-[28px] min-w-[28px]"
                                src="/assets/images/svg/circle-check.svg"
                                alt="check"
                            />
                            <div className="font-bold text-sm mt-2">
                                {getLangText('labelsHasBennGeneratedLabel')}
                            </div>
                        </div>
                        <button
                            onClick={downloadFile}
                            type="button"
                            className={`button w-full mt-3 primary ${isLoading ? 'loading-button' : ''}`}
                        >
                            {isLoading && <Loader isLoading/>}
                            {getLangText('printLabelsLabel')}
                        </button>
                    </div>
                }
            </Modal.Body>
        </Modal>
    );
};

export default GenerateLabelModal;
