import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import React from "react";
import {checkRoles} from "./PermissionRoute";

const PermissionsWrapper = ({ children, allowedPermissions, NoPermissionComponent }) => {
	const {permissions} = useSelector((state) => state.session.userData);

	const permissionsMatched = checkRoles(permissions, allowedPermissions);

	if (!permissionsMatched) {
		return NoPermissionComponent && <NoPermissionComponent />;
	}

	return children;
};

PermissionsWrapper.propTypes = {
	allowedPermissions: PropTypes.arrayOf(
		PropTypes.string,
	).isRequired,
	children: PropTypes.node,
};

PermissionsWrapper.defaultProps = {
	children: null,
	NoPermissionComponent: null,
};

export default PermissionsWrapper;
