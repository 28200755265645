import React from 'react';
import {Route} from "react-router-dom";
import StatsPage from "../app/stats/StatsPage";
import UserPermissionRoute from "./UserPermissionRoute";
import {USER_PERMISSION_STATS_MENU_VISIBILITY} from "../app/shared/enums/UserPermissionsTypes";
import AnalyticsPage from "../app/analytics/Page";
import DMListPage from "../app/dmList/Page";

const DMListRoute = ({path}) => {
	return (
		<Route
			component={DMListPage}
			path={path}
			exact
		/>
	);
};

export default DMListRoute;
