import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../routes/RouterPath";
import {services} from "../../../../RestServices";
import useLang from "../../../../../src_shared/hooks/useLang";
import useFormLocal from "../../../../../src_shared/hooks/useFormLocal";
import SwitchField from "../../../../../src_shared/components/form/switchField/SwitchField";
import Loader from "../../../../app/shared/Loader";
import Wrapper from "../Wrapper";
import SettingsWoocommerceModel from "../../../../../modules/models/settings/SettingsWoocommerceModel";
import Field from "../../../../../src_shared/components/form/field/Field";
import ConfirmModal from "../../../../app/orders/list/components/export/ConfirmModal";
import Model from "../../../../../src_shared/modules/model/Model";
import {useDispatch} from "react-redux";
import {showAlert} from "../../../../app/shared/components/alert/AlertActions";

const WoocommerceConfiguration = () => {
    const {getLangText} = useLang();
    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch();

    const [{onSave, isWaiting, getFieldProps, isLoading}] = useFormLocal({
        rest: services.API_SETTINGS_WOOCOMMERCE,
        model: SettingsWoocommerceModel,
    });

    const [{onSave: syncProducts, isWaiting: waitingForProducts}] = useFormLocal({
        rest: services.API_SETTINGS_SYNC_WOOCOMMERCE,
        initialAutoLoad: false,
        model: new Model(),
        method: 'get',
        afterSubmit: () => {
            setShowModal(false)
            dispatch(showAlert('syncProductsSuccess', 'productsWillShowInFewMinutes'))
        }
    });

    return (
        <>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <Link to={RouterPaths.Settings}>
                            {getLangText('asideSettingsLabel')}
                        </Link>
                    </li>
                    <li>
                        <Link to={RouterPaths.SettingsIntegration}>{getLangText('settings_nav_integrations')}</Link>
                    </li>
                    <li className="active">
                        Woocommerce
                    </li>
                </ul>
            </div>
            <Wrapper setShowModal={setShowModal}>
                <div className="loader-parent">
                    <Loader isLoading={isLoading}/>
                    <div className="settings-form pt-4">
                        <div className="steps-wrapper">
                            <div className="step">
                                <div className="step-header align-items-start">
                                    <div className="title d-block flex-grow-1">
                                        <div className="mb-2">{getLangText('pasteKeysInFieldsBelowLabel')}</div>
                                        <Field
                                            {...getFieldProps('url')}
                                            label={getLangText('shopUrl')}
                                        />
                                        <Field
                                            {...getFieldProps('consumer_key')}
                                            label={getLangText('apiKeyLabel')}
                                        />
                                        <Field
                                            {...getFieldProps('consumer_secret')}
                                            label={getLangText('apiSecretKey')}
                                        />
                                        <SwitchField
                                            {...getFieldProps('active')}
                                            label={getLangText('realizeOrdersInMyShop')}
                                            className="switch-form inline-label big-label light-version mt-0 mb-0"
                                        />
                                        <div className="flex items-center mt-2 justify-end">
                                            <button
                                                type="button"
                                                onClick={onSave}
                                                className={`button primary ml-auto ${isWaiting ? 'loading-button' : ''}`}
                                            >
                                                <Loader isLoading={isWaiting}/>
                                                {getLangText('saveButton')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {showModal &&
                    <ConfirmModal
                        dialogClassName="small-modal w-420"
                        title={getLangText('wantToSyncProductsWithWooCommerce')}
                        SubTitle={() => <div className="mt-2" dangerouslySetInnerHTML={{__html: getLangText('wantToSyncProductsWithWooCommerceSubLabel')}} />}
                        saveButton={getLangText('yesButton')}
                        show={showModal}
                        isWaiting={waitingForProducts}
                        onSubmit={syncProducts}
                        hide={() => setShowModal(false)}
                    />
                }
            </Wrapper>
        </>
    );
};

export default WoocommerceConfiguration;
