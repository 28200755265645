import Model from "../../../../src_shared/modules/model/Model";
import ValidationError from "../../../../src_shared/form/validation/ValidationError";

export class TranslationsModel extends Model {
	getModel() {
		return {
			id: 0,
			lang: null,
			source: null,
		};
	}

	getValidators() {
		return {
			lang: ValidationError.notEmpty,
			source: ValidationError.notEmpty,
		};
	}
}

export default new TranslationsModel();
