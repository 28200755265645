import React, {useEffect} from 'react';
import Field from "../../../../../src_shared/components/form/field/Field";
import {useSelector} from "react-redux";
import {SHOP_ORIGIN_US} from "../../../shared/enums/ShopOrigins";

const VatRate = ({getFieldProps, setValue, inModal}) => {
    const {userData} = useSelector((state) => state.session);

    useEffect(() => {
        if (userData.origin === SHOP_ORIGIN_US && !!+userData.vat_rate && inModal) {
            setValue('vat_rate', userData.vat_rate)
        }
    }, [userData.vat_rate, userData.origin, inModal])

    return (
        <div className="w-1/2">
            <Field
                {...getFieldProps('vat_rate')}
                setValue={setValue}
                label="Stawka VAT"
                type="number"
                placeholder="0.00"
                addon="%"
                className={!inModal ? 'mt-3' : ''}
            />
        </div>
    );
};

export default VatRate;
