import React, {useState} from 'react';
import axios from "axios";
import {selmoUrl} from "../../../../../src_shared/api/api";
import {services} from "../../../../RestServices";
import Loader from "../../../messenger/loader/Loader";
import {useDispatch} from "react-redux";
import {showAlert} from "../../../shared/components/alert/AlertActions";
import moment from "moment";
import {findOrderStatusById} from "../../../shared/enums/OrderStatuses";
import EditOrderFormActions from "../form/FormActions";
import useLang from "../../../../../src_shared/hooks/useLang";
import api from "../../../../../services/axios/axios";
import {convertToUserTimeZone} from "../../../shared/helpers/dateHelpers";

const SendEmailForm = ({values}) => {
	const {getLangText} = useLang();

	const [waiting, setWaiting] = useState(false)

	const dispatch = useDispatch();
	const sendEmail = async () => {
		setWaiting(true)
		try {
			await api.post(`${selmoUrl}/${services.API_ORDER_SEND_EMAIL_STATUS}/${values.id}`)
			dispatch(showAlert('emailCorrectSentLabel', ''))
			dispatch(EditOrderFormActions.loadData(values.shop_order_id))

		} catch (e) {
			console.warn('Error send email')
			dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
		} finally {
			setWaiting(false)
		}
	}

	const preparedDate = (date) => {
		if (convertToUserTimeZone(date).format('DD.MM.YYYY') === convertToUserTimeZone(new Date()).format('DD.MM.YYYY')) {
			return `${getLangText('todayAtLabel')} ${convertToUserTimeZone(date).format('HH:mm')}`
		}
		return convertToUserTimeZone(date).format('DD.MM.YYYYr. o HH:mm')
	}

	const isEmailAvailable = values.shippingData.email;


	return (
		<section className="summary-section email-status-wrapper">
			<h2>{getLangText('emailWithOrderStatusLabel')}</h2>
			<div>
				<div className="tooltip-parent">
					{!isEmailAvailable &&
						<div className="tooltip-content">
							{getLangText('enterEmailLabel')}
						</div>
					}
					<button
						disabled={!isEmailAvailable}
						type="button"
						onClick={sendEmail}
						className={`button border-button w-100 ${waiting ? 'loading-button' : ''}`}
					>
						{waiting && <Loader/>}
						{getLangText('sendEmailWithOrderStatusLabel')}
					</button>
				</div>
			</div>
			{!!values.email_history.length &&
				<div className="sent-emails">
					<h2>{getLangText('sentEmailsLabel')}</h2>
					<div className="emails-sent-wrapper">
						{values.email_history.map((email) => (
							<div
								key={email.date}
								className="email-row">
								<div className="date">
									{preparedDate(email.date)}
								</div>
								<div className={`status-bg ${findOrderStatusById(email.status)?.color}`}>
									{getLangText(findOrderStatusById(email.status)?.label)}
								</div>
							</div>
						))}
					</div>
				</div>
			}
		</section>
	);
};

export default SendEmailForm;
