import React from 'react';
import useLang from "../../../../../src_shared/hooks/useLang";
import useFetchData from "../../../../../src_shared/hooks/useFetchData";
import {services} from "../../../../RestServices";
import Loader from "../../Loader";

const NotPaidSelmoProBar = () => {
    const {getLangText} = useLang();

    const [{isLoading, fetchData: getUrl}] = useFetchData({
        rest: services.API_SELMO_PRO_FEE,
        afterSubmit: (response) => window.location.href = response.url,
        initialAutoLoad: false,
    })

    return (
        <div className="bg-[#f75f5e] flex text-[#fff] items-center font-bold text-sm justify-center h-[40px] px-6">
            {getLangText('paymentsForSelmoProFailed')}
            <button
                type="button"
                onClick={getUrl}
                className={`bg-[#fff] relative button white px-[8px] py-[3px] font-bold rounded-[5px] ml-2 text-[#f75f5e] ${isLoading ? 'loading-button pointer-events-none' : ''}`}
            >
                {isLoading && <Loader isLoading/>}
                {getLangText('pay')}
            </button>
        </div>
    );
};

export default NotPaidSelmoProBar;
