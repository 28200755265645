import React from 'react';
import TextArea from "../../../../../src_shared/components/form/textarea/TextArea";
import useLang from "../../../../../src_shared/hooks/useLang";
import CopyButton from "../../../../../src_shared/components/form/copyButton/CopyButton";

const Description = ({getFieldProps, setValue, values}) => {
    const {getLangText} = useLang();

    return (
        <div className="relative">
            <TextArea
                {...getFieldProps('description')}
                placeholder={getLangText('enterDescription')}
                setValue={setValue}
                label={getLangText('input_label_package_type_description')}
                value={values.description}
                className="big-bottom-padd"
                rows={4}
                optional
            />
            <div className="absolute bottom-2 right-2">
                <CopyButton
                    copyButtonText={getLangText('copyButton')}
                    copiedButtonText={getLangText('copiedButton')}
                    copyText={values.description}
                    buttonClassName="border-button ml-0 copied"
                />
            </div>
        </div>
    );
};

export default Description;
