import React from 'react';
import useSubmitData from "../../../../../../../src_shared/hooks/useSubmitData";
import {services} from "../../../../../../RestServices";
import {showAlert} from "../../../../../shared/components/alert/AlertActions";
import {useDispatch} from "react-redux";
import {resetAndUpdateClients} from "../../LiveDetailsActions";
import Loader from "../../../../../messenger/loader/Loader";

const ResetCartButton = ({userData, finishedLive, shopLiveId, liveId}) => {

    const dispatch = useDispatch();

    const [{onSave, isWaiting}] = useSubmitData({
        rest: `${services.API_LIVE_RESET_CART}/${shopLiveId}`,
        afterSubmit: () => {
            dispatch(showAlert())
            dispatch(resetAndUpdateClients(liveId));
        },
    });


    if (+userData.selmo_boss !== 69 || !finishedLive) {
        return null
    }

    return (
        <button
            type="button"
            className="button primary small-size mb-3 w-100 d-flex justify-content-center mt-24"
            onClick={onSave}
        >
            {isWaiting && <Loader/>}
            Usuń wysłane stany koszyka
        </button>
    );
};

export default ResetCartButton;
