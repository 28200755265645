import {getStateRoot, prefix} from './listReducer.js';
import {services} from "../../../../../RestServices.js";
import {RouterPaths} from "../../../../../routes/RouterPath.js";
import ListActions from "../../../../../../src_shared/list/ListActions.js";

export class LivePlatformActiveListActions extends ListActions {

	updateFromPusher() {
		return (dispatch) => {
			if (window.location.pathname.includes(RouterPaths.LivePlatformActiveList)) {
				dispatch(this.loadData())
			}
		}
	}

}

export const getInstance = () => new LivePlatformActiveListActions({
	getStateRoot,
	restService: services.API_PRODUCTS_LATEST,
	pathname: RouterPaths.LivePlatformActiveList,
	preventPushParams: true,
	prefix,
});

export default getInstance();
