import React, {useContext} from "react";
import FormActions from "./FormActions.js";
import useForm from "../../../../src_shared/form/useForm.js";
import {Helmet} from "react-helmet";
import useLang from "../../../../src_shared/hooks/useLang";
import ImageUpload from "../../../../src_shared/components/form/imageUpload/ImageUpload";
import Field from "../../../../src_shared/components/form/field/Field";
import Loader from "../../shared/Loader";
import TextArea from "../../../../src_shared/components/form/textarea/TextArea";
import ShopNameBar from "../../../routes/shopSettingsRoute/ShopNameBar";
import {useDispatch, useSelector} from "react-redux";
import {ShopSettingsContext} from "../../../routes/shopSettingsRoute/ShopSettingsRoute";
import SwitchField from "../../../../src_shared/components/form/switchField/SwitchField";

const ShopGeneral = () => {
	const {getLangText} = useLang();
	const dispatch = useDispatch();
	const {setIsChanging} = useContext(ShopSettingsContext);
	const {lang} = useSelector((state) => state.i18n);

	const {
		data: {values, setValue, updateValues},
		form: {isWaiting, getFieldProps},
	} = useForm({
		FormActions,
		params: {},
		requireParentId: false
	});

	const onSave = () => dispatch(FormActions.submitEditForm(values, () => setIsChanging(new Date())))

	return (
		<>
			<ShopNameBar/>
			<div className="settings-page sub-settings-page loader-parent">
				<Helmet>
					<meta charSet="utf-8"/>
					<title>{getLangText('shopGeneralSettingsLabel')}Selmo</title>
				</Helmet>
				<div className="settings-form loader-parent">
					<Loader isLoading={isWaiting}/>
					<section>
						<div className="left-side">
							<h2 className="label">{getLangText('shopLogoLabel')}</h2>
						</div>
						<div className="form-col">
							<ImageUpload
								defaultImage="shop-logo.svg"
								values={values}
								updateValues={updateValues}
								FormActions={FormActions}
								imagePath="store"
								bigPhoto
								sizes="150x30 px"
							/>
						</div>
					</section>
					<section>
						<div className="left-side">
							<h2 className="label">{getLangText('faviconLabel')}</h2>
						</div>
						<div className="form-col">
							<ImageUpload
								defaultImage="selmo-favicon.png"
								values={values}
								updateValues={updateValues}
								FormActions={FormActions}
								imagePath="store/favicon"
								field="favicon"
								sizes="16x16 px"
								formats="correctFormatsPngLabel"
							/>
							<div className="form-info-box w-100 mt-3">
								<div>
									<i className="icon-info-c"/>
								</div>
								<div>
									<div className="title">
										{getLangText('whatIsFaviconTitleLabel')}
									</div>
									<div className="desc">
										{getLangText('whatIsFaviconDescLabel')}
									</div>
									<img className="mt-2" style={{maxWidth: '250px', borderRadius: '5px'}}
										 src={`/assets/images/multiLanguage/${lang}/favicon.webp`}/>
								</div>
							</div>
						</div>
					</section>
					<section>
						<div className="left-side">
							<h2 className="label mb-0">{getLangText('ogPhotoLabel')}</h2>
						</div>
						<div className="form-col">
							<ImageUpload
								defaultImage="selmo-store-og.jpg"
								values={values}
								updateValues={updateValues}
								FormActions={FormActions}
								imagePath="store/og-photo"
								field="og_photo"
								sizes="1200x628 px"
								formats="correctFormatsJpgLabel"
								bigPhoto
							/>
							<div className="form-info-box w-100 mt-3">
								<div>
									<i className="icon-info-c"/>
								</div>
								<div>
									<div className="title">
										{getLangText('whatIsOgPhotoTitleLabel')}
									</div>
									<div className="desc">
										{getLangText('ogPhotoSubLabel')}
									</div>
									{/*<img className="mt-2" style={{maxWidth: '250px', borderRadius: '5px'}}*/}
									{/*	 src="/assets/images/shopSettings/favicon.png"/>*/}
								</div>
							</div>
						</div>
					</section>
					<section>
						<div className="left-side">
							<h2 className="label mb-0">{getLangText('contactDataLabel')}</h2>
							<div className="sub-label">
								{getLangText('willBeVisibleInFooterLabel')}
							</div>
						</div>
						<div className="form-col">
							<Field
								{...getFieldProps('contact_email')}
								label={getLangText('emailLabel')}
								placeholder={getLangText('textEmailLabel')}
								setValue={setValue}
							/>
							<TextArea
								{...getFieldProps('contact_address')}
								label={getLangText('addressLabel')}
								placeholder={getLangText('textAddressLabel')}
								setValue={setValue}
								rows={4}
							/>
							<Field
								{...getFieldProps('contact_phone')}
								label={getLangText('phoneLabel')}
								placeholder={getLangText('typePhoneNumberLabel')}
								setValue={setValue}
							/>
						</div>
					</section>
					<section>
						<div className="left-side">
							<h2 className="label">{getLangText('socialMediaLabel')}</h2>
						</div>
						<div className="form-col">
							<Field
								{...getFieldProps('facebook_link')}
								label="Facebook"
								placeholder={getLangText('typeLinkLabel')}
								setValue={setValue}
							/>
							<Field
								{...getFieldProps('instagram_link')}
								label="Instagram"
								placeholder={getLangText('typeLinkLabel')}
								setValue={setValue}
							/>
							<Field
								{...getFieldProps('tiktok_link')}
								label="TikTok"
								placeholder={getLangText('typeLinkLabel')}
								setValue={setValue}
							/>
							<Field
								{...getFieldProps('twitter_link')}
								label="Twitter"
								placeholder={getLangText('typeLinkLabel')}
								setValue={setValue}
							/>
						</div>
					</section>
					<section>
						<div className="left-side">
							<h2 className="label">{getLangText('basketCartLabel')}</h2>
						</div>
						<div className="form-col">
							<div>
								<SwitchField
									{...getFieldProps('guest_active')}
									className="switch-form inline-label big-label light-version mb-0 mt-0"
									setValue={setValue}
									label={getLangText('createOrderAsGuestLabel')}
									subLabel={getLangText('allowCreateOrderAsGuestLabel')}
									id="guest_active"
								/>
							</div>
						</div>
					</section>
					<section>
						<div className="left-side">
							<h2 className="label">{getLangText('languageVersionLabel')}</h2>
						</div>
						<div className="form-col">
							<div>
								<SwitchField
									{...getFieldProps('multi_language_active')}
									className="switch-form inline-label big-label light-version mb-0 mt-0"
									setValue={setValue}
									label={getLangText('languageVersionLabel')}
									subLabel={getLangText('allowChangeLanguageOnShopLabel')}
									id="guest_active"
								/>
							</div>
							<button
								onClick={onSave}
								type="button"
								className={`button primary w-sm-auto w-100 mt-sm-5 mt-3`}
							>
								{getLangText('saveButton')}
							</button>
						</div>
					</section>
				</div>
			</div>
		</>
	);
};

export default ShopGeneral;



