import React from 'react';
import useLang from "../../../../../../src_shared/hooks/useLang";
import SwitchField from "../../../../../../src_shared/components/form/switchField/SwitchField";
import SalesPostModel from "../../../../../../modules/models/salesPost/SalesPostModel";
import ConfirmRemoveModal from "../../../../../../src_shared/components/modal/ConfirmRemoveModal";
import useRemoveData from "../../../../../../src_shared/hooks/useRemoveData";
import {services} from "../../../../../RestServices";
import {RouterPaths} from "../../../../../routes/RouterPath";

const SaveAndPublish = ({onSave, getFieldProps, setValue, values}) => {
    const {getLangText} = useLang();
    const checkSaveButtonActivity = () => SalesPostModel.validateData(values);

    const isActiveSaveButton = checkSaveButtonActivity();

    const [{
        openModal,
        hideModal,
        confirmModalVisible,
        isDeleting,
        removeItem,
    }] = useRemoveData({
        rest: services.API_VIDEO_SOCIAL_VIDEOS,
        redirectRoute: RouterPaths.SalesPost
    });

    return (
        <div className="rounded-[5px] p-[25px] bg-[white] mt-3">
            <div className={`font-bold text-lg ${isActiveSaveButton ? '' : 'text-[#9CA3AF]'}`}>
                {getLangText('summaryLabel')}
            </div>
            <div className="space-y-3 mt-3">
                <div>
                    <SwitchField
                        disabled={!isActiveSaveButton}
                        {...getFieldProps('comments_disabled')}
                        setValue={setValue}
                        className="switch-form align-items-start inline-label big-label light-version mb-0"
                        label={getLangText('disableCommentsInSelmoApp')}
                        subLabel={getLangText('disableCommentsInSelmoAppDesc')}
                        id="comments_disabled"
                    />
                </div>
                {/*<div>*/}
                {/*    <SwitchField*/}
                {/*        disabled={!isActiveSaveButton}*/}
                {/*        {...getFieldProps('reserve_list_enabled')}*/}
                {/*        setValue={setValue}*/}
                {/*        className="switch-form align-items-start inline-label big-label light-version mb-0"*/}
                {/*        label={getLangText('disableReserveListInSelmoApp')}*/}
                {/*        subLabel={getLangText('disableReserveListInSelmoAppDesc')}*/}
                {/*        id="reserve_list_enabled"*/}
                {/*    />*/}
                {/*</div>*/}
            </div>
            <div className="flex items-center p-[20px] rounded-b-[5px] bg-[#F9FAFB] -mx-[25px] -mb-[25px] mt-3">
                {values.id &&
                    <button
                        type="button"
                        onClick={() => openModal(values)}
                        className="button grey-button"
                    >
                        <i className="icon-bin mr-1"/>
                        {getLangText('removePost')}
                    </button>
                }
                <button
                    style={{fontSize: 14, paddingTop: 17, paddingBottom: 17, fontWeight: 'bold'}}
                    className={`primary small-size ml-auto button ${isActiveSaveButton ? '' : 'not-active'}`}
                    type="button"
                    onClick={onSave}
                >
                    {getLangText('saveAndPublish')}
                </button>
            </div>
            {confirmModalVisible &&
                <ConfirmRemoveModal
                    show={confirmModalVisible}
                    title={getLangText('wantToRemovePost')}
                    subTitle={getLangText('postWillRemoveOnlyInSelmoApp')}
                    hide={hideModal}
                    confirm={removeItem}
                    isWaiting={isDeleting}
                    confirmButton={getLangText('removeButton')}
                    dialogClassName="small-modal w-420"
                />
            }
        </div>
    );
};

export default SaveAndPublish;
