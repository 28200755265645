import {prefix} from "./formReducer";
import {getStateRoot as getInfoStateRoot} from "../info/dataReducer";
import {getStateRoot} from "./formReducer";
import FormActions from "../../../../../src_shared/form/FormActions";
import EditClientModel from "../../../../../modules/models/EditClientModel";
import InfoDataActions from '../info/DataActions';
import {batch} from "react-redux";

import {selmoUrl} from "../../../../../src_shared/api/api";
import {ERROR_NOT_FOUND} from "../../../../../src_shared/enums/ErrorEnums";
import {services} from "../../../../RestServices";
import api from "../../../../../services/axios/axios";

export class EditClientFormActions extends FormActions {
	afterSubmit() {
		return (dispatch, getState) => {
			const {data} = getInfoStateRoot(getState());
			batch(() => {
				dispatch(this.modal.hideModal())
				dispatch(InfoDataActions.loadData(data.shop_client_id));
			})
		}
	}

	showClientModal(toggle) {
		return {
			type: `${this.prefix}SHOW_CLIENT_MODAL`,
			toggle,
		}
	}

	loadData(id) {
		return async (dispatch) => {
			dispatch(this.waiting.startWaiting());
			const restUrl = `${services.API_CLIENT_INFO}/${id}`
			try {
				const {data} = await api.get(`${selmoUrl}/${restUrl}`)
				const parsedItem = dispatch(this.parseData(data.item));
				dispatch(this.data.updateValues(parsedItem))
			} catch (e) {
				if (e.response?.data?.message === ERROR_NOT_FOUND || e.response?.status === 500) {
					dispatch(this.data.setValue('notFound', true));
				}
				dispatch(this.onErrorLoad(e))
				console.error('Error loadData')
			} finally {
				dispatch(this.waiting.stopWaiting());
			}
		}
	}
}

export const getInstance = () => new EditClientFormActions({
	prefix,
	getStateRoot,
	restService: 'api/clients',
	model: EditClientModel,
});

export default getInstance();

