import React, {useContext, useState} from 'react';
import {AuthInstagramContext} from "./AuthInstagramContext";
import useLang from "../../../../src_shared/hooks/useLang";
import {INSTAGRAM_SIGNUP_CLICK, plausibleInstagram} from "../signup/SignupInstagram";
import Loader from "../../shared/Loader";
import useSubmitData from "../../../../src_shared/hooks/useSubmitData";
import {services} from "../../../RestServices";

const InstagramButton = () => {
    const {getLangText} = useLang();
    const data = useContext(AuthInstagramContext);


    const [{onSave: handleClick, isWaiting}] = useSubmitData({
        rest: services.API_REGISTER_LOG,
        values: {
            selmo_token: data.selmoAuthToken,
        },
        afterSubmit: () => window.location.href = data.instagramUrl
    });

    if (data.isLoading) return (
        <div className="animate-pulse h-[50px] rounded-[8px] bg-gray-200 dark:bg-gray-700 w-full"/>
    )

    return (
        <button
            onClick={handleClick}
            className={`button border-button google-button w-full overflow-hidden ${isWaiting ? 'loading-button' : ''}`}
            type="button"
        >
            {isWaiting && <Loader isLoading/>}
            <img width={18} height={18} className="mr-1" alt="instagram"
                 src="/assets/images/instagram.png"/>
            {getLangText('continueWithInstagram')}
        </button>
    );
};

export default InstagramButton;
