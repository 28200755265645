import React from 'react';
import ServerImage from "../../../shared/components/serverImage/ServerImage";
import moment from "../../../shared/moment";
import useLang from "../../../../../src_shared/hooks/useLang";
import {RouterPaths} from "../../../../routes/RouterPath";
import {useSelector} from "react-redux";
import useScanTranslations, {SCAN_TRANSLATIONS_WITHOUT_ORDER} from "../../../shared/hooks/useScanTranslations";
import {convertToUserTimeZone} from "../../../shared/helpers/dateHelpers";

const OrderHistoryList = ({items}) => {
    const {getLangText} = useLang();
    const {userData} = useSelector((state) => state.session);
    const {getScanTranslations} = useScanTranslations(SCAN_TRANSLATIONS_WITHOUT_ORDER)

    return (
        <div className="employee-history">
            {items?.map((item) => (
                <div
                    key={item.employee_user_id}
                    className="flex">
                    <div className="z-[1] w-[20px] h-[20px] min-w-[20px] rounded-full overflow-hidden mr-2">
                        {item.photo && item.photo !== '0' ?
                            <ServerImage className="w-full h-full object-cover" src={item.photo}/> :
                            <img className="w-full h-full object-cover"
                                 src="/assets/images/default/avatar.svg" alt=""/>}
                    </div>
                    <div className="text-xs">
                        {!!item.name ?
                            <a
                                target="_blank"
                                href={`${RouterPaths.SettingsTeamMembers}/${item.employee_user_id}`}
                                className="font-bold hover:text-[black]"
                            >
                                {item.name}
                            </a> :
                            <span className="font-bold hover:text-[black]">
                                {userData.name}
                            </span>
                        }
                        <span className="text-[8px] text-desc mx-1 align-middle">•</span>
                        <span className="font-medium scan-type-no-bold mr-2"
                              dangerouslySetInnerHTML={{__html: getScanTranslations(item)}}/>
                        <div className="text-desc font-medium">{convertToUserTimeZone(item.date).format('DD.MM.YYYY HH:mm:ss')}</div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default OrderHistoryList;
