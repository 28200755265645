import React from 'react';
import RegulationWrapper from "../RegulationWrapper";
import useDataApi from "../../../../../src_shared/hooks/useDataApi";
import {services} from "../../../../RestServices";
import Ckeditor from "../../../../../src_shared/components/form/ckeditor/Ckeditor";
import useLang from "../../../../../src_shared/hooks/useLang";
import ShopNameBar from "../../../../routes/shopSettingsRoute/ShopNameBar";

const Privacy = () => {
	const {getLangText} = useLang();
	const [{data, isLoading, setData, fetchData}] = useDataApi(`${services.API_STORE_PRIVACY}`, '')

	return (
		<>
			<ShopNameBar redirect="privacy/"/>
			<RegulationWrapper
				rest={services.API_STORE_PRIVACY}
				value={{policy: data}}
				isLoading={isLoading}
				fetch={fetchData}
			>
				<h2 className="label mb-3">{getLangText('customerBasketAgreementLabel4')}</h2>
				<Ckeditor
					className="big-min-height"
					value={data || ''}
					setValue={(field, value) => setData(value)}
					label={getLangText('contentsLabel')}
					placeholder={getLangText('typePolicyPrivacyTextLabel')}
				/>
			</RegulationWrapper>
		</>
	);
};

export default Privacy;
