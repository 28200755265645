import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {addNewLiveModalToggleVisibility} from "../addNewLive/NewLiveModalActions.js";
import useList from "../../../../src_shared/list/useList.js";
import ListActions from "./ListActions.js";
import {getStateRoot} from "./listReducer.js";
import Pagination from "../../shared/components/pagination/Pagination.jsx";
import SearchInput from "../../shared/components/form/SearchInput.jsx";
import Table from "./components/table/Table.jsx";
import useLang from "../../../../src_shared/hooks/useLang.js";
import {Helmet} from "react-helmet";
import FirstStepPage from "../../../../src_shared/components/ui/FirstStepPage/FirstStepPage";
import ComponentLoader from "../../shared/components/componentLoader/ComponentLoader";
import {FIRST_PAGE_LIVES} from "../../shared/enums/TutorialVideoStatsTypes";

export const firstPageBenefits = [
    {id: 0, title: 'sendNotificationsOneClickLabel', desc: 'sendNotificationsOneClickSubLabel'},
    {id: 1, title: 'displayCommentsLabel', desc: 'displayCommentsSubLabel'},
    {id: 1, title: 'addProductsFromStorageOrWithoutLabel', desc: 'addProductsFromStorageOrWithoutSubLabel'},
]

const LiveList = () => {

    const dispatch = useDispatch();
    const {getLangText} = useLang();
    const {first_step_live} = useSelector((state) => state.session.userData);

    const {
        pagination: {
            changePage,
            getPagination,
            itemsPerPage,
            nextPage,
            prevPage,
            totalItems,
            updateItemsPerPage
        },
        list: {items, isWaiting, isFirstStepPage},
        search,
        sort: {updateSortOptions, getSortClass},
    } = useList({
        ListActions,
        getStateRoot,
    });

    if (isWaiting && !!+first_step_live) return <ComponentLoader />

    return (
        <div className="live-list-wrapper table-list-wrapper sm:order-none order-3">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getLangText('liveListsTitle')} - Selmo</title>
            </Helmet>
            {isFirstStepPage ?
                <FirstStepPage
                    title="liveListsTitle"
                    shortDesc="sendNotificationsOneClickLabel"
                    icon="broadcast-icon"
                    fullDesc="createOrdersDuringTheLiveBroadcastLabel"
                    buttonClick={() => dispatch(addNewLiveModalToggleVisibility(true))}
                    headerButtonTitle="liveListAddLiveButton"
                    mainButtonTitle="createFirstLiveLabel"
                    benefits={firstPageBenefits}
                    videoStatsName={FIRST_PAGE_LIVES}
                /> :
                <>
                    <div className="default-shadow-box pb-0">
                        <div className="header">
                            <div>
                                <div className="title">
                                    {getLangText('liveListsTitle')}
                                    {totalItems > 0 && <div className="badge">{totalItems}</div>}
                                </div>
                                <div className="desc">
                                    {getLangText('liveListSubTitle')}
                                </div>
                            </div>
                            <SearchInput
                                placeholder={getLangText('searchPlaceholder')}
                                search={search}
                            />
                            <button
                                type="submit"
                                className="button primary small-size"
                                onClick={() => dispatch(addNewLiveModalToggleVisibility(true))}
                            >
                                {getLangText('liveListAddLiveButton')}
                            </button>
                        </div>
                        <Table
                            isWaiting={isWaiting}
                            items={items}
                            updateSortOptions={updateSortOptions}
                            getSortClass={getSortClass}
                        />
                    </div>
                    <Pagination
                        pages={getPagination()}
                        items={items}
                        itemsPerPage={itemsPerPage}
                        getLangText={getLangText}
                        totalItems={totalItems}
                        onPrevPage={prevPage}
                        onChangePage={changePage}
                        onNextPage={nextPage}
                        onChangeItemsPerPage={updateItemsPerPage}
                        classes={{
                            prevIcon: 'icon-chevron left',
                            nextIcon: 'icon-chevron right',
                        }}
                        showItemsPerPageSelect
                    />
                </>
            }
        </div>
    );
};

export default LiveList;
