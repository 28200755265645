import React, {useRef} from 'react';
import useLang from "../../../../../../src_shared/hooks/useLang";
import useDropdown from "../../../../shared/hooks/useDropdown";
import {useDispatch} from "react-redux";
import ListActions from "../ListActions";

const MobileSort = ({sortBy, sortDir}) => {

    const buttonRef = useRef(null)
    const dropdownRef = useRef(null)
    const {getLangText} = useLang();
    const dispatch = useDispatch();
    const hideDropdown = () => setOpen(false)

    const {open, setOpen} = useDropdown(buttonRef, dropdownRef, hideDropdown);

    const onButtonClick = (value, dir = 'desc') => {
        setOpen(false);
        dispatch(ListActions.callSort(value, dir))
    }

    return (
        <div ref={dropdownRef} className="react-dropdown position-relative mr-2 sort-button">
            <button
                className={`dropdown-toggle mr-0 tooltip-parent ${open ? 'show' : ''}`}
                onClick={() => setOpen(!open)}
                ref={buttonRef}
            >
                <div className="tooltip-content">
                    {getLangText('sortLabel')}
                </div>
                <i className="icon-sort"/>
            </button>
            {open &&
                <div className="dropdown-menu show">
                    <button
                        type="button"
                        className={`custom-option ${sortBy === 'reserve_list_sum' ? 'active' : ''}`}
                        onClick={() => onButtonClick('reserve_list_sum')}
                    >
                        {getLangText('sortByReserveList')}
                    </button>
                    <button
                        type="button"
                        className={`custom-option ${(sortBy === 'date' && sortDir === 'desc') ? 'active' : ''}`}
                        onClick={() => onButtonClick('date')}
                    >
                        {getLangText('sortByDateDiscLabel')}
                    </button>
                    <button
                        type="button"
                        className={`custom-option ${(sortBy === 'date' && sortDir === 'asc') ? 'active' : ''}`}
                        onClick={() => onButtonClick('date', 'asc')}
                    >
                        {getLangText('sortByDateAscLabel')}
                    </button>
                </div>
            }
        </div>
    );
};

export default MobileSort;
