import React, {useEffect, useState} from 'react';
import TrackingInput from "./components/TrackingInput";
import {services} from "../../../RestServices";
import useLang from "../../../../src_shared/hooks/useLang";
import ServerImage from "../../shared/components/serverImage/ServerImage";
import {convertToUserTimeZone} from "../../shared/helpers/dateHelpers";
import Loader from "../../../../src_shared/components/loader/Loader";
import useFetchData from "../../../../src_shared/hooks/useFetchData";
import api from "../../../../services/axios/axios";
import {selmoUrl} from "../../../../src_shared/api/api";
import {getGETParamsUrl} from "../../../../src_shared/list/ListActions";

const TrackingPage = ({match}) => {
    const {trackingId} = match.params;
    const {getLangText} = useLang();

    const [isLoading, setIsLoading] = useState(!!trackingId);
    const [data, setData] = useState({});

    const fetchData = async () => {
        setIsLoading(true);

        try {
            const response = await api.get(`${selmoUrl}/${services.API_CART_LABEL_TRACKING}/${trackingId}`)
            setData(response.data);

        } catch (e) {

        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (trackingId) {
            fetchData()
        }
    }, [trackingId]);

    return (
        <div className="sm:py-20 py-10 lg:px-0 px-6 relative min-h-[100vh] bg-[#f9fafb]">
            <img
                className="absolute left-0 top-0 w-full h-full object-cover max-h-[300px]"
                width={650}
                height={530} src="/assets/images/svg/background-tracking.svg" alt="background color"
            />
            <div className="max-w-6xl mx-auto relative z-[1]">
                <img
                    className="h-[28px] mb-sm-5 mb-3"
                    height={28}
                    src="/assets/images/selmo-logo.svg"
                    alt=""
                />
                <TrackingInput
                    data={data}
                    trackingId={trackingId}
                    isLoading={isLoading}
                />
                <div class="grid sm:grid-cols-5 mt-10 gap-6">
                    <div className="sm:col-span-3 sm:order-1 order-2">
                        <div className="bg-[#fff] shadow-default-small rounded-[12px] sm:rounded-[20px] px-6 sm:px-12 py-6 sm:py-10 relative">
                            <div className="font-bold text-xl mb-4">
                                {getLangText('shipmentHistory')}
                            </div>
                            {isLoading ?
                                <div className="space-y-5">
                                    {[...Array(5)].map((_, index) => (
                                        <div
                                            key={index}
                                            className="flex">
                                            <div
                                                className="animate-pulse h-[32px] bg-gray-200 rounded-full dark:bg-gray-700 w-[32px] mr-1"/>
                                            <div className="grow">
                                                <div
                                                    className="animate-pulse h-[24px] bg-gray-200 rounded-md dark:bg-gray-700 w-[240px]"/>
                                                <div
                                                    className="animate-pulse h-[20px] mt-2 bg-gray-200 rounded-md dark:bg-gray-700 w-[200px]"/>
                                            </div>
                                        </div>
                                    ))}
                                </div> :
                                <div>
                                    {data?.items?.length > 0 ?
                                        <div className="employee-history tracking-history">
                                            {data?.items?.map((item, index) => (
                                                <div
                                                    key={item.id}
                                                    className={`flex ${index === 0 && !item.end_status ? 'blue-border' : ''}`}
                                                >
                                                    <div
                                                        className={`z-[1] border-[2px] text-xs text-[#fff] w-[32px] flex items-center justify-center h-[32px] min-w-[32px] rounded-full overflow-hidden mr-2 border-[#fff] ${(index === 0 && !item.end_status) ? 'bg-[#2464EB] shadow-[0_0_1px_1px_#2464EB]' : 'bg-[#34D299] shadow-[0_0_1px_1px_#34D299]'}`}>
                                                        {(index === 0 && !item.end_status) ?
                                                            <i class="icon-wysylka text-sm"/> :
                                                            <i class="icon-tick"/>
                                                        }
                                                    </div>
                                                    <div className="">
                                                        <div
                                                            className="font-bold leading-[22px]">{item.description}</div>
                                                        <div
                                                            className="text-[#9CA3AF] text-sm font-medium">
                                                            {item.city && `${item.city}, `}
                                                            {convertToUserTimeZone(item.date).format('DD.MM.YYYY HH:mm:ss')}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div> :
                                        <div className="text-center py-6">
                                            <div className="font-bold mb-2">
                                                {getLangText('error')}
                                            </div>
                                            <div className="font-medium whitespace-pre-wrap text-sm text-desc">
                                                {getLangText('noPackageHistory')}
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    <div className="sm:col-span-2 sm:order-2 order-1">
                        <a
                            href="https://onelink.to/selmo?utm_source=selmo&utm_medium=panel&utm_campaign=panel_tracking"
                            target="_blank"
                            className="bg-[#1C37D1] w-full hover:bg-[#132dbf] transition-all hover:no-underline rounded-[12px] items-center flex flex-col p-9 sm:min-h-[400px]">
                            <div className="text-center">
                                <img
                                    className="h-[26px] mx-auto mb-3 mt-3"
                                    src="/assets/images/selmo-white.svg" alt=""/>
                                <div
                                    className="text-[22px] whitespace-pre-wrap leading-[30px] text-[#fff] font-bold text-with-red-box"
                                    dangerouslySetInnerHTML={{__html: getLangText('dontMissLiveBroadcasts')}}
                                />
                                <div
                                    className="inline-block bg-[#fff] rounded-[22px] font-bold text-[#1C37D1] py-2 px-3 my-3">
                                    {getLangText('downloadNow')}
                                </div>
                            </div>
                            <img
                                className="mt-auto -mb-9"
                                src="/assets/images/multiLanguage/pl/tracking/phone.png" alt=""/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrackingPage;