import React from 'react';
import {NavLink, useHistory} from "react-router-dom";
import useLang from "../../../src_shared/hooks/useLang";
import {Helmet} from "react-helmet";
import {RouterPaths} from "../../routes/RouterPath";
import Loader from "../../../src_shared/components/loader/Loader";
import useSubmitData from "../../../src_shared/hooks/useSubmitData";
import {services} from "../../RestServices";
import useCreatePost from "./list/components/useCreatePost";

const tabs = [
    {id: 0, path: RouterPaths.SalesPost, label: 'addedPosts'},
    {id: 1, path: RouterPaths.SalesPostsStats, label: 'salesStatistics'},
    {id: 3, path: RouterPaths.SalesPostsHowItWorks, label: 'paymentsHowItWorksLabel'},
]

const SalesPostWrapper = ({title, children}) => {
    const {getLangText} = useLang();

    const {createPost, isWaitingForPost} = useCreatePost()

    return (
        <div className={`settings-page sub-settings-page loader-parent list-with-tabs separated-box rounded-b-lg`}>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getLangText('title')}</title>
            </Helmet>
            <div className="tabs-header mb-3 shadow-default">
                <div className="flex items-center px-[18px] pt-[18px]">
                    <div className="page-title p-0">
                        {getLangText(title)}
                    </div>
                    <button
                        onClick={createPost}
                        type="button"
                        className={`button primary small-size ml-auto ${isWaitingForPost ? 'loading-button' : ''}`}
                    >
                        {isWaitingForPost && <Loader isLoading/>}
                        {getLangText('addPost')}
                    </button>
                </div>
                <div
                    style={{borderBottom: 0, borderRadius: 5}}
                    className="tabs-wrapper rounded-b-[5px]"
                >
                    {tabs.map((i) => (
                        <NavLink
                            exact
                            to={i.path}
                            key={i.id}
                            activeClassName="active"
                        >
                            {getLangText(i.label)}
                        </NavLink>
                    ))}
                </div>
            </div>
            {children}
        </div>
    );
};

export default SalesPostWrapper;
