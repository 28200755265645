import React, {useEffect, useState} from 'react';
import useLang from "../../../../../src_shared/hooks/useLang";
import BlueMediaWrapper from "../BlueMediaWrapper";
import SwitchField from "../../../../../src_shared/components/form/switchField/SwitchField";
import SelectField from "../../../../../src_shared/components/form/selectField/SelectField";
import useFormLocal from "../../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../../RestServices";
import Field from "../../../../../src_shared/components/form/field/Field";
import CopyButton from "../../../../../src_shared/components/form/copyButton/CopyButton";
import {useSelector} from "react-redux";
import SettingsBlueMediaModel from "../../../../../modules/models/settings/SettingsBlueMediaModel";
import {CommissionPriceTypes} from "../../../../app/shared/enums/CommissionPriceTypes";
import ComponentLoader from "../../../../../src_shared/components/ui/componentLoader/ComponentLoader";
import {SHOP_BAZAR_SHOP_ID, TEST_ACCOUNT_SHOP_ID, TIM_SHOP_ID} from "../../../../../src_shared/enums/TrustedShopsIds";

const BlueMediaConfiguration = () => {
    const {getLangText} = useLang();
    const {userData} = useSelector((state) => state.session);
    const [shouldSave, setShouldSave] = useState(false);

    const [{
        onSave,
        isWaiting,
        getFieldProps,
        isLoading,
        values,
        setValue,
    }] = useFormLocal({
        rest: services.API_SETTINGS_BLUEMEDIA,
        model: SettingsBlueMediaModel,
    });

    const bmPriceActiveSetValue = (field, value) => {
        setValue(field, value)
        if (!+value) {
            setShouldSave(true);
        }
    }

    useEffect(() => {
        if (shouldSave) {
            onSave()
            setShouldSave(false)
        }
    }, [shouldSave])

    return (
        <BlueMediaWrapper>
            {(isLoading || isWaiting) ?
                <ComponentLoader/> :
                <div className="mt-sm-3">
                    <div className="row small-padd-row">
                        <div className="col-md-7">
                            <div className="settings-page blue-media-settings-page">
                                <div className="section-header">
                                    <div className="title">Konfiguracja Autopay</div>
                                    <div className="desc">Podłącz płatności natychmiastowe i zwiększ konwersję
                                        swojego
                                        sklepu
                                    </div>
                                </div>
                                <div className="settings-form mb-3">
                                    <div className="steps-wrapper full-filled-numbers">
                                        <div className="step-group">
                                            <div className="step">
                                                <div className="step-header flex-wrap">
                                                    <div className="d-flex">
                                                        <div className="number">1</div>
                                                        <div className="mb-3">
                                                            <div className="title">
                                                                Klknij w poniższy przycisk
                                                                "{getLangText('settings_integrations_fakturownia_description_4')}"
                                                            </div>
                                                            <div className="sub-title">W formularzu rejestracyjnym
                                                                uzupełnij
                                                                wymagane pola
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="w-100 pl-4 ml-3">
                                                        <a href="https://platnosci.bm.pl/7e0ffaef51b9cec28803deb4c8147b3a0720857bef86e6573b7039787be99374/bo"
                                                           target="_blank"
                                                           className="button primary w-100 text-center py-3 small-text">{getLangText('settings_integrations_fakturownia_description_4')}</a>
                                                    </div>
                                                </div>
                                                <div className="step-body pb-2">
                                                </div>
                                            </div>
                                            <div className="step">
                                                <div className="step-header">
                                                    <div className="number">2</div>
                                                    <div className="title">
                                                        {getLangText('settings_payments_bluemedia_first_step')}
                                                    </div>
                                                </div>
                                                <div className="step-body">
                                                    <div className="form-group with-copy-btn mt-3">
                                                        <div className="form-control">
                                                            <div className="short-link flex-grow-1">
                                                                {`app.selmo.io/sklep_bm/${userData.name_url}-${userData.id}/`}
                                                            </div>
                                                            <CopyButton
                                                                copyText={`app.selmo.io/sklep_bm/${userData.name_url}-${userData.id}/`}
                                                                copyButtonText={getLangText('copyButton')}
                                                                copiedButtonText={getLangText('copiedButton')}
                                                                withoutTooltip={true}
                                                                buttonClassName="border-button "
                                                            />
                                                        </div>
                                                    </div>
                                                    <a href="/assets/images/settings/bluemedia-link.jpeg"
                                                       target="_blank"
                                                       className="link-info mt-2">
                                                        <img alt="gdzie mam wkleić link?" className="w-100"
                                                             style={{borderRadius: '5px'}}
                                                             src="/assets/images/bm/bm-05.svg"/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="step d-none">
                                                <div className="step-header">
                                                    <div className="number">3</div>
                                                    <div
                                                        className="title">{getLangText('settings_payments_bluemedia_create_account')}<img
                                                        src="/assets/images/payments/bluemedia.svg" alt="Autopay"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="step">
                                                <div className="step-header">
                                                    <div className="number">3</div>
                                                    <div
                                                        className="title">{getLangText('settings_payments_verification_process')}</div>
                                                </div>
                                                <div className="step-body">
                                                    <div className="form-info-box small-line-height w-100">
                                                        <div>
                                                            <i className="icon-info-c"/>
                                                        </div>
                                                        <div>
                                                            <div
                                                                className="title">{getLangText('settings_payments_verification_question')}</div>
                                                            <div
                                                                className="desc">{getLangText('settings_payments_verification_time')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="step">
                                                <div className="step-header">
                                                    <div className="number">4</div>
                                                    <div>
                                                        <div className="title">Skopiuj i wklej poniżej dane z panelu
                                                           Autopay
                                                        </div>
                                                        <div className="sub-title">
                                                            Dane znajdziesz po zalogowaniu się tutaj:
                                                            <a href="https://oplacasie.bm.pl/admin/transaction/"
                                                               target="_blank"
                                                               className="link-info bigger d-inline-block">
                                                                Panel administracyjny</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="step-body border-bottom-0">
                                                    <div className="data-row-body">
                                                        <Field
                                                            {...getFieldProps('bm_service_id')}
                                                            className="flex-grow-1 mr-2"
                                                            label={getLangText('input_label_bluemedia_service_id')}
                                                        />
                                                        <Field
                                                            {...getFieldProps('bm_shared_key')}
                                                            className="flex-grow-1 mr-0 ml-2"
                                                            label="Klucz"
                                                        />
                                                    </div>
                                                    <div className="d-flex flex-wrap blue-media-bottom-row">
                                                        <SwitchField
                                                            {...getFieldProps('bm_active')}
                                                            label={getLangText('active_payment')}
                                                            className="switch-form inline-label big-label light-version mb-0 mt-0"
                                                        />
                                                        <button
                                                            type="submit"
                                                            onClick={onSave}
                                                            className={`button primary ml-auto ${isWaiting ? 'loading-button' : ''}`}
                                                        >
                                                            {/*<Loader isLoading={isWaiting}/>*/}
                                                            {getLangText('saveButton')}
                                                        </button>
                                                    </div>
                                                    <div className="form-info-box small-line-height w-100 mt-3">
                                                        <div>
                                                            <i className="icon-info-c"/>
                                                        </div>
                                                        <div>
                                                            <div className="title">Uwaga!</div>
                                                            <div className="desc">Aktywuj płatność dopiero po
                                                                pozytywnej
                                                                weryfikacji
                                                                konta.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="step-group mt-3">
                                            <div className="step optional-step">
                                                <div className="step-header">
                                                    <SwitchField
                                                        {...getFieldProps('bm_price_active')}
                                                        label="Dodaj opłatę za płatność dla klienta"
                                                        className="switch-form inline-label big-label light-version mb-0 mt-0"
                                                        setValue={bmPriceActiveSetValue}
                                                    />
                                                    <div className="status-bg grey ml-auto small-status">Opcjonalnie
                                                    </div>
                                                </div>
                                                {!!+values.bm_price_active &&
                                                    <div className="step-body mt-0" id="bm-price-toggle">
                                                        <div className="form-label">
                                                            Ustaw kwotę opłaty
                                                        </div>
                                                        <div className="data-row-body align-items-start mt-0">
                                                            {/*<div*/}
                                                            {/*    className="form-group small-width form-group-addon mb-0">*/}
                                                            {/*    <label className="control-label"></label>*/}
                                                            {/*    <input*/}
                                                            {/*        type="number"*/}
                                                            {/*        step=".01"*/}
                                                            {/*        className="form-control"*/}
                                                            {/*        {...getFieldProps('bm_price')}*/}
                                                            {/*        onChange={(e) => setValue('bm_price', e.target.value)}*/}
                                                            {/*    />*/}
                                                            {/*    <SelectField*/}
                                                            {/*        {...getFieldProps('bm_price_type')}*/}
                                                            {/*        className="default-size mb-0 mr-0"*/}
                                                            {/*        options={CommissionPriceTypes.map((i) => ({*/}
                                                            {/*            ...i,*/}
                                                            {/*            label: getLangText(i.label) || i.label*/}
                                                            {/*        }))}*/}
                                                            {/*        extraProps={{*/}
                                                            {/*            isSearchable: false*/}
                                                            {/*        }}*/}
                                                            {/*    />*/}
                                                            {/*</div>*/}
                                                            <Field
                                                                {...getFieldProps('bm_price')}
                                                                showLabel={false}
                                                                className="mb-0"
                                                                addon="%"
                                                                type="number"
                                                                extraProps={{
                                                                    required: true
                                                                }}
                                                            />
                                                            <button
                                                                type="submit"
                                                                onClick={onSave}
                                                                style={{height: '45px'}}
                                                                className={`button primary ${isWaiting ? 'loading-button' : ''}`}
                                                            >
                                                                {/*<Loader isLoading={isWaiting}/>*/}
                                                                {getLangText('saveButton')}
                                                            </button>
                                                        </div>
                                                        <div className="form-info-box small-line-height w-100 mt-3">
                                                            <div>
                                                                <i className="icon-info-c"/>
                                                            </div>
                                                            <div>
                                                                <div className="title">Jak to działa?</div>
                                                                <div className="desc">
                                                                    Klient dopłaci dodatkową kwotę za wybór szybkich
                                                                    płatności. <br/>
                                                                    Możesz ustawić stałą kwotę w PLN lub procent od
                                                                    wartości
                                                                    zamówienia
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div
                                className="default-shadow-box help-box bigger-y-padd payment-benefits-wrapper">
                                <h2 className="title mb-1">
                                    Potrzebujesz pomocy?
                                </h2>
                                <div className="help-box__description">
                                    Napisz do nas lub zadzwoń, aby uzyskać odpowiedzi na Twoje pytania
                                </div>
                                <div className="help-box__buttons">
                                    <a href="https://www.facebook.com/selmo.io/" target="_blank"
                                       className="button border-button">
                                        <i className="icon-messenger-2-f"></i>
                                        Napisz na Messengerze
                                    </a>
                                    <a className="button border-button mt-2" href="tel:123457755">
                                        Zadzwoń: +12 345 77 55
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </BlueMediaWrapper>
    );
};

export default BlueMediaConfiguration;
