import React, {useEffect, useState} from 'react';
import axios from "axios";
import {addNewMessageToConversation, setSendStatusMessage} from "../../../chatBox/ChatBoxActions";
import {batch, useDispatch} from "react-redux";
import Loader from "../../../loader/Loader.jsx";
import {addOrderModalToggleVisibility} from "../../AddOrderModalActions";
import moment from "moment";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {selmoUrl} from "../../../../../../src_shared/api/api";
import SendVoiceMessage from "../../../../orders/details/sendVoiceMessage/SendVoiceMessage";
import {updateItemInList} from "../../../chatList/ChatListActions";
import useLang from "../../../../../../src_shared/hooks/useLang";
import {showAlert} from "../../../../shared/components/alert/AlertActions";
import api from "../../../../../../services/axios/axios";
import CopyButton from "../../../../../../src_shared/components/form/copyButton/CopyButton";
import {convertToUserTimeZone} from "../../../../shared/helpers/dateHelpers";

const Summary = ({activeConversation, lastId, setStatusVisible, messageScrollRef, orderData, summaryWaiting}) => {
	const dispatch = useDispatch();
	const [isWaiting, setIsWaiting] = useState(false);
	const [isWithLink, setIsWithLink] = useState(true)
	const [inputValueSummary, setInputValueSummary] = useState('')
	const [inputCurrentCartText, setInputCurrentCartText] = useState('')
	const [loaderType, setLoaderType] = useState('currentCart')
	const {getLangText} = useLang();

	useEffect(() => {
		setInputValueSummary(orderData.order_summary)
		setInputCurrentCartText(orderData.current_cart_text)
	}, [orderData])

	const sendMessage = (value, type) => {
		setLoaderType(type)
		const preparedId = lastId ? lastId + 1 : 0;

		const preparedData = {
			message: value,
			recipient: activeConversation.user_id,
			order_id: type === 'currentCart' ? null : orderData.id,
			with_link: type === 'currentCart' ? null : isWithLink,
			instagramMessage: !!activeConversation.instagram_conversation_id
		}

		const preparedLastMessage = {
			...activeConversation,
			last_message: value,
			last_update: convertToUserTimeZone().format("YYYY-MM-DD HH:mm:ss"),
		}

		batch(() => {
			dispatch(addNewMessageToConversation({
				id: preparedId,
				date: convertToUserTimeZone().format("YYYY-MM-DD HH:mm:ss"),
				is_sent: false,
				sender: activeConversation.page_id,
				order_link: type === 'currentCart' ? null : (isWithLink ? orderData.link : null),
				...preparedData,
			}))
			dispatch(updateItemInList(preparedLastMessage, activeConversation.id))
		})

		setStatusVisible(true)
		setTimeout(() => {
			messageScrollRef.current.scrollIntoView(
				{
					behavior: 'smooth',
				})
		})

		const setStatusDelay = () => {
			setTimeout(() => {
				setSendStatusMessage(preparedId, true)
			}, 700)
		}

		setIsWaiting(true)
		api.post(`${selmoUrl}/api/message`, {
			...preparedData
		})
			.then(() => {
				batch(() => {
					dispatch(setSendStatusMessage(preparedId, 'filled'))
					dispatch(showAlert( type === 'currentCart' ? 'basketStateSentLabel' : 'summarySentLabel', ''))
					if (type === 'summary') {
						dispatch(addOrderModalToggleVisibility(false))
					}
					// dispatch(addOrderModalToggleVisibility(false))
				})
				setStatusDelay()
			})
			.catch(() => {
				dispatch(setSendStatusMessage(preparedId, false))
			})
			.finally(() => {
				setIsWaiting(false)
				setStatusDelay()
			})
	}

	if (summaryWaiting) {
		return <Loader/>
	}

	return (
		<>
			<div className="form-group with-copy-btn">
				<label className="control-label">{getLangText('orderLinkLabel')}</label>
				<div className="form-control">
					<a href={orderData.link} target="_blank">{orderData.link}</a>
					<CopyButton
						copyText={orderData.link}
						copyButtonText={getLangText('copyButton')}
						copiedButtonText={getLangText('copiedLabel')}
						buttonClassName="border-button copied"
					/>
				</div>
			</div>
			<div className="form-group">
				<label className="control-label">{getLangText('currentBasketStateLabel')}</label>
				<div className="border-area">
					{isWaiting && loaderType === 'currentCart' &&
						<Loader/>
					}
					<textarea
						className="form-control"
						name="client-message"
						rows="10"
						value={inputCurrentCartText}
						onChange={(e) => setInputCurrentCartText(e.target.value)}
					/>
					<div className="bottom-part">
						<button
							type="button"
							className="send-button"
							onClick={() => sendMessage(inputCurrentCartText, 'currentCart')}
						>
							{getLangText('sendButton')}
						</button>
					</div>
				</div>
			</div>
			<div className="form-group">
				<label className="control-label">{getLangText('orderSummaryLabel')}</label>
				<div className="border-area">
					{isWaiting && loaderType === 'summary' &&
						<Loader/>
					}
					<textarea
						className="form-control"
						name="client-message"
						rows="10"
						value={inputValueSummary}
						onChange={(e) => setInputValueSummary(e.target.value)}
					/>
					<div className="bottom-part">
						<div className="form-group switch-form inline-label">
							<input
								type="checkbox"
								className="bot-status-checkbox"
								checked={isWithLink}
								onChange={() => setIsWithLink(!isWithLink)}
							/>
							<label className="switch">
								<span className="slider"/>
							</label>
							<div className="control-label font-weight-bold">
								{getLangText('orderLinkLabel')}
								<OverlayTrigger
									placement='top'
									overlay={
										<Tooltip id="link-to-client-info">
											{getLangText('orderLinkTooltipLabel')}
										</Tooltip>
									}
								>
									<i className="icon-info"/>
								</OverlayTrigger>
							</div>
						</div>
						<button
							type="button"
							className="send-button"
							onClick={() => sendMessage(inputValueSummary, 'summary')}
						>
							{getLangText('sendButton')}
						</button>
					</div>
				</div>
			</div>
			<SendVoiceMessage
				inMessenger
				values={orderData}
				menuPlacement="bottom"
				className="mb-2"
			/>
		</>
	);
};

export default Summary;
