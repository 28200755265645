import Model from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

class SettingsPrestaShopModel extends Model {
	getModel() {
		return {
			url: '',
			api_key: '',
			secret_key: '',
			active: '0',
		};
	}

	getValidators() {
		return {
			url: ValidationError.notEmpty,
			api_key: ValidationError.notEmpty,
			secret_key: ValidationError.notEmpty,
			active: ValidationError.skip,
		};
	}
	buildDTO(data) {
		return {
			url: data.url,
			api_key: data.api_key,
			secret_key: data.secret_key,
			active: data.active,
		}
	}
}

export default new SettingsPrestaShopModel();
