import React from 'react';
import useLang from "../../../../../../../../../src_shared/hooks/useLang";

const ProductError = () => {
    const {getLangText} = useLang();

    return (
        <div
            className="form-info-box red align-items-center small-line-height w-100 mb-0">
            <div>
                <i className="icon-info-c"/>
            </div>
            <div className="title">{getLangText('selectProductWhichYouSellingLabel')}</div>
        </div>
    );
};

export default ProductError;
