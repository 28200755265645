import {getStateRoot, prefix} from "./sharedEditProductReducer";

export class SharedEditProductActions {
	constructor() {
		this.prefix = prefix;
	}

	setProductFormView(view) {
		return {
			type: `${this.prefix}SET_PRODUCT_FORM_VIEW`,
			view,
		}
	}

	showExtraAddNewButton(toggle) {
		return {
			type: `${this.prefix}SHOW_EXTRA_ADD_NEW_BUTTON`,
			toggle,
		}
	}
}

export const getInstance = () => new SharedEditProductActions({
	getStateRoot,
});

export default getInstance();
