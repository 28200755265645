import React from 'react';
import {Route} from 'react-router-dom';
import {useSelector} from "react-redux";
import NoPermissionPage from "../app/shared/NoPermissionPage";

export const checkRoles = (userPermissions, allowedPermissions) => (
	userPermissions?.some((x) => allowedPermissions?.includes(x))
);

export const PermissionRoute = ({
									Component,
									noPermissionComponent,
									allowedPermissions,
									children,
									...rest
								}) => {
	const {permissions} = useSelector((state) => state.session.userData);

	const permissionsMatched = checkRoles(permissions, allowedPermissions);

	if (!permissionsMatched) {
		return noPermissionComponent()
	}

	if (!Component) {
		return (
			<Route {...rest}>
				{children}
			</Route>
		);
	}

	return (
		<Route
			component={Component}
			{...rest}
		/>
	);
};

PermissionRoute.defaultProps = {
	noPermissionComponent: () => <NoPermissionPage/>
}
export default PermissionRoute;
