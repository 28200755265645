import React from 'react';
import {Helmet} from "react-helmet";
import BoxesStats from "./list/List";
import ChartsStats from "./charts/Charts";
import useLang from "../../../src_shared/hooks/useLang";

const StatsNewPage = () => {
	const {getLangText} = useLang();

	return (
		<div className="stats-wrapper">
			<Helmet>
				<meta charSet="utf-8"/>
				<title>{getLangText('asideStatsLabel')} - Selmo</title>
			</Helmet>
			<BoxesStats />
			<ChartsStats />
		</div>
	);
};

export default StatsNewPage;
