import React, {useRef, useState} from 'react';
import useLang from "../../../../../../../src_shared/hooks/useLang";
import EditDropdown from "./EditDropdown";
import useRemoveData from "../../../../../../../src_shared/hooks/useRemoveData";
import {services} from "../../../../../../RestServices";
import ConfirmModal from "../../../../../live/details/default/summary/export/ConfirmModal";

const TemplatesMessages = ({data, setShowModal, setInputValue, hideDropdown, fetchData, textAreaRef}) => {
    const {getLangText} = useLang();
    const [visibleDropdown, setVisibleDropdown] = useState(null)
    const scrollContainer = useRef(null);
    const menuRef = useRef(null);
    const onMessageClick = (message) => {
        setInputValue(message)
        hideDropdown();
        textAreaRef?.current?.focus();
    };

    const [{
        openModal: openRemoveModal,
        hideModal: hideRemoveModal,
        confirmModalVisible,
        isDeleting,
        removeItem,
        itemToRemove
    }] = useRemoveData({
        rest: services.API_MESSENGER_TEMPLATE,
        afterSubmit: () => fetchData(),
    });

    return (
        <div
            ref={scrollContainer}
            className="space-y-5 p-3 max-h-[400px] overflow-y-auto vertical-custom-scroll"
        >
            {!!data.length ?
                data?.map((item) => (
                    <div
                        key={item.id}
                        className="text-sm"
                    >
                        <div className="flex items-center">
                            <button
                                onClick={() => onMessageClick(item.text)}
                                type="button"
                                className="font-bold flex items-center mb-1"
                            >
                                <div className="flex items-center justify-center w-[18px] h-[18px] rounded-full bg-[#3861EA] mr-1">
                                    <i className="text-[white] no-underline icon-arrow-left text-[8px]" />
                                </div>
                               <div className="underline">{item.name}</div>
                            </button>
                            <EditDropdown
                                visible={visibleDropdown === item.id}
                                setVisible={setVisibleDropdown}
                                id={item.id}
                                menuRef={menuRef}
                                offsetY={15}
                                className="ml-auto -mt-2"
                            >
                                <div ref={menuRef}>
                                    <button
                                        type="button"
                                        className="custom-option"
                                        onClick={() => setShowModal(item.id)}
                                    >
                                        {getLangText('editButton')}
                                    </button>
                                    <button
                                        type="button"
                                        className="custom-option"
                                        onClick={() => openRemoveModal(item)}
                                    >
                                        {getLangText('removeButton')}
                                    </button>
                                </div>
                            </EditDropdown>
                        </div>
                        <div className="text-desc whitespace-break-spaces">{item.text}</div>
                    </div>
                )) :
                <div className="text-center p-3">
                    <div className="font-bold">{getLangText('noSavedTemplates')}</div>
                    <button
                        type="button"
                        onClick={() => setShowModal(0)}
                        className="button text-only with-icon lgrey add-order-table"
                    >
                        <i className="icon-plus"/>
                        {getLangText('settings_package_types_text_1')}
                    </button>
                </div>
            }
            {confirmModalVisible &&
                <ConfirmModal
                    dialogClassName="w-380"
                    title={getLangText('delete_modal_accept')}
                    SubTitle={() => <span dangerouslySetInnerHTML={{
                        __html: getLangText({
                            key: 'wantToRemoveTemplateLabel',
                            data: [itemToRemove.name]
                        })
                    }}/>}
                    saveButton={getLangText('removeButton')}
                    show={confirmModalVisible}
                    isWaiting={isDeleting}
                    onSubmit={removeItem}
                    hide={hideRemoveModal}
                    saveButtonClass="danger"
                />
            }
        </div>
    );
};

export default TemplatesMessages;
