import React, {useEffect} from 'react';
import {useDispatch} from "react-redux";
import Loader from "../../../../../../src_shared/components/loader/Loader";

const Summary = ({hideModal, generatedOrdersCount, waitingForDocument, onPrint, labels = true, summaryContent, ListActions}) => {

	const dispatch = useDispatch();

	useEffect(() => {
		return () => dispatch(ListActions.loadData());
	}, [])

	return (
		<div className="summary-generated-view">
			<button
				onClick={hideModal}
				type="button"
				className="button close-modal"
			>
				<i className="icon-cross"/>
			</button>
			{summaryContent(generatedOrdersCount, hideModal, waitingForDocument, labels, onPrint)}
		</div>
	);
};

Summary.defaultProps = {
	summaryContent: (generatedCount, hideModal, waiting, labels, onPrint) => (
		!!+generatedCount?.success_orders ?
			<>
				<div className="circle-icon">
					<i className="icon-tick"/>
				</div>
				<div className="title">Gotowe!</div>
				<div className="desc">
					Udało się Ci się wygenerować <strong>{generatedCount?.success_orders}/{generatedCount?.all_orders}</strong> {labels ? 'etykiet' : 'faktur'}
				</div>
				<div className="modal-footer">
					<button
						onClick={onPrint}
						type="button"
						className={`button primary ${waiting ? 'loading-button' : ''}`}
					>
						{waiting && <Loader isLoading />}
						Wydrukuj {labels ? 'etykiety' : 'faktury'}
					</button>
					<button
						onClick={hideModal}
						type="button"
						className="button border-button"
					>
						Zamknij
					</button>
				</div>
			</> :
			<>
				<div className="circle-icon red">
					<i className="icon-cross"/>
				</div>
				<div className="title">Ups!</div>
				<div className="desc">Nie udało Ci się wygenerować {labels ? 'etykiet' : 'faktur'}</div>
				<div className="modal-footer">
					<button
						onClick={hideModal}
						type="button"
						className="button border-button"
					>
						Zamknij
					</button>
				</div>
			</>
	)
}

export default Summary;
