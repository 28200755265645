import React from 'react';
import {
    ONBOARDING_ACCOUNT_TYPE_VIEW,
} from "../CreateShopFirstStep";
import useLang from "../../../../../src_shared/hooks/useLang";
import {useSelector} from "react-redux";

const CustomerType = ({setInternalView}) => {
    const {getLangText} = useLang();
    const {lang} = useSelector((state) => state.i18n);

    return (
        <div className="grow flex-col flex">
            <div className="flex flex-col grow-1 animate-tutorialSlideAndFadeIn">
                <button
                    type="button"
                    onClick={() => setInternalView(ONBOARDING_ACCOUNT_TYPE_VIEW)}
                    className="text-desc flex items-center font-bold text-sm mr-auto mt-3 sm:ml-3 sm:mb-0 mb-3 transition-all hover:text-[#575d68]">
                    <div className="flex items-center justify-center w-[32px] h-[32px] bg-[#F3F4F6] rounded-full mr-2">
                        <i className="icon-arrow-left text-xs"/>
                    </div>
                    {getLangText('backButton')}
                </button>
                <div className="max-w-[480px] m-auto">
                    <div className="font-bold text-lg">{getLangText('selectedShopClientLabel')}</div>
                    <div className="form-info-box w-100 my-3">
                        <div>
                            <i className="icon-info-c"/>
                        </div>
                        <div>
                            <div className="title">{getLangText('dontNeedToCreateAccountLabel')}</div>
                        </div>
                    </div>
                    <div className="text-sm font-bold">
                        {getLangText('howToCheckYourOrderStatusLabel')}
                    </div>
                    <div className="text-desc text-sm mb-3"
                         dangerouslySetInnerHTML={{__html: getLangText('textOnMessengerSelmoLinkLabel')}}/>
                </div>
            </div>
            <div className="sm:bg-[#f9fafb] grow flex flex-col items-center sm:min-h-[300px] sm:mt-0 mt-3 justify-center">
                <div className="max-w-[480px] w-full mx-auto animate-tutorialSlideAndFadeInDelay1 opacity-0">
                    <div className="flex flex-grow items-center">
                        <div className="grow">
                            <div className="flex items-center mb-2">
                                <div
                                    className="font-bold leading-[24px] grow text-lg">{getLangText('beUpToDateWithYourShopLabel')}</div>
                                <div
                                    className={`relative h-[50px] min-w-[50px] w-[50px] rounded-[10px] ml-3 sm:hidden`}>
                                    <img
                                        className="h-full w-full sm:w-auto object-cover rounded-[10px]"
                                        src={`/assets/images/multiLanguage/${lang}/app/apka-top.jpeg`}
                                        alt="preview"
                                    />
                                </div>
                            </div>
                            <div className="flex items-center">
                                <div className="mr-3">
                                    <i className="icon-tick-c text-[#5FA5FA] text-lg"/>
                                </div>
                                <div>
                                    <div className="text-desc font-medium text-sm">
                                        {getLangText('dontMissPromotionsLabel')}
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center">
                                <div className="mr-3">
                                    <i className="icon-tick-c text-[#5FA5FA] text-lg"/>
                                </div>
                                <div>
                                    <div className="text-desc font-medium text-sm">
                                        {getLangText('getNotificationsLabel')}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="sm:ml-auto sm:w-auto w-full sm:mt-0 mt-3.5 sm:block hidden">
                            <div
                                className={`relative sm:h-[70px] w-full sm:w-[70px] rounded-[10px]`}>
                                <img
                                    className="h-full w-full sm:w-auto object-cover rounded-[10px]"
                                    src={`/assets/images/multiLanguage/${lang}/app/apka-top.jpeg`}
                                    alt="preview"
                                />
                            </div>
                        </div>
                    </div>
                    <a
                        style={{
                            fontSize: 14,
                            borderRadius: 8,
                            padding: 16,
                        }}
                        target="_blank"
                        rel="noreferrer"
                        className="button primary w-full text-center medium-size mt-3 sm:mb-0 mb-4"
                        href={`https://onelink.to/selmo?utm_source=selmo&utm_medium=panel&utm_campaign=panel_onboarding`}
                    >
                        {getLangText('downloadApplicationLabel')}
                    </a>
                </div>
            </div>
        </div>
    );
};

export default CustomerType;
