import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {hideAlert} from "./AlertActions";
import useLang from "../../../../../src_shared/hooks/useLang";

export const AlertClassList = {
	success: 'alert-success',
	fail: 'alert-fail',
	info: 'alert-info',
}

const Alert = () => {

	const {alert} = useSelector((state) => state.shared.alert)
	const {getLangText} = useLang();

	const dispatch = useDispatch();

	useEffect(() => {
		const timeout = setTimeout(() => {
			if (alert.visibility) {
				dispatch(hideAlert())
			}
		}, 3000)
		return () => clearTimeout(timeout)
	}, [alert.visibility, alert.title])

	const getIconClass = () => {
		switch (alert.className) {
			case AlertClassList.fail:
				return 'icon-cross'
			case AlertClassList.info:
				return 'icon-info1'
			default:
				return 'icon-tick'
		}
	}

	if (!alert.visibility) {
		return null;
	}

	return (
		<div className={`alert selmo-react-alert ${alert.className}`}>
			<div className="top-part">
				<div className="icon">
					<i className={getIconClass()}/>
				</div>
				<div className="title">
					{getLangText(alert.title) || alert.title}
				</div>
				<button
					className="btn-close"
					onClick={() => dispatch(hideAlert())}
				/>
			</div>
			{alert.desc &&
				<div className="desc mb-0">
					{getLangText(alert.desc) || alert.desc}
				</div>
			}
		</div>
	);
};

export default Alert;
