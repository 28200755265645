import React from 'react';
import {Link, useLocation} from "react-router-dom";
import {Helmet} from "react-helmet";
import ClientsListViews from "../shared/enums/ClientsListViews";
import useLang from "../../../src_shared/hooks/useLang";

const ClientsWrapper = ({children, helmetTitle}) => {

	const {pathname} = useLocation();
	const {getLangText} = useLang();

	return (
		<div className={`settings-page sub-settings-page loader-parent list-with-tabs`}>
			<Helmet>
				<meta charSet="utf-8"/>
				<title>{helmetTitle}</title>
			</Helmet>
			<div className="tabs-wrapper">
				{ClientsListViews.map((i) => (
					<Link
						to={i.path}
						key={i.id}
						className={pathname === i.path ? 'active' : ''}
					>
						{getLangText(i.label)}
					</Link>
				))}
			</div>
			{children}
		</div>
	);
};

export default ClientsWrapper;
