import React, {useEffect} from 'react';
import {useLocation} from "react-router-dom";

const useScrollToFoundSection = () => {
    const location = useLocation();

    useEffect(() => {
        setTimeout(() => {
            if (location.hash) {
                const id = location.hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    document.querySelectorAll('.bg-[red]').forEach((el) => {
                        el.classList.remove('search-active-section');
                    });

                    element.classList.add('search-active-section');

                    const yOffset = -30;
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

                    window.scrollTo({top: y, behavior: 'smooth'});

                    const timer = setTimeout(() => {
                        element.classList.remove('search-active-section');
                    }, 3000);

                    return () => clearTimeout(timer);
                }
            }
        }, 300)
    }, [location]);
};

export default useScrollToFoundSection;
