import {combineReducers} from "redux";
import platformReducer from "./details/platform/platformReducer";
import liveDetailsReducer from "./details/default/liveDetailsReducer.js";
import newLiveModalReducer from "./addNewLive/newLiveModalReducer";
import assignModalReducer from "./details/default/clients/assignModal/assignModalReducer";
import exportReducer from "./details/default/summary/export/formReducer";
import definedVariantFormReducer from "./details/default/definedVariantsModal/formReducer";
import commentsReducer from "./details/default/comments/listReducer";
import newCommentsReducer from "./details/default/newComments/listReducer";
import productsListReducer from "./details/default/clients/productsList/listReducer";
import listReducer from "./list/listReducer";

export default combineReducers({
	platform: platformReducer,
	newLiveModal: newLiveModalReducer,
	details: liveDetailsReducer,
	assignModal: assignModalReducer,
	definedVariants: definedVariantFormReducer,
	export: exportReducer,
	comments: commentsReducer,
	newComments: newCommentsReducer,
	productsList: productsListReducer,
	list: listReducer
})
