import Model, {convertObjectProperties} from "../../../src_shared/modules/model/Model";

export class ShopSettingsMainPageModel extends Model {
	getModel() {
		return {
			banners: [],
		};
	}
	parseDTO(data) {
		return convertObjectProperties(
			data,
			{
			},
			{
				banners: [],
			}
		);
	}
	buildDTO(data) {
		return data.banners;
	}
}

export default new ShopSettingsMainPageModel();
