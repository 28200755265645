import React from 'react';
import useLang from "../../../../src_shared/hooks/useLang";

const Connected = ({data}) => {
    const {getLangText} = useLang();

    return (
        <div className="settings-page sub-settings-page partners-page mb-sm-4 instagram-connected-page"
             style={{borderTopLeftRadius: 0, borderTopRightRadius: 0}}>
            <div className="settings-form" style={{minHeight: "300px"}}>
                <section>
                    <div className="left-side">
                        <h2 className="label">
                            {getLangText('instagramIntegrationLabel')}
                        </h2>
                    </div>
                    <div className="form-col">
                        <div className="shop-info-wrapper">
                            <figure>
                                <img src={data?.extraInstagramInfo?.profile_picture_url || '/assets/images/default/default.jpeg'}
                                     alt={data.instagram_username}/>
                            </figure>
                            <div className="content">
                                <div>
                                    <div className="name">{data.instagram_username}</div>
                                    <div className="grey-txt">
                                        <i className="icon-tick-c" />
                                        {getLangText('settings_facebook_text_10')}&nbsp;
                                        <strong>{data.instagram_date}</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>

    );
};

export default Connected;
