export const ORDER_STATUS_ALL = 'all'
export const ORDER_STATUS_CREATED = 1
export const ORDER_STATUS_NOT_PAID = 5
export const ORDER_STATUS_TO_SEND = 2
export const ORDER_STATUS_OPEN_CART = 8
export const ORDER_STATUS_EDITED = 7
export const ORDER_STATUS_SENT = 3
export const ORDER_STATUS_FINISHED = 4
export const ORDER_STATUS_CANCELLED = 9


export const ORDER_STATUES = [
	{
		value: ORDER_STATUS_CREATED, label: 'statusCreatedLabel', color: 'blue',
	},
	{
		value: ORDER_STATUS_NOT_PAID, label: 'statusNotPaidLabel', color: 'red',
	},
	{
		value: ORDER_STATUS_TO_SEND, label: 'statusToSendLabel', color: 'orange',
	},
	{
		value: ORDER_STATUS_OPEN_CART, label: 'statusOpenCartLabel', color: 'light-blue',
	},
	{
		value: ORDER_STATUS_EDITED, label: 'statusEditedLabel', color: 'pink',
	},
	{
		value: ORDER_STATUS_SENT, label: 'statusSentLabel', color: 'purple',
	},
	{
		value: ORDER_STATUS_FINISHED, label: 'statusFinishedLabel', color: 'green',
	},
	{
		value: ORDER_STATUS_CANCELLED, label: 'statusCancelledLabel', color: 'cancelled',
	},
]

export const findOrderStatusById = (id) => ORDER_STATUES.find((status) => +status.value === +id);
