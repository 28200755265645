import React from 'react';
import {
    Collapse,
} from "@material-tailwind/react";
import useLang from "../../../../src_shared/hooks/useLang";
import CheckboxField from "../../../../src_shared/components/form/checkboxField/CheckboxField";
import {Link} from "react-router-dom";
import PhotoPreviewButton from "../../../../src_shared/components/ui/photoPreview/PhotoPreviewButton";
import VideoPreview from "../../../../src_shared/components/ui/photoPreview/VideoPreview";
import usePhotoPreview from "../../../../src_shared/components/ui/photoPreview/usePhotoPreview";
import {useSelector} from "react-redux";
import TutorialsVideo from "../../shared/enums/TutorialsVideo";

const CollapseStep = ({
                          title,
                          desc,
                          checked,
                          buttonTitle,
                          buttonLink,
                          videoStatsName,
                          previewSrc,
                          setOpen,
                          open,
                          id
                      }) => {
    const {showPreviewModal, hidePreviewModal, openPreviewModal} = usePhotoPreview();
    const {getLangText} = useLang();
    const {lang} = useSelector((state) => state.i18n);

    const previewClick = (e) => {
        e.stopPropagation();
        openPreviewModal();
    }

    const getVideoSource = () => {
        const matched = TutorialsVideo.find((i) => i.id === videoStatsName);

        return matched[`src_${lang}`] || null;
    }

    return (
        <>
            <button
                type="button"
                onClick={() => setOpen(id)}
                className={`flex items-start w-full p-3.5 transition-all rounded-xl ${open === id ? 'bg-[#F9FAFB]' : ''}`}
            >
                <CheckboxField
                    value={checked}
                    className="big-circle mb-0 sm:top-0 top-[-1px]"
                />
                <div className="grow">
                    <div className="sm:text-base text-sm flex items-center font-bold w-full text-left">
                        {getLangText(title)}
                        <i className="icon-arrows ml-auto text-[10px] text-[#9CA3AF]"/>
                    </div>
                    <Collapse open={open === id} className="pt-1.5 text-left">
                        <div className="text-desc text-sm leading-[23px] font-medium sm:flex items-start">
                            <div>
                                {getLangText(desc)}
                                <div>
                                    <Link
                                        className="button hidden sm:inline-block primary small-size mt-3"
                                        to={buttonLink}
                                    >
                                        {getLangText(buttonTitle)}
                                    </Link>
                                </div>
                            </div>
                            {!!getVideoSource() &&
                                <PhotoPreviewButton
                                    onClick={previewClick}
                                    className="sm:ml-2 w-[140px] min-w-[140px] sm:mt-0 mt-3.5"
                                    src={`/assets/images/multiLanguage/${lang}/tutorialPageBox/${previewSrc}.webp`}
                                />
                            }
                            <Link
                                className="button sm:hidden primary small-size mt-3 sm:w-auto w-full text-center"
                                to={buttonLink}
                            >
                                {getLangText(buttonTitle)}
                            </Link>
                        </div>
                    </Collapse>
                </div>
            </button>
            <VideoPreview
                hide={hidePreviewModal}
                show={showPreviewModal}
                videoStatsName={videoStatsName}
                src={getVideoSource()}
                title={title}
            />
        </>
    );
};

export default CollapseStep;
