import React, {useEffect} from 'react';
import Modal from "react-bootstrap/Modal";
import {usePostHog} from "posthog-js/react";
import useLang from "../../../hooks/useLang";

const VideoPreview = ({show, hide, src, videoStatsName = null, benefits = [], title, subTitle = 'paymentsHowItWorksLabel', bigSize = false}) => {
    const posthog = usePostHog();
    const {getLangText} = useLang();

    useEffect(() => {
        if (posthog && show && videoStatsName) {
            posthog?.capture('TUTORIAL VIDEO', {type: videoStatsName})
        }
    }, [show, posthog])


    if (!show) {
        return null;
    }

    return (
        <Modal
            dialogClassName={`default-modal ${bigSize ? 'big-modal' : 'medium-modal'} preview-img-modal full-height-mobile`}
            show={show}
            onHide={hide}
            className="higher-index"
            backdropClassName="higher-index"
            contentClassName="bg-transparent"
        >
            <div
                className={`bg-[white] relative p-4 ${!!benefits.length ? 'sm:rounded-[12px] sm:h-auto h-full' : 'rounded-[12px] '}`}>
                <button
                    onClick={hide}
                    type="button"
                    className="absolute top-4 right-4 text-[12px] rounded-full bg-white text-[#9CA3AF]"
                >
                    <i className="icon-cross"/>
                </button>
                {title &&
                    <div className="font-medium text-xs text-[#6B7280]">{getLangText(title) || title}</div>
                }
                <div className="text-[#101827 text-lg font-bold mb-6">{getLangText(subTitle)}</div>
                <div className="selmo-video-wrapper mb-3">
                    <iframe
                        frameBorder="0" allowFullScreen="" scrolling="no"
                        allow="autoplay;fullscreen"
                        src={src || show}
                        style={{
                            position: 'absolute',
                            height: '100%',
                            width: '100%',
                            left: '0px',
                            top: '0px'
                        }}
                    />
                </div>
                {!!benefits.length &&
                    <div>
                        <div className="relative">
                            <span
                                className="text-[#4B5563] font-semibold relative z-10 text-sm bg-[#fff] pr-2">{getLangText('knowTheBenefitsLabel')}</span>
                            <span
                                className="after:content-[''] after:bg-[#F3F4F6] after:absolute after:top-1/2 after:left-0 after:h-[1px] after:-translate-Y-1/2 after:w-full"/>
                        </div>
                        <div className="space-y-4 mt-3 mb-4">
                            {benefits.map((i) => (
                                <div
                                    key={i.id}
                                    className="flex items-start"
                                >
                                    <div className="mr-2 -mt-1">
                                        <i className="icon-tick-c text-[#5FA5FA] text-lg"/>
                                    </div>
                                    <div>
                                        <div
                                            className="text-[#101827] font-bold text-sm">{getLangText(i.title)}</div>
                                        <div className="text-desc text-sm font-medium">{getLangText(i.desc)}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                }
            </div>
        </Modal>
    );
};

export default VideoPreview;
