import React from 'react';
import Wrapper from "../Wrapper";
import useLang from "../../../../../src_shared/hooks/useLang";
import useFormLocal from "../../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../../RestServices";
import Loader from "../../../../app/shared/Loader";
import Model from "../../../../../src_shared/modules/model/Model";
import SwitchField from "../../../../../src_shared/components/form/switchField/SwitchField";

const Configuration = () => {
    const {getLangText} = useLang()

    const [{onSave, isWaiting, getFieldProps, isLoading, setValue}] = useFormLocal({
        rest: services.API_SETTINGS_RESERVE_LIST_AUTOMATIC,
        model: new Model(),
    });

    return (
        <Wrapper>
            <div className="loader-parent">
                <Loader isLoading={isLoading}/>
                <div className="settings-form pt-4 bg-[white] rounded-b-[5px]">
                    <form onSubmit={onSave}>
                        <div className="steps-wrapper md:max-w-[50%]">
                            <SwitchField
                                {...getFieldProps('create_order')}
                                className="switch-form inline-label big-label light-version mb-0 align-items-start"
                                label={getLangText('createOrderButton')}
                                subLabel={getLangText('insteadSendQuestionWillCreateOrder')}
                                id="create_order"
                            />
                            <div className="flex items-center mt-4">
                                <SwitchField
                                    {...getFieldProps('active')}
                                    label={getLangText('active')}
                                    className="switch-form inline-label big-label light-version d-flex mb-0"
                                />
                                <button
                                    type="submit"
                                    className={`button small-size ml-auto primary ${isWaiting ? 'loading-button' : ''}`}
                                >
                                    {isWaiting && <Loader isLoading/>}
                                    {getLangText('saveButton')}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Wrapper>
    );
};

export default Configuration;
