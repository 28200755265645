import React from 'react';
import moment from "moment/moment";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import {convertToUserTimeZone} from "../../../../../shared/helpers/dateHelpers";

const StagesHistory = ({data}) => {
    const {getLangText} = useLang();

    const sentHistory = [
        {
            date: data.fakturownia_invoice_messenger,
            label: getLangText('messageSentLabel')
        },
        {
            date: data.fakturownia_invoice_email,
            label: getLangText('emailSentLabel')
        },
    ]

    const sortedHistory = sentHistory.sort(function (a, b) {
        return new Date(a.date) - new Date(b.date);
    });

    return (
        <div className="mt-3">
            <div className="font-bold text-sm mb-2">{getLangText('historyLabel')}</div>
            <div className="histories-info">
                <div className="info-label blue-icon">
                    <i className="icon-created-c"/>
                    {getLangText('createdDocumentLabel')}
                    <div className="date-box">
                        <span>{convertToUserTimeZone(data.fakturownia_invoice_date).format('DD.MM.YYYY')}</span>
                        <span>{convertToUserTimeZone(data.fakturownia_invoice_date).format('HH:mm:ss')}</span>
                    </div>
                </div>
                {!!data.fakturownia_fiscal_print_date &&
                    <div className="info-label blue-icon">
                        <i className="icon-created-c"/>
                        {getLangText('createdFiscalPrintLabel')}
                        <div className="date-box">
                            <span>{convertToUserTimeZone(data.fakturownia_fiscal_print_date).format('DD.MM.YYYY')}</span>
                            <span>{convertToUserTimeZone(data.fakturownia_fiscal_print_date).format('HH:mm:ss')}</span>
                        </div>
                    </div>
                }
                {sortedHistory.map((i) => (
                    i.date &&
                    <div
                        key={i.date}
                        className="info-label blue-icon">
                        <i className="icon-sent-c"/>
                        {i.label}
                        <div className="date-box">
                            <span>{convertToUserTimeZone(i.date).format('DD.MM.YYYY')}</span>
                            <span>{convertToUserTimeZone(i.date).format('HH:mm:ss')}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default StagesHistory;
