import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import FiltersActions from "./filters/FiltersActions";
import {ORDER_STATUS_NOT_PAID, ORDER_STATUS_TO_SEND} from "../shared/enums/OrderStatuses";
import {createSelector} from "reselect";
import {getStateRoot as getPanelFiltersStateRoot} from './list/filtersPanel/form/formReducer'
import {getStateRoot as getFiltersStateRoot} from "./filters/filtersReducer";
import ListActions from "./list/ListActions";
import {ORDERS_LIST_VIEW, ORDERS_PACKING_CLOSED_LIST_VIEW} from "../shared/enums/OrdersListViews";
import _ from "lodash";
import {Helmet} from "react-helmet";
import useLang from "../../../src_shared/hooks/useLang";

const OrdersWrapper = ({children, totalItems, setFiltersVisible, isFiltersVisible, showAddOrderModal, width}) => {
    const dispatch = useDispatch();
    const {userData} = useSelector((state) => state.session);
    const {getLangText} = useLang();

    const [defaultFilters, activeFilters] = useSelector(createSelector(getPanelFiltersStateRoot, getFiltersStateRoot, (stateRoot, filtersStateRoot) => [
        stateRoot.emptyData,
        filtersStateRoot.data,
    ]));
    const [view, setView] = useState(ORDERS_LIST_VIEW);

    const {
        statuses,
        filtersSelectAll,
        payments,
        paymentsSelectAll,
        shipping,
        shippingSelectAll,
        tag,
        tagSelectAll,
        isPacked,
        isPaid,
        label,
        invoice,
        discounts,
        discountsSelectAll,
        country,
        countrySelectAll,
    } = activeFilters;

    const preparedActiveFilters = {
        statuses,
        filtersSelectAll,
        payments,
        paymentsSelectAll,
        shipping,
        shippingSelectAll,
        tag,
        tagSelectAll,
        isPacked,
        isPaid,
        label,
        invoice,
        discounts,
        discountsSelectAll,
        country,
        countrySelectAll
    }

    const closePackingFilter = {
        ...defaultFilters,
        statuses: [ORDER_STATUS_NOT_PAID, ORDER_STATUS_TO_SEND],
        filtersSelectAll: false,
    }

    const setClosePackingFilter = () => {
        dispatch(FiltersActions.data.updateValues(closePackingFilter))
        dispatch(ListActions.loadData());
    }

    const setDefaultFilter = () => {
        dispatch(FiltersActions.data.updateValues(defaultFilters))
        dispatch(ListActions.loadData());
    }

    useEffect(() => {
        if (_.isEqual(closePackingFilter, preparedActiveFilters)) {
            setView(ORDERS_PACKING_CLOSED_LIST_VIEW)
        } else {
            setView(ORDERS_LIST_VIEW)
        }
    }, [statuses])


    return (
        <div className={`settings-page sub-settings-page loader-parent list-with-tabs`}>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getLangText('asideOrdersLabel')} - Selmo</title>
            </Helmet>
            <div className="top-part">
                <h1 className="title">
                    {getLangText('asideOrdersLabel')}
                    {totalItems > 0 &&
                        <div className="badge">{totalItems}</div>
                    }
                </h1>
                <button
                    type="button"
                    className='toggle-filters'
                    onClick={() => setFiltersVisible(!isFiltersVisible)}
                >
                    <i className='icon-filter'/>
                </button>
                <button
                    type="button"
                    className={`button with-icon lgrey add-order-table ${width > 991 ? 'text-only' : 'border-button small-size'}`}
                    onClick={showAddOrderModal}
                >
                    <i className="icon-plus"/>
                    <span className="d-sm-inline-block d-none">{getLangText('addOrderLabel')}</span>
                    <span className="d-sm-none">{getLangText('createButton')}</span>
                </button>
            </div>
            {!!+userData.open_cart_enabled &&
                <div className="tabs-wrapper">
                    <button
                        type="button"
                        onClick={setDefaultFilter}
                        className={view === ORDERS_LIST_VIEW ? 'active' : ''}
                    >
                        {getLangText('allLabel')}
                    </button>
                    <button
                        type="button"
                        onClick={setClosePackingFilter}
                        className={view === ORDERS_PACKING_CLOSED_LIST_VIEW ? 'active' : ''}
                    >
                        {getLangText('closePackagesLabel')}
                    </button>
                </div>
            }
            {children}
        </div>
    );
};

export default OrdersWrapper;
