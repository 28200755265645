import React from 'react';
import {Route} from "react-router-dom";
import StatsPage from "../app/stats/StatsPage";
import UserPermissionRoute from "./UserPermissionRoute";
import {USER_PERMISSION_STATS_MENU_VISIBILITY} from "../app/shared/enums/UserPermissionsTypes";
import AnalyticsPage from "../app/analytics/Page";

const AnalyticsRoute = ({path}) => {
	return (
		<UserPermissionRoute
			path={path}
			allowedPermissions={[USER_PERMISSION_STATS_MENU_VISIBILITY]}
		>
			<Route
				component={AnalyticsPage}
				path={path}
				exact
			/>
		</UserPermissionRoute>
	);
};

export default AnalyticsRoute;
