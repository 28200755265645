import React from 'react';
import useLang from "../../../src_shared/hooks/useLang";
import {Helmet} from "react-helmet";
import {NavLink} from "react-router-dom";
import {RouterPaths} from "../../routes/RouterPath";

const SelmoPaczkaPageWrapper = ({children}) => {
    const {getLangText} = useLang();

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Selmo Paczka - Selmo</title>
            </Helmet>
            <div className="settings-page sub-settings-page loader-parent"
                 style={{background: 'transparent', boxShadow: 'none'}}>
                <div className="white-bg rounded-lg" style={{background: 'white'}}>
                    <div className="header-wrapper pb-1 pt-4 border-bottom-0 d-flex align-items-center">
                        <h1>Selmo Paczka</h1>
                        <img className="ml-auto" width="95" src="/assets/images/svg/selmo-paczka-logo.svg"
                             alt="Selmo Paczka"/>
                    </div>
                    <ul className="tabs-wrapper px-2 border-0 mb-0">
                        <NavLink
                            exact
                            activeClassName="active"
                            to={RouterPaths.SelmoPaczka}
                        >
                            {getLangText('pricingWhyWorthyTitle')}
                        </NavLink>
                        <NavLink
                            exact
                            activeClassName="active"
                            to={RouterPaths.SelmoPaczkaHistory}
                        >
                            {getLangText('balanceHistory')}
                        </NavLink>
                        <NavLink
                            exact
                            activeClassName="active"
                            to={RouterPaths.SelmoPaczkaConfiguration}
                        >
                            {getLangText('configurationLabel')}
                        </NavLink>
                    </ul>
                </div>
                {children}
            </div>
        </>
    );
};

export default SelmoPaczkaPageWrapper;
