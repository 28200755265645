import React from "react";
import {Helmet} from "react-helmet";
import Banners from "./components/Banners";
import Categories from "./components/categories/Categories";
import useLang from "../../../../src_shared/hooks/useLang";
import ShopNameBar from "../../../routes/shopSettingsRoute/ShopNameBar";
import useSubmitData from "../../../../src_shared/hooks/useSubmitData";
import {services} from "../../../RestServices";
import Loader from "../../shared/Loader";

const ShopMainPage = () => {
    const {getLangText} = useLang();

    const [{onSave: refreshMainPage, isWaiting}] = useSubmitData({
        rest: services.API_STORE_CLEAR_HOMEPAGE_CACHE,
        method: 'get',
    });

    return (
        <>
            <ShopNameBar/>
            <div className="settings-page sub-settings-page loader-parent">
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>{getLangText('shopSettingsMainPageLabel')}Selmo</title>
                </Helmet>
                <div className="settings-form">
                    <div className="section-header">
                        <div className="flex items-center flex-wrap">
                            <div>
                                <h1>{getLangText('mainPageLabel')}</h1>
                                <div className="desc">{getLangText('editTextsOnMainPageLabel')}</div>
                            </div>
                            <button
                                onClick={refreshMainPage}
                                className={`button sm:w-auto w-full sm:mt-0 mt-2.5 border-button ml-auto ${isWaiting ? 'loading-button' : ''}`}
                                type="button"
                            >
                                {isWaiting && <Loader isLoading={true}/>}
                                {getLangText('refreshMainPage')}
                            </button>
                        </div>
                    </div>
                    <Banners/>
                    <Categories/>
                </div>
            </div>
        </>
    );
};

export default ShopMainPage;



