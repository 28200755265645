import React from 'react';
import {SortableContainer, SortableElement} from "react-sortable-hoc";
import {arrayMoveImmutable as arrayMove} from 'array-move';
import useLang from "../../../hooks/useLang";
import EmptyList from "../../../../components/app/shared/EmptyList";

const SortableItem = SortableElement(({item, onSortProductEnd, getLangText, ItemComponent}) => {

	return (
		<li className="sortable-tree-item">
			{ItemComponent(item)}
		</li>
	)
});

const SortableListWrapper = SortableContainer(({items, onSortProductEnd, getLangText, ItemComponent}) => {
	return (
		<ul className="category-tree">
			{items.map((item, index) => (
				<SortableItem
					key={item.id}
					index={index}
					items={items}
					item={item}
					onSortProductEnd={(i) => onSortProductEnd(i, item)}
					getLangText={getLangText}
					ItemComponent={ItemComponent}
				/>
			))}
		</ul>
	);
});

const SortableList = ({items, updateAllItemsOnServer, isWaiting, ItemComponent, noResultsLabel = '', noResultsSubLabel = '', emptyImg = '/assets/images/empty/products-empty.svg', ExtraEmptyComponent = null, emptyListClassName = 'with-padd'}) => {
	const {getLangText} = useLang();

	const onSortEnd = ({oldIndex, newIndex}) => {
		const updatedItems = arrayMove(items, oldIndex, newIndex);
		updateAllItemsOnServer(updatedItems)
	};

	return (
		<EmptyList
			items={items}
			isLoading={isWaiting}
			className={emptyListClassName}
			imgSrc={emptyImg}
			title={noResultsLabel}
			text={noResultsSubLabel}
			ExtraComponent={ExtraEmptyComponent}
		>
			<div className="sortable-tree-wrapper pb-3">
				<SortableListWrapper
					items={items}
					onSortEnd={onSortEnd}
					getLangText={getLangText}
					ItemComponent={ItemComponent}
				/>
			</div>
		</EmptyList>
	);
};

SortableList.defaultProps = {
	ItemComponent: (item) => (
		<div className="category-row">
			<div className="sort-button">
				<i className="icon-drag"/>
			</div>
			{item.name}
		</div>
	)
}

export default SortableList;
