import Model from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

class SettingsProductHasVariantsTemplatesModel extends Model {
	getModel() {
		return {
			product_has_variants_1: '',
			product_has_variants_2: '',
			product_has_variants_3: '',
		};
	}

	getValidators() {
		return {
			product_has_variants_1: ValidationError.notEmpty,
			product_has_variants_2: ValidationError.notEmpty,
			product_has_variants_3: ValidationError.notEmpty,
		};
	}

	buildDTO = (data) => {
		const filteredData = Object.entries(data)
			.filter(([key, value]) => !!value && key.startsWith('product_has_variants_'))
			.sort(([keyA], [keyB]) => Number(keyA.split('_')[2]) - Number(keyB.split('_')[2]));

		return filteredData.reduce((acc, [_, value], index) => {
			acc[`product_has_variants_${index + 1}`] = value;
			return acc;
		}, {});
	};
}

export default new SettingsProductHasVariantsTemplatesModel();
