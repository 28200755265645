import React from 'react';
import {RouterPaths} from "../../../../routes/RouterPath";
import Loader from "../../../shared/Loader";
import {useDispatch, useSelector} from "react-redux";
import EditClientFormActions from "../form/FormActions";
import FormActions from "./DataActions";
import {Link, useParams} from "react-router-dom";
import useForm from "../../../../../src_shared/form/useForm";
import CustomDropdown from "../../../shared/components/dropdown/CustomDropdown";
import ConfirmModal from "../../../orders/list/components/export/ConfirmModal";
import {createSelector} from "reselect";
import {getStateRoot} from "../form/formReducer";
import {ORDER_PAYMENT_STATUES} from "../../../shared/enums/PaymentStatuses";
import ClientPaymentReceived from "./clientPaymentReceived/ClientPaymentReceived";
import ToggleContent from "../../../../../src_shared/components/toggle/ToggleContent";
import useLang from "../../../../../src_shared/hooks/useLang";
import {useUnitPrice} from "../../../shared/helpers/Price";
import ClientAvatar from "../../../live/shared/Avatar";

const ClientInfo = () => {
    const dispatch = useDispatch();
    const {id} = useParams();
    const {userData} = useSelector((state) => state.session);
    const {getLangText} = useLang();
    const {getPrice} = useUnitPrice();

    const {
        data: {values},
        form: {isWaiting, deleteWithoutAsk},
    } = useForm({
        FormActions,
        params: {
            id,
        },
    });

    const showModal = () => {
        // dispatch(EditClientFormActions.data.updateValues({
        // 	id: values.shop_client_id,
        // 	name: values.name,
        // 	photo: values.photo,
        // }))
        dispatch(EditClientFormActions.modal.showModal())
    };

    const {
        clientModalVisible,
        waitingForRemoving
    } = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));

    const showRemoveConfirmModal = () => {
        dispatch(EditClientFormActions.showClientModal(true))
        dispatch(FormActions.data.updateValues({
            id: id,
            name: values.name,
        }))
    };
    const hideRemoveConfirmModal = () => dispatch(EditClientFormActions.showClientModal(false));

    const getShippingData = () => {
        if (values.shippingData.inpost_box_code) {
            return (
                <div className="data">
                    {values.shippingData.shipping_name} <br/>
                    Paczkomat {values.shippingData.inpost_box_code}<br/>
                    {values.shippingData.inpost_box_street}<br/>
                    {values.shippingData.inpost_box_city}
                </div>
            )
        }
        if (values.shippingData.mondial_box_name) {
            return (
                <div className="data">
                    {values.shippingData.shipping_name} <br/>
                    {getLangText('mondialRelayCollectionRELLabel')} {values.shippingData.mondial_box_code}<br/>
                    {values.shippingData.mondial_box_street}<br/>
                    {values.shippingData.mondial_box_city}
                </div>
            )
        }
        return (
            <div className="data">
                {values.shippingData.shipping_name} <br/>
                {`${values.shippingData.street} ${values.shippingData.building_number}`}
                <br/>
                {values.shippingData.zip_code}{' '}{values.shippingData.city}
            </div>
        )
    }

    const orderPaymentMethod = values.shippingData?.payment && getLangText(ORDER_PAYMENT_STATUES.find((status) => status.value === values.shippingData.payment)?.label)

    return (
        <>
            <div className="default-shadow-box loader-parent">
                <Loader isLoading={isWaiting}/>
                <div className="top-part">
                    <div className="avatar-row">
                        <ClientAvatar
                            photo={values.photo}
                            type={values.social_type}
                            fbLogo={userData.facebook_id && userData.instagram_id}
                        />
                        {/*{values.photo && values.photo !== '0' ?*/}
                        {/*	<figure>*/}
                        {/*		<img src={values.photo}*/}
                        {/*			 alt={values.name}/>*/}
                        {/*	</figure> :*/}
                        {/*	<figure>*/}
                        {/*		<img src="/assets/images/default/fb-default.jpeg"*/}
                        {/*			 alt="Brak danych"/>*/}
                        {/*	</figure>*/}
                        {/*}*/}
                        <div>
                            {values.name ? <div className="name">{values.name}</div> :
                                <div className="name">{getLangText('noDataLabel')}</div>}
                            {values.shop_client_id &&
                                <div className="client-number">
                                    {getLangText('clientNumberLabel')}: <strong>{values.shop_client_id}</strong>
                                </div>
                            }
                        </div>
                        <CustomDropdown
                            className="ml-auto"
                        >
                            <button
                                type="button"
                                className="custom-option"
                                onClick={showModal}
                            >
                                {getLangText('editButton')}
                            </button>
                            <button
                                type="button"
                                className="custom-option"
                                onClick={showRemoveConfirmModal}
                            >
                                {getLangText('removeButton')}
                            </button>
                        </CustomDropdown>
                        <ConfirmModal
                            title={getLangText('clientsRemoveClientTitle')}
                            SubTitle={() => <span>{getLangText('clientsAskForRemoveClientLabel')} <strong
                                className="d-block">{values.name}</strong></span>}
                            saveButton={getLangText('removeButton')}
                            show={clientModalVisible}
                            isWaiting={waitingForRemoving}
                            onSubmit={deleteWithoutAsk}
                            hide={hideRemoveConfirmModal}
                            submitButtonClass="danger"
                        />
                    </div>
                    {values.conversation_id &&
                        <Link to={`${RouterPaths.Messenger}${values.conversation_id}`}
                              className="button go-to-conversation mt-4">
                            <i className="icon-messenger"/>
                            {getLangText('goToConversationButton')}
                        </Link>
                    }
                </div>
                <div className="data-part">
                    <div className="title">{getLangText('statisticsLabel')}</div>
                    <div className="stats-boxes flex-wrap">
                        <div>
                            <div className="inner">
                                <div className="label">{getLangText('clientsSpentMoneyLabel')}</div>
                                <div className="value">{getPrice(values.money_spent)}</div>
                            </div>
                        </div>
                        <div>
                            <div className="inner">
                                <div className="label">{getLangText('ordersLabel')}</div>
                                <div className="value">{values.orders_count}</div>
                            </div>
                        </div>
                    </div>
                </div>
                {values.transfer_account_name &&
                    <div className="data-part border-t pt-3">
                        <div className="title mb-1">{getLangText('accountNumberLabel')}</div>
                        <div className="text-desc text-sm font-medium">{values.transfer_account_name}</div>
                    </div>
                }
                {values.shippingData &&
                    <ToggleContent className="pt-3 mt-3 pb-3 with-border"
                                   title={getLangText('lastFilledDeliveryAddressLabel')}>
                        <div className="data-part mt-2 pt-0">
                            <div className="data">
                                {values.shippingData.first_name}{' '}{values.shippingData.last_name} <br/>
                                {values.shippingData.email} <br/>
                                {values.shippingData.phone} <br/>
                                {values.shippingData.street}{' '}{values.shippingData.building_number}
                                <br/>
                                {values.shippingData.zip_code}{' '}{values.shippingData.city}
                            </div>
                        </div>
                        {values.shippingData && values.shippingData.shipping_name &&
                            <div className="data-part with-border">
                                <div className="title">{getLangText('lastDeliveryAddressLabel')}</div>
                                {getShippingData()}
                            </div>
                        }
                        {values.shippingData && values.shippingData.payment &&
                            <div className="data-part with-border">
                                <div className="title">{getLangText('lastPaymentMethodLabel')}</div>
                                <div className="data">
                                    {orderPaymentMethod}
                                </div>
                            </div>
                        }
                        {values.chosen_package_type &&
                            <div className="data-part with-border">
                                <div className="title">{getLangText('lastPackedTypeLabel')}</div>
                                <div className="data">
                                    {values.chosen_package_type.name}
                                </div>
                            </div>
                        }
                    </ToggleContent>
                }
            </div>
            {(values.facebook_id && !!+userData.show_payment_received) &&
                <div className="default-shadow-box mt-3">
                    <ClientPaymentReceived
                        className="mt-0"
                        data={values}
                    />
                </div>
            }
        </>
    );
};

export default ClientInfo;
