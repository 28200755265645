import React, {useEffect, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {useSelector} from "react-redux";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import CopyButton from "../../../../../../../src_shared/components/form/copyButton/CopyButton";
import SwitchField from "../../../../../../../src_shared/components/form/switchField/SwitchField";
import {useUnitPrice} from "../../../../../shared/helpers/Price";
import ValidationError from "../../../../../../../src_shared/form/validation/ValidationError";

const PreparedPostText = ({modalVisible, hideModal, data, productCode}) => {

	const {getLangText} = useLang();
	const {userData} = useSelector((state) => state.session);
	const productTextCode = `${productCode} ${data.selmo_code}`
	const defaultFooter = getLangText('orderHandledBySelmoSystemLabel')
	const {getPrice} = useUnitPrice();

	const defaultValues = {
		text: '',
		inStock: '1',
		showPrices: '0',
		withVariants: '1',
	}

	const [values, setValues] = useState(defaultValues)

	const isInStock = !!+values.inStock;

	const preparedProducts = isInStock
		? data?.products?.map((product) => {
			const hasSizes = !!+product.has_sizes;
			if (hasSizes) {
				const filteredSizes = product.sizes?.filter((size) => !!+size.squ);
				return { ...product, sizes: filteredSizes };
			} else {
				return !!+product.squ ? product : null;
			}
		}).filter(Boolean)
		: data.products;

	const getTotalProductsWithLabel = (product) => !+values.showPrices ? `\n\n${getLangText('priceLabel')}: ${getPrice(product.price)}` : '';

	const productNoVariants = (product) => `${productCode} ${product.selmo_code} ${!+values.showPrices ? '' : `- ${getPrice(product.price)}`}${getTotalProductsWithLabel(product)}`
	const productsVariants = (product) => `${productCode} ${product.selmo_code_size} ${!+values.showPrices ? '' : `- ${getPrice(product.price)}`}`

	const products = preparedProducts?.map((product) =>
		`${getLangText({
			key: 'toOrderProductTextInCommentLabel',
			data: [product.name]
		})}\n${productNoVariants(product)}\n`);

	const productsWithVariants = preparedProducts?.map((product) =>
		`${getLangText({
			key: 'toOrderProductTextInCommentLabel',
			data: [product.name]
		})}\n\n${!!+product.has_sizes ? `${product?.sizes?.map(productsVariants).join('\n')}${getTotalProductsWithLabel(product)}` : productNoVariants(product)}\n`)

	const defaultTextWithVariant = `${productsWithVariants.join('\n')}\n${defaultFooter}`
	const defaultText = `${products.join('\n')}\n${defaultFooter}`

	useEffect(() => {
		setValues({...values, text: !!+values.withVariants ? defaultTextWithVariant : defaultText})
	}, [values.inStock, values.showPrices, values.withVariants, values.inStock])

	return (
		<Modal
			dialogClassName="default-modal"
			show={modalVisible}
			onHide={hideModal}
		>
			<Modal.Header className="with-border">
				<Modal.Title>
					{getLangText('prepareTextToPostLabel')}
				</Modal.Title>
				<button
					onClick={hideModal}
					type="button"
					className="button"
				>
					<i className="icon-cross"/>
				</button>
			</Modal.Header>
			<Modal.Body>
				<div className="form-group">
					<label className="control-label">{getLangText('textLabel')}</label>
					<div className="border-area">
						<textarea
							className="form-control"
							name="client-message"
							rows="10"
							value={values.text}
							onChange={(e) => setValues({...values, text: e.target.value})}
						/>
						<div className="bottom-part justify-content-end">
							<CopyButton
								copyButtonText={getLangText('copyButton')}
								copiedButtonText={getLangText('copiedLabel')}
								copyText={values.text}
								withIcon={false}
								withTooltip={false}
								buttonClassName="primary copied"
							/>
						</div>
					</div>
				</div>
				<SwitchField
					className="switch-form inline-label align-items-start"
					label={getLangText('variantsInStockLabel')}
					subLabel={getLangText('variantsInStockSubLabel')}
					setValue={(field, value) => setValues({...values, inStock: value})}
					value={values.inStock}
					validateErrors={false}
					getError={() => ValidationError.skip()}
				/>
				<div>
					<SwitchField
						className="switch-form inline-label align-items-start"
						label={getLangText('showPricesLabel')}
						subLabel={getLangText('showPricesInVariantsLabel')}
						setValue={(field, value) => setValues({...values, showPrices: value})}
						value={values.showPrices}
						validateErrors={false}
						getError={() => ValidationError.skip()}
					/>
				</div>
				<div>
					<SwitchField
						className="switch-form inline-label align-items-start"
						label={getLangText('productsWithVariants')}
						subLabel={getLangText('showProductsWithVariants')}
						setValue={(field, value) => setValues({...values, withVariants: value})}
						value={values.withVariants}
						validateErrors={false}
						getError={() => ValidationError.skip()}
					/>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<button
					onClick={hideModal}
					type="button"
					className="button border-button"
				>
					{getLangText('closeButton')}
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default PreparedPostText;
