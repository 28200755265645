import React from 'react';
import FunctionalityPurchasePage from "../../shared/FunctionalityPurchasePage";
import FunctionalityModal from "../../payments/functionalityModal/Form";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {getStateRoot as getFunctionalityModalStateRoot} from "../../payments/functionalityModal/formReducer";
import FunctionalityModalActions from "../../payments/functionalityModal/FormActions";
import {PRODUCT_CODE_PERMISSION} from "../../shared/enums/SelmoPermissions";
import {checkRoles} from "../../../routes/PermissionRoute";
import Field from "../../../../src_shared/components/form/field/Field";
import useForm from "../../../../src_shared/form/useForm";
import FormActions from "./FormActions";
import Loader from "../../shared/Loader";
import useLang from "../../../../src_shared/hooks/useLang";
import moment from "moment";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import {convertToUserTimeZone} from "../../shared/helpers/dateHelpers";

const SettingsProductCodes = () => {
    const dispatch = useDispatch();
    const {getLangText} = useLang();
    const {userData} = useSelector((state) => state.session);
    const {lang} = useSelector((state) => state.i18n);

    const {permissions} = useSelector((state) => state.session.userData);
    const {waitingForResponse} = useSelector((state) => state.session);
    const {modalVisible} = useSelector(createSelector(getFunctionalityModalStateRoot, (stateRoot) => stateRoot));
    const permissionsMatched = checkRoles(permissions, [PRODUCT_CODE_PERMISSION]);

    const {
        data: {setValue},
        form: {isWaiting, onSave, getFieldProps},
    } = useForm({
        FormActions,
        params: {},
        requireParentId: false,
    });

    const showModal = () => dispatch(FunctionalityModalActions.modal.showModal());

    const activeFunctionality = userData?.functionalities_expiration?.find((i) => i.selmo_name === PRODUCT_CODE_PERMISSION);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getLangText('ownProductCodeLabel')} - Selmo</title>
            </Helmet>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <Link to={RouterPaths.Settings}>{getLangText('asideSettingsLabel')}</Link>
                    </li>
                    <li>
                        <Link to={RouterPaths.SettingsShop}>{getLangText('settings_nav_shop_settings')}</Link>
                    </li>
                    <li className="active">
                        <div>{getLangText('ownProductCodeLabel')}</div>
                    </li>
                </ul>
            </div>
            <div className="row small-padd-row">
                <div className="col-md-7">
                    <div className="loader-parent">
                        <Loader isLoading={waitingForResponse || isWaiting}/>
                        {permissionsMatched ?
                            <div className="settings-page sub-settings-page">
                                <div className="header-wrapper">
                                    <h1>
                                        <img src="/assets/images/svg/codes.svg"
                                             alt="overview"/>
                                        {getLangText('ownProductCodeLabel')}
                                    </h1>
                                    <div className="description">{getLangText('createOwnUniqueProductCodeLabel')}</div>
                                </div>
                                <div className="settings-form">
                                    <section>
                                        <div className="form-col">
                                            <div className="d-flex align-items-end product-code-row">
                                                <div className="flex-grow-1">
                                                    <h2 className="label mb-2">{getLangText('createCodeLabel')}</h2>
                                                    <Field
                                                        showLabel={false}
                                                        {...getFieldProps('code')}
                                                        label={getLangText('codeLabel')}
                                                        setValue={setValue}
                                                    />
                                                </div>
                                                <div className="button-place ml-3">
                                                    <button
                                                        onClick={onSave}
                                                        className="button primary"
                                                        type="submit"
                                                    >
                                                        {getLangText('saveButton')}
                                                    </button>
                                                </div>
                                            </div>
                                            <div
                                                className="form-info-box align-items-center small-line-height w-100 mt-3">
                                                <div>
                                                    <i className="icon-info-c"/>
                                                </div>
                                                <div>
                                                    <div
                                                        className="title">{getLangText('customersWillTypeWordLabel')}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div> :
                            <FunctionalityPurchasePage
                                imgSrc="/assets/images/svg/codes.svg"
                                title={getLangText('ownProductCodeLabel')}
                                onClick={showModal}
                            />
                        }
                        {modalVisible &&
                            <FunctionalityModal
                                title={getLangText('productsCodeLabel')}
                                desc={getLangText('changeSelmoCodeToOwnLabel')}
                                functionalityName={PRODUCT_CODE_PERMISSION}
                            />
                        }
                    </div>
                </div>
                <div className="col-md-5 mt-md-0 mt-3">
                    {permissionsMatched &&
                        <div className="default-shadow-box bigger-y-padd payment-benefits-wrapper mb-3">
                            <div className="label">{getLangText('subscriptionStatusLabel')}</div>
                            <div className="active-plan-date d-flex align-items-center">
                                <div className="date">{getLangText('paymentsActivePlanToLabel')}
                                    <strong>{convertToUserTimeZone(activeFunctionality?.expires_in).format('DD.MM.YYYY')}</strong>
                                </div>
                                <button
                                    type="button"
                                    onClick={showModal}
                                    className="button primary small-size ml-2"
                                >
                                    {getLangText('extendLabel')}
                                </button>
                            </div>
                        </div>
                    }
                    <div className="default-shadow-box bigger-y-padd payment-benefits-wrapper">
                        <div className="label">{getLangText('paymentsHowItWorksLabel')}</div>
                        <ul className="benefits-list">
                            <li>
                                <i className="icon-tick-c"/>
                                {getLangText('addAnyWordBeforeProductCodeLabel')}
                            </li>
                            <li>
                                <i className="icon-tick-c"/>
                                {getLangText('youCanChangeCodeAtAnyTimeLabel')}
                            </li>
                        </ul>
                        <figure>
                            <img style={{width: '100%'}} src={`/assets/images/multiLanguage/${lang}/own_codes.webp`}/>
                        </figure>
                    </div>
                </div>
            </div>
        </>
	);
};

export default SettingsProductCodes;
