import Model, {convertObjectProperties} from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

class SettingsAutopayAutomaticTransferModel extends Model {
	getModel() {
		return {
			bm_transfer_active: '0',
			bm_first_message_active: '0',
			bm_first_message: '',
			bm_second_message_active: '0',
			bm_second_message: '',
		};
	}

	getValidators() {
		return {
			bm_transfer_active: ValidationError.skip,
			bm_first_message_active: ValidationError.skip,
			bm_first_message: ValidationError.skip,
			bm_second_message_active: ValidationError.skip,
			bm_second_message: ValidationError.skip,
		};
	}
}

export default new SettingsAutopayAutomaticTransferModel();
