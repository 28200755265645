import React from 'react';
import Modal from "react-bootstrap/Modal";
import {batch, useDispatch, useSelector} from "react-redux";
import {addOrderModalToggleVisibility} from "../../AddOrderModalActions";
import {createSelector} from "reselect";
import {getStateRoot} from "../../newAddOrderModalReducer";
import NewAddOrderModalActions from "../../NewAddOrderModalActions";
import useLang from "../../../../../../src_shared/hooks/useLang";

const SafetyCloseModal = ({getOrderProducts}) => {

	const dispatch = useDispatch();
	const {getLangText} = useLang();

	const {safetyCloseModalVisibility} = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));
	const hideSafetyCloseModal = () => dispatch(NewAddOrderModalActions.safetyCloseModalVisibility({
		visible: false,
		toSummaryView: false,
	}));

	const handleClose = () => {
		if (safetyCloseModalVisibility.toSummaryView) {
			getOrderProducts('summary');
			batch(() => {
				hideSafetyCloseModal();
				dispatch(NewAddOrderModalActions.resetOrderProducts());
			})
			return;
		}
		batch(() => {
			hideSafetyCloseModal();
			dispatch(NewAddOrderModalActions.resetOrderProducts())
			dispatch(addOrderModalToggleVisibility(false))
		})
	}

	return (
		<Modal
			className="confirm-modal"
			show={safetyCloseModalVisibility?.visible}
			onHide={hideSafetyCloseModal}
		>
			<Modal.Header>
				<Modal.Title>{getLangText('notSavedChangesLabel')}</Modal.Title>
				<button
					onClick={hideSafetyCloseModal}
					type="button"
				>
					<i className="icon-cross"/>
				</button>
			</Modal.Header>
			<Modal.Body>{getLangText('askNotSavedChangesLabel')}</Modal.Body>
			<Modal.Footer>
				<button
					onClick={hideSafetyCloseModal}
					type="button"
					className="button border-button"
				>
					{getLangText('cancelButton')}
				</button>
				<button
					onClick={handleClose}
					type="button"
					className="button red"
				>
					<span className="mobile-text">{getLangText('yesButton')}</span>
					<span className="desktop-text">{getLangText('rejectButton')}</span>
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default SafetyCloseModal;
