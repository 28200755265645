import createReducer from "../../../shared/helpers/createReducer.js";
import Composition from "../../../shared/helpers/Composition.js";
import FormReducer from "../../../../../src_shared/form/FormReducer.js";
import AssignProductsModel from "../../../../../modules/models/category/AssignProductsModel";

export const getStateRoot = (state) => state.products.categories.assignProducts;
export const prefix = 'CATEGORIES_ASSIGN_PRODUCTS_MODAL_FORM_';

const getInitState = () => FormReducer.getInitState(AssignProductsModel.getModel());

const getReduceMap = () => new Composition(
	FormReducer.getReduceMap(),
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

