import React, {useState} from 'react';
import useLang from "../../../../../../../../src_shared/hooks/useLang";
import Modal from "react-bootstrap/Modal";
import Loader from "../../../../../../shared/Loader";
import useFetchData from "../../../../../../../../src_shared/hooks/useFetchData";
import {services} from "../../../../../../../RestServices";

const PostModal = ({hideModal, modalVisible, onSave, values}) => {
    const {getLangText} = useLang();
    const [activePost, setActivePost] = useState(values.selectedPost)

    const [{isLoading, data}] = useFetchData({
        rest: services.API_INSTAGRAM_POSTS,
        initialData: [],
    })

    const onSubmit = () => {
        onSave(activePost);
        hideModal()
    }

    return (
        <Modal
            dialogClassName="default-modal w-450"
            show={modalVisible}
            onHide={hideModal}
        >
            <Modal.Header>
                <Modal.Title>
                    {getLangText('selectPostLabel')}
                    <div className="sub-title">{getLangText('selectWhichPostYouWantLabel')}</div>
                </Modal.Title>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            <Modal.Body>
                {isLoading ?
                    <Loader isLoading className="small-loader static-loader mt-3"/> :
                    !data?.length ?
                        <div className="form-info-box align-items-center w-100 mb-0 small-line-height">
                            <div>
                                <i className="icon-info-c"/>
                            </div>
                            <div>
                                <div className="title">{getLangText('noPostToSelectLabel')}</div>
                            </div>
                        </div>
                        :
                        <div className="grid grid-cols-3 gap-2 max-h-[467px] overflow-y-auto">
                            {data.map((item) => (
                                <button
                                    onClick={() => setActivePost(item)}
                                    type="button"
                                    key={item.ig_media_id}
                                    className={`w-full h-[150px] rounded-[8px] ${activePost?.ig_media_id === item.ig_media_id ? 'border-2 border-[#3C61EA]' : 'border-2 border-transparent'}`}
                                >
                                    <img className="w-full p-1 h-full object-cover rounded-[8px]"
                                         src={item.ig_media_photo} alt="ig_media_id"/>
                                </button>
                            ))}
                        </div>
                }
            </Modal.Body>
            <Modal.Footer className="open-cart-footer-modal mt-4">
                <button
                    onClick={hideModal}
                    type="button"
                    className="button border-button"
                >
                    {getLangText('cancelButton')}
                </button>
                <button
                    onClick={onSubmit}
                    type="button"
                    disabled={!activePost}
                    className={`button primary ${!activePost ? 'not-active' : ''}`}
                >
                    {getLangText('saveButton')}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default PostModal;
