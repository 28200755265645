import React from 'react';
import EmptyList from "../../../../../shared/EmptyList.jsx";
import {useHistory} from "react-router-dom";
import {RouterPaths} from "../../../../../../routes/RouterPath.js";
import Tooltip from "react-bootstrap/Tooltip";
import moment from "../../../../../messenger/helpers/moment";
import TimeAgo from "../../../../../../../src_shared/helpers/TimeAgo";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import useTableRowClick from "../../../../../shared/hooks/useTableRowClick";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import {useSelector} from "react-redux";
import ServerImage from "../../../../../shared/components/serverImage/ServerImage";
import {convertToUserTimeZone} from "../../../../../shared/helpers/dateHelpers";

const ProductsTable = ({items, isWaiting, updateSortOptions, getSortClass}) => {

	const onTableRowClick = useTableRowClick(RouterPaths.ClientsList)
	const {getLangText} = useLang();
	const {lang} = useSelector((state) => state.i18n);

	return (
		<EmptyList
			items={items}
			isLoading={isWaiting}
			className="with-padd"
			imgSrc="/assets/images/empty/clients-empty.svg"
			title={getLangText('emptyHereLabel')}
			text={getLangText('emptyClientListDescription')}
		>
			<div className="table-wrapper">
				<div className="table-responsive overflow-visible">
					<table className="table live-table smaller-padd">
						<thead>
						<tr>
							<th
								name="shop_client_id"
								onClick={updateSortOptions}
								className={`client-number ${getSortClass('shop_client_id')}`}
							>
							<span name="shop_client_id">
								{getLangText('numberShortLabel')}
							</span>
							</th>
							<th
								name="name"
								onClick={updateSortOptions}
								className={getSortClass('name')}
							>
							<span name="name">
								{getLangText('nameAndSurnameLabel')}
							</span>
							</th>
							<th
								name="selmo_user_notifications.last_message_date"
								onClick={updateSortOptions}
								className={`text-sm-right ${getSortClass('selmo_user_notifications.last_message_date')}`}
							>
							<span name="selmo_user_notifications.last_message_date">
								{getLangText('lastNotificationLabel')}
							</span>
							</th>
							<th
								name="selmo_user_notifications.date"
								onClick={updateSortOptions}
								className={`text-sm-right ${getSortClass('selmo_user_notifications.date')}`}
							>
							<span name="selmo_user_notifications.date">
								{getLangText('submittedDateLabel')}
							</span>
							</th>
						</tr>
						</thead>
						<tbody>
						{items.map((item) => (
							<tr{...onTableRowClick(item.shop_client_id)}>
								<td
									data-thead={`${getLangText('numberShortLabel')}:`}
									className="number with-mobile-thead inline-thead mb-lg-0 mb-1"
								>
									{item.shop_client_id}
								</td>
								<td className="name">
									<div className="avatar-row">
										<figure className="user-avatar">
											{item.photo && item.photo !== '0' ?
												<div className="fb-pic">
													<ServerImage src={item.photo}/>
												</div> :
												<img src="/assets/images/default/fb-default.jpeg"/>
											}
										</figure>
										{item.name ? item.name : getLangText('noNameLabel')}
									</div>
								</td>
								<td
									data-thead={`${getLangText('lastNotificationLabel')}:`}
									className="text-sm-right date w-lg-auto w-100 order-lg-0 order-2 with-mobile-thead inline-thead mb-lg-0 text-nowrap"
								>
									{item.last_message_date ?
										<OverlayTrigger
											placement='top'
											overlay={
												<Tooltip
													className="small-size"
													id="last_message_date"
												>
													{convertToUserTimeZone(item.last_message_date).format('DD.MM.YYYY HH:mm')}
												</Tooltip>
											}
										>
											<TimeAgo
												datetime={convertToUserTimeZone(item.last_message_date).format('YYYY-MM-DD HH:mm')}
												locale={lang}
											/>
										</OverlayTrigger> :
										getLangText('lackLabel')
									}
								</td>
								<td
									data-thead={`${getLangText('submittedDateLabel')}:`}
									className="text-sm-right date w-lg-auto w-100 order-lg-0 order-2 with-mobile-thead inline-thead mb-lg-0 text-nowrap"
								>
									<OverlayTrigger
										placement='top'
										overlay={
											<Tooltip
												className="small-size"
												id="time"
											>
												{convertToUserTimeZone(item.date).format('DD.MM.YYYY HH:mm')}
											</Tooltip>
										}
									>
										<TimeAgo
											datetime={convertToUserTimeZone(item.date).format('YYYY-MM-DD HH:mm')}
											locale={lang}
										/>
									</OverlayTrigger>
								</td>
							</tr>
						))}
						</tbody>
					</table>
				</div>
			</div>
		</EmptyList>
	);
};

export default ProductsTable;
