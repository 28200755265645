import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import React from "react";

const AdminPermissionsWrapper = ({ children, NoPermissionComponent }) => {
    const {userData} = useSelector((state) => state.session);

    const isAdmin = +userData.selmo_boss === 69;

    if (!isAdmin) {
        return NoPermissionComponent && <NoPermissionComponent />;
    }

    return children;
};

AdminPermissionsWrapper.propTypes = {
    children: PropTypes.node,
};

AdminPermissionsWrapper.defaultProps = {
    children: null,
    NoPermissionComponent: null,
};

export default AdminPermissionsWrapper;
