import React from 'react';
import Alert from "../shared/components/alert/Alert";
import LanguagePicker from "../shared/languagePicker/LanguagePicker";
import Plausible from "plausible-tracker";
import {PostHogProvider} from "posthog-js/react";
import {posthogOptions} from "../AppWrapper";
import {useLocation, useParams} from "react-router-dom";
import {RouterPaths} from "../../routes/RouterPath";

const LoginWrapper = ({children}) => {

    const route = useLocation();
    const {pathname} = useLocation();

    const plausible = Plausible({
        domain: 'app.selmo.io',
    })

    plausible.trackPageview();

    return (
        <PostHogProvider
            apiKey="phc_ePheWHOSpLMyVFJGGQqZIHyP7SnUD9jGP2mIFVnu4Ei"
            options={posthogOptions}
        >
            <div className="login-page-wrapper">
                <div className={`left-side ${pathname.includes(RouterPaths.AuthInstagramConnect) || pathname.includes(RouterPaths.AuthInstagramProgress) ? 'connect-ig' : ''}`}>
                    {children}
                    {!route.pathname.includes(RouterPaths.AuthEmployee) &&
                        <div className="text-center mt-8">
                            <LanguagePicker
                                buttonClassName="px-2.5 py-1.5 rounded-[8px] inline-flex items-center justify-center opacity-60 hover:opacity-100 transition-all custom-hover with-border"
                                withCountryName
                            />
                        </div>
                    }
                </div>
            </div>
            <Alert/>
        </PostHogProvider>
    );
};

export default LoginWrapper;
