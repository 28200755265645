import {getStateRoot, prefix} from './listReducer.js';
import {RouterPaths} from "../../../routes/RouterPath.js";
import {services} from "../../../RestServices.js";
import ListActions, {getGETParamsUrl} from "../../../../src_shared/list/ListActions";
import {convertDate} from "../../../app/shared/helpers/dateHelpers";
import history from "../../../history";
import {getStateRoot as getFiltersStateRoot} from "../filters/filtersReducer";


export class TeamStatisticsListActions extends ListActions {
	getLoadParams(state) {
		const params = super.getLoadParams(state);
		const dateFrom = getFiltersStateRoot(state).data.fromDate;
		const dateTo = getFiltersStateRoot(state).data.toDate;
		const types = getFiltersStateRoot(state).data.types;
		const employees = getFiltersStateRoot(state).data.employees;

		if (dateFrom) {
			params.dateFrom = convertDate(dateFrom);
		}

		if (dateTo) {
			params.dateTo = convertDate(dateTo);
		}

		if (types.length) {
			params['types[]'] = types
		}

		if (employees.length) {
			params['employees[]'] = employees
		}

		history.replace({
			pathname: this.pathname,
			search: getGETParamsUrl(params)
		});

		return params;
	}
}

export const getInstance = () => new TeamStatisticsListActions({
	getStateRoot,
	restService: services.API_PERMISSIONS_EMPLOYEE_HISTORY,
	pathname: RouterPaths.SettingsTeamActivityHistory,
	prefix,
});

export default getInstance();
