import createReducer from "../../../../shared/helpers/createReducer";
import DataReducer from "../../../../../../src_shared/form/data/DataReducer";
import {getReduceMap} from "../../../../../../src_shared/filters/filterReducer";
import {INTERVAL} from "../../../../shared/enums/OrderViewTypes";
import moment from "moment/moment";
import {convertToUserTimeZone} from "../../../../shared/helpers/dateHelpers";


export const getStateRoot = (state) => state.products.form.activityHistory.filters;

export const getInitState = () => DataReducer.getInitState(
    {
        fromDate: moment(new Date()).subtract(1, 'M').toDate(),
        toDate: new Date(),
        viewType: INTERVAL,
    }
)

export const prefix = 'PRODUCTS_FORM_ACTIVITY_HISTORY_FILTERS_';

export const getInstance = () => createReducer(
    getInitState(),
    getReduceMap(),
    prefix,
);

export default getInstance();
