import React from 'react';
import useLang from "../../../../src_shared/hooks/useLang";
import {Helmet} from "react-helmet";
import InstagramButton from "../instagram/InstagramButton";
import Plausible from "plausible-tracker";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";

export const INSTAGRAM_SIGNUP_CLICK = 'INSTAGRAM_SIGNUP_CLICK';

export const plausibleInstagram = Plausible({
    trackLocalhost: false,
    domain: 'app.selmo.io',
})

const RegisterInstagramPage = () => {
    const {getLangText} = useLang();

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getLangText('registerForFreeLabel')} - Selmo</title>
            </Helmet>
            <img alt="selmo" width={107} src="/assets/images/logo.svg"/>
            <h1 className="text-3xl font-bold mt-6 mb-8"
                dangerouslySetInnerHTML={{__html: getLangText('testSelmoForFreeLabel')}}/>
            <InstagramButton/>
            <div className="change-page">
                {getLangText('haveAnAccount')}
                <Link to={RouterPaths.LoginInstagram} className="special-link">{getLangText('loginButton')}</Link>
            </div>
            {/*<FacebookButton/>*/}
        </>
    );
};

export default RegisterInstagramPage;
