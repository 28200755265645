import React from 'react';
import ServerImage from "../../../../../../../shared/components/serverImage/ServerImage";
import {useUnitPrice} from "../../../../../../../shared/helpers/Price";
import useLang from "../../../../../../../../../src_shared/hooks/useLang";

const SelectedProduct = ({values, showModal}) => {
    const {getPrice} = useUnitPrice();
    const {getLangText} = useLang();

    return (
        <div className="mt-3">
            <div className="form-label">
                {getLangText('selectedProductLabel')}
            </div>
            <button
                type="button"
                onClick={showModal}
                className="rounded-[8px] border border-[#DFE1E4] p-3 hover:bg-gray-50 transition-all w-full text-left"
            >
                <div className="flex items-center">
                    <figure className="product-avatar">
                        {values.selectedProduct.photo_small && values.selectedProduct.photo_small !== '0' ?
                            <ServerImage src={values.selectedProduct.photo_small}/> :
                            <img src="/assets/images/default/product.svg"/>
                        }
                    </figure>
                    <div>
                        <div className="font-bold text-sm">
                            {values.selectedProduct.name}
                        </div>
                        <div className="text-xs text-desc">
                            {getLangText('availableLabel')}: <strong>{values.selectedProduct.squ}</strong><span
                            className="text-[8px]">{' • '}</span>
                            <strong>{getPrice(values.selectedProduct.price)}</strong>
                        </div>
                    </div>
                    <div className="button border-button small-size no-hover ml-auto">
                        {getLangText('customerBasketChangeButton')}
                    </div>
                </div>
            </button>
        </div>
    );
};

export default SelectedProduct;
