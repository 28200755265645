import {convertToUserTimeZone} from "../../components/app/shared/helpers/dateHelpers";
import moment from "moment";

export const trialEnding = (date, trialDays = 7) => {
	const current = convertToUserTimeZone().startOf('day')
	const preparedDate = convertToUserTimeZone(date).toDate();
	const stopDate = convertToUserTimeZone(preparedDate, "YYYY-MM-DD").add(trialDays, 'days');

	return Math.ceil(convertToUserTimeZone.duration(stopDate.diff(current)).asDays());
}

export const getDaysFromToday = (endDate) => {
	const given = convertToUserTimeZone(endDate, "YYYY-MM-DD");
	const current = convertToUserTimeZone().startOf('day');

	return Math.ceil(moment.duration(given.diff(current)).asDays());
}
