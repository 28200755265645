import React from 'react';
import useLang from "../../../../../../../src_shared/hooks/useLang";
import CheckboxField from "../../../../../../../src_shared/components/form/checkboxField/CheckboxField";
import MessageRow from "./components/MessageRow";
import {createNewId} from "../../../../../live/details/default/liveDetailsReducer";
import cn from "clsx";

const CommentReply = ({setValue, values, setNestedValue, updateValues, noInteractionSelectedError}) => {
    const {getLangText} = useLang();

    const checkboxLabel = () => (
        <div className="text-sm -mt-[3px]">
            <div className="font-bold text-[#212529] space-x-0.5">
                {getLangText('addAnswerToCommentLabel')}
            </div>
            <div className="text-desc font-medium">{getLangText('automaticallyAnswerToCommentLabel')}</div>
        </div>
    )

    const addNewVariant = () => updateValues({
        reply_comment_messages: [...values.reply_comment_messages, {id: createNewId(), message: ''}]
    });

    return (
        <div className={cn('rounded-[8px] border p-3 pt-[18px]', noInteractionSelectedError ? 'border-[#F87171]' : 'border-[#DFE1E4]')}>
            <CheckboxField
                className="align-items-start mb-0"
                name="reply_comment"
                LabelComponent={checkboxLabel()}
                setValue={setValue}
                value={values.reply_comment}
            />
            {!!+values.reply_comment &&
                <div className="animate-tutorialSlideAndFadeIn mt-3 sm:pl-[24px]">
                    <MessageRow
                        setNestedValue={setNestedValue}
                        values={values}
                        setValue={setValue}
                    />
                    <button
                        className="button add-new-row text-blue-button"
                        onClick={addNewVariant}
                        type="button"
                    >
                        {getLangText('addAnswerLabel')}
                    </button>
                </div>
            }
        </div>
    );
};

export default CommentReply;
