import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import LivesOverlayList from "../app/liveOverlay/list/List";
import {RouterPaths} from "./RouterPath";
import LivesOverlayForm from "../app/liveOverlay/form/Form";
import SelmoProRoute from "./SelmoProRoute";
import {USER_PERMISSIONS_LIVE_OVERLAY_MENU_VISIBILITY} from "../app/shared/enums/UserPermissionsTypes";
import UserPermissionRoute from "./UserPermissionRoute";
import {SELMO_APP_PERMISSION} from "../app/shared/enums/SelmoPermissions";
import PermissionRoute from "./PermissionRoute";

const LiveOverlayRoute = ({path}) => {

    return (
        <PermissionRoute
            path={path}
            allowedPermissions={[SELMO_APP_PERMISSION]}
        >
            <UserPermissionRoute
                allowedPermissions={[USER_PERMISSIONS_LIVE_OVERLAY_MENU_VISIBILITY]}
                path={path}
            >
                <Switch>
                    <Route
                        exact
                        path={path}
                        component={LivesOverlayList}
                    />
                    <SelmoProRoute
                        exact
                        path={RouterPaths.LiveOverlayForm}
                        component={LivesOverlayForm}
                    />
                    <Redirect to={path}/>
                </Switch>
            </UserPermissionRoute>
        </PermissionRoute>
    );
};

export default LiveOverlayRoute;
