import React from 'react';
import Modal from "react-bootstrap/Modal";
import Loader from "../../../shared/Loader";
import useLang from "../../../../../src_shared/hooks/useLang";
import useFormLocal from "../../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../../RestServices";
import {showAlert} from "../../../shared/components/alert/AlertActions";
import useFetchData from "../../../../../src_shared/hooks/useFetchData";
import ComponentLoader from "../../../shared/components/componentLoader/ComponentLoader";
import {useDispatch} from "react-redux";
import ShipmondoLabelModel from "../../../../../modules/models/order/ShipmondoLabelModel";

const TemplateModal = ({modalVisible, hideModal, orderId, loadData, shopOrderId}) => {
    const {getLangText} = useLang();
    const dispatch = useDispatch();
    const afterSubmit = () => {
        loadData()
        hideModal()
    }

    const [{onSave, isWaiting, setValue, values, updateValues}] = useFormLocal({
        rest: `${services.API_SHIPMENT_SHIPMONDO_LABEL}/${orderId}`,
        model: ShipmondoLabelModel,
        initialAutoLoad: false,
        afterSubmit,
        onError: (e) => dispatch(showAlert(e.response?.data?.message, '', 'alert-fail'))
    });

    const [{isLoading}] = useFetchData({
        rest: `${services.API_SHIPMENT_SHIPMONDO_TEMPLATES}/${shopOrderId}`,
        afterSubmit: (items) => updateValues({items})
    })

    return (
        <Modal
            dialogClassName="default-modal modal-dialog-centered default-modal selmo-delete-modal"
            contentClassName="modal-content sellfie-form"
            show={modalVisible}
            onHide={hideModal}
        >
            <Modal.Header className="mb-4">
                <Modal.Title>
                    {getLangText('selectTemplateLabel')}
                </Modal.Title>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            <form id="orlen-label-form" onSubmit={onSave}>
                <Modal.Body>
                    {isLoading ?
                        <ComponentLoader height={200}/> :
                        !!values.items?.length ?
                            <div className="data-boxes-wrapper separated small-padd">
                                {values.items?.map((item) => (
                                    <div
                                        key={item.id}
                                        className={`content ${values.template_id === item.id ? 'active' : ''}`}
                                    >
                                        <div className="content__top-part">
                                            <input
                                                name="orlen-label"
                                                onChange={() => setValue('template_id', item.id)}
                                                checked={values.template_id === item.id}
                                                type="radio"
                                                required
                                            />
                                            <span className="checkmark"/>
                                            <div className="flex-grow-1 text-xs">
                                                <div className="label text-xs">{item.name}</div>
                                                {/*<div className="text-desc font-normal">{item.sizes}</div>*/}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div> :
                            <div
                                className="form-info-box align-items-center w-100 small-line-height">
                                <div>
                                    <i className="icon-info-c"/>
                                </div>
                                <div>
                                    <div className="title">{getLangText('noTemplates')}</div>
                                </div>
                            </div>
                    }
                </Modal.Body>
                <Modal.Footer className="open-cart-footer-modal mt-4">
                    <button
                        onClick={hideModal}
                        type="button"
                        className="button border-button"
                    >
                        {getLangText('cancelButton')}
                    </button>
                    <button
                        disabled={!values.template_id}
                        type="submit"
                        style={{width: 'auto'}}
                        className={`button primary w-full ${!values.template_id ? 'not-active' : ''} ${isWaiting ? 'loading-button' : ''}`}
                    >
                        {isWaiting && <Loader isLoading/>}
                        {getLangText('createLabelLabel')}
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default TemplateModal;
