import React, {useEffect} from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import {RouterPaths} from "./RouterPath";
import TutorialWrapper from "../app/login/tutorial/TutorialWrapper";
import {posthogOptions} from "../app/AppWrapper";
import {PostHogProvider} from "posthog-js/react";
import Alert from "../app/shared/components/alert/Alert";
import CreateShopFirstStep from "../app/tutorial/createShopFirstStep/CreateShopFirstStep";
import CreateShopSecondStep from "../app/tutorial/createShopSecondStep/CreateShopSecondStep";
import ConnectYourPage from "../app/tutorial/connectYourPage/ConnectYourPage";
import TutorialStart from "../app/tutorial/finalSteps/tutorialStart/TutorialStart";
import TutorialOrder from "../app/tutorial/finalSteps/tutorialOrder/TutorialOrder";
import Facebook from "../app/tutorial/connectYourPage/facebook/Facebook";
import Instagram from "../app/tutorial/connectYourPage/instagram/Instagram";
import FinalStepsWrapper from "../app/tutorial/finalSteps/FinalStepsContext";
import {removeFromLS} from "../app/shared/helpers/ls";
import LastStepPage from "../app/tutorial/finalSteps/lastStep/LastStepPage";
import ConnectInstagram from "../app/login/instagram/ConnectInstagram";
import InstagramWaitingForLogin from "../app/login/instagram/InstagramWaitingForLogin";

const TutorialRoute = () => {

    useEffect(() => {
        removeFromLS('lang_auth')
    }, []);

    return (
        <PostHogProvider
            apiKey="phc_ePheWHOSpLMyVFJGGQqZIHyP7SnUD9jGP2mIFVnu4Ei"
            options={posthogOptions}
        >
            <TutorialWrapper>
                <Switch>
                    <Route exact path={RouterPaths.Tutorial} component={CreateShopFirstStep}/>
                    <Route exact path={RouterPaths.TutorialDetails} component={CreateShopSecondStep}/>
                    <Route exact path={RouterPaths.TutorialSocials} component={ConnectYourPage}/>
                    <Route exact path={RouterPaths.TutorialFacebook} component={Facebook}/>
                    <Route exact path={RouterPaths.TutorialInstagram} component={Instagram}/>
                    <Route exact path={RouterPaths.TutorialInstagramConnect} component={ConnectInstagram}/>
                    <Route exact path={RouterPaths.TutorialInstagramProgress} component={InstagramWaitingForLogin}/>
                    <Route>
                        <FinalStepsWrapper>
                            <Switch>
                                <Route exact path={RouterPaths.TutorialStart} component={TutorialStart}/>
                                <Route exact path={RouterPaths.TutorialOrder} component={TutorialOrder}/>
                                <Route exact path={RouterPaths.TutorialBuilding} component={LastStepPage}/>
                                <Redirect to={RouterPaths.Tutorial}/>
                            </Switch>
                        </FinalStepsWrapper>
                    </Route>
                </Switch>
                <Alert/>
            </TutorialWrapper>
        </PostHogProvider>
    );
};

export default TutorialRoute;
