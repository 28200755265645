import React, {useEffect, useState} from "react";
import useForm from "../../../../src_shared/form/useForm";
import FormActions from "./FormActions";
import {useDispatch, useSelector} from "react-redux";
import Modal from "react-bootstrap/Modal";
import SharedEditProductForm from "../shared/SharedEditProductForm";
import SwitchField from "../../../../src_shared/components/form/switchField/SwitchField";
import {selmoUrl} from "../../../../src_shared/api/api";
import {services} from "../../../RestServices";
import EditProductFormActions from "../form/edit/FormActions";
import useLang from "../../../../src_shared/hooks/useLang";
import api from "../../../../services/axios/axios";
import {showAlert} from "../../shared/components/alert/AlertActions";
import {useHistory} from "react-router-dom";
import {getStateRoot} from "../form/edit/formReducer";
import _ from "lodash";
import {createSelector} from "reselect";
import SubmitButton from "./components/SubmitButton";
import {getGETParamsUrl} from "../../../../src_shared/list/ListActions";
import {filesSetModalVisibility} from "../../shared/header/files/filesDropdown/FilesActions";

export const PRODUCT_MODAL_IN_PRODUCTS_PLACE_TYPE = 'PRODUCT_MODAL_IN_PRODUCTS_PLACE_TYPE'
export const PRODUCT_MODAL_IN_AUTOMATION_PLACE_TYPE = 'PRODUCT_MODAL_IN_AUTOMATION_PLACE_TYPE'

const ProductModalForm = ({
                              modalVisible, hideModal, afterSubmit = () => {
    }, type = PRODUCT_MODAL_IN_PRODUCTS_PLACE_TYPE
                          }) => {
    const dispatch = useDispatch();
    const {getLangText} = useLang();
    const history = useHistory();
    const {uniqueProductCodeError, emptyData} = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));

    const {
        data: {values, setValue, setNestedValue, updateValues},
        form: {isWaiting, getFieldProps},
        validation,
    } = useForm({
        FormActions,
        params: {}
    });

    const onSave = async (e, qrCode = false) => {
        e.preventDefault();
        const notDeletedSizes = values.sizes.filter((i) => !+i.deleted);

        dispatch(FormActions.validation.activateValidation());

        if (uniqueProductCodeError) {
            dispatch(showAlert('enteredCodeIsAlreadyUsedLabel', '', 'alert-fail'))
            console.error('Validation failed!');
            return
        }

        if (_.uniqBy(notDeletedSizes, 'sizeName').length !== notDeletedSizes.length) {
            dispatch(showAlert('variantsHaveTheSameProductCodesLabel', 'setUniqueValuesLabel', 'alert-fail'))
            console.error('Validation failed!');
            return
        }

        if (!FormActions.dataModel.validateData(values)) {
            dispatch(showAlert('fillRequireFieldWarning', '', 'alert-fail'))
            console.error('Validation failed!');
            return;
        }

        const filteredData = Object.entries(values).filter(([key]) => key !== 'id');
        const finalData = Object.fromEntries(filteredData)
        const filteredEmptyData = Object.entries(emptyData).filter(([key]) => key !== 'id');
        const finalEmptyData = Object.fromEntries(filteredEmptyData)

        const preparedData = FormActions.dataModel.buildDTO(finalData, finalEmptyData)

        dispatch(FormActions.waiting.startWaiting());

        const params = {}

        if (qrCode) {
            params.qr_code = '1';
        }

        try {
            const {data} = await api.post(`${selmoUrl}/${FormActions.restService}${getGETParamsUrl(params)}`, preparedData)
            if (type === PRODUCT_MODAL_IN_AUTOMATION_PLACE_TYPE) {
                dispatch(showAlert())
                afterSubmit();
                return
            }
            FormActions.updateRoute(data, history)
            if (qrCode) {
                dispatch(filesSetModalVisibility(true))
            } else {
                dispatch(showAlert())
            }
            dispatch(FormActions.afterSubmit(values.id, values))
            dispatch(FormActions.validation.deactivateValidation())
            afterSubmit();
        } catch (e) {
            if (e?.response?.data?.message) {
                dispatch(showAlert(e.response.data.message, '', 'alert-fail'))
                return;
            }
            dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
            console.error('Error submitEditForm')
        } finally {
            dispatch(FormActions.waiting.stopWaiting());
        }
    }

    const [waitingForCode, setWaitingForCode] = useState(false);

    const generateCode = async () => {
        setWaitingForCode(true)
        dispatch(EditProductFormActions.setUniqueProductCodeError(null))
        try {
            const {data} = await api.get(`${selmoUrl}/${services.API_PRODUCT_CODE}`,)
            setValue('selmo_code', data.code)
        } catch (e) {

        } finally {
            setWaitingForCode(false);
        }
    }

    // useEnterSubmitForm(onSave, values);

    useEffect(() => {
        generateCode()
    }, [])

    return (
        <Modal
            show={modalVisible}
            className="add-order-modal"
            dialogClassName="default-modal"
            onHide={hideModal}
        >
            <Modal.Header>
                <Modal.Title>
                    {getLangText('addProductLabel')}
                    <div
                        className="text-[#9CA3AF] text-sm font-medium mt-1">{getLangText('moreOptionsInProductPageLabel')}</div>
                </Modal.Title>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            <form onSubmit={onSave}>
                <Modal.Body className="add-product-modal-body">
                    <SharedEditProductForm
                        FormActions={FormActions}
                        getFieldProps={getFieldProps}
                        setValue={setValue}
                        setNestedValue={setNestedValue}
                        values={values}
                        generateCode={generateCode}
                        validation={validation}
                        waitingForCode={waitingForCode}
                        updateValues={updateValues}
                        inModal
                        type={type}
                    />
                    <SwitchField
                        {...getFieldProps('active')}
                        setValue={setValue}
                        label={getLangText('activeLabel')}
                        id="active"
                        className="switch-form inline-label big-label light-version mb-0 mt-3"
                    />
                </Modal.Body>
                <Modal.Footer>
                    <button
                        onClick={hideModal}
                        type="button"
                        className="button border-button"
                    >
                        {getLangText('cancelButton')}
                    </button>
                    <SubmitButton
                        isWaiting={isWaiting}
                        onSave={onSave}
                    />
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default ProductModalForm;



