import React from 'react';
import RegulationWrapper from "../RegulationWrapper";
import Ckeditor from "../../../../../src_shared/components/form/ckeditor/Ckeditor";
import useDataApi from "../../../../../src_shared/hooks/useDataApi";
import {services} from "../../../../RestServices";
import useLang from "../../../../../src_shared/hooks/useLang";
import ShopNameBar from "../../../../routes/shopSettingsRoute/ShopNameBar";

const Regulation = () => {
	const {getLangText} = useLang();

	const [{data, isLoading, setData, fetchData}] = useDataApi(`${services.API_STORE_REGULATION}`, '')

	return (
		<>
			<ShopNameBar redirect="terms/"/>
			<RegulationWrapper
				rest={services.API_STORE_REGULATION}
				value={{terms: data}}
				isLoading={isLoading}
				fetch={fetchData}
			>
				<h2 className="label mb-3">{getLangText('customerBasketRegulationsModalTitle')}</h2>
				<Ckeditor
					className="big-min-height"
					value={data || ''}
					setValue={(field, value) => setData(value)}
					label={getLangText('contentsLabel')}
					placeholder={getLangText('typeRegulationContentLabel')}
				/>
			</RegulationWrapper>
		</>
	);
};

export default Regulation;
