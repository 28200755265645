import React from 'react';
import VideoPreview from "../../../../src_shared/components/ui/photoPreview/VideoPreview";
import useLang from "../../../../src_shared/hooks/useLang";
import usePhotoPreview from "../../../../src_shared/components/ui/photoPreview/usePhotoPreview";
import {useSelector} from "react-redux";

const ClientsVideo = () => {
    const {getLangText} = useLang();
    const {showPreviewModal, hidePreviewModal, openPreviewModal} = usePhotoPreview();
    const {lang} = useSelector((state) => state.i18n);

    return (
        <div className="default-shadow-box bigger-y-padd mb-3">
            <h2 className="font-bold mb-3.5">{getLangText('clientsVideoLabel')}</h2>
            <div className="p-3 rounded-[10px] bg-[#F6FCFF] border border-[#D7EAFE] flex items-center">
                <div className="max-w-[410px]">
                    <div className="text-xs text-[#0D3364] font-bold mb-2">{getLangText('downloadAndSharedVideoLabel')}</div>
                    <a
                        download
                        href={`/assets/videos/${lang}/notifications.mp4`}
                        className="button border-button left-icon small-size mt-[2px]">
                        <i className="icon-download"/>
                        <span className="text-xs">{getLangText('downloadVideoLabel')}</span>
                    </a>
                </div>
                <div className="sm:ml-auto sm:w-auto w-full sm:mt-0 mt-3.5">
                    <div
                        onClick={openPreviewModal}
                        className={`relative h-[80px] w-full sm:w-[80px] cursor-pointer rounded-[10px] overflow-hidden after:content-[''] after:bg-[rgba(0,0,0,0.3)] after:absolute after:top-0 after:left-0 after:right-0 after:bottom-0 after:w-full after:h-full`}>
                        <img
                            className="h-full w-full sm:w-auto object-cover"
                            src={`/assets/images/multiLanguage/${lang}/notifications-video.png`}
                            alt="preview"
                        />
                        <div
                            className="absolute z-10 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 w-[30px] h-[30px] bg-[white] rounded-full flex items-center justify-center">
                            <img
                                style={{width: '10px'}}
                                className="pointer-events-none  group-hover:scale-[115%] transition-all"
                                src="/assets/images/svg/play-black.svg"
                                alt="play"
                            />
                        </div>
                    </div>
                    <VideoPreview
                        hide={hidePreviewModal}
                        show={showPreviewModal}
                        src={`/assets/videos/${lang}/notifications.mp4`}
                    />
                </div>
            </div>
        </div>
    );
};

export default ClientsVideo;
