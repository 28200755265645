import Model, {convertObjectProperties} from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";
import {value} from "lodash/seq";

class SettingsNipChooserModel extends Model {
	getModel() {
		return {
			has_nip: true,
			name: '',
			nip: '',
			address: '',
		};
	}

	getValidators() {
		return {
			nip: (value, data) => !!+data.has_nip ? ValidationError.notEmpty(value, data) : ValidationError.skip(value, data),
			name: (value, data) => !+data.has_nip ? ValidationError.notEmpty(value, data) : ValidationError.skip(value, data),
			address: (value, data) => !+data.has_nip ? ValidationError.notEmpty(value, data) : ValidationError.skip(value, data),
			has_nip: ValidationError.skip
		};
	}

	buildDTO(data) {
		if (!!+data.has_nip) {
			return {
				nip: data.nip,
				has_nip: '1',
			}
		}
		return {
			has_nip: '0',
			name: data.name,
			address: data.address,
		};
	}
}

export default new SettingsNipChooserModel();
