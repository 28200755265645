import React from 'react';
import {usePrintFile} from "../../../../../../../src_shared/hooks/usePrintFile";
import {services} from "../../../../../../RestServices";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import Tooltip from "react-bootstrap/Tooltip";
import Loader from "../../../../../shared/Loader";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const Variant = ({size, values}) => {
    const {getLangText} = useLang();
    const {print, waiting: waitingForQRCode} = usePrintFile({
        rest: `${services.API_MOBILE_QR_CODE}/${values.id}?product_size_id=${size.id}`,
    })
    return (
        <div
            key={size.id}
            className="flex items-center py-1 group border-b last:border-0"
        >
            <div className="grow">{size.size}</div>
            {size.shelf_id ?
                <div className="">{size.shelf_name}</div>:
                <div
                    className={`rounded-[5px] inline-block p-1 text-xs font-bold bg-[#FEF2F2] text-[#981B1B]`}>
                    {getLangText('lackLabel')}
                </div>
            }
            <OverlayTrigger
                placement='top'
                overlay={
                    <Tooltip id="qr-code">
                        {getLangText('generateQRCodeLabel')}
                    </Tooltip>
                }
            >
                <button
                    onClick={print}
                    type="button"
                    className={`button w-[28px] h-[28px] bg-[#fff] px-2 border-button ml-2 ${waitingForQRCode ? 'pointer-events-none' : ''}`}
                >
                    {waitingForQRCode && <Loader/>}
                    <i className="icon-qr-code m-0"/>
                </button>
            </OverlayTrigger>
        </div>
    );
};

export default Variant;