import ListReducer from "../../../../../src_shared/list/ListReducer.js";
import createReducer from "../../../shared/helpers/createReducer.js";
import Composition from "../../../shared/helpers/Composition.js";

export const getStateRoot = (state) => state.products.lists.reserveProductsList;
export const prefix = 'PRODUCTS_RESERVE_LIST_';

const defaultState = {
	sortBy: 'date',
	sortDir: 'desc',
	totalPrice: null,
}

const getInitState = () => ListReducer.getInitState(defaultState);

const getReduceMap = () =>  new Composition(
	ListReducer.getReduceMap(),
	{
		SET_TOTAL_PRICE: (state, action) => ({...state, totalPrice: action.totalPrice})
	}
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

