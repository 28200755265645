import React, {useEffect} from 'react';
import Modal from "react-bootstrap/Modal";
import SignupForm from "../signup/SignupForm";
import {batch, useDispatch, useSelector} from "react-redux";
import {RouterPaths} from "../../../routes/RouterPath";
import {useHistory} from "react-router-dom";
import SessionActions from "../../shared/session/SessionActions";
import {removeFromLS} from "../../shared/helpers/ls";
import {EXPANSION_CLICK, EXPANSION_SIGNUP} from "./SinglePage";

const SignUpModal = ({modalVisible, hideModal, getLangText, trackEvent}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {lang} = useSelector((state) => state.i18nAuthSinglePage);
    const trackExpansionSignUp = () => trackEvent(EXPANSION_SIGNUP);
    const afterSubmit = (response) => {
        batch(() => {
            trackExpansionSignUp();
            dispatch(SessionActions.setAndSaveInStorageToken(response.token))
            removeFromLS('lang_auth');
            history.push(`${RouterPaths.Tutorial}?email=${response.email}&origin=${response.origin}`)
        })
    }

    useEffect(() => {
        trackEvent(EXPANSION_CLICK);
    }, [])

    return (
        <Modal
            dialogClassName="default-modal"
            contentClassName="rounded-xl sm:p-10 py-10"
            show={modalVisible}
            onHide={hideModal}
        >
            <Modal.Header className="absolute right-[20px] top-[20px] z-10">
                <button
                    onClick={hideModal}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross text-lg font-normal opacity-50"/>
                </button>
            </Modal.Header>
            <Modal.Body>
                <img alt="selmo" width={107} src="/assets/images/logo.svg"/>
                <h1 className="text-3xl font-bold mt-6 mb-8"
                    dangerouslySetInnerHTML={{__html: getLangText('loginModalTitle')}}/>
                <SignupForm
                    lang={lang}
                    getLangText={getLangText}
                    withSwitchButton={false}
                    afterSubmit={afterSubmit}
                    afterSocialSubmit={trackExpansionSignUp}
                />
            </Modal.Body>
        </Modal>
    );
};

export default SignUpModal;