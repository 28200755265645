import {supportedOptionLangs} from "../../../../services/i18n/i18n";

export const SHOP_ORIGIN_PL = 'pl';
export const SHOP_ORIGIN_EN = 'en';
export const SHOP_ORIGIN_IT = 'it';
export const SHOP_ORIGIN_FR = 'fr';
export const SHOP_ORIGIN_DE = 'de';
export const SHOP_ORIGIN_PT = 'pt';
export const SHOP_ORIGIN_SK = 'sk';
export const SHOP_ORIGIN_DA = 'dk';
export const SHOP_ORIGIN_US = 'us';
export const SHOP_ORIGIN_ES = 'es';

const ShopOrigins = supportedOptionLangs;

export default ShopOrigins;
