import {Model} from "../../../src_shared/modules/model/Model";
import ValidationError, {validateEmail} from "../../../src_shared/form/validation/ValidationError";


class NewsletterModel extends Model {
    getModel() {
        return {
            email: '',
            agreement: false,
        };
    }

    getValidators() {
        return {
            email: validateEmail,
            agreement: ValidationError.notFalse,
        };
    }

    buildDTO(data) {
        return {
            email: data.email,
            agreement: data.agreement,
        }
    }
}

export default new NewsletterModel();
