import createReducer from "../../../shared/helpers/createReducer.js";
import {EDIT_PRODUCT_VIEW} from "../../../shared/enums/EditProductViews";

export const getStateRoot = (state) => state.products.form.shared;
export const prefix = 'PRODUCTS_FORM_SHARED_';

const getInitState = {
	editProductView: EDIT_PRODUCT_VIEW,
	showExtraAddNewButton: false,

}

const getReduceMap = {
	SET_PRODUCT_FORM_VIEW: (state, action) => ({...state, editProductView: action.view}),
	SHOW_EXTRA_ADD_NEW_BUTTON: (state, action) => ({...state, showExtraAddNewButton: action.toggle}),
}

export const getInstance = () => createReducer(
	getInitState,
	getReduceMap,
	prefix,
);

export default getInstance();

