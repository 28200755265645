import React, {useCallback, useEffect, useRef, useState} from 'react';
import useLang from "../../../../hooks/useLang";
import Loader from "../../../loader/Loader";

const AsyncDropdown = ({
                           setValue,
                           name,
                           options,
                           setOptions,
                           setInputValue,
                           formatOptionLabel,
                           setExtraValues,
                           inputRef,
                           isOpenDropdown,
                           setIsOpenDropdown,
                           waitingForOptions,
                           withSearchInput,
                       }) => {
    const hintRef = useRef();

    const [filteredOptions, setFilteredOptions] = useState(options)

    const onKeyPress = (e, option) => {
        if (e.code === 'Enter') {
            onOptionSelect(option)
        }
    }

    const {getLangText} = useLang()

    const onKeyDown = (event) => {
        const focusableInputElements = document.querySelectorAll(`.select-custom-option`);

        const focusable = [...focusableInputElements];

        const index = focusable.indexOf(document.activeElement);

        let nextIndex = 0;
        if (event.keyCode === 40) {
            event.preventDefault();
            nextIndex = index + 1 < focusable.length ? index + 1 : index;
            focusableInputElements[nextIndex].focus();
        }
        if (event.keyCode === 38) {
            event.preventDefault();
            nextIndex = index > 0 ? index - 1 : 0;
            focusableInputElements[nextIndex].focus();
        }
    }

    const onOptionSelect = (option) => {
        setValue(name, option.id)
        setExtraValues(option)
        setInputValue(option)
        setOptions([])
        setIsOpenDropdown(false)
    }


    const handleClickOutside = useCallback((e) => {
        if (setIsOpenDropdown
            && inputRef
            && hintRef
            && inputRef.current !== e.target
            && hintRef.current !== e.target
            && !inputRef.current?.contains(e.target)
            && !hintRef.current?.contains(e.target)) {
            setIsOpenDropdown(false)
            setOptions([])
        }
    }, [setIsOpenDropdown]);

    const filterOptions = (e) => {
        const value = e.target.value;
        const preparedFilteredArray = options.filter(i => i.name.toLowerCase().search(value?.toLowerCase()) !== -1);
        if (!!value) {
            setFilteredOptions(preparedFilteredArray);
        } else {
            setFilteredOptions(options)
        }
    }

    useEffect(() => {
        document.body.addEventListener('click', handleClickOutside);
        return () => {
            document.body.removeEventListener('click', handleClickOutside);
        };
    }, [handleClickOutside]);

    useEffect(() => {
        setFilteredOptions(options)
    }, [options])

    return (
        <div
            className={`custom-dropdown ${isOpenDropdown && !!options?.length ? 'opened-custom-dropdown' : ''}`}
            ref={hintRef}
        >
            <div className={`loader-parent`}>
                {isOpenDropdown &&
                    (waitingForOptions ?
                        <div className="flex items-center justify-center min-h-[50px]">
                            <Loader
                                circleClassName="ml-auto mr-auto"
                                className="small-loader static-loader transform-none"
                                isLoading={true}
                            />
                        </div> :
                        <>
                            {withSearchInput && options?.length > 5 &&
                                <div className="-mx-[5px] p-[5px] bg-[white] sticky top-[-5px] -mt-[5px] z-[1]">
                                    <div
                                        className="bg-[#F3F4F6] rounded-[5px] h-[36px] text-[12px] font-medium flex px-2">
                                        <i className="icon-magnifier self-center mr-2 text-[12px] text-[#D0D5DC]"/>
                                        <input
                                            autoFocus
                                            onChange={filterOptions}
                                            type="text"
                                            placeholder={getLangText('searchPlaceholder')}
                                            className="placeholder:text-[#6B7280] grow bg-[transparent] shadow-none outline-0"
                                        />
                                    </div>
                                </div>
                            }
                            {!!options?.length ?
                                filteredOptions.map((option) => (
                                    <div
                                        onKeyPress={(e) => onKeyPress(e, option)}
                                        onKeyDown={onKeyDown}
                                        onClick={() => onOptionSelect(option)}
                                        className="select-custom-option"
                                        key={option.id}
                                        tabIndex="0"
                                    >
                                        <div>
                                            {formatOptionLabel(option)}
                                        </div>
                                    </div>
                                )) :
                                <div
                                    tabIndex="0"
                                    className="select-custom-option no-result-message">
                                    <div>
                                        {getLangText('noResultsLabel')}
                                    </div>
                                </div>
                            }
                        </>)
                }
            </div>
        </div>
    );
};

AsyncDropdown.defaultProps = {
    formatOptionLabel: (option) => (
        <div className="name">
            {option.name}
        </div>
    ),
    setExtraValues: (option) => {
    },
    options: [],
}

export default AsyncDropdown;
