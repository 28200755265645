export const PRODUCTS_TYPE_ALL = 'all';
export const PRODUCTS_TYPE_INSTOCK = 'instock';
export const PRODUCTS_TYPE_NONE = 'none';

export const PRODUCTS_TYPES = [
	{
		value: PRODUCTS_TYPE_ALL, label: 'allLabel',
	},
	{
		value: PRODUCTS_TYPE_INSTOCK, label: 'inStockLabel',
	},
	{
		value: PRODUCTS_TYPE_NONE, label: 'lackLabel',
	},
]
