import React from 'react';
import useLang from "../../../../../src_shared/hooks/useLang";
import Box from "./Box";

const PaymentHistory = ({data}) => {
    const {getLangText} = useLang();

    if (!data.history?.length) {
        return null;
    }

    return (
        <div className="default-shadow-box">
            <h3 className="title mb-0">{getLangText('paymentHistoryLabel')}</h3>
            <div className="text-desc text-sm mb-3">{getLangText('newestInvoices')}</div>
            <div className="space-y-2 w-full">
                {data.history.map((item) => (
                    <Box item={item} key={item.id}/>
                ))}
            </div>
        </div>
    );
};

export default PaymentHistory;
