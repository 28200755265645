import React from 'react';
import useLang from "../../../../../../src_shared/hooks/useLang";
import ActivateButton from "./ActivateButton";
import usePhotoPreview from "../../../../../../src_shared/components/ui/photoPreview/usePhotoPreview";
import VideoPreview from "../../../../../../src_shared/components/ui/photoPreview/VideoPreview";

const SelmoProLandingSingleVideoBox = ({videoSrc = null, imgSrc, title}) => {
    const {getLangText} = useLang();
    const {showPreviewModal, hidePreviewModal, openPreviewModal} = usePhotoPreview();

    return (
        <div className="sm:px-[215px] pb-12 sm:pt-24 pt-16 px-6">
            <div className="text-center mb-8">
                <img
                    style={{width: 'auto', height: 24}}
                    className="mb-2 mx-auto"
                    src="/assets/images/selmo-pro-logo.png"
                    alt="logo-selmo"
                />
                <div
                    className="text-[#fff] whitespace-pre-wrap font-bold selmo-pro-landing-title text-[30px] leading-[34px] sm:text-[36px] sm:leading-[42px] mb-3"
                    dangerouslySetInnerHTML={{__html: getLangText(title)}}
                />
            </div>
            <div className="relative">
                <div className=" before:content-[''] before:pointer-events-none before:absolute before:bottom-0 before:top-0 before:left-0 before:right-0 before:bg-[rgba(0,0,0,0.5)]">
                    <img
                        className="rounded-[15px] cursor-pointer"
                        onClick={openPreviewModal}
                        src={imgSrc}
                        alt=""
                    />
                </div>
                {videoSrc &&
                    <img
                        className="absolute pointer-events-none sm:w-auto sm:h-auto w-[62px] h-[62px] left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 group-hover:scale-105 transition-all duration-200"
                        src="/assets/images/svg/play-white.svg"
                        alt="play"
                        width={84}
                        height={84}
                    />
                }
            </div>
            {videoSrc &&
                <VideoPreview
                    src={videoSrc}
                    hide={hidePreviewModal}
                    show={showPreviewModal}
                    bigSize={true}
                />
            }
        </div>
    );
};

export default SelmoProLandingSingleVideoBox;
