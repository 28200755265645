import {getStateRoot, prefix} from './listReducer.js';
import {RouterPaths} from "../../../../routes/RouterPath.js";
import {services} from "../../../../RestServices.js";
import ListActions, {getGETParamsUrl} from "../../../../../src_shared/list/ListActions";
import {getStateRoot as getFiltersStateRoot} from "../../filters/filtersReducer";
import {convertDate} from "../../../../app/shared/helpers/dateHelpers";
import history from "../../../../history";


export class TeamMemberHistoryListActions extends ListActions {
	getLoadParams(state, id) {

		const params = super.getLoadParams(state);
		const dateFrom = getFiltersStateRoot(state).data.fromDate;
		const dateTo = getFiltersStateRoot(state).data.toDate;

		if (dateFrom) {
			params.dateFrom = convertDate(dateFrom);
		}

		if (dateTo) {
			params.dateTo = convertDate(dateTo);
		}

		if (!this.preventPushParams) {
			history.replace({
				pathname: id ? `${this.pathname.replace(':id', id)}` : this.pathname,
				search: getGETParamsUrl(params)
			});
		}

		return params;
	}
}

export const getInstance = () => new TeamMemberHistoryListActions({
	getStateRoot,
	restService: services.API_PERMISSIONS_EMPLOYEE_HISTORY,
	pathname: RouterPaths.SettingsTeamMemberHistory,
	prefix,
});

export default getInstance();
