import {getStateRoot, prefix} from "./formReducer.js";
import {services} from "../../../../RestServices.js";
import FormActions from "../../../../../src_shared/form/FormActions";
import DeliveryAddressModel from "../../../../../modules/models/DeliveryAddressModel";
import {selmoUrl} from "../../../../../src_shared/api/api";
import {getStateRoot as getEditProductStateRoot} from "../form/formReducer";
import {batch} from "react-redux";
import EditProductFormActions from "../form/FormActions";
import api from "../../../../../services/axios/axios";

export class OrderDetailsDeliveryAddressFormActions extends FormActions {
	loadCountries() {
		return async (dispatch) => {
			// dispatch(this.waiting.startWaiting());
			try {
				const {data} = await api.get(`${selmoUrl}/${services.API_ORDER_COUNTRIES}`)
				dispatch(this.data.setValue('countries', data.item));
			} catch (e) {
				dispatch(this.onErrorLoad(e))
				console.error('Error loadData')
			} finally {
				dispatch(this.waiting.stopWaiting());
			}
		}
	}

	afterSubmit() {
		return (dispatch, state) => {
			const {data} = getEditProductStateRoot(state());
			batch(() => {
				dispatch(this.modal.hideModal())
				dispatch(EditProductFormActions.loadData(data.shop_order_id))
			})
		}
	}
}

export const getInstance = () => new OrderDetailsDeliveryAddressFormActions({
	getStateRoot,
	prefix,
	model: DeliveryAddressModel,
	restService: services.API_ORDER_DETAILS,
});

export default getInstance();
