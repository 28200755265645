import React from 'react';
import useSubmitData from "../../../../../src_shared/hooks/useSubmitData";
import {services} from "../../../../RestServices";
import SessionActions from "../../../shared/session/SessionActions";
import {useDispatch} from "react-redux";
import {RouterPaths} from "../../../../routes/RouterPath";
import {useHistory} from "react-router-dom";

const useCreatePost = (callback = (res) => {}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [{onSave: createPost, isWaiting: isWaitingForPost}] = useSubmitData({
        rest: services.API_VIDEO_SOCIAL_VIDEOS,
        afterSubmit: (response) => {
            callback(response);
            history.push(`${RouterPaths.SalesPost}/${response.id}`)
            dispatch(SessionActions.updateUserData('first_step_sales_posts', false))
        }
    });

    return {
        createPost,
        isWaitingForPost,
    }
};

export default useCreatePost;
