import ListActions, {getGETParamsUrl} from "../../../../../src_shared/list/ListActions.js";
import {getStateRoot, prefix} from './listReducer.js';
import {RouterPaths} from "../../../../routes/RouterPath.js";
import {services} from "../../../../RestServices.js";
import {getStateRoot as getFiltersStateRoot} from "../../filters/filtersReducer";
import history from "../../../../history";


export class ClientsListListActions extends ListActions {
    getLoadParams(state, id) {
        const params = super.getLoadParams(state);
        const is_deleted = getFiltersStateRoot(state).data.is_deleted

        if (is_deleted && is_deleted !== 'all') {
            params.is_deleted = is_deleted
        }

        if (!this.preventPushParams) {
            history.replace({
                pathname: id ? `${this.pathname.replace(':id', id)}` : this.pathname,
                search: getGETParamsUrl(params)
            });
        }

        return params;
    }
}

export const getInstance = () => new ClientsListListActions({
    getStateRoot,
    restService: services.API_CLIENTS,
    pathname: RouterPaths.ClientsList,
    prefix,
    defaultSortBy: 'shop_client_id',
});

export default getInstance();
