import FormActions from "../../../../src_shared/form/FormActions";
import {showAlert} from "../../shared/components/alert/AlertActions";
import {selmoUrl} from "../../../../src_shared/api/api";
import {PAYMENT_SUBSCRIPTION_PAYPAL, PAYMENT_SUBSCRIPTION_TRANSFER} from "../../shared/enums/PaymentsType";
import {services} from "../../../RestServices";
import {batch} from "react-redux";
import api from "../../../../services/axios/axios";
import {getStateRoot} from "../formReducer";
import {ERROR_NOT_FOUND} from "../../../../src_shared/enums/ErrorEnums";

export class PaymentsSharedFormActions extends FormActions {

	loadData(id) {
		return async (dispatch) => {
			dispatch(this.waiting.startWaiting());
			dispatch(this.onFirstInit());
			const restUrl = id ? `${this.restService}/${id}` : this.restService;
			try {
				const {data} = await api.get(`${selmoUrl}/${restUrl}`)

				if (!!data?.item?.redirect_url) {
					window.location.href = data.item.redirect_url
				} else {
					const parsedItem = dispatch(this.parseData(data.item));
					dispatch(this.data.updateValues(parsedItem))

				}
			} catch (e) {
				if (e.response?.data?.message === ERROR_NOT_FOUND || e.response?.status === 500) {
					dispatch(this.data.setValue('notFound', true));
				}
				dispatch(this.onErrorLoad(e))
				console.error('Error loadData')
			} finally {
				dispatch(this.waiting.stopWaiting());
			}
		}
	}

	setWaitingForTotalPrice(toggle) {
		return {
			type: `${this.prefix}WAITING_FOR_TOTAL_PRICE`,
			toggle,
		};
	}

	setTransferPage(toggle) {
		return {
			type: `${this.prefix}SET_TRANSFER_PAGE`,
			toggle,
		};
	}

	afterSubmit() {
		return (dispatch) => {
			batch(() => {
				dispatch(this.waiting.stopWaiting());
				dispatch(this.setWaitingForTotalPrice(false))
			})
		}
	}

	waitingForPayment(toggle) {
		return {
			type: `${this.prefix}SET_WAITING_FOR_PAYMENT`,
			toggle,
		};
	}

	setNotValidNip(toggle) {
		return {
			type: `${this.prefix}SET_NOT_VALID_NIP`,
			toggle,
		};
	}

	submitEditForm(values) {
		return async (dispatch, getState) => {
			dispatch(this.validation.activateValidation());
			const {summaryInfo} = getStateRoot(getState());

			if (!this.dataModel.validateData(values)) {
				dispatch(showAlert('fillRequireFieldWarning', '', 'alert-fail'))
				console.error('Validation failed!');
				return;
			}

			const filteredData = Object.entries(values).filter(([key]) => key !== 'id');
			const finalData = Object.fromEntries(filteredData)

			const preparedData = this.dataModel.buildDTO(finalData)
			const windowOrigin = window.location.pathname;

			batch(() => {
				dispatch(this.waiting.startWaiting());
				dispatch(this.waitingForPayment(true));
				dispatch(this.setWaitingForTotalPrice(true))
			})

			try {
				const {data} = await api.post(`${selmoUrl}/${services.API_PAYMENTS}`, {...preparedData, windowOrigin})
				dispatch(this.validation.deactivateValidation())

				if (values.selected_payment === PAYMENT_SUBSCRIPTION_TRANSFER) {
					dispatch(this.setTransferPage(true))
					dispatch(this.afterSubmit());
					return;
				}

				if (values.selected_payment === PAYMENT_SUBSCRIPTION_PAYPAL) {
					window.location.href = `https://paypal.me/selmolive/${summaryInfo.totalPriceGross}eur`;
					return;
				}

				window.location.href = data.item;

			} catch (e) {
				dispatch(this.afterSubmit());
				if (e.response.data.message === 'NOT_VALID_NIP') {
					batch(() => {
						dispatch(showAlert('incorrectNipWarning', 'checkNipValidLabel', 'alert-fail'))
						dispatch(this.validation.activateValidation());
						dispatch(this.setNotValidNip(true));
					})
					return;
				}
				dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
				dispatch(this.validation.deactivateValidation())
				console.error('Error submitEditForm')
			}
		}
	}
}

export default PaymentsSharedFormActions;
