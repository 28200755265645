import React, {useEffect, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import Loader from "../../../../shared/Loader";
import {useDispatch} from "react-redux";
import FormActions from "./FormActions";
import useDataApi from "../../../../../../src_shared/hooks/useDataApi";
import {services} from "../../../../../RestServices";
import TextArea from "../../../../../../src_shared/components/form/textarea/TextArea";
import useLang from "../../../../../../src_shared/hooks/useLang";

const SendVoiceMessageModal = ({
								   modalVisible, hideModal, values, isWaiting, userData
							   }) => {
	const dispatch = useDispatch();
	const {getLangText} = useLang();
	const [{data, isLoading}] = useDataApi(`${services.API_CLIENTS_PAYMENTS_AUDIO}/${values.id}`, {
		message: ''
	})

	const [value, setValue] = useState(data.message);

	useEffect(() => {
		if (data.message) {
			 setValue(data.message)
		}
	}, [data.message])

	const setNewPaymentAndSendMessage = () => dispatch(FormActions.setNewPaymentAndSendMessage(value));


	return (<Modal
		dialogClassName="default-modal small-modal"
		show={modalVisible}
		onHide={hideModal}
	>
		<Modal.Header className="with-border">
			<Modal.Title>
				{getLangText('sendMessageLabel')}
			</Modal.Title>
			<button
				onClick={hideModal}
				type="button"
				className="button"
			>
				<i className="icon-cross"/>
			</button>
		</Modal.Header>
		<Modal.Body className="small-text">
			<div>{getLangText('sendVoiceMessageModalLabel')}</div>
			{getLangText('paymentLabel')}: <strong>{values.new_payment_received} {userData.currency_info.text}</strong>
			<div className="loader-parent min-height-auto">
				<Loader className="small-loader" isLoading={isLoading}/>
				<TextArea
					label={getLangText('messageForClientLabel')}
					name=""
					value={value}
					setValue={(field, value) => setValue(value)}
					className="mt-2"
				/>
			</div>
		</Modal.Body>
		<Modal.Footer>
			<button
				onClick={hideModal}
				type="button"
				className="button border-button"
			>
				{getLangText('cancelButton')}
			</button>
			<button
				onClick={setNewPaymentAndSendMessage}
				type="button"
				className={`button small-size primary ${isWaiting ? 'loading-button' : ''}`}
			>
				{isWaiting && <Loader isLoading={true}/>}
				{getLangText('sendButton')}
			</button>
		</Modal.Footer>
	</Modal>);
};

export default SendVoiceMessageModal;
