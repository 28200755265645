import React, {useEffect, useState} from 'react';
import CheckboxField from "../../../../../../src_shared/components/form/checkboxField/CheckboxField";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import DropdownWithPortal from "../../../../../../src_shared/components/dropdownPortal/DropdownWithPortal";
import useLang from "../../../../../../src_shared/hooks/useLang";
import {selmoUrl} from "../../../../../../src_shared/api/api";
import {services} from "../../../../../RestServices";
import {useDispatch, useSelector} from "react-redux";
import {showAlert} from "../../../../shared/components/alert/AlertActions";
import ModalWithSuccessPage from "./ModalWithSuccessPage";
import api from "../../../../../../services/axios/axios";
import useWindowDimensions from "../../../../messenger/hooks/useWindowDimensions/useWindowDimensions";
import DropdownItem from "./DropdownItem";
import {createSelector} from "reselect";
import {getStateRoot} from "../../../filters/filtersReducer";
import {PRODUCTS_TYPE_ALL} from "../../../../shared/enums/ProductsType";
import {usePrintFile} from "../../../../../../src_shared/hooks/usePrintFile";
import {MODAS_MENKANTA_SHOP_ID, TEST_ACCOUNT_SHOP_ID} from "../../../../../../src_shared/enums/TrustedShopsIds";
import {SHOP_ORIGIN_DA} from "../../../../shared/enums/ShopOrigins";
import ConfirmModal from "../../../../../../src_shared/components/modal/ConfirmModal";

const REMOVE_PRODUCTS = 'REMOVE_PRODUCTS';
const DEACTIVATE_PRODUCTS = 'DEACTIVATE_PRODUCTS';
const ACTIVATE_PRODUCTS = 'ACTIVATE_PRODUCTS';
const ACTIVE_IN_SHOP_PRODUCTS = 'ACTIVE_IN_SHOP_PRODUCTS';
const DEACTIVATE_IN_SHOP_PRODUCTS = 'DEACTIVATE_IN_SHOP_PRODUCTS';
const ACTIVE_IN_APP_PRODUCTS = 'ACTIVE_IN_APP_PRODUCTS';
const DEACTIVATE_IN_APP_PRODUCTS = 'DEACTIVATE_IN_APP_PRODUCTS';
const CREATE_ORDERS = 'CREATE_ORDERS';
const REMOVE_FROM_RESERVE_LIST = 'REMOVE_FROM_RESERVE_LIST';
const PRINT_PRODUCT_CARDS = 'PRINT_PRODUCT_CARDS';

const SelectedRow = ({
                         isSelected,
                         selectedItems,
                         selectAllItems,
                         checkIfSomeExist,
                         colSpan,
                         ListActions,
                         reserveList = false,
                         updateSelection,
                         isAllSelected = false,
                         setIsAllSelectedItems = () => {
                         },
                         setIsAllSelected = () => {
                         },
                         totalItems = null,
                     }) => {
    const {getLangText} = useLang();
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState(null);
    const [successGenerated, setSuccessGenerated] = useState(false);
    const [generatedCount, setGeneratedCount] = useState(false);
    const {width} = useWindowDimensions();
    const [isWaiting, setIsWaiting] = useState(false);
    const {userData} = useSelector((state) => state.session);
    const {data: filterData} = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));

    const maxSelected = 100;
    const isMoreThanMax = selectedItems.length > maxSelected;

    const selectedItemsLength = isAllSelected ? totalItems : selectedItems.length;

    const tooltipText = getLangText({key: 'canSelectMaxProductsLabel', data: [maxSelected]});

    const getParams = () => !reserveList ? dispatch(ListActions.getParams()) : "";

    const getPreparedValues = (active = null) => {
        if (active) {
            return {
                selected_items: isAllSelected ? [] : selectedItems.map((i) => i.id),
                active,
                all_selected: isAllSelected,
            }
        }
        return {
            selected_items: isAllSelected ? [] : selectedItems.map((i) => i.id),
            all_selected: isAllSelected,
        }
    }

    const {print, waiting: waitingForPrint} = usePrintFile({
        rest: services.API_EXPORT_PRODUCTS_CARD_INFO,
        afterSubmit: () => setModalOpen(null),
        values: getPreparedValues(null),
        params: getParams(),
    })


    useEffect(() => {
        if (filterData.type !== PRODUCTS_TYPE_ALL && !!filterData.categories?.length) {
            setIsAllSelected(false)
        }
    }, [filterData.type, filterData.categories?.length])

    const onSubmit = async (rest, active = null) => {

        const afterSubmit = (item) => {
            dispatch(showAlert('yuhuLabel'))
            setSuccessGenerated(true)
            setGeneratedCount(item);
            if (modalOpen === REMOVE_PRODUCTS || modalOpen === REMOVE_FROM_RESERVE_LIST) {
                updateSelection([]);
            }
        }

        setIsWaiting(true)

        try {
            const {data} = await api.post(`${selmoUrl}/${rest}${getParams()}`, getPreparedValues(active))
            afterSubmit(data.item)

        } catch (e) {
            console.log(e);
            dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
            console.warn('Cannot send selected products')
        } finally {
            setIsWaiting(false)
        }
    }

    const summaryContent = (description) => (
        !!+generatedCount?.success_count ?
            <>
                <div className="circle-icon">
                    <i className="icon-tick"/>
                </div>
                <div className="title">{getLangText('doneLabel')}</div>
                <div className="desc" dangerouslySetInnerHTML={{__html: description}}/>
                <div className="modal-footer">
                    <button
                        onClick={() => setModalOpen(null)}
                        type="button"
                        className="button border-button"
                    >
                        {getLangText('closeButton')}
                    </button>
                </div>
            </> :
            <>
                <div className="circle-icon red">
                    <i className="icon-cross"/>
                </div>
                <div className="title">{getLangText('upsLabel')}</div>
                <div className="desc" dangerouslySetInnerHTML={{__html: description}}/>
                <div className="modal-footer">
                    <button
                        onClick={() => setModalOpen(null)}
                        type="button"
                        className="button border-button"
                    >
                        {getLangText('closeButton')}
                    </button>
                </div>
            </>
    )

    const DropdownPortal = () => {
        return (
            reserveList ?
                <DropdownWithPortal
                    ButtonToggle={() => <span>{getLangText('selectActionLabel')}</span>}
                    icon="icon-arrows"
                    buttonClassName="button border-button"
                    className="right"
                >
                    {isMoreThanMax ?
                        <OverlayTrigger
                            placement='top'
                            overlay={
                                <Tooltip id="generate-label-tooltip">
                                    <span dangerouslySetInnerHTML={{__html: tooltipText}}/>
                                </Tooltip>
                            }
                        >
                            <button
                                type="button"
                                className="custom-option disabled"
                            >
                                {getLangText('createOrdersLabel')}
                            </button>
                        </OverlayTrigger>
                        :
                        <button
                            type="button"
                            className="custom-option"
                            onClick={() => setModalOpen(CREATE_ORDERS)}
                            disabled={isMoreThanMax}
                        >
                            {getLangText('createOrdersLabel')}
                        </button>
                    }
                    {isMoreThanMax ?
                        <OverlayTrigger
                            placement='top'
                            overlay={
                                <Tooltip id="generate-label-tooltip">
                                    <span dangerouslySetInnerHTML={{__html: tooltipText}}/>
                                </Tooltip>
                            }
                        >
                            <button
                                type="button"
                                className="custom-option disabled"
                            >
                                {getLangText('removeButton')}
                            </button>
                        </OverlayTrigger>
                        :
                        <button
                            type="button"
                            className="custom-option"
                            onClick={() => setModalOpen(REMOVE_FROM_RESERVE_LIST)}
                            disabled={isMoreThanMax}
                        >
                            {getLangText('removeButton')}
                        </button>
                    }
                </DropdownWithPortal> :
                <DropdownWithPortal
                    ButtonToggle={() => <span>{getLangText('selectActionLabel')}</span>}
                    icon="icon-arrows"
                    buttonClassName="button border-button"
                    className="right"
                >
                    {isMoreThanMax ?
                        <OverlayTrigger
                            placement='top'
                            overlay={
                                <Tooltip id="generate-label-tooltip">
                                    <span dangerouslySetInnerHTML={{__html: tooltipText}}/>
                                </Tooltip>
                            }
                        >
                            <button
                                type="button"
                                className="custom-option disabled"
                            >
                                {getLangText('deleteProductsLabel')}
                            </button>
                        </OverlayTrigger>
                        :
                        <button
                            type="button"
                            className="custom-option"
                            onClick={() => setModalOpen(REMOVE_PRODUCTS)}
                            disabled={isMoreThanMax}
                        >
                            {getLangText('deleteProductsLabel')}
                        </button>
                    }
                    {!isAllSelected &&
                        <DropdownItem
                            isMoreThanMax={isMoreThanMax}
                            maxSelected={maxSelected}
                            buttonTitle="activateProducts"
                            type={ACTIVATE_PRODUCTS}
                            setModalOpen={setModalOpen}
                        />
                    }
                    {isMoreThanMax ?
                        <OverlayTrigger
                            placement='top'
                            overlay={
                                <Tooltip id="generate-label-tooltip">
                                    <span dangerouslySetInnerHTML={{__html: tooltipText}}/>
                                </Tooltip>
                            }
                        >
                            <button
                                type="button"
                                className="custom-option disabled"
                            >
                                {getLangText('deactivateProductsLabel')}
                            </button>
                        </OverlayTrigger>
                        :
                        <button
                            type="button"
                            className="custom-option"
                            onClick={() => setModalOpen(DEACTIVATE_PRODUCTS)}
                            disabled={isMoreThanMax}
                        >
                            {getLangText('deactivateProductsLabel')}
                        </button>
                    }
                    {isMoreThanMax ?
                        <OverlayTrigger
                            placement='top'
                            overlay={
                                <Tooltip id="generate-label-tooltip">
                                    <span dangerouslySetInnerHTML={{__html: tooltipText}}/>
                                </Tooltip>
                            }
                        >
                            <button
                                type="button"
                                className="custom-option disabled"
                            >
                                {getLangText('activeVisibilityInShopProductsLabel')}
                            </button>
                        </OverlayTrigger>
                        :
                        <button
                            type="button"
                            className="custom-option"
                            onClick={() => setModalOpen(ACTIVE_IN_SHOP_PRODUCTS)}
                            disabled={isMoreThanMax}
                        >
                            {getLangText('activeVisibilityInShopProductsLabel')}
                        </button>
                    }
                    {isMoreThanMax ?
                        <OverlayTrigger
                            placement='top'
                            overlay={
                                <Tooltip id="generate-label-tooltip">
                                    <span dangerouslySetInnerHTML={{__html: tooltipText}}/>
                                </Tooltip>
                            }
                        >
                            <button
                                type="button"
                                className="custom-option disabled"
                            >
                                {getLangText('deactivateVisibilityInShopProductsLabel')}
                            </button>
                        </OverlayTrigger>
                        :
                        <button
                            type="button"
                            className="custom-option"
                            onClick={() => setModalOpen(DEACTIVATE_IN_SHOP_PRODUCTS)}
                            disabled={isMoreThanMax}
                        >
                            {getLangText('deactivateVisibilityInShopProductsLabel')}
                        </button>
                    }
                    <DropdownItem
                        isMoreThanMax={isMoreThanMax}
                        buttonTitle="activeVisibilityInAppProductsLabel"
                        type={ACTIVE_IN_APP_PRODUCTS}
                        setModalOpen={setModalOpen}
                        maxSelected={maxSelected}
                    />
                    <DropdownItem
                        isMoreThanMax={isMoreThanMax}
                        buttonTitle="deactivateVisibilityInAppProductsLabel"
                        type={DEACTIVATE_IN_APP_PRODUCTS}
                        setModalOpen={setModalOpen}
                        maxSelected={maxSelected}
                    />
                    {(+userData.id === TEST_ACCOUNT_SHOP_ID || userData.origin === SHOP_ORIGIN_DA) &&
                        <DropdownItem
                            isMoreThanMax={isMoreThanMax}
                            buttonTitle="printProductCards"
                            type={PRINT_PRODUCT_CARDS}
                            setModalOpen={setModalOpen}
                            maxSelected={maxSelected}
                        />
                    }
                </DropdownWithPortal>
        )
    }
    return (
        <th
            className={`checkbox-col ${isSelected ? 'selected' : ''}`}
            colSpan={colSpan}
        >
            <div className="selected-row">
                <div className="selected-checkbox">
                    <CheckboxField
                        label={width <= 991 && !isSelected ? getLangText('selectAllLabel') : ''}
                        name="selectAllItems"
                        className="mb-0"
                        setValue={selectAllItems}
                        value={checkIfSomeExist()}
                    />
                    {isSelected &&
                        <div className="flex items-center">
                            <div
                                className={`text-right mr-1 ${totalItems ? 'min-w-[24px]' : ''}`}>{selectedItemsLength}</div>
                            {getLangText('selectedLabel')}
                        </div>
                    }
                </div>
                {isSelected &&
                    <>
                        {(totalItems && filterData.type === PRODUCTS_TYPE_ALL && !filterData.categories?.length) &&
                            <button
                                type="button"
                                onClick={setIsAllSelectedItems}
                                className="text-[#000] ml-1.5"
                            >
                                {getLangText(isAllSelected ? 'selectedAll' : 'selectAll')}
                            </button>
                        }
                        <DropdownPortal/>
                    </>
                }
            </div>
            {modalOpen === REMOVE_PRODUCTS &&
                <ModalWithSuccessPage
                    summaryContent={() => summaryContent(getLangText({
                        key: 'successRemovedProductsLabel',
                        data: [generatedCount?.success_count, generatedCount?.all_count]
                    }))}
                    successGenerated={successGenerated}
                    generatedCount={generatedCount}
                    submitButtonClass="danger"
                    title={getLangText('deleteProductsLabel')}
                    SubTitle={() =>
                        <span>{getLangText('wantToRemoveProductsLabel')} ({selectedItemsLength})</span>}
                    saveButton={getLangText('removeButton')}
                    show={modalOpen === REMOVE_PRODUCTS}
                    isWaiting={isWaiting}
                    onSubmit={() => onSubmit(services.API_BATCH_REMOVE_PRODUCTS)}
                    hide={() => setModalOpen(null)}
                    ListActions={ListActions}
                />
            }
            {modalOpen === DEACTIVATE_PRODUCTS &&
                <ModalWithSuccessPage
                    summaryContent={() => summaryContent(getLangText({
                        key: 'successDeactivatedProductsLabel',
                        data: [generatedCount?.success_count, generatedCount?.all_count]
                    }))}
                    successGenerated={successGenerated}
                    generatedCount={generatedCount}
                    title={getLangText('deactivateProductsLabel')}
                    SubTitle={() =>
                        <span>{getLangText('wantToDeactivateProductsLabel')} ({selectedItemsLength})</span>}
                    saveButton={getLangText('deactivateProductsLabel')}
                    show={modalOpen === DEACTIVATE_PRODUCTS}
                    isWaiting={isWaiting}
                    onSubmit={() => onSubmit(services.API_BATCH_DEACTIVATE_PRODUCTS)}
                    hide={() => setModalOpen(null)}
                    ListActions={ListActions}
                />
            }
            {modalOpen === ACTIVATE_PRODUCTS &&
                <ModalWithSuccessPage
                    summaryContent={() => summaryContent(getLangText({
                        key: 'successActivatedProductsLabel',
                        data: [generatedCount?.success_count, generatedCount?.all_count]
                    }))}
                    successGenerated={successGenerated}
                    generatedCount={generatedCount}
                    title={getLangText('activateProducts')}
                    SubTitle={() =>
                        <span>{getLangText('wantToActivateProductsLabel')} ({selectedItemsLength})</span>}
                    saveButton={getLangText('activateProducts')}
                    show={modalOpen === ACTIVATE_PRODUCTS}
                    isWaiting={isWaiting}
                    onSubmit={() => onSubmit(services.API_BATCH_ACTIVATE_PRODUCTS)}
                    hide={() => setModalOpen(null)}
                    ListActions={ListActions}
                />
            }
            {modalOpen === CREATE_ORDERS &&
                <ModalWithSuccessPage
                    summaryContent={() => summaryContent(getLangText({
                        key: 'successCreateOrderFromProductsLabel',
                        data: [generatedCount?.success_count, generatedCount?.all_count]
                    }))}
                    successGenerated={successGenerated}
                    generatedCount={generatedCount}
                    title={getLangText('createOrdersLabel')}
                    SubTitle={() =>
                        <span>{getLangText('wantToCreateOrdersForProductsLabel')} ({selectedItemsLength})</span>}
                    saveButton={getLangText('createButton')}
                    show={modalOpen === CREATE_ORDERS}
                    isWaiting={isWaiting}
                    onSubmit={() => onSubmit(services.API_BATCH_CREATE_ORDERS)}
                    hide={() => setModalOpen(null)}
                    ListActions={ListActions}
                />
            }
            {modalOpen === REMOVE_FROM_RESERVE_LIST &&
                <ModalWithSuccessPage
                    summaryContent={() => summaryContent(getLangText({
                        key: 'successRemovedClientsFromReserveListLabel',
                        data: [generatedCount?.success_count, generatedCount?.all_count]
                    }))}
                    successGenerated={successGenerated}
                    generatedCount={generatedCount}
                    title={getLangText('removeClientsLabel')}
                    SubTitle={() =>
                        <span>{getLangText('removeClientsFromReserveListLabel')} ({selectedItemsLength})</span>}
                    saveButton={getLangText('removeButton')}
                    show={modalOpen === REMOVE_FROM_RESERVE_LIST}
                    isWaiting={isWaiting}
                    onSubmit={() => onSubmit(services.API_BATCH_REMOVE_RESERVE_LIST)}
                    hide={() => setModalOpen(null)}
                    submitButtonClass="danger"
                    ListActions={ListActions}
                />
            }
            {modalOpen === ACTIVE_IN_SHOP_PRODUCTS &&
                <ModalWithSuccessPage
                    summaryContent={() => summaryContent(getLangText({
                        key: 'successSetActiveProductsInShopLabel',
                        data: [generatedCount?.success_count, generatedCount?.all_count]
                    }))}
                    successGenerated={successGenerated}
                    generatedCount={generatedCount}
                    title={getLangText('activeVisibilityInShopProductsLabel')}
                    SubTitle={() =>
                        <span>{getLangText('askForActiveVisibilityInShopLabel')} ({selectedItemsLength})</span>}
                    saveButton={getLangText('yesButton')}
                    show={modalOpen === ACTIVE_IN_SHOP_PRODUCTS}
                    isWaiting={false}
                    onSubmit={() => onSubmit(services.API_BATCH_ACTIVE_PRODUCTS_IN_SHOP, '1')}
                    hide={() => setModalOpen(null)}
                    ListActions={ListActions}
                />
            }
            {modalOpen === DEACTIVATE_IN_SHOP_PRODUCTS &&
                <ModalWithSuccessPage
                    summaryContent={() => summaryContent(getLangText({
                        key: 'successSetDeactivateProductsInShopLabel',
                        data: [generatedCount?.success_count, generatedCount?.all_count]
                    }))}
                    successGenerated={successGenerated}
                    generatedCount={generatedCount}
                    title={getLangText('deactivateVisibilityInShopProductsLabel')}
                    SubTitle={() =>
                        <span>{getLangText('askForDeactivateVisibilityInShopLabel')} ({selectedItemsLength})</span>}
                    saveButton={getLangText('yesButton')}
                    show={modalOpen === DEACTIVATE_IN_SHOP_PRODUCTS}
                    isWaiting={false}
                    onSubmit={() => onSubmit(services.API_BATCH_ACTIVE_PRODUCTS_IN_SHOP, '0')}
                    hide={() => setModalOpen(null)}
                    ListActions={ListActions}
                />
            }
            {modalOpen === DEACTIVATE_IN_APP_PRODUCTS &&
                <ModalWithSuccessPage
                    summaryContent={() => summaryContent(getLangText({
                        key: 'successSetDeactivateProductsInAppLabel',
                        data: [generatedCount?.success_count, generatedCount?.all_count]
                    }))}
                    successGenerated={successGenerated}
                    generatedCount={generatedCount}
                    title={getLangText('deactivateVisibilityInAppProductsLabel')}
                    SubTitle={() =>
                        <span>{getLangText('askForDeactivateVisibilityInShopLabel')} ({selectedItemsLength})</span>}
                    saveButton={getLangText('yesButton')}
                    show={modalOpen === DEACTIVATE_IN_APP_PRODUCTS}
                    isWaiting={isWaiting}
                    onSubmit={() => onSubmit(services.API_BATCH_ACTIVE_PRODUCTS_IN_APP, '0')}
                    hide={() => setModalOpen(null)}
                    ListActions={ListActions}
                />
            }
            {modalOpen === ACTIVE_IN_APP_PRODUCTS &&
                <ModalWithSuccessPage
                    summaryContent={() => summaryContent(getLangText({
                        key: 'successSetActiveProductsInAppLabel',
                        data: [generatedCount?.success_count, generatedCount?.all_count]
                    }))}
                    successGenerated={successGenerated}
                    generatedCount={generatedCount}
                    title={getLangText('activeVisibilityInAppProductsLabel')}
                    SubTitle={() =>
                        <span>{getLangText('askForActiveVisibilityInAppLabel')} ({selectedItemsLength})</span>}
                    saveButton={getLangText('yesButton')}
                    show={modalOpen === ACTIVE_IN_APP_PRODUCTS}
                    isWaiting={isWaiting}
                    onSubmit={() => onSubmit(services.API_BATCH_ACTIVE_PRODUCTS_IN_APP, '1')}
                    hide={() => setModalOpen(null)}
                    ListActions={ListActions}
                />
            }
            {modalOpen === PRINT_PRODUCT_CARDS &&
                <ConfirmModal
                    title={getLangText('printProductCards')}
                    SubTitle={() =>
                        <span>{getLangText('askForPrintProductCards')} ({selectedItemsLength})</span>}
                    saveButton={getLangText('yesButton')}
                    show={modalOpen === PRINT_PRODUCT_CARDS}
                    isWaiting={waitingForPrint}
                    onSubmit={print}
                    hide={() => setModalOpen(null)}
                />
            }
        </th>
    );
};

export default SelectedRow;
