import {getStateRoot, prefix} from "./formReducer";
import {services} from "../../../RestServices";
import {RouterPaths} from "../../../routes/RouterPath";
import ProductModel from "../../../../modules/models/ProductModel";
import SharedEditProductFormActions from "../shared/SharedEditProductFormActions";
import CountDownNotificationActions from "../../shared/components/notification/CountDownNotificationActions";
import {ADD_NEW_PRODUCT_NOTIFICATION} from "../../shared/enums/CountDownNotificationsTypes";
import SessionActions from "../../shared/session/SessionActions";
import {batch} from "react-redux";

export class EditProductModalFormActions extends SharedEditProductFormActions {
    afterSubmit() {
        return (dispatch) => {
            batch(() => {
                dispatch(SessionActions.updateUserData('first_step_products', false))
                dispatch(this.modal.hideModal())
                dispatch(CountDownNotificationActions.setNotificationVisibility(true, ADD_NEW_PRODUCT_NOTIFICATION))
            })
        };
    }
}

export const getInstance = () => new EditProductModalFormActions({
    getStateRoot,
    prefix,
    model: ProductModel,
    restService: services.API_PRODUCTS,
    route: `${RouterPaths.ProductsList}/edytuj`
});

export default getInstance();
