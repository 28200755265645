import React, {useRef} from 'react';
import useDropdown from "../../hooks/useDropdown";
import Dropdown from "./Dropdown";

const DropdownWithPortal = ({children, icon = 'icon-dots', ButtonToggle = null, tooltipTitle, className = '', placement = '', onClick = () => {}, buttonClassName, offsetY = 0, rightPosition}) => {

	const buttonRef = useRef(null)
	const dropdownRef = useRef(null)

	const hideDropdown = () => setOpen(false)

	const {open, setOpen} = useDropdown(buttonRef, dropdownRef, hideDropdown);

	return (
		<>
			<div
				onClick={onClick}
				ref={dropdownRef}
				className={`react-dropdown position-relative ${className} ${placement}`}
			>
				<button
					className={`dropdown-toggle ${buttonClassName} ${open ? 'show' : ''} ${tooltipTitle ? 'tooltip-parent' : ''}`}
					onClick={() => setOpen(!open)}
					ref={buttonRef}
				>
					{tooltipTitle &&
						<div className="tooltip-content">
							{tooltipTitle}
						</div>
					}
					{ButtonToggle && <ButtonToggle/>}
					<i className={icon}/>
				</button>
				{open &&
					<Dropdown
						offsetY={offsetY}
						basedOn={buttonRef}
						rightPosition={rightPosition}
					>
						{children}
					</Dropdown>
				}
			</div>
		</>
	)
};

export default DropdownWithPortal;


