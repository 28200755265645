import React, {useCallback, useEffect, useState} from "react";
import FormActions from "./FormActions";
import {useParams} from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import useForm from "../../../../../../src_shared/form/useForm";
import Loader from "../../../../shared/Loader";
import {getGETParamsUrl} from "../../../../../../src_shared/list/ListActions";
import _ from 'lodash';
import useLang from "../../../../../../src_shared/hooks/useLang";
import axios from "axios";
import {INPOST_POINT_MODAL} from "../../../../basket/secondStep/SecondStep";

const InpostShippingModal = ({updateValues, data, show, hideModal, shippingId}) => {
	const params = useParams()
	const [items, setItems] = useState([]);
	const [waiting, setWaiting] = useState(false);
	const {getLangText} = useLang();

	const {
		data: {values}
	} = useForm({
		FormActions,
		params,
		preventAutoLoad: true,
	});

	const selectPoint = (point) => {
		updateValues({
			pointMachineType: INPOST_POINT_MODAL,
			pointMachineCode: point.name,
			pointMachineStreet: point.address.line1,
			pointMachineCity: point.address.line2,
			shipping: shippingId,
		})
		hideModal()
	}

	const getPointsList = async (inputValue = data.shippingData.zip_code || '') => {
		const params = {}
		params.per_page = 200;
		params.query = inputValue;
		setWaiting(true)
		try {
			const response = await axios.get(`https://api.inpost.pl/v1/points${getGETParamsUrl(params)}&type=parcel_locker&functions=parcel_collect`, {
				headers: {
					'Authorization': `Bearer ${process.env.REACT_APP_INPOST_API_KEY}`
				}
			})
			setItems(response.data.items)

		} catch (e) {
			console.log("Error get inpost points")
		}
		finally {
			setWaiting(false)
		}
	}

	useEffect(() => {
		getPointsList()
	}, [data.shippingData.zip_code])


	const debouncedSave = useCallback(
		_.debounce((name) => getPointsList(name), 500),
		[]
	);

	return (
		<Modal
			show={show}
			dialogClassName="default-modal sm-size"
			className="higher-index"
			backdropClassName="higher-index"
			onHide={hideModal}
			animation={false}
		>
			<Modal.Header>
				<Modal.Title>{getLangText('customerBasketSelectInpostLabel')}</Modal.Title>
				<button
					onClick={hideModal}
					type="button"
					className="button"
				>
					<i className="icon-cross"/>
				</button>
			</Modal.Header>
			<Modal.Body>
				<div id="easypack-widget" className="easypack-widget">
					<div className="easypack-dropdown" data-open="true">
						<div className="easypack-dropdown__select">
							<span>{getLangText('customerBasketSelectInpostPointLabel')}</span>
							<span
								className="easypack-dropdown__arrow">
							<img src="https://geowidget.easypack24.net//images/desktop/icons/filters.png?4.12.8"/>
						</span>
						</div>
						<div className="easypack-dropdown__content">
							<div className="search-input-loader-wrapper">
								<input
									autoFocus={true}
									type="text"
									onChange={(e) => debouncedSave(e.target.value)}
									placeholder={getLangText('customerBasketSearchInpostLabel')}
								/>
							</div>
							<ul className="easypack-dropdown__list loader-parent">
								{waiting ?
								<Loader className="static-loader" isLoading={true} />:
								items.map((i) => (
									<li
										key={i.name}
										onClick={() => selectPoint(i)}
									>
										{i.address.line1}, {i.address.line2}, {i.name}
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<button
					onClick={hideModal}
					type="button"
					className="button border-button m-0"
				>
					{getLangText('cancelButton')}
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default InpostShippingModal;



