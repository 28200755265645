import React from 'react';
import Benefit from "./Benefit";


const benefits = [
    {id: 0, title: 'selmoProBenefit1', desc: 'selmoProBenefitDesc1'},
    {id: 1, title: 'selmoProBenefit2', desc: 'selmoProBenefitDesc2'},
    {id: 2, title: 'selmoProBenefit3', desc: 'selmoProBenefitDesc3'},
    {id: 3, title: 'selmoProBenefit7', desc: 'selmoProBenefitDesc7'},
    {id: 4, title: 'selmoProBenefit5', desc: 'selmoProBenefitDesc5'},
    {id: 5, title: 'selmoProBenefit6', desc: 'selmoProBenefitDesc6'},
]

const SelmoProBenefits = () => {
    return (
        <div className="w-full">
            {benefits.map((item, index) => (
               <Benefit
                   isOpenDefault={index === 0}
                   item={item}
               />
            ))}
        </div>
    );
};

export default SelmoProBenefits;
