import React, {useMemo} from 'react';
import Field from "../../../../../../src_shared/components/form/field/Field";
import useLang from "../../../../../../src_shared/hooks/useLang";
import SelectField from "../../../../../../src_shared/components/form/selectField/SelectField";
import RoleMembersTypes from "../../../../../app/shared/enums/RoleMembers";
import {
    USER_PERMISSIONS_TEAM_EDIT_PERMISSIONS
} from "../../../../../app/shared/enums/UserPermissionsTypes";
import UserPermissionsWrapper from "../../../../../routes/UserPermissionsWrapper";

const MemberRow = ({getFieldProps, item, setNestedValue}) => {
    const {getLangText} = useLang();

    const translatedOptions = useMemo(() => RoleMembersTypes.map((i) => ({...i, label: getLangText(i.label)})), [])

    return (
        <div className="flex space-x-3">
            <Field
                {...getFieldProps('email')}
                value={item.email}
                label={getLangText('emailLabel')}
                placeholder={getLangText('textEmailLabel')}
                setValue={(field, value) => setNestedValue('row', item.id, field, value)}
                className="grow mb-0"
            />
            <UserPermissionsWrapper allowedPermissions={[USER_PERMISSIONS_TEAM_EDIT_PERMISSIONS]}>
                <SelectField
                    {...getFieldProps('role')}
                    value={item.role || null}
                    placeholder={getLangText('selectButton')}
                    className="default-size mb-0 sm:w-[220px]"
                    label={getLangText('permissions')}
                    setValue={(field, value) => setNestedValue('row', item.id, field, value)}
                    options={translatedOptions}
                    extraProps={{
                        isSearchable: false,
                    }}
                />
            </UserPermissionsWrapper>
        </div>
    );
};

export default MemberRow;