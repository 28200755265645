import React, {useEffect, useState} from 'react';
import {Link, useLocation} from "react-router-dom";

const SubMenu = ({children, name, includeRoute, route, icon, iconStyles}) => {

	const {pathname} = useLocation();

	const [active, setActive] = useState(false)

	useEffect(() => {
		if (pathname.includes(includeRoute)) {
			setActive(true)
		} else {
			setActive(false)
		}
	}, [pathname])

	return (
		<li className={`with-sub-menu ${active ? 'active' : ''}`}>
			<Link
				to={route}
				className={`menu-name ${active ? 'active' : ''}`}
				onClick={() => setActive(!active)}
			>
				<i
					style={iconStyles}
					className={icon}/>
				{name}
			</Link>
			<ul>
				{children}
			</ul>
		</li>
	);
};

export default SubMenu;
