import FormActions from "../../../../src_shared/form/FormActions";
import {getStateRoot} from "../form/edit/formReducer";
import {showAlert} from "../../shared/components/alert/AlertActions";
import _ from "lodash";
import {selmoUrl} from "../../../../src_shared/api/api";
import ShopSettingsLoaderActions from "../../shared/components/shopSettingsLoader/ShopSettingsLoaderActions";
import api from "../../../../services/axios/axios";
import {ERROR_NOT_FOUND} from "../../../../src_shared/enums/ErrorEnums";
import LangsActions from "../../shared/langs/LangsActions";
import {batch} from "react-redux";
import {getGETParamsUrl} from "../../../../src_shared/list/ListActions";
import {
	filesSetModalVisibility
} from "../../shared/header/files/filesDropdown/FilesActions";

export class SharedEditProductFormActions extends FormActions {
	validateProducts(items) {
		return items.some((item) => item.product_name && !item.product_price)
	}

	setOpenSaveConfirmModal(toggle) {
		return {
			type: `${this.prefix}OPEN_SAVE_CONFIRM_MODAL`,
			toggle,
		}
	}

	setOpenAutomaticReserveListModal(visible, data) {
		return {
			type: `${this.prefix}OPEN_AUTOMATIC_RESERVE_LIST_MODAL`,
			visible,
			data
		}
	}

	addNewVariant() {
		return (dispatch, getState) => {
			const stateRoot = this.getStateRoot(getState());
			const {data} = stateRoot;
			const newId = this.dataModel.createNewId();
			const variant = {
				id: newId,
				sizePrice: data.price,
				sizeName: '',
				sizeSqu: '0',
				deleted: '0',
				local: true,
			};

			const oldVariantList = stateRoot.data.sizes;
			const newVariantsList = [...oldVariantList, variant]

			dispatch(this.data.setValue('sizes', newVariantsList));
		};
	}

	loadData(id) {
		return async (dispatch) => {
			dispatch(this.waiting.startWaiting());
			api.defaults.headers.common['lang'] = dispatch(LangsActions.getLang());
			const restUrl = id ? `${this.restService}/${id}` : this.restService;
			try {
				const {data} = await api.get(`${selmoUrl}/${restUrl}`)
				const parsedItem = dispatch(this.parseData(data.item));
				batch(() => {
					dispatch(this.data.updateValues(parsedItem))
					dispatch(this.data.updateEmptyValues(parsedItem))
				})
			} catch (e) {
				if (e.response?.data?.message === ERROR_NOT_FOUND || e.response?.status === 500) {
					dispatch(this.data.setValue('notFound', true));
				}
				dispatch(this.onErrorLoad(e))
				console.error('Error loadData')
			} finally {
				dispatch(this.waiting.stopWaiting());
			}
		}
	}

	submitEditForm(values, history, qrCode) {
		return async (dispatch, state) => {
			const {id} = values;
			const {uniqueProductCodeError, emptyData} = getStateRoot(state());
			const notDeletedSizes = values.sizes.map((x) => ({...x, sizeName: x.sizeName?.toLowerCase()})).filter((i) => !+i.deleted);

			dispatch(this.validation.activateValidation());

			if (uniqueProductCodeError) {
				dispatch(showAlert('enteredCodeIsAlreadyUsedLabel', '', 'alert-fail'))
				console.error('Validation failed!');
				return
			}

			if (_.uniqBy(notDeletedSizes, 'sizeName').length !== notDeletedSizes.length) {
				dispatch(showAlert('variantsHaveTheSameProductCodesLabel', 'setUniqueValuesLabel', 'alert-fail'))
				console.error('Validation failed!');
				return
			}

			if (!this.dataModel.validateData(values)) {
				dispatch(showAlert('fillRequireFieldWarning', '', 'alert-fail'))
				console.error('Validation failed!');
				return;
			}

			const filteredData = Object.entries(values).filter(([key]) => key !== 'id');
			const finalData = Object.fromEntries(filteredData)
			const filteredEmptyData = Object.entries(emptyData).filter(([key]) => key !== 'id');
			const finalEmptyData = Object.fromEntries(filteredEmptyData)

			const preparedData = this.dataModel.buildDTO(finalData, finalEmptyData, id)

			dispatch(this.waiting.startWaiting());

			const params = {}

			if (qrCode) {
				params.qr_code = '1';
			}

			try {
				if (id) {
					const {data} = await api.put(`${selmoUrl}/${this.restService}/${id}${getGETParamsUrl(params)}`, preparedData)
					if (data.message === 'AUTOMATIC_RESERVE_LIST') {
						dispatch(this.setOpenAutomaticReserveListModal(true, data.items));
						return;
					}
				} else {
					const {data} = await api.post(`${selmoUrl}/${this.restService}`, preparedData)
					this.updateRoute(data, history)
				}
				dispatch(this.afterSubmit(values.id, values))
				dispatch(this.validation.deactivateValidation())
				if (qrCode) {
					dispatch(filesSetModalVisibility(true))
				} else {
					dispatch(showAlert())
				}
			} catch (e) {
				if (e?.response?.data?.message) {
					dispatch(showAlert(e.response.data.message, '', 'alert-fail'))
					return;
				}
				dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
				console.error('Error submitEditForm')
			} finally {
				dispatch(this.waiting.stopWaiting());
			}
		}
	}

	afterSubmit(id, data) {
		return (dispatch) => {
			if (!!+data.is_shop_active) {
				dispatch(ShopSettingsLoaderActions.startChanges(new Date()));
			}
			dispatch(this.setOpenSaveConfirmModal(false));
			dispatch(this.loadData(id))
		};
	}

	removeVariant(id) {
		return (dispatch, getState) => {
			const newId = this.dataModel.createNewId();
			const stateRoot = this.getStateRoot(getState());
			const variant = {
				id: newId,
				sizePrice: '',
				sizeName: '',
				sizeSqu: 1,
				deleted: '0',
			};
			const oldVariantList = stateRoot.data.sizes;
			if (oldVariantList.length === 1) {
				dispatch(this.data.setValue('sizes', [variant]));
				return
			}
			const newVariantsList = oldVariantList.filter((i) => i.id !== id)
			dispatch(this.data.setValue('sizes', newVariantsList));
		}
	}
}

export default SharedEditProductFormActions;
