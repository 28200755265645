import React from 'react';
import useFormLocal from "../../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../../RestServices";
import Field from "../../../../../src_shared/components/form/field/Field";
import Model from "../../../../../src_shared/modules/model/Model";
import useLang from "../../../../../src_shared/hooks/useLang";
import Loader from "../../../shared/Loader";
import {useHistory} from "react-router-dom";
import {RouterPaths} from "../../../../routes/RouterPath";

const OrderSearch = () => {
    const {getLangText} = useLang();
    const history = useHistory();

    const [{onSave, isWaiting, getFieldProps}] = useFormLocal({
        rest: services.API_WAREHOUSE_ORDER_ID,
        model: new Model(),
        afterSubmit: (response) => history.push(`${RouterPaths.StorageScanner}/order/${response.id}`),
        initialAutoLoad: false,
    });


    return (
        <div className="px-6 mt-3">
            <div className="or-separator text-center">
                <div style={{background: '#EFF2F6'}}>{getLangText('separatorOrLabel')}</div>
            </div>
            <form onSubmit={onSave}>
                <div className="flex items-end mt-3">
                    <Field
                        className="grow mb-0"
                        {...getFieldProps('shop_order_id')}
                        label={getLangText('enterShopIdFromLabel')}
                        type="number"
                        extraProps={{
                            required: true,
                        }}
                    />
                    <button
                        type="submit"
                        style={{paddingTop: 14, paddingBottom: 14}}
                        className={`button primary ml-2 ${isWaiting ? 'loading-button' : ''}`}
                    >
                        {isWaiting && <Loader isLoading/>}
                        {getLangText('goToLabel')}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default OrderSearch;
