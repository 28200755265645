export const initState = {};

export const getSelectReducer = (stateTargetPath) => (state, action) => {
    if (!action.data) {
        console.error('checkboxMultiselectReducer error: action SELECT_ITEM'
            + ` for [${stateTargetPath.join(',')}] has invalid data!`);
        console.info(action);
        return state;
    }
	if (state[stateTargetPath].some((i) => i.id === action.data.id)) {
		return state;
	}
	return {...state, [stateTargetPath]: [...state[stateTargetPath], action.data]}
};

export const getDeselectReducer = (stateTargetPath) => (state, action) => {
    if (!action.itemId) {
        console.error('checkboxMultiselectReducer error: action DESELECT_ITEM'
            + ` for [${stateTargetPath.join(',')}] has invalid data!`);
        console.info(action);
        return state;
    }

	const filteredItems = state[stateTargetPath].filter((i) => i.id !== action.itemId)
	return {...state, [stateTargetPath]: filteredItems}

};

export const getUpdateSelectionReducer = (stateTargetPath) => (state, action) => {
    if (!action.items) {
        console.error('checkboxMultiselectReducer error: action REPLACE_SELECTION'
            + ` for [${stateTargetPath.join(',')}] has invalid data!`);
        console.info(action);
        return state;
    }
	return {...state, [stateTargetPath]: action.items}

};

export const getActionPrefix = (name) => `CHECKBOX_MULTISELECT_${name.toUpperCase()}`;

export const createReduceMap = (name, stateTargetPath) => {
    const reduceMap = {};
    const actionPrefix = getActionPrefix(name);
    const selectAction = `${actionPrefix}_SELECT_ITEM`;
    const deselectAction = `${actionPrefix}_DESELECT_ITEM`;
    const updateSelectionAction = `${actionPrefix}_UPDATE_SELECTION`;

    reduceMap[selectAction] = getSelectReducer(stateTargetPath);
    reduceMap[deselectAction] = getDeselectReducer(stateTargetPath);
    reduceMap[updateSelectionAction] = getUpdateSelectionReducer(stateTargetPath);

    return reduceMap;
};

export const buildReducer = (name, stateTargetPath) => ({
    initState,
    reduceMap: createReduceMap(name, stateTargetPath),
});

export default {
    getSelectReducer,
    getDeselectReducer,
    getUpdateSelectionReducer,
    getActionPrefix,
    initState,
    createReduceMap,
    buildReducer,
};
