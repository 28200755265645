import {prefix} from "./formReducer";
import {getStateRoot} from "./formReducer";
import {getStateRoot as getOrderStateRoot} from "../form/formReducer";
import OrderProductsFormActions from "../../../shared/components/form/orderProductsForm/OrderProductsFormActions";
import axios from "axios";
import {batch} from "react-redux";
import {showAlert} from "../../../shared/components/alert/AlertActions";
import {selmoUrl} from "../../../../../src_shared/api/api";
import EditOrderFormActions from "../form/FormActions";
import {services} from "../../../../RestServices";
import api from "../../../../../services/axios/axios";

export class DetailsProductEditProductForm extends OrderProductsFormActions {
	getOrderProducts(id) {
		return async (dispatch) => {
			dispatch(this.waiting.startWaiting())

			try {
				const response = await api.get(`${selmoUrl}/${services.API_ORDER_PRODUCT}/${id}`)
				const {item} = response.data

				dispatch(this.updateOrderData(item))

			} catch (e) {

			} finally {
				dispatch(this.waiting.stopWaiting())
			}
		}
	}

	showModal(id) {
		return (dispatch) => {
			batch(() => {
				dispatch(this.getOrderProducts(id))
				dispatch(this.modal.showModal())
			})
		}
	}

	afterSubmit(id) {
		return (dispatch) => {
			batch(() => {
				dispatch(showAlert())
				dispatch(this.modal.hideModal())
				dispatch(EditOrderFormActions.loadData(id))
			})
		}
	}

	submit(e) {
		return async (dispatch, getState) => {
			e?.preventDefault();
			const {manualProducts} = getStateRoot(getState());
			const {data} = getOrderStateRoot(getState());

			if (this.validateOrderProducts(manualProducts)) {
				dispatch(this.updateField('productActiveValidation', true))
				return
			}

			dispatch(this.setEditOrderWaiting(true))

			try {
				await api.put(`${selmoUrl}/api/order?fromOrder=true`, {
					order_id: data.shop_order_id,
					products: manualProducts.map((product) => ({...product, quantity: !+product.quantity ? 1 : product.quantity})),
				})
				dispatch(this.afterSubmit(data.shop_order_id))
			} catch (e) {
				console.warn('submit `api/order` action failed')
				//	TODO
				dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
			} finally {
				dispatch(this.updateField('productActiveValidation', false))
				dispatch(this.setEditOrderWaiting(false))
			}
		}
	}
}

export const getInstance = () => new DetailsProductEditProductForm({
	prefix,
	getStateRoot
});

export default getInstance();

