import {prefix} from "./formReducer";
import {getStateRoot} from "./formReducer";
import FunctionalityPaymentsModel from "../../../../modules/models/FunctionalityPaymentsModel";
import {services} from "../../../RestServices";
import axios from "axios";
import {selmoUrl} from "../../../../src_shared/api/api";
import {ERROR_NOT_FOUND} from "../../../../src_shared/enums/ErrorEnums";
import PaymentsSharedFormActions from "../shared/PaymentsSharedFormActions";
import api from "../../../../services/axios/axios";

export class FunctionalityModalActions extends PaymentsSharedFormActions {
	loadData(functionalitiesName) {
		return async (dispatch) => {
			dispatch(this.waiting.startWaiting());
			const restUrl =  this.restService;
			try {
				const {data} = await api.get(`${selmoUrl}/${restUrl}?selmo_name=${functionalitiesName}`)
				const parsedItem = dispatch(this.parseData(data.item));
				dispatch(this.data.updateValues(parsedItem))
			} catch (e) {
				if (e.response.data.message === ERROR_NOT_FOUND || e.response.status === 500) {
					dispatch(this.data.setValue('notFound', true));
				}
				dispatch(this.onErrorLoad(e))
				console.error('Error loadData')
			} finally {
				dispatch(this.waiting.stopWaiting());
			}
		}
	}
}

export const getInstance = () => new FunctionalityModalActions({
	prefix,
	getStateRoot,
	restService: services.API_FUNCTIONALITIES,
	model: FunctionalityPaymentsModel,
});

export default getInstance();

