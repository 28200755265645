import createReducer from "../shared/helpers/createReducer";
import FormReducer from "../../../src_shared/form/FormReducer";
import Composition from "../shared/helpers/Composition";
import PaymentsModel from "../../../modules/models/PaymentsModel";

export const getStateRoot = (state) => state.payments.form;
export const prefix = 'PAYMENTS_FORM_';

export const getInitState = () => FormReducer.getInitState(PaymentsModel.getModel(), {
	summaryInfo: {
		totalPrice: 0,
		totalPriceGross: 0,
		taxRate: 0,
		functionalities: {},
		subscription: {
			daysLeft: 0,
		},
	},
	waitingForTotalPrice: true,
	waitingForPayment: false,
	waiting: true,
	isTransferPage: false,
	notValidNip: false,
})

export const getReduceMap = () => new Composition(
	FormReducer.getReduceMap(),
	{
		SET_SUMMARY_INFO: (state, action) => ({...state, summaryInfo: action.item}),
		WAITING_FOR_TOTAL_PRICE: (state, action) => ({...state, waitingForTotalPrice: action.toggle}),
		SET_TRANSFER_PAGE: (state, action) => ({...state, isTransferPage: action.toggle}),
		SET_WAITING_FOR_PAYMENT: (state, action) => ({...state, waitingForPayment: action.toggle}),
		SET_NOT_VALID_NIP: (state, action) => ({...state, notValidNip: action.toggle}),
	}
);

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();
