import Model, {convertObjectProperties} from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

class DPDPromoModel extends Model {
	getModel() {
		return {
			price: 5,
		};
	}

	parseDTO(data) {
		return convertObjectProperties(
			data,
			{
			},
			{
				price: 5,
			}
		);
	}

	getValidators() {
		return {
			price: ValidationError.notEmpty,
		};
	}
}

export default new DPDPromoModel();
