import React from 'react';
import Loader from "../../../../shared/Loader";
import Field from "../../../../../../src_shared/components/form/field/Field";
import TextArea from "../../../../../../src_shared/components/form/textarea/TextArea";
import {services} from "../../../../../RestServices";
import {selmoAPI2Url} from "../../../../../../src_shared/api/api";
import EmptyList from "../../../../shared/EmptyList";
import SelectedProducts from "../selectedProducts/SelectedProducts";
import SwitchField from "../../../../../../src_shared/components/form/switchField/SwitchField";
import {useSelector} from "react-redux";
import useLang from "../../../../../../src_shared/hooks/useLang";
import {useParams} from "react-router-dom";
import VideoUpload from "./VideUpload";

const ReelsFormComponent = ({
                                isLoading,
                                getFieldProps,
                                values,
                                updateValues,
                                setShowAssignProductsModal,
                                setValue,
                                isWaiting,
                                onSave,
                                openRemoveModal
                            }) => {
    const {userData} = useSelector((state) => state.session);
    const {getLangText} = useLang();
    const {id} = useParams();

    const AssignProductsButton = () => (
        <button
            onClick={() => setShowAssignProductsModal(true)}
            type="button"
            className="button border-button small-size mt-3"
        >
            {getLangText('assignProductsLabel')}
        </button>
    )

    return (
        <div className="loader-parent higher-index">
            <Loader isLoading={isLoading}/>
            <div className="settings-form">
                <Field
                    name="name"
                    {...getFieldProps('name')}
                    setValue={setValue}
                    label={getLangText('nameLabel')}
                />
                <TextArea
                    name="description"
                    {...getFieldProps('description')}
                    setValue={setValue}
                    label={getLangText('input_label_package_type_description')}
                />
                {/*<ReactPlayerVideo url={values.url}/>*/}

                <div>
                    <div className="form-label">
                        {getLangText('videoFile')}
                    </div>
                    {!+values.is_being_transmitted ?
                        <VideoUpload
                            values={values}
                            updateValues={updateValues}
                            validation={false}
                            showLabel={false}
                            internalLabel="insertVideo"
                            restService={`${services.API_VIDEO}/${id}`}
                            restUrl={selmoAPI2Url}
                            afterUpload={() => {
                                setValue('is_being_transmitted', true)
                            }}
                        /> :
                        <div className="flex items-center bg-[#F0FAFF] p-3 rounded-[5px] border-[#5FA5FA] mb-3">
                            <Loader
                                className="small-loader static-loader mr-2.5 mt-0.5 w-auto bg-transparent"
                                isLoading
                            />
                            <div>
                                <div className="font-bold text-sm text-[#0D3364]">
                                    {getLangText('theVideoIsBeingProcessed')}
                                </div>
                                <div className="text-xs text-desc">
                                    {getLangText('ALREADY_REQUESTED')}
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className="form-label mb-0">
                    {getLangText('assignedProducts')}
                </div>
                <EmptyList
                    items={values.products}
                    isLoading={false}
                    className="py-6"
                    imgSrc="/assets/images/empty/products-empty.svg"
                    title={getLangText('emptyHereLabel')}
                    text={getLangText('assignProductsLabel')}
                    ExtraComponent={AssignProductsButton}
                >
                    <SelectedProducts
                        products={values.products}
                        setValue={setValue}
                    />
                </EmptyList>
                {!!values.products.length &&
                    <button
                        type="button"
                        className="button text-blue-button add-new-row mt-3"
                        onClick={() => setShowAssignProductsModal(true)}
                    >
                        <i className="icon-plus"/>
                        {getLangText('assignProductsLabel')}
                    </button>
                }
                <div className="button-place mt-4 flex justify-between mb-4">
                    <SwitchField
                        {...getFieldProps('active')}
                        setValue={setValue}
                        label={getLangText('active')}
                        className="switch-form inline-label align-items-start big-label light-version mb-0"
                    />
                    <button
                        type="button"
                        className={`button primary ${isWaiting ? 'loading-button' : ''}`}
                        onClick={onSave}
                    >
                        {/*{isWaiting && <Loader isLoading/>}*/}
                        {getLangText(!id ? 'editButton' : 'saveButton')}
                    </button>
                </div>
                {!!+id &&
                    <div className="remove-delivery-section border-t pt-4">
                        <button
                            type="button"
                            onClick={() => openRemoveModal(values)}
                            className="button border-button left-icon big-size"
                        >
                            <i className="icon-bin"/>
                            {getLangText('removeReelsLabel')}
                        </button>
                    </div>
                }
            </div>
        </div>
    );
};

export default ReelsFormComponent;
