import {selmoUrl} from "../../../../../../src_shared/api/api";
import {useDispatch} from "react-redux";
import React, {useState} from "react";
import useLang from "../../../../../../src_shared/hooks/useLang";
import api from "../../../../../../services/axios/axios";
import {showAlert} from "../../../../shared/components/alert/AlertActions";
import Loader from "../../../../../../src_shared/components/loader/Loader";

const VideoUpload = ({
                         values,
                         updateValues,
                         restService,
                         validation = false,
                         field,
                         className,
                         label,
                         formatsLabel,
                         withRemove,
                         internalLabel = 'insertFileLabel',
                         afterUpload = () => {
                         },
                         restUrl = selmoUrl,
                     }) => {
    const dispatch = useDispatch();
    const [isWaiting, setIsWaiting] = useState(false);
    const {getLangText} = useLang();

    const isValidationActive = validation && !values[field]

    const removeAvatar = async () => {
        try {
            await api.delete(`${selmoUrl}/${restService}/${values.id}`)
            updateValues({
                [field]: null,
            })
        } catch (e) {

        }
    }

    const onSubmit = async (file) => {
        setIsWaiting(true)

        const formData = new FormData();
        formData.append(field, file);

        if (values.id) {
            formData.append('id', values.id);
        }

        try {
            const {data} = await api.post(`${restUrl}/${restService}`, formData)
            updateValues({
                attachment_name: data.attachment_name,
                attachment_path: data.attachment_path,
            })
            afterUpload();
            dispatch(showAlert('imageSaveSuccessLabel'))

        } catch (e) {
            dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
            console.error('Error submitEditForm')
        } finally {
            setIsWaiting(false)
        }
    }

    const uploadFileHandler = async (event) => {
        const files = Object.values(event.target.files);
        if (files[0]) {
            await onSubmit(files[0]);
        }
    };

    const isUploaded = !!values.attachment_path;

    return (
        <div
            className={`${className} logo-upload-wrapper form-group d-block big-image-wrapper video-upload ${isValidationActive ? 'error-group' : ''}`}>
            <div className="photo-box-wrapper d-block">
                {!!label && <div className="form-label">{getLangText(label) || label}</div>}
                <div
                    style={{width: '100%', display: 'flex', alignItems: 'center'}}
                    className={`photo-wrapper with-border p-3 ${isWaiting ? '' : 'hover:bg-gray-50'}  transition-all border-dashed`}
                >
                    {isWaiting ?
                        <div className="flex items-center">
                            <Loader
                                className="small-loader static-loader mr-2.5 mt-0.5 w-auto bg-transparent"
                                isLoading
                            />
                            <div>
                                <div className="font-bold text-sm">{getLangText('videoIsBeingSent')}</div>
                                <div className="text-desc text-xs">
                                    {getLangText('itMakeTakeAWhile')}
                                </div>
                            </div>
                        </div>
                        :
                        <>
                            {isUploaded ?
                                <a
                                    href={values.attachment_path}
                                    download
                                    target="_blank"
                                    className="flex items-center space-x-2 relative z-[9]"
                                >
                                    <i className="icon-orders text-[#3C61EA]"/>
                                    <span
                                        className="text-xs text-[#4B5563] underline max-w-[220px] text-ellipsis overflow-hidden text-nowrap">{values.attachment_name}</span>
                                </a> :
                                <div className="flex items-center space-x-2">
                                    <i className="icon-orders text-[#9CA3AF]"/>
                                    <span className="text-xs text-[#4B5563]">{getLangText(internalLabel)}</span>
                                </div>
                            }
                            <div className="button border-button left-icon ml-auto relative cursor-pointer">
                                <i className="icon-upload text-[#9CA3AF]"/>
                                {getLangText(isUploaded ? 'editFileLabel' : 'insertFileLabel')}
                            </div>
                            <input
                                multiple={false}
                                style={{width: '100%'}}
                                type="file"
                                onChange={uploadFileHandler}
                                className="hidden-file-input"
                                hidden={isWaiting}
                            />
                        </>
                    }
                </div>
                <div className="sizes-label">
                    {formatsLabel &&
                        <div dangerouslySetInnerHTML={{__html: formatsLabel}}/>
                    }
                </div>
            </div>
            {!!values[field] && withRemove &&
                <button
                    type="button"
                    className="button text-only mt-2"
                    onClick={removeAvatar}
                >
                    <i className="icon-bin mr-1"/>
                    Usuń plik
                </button>
            }
            {isValidationActive &&
                <div className="error-text">
                    {getLangText('photoCannotBeEmptyLabel')}
                </div>
            }
        </div>
    );
};

VideoUpload.defaultProps = {
    restService: 'api/media',
    circleImage: false,
    loadData: (dispatch, FormActions, values) => dispatch(FormActions.loadData(values.id)),
    field: 'file',
    className: '',
    label: '',
    formatsLabel: '',
}

export default VideoUpload;
