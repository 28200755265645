import React from 'react';
import EmptyList from "../../../../../shared/EmptyList.jsx";
import TableHeader from "./TableHeader.jsx";
import TableBody from "./TableBody.jsx";
import useTableScrollClasses from "../../../../../../../src_shared/hooks/useTableScrollClassses.js";
import useTableColumn from "../../../../../orders/list/components/table/useTableColumn.js";
import {CLIENTS_TABLE_COLUMN} from "./ClientsTableColumnEnums.js";
import {getStateRoot as getFiltersStateRoot} from "../../../../filters/filtersReducer.js";
import useLang from "../../../../../../../src_shared/hooks/useLang";

const ClientsTable = ({items, isWaiting, updateSortOptions, getSortClass}) => {

	const {tableColumn} = useTableColumn(CLIENTS_TABLE_COLUMN, 'clientsTableColumn', getFiltersStateRoot);
	const {tableRef, tableResponsiveRef} = useTableScrollClasses(tableColumn, isWaiting)
	const {getLangText} = useLang();

	return (
		<EmptyList
			items={items}
			isLoading={isWaiting}
			className="with-padd"
			imgSrc="/assets/images/empty/clients-empty.svg"
			title={getLangText('emptyHereLabel')}
			text={getLangText('emptyClientListDescription')}
		>
			<div className="table-wrapper">
				<div
					ref={tableResponsiveRef}
					className="table-responsive"
				>
					<table
						ref={tableRef}
						className="table live-table smaller-padd default-list-table"
					>
						<TableHeader
							updateSortOptions={updateSortOptions}
							getSortClass={getSortClass}
						/>
						<TableBody
							items={items}
						/>
					</table>
				</div>
			</div>
		</EmptyList>
	);
};

export default ClientsTable;
