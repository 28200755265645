import React, {useState} from 'react';
import EmptyList from "../../../shared/EmptyList";
import useLang from "../../../../../src_shared/hooks/useLang";
import Pagination from "../../../shared/components/pagination/Pagination";
import ListActions from "./ListActions";
import {getStateRoot} from "./listReducer";
import useList from "../../../../../src_shared/list/useList";
import SearchInput from "../../../shared/components/form/SearchInput";
import {Link, useHistory} from "react-router-dom";
import {RouterPaths} from "../../../../routes/RouterPath";
import {Helmet} from "react-helmet";
import moment from "../../../messenger/helpers/moment";
import CustomDropdown from "../../../shared/components/dropdown/CustomDropdown";
import Loader from "../../../shared/Loader";
import ShopNameBar from "../../../../routes/shopSettingsRoute/ShopNameBar";
import ConfirmRemoveModal from "../../../../../src_shared/components/modal/ConfirmRemoveModal";
import {useDownloadFile} from "../../../../../src_shared/hooks/useDownloadFile";
import {services} from "../../../../RestServices";
import ExportModal from "../../../../../src_shared/components/exportModal/ExportModal";
import {convertToUserTimeZone} from "../../../shared/helpers/dateHelpers";

const ShopPagesList = () => {
    const {getLangText} = useLang();
    const [openExportModal, setOpenExportModal] = useState(false);

    const {
        pagination: {
            changePage,
            getPagination,
            itemsPerPage,
            nextPage,
            prevPage,
            totalItems,
            updateItemsPerPage,
        },
        list: {items, isWaiting},
        search,
        sort: {updateSortOptions, getSortClass},
        remove: {askForRemoveItem, isModalVisible, cancelItemRemoval, markedToRemove, confirmItemRemoval},
    } = useList({
        ListActions,
        getStateRoot,
    });

    return (
        <div className={`settings-page sub-settings-page`}>
            <ShopNameBar/>
            <div className="loader-parent">
                <Loader isLoading={isWaiting}/>
                <div className="table-list-wrapper">
                    <Helmet>
                        <meta charSet="utf-8"/>
                        <title>{getLangText('shopSettingsNewsletterLabel')}Selmo</title>
                    </Helmet>
                    <div className="default-shadow-box pb-0 pt-sm-3">
                        <div className="table-header d-sm-flex align-items-center">
                            <div className="top-part">
                                <div>
                                    <h1 className="title">
                                        {getLangText('newsletterLabel')}
                                        {totalItems > 0 &&
                                            <div className="badge">{totalItems}</div>
                                        }
                                    </h1>
                                    <div className="small-text sub-title">
                                        {getLangText('manageNewsletterListLabel')}
                                    </div>
                                </div>
                                <div className="d-sm-none">
                                    <CustomDropdown
                                        icon="icon-print"
                                        buttonClassName="default-size mr-sm-3"
                                        tooltipTitle={getLangText('exportLabel')}
                                    >
                                        <button
                                            type="button"
                                            onClick={() => setOpenExportModal(true)}
                                            className={`custom-option`}
                                        >
                                            {`${getLangText('exportList')} (XLS)`}
                                        </button>
                                    </CustomDropdown>
                                </div>
                            </div>
                            <div className="filters-part mt-0 ml-auto flex-sm-nowrap">
                                <CustomDropdown
                                    icon="icon-print"
                                    buttonClassName="default-size mr-sm-3 d-sm-block d-none"
                                    tooltipTitle={getLangText('exportLabel')}
                                >
                                    <button
                                        type="button"
                                        onClick={() => setOpenExportModal(true)}
                                        className={`custom-option`}
                                    >
                                        {`${getLangText('exportList')} (XLS)`}
                                    </button>
                                </CustomDropdown>
                                <SearchInput
                                    placeholder={getLangText('searchPlaceholder')}
                                    className="mr-0 ml-auto"
                                    search={search}
                                />
                            </div>
                        </div>
                        <EmptyList
                            items={items}
                            isLoading={false}
                            className="with-padd"
                            imgSrc="/assets/images/empty/clients-empty.svg"
                            title={getLangText('emptyHereLabel')}
                        >
                            <div className="table-wrapper">
                                <div className="table-responsive overflow-visible">
                                    <table className="table live-table smaller-padd">
                                        <thead>
                                        <tr>
                                            <th
                                                name="email"
                                                onClick={updateSortOptions}
                                                className={getSortClass('email')}
                                            >
											<span name="email">
												{getLangText('emailLabel')}
											</span>
                                            </th>
                                            <th
                                                name="date"
                                                onClick={updateSortOptions}
                                                className={getSortClass('date')}
                                            >
												<span name="date">
													{getLangText('createdDateLabel')}
												</span>
                                            </th>
                                            <th/>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {items.map((item) => (
                                            <tr
                                                className="not-clicked-tr"
                                                key={item.id}
                                            >
                                                <td
                                                    className="name"
                                                >
                                                    {item.email}
                                                </td>
                                                <td
                                                    data-thead={`${getLangText('dateLabel')}:`}
                                                    className="with-mobile-thead inline-thead"
                                                >
                                                    <div>{convertToUserTimeZone(item.date).format('DD.MM.YYYY HH:mm')}</div>
                                                </td>
                                                <td className="text-right absolute-dropdown-dots">
                                                    <CustomDropdown
                                                        icon="icon-dots"
                                                        onClick={(e) => e.stopPropagation()}
                                                    >
                                                        <button
                                                            onClick={() => askForRemoveItem(item)}
                                                            type="button"
                                                            className="custom-option"
                                                        >
                                                            {getLangText('removeButton')}
                                                        </button>
                                                    </CustomDropdown>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </EmptyList>
                    </div>
                    {totalItems > itemsPerPage &&
                        <Pagination
                            getLangText={getLangText}
                            pages={getPagination()}
                            items={items}
                            itemsPerPage={itemsPerPage}
                            totalItems={totalItems}
                            onPrevPage={prevPage}
                            onChangePage={changePage}
                            onNextPage={nextPage}
                            onChangeItemsPerPage={updateItemsPerPage}
                            classes={{
                                prevIcon: 'icon-chevron left',
                                nextIcon: 'icon-chevron right',
                            }}
                            showItemsPerPageSelect
                        />
                    }
                    {isModalVisible &&
                        <ConfirmRemoveModal
                            dialogClassName="small-modal w-380"
                            show={isModalVisible}
                            title={getLangText('removeUserNewsletterListTitleLabel')}
                            subTitle={getLangText({
                                key: 'removeUserNewsletterListLabel',
                                data: [markedToRemove.email]
                            })}
                            hide={cancelItemRemoval}
                            confirm={confirmItemRemoval}
                        />
                    }
                </div>
            </div>
            <ExportModal
                title={getLangText('exportLabel')}
                subTitle={getLangText('receivedFileInXLSFormatLabel')}
                saveButton={getLangText('exportLabel')}
                rest={services.API_EXPORT_NEWSLETTER_LIST_XLS}
                fileName='newsletter'
                fileType='xlsx'
                show={openExportModal}
                hide={() => setOpenExportModal(false)}
            />
        </div>
    );
};

export default ShopPagesList;
