import React from 'react';
import Group from "../group/Group";
import Select from "react-select";

const SelectField = (props) => {

	const {
		name,
		setValue,
		disabled,
		extraProps,
		defaultValue,
		options,
		id,
		className,
		formatOptionLabel,
		value,
		placeholder,
		onInputChange,
		Components,
		required = false
	} = props;

	const preparedValue = typeof value === 'object' ? value : (options.find((i) => i.value === value) || defaultValue);

	return (
		<Group {...props}>
			<Select
				className={className}
				id={id}
				defaultValue={defaultValue}
				options={options}
				onChange={(status) => setValue(name, status.value)}
				hideSelectedOptions={false}
				isDisabled={disabled}
				value={preparedValue}
				formatOptionLabel={formatOptionLabel}
				placeholder={placeholder}
				onInputChange={onInputChange}
				components={Components}
				required={required}
				classNames={{
					option: (data) => data.isSelected ? 'selected' : '',
				}}
				{...extraProps}
			/>
		</Group>
	);
}

SelectField.defaultProps = {
	options: [],
	defaultValue: {
		value: '',
		label: '',
	},
	setValue: () => {
	},
	formatOptionLabel: null,
	value: '',
	required: false,
	name: '',
	disabled: false,
	extraProps: {},
	id: 'custom-dropdown-default',
}

export default SelectField;
