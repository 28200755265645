import React from 'react';
import {Route} from "react-router-dom";
import Alert from "../app/shared/components/alert/Alert";
import Checkout from "../app/checkout/Checkout";

const CheckoutRoute = ({path}) => {

	return (
		<>
			{/*<Route exact path={RouterPaths.BasketTransferEN} component={BasketTransferDetails} />*/}
			<Route exact path={path} component={Checkout} />
			<Alert/>
		</>
	);
};

export default CheckoutRoute;
