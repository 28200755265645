import React, {useEffect, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {getStateRoot} from "../../../listReducer";
import {services} from "../../../../../../RestServices";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import DPDForm from "./DPDForm";
import api from "../../../../../../../services/axios/axios";
import {selmoUrl} from "../../../../../../../src_shared/api/api";
import Loader from "../../../../../shared/Loader";
import {setGenerateLabelModalVisibility} from "../../../../../shared/generateLabelModal/generateLabelModalActions";

export const SK_BATCH_LABEL_DPD_VIEW = 'SK_BATCH_LABEL_DPD_VIEW';
export const SK_BATCH_LABEL_GLS_VIEW = 'SK_BATCH_LABEL_GLS_VIEW';
const GenerateSkLabelModal = ({
                                  modalVisible,
                                  hideModal,
                              }) => {

    const {getLangText} = useLang();
    const [activeView, setActiveView] = useState(SK_BATCH_LABEL_DPD_VIEW);
    const dispatch = useDispatch();
    const {selectedItems} = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));
    const {userData} = useSelector((state) => state.session);
    const [waitingForGenerate, setWaitingForGenerate] = useState(false);

    const isGlsActive = !!+userData.shop_gls_sk_active;
    const isDpdActive = !!+userData.shop_dpd_sk_active;

    const generateLabels = async (type, templateId = null, e) => {
        e?.preventDefault();
        setWaitingForGenerate(true);
        const values = {
            orders: selectedItems.map((i) => i.id),
            type,
        }

        if (templateId) {
            values.template = templateId;
        }

        try {
            await api.post(`${selmoUrl}/${services.API_BATCH_LABELS}`, values)
            dispatch(setGenerateLabelModalVisibility(true, {
                rest: services.API_PRINT_LABELS,
                items: selectedItems,
            }))
            hideModal();
        } catch (e) {
            console.error(e)
        } finally {
            setWaitingForGenerate(false);
        }
    }


    useEffect(() => {
        if (isDpdActive) {
            setActiveView(SK_BATCH_LABEL_DPD_VIEW)
            return;
        }
        if (isGlsActive) {
            setActiveView(SK_BATCH_LABEL_GLS_VIEW)
            return;
        }
        setActiveView(null)
    }, [isGlsActive, isDpdActive])

    return (
        <Modal
            show={modalVisible}
            dialogClassName="default-modal w-380"
            onHide={hideModal}
        >
            <Modal.Header className="with-tabs">
                <Modal.Title>
                    {getLangText('generateLabelsLabel')}
                </Modal.Title>
                <div className="tabs-wrapper">
                    {isDpdActive &&
                        <button
                            type="button"
                            className={activeView === SK_BATCH_LABEL_DPD_VIEW ? 'active' : ''}
                            onClick={() => setActiveView(SK_BATCH_LABEL_DPD_VIEW)}
                        >
                            DPD
                        </button>
                    }
                    {isGlsActive &&
                        <button
                            type="button"
                            className={activeView === SK_BATCH_LABEL_GLS_VIEW ? 'active' : ''}
                            onClick={() => setActiveView(SK_BATCH_LABEL_GLS_VIEW)}
                        >
                            GLS
                        </button>
                    }
                </div>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            <Modal.Body>
                <div className="orders-labels">
                    {activeView === SK_BATCH_LABEL_DPD_VIEW &&
                        <DPDForm
                            generateLabels={generateLabels}
                            waitingForGenerate={waitingForGenerate}
                        />
                    }
                    {activeView === SK_BATCH_LABEL_GLS_VIEW &&
                        <button
                            onClick={() => generateLabels(5)}
                            type="button"
                            className={`button primary w-full ${waitingForGenerate ? 'loading-button' : ''}`}
                        >
                            {waitingForGenerate && <Loader isLoading/>}
                            {getLangText('generateLabelsLabel')}
                        </button>
                    }
                    {!activeView &&
                        <div className="empty-view pb-4">
                            <div className="labels-header">
                                <div className="labels-title mb-2">
                                    {getLangText('noIntegrationsToGenerateLabel')}
                                </div>
                            </div>
                            {/*<a*/}
                            {/*    className="button border-button big-width w-100"*/}
                            {/*    href="/ustawienia/integracje/"*/}
                            {/*    target="_blank"*/}
                            {/*>*/}
                            {/*    Skonfiguruj*/}
                            {/*</a>*/}
                        </div>
                    }
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default GenerateSkLabelModal;

