import React from 'react';
import Field from "../../../../../src_shared/components/form/field/Field";
import {getCurrenciesByShortcut} from "../../../stats/list/List";
import useLang from "../../../../../src_shared/hooks/useLang";
import {checkRoles} from "../../../../routes/PermissionRoute";
import {SHOP_PERMISSION} from "../../../shared/enums/SelmoPermissions";
import {useSelector} from "react-redux";
import {SHOP_ORIGIN_DA} from "../../../shared/enums/ShopOrigins";
import useWindowDimensions from "../../../messenger/hooks/useWindowDimensions/useWindowDimensions";

const PriceAndCountInput = ({getFieldProps, setValue, currency_info, values, inModal}) => {
    const {getLangText} = useLang();
    const {width} = useWindowDimensions();

    const {permissions, origin} = useSelector((state) => state.session.userData);

    return (
        <div className="flex space-x-2 sm:space-x-4">
            {!+values.has_sizes &&
                <div
                    className={`main-quantity-wrapper flex-1`}>
                    <Field
                        {...getFieldProps('squ')}
                        setValue={setValue}
                        label={getLangText('quantityLabel')}
                        type="number"
                        placeholder={getLangText('enterQuantityLabel')}
                    />
                </div>
            }
            <div className={`flex-1`}>
                <Field
                    {...getFieldProps('price')}
                    setValue={setValue}
                    label={getLangText('priceLabel')}
                    addon={getCurrenciesByShortcut(currency_info?.text)}
                    type="number"
                    placeholder="0.00"
                />
            </div>
            {(origin === SHOP_ORIGIN_DA || !inModal) &&
                <div className={`flex-1`}>
                    <Field
                        {...getFieldProps('old_price')}
                        setValue={setValue}
                        label={getLangText('oldPriceLabel')}
                        type="number"
                        addon={getCurrenciesByShortcut(currency_info?.text)}
                        placeholder="0.00"
                        optional={width > 991}
                        labelClassName="whitespace-nowrap"
                    />
                </div>
            }
        </div>
    );
};

export default PriceAndCountInput;
