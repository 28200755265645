import React, {useState} from 'react';
import cn from "clsx";
import OwnerIcon from "../../../../../src_shared/components/ui/Iocns/OwnerIcon";
import ClientIcon from "../../../../../src_shared/components/ui/Iocns/ClientIcon";
import Button from "../../../../../src_shared/components/ui/Button/Button";
import useLang from "../../../../../src_shared/hooks/useLang";
import {ONBOARDING_CREATE_SHOP_VIEW, ONBOARDING_CUSTOMER_TYPE_VIEW} from "../CreateShopFirstStep";

const OWNER_TYPE = 'OWNER_TYPE';
const CUSTOMER_TYPE = 'CUSTOMER_TYPE';

const options = [
    {id: OWNER_TYPE, label: 'shopOwnerLabel', desc: 'tutorialAccountSellerSubTitle'},
    {id: CUSTOMER_TYPE, label: 'shopClientLabel', desc: 'shoppingInShopLabel'},
]
const AccountTypeOptions = ({setActiveView, setInternalView}) => {
    const {getLangText} = useLang();
    const [activeType, setActiveType] = useState('');
    const [validationActive, setValidationActive] = useState(false);


    const isValidationActive = validationActive && !activeType;
    const handleSubmit = () => {
        if (!activeType) {
            setValidationActive(true);
        } else if (activeType === CUSTOMER_TYPE) {
            setInternalView(ONBOARDING_CUSTOMER_TYPE_VIEW)
        } else {
            setActiveView(ONBOARDING_CREATE_SHOP_VIEW)
        }
    };

    return (
        <>
            <div className="w-full sm:w-[380px] mx-auto animate-tutorialSlideAndFadeInDelay2 opacity-0">
                <div className="grid sm:grid-cols-2 gap-3 sm:gap-4">
                    {options.map((option) => (
                        <button
                            key={option.id}
                            onClick={() => setActiveType(option.id)}
                            type="button"
                            className="cursor-pointer text-left"
                        >
                            <div
                                className={cn('relative border-2 sm:p-2 p-3 rounded-[8px] flex items-center justify-center sm:h-[140px] transition-all', isValidationActive ? 'border-[#ef4444] bg-[#FEF2F2]' : option.id === activeType ? 'border-[#3c61ea] bg-[#F7F9FE]' : '')}>
                                <div
                                    className={`sm:absolute mr-sm-0 mr-2 top-2 right-2 w-[20px] h-[20px] rounded-full transition-all border flex items-center justify-center ${isValidationActive ? 'border-[#F87171]' : activeType === option.id ? 'bg-[#3c61ea] border-[#3c61ea]' : 'border-[#dadada]'}`}>
                                    <i className={`icon-tick text-[white] text-[8px] transition-all delay-200 ${activeType === option.id ? 'opacity-100' : 'opacity-0'}`}/>
                                </div>
                                <div className="sm:hidden mr-2">
                                    <div
                                        className="font-bold text-sm text-[#101827] mb-1">{getLangText(option.label)}</div>
                                    <div className="font-medium text-xs text-desc">{getLangText(option.desc)}</div>
                                </div>
                                {option.id === OWNER_TYPE ?
                                    <OwnerIcon
                                        className="transition-all sm:w-auto ml-sm-0 ml-auto w-[20px]"
                                        fill={isValidationActive ? '#F87171' : activeType === option.id ? '#3c61ea' : '#9ca3af'}/> :
                                    <ClientIcon
                                        className="transition-all sm:w-auto ml-sm-0 ml-auto w-[20px]"
                                        fill={isValidationActive ? '#F87171' : activeType === option.id ? '#3c61ea' : '#9ca3af'}/>
                                }
                            </div>
                            <div className="mt-2 sm:block hidden">
                                <div
                                    className="font-bold text-sm text-[#101827] mb-1">{getLangText(option.label)}</div>
                                <div className="font-medium text-xs text-desc">{getLangText(option.desc)}</div>
                            </div>
                        </button>
                    ))}
                </div>
                {isValidationActive &&
                    <div className="form-info-box red small-line-height w-full mt-3">
                        <div>
                            <i className="icon-alert-c"/>
                        </div>
                        <div className="title mt-1">
                            {getLangText('selectAccountTypeLabel')}
                        </div>
                    </div>
                }
            </div>
            <div
                className="mt-auto sm:mx-0 -mx-6 bg-[#F9FAFB] sm:w-full p-[20px] rounded-b-[12px] text-right">
                <Button
                    onClick={handleSubmit}
                    type="button"
                    title="goNextLabel"
                    className="bg-[#3C61EA] overflow-hidden  button text-xs sm:text-sm font-semibold rounded-[8px] px-[20px] py-[11px] text-white transition-all hover:bg-[#1c4ed8]"
                />
            </div>
        </>
    );
};

export default AccountTypeOptions;