import React from 'react';
import Loader from "../../../../shared/Loader";
import Field from "../../../../../../src_shared/components/form/field/Field";
import TextArea from "../../../../../../src_shared/components/form/textarea/TextArea";
import ReactPlayerVideo from "../../components/ReactPlayerVideo";
import FileUpload from "../../../../../../src_shared/components/form/fileUpload/FileUpload";
import {services} from "../../../../../RestServices";
import {selmoAPI2Url} from "../../../../../../src_shared/api/api";
import EmptyList from "../../../../shared/EmptyList";
import SelectedProducts from "../selectedProducts/SelectedProducts";
import SwitchField from "../../../../../../src_shared/components/form/switchField/SwitchField";
import {useSelector} from "react-redux";
import useLang from "../../../../../../src_shared/hooks/useLang";
import {useParams} from "react-router-dom";

const ReelsFormComponent = ({
                                isLoading,
                                getFieldProps,
                                values,
                                updateValues,
                                setShowAssignProductsModal,
                                setValue,
                                isWaiting,
                                onSave,
                                openRemoveModal
                            }) => {
    const {userData} = useSelector((state) => state.session);
    const {getLangText} = useLang();
    const {id} = useParams();

    const AssignProductsButton = () => (
        <button
            onClick={() => setShowAssignProductsModal(true)}
            type="button"
            className="button border-button small-size mt-3"
        >
            {getLangText('assignProductsLabel')}
        </button>
    )

    return (
        <div className="loader-parent">
            <Loader isLoading={isLoading}/>
            <div className="settings-form">
                <Field
                    {...getFieldProps('name')}
                    label={getLangText('nameLabel')}

                />
                <TextArea
                    {...getFieldProps('description')}
                    label={getLangText('input_label_package_type_description')}
                />
                <Field
                    {...getFieldProps('url')}
                    label={getLangText('videoLinkLabel')}
                />
                <ReactPlayerVideo url={values.url}/>
                {+userData.selmo_boss === 69 &&
                    <div>
                        <div className="form-label">
                            {getLangText('videoFile')}
                        </div>
                        <FileUpload
                            values={values}
                            updateValues={updateValues}
                            validation={false}
                            showLabel={false}
                            internalLabel="insertVideo"
                            restService={`${services.API_VIDEO}/${id}`}
                            restUrl={selmoAPI2Url}
                        />
                    </div>
                }
                <div className="form-label mb-0">
                    {getLangText('assignedProducts')}
                </div>
                <EmptyList
                    items={values.products}
                    isLoading={false}
                    className="with-padd"
                    imgSrc="/assets/images/empty/products-empty.svg"
                    title={getLangText('emptyHereLabel')}
                    text={getLangText('assignProductsLabel')}
                    ExtraComponent={AssignProductsButton}
                >
                    <SelectedProducts
                        products={values.products}
                        setValue={setValue}
                    />
                </EmptyList>
                {!!values.products.length &&
                    <button
                        type="button"
                        className="button text-blue-button add-new-row mt-3"
                        onClick={() => setShowAssignProductsModal(true)}
                    >
                        <i className="icon-plus"/>
                        {getLangText('assignProductsLabel')}
                    </button>
                }
                <div className="mt-3">
                    <SwitchField
                        {...getFieldProps('active')}
                        label={getLangText('active')}
                        className="switch-form inline-label align-items-start big-label light-version mt-3 mb-0"
                    />
                </div>
                <div className="button-place mt-3">
                    <button
                        type="button"
                        className={`button primary ${isWaiting ? 'loading-button' : ''}`}
                        onClick={onSave}
                    >
                        {isWaiting && <Loader isLoading/>}
                        {getLangText(!id ? 'editButton' : 'saveButton')}
                    </button>
                </div>
                {!!+id &&
                    <div className="remove-delivery-section">
                        <button
                            type="button"
                            onClick={() => openRemoveModal(values)}
                            className="button border-button left-icon big-size"
                        >
                            <i className="icon-bin"/>
                            {getLangText('removeReelsLabel')}
                        </button>
                    </div>
                }
            </div>
        </div>
    );
};

export default ReelsFormComponent;
