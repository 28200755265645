import FormActions from "../../../../src_shared/form/FormActions";
import {showAlert} from "../../shared/components/alert/AlertActions";
import axios from "axios";
import {selmoUrl} from "../../../../src_shared/api/api";
import api from "../../../../services/axios/axios";

export class SharedShopSettingsActions extends FormActions {
	submitEditForm(values, afterSubmit) {
		return async (dispatch) => {
			dispatch(this.validation.activateValidation());

			if (!this.dataModel.validateData(values)) {
				dispatch(showAlert('fillRequireFieldWarning', '', 'alert-fail'))
				console.error('Validation failed!');
				return;
			}

			const filteredData = Object.entries(values).filter(([key]) => key !== 'id');
			const finalData = Object.fromEntries(filteredData)

			const preparedData = this.dataModel.buildDTO(finalData)

			dispatch(this.waiting.startWaiting());

			try {
				const {data} = await api.post(`${selmoUrl}/${this.restService}`, preparedData)
				dispatch(this.loadData(data.id))
				dispatch(showAlert('yuhuLabel', 'changesWillBeVisibleInFewMinutesLabel'))
				dispatch(this.validation.deactivateValidation())
				afterSubmit(values);
			} catch (e) {
				dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
				console.error('Error submitEditForm')
			} finally {
				dispatch(this.waiting.stopWaiting());
			}
		}
	}
}
