import React from 'react';
import moment from "../../../../../messenger/helpers/moment.js";
import EmptyList from "../../../../../shared/EmptyList.jsx";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../../../routes/RouterPath.js";
import CustomDropdown from "../../../../../shared/components/dropdown/CustomDropdown";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import TimeAgo from "../../../../../../../src_shared/helpers/TimeAgo";
import useTableRowClick from "../../../../../shared/hooks/useTableRowClick";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import {useSelector} from "react-redux";
import ClientAvatar from "../../../../../live/shared/Avatar";
import ProductTypeIcon from "../../../../../shared/components/productType/ProductTypeIcon";
import ServerImage from "../../../../../shared/components/serverImage/ServerImage";
import {convertToUserTimeZone} from "../../../../../shared/helpers/dateHelpers";
import {useUnitPrice} from "../../../../../shared/helpers/Price";
import SelmoBoosterTag from "../../../../../../../src_shared/components/ui/SelmoBoosterTag/SelmoBoosterTag";

const ProductsTable = ({items, isWaiting, updateSortOptions, getSortClass}) => {
    const {getLangText} = useLang();
    const {lang} = useSelector((state) => state.i18n);
    const {getPrice} = useUnitPrice();

    const onTableRowClick = useTableRowClick(`${RouterPaths.ProductsList}/edytuj`)

    return (
        <EmptyList
            items={items}
            isLoading={isWaiting}
            className="with-padd"
            imgSrc="/assets/images/empty/products-empty.svg"
            title={getLangText('emptyHereLabel')}
            text={getLangText('changeSearchOrAddProductsLabel')}
        >
            <div className="table-wrapper">
                <div className="table-responsive overflow-visible">
                    <table className="table live-table smaller-padd">
                        <thead>
                        <tr>
                            <th
                                name="selmo_code"
                                onClick={updateSortOptions}
                                className={getSortClass('selmo_code')}
                            >
							<span name="selmo_code">
								{getLangText('productCodeLabel')}
							</span>
                            </th>
                            <th
                                name="products.name"
                                onClick={updateSortOptions}
                                className={getSortClass('products.name')}
                            >
							<span name="products.name">
								{getLangText('productLabel')}
							</span>
                            </th>
                            <th
                                name="selmo_clients.name"
                                onClick={updateSortOptions}
                                className={getSortClass('selmo_clients.name')}
                            >
							<span name="selmo_clients.name">
								{getLangText('clientLabel')}
							</span>
                            </th>
                            <th
                                name="products.price"
                                onClick={updateSortOptions}
                                className={getSortClass('products.price')}
                            >
							<span name="products.price">
								{getLangText('priceLabel')}
							</span>
                            </th>
                            <th
                                name="quantity"
                                onClick={updateSortOptions}
                                className={getSortClass('quantity')}
                            >
							<span name="quantity">
								{getLangText('quantityLabel')}
							</span>
                            </th>
                            <th>
                                {getLangText('sourceLabel')}
                            </th>
                            <th
                                name="date"
                                onClick={updateSortOptions}
                                className={`text-right ${getSortClass('date')}`}
                            >
							<span name="date">
								{getLangText('dateLabel')}
							</span>
                            </th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {items.map((item) => (
                            <tr
                                {...onTableRowClick(item.product_id)}
                                key={item.id}
                            >
                                <td
                                    data-thead={`${getLangText('codeLabel')}:`}
                                    className="number with-mobile-thead inline-thead order-lg-0 order-1 my-lg-0 my-1"
                                >
                                    {item.selmo_code}
                                </td>
                                <td className="with-photo w-lg-auto w-100">
                                    <div className="d-flex align-items-lg-center mt-sm-0 mt-2">
                                        <div>
                                            <figure>
                                                {item.product_photo && item.product_photo !== '0' ?
                                                    <ServerImage src={item.product_photo} alt={item.name}/> :
                                                    <img src="/assets/images/default/product.svg" alt=""/>
                                                }
                                            </figure>
                                        </div>
                                        <div>
                                            <div className="product-name">
                                                {item.name}
                                                <span
                                                    className="d-lg-none">{` • ${getPrice(item.price, item.currency_text)}`}</span>
                                                <span
                                                    className="d-lg-none">{` • ${item.quantity} ${getLangText('piecesShortLabel')}`}</span>
                                                <div className="small">{item.size_name}</div>
                                                {!!item.selmo_booster &&
                                                    <SelmoBoosterTag />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td className="with-photo small-client full-radius w-lg-auto w-100">
                                    <div className="d-flex align-items-lg-center mt-sm-0 mt-2">
                                        <ClientAvatar
                                            className="d-lg-block d-none"
                                            photo={item.client_avatar}
                                            type={item.social_type}
                                        />
                                        <div>
                                            <div className="product-name">
                                                {item.client_name || getLangText('noNameLabel')}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td className="d-lg-table-cell d-none">
                                    {getPrice(!!+item.size_price ? item.size_price : item.price, item.currency_text)}
                                </td>
                                <td className="d-lg-table-cell d-none">
                                    {item.quantity}
                                </td>
                                <td className={`d-lg-table-cell d-flex text-center ${item.type ? '' : 'p-0'}`}>
                                    <ProductTypeIcon product={item}/>
                                </td>
                                <td
                                    data-thead={`${getLangText('addedLabel')}:`}
                                    className="text-sm-right date w-lg-auto w-100 order-lg-0 order-2 with-mobile-thead inline-thead mb-lg-0 mb-2 text-nowrap"
                                >
                                    <OverlayTrigger
                                        placement='top'
                                        overlay={
                                            <Tooltip
                                                className="small-size"
                                                id="time"
                                            >
                                                {convertToUserTimeZone(item.date).format('DD.MM.YYYY HH:mm')}
                                            </Tooltip>
                                        }
                                    >
                                        <TimeAgo
                                            datetime={convertToUserTimeZone(item.date).format('YYYY-MM-DD HH:mm')}
                                            locale={lang}
                                        />
                                    </OverlayTrigger>
                                </td>
                                <td className="text-right absolute-dropdown-dots">
                                    <CustomDropdown
                                        icon="icon-dots"
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <Link
                                            to={`${RouterPaths.OrdersList}/${item.shop_order_id}`}
                                            className="custom-option"
                                        >
                                            {getLangText('goToOrderButton')}
                                        </Link>
                                        <Link
                                            to={`${RouterPaths.ClientsList}/${item.shop_client_id}`}
                                            className="custom-option"
                                        >
                                            {getLangText('goToClientButton')}
                                        </Link>
                                    </CustomDropdown>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </EmptyList>
    );
};

export default ProductsTable;
