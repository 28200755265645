import React from 'react';
import {Helmet} from "react-helmet";
import FormActions from "../edit/FormActions";
import ImageUploadGallery from "../../../../../src_shared/components/form/imageUploadGallery/ImageUploadGallery";
import useForm from "../../../../../src_shared/form/useForm";
import {useDispatch, useSelector} from "react-redux";
import useLang from "../../../../../src_shared/hooks/useLang";
import {createSelector} from "reselect";
import {getStateRoot} from "../edit/formReducer";
import {useParams} from "react-router-dom";
import Loader from "../../../shared/Loader";
import ImageUpload from "../../../../../src_shared/components/form/imageUpload/ImageUpload";

const ProductGallery = () => {
    const dispatch = useDispatch();
    const {getLangText} = useLang();
    const {selectedPhotos} = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));
    const params = useParams()

    const {
        data: {values, updateValues},
        form: {isWaiting},
    } = useForm({
        FormActions,
        params,
        beforeDestroy: () => {
        }
    });

    const updateProductsOrdersOnServer = (items) => dispatch(FormActions.updateProductsOrdersOnServer(items, values.id))
    const setSelectedGalleryPhoto = (photo) => dispatch(FormActions.setSelectedGalleryPhoto(photo))
    const setSelectedGalleryPhotos = (photos) => dispatch(FormActions.setSelectedGalleryPhotos(photos))

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{values.name} - {getLangText('editProductLabel')} - Selmo</title>
            </Helmet>
            <div className="loader-parent" style={{minHeight: '300px'}}>
                <Loader isLoading={isWaiting}/>
                <div className="settings-form bg-white rounded-b">
                    <h2 className="mb-1">{getLangText('photoLabel')}</h2>
                    <div className="header-description mb-3">{getLangText('productPhotoGalleryMainPhotoSubLabel')}</div>
                    <ImageUpload
                        values={values}
                        updateValues={updateValues}
                        FormActions={FormActions}
                        field="photo_small"
                        proportions="proportionLabel"
                        withRemove={false}
                    />
                    <hr className="mt-5 pb-3"/>
                    <h2 className="mb-1 mt-4">{getLangText('productPhotoGalleryLabel')}</h2>
                    <div className="header-description">{getLangText('productPhotoGallerySubLabel')}</div>
                    <div className="header-description extra-small-text mt-1">
                        {`(${getLangText('maxImageSizeLabel')})`}
                    </div>
                    <ImageUploadGallery
                        values={values}
                        updateValues={updateValues}
                        FormActions={FormActions}
                        updateAllItemsOnServer={updateProductsOrdersOnServer}
                        selectedPhotos={selectedPhotos}
                        setSelectedGalleryPhoto={setSelectedGalleryPhoto}
                        setSelectedGalleryPhotos={setSelectedGalleryPhotos}
                    />
                </div>
            </div>
        </>
    );
};

export default ProductGallery;
