import Composition from "../../../../../shared/helpers/Composition.js";
import createReducer from "../../../../../shared/helpers/createReducer.js";
import FormReducer from "../../../../../../../src_shared/form/FormReducer.js";

export const getStateRoot = (state) => state.lives.assignModal;

export const prefix = 'LIVE_DETAILS_ASSIGN_MODAL_';

export const getInitState = () => FormReducer.getInitState({
	name: '',
})

export const getReduceMap = () => new Composition(
	FormReducer.getReduceMap(),
);

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();
