import React from 'react';
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import useLang from "../../../../src_shared/hooks/useLang";
import {Helmet} from "react-helmet";
import TabsWrapper from "../../components/TabsWrapper";
import ProfileBoxes from "./ProfileBoxes";

const ProfileDashboard = () => {
    const {getLangText} = useLang();

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getLangText('settings_nav_profile_settings')} - Selmo</title>
            </Helmet>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <Link to={RouterPaths.Settings}>{getLangText('asideSettingsLabel')}</Link>
                    </li>
                    <li className="active">
                        <div>{getLangText('settings_nav_profile_settings')}</div>
                    </li>
                </ul>
            </div>
            <div className="settings-page">
                <TabsWrapper>
                    <div className="settings-wrapper rounded-b-[5px]">
                        <ProfileBoxes/>
                    </div>
                </TabsWrapper>
            </div>
        </>
    )
};

export default ProfileDashboard;
