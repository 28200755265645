import React, {useState} from 'react';
import useLang from "../../../../../../src_shared/hooks/useLang";
import Faq from "./Faq";

export const questions = [
    {
        title: 'paymentsHowItWorksLabel',
        desc: 'faqNotificationsSubLabel1',
        id: 0
    },
    {
        title: 'faqNotificationsLabel2',
        desc: 'faqNotificationsSubLabel2',
        id: 1
    },
    {
        title: 'faqNotificationsLabel3',
        desc: 'faqNotificationsSubLabel3',
        id: 2
    },
    {
        title: 'faqNotificationsLabel4',
        desc: 'faqNotificationsSubLabel4',
        id: 3
    },
    {
        title: 'faqNotificationsLabel5',
        desc: 'faqNotificationsSubLabel5',
        id: 4
    },
]
const FaqSection = () => {
    const {getLangText} = useLang();
    const [isActive, setActive] = useState(0);
    return (
        <section className="padd-section pt-0 px-0">
            <div className="section-sub-title">
                {getLangText('applicationLabel')}
            </div>
            <div className="section-title mb-3">
                <strong>{getLangText('faqPageTitle')}</strong>
            </div>
            <Faq
                setActive={setActive}
                isActive={isActive}
                questions={questions}
            />
        </section>
    );
};

export default FaqSection;
