import React from 'react';
import {useHistory} from "react-router-dom";

const useTableRowClick = (path) => {
	const history = useHistory();
	const onTableRowClick = (id, e, setSelectedRow = () => {
	}) => {
		const url = `${path}/${id}/`;
		setSelectedRow(id)
		if (e.type === 'click') {
			history.push(url);
		} else if (e.type === 'contextmenu') {
			e.preventDefault();
			window.open(url);
		}
	}

	const onMouseDown = (id, e) => {
		const url = `${path}/${id}/`;
		if (e.button === 1 || e.buttons === 4) {
			window.open(url);
		}
	}

	return (id, setSelectedRow) => ({
		onClick: (e) => onTableRowClick(id, e, setSelectedRow),
		onContextMenu: (e) => onTableRowClick(id, e, setSelectedRow),
		onMouseDown: (e) => onMouseDown(id, e),
		key: id,
	})
}

export default useTableRowClick;
