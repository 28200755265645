import FormReducer from "../../../src_shared/form/FormReducer";
import AutomaticallyNotificationsModel from "../../../modules/models/settings/AutomaticallyNotificationsModel";
import Composition from "../../app/shared/helpers/Composition";
import createReducer from "../../app/shared/helpers/createReducer";

export const getStateRoot = (state) => state.settings.automaticallyNotifications;
export const prefix = 'AUTOMATICALLY_NOTIFICATIONS_CODES_FORM_';

export const getInitState = () => FormReducer.getInitState(AutomaticallyNotificationsModel.getModel())

export const getReduceMap = () => new Composition(
	FormReducer.getReduceMap(),
);

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();
