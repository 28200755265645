import React from 'react';
import AsyncSelectField from "../../../../../../src_shared/components/form/selectField/AsyncSelectField";
import useLang from "../../../../../../src_shared/hooks/useLang";
import {services} from "../../../../../RestServices";

const ManufacturerSelect = ({getFieldProps, setValue, values, inModal}) => {
    const {getLangText} = useLang();

    return (
        <div className={inModal ? '' : 'w-50 mt-3'}>
            <AsyncSelectField
                {...getFieldProps('manufacturer_id')}
                setValue={setValue}
                label={getLangText('manufacturerLabel')}
                defaultValue={{
                    id: values.manufacturer_id,
                    name: values.manufacturer_name
                }}
                rest={services.API_STORAGE_SEARCH_MANUFACTURERS}
                optionsParser={(items) => items.map((i) => ({
                    value: i.id,
                    label: i.name,
                }))}
            />
        </div>
    );
};

export default ManufacturerSelect;
