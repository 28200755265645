import React from 'react';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import ExportModal from "../../../../../src_shared/components/exportModal/ExportModal";
import {services} from "../../../../RestServices";
import useLang from "../../../../../src_shared/hooks/useLang";
import {useModal} from "../../../shared/hooks/useModal";
import {useParams} from "react-router-dom";

const ExportProductCard = () => {

    const {getLangText} = useLang();
    const [openModal, setOpenModal] = useModal(false);
    const {id} = useParams();

    return (
        <div className="mt-1">
            <OverlayTrigger
                placement='top'
                overlay={
                    <Tooltip id="product-card">
                        {getLangText('exportProductCard')}
                    </Tooltip>
                }
            >
                <button
                    onClick={() => setOpenModal(true)}
                    type="button"
                    className="icon-button packed-button print-order-button ml-sm-2 default-size"
                >
                    <i className="icon-print"/>
                </button>
            </OverlayTrigger>
            {openModal &&
                <ExportModal
                    title={getLangText('exportProductCard')}
                    saveButton={getLangText('downloadLabel')}
                    rest={`${services.API_EXPORT_PRODUCT_INFO}/${id}`}
                    fileName="product-card"
                    show={openModal}
                    hide={() => setOpenModal(false)}
                    initialCheckBigFile={false}
                />
            }
        </div>
    );
};

export default ExportProductCard;