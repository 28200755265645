import {prefix} from "./formReducer";
import {getStateRoot} from "./formReducer";
import FormActions from "../../../../src_shared/form/FormActions";
import BasketDeliveryMethodModel from "../../../../modules/models/basket/BasketDeliveryMethodModel";
import {services} from "../../../RestServices";

import {selmoUrl} from "../../../../src_shared/api/api";
import {ERROR_NOT_FOUND} from "../../../../src_shared/enums/ErrorEnums";
import {getStateRoot as getDeliveryMethodsStateRoot} from '../deliveryMethods/formReducer'
import {getGETParamsUrl} from "../../../../src_shared/list/ListActions";
import {batch} from "react-redux";
import BasketPageFormActions from "../page/FormActions";
import {ORDER_PAYMENT_TRANSFER} from "../../shared/enums/PaymentStatuses";
import BasketDeliveryMethodsFormActions from "../deliveryMethods/FormActions";
import api from "../../../../services/axios/axios";
import {cloneDeep} from "lodash";
import {showAlert} from "../../shared/components/alert/AlertActions";

export class BasketPaymentMethodsFormActions extends FormActions {
	setPaymentMethodsValidation(toggle) {
		return {
			type: `${this.prefix}SET_PAYMENT_METHODS_VALIDATION`,
			toggle,
		}
	}

	loadData(magicUrl, shopId, value) {
		return async (dispatch, getState) => {
			dispatch(this.waiting.startWaiting());
			const {open_cart_status} = getDeliveryMethodsStateRoot(getState()).data;
			const {payment} = getStateRoot(getState()).data;
			const params = {
				magic_url: magicUrl,
				shop_id: shopId,
				open_cart_status: value || open_cart_status,
			};

			try {
				const {data} = await api.get(`${selmoUrl}/${this.restService}${getGETParamsUrl(params)}`)
				const parsedItem = dispatch(this.parseData(data.item));

				let preparedAdditionalTransfers = [];
				data.item?.payment_methods?.map((i) => i?.additional_transfer_accounts?.length && i?.additional_transfer_accounts?.map((x) => {
					preparedAdditionalTransfers.push({
						type: `${ORDER_PAYMENT_TRANSFER}_${x.id}`
					})
				}));
				const paymentExist = data?.item.payment_methods?.concat(preparedAdditionalTransfers).some((i) => i.type === payment);

				dispatch(this.data.updateValues(parsedItem))

				if (!paymentExist) {
					dispatch(this.data.setValue('payment', null))
					dispatch(BasketDeliveryMethodsFormActions.data.setValue('payment', null))
				}
			} catch (e) {
				if (e.response?.data?.message === ERROR_NOT_FOUND || e.response?.status === 500) {
					dispatch(this.data.setValue('notFound', true));
				}
				console.error('Error loadData')
			} finally {
				dispatch(this.waiting.stopWaiting());
			}
		}
	}

	setValueOnServer(field, value, magicUrl, shopId) {
		return async (dispatch, getState) => {
			const {data} = getStateRoot(getState());
			const cloned = cloneDeep(data);

			try {
				const preparedValue = typeof field === 'object' ? field : {[field]: value}
				dispatch(this.data.updateValues(preparedValue))

				await api.put(`${selmoUrl}/${services.API_CART_PAYMENT_METHODS}`, {
					...preparedValue,
					magic_url: magicUrl,
					shop_id: shopId,
				})
				batch(() => {
					dispatch(BasketPageFormActions.getAndUpdatePrices(magicUrl, shopId));
				})
			} catch (e) {
				dispatch(showAlert('cannotSetPayment'))
				dispatch(this.data.updateValues(cloned))
				console.error('Error send value on server')
			}
		}
	}
}

export const getInstance = () => new BasketPaymentMethodsFormActions({
	prefix,
	getStateRoot,
	model: BasketDeliveryMethodModel,
	restService: services.API_CART_PAYMENT_METHODS,
});

export default getInstance();

