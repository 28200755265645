import React, {useEffect} from 'react';
import Loader from "../../../../../src_shared/components/loader/Loader";
import {useDispatch, useSelector} from "react-redux";
import useLang from "../../../../../src_shared/hooks/useLang";
import {getFromLS} from "../../../../../src_shared/helpers/LS";
import moment from "../../moment";
import ShopSettingsLoaderActions from "./ShopSettingsLoaderActions";
import cn from "clsx";
import {convertToUserTimeZone} from "../../helpers/dateHelpers";

const ShopSettingsLoader = ({className = ''}) => {

	const {getLangText} = useLang();
	const dispatch = useDispatch();

	const {isChanging} = useSelector((state) => state.shared.shopSettingsLoader);
	const difference = convertToUserTimeZone(getFromLS('changeShouldBeReady')).diff(convertToUserTimeZone().toDate(), 'seconds');

	useEffect(() => {

		if (getFromLS('changeShouldBeReady') && (convertToUserTimeZone(getFromLS('changeShouldBeReady')).toDate() > convertToUserTimeZone().toDate())) {
			dispatch(ShopSettingsLoaderActions.changingVisibility(getFromLS('changeShouldBeReady')))
		}

		if (convertToUserTimeZone(getFromLS('changeShouldBeReady')).toDate() < convertToUserTimeZone().toDate()) {
			dispatch(ShopSettingsLoaderActions.changingVisibility(null))
		}

		const intervalId = setTimeout(() => {
			dispatch(ShopSettingsLoaderActions.changingVisibility(null))
		}, difference * 1000);
		return () => {
			clearInterval(intervalId);
		};
	}, [isChanging]);


	if (!isChanging) {
		return null;
	}

	return (
		<div className={cn('tooltip-parent sm:order-1 order-2', className)}>
			<div className="tooltip-content">
				{getLangText('mightTakeAFewMinutesLabel')}
			</div>
			<div className="d-flex align-items-center active-change mr-3">
				<Loader isLoading className="w-auto extra-small-loader static-loader"/>
				{getLangText('publishingChangesLabel')}
			</div>
		</div>
	);
};

export default ShopSettingsLoader;
