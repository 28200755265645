import {getStateRoot, prefix} from "./formReducer.js";
import {services} from "../../../../RestServices";
import FormActions from "../../../../../src_shared/form/FormActions";
import PageModel from "../../../../../modules/models/shopSettings/PageModel";
import {RouterPaths} from "../../../../routes/RouterPath";
import {showAlert} from "../../../shared/components/alert/AlertActions";
import axios from "axios";
import {selmoUrl} from "../../../../../src_shared/api/api";
import {batch} from "react-redux";
import api from "../../../../../services/axios/axios";

export class ShopSettingsPageFormActions extends FormActions {
	submitEditForm(values, history, afterSubmit) {
		return async (dispatch) => {
			const {id} = values;

			dispatch(this.validation.activateValidation());

			if (!this.dataModel.validateData(values)) {
				dispatch(showAlert('fillRequireFieldWarning', '', 'alert-fail'))
				console.error('Validation failed!');
				return;
			}

			const filteredData = Object.entries(values).filter(([key]) => key !== 'id');
			const finalData = Object.fromEntries(filteredData)

			const preparedData = this.dataModel.buildDTO(finalData)

			dispatch(this.waiting.startWaiting());

			try {
				await api.put(`${selmoUrl}/${this.restService}/${id}`, preparedData)
				dispatch(this.afterSubmit(id))
				dispatch(showAlert('yuhuLabel', 'changesWillBeVisibleInFewMinutesLabel'))
				dispatch(this.validation.deactivateValidation())
				afterSubmit()
			} catch (e) {
				if (e.response.data.message === 'URL_ALREADY_EXIST') {
					dispatch(showAlert('URL_ALREADY_EXIST', '', 'alert-fail'))
					return;
				}
				dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
				console.error('Error submitEditForm')
			} finally {
				dispatch(this.waiting.stopWaiting());
			}
		}
	}

	deleteWithoutAsk(history, afterSubmit) {
		return async (dispatch, getState) => {
			const {data} = this.getStateRoot(getState());
			dispatch(this.waitingForRemoving(true));

			try {
				await api.delete(`${selmoUrl}/${this.restService}/${data.id}`)

				batch(() => {
					dispatch(showAlert('yuhuLabel', 'Pomyślnie usunięto'))
					dispatch(this.afterDeleteSubmit(history));
					afterSubmit();
				})
			} catch (e) {
				dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
			} finally {
				dispatch(this.waitingForRemoving(false));
			}
		}
	}

	afterDeleteSubmit(history) {
		return () => {
			history.push(RouterPaths.ShopPages)
		};
	}
}


export const getInstance = () => new ShopSettingsPageFormActions({
	getStateRoot,
	prefix,
	model: PageModel,
	restService: services.API_STORE_PAGES,
});

export default getInstance();
