import React, {memo, useEffect, useRef, useState} from 'react';
import {WithContext as ReactTags} from "react-tag-input";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {selmoUrl} from "../../../../../src_shared/api/api";
import {removeTagInUser, updateTagInUser} from "../../chatList/ChatListActions";
import {useDispatch, useSelector} from "react-redux";
import useWindowDimensions from "../../hooks/useWindowDimensions/useWindowDimensions";
import useLang from "../../../../../src_shared/hooks/useLang";
import {useParams} from "react-router-dom";
import {services} from "../../../../RestServices";
import useOutsideClick from "../../hooks/useOutsideClick/useOutsideClick";
import api from "../../../../../services/axios/axios";

const Tags = () => {

    const {width} = useWindowDimensions();
    const {getLangText} = useLang();
    const {conversationId} = useParams();
    const dispatch = useDispatch();
    const {activeConversation} = useSelector((state) => state.messenger.chatBox);

    const [tags, setTags] = useState([]);
    const [customSuggestions, setCustomSuggestions] = useState([])
    const [showTagInput, setShowTagInput] = useState(false);
    const tagRef = useRef();
    const tagsWrapperRef = useRef();
    const addTagButtonRef = useRef();
    const suggestionsRef = useRef();


    const KeyCodes = {
        comma: 188,
        enter: 13,
    };

    const delimiters = [KeyCodes.comma, KeyCodes.enter];

    const handleInputChange = async (value) => {
        try {
            const {data} = await api.get(`${selmoUrl}/api/tags?search=${value}`)
            const filteredSuggestions = data.filter((suggestion) => !tags.some((i) => i.text === suggestion.text))
            if (value.length === 0) {
                setCustomSuggestions([])
            } else {
                setCustomSuggestions(filteredSuggestions)
            }
        } catch (e) {
            console.log(e.message)
        }
    }

    const handleAddition = async (newTag) => {

        if (tags?.length > 5) {
            setTags([...tags, {}])
        }

        const tagColors = ['green', 'grey', 'yellow', 'red', 'blue', 'purple', 'pink'];
        const randomColor = tagColors[Math.floor(Math.random() * tagColors?.length)];
        const tagExist = customSuggestions.find((suggestion) => suggestion.text === newTag.text)
        const generateColor = tagExist ? tagExist.className : (newTag.className || randomColor);

        const fullNewTag = {...newTag, className: generateColor};

        setTags([...tags, fullNewTag])
        setCustomSuggestions([]);

        try {
            await api.post(`${selmoUrl}/api/tags`, {
                text: fullNewTag.text,
                className: fullNewTag.className,
                conversation_id: conversationId,
            })
            // dispatch(updateTagInUser('tags', fullNewTag, conversationId))
        } catch (e) {
            setTags(tags.filter((tag) => tag.id !== newTag.id));
        } finally {
            setShowTagInput(false)
        }
    }

    const handleDelete = async (i) => {
        const getTag = tags.find((tag, index) => index === i);
        try {
            await api.post(`${selmoUrl}/${services.API_MESSENGER_TAG}`, {
                conversation_id: conversationId,
                text: getTag.text,
            })
            setTags(tags.filter((tag, index) => index !== i));
            dispatch(removeTagInUser('tags', getTag, conversationId))
        } catch (e) {
            console.warn('Cannot remove tag')
        }
    }

    const deleteSuggestion = async (tag) => {
        try {
            await api.post(`${selmoUrl}/api/deleteTag`, {
                tag_id: tag.id,
            })
            setCustomSuggestions(customSuggestions.filter((i) => i.id !== tag.id))

        } catch (e) {
            console.warn('Cannot delete suggestion')
        }
    }

    const TagsRow = () => {
        let displayedTagsNumber = 5;
        if (width < 767) {
            displayedTagsNumber = 2;
        }
        if (width < 480) {
            displayedTagsNumber = 1;
        }

        const writeSelectedOrders = () => {
            const rest = tags?.length - displayedTagsNumber;
            return rest > 0 ?
                <button onClick={() => setShowTagInput(true)} className="more tag">
                    {getLangText({
                        key: 'andMoreWithNumberLabel',
                        data: [rest]
                    })}
                </button> : '';
        }

        return (
            <div className="tags-row" ref={tagsWrapperRef}>
                {tags && tags.slice(0, displayedTagsNumber).map((i, index) => (
                    <div key={i.id} className={`tag ${i.className}`}>
                        {i.text}
                        <button
                            type="button"
                            className="hide-tag-input"
                            onClick={() => handleDelete(index)}
                        >
                            <i className="icon-cross"/>
                        </button>
                    </div>
                ))}
                {writeSelectedOrders()}
                <button
                    ref={addTagButtonRef}
                    type="button"
                    className="add-new-tag"
                    onClick={() => setShowTagInput(true)}
                >
                    {getLangText('addButton')}&nbsp;+
                </button>
            </div>
        )
    }

    useEffect(() => {
        setTags(activeConversation?.tags || [])
        setShowTagInput(false)
    }, [activeConversation?.id])


    useOutsideClick(suggestionsRef, () => {
        setCustomSuggestions([])
    });

    return (
        showTagInput ?
            <div className={`react-tag-container ${showTagInput ? 'active' : ''}`}>
                <ReactTags
                    inline
                    ref={tagRef}
                    tags={tags}
                    delimiters={delimiters}
                    handleDelete={handleDelete}
                    handleAddition={handleAddition}
                    allowDragDrop={false}
                    handleInputChange={handleInputChange}
                    placeholder=""
                    inputProps={{
                        autoComplete: "off"
                    }}
                />
                {customSuggestions?.length &&
                    <ul
                        ref={suggestionsRef}
                        className="custom-dropdown"
                    >
                        {customSuggestions.map((i) => (
                            <li>
                                <button
                                    onClick={() => {
                                        handleAddition(i);
                                        setCustomSuggestions([]);
                                        document.querySelector('.ReactTags__tagInputField').value = '';
                                    }}
                                    className={`tag ${i.className}`}
                                >
                                    {i.text}
                                </button>
                                <OverlayTrigger
                                    placement='top'
                                    overlay={
                                        <Tooltip id={`tooltip-remove-products`}>
                                            {getLangText('removeSuggestionLabel')}
                                        </Tooltip>
                                    }
                                >
                                    <button
                                        type="button"
                                        className="remove-suggestion"
                                        onClick={() => deleteSuggestion(i)}
                                    >
                                        <i className="icon-bin"/>
                                    </button>
                                </OverlayTrigger>

                            </li>
                        ))}
                    </ul>
                }
                <button
                    type="button"
                    className="hide-tag-input"
                    onClick={() => setShowTagInput(false)}
                >
                    <i className="icon-cross"/>
                </button>
            </div>
            :
            <TagsRow/>
    );
};
export default memo(Tags);
