import React, {useEffect} from 'react';
import useFormLocal from "../../../../../src_shared/hooks/useFormLocal";
import Field from "../../../../../src_shared/components/form/field/Field";
import Loader from "../../../../app/shared/Loader";
import SettingsNipChooserModel from "../../../../../modules/models/settings/SettingsNipChooserModel";
import useLang from "../../../../../src_shared/hooks/useLang";
import {services} from "../../../../RestServices";
import {useDispatch} from "react-redux";
import {showAlert} from "../../../../app/shared/components/alert/AlertActions";

const NipChooser = ({fetchData}) => {
	const {getLangText} = useLang();
	const dispatch = useDispatch();
	const [{
		onSave,
		isWaiting,
		getFieldProps,
		setValue,
		values,
		setIsValidationActive,
	}] = useFormLocal({
		rest: services.API_SETTINGS_NIP_CHOOSER,
		model: SettingsNipChooserModel,
		initialAutoLoad: false,
		afterSubmit: () => {
			fetchData()
			dispatch(showAlert());
		},
	});

	useEffect(() => {
		setIsValidationActive(false);
	}, [values.has_nip])

	return (
		<section className="payments-chooser">
			{!values.has_nip ?
				<div className="payments-chooser__box">
					<div className="header-text mb-3">
						<h2>Wprowadź swoje dane</h2>
						<div className="description-text">
							Rozpocznij przyjmować płatności za zamówienia
						</div>
					</div>
					<Field
						{...getFieldProps('name')}
						label="Imię i nazwisko"
						type="text"
						placeholder="Wpisz imię i nazwisko"
					/>
					<Field
						{...getFieldProps('address')}
						label="Adres"
						type="text"
						placeholder="Wpisz adres"
						className="mt-3"
					/>
					<div className="button-place mt-3">
						<button
							type="button"
							onClick={onSave}
							className={`button primary w-100 ${isWaiting ? 'loading-button' : ''}`}
						>
							<Loader isLoading={isWaiting}/>
							{getLangText('saveButton')}
						</button>
					</div>
					<div className="text-separator">
						<span>LUB</span>
					</div>
					<div className="no-nip-link">
						<button
							type="button"
							onClick={() => setValue('has_nip', true)}
						>
							Powrót
						</button>
					</div>
				</div>
				:
				<div className="payments-chooser__box">
					<div className="header-text">
						<h2>Wprowadź NIP</h2>
						<div className="description-text">
							Rozpocznij przyjmować płatności za zamówienia
						</div>
					</div>
					<div className="flex-row">
						<Field
							{...getFieldProps('nip')}
							label="Twój NIP"
							type="text"
							placeholder="Wpisz numer NIP"
							className="absolute-error"
						/>
						<div className="button-place">
							<button
								type="submit"
								onClick={onSave}
								className={`button primary ${isWaiting ? 'loading-button' : ''}`}
							>
								<Loader isLoading={isWaiting}/>
								{getLangText('saveButton')}
							</button>
						</div>
					</div>
					<div className="text-separator">
						<span>LUB</span>
					</div>
					<div className="no-nip-link">
						<button
							type="button"
							onClick={() => setValue('has_nip', false)}
						>
							Nie mam numeru NIP
						</button>
					</div>
				</div>
			}
		</section>

	);
};

export default NipChooser;
