export const ROLE_MEMBERS_ADMIN = 'ROLE_MEMBERS_ADMIN';
export const ROLE_MEMBERS_EMPLOYEE = 'ROLE_MEMBERS_EMPLOYEE';

const RoleMembersTypes = [
	{
		value: ROLE_MEMBERS_ADMIN,
		label: 'admin',
	},
	{
		value: ROLE_MEMBERS_EMPLOYEE,
		label: 'employee',
	},
];

export default RoleMembersTypes;
