import React from 'react';
import Modal from "react-bootstrap/Modal";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import useFetchData from "../../../../../../../src_shared/hooks/useFetchData";
import ComponentLoader from "../../../../../../../src_shared/components/ui/componentLoader/ComponentLoader";
import {convertToUserTimeZone} from "../../../../../shared/helpers/dateHelpers";

const PackageStatusModal = ({show, hideModal, data, rest}) => {
    const {getLangText} = useLang()

    const [{data: values, isLoading}] = useFetchData({
        rest: `${rest}/${data.id}`,
    });

    return (
        <Modal
            show={show}
            dialogClassName="default-modal w-420"
            onHide={hideModal}
        >
            <Modal.Header className="">
                <Modal.Title>
                    {getLangText('packageStatus')}
                </Modal.Title>
                <div className="ml-auto text-right">
                    <button
                        onClick={hideModal}
                        type="button"
                        className="button text-right text-[#6b7280] text-[11px]"
                    >
                        <i className="icon-cross"/>
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body className="mt-2">
                {isLoading ?
                    <ComponentLoader className="small-loader" style={{minHeight: '150px'}}/> :
                    <div className="max-h-[70vh] overflow-y-auto">
                        {!!values.length ?
                            <div className="employee-history">
                                {values?.map((item) => (
                                    <div
                                        key={item.date}
                                        className="flex items-center pb-3"
                                    >
                                        <div
                                            className="z-[1] relative w-[20px] h-[20px] flex items-center justify-center min-w-[20px] bg-[#f9f9f9] rounded-full overflow-hidden mr-2">
                                                <i className="icon-tick text-[8px] text-desc" />
                                        </div>
                                        <div className="text-xs">
                                            <span className="font-bold hover:text-[black]">{item.description}</span>
                                            <span className="text-[8px] text-desc mx-1 align-middle">•</span>
                                            <span className="text-desc font-medium">{convertToUserTimeZone(item.date).format('DD.MM.YYYY HH:mm:ss')}</span>
                                        </div>
                                    </div>
                                ))}
                            </div> :
                            <div className="font-bold text-sm">Brak informacji</div>
                        }
                    </div>
                }
            </Modal.Body>
        </Modal>
    );
};

export default PackageStatusModal;
