import React, {useEffect} from 'react';
import Header from "../app/shared/header/Header";
import Alert from "../app/shared/components/alert/Alert";
import {useDispatch, useSelector} from "react-redux";
import SettingsAside from "../app/shared/aside/SettingsAside";
import FunctionalityBoxes from "../../src_shared/components/functionalityBoxes/FunctionalityBoxes";
import useWindowDimensions from "../app/messenger/hooks/useWindowDimensions/useWindowDimensions";
import TopInfoBars from "../app/shared/header/TopInfoBars";
import SearchModal from "./searchModal/SearchModal";
import {createSelector} from "reselect";
import {getStateRoot as getSearchModalStateRoot} from "./searchModal/listReducer";
import ListActions from "./searchModal/ListActions";

const SettingsLayout = ({children}) => {
    const {userData} = useSelector((state) => state.session);
    const {width} = useWindowDimensions();
    const {modalVisible} = useSelector(createSelector(getSearchModalStateRoot, (stateRoot) => stateRoot));
    const dispatch = useDispatch();

    useEffect(() => {
        const handleKeyDown = (event) => {
            if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
                event.preventDefault();
                dispatch(ListActions.modal.showModal())
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <>
            <TopInfoBars />
            <div className="main-container">
                {width < 991 &&
                    <FunctionalityBoxes/>
                }
                <div className="wrapper-section loader-parent">
                    <SettingsAside userData={userData}/>
                    <main>
                        <Header
                            inSettings={true}
                            userData={userData}
                        />
                        {children}
                    </main>
                    <Alert/>
                </div>
                {modalVisible &&
                    <SearchModal/>
                }
            </div>
        </>
    );
};

export default SettingsLayout;
