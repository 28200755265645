import React, {useState} from 'react';
import {selmoUrl} from "../../../api/api";
import {showAlert} from "../../../../components/app/shared/components/alert/AlertActions";
import {useDispatch} from "react-redux";
import useLang from "../../../hooks/useLang";
import api from "../../../../services/axios/axios";
import Loader from "../../loader/Loader";
import ServerImage from "../../../../components/app/shared/components/serverImage/ServerImage";
import usePhotoPreview from "../../ui/photoPreview/usePhotoPreview";
import PhotoPreview from "../../ui/photoPreview/PhotoPreview";

const ImageUpload = ({
                         values,
                         defaultImage,
                         imagePath,
                         updateValues,
                         restService,
                         FormActions,
                         circleImage,
                         loadData,
                         bigPhoto = false,
                         field,
                         sizes,
                         formats,
                         proportions,
                         previewPhotoField,
                         withPreview,
                         removePhotoField,
                         withRemove,
                     }) => {
    const dispatch = useDispatch();
    const [isWaiting, setIsWaiting] = useState(false);
    const {getLangText} = useLang();
    const {showPreviewModal, hidePreviewModal, openPreviewModal} = usePhotoPreview();

    const uploadFileHandler = async (event) => {
        const files = Object.values(event.target.files);
        if (files[0]) {
            await onSubmit(files[0]);
        }
    };
    const removePhoto = () => {
        if (removePhotoField) {
            updateValues({
                [removePhotoField]: null,
                [field]: null,
            })
        } else {
            updateValues({
                [field]: null,
            })
        }
    }

    const onSubmit = async (photo) => {
        setIsWaiting(true)

        const formData = new FormData();
        formData.append('photo', photo);
        formData.append('path', imagePath);

        try {
            if (!!+values.id) {
                await api.post(`${selmoUrl}/${restService}/${values.id}`, formData)
                loadData(dispatch, FormActions, values);
            } else {
                const {data} = await api.post(`${selmoUrl}/${restService}`, formData)

                updateValues({
                    [field]: data?.path,
                    id: data?.id || null,
                })
            }
            dispatch(showAlert('imageSaveSuccessLabel'))

        } catch (e) {
            if (e.response.data.message === 'addNewPhotoLabel') {
                dispatch(showAlert(e.response.data.message, '', 'alert-fail'))
                return;
            }
            dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
            console.error('Error submitEditForm')
        } finally {
            setIsWaiting(false)
        }
    }

    return (
        <>
            <div
                className={`logo-upload-wrapper ${values[field] && values[field] !== '0' ? '' : 'with-border'} ${bigPhoto ? 'big-photo' : ''} ${circleImage ? 'rounded-circle' : ''}`}>
                <div className={`${(withPreview && !!values[field]) ? 'cursor-zoom-in' : 'cursor-pointer'} toggle-file-input relative`}>
                    <Loader className="small-loader w-[calc(100%-15px)]" isLoading={isWaiting}/>
                    {values[field] && values[field] !== '0' ?
                        <figure onClick={openPreviewModal}>
                            <ServerImage
                                src={values[field]}
                                alt={values.name}
                            />
                        </figure>
                        :
                        <figure className="default-img">
                            <img src={`/assets/images/default/${defaultImage}`}
                                 alt="Brak danych"
                            />
                        </figure>
                    }
                    {(!withPreview || !values[field]) &&
                        <input
                            type="file"
                            onChange={uploadFileHandler}
                            className="hidden-file-input"
                        />
                    }
                </div>
                <div className={bigPhoto ? 'sm:w-auto w-full' : ''}>
                    <div className="action-buttons">
                        <button
                            type="button"
                            className="button border-button left-icon upload-btn toggle-file-input"
                        >
                            <i className="icon-upload"/>
                            <input
                                type="file"
                                onChange={uploadFileHandler}
                                className="hidden-file-input"
                            />
                            {getLangText('selectButton')}
                        </button>
                        {(withRemove && (values[field] && values[field] !== '0')) &&
                            <button
                                type="button"
                                onClick={removePhoto}
                                className="text-gray-400 text-xs font-semibold ml-3 hover:text-gray-700 transition-all underline"
                            >
                                {getLangText('removeButton')}
                            </button>
                        }
                    </div>
                    <div className="bottom-text">
                        {proportions &&
                            <div dangerouslySetInnerHTML={{__html: getLangText(proportions)}}/>
                        }
                        {formats &&
                            <div dangerouslySetInnerHTML={{__html: getLangText(formats)}}/>
                        }
                        {!!sizes &&
                            <div className="mt-1"
                                 dangerouslySetInnerHTML={{__html: `${getLangText('dimensionLabel')}: <strong>${sizes}</strong>`}}/>
                        }
                    </div>
                </div>
            </div>
            <PhotoPreview
                src={values[previewPhotoField]}
                show={showPreviewModal}
                hide={hidePreviewModal}
            />
        </>
    );
};

ImageUpload.defaultProps = {
    defaultImage: 'product.svg',
    imagePath: 'products',
    restService: 'api/media',
    circleImage: false,
    loadData: (dispatch, FormActions, values) => dispatch(FormActions.loadData(values.id)),
    field: 'photo',
    removePhotoField: null,
    previewPhotoField: 'photo',
    withPreview: false,
    sizes: '',
    formats: 'correctFormatsLabel',
    proportions: '',
    withRemove: true,
}

export default ImageUpload;
