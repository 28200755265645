export const ORDER_LABEL_ALL = 'all';
export const ORDER_LABEL_GENERATED = '1';
export const ORDER_LABEL_NULL = '0';


export const ORDER_LABEL_STATUES = [
	{
		value: ORDER_LABEL_ALL, label: 'allLabel', color: 'waiting',
	},
	{
		value: ORDER_LABEL_GENERATED, label: 'generatedLabel', color: 'waiting',
	},
	{
		value: ORDER_LABEL_NULL, label: 'noLabelLabel', color: 'waiting',
	},
]
export const findOrderLabelStatusById = (id) => ORDER_LABEL_STATUES.find((status) => status.value === id);

