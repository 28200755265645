import React from 'react';
import {Helmet} from "react-helmet";
import ListActions from "./ListActions";
import {getStateRoot} from "./listReducer";
import useList from "../../../../../src_shared/list/useList";
import Pagination from "../../../shared/components/pagination/Pagination";
import {useParams} from "react-router-dom";
import Table from "../components/table/Table";
import Header from "../components/header/Header";
import {useSelector} from "react-redux";
import {createSelector} from "reselect";
import useLang from "../../../../../src_shared/hooks/useLang";
import {getStateRoot as getFiltersStateRoot} from "../filters/filtersReducer";

const ReserveList = ({data}) => {
	const params = useParams();
	const {totalPrice, waitingForSend, quantity} = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot))
	const {getLangText} = useLang();

	const {deleted} = useSelector(createSelector(getFiltersStateRoot, (stateRoot) => stateRoot.data));

	const {
		pagination: {
			changePage,
			getPagination,
			itemsPerPage,
			nextPage,
			prevPage,
			totalItems,
			updateItemsPerPage
		},
		list: {items, isWaiting, loadData},
		search,
		sort: {updateSortOptions, getSortClass},
	} = useList({
		ListActions,
		getStateRoot,
		hasParentId: true,
		params,
	});

	return (
		<div className="table-list-wrapper">
			<Helmet>
				<meta charSet="utf-8"/>
				<title>{getLangText('reserveListLabel')} - Selmo</title>
			</Helmet>
			<div className={`default-shadow-box pb-0 ${waitingForSend ? 'pt-3' : ''}`}>
				<Header
					ListActions={ListActions}
					params={params}
					search={search}
					totalItems={totalItems}
					data={data}
					title={getLangText('reserveListLabel')}
					totalPrice={totalPrice}
					type="RESERVE_LIST"
					waitingForSend={waitingForSend}
					quantity={quantity}
				/>
				<Table
					getSortClass={getSortClass}
					updateSortOptions={updateSortOptions}
					items={items}
					isWaiting={isWaiting}
					loadData={loadData}
					hiddenActions={!!+deleted}
				/>
			</div>
			{totalItems > itemsPerPage &&
				<Pagination
					getLangText={getLangText}
					pages={getPagination()}
					items={items}
					itemsPerPage={itemsPerPage}
					totalItems={totalItems}
					onPrevPage={prevPage}
					onChangePage={changePage}
					onNextPage={nextPage}
					onChangeItemsPerPage={updateItemsPerPage}
					classes={{
						prevIcon: 'icon-chevron left',
						nextIcon: 'icon-chevron right',
					}}
					showItemsPerPageSelect
				/>
			}
		</div>
	);
};

export default ReserveList;
