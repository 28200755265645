const initialState = {
	alert: {}
}

export default (state = initialState, action) => {
	switch (action.type) {
		case 'SHARED_ACTIONS_SHOW_ALERT':
			const {title, desc, className} = action;
			return {...state, alert: {title, desc, className, visibility: true}};
		case 'SHARED_ACTIONS_HIDE_ALERT':
			return {...state, alert: {...state.alert, visibility: false}};
		default:
			return state;
	}
}
