import Model from "../../../src_shared/modules/model/Model";

class PaymentTypesModel extends Model {
	getModel() {
		return {
			payment: '',
			transfer_account_id: 0,
			additional_transfer_accounts: [],
		};
	}

	buildDTO(data) {
		return {
			payment: data.payment,
			transfer_account_id: data.transfer_account_id,
		};
	}
}

export default new PaymentTypesModel();
