export const INVOICE_DATA_COMPANY = 'INVOICE_DATA_COMPANY';
export const INVOICE_DATA_INDIVIDUAL = 'INVOICE_DATA_INDIVIDUAL';

const InvoiceDataTypes = [
	{
		id: INVOICE_DATA_COMPANY,
		label: 'companyOrganizationLabel',
	},
	{
		id: INVOICE_DATA_INDIVIDUAL,
		label: 'privatePersonLabel',
	},
];

export default InvoiceDataTypes;
