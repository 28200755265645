import React from 'react';
import useLang from "../../../src_shared/hooks/useLang";
import {services} from "../../RestServices";
import ComponentLoader from "../../../src_shared/components/ui/componentLoader/ComponentLoader";
import {useParams} from "react-router-dom";
import useFetchData from "../../../src_shared/hooks/useFetchData";

const ConfirmEmail = () => {
    const {getLangText} = useLang();
    const {id} = useParams();

    const [{isLoading, isError}] = useFetchData({
        rest: `${services.API_MOBILE_PUBLIC_CONFIRM_EMAIL}/${id}`,
        method: 'post'
    })

    return (
        <div
            className={`bg-[#eff2f6] sm:py-0 py-6 sm:px-0 px-6 sm:min-h-auto min-h-full sm:h-[100vh] w-[100vw] flex sm:items-center sm:justify-center`}>
            <div
                className={`w-full sm:my-0 my-auto md:px-0 px-6 rounded-[12px] shadow-default bg-white md:w-[800px] min-h-[450px] md:min-h-[600px] flex flex-col animate-tutorialSlideAndFadeIn`}>
                {isLoading ?
                    <ComponentLoader/> :
                    <div className={`text-center animate-tutorialSlideAndFadeInDelay1 opacity-0 m-auto`}>
                        <img
                            width={100}
                            className="mx-auto mb-4"
                            src="/assets/images/selmo-logo.svg"
                            alt="facebook"
                        />
                        {isError ?
                            <>
                                <h1 className="sm:text-2xl text-xl font-bold"
                                    dangerouslySetInnerHTML={{__html: getLangText('sthWentWrongWarning')}}/>
                                <a href="mailto: hello@selmo.io" className="button primary mt-4"
                                   style={{paddingTop: 16, paddingBottom: 16}}>
                                    <span className="text-sm">{getLangText('footerContactWithUsLabel')}</span>
                                </a>
                            </> :
                            <div>
                                <h1 className="sm:text-2xl text-xl font-bold"
                                    dangerouslySetInnerHTML={{__html: getLangText('successfulConfirmedEmailLabel')}}/>
                                <h2 className="text-[#9CA3AF] text-sm font-medium mt-1"
                                    dangerouslySetInnerHTML={{__html: getLangText('successfulConfirmedEmailSubLabel')}}/>
                                <a href="selmoapp://app?confirmEmail=1" className="button primary mt-4"
                                   style={{paddingTop: 16, paddingBottom: 16}}>
                                    <span className="text-sm">{getLangText('backToAppLabel')}</span>
                                </a>
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    );
};

export default ConfirmEmail;
