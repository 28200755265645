import {getStateRoot, prefix} from "./formReducer.js";
import {services} from "../../../../RestServices.js";
import FormActions from "../../../../../src_shared/form/FormActions";
import {showAlert} from "../../../shared/components/alert/AlertActions";
import axios from "axios";
import {selmoUrl} from "../../../../../src_shared/api/api";
import Model from "../../../../../src_shared/modules/model/Model";
import api from "../../../../../services/axios/axios";

export class CategorySeoFormActions extends FormActions {
	submitEditForm(values, id) {
		return async (dispatch) => {
			dispatch(this.validation.activateValidation());

			if (!this.dataModel.validateData(values)) {
				dispatch(showAlert('fillRequireFieldWarning', '', 'alert-fail'))
				console.error('Validation failed!');
				return;
			}

			const filteredData = Object.entries(values).filter(([key]) => key !== 'id');
			const finalData = Object.fromEntries(filteredData)

			const preparedData = this.dataModel.buildDTO(finalData)

			dispatch(this.waiting.startWaiting());

			try {
				await api.put(`${selmoUrl}/${services.API_CATEGORIES}/${id}`, preparedData)
				dispatch(this.afterSubmit(id))
				dispatch(showAlert())
				dispatch(this.validation.deactivateValidation())
			} catch (e) {
				dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
				console.error('Error submitEditForm')
			} finally {
				dispatch(this.waiting.stopWaiting());
			}
		}
	}
}

export const getInstance = () => new CategorySeoFormActions({
	getStateRoot,
	prefix,
	model: new Model(),
	restService: services.API_CATEGORY_SEO,
});

export default getInstance();
