import React from 'react';
import Product from "./Product";
import ConfirmModal from "../../../../../../../orders/list/components/export/ConfirmModal";
import useSubmitData from "../../../../../../../../../src_shared/hooks/useSubmitData";
import useRemoveData from "../../../../../../../../../src_shared/hooks/useRemoveData";
import {services} from "../../../../../../../../RestServices";
import {useParams} from "react-router-dom";
import useLang from "../../../../../../../../../src_shared/hooks/useLang";

const Products = ({products, fetchData, currency, canEdit = true}) => {
    const {id} = useParams();
    const {getLangText} = useLang();
    const [{
        openModal,
        hideModal,
        confirmModalVisible,
        itemToRemove,
    }] = useRemoveData();

    const [{
        openModal: openMarkAsPackedModal,
        hideModal: hideMarkAsPackedModal,
        confirmModalVisible: markAsPackedModalVisible,
        itemToRemove: markAsPackedItem,
    }] = useRemoveData();

    const [{onSave, isWaiting}] = useSubmitData({
        rest: itemToRemove.product_id ? `${services.API_WAREHOUSE_UNSCAN_PRODUCT}/${itemToRemove.product_id}` : services.API_WAREHOUSE_UNSCAN_PRODUCT,
        values: {
            order_id: id,
            id: itemToRemove.id,
            product_size_id: itemToRemove.product_size_id,
            manual: '1',
        },
        afterSubmit: () => {
            hideModal();
            fetchData();
        }
    });

    const [{onSave: markAsPacked, isWaiting: isWaitingForMarking}] = useSubmitData({
        rest: markAsPackedItem.product_id ? `${services.API_WAREHOUSE_SCAN_PRODUCT}/${markAsPackedItem.product_id}` : services.API_WAREHOUSE_SCAN_PRODUCT,
        values: {
            order_id: id,
            id: markAsPackedItem.id,
            product_size_id: markAsPackedItem.product_size_id,
            manual: '1',
        },
        afterSubmit: () => {
            hideMarkAsPackedModal();
            fetchData();
        }
    });

    const customComparator = (itemA, itemB) => {
        const conditionA = !!itemA.last_qr_scan && (+itemA.quantity === +itemA.scanned_qty);
        const conditionB = !!itemB.last_qr_scan && (+itemB.quantity === +itemB.scanned_qty);

        if (conditionA === conditionB) {
            return 0;
        } else if (conditionA) {
            return 1;
        } else {
            return -1;
        }
    }

    if (!products.length) {
        return (
            <div>
                <div className="font-bold">
                    {getLangText('customerBasketNoProductsInOrderLabel')}
                </div>
            </div>
        )
    }

    return (
        <>
            {products?.sort(customComparator)?.map((item) => (
                <Product
                    openMarkAsPackedModal={openMarkAsPackedModal}
                    openModal={openModal}
                    key={item.id}
                    item={item}
                    currency={currency}
                    canEdit={canEdit}
                />
            ))}
            {confirmModalVisible &&
                <ConfirmModal
                    title={getLangText('wantToMarkProductsAsUnPackedLabel')}
                    saveButton="yesButton"
                    show={confirmModalVisible}
                    isWaiting={isWaiting}
                    onSubmit={onSave}
                    hide={hideModal}
                />
            }
            {markAsPackedModalVisible &&
                <ConfirmModal
                    title={getLangText('wantToMarkProductsAsPackedLabel')}
                    saveButton="yesButton"
                    show={markAsPackedModalVisible}
                    isWaiting={isWaitingForMarking}
                    onSubmit={markAsPacked}
                    hide={hideMarkAsPackedModal}
                />
            }
        </>
    );
};

export default Products;
