import React, {useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import useLang from "../../../../../../src_shared/hooks/useLang";
import {TutorialFinalStepsContext} from "../../FinalStepsContext";
import {confettiSetVisibleActions} from "../../../../shared/components/confetti/ConfettiActions";
import {RouterPaths} from "../../../../../routes/RouterPath";
import {Helmet} from "react-helmet";
import {usePostHog} from "posthog-js/react";
import useFormLocal from "../../../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../../../RestServices";
import Model from "../../../../../../src_shared/modules/model/Model";

const facebookSteps = [
    {
        id: 1, title: 'summaryOneClickLabel', img: 'fb-01.webp'
    },
    {
        id: 2, title: 'sendSummariesTutorialLabel', img: 'fb-02.webp'
    },
    {
        id: 3, title: 'saveTimeAndIncreaseSalesLabel', img: 'fb-03.webp'
    },
]

const instagramSteps = [
    {
        id: 1, title: 'automateInDMsAndPost', img: 'insta-01.webp'
    },
    {
        id: 2, title: 'sellOnStoriesLabel', img: 'insta-02.webp'
    },
    {
        id: 3, title: 'saveTimeAndIncreaseSalesLabel', img: 'insta-03.webp'
    },
]

const BuildingPanel = () => {

    const {lang} = useSelector((state) => state.i18n);
    const [activeStep, setActiveStep] = useState(1);
    const {getLangText} = useLang();
    const {data} = useContext(TutorialFinalStepsContext);

    const history = useHistory();
    const dispatch = useDispatch();
    const posthog = usePostHog();

    const preparedOptions = data?.isInstagram ? instagramSteps : facebookSteps

    const afterSubmit = () => {
        if (posthog) {
            posthog?.capture(`ONBOARDING - Completed`);
        }
        dispatch(confettiSetVisibleActions(true));
        if (data.isInstagram) {
            history.push(RouterPaths.Automation);
        } else {
            history.push(RouterPaths.Dashboard);
        }
    }

    const [{onSave}] = useFormLocal({
        rest: services.API_TUTORIAL_FINISHED,
        model: new Model(),
        afterSubmit,
        initialAutoLoad: false,
    });


    useEffect(() => {
        const timeout = setTimeout(() => {
            if (activeStep >= preparedOptions.length) {
                onSave();
                return;
            }
            setActiveStep(activeStep + 1)
        }, 3500)
        return () => clearTimeout(timeout);
    }, [activeStep])

    useEffect(() => {
        if (posthog) {
            posthog?.capture(`ONBOARDING - building page visit`);
        }
    }, [posthog])

    return (
        <div
            className="bg-[#eff2f6] sm:px-0 px-6 h-full sm:h-[100vh] w-[100vw] flex items-center justify-center animate-tutorialSlideAndFadeIn">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getLangText('shopBuildingLabel')} - Selmo</title>
            </Helmet>
            <div
                className="w-full md:px-0 px-6 rounded-[12px] shadow-default bg-white md:w-[800px] md:min-h-[600px] flex flex-col">
                <div className="text-center mt-20 grow flex flex-col">
                    <div className="animate-tutorialSlideAndFadeInDelay1 opacity-0">
                        <div className="flex justify-center mb-2">
                            <div className="snippet">
                                <div className="stage">
                                    <div className="dot-flashing"/>
                                </div>
                            </div>
                        </div>
                        <div className="sm:text-2xl text-xl font-bold">{getLangText('creatingShopLabel')}</div>
                    </div>
                    <div
                        className="bg-[#F9FAFB] sm:px-0 px-6 sm:mx-0 -mx-6 sm:h-auto h-[420px] rounded-b-[12px] flex flex-col grow mt-12">
                        <div className="grow flex flex-col animate-tutorialSlideAndFadeInDelay2 opacity-0 relative">
                            {preparedOptions.map((step) => (
                                <div
                                    key={step.id}
                                    className={`sm:grid sm:grid-cols-2 flex flex-col absolute left-0 right-0 top-0 bottom-0 grow ${activeStep === step.id ? (activeStep === 1 ? '' : 'animate-[tutorialSlideRightAndShow_.6s_ease_forwards_.3s] opacity-0') : 'animate-tutorialSlideOut'}`}>
                                    <h1 className={`sm:text-3xl sm:mt-0 mt-6 text-center text-sm-left sm:flex sm:flex-col whitespace-break-spaces justify-center sm:pl-20 text-xl font-bold`}
                                        dangerouslySetInnerHTML={{__html: getLangText(step.title)}}/>
                                    <div className="relative mt-8 grow">
                                        <img className="absolute left-1/2 -translate-x-1/2 h-full" alt={step.title}
                                             src={`/assets/images/multiLanguage/${lang}/tutorial/${step.img}`}/>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BuildingPanel;
