import React, {useEffect, useState} from 'react';
import useDataApi from "../../../../../../../../src_shared/hooks/useDataApi";
import {services} from "../../../../../../../RestServices";
import {useDispatch} from "react-redux";
import ListActions from "../../../../ListActions";
import Loader from "../../../../../../shared/Loader";

const FurgonetkaLabels = ({hideModal, waitingForDocument, validationActive}) => {
	const [{data, isLoading}] = useDataApi(services.API_FURGONETKA_TEMPLATES, [])
	const dispatch = useDispatch();
	const [values, setValues] = useState({})
	const onSubmit = () => dispatch(ListActions.generateLabels(values, 1))
	const setValue = (field, value) => setValues({
		...values,
		[field]: value,
	})

	const isError = validationActive && !values.template;

	useEffect(() => {
		if (!!data.length) {
			setValue('template', data[0]?.id)
		}
	}, [data])

	useEffect(() => {
		return () => dispatch(ListActions.setGenerateLabelTemplateError(false));
	}, [])

	return (
		<>
			<div className="labels-header">
				<div className="labels-title">
					Wybierz szablon
				</div>
				<img
					width={100}
					height={34}
					src="/assets/images/export/furgonetka-logo.svg"
					alt="furgonetka"
				/>
			</div>
			<div
				className={`loader-parent inpost-chooser pb-5 ${isError ? 'error-group' : ''} ${isLoading ? '' : 'min-height-auto'}`}>
				<Loader isLoading={isLoading}/>
				{!!data.length ?
					data.map((box) => (
						<div
							key={box.id}
							id={box.id}
							className={`inpost-chooser__box furgonetka-chooser ${values.template === box.id ? 'active' : ''}`}
						>
							<input
								onChange={() => setValue('template', box.id)}
								checked={values.template === box.id}
								type="radio"
								value={values.template}
							/>
							<span className="checkmark"/>
							<div className="inpost-chooser__box__text-wrapper">
								<div className="inpost-chooser__box__text-wrapper__top">
									Szablon {box.name}
								</div>
								<div className="inpost-chooser__box__text-wrapper__bottom">
									max. {box.width} x {box.height} x {box.depth} do {box.weight} kg
								</div>
							</div>
						</div>
					)) :
					<div
						className="form-info-box align-items-center w-100 small-line-height">
						<div>
							<i className="icon-info-c"/>
						</div>
						<div>
							<div className="title">Brak szablonów</div>
						</div>
					</div>
				}
			</div>
			<div className="modal-footer">
				<button
					onClick={hideModal}
					type="button"
					className="button border-button"
				>
					Zamknij
				</button>
				<button
					onClick={onSubmit}
					type="button"
					className={`button primary ${waitingForDocument ? 'loading-button' : ''}`}
				>
					{waitingForDocument && <Loader isLoading={true}/>}
					Wygeneruj
				</button>
			</div>
		</>

	);
};

export default FurgonetkaLabels;
