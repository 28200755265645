import createReducer from "../../shared/helpers/createReducer.js";
import FormReducer from "../../../../src_shared/form/FormReducer.js";
import Composition from "../../shared/helpers/Composition.js";
import GeneralModel from "../../../../modules/models/shopSettings/GeneralModel";

export const getStateRoot = (state) => state.shopSettings.general;
export const prefix = 'SHOP_SETTINGS_SETTINGS_FORM_';

const getInitState = () => FormReducer.getInitState(GeneralModel.getModel());

const getReduceMap = () => new Composition(
	FormReducer.getReduceMap(),
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

