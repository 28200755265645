import React from 'react';
import {batch, useDispatch, useSelector} from "react-redux";
import FormActions from "./FormActions";
import {createSelector} from "reselect";
import {getStateRoot as getOrderDetailStateRoot} from "../form/formReducer";
import Tooltip from "react-bootstrap/Tooltip";
import {OverlayTrigger} from "react-bootstrap";
import useLang from "../../../../../src_shared/hooks/useLang";

const AssignCustomer = () => {
	const dispatch = useDispatch();
	const {id} = useSelector(createSelector(getOrderDetailStateRoot, (stateRoot) => stateRoot.data));
	const {getLangText} = useLang();

	const showModal = () => {
		batch(() => {
			dispatch(FormActions.modal.showModal())
			dispatch(FormActions.data.setValue('id', id))
		})
	}

	return (
		<OverlayTrigger
			placement='top'
			overlay={
				<Tooltip id="assign-order">
					{getLangText('assignOrderLabel')}
				</Tooltip>
			}
		>
			<button
				className="icon-button circle-on-hover mr-1"
				type="button"
				onClick={showModal}
			>
				<i className="icon-profile"/>
			</button>
		</OverlayTrigger>
	);
};

export default AssignCustomer;
