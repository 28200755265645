import {selmoUrl} from "../../../../src_shared/api/api";
import axios from "axios";
import {getConversation, setConversationPage, updateNextParameter} from "../chatBox/ChatBoxActions";
import SessionActions from "../../shared/session/SessionActions";
import api from "../../../../services/axios/axios";

const getMessagesListRequested = () => {
	return {
		type: 'GET_MESSAGES_LIST_REQUESTED'
	};
}

const setHasMoreMessages = (toggle) => {
	return {
		type: 'CHAT_MESSAGES_LIST_HAS_MORE',
		toggle,
	};
}

export const updateMessageList = items => {
	return {
		type: 'UPDATE_MESSAGES_LIST',
		items,
	};
}

export const removeItemInMessagesList = (id) => {
	return {
		type: 'REMOVE_ITEM_IN_MESSAGES_LIST',
		id,
	};
}

export const getMessagesItems = items => {
	return {
		type: 'GET_MESSAGES_ITEMS',
		items,
	};
}
export const messengerChatListReset = () => {
	return {
		type: 'MESSENGER_CHAT_LIST_RESET',
	};
}

export const checkPageAndUpdateList = () => {
	return (dispatch, getState) => {
		const {page} = getState().messenger.chatList;
		if (page === 1) {
			dispatch(setListPage(1))
			dispatch(getMessagesList(false));
		} else {
			dispatch(showNewMessageButton(true))
		}
	}
}

export const updateMessengerFromPusher = (data) => {
	return (dispatch, getState) => {
		const {items} = getState().messenger.chatList;
		const {activeConversation} = getState().messenger.chatBox;
		if (!items.length) {
			return
		}
		if (data.message && data.conversationId && (activeConversation.id === data.conversationId)) {
			dispatch(updateNextParameter(null))
			dispatch(setConversationPage(1))
			dispatch(getConversation(activeConversation.id, false))
		}
		dispatch(checkPageAndUpdateList())
	}
}


const getMessagesListError = () => {
	return {
		type: 'GET_MESSAGES_LIST_ERROR'
	};
}

export const setFieldValue = (field, value) => {
	return {
		type: `SET_FIELD_${field.toUpperCase()}_VALUE`,
		field,
		value,
	}
}

export const setListPage = (page) => {
	return {
		type: 'SET_LIST_PAGE',
		page,
	};
}

export const nextListPage = () => {
	return {
		type: 'NEXT_LIST_PAGE',
	};
}

export const setNoResults = () => {
	return {
		type: 'SET_NO_RESULTS',
	};
}

export const setFieldInChatList = (field, value) => {
	return {
		type: 'MESSAGES_LIST_SET_VALUE',
		field,
		value
	};
}

export const serialize = (obj) => {
	if (Object.keys(obj).length === 0) {
		return '';
	}
	let str = '?' + Object.keys(obj).reduce(function (a, k) {
		a.push(k + '=' + encodeURIComponent(obj[k]));
		return a;
	}, []).join('&');
	return str;
}

export const getMessagesList = (withLoader, signal) => async (dispatch, getState) => {

	const {search, status, page} = getState().messenger.chatList;

	const params = () => {
		let queryParams = {};

		if (search) {
			queryParams.search = search
		}

		if (status !== null && typeof status !== "undefined") {
			queryParams.status = status
		}

		if (status?.tag) {
			queryParams.status = status?.value;
			queryParams.tag = true
		}

		if (page) {
			queryParams.page = withLoader ? '1' : page
		}

		return serialize(queryParams);
	}

	if (withLoader) {
		dispatch(getMessagesListRequested());
	}

	try {
		const response = await api.get(`/api/conversations${params()}`, {signal})
		const {data, hasMore, allItems} = response.data;
		dispatch(setHasMoreMessages(hasMore))
		if (search) {
			const sorted = data.sort((a, b) => new Date(b.last_update) - new Date(a.last_update));
			dispatch(updateMessageList(sorted))
		} else {
			dispatch(updateMessageList(data))
		}
		dispatch(setFieldInChatList('hasItems', allItems > 0))
	} catch (e) {
		if (e.message === "canceled") {
			return;
		}
		dispatch(getMessagesListError())
		console.error(e)
	}
}

export const setActiveConversation = (activeConversation) => {
	return {
		type: 'SET_ACTIVE_CONVERSATION',
		activeConversation,
	}
}

export const updateTagInUser = (field, value, id) => {
	return {
		type: 'UPDATE_TAG_IN_USER',
		field,
		value,
		id,
	}
}
export const removeTagInUser = (field, value, id) => {
	return {
		type: 'REMOVE_TAG_IN_USER',
		field,
		value,
		id,
	}
}
export const updateFieldInItem = (field, value, id) => {
	return {
		type: 'UPDATE_FIELD_IN_ITEM',
		field,
		value,
		id,
	}
}

export const updateItemInList = (item, id) => {
	return {
		type: 'CHAT_LIST_UPDATE_ITEM',
		item,
		id,
	}
}

export const showNewMessageButton = (toggle) => {
	return {
		type: 'SHOW_NEW_MESSAGES_BUTTON',
		toggle,
	}
}


