import React from 'react';
import ServerImage from "../../../../../../../shared/components/serverImage/ServerImage";
import useLang from "../../../../../../../../../src_shared/hooks/useLang";
import CustomDropdown from "../../../../../../../shared/components/dropdown/CustomDropdown";
import {useSelector} from "react-redux";
import {
    BY_ANN_SHOP_ID,
    PUPSIK_SHOP_ID,
    SENSI_SHOP_ID,
    TEST_ACCOUNT_SHOP_ID
} from "../../../../../../../../../src_shared/enums/TrustedShopsIds";

const Product = ({item, openModal, openMarkAsPackedModal, currency, canEdit}) => {
    const {getLangText} = useLang();
    const {userData} = useSelector((state) => state.session);

    const isChecked = !!item.last_qr_scan && (+item.quantity === +item.scanned_qty);

    return (
        <div
            key={item.id}
            className={`content payment-content rounded-[5px] d-block w-full p-2 text-left transition-all ${(isChecked && canEdit) ? 'border border-[#3CD9A0] bg-[#EFFEF4]' : ''}`}
        >
            <div className="content__top-part">
                <div className="flex-grow-1 d-flex align-items-center">
                    <figure className={`product-avatar ${canEdit ? '' : 'big-size mr-3'}`}>
                        {item.photo_small && item.photo_small !== '0' ?
                            <ServerImage src={item.photo_small}/> :
                            <img src="/assets/images/default/product.svg"/>
                        }
                        {!canEdit &&
                            <span
                                className={`absolute -top-2 -right-2 text-white rounded-full h-5 w-5 text-xs font-bold leading-3 flex items-center justify-center border-2 border-white ${+item.quantity > 1 ? 'bg-[#5fa5fa]' : 'bg-gray-500'}`}>{item.quantity}</span>
                        }
                    </figure>
                    <div>
                        <div className="label text-sm font-medium break-all">
                            {item.product_name} <span
                            className="text-xs text-desc">•&nbsp;{item.product_price}&nbsp;{currency}</span>
                        </div>
                        <div className="text-xs text-desc">
                            {(+userData.id === SENSI_SHOP_ID || +userData.id === PUPSIK_SHOP_ID || +userData.id === TEST_ACCOUNT_SHOP_ID || +userData.id === BY_ANN_SHOP_ID) &&
                                <>
                                    {getLangText('localizationLabel')}: <strong>{item.shelf_id ? item.shelf_name : getLangText('lackLabel')}</strong>
                                    <span className="text-[8px] mx-1">{' • '}</span>
                                </>
                            }
                            {item.product_size &&
                                <>
                                    {getLangText('variantLabel')}: <strong>{item.product_size}</strong>
                                    <span className="text-[8px] mx-1">{' • '}</span>
                                </>
                            }
                            {item.product_code &&
                                <>
                                    {getLangText('codeLabel')}: <strong>{item.product_code}</strong>
                                    <span className="text-[8px] mx-1">{' • '}</span>
                                </>
                            }
                            {(+userData.id !== SENSI_SHOP_ID && +userData.id !== PUPSIK_SHOP_ID && +userData.id !== TEST_ACCOUNT_SHOP_ID && +userData.id !== BY_ANN_SHOP_ID) &&
                                <>
                                    {getLangText('localizationLabel')}: <strong>{item.shelf_id ? item.shelf_name : getLangText('lackLabel')}</strong>
                                </>
                            }
                            {item.manufacturers_code &&
                                <>
                                    <span className="text-[8px] mx-1">{' • '}</span>
                                    {getLangText('manufacturerCode')}: <strong>{item.manufacturers_code}</strong>
                                </>
                            }
                            {item.external_id &&
                                <>
                                    <span className="text-[8px] mx-1">{' • '}</span>
                                    {getLangText('storageCode')}: <strong>{item.external_id}</strong>
                                </>
                            }
                        </div>
                    </div>
                    {canEdit &&
                        <div className="ml-auto flex items-center">
                            {item.quantity > 1 &&
                                <div
                                    className={`w-[28px] h-[28px] ml-auto flex items-center justify-center rounded-[5px] border text-xs ${isChecked ? 'bg-[#EFFEF4] text-[#3CD9A0]  border-[#3CD9A0]' : 'bg-[#F0FAFF] text-[#0D3364] border-[#0D3364]'}`}>
                                    {item.scanned_qty}/{item.quantity}
                                </div>
                            }
                            {!+userData.without_manual_packing &&
                                <CustomDropdown
                                    className="-mt-2 ml-2"
                                    icon="icon-dots ml-auto"
                                >
                                    {!isChecked &&
                                        <button
                                            className="custom-option"
                                            type="button"
                                            onClick={() => openMarkAsPackedModal(item)}
                                        >
                                            {getLangText('markAsPackedLabel')}
                                        </button>
                                    }
                                    {(isChecked || (item.quantity > 1 && +item.scanned_qty > 0)) &&
                                        <button
                                            className="custom-option"
                                            type="button"
                                            onClick={() => openModal(item)}
                                        >
                                            {getLangText('markAsUnPackedLabel')}
                                        </button>
                                    }
                                </CustomDropdown>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default Product;
