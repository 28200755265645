import DataReducer from "../../../../src_shared/form/data/DataReducer";
import {getReduceMap} from "../../../../src_shared/filters/filterReducer";
import history from "../../../history";
import {parseParams} from "../../../../src_shared/list/ListActions";
import moment from "moment";
import {ALL_ORDERS_VIEW, INTERVAL} from "../../../app/shared/enums/OrderViewTypes";
import createReducer from "../../../app/shared/helpers/createReducer";
import {convertToUserTimeZone} from "../../../app/shared/helpers/dateHelpers";

const queryParams = history.location.search;
const parsedParams = parseParams(queryParams)

const preparedViewType = !!queryParams ? (parsedParams.dateFrom ? INTERVAL : ALL_ORDERS_VIEW) : INTERVAL;
const preparedEmployees = parsedParams['employees[]'] || [];
const preparedTypes = parsedParams['types[]'] || [];

const preparedDate = (initialState, paramsDate) => {
	if (!!queryParams) {
		if (parsedParams[paramsDate]) {
			return moment(parsedParams[paramsDate]).toDate()
		} else {
			return null;
		}
	} else {
		return initialState;
	}
}

export const getStateRoot = (state) => state.settings.team.filters;

export const getInitState = () => DataReducer.getInitState({
	fromDate: preparedDate(moment(new Date()).subtract(7, 'd').toDate(), 'dateFrom'),
	toDate: preparedDate(new Date(), 'dateTo'),
	viewType: preparedViewType,
	employees: preparedEmployees,
	employeesSelectAll: false,
	types: preparedTypes,
	typesSelectAll: false,
}, {
	fromDate: moment(new Date()).subtract(7, 'd').toDate(),
	toDate: moment().toDate(),
	viewType: INTERVAL,
	employees: [],
	employeesSelectAll: false,
	types: [],
	typesSelectAll: false,
})

export const prefix = 'SETTINGS_TEAM_STATISTICS_FILTERS_';

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();
