import React, {useEffect, useRef, useState} from 'react';
import TemplatesDropdown from "./templatesDropdown/TemplatesDropdown";

const MessageTemplates = ({setInputValue, textAreaRef}) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null);

    const handleClick = e => {
        const rootDiv = document.getElementById('root');
        if (
            rootDiv &&
            rootDiv.contains(e.target) &&
            dropdownRef.current &&
            !dropdownRef.current.contains(e.target)
        ) {
            setShowDropdown(false)
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener("click", handleClick);
        };
    });

    return (
        <div
            ref={dropdownRef}
            className="relative">
            <button
                type="button"
                className="icon-btn mr-2"
                onClick={() => setShowDropdown((prevState) => !prevState)}
            >
                <i className="icon-chat1" />
            </button>
            {showDropdown &&
                <TemplatesDropdown
                    setInputValue={setInputValue}
                    show={showDropdown}
                    hide={() => setShowDropdown(false)}
                    textAreaRef={textAreaRef}
                />
            }
        </div>
    );
};

export default MessageTemplates;
