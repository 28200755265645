import React, {useEffect} from "react";
import useForm from "../../../../../src_shared/form/useForm";
import FormActions from "./FormActions";
import Loader from "../../../shared/Loader";
import {useParams} from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import useLang from "../../../../../src_shared/hooks/useLang";

const CurrencyTypeForm = () => {
	const params = useParams()
	const {getLangText} = useLang();

	const {
		data: {values, setValue},
		form: {isWaiting, onSave},
		modal: {hideModal, modalVisible},
	} = useForm({
		FormActions,
		params,
	});


	return (
		<Modal
			show={modalVisible}
			className="add-order-modal"
			dialogClassName="default-modal sm-size"
			onHide={hideModal}
		>
			<Modal.Header>
				<Modal.Title>{getLangText('editCurrencyLabel')}</Modal.Title>
				<button
					onClick={hideModal}
					type="button"
					className="button"
				>
					<i className="icon-cross"/>
				</button>
			</Modal.Header>
			<Modal.Body>
				<div className="loader-parent">
					<Loader isLoading={isWaiting}/>
					<div className="data-boxes-wrapper">
						{values.currencies.map((item) => (
							<div
								key={item.id}
								className="content payment-content"
							>
								<div className="content__top-part">
									<input
										checked={values.currency === item.id}
										onChange={() => setValue('currency', item.id)}
										type="radio"
										name="currency_radio"
									/>
									<span className="checkmark"/>
									<div className="flex-grow-1">
										<div className="label">{item.name}</div>
										<div className="data">{item.shortcut}</div>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<button
					onClick={hideModal}
					type="button"
					className="button border-button"
				>
					{getLangText('cancelButton')}
				</button>
				<button
					type="button"
					className="button primary"
					onClick={onSave}
				>
					{isWaiting && <Loader isLoading/>}
					{getLangText('saveButton')}
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default CurrencyTypeForm;



