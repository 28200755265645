import React from 'react';
import {services} from "../../../../RestServices";
import {RouterPaths} from "../../../../routes/RouterPath";
import useFormLocal from "../../../../../src_shared/hooks/useFormLocal";
import Loader from "../../../../app/shared/Loader";
import useLang from "../../../../../src_shared/hooks/useLang";
import {Link} from "react-router-dom";
import MondialRelaySettingsWrapper from "../MondialRelayWrapper";
import AsyncSearchSelectField
    from "../../../../../src_shared/components/form/selectField/asyncSearchSelectField/AsyncSearchSelectField";
import Field from "../../../../../src_shared/components/form/field/Field";
import SettingsMondialRelaySenderDataModel
    from "../../../../../modules/models/settings/SettingsMondialRelaySenderDataModel";

const MondialRelaySenderDataForm = () => {
    const {getLangText} = useLang()

    const [{onSave, isWaiting, getFieldProps, isLoading, values}] = useFormLocal({
        rest: services.API_SETTINGS_MONDIAL,
        model: SettingsMondialRelaySenderDataModel,
    });

    const formatOptionLabel = (option) => (
        <div style={{display: "flex"}}>
            <div className="mr-1">{option.flag}</div>
            <div>{option.name}</div>
        </div>
    );

    return (
        <>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <a href={RouterPaths.Settings}>
                            {getLangText('asideSettingsLabel')}
                        </a>
                    </li>
                    <li>
                        <Link to={RouterPaths.SettingsIntegration}>{getLangText('settings_nav_integrations')}</Link>
                    </li>
                    <li className="active">
                        Mondial Relay
                    </li>
                </ul>
            </div>
            <MondialRelaySettingsWrapper>
                <div className="loader-parent">
                    <Loader isLoading={isLoading}/>
                    <div className="settings-form pt-3">
                        <h2 className="mb-3">
                            {getLangText('senderDataLabel')}
                        </h2>
                        <div className="grid grid-cols-2 gap-x-5">
                            <Field
                                {...getFieldProps('first_name')}
                                label={getLangText('firstNameLabel')}

                            />
                            <Field
                                {...getFieldProps('last_name')}
                                label={getLangText('lastNameLabel')}

                            />
                            <Field
                                {...getFieldProps('street')}
                                label={getLangText('streetNumberLabel')}

                            />
                            <Field
                                {...getFieldProps('city')}
                                label={getLangText('cityLabel')}

                            />
                            <Field
                                {...getFieldProps('zip_code')}
                                label={getLangText('zipCodeLabel')}

                            />
                            <AsyncSearchSelectField
                                {...getFieldProps('language')}
                                className="default-size"
                                label={getLangText('countryLabel')}
                                rest={services.API_SETTINGS_SHIPPING_COUNTRIES}
                                formatOptionLabel={formatOptionLabel}
                                defaultValue={{
                                    id: values.language,
                                    name: values?.country_info?.name
                                }}
                                optionsParser={(options) => options?.map((i) => ({
                                    ...i,
                                    id: i.shortcut,
                                }))}
                                // setExtraValues={updateCountryName}
                                searchOnFocus
                            />
                            <div className="col-span-2">
                                <Field
                                    {...getFieldProps('phone')}
                                    label={getLangText('phoneLabel')}
                                />
                            </div>
                        </div>
                        <div className="button-place mt-3">
                            <button
                                type="button"
                                className={`button primary ${isWaiting ? 'loading-button' : ''}`}
                                onClick={onSave}
                            >
                                {isWaiting && <Loader isLoading/>}
                                {getLangText('saveButton')}
                            </button>
                        </div>
                    </div>
                </div>
            </MondialRelaySettingsWrapper>
        </>
    );
};

export default MondialRelaySenderDataForm;
