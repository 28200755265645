import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import {RouterPaths} from "../../RouterPath";
import ManufacturesList from "../../../app/products/manufactures/list/List";
import ManufacturerForm from "../../../app/products/manufactures/form/Form";

const ManufacturersRoute = ({path}) => {

	return (
		<Route path={path}>
			<Switch>
				<Route
					exact
					path={path}
					component={ManufacturesList}
				/>
				<Route
					exact
					path={RouterPaths.Manufacturer}
					component={ManufacturerForm}
				/>
				<Redirect to={path}/>
			</Switch>
		</Route>
	);
};

export default ManufacturersRoute;
