import React, {useEffect, useState} from 'react';
import {Link, useHistory, useLocation, useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import moment from "../../messenger/helpers/moment";
import {useDispatch, useSelector} from "react-redux";
import {
    getLiveDetailsInfo, liveDetailsGetTotalOrderPrice,
    removeLive,
    setConfirmModalToggleVisibility
} from "./default/LiveDetailsActions";
import {RouterPaths} from "../../../routes/RouterPath";
import CustomDropdown from "../shared/Dropdown";
import LiveCommentsListActions from "./default/comments/ListActions";
import {addNewLiveModalToggleVisibility, updateValuesInNewLiveModalForm} from "../addNewLive/NewLiveModalActions";
import {REMOVE_LIVE} from "./default/summary/Summary";
import RemoveLive from "./default/clients/confirmModals/ConfirmModal";
import {CONFIRM_MODAL_TYPES} from "./default/clients/confirmModals/ConfirmModalTypes";
import CommentsList from "./default/comments/List";
import {createSelector} from "reselect";
import {getStateRoot} from "./default/comments/listReducer";
import useLang from "../../../../src_shared/hooks/useLang";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {useUnitPrice} from "../../shared/helpers/Price";
import Export from "./default/summary/export/Export";
import {
    ANGELO_LILI_LIVE_SHOP_ID, DESPERADO_SHOP_ID,
    LA_BOUTIQUE_DU_CENTRE_SHOP_ID, MERCERIA_SHOP_ID,
    MOI_BOUTIQUE_SHOP_ID, PATTI_CON_LE_SHOP_ID, POPUPLIVE_STORE_SHOP_ID,
    SHOP_BAZAR_SHOP_ID, SKLEP_ODZIEZOWY_U_EMI_SHOP_ID, SWIAT_SLODYCZY_SHOP_ID,
    TEST_ACCOUNT_SHOP_ID, UNDERGROUND_LINE_SHOP_ID, ZAKUPOHOLICZKI_SHOP_ID
} from "../../../../src_shared/enums/TrustedShopsIds";
import CommentsListWithVirtualization from "./default/comments/NewList";
import UserPermissionsWrapper from "../../../routes/UserPermissionsWrapper";
import {USER_PERMISSIONS_LIVE_PRICE_OVERALL} from "../../shared/enums/UserPermissionsTypes";
import {convertToUserTimeZone} from "../../shared/helpers/dateHelpers";

const LiveListsWrapper = ({children, helmetTitle}) => {

    const {pathname} = useLocation();
    const dispatch = useDispatch();
    const {liveInfo} = useSelector((state) => state.lives.details);
    const {commentsPanelVisible} = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));
    const {getLangText} = useLang();
    const {getPrice} = useUnitPrice();
    const {userData} = useSelector((state) => state.session);

    const [visibleDropdown, setVisibleDropdown] = useState(false)
    const history = useHistory();

    const {id} = useParams();

    const time = convertToUserTimeZone(liveInfo.date).format('HH:mm')
    const date = convertToUserTimeZone(liveInfo.date).toDate()

    const showCommentPanel = () => dispatch(LiveCommentsListActions.showCommentsPanel(true));
    const openConfirmModal = (type) => dispatch(setConfirmModalToggleVisibility(type, liveInfo))

    useEffect(() => {
        if (id) {
            dispatch(getLiveDetailsInfo(id))
        }
    }, [id])

    useEffect(() => {
        setTimeout(() => {
            document.querySelector('.show-comments .pulse-icon')?.classList?.remove('pulse-icon')
        }, 10000)
    }, [])

    const onEditLiveClick = () => {
        dispatch(addNewLiveModalToggleVisibility(true))
        dispatch(updateValuesInNewLiveModalForm({
            date,
            time,
            link: liveInfo.link,
            title: liveInfo.title,
            id: liveInfo.id,
            shopLiveId: liveInfo.shop_live_id,
            storage_products: liveInfo.storage_products,
            single_order_enabled: liveInfo.single_order_enabled
        }))
    }

    const getTotalPrice = () => dispatch(liveDetailsGetTotalOrderPrice(liveInfo.id))

    return (
        <>
            <ul className="breadcrumbs">
                <li>
                    <Link to={RouterPaths.LiveList}>{getLangText('asideLivesLabel')}</Link>
                </li>
                <li>
                    <strong>{liveInfo.date && convertToUserTimeZone(liveInfo.date).format('DD.MM.YYYY')}</strong>
                </li>
            </ul>
            <div className={`settings-page sub-settings-page loader-parent list-with-tabs separated-box`}>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>{helmetTitle}</title>
                </Helmet>
                <div className="tabs-header live-details-tabs-header mb-sm-3 flex-wrap">
                    <div className="left-column">
                        <div className="page-title">
                            {getLangText('liveListTitle')}&nbsp;
                            <span>{convertToUserTimeZone(liveInfo.date).format('DD.MM.YYYY')}</span>&nbsp;
                            <span>{convertToUserTimeZone(liveInfo.date).format('HH:mm')}</span>
                            {liveInfo.title &&
                                <div className="description">
                                    {liveInfo.title}
                                </div>
                            }
                        </div>
                        <div className="tabs-wrapper">
                            <Link
                                to={`${RouterPaths.LiveList}/${id}`}
                                className={pathname === `${RouterPaths.LiveList}/${id}` ? 'active' : ''}
                            >
                                {getLangText('defaultLabel')}
                            </Link>
                            {/*<Link*/}
                            {/*    to={`${RouterPaths.LivePlatformActiveList}/${id}`}*/}
                            {/*    className={pathname.includes('platforma') ? 'active' : ''}*/}
                            {/*>*/}
                            {/*    {getLangText('platformLabel')}*/}
                            {/*    <div className="new-tag status-bg blue">Beta</div>*/}
                            {/*</Link>*/}
                        </div>
                    </div>
                    <div className="right-column">
                        <div>
                            <div className="d-flex align-items-center justify-content-sm-end">
                                {!pathname.includes('platforma') &&
                                    <Export liveInfoId={liveInfo.id}/>
                                }
                                <div className="tooltip-parent show-comments mr-3 ml-3">
                                    <div className="tooltip-content">
                                        {getLangText('showCommentsButton')}
                                    </div>
                                    <button
                                        type="button"
                                        onClick={showCommentPanel}
                                        className="button icon-button circle-on-hover pulse-icon default-size"
                                    >
                                        <i className="icon-messenger"/>
                                    </button>
                                </div>
                                <CustomDropdown
                                    className="-mt-1"
                                    visible={visibleDropdown}
                                    setVisible={setVisibleDropdown}
                                >
                                    <button
                                        className="custom-option"
                                        onClick={onEditLiveClick}
                                    >
                                        {getLangText('editButton')}
                                    </button>
                                    <button
                                        className="custom-option"
                                        onClick={() => openConfirmModal(REMOVE_LIVE)}
                                    >
                                        {getLangText('removeButton')}
                                    </button>
                                </CustomDropdown>
                            </div>
                            <UserPermissionsWrapper allowedPermissions={[USER_PERMISSIONS_LIVE_PRICE_OVERALL]}>
                                <div className="sum-part mt-3" style={{minHeight: '20px'}}>
                                    {!pathname.includes('platforma') &&
                                        <div className="price-info">
                                            <div className="label">{getLangText('totalOrderPriceLabel')}</div>
                                            <div className="price-box ml-2">
                                                <div className="price">{getPrice(liveInfo.total_price)}
                                                    <OverlayTrigger
                                                        placement='top'
                                                        overlay={<Tooltip
                                                            className="small-size"
                                                            id="refresh-price">
                                                            {getLangText('refreshPriceLabel')}
                                                        </Tooltip>}
                                                    >
                                                        <button
                                                            type="button"
                                                            className="button refresh-price"
                                                            onClick={getTotalPrice}
                                                        >
                                                            <i className="icon-refresh"/>
                                                        </button>
                                                    </OverlayTrigger>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </UserPermissionsWrapper>
                        </div>
                    </div>
                </div>
                <div className="live-details-wrapper">
                    {children}
                </div>
                <RemoveLive
                    modalType={CONFIRM_MODAL_TYPES.REMOVE_LIVE}
                    onSave={() => dispatch(removeLive(history))}
                    title={getLangText('removeLiveLabel')}
                    desc={getLangText('askForRemoveLiveLabel')}
                    saveButton={getLangText('removeLiveLabel')}
                    saveButtonClass="danger"
                />
                {commentsPanelVisible && <CommentsListWithVirtualization/>}
            </div>
        </>
    );
};

export default LiveListsWrapper;
