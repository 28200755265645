import React, {useEffect, useState} from "react";
import useForm from "../../../../../src_shared/form/useForm";
import FormActions from "./FormActions";
import Field from "../../../../../src_shared/components/form/field/Field";
import useLang from "../../../../../src_shared/hooks/useLang";
import {Link, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import ConfirmModal from "../../../live/details/default/summary/export/ConfirmModal";
import {RouterPaths} from "../../../../routes/RouterPath";
import Loader from "../../../shared/Loader";
import {useUnitPrice} from "../../../shared/helpers/Price";
import AssignProductsModal from "../assignProducts/Form";
import AssignProductsModalActions from "../assignProducts/FormActions";
import {createSelector} from "reselect";
import {getStateRoot as getAssignProductStateRoot} from "../assignProducts/formReducer";
import EmptyList from "../../../shared/EmptyList";
import useTableRowClick from "../../../shared/hooks/useTableRowClick";
import useRemoveData from "../../../../../src_shared/hooks/useRemoveData";
import {services} from "../../../../RestServices";
import {useModal} from "../../../shared/hooks/useModal";
import PreparedPostText from "./components/preparedPostText/PreparedPostText";
import {showAlert} from "../../../shared/components/alert/AlertActions";
import ServerImage from "../../../shared/components/serverImage/ServerImage";

const CollectionForm = () => {
	const {getLangText} = useLang();
	const {id} = useParams();
	const dispatch = useDispatch();
	const {getPrice} = useUnitPrice();
	const [name, setName] = useState('');
	const {product_code} = useSelector((state) => state.session.userData);
	const onTableRowClick = useTableRowClick(`${RouterPaths.ProductsList}/edytuj`)

	const [openPostTextModal, setOpenPostTextModal] = useModal(false);


	const afterSubmit = () => {
		dispatch(showAlert('yuhuLabel', 'successfulRemovedLabel'));
		dispatch(FormActions.loadData(id));
	}
	const showModalAssignModal = () => dispatch(AssignProductsModalActions.showModal(id));
	const hideModalAssignModal = () => dispatch(AssignProductsModalActions.modal.hideModal());

	const assignProductsData = useSelector(createSelector(getAssignProductStateRoot, (stateRoot) => stateRoot))

	const {
		data: {values, setValue},
		form: {isWaiting, getFieldProps, deleteWithoutAsk, onSave},
		modal: {showModal, modalVisible, hideModal},
	} = useForm({
		FormActions,
		params: {
			id,
		}
	});

	const [{
		openModal,
		hideModal: hideRemoveModal,
		confirmModalVisible,
		itemToRemove,
		isDeleting,
		removeItem,
	}] = useRemoveData({
		rest: services.API_COLLECTIONS_ASSIGN_PRODUCTS,
		afterSubmit,
		params: {collection_id: id}
	});


	useEffect(() => {
		setName(values.name)
	}, [isWaiting])

	return (
		<>
			<div className="header-bar breadcrumbs-in-react-app">
				<ul className="breadcrumbs">
					<li>
						<Link to={RouterPaths.CollectionsList}>{getLangText('collectionsLabel')}</Link>
					</li>
					<li className="active">
						<div>{name}</div>
					</li>
				</ul>
				<Link to={RouterPaths.CollectionsList} className="button back-to-panels mb-lg-3 mt-lg-0 mt-3">
					<i className="icon-arrow-left"/>
					{getLangText('backToCollectionsLabel')}
				</Link>
			</div>
			<div
				className={`settings-page sub-settings-page list-with-tabs with-bg loader-parent ${isWaiting ? 'active-loader-parent' : ''}`}>
				<Loader isLoading={isWaiting}/>

				<div className="top-part">
					<h1 className="title">
						{name}
					</h1>
				</div>
				<div className="table-list-wrapper">
					<div className="default-shadow-box pb-4">
						<div className="settings-form p-0">
							<section>
								<div className="left-side">
									<h2 className="label">{getLangText('basicDataLabel')}</h2>
								</div>
								<div className="form-col mb-3">
									<div className="d-flex align-items-end">
										<Field
											className="mb-0 flex-grow-1"
											label={getLangText('collectionNameLabel')}
											{...getFieldProps('name')}
											setValue={setValue}
										/>
										<button
											onClick={onSave}
											type="button"
											className={`ml-3 button primary`}
										>
											{getLangText('saveButton')}
										</button>
									</div>

								</div>
							</section>
							<section>
								<div className="left-side">
									<h2 className="label">{getLangText('assignedProducts')}</h2>
									<button
										className="button border-button mt-2"
										type="button"
										onClick={() => setOpenPostTextModal(true)}
									>
										{getLangText('textToPostLabel')}
									</button>
									{openPostTextModal &&
										<PreparedPostText
											data={values}
											modalVisible={openPostTextModal}
											hideModal={() => setOpenPostTextModal(false)}
											productCode={product_code}
										/>
									}
								</div>
								<div className="form-col">
									<div className="loader-parent">
										<EmptyList
											items={values?.products}
											isLoading={isWaiting}
											className="with-padd"
											imgSrc="/assets/images/empty/products-empty.svg"
											title={getLangText('emptyHereLabel')}
											text={getLangText('changeSearchOrAddProductsLabel')}
										>
											<div className="table-wrapper">
												<div className="table-responsive">
													<table className="table live-table smaller-padd assigned-products-table">
														<thead>
														<tr>
															<th>
																{getLangText('productLabel')}
															</th>
															<th>
																{getLangText('priceLabel')}
															</th>
															<th>
																{getLangText('quantityLabel')}
															</th>
															<th>
																{getLangText('codeLabel')}
															</th>
															<th />
														</tr>
														</thead>
														<tbody>
														{values?.products?.map((item) => (
															<tr{...onTableRowClick(item.id)}>
																<td className="with-photo medium-size w-lg-auto w-100">
																	<div
																		className="d-flex align-items-lg-center mt-sm-0 mt-2">
																		<figure>
																			{item.photo && item.photo !== '0' ?
																				<ServerImage src={item.photo}
																					 alt={item.name}/> :
																				<img
																					src="/assets/images/default/product.svg"
																					alt=""/>
																			}
																		</figure>
																		<div>
																			<div className="product-name">
																				{item.name}
																			</div>
																			<div className="d-lg-none mobile-price">
																				{getPrice(item.price)}
																			</div>
																		</div>
																	</div>
																</td>
																<td className="w-lg-auto w-100 d-none d-lg-table-cell text-nowrap">
																	{getPrice(item.price)}
																</td>
																<td className="order-lg-0 order-3">
																	{item.squ}
																</td>
																<td>
																	<div className="code-col">
																		<div
																			className={`form-group with-copy-btn mb-0 disabled-bg`}>
																			<div className="form-control">
																				<input
																					className="dotted-text"
																					type="text"
																					value={`${product_code} ${item.selmo_code}`}
																					disabled
																				/>
																				<button
																					type="button"
																					className="button border-button small-size copy-btn only-icon"
																					data-link={`${product_code} ${item.selmo_code}`}
																				>
																					<i className="icon-copy mr-0"/>
																				</button>
																			</div>
																		</div>
																	</div>
																</td>
																<td className="pl-0 text-right">
																	<button
																		onClick={(e) => {
																			e.stopPropagation();
																			openModal(item)
																		}}
																		className="button icon-button lgrey"
																	>
																		<i className="icon-bin"/>
																	</button>
																</td>
															</tr>
														))}
														</tbody>
													</table>
												</div>
											</div>
										</EmptyList>
										<button
											type="button"
											onClick={showModalAssignModal}
											className="button text-blue-button"
										>
											<i className="icon-plus"/>
											{getLangText('assignProductsLabel')}
										</button>
									</div>
								</div>
							</section>
							<hr/>
							<button
								type="button"
								className="button border-button big-size left-icon tooltip-parent"
								onClick={showModal}
							>
								<i className="icon-bin"/>
								{getLangText('removeCollectionLabel')}
							</button>
						</div>
					</div>
					{modalVisible &&
						<ConfirmModal
							dialogClassName="w-380"
							title={getLangText('wantToRemoveCollectionLabel')}
							SubTitle={() => <span dangerouslySetInnerHTML={{
								__html: getLangText({
									key: 'askForRemoveCollectionWithNameLabel',
									data: [values.name]
								})
							}}/>}
							saveButton={getLangText('removeButton')}
							show={modalVisible}
							isWaiting={false}
							onSubmit={deleteWithoutAsk}
							hide={hideModal}
							saveButtonClass="danger"
						/>
					}
					{confirmModalVisible &&
						<ConfirmModal
							dialogClassName="w-380"
							title={getLangText('removeAssignedProductLabel')}
							SubTitle={() => <span dangerouslySetInnerHTML={{
								__html: getLangText({
									key: 'askForRemoveAssignedProductLabel',
									data: [itemToRemove.name, values.name]
								})
							}}/>}
							saveButton={getLangText('removeButton')}
							show={confirmModalVisible}
							isWaiting={isDeleting}
							onSubmit={removeItem}
							hide={hideRemoveModal}
							saveButtonClass="danger"
						/>
					}
					{assignProductsData.modalVisible &&
						<AssignProductsModal
							modalVisible={assignProductsData.modalVisible}
							hideModal={hideModalAssignModal}
						/>
					}
				</div>
			</div>
		</>
	)
};

export default CollectionForm;



