import React, {useEffect, useRef, useState} from "react";
import useLang from "../../../src_shared/hooks/useLang";
import useList from "../../../src_shared/list/useList";
import {getStateRoot} from "./listReducer";
import Modal from "react-bootstrap/Modal";
import SearchInput from "../../app/shared/components/form/SearchInput";
import Loader from "../../app/shared/Loader";
import ListActions from "./ListActions";
import useCSSClass from "../../app/messenger/hooks/useCSSClass/useCSSClass";
import SearchList from "./SearchList";

function useOperatingSystem() {
    const [os, setOs] = useState('unknown');

    useEffect(() => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        const platform = window.navigator.platform.toLowerCase();

        if (userAgent.indexOf('win') > -1) {
            setOs('windows');
        } else if (userAgent.indexOf('mac') > -1) {
            setOs('mac');
        } else if (userAgent.indexOf('linux') > -1) {
            setOs('linux');
        } else if (/iphone|ipad|ipod/.test(userAgent)) {
            setOs('ios');
        } else if (userAgent.indexOf('android') > -1) {
            setOs('android');
        }
    }, []);

    return os;
}

const SearchModal = () => {

    useCSSClass('with-blur')
    const searchRef = useRef(null);
    const {getLangText} = useLang();
    const os = useOperatingSystem();
    const {
        list: {items, isWaiting},
        remove: {isModalVisible, cancelItemRemoval},
        search
    } = useList({
        ListActions,
        getStateRoot,
        preventAutoLoad: true,
    })

    const isEmptyResult = (!items?.length || search.searchText.length < 1) && !isWaiting;

    const EmptyStateContent = () => {
        if (search.searchText.length > 1) {
            return (
                <div className="list-place empty-results">
                    {getLangText('noSearchResultLabel')}
                </div>
            )
        }
        return (
            <div className="list-place empty-results font-medium">
                {getLangText('textToSearchLabel')}
                <div
                    className="xl:block hidden text-left text-xs mt-4 bg-[#f9f9f9] p-3 rounded-[10px] max-w-[50%] mx-auto">
                    <div className="font-bold">{getLangText('funFact')}</div>
                    <div>
                        <div className="text-desc font-medium whitespace-pre-wrap">
                            {getLangText('canOpenSearchModalByShortcuts')}
                        </div>
                        <div className="flex items-center space-x-2 mt-2">
                            <div className="bg-[#e5e5e5] text-[#000] flex items-center p-1 px-2 rounded-[5px]">
                                {os === 'mac' ?
                                    <>
                                        <img
                                            className="w-[10px] mr-0.5"
                                            src="/assets/images/svg/cmd-icon.svg" alt=""/>
                                        CMD
                                    </> :
                                    'CTRL'
                                }
                            </div>
                            <div>+</div>
                            <div className="bg-[#e5e5e5] text-[#000] py-[4px] px-[10px] rounded-[5px]">
                                K
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    return (
        <Modal
            show={isModalVisible}
            dialogClassName="default-modal w-650"
            contentClassName="rounded-xl"
            backdropClassName="with-blur"
            onHide={cancelItemRemoval}
        >
            <Modal.Body className="p-0">
                <div className="search-place">
                    <SearchInput
                        searchRef={searchRef}
                        className="big-icon"
                        placeholder={getLangText('searchPlaceholder')}
                        search={search}
                        autoFocus={true}
                    />
                    <button
                        onClick={cancelItemRemoval}
                        type="button"
                        className="button"
                    >
                        <i className="icon-cross"/>
                    </button>
                </div>
                {isEmptyResult ?
                    <EmptyStateContent/> :
                    <div className="list-place loader-parent">
                        <Loader className="small-loader" isLoading={isWaiting}/>
                        <SearchList
                            hideModal={cancelItemRemoval}
                            search={search}
                            items={items}
                        />
                    </div>
                }
                <div className="border-t flex items-center py-3 px-6">
                    <div className="flex items-center">
                        <img
                            className="h-[20px] mr-1"
                            src="/assets/images/svg/up.svg"
                            alt=""
                        />
                        <img
                            className="h-[20px]"
                            src="/assets/images/svg/down.svg"
                            alt=""
                        />
                        <div className="text-[#9CA3AF] font-semibold ml-1.5 text-xs">
                            {getLangText('navigate')}
                        </div>
                    </div>
                    <div className="flex items-center ml-3">
                        <img
                            className="h-[20px]"
                            src="/assets/images/svg/select.svg"
                            alt=""
                        />
                        <div className="text-[#9CA3AF] font-semibold ml-1.5 text-xs">
                            {getLangText('selectButton')}
                        </div>
                    </div>
                    <div className="flex items-center ml-auto">
                        <img
                            className="h-[20px]"
                            src="/assets/images/svg/esc.svg"
                            alt=""
                        />
                        <div className="text-[#9CA3AF] font-semibold ml-1.5 text-xs">
                            {getLangText('closeButton')}
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default SearchModal;
