export const FUNCTIONALITY_RANGE_TO_END_PLAN = 'TO_END';
export const FUNCTIONALITY_RANGE_MONTHLY = 'PER_MONTH';

const FunctionalityPaymentRangeTypes = [
	{
		id: FUNCTIONALITY_RANGE_TO_END_PLAN,
		label: 'paymentToEndPlanLabel',
	},
	{
		id: FUNCTIONALITY_RANGE_MONTHLY,
		label: 'paymentMonthlyLabel',
	},
];

export default FunctionalityPaymentRangeTypes;
