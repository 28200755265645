import Model, {convertObjectProperties} from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

class PaxyModel extends Model {
	getModel() {
		return {
			country: null,
			countries: [],
		};
	}

	getValidators() {
		return {
			country: ValidationError.notEmpty,
			countries: ValidationError.skip,
		};
	}

	parseDTO(data) {
		return convertObjectProperties(
			data,
			{
			},
			{
				countries: data,
			},
		);
	}
}

export default new PaxyModel();
