import React from 'react';
import {Route, Switch} from "react-router-dom";
import {RouterPaths} from "./RouterPath";
import {useSelector} from "react-redux";
import {SELMO_APP_PERMISSION} from "../app/shared/enums/SelmoPermissions";
import PermissionRoute from "./PermissionRoute";
import TutorialPageBox from "../../src_shared/components/ui/TutorialPageBox/TutorialPageBox";
import {createSelector} from "reselect";
import {getStateRoot as getAutomationListStateRoot} from "../app/automation/lists/automation/listReducer";
import AutomationEditPage from "../app/automation/form/edit/Page";
import AutomationList, {firstPageBenefits} from "../app/automation/lists/automation/AutomaticationList";
import AutomationInteractionsList from "../app/automation/lists/interactions/InteractionsList";
import AutomationInteractionsEditList from "../app/automation/form/interactions/InteractionsList";
import {HEADER_BOX_AUTOMATION} from "../app/shared/enums/TutorialsVideo";
import FormWrapper from "../app/automation/form/FormWrapper";
import ListsWrapper from "../app/automation/lists/ListsWrapper";
import AutomationNewsletterList from "../app/automation/lists/newsletter/List";
import AutomationStatisticsPage from "../app/automation/lists/statistics/AutomationStatisticsPage";

const AutomationRoute = ({path}) => {

    const {userData} = useSelector((state) => state.session);
    const {isFirstStepPage} = useSelector(createSelector(getAutomationListStateRoot, (stateRoot) => stateRoot));

    return (
        <PermissionRoute
            path={path}
            allowedPermissions={[SELMO_APP_PERMISSION]}
        >
            {(!isFirstStepPage && !userData.first_step_automation) &&
                <TutorialPageBox
                    videoStatsName={HEADER_BOX_AUTOMATION}
                    page="automation"
                    benefits={firstPageBenefits}
                    title="automationLabel"
                />
            }
            <Switch>
                <Route path={RouterPaths.AutomationEditFull}>
                    <FormWrapper>
                        <Switch>
                            <Route
                                exact
                                path={RouterPaths.AutomationInteractionFull}
                                component={AutomationInteractionsEditList}
                            />
                            <Route
                                exact
                                path={RouterPaths.AutomationStatisticsFull}
                                component={AutomationStatisticsPage}
                            />
                            <Route
                                exact
                                path={RouterPaths.AutomationNewsletterFull}
                                component={AutomationNewsletterList}
                            />
                            <Route
                                exact
                                path={RouterPaths.AutomationEditFull}
                                component={AutomationEditPage}
                            />
                        </Switch>
                    </FormWrapper>
                </Route>
                <Route path={RouterPaths.Automation}>
                    <ListsWrapper>
                        <Switch>
                            <Route
                                exact
                                path={RouterPaths.AutomationInteractions}
                                component={AutomationInteractionsList}
                            />
                            <Route
                                exact
                                path={RouterPaths.AutomationStatistics}
                                component={AutomationStatisticsPage}
                            />
                            <Route
                                exact
                                path={RouterPaths.Automation}
                                component={AutomationList}
                            />
                        </Switch>
                    </ListsWrapper>
                </Route>
            </Switch>
            {/*<Redirect to={RouterPaths.Automation}/>*/}
        </PermissionRoute>
    );
};

export default AutomationRoute;
