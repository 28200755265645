import React from 'react';
import CheckboxField from "../../../../../src_shared/components/form/checkboxField/CheckboxField";
import useLang from "../../../../../src_shared/hooks/useLang";
import Field from "../../../../../src_shared/components/form/field/Field";

const InvoiceData = ({getFieldProps, setValue, values, inOrder = false}) => {
    const {getLangText} = useLang();

    return (
        <div className="w-full">
            <CheckboxField
                {...getFieldProps('want_invoice')}
                LabelComponent={getLangText('wantInvoiceLabel')}
                setValue={setValue}
            />
            {!!+values.want_invoice &&
                <Field
                    {...getFieldProps('nip')}
                    label={getLangText('nipLabel')}
                    setValue={setValue}
                    floatedLabel={!inOrder}
                    className="w-full"
                />
            }
        </div>
    );
};

export default InvoiceData;