import React, {useEffect, useState} from 'react';
import SelectField from "../../../../../src_shared/components/form/selectField/SelectField";
import {selmoUrl} from "../../../../../src_shared/api/api";
import {services as servies} from "../../../../RestServices";
import {useDispatch, useSelector} from "react-redux";
import {showAlert} from "../../../shared/components/alert/AlertActions";
import {components} from 'react-select'
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import useLang from "../../../../../src_shared/hooks/useLang";
import ProductTypeIcon from "../../../shared/components/productType/ProductTypeIcon";
import api from "../../../../../services/axios/axios";
import ServerImage from "../../../shared/components/serverImage/ServerImage";
import SendDigitalInEmail from "./components/SendDigitalInEmail";
import {convertToUserTimeZone} from "../../../shared/helpers/dateHelpers";
import ReturnProduct from "./components/returnProduct/ReturnProduct";
import {BUTIK_MADAME_SHOP_ID, TEST_ACCOUNT_SHOP_ID} from "../../../../../src_shared/enums/TrustedShopsIds";

const Product = ({product, values, getTotalPrice, inClientsOrders, openPreviewModal, editable}) => {
    const dispatch = useDispatch();
    const {getLangText} = useLang();
    const {lang} = useSelector((state) => state.i18n);
    const {userData} = useSelector((state) => state.session);

    const options = [
        {value: 'paid', label: getLangText('paidOptionLabel')},
        {value: 'not_paid', label: getLangText('notPaidOptionLabel')},
    ]

    const defaultValue = product.payment_date ? options[0] : options[1]

    const [paidStatusValue, setPaidStatusValue] = useState(defaultValue);

    const setPaidStatus = async (name, status) => {
        try {
            const response = await api.put(`${selmoUrl}/${servies.API_ORDER_PAID_STATUS}/${product.order_id}`, {
                status,
                id: product.id,
            })
            const {item} = response.data
            if (item) {
                setPaidStatusValue(options[0])
            } else {
                setPaidStatusValue(options[1])
            }
        } catch (e) {
            dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
        }
    }

    const getLiveOrderedDate = () => {
        const matched = values.liveProducts?.find((i) => product.product_name?.trim() === i.name?.trim() && product.product_price?.trim() === i.price?.trim())
        if (matched) {
            return matched.date;
        } else return null;
    }

    useEffect(() => {
        const preparedValue = options.find((i) => i.value === paidStatusValue.value);
        if (preparedValue !== paidStatusValue) {
            setPaidStatusValue(preparedValue)
        }
    }, [lang])

    useEffect(() => {
        if (product.payment_date) {
            setPaidStatusValue(options[0]);
        } else {
            setPaidStatusValue(options[1]);
        }
    }, [product.payment_date])

    return (
        <li className={`${product.removed_date ? 'product-removed' : ''}`}>
            <figure>
                {product.product_photo && product.product_photo !== '0' ?
                    <ServerImage
                        className="cursor-zoom-in"
                        onClick={() => openPreviewModal(product.product_photo_big)}
                        src={product.product_photo}/>
                    :
                    <img src="/assets/images/default/product.svg"
                         alt={product.product_name}/>
                }
                <div className={`count ${+product.quantity > 1 ? 'active' : ''}`}>
                    <span>{product.quantity}</span>
                </div>
            </figure>
            <div className={`order-summary__products__content ${inClientsOrders ? 'in-clients-orders' : ''}`}>
                <div>
                    <div className="order-summary__products__content__name">
                        {product.product_name}
                        {(!!product.payment_started && !product.payment_date) &&
                            <div className="d-inline-flex ml-[8px] mb-[6px]">
                                <OverlayTrigger
                                    placement='top'
                                    overlay={
                                        <Tooltip
                                            className="small-size"
                                            id="payment-started"
                                        >
                                            {getLangText('startPayment')}
                                        </Tooltip>
                                    }
                                >
                                <span className="text-[10px] bg-[#FCF6E5] text-[#DE8E09] rounded-[5px] px-[5px]">
                                    {convertToUserTimeZone(product.payment_started).format('DD.MM.YYYY HH:mm')}
                                </span>
                                </OverlayTrigger>
                            </div>
                        }
                        <div className="d-inline-flex align-items-center ml-1 hover-icons">
                            <OverlayTrigger
                                placement='top'
                                overlay={
                                    <Tooltip
                                        className="small-size"
                                        id="added-date"
                                    >
                                        {getLangText('addedLabel')}&nbsp;
                                        {convertToUserTimeZone(product.date).format('DD.MM.YYYY HH:mm')}
                                    </Tooltip>
                                }
                            >
                                <img src="/assets/images/svg/product-type/info.svg" width={14} alt=""/>
                            </OverlayTrigger>
                            <ProductTypeIcon product={product}/>
                        </div>
                    </div>
                    {getLiveOrderedDate() &&
                        (+values.shop_id === 29 || +values.shop_id === 5) &&
                        <div className="order-summary__products__content__size">

                            <div className="removed-by-client">
								<span>
									{getLangText('liveListTitle')}:
                                    &nbsp;
                                    <strong>{convertToUserTimeZone(getLiveOrderedDate()).format('DD.MM.YYYY HH:mm')}</strong>
								</span>
                            </div>
                        </div>
                    }
                    {product.removed_date &&
                        <div className="removed-by-client">
                            {getLangText('removedByClientLabel')} {convertToUserTimeZone(product.removed_date).format('DD.MM.YYYY HH:mm')}
                        </div>
                    }
                    {(product.product_code || product.product_size) &&
                        <div className="order-summary__products__content__size">
                            {product.product_code &&
                                <span>{getLangText('codeLabel')}: <strong>{product.product_code}</strong></span>
                            }
                            {product.product_size && product.product_code && <span className="mx-1">•</span>}
                            {product.product_size &&
                                <span>{getLangText('variantLabel')}: <strong>{product.product_size}</strong></span>
                            }
                            {product.shelf_name && <span className="mx-1">•</span>}
                            {product.shelf_name &&
                                <span>{getLangText('localizationLabel')}: <strong>{product.shelf_name || getLangText('lackLabel')}</strong></span>
                            }
                            {product.manufacturers_code && <span className="mx-1">•</span>}
                            {product.manufacturers_code &&
                                <span>{getLangText('manufacturerCode')}: <strong>{product.manufacturers_code}</strong></span>
                            }
                            {product.external_id && <span className="mx-1">•</span>}
                            {product.external_id &&
                                <span>{getLangText('storageCode')}: <strong>{product.external_id}</strong></span>
                            }
                        </div>
                    }
                    <div className="flex items-center">
                        {editable &&
                            <SelectField
                                name="status"
                                options={options}
                                setValue={setPaidStatus}
                                className="d-inline-block mb-0"
                                value={paidStatusValue}
                                extraProps={{
                                    isSearchable: false,
                                    components: {
                                        Control: ({children, ...rest}) => (
                                            <components.Control {...rest}>
                                                {paidStatusValue.value === 'paid' ?
                                                    <div className="green-option chosen-option">
                                                        <i className="icon-tick-c"/>
                                                        {children}
                                                    </div> :
                                                    <div className="red-option chosen-option">
                                                        <i className="icon-cross-c"/>
                                                        {children}
                                                    </div>
                                                }
                                            </components.Control>
                                        )
                                    }
                                }}
                            />
                        }
                        {!!+product.digital &&
                            <SendDigitalInEmail
                                id={values.id}
                                product={product}
                            />
                        }
                        {editable && (+userData.id === BUTIK_MADAME_SHOP_ID || +userData.id === TEST_ACCOUNT_SHOP_ID) &&
                            <ReturnProduct
                                id={values.id}
                                product={product}
                                shopOrderId={values.shop_order_id}
                            />
                        }
                    </div>
                </div>
                <div className="order-summary__products__content__price">
                    {getTotalPrice(product.product_total_price)}
                </div>
            </div>
        </li>
    );
};

export default Product;
