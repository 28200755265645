import React from 'react';
import Modal from "react-bootstrap/Modal";
import useLang from "../../../../../src_shared/hooks/useLang";

const MarketingRegulationModal = ({modalVisible, hideModal, desc = '', DescComponent = null}) => {

    const {getLangText} = useLang();

    return (
        <Modal
            dialogClassName="default-modal"
            show={modalVisible}
            contentClassName="rounded-xl"
            onHide={hideModal}
        >
            <Modal.Header>
                <Modal.Title>
                    <div className="text-xl font-bold">
                        {getLangText('marketingCommunications')}
                    </div>
                </Modal.Title>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            <Modal.Body>
                <div
                    className="text-sm text-desc font-medium leading-[25px]"
                >
                    {DescComponent ||  <span dangerouslySetInnerHTML={{__html: getLangText(desc)}}/>}
                </div>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button border-button big-size w-full mt-4 mb-2"
                >
                    <div className="text-sm font-bold">{getLangText('closeButton')}</div>
                </button>
            </Modal.Body>
        </Modal>
    );
};

export default MarketingRegulationModal;
