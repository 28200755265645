import Model from "../../../../src_shared/modules/model/Model";
import ValidationError from "../../../../src_shared/form/validation/ValidationError";
import moment from "moment";
import {CLIENT_STRIPE} from "../../components/app/shared/enums/ClientsPaymentTypes";
import {CLIENT_PERIOD_MONTHLY} from "../../components/app/shared/enums/ClientsPeriodTypes";

export class ClientsModel extends Model {
	getModel() {
		return {
			id: 0,
			user_id: '',
			name: '',
			date: new Date(),
			new_date: new Date(),
			invoice_number: '',
			payment_type: CLIENT_STRIPE,
			type: CLIENT_PERIOD_MONTHLY,
			price: 367.77,
			invoice_wanted: '0',
			vat_zero: '0',
			currency: '1',
		};
	}

	getValidators() {
		return {
			name: ValidationError.skip,
			date: ValidationError.skip,
			new_date: ValidationError.skip,
			user_id: ValidationError.skip,
			invoice_number: ValidationError.skip,
			vat_zero: ValidationError.skip,
			currency: ValidationError.skip,
			price: ValidationError.notEmpty,
			payment_type: ValidationError.notEmpty,
			type: ValidationError.notEmpty,
		};
	}

	buildDTO(data) {
		return {
			user_id: data.user_id,
			payment_type: data.payment_type,
			type: data.type,
			invoice_number: data.invoice_number,
			new_date: moment(data.new_date).toDate(),
			date: moment(data.date).toDate(),
			price: data.price,
			invoice_wanted: data.invoice_wanted,
			vat_zero: data.vat_zero,
			currency: data.currency,
		}
	}
}

export default new ClientsModel();
