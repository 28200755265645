import React, {useState} from 'react';
import CheckboxField from "../../../../../../../../src_shared/components/form/checkboxField/CheckboxField";
import useLang from "../../../../../../../../src_shared/hooks/useLang";
import AssignToPlace from "../../../../assignToPlace/AssignToPlace";

const Variants = ({values, selectedVariants, setSelectedVariants}) => {
    const {getLangText} = useLang();

    const setValue = (id) => {
        if (selectedVariants.includes(id)) {
            const filtered = selectedVariants.filter((i) => i !== id);
            setSelectedVariants(filtered)
        } else {
            setSelectedVariants([...selectedVariants, id])
        }
    }

    const isAllSelected = values.sizes?.map((i) => i.id)?.length === selectedVariants.length;

    const selectAll = () => {
        const allVariants = values.sizes.map((i) => i.id);
        if (isAllSelected) {
            setSelectedVariants([])
        } else {
            setSelectedVariants(allVariants);
        }
    }

    if (!+values.has_sizes) {
        return null;
    }

    return (
        <>
            <div className="mt-3">
                <div className="font-bold mb-2">{getLangText('variantsLabel')}</div>
                <table className="w-full border-spacing-y-[10px] border-separate">
                    <thead>
                    <tr
                        onClick={selectAll}
                        className="text-xs text-desc font-regular">
                        <th className="mb-3">
                            <div className="grow flex items-center">
                                <CheckboxField
                                    className="align-items-start mb-0"
                                    value={isAllSelected}
                                    setValue={() => {
                                    }}
                                />
                                {getLangText('nameLabel')}
                            </div>
                        </th>
                        <th className="text-right">
                            {getLangText('localizationLabel')}
                        </th>
                        <th className="text-right">
                            {getLangText('globalQuantityLabel')}
                        </th>
                        <th className="text-right">
                            {getLangText('quantityLabel')}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {values.sizes?.map((size) => (
                        <tr
                            onClick={() => setValue(size.id)}
                        >
                            <td>
                                <div
                                    onClick={() => setValue(size.id)}
                                    className="flex"
                                >
                                    <CheckboxField
                                        className="items-center mb-0"
                                        setValue={() => {
                                        }}
                                        value={selectedVariants.includes(size.id)}
                                    />
                                    <div className="grow">{size.size}</div>
                                </div>
                            </td>
                            <td className="text-right">
                                {size.shelf_name ?
                                    <div>{size.shelf_name}</div> :
                                    <div
                                        className={`rounded-[5px] inline-block p-1 text-xs font-bold bg-[#FEF2F2] text-[#981B1B]`}>
                                        {getLangText('lackLabel')}
                                    </div>
                                }
                            </td>
                            <td className="text-right">
                                {size.squ}
                            </td>
                            <td className="text-right">
                                {size.squ_warehouse}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                {/*<div*/}
                {/*    onClick={selectAll}*/}
                {/*    className="flex items-center mb-1 text-xs text-desc cursor-pointer"*/}
                {/*>*/}
                {/*    <div className="grow flex items-center">*/}
                {/*        <CheckboxField*/}
                {/*            className="align-items-start mb-0"*/}
                {/*            value={isAllSelected}*/}
                {/*            setValue={() => {*/}
                {/*            }}*/}
                {/*        />*/}
                {/*        {getLangText('nameLabel')}*/}
                {/*    </div>*/}
                {/*    <div className="mr-3">{getLangText('localizationLabel')}</div>*/}
                {/*    <div className="mr-2 w-[100px] text-right">{getLangText('quantityLabel')}</div>*/}
                {/*    <div className="text-right w-[100px]">{getLangText('globalQuantityLabel')}</div>*/}
                {/*</div>*/}
                {/*<div className="mt-2">*/}
                {/*    {values.sizes?.map((size) => (*/}
                {/*        <div*/}
                {/*            key={size.id}*/}
                {/*            className="flex items-center cursor-pointer py-2 group border-b last:border-0"*/}
                {/*            onClick={() => setValue(size.id)}*/}
                {/*        >*/}
                {/*            <CheckboxField*/}
                {/*                className="align-items-start mb-0"*/}
                {/*                setValue={() => {*/}
                {/*                }}*/}
                {/*                value={selectedVariants.includes(size.id)}*/}
                {/*            />*/}
                {/*            <div className="grow">{size.size}</div>*/}
                {/*            {size.shelf_name ?*/}
                {/*                <div className="mr-3">{size.shelf_name}</div> :*/}
                {/*                <div*/}
                {/*                    className={`rounded-[5px] inline-block mr-3 p-1 text-xs font-bold bg-[#FEF2F2] text-[#981B1B]`}>*/}
                {/*                    {getLangText('lackLabel')}*/}
                {/*                </div>*/}
                {/*            }*/}
                {/*            <div className="text-right mr-2 w-[100px]">{size.squ}</div>*/}
                {/*            <div className="text-right w-[100px]">{size.squ_warehouse}</div>*/}
                {/*        </div>*/}
                {/*    ))}*/}
                {/*</div>*/}
            </div>
        </>
    );
};

export default Variants;