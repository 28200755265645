import React from 'react';
import useLang from "../../../../../../src_shared/hooks/useLang";

const Field = ({showLabel, label, name, setValue, value, activeValidation, required, disabled, extraProps, displayTextError, placeholder}) => {

	const hasError = required && activeValidation && !value;
	const {getLangText} = useLang();

	return (
		<div className={`form-group ${hasError ? 'error-group' : ''}`}>
			{showLabel &&
				<label className="control-label">{label}</label>
			}
			<input
				type="text"
				className="form-control"
				onChange={(e) => setValue(name, e.target.value)}
				value={value}
				disabled={disabled}
				placeholder={placeholder}
				{...extraProps}
			/>
			{hasError && displayTextError &&
				<div className="error-text">
					{getLangText('fieldIsRequiredLabel')}
				</div>
			}
		</div>
	);
};

Field.defaultProps = {
	setValue: () => {},
	showLabel: true,
	label: '',
	value: '',
	activeValidation: false,
	required: false,
	name: '',
	disabled: false,
	extraProps: {},
	displayTextError: true,
	placeholder: '',
}

export default Field;
