import React, {useContext, useEffect, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import SearchInput from "../../../../../shared/components/form/SearchInput";
import {services} from "../../../../../../RestServices";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import useDataApi from "../../../../../../../src_shared/hooks/useDataApi";
import Loader from "../../../../../shared/Loader";
import axios from "axios";
import {selmoUrl} from "../../../../../../../src_shared/api/api";
import {useDispatch} from "react-redux";
import {showAlert} from "../../../../../shared/components/alert/AlertActions";
import {ShopSettingsContext} from "../../../../../../routes/shopSettingsRoute/ShopSettingsRoute";
import api from "../../../../../../../services/axios/axios";

const AssignCategoriesModal = ({modalVisible, hideModal, afterSubmit}) => {

	const {getLangText} = useLang();
	const dispatch = useDispatch();
	const [searchText, setSearchText] = useState('');
	const [selected, setSelected] = useState([]);
	const [waiting, setWaiting] = useState();
	const {setIsChanging} = useContext(ShopSettingsContext);

	const onSave = async () => {
		setWaiting(true);
		try {
			await api.post(`${selmoUrl}/${services.API_STORE_HOMEPAGE_CATEGORIES}`, {
				categories: selected,
				homepage_active: '1',
			})
			hideModal();
			afterSubmit();
			dispatch(showAlert())
			setIsChanging(new Date());
		} catch (error) {
			dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
			console.error('Cannot assign categories')
		} finally {
			setWaiting(false);
		}
	};

	const params = {
		homepage_active: 0,
	}

	const [{
		data,
		isLoading,
		fetchData
	}] = useDataApi(`${services.API_STORE_HOMEPAGE_CATEGORIES}?homepage_active=0`, [], [], true, params)

	const setProducts = (id) => {
		const matched = selected.find((i) => i === id);
		if (matched) {
			const filtered = selected.filter((i) => i !== id)
			setSelected(filtered)
		} else {
			const preparedProducts = [...selected, id]
			setSelected(preparedProducts)
		}
	}

	const search = {
		updateSearchText: setSearchText,
		searchText,
		callSearch: fetchData,
	}

	useEffect(() => {
		fetchData();
	}, [])

	return (
		<Modal
			dialogClassName="default-modal"
			show={modalVisible}
			onHide={hideModal}
		>
			<Modal.Header className="with-border">
				<Modal.Title>
					{getLangText('assignCategoriesLabel')}
				</Modal.Title>
				<button
					onClick={hideModal}
					type="button"
					className="button"
				>
					<i className="icon-cross"/>
				</button>
			</Modal.Header>
			<Modal.Body className="pt-3 pb-5 assign-products-body">
				<div className="form-label d-flex align-items-center">
					{getLangText('selectCategoryWhichIsPresentingLabel')}
					<div
						className="ml-auto info-text text-capitalize text-nowrap">{getLangText('selectedLabel')}: {selected.length}</div>
				</div>
				<SearchInput
					className="mb-2"
					placeholder={getLangText('searchPlaceholder')}
					search={search}
				/>
				<div className="loader-parent">
					<Loader isLoading={isLoading}/>
					<div className="data-boxes-wrapper small-padd products-boxes">
						{data?.length ?
							data.map((item) => (
								<div
									key={item.id}
									className={`content payment-content ${selected.includes(item.id) ? 'active' : ''}`}
								>
									<div className="content__top-part">
										<input
											checked={selected.includes(item.id)}
											onChange={() => setProducts(item.id)}
											type="checkbox"
										/>
										<span className="checkmark"/>
										<div className="flex-grow-1">
											<div className="label">
												{item.name}
											</div>
										</div>
									</div>
								</div>
							)) :
							<div className="text-center mt-4">Brak kategorii</div>
						}
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<button
					onClick={hideModal}
					type="button"
					className="button border-button"
				>
					{getLangText('cancelButton')}
				</button>
				<button
					onClick={onSave}
					type="button"
					className={`button primary px-4 ${waiting ? 'loading-button' : ''}`}
				>
					{waiting && <Loader/>}
					{getLangText('assignCategoriesLabel')}
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default AssignCategoriesModal;
