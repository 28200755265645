import ListReducer from "../../../../src_shared/list/ListReducer";
import createReducer from "../../shared/helpers/createReducer";
import Composition from "../../shared/helpers/Composition";

export const getStateRoot = (state) => state.stats.list;
export const prefix = 'BOXES_STATS_LIST_';

const getInitState = () => ListReducer.getInitState({
	items: {
		currencies: [],
	}
});

const getReduceMap = () =>  new Composition(
	ListReducer.getReduceMap(),
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

