import React from 'react';
import {SortableContainer, SortableElement} from "react-sortable-hoc";
import ListActions from "./ListActions";
import {useDispatch} from "react-redux";
import EmptyList from "../../../shared/EmptyList";
import {RouterPaths} from "../../../../routes/RouterPath";
import {useHistory} from "react-router-dom";
import useLang from "../../../../../src_shared/hooks/useLang";
import {arrayMoveImmutable as arrayMove} from 'array-move';

const SortableProductsList = SortableContainer(({items}) => {
    return (
        <ul className="sub-tree">
            {items.map((item, index) => (
                <SortableProductItem
                    key={`item-${item.id}`}
                    index={index}
                    item={item}
                />
            ))}
        </ul>
    );
});

const SortableProductItem = SortableElement(({item}) => {
    const history = useHistory();

    return (
        <li className="sub-category-row">
            <div className="sort-button">
                <i className="icon-drag"/>
            </div>
            <div className="sortable-tree-item">
                <button
                    className="text-left hover:underline"
                    type="button"
                    onClick={() => history.push(`${RouterPaths.CategoriesList}/${item.id}/`)}
                >
                    {item.name}
                </button>
            </div>
        </li>
    )
});

const SortableItem = SortableElement(({item, onItemClick, onSortSubCategoriesEnd}) => {
    const {getLangText} = useLang();
    const history = useHistory();
    return (
        <li className={`sortable-tree-item ${!!+item.totalSubcategories ? 'with-products' : ''}`}>
            <div className="category-row">
                <div className="sort-button">
                    <i className="icon-drag"/>
                </div>
                <button
                    className="text-left hover:underline"
                    type="button"
                    onClick={() => history.push(`${RouterPaths.CategoriesList}/${item.id}/`)}
                >
                    {item.name}
                </button>
                {!!+item.totalSubcategories &&
                    <button
                        type="button"
                        onClick={onItemClick}
                        className="sub-categories-count"
                    >
                        {getLangText('subCategoriesLabel')}: {item.totalSubcategories}
                        <div className="toggle-icon">
                            <i className="icon-arrows"/>
                        </div>
                    </button>
                }
            </div>
            {!!item.children?.length && item.expanded &&
                <SortableProductsList
                    items={item.children}
                    onSortEnd={onSortSubCategoriesEnd}
                />
            }
        </li>
    )
});

const SortableList = SortableContainer(({items, onItemClick, onSortSubCategoriesEnd}) => {
    return (
        <ul className="category-tree">
            {items.map((item, index) => (
                <SortableItem
                    key={`item-${item.id}`}
                    index={index}
                    items={items}
                    item={item}
                    onItemClick={() => onItemClick(item)}
                    onSortSubCategoriesEnd={(i) => onSortSubCategoriesEnd(i, item)}
                />
            ))}
        </ul>
    );
});

const Sortable = ({items, isWaiting}) => {
    const dispatch = useDispatch();
    const {getLangText} = useLang();

    const updateAllItemsOnServer = (updatedItems) => dispatch(ListActions.updateItemsOnServer(updatedItems))
    const updateSubCategoriesOnServer = (categoryId, updatedItems) => dispatch(ListActions.updateSubCategoriesOnServer(categoryId, updatedItems))

    const getSubCategories = (category) => dispatch(ListActions.getSubCategories(category))


    const onSortEnd = ({oldIndex, newIndex}) => {
        const updatedItems = arrayMove(items, oldIndex, newIndex);
        updateAllItemsOnServer(updatedItems)
    };

    const onSortSubCategoriesEnd = (props, item) => {

        const updatedItems = arrayMove(item.children, props.oldIndex, props.newIndex);
        updateSubCategoriesOnServer(item.id, updatedItems)
    };

    return (
        <EmptyList
            items={items}
            isLoading={isWaiting}
            className="with-padd functionality-purchase"
            imgSrc="/assets/images/default/shop-logo.svg"
            title={getLangText('emptyHereLabel')}
            text="changeSearchOrAddCategoryLabel"

        >
            <div className="sortable-tree-wrapper pb-4">
                <SortableList
                    items={items}
                    onSortEnd={onSortEnd}
                    onItemClick={getSubCategories}
                    onSortSubCategoriesEnd={onSortSubCategoriesEnd}
                />
            </div>
        </EmptyList>
    );
};

export default Sortable;
