import ListReducer from "../../../../../../src_shared/list/ListReducer.js";
import createReducer from "../../../../shared/helpers/createReducer.js";
import Composition from "../../../../shared/helpers/Composition.js";

export const getStateRoot = (state) => state.lives.comments;
export const prefix = 'LIVES_DETAILS_COMMENTS_';

const getInitState = () => ListReducer.getInitState({
	itemsPerPage: 20,
	scrollPage: 1,
	hasMore: true,
	commentsPanelVisible: false,
	scrollTouched: false,
	videoId: null,
	reversedItems: [],
	updateScrollAfterSocketMessage: false,
	scrollToBottom: true,
	waitingForFirstPage: true,
});

const getReduceMap = () => new Composition(
	ListReducer.getReduceMap(),
	{
		SET_SCROLL_PAGE: (state, action) => ({...state, scrollPage: action.scrollPage}),
	},
	{
		SET_HAS_MORE_PAGES: (state, action) => ({...state, hasMore: action.toggle}),
	},
	{
		SHOW_COMMENTS_PANEL: (state, action) => ({...state, commentsPanelVisible: action.toggle}),
	},
	{
		SET_SCROLL_TOUCHED: (state, action) => ({...state, scrollTouched: action.toggle}),
	},
	{
		SET_WAITING_FOR_FIRST_PAGE: (state, action) => ({...state, waitingForFirstPage: action.toggle}),
	},
	{
		SET_VIDEO_ID: (state, action) => ({...state, videoId: action.id}),
	},
	{
		UPDATE_SCROLL_AFTER_SOCKET_MESSAGE: (state, action) => ({...state, updateScrollAfterSocketMessage: action.toggle}),
	},
	{
		SCROLL_TO_BOTTOM: (state, action) => ({...state, scrollToBottom: action.toggle}),
	},
	{
		SET_REVERSED_ITEMS: (state, action) => {
			return ({...state, reversedItems: action.items})
		},
	},
	{
		ADD_COMMENT_FROM_SOCKET: (state, action) => {
			const {item} = action;
			if (!item) {
				console.error('listReducer error: action '
					+ 'TABLE_ADD_TO_BEGIN has invalid data!');
				console.info(action);
				return state;
			}

			const matched = state.reversedItems.find((i) => i.id === item.id);

			if (matched && item.fromFacebookSocket) {
				return state;
			}

			if (matched) {
				return {
					...state,
					reversedItems: state.reversedItems.map((i) => item.id === i.id ? item : i)
				}
			}

			return {
				...state,
				reversedItems: [...state.reversedItems, item]
			}
		},
	}
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

