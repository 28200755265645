import {getStateRoot, prefix} from "./formReducer.js";
import {services} from "../../../../RestServices.js";
import FormActions from "../../../../../src_shared/form/FormActions.js";
import AssignCustomerModel from "../../../../../modules/models/order/AssignCustomerModel";
import {showAlert} from "../../../shared/components/alert/AlertActions";
import axios from "axios";
import {selmoUrl} from "../../../../../src_shared/api/api";
import {getStateRoot as getEditProductStateRoot} from "../form/formReducer";
import {RouterPaths} from "../../../../routes/RouterPath";
import {ASSIGN_ORDER_TYPE_NEW} from "../../../shared/enums/AssignOrderTypes";
import EditOrderFormActions from "../form/FormActions";
import api from "../../../../../services/axios/axios";

export class AssignCustomerFormActions extends FormActions {

	submitEditForm(values, history) {
		return async (dispatch, getState) => {
			const {id, shop_order_id} = getEditProductStateRoot(getState()).data;
			const {orderType} = getStateRoot(getState()).data;

			dispatch(this.validation.activateValidation());

			if (!this.dataModel.validateData(values)) {
				dispatch(showAlert('fillRequireFieldWarning', '', 'alert-fail'))
				console.error('Validation failed!');
				return;
			}

			const filteredData = Object.entries(values).filter(([key]) => key !== 'id');
			const finalData = Object.fromEntries(filteredData)

			const preparedData = this.dataModel.buildDTO(finalData)

			dispatch(this.waiting.startWaiting());

			try {
				const {data} = await api.put(`${selmoUrl}/${this.restService}/${id}`, preparedData)
				this.updateRoute(data, history)

				if (orderType === ASSIGN_ORDER_TYPE_NEW) {
					dispatch(EditOrderFormActions.loadData(shop_order_id))
				}

				dispatch(this.modal.hideModal());

				dispatch(this.validation.deactivateValidation())
				dispatch(showAlert('yuhuLabel', 'orderHasBeenAssignedLabel'))
			} catch (e) {
				dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
				console.error('Error submitEditForm')
			} finally {
				dispatch(this.waiting.stopWaiting());
			}
		}
	}
}

export const getInstance = () => new AssignCustomerFormActions({
	getStateRoot,
	prefix,
	model: AssignCustomerModel,
	restService: services.API_NEW_ORDER,
	route: RouterPaths.OrdersList
});

export default getInstance();
