import React from 'react';
import useLang from "../../../../src_shared/hooks/useLang";
import {Link, useHistory} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import {useDispatch, useSelector} from "react-redux";
import OrderFiltersActions from '../../orders/filters/FiltersActions'
import {ALL_ORDERS_VIEW} from "../../shared/enums/OrderViewTypes";
import {STYLOWE_BUTY_SHOP_ID, TEST_ACCOUNT_SHOP_ID} from "../../../../src_shared/enums/TrustedShopsIds";

const OrdersStatsOptions = [
    {id: 0, label: 'statusToSendLabel', value: 'toSendOrders', icon: 'to-send', status: 2},
    {id: 1, label: 'statusNotPaidLabel', value: 'notPaidOrders', icon: 'unpaid', status: 5},
    {id: 2, label: 'statusOpenCartLabel', value: 'openPackageOrders', icon: 'open-pack', status: 8},
]

const OrdersBoxes = ({data}) => {
    const {getLangText} = useLang();
    const dispatch = useDispatch();
    const history = useHistory();
    const {userData} = useSelector((state) => state.session);

    const handleClick = (status) => {
        dispatch(OrderFiltersActions.data.updateValues({
            fromDate: null,
            toDate: null,
            viewType: ALL_ORDERS_VIEW,
            statuses: [status]
        }))
        history.push(RouterPaths.OrdersList);
    }

    return (
        <div>
            <div className="flex items-center mb-3 mt-10">
                <h2 className="grow text-xl font-bold">{getLangText('asideOrdersLabel')}</h2>
                <Link
                    className="text-[#3C61EA] text-sm font-bold"
                    to={RouterPaths.OrdersList}>
                    {getLangText('seeAllLabel')}
                    <i className="icon-arrows ml-1 text-[10px] inline-block -rotate-[90deg] hover:no-underline hover:text-[#]"/>
                </Link>
            </div>

            <div className="grid sm:grid-cols-3 gap-3 sm:gap-5">
                {OrdersStatsOptions.map((item) => (
                    <button
                        onClick={() => handleClick(item.status)}
                        key={item.id}
                        className="dashboard-card text-left hover:no-underline hover:scale-[102%] transition-all"
                    >
                        <div className="flex w-full relative">
                            <div className="grow">
                                <div className="text-[#1F2937] font-medium text-sm">{getLangText(item.label)}</div>
                                <div className="font-bold text-3xl mt-3 text-[#101827] grow">{data[item.value]}</div>
                            </div>
                            <img
                                className="sm:absolute right-0 bottom-0 sm:self-end w-[36px] sm:w-[28px] pointer-events-none"
                                width={28}
                                src={`/assets/images/svg/${item.icon}.svg`} alt="icon"/>
                        </div>
                    </button>
                ))}
                {(+userData.id === TEST_ACCOUNT_SHOP_ID || +userData.id === STYLOWE_BUTY_SHOP_ID) &&
                    <>
                        <button
                            onClick={() => handleClick(1)}
                            className="dashboard-card text-left hover:no-underline hover:scale-[102%] transition-all"
                        >
                            <div className="flex w-full relative">
                                <div className="grow">
                                    <div
                                        className="text-[#1F2937] font-medium text-sm">{getLangText('statusCreatedLabel')}</div>
                                    <div
                                        className="font-bold text-3xl mt-3 text-[#101827] grow">{data.createdOrders}</div>
                                </div>
                                <img
                                    className="sm:absolute right-0 bottom-0 sm:self-end w-[36px] sm:w-[28px] pointer-events-none"
                                    width={28}
                                    src={`/assets/images/svg/open-pack.svg`} alt="icon"/>
                            </div>
                        </button>
                        <button
                            onClick={() => handleClick(7)}
                            className="dashboard-card text-left hover:no-underline hover:scale-[102%] transition-all"
                        >
                            <div className="flex w-full relative">
                                <div className="grow">
                                    <div
                                        className="text-[#1F2937] font-medium text-sm">{getLangText('statusEditedLabel')}</div>
                                    <div
                                        className="font-bold text-3xl mt-3 text-[#101827] grow">{data.editedOrders}</div>
                                </div>
                                <img
                                    className="sm:absolute right-0 bottom-0 sm:self-end w-[36px] sm:w-[28px] pointer-events-none"
                                    width={28}
                                    src={`/assets/images/svg/open-pack.svg`} alt="icon"/>
                            </div>
                        </button>
                    </>
                }
            </div>
        </div>
    );
};

export default OrdersBoxes;
