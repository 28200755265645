import Model from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

class SettingsEupagoModel extends Model {
	getModel() {
		return {
			client_guid: '',
			nif: '',
			active: '0',
		};
	}

	getValidators() {
		return {
			active: ValidationError.skip,
			nif: ValidationError.notEmpty,
			client_guid: ValidationError.notEmpty,
		};
	}
}

export default new SettingsEupagoModel();
