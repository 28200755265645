import React, {useState} from 'react';
import SwitchField from "../../../../src_shared/components/form/switchField/SwitchField";
import {useModal} from "../../shared/hooks/useModal";
import Modal from "react-bootstrap/Modal";
import {FUNCTIONALITY_BENEFITS} from "../../shared/enums/FuncionalitiesBenefits";
import {LIVE_NOTIFICATION_PERMISSION} from "../../shared/enums/SelmoPermissions";
import useLang from "../../../../src_shared/hooks/useLang";
import {useUnitPrice} from "../../shared/helpers/Price";
import {useSelector} from "react-redux";

const Functionalities = ({values, setValue, className = ''}) => {

	const [openModal, setOpenModal] = useModal(false);
	const [selectedFunctionality, setSelectedFunctionality] = useState(null);
	const {getLangText} = useLang();
	const {getPrice} = useUnitPrice();
	const {lang} = useSelector((state) => state.i18n);

	const hideModal = () => setOpenModal(false);
	const showModal = (e, func) => {
		e.stopPropagation()
		e.preventDefault();
		setSelectedFunctionality(func)
		setOpenModal(true)
	}

	const functionalityInfo = FUNCTIONALITY_BENEFITS.find((i) => i.id === selectedFunctionality) || {};

	return (
		<div className={`select-boxes-wrapper ${className}`}>
			<div className="section-label">
				{getLangText('paymentsAddExtraServicesHeader')}
			</div>
			<div className="data-boxes-wrapper separated small-padd">
				{values.functionalities.filter((i) => i.selmo_name !== LIVE_NOTIFICATION_PERMISSION).map((item) => (
					<div
						key={item.id}
						className="content payment-content"
					>
						<div className="content__top-part">
							<SwitchField
								className="switch-form mr-2 align-self-start mb-0"
								setValue={setValue}
								showLabel={false}
								name={item.selmo_name}
								id={item.selmo_name}
								value={values[item.selmo_name]}
							/>
							<div className="flex-grow-1">
								<div className="label">{item.name}</div>
								<div className="data">{item.description}</div>
								<button
									onClick={(e) => showModal(e, item.selmo_name)}
									type="button"
									className="button text-only"
								>
									{getLangText('paymentsHowItWorksLabel')}
								</button>
							</div>
							<div className="price align-self-start text-nowrap">
								{getPrice(item.price, values.currencyText)}
							</div>
						</div>
					</div>
				))}
			</div>
			{openModal &&
				<Modal
					dialogClassName="default-modal"
					show={openModal}
					onHide={hideModal}
				>
					<Modal.Header className="with-border">
						<Modal.Title>
							{getLangText(functionalityInfo.title) || functionalityInfo.title}
						</Modal.Title>
						<button
							onClick={hideModal}
							type="button"
							className="button"
						>
							<i className="icon-cross"/>
						</button>
					</Modal.Header>
					<Modal.Body className="payment-benefits-wrapper">
						<div className="label">{getLangText('paymentsHowItWorksLabel')}</div>
						<ul className="benefits-list">
							{functionalityInfo?.benefits?.map((i) => (
								<li key={i.id}>
									<i className="icon-tick-c"/>
									{getLangText(i) || i}
								</li>
							))}
						</ul>
						{!!functionalityInfo.media &&
							<figure>
								{functionalityInfo.video ?
									<video controls playsInline autoPlay
										   src={`/assets/images/multiLanguage/${lang}/${functionalityInfo.media}`}/>
									:
									<img style={{width: '100%'}} alt="media" src={`/assets/images/multiLanguage/${lang}/${functionalityInfo.media}`}/>
								}
							</figure>
						}
					</Modal.Body>
				</Modal>
			}
		</div>
	);
};

export default Functionalities;
