import ListReducer from "../../../../../src_shared/list/ListReducer";
import Composition from "../../../../app/shared/helpers/Composition";
import createReducer from "../../../../app/shared/helpers/createReducer";

export const getStateRoot = (state) => state.settings.automations.automaticReserveList.stats;
export const prefix = 'SETTINGS_AUTOMATIONS_AUTOMATIC_RESERVE_LIST';

const getInitState = () => ListReducer.getInitState();

const getReduceMap = () => new Composition(
	ListReducer.getReduceMap(),
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

