import React, {useEffect} from 'react';

const Alert = ({showAlert, setShowAlert}) => {

    useEffect(() => {
        if (showAlert.visible) {
            const timeout = setTimeout(() => {
                setShowAlert({
                    visible: false,
                    data: {},
                })
            }, 1500)
            return () => clearTimeout(timeout)
        }
    }, [showAlert.visible])

    return (
        <div
            className={`absolute w-full h-full flex-col flex items-center justify-center animate-[fadeIn_.1s_ease_forwards] z-10 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 p-3 text-center ${showAlert.data.id === 'SUCCESS' ? 'bg-[#EFFEF4] text-[#3CD9A0] border-[#3CD9A0]' : 'bg-[#FEF2F2] text-[#981B1B] border-[#F87171]'} border`}>
            <img
                className="w-[22px] min-w-[22px] mb-1 mx-auto"
                src={`/assets/images/svg/${showAlert.data.icon}.svg`}
                alt="check"
            />
            <div
                className={`text-[18px] font-bold ${showAlert.data.id === 'SUCCESS' ? 'text-[#156534]' : ''}`}>{showAlert.data.title}</div>
            <div
                className={`text-sm font-medium ${showAlert.data.id === 'SUCCESS' ? 'text-[#419860]' : 'text-[#B91F1D]'}`}
                dangerouslySetInnerHTML={{__html: showAlert.data.desc}}
            />
        </div>
    );
};

export default Alert;
