import ListReducer from "../../../../../../src_shared/list/ListReducer.js";
import createReducer from "../../../../shared/helpers/createReducer.js";
import Composition from "../../../../shared/helpers/Composition.js";

export const getStateRoot = (state) => state.lives.newComments;
export const prefix = 'LIVES_DETAILS_NEW_COMMENTS_';

const getInitState = () => ListReducer.getInitState({
	itemsPerPage: 20,
	scrollPage: 1,
	hasMore: true,
	commentsPanelVisible: false,
	scrollTouched: false,
});

const getReduceMap = () => new Composition(
	ListReducer.getReduceMap(),
	{
		SET_SCROLL_PAGE: (state, action) => ({...state, scrollPage: action.scrollPage}),
	},
	{
		SET_HAS_MORE_PAGES: (state, action) => ({...state, hasMore: action.toggle}),
	},
	{
		SHOW_COMMENTS_PANEL: (state, action) => ({...state, commentsPanelVisible: action.toggle}),
	},
	{
		SET_SCROLL_TOUCHED: (state, action) => ({...state, scrollTouched: action.toggle}),
	},
	{
		SET_EXTRA_FIELD: (state, action) => ({...state, [action.field]: action.value}),
	},
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

