import React from 'react';
import useLang from "../../../../../../src_shared/hooks/useLang";
import Loader from "../../../../../../src_shared/components/loader/Loader";
import {Scanner} from "@yudiel/react-qr-scanner";
import QrScanner from "../../../../shared/components/QRScanner/QRScanner";

const QrCodeView = ({setSelectView, handleResult, isWaiting}) => {
    const {getLangText} = useLang();

    return (
        <div className="-mx-[25px] h-[calc(100%+3rem)] flex flex-col">
            {isWaiting ?
                <Loader className="static-loader small-laoder" isLoading/> :
                <>
                    <div className="px-6 mb-3">
                        <button
                            type="button"
                            onClick={setSelectView}
                            className="button back-to-panels"
                        >
                            <i
                                style={{background: '#f4f4f4'}}
                                className="icon-arrow3"
                            />
                            {getLangText('backButton')}
                        </button>
                    </div>
                    <div className="completing-scanner padd-border">
                        <QrScanner height="45vh" handleResult={handleResult}/>
                    </div>
                </>
            }
        </div>
    );
};

export default QrCodeView;
