import React from 'react';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Group from "../group/Group";
import '@ckeditor/ckeditor5-build-classic/build/translations/pl';
import '@ckeditor/ckeditor5-build-classic/build/translations/it';
import '@ckeditor/ckeditor5-build-classic/build/translations/fr';
import {useSelector} from "react-redux";
import Cookies from 'js-cookie'

const Ckeditor = (props) => {
	const {userData} = useSelector((state) => state.session);

	const {
		name,
		setValue,
		value,
		disabled,
		extraProps,
		placeholder,
		customConfig = custom_config,
	} = props;

	return (
		<Group {...props}>
			<CKEditor
				editor={ClassicEditor}
				data={value}
				onChange={(event, editor) => setValue(name, editor.getData())}
				disabled={disabled}
				config={{...customConfig, language: userData.origin}}
				placeholder={placeholder}
				{...extraProps}
			/>
		</Group>
	);
};

export const custom_config = {
	extraPlugins: [MyCustomUploadAdapterPlugin],
	toolbar: {
		items: [
			'heading',
			'|',
			'bold',
			'italic',
			'link',
			'bulletedList',
			'numberedList',
			'|',
			'blockQuote',
			'insertTable',
			'|',
			'imageUpload',
			'mediaEmbed',

			'undo',
			'redo'
		]
	},
	link: {
		addTargetToExternalLinks: true,
		defaultTarget: '_blank'
	},
	mediaEmbed: {
		previewsInData: true,
		// providers: [
		// 	{
		// 		name: "embedly",
		// 		url: [/^youtube\.com\//, /^facebook\.com\/[A-Za-z]+\/videos\/[0-9]+/i],
		// 		html: match => console.log(match) || '<div style="position:relative; padding-bottom: 56.2493%; height:0">' +
		// 			'<iframe src="https://www.youtube.com/embed/' + match[0].split('?v=')[1] + '?rel=0" frameborder="0" ' +
		// 			'style="position:absolute; width:100%; height:100%; top:0; left:0">' +
		// 			'</iframe>' +
		// 			'</div>'
		// 	}
		// ]
	},
	table: {
		contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
	},
}

function MyCustomUploadAdapterPlugin(editor) {
	editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
		return new MyUploadAdapter(loader)
	}
}

class MyUploadAdapter {
	constructor(props) {
		// CKEditor 5's FileLoader instance.
		this.loader = props;
		// URL where to send files.
		this.url = `https://app.selmo.io/api/mediaCkEditor`;
	}

	upload() {
		return new Promise((resolve, reject) => {
			this._initRequest();
			this._initListeners(resolve, reject);
			this._sendRequest();
		});
	}

	abort() {
		if (this.xhr) {
			this.xhr.abort();
		}
	}

	_initRequest() {
		const xhr = this.xhr = new XMLHttpRequest();

		xhr.open('POST', this.url, true);
		xhr.setRequestHeader('Authorization', `Bearer ${Cookies.get('token')}`);
		xhr.responseType = 'json';
	}

	// Initializes XMLHttpRequest listeners.
	_initListeners(resolve, reject) {
		const xhr = this.xhr;
		const loader = this.loader;
		const genericErrorText = 'Couldn\'t upload file:' + ` ${loader.file.name}.`;

		xhr.addEventListener('error', () => reject(genericErrorText));
		xhr.addEventListener('abort', () => reject());
		xhr.addEventListener('load', () => {
			const response = xhr.response;
			if (!response || response.error) {
				return reject(response && response.error ? response.error.message : genericErrorText);
			}

			resolve({
				default: `https://app.selmo.io${response.path}`
			});
		});

		if (xhr.upload) {
			xhr.upload.addEventListener('progress', evt => {
				if (evt.lengthComputable) {
					loader.uploadTotal = evt.total;
					loader.uploaded = evt.loaded;
				}
			});
		}
	}

	// Prepares the data and sends the request.
	_sendRequest() {
		const data = new FormData();

		this.loader.file.then(result => {
				data.append('photo', result);
				data.append('path', 'ckeditor')
				data.append('name', result.name)
				this.xhr.send(data);
			}
		)
	}

}


export default Ckeditor;
