import {useEffect, useState} from "react";

const useFormValues = (defaultValues = {}) => {
	const [values, setValues] = useState(defaultValues);

	const setValue = (field, value) => setValues({...values, [field]: value})

	const getFieldProps = (source) => {

		return {
			name: source,
			value: values[source],
			// getError: validateErrors[source],
			validationData: values,
			setValue,
		};
	};

	useEffect(() => {
		setValues(defaultValues)
	}, [defaultValues])

	return [{values, setValue, getFieldProps}];
};

export default useFormValues;
