import CopyButton from "../../../../src_shared/components/form/copyButton/CopyButton";
import React, {useContext} from "react";
import FormActions from "./FormActions.js";
import useForm from "../../../../src_shared/form/useForm.js";
import {Helmet} from "react-helmet";
import useLang from "../../../../src_shared/hooks/useLang";
import Loader from "../../shared/Loader";
import ShopNameBar from "../../../routes/shopSettingsRoute/ShopNameBar";
import {useDispatch, useSelector} from "react-redux";
import {ShopSettingsContext} from "../../../routes/shopSettingsRoute/ShopSettingsRoute";
import {SHOP_ORIGIN_PL} from "../../shared/enums/ShopOrigins";

const ShopDomain = () => {
	const {getLangText} = useLang();
	const dispatch = useDispatch();
	const {getShopData, setIsChanging} = useContext(ShopSettingsContext);
	const {userData} = useSelector((state) => state.session);

	const {
		data: {values, setValue},
		form: {isWaiting},
	} = useForm({
		FormActions,
		params: {},
		requireParentId: false
	});

	const {shopData} = useContext(ShopSettingsContext);
	const shopLink = `https://${shopData.domain || shopData.full_url}/${userData.origin !== SHOP_ORIGIN_PL ? `${userData.origin}/` : ''}`
	const shopLinkToDisplay = `https://${shopData.domain || shopData.full_url}/`

	const onSave = () => dispatch(FormActions.submitEditForm(values, () => {
		getShopData();
		setIsChanging(new Date());
	}))

	return (
		<>
			<ShopNameBar/>
			<div className="settings-page sub-settings-page loader-parent">
				<Helmet>
					<meta charSet="utf-8"/>
					<title>{getLangText('shopGeneralSettingsLabel')}Selmo</title>
				</Helmet>
				<div className="settings-form loader-parent">
					<div className="section-header">
						<h1>{getLangText('domainSettingsLabel')}</h1>
						<div className="desc">{getLangText('manageAddressShopLabel')}</div>
					</div>
					<Loader isLoading={isWaiting}/>
					<section>
						<div className="left-side">
							<h2 className="label">{getLangText('currentShopAddressLabel')}</h2>
						</div>
						<div className="form-col">
							<div>
								<div className="form-group with-copy-btn with-preview overflow-visible">
									<div
										className="form-label bigger">{getLangText('yourCurrentShopAddressLabel')}</div>
									<div className="form-control grey-bg">
										<a
											href={shopLink}
											target="_blank"
											className="short-link"
										>
											{shopLinkToDisplay}
										</a>
										<CopyButton
											copyText={shopLink}
										/>
										<a
											href={shopLink}
											className="button border-button small-size ml-sm-1 mb-0 d-lg-flex d-none"
											target="_blank"
										>
											<i className="icon-eye"/>
											{getLangText('previewButton')}
										</a>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section>
						<div className="left-side">
							<h2 className="label">{getLangText('howToChangeShopAddressLabel')}</h2>
						</div>
						<div className="form-col">
							<div className="form-group code-form overflow-visible">
								<div className="form-label bigger">
									{getLangText('enterYourShopAddressLabel')}
								</div>
								<div className="input-with-prefix with-save-button">
									<input
										onChange={(e) => setValue('url', e.target.value)}
										value={values.url}
										type="text"
										className="form-control"
									/>
									<button
										type="button"
										onClick={onSave}
										className="button primary small-size ml-sm-auto top-0"
									>
										{getLangText('saveButton')}
									</button>
								</div>
							</div>
						</div>
					</section>
					<section>
						<div className="left-side">
							<h2 className="label">{getLangText('howToSetUpOwnDomainLabel')}</h2>
						</div>
						<div className="form-col">
							<div className="form-info-box w-100 mb-3">
								<div>
									<i className="icon-info-c"/>
								</div>
								<div>
									<div className="title">
										{getLangText('setUpCustomShopAddressLabel')}
									</div>
									<div className="desc">{getLangText('configureOwnDomainLabel')}</div>
								</div>
							</div>
							<div className="steps-wrapper">
								<div className="step border-step">
									<div className="step-header">
										<div className="number align-self-start">
											1
										</div>
										<div className="flex-grow-1 mt-1">
											<div className="title d-block font-weight-normal mb-3"
												 dangerouslySetInnerHTML={{__html: getLangText('enterYourDomainLabel')}}/>

											<div className="form-group code-form" style={{maxWidth: 'none'}}>
												<div className="input-with-prefix with-save-button">
													<input
														onChange={(e) => setValue('domain', e.target.value)}
														value={values.domain}
														placeholder={getLangText('enterYourDomainNamePlaceholder')}
														type="text"
														className="form-control"
													/>
													<button
														type="button"
														onClick={onSave}
														className="button primary ml-sm-auto top-0"
													>
														{getLangText('saveButton')}
													</button>
												</div>
											</div>
											<div className="info-text grey mt-2 align-items-sm-center align-items-start">
												<i className="icon-alert-c"/>
												<div>
													<div
														className="d-inline"
														dangerouslySetInnerHTML={{__html: getLangText('dontHaveDomainLabel')}}/>
													<a target="_blank" href="https://dhosting.pl/pp-selmo">{getLangText('hereLabel')}</a>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="step border-step mt-3">
									<div className="step-header">
										<div className="number align-self-start">
											2
										</div>
										<div className="flex-grow-1 mt-1">
											<div className="title d-block font-weight-normal"
												 dangerouslySetInnerHTML={{__html: getLangText('goToDomainAndAddNewNDNSLabel')}}/>
											<div className="sub-title mb-3">
												{getLangText('itCanBeLookLikeDNSLabel')}
											</div>
										</div>
									</div>
								</div>
								<div className="step border-step mt-3">
									<div className="step-header">
										<div className="number align-self-start">
											3
										</div>
										<div className="flex-grow-1">
											<div className="title d-block font-weight-normal mt-1"
												 dangerouslySetInnerHTML={{__html: getLangText('createOneEntryDNSLabel')}}/>
											<div className="sub-title mb-3">
												{getLangText('copyAndEntryDataLabel')}
											</div>
											<div className="standard-transfer__bottom">
												<div className="standard-transfer-box">
													<div className="flex-grow-1">
														<div className="top-text">{getLangText('typeLabel')}</div>
														<div className="bottom-text">A</div>
													</div>
												</div>
												<div className="standard-transfer-box">
													<div className="flex-grow-1">
														<div className="top-text">{getLangText('nameLabel')}</div>
														<div className="bottom-text">@</div>
													</div>
													<div>
														<CopyButton
															copyText="@"
															copyButtonText={getLangText('copyButton')}
															copiedButtonText={getLangText('copyButton')}
															buttonClassName="btn-copy copied border-button"
														/>
													</div>
												</div>
												<div className="standard-transfer-box">
													<div className="flex-grow-1">
														<div className="top-text">{getLangText('valueLabel')}</div>
														<div className="bottom-text">76.76.21.21</div>
													</div>
													<div>
														<CopyButton
															copyText="76.76.21.21"
															copyButtonText={getLangText('copyButton')}
															copiedButtonText={getLangText('copyButton')}
															buttonClassName="btn-copy copied border-button"
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="step border-step mt-3">
									<div className="step-header">
										<div className="number align-self-start">
											4
										</div>
										<div className="flex-grow-1">
											<div className="title d-block font-weight-normal mt-1"
												 dangerouslySetInnerHTML={{__html: getLangText('createTwoEntryDNSLabel')}}/>
											<div className="sub-title mb-3">
												{getLangText('copyAndEntryDataLabel')}
											</div>
											<div className="standard-transfer__bottom">
												<div className="standard-transfer-box">
													<div className="flex-grow-1">
														<div className="top-text">{getLangText('typeLabel')}</div>
														<div className="bottom-text">CNAME</div>
													</div>
												</div>
												<div className="standard-transfer-box">
													<div className="flex-grow-1">
														<div className="top-text">{getLangText('nameLabel')}</div>
														<div className="bottom-text">www</div>
													</div>
													<div>
														<CopyButton
															copyText="www"
															copyButtonText={getLangText('copyButton')}
															copiedButtonText={getLangText('copyButton')}
															buttonClassName="btn-copy copied border-button"
														/>
													</div>
												</div>
												<div className="standard-transfer-box">
													<div className="flex-grow-1">
														<div className="top-text">{getLangText('valueLabel')}</div>
														<div className="bottom-text">cname.vercel-dns.com.</div>
													</div>
													<div>
														<CopyButton
															copyText="cname.vercel-dns.com."
															copyButtonText={getLangText('copyButton')}
															copiedButtonText={getLangText('copyButton')}
															buttonClassName="btn-copy copied border-button"
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="form-info-box align-items-center w-100 mt-3">
								<div>
									<i className="icon-info-c"/>
								</div>
								<div className="title">
									{getLangText('canBeActiveAfter24HoursLabel')}
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
			{/*<div className="settings-page sub-settings-page loader-parent mt-sm-3">*/}
			{/*	<div className="settings-form loader-parent">*/}
			{/*		<section>*/}
			{/*			<div className="left-side">*/}
			{/*				<h2 className="label">{getLangText('howToChangeShopAddressLabel')}</h2>*/}
			{/*			</div>*/}
			{/*			<div className="form-col">*/}
			{/*				<div className="form-group code-form overflow-visible">*/}
			{/*					<div className="form-label bigger">*/}
			{/*						{getLangText('enterYourShopAddressLabel')}*/}
			{/*					</div>*/}
			{/*					<div className="input-with-prefix with-save-button">*/}
			{/*						<input*/}
			{/*							onChange={(e) => setValue('url', e.target.value)}*/}
			{/*							value={values.url}*/}
			{/*							type="text"*/}
			{/*							className="form-control"*/}
			{/*						/>*/}
			{/*						<button*/}
			{/*							type="button"*/}
			{/*							onClick={onSave}*/}
			{/*							className="button primary small-size ml-sm-auto top-0"*/}
			{/*						>*/}
			{/*							{getLangText('saveButton')}*/}
			{/*						</button>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*		</section>*/}
			{/*	</div>*/}
			{/*</div>*/}
			{/*<div className="settings-page sub-settings-page loader-parent mt-sm-3">*/}
			{/*	<div className="settings-form loader-parent">*/}
			{/*		<section>*/}
			{/*			<div className="left-side">*/}
			{/*				<h2 className="label">{getLangText('howToSetUpOwnDomainLabel')}</h2>*/}
			{/*			</div>*/}
			{/*			<div className="form-col">*/}
			{/*				<div className="form-info-box w-100 mb-3">*/}
			{/*					<div>*/}
			{/*						<i className="icon-info-c"/>*/}
			{/*					</div>*/}
			{/*					<div>*/}
			{/*						<div className="title">*/}
			{/*							{getLangText('setUpCustomShopAddressLabel')}*/}
			{/*						</div>*/}
			{/*						<div className="desc">{getLangText('configureOwnDomainLabel')}</div>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*				<div className="steps-wrapper">*/}
			{/*					<div className="step border-step">*/}
			{/*						<div className="step-header">*/}
			{/*							<div className="number align-self-start">*/}
			{/*								1*/}
			{/*							</div>*/}
			{/*							<div className="flex-grow-1 mt-1">*/}
			{/*								<div className="title d-block font-weight-normal mb-3"*/}
			{/*									 dangerouslySetInnerHTML={{__html: getLangText('enterYourDomainLabel')}}/>*/}

			{/*								<div className="form-group code-form" style={{maxWidth: 'none'}}>*/}
			{/*									<div className="input-with-prefix with-save-button">*/}
			{/*										<input*/}
			{/*											onChange={(e) => setValue('domain', e.target.value)}*/}
			{/*											value={values.domain}*/}
			{/*											placeholder={getLangText('enterYourDomainNamePlaceholder')}*/}
			{/*											type="text"*/}
			{/*											className="form-control"*/}
			{/*										/>*/}
			{/*										<button*/}
			{/*											type="button"*/}
			{/*											onClick={onSave}*/}
			{/*											className="button primary ml-sm-auto top-0"*/}
			{/*										>*/}
			{/*											{getLangText('saveButton')}*/}
			{/*										</button>*/}
			{/*									</div>*/}
			{/*								</div>*/}
			{/*								<div className="info-text grey mt-2 align-items-sm-center align-items-start">*/}
			{/*									<i className="icon-alert-c"/>*/}
			{/*									<div>*/}
			{/*										<div*/}
			{/*											className="d-inline"*/}
			{/*											dangerouslySetInnerHTML={{__html: getLangText('dontHaveDomainLabel')}}/>*/}
			{/*										<a href="#">{getLangText('hereLabel')}</a>*/}
			{/*									</div>*/}
			{/*								</div>*/}
			{/*							</div>*/}
			{/*						</div>*/}
			{/*					</div>*/}
			{/*					<div className="step border-step mt-3">*/}
			{/*						<div className="step-header">*/}
			{/*							<div className="number align-self-start">*/}
			{/*								2*/}
			{/*							</div>*/}
			{/*							<div className="flex-grow-1 mt-1">*/}
			{/*								<div className="title d-block font-weight-normal"*/}
			{/*									 dangerouslySetInnerHTML={{__html: getLangText('goToDomainAndAddNewNDNSLabel')}}/>*/}
			{/*								<div className="sub-title mb-3">*/}
			{/*									{getLangText('itCanBeLookLikeDNSLabel')}*/}
			{/*								</div>*/}
			{/*							</div>*/}
			{/*						</div>*/}
			{/*					</div>*/}
			{/*					<div className="step border-step mt-3">*/}
			{/*						<div className="step-header">*/}
			{/*							<div className="number align-self-start">*/}
			{/*								3*/}
			{/*							</div>*/}
			{/*							<div className="flex-grow-1">*/}
			{/*								<div className="title d-block font-weight-normal mt-1"*/}
			{/*									 dangerouslySetInnerHTML={{__html: getLangText('createOneEntryDNSLabel')}}/>*/}
			{/*								<div className="sub-title mb-3">*/}
			{/*									{getLangText('copyAndEntryDataLabel')}*/}
			{/*								</div>*/}
			{/*								<div className="standard-transfer__bottom">*/}
			{/*									<div className="standard-transfer-box">*/}
			{/*										<div className="flex-grow-1">*/}
			{/*											<div className="top-text">{getLangText('typeLabel')}</div>*/}
			{/*											<div className="bottom-text">A</div>*/}
			{/*										</div>*/}
			{/*									</div>*/}
			{/*									<div className="standard-transfer-box">*/}
			{/*										<div className="flex-grow-1">*/}
			{/*											<div className="top-text">{getLangText('nameLabel')}</div>*/}
			{/*											<div className="bottom-text">@</div>*/}
			{/*										</div>*/}
			{/*										<div>*/}
			{/*											<CopyButton*/}
			{/*												copyText="@"*/}
			{/*												copyButtonText={getLangText('copyButton')}*/}
			{/*												copiedButtonText={getLangText('copyButton')}*/}
			{/*												buttonClassName="btn-copy copied border-button"*/}
			{/*											/>*/}
			{/*										</div>*/}
			{/*									</div>*/}
			{/*									<div className="standard-transfer-box">*/}
			{/*										<div className="flex-grow-1">*/}
			{/*											<div className="top-text">{getLangText('valueLabel')}</div>*/}
			{/*											<div className="bottom-text">76.76.21.21</div>*/}
			{/*										</div>*/}
			{/*										<div>*/}
			{/*											<CopyButton*/}
			{/*												copyText="76.76.21.21"*/}
			{/*												copyButtonText={getLangText('copyButton')}*/}
			{/*												copiedButtonText={getLangText('copyButton')}*/}
			{/*												buttonClassName="btn-copy copied border-button"*/}
			{/*											/>*/}
			{/*										</div>*/}
			{/*									</div>*/}
			{/*								</div>*/}
			{/*							</div>*/}
			{/*						</div>*/}
			{/*					</div>*/}
			{/*					<div className="step border-step mt-3">*/}
			{/*						<div className="step-header">*/}
			{/*							<div className="number align-self-start">*/}
			{/*								4*/}
			{/*							</div>*/}
			{/*							<div className="flex-grow-1">*/}
			{/*								<div className="title d-block font-weight-normal mt-1"*/}
			{/*									 dangerouslySetInnerHTML={{__html: getLangText('createTwoEntryDNSLabel')}}/>*/}
			{/*								<div className="sub-title mb-3">*/}
			{/*									{getLangText('copyAndEntryDataLabel')}*/}
			{/*								</div>*/}
			{/*								<div className="standard-transfer__bottom">*/}
			{/*									<div className="standard-transfer-box">*/}
			{/*										<div className="flex-grow-1">*/}
			{/*											<div className="top-text">{getLangText('typeLabel')}</div>*/}
			{/*											<div className="bottom-text">CNAME</div>*/}
			{/*										</div>*/}
			{/*									</div>*/}
			{/*									<div className="standard-transfer-box">*/}
			{/*										<div className="flex-grow-1">*/}
			{/*											<div className="top-text">{getLangText('nameLabel')}</div>*/}
			{/*											<div className="bottom-text">www</div>*/}
			{/*										</div>*/}
			{/*										<div>*/}
			{/*											<CopyButton*/}
			{/*												copyText="www"*/}
			{/*												copyButtonText={getLangText('copyButton')}*/}
			{/*												copiedButtonText={getLangText('copyButton')}*/}
			{/*												buttonClassName="btn-copy copied border-button"*/}
			{/*											/>*/}
			{/*										</div>*/}
			{/*									</div>*/}
			{/*									<div className="standard-transfer-box">*/}
			{/*										<div className="flex-grow-1">*/}
			{/*											<div className="top-text">{getLangText('valueLabel')}</div>*/}
			{/*											<div className="bottom-text">cname.vercel-dns.com.</div>*/}
			{/*										</div>*/}
			{/*										<div>*/}
			{/*											<CopyButton*/}
			{/*												copyText="cname.vercel-dns.com."*/}
			{/*												copyButtonText={getLangText('copyButton')}*/}
			{/*												copiedButtonText={getLangText('copyButton')}*/}
			{/*												buttonClassName="btn-copy copied border-button"*/}
			{/*											/>*/}
			{/*										</div>*/}
			{/*									</div>*/}
			{/*								</div>*/}
			{/*							</div>*/}
			{/*						</div>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*				<div className="form-info-box align-items-center w-100 mt-3">*/}
			{/*					<div>*/}
			{/*						<i className="icon-info-c"/>*/}
			{/*					</div>*/}
			{/*					<div className="title">*/}
			{/*						{getLangText('canBeActiveAfter24HoursLabel')}*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*		</section>*/}
			{/*	</div>*/}
			{/*</div>*/}
		</>
	);
};

export default ShopDomain;
