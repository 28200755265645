import React from 'react';
import Modal from "react-bootstrap/Modal";
import Loader from "../../../components/app/messenger/loader/Loader";
import useLang from "../../hooks/useLang";

const ConfirmRemoveModal = ({title, subTitle, children, hide, show, confirm, isWaiting, confirmButton = 'Usuń', dialogClassName = '', confirmButtonClassName = 'danger'}) => {
	const {getLangText} = useLang();
	return (
		<Modal
			dialogClassName={`default-modal ${dialogClassName}`}
			show={show}
			onHide={hide}
		>
			<Modal.Header>
				<Modal.Title>
					<div className="font-bold sm:text-[18px] sm:leading-[24px] mb-1.5">{title}</div>
					<div className="sub-title" dangerouslySetInnerHTML={{__html: subTitle}} />
				</Modal.Title>
				<button
					onClick={hide}
					type="button"
					className="button"
				>
					<i className="icon-cross"/>
				</button>
			</Modal.Header>
			<Modal.Body>{children}</Modal.Body>
			<Modal.Footer>
				<button
					onClick={hide}
					type="button"
					className="button border-button"
				>
					{getLangText('cancelButton')}
				</button>
				<button
					onClick={confirm}
					type="button"
					className={`button ${confirmButtonClassName} ${isWaiting ? 'loading-button' : ''}`}
				>
					{isWaiting && <Loader />}
					{confirmButton}
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default ConfirmRemoveModal;
