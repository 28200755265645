import {getStateRoot, prefix} from './listReducer';
import {services} from "../../../../RestServices";
import ListActions from "../../../../../src_shared/list/ListActions";
import {RouterPaths} from "../../../../routes/RouterPath";
export const getInstance = () => new ListActions({
    getStateRoot,
    restService: services.API_SETTINGS_ORDER_CANCELLATION_ORDERS,
    pathname: RouterPaths.SettingsAutomationsOrderCancellationOrders,
    prefix,
});

export default getInstance();
