import React, {useEffect} from 'react';
import Field from "../../../../../src_shared/components/form/field/Field";
import useLang from "../../../../../src_shared/hooks/useLang";
import {SHOP_ORIGIN_FR, SHOP_ORIGIN_US} from "../../../shared/enums/ShopOrigins";

const PhoneInput = ({getFieldProps, lang, setValue, values}) => {
    const {getLangText} = useLang();

    useEffect(() => {
        // if (values.phone?.length <= 4 && lang === SHOP_ORIGIN_FR) setValue('phone', '+33 ')
    }, [lang, values.phone])

    return (
        <>
            <Field
                {...getFieldProps('phone')}
                label={getLangText('contactNumberLabel')}
                placeholder={getLangText('typeYourPhoneNumberLabel')}
                className="big-size mt-8 mb-2"
                extraProps={{
                    required: true,
                }}
            />
            {lang === SHOP_ORIGIN_FR &&
                <div className="form-info-box small-line-height w-100">
                    <div>
                        <i className="icon-info-c"/>
                    </div>
                    <div>
                        <div className="desc font-medium">
                            N'oubliez pas d'ajouter l'indicatif international <strong>+33</strong>&nbsp;(France)
                            ou <strong>+32</strong>&nbsp;(Belgique) sans le "0"
                            devant votre numéro.
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default PhoneInput;
