import React from 'react';
import {
    labelStyle
} from "../../stats/charts/chartTypes/IncomesChart";
import useLang from "../../../../src_shared/hooks/useLang";
import moment from "moment";
import Chart from "react-apexcharts";
import {convertToUserTimeZone} from "../../shared/helpers/dateHelpers";

const OrdersChart = ({data}) => {
    const {getLangText} = useLang();

    const options = {
        chart: {
            id: "basic-bar",
            toolbar: {
                show: false,
            }
        },
        xaxis: {
            categories: data.map((i, index) => convertToUserTimeZone().weekday(index).format('ddd')),
            labels: {
                ...labelStyle,
                hideOverlappingLabels: true,
            },
            tooltip: {
                enabled: false
            },
            axisTicks: {
                show: false,
            },
        },
        yaxis: {
            labels: {
                style: {
                    fontSize: '12px',
                    colors: '#9CA3AF',
                    fontFamily: 'Plus Jakarta Sans',
                },
                formatter: function(val) {
                    return val.toFixed(0);
                }
            }
        },
        grid: {
            borderColor: '#D1D5DB',
            strokeDashArray: 5,
        },
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 5,
                dataLabels: {
                    orientation: 'vertical',
                    position: 'center',
                    enable: false,
                    show: false,
                },
                columnWidth: 35,
            },
        },
        dataLabels: {
            enabled: false
        },
        colors: ['#1C4ED8', '#5FA5FA', '#90E8EE', '#3ee1f6'],
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'dark',
                type: "vertical",
                gradientToColors: undefined,
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                colorStops: [
                    {
                        offset: 0,
                        color: "#4090ea",
                        opacity: 1
                    },
                    {
                        offset: 50,
                        color: "#5FA5FA",
                        opacity: 1
                    },
                ]
            }
        },
        legend: {
            show: false
        },
        tooltip: {
            custom: function ({series, dataPointIndex, w}) {
                return `<div class="chart-tooltip">
<div class="date-label">
						${w.config.series[0].data[dataPointIndex].x}
						</div>
<div class="label">${getLangText('ordersLabel')}</div>
						<div class="value">${series[0][dataPointIndex]}</div>

</div>`
            }
        }
    };

    const series = [
        {
            name: getLangText('incomesLabel'),
            data: data.map((i, index) => ({
                y: Math.round(+i.count),
                x: convertToUserTimeZone().weekday(index).format('ddd'),
            })),
        },
    ]

    return (
        <div className="dashboard-card pb-2">
            <div className="flex items-center text-lg font-bold mb-3">
                {getLangText('ordersCountLabel')}
                <div className="ml-auto text-sm text-gray-400 font-medium">
                    {getLangText('lastThirtyDaysLabel')}
                </div>
            </div>
            <div className="chart-wrapper">
                {data.some((i) => !!+i.count) ?
                    <Chart
                        series={series}
                        options={options}
                        type="bar"
                        width="100%"
                        height={250}
                    /> :
                    <img className="w-full mt-4" src="/assets/images/empty/chart-2.svg" alt=""/>
                }
            </div>
        </div>
    );
};

export default OrdersChart;
