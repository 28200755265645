export const skip = () => false;
const postalCodeRegExp = /^\d{2}-\d{3}$/;

export const validateCVC = (cvc) => {
	if (!cvc) {
		return false;
	}

	return /^\d{3,4}$/.test(cvc);
}

export const isValidUrl = (string) => {
	const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$|^www\.[^\s/$.?#].[^\s]*$/;
	return urlPattern.test(string) ? false : 'incorrectValueWarning';
}

export const validateExpirationDate = (expirationDate) => {
	if (!expirationDate) {
		return false;
	}

	if (!/^\d{2}\/\d{2}$/.test(expirationDate)) {
		return false;
	}

	const [month, year] = expirationDate.split('/');

	if (parseInt(month, 10) < 1 || parseInt(month, 10) > 12) {
		return false;
	}

	const currentYear = new Date().getFullYear();
	return parseInt(year, 10) >= currentYear % 100;
}

export const validateCreditCardNumber = (cardNumber) => {
	cardNumber = cardNumber?.replace(/\s|-/g, "");

	if (cardNumber?.length !== 15 && cardNumber?.length !== 16) {
		return false;
	}

	if (!/^\d+$/.test(cardNumber)) {
		return false;
	}

	let sum = 0;
	let shouldDouble = cardNumber.length === 15 ? 1 : 0;

	for (let i = 0; i < cardNumber.length; i++) {
		let digit = parseInt(cardNumber.charAt(i));

		if ((i + shouldDouble) % 2 === 0) {
			digit *= 2;

			if (digit > 9) {
				digit -= 9;
			}
		}

		sum += digit;
	}

	return sum % 10 === 0;
}

export const notEmpty = (value) => {
	if ((!value && value !== 0 && value !== false) || (typeof value === 'string' && !value.trim())) {
		return 'fieldIsRequireLabel';
	}

	return false;
};

export const notFalse = (value) => {
	if (!+value && +value === 0) {
		return 'fieldIsRequireLabel';
	}

	return false;
};

export const maxLength = (max, warningLangKey = 'incorrectValueWarning') => (value) => {
	if (value.length > max) {
		return warningLangKey;
	}

	return false;
};

export const minLength = (min, warningLangKey = 'incorrectValueWarning') => (value) => {
	if (value.length < min) {
		return warningLangKey;
	}

	return false;
};


export const notEmptyInArray = (field) => (items) => {
	let result = false;

	items.toArray().forEach(item => {
		result = result || notEmpty(item[field]);
	});

	return result;
};

export const arrayNotEmpty = (array) => {
	const length = array.length;

	return !!length ? false : 'requiredWarning';
};

export const greaterThan = (number, warningLangKey = 'incorrectValueWarning') => (value) => (
	value > number ? false : warningLangKey
);

export const smallerThan = (number, warningLangKey = 'incorrectValueWarning') => (value) => (
	+value < +number ? false : warningLangKey
);

export const smallerOrEqualThan = (number, warningLangKey = 'incorrectValueWarning') => (value) => (
	+value <= +number ? false : warningLangKey
);

export const greaterThanField = (
	field,
	warningLangKey = 'incorrectValueWarning'
) => (value, data) => {
	const fieldValue = data[field];

	return !(value >= fieldValue) && warningLangKey;
};

export const notEqualToField = (
	field,
	warningLangKey = 'incorrectValueWarning'
) => (value, data) => {
	const fieldValue = data.get(field);

	return !(value !== fieldValue) && warningLangKey;
};

export const validateEmail = value => {
	const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return !value || emailRegExp?.test(value) ? false : 'incorrectAddressEmailWarning';
};

export const validatePhone = value => {
	const phoneRegExp = /^[+]{0,1}[1-9]{1}[0-9]{8,12}$/;
	return !value || phoneRegExp?.test(value) ? false : 'incorrectPhoneWarning';
};

export const combine = (...validators) => (value, data) => {
	let result;

	validators.forEach((validator) => {
		result = result || validator(value, data);
	});
	return result;
};

export const validatePassword = (password) => {
	const passwordRegExp = /^(?=.*[A-Z])(?=.*\d).{8,}$/;
	return !password || passwordRegExp?.test(password) ? false : 'notValidPasswordLabel'
};

export const validateRepeatPassword = (repeatPassword, data) => {
	if (!repeatPassword) {
		return notEmpty(repeatPassword);
	}

	return data?.newPassword === repeatPassword
		? false
		: 'settings_form_validation_text_2';
};

export const validateZipCode = (value) => {
	const trimmed = typeof value === 'string' ? value?.trim() : value;

	return !trimmed || postalCodeRegExp?.test(trimmed) ? false : 'incorrectZipCodeWarning';
};

export const validateNip = (value) => (value?.match(/^[0-9]{10}$/g) ? false : 'incorrectNipWarning');


export default {
	skip,
	notEmpty,
	notFalse,
	maxLength,
	minLength,
	notEmptyInArray,
	arrayNotEmpty,
	greaterThanField,
	notEqualToField,
	validateEmail,
	validatePhone,
	combine,
	validatePassword,
	validateRepeatPassword,
	greaterThan,
	smallerThan,
	smallerOrEqualThan,
	validateZipCode,
	validateNip,
	isValidUrl,
};
