import React, {useEffect, useState} from 'react';
import axios from "axios";
import {useDispatch} from "react-redux";
import {selmoUrl} from "../../../../api/api";
import useStatusFilters from "../../../../hooks/useStatusFilters";
import useLang from "../../../../hooks/useLang";
import Loader from "../../../loader/Loader";
import api from "../../../../../services/axios/axios";

const AsyncPanelFilter = ({
                              ListActions,
                              FiltersActions,
                              getFiltersStateRoot,
                              name,
                              selectAllName,
                              rest,
                              optionLabel,
                              optionsParser,
                              FormActions,
                              getStateRoot,
                          }) => {

    const [options, setOptions] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const {getLangText} = useLang();

    const {
        statuses,
        isSelectAll,
        handleCheckBoxClick,
        allItemsClick,
        getCheckboxValue,
    } = useStatusFilters(
        ListActions,
        getFiltersStateRoot,
        FiltersActions,
        name,
        selectAllName,
        options,
        FormActions,
        getStateRoot,
    );

    const preparedOption = (items) => {
        const data = optionsParser(items);
        setOptions(data);
    }

    const getOptions = async () => {
        setLoading(true)
        try {
            const {data} = await api.get(`${selmoUrl}/${rest}`)
            preparedOption(data.items)
            if (data.items.length === statuses.length) {
                dispatch(FormActions.data.setValue(selectAllName, true))
            }
        } catch (e) {
            console.warn('Fail get async filters statuses')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getOptions();
    }, [])

    return (
        <div className="loader-parent min-height-auto">
            {isLoading ?
                <Loader
                    className="small-loader static-loader py-3.5"
                    isLoading
                /> :
                <div className="checkbox-filter-wrapper">
                    <div className="form-group checkbox-form">
                        <input
                            className="checkbox-input form-control"
                            type="checkbox"
                            onChange={allItemsClick}
                            checked={isSelectAll}
                        />
                        <span className="checkbox-checkmark"/>
                        <label className="control-label">{getLangText('allLabel')}</label>
                    </div>
                    {options.map((item) => (
                        <div
                            key={item.value}
                            className="form-group checkbox-form"
                        >
                            <input
                                className="checkbox-input form-control"
                                type="checkbox"
                                onChange={() => handleCheckBoxClick(item.value)}
                                checked={getCheckboxValue(item.value)}
                            />
                            <span className="checkbox-checkmark"/>
                            {optionLabel(item)}
                        </div>
                    ))}
                </div>
            }
        </div>
    );
};

AsyncPanelFilter.defaultProps = {
    className: '',
    extraProps: {},
    optionLabel: (item) => (
        <label className="control-label">
            {item.label}
        </label>
    ),
    optionsParser: (items) => items.map((i) => ({
        value: i.id,
        label: i.name,
        country: i.country_name,
    }))
}

export default AsyncPanelFilter;
