import {Model} from "../../../src_shared/modules/model/Model";

class PaymentStatusModel extends Model {
	getModel() {
		return {
			status: 'paid',
		};
	}
}

export default new PaymentStatusModel();
