import React, {useEffect} from 'react';
import useFormLocal from "../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../RestServices";
import useLang from "../../../../src_shared/hooks/useLang";
import SwitchField from "../../../../src_shared/components/form/switchField/SwitchField";
import Loader from "../../../app/shared/Loader";
import ComponentLoader from "../../../app/shared/components/componentLoader/ComponentLoader";
import {Link, useLocation} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import useDataApi from "../../../../src_shared/hooks/useDataApi";
import {parseParams} from "../../../../src_shared/list/ListActions";
import SettingsStripeModel from "../../../../modules/models/settings/SettingsStripeModel";
import SelectField from "../../../../src_shared/components/form/selectField/SelectField";
import {Helmet} from "react-helmet";
import SelectCountry from "./components/SelectCountry";
import AccountTypeSelect from "../paypal/components/AccountTypeSelect";
import {useSelector} from "react-redux";

const Stripe = () => {
    const {userData} = useSelector((state) => state.session);
    const {getLangText} = useLang();
    const {search} = useLocation();
    const query = parseParams(search);
    const afterSubmit = (response) => window.location.href = response.redirectUrl;

    const [{
        isLoading: waitingForReAuthLink,
        fetchData,
    }] = useDataApi(services.API_SETTINGS_PAYMENT_STRIPE_REAUTH, {}, [], false, {}, afterSubmit);

    const commissionPriceTypes = [
        {
            value: '2',
            label: '%',
        },
        {
            value: '1',
            label: userData?.currency_info?.text,
        },
    ]

    const [{
        onSave,
        isWaiting,
        getFieldProps,
        isLoading,
        values,
        fetchData: fetchStipeData,
        setValue,
    }] = useFormLocal({
        rest: services.API_SETTINGS_PAYMENT_STRIPE,
        model: SettingsStripeModel,
    });

    useEffect(() => {
        if (!!+query.reauth) {
            fetchData()
        }
    }, [])

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Stripe - Selmo</title>
            </Helmet>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <Link to={RouterPaths.Settings}>
                            {getLangText('asideSettingsLabel')}
                        </Link>
                    </li>
                    <li>
                        <Link to={RouterPaths.SettingsPayments}>
                            {getLangText('paymentsLabel')}
                        </Link>
                    </li>
                    <li className="active">
                        <div>Stripe</div>
                    </li>
                </ul>
                <Link
                    to={RouterPaths.SettingsPayments}
                    className="button back-to-panels mb-3">
                    <i className="icon-arrow-left"/>
                    {getLangText('button_back_to_payment')}
                </Link>
            </div>
            <div className="settings-page sub-settings-page">
                <div className="header-wrapper">
                    <img
                        className="w-[60px]"
                        src="/assets/images/payments/stripe.png" alt="Stripe"/>
                    <div className="description">
                        {getLangText('stripeAddPaymentsDescription')}
                    </div>
                </div>
                {(isLoading || waitingForReAuthLink) ?
                    <ComponentLoader/> :
                    <div className="settings-form mb-3">
                        <div className="steps-wrapper">
                            {!values?.stripeAccountInfo?.details_submitted ?
                                <div className="max-w-[500px]">
                                    <div className="step">
                                        <div className="step-header">
                                            <div className="number">1</div>
                                            <div className="title">{getLangText('selectCountryLabel')}
                                            </div>
                                        </div>
                                        <SelectCountry
                                            getFieldProps={getFieldProps}
                                            countryValues={{
                                                country: values.country,
                                                country_name: values.country_name,
                                            }}
                                            fetchData={fetchStipeData}
                                        />
                                    </div>
                                    {values.country &&
                                        <div className="step animate-tutorialSlideAndFadeIn">
                                            <div className="step-header">
                                                <div className="number">2</div>
                                                <div className="title">{getLangText('stripeConnectAccount')} <img
                                                    src="/assets/images/payments/stripe.png" alt="Stripe"/>
                                                </div>
                                            </div>
                                            <div className="step-body">
                                                <div className="new-account-step-body">
                                                    {values?.stripeAccountInfo ?
                                                        <a
                                                            className="button border-button small-size"
                                                            href={values.stripeAccountLink.url} target="_blank"
                                                            rel="noreferrer">
                                                            {getLangText('goToStripeAccount')}
                                                        </a>
                                                        :
                                                        <a href={values?.redirectUrl}
                                                           target="_blank"
                                                           className="button border-button small-size"
                                                           rel="noreferrer">{getLangText('stripeConnectAccountWithStripe')}
                                                        </a>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                :
                                <ul>
                                    <li>
                                        <a
                                            className="border-button button mb-3"
                                            href={values?.stripeAccountUrl?.url}
                                            target="_blank" rel="noreferrer"
                                        >
                                            {getLangText('goToStripeAccount')}
                                        </a>
                                    </li>
                                    <li>
                                        <span className="text-sm font-bold">{getLangText('cardPaymentLabel')}</span>
                                        <span
                                            className="badge badge-success ml-2">{values?.stripeAccountInfo?.capabilities?.card_payments}</span>
                                    </li>
                                    <form method="post" className="mt-3">
                                        <div className="sm:max-w-[72%]">
                                            <div className="flex items-center flex-wrap blue-media-bottom-row">
                                                <div
                                                    className="form-group small-width form-group-addon mr-3 hidden-arrows">
                                                    <label
                                                        className="control-label">{getLangText('settings_payments_bluemedia_payment_commission_value')}</label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        {...getFieldProps('stripe_price')}
                                                        onChange={(e) => setValue('stripe_price', e.target.value)}
                                                    />
                                                    <SelectField
                                                        {...getFieldProps('stripe_price_type')}
                                                        className="default-size mb-0"
                                                        showLabel={false}
                                                        options={commissionPriceTypes}
                                                        extraProps={{
                                                            isSearchable: false
                                                        }}
                                                    />
                                                </div>
                                                <SwitchField
                                                    {...getFieldProps('stripe_active')}
                                                    label={getLangText('active_payment')}
                                                    className="switch-form inline-label big-label mr-3 mb-0 light-version"

                                                />
                                                <button
                                                    type="button"
                                                    onClick={onSave}
                                                    className={`button ml-auto primary small-size ${isWaiting ? 'loading-button' : ''}`}
                                                >
                                                    <Loader isLoading={isWaiting}/>
                                                    {getLangText('saveButton')}
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                    <div className="sm:max-w-[72%]">
                                        <AccountTypeSelect
                                            fieldName="stripe_account"
                                            data={values}
                                            label="stripeAccount"
                                            type="stripe"
                                        />
                                    </div>
                                </ul>
                            }
                        </div>
                    </div>
                }
            </div>
        </>
    );
};

export default Stripe;
