import React from 'react';
import Loader from "../../Loader";

const ComponentLoader = ({height = 400}) => {
	return (
		<div className="loader-parent" style={{minHeight: `${height}px`}}>
			<Loader isLoading />
		</div>
	);
};

export default ComponentLoader;
