import React from 'react';
import ConfirmRemoveModal from "../../../../../src_shared/components/modal/ConfirmRemoveModal";
import useLang from "../../../../../src_shared/hooks/useLang";

const RemoveOrder = ({showModal, modalVisible, hideModal, values, removeOrder, mobile = false}) => {
	const {getLangText} = useLang();

	return (
		<div className={`form-group details-section mt-3 ${mobile ? 'mobile-remove-section summary-section' : 'remove-section'}`}>
			<div className="d-flex align-items-center flex-sm-nowrap flex-wrap">
				<h2 className="mb-0 flex-grow-1">{getLangText('irrevocablyRemoveOrderLabel')} {values.shop_order_id}</h2>
				<div className="form-col w-sm-auto w-100">
					<button
						type="button"
						className="button border-button big-size left-icon w-sm-auto w-100 mt-sm-0 mt-2"
						onClick={showModal}
					>
						<i className="icon-bin"/>
						{getLangText('removeTheOrderLabel')}
					</button>
					<ConfirmRemoveModal
						show={modalVisible}
						title={`${getLangText('askToRemoveTheOrderLabel')} ${values.shop_order_id}`}
						subTitle={getLangText('actionCannotBeUnDoneLabel')}
						hide={hideModal}
						confirm={removeOrder}
						confirmButton={getLangText('removeButton')}
						dialogClassName="small-modal"
					/>
				</div>
			</div>
		</div>
	);
};

export default RemoveOrder;
