import React, {useState} from 'react';
import {Menu} from "@headlessui/react";
import Dropdown from "../../../../../../../src_shared/components/ui/Dropdown/Dropdown";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import {batch, useDispatch} from "react-redux";
import VariantsSquModalFormActions from "../../../../form/variantsSquModal/FormActions";
import VariantsPriceModal from "../../../../form/variantsPriceModal/VariantsPriceModal";
import useSubmitData from "../../../../../../../src_shared/hooks/useSubmitData";
import {services} from "../../../../../../RestServices";
import {useParams} from "react-router-dom";
import EditProductFormActions from "../../../../form/edit/FormActions";
import ConfirmModal from "../../../../../orders/list/components/export/ConfirmModal";

const ReplenishStock = ({values}) => {
    const {getLangText} = useLang();
    const dispatch = useDispatch();
    const [openProductSizePriceModal, setOpenProductSizePriceModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const {id} = useParams();

    const [{onSave, isWaiting}] = useSubmitData(
        {
            rest: `${services.API_STORAGE_RESET_PRODUCT_SIZE_STOCK}/${id}`,
            afterSubmit: () => {
                setShowModal(false);
                dispatch(EditProductFormActions.loadData(id));
            }
        });


    const showVariantSquModal = () => {
        batch(() => {
            dispatch(VariantsSquModalFormActions.modal.showModal());
            dispatch(VariantsSquModalFormActions.data.setValue('id', values.id));
        })
    };

    const HeaderButton = () => (
        <div className="button border-button small-size left-icon">
            <i
                style={{top: 0}}
                className="icon-plus"/>
            {getLangText('moreLabel')}
        </div>
    );

    return (
        <>
            <Dropdown
                Button={HeaderButton}
                dropdownContentClassName="min-w-[200px] ml-auto">
                <Menu.Item>
                    <button
                        onClick={() => setOpenProductSizePriceModal(true)}
                        type="button"
                        className="text-left text-nowrap p-2.5 min-h-[36px] w-full hover:bg-gray-100 rounded-sm px-2 py-[6px] transition-all flex items-center text-[#4B5563] text-xs hover:text-[#1F2937] font-semibold hover:no-underline"
                    >
                        {getLangText('setVariantsPriceLabel')}
                    </button>
                </Menu.Item>
                <Menu.Item>
                    <button
                        onClick={showVariantSquModal}
                        type="button"
                        className="text-left p-2.5 min-h-[36px] w-full hover:bg-gray-100 rounded-sm px-2 py-[6px] transition-all flex items-center text-[#4B5563] text-xs hover:text-[#1F2937] font-semibold hover:no-underline"
                    >
                        {getLangText('setUpSquLabel')}
                    </button>
                </Menu.Item>
                <Menu.Item>
                    <button
                        onClick={() => setShowModal(true)}
                        type="button"
                        className="text-left p-2.5 min-h-[36px] w-full hover:bg-gray-100 rounded-sm px-2 py-[6px] transition-all flex items-center text-[#4B5563] text-xs hover:text-[#1F2937] font-semibold hover:no-underline"
                    >
                        {getLangText('resetAllVariantsCountLabel')}
                    </button>
                </Menu.Item>
            </Dropdown>
            {openProductSizePriceModal &&
                <VariantsPriceModal
                    values={values}
                    show={openProductSizePriceModal}
                    hide={() => setOpenProductSizePriceModal(false)}
                />
            }
            {showModal &&
                <ConfirmModal
                    title={getLangText('wantToResetAllVariantsCountLabel')}
                    saveButton={getLangText('yesButton')}
                    show={showModal}
                    isWaiting={isWaiting}
                    onSubmit={onSave}
                    hide={() => setShowModal(false)}
                />
            }
        </>
    );
};

export default ReplenishStock;