const initialState = {
    activeViewType: 'edit',

}
export default (state = initialState, action) => {
    switch (action.type) {
        case 'SET_VIEW_TYPE':
            return {...state, activeViewType: action.viewType};
        default:
            return state;
    }
};
