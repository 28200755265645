import createReducer from "../../../shared/helpers/createReducer";
import Composition from "../../../shared/helpers/Composition";
import ListReducer from "../../../../../../../src_shared/list/ListReducer";
export const getStateRoot = (state) => state.admin.sales.stats.list;
export const prefix = 'ADMIN_SALES_STATS';

const getInitState = () => ListReducer.getInitState();

const getReduceMap = () =>  new Composition(
	ListReducer.getReduceMap(),
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

