import {combineReducers} from "redux";
import listReducer from './list/listReducer'
import assignProductsReducer from './form/assignProducts/formReducer'
import formReducer from "./form/formReducer";

export default combineReducers({
	list: listReducer,
	assignProducts: assignProductsReducer,
	form: formReducer,
});
