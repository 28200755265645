import React from 'react';
import Modal from "react-bootstrap/Modal";
import {services} from "../../../../../RestServices";
import Loader from "../../../../messenger/loader/Loader";
import useLang from "../../../../../../src_shared/hooks/useLang";
import useFormLocal from "../../../../../../src_shared/hooks/useFormLocal";
import {useParams} from "react-router-dom";
import DatePicker from "react-datepicker";
import TimePicker from "react-time-picker";
import SwitchField from "../../../../../../src_shared/components/form/switchField/SwitchField";
import {useSelector} from "react-redux";
import TextArea from "../../../../../../src_shared/components/form/textarea/TextArea";
import SalesNotificationPostModel from "../../../../../../modules/models/salesPost/SalesNotificationPostModel";
import {prepareRealLang} from "../../../../../../services/i18n/i18nAPI";

const PostNotificationModal = ({modalVisible, hideModal, data, loadData}) => {
    const {getLangText} = useLang();
    const {id} = useParams();
    const {lang} = useSelector((state) => state.i18n);

    const [{onSave, isWaiting, getFieldProps, values, setValue}] = useFormLocal({
        rest: `${services.API_VIDEO_MOBILE_NOTIFICATIONS}/${id}`,
        model: SalesNotificationPostModel,
        initialAutoLoad: false,
        afterSubmit: () => {
            loadData(id);
            hideModal();
        }
    });

    return (
        <Modal
            show={modalVisible}
            dialogClassName="default-modal small-modal w-420"
            onHide={hideModal}
        >
            <Modal.Header>
                <Modal.Title>
                    {getLangText('notifyInSelmoApp')}
                    <div className="sub-title">{getLangText('sendNotificationInThisPost')}</div>
                </Modal.Title>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            <Modal.Body>
                <div className="rouned-[5px] bg-[#F9FAFB] p-1 flex items-center mb-4">
                    <div className="w-[48px] h-[48px] min-w-[48px] mr-2 rounded-[5px] overflow-hidden">
                        <img
                            className="w-full h-full object-cover"
                            src={data.poster_url} alt="poster"
                        />
                    </div>
                    <div>
                        <div className="font-bold text-sm">{getLangText('selectedPostLabel')}</div>
                        <div className="text-desc text-sm">{getLangText('hereWillBeRedirectedClients')}</div>
                    </div>
                </div>
                <TextArea
                    {...getFieldProps('message')}
                    label={getLangText('notificationMessageLabel')}
                />
                <SwitchField
                    {...getFieldProps('notify_later')}
                    name="notify_later"
                    optional
                    className="switch-form inline-label big-label light-version mb-0 "
                    label={getLangText('notifyLater')}
                />
                {!!+values.notify_later &&
                    <div className="grid grid-cols-3 gap-2 mt-2 animate-[slideAndShow_.3s_ease_forwards] mb-3">
                        <div className="col-span-2">
                            <div className="form-group calendar-input">
                                <div className="control-label">
                                    {getLangText('dateLabel')}
                                </div>
                                <DatePicker
                                    portalId="my-popper"
                                    className="form-control"
                                    selected={values.notify_date}
                                    onChange={(value) => setValue('notify_date', value)}
                                    locale={lang}
                                    dateFormat="dd.MM.y"
                                />
                                <i className="icon-calendar"/>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="control-label">
                                {getLangText('hour')}
                            </div>
                            <div className="react-time-picker-wrapper">
                                <TimePicker
                                    clearIcon={false}
                                    className="form-control flex items-center"
                                    disableClock={true}
                                    locale={prepareRealLang(lang)}
                                    onChange={(value) => setValue('notify_time', value)}
                                    value={values.notify_time}
                                    format="HH:mm"
                                />
                                <i className="icon-time"/>
                            </div>
                        </div>
                    </div>
                }
            </Modal.Body>
            <Modal.Footer>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button border-button"
                >
                    {getLangText('cancelButton')}
                </button>
                <button
                    type="button"
                    className={`button primary small-size ${isWaiting ? 'loading-button' : ''}`}
                    onClick={onSave}
                >
                    {isWaiting && <Loader/>}
                    {getLangText('sendButton')}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default PostNotificationModal;
