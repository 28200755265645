import React, {useMemo, useState} from 'react';
import ConfirmModal from "../../list/components/export/ConfirmModal";
import useLang from "../../../../../src_shared/hooks/useLang";
import useDataApi from "../../../../../src_shared/hooks/useDataApi";
import {services} from "../../../../RestServices";
import {useParams} from "react-router-dom";
import SwitchBoxes from "../../../../../src_shared/components/form/switchBoxes/SwitchBoxes";
import axios from "axios";
import {selmoUrl} from "../../../../../src_shared/api/api";
import {showAlert} from "../../../shared/components/alert/AlertActions";
import {batch, useDispatch} from "react-redux";
import ValidationError from "../../../../../src_shared/form/validation/ValidationError";
import Loader from "../../../shared/Loader";
import EditOrderFormActions from "../form/FormActions";
import api from "../../../../../services/axios/axios";

const SelectCourierModal = ({show, hide, template}) => {
	const {id} = useParams();
	const dispatch = useDispatch();
	const [isWaiting, setIsWaiting] = useState(false);
	const [isValidationActive, setIsValidationActive] = useState(false);
	const [value, setValue] = useState(null);
	const [{
		data,
		isLoading,
		errorMessage
	}] = useDataApi(`${services.API_SHIPPYPRO_CARRIERS}/${id}`, [], [], true, {template})

	const {getLangText} = useLang();

	const options = useMemo(() => data.map((i) => ({
		id: i.carrier_id,
		label: i.carrier,
		description: `${i.service} <span style="font-size: 8px; margin-left: 2px; margin-right: 2px; vertical-align: bottom">•</span> ${i.delivery_days} ${getLangText('daysLabel')}`,
		price: `${i.rate} ${i.currency}`
	})))

	const onSubmit = async () => {
		if (!value) {
			setIsValidationActive(true)
			return;
		}
		setIsWaiting(true);
		const selectedCarrier = data.find((i) => i.carrier_id === value) || {};
		try {
			await api.post(`${selmoUrl}/${services.API_SHIPPYPRO_LABEL}/${id}`, {
				carrier_id: selectedCarrier.carrier_id,
				carrier_service: selectedCarrier.service,
				carrier_name: selectedCarrier.carrier,
				template_id: template,
			})
			batch(() => {
				dispatch(showAlert('labelHasBennGeneratedLabel', ''))
				dispatch(EditOrderFormActions.loadData(id))
			})

		} catch (e) {
			dispatch(showAlert('sthWentWrongWarning', e.response.data.message, 'alert-fail'))
		}
		finally {
			hide()
		}

	}

	return (
		<ConfirmModal
			title={getLangText('selectCourierLabel')}
			saveButton={getLangText('createLabelLabel')}
			show={show}
			isWaiting={isWaiting}
			onSubmit={onSubmit}
			hide={hide}
			disabledSubmitButton={!!errorMessage}
			submitButtonClass={`${!!errorMessage ? 'not-active' : ''} primary`}
		>
			{isLoading ?
				<div className="my-2">
					<Loader className="static-loader" isLoading/>
				</div> :
				!!errorMessage ?
					<div className="form-info-box red w-100">
						<div>
							<i className="icon-info-c"/>
						</div>
						<div>
							<div className="title">{errorMessage}</div>
						</div>
					</div> :
					<SwitchBoxes
						value={value}
						validateErrors={isValidationActive}
						options={options}
						setValue={(field, value) => setValue(value)}
						getError={() => ValidationError.notEmpty(value)}
						ErrorComponent={() => (
							<div className="form-info-box alert-style red w-100 mb-2">
								<div>
									<i className="icon-info-c"/>
								</div>
								<div className="title">
									{getLangText('selectCourierLabel')}
								</div>
							</div>
						)}
					/>

			}
		</ConfirmModal>
	);
};

export default SelectCourierModal;
