import React from 'react';
import useLang from "../../../../../src_shared/hooks/useLang";
import {
    DPD_POINT_MODAL, GLS_POINT_MODAL,
    INPOST_POINT_MODAL,
    MONDIAL_RELAY_POINT_MODAL,
    ORLEN_POINT_MODAL
} from "../../secondStep/SecondStep";

const SelectedPointMachineBox = ({values, setIsPointModalOpen, setActiveShippingId}) => {
    const {getLangText} = useLang();

    const openPointMachineModal = () => {
        setActiveShippingId(values.shipping);
        setIsPointModalOpen(values.pointMachineType)
    }

    return (
        <div
            className="inpost-results-wrapper">
            <div className="inpost-section-title">
                {values.pointMachineType === INPOST_POINT_MODAL &&
                    getLangText('customerBasketSelectedInpostLabel')
                }
                {values.pointMachineType === MONDIAL_RELAY_POINT_MODAL &&
                    getLangText('selectedMondialRelayPointLabel')
                }
                {values.pointMachineType === ORLEN_POINT_MODAL &&
                    getLangText('selectOrlenPointLabel')
                }
                {values.pointMachineType === DPD_POINT_MODAL &&
                    getLangText('selectDpdPickupPoint')
                }
                {values.pointMachineType === GLS_POINT_MODAL &&
                    getLangText('selectGlsPickupPoint')
                }
            </div>
            <div className="inpost-row">
                <div className="photo">
                    {values.pointMachineType === INPOST_POINT_MODAL &&
                        <img src="/assets/images/inpost.svg" alt=""/>
                    }
                    {values.pointMachineType === MONDIAL_RELAY_POINT_MODAL &&
                        <img width={60} src="/assets/images/svg/logomondial-relay.svg" alt=""/>
                    }
                    {values.pointMachineType === ORLEN_POINT_MODAL &&
                        <img width={30} src="/assets/images/svg/orlen-paczka.svg" alt=""/>
                    }
                    {values.pointMachineType === DPD_POINT_MODAL &&
                        <img width={90} src="/assets/images/svg/dpd-pick-up-logo.svg" alt=""/>
                    }
                    {values.pointMachineType === GLS_POINT_MODAL &&
                        <img width={52} src="/assets/images/gls.png" alt=""/>
                    }
                </div>
                {(values.pointMachineType === DPD_POINT_MODAL || values.pointMachineType === GLS_POINT_MODAL) ?
                    <div className="name-wrapper">
                        <div
                            className="top-name">
                            <div className="font-bold">{values.pointMachineName}</div>
                        </div>
                        <div className="extra-info">
                            <div>{values.pointMachineStreet}, {values.pointMachineCity} {values.pointMachineCode}</div>
                        </div>
                    </div> :
                    <div className="name-wrapper">
                        <div
                            className="top-name">
                            <div>{values.pointMachineStreet} {values.pointMachineCode}</div>
                        </div>
                        <div className="extra-info">{values.pointMachineCity}</div>
                    </div>
                }
                <div className="action">
                    <div
                        onClick={openPointMachineModal}
                        className="button border-button btn-change change-inpost text-nowrap">
                        {getLangText('customerBasketChangeButton')}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SelectedPointMachineBox;
