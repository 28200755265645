import React from 'react';
import {Link, NavLink, useLocation} from "react-router-dom";
import {RouterPaths} from "../../routes/RouterPath";
import useLang from "../../../src_shared/hooks/useLang";
import {Helmet} from "react-helmet";
import AddMembersForm from "./members/addMemberForm/AddMembersForm";
import UserPermissionsWrapper from "../../routes/UserPermissionsWrapper";
import {
    USER_PERMISSIONS_TEAM_ADD_MEMBER,
    USER_PERMISSIONS_TEAM_STATISTICS_VISIBILITY_PERMISSIONS
} from "../../app/shared/enums/UserPermissionsTypes";
import {useSelector} from "react-redux";

const TeamWrapper = ({setShowModal, modalVisible, children, hideButton = false, title = 'teamLabel'}) => {
    const {getLangText} = useLang();
    const {userData} = useSelector((state) => state.session);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getLangText('teamLabel')} - Selmo</title>
            </Helmet>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <Link to={RouterPaths.Settings}>{getLangText('asideSettingsLabel')}</Link>
                    </li>
                    <li>
                        <Link to={RouterPaths.SettingsShop}>{getLangText('settings_nav_shop_settings')}</Link>
                    </li>
                    <li className="active">
                        {getLangText(title)}
                    </li>
                </ul>
            </div>
            <div className="settings-page sub-settings-page"
                 style={{borderBottomLeftRadius: 0, borderBottomRightRadius: 0}}>
                <div className="header-wrapper no-border pb-0">
                    <div className="flex items-center">
                        <div>
                            <h1>{getLangText('teamLabel')}</h1>
                            <div className="header-description">{getLangText('manageTeamLabel')}</div>
                        </div>
                        {!hideButton &&
                            <UserPermissionsWrapper allowedPermissions={[USER_PERMISSIONS_TEAM_ADD_MEMBER]}>
                                <button
                                    onClick={() => setShowModal(true)}
                                    type="button"
                                    className="button primary small-size ml-auto"
                                >
                                    {getLangText('addPersonLabel')}
                                </button>
                            </UserPermissionsWrapper>
                        }
                    </div>
                </div>
                <div className="tabs-wrapper">
                    <NavLink
                        exact
                        activeClassName="active"
                        to={RouterPaths.SettingsTeamMembers}
                    >
                        {getLangText('membersLabel')}
                    </NavLink>
                    <NavLink
                        exact
                        activeClassName="active"
                        to={RouterPaths.SettingsTeamInvitations}
                    >
                        {getLangText('invitationsLabel')}
                    </NavLink>
                    <UserPermissionsWrapper allowedPermissions={[USER_PERMISSIONS_TEAM_STATISTICS_VISIBILITY_PERMISSIONS]}>
                        <NavLink
                            exact
                            activeClassName="active"
                            to={RouterPaths.SettingsTeamActivityHistory}
                        >
                            {getLangText('activityHistory')}
                        </NavLink>
                    </UserPermissionsWrapper>
                    <UserPermissionsWrapper allowedPermissions={[USER_PERMISSIONS_TEAM_STATISTICS_VISIBILITY_PERMISSIONS]}>
                        <NavLink
                            exact
                            activeClassName="active"
                            to={RouterPaths.SettingsTeamStatistics}
                        >
                            {getLangText('statisticsLabel')}
                        </NavLink>
                    </UserPermissionsWrapper>
                </div>
                {/*<div className="p-6">*/}
                {/*    <div className="form-info-box w-100">*/}
                {/*        <div>*/}
                {/*            <i className="icon-info-c"/>*/}
                {/*        </div>*/}
                {/*        <div>*/}
                {/*            <div className="title">{getLangText('whatEmployeeAccountsAllowForSubLabel')}</div>*/}
                {/*            <div className="desc">{getLangText('whatEmployeeAccountsAllowForSubLabel')}</div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
            {children}
            {modalVisible &&
                <AddMembersForm
                    modalVisible={modalVisible}
                    hideModal={() => setShowModal(false)}
                />
            }
        </>

    );
};

export default TeamWrapper;
