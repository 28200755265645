import axios from "axios";
import {showAlert} from "../../shared/components/alert/AlertActions";
import moment from "moment";
import {getLiveDetailsInfo} from "../details/default/LiveDetailsActions.js";
import {RouterPaths} from "../../../routes/RouterPath";
import {selmoUrl} from "../../../../src_shared/api/api";
import api from "../../../../services/axios/axios";
import {services} from "../../../RestServices";
import SessionActions from "../../shared/session/SessionActions";
import {convertToWarsawTimeZone} from "../../shared/helpers/dateHelpers";

export const addNewLiveModalToggleVisibility = (toggle) => {
	return {
		type: 'ADD_NEW_LIVE_MODAL_TOGGLE_VISIBILITY',
		toggle,
	};
}

const submitNewLiveRequested = () => {
	return {
		type: 'NEW_LIVE_SUBMIT_REQUESTED'
	};
}

const submitNewLiveError = () => {
	return {
		type: 'NEW_LIVE_SUBMIT_ERROR'
	};
}

export const updateFieldInNewLiveModalForm = (field, value) => {
	return {
		type: 'NEW_LIVE_UPDATE_FIELD_IN_MODAL_FORM',
		field,
		value,
	}
}

export const updateValuesInNewLiveModalForm = (values) => {
	return {
		type: 'NEW_LIVE_UPDATE_VALUES_IN_MODAL_FORM',
		values,
	}
}

export const onSubmit = (history) => {

	return async (dispatch, getState) => {
		const livesNewLiveModalRoot = getState().lives.newLiveModal;
		const {data} = livesNewLiveModalRoot;

		const preparedDate = moment(data.date).format('YYYY-MM-DD');

		const date = {
			date: preparedDate,
			time: data.time
		};

		const preparedData = {
			date: convertToWarsawTimeZone(date).format('YYYY-MM-DD HH:mm'),
			link: data.link,
			title: data.title,
			live_id: data.id,
			storage_products: data.storage_products,
			single_order_enabled: data.single_order_enabled,
		}

		dispatch(submitNewLiveRequested());

		try {
			const response = data.id ? await api.put(`${selmoUrl}/${services.API_LIVE}`, {
				...preparedData
			}) : await api.post(`${selmoUrl}/${services.API_LIVE}`, {
				...preparedData
			});

			if (data.id) {
				dispatch(getLiveDetailsInfo(data.shopLiveId))
				dispatch(showAlert('yuhuLabel', 'liveSuccessfulEditedLabel'))
			} else {
				history.push(`${RouterPaths.LiveList}/${response.data.message}/`)
				dispatch(showAlert('yuhuLabel', 'liveSuccessfulAddedLabel'));
				dispatch(SessionActions.updateUserData('first_step_live', false))
			}
		} catch (e) {
			dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
		}
		finally {
			dispatch(addNewLiveModalToggleVisibility(false))
		}
	}
}
