import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../../routes/RouterPath";
import EmptyList from "../../../../shared/EmptyList";
import useLang from "../../../../../../src_shared/hooks/useLang";
import {useUnitPrice} from "../../../../shared/helpers/Price";
import ServerImage from "../../../../shared/components/serverImage/ServerImage";
import CustomDropdown from "../../../../shared/components/dropdown/CustomDropdown";
import CreateOrderModal from "./CreateOrderModal";
import ConfirmRemoveModal from "../../../../../../src_shared/components/modal/ConfirmRemoveModal";
import useRemoveData from "../../../../../../src_shared/hooks/useRemoveData";
import {services} from "../../../../../RestServices";

const Table = ({isWaiting, items, fetchData}) => {
    const {getLangText} = useLang();
    const {getPrice} = useUnitPrice();
    const [selectedItem, setSelectedItem] = useState({});
    const [showModal, setShowModal] = useState(false);


    const handleCreateOrder = (item) => {
        setSelectedItem(item)
        setShowModal(true);
    }

    const [{
        openModal,
        hideModal,
        confirmModalVisible,
        itemToRemove,
        isDeleting,
        removeItem
    }] = useRemoveData({
        rest: services.API_PRODUCT_RESERVE_LIST,
        afterSubmit: () => fetchData(),
    });

    return (
        <EmptyList
            items={items}
            isLoading={isWaiting}
            className="with-padd with-bg"
            imgSrc="/assets/images/empty/products-empty.svg"
            title={`<strong>${getLangText('noProductsLabel')}</strong>`}
        >
            {items.map((item) => (
                <div
                    key={item.id}
                    className="box"
                >
                    <div className="flex items-center grow">
                        <figure className="product-avatar">
                            {item.product_photo && item.product_photo !== '0' ?
                                <ServerImage className="w-full h-full object-cover" src={item.product_photo}
                                             alt={item.name}/> :
                                <img className="w-full h-full object-cover" src="/assets/images/default/product.svg"
                                     alt="default"/>
                            }
                        </figure>
                        <div>
                            <div className="font-bold text-sm">
                                {item.name}
                            </div>
                            <div className="text-xs text-desc">
                                {item.product_size_id &&
                                    <>
                                        {getLangText('variantLabel')}: <strong>{item.size_name}</strong>
                                        <span className="text-[8px] mx-1">{' • '}</span>
                                    </>
                                }
                                {getLangText('codeLabel')}: <strong>{item.selmo_code}</strong>
                            </div>
                        </div>
                        <div className="ml-auto font-bold text-sm">
                            {getPrice(item.price)}
                        </div>
                        <CustomDropdown
                            className="-mt-2 ml-[10px]"
                            icon="icon-dots ml-auto"
                        >
                            <button
                                type="button"
                                onClick={() => handleCreateOrder(item)}
                                className="custom-option"
                            >
                                {getLangText('createOrderButton')}
                            </button>
                            <Link
                                to={`${RouterPaths.ProductsList}/edytuj/${item.product_id}`}
                                className="custom-option"
                            >
                                {getLangText('goToProduct')}
                            </Link>
                            <button
                                type="button"
                                onClick={() => openModal(item)}
                                className="custom-option"
                            >
                                {getLangText('removeButton')}
                            </button>
                        </CustomDropdown>
                    </div>
                </div>
            ))}
            {showModal &&
                <CreateOrderModal
                    fetchData={fetchData}
                    selectedProduct={selectedItem}
                    modalVisible={showModal}
                    hideModal={() => setShowModal(false)}
                />
            }
            {confirmModalVisible &&
                <ConfirmRemoveModal
                    show={confirmModalVisible}
                    subTitle={itemToRemove.name}
                    dialogClassName="small-modal"
                    title={getLangText('wantToRemoveProductFormReserveList')}
                    hide={hideModal}
                    confirm={removeItem}
                    isWaiting={isDeleting}
                    confirmButton={getLangText('removeButton')}
                />
            }
        </EmptyList>
    );
};

export default Table;
