import ListActions, {getGETParamsUrl} from "../../../../src_shared/list/ListActions";
import {getStateRoot, prefix} from './listReducer';
import {RouterPaths} from "../../../routes/RouterPath";
import {getStateRoot as getFiltersStateRoot} from "../filters/filtersReducer";
import {convertDate} from "../../shared/helpers/dateHelpers";
import history from "../../../history";
import {services} from "../../../RestServices";

export class BoxesStatsListActions extends ListActions {
	getLoadParams(state) {
		const params = {};
		const dateFrom = getFiltersStateRoot(state).data.fromDate;
		const dateTo = getFiltersStateRoot(state).data.toDate;
		const isRealized = getFiltersStateRoot(state).data.isRealized;
		const currency = getFiltersStateRoot(state).data.currency;

		if (dateFrom) {
			params.dateFrom = convertDate(dateFrom);
		}

		if (dateTo) {
			params.dateTo = convertDate(dateTo);
		}

		if (isRealized === '1') {
			params.isRealized = isRealized;
		}

		if (currency) {
			params.currency = currency;
		}

		history.replace({
			pathname: this.pathname,
			search: getGETParamsUrl(params)
		});

		return params;
	}

}

export const getInstance = () => new BoxesStatsListActions({
	getStateRoot,
	restService: services.API_STATS_BOXES,
	prefix,
	pathname: RouterPaths.Stats
});

export default getInstance();
