import React, {useState} from 'react';
import {services} from "../../RestServices";
import {useLocation, useParams} from "react-router-dom";
import useFormLocal from "../../../src_shared/hooks/useFormLocal";
import NewsletterModel from "../../../modules/models/newsletter/NewsletterModel";
import Skeleton from "./Skeleton";
import {Helmet} from "react-helmet";
import LanguagePicker from "../shared/languagePicker/LanguagePicker";
import ServerImage from "../shared/components/serverImage/ServerImage";
import Field from "../../../src_shared/components/form/field/Field";
import useLang from "../../../src_shared/hooks/useLang";
import CheckboxField from "../../../src_shared/components/form/checkboxField/CheckboxField";
import RegulationModal from "../basket/deliveryAddress/components/RegulationModal";
import {useModal} from "../shared/hooks/useModal";
import Loader from "../messenger/loader/Loader";

const Newsletter = () => {
    const {shopName, newsletterId} = useParams();
    const shopId = shopName.split('-')[shopName.split('-').length - 1];
    const rest = `${services.API_CHECKOUT_NEWSLETTER}?shop_id=${shopId}&newsletter_id=${newsletterId}`;
    const {getLangText} = useLang();
    const [openRegulationsModal, setOpenRegulationsModal] = useModal(false);
    const [openPolicyModal, setOpenPolicyModal] = useModal(false);

    const afterSubmit = (response) => {

    }


    const regulationModalButton = () => {
        return (
            <button
                style={{position: "relative", zIndex: '9', textDecoration: 'underline'}}
                type="button"
                onClick={() => setOpenRegulationsModal(true)}
            >
                {getLangText('customerBasketAgreementLabel2')}
            </button>
        )
    }

    const policyModalButton = () => (
        <button
            style={{position: "relative", zIndex: '9', textDecoration: 'underline'}}
            type="button"
            onClick={() => setOpenPolicyModal(true)}
        >
            {getLangText('customerBasketAgreementLabel4')}
        </button>
    )
    const checkboxLabel = () => {
        return (
            <>
                {getLangText('customerBasketAgreementLabel1')}
                &nbsp;
                {regulationModalButton()}
                &nbsp;
                {getLangText('customerBasketAgreementLabel3')}
                &nbsp;
                {policyModalButton()}
            </>
        )
        return (
            <>
                {getLangText('customerBasketAgreementLabel1')}
                &nbsp;
                {regulationModalButton()}
            </>
        )
    }

    const [{
        values, isLoading,
        getFieldProps,
        updateValues,
        onSave,
        setValue,
        isWaiting,
    }] = useFormLocal({
        rest,
        model: NewsletterModel,
        afterSubmit,
    });

    return (
        <div className="min-h-[100vh] sm:h-auto h-full flex flex-col sm:py-6">
            {values.shopInfo?.name &&
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>{`Newsletter - ${values?.name}`}</title>
                </Helmet>
            }
            <div className="md:w-[690px] w-full sm:m-auto sm:h-auto h-full bg-[transparent] bg-[white]">
                <div className="dashboard-card newsletter m-auto sm:p-[70px]">
                    {isLoading ?
                        <Skeleton/> :
                        <div>
                            <div className="flex items-center pb-3 border-b mb-4 border-[#F3F4F6]">
                                <div className="w-[50px] h-[50px] rounded-[5px] overflow-hidden border">
                                    {values?.logo && values?.logo !== '0'
                                        ? <ServerImage
                                            className="w-full h-full object-cover"
                                            src={values?.logo}
                                            alt={values?.name}
                                        /> :
                                        <img
                                            className="w-full h-full object-cover"
                                            src="/assets/images/default/shop.svg"
                                            alt={values?.name}
                                        />
                                    }
                                </div>
                                <div className="font-bold text-[#101827] ml-2">{values?.name}</div>
                                <LanguagePicker
                                    buttonClassName="ml-auto"
                                />
                            </div>
                            <div className="pb-4 border-b mb-4 border-[#F3F4F6]">
                                <div className="font-bold text-[#101827] text-[20px] mb-2 leading-[26px]">{values.newsletter_title}</div>
                                <div className="ext-[#101827] leading-[24px]">{values.newsletter_description}</div>
                            </div>
                            <form
                                id="newsletter-form"
                                onSubmit={onSave}
                            >
                                <div className="font-bold text-[#101827] mb-2">{getLangText('textEmailLabel')}</div>
                                <Field
                                    {...getFieldProps('email')}
                                    label={getLangText('emailLabel')}
                                    className="big-size"
                                    type="email"
                                    floatedLabel
                                    extraProps={{
                                        autoFocus: true
                                    }}
                                />
                                <CheckboxField
                                    {...getFieldProps('agreement')}
                                    LabelComponent={checkboxLabel()}
                                    setValue={setValue}
                                />
                                <button
                                    type="submit"
                                    style={{fontSize: 14, height: 50}}
                                    className={`button primary w-full mt-3 ${isWaiting ? 'loading-button' : ''}`}
                                >
                                    {isWaiting && <Loader/>}
                                    {getLangText('joinLabel')}
                                </button>
                            </form>
                            <div className="mt-5 sm:hidden">
                                <div className="text-xs font-medium text-[#6B7280] mb-2">{getLangText('weAreOnPlatform')}</div>
                                <a
                                    rel="noreferrer"
                                    href="https://selmo.io"
                                    target="_blank"
                                >
                                    <img
                                        className="block"
                                        src="/assets/images/selmo-logo.svg"
                                        alt={'Selmo'}
                                        height={25}
                                        width={89}
                                    />
                                </a>
                            </div>
                        </div>
                    }
                </div>
                <div className="mt-5 sm:block hidden">
                    <div className="text-xs font-medium text-[#6B7280] mb-2">{getLangText('weAreOnPlatform')}</div>
                    <a
                        rel="noreferrer"
                        href="https://selmo.io"
                        target="_blank"
                    >
                        <img
                            className="block"
                            src="/assets/images/selmo-logo.svg"
                            alt={'Selmo'}
                            height={25}
                            width={89}
                        />
                    </a>
                </div>
            </div>
            {openRegulationsModal &&
                <RegulationModal
                    modalVisible={openRegulationsModal}
                    hideModal={() => setOpenRegulationsModal(false)}
                    rest={services.API_CART_TERMS}
                    shopId={shopId}
                    title={getLangText('customerBasketRegulationsModalTitle')}
                />
            }
            {openPolicyModal &&
                <RegulationModal
                    modalVisible={openPolicyModal}
                    hideModal={() => setOpenPolicyModal(false)}
                    rest={services.API_CART_SHOP_POLICY}
                    shopId={shopId}
                    title={`${getLangText('customerBasketShopPolicyModalTitle')}${values?.name}`}
                />
            }
        </div>
    );
};

export default Newsletter;
