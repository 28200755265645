import React from 'react';
import EmptyList from "../../../shared/EmptyList";
import moment from "moment";
import useLang from "../../../../../src_shared/hooks/useLang";
import ClientAvatar from "../../../live/shared/Avatar";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../routes/RouterPath";
import {convertToUserTimeZone} from "../../../shared/helpers/dateHelpers";

const IG_COMMENT = 'IG_COMMENT'
const IG_MESSAGE = 'IG_MESSAGE'
const IG_STORIES = 'IG_STORIES'


const InteractionsSourceTypes = [
    {id: IG_COMMENT, lang: 'postLabel'},
    {id: IG_MESSAGE, lang: 'messageLabel'},
    {id: IG_STORIES, lang: 'storyLabel'},
]

const getInteractionSourceLabel = (type) => InteractionsSourceTypes.find((i) => i.id === type)?.lang;

const Table = ({updateSortOptions, isWaiting, getSortClass, items}) => {
    const {getLangText} = useLang();

    return (
        <EmptyList
            items={items}
            isLoading={isWaiting}
            className="with-padd"
            imgSrc="/assets/images/empty/clients-empty.svg"
            title={getLangText('emptyHereLabel')}
            text={getLangText('emptyListInteractionsLabel')}
        >
            <div className="table-wrapper">
                <div className="table-responsive">
                    <table className="table live-table">
                        <thead>
                        <tr>
                            <th
                                name="user"
                                onClick={updateSortOptions}
                                className={`${getSortClass('user')}`}
                            >
							<span name="user">
								{getLangText('userLabel')}
							</span>
                            </th>
                            <th
                                name="source"
                                onClick={updateSortOptions}
                                className={`text-right ${getSortClass('source')}`}
                            >
							<span name="source">
								{getLangText('sourceLabel')}
							</span>
                            </th>
                            <th
                                name="date"
                                onClick={updateSortOptions}
                                className={`text-right ${getSortClass('date')}`}
                            >
							<span name="date">
								{getLangText('dateLabel')}
							</span>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {items.map((item) => (
                            <tr
                                className="not-clicked-tr"
                                key={item.id}
                            >
                                <td className="name">
                                    <Link
                                        to={`${RouterPaths.ClientsList}/${item.shop_client_id}`}
                                        className="avatar-row hover:no-underline no-underline">
                                        <ClientAvatar
                                            photo={item.photo}
                                            type="instagram"
                                        />
                                        {item.name ? item.name : 'Brak nazwy'}
                                    </Link>
                                </td>
                                <td className={`d-lg-table-cell d-flex order-lg-0 order-4 text-right ${item.type ? '' : 'p-0'}`}>
                                    <div className="bg-[#F3F4F6] rounded-[9px] p-[3px] pl-[5px] inline-flex items-center">
                                        <span className="text-[#6B7280] text-[10px] leading-[10px] font-medium">
                                            {getLangText(getInteractionSourceLabel(item.type))}
                                        </span>
                                        <img
                                            className={`source-icon ml-1`}
                                            src={`/assets/images/svg/product-type/selmo.svg`}
                                            width={14}
                                            alt=""
                                        />
                                    </div>
                                </td>
                                <td className="date-td date text-right">
                                    <div>{convertToUserTimeZone(item.date).format('DD.MM.YYYY HH:mm')}</div>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </EmptyList>
    );
};

export default Table;
