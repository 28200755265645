import React from 'react';
import {useDispatch} from "react-redux";
import {
	createOrder,
	removeClient,
} from "../LiveDetailsActions";
import BasketStateModal from "./confirmModals/BasketStateModal";
import SummaryModal from "./confirmModals/BasketStateModal";
import RemoveUserConfirmModal from "./confirmModals/ConfirmModal";
import CreateOrderModal from "./confirmModals/ConfirmModal";
import PrintAllOrdersModal from "./confirmModals/PrintAllOrdersModal";
import {CONFIRM_MODAL_TYPES} from "./confirmModals/ConfirmModalTypes";
import AssignModal from "./assignModal/AssignModal";
import {addNewLiveModalToggleVisibility} from "../../../addNewLive/NewLiveModalActions";
import ClientsProductsContainer from "./ClientsProductsContainer";
import useLang from "../../../../../../src_shared/hooks/useLang";

const Clients = ({id, isAtLeastOneOrderExist}) => {
	const dispatch = useDispatch();
	const addNewLive = () => dispatch(addNewLiveModalToggleVisibility(true));
	const {getLangText} = useLang();

	return (
		<>
			{!!isAtLeastOneOrderExist &&
				<div className="default-shadow-box finished-live-box mb-3">
					<div className="d-flex flex-grow-1 align-items-center flex-sm-nowrap flex-wrap">
						<div className="flex-grow-1">
							<div className="title">{getLangText('liveEndedLiveTitle')}</div>
							<div className="desc">{getLangText('liveEndedLiveDescription')}</div>
						</div>
						<button
							className="button primary small-size w-sm-auto w-100 mt-sm-0 mt-3"
							type="button"
							onClick={addNewLive}
						>
							{getLangText('createButton')}
						</button>
					</div>
				</div>
			}
			<ClientsProductsContainer id={id}/>
			<AssignModal/>
			<CreateOrderModal
				onSave={() => dispatch(createOrder())}
				title={getLangText('createOrderButton')}
				desc={getLangText('askForCreateOrderLabel')}
				modalType={CONFIRM_MODAL_TYPES.CREATE_ORDER}
				saveButton={getLangText('createOrderButton')}
			/>
			<BasketStateModal
				title={getLangText('basketStateLabel')}
				formLabel={getLangText('currentBasketStateLabel')}
				saveButton={getLangText('sendBasketStateButton')}
				modalType={CONFIRM_MODAL_TYPES.BASKET_STATE}
			/>
			<SummaryModal
				title={getLangText('summaryLabel')}
				formLabel={getLangText('liveAddNewModalDateLabel')}
				saveButton={getLangText('sendSummaryButton')}
				modalType={CONFIRM_MODAL_TYPES.SUMMARY}
			/>
			<RemoveUserConfirmModal
				onSave={() => dispatch(removeClient())}
				title={getLangText('clientsRemoveClientTitle')}
				desc={getLangText('askForeMoveClientLabel')}
				modalType={CONFIRM_MODAL_TYPES.REMOVE_CLIENT}
				saveButton={getLangText('clientsRemoveClientTitle')}
				saveButtonClass="danger"
			/>
			<PrintAllOrdersModal
				title={getLangText('printOrdersLabel')}
				desc={getLangText('printOrdersLiveListLabel')}
				modalType={CONFIRM_MODAL_TYPES.PRINT_ALL_ORDERS}
				saveButton={getLangText('clientsRemoveClientTitle')}
				saveButtonClass="primary"
			/>
		</>
	);
};

export default Clients;
