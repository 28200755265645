export const PRODUCTS_MOBILE_APP_VISIBILITY_TYPE_ACTIVE_ALL = 'all';
export const PRODUCTS_MOBILE_APP_VISIBILITY_TYPE_ACTIVE = '1';
export const PRODUCTS_MOBILE_APP_VISIBILITY_TYPE_N0T_ACTIVE = '0';


export const PRODUCTS_MOBILE_APP_VISIBILITY_TYPES = [
	{
		value: PRODUCTS_MOBILE_APP_VISIBILITY_TYPE_ACTIVE_ALL, label: 'allLabel',
	},
	{
		value: PRODUCTS_MOBILE_APP_VISIBILITY_TYPE_ACTIVE, label: 'mobileAppVisibility',
	},
	{
		value: PRODUCTS_MOBILE_APP_VISIBILITY_TYPE_N0T_ACTIVE, label: 'mobileAppNotVisibility',
	},
]
