import React from 'react';
import Modal from "react-bootstrap/Modal";
import {services} from "../../../../RestServices";
import useLang from "../../../../../src_shared/hooks/useLang";
import useForm from "../../../../../src_shared/form/useForm";
import FormActions from "./FormActions";
import AsyncSelectField from "../../../../../src_shared/components/form/selectField/AsyncSelectField";
import Field from "../../../../../src_shared/components/form/field/Field";
import Loader from "../../../messenger/loader/Loader";

const AddCategoriesModal = ({modalVisible, hideModal}) => {

	const {getLangText} = useLang();

	const {
		data: {values, setValue, updateValues},
		form: {isWaiting, onSave, getFieldProps},
	} = useForm({
		FormActions,
		params: {},
		preventAutoLoad: true
	});

	return (
		<Modal
			dialogClassName="default-modal small-size"
			show={modalVisible}
			onHide={hideModal}
		>
			<Modal.Header className="with-border">
				<Modal.Title>
					{getLangText('createCategoryLabel')}
				</Modal.Title>
				<button
					onClick={hideModal}
					type="button"
					className="button"
				>
					<i className="icon-cross"/>
				</button>
			</Modal.Header>
			<Modal.Body className="pt-3 pb-5">
				<Field
					label={getLangText('nameLabel')}
					{...getFieldProps('name')}
					setValue={setValue}
				/>
				<AsyncSelectField
					{...getFieldProps('parent_id')}
					label={getLangText('superiorCategoryIfContainLabel')}
					className="default-size"
					setValue={setValue}
					rest={services.API_CATEGORIES}
					defaultValue={{id: values.parent_id, name: values.parent_name}}
				/>
				<div className="form-info-box align-items-center w-100 mt-2 small-line-height">
					<div>
						<i className="icon-info-c"/>
					</div>
					<div>
						<div className="title">
							{getLangText('selectOnlyWhenYouWantCreateSubCategoryLabel')}
						</div>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<button
					onClick={hideModal}
					type="button"
					className="button border-button"
				>
					{getLangText('cancelButton')}
				</button>
				<button
					onClick={onSave}
					type="button"
					className={`button primary px-4 ${isWaiting ? 'loading-button' : ''}`}
				>
					{isWaiting && <Loader/>}
					{getLangText('createCategoryLabel')}
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default AddCategoriesModal;
