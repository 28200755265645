import Model, {convertObjectProperties} from "../../src_shared/modules/model/Model";
import ValidationError from "../../src_shared/form/validation/ValidationError";
import {SHOP_ORIGIN_US} from "../../components/app/shared/enums/ShopOrigins";

const validatePhone = (value, data) => {
    if (data.only_digital) {
        return false;
    }
    const countryName = data.countries.find((i) => i.id === data.country)?.name;
    if (countryName && countryName.toLowerCase() === 'polska') {
        return ValidationError.combine(
            ValidationError.notEmpty,
            ValidationError.validatePhone,
            ValidationError.maxLength(9, 'Niepoprawny numer telefonu'),
        )(value, data)
    }
    return ValidationError.notEmpty(value);
}

export const validateZipCode = (value, data) => {
    if (data.only_digital) {
        return false;
    }
    const countryName = data.countries.find((i) => i.id === data.country)?.name;
    if (countryName && countryName.toLowerCase() === 'polska') {
        return ValidationError.combine(
            ValidationError.notEmpty,
            ValidationError.validateZipCode,
        )(value, data)
    }
    return ValidationError.notEmpty(value);
}

const checkDigitalProduct = (value, data) => {
    if (data.only_digital) {
        return false;
    }
    return ValidationError.notEmpty(value, data);
}

export class DeliveryAddressModel extends Model {
    getModel() {
        return {
            id: 0,
            first_name: '',
            last_name: '',
            email: '',
            country: '',
            street: '',
            building_number: '',
            city: '',
            zip_code: '',
            phone: '',
            extra_description: '',
            countries: [],
            nip: null,
            want_invoice: '0',
            state: null,
        };
    }

    getValidators() {
        return {
            first_name: checkDigitalProduct,
            last_name: checkDigitalProduct,
            email: ValidationError.combine(
                ValidationError.notEmpty,
                ValidationError.validateEmail,
            ),
            country: ValidationError.notEmpty,
            street: checkDigitalProduct,
            building_number: checkDigitalProduct,
            city: checkDigitalProduct,
            zip_code: validateZipCode,
            phone: validatePhone,
            state_name: ValidationError.skip,
            state: (value, data) => data?.country_name?.toLowerCase()?.includes('united states') ? ValidationError.notEmpty(value) : false,
            extra_description: ValidationError.skip,
            nip: (value, data) => ((data.country_name?.toLowerCase().includes('polska') && !!+data.want_invoice) && !+data.only_digital) ? ValidationError.validateNip(value) : ValidationError.skip(),
        };
    }

    parseDTO(data) {
        return convertObjectProperties(
            data,
            {},
            {
                want_invoice: !!data.nip
            },
        );
    }

    buildDTO(data) {
        if (!+data.want_invoice) {
            data.nip = null;
        }
        if (data.origin !== SHOP_ORIGIN_US) {
            delete data.state
        }
        delete data.countries;
        delete data.country_name;
        delete data.state_name;
        delete data.want_invoice;
        delete data.only_digital;
        delete data.origin;
        return data;
    }
}

export default new DeliveryAddressModel();
