import {getStateRoot, prefix} from "./formReducer.js";
import {services} from "../../../../RestServices.js";
import OrderModel from "../../../../../modules/models/order/OrderModel";
import FormActions from "../../../../../src_shared/form/FormActions";
import axios from "axios";
import {selmoUrl} from "../../../../../src_shared/api/api";
import {showAlert} from "../../../shared/components/alert/AlertActions";
import {RouterPaths} from "../../../../routes/RouterPath";
import api from "../../../../../services/axios/axios";

export class EditOrderFormActions extends FormActions {
	removeIntegrationBox(boxType) {
		return async (dispatch, getState) => {
			const {shippingData} = getStateRoot(getState()).data;
			const fieldToUpdate = () => {
				if (boxType === 'furgonetka') {
					return {
						shop_furgonetka_active: '0'
					}
				}
				if (boxType === 'fakturownia') {
					return {
						shop_fakturownia_active: '0'
					}
				}
				if (boxType === 'mondial') {
					return {
						shop_mondial_active: '0'
					}
				}
				return {
					shop_inpost_active: '0'
				}
			}
			try {
				await api.post(`${selmoUrl}/${services.API_ORDER_DISABLE_INTEGRATION}`, {
					type: boxType,
				})
				dispatch(this.data.updateValues({
					shippingData: {
						...shippingData,
						...fieldToUpdate()
					}
				}))
			} catch (e) {
				dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
			}
		}
	}

	afterDeleteSubmit(history) {
		return () => {
			history.push(RouterPaths.OrdersList)
		};
	}
}

export const getInstance = () => new EditOrderFormActions({
	getStateRoot,
	prefix,
	model: OrderModel,
	restService: services.API_ORDER,
});

export default getInstance();
