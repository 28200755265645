import {getStateRoot, prefix} from './listReducer';
import {services} from "../../../../RestServices";
import ListActions, {getGETParamsUrl} from "../../../../../src_shared/list/ListActions";
import {getStateRoot as getFiltersStateRoot} from "../../filters/filtersReducer";
import {convertDate} from "../../../shared/helpers/dateHelpers";
import history from "../../../../history";
import {RouterPaths} from "../../../../routes/RouterPath";

export class ExpendituresListActions extends ListActions {
	getLoadParams(state) {
		const params = super.getLoadParams(state);
		const dateFrom = getFiltersStateRoot(state).data.fromDate;
		const dateTo = getFiltersStateRoot(state).data.toDate;
		const reasons = getFiltersStateRoot(state).data.reasons;

		if (dateFrom) {
			params.dateFrom = convertDate(dateFrom);
		}

		if (dateTo) {
			params.dateTo = convertDate(dateTo);
		}

		if (reasons.length) {
			params['reasons[]'] = reasons
		}

		history.replace({
			pathname: this.pathname,
			search: getGETParamsUrl(params)
		});

		return params;
	}
}
export const getInstance = () => new ExpendituresListActions({
	getStateRoot,
	restService: services.API_WAREHOUSE_WAREHOUSE_EXPENDITURES,
	pathname: RouterPaths.ProductsExpendituresList,
	prefix,
});

export default getInstance();
