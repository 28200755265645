import React from 'react';
import Modal from "react-bootstrap/Modal";
import useForm from "../../../../../src_shared/form/useForm";
import FormActions from "./FormActions";
import ClientInput from "../../../live/details/default/components/ClientInput";
import {services} from "../../../../RestServices";
import {useSelector} from "react-redux";
import {createSelector} from "reselect";
import {getStateRoot as getOrderDetailStateRoot} from "../form/formReducer";
import AssignOrderTypes, {ASSIGN_ORDER_TYPE_EXIST} from "../../../shared/enums/AssignOrderTypes";
import moment from "moment";
import OrderStatus from "../../../shared/components/orderStatus/OrderStatus";
import AsyncSearchSelectField
	from "../../../../../src_shared/components/form/selectField/asyncSearchSelectField/AsyncSearchSelectField";
import Loader from "../../../messenger/loader/Loader";
import useLang from "../../../../../src_shared/hooks/useLang";
import {convertToUserTimeZone} from "../../../shared/helpers/dateHelpers";
import {useUnitPrice} from "../../../shared/helpers/Price";

const AssignCustomerModal = () => {
	const {
		client,
		shop_order_id,
		id
	} = useSelector(createSelector(getOrderDetailStateRoot, (stateRoot) => stateRoot.data));
	const {getLangText} = useLang();
	const {getPrice} = useUnitPrice();

	const {
		data: {values, setValue},
		form: {isWaiting, onSave, getFieldProps},
		modal: {modalVisible, hideModal},
		validation: {isActive}
	} = useForm({
		FormActions,
		params: {
			id,
		},
		preventAutoLoad: true,
	});

	const optionsParser = (options) => options?.map((i) => ({
		id: i.id,
		value: i.id,
		label: i.shop_order_id,
		date: i.date,
		status: i.status,
		price: i.total_price,
		currency_text: i.currency_text,
	}))

	const formatOptionLabel = (option) => {
		return (
			<div className="d-flex w-100">
				<div>
					<div className="name">{getLangText('customerBasketOrderLabel')} {option.label}</div>
					<div className="bottom-bar no-separator">
						{convertToUserTimeZone(option.date).format('DD.MM.YYYY HH:mm')}
						{' • '}
						{getPrice(option.price, option.currency_text)}
					</div>
				</div>
				<OrderStatus
					status={option.status}
					className="ml-auto"
				/>
			</div>
		)
	}

	const isVisibleOrderSelect = values.orderType === ASSIGN_ORDER_TYPE_EXIST && values.shop_client_id;

	return (
		<Modal
			show={modalVisible}
			dialogClassName="default-modal small-modal"
			onHide={hideModal}
		>
			<Modal.Header className="with-border">
				<Modal.Title>
					{getLangText('assignOrderNumberLabel')} {shop_order_id}
					<div className="sub-title">{getLangText('assignOrderToClientLabel')}</div>
				</Modal.Title>
				<button
					onClick={hideModal}
					type="button"
					className="button"
				>
					<i className="icon-cross"/>
				</button>
			</Modal.Header>
			<Modal.Body className="assign-order-modal-body">
				<div
					className="inputs-row with-arrow-bottom form-row single-client-row small-inputs default-form-label mt-0">
					<div className="product-input-col with-avatar">
						<ClientInput
							isLiveFinished={true}
							updateField={() => {
							}}
							customer={client}
							customerId={client.id}
							label={getLangText('currentClientLabel')}
						/>
					</div>
					<i className="icon-arrow-left"/>
				</div>

				<div className="inputs-row form-row single-client-row small-inputs default-form-label mt-0 mb-3">
					<div className="product-input-col with-avatar">
						<ClientInput
							name="shop_client_id"
							updateField={setValue}
							customer={values}
							customerId={values.id}
							label={getLangText('newClientLabel')}
							hasCustomerError={isActive}
							showErrorAlert
						/>
					</div>
				</div>
				<div className="select-boxes-wrapper">
					<div className="form-label">{getLangText('selectOrderTypeLabel')}</div>
					<div className="data-boxes-wrapper mb-3 extra-small-padd inline">
						{AssignOrderTypes.map((item) => (
							<div key={item.id}>
								<div
									className="content payment-content"
								>
									<div className="content__top-part">
										<input
											type="radio"
											name="clients"
											checked={values.orderType === item.id}
											onChange={() => setValue('orderType', item.id)}
										/>
										<span className="checkmark"/>
										<div className="flex-grow-1">
											{getLangText(item.label)}
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
				<div style={{display: isVisibleOrderSelect ? 'block' : 'none'}}>
					<AsyncSearchSelectField
						{...getFieldProps('new_order_id')}
						label={getLangText('selectExistingOrderLabel')}
						setValue={setValue}
						rest={`${services.API_CLIENT_ORDERS}/${values.shop_client_id}?order=${values.id}`}
						formatOptionLabel={formatOptionLabel}
						selectedOption={formatOptionLabel}
						defaultValue={{}}
						isSearchable={false}
						optionsParser={optionsParser}
						placeholder={getLangText('selectButton')}
						className="order-selection-select"
					/>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<button
					onClick={hideModal}
					type="button"
					className="button border-button"
				>
					{getLangText('cancelButton')}
				</button>
				<button
					type="button"
					className="button primary small-size"
					onClick={onSave}
				>
					{isWaiting &&
						<Loader />
					}
					{getLangText('assignButton')}
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default AssignCustomerModal;
