import {getStateRoot, prefix} from './listReducer.js';
import {services} from "../../../../../RestServices.js";
import {RouterPaths} from "../../../../../routes/RouterPath.js";
import ShownSharedListActions from "../shared/ShownSharedListActions";

export class LivePlatformNotShownListActions extends ShownSharedListActions {

}

export const getInstance = () => new LivePlatformNotShownListActions({
	getStateRoot,
	restService: services.API_LIVE_NO_SHOWN_PRODUCTS,
	pathname: RouterPaths.LivePlatformNotShownList,
	preventPushParams: true,
	prefix,
});

export default getInstance();
