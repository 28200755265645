import React from 'react';
import {Link, NavLink} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import useLang from "../../../../src_shared/hooks/useLang";
import useWindowDimensions from "../../messenger/hooks/useWindowDimensions/useWindowDimensions";
import Profile from "../header/Profile";
import ServerImage from "../components/serverImage/ServerImage";
import SubMenu from "./SubMenu";
import {SETTINGS_SHOP} from "../../../settings/general/dashboard/GeneralBoxes";
import SettingsAsideItem from "../../../settings/components/SettingsAsideItem";
import {SETTINGS_PAYMENTS_SHIPMENTS} from "../../../settings/shippings/dashboard/PaymentShipmentBoxes";
import {SETTINGS_META} from "../../../settings/meta/dashboard/MetaBoxes";
import {SETTINGS_PROFILE} from "../../../settings/account/dashboard/ProfileBoxes";
import {SETTINGS_INTEGRATIONS} from "../../../settings/integrations/dashboard/IntegrationBoxes";

const SettingsAside = ({userData}) => {
    const {getLangText} = useLang();
    const {width} = useWindowDimensions();

    const body = document.querySelector('body')

    const toggleMobileMenu = () => {
        if (body.classList.contains('open-menu')) {
            body.classList.remove('open-menu')
        } else {
            body.classList.add('open-menu')
        }
    }

    const closeMobileMenu = () => {
        body.classList.remove('open-menu');
    }

    return (
        <aside className="main-aside">
            <div>
                <Link
                    to="/"
                    className="brand-name"
                >
                    <img
                        src="/assets/images/logo.svg"
                        alt="logo-selmo"
                    />
                </Link>
                <div
                    onClick={toggleMobileMenu}
                    className="hamburger-btn"
                >
                    <div className="bar1"/>
                    <div className="bar3"/>
                </div>
                {width < 991 &&
                    <div className="right-aside-wrapper">
                        <Profile userData={userData}/>
                    </div>
                }
                <Link
                    onClick={closeMobileMenu}
                    to="/"
                    className="shop-info-box"
                >
                    <div>
                        <figure>
                            {userData.logo && userData.logo !== '0'
                                ? <ServerImage src={userData.logo}
                                               alt={userData.name}/> :
                                <img src="/assets/images/default/shop.svg" alt={userData.name}/>}
                        </figure>
                    </div>
                    {userData.name}
                </Link>
                <ul>
                    <li className="pb-1 mb-2 border-b">
                        <Link
                            onClick={closeMobileMenu}
                            to="/"
                            className="go-back"
                        >
                            <i className="icon-arrows"/>
                            {getLangText('settings_menu_go_back')}
                        </Link>
                    </li>
                    <li>
                        <NavLink onClick={closeMobileMenu}
                                 activeClassName="active"
                                 to={RouterPaths.Settings}
                                 exact
                        >
                            <i className="icon-settings"/>
                            {getLangText('settings_menu_settings')}
                        </NavLink>
                    </li>
                    <SubMenu
                        includeRoute={RouterPaths.SettingsShop}
                        route={RouterPaths.SettingsShop}
                        name={getLangText('settings_menu_shop')}
                        icon="icon-home"
                    >
                        {SETTINGS_SHOP.map((item) => (
                            <SettingsAsideItem
                                item={item}
                                key={item.id}
                                closeMobileMenu={closeMobileMenu}
                            />
                        ))}
                    </SubMenu>
                    <SubMenu
                        includeRoute="/settings/payments"
                        route={RouterPaths.SettingsPaymentsShipments}
                        name={getLangText('settings_menu_payment_shipping')}
                        icon="icon-packed"
                    >
                        {SETTINGS_PAYMENTS_SHIPMENTS.map((item) => (
                            <SettingsAsideItem
                                item={item}
                                key={item.id}
                                closeMobileMenu={closeMobileMenu}
                            />
                        ))}
                    </SubMenu>
                    <SubMenu
                        iconStyles={{fontSize: 18, top: 0}}
                        includeRoute={RouterPaths.SettingsMeta}
                        route={RouterPaths.SettingsMeta}
                        name={getLangText('socialMediaChannels')}
                        icon="icon-meta-small"
                    >
                        {SETTINGS_META.map((item) => (
                            <SettingsAsideItem
                                item={item}
                                key={item.id}
                                closeMobileMenu={closeMobileMenu}
                            />
                        ))}
                    </SubMenu>
                    <SubMenu
                        includeRoute={RouterPaths.SettingsIntegration}
                        route={RouterPaths.SettingsIntegration}
                        name={getLangText('settings_menu_integrations')}
                        icon="icon-integrations"
                        iconStyles={{fontSize: 15}}
                    >
                        {SETTINGS_INTEGRATIONS.map((item) => (
                            <SettingsAsideItem
                                item={item}
                                key={item.id}
                                closeMobileMenu={closeMobileMenu}
                            />
                        ))}
                    </SubMenu>
                    <SubMenu
                        includeRoute={RouterPaths.SettingsAccount}
                        route={RouterPaths.SettingsAccount}
                        name={getLangText('settings_menu_profile')}
                        icon="icon-profile"
                    >
                        {SETTINGS_PROFILE.map((item) => (
                            <SettingsAsideItem
                                item={item}
                                key={item.id}
                                closeMobileMenu={closeMobileMenu}
                            />
                        ))}
                    </SubMenu>
                </ul>
            </div>
        </aside>
    );
};

export default SettingsAside;
