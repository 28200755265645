import Model from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

class SettingsPickupModel extends Model {
	getModel() {
		return {
			pickup_price: 0,
			pickup_active: '0',
		};
	}

	getValidators() {
		return {
			pickup_price: ValidationError.skip,
			pickup_active: ValidationError.skip,
		};
	}

	buildDTO(data) {
		const {pickup_price, pickup_active} = data;
		return {
			pickup_price,
			pickup_active
		}
	}
}

export default new SettingsPickupModel();
