import * as React from "react"
const OwnerIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        style={{
            enableBackground: "new 0 0 34.768 32",
        }}
        viewBox="0 0 34.768 32"
        width={34}
        height={32}
        {...props}
    >
        <path
            d="M31.514 5.67a6.057 6.057 0 0 0-4.29-1.74h-2.95c-.35-2.17-2.34-3.84-4.77-3.84h-4.25c-1.27 0-2.46.46-3.37 1.28-.77.69-1.24 1.59-1.39 2.56h-2.94c-3.35 0-6.07 2.67-6.08 5.95v5.41c0 .41.21.8.56 1.02 3.36 2.14 7.23 3.54 11.21 4.07.6.07 1.16-.3 1.32-.88.41-1.5 2.01-2.39 3.55-1.98 1 .26 1.78 1.02 2.05 1.99.14.53.62.89 1.16.89.05 0 .1 0 .16-.01 3.99-.52 7.88-1.93 11.25-4.08.35-.22.56-.61.56-1.02V9.9c0-1.6-.63-3.1-1.78-4.23zm-18.01-2.51c.46-.42 1.09-.65 1.76-.65h4.24c1.07 0 1.97.6 2.29 1.42h-8.82c.11-.29.28-.55.53-.77zm17.37 11.46a25.215 25.215 0 0 1-8.78 3.24 5.34 5.34 0 0 0-4.73-2.86c-1.98 0-3.82 1.1-4.73 2.84-3.08-.52-6.08-1.62-8.74-3.22V9.89c0-1.96 1.64-3.55 3.66-3.55h19.69c.97.01 1.89.38 2.58 1.05.68.67 1.05 1.56 1.05 2.5v4.73zm-13.49 5.925c.667 0 1.207.54 1.207 1.207v2.556a1.206 1.206 0 1 1-2.415 0v-2.556c0-.667.541-1.207 1.208-1.207zm15.872 1.301.031.003c-.001.001-.224 2.807-.281 3.691a7.458 7.458 0 0 1-1.521 4.104c-1.23 1.55-3.102 2.388-5.044 2.248l-18.2.003c-.128.01-.256.014-.384.014-1.725 0-3.433-.82-4.564-2.247a7.45 7.45 0 0 1-1.535-4.131c-.052-.88-.277-3.681-.277-3.682l.019-.002c-.011-.065-.039-.124-.039-.192a1.209 1.209 0 1 1 2.418 0v.001l.009-.001s.228 2.84.281 3.734a5.032 5.032 0 0 0 1.029 2.787 3.51 3.51 0 0 0 2.96 1.307l18.368-.003c1.221.082 2.334-.412 3.055-1.32.6-.786.956-1.751 1.016-2.77.057-.883.274-3.621.282-3.725l-.002-.011a1.209 1.209 0 1 1 2.418 0c-.001.069-.028.127-.039.192z"
            // style={{
            //     fill: "#3c61ea",
            // }}
        />
    </svg>
)
export default OwnerIcon
