import {useCallback, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import useWaiting from "../list/waiting/useWaiting";
import useLoadData from '../dataLoader/useLoadData';
import useData from './data/useData';
import {useHistory} from "react-router-dom";
import useValidation from "./validation/useValidation";
import useModal from "../modal/useModal";

export const useClearState = (beforeDestroy) => {
	useEffect(() => () => {
		beforeDestroy();
	}, []);
};

export const useFormActions = (FormActions) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const loadData = useCallback(
		(id) => dispatch(FormActions.loadData(id)),
		[dispatch, FormActions],
	);

	const resetData = useCallback(
		() => dispatch(FormActions.resetData()),
		[dispatch, FormActions],
	);

	const submitEditForm = useCallback(
		(formData) => dispatch(FormActions.submitEditForm(formData, history)),
		[dispatch, FormActions],
	);

	const deleteWithoutAsk = useCallback(
		() => dispatch(FormActions.deleteWithoutAsk(history)),
		[dispatch, FormActions],
	);

	const reset = useCallback(() => dispatch(FormActions.reset()),
		[dispatch, FormActions],
	);

	const stopWaiting = useCallback(
		() => dispatch(FormActions.stopWaiting()),
		[dispatch, FormActions],
	);

	return {
		loadData,
		resetData,
		submitEditForm,
		reset,
		stopWaiting,
		deleteWithoutAsk,
	};
};

export default function useForm(config) {
	const {
		FormActions,
		beforeDestroy,
		params,
		preventAutoLoad,
		validateErrors: configValidateErrors,
		requireParentId = true,
	} = config;

	const {getStateRoot, dataModel} = FormActions;
	const validateErrors = configValidateErrors || (dataModel && dataModel.getValidators()) || {};
	const dispatchActions = useFormActions(FormActions, getStateRoot);
	const validation = useValidation(FormActions.validation, getStateRoot);
	const data = useData(FormActions.data, getStateRoot);
	const waiting = useWaiting(FormActions.waiting, getStateRoot);
	const modal = useModal(FormActions.modal, getStateRoot)

	const onSave = () => dispatchActions.submitEditForm(data.values);
	const deleteWithoutAsk = () => dispatchActions.deleteWithoutAsk();
	const getFieldProps = (source, extraProps = {}) => {

		return {
			name: source,
			value: data.values[source],
			getError: validateErrors[source],
			validationData: data.values,
			validateErrors: validation.isActive,
			...extraProps,
		};
	};

	useLoadData({
		loadData: dispatchActions.loadData,
		preventAutoLoad,
		id: params.id,
		name: params.name,
		dataPreloaded: data.isPreloaded,
		requireParentId,
	});

	useClearState(beforeDestroy || dispatchActions.reset);

	return {
		validation: {
			...validation,
			validateErrors,
		},
		data,
		form: {
			isWaiting: waiting.waiting,
			loadData: dispatchActions.loadData,
			resetData: dispatchActions.resetData,
			reset: dispatchActions.reset,
			onSave,
			getFieldProps,
			deleteWithoutAsk,
		},
		modal,
	};
}
