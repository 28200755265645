import ListReducer from "../../../../../../../src_shared/list/ListReducer.js";
import createReducer from "../../../../../shared/helpers/createReducer.js";
import Composition from "../../../../../shared/helpers/Composition.js";
import {createNewId} from "../../liveDetailsReducer.js";

export const getStateRoot = (state) => state.lives.productsList;
export const prefix = 'LIVES_DETAILS_PRODUCTS_LIST_';

const getInitState = () => ListReducer.getInitState({
	itemsPerPage: 10,
	scrollPage: 1,
	hasMore: true,
	validationActive: false,
	waitingForAdding: false,
});

const getReduceMap = () => new Composition(
	ListReducer.getReduceMap(),
	{
		SET_SCROLL_PAGE: (state, action) => ({...state, scrollPage: action.scrollPage}),
	},
	{
		SET_HAS_MORE_PAGES: (state, action) => ({...state, hasMore: action.toggle}),
	},
	{
		SET_WAITING_FOR_ADDING: (state, action) => ({...state, waitingForAdding: action.toggle}),
	},
	{
		SET_VALIDATION_ACTIVE: (state, action) => ({...state, validationActive: action.toggle}),
	},
	{
		SET_CUSTOMER_DUPLICATED_ID: (state, action) => ({
			...state,
			items: state.items.map((item) => item.id === action.productId ? {
				...item,
				customerDuplicatedId: action.duplicatedId,
			} : item)
		}),
	},
	{
		UPDATE_FIELD_IN_CLIENT: (state, action) => ({
			...state,
			items: state.items.map((item) => item.id === action.productId ? {
				...item,
				clients: item.clients.map((i) => i.live_products_id === action.clientId ? {
					...i,
					[action.field]: action.value
				} : i)
			} : item)
		}),
	},
	{
		ADD_NEW_CUSTOMER: (state, action) => {
			return {
				...state,
				items: state.items.map((item) => item.id === action.productId ? {
					...item,
					clients: [...item.clients, {
						id: null,
						name: '',
						color: '',
						size: '',
						quantity: 1,
						variant_id: null,
						is_deleted: '0',
						live_products_id: createNewId()
					}]
				} : item)
			}
		},
	},
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

