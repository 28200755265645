export const ORDER_MR_AUTOMATE_PAUSED_ALL = 'all';
export const ORDER_MR_AUTOMATE_PAUSED = '1';
export const ORDER_MR_AUTOMATE_NOT_PAUSED = '0';


export const ORDER_MR_AUTOMATE_PAUSED_STATUSES = [
	{
		value: ORDER_MR_AUTOMATE_PAUSED_ALL, label: 'allLabel', color: 'waiting',
	},
	{
		value: ORDER_MR_AUTOMATE_PAUSED, label: 'paused', color: 'waiting',
	},
	{
		value: ORDER_MR_AUTOMATE_NOT_PAUSED, label: 'unPaused', color: 'waiting',
	},
]
export const findOrderMrAutomatePausedStatusById = (id) => ORDER_MR_AUTOMATE_PAUSED_STATUSES.find((status) => status.value === id);
