import React, {useState} from 'react';
import Modal from "react-bootstrap/Modal";
import Loader from "../../../shared/Loader";
import axios from "axios";
import {selmoUrl} from "../../../../../src_shared/api/api";
import {services} from "../../../../RestServices";
import {useDispatch} from "react-redux";
import _ from "lodash";
import SelectField from "../../../../../src_shared/components/form/selectField/SelectField";
import useDataApi from "../../../../../src_shared/hooks/useDataApi";
import {showAlert} from "../alert/AlertActions";
import useLang from "../../../../../src_shared/hooks/useLang";
import api from "../../../../../services/axios/axios";

const DiscountCodeModal = ({modalVisible, hideModal, values, loadData}) => {
	const dispatch = useDispatch();
	const {getLangText} = useLang();
	const [waiting, setWaiting] = useState(false);
	const [value, setValue] = useState({
		id: values.discount_code_info.id,
		name: values.discount_code_info.name,
	});

	const [{data, isLoading}] = useDataApi(`${services.API_ORDER_DISCOUNT_CODE}/${values.id}`, [])

	const setDiscountCode = async () => {
		setWaiting(true);
		try {
			await api.put(`${selmoUrl}/${services.API_ORDER_DISCOUNT_CODE}/${values.id}`, {
				name: data.find((i) => i.id === value.id)?.name
			})
			dispatch(showAlert('introducedDiscountCodeLabel', ''));
			loadData(values.shop_order_id)
			hideModal();

		} catch (e) {
			dispatch(showAlert('discountCodeFailTitle', '', 'alert-fail'))
		}
		finally {
			setWaiting(false);
		}
	}

	const removeDiscountCode = async () => {
		try {
			await api.delete(`${selmoUrl}/${services.API_ORDER_DISCOUNT_CODE}/${values.id}`)
			dispatch(showAlert('discountCodeRemovedTitle', ''));
			loadData(values.shop_order_id)
			setValue('')
			hideModal();

		} catch (e) {
			console.error('Fail delete discount code')
		}
	}

	const preparedOptions = data.map((i) => ({
		label: i.name,
		value: i.id,
		amount: i.value,
		type: i.type
	}))

	const formatOptionLabel = (value) => (
		<div className="select-custom-option">
			{!value.value ?
				<div className="placeholder">{getLangText('selectButton')}</div> :
				<>
					<div className="name">{value.label}</div>
					<div className="bottom-bar no-separator">
						{value.amount ?
							`${value.amount} ${value.type === '1' ? values?.currency?.text : '%'}` :
							getLangText('settings_general_free_shipping')
						}
					</div>
				</>
			}

		</div>
	)

	return (
		<Modal
			show={modalVisible}
			className="add-order-modal"
			dialogClassName="default-modal sm-size"
			onHide={hideModal}
		>
			<Modal.Header className="with-border">
				<Modal.Title>{getLangText('editDiscountCodeLabel')}</Modal.Title>
				<button
					onClick={hideModal}
					type="button"
					className="button"
				>
					<i className="icon-cross"/>
				</button>
			</Modal.Header>
			<Modal.Body>
				<div className="loader-parent min-height-auto pb-3">
					<Loader isLoading={isLoading}/>
					<div className="form-group discount-code-field mb-sm-0 mb-3">
						<SelectField
							label={getLangText('discountCodeLabel')}
							className="default-size mb-0"
							placeholder={getLangText('selectButton')}
							name="discount_code"
							options={preparedOptions}
							setValue={(field, newValue) => setValue({...value, id: newValue})}
							value={value.id}
							formatOptionLabel={formatOptionLabel}
						/>
					</div>
					{!_.isEmpty(values.discount_code_info) &&
						<button
							onClick={removeDiscountCode}
							type="button"
							className="button text-only small-size with-icon mt-sm-3"
						>
							<i className="icon-cross" />
							{getLangText('removeDiscountCodeFromOrderLabel')}
						</button>
					}
				</div>
			</Modal.Body>
			<Modal.Footer>
				<button
					onClick={hideModal}
					type="button"
					className="button border-button ml-auto"
				>
					{getLangText('cancelButton')}
				</button>
				<button
					type="button"
					className={`button primary ${waiting ? 'loading-button' : ''}`}
					onClick={setDiscountCode}
				>
					{waiting && <Loader/>}
					{getLangText('saveButton')}
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default DiscountCodeModal;
