import {getStateRoot, prefix} from "./formReducer.js";
import {services} from "../../../../RestServices.js";
import FormActions from "../../../../../src_shared/form/FormActions";
import {batch} from "react-redux";
import {showAlert} from "../../../shared/components/alert/AlertActions";
import AssignProductsModel from "../../../../../modules/models/category/AssignProductsModel";
import ProductsInCategoryListActions from "../products/list/ListActions";
import axios from "axios";
import {selmoUrl} from "../../../../../src_shared/api/api";
import api from "../../../../../services/axios/axios";

export class AssignProductsModalActions extends FormActions {
	showModal(id) {
		return (dispatch) => {
			batch(() => {
				dispatch(this.data.setValue('id', id))
				dispatch(this.modal.showModal());
			})
		}
	}

	submitEditForm(values, history) {
		return async (dispatch) => {
			const {id} = values;

			dispatch(this.validation.activateValidation());

			if (!this.dataModel.validateData(values)) {
				dispatch(showAlert('fillRequireFieldWarning', '', 'alert-fail'))
				console.error('Validation failed!');
				return;
			}

			const filteredData = Object.entries(values).filter(([key]) => key !== 'id');
			const finalData = Object.fromEntries(filteredData)

			const preparedData = this.dataModel.buildDTO(finalData)

			dispatch(this.waiting.startWaiting());

			try {
				await api.post(`${selmoUrl}/${this.restService}`, preparedData)
				dispatch(this.afterSubmit(id))
				dispatch(showAlert())
				dispatch(this.validation.deactivateValidation())
			} catch (e) {
				dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
				console.error('Error submitEditForm')
			} finally {
				dispatch(this.waiting.stopWaiting());
			}
		}
	}

	afterSubmit(id) {
		return (dispatch) => {
			batch(() => {
				dispatch(showAlert('yuhuLabel', 'Produkty zostały przypisane do wybranej kategorii'))
				dispatch(this.modal.hideModal())
				dispatch(ProductsInCategoryListActions.loadData(id))
			})
		}
	}
}

export const getInstance = () => new AssignProductsModalActions({
	getStateRoot,
	prefix,
	model: AssignProductsModel,
	restService: services.API_ASSIGN_PRODUCTS_TO_CATEGORY,
	route: null,
});

export default getInstance();
