import React, {useEffect} from 'react';
import Modal from "react-bootstrap/Modal";
import useLang from "../../../../../src_shared/hooks/useLang";
import Field from "../../../../../src_shared/components/form/field/Field";
import Loader from "../../../shared/Loader";
import useFormLocal from "../../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../../RestServices";
import {useParams} from "react-router-dom";
import Model from "../../../../../src_shared/modules/model/Model";
import {useDispatch, useSelector} from "react-redux";
import EditProductFormActions from "../edit/FormActions";
import {getCurrenciesByShortcut} from "../../../stats/list/List";

const VariantsPriceModal = ({show, hide, values}) => {
    const {getLangText} = useLang();
    const {id} = useParams();
    const dispatch = useDispatch();
    const {currency_info} = useSelector((state) => state.session.userData);

    const afterSubmit = () => {
        hide();
        dispatch(EditProductFormActions.loadData(id))
    }

    const [{
        onSave,
        isWaiting,
        getFieldProps,
        setValue,
        isLoading,
    }] = useFormLocal({
        rest: `${services.API_STORAGE_PRODUCT_SIZE_PRICE}/${id}`,
        model: new Model(),
        afterSubmit,
        initialAutoLoad: false,
        method: 'put'
    });

    useEffect(() => {
        if (values.price) {
            setValue('price', values.price)
        }
    }, [values.price])

    return (
        <Modal
            dialogClassName="default-modal w-420"
            show={show}
            onHide={hide}
        >
            <Modal.Header className="with-border">
                <Modal.Title>
                    {getLangText('setVariantsPriceLabel')}
                    <div className="text-desc font-medium text-sm">
                        {getLangText('setTheSamePriceForAllVariantsLabel')}
                    </div>
                </Modal.Title>
                <button
                    onClick={hide}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            <div className="loader-parent">
                <Loader isLoading={isLoading}/>
                <Modal.Body>
                    <Field
                        {...getFieldProps('price')}
                        label={getLangText('priceLabel')}
                        addon={getCurrenciesByShortcut(currency_info?.text)}
                        type="number"
                        placeholder="0.00"
                    />
                </Modal.Body>
                <Modal.Footer className="justify-content-start">
                    <button
                        type="button"
                        className="button border-button ml-auto"
                        onClick={hide}
                    >
                        {getLangText('cancelButton')}
                    </button>
                    <button
                        type="button"
                        className={`button primary ${isWaiting ? 'loading-button' : ''}`}
                        onClick={onSave}
                    >
                        <Loader isLoading={isWaiting}/>
                        {getLangText('saveButton')}
                    </button>
                </Modal.Footer>
            </div>
        </Modal>
    )
        ;
};

export default VariantsPriceModal;
