import React from 'react';
import {getProductCount, isOutOfStock} from "../../../messenger/addOrderModal/components/hintDropdown.jsx";
import useLang from "../../../../../src_shared/hooks/useLang";
import ServerImage from "../../../shared/components/serverImage/ServerImage";

const ProductDropdown = ({visibleDropdown, storageProducts, onHintSelect}) => {
	const {getLangText} = useLang();

	return (
		<div className="custom-hint-dropdown max-height-dropdown">
			{visibleDropdown && !!storageProducts.length && storageProducts.map((hintProduct) => (
				<div
					onClick={() => onHintSelect(hintProduct)}
					className={`select-custom-option with-img ${isOutOfStock(hintProduct) ? 'disabled-bg' : ''}`}
					key={hintProduct.id}
				>
					<div className="d-flex align-items-center">
						<figure className="user-avatar">
							{hintProduct.photo_small && hintProduct.photo_small !== '0' ?
								<ServerImage src={hintProduct.photo_small}/> :
								<img src="/assets/images/default/product.svg"/>
							}
						</figure>
						<div className="name">
							{hintProduct.name}
							<div className="client-number">
								{getProductCount(hintProduct, getLangText)}
								{` • `}
								{getLangText('codeLabel')}: {hintProduct.selmo_code}
							</div>
						</div>
					</div>
				</div>
			))}
		</div>
	);
};

export default ProductDropdown;
