import Composition from "../../../shared/helpers/Composition";
import createReducer from "../../../shared/helpers/createReducer";
import FormReducer from "../../../../../src_shared/form/FormReducer";
import MobileReelsModel from "../../../../../modules/models/reels/MobileReelsModel";

export const getStateRoot = (state) => state.products.mobileReels.form;
export const prefix = 'MOBILE_REELS_FORM_';

export const getInitState = () => FormReducer.getInitState(MobileReelsModel.getModel())

const getReduceMap = () => new Composition(
	FormReducer.getReduceMap(),
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();
