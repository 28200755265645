import Model from "../../../src_shared/modules/model/Model";

class OrlenPaczkaLabelModel extends Model {
	getModel() {
		return {
			type: 'S',
		};
	}
}

export default new OrlenPaczkaLabelModel();
