import React from 'react';
import {Route} from "react-router-dom";
import NewsPage from "../app/news/NewsPage";
import NewsList from "../app/news/list/List";
import Layout from "../app/Layout";

const NewsRoute = ({path}) => {

	return (
		<Route
			exact
			path={path}
		>
			<NewsPage>
				<NewsList/>
			</NewsPage>
		</Route>
	);
};

export default NewsRoute;
