import Composition from "../../shared/helpers/Composition";
import createReducer from "../../shared/helpers/createReducer";
import FormReducer from "../../../../src_shared/form/FormReducer";

export const getStateRoot = (state) => state.salesPost.stats;
export const prefix = 'SALES_POST_STATS_';

export const getInitState = () => FormReducer.getInitState()

const getReduceMap = () => new Composition(
	FormReducer.getReduceMap(),
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();
