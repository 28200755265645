import React, {useState} from 'react';
import VariantFilter from "../variantFilter/VariantFilter";
import SearchInput from "../../../../shared/components/form/SearchInput";
import DateRangePicker from "../../../../shared/components/form/DateRangePicker";
import useWindowDimensions from "../../../../messenger/hooks/useWindowDimensions/useWindowDimensions";
import FiltersActions from "../../filters/FiltersActions";
import {getStateRoot as getFiltersStateRoot} from "../../filters/filtersReducer";
import {getGETParamsUrl} from "../../../../../../src_shared/list/ListActions";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {convertDate} from "../../../../shared/helpers/dateHelpers";
import useLang from "../../../../../../src_shared/hooks/useLang";
import {ORDER_STATUES} from "../../../../shared/enums/OrderStatuses";
import StatusFilter from "../../../../../../src_shared/components/filters/statusFilter/StatusFilter";
import {useUnitPrice} from "../../../../shared/helpers/Price";
import {ProductsSourceTypes} from "../../../../shared/enums/ProductSourceType";
import {WIKA_EWA_FASHION_ID} from "../../../../../../src_shared/enums/TrustedShopsIds";
import WaitingForSent from "../../../../../../src_shared/components/ui/waitingForSent/WaitingForSent";
import {services} from "../../../../../RestServices";
import CustomDropdown from "../../../../shared/components/dropdown/CustomDropdown";
import ExportModal from "../../../../../../src_shared/components/exportModal/ExportModal";
import {EXPENDITURES_LIST_PRODUCT_VIEW, REMOVED_LIST_PRODUCT_VIEW} from "../../../../shared/enums/EditProductViews";
import {USER_PERMISSIONS_PRODUCT_TOTAL_PRICES_VISIBILITY} from "../../../../shared/enums/UserPermissionsTypes";
import UserPermissionsWrapper from "../../../../../routes/UserPermissionsWrapper";
import SingleSelectFiltersStatus
    from "../../../../../../src_shared/components/filters/singleSelectFiltersStatus/SingleSelectFiltersStatus";
import {
    PRODUCTS_RESERVE_LIST_REMOVED_FROM_TYPES_TYPES
} from "../../../../shared/enums/ProductsReserveListRemovedFromAppTypes";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../../routes/RouterPath";

const PDF_EXPORT_MODAL = 'PDF_EXPORT_MODAL';
const PDF_CLIENTS_BOUGHT_EXPORT_MODAL = 'PDF_CLIENTS_BOUGHT_EXPORT_MODAL';
const PDF_CLIENTS_CURRENT_EXPORT_MODAL = 'PDF_CLIENTS_CURRENT_EXPORT_MODAL';


const Header = ({data, totalItems, ListActions, params, search, title, totalPrice, type, waitingForSend, quantity}) => {

    const {userData} = useSelector((state) => state.session);
    const {width} = useWindowDimensions();
    const [isFiltersVisible, setFiltersVisible] = useState(false);
    const {getLangText} = useLang();
    const {getPrice} = useUnitPrice();
    const dispatch = useDispatch();
    const [openExportModal, setOpenExportModal] = useState('');

    const {
        statuses,
        fromDate,
        toDate,
        deleted
        // eslint-disable-next-line react-hooks/rules-of-hooks
    } = useSelector(createSelector(getFiltersStateRoot, (stateRoot) => stateRoot.data));

    const preparedDateParams = () => {
        const listParams = {}

        if (fromDate) {
            listParams.dateFrom = convertDate(fromDate);
        }

        if (toDate) {
            listParams.dateTo = convertDate(toDate);
        }

        if (statuses.length) {
            listParams['status[]'] = statuses
        }

        if (!!+deleted) {
            listParams.deleted = deleted
        }

        if (search.searchText) {
            listParams.superSearch = search.searchText;
        }

        return getGETParamsUrl(listParams);
    }

    return (
        <div className="table-header">
            <div className="top-part w-lg-auto w-100 mb-lg-auto mb-2">
                <div className="flex-grow-1 d-flex align-items-center">
                    <div className="flex-grow-1">
                        <h1 className="title w-auto flex-lg-grow-0 flex-grow-1">
                            {title}
                            {totalItems > 0 && width > 767 &&
                                <div className="badge">{totalItems}</div>
                            }
                        </h1>
                        <UserPermissionsWrapper allowedPermissions={[USER_PERMISSIONS_PRODUCT_TOTAL_PRICES_VISIBILITY]}>
                            {((!!+totalPrice || !!+quantity) && +userData.id !== WIKA_EWA_FASHION_ID) &&
                                <div className="flex items-center space-x-2">
                                    <div className="total-price extra-small-text">
                                        {getLangText('totalPriceLabel')}:&nbsp;
                                        <strong>{getPrice(totalPrice)}</strong>
                                    </div>
                                    {(!!+quantity && !!+totalPrice) &&
                                        <span className="text-[8px] font-normal text-desc">•</span>
                                    }
                                    {!!+quantity &&
                                        <div className="total-price extra-small-text">
                                                    <span
                                                        className="capitalize">{getLangText('piecesGenitiveLabel')}</span>:&nbsp;
                                            <strong>{quantity}</strong>
                                        </div>
                                    }
                                </div>
                            }
                        </UserPermissionsWrapper>
                    </div>
                    {type === 'RESERVE_LIST' && waitingForSend &&
                        <WaitingForSent
                            small
                            loadData={() => dispatch(ListActions.loadData(params.id))}
                            rest={`${services.API_STORAGE_SEND_BATCH_RESERVE_LIST_STATUS}/${params.id}`}
                        />
                    }
                </div>
                {type === 'FINISHED_CART' && width < 767 &&
                    <CustomDropdown
                        ButtonToggle={() => <span className="d-md-none">{getLangText('exportLabel')}</span>}
                        icon="icon-print"
                        className="ml-sm-auto"
                        buttonClassName="mr-sm-3 default-size"
                        tooltipTitle={getLangText('exportLabel')}
                    >
                        <button
                            type="button"
                            onClick={() => setOpenExportModal(PDF_EXPORT_MODAL)}
                            className="custom-option"
                        >
                            {getLangText('exportPurchasedListLabel')}
                        </button>
                        <button
                            type="button"
                            onClick={() => setOpenExportModal(PDF_CLIENTS_BOUGHT_EXPORT_MODAL)}
                            className="custom-option"
                        >
                            {getLangText('printClientsPDFLabel')}
                        </button>
                    </CustomDropdown>
                }
                {type === 'CURRENT_CART' && width < 767 &&
                    <CustomDropdown
                        ButtonToggle={() => <span className="d-md-none">{getLangText('exportLabel')}</span>}
                        icon="icon-print"
                        className="ml-sm-auto"
                        buttonClassName="mr-sm-3 default-size"
                        tooltipTitle={getLangText('exportLabel')}
                    >
                        <button
                            type="button"
                            onClick={() => setOpenExportModal(PDF_CLIENTS_CURRENT_EXPORT_MODAL)}
                            className="custom-option"
                        >
                            {getLangText('printClientsPDFLabel')}
                        </button>
                    </CustomDropdown>
                }
                <button
                    type="button"
                    className='toggle-filters with-text'
                    onClick={() => setFiltersVisible(!isFiltersVisible)}
                >
                    <i className='icon-filter'/>
                    {getLangText('filterButton')}
                </button>
            </div>
            {(width > 767 || isFiltersVisible) &&
                <div className="filters-part mt-3">
                    {(type !== 'RESERVE_LIST' && type !== EXPENDITURES_LIST_PRODUCT_VIEW) &&
                        <StatusFilter
                            ListActions={ListActions}
                            FiltersActions={FiltersActions}
                            getFiltersStateRoot={getFiltersStateRoot}
                            options={ProductsSourceTypes.map((i) => ({...i, value: i.id}))}
                            name="source"
                            className="mr-md-2"
                            selectAllName="sourceSelectAll"
                            defaultSelectedLabel={getLangText('sourceLabel')}
                            optionLabel={(item) => (
                                <label className="control-label text-nowrap">
                                    <img
                                        className="source-icon inline-block"
                                        src={`/assets/images/svg/product-type/${item.icon}.svg`}
                                        width={14}
                                        alt=""
                                    />
                                    {getLangText(item.lang)}
                                </label>
                            )}
                            extraProps={{
                                id: params.id,
                            }}
                        />
                    }
                    {(type !== EXPENDITURES_LIST_PRODUCT_VIEW && type !== REMOVED_LIST_PRODUCT_VIEW) &&
                        <StatusFilter
                            FiltersActions={FiltersActions}
                            ListActions={ListActions}
                            getFiltersStateRoot={getFiltersStateRoot}
                            className="mr-md-2"
                            extraProps={{
                                id: params.id,
                            }}
                            options={ORDER_STATUES}
                            name="statuses"
                            selectAllName="filtersSelectAll"
                            defaultSelectedLabel={getLangText('statusLabel')}
                        />
                    }
                    {data.has_sizes === '1' &&
                        <VariantFilter
                            FiltersActions={FiltersActions}
                            ListActions={ListActions}
                            getFiltersStateRoot={getFiltersStateRoot}
                            className="mr-md-2"
                            values={data.sizes || []}
                            extraProps={{
                                id: params.id,
                            }}
                        />
                    }
                    {(type === 'RESERVE_LIST' && !!+userData.mobile_app) &&
                        <SingleSelectFiltersStatus
                            ListActions={ListActions}
                            FiltersActions={FiltersActions}
                            getFiltersStateRoot={getFiltersStateRoot}
                            options={PRODUCTS_RESERVE_LIST_REMOVED_FROM_TYPES_TYPES}
                            name="deleted"
                            defaultSelectedLabel={getLangText('removedFromSelmoApp')}
                            withClear={false}
                            extraProps={{
                                id: params.id,
                            }}
                        />
                    }
                    {type === 'FINISHED_CART' && width > 767 &&
                        <CustomDropdown
                            ButtonToggle={() => <span className="d-md-none">{getLangText('exportLabel')}</span>}
                            icon="icon-print"
                            className="ml-sm-auto"
                            buttonClassName="mr-sm-3 default-size"
                            tooltipTitle={getLangText('exportLabel')}
                        >
                            <button
                                type="button"
                                onClick={() => setOpenExportModal(PDF_EXPORT_MODAL)}
                                className="custom-option"
                            >
                                {getLangText('exportPurchasedListLabel')}
                            </button>
                            <button
                                type="button"
                                onClick={() => setOpenExportModal(PDF_CLIENTS_BOUGHT_EXPORT_MODAL)}
                                className="custom-option"
                            >
                                {getLangText('printClientsPDFLabel')}
                            </button>
                        </CustomDropdown>

                    }
                    {type === 'CURRENT_CART' && width > 767 &&
                        <CustomDropdown
                            ButtonToggle={() => <span className="d-md-none">{getLangText('exportLabel')}</span>}
                            icon="icon-print"
                            className="ml-sm-auto"
                            buttonClassName="mr-sm-3 default-size"
                            tooltipTitle={getLangText('exportLabel')}
                        >
                            <button
                                type="button"
                                onClick={() => setOpenExportModal(PDF_CLIENTS_CURRENT_EXPORT_MODAL)}
                                className="custom-option"
                            >
                                {getLangText('printClientsPDFLabel')}
                            </button>
                        </CustomDropdown>

                    }
                    {type !== EXPENDITURES_LIST_PRODUCT_VIEW &&
                        <SearchInput
                            className={`mr-md-2 ${((type === 'FINISHED_CART' || type === 'CURRENT_CART') && width > 767) ? '' : 'ml-md-auto'}`}
                            placeholder={getLangText('searchPlaceholder')}
                            search={search}
                        />
                    }
                    {type === EXPENDITURES_LIST_PRODUCT_VIEW &&
                        <Link
                            className="button border-button ml-auto mr-sm-2 sm:w-auto w-full sm:mb-0 mb-2.5"
                            to={`${RouterPaths.StorageScanner}/product/${params.id}`}>
                            {getLangText('seeDetails')}
                        </Link>
                    }
                    <DateRangePicker
                        actions={ListActions}
                        getFiltersStateRoot={getFiltersStateRoot}
                        FiltersActions={FiltersActions}
                        extraProps={{
                            id: params.id,
                        }}
                    />
                </div>
            }
            {openExportModal === PDF_EXPORT_MODAL &&
                <ExportModal
                    title={getLangText('exportPurchasedListLabel')}
                    subTitle={getLangText('receivedFileInPDFFormatLabel')}
                    saveButton={getLangText('exportLabel')}
                    rest={`${services.API_EXPORT_BOUGHT_PRODUCT_PDF}/${params.id}${preparedDateParams()}`}
                    fileName={title}
                    show={openExportModal === PDF_EXPORT_MODAL}
                    hide={() => setOpenExportModal('')}
                />
            }
            {openExportModal === PDF_CLIENTS_BOUGHT_EXPORT_MODAL &&
                <ExportModal
                    title={getLangText('exportLabel')}
                    subTitle={getLangText('receivedFileInPDFFormatLabel')}
                    saveButton={getLangText('exportLabel')}
                    rest={`${services.API_EXPORT_STORAGE_BOUGHT_PRODUCT_CLIENTS_PDF}/${params.id}${preparedDateParams()}`}
                    fileName={title}
                    show={openExportModal === PDF_CLIENTS_BOUGHT_EXPORT_MODAL}
                    hide={() => setOpenExportModal('')}
                />
            }
            {openExportModal === PDF_CLIENTS_CURRENT_EXPORT_MODAL &&
                <ExportModal
                    title={getLangText('exportLabel')}
                    subTitle={getLangText('receivedFileInPDFFormatLabel')}
                    saveButton={getLangText('exportLabel')}
                    rest={`${services.API_EXPORT_STORAGE_CURRENT_PRODUCT_CLIENTS_PDF}/${params.id}${preparedDateParams()}`}
                    fileName={title}
                    show={openExportModal === PDF_CLIENTS_CURRENT_EXPORT_MODAL}
                    hide={() => setOpenExportModal('')}
                />
            }
        </div>
    );
};

export default Header;
