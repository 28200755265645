import React, {useRef} from 'react';
import useDropdown from "../../hooks/useDropdown";

const CustomDropdown = ({children, icon = 'icon-dots', ButtonToggle = null, tooltipTitle, className = '', placement = '', openDropdown, setOpenDropdown, onClick = () => {}, buttonClassName}) => {

	const buttonRef = useRef(null)
	const dropdownRef = useRef(null)

	const hideDropdown = () => setOpen(false)

	const {open, setOpen} = useDropdown(buttonRef, dropdownRef, hideDropdown);

	return (
		<>
			<div
				onClick={onClick}
				ref={dropdownRef}
				className={`react-dropdown position-relative ${className} ${placement}`}
			>
				<button
					type="button"
					className={`dropdown-toggle ${!ButtonToggle ? 'only-icon' : ''} ${buttonClassName} ${open ? 'show' : ''} ${tooltipTitle ? 'tooltip-parent' : ''}`}
					onClick={(e) => {
						e.stopPropagation()
						setOpen(!open)
					}}
					ref={buttonRef}
				>
					{tooltipTitle &&
						<div className="tooltip-content">
							{tooltipTitle}
						</div>
					}
					{ButtonToggle && <ButtonToggle/>}
					<i className={icon}/>
				</button>
				{open &&
					<div className="dropdown-menu show">
						{children}
					</div>
				}
			</div>
		</>
	)
};

export default CustomDropdown;


