import React, {memo, useEffect, useMemo} from 'react';
import moment from "moment";
import SwitchField from "../../../../src_shared/components/form/switchField/SwitchField";
import {useModal} from "../../shared/hooks/useModal";
import Modal from "react-bootstrap/Modal";
import useLang from "../../../../src_shared/hooks/useLang";
import {useUnitPrice} from "../../shared/helpers/Price";
import {convertToUserTimeZone} from "../../shared/helpers/dateHelpers";

const benefits = [
	{id: 1, title: 'paymentPlanBenefitsLabel1'},
	{id: 2, title: 'paymentPlanBenefitsLabel2'},
	{id: 3, title: 'paymentPlanBenefitsLabel3'},
	{id: 4, title: 'paymentPlanBenefitsLabel4'},
	{id: 5, title: 'paymentPlanBenefitsLabel5'},
	{id: 6, title: 'paymentPlanBenefitsLabel6'},
	{id: 7, title: 'paymentPlanBenefitsLabel7'},
	{id: 8, title: 'paymentPlanBenefitsLabel8'},
]

const PaymentRange = ({values, setValue, className = '', userData}) => {

	const [openModal, setOpenModal] = useModal(false);
	const hideModal = () => setOpenModal(false);
	const {getLangText} = useLang();
	const {getPrice} = useUnitPrice();

	const CurrentPlanTitle = memo(() => {
		if (values.activeSubscriptionInfo.type === 'monthly') {
			return (
				<div className="top-part">
					<div className="small-label">
						{getLangText('paymentsCurrentPlanLabel')}
						<div className="price">
							<strong>{getPrice(values.activeSubscriptionInfo.price, values.currencyText)}</strong> / {getLangText('monthLabel')}
						</div>
					</div>
					{getLangText('monthlyBillingLabel')}
				</div>
			)
		}
		return (
			<div className="top-part">
				<div className="small-label">
					{getLangText('paymentsCurrentPlanLabel')}
					<div className="price">
						<strong>{getPrice(values.activeSubscriptionInfo.price, values.currencyText)}</strong> / {getLangText('yearLabel')}
					</div>
				</div>
				{getLangText('annualBillingLabel')}
			</div>
		)
	}, [values.activeSubscriptionInfo.type])

	return (
		<div className={`select-boxes-wrapper ${className}`}>
			{!!userData.user_info.premium_date ?
				<div className="current-plan-box">
					<CurrentPlanTitle/>
					<div className="active-date">
						{getLangText('paymentsActivePlanToLabel')}
						<strong>{convertToUserTimeZone(userData.user_info.premium_date).format('DD.MM.YYYY')}</strong>
					</div>
					<button
						onClick={() => setOpenModal(true)}
						type="button"
						className="button text-only d-block"
					>
						{getLangText('paymentsSeeWhatIsIncludedInPlanLabel')}
					</button>
					{openModal &&
						<Modal
							dialogClassName="default-modal sm-size"
							show={openModal}
							onHide={hideModal}
						>
							<Modal.Header className="with-border">
								<Modal.Title>
									{getLangText('paymentsSeeWhatIncludesMyPlanLabel')}
								</Modal.Title>
								<button
									onClick={hideModal}
									type="button"
									className="button"
								>
									<i className="icon-cross"/>
								</button>
							</Modal.Header>
							<Modal.Body className="payment-benefits-wrapper">
								<div className="label">{getLangText('paymentsPlanIncludedLabel')}</div>
								<ul className="benefits-list">
									{benefits?.map((i) => (
										<li key={i.id}>
											<i className="icon-tick-c"/>
											{getLangText(i.title)}
										</li>
									))}
								</ul>
							</Modal.Body>
						</Modal>
					}
					<SwitchField
						className="switch-form inline-label mt-3 mb-2"
						setValue={setValue}
						label={getLangText('paymentsExtendYourCurrentPlanLabel')}
						name="extend_plan"
						id="extend_plan"
						value={values.extend_plan}
					/>
					{!!+values.extend_plan &&
						<div className="data-boxes-wrapper separated small-padd">
							{values.subscriptions.map((item) => (
								<div key={item.id}>
									<div
										className={`content mb-0 ${values.selected_subscription === item.id ? 'active' : ''}`}>
										<div className="content__top-part">
											<input
												onChange={() => setValue('selected_subscription', item.id)}
												checked={values.selected_subscription === item.id}
												type="radio"
												name="selected_subscription"
											/>
											<div className="d-flex w-100 align-items-center">
												<span className="checkmark"/>
												<div className="flex-grow-1 d-flex align-items-center flex-wrap">
													{item.name}
													<div className="ml-auto w-100 mt-1">
														{+item.months === 6 &&
															<div className="status-bg blue small-status mr-3">
																{getLangText({
																	key: 'paymentYouSaveLabel',
																	data: [1, getLangText('pricingMonthLabel')]
																})}
															</div>
														}
														{+item.months === 12 &&
															<div className="status-bg blue small-status mr-3">
																{getLangText({
																	key: 'paymentYouSaveLabel',
																	data: [2, getLangText('pricingPluralMonthLabel')]
																})}
															</div>
														}
													</div>
												</div>
											</div>
											<div className="button-place ml-auto align-self-start">
												{item.discount_amount &&
													<div className="status-bg green no-bg small-status text-nowrap">
														{getLangText({
															key: 'saveMoneyLabel',
															data: [getPrice(item.discount_amount, values.currencyText)]
														})}
													</div>
												}
											</div>
										</div>
									</div>
								</div>
							))}
						</div>
					}
				</div> :
				<>
					<div className="section-label">
						{getLangText('paymentSelectPaymentMethodLabel')}
					</div>
					<div className="data-boxes-wrapper separated small-padd">
						{values.subscriptions.map((item) => (
							<div
								key={item.id}
								className={`content ${values.selected_subscription === item.id ? 'active' : ''}`}
							>
								<div className="content__top-part">
									<input
										onChange={() => setValue('selected_subscription', item.id)}
										checked={values.selected_subscription === item.id}
										type="radio"
										name="selected_subscription"
									/>
									<div className="d-flex flex-wrap w-100 align-items-center">
										<span className="checkmark"/>
										<div className="flex-grow-1 d-flex align-items-center flex-wrap">
											{item.name}
											<div className="ml-auto w-100 mt-1">
												{+item.months === 6 &&
													<div className="status-bg blue small-status mr-3">
														{getLangText({
															key: 'paymentYouSaveLabel',
															data: [1, getLangText('pricingMonthLabel')]
														})}
													</div>
												}
												{+item.months === 12 &&
													<div className="status-bg blue small-status mr-3">
														{getLangText({
															key: 'paymentYouSaveLabel',
															data: [2, getLangText('pricingPluralMonthLabel')]
														})}
													</div>
												}
											</div>
										</div>
									</div>
									<div className="button-place ml-auto align-self-start">
										{item.discount_amount &&
											<div className="status-bg green no-bg small-status text-nowrap">
												{getLangText({
													key: 'saveMoneyLabel',
													data: [getPrice(item.discount_amount, values.currencyText)]
												})}
											</div>
										}
									</div>
								</div>
							</div>
						))}
					</div>
				</>
			}
		</div>
	);
};

export default PaymentRange;
