import React from 'react';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {getProductSourceTypeIcon} from "../../enums/ProductSourceType";
import useLang from "../../../../../src_shared/hooks/useLang";

const ProductTypeIcon = ({product, className = ''}) => {

	const {getLangText} = useLang();

	if (!product.type) {
		return null;
	}


	return (
		<OverlayTrigger
			placement='top'
			overlay={
				<Tooltip
					className="small-size"
					id="added-date"
				>
					{getLangText(getProductSourceTypeIcon(product.type)?.lang)}
				</Tooltip>
			}
		>
			<img
				className={`source-icon ${className}`}
				src={`/assets/images/svg/product-type/${getProductSourceTypeIcon(product.type)?.icon}.svg`}
				width={14}
				alt=""
			/>
		</OverlayTrigger>
	);
};

export default ProductTypeIcon;
