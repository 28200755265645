export const createActions = (prefix) => ({
    changePage: (page) => ({
        type: `${prefix}PAGINATION_CHANGE_PAGE`,
        page,
    }),
    updateTotalItems: (total) => ({
        type: `${prefix}PAGINATION_UPDATE_TOTAL_ITEMS`,
        total,
    }),
    setItemPerPage: (itemsPerPage) => ({
        type: `${prefix}PAGINATION_SET_ITEM_PER_PAGE`,
        itemsPerPage,
    }),
});

export default {
    createActions,
};
