import createReducer from "../../../shared/helpers/createReducer.js";
import FormReducer from "../../../../../src_shared/form/FormReducer.js";
import VariantsSquModalModel from "../../../../../modules/models/VariantsSquModalModel";
import Composition from "../../../shared/helpers/Composition";

export const getStateRoot = (state) => state.products.form.variantsSqu;
export const prefix = 'PRODUCTS_VARIANTS_SQU_FORM_';

const getInitState = () => FormReducer.getInitState(VariantsSquModalModel.getModel());

const getReduceMap = () => new Composition(
	FormReducer.getReduceMap(),
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

