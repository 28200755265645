import React from 'react';
import {services} from "../../../../RestServices";
import {RouterPaths} from "../../../../routes/RouterPath";
import useFormLocal from "../../../../../src_shared/hooks/useFormLocal";
import Loader from "../../../../app/shared/Loader";
import useLang from "../../../../../src_shared/hooks/useLang";
import {Link} from "react-router-dom";
import Wrapper from "../Wrapper";
import SettingsShipmondoDataModel from "../../../../../modules/models/settings/SettingsShipmondoDataModel";
import Field from "../../../../../src_shared/components/form/field/Field";

const ShipmondoDataForm = () => {
    const {getLangText} = useLang()

    const [{onSave, isWaiting, getFieldProps, isLoading}] = useFormLocal({
        rest: services.API_SETTINGS_SHIPMONDO,
        model: SettingsShipmondoDataModel,
    });

    return (
        <>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <a href={RouterPaths.Settings}>
                            {getLangText('asideSettingsLabel')}
                        </a>
                    </li>
                    <li>
                        <Link to={RouterPaths.SettingsIntegration}>{getLangText('settings_nav_integrations')}</Link>
                    </li>
                    <li className="active">
                        Shipmondo
                    </li>
                </ul>
            </div>
            <Wrapper>
                <form
                    onSubmit={onSave}
                    id="shipmondo-settings-form"
                >
                    <div className="loader-parent">
                        <Loader isLoading={isLoading}/>
                        <div className="settings-form pt-3">
                            <h2 className="mb-3">
                                {getLangText('senderDataLabel')}
                            </h2>
                            <div className="grid grid-cols-2 gap-x-5">
                                <Field
                                    {...getFieldProps('name')}
                                    label={getLangText('nameLabel')}
                                />
                                <Field
                                    {...getFieldProps('address')}
                                    label={getLangText('addressLabel')}
                                />
                                <Field
                                    {...getFieldProps('city')}
                                    label={getLangText('cityLabel')}

                                />
                                <Field
                                    {...getFieldProps('zipcode')}
                                    label={getLangText('zipCodeLabel')}

                                />
                                <Field
                                    {...getFieldProps('phone')}
                                    label={getLangText('phoneLabel')}
                                />
                                <Field
                                    {...getFieldProps('email')}
                                    label={getLangText('emailLabel')}
                                />
                            </div>
                            <div className="button-place mt-3">
                                <button
                                    type="submit"
                                    className={`button primary ${isWaiting ? 'loading-button' : ''}`}
                                >
                                    {isWaiting && <Loader isLoading/>}
                                    {getLangText('saveButton')}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </Wrapper>
        </>
    );
};

export default ShipmondoDataForm;
