import React from 'react';
import {DPD, DPD_PICKUP, ORLEN} from "../SelmoPaczka";
import {useSelector} from "react-redux";

const SelmoPaczkaHeader = ({setActiveView, activeView}) => {
    const {userData} = useSelector((state) => state.session);

    return (
        <div>
            <img
                className="w-[90px] mb-3"
                src="/assets/images/svg/selmo-paczka-logo.svg" alt="selmo paczka"/>
            <div className={`grid font-bold gap-3 text-xs bg-[#f9f9f9] rounded-[8px] p-1.5 ${+userData.id === 11480 ? 'grid-cols-4' : 'grid-cols-3'}`}>
                <button
                    type="button"
                    className={`p-2 rounded-[8px] transition-all ${activeView === DPD ? 'bg-[#fff] shadow-default focus:shadow-default' : 'text-desc'}`}
                    onClick={() => setActiveView(DPD)}
                >
                    DPD
                </button>
                <button
                    type="button"
                    className={`p-2 rounded-[8px] transition-all ${activeView === DPD_PICKUP ? 'bg-[#fff] shadow-default focus:shadow-default' : 'text-desc'}`}
                    onClick={() => setActiveView(DPD_PICKUP)}
                >
                    DPD Pickup
                </button>
                <button
                    type="button"
                    className={`p-2 rounded-[8px] transition-all ${activeView === ORLEN ? 'bg-[#fff] shadow-default focus:shadow-default' : 'text-desc'}`}
                    onClick={() => setActiveView(ORLEN)}
                >
                    Orlen
                </button>
                {+userData.id === 11480 &&
                    <button
                        type="button"
                        className={`p-2 rounded-[8px] transition-all ${activeView === ORLEN ? 'bg-[#fff] shadow-default focus:shadow-default' : 'text-desc'}`}
                        onClick={() => setActiveView(ORLEN)}
                    >
                        InPost
                    </button>
                }
            </div>
        </div>
    );
};

export default SelmoPaczkaHeader;
