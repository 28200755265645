import React, {memo} from 'react';
import {NavLink} from "react-router-dom";
import {Helmet} from "react-helmet";
import useLang from "../../../../src_shared/hooks/useLang";
import {RouterPaths} from "../../../routes/RouterPath";

const Wrapper = ({children}) => {
    const {getLangText} = useLang()

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>GLS - Selmo</title>
            </Helmet>
            <div className="row small-padd-row">
                <div className="col-lg-7">
                    <div className="settings-page sub-settings-page export-sub-settings">
                        <div className="header-wrapper no-border pb-0 flex items-center">
                            <img style={{width: 'auto', height: '30px'}} src="/assets/images/gls.png"
                                 alt="gls"/>
                        </div>
                        <div className="tabs-wrapper">
                            <NavLink
                                exact
                                activeClassName="active"
                                to={RouterPaths.SettingsIntegrationsGlsFrance}
                            >
                                {getLangText('configurationLabel')}
                            </NavLink>
                        </div>
                        {children}
                    </div>
                </div>
            </div>
        </>
    );
};

export default memo(Wrapper);
