import React, {useEffect} from 'react';

const NumberInput = ({value, onChange, className = '', name, min, tabIndex = '1', disabled}) => {

	const removeScrollInInput = () => {
		if(document.activeElement.type === "number"){
			document.activeElement.blur();
		}
	}

	useEffect(() => {
		document.addEventListener("wheel", removeScrollInInput);
		return () => {
			document.removeEventListener("wheel", removeScrollInInput);
		}
	}, [])

	return (
		<input
			type="number"
			onChange={onChange}
			value={value}
			className={`form-control number-input ${className}`}
			name={name}
			required
			min={min}
			pattern="^[0-9]*$"
			tabIndex={tabIndex}
			disabled={disabled}
			inputMode="numeric"
			title="Non-negative integral number"
		/>
	);
};

export default NumberInput;
