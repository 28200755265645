import React, {useCallback, useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import _ from 'lodash';
import {getGETParamsUrl} from "../../../../../src_shared/list/ListActions";
import useLang from "../../../../../src_shared/hooks/useLang";
import {services} from "../../../../RestServices";
import Loader from "../../Loader";
import api from "../../../../../services/axios/axios";
import {getOrlenPaczkaPointsTypeById} from "../../enums/OrlenPaczkaPointTypes";
import {ORLEN_POINT_MODAL} from "../../../basket/secondStep/SecondStep";

const OrlenPointModal = ({show, hideModal, updateValues = (obj) => {}, shippingId, rest = services.API_CART_ORLEN_PACZKA_POINTS}) => {
    const [items, setItems] = useState([]);
    const [waiting, setWaiting] = useState(false);
    const {getLangText} = useLang();

    const selectPoint = (point) => {
        updateValues({
            pointMachineType: ORLEN_POINT_MODAL,
            pointMachineId: point.destination_code,
            pointMachineCity: point.city,
            pointMachineStreet: point.street,
            pointMachineCode: point.psd_code,
            shipping: shippingId,
        })
        hideModal()
    }

    const getPointsList = async (inputValue = '') => {
        const params = {};
        params.superSearch = inputValue;
        setWaiting(true)
        try {
            const {data} = await api.get(`/${rest}${getGETParamsUrl(params)}`)
            setItems(data.items);

        } catch (e) {
            console.log("Error get points")
        } finally {
            setWaiting(false)
        }
    }

    useEffect(() => {
        getPointsList()
    }, [])


    const debouncedSave = useCallback(
        _.debounce((name) => getPointsList(name), 500),
        []
    );

    return (
        <Modal
            show={show}
            className="higher-index"
            dialogClassName="default-modal sm-size"
            backdropClassName="higher-index"
            onHide={hideModal}
            animation={false}
        >
            <Modal.Header>
                <Modal.Title>Wybierz punkt Orlen</Modal.Title>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            <Modal.Body>
                <div id="easypack-widget" className="easypack-widget">
                    <div className="easypack-dropdown" data-open="true">
                        <div className="easypack-dropdown__select">
                            <span>{getLangText('customerBasketSelectInpostPointLabel')}</span>
                        </div>
                        <div className="easypack-dropdown__content">
                            <div className="search-input-loader-wrapper">
                                <input
                                    autoFocus={true}
                                    type="text"
                                    onChange={(e) => debouncedSave(e.target.value)}
                                    placeholder={getLangText('searchViaZipCodeLabel')}
                                />
                            </div>
                            <ul className="easypack-dropdown__list loader-parent">
                                {waiting ?
                                    <Loader className="static-loader" isLoading={true} />:
                                    items.map((i) => (
                                        <li
                                            key={i.id}
                                            onClick={() => selectPoint(i)}
                                        >
                                            {i.street}, {i.zip_code} {i.city} - {i.psd_code}
                                            <div className="text-desc text-xs space-x-1">
                                                <span>Typ punktu:</span>
                                                <span>{getOrlenPaczkaPointsTypeById(i.point_type)}</span>
                                            </div>
                                        </li>
                                    ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button border-button m-0"
                >
                    {getLangText('cancelButton')}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default OrlenPointModal;



