import React, {useCallback, useRef, useState} from 'react';
import ClientDropdown from "./ClientDropdown.jsx";
import debounce from "lodash.debounce";
import {selmoUrl} from "../../../../../src_shared/api/api.js";
import optimizedMemo from "../../../../../src_shared/helpers/optimizedMemo.js";
import {OverlayTrigger} from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import useLang from "../../../../../src_shared/hooks/useLang";
import api from "../../../../../services/axios/axios";
import ClientAvatar from "../../../live/shared/Avatar";

const ClientInput = ({
                         updateField,
                         customer,
                         extraClass = '',
                         rest = '/apilive/clients',
                         hintItem,
                         label,
                         clientAvatar,
                         hasCustomerError = false,
                         updateProductOnNameChange = () => {
                         },
                         customerId,
                         showErrorAlert,
    autoFocus = false ,
                     }) => {
    const inputRef = useRef(null)
    const [hints, setHints] = useState([]);
    const [hintsVisible, setHintsVisible] = useState(null);
    const {getLangText} = useLang();

    const hintDropdownFocus = (e) => {
        if (document.querySelector('.custom-dropdown .select-custom-option:first-child') && e.keyCode === 40) {
            document.querySelector('.custom-dropdown .select-custom-option:first-child').focus()
        }
    }

    const searchHints = async (name) => {
        if (name.length > 0) {
            try {
                const {data} = await api.get(`${selmoUrl}${rest}?search=${name}`)
                setHints(data)
                setHintsVisible(customerId)

            } catch (e) {
                setHints([])
                console.warn('Cannot get clients')
            }
        }
    }

    const debouncedSave = useCallback(
        debounce((name) => searchHints(name), 500),
        []
    );

    const removeClient = () => {
        updateField('photo', null)
        updateField('name', '')
        updateField('shop_client_id', null)
        updateField('facebook_id', null)
        updateField('social_type', null)
        setTimeout(() => {
            inputRef.current?.focus()
        })
    }

    const onCustomerNameChange = (e) => {
        updateField('name', e.target.value)
        debouncedSave(e.target.value)
    }

    const isError = hasCustomerError && !customer.name;

    const customerInputFormClassNames = () => {
        const errorClass = isError ? 'error-group' : '';
        const customerNumberClass = customer.shop_client_id ? 'with-client-number' : '';
        const errorAlertClass = showErrorAlert ? 'with-error-alert' : '';
        return `form-group  ${extraClass} ${errorClass} ${customerNumberClass} ${errorAlertClass}`
    }

    return (
        <div className="tooltip-parent">
            {isError &&
                <div className="tooltip-content">
                    {getLangText('selectClientFromListOrEnterDataLabel')}
                </div>
            }
            <div className={customerInputFormClassNames()}>
                <label className="control-label">{getLangText(label) || label}</label>
                {!!customer.shop_client_id && clientAvatar(customer)}
                <input
                    ref={inputRef}
                    onChange={(e) => onCustomerNameChange(e)}
                    onKeyDown={hintDropdownFocus}
                    type="text"
                    value={customer.name}
                    tabIndex={customer.tabIndex}
                    autoFocus={customer.tabIndex > 1 || autoFocus}
                    className={`form-control pl-[50px]`}
                    style={{paddingLeft: customer.shop_client_id ? '41px' : '15px', paddingBottom: customer.shop_client_id ? '16px' : '6px'}}
                    autoComplete="off"
                    required
                    placeholder={getLangText('textToSearchClientLabel')}
                    disabled={!!customer.shop_client_id}
                />
                {customer.shop_client_id &&
                    <div className="client-number absolute left-[41px] top-[49px] text-[#6B7280] text-[10px] font-light leading-[10px]">
                        {getLangText('clientNumberFullLabel')}: {customer.shop_client_id}
                    </div>
                }
                {!!customer.shop_client_id &&
                    <OverlayTrigger
                        placement='top'
                        overlay={
                            <Tooltip id="remove-product">
                                {getLangText('clientsRemoveClientTitle')}
                            </Tooltip>
                        }
                    >
                        <button
                            type="button"
                            className="remove-product absolute right-[15px] top-[40px] text-[#9CA3AF] text-[8px]"
                            onClick={removeClient}
                        >
                            <i className="icon-cross"/>
                        </button>
                    </OverlayTrigger>
                }
                {(isError && showErrorAlert) &&
                    <div className="error-text">
                        {getLangText('fieldIsRequiredLabel')}
                    </div>
                }
                <ClientDropdown
                    onChange={updateField}
                    hints={hints}
                    setHints={setHints}
                    hintsVisible={hintsVisible}
                    setHintsVisible={setHintsVisible}
                    hintItem={hintItem}
                    updateProductOnNameChange={updateProductOnNameChange}
                    customerId={customerId}
                />
            </div>
        </div>
    );
};

ClientInput.defaultProps = {
    label: 'clientLabel',
    clientAvatar: (client) => (
        <ClientAvatar
            className="absolute left-[9px] top-[34px]"
            photo={client.photo}
            type={client.social_type}
        />
    ),
    showErrorAlert: false,

}

export default optimizedMemo(ClientInput);
