import React from 'react';
import useLang from "../../../../../src_shared/hooks/useLang";
import ListActions from "./ListActions";
import {getStateRoot} from "./listReducer";
import useList from "../../../../../src_shared/list/useList";
import {Helmet} from "react-helmet";
import FiltersActions from "../filters/FiltersActions";
import ComponentLoader from "../../../shared/components/componentLoader/ComponentLoader";
import {getStateRoot as getFiltersStateRoot} from "../filters/filtersReducer";
import Table from "./components/Table";

const AutomationNewsletterList = () => {
    const {getLangText} = useLang();
    const {
        pagination,
        list: {items, isWaiting},
        search,
        sort: {updateSortOptions, getSortClass},
    } = useList({
        ListActions,
        getStateRoot,
        FiltersActions
    });

    if (isWaiting) return <ComponentLoader/>

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getLangText('newsletterLabel')} - Selmo</title>
            </Helmet>
            <Table
                isWaiting={isWaiting}
                items={items}
                updateSortOptions={updateSortOptions}
                getSortClass={getSortClass}
                pagination={pagination}
                search={search}
                ListActions={ListActions}
                getFiltersStateRoot={getFiltersStateRoot}
                FiltersActions={FiltersActions}
            />
        </>
    );
};

export default AutomationNewsletterList;
