import React from 'react';
import Modal from "react-bootstrap/Modal";
import Loader from "../../../../../messenger/loader/Loader";
import Summary from "../generateLabelsModal/summary/Summary";
import {services} from "../../../../../../RestServices";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import {useDownloadFile} from "../../../../../../../src_shared/hooks/useDownloadFile";
import {getGETParamsUrl} from "../../../../../../../src_shared/list/ListActions";
import {useSelector} from "react-redux";
import {createSelector} from "reselect";
import {getStateRoot} from "../../../listReducer";

const GenerateInvoiceModal = ({
								  show,
								  hide,
								  isWaiting,
								  title,
								  SubTitle,
								  saveButton,
								  onSubmit,
								  successGenerated,
								  generatedOrdersCount,
								  children,
								  summaryContent,
							  }) => {

	const {selectedItems} = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));

	const {download, waiting} = useDownloadFile({
		rest: `${services.API_PRINT_INVOICES}${getGETParamsUrl({'orders[]': selectedItems.map((i) => i.id)})}`,
		fileName: 'Faktura',
		afterSubmit: hide,
	})

	const {getLangText} = useLang();

	return (
		<Modal
			dialogClassName="default-modal small-modal"
			show={show}
			onHide={hide}
		>
			{successGenerated ?
				<Summary
					waitingForDocument={waiting}
					hideModal={hide}
					generatedOrdersCount={generatedOrdersCount}
					onPrint={download}
					labels={false}
					summaryContent={summaryContent}
				/> :
				<>
					<Modal.Header>
						<Modal.Title>
							{title}
							<div className="description">
								{SubTitle && <SubTitle/>}
							</div>
						</Modal.Title>
						<button
							onClick={hide}
							type="button"
							className="button"
						>
							<i className="icon-cross"/>
						</button>
					</Modal.Header>
					<Modal.Body>
						{children}
					</Modal.Body>
					<Modal.Footer>
						<button
							onClick={hide}
							type="button"
							className="button border-button"
						>
							{getLangText('cancelButton')}
						</button>
						<button
							onClick={onSubmit}
							type="button"
							className={`button primary px-0 ${isWaiting ? 'loading-button' : ''}`}
						>
							{isWaiting && <Loader/>}
							{saveButton}
						</button>
					</Modal.Footer>
				</>
			}
		</Modal>
	);
};

export default GenerateInvoiceModal;
