import Model from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";
import {MONDIAL_RELAY_LABEL_24R} from "../../../components/app/shared/enums/MondialRelayLabelEnums";

class MondialLabelModel extends Model {
	getModel() {
		return {
			mode: MONDIAL_RELAY_LABEL_24R,
			weight: '5',
		};
	}

	getValidators() {
		return {
			mode: ValidationError.notEmpty,
			weight: ValidationError.notEmpty,
		};
	}

}

export default new MondialLabelModel();
