import React from 'react';
import {findOrderStatusById} from "../../enums/OrderStatuses";
import useLang from "../../../../../src_shared/hooks/useLang";

const OrderStatus = ({status, className}) => {
	const {getLangText} = useLang();

	return (
		<div className={`status-bg ${className} ${findOrderStatusById(status)?.color}`}>
			{getLangText(findOrderStatusById(status)?.label)}
		</div>
	);
};

export default OrderStatus;
