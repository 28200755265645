import React from "react";
import useForm from "../../../../../src_shared/form/useForm";
import FormActions from "./FormActions";
import useLang from "../../../../../src_shared/hooks/useLang";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import Ckeditor from "../../../../../src_shared/components/form/ckeditor/Ckeditor";
import Loader from "../../../shared/Loader";

const SEOForm = () => {
	const {getLangText} = useLang();
	const {id} = useParams();
	const dispatch = useDispatch();

	const {
		data: {values, setValue},
		form: {isWaiting, getFieldProps},
	} = useForm({
		FormActions,
		params: {
			id,
		}
	});
	const onSave = () => dispatch(FormActions.submitEditForm(values, id))

	return (
		<div className="table-list-wrapper">
			<div className="default-shadow-box loader-parent">
				<Loader isLoading={isWaiting}/>
				<div className="title">SEO</div>
				<Ckeditor
					className="big-min-height"
					{...getFieldProps('seo_text')}
					setValue={setValue}
				/>
				<div className="d-flex pb-5">
					<button
						onClick={onSave}
						type="button"
						className={`ml-auto button small-size primary`}
					>
						{getLangText('saveButton')}
					</button>
				</div>
			</div>
		</div>
	)
};

export default SEOForm;



