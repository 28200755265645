import {selmoUrl} from "../../../../../src_shared/api/api";

export const galleryModalToggleVisibility = (toggle) => {
    return {
        type: 'GALLERY_MODAL_TOGGLE_VISIBILITY',
        toggle,
    };
}

export const setImageGalleries = (images, startIndex) => {
    return {
        type: 'MESSENGER_GALLERY_SET_IMAGES',
		images,
		startIndex,
    };
}
