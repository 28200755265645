import React from 'react';
import Modal from "react-bootstrap/Modal";
import useLang from "../../../../../../src_shared/hooks/useLang";
import {services} from "../../../../../RestServices";
import useFormLocal from "../../../../../../src_shared/hooks/useFormLocal";
import NewReelsModel from "../../../../../../modules/models/reels/NewReelsModel";
import {RouterPaths} from "../../../../../routes/RouterPath";
import Field from "../../../../../../src_shared/components/form/field/Field";
import TextArea from "../../../../../../src_shared/components/form/textarea/TextArea";
import Loader from "../../../../../../src_shared/components/loader/Loader";

const FormModal = ({modalVisible, hideModal}) => {
    const {getLangText} = useLang()

    const [{
        onSave,
        isWaiting,
        getFieldProps,
        isLoading,
        values,
        setValue,
        updateValues,
    }] = useFormLocal({
        rest: services.API_STORAGE_REELS,
        model: NewReelsModel,
        initialAutoLoad: false,
        redirectRoute: RouterPaths.MobileReelsList
    });

    return (
        <Modal
            show={modalVisible}
            className="add-order-modal"
            dialogClassName="default-modal"
            onHide={hideModal}
        >
            <Modal.Header>
                <Modal.Title>
                    {getLangText('addReelLabel')}
                    <div
                        className="text-[#9CA3AF] text-sm font-medium mt-1">{getLangText('moreOptionsWillFindInside')}</div>
                </Modal.Title>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            <form onSubmit={onSave}>
                <Modal.Body className="add-product-modal-body">
                    <Field
                        {...getFieldProps('name')}
                        label={getLangText('nameLabel')}
                    />
                    <TextArea
                        {...getFieldProps('description')}
                        label={getLangText('input_label_package_type_description')}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <button
                        onClick={hideModal}
                        type="button"
                        className="button border-button"
                    >
                        {getLangText('cancelButton')}
                    </button>
                    <button
                        type="submit"
                        className={`button primary ${isWaiting ? 'loading-button' : ''}`}
                    >
                        {isWaiting && <Loader isLoading/>}
                        {getLangText('saveButton')}
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default FormModal;
