export const SHOP_STATUS_POTENTIAL_CLIENT = 1
export const SHOP_STATUS_POSTPONED = 2
export const SHOP_STATUS_FUNCTIONALITY_LACK = 3
export const SHOP_STATUS_LOST = 4
export const SHOP_STATUS_CALL = 5
export const SHOP_STATUS_TRIAL = 6
export const SHOP_STATUS_CLIENT = 7
export const SHOP_STATUS_WILL_BACK = 8
export const SHOP_STATUS_POSSIBLE_BACK = 9
export const SHOP_STATUS_NOT_BACK = 10
export const SHOP_STATUS_NULL = '0'

export const ADMIN_SHOP_STATUSES = [
	{
		value: SHOP_STATUS_POTENTIAL_CLIENT, label: 'Potencjalny klient', color: 'waiting',
	}
	,{
		value: SHOP_STATUS_POSTPONED, label: 'Przełożone', color: 'to-send',
	},
	{
		value: SHOP_STATUS_FUNCTIONALITY_LACK, label: 'Brak funkcji', color: 'edited',
	},
	{
		value: SHOP_STATUS_CALL, label: 'Odezwać się', color: 'to-send',
	},
	{
		value: SHOP_STATUS_LOST, label: 'Lost', color: 'grey',
	},
	{
		value: SHOP_STATUS_TRIAL, label: 'Trial', color: 'edited',
	},
	{
		value: SHOP_STATUS_CLIENT, label: 'Klient', color: 'green',
	},
	{
		value: SHOP_STATUS_WILL_BACK, label: '100% wróci', color: 'grey',
	},
	{
		value: SHOP_STATUS_POSSIBLE_BACK, label: 'Możliwy powrót', color: 'grey',
	},
	{
		value: SHOP_STATUS_NOT_BACK, label: 'Nie wróci', color: 'grey',
	},
	{
		value: SHOP_STATUS_NULL, label: 'Brak', color: 'grey',
	},
]

export const findAdminShopStatusStatusById = (id) => ADMIN_SHOP_STATUSES.find((status) => status.value === id);
