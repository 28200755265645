import React from 'react';
import Product from "./Product";
import PhotoPreview from "../../../../../src_shared/components/ui/photoPreview/PhotoPreview";
import usePhotoPreview from "../../../../../src_shared/components/ui/photoPreview/usePhotoPreview";

const NormalProducts = ({values, getTotalPrice, inClientsOrders, editable}) => {
	const {showPreviewModal, hidePreviewModal, openPreviewModal} = usePhotoPreview();

	return (
		<ul className="order-summary__products">
			{values.products.map((product) => (
				<Product
					editable={editable}
					key={product.id}
					values={values}
					product={product}
					getTotalPrice={getTotalPrice}
					inClientsOrders={inClientsOrders}
					openPreviewModal={openPreviewModal}
				/>
			))}
			<PhotoPreview
				hide={hidePreviewModal}
				show={showPreviewModal}
			/>
		</ul>
	);
};

export default NormalProducts;
