import React from 'react';
import useLang from "../../../../src_shared/hooks/useLang";
import Field from "../../../../src_shared/components/form/field/Field";
import Loader from "../../../app/shared/Loader";
import SwitchField from "../../../../src_shared/components/form/switchField/SwitchField";
import {useParams} from "react-router-dom";
import {services} from "../../../RestServices";
import AsyncSearchSelectField
	from "../../../../src_shared/components/form/selectField/asyncSearchSelectField/AsyncSearchSelectField";

const ShippingCountryForm = ({getFieldProps, onSave, isWaiting, values}) => {
	const {getLangText} = useLang();
	const {id} = useParams();

	const {setValue} = getFieldProps('country_name');

	const formatOptionLabel = (option) => (<div style={{display: "flex"}}>
			<div className="mr-1">{option.flag}</div>
			<div>{option.name}</div>
		</div>
	);

	return (
		<div className={`${!!id ? '' : 'd-flex'} flex-sm-nowrap flex-wrap mb-3`}>
			<div className={!!id ? 'd-flex' : ''}>
				<AsyncSearchSelectField
					{...getFieldProps('country_id')}
					label={getLangText('input_label_shipping_country')}
					className="default-size mr-sm-3 mr-2 mb-0"
					placeholder={getLangText('input_placeholder_shipping_country_name')}
					rest={services.API_SETTINGS_SHIPPING_COUNTRIES}
					// formatOptionLabel={formatOptionLabel}
					defaultValue={{id: values.country_id, name: values.country_name}}
					formatOptionLabel={formatOptionLabel}
					setSearchValue={(value) => setValue('country_name', value)}
					searchOnFocus
				/>
			</div>
			{id &&
				<div>
					<SwitchField
						{...getFieldProps('active')}
						label={getLangText('active_label_2')}
						className="switch-form mt-3 inline-label align-items-start big-label light-version"
					/>
				</div>
			}
			<div className="button-place text-left">
				<button
					type="button"
					style={{height: '45px', marginTop: id ? '0' : '22px'}}
					onClick={onSave}
					className={`button primary ${isWaiting ? 'loading-button' : ''}`}
				>
					<Loader isLoading={isWaiting}/>
					{getLangText(!!id ? 'button_save_changes' : 'button_add_country')}
				</button>
			</div>
		</div>
	);
};

export default ShippingCountryForm;
