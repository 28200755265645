import useLang from "../../../../../src_shared/hooks/useLang";
import useFormLocal from "../../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../../RestServices";
import Loader from "../../../../app/shared/Loader";
import Field from "../../../../../src_shared/components/form/field/Field";
import SwitchField from "../../../../../src_shared/components/form/switchField/SwitchField";
import TextArea from "../../../../../src_shared/components/form/textarea/TextArea";
import CharactersCounter from "../../../../../src_shared/components/ui/CharactersCounter";
import React from "react";
import Wrapper from "../Wrapper";
import OrderPaymentReminderModel from "../../../../../modules/models/settings/OrderPaymentReminderModel";
import {findOrderStatusById, ORDER_STATUES} from "../../../../app/shared/enums/OrderStatuses";
import Select from 'react-select';
import Group from "../../../../../src_shared/components/form/group/Group";

const OrderPaymentReminderConfiguration = () => {
    const {getLangText} = useLang()

    const [{onSave, isWaiting, getFieldProps, isLoading, values, setValue, updateValues}] = useFormLocal({
        rest: services.API_SETTINGS_ORDER_PAYMENT_REMINDER,
        model: OrderPaymentReminderModel,
    });

    const formatOptionLabel = (option) => {
        if (!option?.value) return null;
        return (
            <div
                style={{paddingRight: 16}}
                className={`status-bg ${findOrderStatusById(option.value)?.color}`}>{getLangText(findOrderStatusById(option.value)?.label)}
            </div>
        )
    }


    const ClearIndicator = () => null

    return (
        <Wrapper>
            <div className="loader-parent">
                <Loader isLoading={isLoading}/>
                <div className="settings-form pt-4 bg-[white] rounded-b-[5px]">
                    <form onSubmit={onSave}>
                        <div className="steps-wrapper md:max-w-[50%]">
                            <div className="font-bold">
                                {getLangText('selectStatusLabel')}
                            </div>
                            <div className="text-desc text-sm mb-2 font-medium">
                                {getLangText('selectStatusDescription')}
                            </div>
                            <Group
                                showLabel={false}
                                className="default-size"
                            >
                                <Select
                                    {...getFieldProps('statuses')}
                                    id="custom-dropdown-default"
                                    placeholder={getLangText('selectButton')}
                                    className="default-size order-status"
                                    options={ORDER_STATUES}
                                    onChange={(value) => updateValues({
                                        statuses: value
                                    })}
                                    hideSelectedOptions={false}
                                    formatOptionLabel={formatOptionLabel}
                                    isMulti
                                    classNames={{
                                        option: (data) => data.isSelected ? 'selected' : '',
                                    }}
                                    styles={{
                                        multiValue: (styles) => ({
                                            ...styles,
                                            position: 'relative'
                                        }),
                                    }}
                                    components={{
                                        ClearIndicator
                                    }}
                                />
                            </Group>
                            <div className="p-3 rounded-[8px] border mb-3">
                                <button
                                    type="button"
                                    onClick={() => setValue('warning_active', !+values.warning_active)}
                                    className="flex items-center w-full"
                                >
                                    <div className="font-bold">{getLangText('sendingAnAlert')}</div>
                                    <SwitchField
                                        {...getFieldProps('warning_active')}
                                        label={getLangText('active')}
                                        className="switch-form ml-auto inline-label big-label light-version d-flex mb-0"
                                    />
                                </button>
                                {!!+values.warning_active &&
                                    <div className="mt-3">
                                        <div className="max-w-[200px]">
                                            <Field
                                                addon={getLangText('daysLabel')}
                                                label={getLangText('daysAmount')}
                                                {...getFieldProps('warning_days')}
                                                className="mb-2"
                                                type="number"
                                            />
                                        </div>
                                        <div className="form-info-box small-line-height w-100 mb-2">
                                            <div>
                                                <i className="icon-info-c"/>
                                            </div>
                                            <div>
                                                <div className="title">{getLangText('daysAmountFromProductAdded')}</div>
                                                <div className="desc">
                                                    {getLangText('daysAmountWillBeSentAnAlert')}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="characters-counter-parent">
                                            <TextArea
                                                {...getFieldProps('warning_message')}
                                                rows={5}
                                                label={getLangText('messageLabel')}
                                                className="mt-3 mb-2"
                                                extraProps={{
                                                    maxLength: 640,
                                                }}
                                            />
                                            <CharactersCounter value={values.warning_message} max={640}/>
                                        </div>
                                        <div className="form-info-box small-line-height w-100">
                                            <div>
                                                <i className="icon-info-c"/>
                                            </div>
                                            <div>
                                                <div className="title">{getLangText('messageToClient')}</div>
                                                <div className="desc">
                                                    <div>
                                                        <div className="tag">{getLangText('days_tag')}</div>
                                                        {getLangText('inThisPlaceWillBeDaysAmountNumber')}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="p-3 rounded-[8px] border">
                                <button
                                    type="button"
                                    onClick={() => setValue('active', !+values.active)}
                                    className="flex items-center w-full"
                                >
                                    <div className="font-bold">{getLangText('orderCancellation')}</div>
                                    <SwitchField
                                        {...getFieldProps('active')}
                                        label={getLangText('active')}
                                        className="switch-form ml-auto inline-label big-label light-version d-flex mb-0"
                                    />
                                </button>
                                {!!+values.active &&
                                    <div className="mt-3">
                                        <div className="max-w-[200px]">
                                            <Field
                                                addon={getLangText('daysLabel')}
                                                label={getLangText('daysAmount')}
                                                {...getFieldProps('days')}
                                                className="mb-2"
                                                type="number"
                                            />
                                        </div>
                                        <div className="form-info-box small-line-height w-100 mb-4">
                                            <div>
                                                <i className="icon-info-c"/>
                                            </div>
                                            <div>
                                                <div className="title">{getLangText('daysAmountFromProductAdded')}</div>
                                                <div className="desc">
                                                    {getLangText('daysAmountOrderWillBeCancel')}
                                                </div>
                                            </div>
                                        </div>
                                        <SwitchField
                                            {...getFieldProps('message_active')}
                                            label={getLangText('messageToClient')}
                                            subLabel={getLangText('messageToClientAfterCancelOrder')}
                                            className="switch-form align-items-start inline-label big-label light-version d-flex mb-0"
                                        />
                                        {!!+values.message_active &&
                                            <>
                                                <div className="characters-counter-parent">
                                                    <TextArea
                                                        {...getFieldProps('message_text')}
                                                        showLabel={false}
                                                        rows={5}
                                                        className="mt-3 mb-2"
                                                        extraProps={{
                                                            maxLength: 640,
                                                        }}
                                                    />
                                                    <CharactersCounter value={values.message_text} max={640}/>
                                                </div>
                                                <div className="form-info-box small-line-height w-100">
                                                    <div>
                                                        <i className="icon-info-c"/>
                                                    </div>
                                                    <div>
                                                        <div className="title">{getLangText('messageToClient')}</div>
                                                        <div className="desc">
                                                            <div>
                                                                <div className="tag">{getLangText('number_tag')}</div>
                                                                {getLangText('inThisPlaceWillBeOrderNumber')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                }
                            </div>
                            <div className="flex items-center mt-4">
                                <button
                                    type="submit"
                                    className={`button small-size ml-auto primary ${isWaiting ? 'loading-button' : ''}`}
                                >
                                    {isWaiting && <Loader isLoading/>}
                                    {getLangText('saveButton')}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Wrapper>
    );
};

export default OrderPaymentReminderConfiguration;
