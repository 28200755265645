import React from 'react';
import Modal from "react-bootstrap/Modal";
import {services} from "../../../../../RestServices";
import useDataApi from "../../../../../../src_shared/hooks/useDataApi";
import Products from "../../../../orders/details/products/Products";
import Loader from "../../../../shared/Loader";
import useLang from "../../../../../../src_shared/hooks/useLang";

const ProductsModal = ({modalVisible, hideModal, item, getPrice}) => {
	const {getLangText} = useLang();

	const getOrderPrice = (amount) => getPrice(amount, item.currency?.text)

	const [{data, isLoading}] = useDataApi(`${services.API_ORDER_PRODUCT}/${item.shop_order_id}`, {
		products: []
	})

	return (
		<Modal
			show={modalVisible}
			dialogClassName="default-modal"
			onHide={hideModal}
		>
			<Modal.Header className="with-border">
				<Modal.Title>
					{getLangText('productsListTitle')}
					<div className="sub-title">{getLangText('customerBasketOrderLabel')}&nbsp;{item.shop_order_id}</div>
				</Modal.Title>
				<button
					onClick={hideModal}
					type="button"
					className="button"
				>
					<i className="icon-cross"/>
				</button>
			</Modal.Header>
			<Modal.Body>
				<div className="products-wrapper border-0 pt-0 mt-0 loader-parent">
					<Loader isLoading={isLoading} />
					<Products
						getTotalPrice={getOrderPrice}
						values={data}
						inClientsOrders
					/>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<button
					onClick={hideModal}
					type="button"
					className="button border-button"
				>
					{getLangText('closeButton')}
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default ProductsModal;
