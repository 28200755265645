import createReducer from "../../../../shared/helpers/createReducer";
import history from "../../../../../history";
import moment from "moment";
import {ALL_ORDERS_VIEW, INTERVAL} from "../../../../shared/enums/OrderViewTypes";
import {parseParams} from "../../../../../../../../src_shared/list/ListActions";
import DataReducer from "../../../../../../../../src_shared/form/data/DataReducer";
import {getReduceMap} from "../../../../../../../../src_shared/filters/filterReducer";
import {ORDER_STATUES} from "../../../../shared/enums/OrderStatuses";

const queryParams = history.location.search;
const parsedParams = parseParams(queryParams)

const preparedStatuses = parsedParams['status[]'] || [];
const preparedViewType = !!queryParams ? (parsedParams.dateFrom ? INTERVAL : ALL_ORDERS_VIEW) : ALL_ORDERS_VIEW;

const preparedDate = (initialState, paramsDate) => {
	if (!!queryParams) {
		if (parsedParams[paramsDate]) {
			return moment(parsedParams[paramsDate]).toDate()
		} else {
			return null;
		}
	} else {
		return initialState;
	}
}

export const getStateRoot = (state) => state.admin.users.userOrders.filters;

export const getInitState = () => DataReducer.getInitState({
	statuses: preparedStatuses || [],
	filtersSelectAll: preparedStatuses.length === ORDER_STATUES.length,
	fromDate: preparedDate(moment(new Date()).subtract(1, 'M').toDate(), 'dateFrom'),
	toDate: preparedDate(new Date(), 'dateTo'),
	viewType: preparedViewType,
}, {
	statuses: [],
	filtersSelectAll: false,
	fromDate: moment(new Date()).subtract(1, 'M').toDate(),
	toDate: new Date(),
	viewType: ALL_ORDERS_VIEW,
})

export const prefix = 'CMS_USER_ORDERS_FILTERS_';

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();
