import React from 'react';
import ShopSettingsLoader from "../../../shared/components/shopSettingsLoader/ShopSettingsLoader";
import CopyButton from "../../../../../src_shared/components/form/copyButton/CopyButton";
import {SHOP_ORIGIN_PL} from "../../../shared/enums/ShopOrigins";
import {useSelector} from "react-redux";
import useLang from "../../../../../src_shared/hooks/useLang";

const ShopBox = ({values, waiting}) => {
    const {getLangText} = useLang();

    const {userData} = useSelector((state) => state.session);
    const shopLinkToDisplay = `https://${userData.domain || userData.full_url}/${values.name_url}`
    return (
        (!!+values.is_shop_active && !waiting) &&
        <div className="overflow-hidden">
            <h2 className="font-bold mb-3.5">{getLangText('productSourceShopLabel')}</h2>
            <div className="shop-name-bar ml-auto only-link mt-sm-1 mt-3 overflow-hidden">
                <div
                    className="client-link border-0 pb-0 flex-grow overflow-hidden">
                    <div
                        className="form-group with-copy-btn with-preview overflow-visible order-sm-2 order-1 flex-grow">
                        <div className="control-label">{getLangText('productLinkLabel')}</div>
                        <div className="form-control">
                            <a
                                href={shopLinkToDisplay}
                                target="_blank"
                                className="short-link"
                            >
                                {shopLinkToDisplay}
                            </a>
                            <CopyButton
                                buttonClassName="ml-sm-auto border-button only-icon"
                                copyText={shopLinkToDisplay}
                            />
                            <a
                                href={shopLinkToDisplay}
                                className="button border-button small-size mb-0 ml-1 d-lg-flex d-none"
                                target="_blank"
                            >
                                <i className="icon-eye"/>
                                {getLangText('previewLabel')}
                            </a>
                        </div>
                    </div>
                    <ShopSettingsLoader className="mt-3"/>
                </div>
            </div>
        </div>
    );
};

export default ShopBox;
