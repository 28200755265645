import React, {useEffect} from 'react';
import Modal from "react-bootstrap/Modal";
import Field from "../../../../../../src_shared/components/form/field/Field";
import Loader from "../../../../shared/Loader";
import useLang from "../../../../../../src_shared/hooks/useLang";

const EditPaymentReceivedModal = ({modalVisible, hideModal, values, setValue, getFieldProps, isWaiting, onSave, data}) => {
	const {getLangText} = useLang();

	useEffect(() => {
		setValue('editPaymentReceived', true)
		setValue('payment_received', data.payment_received)

		return () => setValue('editPaymentReceived', false)
	}, [])

	return (
		<Modal
		dialogClassName="default-modal small-modal"
		show={modalVisible}
		onHide={hideModal}
	>
		<Modal.Header className="with-border">
			<Modal.Title>
				{getLangText('paymentReceivedEditModalTitle')}
			</Modal.Title>
			<button
				onClick={hideModal}
				type="button"
				className="button"
			>
				<i className="icon-cross"/>
			</button>
		</Modal.Header>
		<Modal.Body>
			<Field
				className="mt-2"
				label={getLangText('paymentDepositedFundsLabel')}
				{...getFieldProps('payment_received')}
				value={values.payment_received}
				setValue={setValue}
				type="number"
				extraProps={{
					min: 0,
				}}
			/>
		</Modal.Body>
		<Modal.Footer>
			<button
				onClick={hideModal}
				type="button"
				className="button border-button"
			>
				{getLangText('cancelButton')}
			</button>
			<button
				onClick={onSave}
				type="button"
				className={`button small-size primary ${isWaiting ? 'loading-button' : ''}`}
			>
				{(isWaiting) &&
					<Loader isLoading/>
				}
				{getLangText('saveButton')}
			</button>
		</Modal.Footer>
	</Modal>
	);
};

export default EditPaymentReceivedModal;
