import Model from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

class SettingsDiscountCodesModel extends Model {
	getModel() {
		return {
			name: '',
			value: '',
			free_shipping: '0',
			is_active: '1',
			codes: [],
		};
	}

	getValidators() {
		return {
			name: ValidationError.notEmpty,
			value: ValidationError.combine(
				ValidationError.notEmpty,
				ValidationError.smallerThan(101),
			),
		};
	}

	buildDTO(data) {
		const {name, value, free_shipping, is_active, discount_without_shipping} = data;
		return {
			name,
			value,
			free_shipping,
			discount_without_shipping: !!+free_shipping ? '0' : discount_without_shipping,
			is_active,
		}
	}
}

export default new SettingsDiscountCodesModel();
