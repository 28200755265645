import React from 'react';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {setFieldInChatList, updateFieldInItem} from "../../chatList/ChatListActions";
import axios from "axios";
import {selmoUrl} from "../../../../../src_shared/api/api";
import {useDispatch, batch} from "react-redux";
import {services} from "../../../../RestServices";
import useLang from "../../../../../src_shared/hooks/useLang";
import api from "../../../../../services/axios/axios";

const SetUnreadButton = ({activeConversation}) => {
	const dispatch = useDispatch();
	const {getLangText} = useLang();

	const setUnreadMessage = async (viewed) => {

		try {
			await api.put(`${selmoUrl}/${services.API_MESSENGER_UNREAD_MESSAGE}`, {
				user_id: activeConversation.user_id,
				viewed,
			})

			const preparedActiveConversation = {
				...activeConversation,
				viewed,
			}
			batch(() => {
				dispatch(setFieldInChatList('activeConversation', preparedActiveConversation))
				dispatch(updateFieldInItem('viewed', viewed, activeConversation.id))
			})
		} catch (e) {
			console.warn('cannot set message as unread')
		}
	}

	return (
		+activeConversation.viewed ?
			<OverlayTrigger
				placement='bottom'
				overlay={
					<Tooltip id="full-screen">
						{getLangText('setAsUnReadLabel')}
					</Tooltip>
				}
			>
				<button
					type="button"
					className="orders-number"
					onClick={() => setUnreadMessage('0')}
				>
					<i className="icon-read"/>
					<div className="lg:hidden text-xs font-bold ml-2">{getLangText('setAsUnReadLabel')}</div>
				</button>
			</OverlayTrigger> :
			<OverlayTrigger
				placement='bottom'
				overlay={
					<Tooltip id="full-screen">
						{getLangText('setAsReadLabel')}
					</Tooltip>
				}
			>
				<button
					type="button"
					className="orders-number"
					onClick={() => setUnreadMessage('1')}
				>
					<i className="icon-unread"/>
					<div className="lg:hidden text-xs font-bold ml-2">{getLangText('setAsReadLabel')}</div>
				</button>
			</OverlayTrigger>

	);
};

export default SetUnreadButton;
