import Model, {convertObjectProperties} from "../../../src_shared/modules/model/Model";
import {
    RANGE_FULL_ACCOUNT, RANGE_SELECTED_POST
} from "../../../components/app/automation/form/edit/components/defaultInformation/codeRangeWorking/CodeRangeWorking";
import {
    BUTTON_DESTINATION_LINK, BUTTON_DESTINATION_NEWSLETTER,
    BUTTON_DESTINATION_PRODUCT
} from "../../../components/app/automation/form/edit/components/addButton/buttonDestination/ButtonDestination";
import ValidationError from "../../../src_shared/form/validation/ValidationError";
import {createNewId} from "../../../components/app/live/details/default/liveDetailsReducer";

const productIdValidator = (value, data) => {
    if (data.buttonDestination === BUTTON_DESTINATION_PRODUCT && !!+data.add_button) {
        return ValidationError.notEmpty(value, data)
    }
    return false;
}

const buttonUrlValidator = (value, data) => {
    if (data.buttonDestination === BUTTON_DESTINATION_LINK && !!+data.add_button) {
        return ValidationError.isValidUrl(value, data)
    }
    return false;
}

export const newCommentReplyItem = {
    id: createNewId(), message: ''
}
class AutomationModel extends Model {
    getModel() {
        return {
            code: [],
            codeRange: null,
            message: '',
            add_button: '0',
            button_text: '',
            button_url: '',
            reply_comment_message: '',
            selectedPost: null,
            selectedProduct: null,
            active: '1',
            product_id: null,
            buttonDestination: BUTTON_DESTINATION_PRODUCT,
            reply_comment: '0',
            reply_message: '0',
            reply_comment_messages: [newCommentReplyItem],
        };
    }

    getValidators() {
        return {
            product_id: productIdValidator,
            code: ValidationError.arrayNotEmpty,
            reply_message: (value, data) => !+data.reply_comment && !+data.reply_message,
            button_url: buttonUrlValidator
        };
    }

    parseDTO(data) {
        return convertObjectProperties(
            data,
            {
                code: () => data.code?.map((i) => ({id: i, text: i, className: 'grey big-size'}))
            },
            {
                add_button: !!data.button_text ? '1' : '0',
                code: [],
                codeRange: data?.ig_media_id ? RANGE_SELECTED_POST : RANGE_FULL_ACCOUNT,
                selectedPost: data?.ig_media_id ? {
                    ig_media_photo: data.ig_media_photo,
                    ig_media_id: data.ig_media_id,
                    ig_media_description: data.ig_media_description,
                } : null,
                selectedProduct: data?.product_id ? {
                    id: data.product_id,
                    photo_small: data.product_photo,
                    price: data.product_price,
                    name: data.product_name,
                } : null,
                buttonDestination: !!data?.button_url ? BUTTON_DESTINATION_LINK : data.newsletter_title ? BUTTON_DESTINATION_NEWSLETTER : BUTTON_DESTINATION_PRODUCT,
                reply_comment_messages: [newCommentReplyItem],
            }
        );
    }

    buildDTO(data) {
        const code = data.code.map((i) => i.text);

        return {
            button_url: data.buttonDestination === BUTTON_DESTINATION_PRODUCT ? null : data.button_url,
            button_text: data.button_text,
            message: data.message,
            reply_comment: data.reply_comment,
            code,
            add_button: data.add_button,
            product_id: data.buttonDestination === BUTTON_DESTINATION_PRODUCT ? data?.selectedProduct?.id || null : null,
            selectedPost: data.codeRange === RANGE_FULL_ACCOUNT ? null : data.selectedPost,
            active: data.active,
            buttonDestination: data.buttonDestination,
            reply_message: data.reply_message,
            reply_comment_messages: data.reply_comment_messages,
            newsletter_title: data.buttonDestination === BUTTON_DESTINATION_NEWSLETTER ? data.newsletter_title : null,
            newsletter_description: data.buttonDestination === BUTTON_DESTINATION_NEWSLETTER ? data.newsletter_description : null,
        };
    }
}

export default new AutomationModel();
