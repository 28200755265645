import Model from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

class SettingsFakturowniaModel extends Model {
	getModel() {
		return {
			fakturownia_url: '',
			fakturownia_api_token: '',
			additional_information: '',
			vat_rate: '',
			lump_sum_tax_rate: '',
			warehouse_id: '',
		};
	}

	getValidators() {
		return {
			fakturownia_url: ValidationError.notEmpty,
			fakturownia_api_token: ValidationError.notEmpty,
			additional_information: ValidationError.skip,
			vat_rate: ValidationError.notEmpty,
			lump_sum_tax_rate: ValidationError.skip,
			warehouse_id: ValidationError.skip,
		};
	}
}

export default new SettingsFakturowniaModel();
