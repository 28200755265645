import Model from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

export class AssignProductsInReelsModel extends Model {
	getModel() {
		return {
			products: [],
		};
	}

	getValidators() {
		return {
			products: ValidationError.arrayNotEmpty,
		};
	}
}

export default new AssignProductsInReelsModel();
