import React, {useRef} from 'react';
import useDropdown from "../../../../../shared/hooks/useDropdown.js";
import useLang from "../../../../../../../src_shared/hooks/useLang";

const SortDropdown = ({toggleClientSort, sortBy, sortByField = 'client_name', sortByLang = 'sortByClientLabel'}) => {

	const buttonRef = useRef(null)
	const dropdownRef = useRef(null)
	const {getLangText} = useLang();

	const hideDropdown = () => setOpen(false)

	const {open, setOpen} = useDropdown(buttonRef, dropdownRef, hideDropdown);

	const onButtonClick = (value) => {
		setOpen(false);
		toggleClientSort(value)
	}

	return (
		<>
			<div ref={dropdownRef} className="react-dropdown position-relative ml-sm-3 sort-button">
				<button
					className={`dropdown-toggle tooltip-parent ${open ? 'show' : ''}`}
					onClick={() => setOpen(!open)}
					ref={buttonRef}
				>
					<div className="tooltip-content">
						{getLangText('sortLabel')}
					</div>
					<i className="icon-sort"/>
				</button>
				{open &&
					<div className="dropdown-menu show">
						<button
							type="button"
							className={`custom-option ${sortBy === sortByField ? 'active' : ''}`}
							onClick={() => onButtonClick(sortByField)}
						>
							{getLangText(sortByLang)}
						</button>
						<button
							type="button"
							className={`custom-option ${sortBy === null ? 'active' : ''}`}
							onClick={() => onButtonClick(null)}
						>
							{getLangText('sortByDateLabel')}
						</button>
					</div>
				}
			</div>
		</>
	);
};

export default SortDropdown;
