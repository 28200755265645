import React from 'react';
import {Helmet} from "react-helmet";
import useLang from "../../../src_shared/hooks/useLang";

const MaintenancePage = () => {
    const {getLangText} = useLang();

    return (
        <div className="cart-auth-wrapper loader-parent">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${getLangText('technicalWorksLabel')} - Selmo`}</title>
            </Helmet>
            <div className="container my-auto">
                <div className="flex align-items-center justify-content-center">
                    <div className="text-center">
                        <div className="mb-4">
                            <div className="logo d-block">
                                <img className="mx-auto" width={100} alt="selmo" src="/assets/images/logo.svg"/>
                            </div>
                        </div>
                        <h1 className="text-2xl mb-3 font-bold">{getLangText('technicalWorksLabel')}</h1>
                        <div className="text-gray-500">
                            {getLangText('technicalWorksDescriptionLabel')}
                        </div>
                        <div className="text-gray-500 mt-1">
                            {getLangText('stayInTouchLabel')} <a className="underline" href="mailto: kontakt@selmo.io">kontakt@selmo.io</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MaintenancePage;
