import Composition from "../../../../shared/helpers/Composition.js";
import createReducer from "../../../../shared/helpers/createReducer.js";
import ListReducer from "../../../../../../src_shared/list/ListReducer.js";

export const getStateRoot = (state) => state.lives.platform.activeList;
export const prefix = 'LIVE_PORTAL_ACTIVE_PRODUCTS_LIST_';

const defaultState = {}

const getInitState = () => ListReducer.getInitState(defaultState);

const getReduceMap = () =>  new Composition(
	ListReducer.getReduceMap(),
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

