import React from 'react';
import useLang from "../../../../../../../src_shared/hooks/useLang";
import useFormLocal from "../../../../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../../../../RestServices";
import SalesPostTemplateModel from "../../../../../../../modules/models/salesPost/SalesPostTemplateModel";
import Loader from "../../../../../../../src_shared/components/loader/Loader";
import Field from "../../../../../../../src_shared/components/form/field/Field";
import TextArea from "../../../../../../../src_shared/components/form/textarea/TextArea";

const TemplateForm = ({activeView, setActiveView}) => {
    const {getLangText} = useLang();
    const afterSubmit = () => {
        // showAlert({title: t('yuhu'), desc: t('successfulSaved')})
        setActiveView("LIST")
    }

    const rest = !!+activeView ? `${services.API_VIDEO_DESCRIPTION_TEMPLATES}/${activeView}` : services.API_VIDEO_DESCRIPTION_TEMPLATES

    const [{onSave, getFieldProps, isWaiting, isLoading, values}] = useFormLocal({
        rest,
        method: !!+activeView ? 'put' : 'post',
        model: SalesPostTemplateModel,
        initialAutoLoad: !!+activeView,
        afterSubmit,
    });

    return (
        <div className="pt-3">
            <div className="flex items-center mb-3">
                <button
                    type="button"
                    onClick={() => setActiveView('LIST')}
                    className="text-desc text-xs mr-2"
                >
                    <i className="icon-arrow-left"/>
                </button>
                <div className="font-bold">
                    {getLangText('editTemplate')}
                </div>
            </div>
            {isLoading ?
                <Loader absolute={true}/> :
                <div>
                    <Field
                        label={getLangText('name')}
                        {...getFieldProps('name')}
                    />
                    <TextArea
                        multiline
                        label={getLangText('input_label_package_type_description')}
                        rows={8}
                        {...getFieldProps('text')}
                        // numberOfLines={8}
                        // style={{height: 200, padding: 10, lineHeight: 16}}
                    />
                    <div className="form-info-box small-line-height w-100 mb-3">
                        <div>
                            <i className="icon-info-c"/>
                        </div>
                        <div>
                            <div className="title">
                                {getLangText('input_tag_products')}
                            </div>
                            <div className="desc">
                                {getLangText('settings_messenger_tag_label_1')}
                            </div>
                        </div>
                    </div>
                    <button
                        onClick={onSave}
                        type="button"
                        className={`button small-size primary w-full mb-4 ${isWaiting ? 'loading-button' : ''}`}
                    >
                        {isWaiting && <Loader isLoading/>}
                        {getLangText('saveButton')}
                    </button>
                </div>
            }

        </div>
    );
};

export default TemplateForm;
