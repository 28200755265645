import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {useSelector} from "react-redux";
import {RouterPaths} from "./RouterPath";

export const checkRoles = (userPermissions, allowedPermissions) => (
	userPermissions?.some((x) => allowedPermissions?.includes(x))
);

export const UserPermissionRoute = ({
									Component,
									allowedPermissions,
									children,
									...rest
								}) => {
	const {user_permissions, main_account_id} = useSelector((state) => state.session.userData);

	const permissionsMatched = checkRoles(user_permissions, allowedPermissions);

	if (!!+main_account_id && !permissionsMatched) {
		return <Redirect path={RouterPaths.Dashboard} />
	}

	if (!Component) {
		return (
			<Route {...rest}>
				{children}
			</Route>
		);
	}

	return (
		<Route
			component={Component}
			{...rest}
		/>
	);
};

UserPermissionRoute.defaultProps = {
	allowedPermissions: [],
}
export default UserPermissionRoute;
