import React, {useState} from 'react';
import useLang from "../../../../../src_shared/hooks/useLang";
import {NavLink, useLocation} from "react-router-dom";
import {RouterPaths} from "../../../../routes/RouterPath";
import useFetchData from "../../../../../src_shared/hooks/useFetchData";
import {services} from "../../../../RestServices";
import SelmoProSignUpModal from "../../../selmoPro/components/SelmoProSignUpModal";
import {useSelector} from "react-redux";
import {SHOP_ORIGIN_PL} from "../../enums/ShopOrigins";

const tabs = [
    {id: 0, href: RouterPaths.SelmoPro, label: 'summaryLabel'},
    {id: 1, href: RouterPaths.SelmoProOverlay, label: 'overlay'},
    {id: 2, href: RouterPaths.SelmoProMrAutomate, label: 'panAutomacikLabel'},
    {id: 3, href: RouterPaths.SelmoProDepot, label: 'storageLabel'},
]

export const SelmoProLandingContext = React.createContext({
    data: {},
    isLoading: false,
    setShowModal: () => {
    },
    isSubmittedToQualified: false,

});

const SelmoProLandingWrapper = ({children, type}) => {
    const {getLangText} = useLang();
    const {pathname} = useLocation();
    const [isSubmittedToQualified, setIsSubmittedToQualified] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const {origin} = useSelector((state) => state.session.userData);

    const [{data, isLoading}] = useFetchData({
        rest: services.API_SELMO_PRO,
    })

    return (
        <SelmoProLandingContext.Provider value={{data, isLoading, isSubmittedToQualified, setShowModal}}>
            <div className="bg-[#000] sm:rounded-[5px] pb-16">
                {origin === SHOP_ORIGIN_PL &&
                    <div className="bg-[rgba(216,216,216,0.07)] p-6 pb-0 border-b border-[#323234]">
                        <div className="text-lg text-[#fff] font-bold mb-[12px]">
                            Selmo Pro
                        </div>
                        <div className="flex items-center space-x-5 pb-3.5 overflow-x-auto">
                            {tabs.map((tab) => (
                                <NavLink
                                    to={tab.href}
                                    className={`relative whitespace-nowrap text-[#97979B] font-bold text-xs hover:text-[#fff] hover:no-underline transition-all before:content-[""] before:absolute before:-bottom-3.5 before:left-0 before:right-0 before:h-[1px] before:transition-all before:duration-300 ${pathname === tab.href ? 'before:bg-[#5a8bf4]' : 'before:bg-[transparent]'}`}
                                    activeClassName="text-[#fff]"
                                    exact
                                >
                                    {getLangText(tab.label)}
                                </NavLink>
                            ))}
                        </div>
                    </div>
                }
                {children}
            </div>
            {showModal &&
                <SelmoProSignUpModal
                    show={showModal}
                    hide={() => setShowModal(false)}
                    setIsSubmittedToQualified={setIsSubmittedToQualified}
                />
            }
        </SelmoProLandingContext.Provider>
    );
};

export default SelmoProLandingWrapper;
