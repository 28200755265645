import React, {useState} from "react";
import FormActions from "./FormActions";
import useForm from "../../../../../src_shared/form/useForm";
import {useParams} from "react-router-dom";
import moment from "moment";
import useLang from "../../../../../src_shared/hooks/useLang";
import SelectCourierModal from "./SelectCourierModal";
import ConfirmModal from "../../../live/details/default/summary/export/ConfirmModal";
import useRemoveData from "../../../../../src_shared/hooks/useRemoveData";
import {services} from "../../../../RestServices";
import {convertToUserTimeZone} from "../../../shared/helpers/dateHelpers";

const ShippyproLabelForm = ({data}) => {
	const params = useParams()
	const {getLangText} = useLang();
	const [selectCourierModalOpen, setSelectCourierModalOpen] = useState(false);
	const {
		data: {values, setValue},
	} = useForm({
		FormActions,
		params,
		preventAutoLoad: true,
	});

	const [{
		openModal: openRemoveModal,
		hideModal: hideRemoveModal,
		confirmModalVisible,
		isDeleting,
		removeItem,
	}] = useRemoveData({
		rest: services.API_SETTINGS_SHIPPYPRO_TEMPLATE,
	});

	return (
		<>
			{(!!data.shippypro_templates?.length && !data.shippingData.shippypro_carrier_id) &&
				<section className="summary-section mb-3">
					<div className="section-header mb-3">
						<div className="flex-grow-1">
							<h2>{getLangText('generateLabelLabel')}</h2>
							<div className="section-header__description">
								{getLangText('addLabelByShippyProLabel')}
							</div>
						</div>
						<div>
							<img
								style={{height: '22px'}}
								src="/assets/images/shippypro.png"
								alt="Shippy Pro"
							/>
						</div>
					</div>
					{data.shippypro_templates?.map((box) => (
						<div
							key={box.id}
							id={box.id}
							className={`inpost-chooser__box furgonetka-chooser ${values.template === box.id ? 'active' : ''}`}
						>
							<input
								onChange={() => setValue('template', box.id)}
								checked={values.template === box.id}
								type="radio"
								value={values.template}
							/>
							<span className="checkmark"/>
							<div className="inpost-chooser__box__text-wrapper">
								<div className="inpost-chooser__box__text-wrapper__top">
									{getLangText('templateLabel')} {box.name}
								</div>
								<div className="inpost-chooser__box__text-wrapper__bottom">
									max. {box.width} x {box.height} x {box.length} do {box.weight} kg
								</div>
							</div>
						</div>
					))}
					<div className="button-place">
						<button
							onClick={() => setSelectCourierModalOpen(true)}
							type="button"
							disabled={!values.template}
							className={`button primary w-100 ${!values.template ? 'not-active' : ''}`}
						>
							{getLangText('selectCourierLabel')}
						</button>
					</div>
				</section>
			}
			{data.shippingData.shippypro_carrier_id &&
				<section className="summary-section mb-3">
					<div className="section-header mb-3">
						<div className="flex-grow-1">
							<h2>{getLangText('labelLabel')}</h2>
							<div className="section-header__description">
								{getLangText('manageLabelLabel')}
							</div>
						</div>
						<div>
							<a
								href="https://furgonetka.pl/konto/partner/1763980?url=https://furgonetka.pl"
								target="_blank"
								rel="nofollow"
							>
								<img
									style={{height: '22px'}}
									src="/assets/images/shippypro.png"
									alt="Shippy Pro"
								/>
							</a>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6 pr-1">
							<a
								target="_blank"
								rel="nofollow"
								href={data.shippingData.shippypro_label_url}
								className="button border-button w-100 big-width position-relative"
							>
								{getLangText('printButton')}
								<i className="icon-print"/>
							</a>
						</div>
						<div className="col-md-6 pl-1">
							<button
								disabled
								onClick={openRemoveModal}
								type="button"
								className="button border-button big-width w-100"
							>
								{getLangText('removeButton')}
								<i className="icon-bin"/>
							</button>
						</div>
					</div>
					{data.shippingData.shippypro_label_date &&
						<div className="info-label mt-3">
							<i className="icon-tick-c"/>
							{getLangText('labelHasBennGeneratedLabel')}
							<div className="date-box">
								<span>{convertToUserTimeZone(data.shippingData.shippypro_label_date).format('DD.MM.YYYY')}</span>
								<span>{convertToUserTimeZone(data.shippingData.shippypro_label_date).format('HH:mm:ss')}</span>
							</div>
						</div>
					}
				</section>
			}
			{confirmModalVisible &&
				<ConfirmModal
					dialogClassName="w-380"
					title={getLangText('askForRemovingShippyProLabelLabel')}
					SubTitle={() => getLangText('wantToRemoveLabelLabel')}
					saveButton={getLangText('removeButton')}
					show={confirmModalVisible}
					isWaiting={isDeleting}
					onSubmit={removeItem}
					hide={hideRemoveModal}
					saveButtonClass="danger"
				/>
			}
			{selectCourierModalOpen &&
				<SelectCourierModal
					template={values.template}
					show={selectCourierModalOpen}
					hide={() => setSelectCourierModalOpen(false)}
				/>
			}
		</>
	);
};

export default ShippyproLabelForm;



