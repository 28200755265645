import Composition from "../../../shared/helpers/Composition";
import createReducer from "../../../shared/helpers/createReducer";
import FormReducer from "../../../../../src_shared/form/FormReducer";
export const getStateRoot = (state) => state.shopSettings.pages.form;
export const prefix = 'SHOP_SETTINGS_PAGES_FORM_';

const getInitState = () => FormReducer.getInitState();

const getReduceMap = () => new Composition(
	FormReducer.getReduceMap(),
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

