import React, {useEffect} from 'react';
import useLang from "../../../../../../src_shared/hooks/useLang";
import TextArea from "../../../../../../src_shared/components/form/textarea/TextArea";
import SwitchField from "../../../../../../src_shared/components/form/switchField/SwitchField";
import TimePicker from "react-time-picker";
import {useSelector} from "react-redux";
import DatePicker from "react-datepicker";
import {prepareRealLang} from "../../../../../../services/i18n/i18nAPI";

const PublishInSelmoApp = ({isActive, getFieldProps, setValue, values}) => {
    const {getLangText} = useLang();
    const {lang} = useSelector((state) => state.i18n);

    useEffect(() => {
        if (values.selmo_app_description === null) {
            setValue('selmo_app_description', getLangText('clickAddToBuy'))
        }
    }, [values.selmo_app_description])

    return (
        <section className="bg-[#fff] rounded-[5px] mt-3 p-[25px] tooltip-parent">
            {!isActive &&
                <div className="tooltip-content small-size leading-[16px] text-center">
                    {getLangText('assignProductToGoNext')}
                </div>
            }
            <div
                onClick={() => isActive && setValue('selmo_app_active', !+values.selmo_app_active)}
                className={`flex items-center ${isActive ? 'cursor-pointer' : 'cursor-not-allowed'}`}
            >
                <div>
                    <img
                        className={`w-[24px] h-[24px] min-w-[24px] mr-2 ${isActive ? '' : 'grayscale opacity-40'}`}
                        src="/assets/images/svg/selmo-circle-blue.svg"
                        alt="selmo"
                    />
                </div>
                <div
                    className={`font-bold text-lg ${isActive ? '' : 'text-[#9CA3AF]'}`}>{getLangText('visibleInSelmoApp')}</div>
                <SwitchField
                    {...getFieldProps('selmo_app_active')}
                    name="selmo_app_active"
                    className="switch-form ml-auto mb-0"
                    setValue={setValue}
                    showLabel={false}
                    disabled={!isActive}
                />
            </div>
            {(!!+values.selmo_app_active && isActive) &&
                <div className="animate-[slideAndShow_.3s_ease_forwards] mt-3">
                    <div className="form-info-box w-100 my-3">
                        <div>
                            <i className="icon-info-c"/>
                        </div>
                        <div>
                            <div className="title">{getLangText('youDontHaveToWriteCodesInApp')}</div>
                            <div className="desc">
                                {getLangText('youDontHaveToWriteCodesInAppDesc')}
                            </div>
                        </div>
                    </div>
                    <TextArea
                        {...getFieldProps('selmo_app_description')}
                        name="selmo_app_description"
                        setValue={setValue}
                        label={getLangText('input_label_package_type_description')}
                    />
                    <SwitchField
                        {...getFieldProps('selmo_app_publish_later')}
                        name="selmo_app_publish_later"
                        className="switch-form inline-label big-label light-version mb-0"
                        setValue={setValue}
                        label={getLangText('publishLater')}
                    />
                    {!!+values.selmo_app_publish_later &&
                        <div className="grid grid-cols-2 gap-4 mt-3 animate-[slideAndShow_.3s_ease_forwards]">
                            <div className="form-group calendar-input">
                                <div className="control-label">
                                    {getLangText('dateLabel')}
                                </div>
                                <DatePicker
                                    portalId="my-popper"
                                    className="form-control"
                                    selected={values.selmo_app_publish_date}
                                    onChange={(value) => setValue('selmo_app_publish_date', value)}
                                    locale={lang}
                                    dateFormat="dd.MM.y"
                                />
                                <i className="icon-calendar"/>
                            </div>
                            <div className="form-group">
                                <div className="control-label">
                                    {getLangText('hour')}
                                </div>
                                <div className="react-time-picker-wrapper">
                                    <TimePicker
                                        clearIcon={false}
                                        className="form-control flex items-center"
                                        disableClock={true}
                                        locale={prepareRealLang(lang)}
                                        onChange={(value) => setValue('selmo_app_publish_time', value)}
                                        value={values.selmo_app_publish_time}
                                        format="HH:mm"
                                    />
                                    <i className="icon-time"/>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
        </section>
    );
};

export default PublishInSelmoApp;
