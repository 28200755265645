import React from 'react';
import useLang from "../../../../../src_shared/hooks/useLang";
import EmptyList from "../../../../app/shared/EmptyList";
import moment from "../../../../app/shared/moment";
import ServerImage from "../../../../app/shared/components/serverImage/ServerImage";
import useScanTranslations from "../../../../app/shared/hooks/useScanTranslations";
import useActivityHistoryRedirect from "../../../../app/shared/hooks/useActivityHistoryRedirect";
import {useSelector} from "react-redux";
import {convertToUserTimeZone} from "../../../../app/shared/helpers/dateHelpers";
const Table = ({updateSortOptions, isWaiting, getSortClass, items}) => {
    const {getLangText} = useLang();
    const {getScanTranslations} = useScanTranslations()
    const getTableRowClick = useActivityHistoryRedirect();
    const {userData} = useSelector((state) => state.session);

    return (
        <EmptyList
            items={items}
            isLoading={isWaiting}
            className="with-padd with-bg rounded-b-[5px]"
            imgSrc="/assets/images/empty/orders-icon-illustration.svg"
            title={getLangText('emptyHereLabel')}
            text={getLangText('noStatisticsToDisplay')}
        >
            <div className="table-wrapper">
                <div className="table-responsive">
                    <table className="table live-table">
                        <thead>
                        <tr>
                            <th
                                name="name"
                                onClick={updateSortOptions}
                                className={getSortClass('name')}
                            >
							<span name="name">
								{getLangText('nameLabel')}
							</span>
                            </th>
                            <th
                                name="type"
                                onClick={updateSortOptions}
                                className={getSortClass('type')}
                            >
							<span name="type">
								{getLangText('typeLabel')}
							</span>
                            </th>
                            <th
                                name="date"
                                onClick={updateSortOptions}
                                className={`${getSortClass('date')} text-right`}
                            >
							<span name="date">
								{getLangText('dateLabel')}
							</span>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {items.map((item) => (
                            <tr
                                {...getTableRowClick(item)}
                                className="not-clicked-tr"
                                key={item.id}
                            >
                                <td className="name-td">
                                    <div className="flex items-center">
                                        <div className="w-[20px] h-[20px] rounded-full overflow-hidden mr-2">
                                            {item.photo && item.photo !== '0' ?
                                                <ServerImage className="w-full h-full object-cover" src={item.photo}/> :
                                                <img className="w-full h-full object-cover"
                                                     src="/assets/images/default/avatar.svg" alt=""/>}
                                        </div>
                                        <strong className="text-nowrap">{item.name || userData.name}</strong>
                                    </div>
                                </td>
                                <td dangerouslySetInnerHTML={{__html: getScanTranslations(item)}} />
                                <td className="date-td date text-right text-nowrap">
                                    <div>{convertToUserTimeZone(item.date).format('DD.MM.YYYY HH:mm:ss')}</div>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </EmptyList>
    );
};

export default Table;
