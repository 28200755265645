export const defaultLang = "en";

export const supportedLangs = {
	sk: "Slovenský",
	sv: "Svenska",
	da: "Dansk",
	en: "English US",
	th: "แบบไทย",
	// es: 'Español',
	// nl: 'Nederlands',
};

export const supportedOptionLangs = [
	{
		label: 'Slovenský',
		value: 'sk',
		flag: 'sk',
	},
	{
		label: 'Svenska',
		value: 'sv',
		flag: 'sv',
	},
	{
		label: 'Dansk',
		value: 'da',
		flag: 'da',
	},
	{
		label: 'English US',
		value: 'en',
		flag: 'en',
	},
	{
		label: 'แบบไทย',
		value: 'th',
		flag: 'th',
	},
]

export const langUrl = "/authLocales/{lang}.json";
