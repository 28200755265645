import React from 'react';
import Field from "../../../../src_shared/components/form/field/Field";
import SwitchField from "../../../../src_shared/components/form/switchField/SwitchField";
import Loader from "../../../app/shared/Loader";
import useLang from "../../../../src_shared/hooks/useLang";
import TextArea from "../../../../src_shared/components/form/textarea/TextArea";
import {useParams} from "react-router-dom";

const PackagesForm = ({getFieldProps, onSave, isWaiting}) => {
	const {getLangText} = useLang()
	const {id} = useParams();
	return (
		<>
			<Field
				{...getFieldProps('name')}
				className="w-100"
				label={getLangText('input_label_package_type_name')}
			/>
			<TextArea
				{...getFieldProps('description')}
				className="mb-sm-0 mb-3 mb-3"
				label={getLangText('input_label_package_type_description')}
			/>
			{!!id &&
				<SwitchField
					{...getFieldProps('active')}
					label={getLangText('active_label')}
					className="d-flex switch-form inline-label big-label light-version mb-0 mt-3"
				/>
			}
			<button
				type="submit"
				onClick={onSave}
				className={`button primary mt-3 ${isWaiting ? 'loading-button' : ''}`}
			>
				<Loader isLoading={isWaiting}/>
				{getLangText(!!id ? 'saveButton' : 'button_add')}
			</button>
		</>
	);
};

export default PackagesForm;
