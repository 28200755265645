import React, {useMemo} from 'react';
import Modal from "react-bootstrap/Modal";
import Field from "../../../../../../../../src_shared/components/form/field/Field";
import SelectField from "../../../../../../../../src_shared/components/form/selectField/SelectField";
import TextArea from "../../../../../../../../src_shared/components/form/textarea/TextArea";
import Loader from "../../../../../../../../src_shared/components/loader/Loader";
import useLang from "../../../../../../../../src_shared/hooks/useLang";
import {ExpendituresReasonTypes} from "../../../../../../shared/enums/ExpendituresTypes";

const ExpendituresModalForm = ({onSave, getFieldProps, hideModal, isWaiting, maxQuantity}) => {
    const {getLangText} = useLang();
    const preparedOptions = useMemo(() => ExpendituresReasonTypes.map((i) => ({...i, label: getLangText(i.label)})), [])

    return (
        <form className="grow flex flex-col" onSubmit={onSave}>
            <Modal.Body className="pt-3 pb-5 grow">
                <div className="grid grid-cols-4 gap-3">
                    <Field
                        {...getFieldProps('quantity')}
                        label={getLangText('quantityLabel')}
                        type="number"
                        extraProps={{
                            min: 1,
                            required: true,
                            max: maxQuantity
                        }}
                    />
                    <div className="col-span-3">
                        <SelectField
                            {...getFieldProps('reason')}
                            label={getLangText('reasonLabel')}
                            options={preparedOptions}
                            placeholder={getLangText('selectExpendituresReasonLabel')}
                            className="default-size"
                            extraProps={{
                                isSearchable: false,
                            }}
                        />
                    </div>
                </div>
                <TextArea
                    {...getFieldProps('note')}
                    label={getLangText('noteLabel')}
                />
            </Modal.Body>
            <Modal.Footer>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button border-button"
                >
                    {getLangText('cancelButton')}
                </button>
                <button
                    type="submit"
                    className={`button primary ${isWaiting ? 'loading-button' : ''}`}
                >
                    {isWaiting && <Loader isLoading/>}
                    {getLangText('saveButton')}
                </button>
            </Modal.Footer>
        </form>
    );
};

export default ExpendituresModalForm;
