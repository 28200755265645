import React, {useState} from 'react';
import useLang from "../../../../src_shared/hooks/useLang";
import BillingDataModal from "./BillingDataModal";
import {services} from "../../../RestServices";
import ComponentLoader from "../../../app/shared/components/componentLoader/ComponentLoader";
import useFetchData from "../../../../src_shared/hooks/useFetchData";


const BillingData = () => {
    const {getLangText} = useLang();
    const [showBillingDataModal, setShowBillingDataModal] = useState(false);

    const [{data: values, isLoading, fetchData}] = useFetchData({
        rest: services.API_SETTINGS_INVOICE_DATA,
    })

    return (
        <div className="sm:col-span-2">
            {isLoading ? <ComponentLoader/> :
                <div className="default-shadow-box">
                    <div className="flex items-center mb-3 w-full">
                        <h3 className="title grow mb-0">{getLangText('billingDataLabel')}</h3>
                        <button
                            type="button"
                            className="button border-button small-size"
                            onClick={() => setShowBillingDataModal(true)}
                        >
                            {getLangText('editButton')}
                        </button>
                    </div>
                    <div className="text-desc text-sm leading-[30px]">
                        <div>{values.name || values.normal_name}</div>
                        {values.normal_address ?
                            <div>
                                {values.normal_address}
                            </div> :
                            <>
                                <div>{values.address}</div>
                                <div>{values.zip_code}{' '}{values.city}</div>
                                <div>{getLangText('nipLabel')}: {values.nip}</div>
                            </>
                        }
                        <div>{values.country_name}</div>
                    </div>
                    {showBillingDataModal &&
                        <BillingDataModal
                            show={showBillingDataModal}
                            hide={() => setShowBillingDataModal(false)}
                            defaultValues={values}
                            fetchData={fetchData}
                        />
                    }
                </div>
            }
        </div>
    );
};

export default BillingData;
