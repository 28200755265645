import React from 'react';
import {Route, Switch} from "react-router-dom";
import {RouterPaths} from "./RouterPath";
import ConfirmEmail from "../app/mobileNotificationsAuth/ConfirmEmail";
import ForgetPassword from "../app/mobileNotificationsAuth/ForgetPassword";

const MobileNotificationsAuthRoute = ({path}) => {

    return (
        <Route path={path}>
            <Switch>
                <Route exact path={RouterPaths.MobileNotificationsAuthForgetPassword} component={ForgetPassword}/>
                <Route exact path={RouterPaths.MobileNotificationsAuthConfirmEmail} component={ConfirmEmail}/>
            </Switch>
        </Route>
    );
};

export default MobileNotificationsAuthRoute;
