import React, {useEffect, useMemo, useState} from 'react';
import CreateShopLayout from "../shared/CreateShopLayout";
import Field from "../../../../src_shared/components/form/field/Field";
import useFormLocal from "../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../RestServices";
import useLang from "../../../../src_shared/hooks/useLang";
import Button from "../../../../src_shared/components/ui/Button/Button";
import SwitchBoxes from "../../../../src_shared/components/form/switchBoxes/SwitchBoxes";
import SelectField from "../../../../src_shared/components/form/selectField/SelectField";
import {useHistory} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import CreateShopSecondStepModel from "../../../../modules/models/tutorial/CreateShopSecondStepModel";
import {Helmet} from "react-helmet";
import {usePostHog} from "posthog-js/react";
import useWindowDimensions from "../../shared/hooks/useWindowDimensions";
import ReactPixel from "react-facebook-pixel";

const ordersOptions = [
    {id: 'small', label: '1-100'},
    {id: 'medium', label: '80-1000'},
    {id: 'big', label: '1000+'},
]

export const knowUsOptions = [
    {value: '0', label: 'howYouKnowUsFacebookAdvertLabel'},
    {value: '1', label: 'howYouKnowUsBrowserAdvertLabel'},
    {value: '2', label: 'howYouKnowUsLiveLabel'},
    {value: '3', label: 'howYouKnowUsFriendsLabel'},
    {value: '4', label: 'howYouKnowUsOtherLabel'},
]

const CreateShopSecondStep = () => {

    const {getLangText} = useLang();
    const history = useHistory();
    const [showReferralCode, setShowReferralCode] = useState(false);
    const posthog = usePostHog();
    const {width} = useWindowDimensions();

    const afterSubmit = () => {
        ReactPixel.track('CompleteRegistration');
        history.push(RouterPaths.TutorialSocials)
    };

    const preparedKnowUsOptions = useMemo(() => knowUsOptions.map((i) => ({
        ...i,
        label: getLangText(i.label),
    })), [knowUsOptions, getLangText])

    const [{onSave, isWaiting, getFieldProps, values}] = useFormLocal({
        rest: services.API_TUTORIAL_SHOP,
        model: CreateShopSecondStepModel,
        initialAutoLoad: false,
        afterSubmit,
    });

    useEffect(() => {
        if (posthog) {
            posthog?.capture('ONBOARDING - Second step visit');
        }
    }, [posthog])

    return (
        <CreateShopLayout
            title="createYourShopLabel"
            desc="addBasicInformationLabel"
        >
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getLangText('createYourShopLabel')} - Selmo</title>
            </Helmet>
            <form
                id="onboarding-second-step-form"
                className="w-full grow flex flex-col"
                onSubmit={onSave}
            >
                <div className="max-w-[380px] mx-auto w-full animate-tutorialSlideAndFadeInDelay2 opacity-0">
                    <div className="form-group big-size">
                        <div className="control-label">
                            {getLangText('orderCountInMonthLabel')}
                        </div>
                        <SwitchBoxes
                            {...getFieldProps('orders_count')}
                            options={ordersOptions}
                            className="separated small-padd inline big-size"
                            withCheckmark={false}
                            required
                        />
                    </div>
                    <SelectField
                        {...getFieldProps('know_us')}
                        label={getLangText('howDoYouKnowUsLabel')}
                        className="big-size"
                        options={preparedKnowUsOptions}
                        placeholder={getLangText('selectFromListLabel')}
                        extraProps={{
                            isSearchable: false,
                            required: true,
                            styles: {
                                menu: (baseStyles) => ({
                                    ...baseStyles,
                                    borderRadius: '8px',
                                }),
                                placeholder: (baseStyles) => ({
                                    ...baseStyles,
                                    fontSize: width > 991 ? '16px' : '14px',
                                }),

                            }
                        }}
                    />
                    {(+values.know_us === 4 || +values.know_us === 2) &&
                        <Field
                            {...getFieldProps('know_us_other')}
                            showLabel={false}
                            placeholder={getLangText('whatDoYouKnowAboutUsLabel')}
                            className="big-size animate-tutorialSlideAndFadeIn"
                        />
                    }
                    <div className="mb-16 mt-8">
                        {showReferralCode ?
                            <Field
                                {...getFieldProps('referral_code')}
                                label={getLangText('partnersCodeLabel')}
                                placeholder={getLangText('typeYourPartnersCodeLabel')}
                                className="big-size mt-8 animate-tutorialSlideAndFadeIn"
                            /> :
                            <button
                                onClick={() => setShowReferralCode(true)}
                                type="button"
                                className="text-[#9CA3AF] font-medium text-sm hover:text-[#000] transition-all"
                            >
                                {getLangText('referralCodeOptionalLabel')}
                            </button>
                        }
                    </div>
                </div>
                <div
                    className="sm:mt-auto mt-8 sm:mx-0 -mx-6 bg-[#F9FAFB] sm:w-full p-[20px] rounded-b-[12px] text-right">
                    <Button
                        isWaiting={isWaiting}
                        type="submit"
                        title="goNextLabel"
                        className="bg-[#3C61EA] overflow-hidden  button text-xs sm:text-sm font-semibold rounded-[8px] px-[20px] py-[11px] text-white transition-all hover:bg-[#1c4ed8]"
                    />
                </div>
            </form>
        </CreateShopLayout>
    );
};

export default CreateShopSecondStep;
