import {combineReducers} from "redux";
import trialsReducer from './registration/list/listReducer'
import freshmenReducer from './freshmen/list/listReducer'
import leadsReducer from './leads/list/listReducer'
import filtersReducer from "./filters/filtersReducer";
import lostReducer from "./lost/list/listReducer";
import finishedTrialReducer from "./finishedTrial/list/listReducer";
import closeToLostReducer from "./closeToLost/list/listReducer";
import clientsReducer from "./clients/list/listReducer";
import cardsReducer from "./cards/cardsReducer";

export default combineReducers({
	cards: cardsReducer,
	trials: trialsReducer,
	freshmen: freshmenReducer,
	lost: lostReducer,
	finishedTrial: finishedTrialReducer,
	closeToLost: closeToLostReducer,
	clients: clientsReducer,
	leads: leadsReducer,
	filters: filtersReducer,
});
