import React from 'react';
import PropTypes from 'prop-types';
import {Route, Redirect} from 'react-router-dom';
import {RouterPaths} from "./RouterPath";
import {useSelector} from "react-redux";

export const SelmoProRoute = ({component: Component, allowField = '', ...rest}) => {

    const {userData, waitingForResponse} = useSelector((state) => state.session);

    const isSelmoProActive = (!!+userData.selmo_pro_active || !!+userData[allowField])

    if (!isSelmoProActive && !waitingForResponse) {
        return <Redirect to={RouterPaths.SelmoPro}/>;
    }

    return (
        <Route
            component={Component}
            {...rest}
        />
    );
};

SelmoProRoute.propTypes = {
    component: PropTypes.elementType,
};

SelmoProRoute.defaultProps = {
    component: undefined,
};

export default SelmoProRoute;
