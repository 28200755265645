import React, {useEffect} from 'react';
import useForm from "../../../../src_shared/form/useForm";
import DeliveryType from "./components/DeliveryType";
import FormActions from "./FormActions";
import {useDispatch, useSelector} from "react-redux";
import {services} from "../../../RestServices";
import {createSelector} from "reselect";
import {getStateRoot} from "./formReducer";
import DeliveryTypeModal from "./components/DeliveryTypeModal";
import useLang from "../../../../src_shared/hooks/useLang";

const DeliveryMethods = ({data, magicUrl, shopId, setIsPointModalOpen, setActiveShippingId}) => {

	const dispatch = useDispatch();
	const {getLangText} = useLang();

	const setDeliveryMethodsModalVisible = (toggle) => dispatch(FormActions.setDeliveryMethodsModalVisible(toggle))

	const {
		deliveryMethodsModalVisible,
		deliveryMethodsVisible,
	} = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));

	const {
		data: {values, updateValues},
		form: {getFieldProps},
		validation,
	} = useForm({
		FormActions,
		params: {},
		requireParentId: false,
		preventAutoLoad: true,
		beforeDestroy: () => {
		}
	});

	const setValueOnServer = (rest, field, value) => dispatch(FormActions.setValueOnServer(rest, field, value, magicUrl, shopId))

	useEffect(() => {
		const errorSwitchBoxes = document.querySelector('.data-boxes-wrapper.error-group')
		if (validation.isActive && errorSwitchBoxes) {
			window.scrollTo({behavior: 'smooth', top: errorSwitchBoxes?.offsetTop - 100});
		}
	}, [validation.isActive])

	useEffect(() => {
		if (!data?.overall?.shipping_value?.name) {
			dispatch(FormActions.setDeliveryMethodsVisible(true));
		}
	}, [])

	return (
		<>
			{deliveryMethodsVisible &&
				<section>
					<div className="section-title">
						{getLangText('customerBasketDeliveryTypeLabel')}
					</div>
					<DeliveryType
						{...getFieldProps('shipping')}
						values={values}
						data={data}
						updateValues={updateValues}
						setValue={(field, value) => setValueOnServer(services.API_CART_SHIPPING_METHODS, field, value)}
						setIsPointModalOpen={setIsPointModalOpen}
						setActiveShippingId={setActiveShippingId}
					/>
				</section>
			}
			{deliveryMethodsModalVisible &&
				<DeliveryTypeModal
					getFieldProps={getFieldProps}
					modalVisible={deliveryMethodsModalVisible}
					hideModal={() => setDeliveryMethodsModalVisible(false)}
					values={values}
					data={data}
					updateValues={updateValues}
					setValueOnServer={setValueOnServer}
					setIsPointModalOpen={setIsPointModalOpen}
					setActiveShippingId={setActiveShippingId}
				/>
			}
		</>
	);
};

export default DeliveryMethods;
