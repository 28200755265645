const initialState = {
    waitingForSendMobileNotifications: null,
    waitingForSalesPostsImport: null,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'WAITING_FOR_SEND':
            return {...state, [action.field]: action.toggle};
        default:
            return state;
    }
}
