export const MONDIAL_RELAY_COLLECTIONS_CCC = 'CCC';
export const MONDIAL_RELAY_COLLECTIONS_CDR = 'CDR';
export const MONDIAL_RELAY_COLLECTIONS_CDS = 'CDS';
export const MONDIAL_RELAY_COLLECTIONS_REL = 'REL';


export const MONDIAL_RELAY_COLLECTIONS = [
    // {
    //     id: MONDIAL_RELAY_COLLECTIONS_CCC, label: 'mondialRelayCollectionCCCLabel',
    // },
    // {
    //     id: MONDIAL_RELAY_COLLECTIONS_CDR, label: 'mondialRelayCollectionCDRLabel',
    // },
    // {
    //     id: MONDIAL_RELAY_COLLECTIONS_CDS, label: 'mondialRelayCollectionCDSLabel',
    // },
    {
        id: MONDIAL_RELAY_COLLECTIONS_REL, label: 'mondialRelayCollectionRELLabel',
    },
]
