import React from 'react';
import Questions from "./Questions";
import useLang from "../../../src_shared/hooks/useLang";
import {useSelector} from "react-redux";
import {SHOP_ORIGIN_IT, SHOP_ORIGIN_PL, SHOP_ORIGIN_EN} from "../shared/enums/ShopOrigins";
import TutorialsVideo from "../shared/enums/TutorialsVideo";
import Iframe from "react-iframe-click";
import {usePostHog} from "posthog-js/react";


const extraHelpVideos = [
    {id: 0, header: 'howToAddOnlinePayments', src_pl: 'https://www.youtube.com/embed/6jfr-qzSY5M?si=EHYwqoUrFSm5fkfL'},
    {id: 1, header: 'howToAddDiscountCodes', src_pl: 'https://www.youtube.com/embed/-Z1G1wyfunc?si=RH3Dm2KlXLYjcMd4'},
    {id: 1, header: 'howToArrangeCashOnDelivery', src_pl: 'https://www.youtube.com/embed/mwPhh88Qqp4?si=4Ndmkbg8skO9To-9'},
    {id: 1, header: 'howToSetPersonalisedMessages', src_pl: 'https://www.youtube.com/embed/tKy7BD50ixs?si=JRYAQy-ofKDxMsaA'},
    {id: 1, header: 'howToTurnOnProductsVisibilityInSelmoAPp', src_pl: 'https://www.youtube.com/embed/O7CCJSJ_N4M?si=dLFfxCblWcercVEc'},
    {id: 1, header: 'howToAddProductsToStorage', src_pl: 'https://www.youtube.com/embed/xOWdbNT3zOI?si=VGndNv52_r7z86pN'},
    {id: 1, header: 'howToConnectFakturownia', src_pl: 'https://www.youtube.com/embed/8q3BpvnwWq4?si=DGOo4a7pVrxn6VHt'},
]

const Help = () => {
    const {getLangText} = useLang();
    const {userData} = useSelector((state) => state.session);
    const {lang} = useSelector((state) => state.i18n);
    const posthog = usePostHog();

    const setPosthogEvent = (type) => {
        if (posthog) {
            posthog?.capture('TUTORIAL VIDEO', {type: `HELP_${type}`})
        }
    };

    const videoSrc = () => {
        if (lang === 'it') {
            return 'https://onelineplayer.com/player.html?autoplay=true&autopause=false&muted=false&loop=false&url=https%3A%2F%2Fwww.dropbox.com%2Fs%2F8ezm9zzl7so093b%2Fmain%2520movie_IT.mp4%3Fraw%3D1&poster=https%3A%2F%2Fwww.dropbox.com%2Fs%2F98ngy9ccng5qp0k%2Fhero_spot.webp%3Fraw%3D1&time=true&progressBar=true&overlay=true&muteButton=true&fullscreenButton=true&style=light&quality=auto&playButton=true'
        }
        if (lang === 'pt') {
            return 'https://onelineplayer.com/player.html?autoplay=true&autopause=false&muted=false&loop=false&url=https%3A%2F%2Fwww.dropbox.com%2Fs%2Ft3fmmypgeeb3037%2Fmain_movie_pt-compressed.mp4%3Fraw%3D1&poster=https%3A%2F%2Fwww.dropbox.com%2Fs%2Fw60qnustnzsv688%2Fhero_spot.webp%3Fraw%3D1&time=true&progressBar=true&overlay=true&muteButton=true&fullscreenButton=true&style=light&quality=auto&playButton=true'
        }
        if (lang === 'fr') {
            return 'https://onelineplayer.com/player.html?autoplay=true&autopause=false&muted=false&loop=false&url=https%3A%2F%2Fwww.dropbox.com%2Fs%2Fmkd9aftd7iilteb%2Fmain_movie_FR.mp4%3Fraw%3D1&poster=https%3A%2F%2Fwww.dropbox.com%2Fs%2Fywqatfxsx3x1w7x%2Fhero_spot.webp%3Fraw%3D1&time=true&progressBar=true&overlay=true&muteButton=true&fullscreenButton=true&style=light&quality=auto&playButton=true'
        }

        return 'https://onelineplayer.com/player.html?autoplay=true&autopause=false&muted=false&loop=false&url=https%3A%2F%2Fwww.dropbox.com%2Fs%2Fq2lxqw8p6d0m2aa%2Fmain%2520movie_en_compressed.mp4%3Fraw%3D1&poster=https%3A%2F%2Fwww.dropbox.com%2Fs%2Ff44enstoa7027ss%2Fhero_spot.webp%3Fraw%3D1&time=true&progressBar=true&overlay=true&muteButton=true&fullscreenButton=true&style=light&quality=auto&playButton=true'
    }

    return (
        <>
            <div className="settings-page sub-settings-page help-contact-box mb-sm-4">
                <h2>{getLangText('helpTitle')}</h2>
                <div className="description">
                    {getLangText('helpDescription')}
                </div>
                <div className="contact-boxes">
                    <a
                        href={userData.origin === SHOP_ORIGIN_IT ? 'https://www.facebook.com/selmoitalia' : 'https://www.facebook.com/selmo.io/'}
                        target="_blank" className="messenger-btn">
                        <i className="icon-messenger-2-f"/>
                        {getLangText('newsTextToMessengerButton')}
                    </a>
                    {userData.origin === SHOP_ORIGIN_PL &&
                        <a href="mailto: kontakt@selmo.io">
                            <i className="icon-mail"/>
                            <span>kontakt@selmo.io</span>
                            <i className="icon-arrow-left"/>
                        </a>
                    }
                    {userData.origin === SHOP_ORIGIN_EN &&
                        <a href="mailto: kontakt@selmo.io">
                            <i className="icon-mail"/>
                            <span>kontakt@selmo.io</span>
                            <i className="icon-arrow-left"/>
                        </a>
                    }
                    {userData.origin === SHOP_ORIGIN_IT &&
                        <a href="mailto: hello@selmo.io">
                            <i className="icon-mail"/>
                            <span>hello@selmo.io</span>
                            <i className="icon-arrow-left"/>
                        </a>
                    }
                    {userData.origin === SHOP_ORIGIN_PL &&
                        <a href="tel:123457755">
                            <i className="icon-tel"/>
                            <span>+12 345 77 55</span>
                            <i className="icon-arrow-left"/>
                        </a>
                    }
                </div>
            </div>
            <div className="settings-page sub-settings-page">
                <div className="header-wrapper">
                    <h1>
                        <i className="icon-help"/>
                        {getLangText('asideHelpLabel')}
                    </h1>
                    <div className="description">{getLangText('helpUseHelpLabel')}</div>
                </div>
                <div className="settings-form">
                    {TutorialsVideo.filter((x) => x.id.includes('HEADER') || x.id.includes('DASHBOARD_MAIN_TRIAL')).map((i) => (
                        i[`src_${lang}`] &&
                        <section key={i.id}>
                            <div className="left-side">
                                <h2 className="label">{getLangText(i.header)}</h2>
                            </div>
                            <div className="form-col">
                                <div className="selmo-video-wrapper">
                                    <Iframe
                                        autoPlay={false}
                                        onInferredClick={setPosthogEvent}
                                        frameBorder="0" allowFullScreen="" scrolling="no"
                                        allow="fullscreen"
                                        src={i[`src_${lang}`].replace('autoplay=1', 'autoplay=0')}
                                        style={{
                                            position: 'absolute',
                                            height: '100%',
                                            width: '100%',
                                            left: '0px',
                                            top: '0px'
                                        }}
                                    />
                                </div>
                            </div>
                        </section>
                    ))}
                    {lang === SHOP_ORIGIN_PL && extraHelpVideos.map((i) => (
                        <section key={i.id}>
                            <div className="left-side">
                                <h2 className="label">{getLangText(i.header)}</h2>
                            </div>
                            <div className="form-col">
                                <div className="selmo-video-wrapper">
                                    <Iframe
                                        autoPlay={false}
                                        onInferredClick={setPosthogEvent}
                                        frameBorder="0" allowFullScreen="" scrolling="no"
                                        allow="fullscreen"
                                        src={i[`src_${lang}`].replace('autoplay=1', 'autoplay=0')}
                                        style={{
                                            position: 'absolute',
                                            height: '100%',
                                            width: '100%',
                                            left: '0px',
                                            top: '0px'
                                        }}
                                    />
                                </div>
                            </div>
                        </section>
                    ))}
                    {lang !== SHOP_ORIGIN_PL &&
                        <section>
                            <div className="left-side">
                                <h2 className="label">{getLangText('seeTheTutorialVideoLabel')}</h2>
                            </div>
                            <div className="form-col">
                                <div className="selmo-video-wrapper">
                                    <iframe frameBorder="0" allowFullScreen="" scrolling="no"
                                            allow="fullscreen"
                                            src={videoSrc()}
                                            style={{
                                                position: 'absolute',
                                                height: '100%',
                                                width: '100%',
                                                left: '0px',
                                                top: '0px'
                                            }}/>
                                </div>
                            </div>
                        </section>
                    }
                    <section>
                        <div className="left-side">
                            <h2 className="label">{getLangText('helpMostFrequentlyAskedQuestionsLabel')}</h2>
                        </div>
                        <div className="form-col">
                            <Questions/>
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
};

export default Help;
