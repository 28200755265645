import React, {useCallback, useRef, useState} from 'react';
import DropdownPortal from "../../../../../messenger/DropdownPortal/DropdownPortal";
import HintDropdown from "../../../../../messenger/addOrderModal/components/hintDropdown";
import useOutsideClick from "../../../../../messenger/hooks/useOutsideClick/useOutsideClick";
import debounce from "lodash.debounce";
import axios from "axios";
import {useDispatch} from "react-redux";
import {selmoUrl} from "../../../../../../../src_shared/api/api";
import {services} from "../../../../../../RestServices";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import api from "../../../../../../../services/axios/axios";
import ServerImage from "../../../serverImage/ServerImage";

const NameInput = ({product, actions, dropdownPortalTarget, autofocus = true}) => {
	const dispatch = useDispatch();
	const hintInputRef = useRef([]);
	const manualProductSearchRef = useRef();
	const [productHints, setProductsHints] = useState([]);
	const [hintsVisible, setHintsVisible] = useState(null);
	const {getLangText} = useLang();

	const updateFieldInProduct = (field, value) => dispatch(actions.updateFieldInProduct(field, value, product.helperId));
	const updateFieldsInProduct = (fields) => dispatch(actions.updateFieldsInProduct(fields, product.helperId));

	const debouncedSave = useCallback(
		debounce((value, id) => {
			if (value) {
				api.get(`${selmoUrl}/${services.API_STORAGE_PRODUCTS}?search=${value}`)
					.then((res) => {
						setHintsVisible(id)
						setProductsHints(res.data)
					})
					.catch((e) => {
						console.log(e)
					})
			}
		}, 500), []);

	const setAndSearchName = (value, id) => {
		updateFieldInProduct('product_name', value)
		debouncedSave(value, id)
		if (!value.length) {
			const defaultProductFields = {
				product_code: null,
				product_size: null,
				product_size_id: null,
				product_photo: product?.photo_small,
				product_price: '',
				product_id: null,
				product_max_count: 999999,
				quantity: 1,
			}
			updateFieldsInProduct(defaultProductFields, id);
		}
	}

	const hintDropdownFocus = (e) => {
		if (document.querySelector('.custom-dropdown .select-custom-option:first-child') && e.keyCode === 40) {
			document.querySelector('.custom-dropdown .select-custom-option:first-child').focus()
		}
	}

	const onKeyDown = (event) => {
		const focusableInputElements = document.querySelectorAll(`.select-custom-option`);

		const focusable = [...focusableInputElements];

		const index = focusable.indexOf(document.activeElement);

		let nextIndex = 0;
		if (event.keyCode === 40) {
			event.preventDefault();
			nextIndex = index + 1 < focusable.length ? index + 1 : index;
			focusableInputElements[nextIndex].focus();
		}
		if (event.keyCode === 38) {
			event.preventDefault();
			nextIndex = index > 0 ? index - 1 : 0;
			focusableInputElements[nextIndex].focus();
		}
	}

	useOutsideClick(manualProductSearchRef, () => {
		if (hintsVisible) {
			setProductsHints([])
			setHintsVisible(null);
		}
	});

	return (
		<div className="product-input-col">
			<div className={`form-group ${product.product_id ? 'storage-product' : ''}`}>
				<label className="control-label">{getLangText('productNameLabel')}</label>
				<input
					ref={(element) => hintInputRef.current[product.helperId] = element}
					tabIndex={product.tabIndex}
					type="text"
					className="form-control"
					name="product_name"
					onChange={(e) => setAndSearchName(e.target.value, product.helperId)}
					value={product.product_name}
					autoComplete="off"
					onKeyDown={hintDropdownFocus}
					autoFocus={autofocus}
					required
				/>
				{product.product_id &&
					<>
						<figure>
							{!product?.product_photo || product?.product_photo === '0' ?
								<img
									src="/assets/images/default/product.svg"
									alt={product?.product_name}
									className="img-preview"/>
								:
								<ServerImage
									src={product?.product_photo}
									alt={product?.product_name}
								/>
							}
						</figure>
						<div className="small-label">
							{product?.product_size && <span>{product?.product_size}</span>}
							<span>{getLangText('codeLabel')}: {product?.product_code}</span>
						</div>
					</>
				}
				<DropdownPortal
					basedOn={hintInputRef}
					item={product}
					offsetY={35}
					target={dropdownPortalTarget}
					className="max-height-dropdown"
				>
					<div ref={manualProductSearchRef}>
						<HintDropdown
							key={product.helperId}
							hintsVisible={hintsVisible}
							setHintsVisible={setHintsVisible}
							productHints={productHints}
							updateFieldsInProduct={updateFieldsInProduct}
							id={product.helperId}
							tabIndex={product.tabIndex}
							onKeyDown={onKeyDown}
						/>
					</div>
				</DropdownPortal>
			</div>
		</div>

	);
};

export default NameInput;
