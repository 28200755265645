import React from 'react';
import useFetchData from "../../../../../src_shared/hooks/useFetchData";
import {services} from "../../../../RestServices";
import OrderHistoryList from "./OrderHistoryList";
import Modal from "react-bootstrap/Modal";
import useLang from "../../../../../src_shared/hooks/useLang";
import ComponentLoader from "../../../../../src_shared/components/ui/componentLoader/ComponentLoader";

const OrderHistoryModal = ({show, hide, values}) => {
    const {getLangText} = useLang();

    const [{data, isLoading, fetchData}] = useFetchData({
        rest: `${services.API_PERMISSIONS_ORDER_HISTORY}/${values.id}?limit=50`,
        initialData: [],
    })

    return (
        <Modal
            dialogClassName={`default-modal w-450`}
            show={show}
            onHide={hide}
        >
            <Modal.Header>
                <Modal.Title>
                    {getLangText('activityHistory')}
                    <div className="text-desc text-sm font-normal">
                        {getLangText('seeActivityHistory')}
                    </div>
                </Modal.Title>
                <button
                    onClick={hide}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            <Modal.Body>
                {isLoading ?
                    <ComponentLoader style={{minHeight: '150px'}}/> :
                    <div className="max-h-[70vh] overflow-y-auto">
                        <OrderHistoryList items={data}/>
                    </div>
                }
            </Modal.Body>
        </Modal>
    );
};

export default OrderHistoryModal;
