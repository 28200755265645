import React from 'react';
import useLang from "../../../../../../src_shared/hooks/useLang";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../../routes/RouterPath";

const allFunctions = [
    {id: 'overlay', label: 'overlay', subLabel: 'displayProductsCodesOnBroadcasts', photo: '/assets/images/multiLanguage/pl/selmoProLanding/overlay-small.webp', href: RouterPaths.SelmoProOverlay},
    {id: 'depositSystem', label: 'depositSystem', subLabel: 'generateQrCodesToShelves', photo: '/assets/images/multiLanguage/pl/selmoProLanding/qr-small.webp', href: RouterPaths.SelmoProDepot},
    {id: 'mrAutomate', label: 'panAutomacikLabel', subLabel: 'displayProductsCodesOnBroadcasts', photo: '/assets/images/robo-landing.webp', href: RouterPaths.SelmoProMrAutomate},
]

const SelmoProLandingMoreFunctionsBox = ({currentPage}) => {
    const {getLangText} = useLang();

    const options = allFunctions.filter((item) => item.id !== currentPage);

    return (
        <div className="sm:px-[100px] sm:py-12 p-6 mt-12">
            <div className="text-center mb-12">
                <img
                    style={{width: 'auto', height: 24}}
                    className="mb-2 mx-auto"
                    src="/assets/images/selmo-pro-logo.png"
                    alt="logo-selmo"
                />
                <div
                    className="text-[#fff] whitespace-pre-wrap font-bold selmo-pro-landing-title text-[30px] leading-[34px] sm:text-[36px] sm:leading-[42px] mb-3"
                    dangerouslySetInnerHTML={{__html: getLangText('learnMoreSelmoFunctions')}}
                />
            </div>
            <div className="grid sm:grid-cols-2 gap-8 sm:items-center">
                {options.map((item) => (
                    <Link
                        to={item.href}
                        className="bg-[#323234] rounded-[12px] p-6 flex items-center hover:no-underline">
                        <div>
                            <div className="font-bold text-[#fff] text-lg">
                                {getLangText(item.label)}
                            </div>
                            <div className="text-sm text-[#CBCBCD] font-medium mt-1 whitespace-pre-wrap">
                                {getLangText(item.subLabel)}
                            </div>
                            <div className="text-[#5FA5FA] font-bold text-sm mt-3">
                                {getLangText('findOutMoreButton')}
                                <i className="icon-arrows inline-block mt-1 text-[10px] ml-1 -rotate-90" />
                            </div>
                        </div>
                        <div className="sm:ml-0 ml-3.5 bg-[#4B4B4E] ml-auto overflow-hidden h-[60px] sm:h-[80px] sm:min-w-[80px] min-w-[60px] w-[60px] rounded-[8px] sm:w-[80px]">
                            <img
                                className="w-full h-full object-contain"
                                src={item.photo}
                                alt=""/>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default SelmoProLandingMoreFunctionsBox;
