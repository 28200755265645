import ListReducer from "../../../../src_shared/list/ListReducer";
import createReducer from "../../shared/helpers/createReducer";
import Composition from "../../shared/helpers/Composition";

export const getStateRoot = (state) => state.knowledgeBase.asideList;
export const prefix = 'KNOWLEDGE_BASE_ASIDE_LIST_';

const getInitState = () => ListReducer.getInitState({
	categoryId: null,
	categoryArticles: [],
});

const getReduceMap = () => new Composition(
	ListReducer.getReduceMap(),
	{
		SET_ACTIVE_CATEGORY_ID: (state, action) => ({...state, categoryId: action.categoryId})
	},
	{
		SET_CATEGORY_ARTICLES: (state, action) => ({
			...state,
			items: state.items.map((i) => action.categoryId === i.id ? {
				...i,
				articles: action.articles,
			} : i)
		})
	},
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

