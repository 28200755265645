import React from 'react';
import useLang from "../../../../../src_shared/hooks/useLang";
import Loader from "../../../shared/Loader";

const BuyNowButton = ({isWaiting}) => {
    const {getLangText} = useLang();

    return (
        <div>
            <div className="text-center">
                <button
                    type="submit"
                    className={`button black w-full ${isWaiting ? 'loading-button' : ''}`}

                >
                    {isWaiting && <Loader isLoading/>}
                    {getLangText('buyNowLabel')}
                </button>
                <div className="text-[10px] text-desc mt-2">
                    <i className="icon-safe mr-1"/>
                    {getLangText('customerBasketSafetyPaymentButton')}
                </div>
            </div>
            <div className="text-desc text-xs border-t border-[#F3F4F6] pt-3 mt-3">
                {getLangText('customerBasketSelmoBasketSignature')}
                <a href="https://selmo.io" target="_blank" className="block w-[90px] mt-2 hover:no-underline no-underline">
                    <img className="w-full" src="/assets/images/logo.svg" alt="Selmo Logo"/>
                </a>
            </div>
        </div>
    );
};

export default BuyNowButton;
