import React from 'react';
import SelmoProLandingIntroBox from "../components/SelmoProLandingIntroBox";
import SelmoProLandingTwoColumnBox from "../components/SelmoProLandingTwoColumnBox";
import SelmoProLandingSingleVideoBox from "../components/SelmoProLandingSingleVideoBox";
import SelmoProLandingMoreFunctionsBox from "../components/SelmoProLandingMoreFunctionsBox";
import SelmoProLandingBenefitsBox from "../components/SelmoProLandingBenefitsBox";

const benefits = [
    {value: '32%', label: 'averageIncreaseInSales',},
    {value: '288h', label: 'timeSavedOnManualActivities'},
]

const LiveOverlayLanding = () => {
    return (
        <div>
            <SelmoProLandingIntroBox
                imgSrc="/assets/images/multiLanguage/pl/selmoProLanding/overlay-big.webp"
                title="showProductsCodesOnLive"
                imgClassName="sm:-mb-12 -mb-6 sm:mt-0 mt-6 sm:h-[416px]"
            />
            <SelmoProLandingSingleVideoBox
                title="watchVideoHowOverlayWorks"
                imgSrc='/assets/images/multiLanguage/pl/selmoProLanding/overlay-intro.png'
                videoSrc="https://www.youtube.com/embed/nTy9IDCf8Pc?si=CGs-Q0QOetKo-v5I&autoplay=1"
            />
            <SelmoProLandingTwoColumnBox
                title="yourClientsEasyTextCode"
                label="overlay"
                desc="yourClientsEasyTextCodeSubLabel"
                imgSrc='/assets/images/multiLanguage/pl/selmoProLanding/overlay-01.webp'
                videoSrc="https://www.youtube.com/embed/nTy9IDCf8Pc?autoplay=1&si=0EQbu2mRgR6kr2Oq&amp;start=31"
            />
            <SelmoProLandingTwoColumnBox
                title="trackYourSales"
                label="overlay"
                desc="trackYourSalesSubLabel"
                imgSrc='/assets/images/multiLanguage/pl/selmoProLanding/overlay-02.webp'
                videoSrc="https://www.youtube.com/embed/nTy9IDCf8Pc?autoplay=1&si=0EQbu2mRgR6kr2Oq&amp;start=43"
                rightText
            />
            {/*<SelmoProLandingBenefitsBox*/}
            {/*    benefits={benefits}*/}
            {/*    imgSrc='/assets/images/multiLanguage/pl/selmoProLanding/chart.svg'*/}
            {/*/>*/}
            <SelmoProLandingMoreFunctionsBox
                currentPage="overlay"
            />
        </div>
    );
};

export default LiveOverlayLanding;
