import {combineReducers} from "redux";
import listReducer from './list/listReducer'
import filtersReducer from "./filters/filtersReducer";
import userOrdersReducer from "./list/orders/userOrdersReducer";
import formReducer from "./form/formReducer";

export default combineReducers({
	list: listReducer,
	userOrders: userOrdersReducer,
	filters: filtersReducer,
	form: formReducer,
});
