import React from 'react';
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../routes/RouterPath";
import {services} from "../../../../RestServices";
import useLang from "../../../../../src_shared/hooks/useLang";
import useFormLocal from "../../../../../src_shared/hooks/useFormLocal";
import SwitchField from "../../../../../src_shared/components/form/switchField/SwitchField";
import Loader from "../../../../app/shared/Loader";
import OrlenSettingsWrapper from "../OrlenWrapper";
import SettingsOrlenModel from "../../../../../modules/models/settings/SettingsOrlenModel";
import Field from "../../../../../src_shared/components/form/field/Field";

const OrlenConfiguration = () => {
	const {getLangText} = useLang()

	const [{onSave, isWaiting, getFieldProps, isLoading}] = useFormLocal({
		rest: services.API_SETTINGS_ORLEN,
		method: 'post',
		model: SettingsOrlenModel,
	});

	return (
		<>
			<div className="header-bar breadcrumbs-in-react-app">
				<ul className="breadcrumbs">
					<li>
						<Link to={RouterPaths.Settings}>
							{getLangText('asideSettingsLabel')}
						</Link>
					</li>
					<li>
						<Link to={RouterPaths.SettingsIntegration}>{getLangText('settings_nav_integrations')}</Link>
					</li>
					<li className="active">
						Orlen Paczka
					</li>
				</ul>
			</div>
			<OrlenSettingsWrapper>
				<div className="loader-parent">
					<Loader isLoading={isLoading}/>
					<div className="settings-form pt-3">
						<div className="steps-wrapper">
							<div className="step border-step">
								<div className="step-header align-items-start">
									<div className="title d-block flex-grow-1">
										<div className="mb-2">{getLangText('pasteKeysInFieldsBelowLabel')}</div>
										<Field
											{...getFieldProps('partner_id')}
											label={getLangText('partnerIdLabel')}
										/>
										<Field
											{...getFieldProps('partner_key')}
											label={getLangText('partnerKeyLabel')}
										/>
									</div>
								</div>
							</div>
							<div className="d-flex align-items-center mt-2">
								<SwitchField
									{...getFieldProps('active')}
									label={getLangText('active')}
									className="switch-form inline-label align-items-start big-label light-version mb-0"
								/>
								<div className="button-place ml-auto">
									<button
										type="button"
										onClick={onSave}
										className={`button primary ${isWaiting ? 'loading-button' : ''}`}
									>
										<Loader isLoading={isWaiting}/>
										{getLangText('saveButton')}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</OrlenSettingsWrapper>
		</>
	);
};

export default OrlenConfiguration;
