import React from 'react';
import {Link, useLocation} from "react-router-dom";
import useLang from "../../../../src_shared/hooks/useLang";
import ShopSettingsIntegrationsViews from "../../shared/enums/ShopSettingsIntegrationsViews";
import ShopNameBar from "../../../routes/shopSettingsRoute/ShopNameBar";
import {Helmet} from "react-helmet";

const ShopSettingsIntegrationsWrapper = ({children}) => {
	const {pathname} = useLocation();
	const {getLangText} = useLang();

	return (
		<>
			<Helmet>
				<meta charSet="utf-8"/>
				<title>{getLangText('shopSettingsIntegrationsLabel')}Selmo</title>
			</Helmet>
			<ShopNameBar/>
			<div className={`settings-page sub-settings-page loader-parent`}>
				<div className="tabs-wrapper">
					{ShopSettingsIntegrationsViews.map((i) => (
						<Link
							to={i.path}
							key={i.id}
							className={pathname === i.path ? 'active' : ''}
						>
							{getLangText(i.label)}
						</Link>
					))}
				</div>
				<div className="settings-form pb-5 mb-5">
					{children}
				</div>
			</div>
		</>
	);
};

export default ShopSettingsIntegrationsWrapper;
