import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {createSelector} from 'reselect';

export default (actions, getFiltersStateRoot, filterActions, field, selectAllField, options, defaultSelectedLabel) => {
	const dispatch = useDispatch();

	const [statuses, selectAll] = useSelector(createSelector(getFiltersStateRoot, (stateRoot) => [
		stateRoot.data[field],
		stateRoot.data[selectAllField],
	]));

	const [statusFilter, setStatusFilter] = useState([]);
	const [isSelectAll, setIsSelectAll] = useState(selectAll);

	const updateLocallyStatusesState = (status = [], allSelect = false) => {
		setStatusFilter(status);
		setIsSelectAll(allSelect);
	};

	const selectAllOptions = () => {
		const preparedFilters = options.map((i) => i.value)
		setStatusFilter(preparedFilters);
	}

	const saveFilters = (id) => {
		dispatch(filterActions.data.setValue(field, statusFilter));
		if (isSelectAll) {
			dispatch(filterActions.data.setValue(selectAllField, true));
		} else {
			dispatch(filterActions.data.setValue(selectAllField, false));
		}
		if (id) {
			dispatch(actions.loadPage(1, id));
		} else {
			dispatch(actions.loadPage(1));
		}
	};

	const filterButtonTitle = () => {
		if (!statuses.length) {
			return {
				text: defaultSelectedLabel,
				className: '',
			};
		}
		return {
			text: `${defaultSelectedLabel} <div class="badge"><span>${statuses.length}</span></div>`,
			className: 'filtered',
		};
	};

	const handleCheckBoxClick = (field) => {
		setIsSelectAll(false);
		const existed = statusFilter.find((i) => i === field);
		if (existed) {
			const filteredHourAvailabilities = statusFilter.filter((i) => i !== field);
			setStatusFilter(filteredHourAvailabilities);
		} else {
			setStatusFilter([
				...statusFilter,
				field,
			]);
		}
	};

	const allItemsClick = () => {
		if (!isSelectAll) {
			selectAllOptions();
		} else {
			updateLocallyStatusesState()
		}
	};

	const getCheckboxValue = (name) => !!statusFilter.find((status) => status == name);

	useEffect(() => {
		setIsSelectAll(selectAll);
		setStatusFilter(statuses);
	}, [statuses]);

	useEffect(() => {
		if (options.length && statusFilter.length === options.length) {
			setIsSelectAll(true);
		}
	}, [statusFilter])

	return {
		statuses,
		selectAll,
		isSelectAll,
		saveFilters,
		filterButtonTitle,
		handleCheckBoxClick,
		allItemsClick,
		updateLocallyStatusesState,
		getCheckboxValue,
		locallyStatusFilter: statusFilter,
		setIsSelectAll,
	};
};
