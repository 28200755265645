import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setFieldValue, setListPage, updateMessageList} from "../ChatListActions";
import Select from "react-select";
import axios from "axios";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {selmoUrl} from "../../../../../src_shared/api/api";
import debounce from "lodash.debounce";
import useLang from "../../../../../src_shared/hooks/useLang";
import api from "../../../../../services/axios/axios";

const SearchChat = ({messengerRef, getMessages}) => {

    const dispatch = useDispatch();

    const {search, status} = useSelector((state) => state.messenger.chatList)
    const [isSearchVisible, setIsSearchVisible] = useState(false)
	const {getLangText} = useLang();
	const setValue = (field, name) => dispatch(setFieldValue(field, name));
    const inputRef = useRef();
    const [customTags, setCustomTags] = useState([])
    const [isFullScreen, setIsFullScreen] = useState(false)

	const onChange = (e) => {
		setValue('search', e.target.value)
		// if (e.target.value.length > 2 || !e.target.value.length) {
			debouncedSave(e)
		// }
	}

	const debouncedSave = useCallback(
		debounce((e) => {
			dispatch(setListPage(1))
			getMessages(true)
		}, 500),
		[]
	);

    // useEffect(() => {
    //     if (search?.length > 2) {
    //         dispatch(getMessagesList(true))
    //     } else if (search?.length === 0) {
    //         //todo on first click
    //         dispatch(getMessagesList(true))
    //     }
    // }, [search])

    const setStatus = (field, value) => {
        dispatch(setListPage(1))
		dispatch(updateMessageList([]));
		setValue(field, value)
		getMessages(true);
    }
	//
    // const setSearchValue = (e) => {
	// 	dispatch(setListPage(1))
	// 	setValue('search', e.target.value)
    // }

    const toggleVisibility = () => {
        if (isSearchVisible && status) {
            dispatch(setListPage(1))
            dispatch(updateMessageList([]))
            setValue('status', null)
			getMessages(true)
        }
        setIsSearchVisible(!isSearchVisible)
        setValue('status', 0)
    }

    const getCustomFilters = () => {
        api.get(`${selmoUrl}/api/tags`,)
            .then((res) => {
                setCustomTags(res.data)
            })
            .catch((e) => {
                console.log(e)
            })
            .finally(() => {

            })
    }

    const setFullScreen = () => {
        if (isFullScreen) {
            messengerRef.current.classList.remove('full-screen')
            setIsFullScreen(false)
        } else {
            messengerRef.current.classList.add('full-screen')
            setIsFullScreen(true)
        }
    }

    const filters = [
        {value: null, label: getLangText('mainFolderLabel')},
        {value: 0, label: getLangText('allLabel')},
		{value: 'viewed', label: getLangText('readLabel')},
		{value: 'not_viewed', label: getLangText('unReadLabel')},
        {value: 3, label: getLangText('openBasketLabel')},
        {value: 4, label: getLangText('readyLabel')},
        {value: 1, label: getLangText('inquiryLabel')},
        {value: 2, label: getLangText('customerBasketOrderLabel')},
        {value: 5, label: getLangText('noResponse')},
    ]

    const getAllFilters = filters.concat(customTags.map((i) => ({value: i.id, label: i.text, tag: true})))

    return (
        <div className="search-box">
            <div className="top-bar">
                <h1>Messenger</h1>
				<OverlayTrigger
                    placement='bottom'
                    overlay={
                        <Tooltip id="full-screen">
							{getLangText('fullScreenLabel')}
                        </Tooltip>
                    }
                >
                    <button
                        type="button"
                        onClick={setFullScreen}
                        className="full-screen-button"
                    >
                        {isFullScreen ?
                            <i className="icon-default-screen"/> :
                            <i className="icon-full-screen"/>
                        }
                    </button>
                </OverlayTrigger>
                <OverlayTrigger
                    placement='bottom'
                    overlay={
                        <Tooltip id="search">
							{getLangText('filtrationLabel')}
                        </Tooltip>
                    }
                >
                    <button
                        type="button"
                        onClick={toggleVisibility}
                    >
                        {isSearchVisible ?
                            <i className="icon-cross"/> :
                            <i className="icon-filter"/>
                        }
                    </button>
                </OverlayTrigger>
            </div>
            {isSearchVisible &&
            <div className="status-filter-box">
                <Select
                    id="dropdown-default"
                    defaultValue={{value: null, label: getLangText('mainFolderLabel')}}
                    options={getAllFilters}
                    onFocus={getCustomFilters}
                    onChange={(status) => setStatus('status', status.tag ? status : status.value)}
                    hideSelectedOptions={false}
                />
            </div>
            }
            <input
                ref={inputRef}
                className="search-input"
                type="text"
                value={search}
                onChange={onChange}
                placeholder={getLangText('searchPlaceholder')}
                autoComplete="off"
                autoFocus
            />
        </div>
    );
};

export default SearchChat;
