import React, {useState} from 'react';
import {checkRoles} from "../../../routes/PermissionRoute";
import {FAKTUROWNIA_PERMISSION} from "../../../app/shared/enums/SelmoPermissions";
import {useSelector} from "react-redux";
import useLang from "../../../../src_shared/hooks/useLang";
import Field from "../../../../src_shared/components/form/field/Field";
import useFormLocal from "../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../RestServices";
import Loader from "../../../app/shared/Loader";
import NoPermissionFakturownia from "./NoPermissionFakturownia";
import SwitchField from "../../../../src_shared/components/form/switchField/SwitchField";
import ComponentLoader from "../../../app/shared/components/componentLoader/ComponentLoader";
import SettingsFakturowniaModel from "../../../../modules/models/settings/SettingsFakturowniaModel";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import {Helmet} from "react-helmet";
import {
    ANGEL_GLADYS_SHOP_ID,
    BUTIK_MADAME_SHOP_ID,
    TEST_ACCOUNT_SHOP_ID
} from "../../../../src_shared/enums/TrustedShopsIds";
import {SHOP_ORIGIN_FR, SHOP_ORIGIN_PL} from "../../../app/shared/enums/ShopOrigins";
import FunctionalityModal from "../../../app/payments/functionalityModal/Form";

const Fakturownia = () => {
        const {getLangText} = useLang();
        const {userData} = useSelector((state) => state.session);
        const fakturowniaPermissionsMatched = checkRoles(userData.permissions, [FAKTUROWNIA_PERMISSION]);
        const [showModal, setShowModal] = useState(false);

        const [{
            onSave,
            isWaiting,
            getFieldProps,
            isLoading,
            values
        }] = useFormLocal({
            rest: services.API_SETTINGS_FAKTUROWNIA,
            model: SettingsFakturowniaModel,
        });

        if (isLoading) return <ComponentLoader/>;

        return (
            <>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>{getLangText('settings_nav_fakturownia')} - Selmo</title>
                </Helmet>
                <div className="header-bar breadcrumbs-in-react-app">
                    <ul className="breadcrumbs">
                        <li>
                            <Link to={RouterPaths.Settings}>{getLangText('asideSettingsLabel')}</Link>
                        </li>
                        <li>
                            <Link to={RouterPaths.SettingsIntegration}>{getLangText('settings_nav_integrations')}</Link>
                        </li>
                        <li className="active">
                            <div>{getLangText('settings_nav_fakturownia')}</div>
                        </li>
                    </ul>
                </div>
                <div className="row small-padd-row">
                    {fakturowniaPermissionsMatched ?
                        <div className="col-lg-7">
                            <div className="settings-page sub-settings-page export-sub-settings">
                                <div className="header-wrapper no-border pb-0">
                                    <h1>
                                        <img src="/assets/images/settings/waybills.svg" alt="waybills"/>
                                        {getLangText('settings_nav_fakturownia')}
                                    </h1>
                                    <div
                                        className="description">{getLangText('settings_integrations_fakturownia_description_1')}</div>
                                    <div className="form-info-box w-100 mt-4">
                                        <div>
                                            <i className="icon-info-c"/>
                                        </div>
                                        <div>
                                            <div
                                                className="title">{getLangText('settings_integrations_fakturownia_description_2')}</div>
                                            <div className="desc">
                                                {getLangText('settings_integrations_fakturownia_description_3')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="settings-form pt-3">
                                    <div className="steps-wrapper">
                                        <div className="step border-step">
                                            <div className="step-header">
                                                <div className="number">
                                                    1
                                                </div>
                                                <div className="title d-block">
                                                    {(userData.origin === SHOP_ORIGIN_FR && +userData.id !== ANGEL_GLADYS_SHOP_ID) ?
                                                        <a
                                                            rel="noreferrer"
                                                            href="https://vosfactures.fr/?partner=kaaubv"
                                                            target="_blank"
                                                        >
                                                            <img
                                                                style={{marginLeft: 0, height: '26px', width: 'auto'}}
                                                                src="/assets/images/fakturownia-logo-fr.png"
                                                                alt="VosFactures"/>
                                                        </a>
                                                        :
                                                        <a
                                                            rel="noreferrer"
                                                            href="https://fakturownia.pl/?partner=kaaubv"
                                                            target="_blank"
                                                        >
                                                            <img style={{marginLeft: 0, height: '26px', width: 'auto'}}
                                                                 src="/assets/images/fakturownia-logo.svg"
                                                                 alt="Fakturownia"/>
                                                        </a>
                                                    }
                                                    <div>
                                                        {getLangText('settings_integrations_furgonetka_login_1')}
                                                    </div>
                                                </div>
                                                {(userData.origin === SHOP_ORIGIN_FR && +userData.id !== ANGEL_GLADYS_SHOP_ID) ?
                                                    <a target="_blank"
                                                       href="https://vosfactures.fr/?partner=kaaubv"
                                                       className="button primary small-size ml-auto"
                                                    >
                                                        {getLangText('settings_integrations_fakturownia_description_4')}
                                                    </a> :
                                                    <a target="_blank"
                                                       href="https://fakturownia.pl/?partner=kaaubv"
                                                       className="button primary small-size ml-auto"
                                                    >
                                                        {getLangText('settings_integrations_fakturownia_description_4')}
                                                    </a>
                                                }
                                            </div>
                                        </div>
                                        <div className="step border-step mt-3 pb-lg-5 pb-3">
                                            <div className="step-header">
                                                <div className="number">
                                                    2
                                                </div>
                                                <div className="title d-block">
                                                    <div>{getLangText('settings_integrations_fakturownia_description_5')}</div>
                                                    {(userData.origin === SHOP_ORIGIN_FR && +userData.id !== ANGEL_GLADYS_SHOP_ID) ?
                                                        <a
                                                            href="/assets/pdf/fakturownia-tutorial-fr.pdf"
                                                            className="link-info"
                                                            target="_blank">{getLangText('settings_integrations_inpost_description_3')}
                                                        </a> :
                                                        <a href="https://app.selmo.io/assets/tutorial/fakturownia-tutorial.pdf"
                                                           className="link-info"
                                                           target="_blank">{getLangText('settings_integrations_inpost_description_3')}
                                                        </a>
                                                    }
                                                </div>
                                            </div>
                                            <Field
                                                {...getFieldProps('fakturownia_url')}
                                                label={getLangText('input_label_org_url')}
                                                className="mb-sm-0 mb-3 mt-3 w-sm-auto w-100"
                                            />
                                            <Field
                                                {...getFieldProps('fakturownia_api_token')}
                                                label={getLangText('input_label_token_api')}
                                                className="mb-0 w-sm-auto w-100 mt-3"
                                            />
                                            {(+userData.id === TEST_ACCOUNT_SHOP_ID || +userData.id === BUTIK_MADAME_SHOP_ID) &&
                                                <Field
                                                    {...getFieldProps('warehouse_id')}
                                                    label="ID magazynu"
                                                    className="mt-3"
                                                />
                                            }
                                        </div>
                                        <h2 className="mt-3">{getLangText('settings_integrations_fakturownia_description_6')}</h2>
                                        <div className="d-flex w-100">
                                            <Field
                                                {...getFieldProps('additional_information')}
                                                label={getLangText('settings_general_additional_info')}
                                                className=" mb-0 w-100 mt-3"
                                            />
                                        </div>
                                        <div className="d-flex w-100">
                                            <Field
                                                {...getFieldProps('vat_rate')}
                                                label={getLangText('input_label_vat')}
                                                className="mb-0 w-100 mt-3"
                                            />
                                            {/*	TODO vat rate default 23*/}
                                        </div>
                                        <div className="d-flex w-100">
                                            <Field
                                                {...getFieldProps('lump_sum_tax_rate')}
                                                label={`${getLangText('settings_integrations_fakturownia_description_7')}<strong>${getLangText('settings_integrations_fakturownia_description_8')}</strong> ${getLangText('settings_integrations_fakturownia_description_9')}`}
                                                className="mb-0 w-100 mt-3"
                                            />
                                            {/*	TODO  default 0*/}
                                        </div>
                                        <SwitchField
                                            {...getFieldProps('paid_invoices')}
                                            label={getLangText('settings_integrations_fakturownia_description_10')}
                                            className="switch-form inline-label align-items-start big-label light-version mb-0 mt-4"

                                        />
                                        <SwitchField
                                            {...getFieldProps('issue_receipts')}
                                            label={getLangText('settings_integrations_fakturownia_description_11')}
                                            className="switch-form inline-label align-items-start big-label light-version mb-0 mt-3"

                                        />
                                        <SwitchField
                                            {...getFieldProps('receipts_shipping')}
                                            label={getLangText('settings_integrations_fakturownia_description_17')}
                                            className="switch-form inline-label align-items-start big-label light-version mb-0 mt-3"
                                        />
                                        <SwitchField
                                            {...getFieldProps('receipts_payment')}
                                            label={getLangText('settings_integrations_fakturownia_description_18')}
                                            className="switch-form inline-label align-items-start big-label light-version mb-0 mt-3"
                                        />
                                        <div className="d-flex align-items-center mt-2">
                                            <SwitchField
                                                {...getFieldProps('active')}
                                                label={getLangText('active')}
                                                className="switch-form inline-label align-items-start big-label light-version mb-0"

                                            />
                                            <div className="button-place ml-auto">
                                                <button
                                                    type="button"
                                                    onClick={onSave}
                                                    className={`button primary ${isWaiting ? 'loading-button' : ''}`}
                                                >
                                                    <Loader isLoading={isWaiting}/>
                                                    {getLangText('saveButton')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {values.fakturownia_api_token && values.fakturownia_url && (+userData.id === 564 || +userData.id === 5 || +userData.id === 74) &&
                                <div className="default-shadow-box bigger-y-padd">
                                    <a href="/apistorage/importFakturowniaProducts"
                                       className="button primary">{getLangText('button_fakturownia_products_import')}</a>
                                </div>
                            }
                        </div>
                        :
                        <div className="col-md-7">
                            <NoPermissionFakturownia/>
                        </div>
                    }
                    <div className="col-md-5 mt-md-0 mt-3">
                        <div className="default-shadow-box bigger-y-padd payment-benefits-wrapper">
                            <div
                                className="label">{getLangText('settings_integrations_fakturownia_description_12')}</div>
                            <ul className="benefits-list">
                                <li>
                                    <i className="icon-tick-c"/>
                                    {getLangText('settings_integrations_fakturownia_description_13')}
                                </li>
                                <li>
                                    <i className="icon-tick-c"/>
                                    {getLangText('settings_integrations_fakturownia_description_14')}
                                </li>
                                <li>
                                    <i className="icon-tick-c"/>
                                    {getLangText('settings_integrations_fakturownia_description_15')}
                                </li>
                                <li>
                                    <i className="icon-tick-c"/>
                                    {getLangText('settings_integrations_fakturownia_description_16')}
                                </li>
                            </ul>
                            {userData.origin === SHOP_ORIGIN_PL &&
                                <div className="selmo-video-wrapper mt-3">
                                    <iframe
                                        frameBorder="0"
                                        allowFullScreen=""
                                        scrolling="no"
                                        allow="autoplay;fullscreen"
                                        src="https://www.youtube.com/embed/8q3BpvnwWq4?si=SsNRTMxfY4zDDaND"
                                        style={{
                                            position: 'absolute',
                                            height: '100%',
                                            width: '100%',
                                            left: '0',
                                            top: '0'
                                        }}
                                    />
                                </div>
                            }
                            {fakturowniaPermissionsMatched &&
                                <button
                                    type="button"
                                    onClick={() => setShowModal(true)}
                                    className="button border-button big-size w-full mt-3"
                                >
                                    {getLangText('extendAccess')}
                                </button>
                            }
                        </div>
                    </div>
                </div>
                {showModal &&
                    <FunctionalityModal
                        openModal={showModal}
                        setModalOpen={setShowModal}
                        desc={getLangText('generateSalesDocument')}
                        title={getLangText('buyAccessToFakturownia')}
                        functionalityName={FAKTUROWNIA_PERMISSION}
                    />
                }
            </>
        );
    }
;

export default Fakturownia;
