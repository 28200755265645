import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import Help from "../app/help/Page";
import Layout from "../app/Layout";

const HelpRoute = ({path}) => {
	return (
		<Route path={path} exact>
			<Help/>
		</Route>
	);
};

export default HelpRoute;
