import React, {useState} from 'react';
import moment from "moment";
import useLang from "../../../../../../src_shared/hooks/useLang";
import {ORDER_PAYMENT_BM_TRANSFER, ORDER_PAYMENT_STRIPE_CARD} from "../../../../shared/enums/PaymentStatuses";
import {BUTIK_MADAME_SHOP_ID, TEST_ACCOUNT_SHOP_ID} from "../../../../../../src_shared/enums/TrustedShopsIds";
import CustomDropdown from "../../../../shared/components/dropdown/CustomDropdown";
import {useSelector} from "react-redux";
import {convertToUserTimeZone} from "../../../../shared/helpers/dateHelpers";

const Toggle = ({group, index, children, getTotalPrice, values, openModal}) => {
    const {getLangText} = useLang();
    const [open, setOpen] = useState(false);
    const {userData} = useSelector((state) => state.session);

    return (
        <div className={`open-cart-products ${open ? 'active' : ''}`}>
            <div className="open-cart-products__title"
                 onClick={() => setOpen(!open)}
            >
                <div className="flex-grow-1">
                    <h3>
                        {getLangText('purchaseLabel')}<span>{index + 1}</span>
                        {group.payment &&
                            <div className="payment-status">
                                <i className="icon-tick-c"/>
                                <div className="info">
                                    <strong>
                                        {values.shippingData.payment === ORDER_PAYMENT_BM_TRANSFER ?
                                            getLangText('paidByAutomaticTransferLabel') :
                                            values.shippingData.payment === ORDER_PAYMENT_STRIPE_CARD ?
                                                getLangText('cardPaymentLabel') :
                                                getLangText('paymentMethodDefaultOnlineTransferLabel')
                                        }
                                    </strong>
                                    <span>{convertToUserTimeZone(group.payment.date || group.payment.payment_date).format('DD.MM.YY, HH:mm')}</span>
                                    <span>{getTotalPrice(group.payment.amount)}</span>
                                </div>
                            </div>
                        }
                    </h3>
                    <div className="date">
                        {convertToUserTimeZone(group.products[0].date).format('DD.MM.YYYY HH:mm')}
                    </div>
                </div>
                <div className="d-flex align-items-center">
                    <div className="price">
                        {getTotalPrice(group.totalPrice)}
                    </div>
                    {(+userData.id === TEST_ACCOUNT_SHOP_ID || +userData.id === BUTIK_MADAME_SHOP_ID) &&
                        <CustomDropdown
                            icon="icon-dots"
                            className="relative -top-1 mr-2 ml-3"
                        >
                            <button
                                type="button"
                                className="custom-option"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    openModal(index + 1)
                                }}
                            >
                                {getLangText('changePaymentStatus')}
                            </button>
                        </CustomDropdown>
                    }
                    <i className="icon-arrows"/>
                </div>
            </div>
            {children}
        </div>
    );
};

export default Toggle;
