import React from 'react';
import {getStateRoot as getStatsListStateRoot} from "../list/listReducer";
import Loader from "../../shared/Loader";
import ShippingChart from "./chartTypes/ShippingChart";
import IncomesChart from "./chartTypes/IncomesChart";
import PaymentsDonutChart from "./chartTypes/PaymentsDonutChart";
import OrdersChart from "./chartTypes/OrdersChart";
import {useSelector} from "react-redux";
import {createSelector} from "reselect";
import {getStateRoot as getFiltersStateRoot} from "../filters/filtersReducer";
import PackageValuesChart from "./chartTypes/PackageValuesChart";

const ChartsStats = () => {

	const {items, waiting: isWaiting} = useSelector(createSelector(getStatsListStateRoot, (stateRoot) => stateRoot));
	const {currency} = useSelector(createSelector(getFiltersStateRoot, (stateRoot) => stateRoot.data));

	return (
		<section className="charts-section loader-parent">
			<Loader
				className="higher-index"
				isLoading={isWaiting}
			/>
			<div className="row">
				<div className="col-md-7 pr-2">
					<IncomesChart currency={currency} items={items} />
					<OrdersChart currency={currency} items={items} />
				</div>
				<div className="col-md-5 pl-2">
					<PaymentsDonutChart items={items} />
					<ShippingChart items={items} />
					<PackageValuesChart items={items} currency={currency} />
				</div>
			</div>
		</section>
	);
};

export default ChartsStats;
