import React, {useState} from 'react';
import TemplatesList from "./TemplatesList";
import TemplateForm from "./TemplateForm";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import Modal from "react-bootstrap/Modal";

const Templates = ({isVisible, onClose, setSelectedTemplateId, selectTemplate, selectedTemplateId}) => {
    const {getLangText} = useLang();
    const [activeView, setActiveView] = useState("LIST");

    return (
        <Modal
            show={isVisible}
            dialogClassName="default-modal w-420"
            // className="higher-index"
            // backdropClassName="higher-index"
            onHide={onClose}
        >
            {activeView === "LIST" &&
                <Modal.Header className="relative">
                    <div className="font-bold">
                        {getLangText('descriptionTemplates')}
                    </div>
                    <div className="ml-auto text-right -mt-2">
                        <button
                            onClick={onClose}
                            type="button"
                            className="button text-right text-[#6b7280] text-[11px]"
                        >
                            <i className="icon-cross"/>
                        </button>
                    </div>
                </Modal.Header>
            }
            <Modal.Body>
                {activeView === "LIST" ?
                    <TemplatesList
                        setActiveView={setActiveView}
                        selectTemplate={selectTemplate}
                        setSelectedTemplateId={setSelectedTemplateId}
                        selectedTemplateId={selectedTemplateId}
                    /> :
                    <TemplateForm
                        activeView={activeView}
                        setActiveView={setActiveView}
                    />
                }
            </Modal.Body>
        </Modal>
    );
};

export default Templates;
