import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {createSelector} from 'reselect';

export default (actions, getFiltersStateRoot, filterActions, field, selectAllField, options, FormActions, getStateRoot) => {
	const dispatch = useDispatch();

	const [statuses, selectAll, locallyField, locallySelectAll] = useSelector(createSelector(getFiltersStateRoot, getStateRoot, (stateRoot, formStateRoot) => [
		stateRoot.data[field],
		stateRoot.data[selectAllField],
		formStateRoot.data[field],
		formStateRoot.data[selectAllField],
	]));

	const selectAllOptions = () => {
		const preparedFilters = options.map((i) => i.value)
		dispatch(FormActions.data.setValue(field, preparedFilters))
	}

	const handleCheckBoxClick = (fieldValue) => {
		dispatch(FormActions.data.setValue(selectAllField, false))
		const existed = locallyField.find((i) => i === fieldValue);
		if (existed) {
			const filteredHourAvailabilities = locallyField.filter((i) => i !== fieldValue);
			dispatch(FormActions.data.setValue(field, filteredHourAvailabilities))
		} else {
			dispatch(FormActions.data.updateValues({
				[field]: [
					...locallyField,
					fieldValue,
				]
			}))
		}
	};

	const allItemsClick = () => {
		if (!locallySelectAll) {
			selectAllOptions();
		} else {
			dispatch(FormActions.data.setValue(field, []))
			dispatch(FormActions.data.setValue(selectAllField, false))
		}
	};

	const getCheckboxValue = (name) => !!locallyField.find((status) => status == name);

	useEffect(() => {
		dispatch(FormActions.data.setValue(field, statuses))
		dispatch(FormActions.data.setValue(selectAllField, selectAll))
	}, [statuses]);

	useEffect(() => {
		if (options.length && locallyField.length === options.length) {
			dispatch(FormActions.data.setValue(selectAllField, true))
		}
	}, [locallyField])

	return {
		statuses,
		isSelectAll: locallySelectAll,
		handleCheckBoxClick,
		allItemsClick,
		getCheckboxValue,
	};
};
