import React from 'react';
import CopyButton from "../../../../../../src_shared/components/form/copyButton/CopyButton";
import useLang from "../../../../../../src_shared/hooks/useLang";
import {SHOP_ORIGIN_PL} from "../../../../shared/enums/ShopOrigins";
import {useSelector} from "react-redux";

const ClientLink = ({values}) => {
	const {getLangText} = useLang();
	const {userData} = useSelector((state) => state.session);

	const magicLink = `${window.location.origin}/${userData.origin === SHOP_ORIGIN_PL ? 'koszyk' : 'cart'}/${values.name_url}-${values.shop_id}/${values.magic_url}/`;
	return (
		<div className="client-link">
			<div className="form-group with-copy-btn with-preview overflow-visible">
				<h2>{getLangText('orderLinkLabel')}</h2>
				<div className="form-control">
					<a
						href={magicLink}
						target="_blank"
						className="short-link"
					>
						{magicLink}
					</a>
					<a
						href={magicLink}
						className="button border-button small-size ml-sm-auto mr-1 mb-0 d-lg-flex d-none"
						target="_blank"
					>
						<i className="icon-eye"/>
						{getLangText('previewButton')}
					</a>
					<CopyButton
						copyText={magicLink}
					/>
				</div>
			</div>
		</div>
	);
};

export default ClientLink;
