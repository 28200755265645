export const PRODUCTS_RESERVE_LIST_REMOVED_FROM_APP_ACTIVE = '1';
export const PRODUCTS_RESERVE_LIST_REMOVED_FROM_APP_NOT_ACTIVE = '0';


export const PRODUCTS_RESERVE_LIST_REMOVED_FROM_TYPES_TYPES = [
	{
		value: PRODUCTS_RESERVE_LIST_REMOVED_FROM_APP_ACTIVE, label: 'removedFromSelmoApp',
	},
	{
		value: PRODUCTS_RESERVE_LIST_REMOVED_FROM_APP_NOT_ACTIVE, label: 'active',
	},
]
