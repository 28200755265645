import axios from "axios";
import {selmoUrl} from "../../src_shared/api/api";
import {errorComposer, responseComposer} from "../../index";
import Cookies from 'js-cookie'
import {parseParams} from "../../src_shared/list/ListActions";
import {getFromLS} from "../../src_shared/helpers/LS";

const api = axios.create({
    // baseURL: selmoUrl,
    baseURL: 'https://app.selmo.io',
});

api.interceptors.request.use(
    (config) => {
        const queryParams = parseParams(window.location.search);
        const lang = getFromLS('lang')
        const customerSupportId = getFromLS('CustomerSupportId')
        const shopifyHash = getFromLS('Shopify-Hash')
        const langAuth = getFromLS('lang_auth')
        const token = Cookies.get('token') || queryParams.token;
        const tutorialStartVariant = Cookies.get('tutorialStartVariant');
        const tutorialSocialsVariant = Cookies.get('tutorialSocialsVariant');

        if (queryParams.token) {
            Cookies.set('token', queryParams.token, {secure: process.env.REACT_APP_COOKIES_SECURE})
        }

        if (tutorialStartVariant) {
            config.headers.tutorialStartVariant = tutorialStartVariant;
        }

        if (tutorialSocialsVariant) {
            config.headers.tutorialSocialsVariant = tutorialSocialsVariant;
        }

        if (lang) {
            config.headers.lang = lang;
        }

        if (langAuth) {
            config.headers.lang = langAuth;
        }

        if (shopifyHash) {
            config.headers['Shopify-Hash'] = shopifyHash;
        }

        if (customerSupportId) {
            config.headers.CustomerSupportId = customerSupportId;
        }

        config.headers.Timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        config.headers.path = window.location.host;

        config.headers.Pathname = window.location.pathname;

        if (!!token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(function (response) {
    responseComposer(response)
    return response;
}, (error) => {
    errorComposer(error)
    return Promise.reject(error);
});

export default api;
