export const CONFIRM_MODAL_TYPES = {
	REMOVE_CLIENT: 'REMOVE_CLIENT',
	REMOVE_LIVE: 'REMOVE_LIVE',
	CREATE_ORDER: 'CREATE_ORDER',
	BASKET_STATE: 'BASKET_STATE',
	SUMMARY: 'SUMMARY',
	CREATE_ALL_ORDERS: 'CREATE_ALL_ORDERS',
	SEND_ALL_BASKET_STATES: 'SEND_ALL_BASKET_STATES',
	SEND_ALL_SUMMARY: 'SEND_ALL_SUMMARY',
	PRINT_ALL_ORDERS: 'PRINT_ALL_ORDERS',
};
