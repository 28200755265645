import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import useLang from "../../../../src_shared/hooks/useLang";
import useFormLocal from "../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../RestServices";
import Loader from "../../../app/shared/Loader";
import SwitchField from "../../../../src_shared/components/form/switchField/SwitchField";
import ComponentLoader from "../../../app/shared/components/componentLoader/ComponentLoader";
import {Link, useLocation} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import CopyButton from "../../../../src_shared/components/form/copyButton/CopyButton";
import SettingsFurgonetkaModel from "../../../../modules/models/settings/SettingsFurgonetkaModel";
import useCallbackUrl from "../../../../src_shared/hooks/useCallbackUrl";
import {parseParams} from "../../../../src_shared/list/ListActions";
import {showAlert} from "../../../app/shared/components/alert/AlertActions";
import {Helmet} from "react-helmet";

const INTEGRATION_ONE = 'INTEGRATION_ONE';
const INTEGRATION_TWO = 'INTEGRATION_TWO';

const Furgonetka = () => {
        const {getLangText} = useLang();
        const dispatch = useDispatch();
        const {userData} = useSelector((state) => state.session);
        const [activeView, setActiveView] = useState(INTEGRATION_ONE)
        const {search} = useLocation();
        const query = parseParams(search);

        const [{
            onSave,
            isWaiting,
            getFieldProps,
            isLoading,
            values,
            fetchData,
        }] = useFormLocal({
            rest: services.API_SETTINGS_FURGONETKA,
            model: SettingsFurgonetkaModel,
        });

        const afterSubmit = async () => {
            await fetchData()
            dispatch(showAlert('accountHasBeenConnectedLabel'))
        }

        const {waitingForCallbackResponse} = useCallbackUrl({
            queryName: 'code',
            rest: `${services.API_SETTINGS_FURGONETKA_REDIRECT}?code=${query.code}`,
            afterSubmit,
        })

        if (isLoading || waitingForCallbackResponse) return <ComponentLoader/>;

        return (
            <>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>{getLangText('settings_nav_furgonetka')} - Selmo</title>
                </Helmet>
                <div className="header-bar breadcrumbs-in-react-app">
                    <ul className="breadcrumbs">
                        <li>
                            <Link to={RouterPaths.Settings}>{getLangText('asideSettingsLabel')}</Link>
                        </li>
                        <li>
                            <Link to={RouterPaths.SettingsIntegration}>{getLangText('settings_nav_integrations')}</Link>
                        </li>
                        <li className="active">
                            <div>{getLangText('settings_nav_furgonetka')}</div>
                        </li>
                    </ul>
                </div>
                <div className="settings-page sub-settings-page export-sub-settings">
                    <div className="row small-padd-row">
                        <div className="col-lg-7">
                            <div className="header-wrapper no-border pb-0">
                                <h1>
                                    <img src="/assets/images/settings/waybills.svg" alt="waybills"/>
                                    {getLangText('settings_nav_furgonetka')}
                                </h1>
                                <div
                                    className="description">{getLangText('settings_integrations_furgonetka_description')}</div>
                            </div>
                            <div className="tabs-wrapper">
                                <button
                                    type="button"
                                    onClick={() => setActiveView(INTEGRATION_ONE)}
                                    className={activeView === INTEGRATION_ONE ? 'active' : ''}
                                >
                                    {getLangText('button_furgonetka_first_integration')}
                                </button>
                                <button
                                    type="button"
                                    onClick={() => setActiveView(INTEGRATION_TWO)}
                                    className={activeView === INTEGRATION_TWO ? 'active' : ''}
                                >
                                    {getLangText('button_furgonetka_second_integration')}
                                </button>
                            </div>
                            {activeView === INTEGRATION_ONE ?
                                <div className="settings-form pr-lg-0">
                                    <div className="form-info-box w-100 mb-3">
                                        <div>
                                            <i className="icon-info-c"/>
                                        </div>
                                        <div>
                                            <div className="title">{getLangText('settings_integrations_furgonetka_info_1')}
                                                <div
                                                    className="tag">{getLangText('settings_integrations_furgonetka_info_2')}</div>
                                            </div>
                                            <div className="desc">
                                                {getLangText('settings_integrations_furgonetka_info_3')}
                                            </div>
                                        </div>
                                    </div>
                                    {!values.token ?
                                        <div className="center-step">
                                            <img className="ml-0"
                                                 src="/assets/images/export/furgonetka-logo.svg"
                                                 alt="Furgonetka"/>
                                            <div
                                                className="title">{getLangText('settings_integrations_furgonetka_login_1')}</div>
                                            <div
                                                className="desc">{getLangText('settings_integrations_furgonetka_login_2')}</div>
                                            <a
                                                target="_blank"
                                                href={values.furgonetkaLink}
                                                className="button primary small-size"
                                            >
                                                {getLangText('button_login_or_register')}
                                            </a>
                                        </div>
                                        :
                                        <>
                                            <div className="integration-status">
                                                <div className="title">
                                                    {getLangText('settings_integrations_status')}
                                                </div>
                                                <div className="status-boxes">
                                                    <div className="box done">
                                                        <i className="icon-tick-c"/>
                                                        {getLangText('settings_integrations_furgonetka_sign_up_success')}
                                                    </div>
                                                    {values.templates?.length ?
                                                        <div className="box done">
                                                            <i className="icon-tick-c"/>
                                                            {getLangText('settings_integrations_furgonetka_new_template_success')}
                                                            <a
                                                                href="https://furgonetka.pl/konto/szablony_przesylek/"
                                                                target="_blank"
                                                                className="button border-button ml-auto small-size"
                                                            >
                                                                {getLangText('button_add_new_template')}
                                                            </a>

                                                        </div>
                                                        :
                                                        <div className="box">
                                                            <i className="icon-cross-c"/>
                                                            {getLangText('settings_integrations_furgonetka_no_templates')}
                                                            <a
                                                                href="https://furgonetka.pl/konto/szablony_przesylek/"
                                                                target="_blank"
                                                                className="button primary ml-auto small-size"
                                                            >
                                                                {getLangText('button_create_templates')}
                                                            </a>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center mt-4">
                                                <SwitchField
                                                    {...getFieldProps('active')}
                                                    label={getLangText('settings_integrations_option_info')}
                                                    className="switch-form inline-label align-items-start big-label light-version mb-0"

                                                />
                                                <div className="button-place ml-auto">
                                                    <button
                                                        type="button"
                                                        onClick={onSave}
                                                        className={`button primary ${isWaiting ? 'loading-button' : ''}`}
                                                    >
                                                        <Loader isLoading={isWaiting}/>
                                                        {getLangText('saveButton')}
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                                :
                                <div className="settings-form pr-lg-0">
                                    <div className="form-info-box w-100 mb-3">
                                        <div>
                                            <i className="icon-info-c"/>
                                        </div>
                                        <div>
                                            <div
                                                className="title">{getLangText('settings_integrations_furgonetka_info_4')}</div>
                                            <div className="desc">
                                                {getLangText('settings_integrations_furgonetka_info_5')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="steps-wrapper">
                                        <div className="step border-step">
                                            <div className="step-header flex-wrap">
                                                <div className="number">1</div>
                                                <div className="title d-block">
                                                    <a href="https://furgonetka.pl/konto/partner/1763980?url=https://furgonetka.pl/"
                                                       target="_blank" className="big-logo">
                                                        <img className="ml-0"
                                                             src="/assets/images/export/furgonetka-logo.svg"
                                                             alt="Furgonetka"/>
                                                    </a>
                                                    <div>
                                                        {getLangText('settings_integrations_furgonetka_login_1')}
                                                    </div>
                                                </div>
                                                <a target="_blank"
                                                   href="https://furgonetka.pl/konto/partner/1763980?url=https://furgonetka.pl/"
                                                   className="button primary small-size ml-auto w-sm-auto w-100 mt-sm-0 mt-3 text-center">{getLangText('settings_integrations_furgonetka_login_2')}</a>
                                            </div>
                                        </div>
                                        <div className="step border-step mt-3">
                                            <div className="step-header">
                                                <div className="number">2</div>
                                                <div
                                                    className="title">{getLangText('settings_integrations_furgonetka_go_to_settings')}</div>
                                                <a href="https://furgonetka.pl/konto/sklepy/dodaj/universal"
                                                   target="_blank"
                                                   className="button primary small-size ml-auto">{getLangText('settings_menu_settings')}</a>
                                            </div>
                                        </div>
                                        <div className="step border-step mt-3">
                                            <div className="step-header">
                                                <div className="number">3</div>
                                                <div className="title d-block">
                                                    {getLangText('settings_integrations_furgonetka_copy_paste_data_1')}
                                                    <a href="/assets/images/furgonetka.jpeg" className="link-info"
                                                       target="_blank">
                                                        {getLangText('settings_integrations_furgonetka_copy_paste_data_2')}
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="mb-3 mt-3">
                                                <div className="form-group with-copy-btn">
                                                    <label
                                                        className="control-label">{getLangText('input_label_name_displayed')}</label>
                                                    <div className="form-control">
                                                        <span className="short-link">{userData.name} - selmo.io</span>
                                                        <CopyButton
                                                            copyText={`${userData.name} - selmo.io`}
                                                            copyButtonText={getLangText('copyButton')}
                                                            copiedButtonText={getLangText('copiedButton')}
                                                            withoutTooltip={true}
                                                            buttonClassName="border-button"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <div className="form-group with-copy-btn">
                                                    <label
                                                        className="control-label">{getLangText('input_label_url_address')}</label>
                                                    <div className="form-control">
                                                        <a
                                                            href={values.export_url}
                                                            className="short-link"
                                                        >
                                                            {values.export_url}
                                                        </a>
                                                        <CopyButton
                                                            copyText={values.export_url}
                                                            copyButtonText={getLangText('copyButton')}
                                                            copiedButtonText={getLangText('copiedButton')}
                                                            withoutTooltip={true}
                                                            buttonClassName="border-button"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <div className="form-group with-copy-btn">
                                                    <label
                                                        className="control-label">{getLangText('input_label_token')}</label>
                                                    <div className="form-control">
                                                        <span className="short-link">{userData.hash}</span>
                                                        <CopyButton
                                                            copyText={userData.hash}
                                                            copyButtonText={getLangText('copyButton')}
                                                            copiedButtonText={getLangText('copiedButton')}
                                                            withoutTooltip={true}
                                                            buttonClassName="border-button"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="step border-step mt-3">
                                            <div className="step-header">
                                                <div>
                                                    <div className="number">4</div>
                                                </div>
                                                <div
                                                    className="title">{getLangText('settings_integrations_furgonetka_label_ready_text')}</div>
                                                <a href="https://furgonetka.pl/konto/zamowienia" target="_blank"
                                                   className="button border-button small-size ml-auto">
                                                    {getLangText('button_shipping_labels')}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="settings-page sub-settings-page export-sub-settings">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="header-wrapper pr-lg-0 no-border pb-0">
                                <h1>
                                    {getLangText('settings_integrations_notification')}
                                </h1>
                                <div className="description">
                                    {getLangText('settings_integrations_notification_description')}
                                </div>
                            </div>
                            <div className="settings-form pr-lg-0">
                                <div className="steps-wrapper">
                                    <div className="step border-step">
                                        <div className="step-header">
                                            <div className="number">1</div>
                                            <div
                                                className="title">{getLangText('settings_integrations_notification_go_to_settings')}</div>
                                            <a href="https://furgonetka.pl/konto/powiadomienia" target="_blank"
                                               className="button primary ml-auto small-size">{getLangText('settings_menu_settings')}</a>
                                        </div>
                                    </div>
                                    <div className="step border-step mt-3">
                                        <div className="step-header">
                                            <div>
                                                <div className="number">2</div>
                                            </div>
                                            <div className="title d-block">
                                                {getLangText('settings_integrations_notification_copy_paste_data_1')}
                                                <a
                                                    href="/assets/images/furgonetka02.jpg"
                                                    className="link-info"
                                                    target="_blank"
                                                >
                                                    {getLangText('settings_integrations_furgonetka_copy_paste_data_2')}
                                                </a>
                                            </div>
                                        </div>
                                        <div className="mb-3 mt-3">
                                            <div className="form-group with-copy-btn">
                                                <label
                                                    className="control-label">{getLangText('input_label_url')}</label>
                                                <div className="form-control">
                                                    <a
                                                        href={values.webhook_url}
                                                        className="short-link"
                                                    >
                                                        {values.webhook_url}
                                                    </a>
                                                    <CopyButton
                                                        copyText={values.webhook_url}
                                                        copyButtonText={getLangText('copyButton')}
                                                        copiedButtonText={getLangText('copiedButton')}
                                                        withoutTooltip={true}
                                                        buttonClassName="border-button"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="form-group with-copy-btn">
                                                <label
                                                    className="control-label">{getLangText('input_label_salt')}</label>
                                                <div className="form-control">
                                                    <span className="short-link">{userData.hash}</span>
                                                    <CopyButton
                                                        copyText={userData.hash}
                                                        copyButtonText={getLangText('copyButton')}
                                                        copiedButtonText={getLangText('copiedButton')}
                                                        withoutTooltip={true}
                                                        buttonClassName="border-button"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="form-group with-copy-btn">
                                                <label
                                                    className="control-label">{getLangText('input_label_format')}</label>
                                                <div className="form-control">
                                                    <span className="short-link">{getLangText('input_label_json')}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
;

export default Furgonetka;
