import React, {useState} from 'react';
import useLang from "../../../src_shared/hooks/useLang";

const DMListPage = () => {
    const {getLangText} = useLang();

    return (
        <div className="bg-[#fff] sm:py-36 py-12 rounded-[5px] shadow-default rounded-b block hover:no-underline group">
            <div className={`max-w-[300px] mx-auto text-center block w-full`}>
                <div>
                    <div className="mx-auto w-[36px] mb-2">
                        <img className="w-full" src="/assets/images/svg/locked.svg" alt=""/>
                    </div>
                    <div>
                        <div className="font-bold text-xl text-[#101827] mb-1">{getLangText('dmList')}</div>
                        <div
                            className="font-medium text-sm text-[#6B7280] leading-[21px] whitespace-pre-wrap">{getLangText('dmListSubLabel')}</div>
                    </div>
                    <div
                        style={{fontSize: 14, borderRadius: 8}}
                        className="button not-active primary w-full small-size mt-4 mb-2"
                    >
                        {getLangText('soonAvailable')}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DMListPage;
