import React from 'react';
import {NavLink, useLocation} from "react-router-dom";
import useLang from "../../../src_shared/hooks/useLang";
import {RouterPaths} from "../../routes/RouterPath";
import {useDispatch, useSelector} from "react-redux";
import ListActions from "../searchModal/ListActions";

const tabsWrapper = [
    {id: 0, href: RouterPaths.Settings, label: 'all'},
    {id: 1, href: RouterPaths.SettingsShop, label: 'settings_nav_shop_settings'},
    {id: 2, href: RouterPaths.SettingsPaymentsShipments, label: 'settings_nav_payment_shipping'},
    {id: 4, href: RouterPaths.SettingsMeta, label: 'socialMediaChannels'},
    {id: 5, href: RouterPaths.SettingsIntegration, label: 'settings_nav_integrations'},
    {id: 6, href: RouterPaths.SettingsAccount, label: 'settings_nav_profile_settings'},
]

const TabsWrapper = ({children}) => {
    const {getLangText} = useLang();
    const {pathname} = useLocation();
    const dispatch = useDispatch();
    const showModal = () => dispatch(ListActions.modal.showModal())

    return (
        <>
            <div className="bg-[white] p-[20px] sm:p-8 pb-0 border-b border-[#E5E7EB] rounded-t-[5px]">
                <div className="text-[22px] leading-[26px] font-bold mb-[12px]">
                    {getLangText('asideSettingsLabel')}
                </div>
                <div className="flex items-end">
                    <div className="flex items-center space-x-5 pb-3.5 overflow-x-auto">
                        {tabsWrapper.map((tab) => (
                            <NavLink
                                to={tab.href}
                                className={`relative whitespace-nowrap font-bold text-xs hover:text-[#000] hover:no-underline transition-all before:content-[""] before:absolute before:-bottom-3.5 before:left-0 before:right-0 before:h-[1px] before:transition-all before:duration-300 ${pathname === tab.href ? 'text-[#000] before:bg-[#2464EB]' : 'before:bg-[transparent] text-[#6B7280]'}`}
                                activeClassName="text-[#000]"
                                exact
                            >
                                {getLangText(tab.label) || tab.label}
                            </NavLink>
                        ))}
                    </div>
                    <div className="ml-auto pl-3 grow mb-2 max-w-[255px]">
                        <button
                            onClick={showModal}
                            className="bg-[#F3F4F6] text-left w-full min-w-[120px] rounded-[5px] text-xs text-[#6B7280] font-medium p-[10px]"
                            type="button"
                        >
                            <i className="icon-magnifier text-[#D0D5DC] mr-2"/>
                            {getLangText('searchPlaceholder')}
                        </button>
                    </div>
                </div>
            </div>
            {children}
        </>
    );
};

export default TabsWrapper;
