import React, {useEffect} from 'react';
import MultiRowListTemplates from "./MultiRowListTemplates";
import useLang from "../../../../src_shared/hooks/useLang";
import useFormLocal from "../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../RestServices";
import SettingsAddToCartTemplatesModel from "../../../../modules/models/settings/SettingsAddToCartTemplatesModel";
import {showAlert} from "../../../app/shared/components/alert/AlertActions";
import {useDispatch} from "react-redux";

const AddToCartRows = ({
                           values,
                           defaultRowLength,
                           model = SettingsAddToCartTemplatesModel,
                           rest = services.API_SETTINGS_MESSENGER_TEMPLATES_ADD_TO_CART,
                           place = 'SETTINGS'
                       }) => {
    const {getLangText} = useLang();
    const dispatch = useDispatch();

    const [{
        onSave,
        isWaiting,
        getFieldProps,
        setValue,
        updateValues,
    }] = useFormLocal({
        rest,
        model,
        initialAutoLoad: false,
        afterSubmit: () => {
            dispatch(showAlert())
        }
    });

    return (
        <section id="add-to-basket">
            <div className="left-side">
                <h2 className="label">{getLangText('settings_messenger_description_2')}</h2>
            </div>
            <MultiRowListTemplates
                getFieldProps={getFieldProps}
                onSave={onSave}
                isWaiting={isWaiting}
                field="add_to_cart"
                label="inputLabelAddToCart"
                setValue={setValue}
                updateValues={updateValues}
                data={values}
                defaultRowLength={defaultRowLength}
                place={place}
            />
        </section>
    );
};

export default AddToCartRows;
