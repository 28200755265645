import React from 'react';
import {Redirect, Route, Switch, useHistory} from "react-router-dom";
import {RouterPaths} from "./RouterPath";
import OrdersList, {firstPageBenefits} from "../app/orders/list/List";
import OrderForm from "../app/orders/details/form/Form";
import AddOrderModal from "../app/orders/form/Form";
import FormActions from "../app/orders/form/FormActions";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {getStateRoot} from "../app/orders/form/formReducer";
import {SELMO_APP_PERMISSION} from "../app/shared/enums/SelmoPermissions";
import PermissionRoute from "./PermissionRoute";
import TutorialPageBox from "../../src_shared/components/ui/TutorialPageBox/TutorialPageBox";
import {getStateRoot as getOrdersListStateRoot} from "../app/orders/list/listReducer";
import {HEADER_BOX_ORDERS} from "../app/shared/enums/TutorialVideoStatsTypes";

const OrdersRoute = ({path}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [{modalVisible}, {isFirstStepPage, isLoading}] = useSelector(createSelector(getStateRoot, getOrdersListStateRoot, (stateRoot, listStateRoot) => [
        stateRoot,
        listStateRoot
    ]));
    const {first_step_orders} = useSelector((state) => state.session.userData);

    const saveOrder = () => dispatch(FormActions.submit(history));

    return (
        <PermissionRoute allowedPermissions={[SELMO_APP_PERMISSION]}>
            <div className="orders-wrapper position-relative">
                {(!isFirstStepPage && !first_step_orders) &&
                    <TutorialPageBox
                        page='orders'
                        videoStatsName={HEADER_BOX_ORDERS}
                        benefits={firstPageBenefits}
                        title="asideOrdersLabel"
                    />
                }
                <Switch>
                    <Route
                        exact
                        path={path}
                        component={OrdersList}
                    />
                    <Route
                        exact
                        path={RouterPaths.OrderForm}
                        component={OrderForm}
                    />
                    <Redirect to={RouterPaths.OrdersList}/>
                </Switch>
            </div>
            {modalVisible &&
                <AddOrderModal
                    saveOrder={saveOrder}
                />
            }
        </PermissionRoute>
    );
};

export default OrdersRoute;
