import React from 'react';
import useLang from "../../../../src_shared/hooks/useLang";
import {useSelector} from "react-redux";

const NotConnected = ({data}) => {
    const {getLangText} = useLang();
    const {userData} = useSelector((state) => state.session);

    return (
        <div className="settings-page sub-settings-page help-contact-box partners-page mb-sm-4 instagram-connected-page"
             style={{borderTopLeftRadius: 0, borderTopRightRadius: 0}}>
            <div className="text-center not-connected-view with-padd">
                <div>
                    <img width="100" src="/assets/images/svg/selmoxfacebook.svg" alt="selmoxfacebook" />
                </div>
                <div className="title">{getLangText('settings_facebook_text_1')}</div>
                <p>
                    {getLangText('automateYourSalesLabel')}
                </p>
                <a href={data.facebookLoginUrl} className="button primary small-size">
                    {getLangText('settings_facebook_text_2')}
                </a>
            </div>

        </div>

);
};

export default NotConnected;
