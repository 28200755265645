import React from 'react';
import moment from "moment";
import useLang from "../../../../../src_shared/hooks/useLang";
import {useDownloadFile} from "../../../../../src_shared/hooks/useDownloadFile";
import {services} from "../../../../RestServices";
import Loader from "../../../../../src_shared/components/loader/Loader";
import {convertToUserTimeZone} from "../../../../app/shared/helpers/dateHelpers";

const Box = ({item}) => {
    const {getLangText} = useLang();

    const {download, waiting} = useDownloadFile({
        rest: `${services.API_SETTINGS_BILLING_INVOICE}/${item.invoice_id}`,
        fileName: 'Faktura',
    })


    return (
        <div className="border rounded-md w-full p-3 flex items-center">
            <div className="grow">
                <div className="text-sm font-bold text-[#101827]">{convertToUserTimeZone(item.date).format('D MMMM YYYY')}</div>
                <div className="text-sm text-desc">{item.price} {item.currency_shortcut}</div>
            </div>
            {item.invoice_id &&
                <button
                    onClick={download}
                    type="button"
                    className={`button border-button left-icon ${waiting ? 'loading-button' : ''}`}
                >
                    {waiting && <Loader isLoading/>}
                    <i className="icon-download relative -top-[1px]"/>
                    {getLangText('downloadInvoiceLabel')}
                </button>
            }
        </div>
    );
};

export default Box;
