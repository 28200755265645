import ListActions from "../../../../src_shared/list/ListActions";
import {getStateRoot, prefix} from './listReducer';
import {services} from "../../../RestServices";
import axios from "axios";
import {selmoUrl} from "../../../../src_shared/api/api";
import api from "../../../../services/axios/axios";

export class KnowledgeBaseAsideActions extends ListActions {
	setCategoryArticles(articles, categoryId) {
		return {
			type: `${this.prefix}SET_CATEGORY_ARTICLES`,
			articles,
			categoryId,
		};
	}

	setActiveCategoryId(categoryId) {
		return {
			type: `${this.prefix}SET_ACTIVE_CATEGORY_ID`,
			categoryId,
		};
	}


	getArticleByCategory(item) {
		return async (dispatch) => {
			dispatch(this.setActiveCategoryId(item.id));
			try {
				const {data} = await api.get(`${selmoUrl}/${services.API_KNOWLEDGE_BASE_CATEGORY_ARTICLES}?name_url=${item.name_url}`)
				dispatch(this.setCategoryArticles(data.items, item.id))
			} catch (e) {
			}
		}
	}

}

export const getInstance = () => new KnowledgeBaseAsideActions({
	getStateRoot,
	restService: services.API_KNOWLEDGE_BASE_CATEGORIES,
	prefix,
	preventPushParams: true,
});

export default getInstance();
