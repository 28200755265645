import waitingActions from "../list/waiting/WaitingActions";
import dataActions from "./data/DataActions";
import modalActions from "../modal/ModalActions";
import axios from "axios";
import {selmoUrl} from "../api/api";
import {showAlert} from "../../components/app/shared/components/alert/AlertActions";
import validationActions from "./validation/ValidationActions";
import {batch} from "react-redux";
import {ERROR_NOT_FOUND} from "../enums/ErrorEnums";
import LangsActions from "../../components/app/shared/langs/LangsActions";
import SessionActions from "../../components/app/shared/session/SessionActions";
import api from "../../services/axios/axios";

export class FormActions {
	constructor(config = {}) {
		const {
			getStateRoot,
			restService,
			prefix,
			model,
			route
		} = {...config};

		this.getStateRoot = getStateRoot;
		this.restService = restService;
		this.prefix = prefix;
		this.route = route

		this.data = dataActions.createActions(this.prefix);
		this.modal = modalActions.createActions(this.prefix);
		this.waiting = waitingActions.createActions(this.prefix);
		this.validation = validationActions.createActions(this.prefix);

		this.dataModel = model;
	}

	parseData(item, ...args) {
		return () => this.dataModel.parseDTO(item, ...args);
	}

	submitEditForm(values, history) {
		return async (dispatch) => {
			const {id} = values;

			dispatch(this.validation.activateValidation());

			if (!this.dataModel.validateData(values)) {
				dispatch(showAlert('fillRequireFieldWarning', '', 'alert-fail'))
				console.error('Validation failed!');
				return;
			}

			const filteredData = Object.entries(values).filter(([key]) => key !== 'id');
			const finalData = Object.fromEntries(filteredData)

			const preparedData = this.dataModel.buildDTO(finalData)

			dispatch(this.waiting.startWaiting());

			try {
				if (id) {
					await api.put(`${selmoUrl}/${this.restService}/${id}`, preparedData)
					dispatch(this.afterSubmit(id))
				} else {
					const {data} = await api.post(`${selmoUrl}/${this.restService}`, preparedData)
					if (this.route) {
						this.updateRoute(data, history)
					} else {
						dispatch(this.afterSubmit(data.id, data))
					}
				}
				dispatch(showAlert())
				dispatch(this.validation.deactivateValidation())
			} catch (e) {
				dispatch(showAlert(e?.response?.data?.message || 'sthWentWrongWarning', '', 'alert-fail'))
				console.error('Error submitEditForm')
			} finally {
				dispatch(this.waiting.stopWaiting());
			}
		}
	}


	afterSubmit(id, data) {
		return (dispatch) => {
			dispatch(this.loadData(id))
		};
	}

	afterDeleteSubmit(history) {
		return (dispatch) => {
			// dispatch(this.loadData(data.id))
		};
	}

	updateRoute(data, history) {
		history.push(`${this.route}/${data.id}/`);
	}

	onFirstInit() {
		return (dispatch) => {
			api.defaults.headers.common['lang'] = dispatch(LangsActions.getLang())
		}
	}

	loadData(id) {
		return async (dispatch) => {
			dispatch(this.waiting.startWaiting());
			dispatch(this.onFirstInit());
			const restUrl = id ? `${this.restService}/${id}` : this.restService;
			try {
				const {data} = await api.get(`${selmoUrl}/${restUrl}`)
				const parsedItem = dispatch(this.parseData(data.item));
					dispatch(this.data.updateValues(parsedItem))
			} catch (e) {
				if (e.response?.data?.message === ERROR_NOT_FOUND || e.response?.status === 500) {
					dispatch(this.data.setValue('notFound', true));
				}
				dispatch(this.onErrorLoad(e))
				console.error('Error loadData')
			} finally {
				dispatch(this.waiting.stopWaiting());
			}
		}
	}

	onErrorLoad(e) {
		return (dispatch) => {
			if (e.message === "canceled") {
				return;
			}}
	}

	resetData() {
		return (dispatch) => {
			dispatch(this.data.restoreEmptyData());
			dispatch(this.validation.deactivateValidation())
		};
	}

	reset() {
		return {
			type: `${this.prefix}RESET`
		};
	}

	waitingForRemoving(toggle) {
		return {
			type: `${this.prefix}WAITING_FOR_REMOVING`,
			toggle,
		}
	}

	deleteWithoutAsk(history) {
		return async (dispatch, getState) => {
			const {data} = this.getStateRoot(getState());
			dispatch(this.waitingForRemoving(true));

			try {
				await api.delete(`${selmoUrl}/${this.restService}/${data.id}`)

				batch(() => {
					dispatch(showAlert('yuhuLabel', 'Pomyślnie usunięto'))
					dispatch(this.afterDeleteSubmit(history))
				})
			} catch (e) {
				dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
			} finally {
				dispatch(this.waitingForRemoving(false));
			}
		}
	}
}

export default FormActions;
