import Model from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

class SettingsTransferModel extends Model {
    getModel() {
        return {
            transfer_account_name: '',
            transfer_name: '',
            transfer_address: '',
            transfer_account_number: '',
            transfer_title: '',
            transfer_swift: '',
            transfer_currency: '',
            transfer_active: '0',
            extraAccount: false,
            defaultCurrency: {},
        };
    }

    getValidators() {
        return {
            transfer_account_name: (value, data) => data.extraAccount ?  ValidationError.notEmpty(value, data) : ValidationError.skip(value, data),
            transfer_name: ValidationError.notEmpty,
            transfer_address: ValidationError.skip,
            transfer_account_number: ValidationError.notEmpty,
            transfer_title: ValidationError.notEmpty,
            transfer_swift: ValidationError.skip,
            transfer_currency: ValidationError.skip,
            transfer_active: ValidationError.skip,
        };
    }

    buildDTO(data) {
        const defaultData = {
            transfer_name: data.transfer_name,
            transfer_address: data.transfer_address,
            transfer_account_number: data.transfer_account_number,
            transfer_title: data.transfer_title,
            transfer_active: data.transfer_active,
        }

        if (!!data.transfer_account_name) {
            return {
                transfer_account_name: data.transfer_account_name,
                transfer_currency: data.transfer_currency || data.defaultCurrency?.value,
                ...defaultData
            }
        }

        return {
            ...defaultData,
            transfer_swift: data.transfer_swift,
        }
    }
}

export default new SettingsTransferModel();
