import React from 'react';
import Description from "./Description";
import ServerImage from "../../../shared/components/serverImage/ServerImage";
import {useUnitPrice} from "../../../shared/helpers/Price";
import LanguagePicker from "../../../shared/languagePicker/LanguagePicker";

const Header = ({values}) => {
    const {getPrice} = useUnitPrice();

    return (
        <div className="-mx-6 -mt-6 mb-4">
            <div className="relative bg-[#F5F5F7] sm:h-[260px] h-[240px] rounded-t-xl flex items-center justify-center">
                <LanguagePicker
                    buttonClassName="absolute right-[16px] sm:right-[24px] top-[16px] sm:top-[24px] px-2.5 py-1.5 bg-[white] rounded-[8px] inline-flex items-center justify-center transition-all custom-hover with-border"
                    withCountryName
                />
                {!values.productInfo?.photo || values.productInfo?.photo === '0' ?
                    <img
                        src="/assets/images/svg/product.svg"
                        alt={values?.productInfo?.name}
                        className="h-[50px] object-cover"
                    />
                    :
                    <ServerImage
                        alt={values?.productInfo?.name}
                        className="h-full w-full object-cover rounded-t-xl"
                        src={values.productInfo?.photo}
                    />
                }
                <div
                    className="absolute left-1/2 bottom-[-25px] p-[4px] rounded-full -translate-x-1/2 w-[88px] h-[88px] bg-[#fff]">
                    {values.shopInfo?.logo && values.shopInfo?.logo !== '0'
                        ? <ServerImage
                            className="w-full h-full object-cover rounded-full"
                            src={values.shopInfo?.logo}
                            alt={values.shopInfo?.name}
                        /> :
                        <img
                            className="w-full h-full object-cover rounded-full"
                            src="/assets/images/default/shop.svg"
                            alt={values.shopInfo?.name}
                        />
                    }
                </div>
            </div>
            <div className="text-center mt-4 max-w-[340px] sm:max-w-[500px] mx-auto">
                <div className="text-sm text-desc font-medium">{values.shopInfo?.name}</div>
                <div className="text-xl font-bold">{values.productInfo?.name}</div>
                {values.productInfo?.old_price ?
                    <div className="flex items-center justify-center text-xs font-medium">
                        <div className="mr-2 line-through text-desc">{getPrice(values.productInfo?.old_price, values.shopInfo?.currency_shortcut)}</div>
                        <div>{getPrice(values.productInfo?.price, values.shopInfo?.currency_shortcut)}</div>
                    </div> :
                    <div className="text-xs font-medium">{getPrice(values.productInfo?.price, values.shopInfo?.currency_shortcut)}</div>
                }
                {values.productInfo?.description &&
                    <Description
                        description={values.productInfo?.description}/>
                }
            </div>
        </div>
    );
};

export default Header;
