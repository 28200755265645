import React, {useState} from 'react';
import useLang from "../../../../../src_shared/hooks/useLang";
import MarketingRegulationModal from "./MarketingRegulationModal";

const CollapseAgreement = ({children, desc}) => {
    const {getLangText} = useLang();
    const [showMore, setShowMore] = useState(false)

    return (
        <div className="text-xs text-[#6B7280] font-medium leading-[20px] mb-3.5">
            {children}
            <div className="ml-[28px]">
                <button
                    onClick={() => setShowMore(true)}
                    type="button"
                    className="block -mt-3 transition-all hover:text-[#000]"
                >
                    <i className="icon-plus text-[8px] mr-1"/>
                    {getLangText('readMoreButton')}
                </button>
            </div>
            {showMore &&
                <MarketingRegulationModal
                    modalVisible={showMore}
                    hideModal={() => setShowMore(false)}
                    desc={desc}
                />
            }
        </div>
    );
};

export default CollapseAgreement;
