import React from 'react';
import {useSelector} from "react-redux";
import {createSelector} from "reselect";

const useTableColumn = (taleColumns, name, getFiltersStateRoot) => {
	const tableColumn = useSelector(createSelector(
		getFiltersStateRoot,
		(stateRoot) => stateRoot.data[name],
	));

	const findTableColumnById = (id) => taleColumns?.find((status) => status.value === id)

	const sortedTableColumn = tableColumn?.sort((a, b) => findTableColumnById(a)?.id - findTableColumnById(b)?.id)

	const preparedTableColumn = sortedTableColumn?.length > 0 ? sortedTableColumn : taleColumns?.map((i) => i.value);

	const removedOldColumnFromLS = preparedTableColumn?.filter((a) => taleColumns?.some((b) => a === b.value));

	return {
		tableColumn,
		preparedTableColumn: removedOldColumnFromLS,
		findTableColumnById,
	}
};

export default useTableColumn;
