import React, {useState} from 'react';
import useLang from "../../../../../../src_shared/hooks/useLang";
import ServerImage from "../../../../shared/components/serverImage/ServerImage";
import Loader from "../../../../../../src_shared/components/loader/Loader";

const VideoPreview = ({values}) => {
    const {getLangText} = useLang();
    const [activeVideo, setActiveVideo] = useState(false)

    if (!values.poster_url) return (
        <section className="bg-[#fff] rounded-[5px] p-[25px]">
            <div
                className={`font-bold text-lg`}>{getLangText('postPreview')}</div>
            <div className="font-medium text-sm text-desc mb-3">{getLangText('thisIsWhatYourClientWillSee')}</div>
            <div className="bg-[#F9FAFB] h-[300px] flex items-center justify-center rounded-[5px]">
                <div className="text-center">
                    <i className="icon-live text-2xl text-desc"/>
                    <div className={`font-bold text-lg`}>{getLangText('postPreview')}</div>
                    <div className="font-medium text-sm text-desc mb-3">{getLangText('hereWillBePostPreview')}</div>
                </div>
            </div>
        </section>
    );

    return (
        <section className="bg-[#fff] rounded-[5px] p-[25px]">
            <div
                className={`font-bold text-lg`}>{getLangText('postPreview')}</div>
            <div className="font-medium text-sm text-desc mb-3">{getLangText('thisIsWhatYourClientWillSee')}</div>
            {values.video_url ?
                <figure className="w-full rounded-[5px] overflow-hidden relative">
                    {activeVideo ?
                        <video
                            autoPlay={true}
                            className="w-full h-full object-cover"
                            controls
                            muted={true}
                            src={values.video_url}
                        /> :
                        <>
                            <div
                                className="absolute top-1/2 left-1/2 -translate-y-1/2 transition-all pointer-events-none group-hover:scale-[105%] -translate-x-1/2 rounded-full  w-[60px] h-[60px] bg-[rgba(255,255,255,0.3)] backdrop-blur-sm flex items-center justify-center">
                                <img
                                    className="sm:w-[18px] w-[14px] z-10"
                                    src="/assets/images/automaticTransfer/triangle.svg"
                                    alt="play"
                                />
                            </div>
                            <ServerImage
                                onClick={() => setActiveVideo(true)}
                                className="h-full w-full object-cover cursor-pointer"
                                src={values.poster_url}
                                alt=""
                            />
                        </>
                    }
                </figure> :
                <div className="rounded-[5px] overflow-hidden relative">
                    <img
                        className="rounded-[5px] w-full object-cover"
                        src={values.poster_url}
                        alt="poster"
                    />
                    {values.shop_post_id &&
                        <div
                            className="bg-[#00000075] absolute top-0 left-0 w-full h-full flex items-center justify-center overflow-hidden">
                            <div className="flex items-center">
                                <Loader
                                    className="small-loader static-loader mr-2.5 mt-0.5 w-auto bg-transparent transparent-loader"
                                    isLoading
                                />
                                <div className="font-bold text-[#fff] text-sm">
                                    {getLangText('theVideoIsBeingProcessed')}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
        </section>
    );
};

export default VideoPreview;
