import React, {useState} from 'react';
import useLang from "../../../../../../src_shared/hooks/useLang";
import AssignProductsModal from "../../../../products/mobileReels/form/assignProducts/Form";
import Products from "./components/products/Products";
import ValidationBox from "../ValidationBox";

const AssignProducts = ({values, setValue, hasError, isActive}) => {
    const {getLangText} = useLang();
    const [showAssignProductsModal, setShowAssignProductsModal] = useState(false);

    return (
        <section className="bg-[#fff] rounded-[5px] mt-3 p-[25px] tooltip-parent">
            {!isActive &&
                <div className="tooltip-content small-size leading-[16px] text-center">
                    {getLangText('selectVideoToUpload')}
                </div>
            }
            <div
                className={`font-bold text-lg ${isActive ? '' : 'text-[#9CA3AF]'}`}>{getLangText('liveAssignProductLabel')}</div>
            {isActive &&
                <>
                    <div className="font-medium text-sm text-desc mb-3">{getLangText('itWillAppearToBuy')}</div>
                    {!!values?.products?.length ?
                        <Products
                            setModalVisible={setShowAssignProductsModal}
                            products={values.products}
                            setProducts={(products) => setValue('products', products)}
                        /> :
                        <button
                            onClick={() => setShowAssignProductsModal(true)}
                            type="button"
                            className="text-center py-16 w-full"
                        >
                            <div
                                className="w-[30px] mx-auto h-[30px] rounded-[5px] bg-[#E5E7EB] flex items-center justify-center text-[#230C34] mb-2">
                                <i className="icon-plus"/>
                            </div>
                            <div className="font-bold sm:text-lg">{getLangText('liveAssignProductLabel')}</div>
                            <div className="font-medium text-sm text-desc">{getLangText('itWillAppearToBuy')}</div>
                            <div className="button primary small-size mt-3" style={{fontSize: 14}}>
                                {getLangText('liveAssignProductLabel')}
                            </div>
                        </button>
                    }
                    {showAssignProductsModal &&
                        <AssignProductsModal
                            hideModal={() => setShowAssignProductsModal(false)}
                            modalVisible={showAssignProductsModal}
                            updateProducts={(products) => setValue('products', products)}
                            defaultSelectedProducts={values.products}
                        />
                    }
                    <ValidationBox
                        visible={hasError}
                        title="selectProductToGo"
                    />
                </>
            }
        </section>
    );
};

export default AssignProducts;
