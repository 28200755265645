import Modal from "react-bootstrap/Modal";
import Loader from "../../messenger/loader/Loader.jsx";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {addNewLiveModalToggleVisibility, updateFieldInNewLiveModalForm, onSubmit} from "./NewLiveModalActions";
import TimePicker from 'react-time-picker';
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {useHistory} from "react-router-dom";
import useEnterSubmitForm from "../../../../src_shared/hooks/useEnterFormSubmit";
import CheckboxField from "../../../../src_shared/components/form/checkboxField/CheckboxField";
import useLang from "../../../../src_shared/hooks/useLang";
import pl from 'date-fns/locale/pl';
import fr from 'date-fns/locale/fr';
import pt from 'date-fns/locale/pt';
import it from 'date-fns/locale/it';
import de from 'date-fns/locale/de';
import sk from 'date-fns/locale/sk';
import {prepareRealLang} from "../../../../services/i18n/i18nAPI";

registerLocale('pl', pl);
registerLocale('fr', fr);
registerLocale('pt', pt);
registerLocale('it', it);
registerLocale('de', de);
registerLocale('sk', sk);


const AddNewLive = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const {getLangText} = useLang();
    const {lang} = useSelector((state) => state.i18n);

    const {visibility, data, isLoading} = useSelector((state) => state.lives.newLiveModal)
    const addNewLive = () => dispatch(onSubmit(history))

    useEnterSubmitForm(addNewLive, data);

    return (
        <Modal
            dialogClassName="default-modal full-height-mobile"
            show={visibility}
            onHide={() => dispatch(addNewLiveModalToggleVisibility(false))}
        >
            <Modal.Header>
                <Modal.Title>
                    {getLangText({
                        key: 'liveAddNewModalTitle',
                        data: [data.id ? getLangText('editButton') : getLangText('addButton')]
                    })}
                </Modal.Title>
                <button
                    onClick={() => dispatch(addNewLiveModalToggleVisibility(false))}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group calendar-input">
                            <div className="control-label">
                                {getLangText('liveAddNewModalDateLabel')}
                            </div>
                            <DatePicker
                                className="form-control"
                                selected={data.date}
                                onChange={(value) => dispatch(updateFieldInNewLiveModalForm('date', value))}
                                locale={prepareRealLang(lang)}
                                dateFormat="dd.MM.y"
                            />
                            <i className="icon-calendar"/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <div className="control-label">
                                {getLangText('liveAddNewModalHourLabel')}
                            </div>
                            <TimePicker
                                clearIcon={false}
                                className="form-control flex items-center"
                                disableClock={true}
                                locale={prepareRealLang(lang)}
                                onChange={(value) => dispatch(updateFieldInNewLiveModalForm('time', value))}
                                value={data.time}
                                format="HH:mm"
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <div className="control-label"
                                 dangerouslySetInnerHTML={{__html: getLangText('liveAddNewModalNameLabel')}}/>
                            <input
                                onChange={(e) => dispatch(updateFieldInNewLiveModalForm('title', e.target.value))}
                                type="text"
                                value={data.title}
                                className="form-control"
                                name="name"
                            />
                        </div>
                    </div>
                </div>
                {data.id &&
                    <div className="form-group">
                        <div className="control-label"
                             dangerouslySetInnerHTML={{__html: getLangText('liveAddNewModalLinkLabel')}}/>
                        <input
                            onChange={(e) => dispatch(updateFieldInNewLiveModalForm('link', e.target.value))}
                            type="text"
                            value={data.link}
                            className="form-control"
                            name="link"
                        />
                    </div>
                }
                <CheckboxField
                    label={getLangText('liveAddNewModalStorageProductsLabel')}
                    subLabel={getLangText('liveAddNewModalStorageProductsSubLabel')}
                    name="storage_products"
                    setValue={(name, value) => dispatch(updateFieldInNewLiveModalForm(name, value))}
                    value={data.storage_products}
                />
                {data.id &&
                    <CheckboxField
                        label={getLangText('createSingleOrderLabel')}
                        subLabel={getLangText('createSingleOrderSubLabel')}
                        name="single_order_enabled"
                        setValue={(name, value) => dispatch(updateFieldInNewLiveModalForm(name, value))}
                        value={data.single_order_enabled}
                    />
                }
            </Modal.Body>
            <Modal.Footer>
                <button
                    onClick={() => dispatch(addNewLiveModalToggleVisibility(false))}
                    type="button"
                    className="button border-button"
                >
                    {getLangText('cancelButton')}
                </button>
                <button
                    onClick={addNewLive}
                    type="button"
                    className="button primary small-size"
                >
                    {isLoading && <Loader/>}
                    {getLangText({
                        key: 'liveAddNewModalTitle',
                        data: [data.id ? getLangText('editButton') : getLangText('addButton')]
                    })}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddNewLive;



