import React from 'react';
import OrdersBoxes from "./components/OrdersBoxes";
import OrdersChart from "./components/OrdersChart";
import DoMoreBoxes from "./components/DoMoreBoxes";
import BlogPost from "./components/BlogPost";
import AfterTrialMainVideo from "./components/AfterTrialMainVideo";
import SelmoAppLanding from "./components/SelmoAppLanding";

const AfterTrialView = ({data}) => {

    return (
        <div className="fadeInProgressAnimation">
            {/*<div className="bg-[#fff] rounded-[5px] shadow-default-small p-6 sm:p-12">*/}
                {/*<SelmoAppLanding/>*/}
            {/*</div>*/}
            <div className="max-w-[600px] sm:p-0 py-6 px-3 mx-auto space-y-6">
                <BlogPost
                    item={data?.latestNews}
                />
                <OrdersBoxes data={data?.orderStatusSummary}/>
                <OrdersChart data={data?.ordersCounter}/>
                <AfterTrialMainVideo/>
                <DoMoreBoxes/>
            </div>
        </div>
    );
};

export default AfterTrialView;
