import createReducer from "../../../../shared/helpers/createReducer";
import FormReducer, {getReduceMap} from "../../../../../../../../src_shared/form/FormReducer";

export const getStateRoot = (state) => state.admin.sales.messages.tabs;

export const getInitState = () => FormReducer.getInitState()
export const prefix = 'ADMIN_SALES_MESSAGES_DASHBOARD_TABS_';

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();
