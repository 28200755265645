import React, {useState} from 'react';
import Field from "../../../../../src_shared/components/form/field/Field";
import useFormLocal from "../../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../../RestServices";
import Model from "../../../../../src_shared/modules/model/Model";
import Loader from "../../../../app/shared/Loader";
import Modal from "react-bootstrap/Modal";

const ActivateTransferModal = ({show, hide}) => {
    const [isSuccess, setSuccess] = useState(false);

    const [{onSave, isWaiting, getFieldProps}] = useFormLocal({
        rest: services.API_ACTIVATE_AUTOMATIC_TRANSFER,
        model: new Model(),
        afterSubmit: () => setSuccess(true),
        initialAutoLoad: false,
    });

    return (
        <Modal
            dialogClassName={`default-modal small-modal w-450`}
            show={show}
            onHide={hide}
        >
            <Modal.Body>
                <button
                    onClick={hide}
                    type="button"
                    className="absolute top-5 right-5 w-[40px] h-[40px] bg-[#f9f9f9] rounded-full flex justify-center items-center"
                >
                    <i className="icon-cross text-desc text-[8px]"/>
                </button>
                <div className="pb-2 pt-2">
                    {isSuccess ?
                        <div className="flex items-center justify-center flex-col py-12">
                            <img
                                className="w-[40px] min-w-[40px]"
                                src="/assets/images/svg/circle-check.svg"
                                alt="check"
                            />
                            <div className="font-bold text-lg mt-3 text-center">Dziękujemy!</div>
                            <div className="font-medium text-desc text-center">
                                Wkrótce się odezwiemy
                            </div>
                        </div> :
                        <div className="p-2">
                            <form onSubmit={onSave}>
                                <div className="pr-[45px]">
                                    <div className="font-bold text-lg">Automatyczne przelewy standardowe</div>
                                    <div className="text-desc font-medium">
                                        Wpisz swój numer telefonu, a nasz specjalista skontaktuje się i pomoże z
                                        aktywacją
                                    </div>
                                </div>
                                <div className="flex items-end mt-3 mb-3">
                                    <Field
                                        className="grow mb-0 hidden-arrows"
                                        {...getFieldProps('phone')}
                                        label="Numer telefonu"
                                        placeholder="Wpisz nr telefonu"
                                        type="number"
                                        extraProps={{
                                            required: true,
                                        }}
                                    />
                                    <button
                                        type="submit"
                                        style={{paddingTop: 14, paddingBottom: 14}}
                                        className={`button primary ml-2 ${isWaiting ? 'loading-button' : ''}`}
                                    >
                                        {isWaiting && <Loader isLoading/>}
                                        Wyślij
                                    </button>
                                </div>
                            </form>
                        </div>
                    }
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ActivateTransferModal;
