import Model from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

class ExpendituresModel extends Model {
	getModel() {
		return {
			quantity: 1,
			reason: null,
			note: '',
		};
	}

	getValidators() {
		return {
			quantity: ValidationError.notEmpty,
			reason: ValidationError.notEmpty,
		};
	}
	buildDTO(data) {
		return {
			quantity: data.quantity,
			reason: data.reason,
			note: data.note,
			product_id: data.product_id,
			product_size_id: data.product_size_id,
		}
	}
}

export default new ExpendituresModel();
