import React, {useState} from 'react';
import useLang from "../../../../../../../../src_shared/hooks/useLang";
import Field from "../../../../../../../../src_shared/components/form/field/Field";
import ProductModalForm, {PRODUCT_MODAL_IN_AUTOMATION_PLACE_TYPE} from "../../../../../../products/modalForm/ModalForm";
import AssignProductsModal from "./components/assignProduct/AssignProductModal";
import SelectedProduct from "./components/SelectedProduct";
import ProductError from "./components/ProductError";
import Ckeditor from "../../../../../../../../src_shared/components/form/ckeditor/Ckeditor";
import TextArea from "../../../../../../../../src_shared/components/form/textarea/TextArea";

export const BUTTON_DESTINATION_PRODUCT = 'BUTTON_DESTINATION_PRODUCT';
export const BUTTON_DESTINATION_LINK = 'BUTTON_DESTINATION_LINK';
export const BUTTON_DESTINATION_NEWSLETTER = 'BUTTON_DESTINATION_NEWSLETTER';

const ranges = [
    {id: BUTTON_DESTINATION_PRODUCT, label: 'productToSellLabel', description: 'selectProductLabel',},
    {id: BUTTON_DESTINATION_LINK, label: 'randomLink', description: 'insertRandomLink'},
    {id: BUTTON_DESTINATION_NEWSLETTER, label: 'saveToNewsletter', description: 'creatingMailingList'},
]
const ButtonDestination = ({
                               values,
                               setValue,
                               updateValues,
                               isValidationActive,
                               setIsValidationActive,
                               getFieldProps
                           }) => {
    const {getLangText} = useLang();
    const [isModalVisible, setModalVisible] = useState(false);
    const [isNewProductModalVisible, setNewProductModalVisible] = useState(false);

    const checkLinkInputVisibility = () => values.buttonDestination === BUTTON_DESTINATION_LINK;

    const checkSelectedItemVisibility = () => values.selectedProduct && values.buttonDestination === BUTTON_DESTINATION_PRODUCT
    const onChange = (item) => {
        if (item.id === BUTTON_DESTINATION_PRODUCT) {
            onClick(item);
            return;
        }
        setIsValidationActive(false);
        setValue('buttonDestination', item.id)
    }

    const onClick = (item) => {
        if (item.id === BUTTON_DESTINATION_PRODUCT) {
            setModalVisible(true);
        }
    }

    const addNewProduct = () => {
        setNewProductModalVisible(true)
        setModalVisible(false);
    }

    const afterNewProductAdded = () => {
        setModalVisible(true)
        setNewProductModalVisible(false)
    }

    const isNotSelectedProductError = isValidationActive && !values.selectedProduct?.id && values.buttonDestination === BUTTON_DESTINATION_PRODUCT;

    return (
        <>
            <div className="form-label">
                {getLangText('whereShouldTheButtonGoLabel')}
            </div>
            {isNotSelectedProductError &&
                <ProductError/>
            }
            <div className="data-boxes-wrapper separated-boxes light-version small-padd mt-2">
                {ranges.map((item) => (
                    <div key={item.id}>
                        <div
                            className={`content ${values.buttonDestination === item.id ? 'active' : ''} ${(isNotSelectedProductError && item.id === BUTTON_DESTINATION_PRODUCT) ? 'error' : ''}`}>
                            <div className={`content__top-part ${item.id === BUTTON_DESTINATION_PRODUCT ? 'pb-default' : ''}`}>
                                <input
                                    id={item.id}
                                    checked={values.buttonDestination === item.id}
                                    onClick={() => onClick(item)}
                                    onChange={() => onChange(item)}
                                    type="radio"
                                    name="buttonDestination"
                                    required
                                />
                                <span
                                    className={`checkmark ${checkLinkInputVisibility(item.id) ? 'self-start relative top-[7px]' : ''}`}/>
                                <div className="flex-grow-1">
                                    <div className="label">
                                        {getLangText(item.label)}
                                    </div>
                                    <div className="data">
                                        {getLangText(item.description)}
                                    </div>
                                </div>
                                {item.id === BUTTON_DESTINATION_PRODUCT &&
                                    <div className="button border-button sm:w-auto sm:mt-0 mt-2.5 sm:static absolute left-5 bottom-3 right-5">
                                        {getLangText('selectProductLabel')}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {checkLinkInputVisibility() &&
                <Field
                    {...getFieldProps('button_url')}
                    label={getLangText('enterLinkLabel')}
                    className="mt-3"
                    placeholder={getLangText('enterLinkLabel')}
                    extraProps={{
                        required: true,
                        // autoFocus: true
                    }}
                />
            }
            {values.buttonDestination === BUTTON_DESTINATION_NEWSLETTER &&
                <div className="mt-3">
                    <Field
                        {...getFieldProps('newsletter_title')}
                        label={getLangText('headerInRegistrationPage')}
                        extraProps={{
                            required: true,
                            // autoFocus: true
                        }}
                    />
                    <TextArea
                        {...getFieldProps('newsletter_description')}
                        setValue={setValue}
                        label={getLangText('input_label_package_type_description')}
                        placeholder={getLangText('textNoteLabel')}
                        optional
                    />
                </div>
            }

            {checkSelectedItemVisibility() &&
                <SelectedProduct
                    showModal={() => setModalVisible(true)}
                    values={values}/>
            }
            {isNewProductModalVisible &&
                <ProductModalForm
                    modalVisible={isNewProductModalVisible}
                    hideModal={afterNewProductAdded}
                    type={PRODUCT_MODAL_IN_AUTOMATION_PLACE_TYPE}
                    afterSubmit={afterNewProductAdded}
                />
            }
            {isModalVisible &&
                <AssignProductsModal
                    modalVisible={isModalVisible}
                    hideModal={() => setModalVisible(false)}
                    updateValues={updateValues}
                    addNewProductModal={addNewProduct}
                    values={values}
                />
            }
        </>
    );
};

export default ButtonDestination;
