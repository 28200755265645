export const initState = {
	waiting: false,
};

export const reduceMap = {
	START_WAITING: state => ({...state, waiting: true}),
	STOP_WAITING: state => ({...state, waiting: false}),
};

export default {
	initState,
	reduceMap,
};
