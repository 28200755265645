import React from 'react';
import VideoPreview from "../../../../src_shared/components/ui/photoPreview/VideoPreview";
import {DASHBOARD_MAIN_AFTER_TRIAL} from "../../shared/enums/TutorialVideoStatsTypes";
import usePhotoPreview from "../../../../src_shared/components/ui/photoPreview/usePhotoPreview";
import useLang from "../../../../src_shared/hooks/useLang";
import {useSelector} from "react-redux";
import TutorialsVideo from "../../shared/enums/TutorialsVideo";

const AfterTrialMainVideo = () => {
    const {showPreviewModal, hidePreviewModal, openPreviewModal} = usePhotoPreview();
    const {getLangText} = useLang();
    const {lang} = useSelector((state) => state.i18n);

    const getVideoSrc = () => {
        const matched = TutorialsVideo.find((i) => i.id === DASHBOARD_MAIN_AFTER_TRIAL);

        return matched[`src_${lang}`] || null;
    }

    if (!getVideoSrc()) {
        return null;
    }


    return (
        <>
            <div
                onClick={openPreviewModal}
                className="dashboard-card p-[14px] sm:flex items-center cursor-pointer group">
                <div
                    onClick={openPreviewModal}
                    className={`relative h-[80px] cursor-pointer rounded-md overflow-hidden after:content-[''] after:bg-[rgba(0,0,0,0.3)] after:absolute after:top-0 after:left-0 after:right-0 after:bottom-0 after:w-full after:h-full`}>
                    <img
                        className="h-full w-auto"
                        src={`/assets/images/multiLanguage/${lang}/mainVideo/howToUse.webp`}
                        alt="preview"
                    />
                    <img
                        className="pointer-events-none absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 sm:w-[18px] w-[14px] z-10 group-hover:scale-[115%] transition-all"
                        src="/assets/images/svg/play.svg"
                        alt="play"
                    />
                </div>
                <div className="ml-3">
                    <div className="text-lg font-bold">
                        {getLangText('seeTheTutorialVideoLabel')}
                    </div>
                    <div className="text-[#6B7280]">
                        {getLangText('learnMoreSelmoLabel')}
                    </div>
                </div>
            </div>
            <VideoPreview
                hide={hidePreviewModal}
                show={showPreviewModal}
                src={getVideoSrc()}
                videoStatsName={DASHBOARD_MAIN_AFTER_TRIAL}
            />
        </>
    );
};

export default AfterTrialMainVideo;