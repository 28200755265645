import Model from "../../../src_shared/modules/model/Model";

class CreateBatchOrdersModel extends Model {
    getModel() {
        return {
            summary: '0',
            cart: '0',
        };
    }
}

export default new CreateBatchOrdersModel();
