import {prefix} from "./dataReducer";
import {getStateRoot} from "./dataReducer";
import FormActions from "../../../../../src_shared/form/FormActions";
import {RouterPaths} from "../../../../routes/RouterPath";
import EditClientFormActions from "../form/FormActions";
import ClientInfoModel from "../../../../../modules/models/clients/info/ClientInfoModel";

export class InfoClientFormActions extends FormActions {
	afterDeleteSubmit(history) {
		return (dispatch) => {
			dispatch(EditClientFormActions.showClientModal(false))
			history.push(RouterPaths.ClientsList);
		}
	}
}

export const getInstance = () => new InfoClientFormActions({
	prefix,
	getStateRoot,
	restService: 'api/clients',
	model: ClientInfoModel,
});

export default getInstance();

