import Composition from "../../../shared/helpers/Composition";
import createReducer from "../../../shared/helpers/createReducer";
import FormReducer from "../../../../../src_shared/form/FormReducer";

export const getStateRoot = (state) => state.salesPost.formStats;
export const prefix = 'SALES_POST_FORM_STATS_';

export const getInitState = () => FormReducer.getInitState()

const getReduceMap = () => new Composition(
	FormReducer.getReduceMap(),
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();
