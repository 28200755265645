import React, {useEffect, useMemo, useState} from 'react';
import {services} from "../../../../../../../RestServices";
import {useParams} from "react-router-dom";
import useFetchData from "../../../../../../../../src_shared/hooks/useFetchData";
import {Scanner} from "@yudiel/react-qr-scanner";
import api from "../../../../../../../../services/axios/axios";
import useLang from "../../../../../../../../src_shared/hooks/useLang";
import Products from "./components/Products";
import Alert from "./components/Alert";
import {showAlert as showGlobalAlert} from "../../../../../../shared/components/alert/AlertActions";
import {useDispatch, useSelector} from "react-redux";
import useSubmitData from "../../../../../../../../src_shared/hooks/useSubmitData";
import OrderInfo from "../OrderInfo";
import Loader from "../../../../../../shared/Loader";
import QrScanner from "../../../../../../shared/components/QRScanner/QRScanner";
import {AMPARO_FASHION_SHOP_ID} from "../../../../../../../../src_shared/enums/TrustedShopsIds";

const Completing = ({values, fetchData, isLoadingOrder}) => {
        const {id} = useParams();
        const {getLangText} = useLang();
        const dispatch = useDispatch();
        const {userData} = useSelector((state) => state.session);
        const [{isLoading, data: products, setData: setProducts, fetchData: fetchProducts}] = useFetchData({
            rest: `${services.API_WAREHOUSE_ORDER_PRODUCTS}/${id}`,
            initialData: [],
        });
        const [waitingForScan, setWaitingForScan] = useState(false);

        const [showAlert, setShowAlert] = useState({
            visible: false,
            data: {},
        })

        const allProductsLength = useMemo(() => {
            return products.reduce((prev, curr) => +prev + +curr.quantity, 0);
        }, [products]);

        const productsScannedLength = products.reduce((prev, curr) => +prev + +curr.scanned_qty, 0);

        const allProductsChecked = () => !!products.filter((product) => product.id)?.length && products.filter((product) => product.id).every((item) => +item.quantity === +item.scanned_qty)

        const [{onSave, isWaiting}] = useSubmitData(
            {
                rest: `${services.API_WAREHOUSE_ORDER}/${id}`,
                afterSubmit: () => {
                    fetchData();
                    dispatch(showGlobalAlert('yuhuLabel', 'successfullyAssignedPlaceLabel'))
                }
            });

        const scanProduct = async (text) => {
            try {
                JSON.parse(text);
                const product = JSON.parse(text);
                setWaitingForScan(true);
                if (product.shelf_id) {
                    try {
                        await onSave(null, {shelf_id: product.shelf_id});
                        dispatch(showGlobalAlert('yuhuLabel', 'successfullyAssignedPlaceLabel'))
                    } catch (e) {

                    }
                    return;
                }

                const isProductExist = (i) => +i.product_id === +product.product_id && +i?.product_size_id === +product.product_size_id;
                const activeProduct = products.find(isProductExist);

                try {
                    const {data} = await api.post(`/${services.API_WAREHOUSE_SCAN_PRODUCT}/${product.product_id}`, {
                        id: activeProduct?.id,
                        product_size_id: product.product_size_id,
                        order_id: id,
                    });

                    setProducts(data.products)

                    if (data.all_products_packed) {
                        setShowAlert({
                            visible: true,
                            data: {
                                id: 'SUCCESS',
                                title: getLangText('yuhuLabel'),
                                desc: getLangText('allProductsAreScannedLabel'),
                                icon: 'circle-check'
                            }
                        })
                    }

                    setShowAlert({
                        visible: true,
                        data: {
                            id: 'SUCCESS',
                            title: getLangText('yuhuLabel'),
                            desc: getLangText({key: 'productHasBeenScannedLabel', data: [activeProduct.product_name]}),
                            icon: 'circle-check'
                        }
                    })

                    // openPanel(getPreparedActiveProduct(activeProduct))
                } catch (e) {
                    if (e?.response?.data?.message === 'ALREADY_SCANNED') {
                        setShowAlert({
                            visible: true,
                            data: {
                                id: 'FAIL',
                                title: getLangText('sthWentWrongWarning'),
                                desc: getLangText({
                                    key: 'productIsAlreadyScannedLabel',
                                    data: [activeProduct.product_name]
                                }),
                                icon: 'circle-cross'
                            }
                        })
                        return
                    }
                    if (e?.response?.data?.message === 'PRODUCT_NOT_FOUND') {
                        setShowAlert({
                            visible: true,
                            data: {
                                id: 'FAIL',
                                title: getLangText('sthWentWrongWarning'),
                                desc: getLangText('productIsNotAssignedToOrderLabel'),
                                icon: 'circle-cross'
                            }
                        })
                    }
                } finally {
                    setWaitingForScan(false);
                }
            } catch (e) {

            } finally {
                setWaitingForScan(false);
            }
        };

        return (
            <>
                <div className="-mx-6 sticky top-0 bg-[white] z-[1]">
                    <div className="completing-scanner padd-border relative">
                        {showAlert.visible &&
                            <div
                                className="absolute top-0 left-0 right-0 mt-[42px]"
                                style={{
                                    height: '40vh'
                                }}>
                                <Alert
                                    setShowAlert={setShowAlert}
                                    showAlert={showAlert}
                                />
                            </div>
                        }
                        <QrScanner
                            waitingForScan={waitingForScan}
                            height="40vh"
                            handleResult={scanProduct}
                            products={products}
                            checkProducts={true}
                            defaultActiveScanner={+userData.id === AMPARO_FASHION_SHOP_ID ? 2 : 1}
                        />
                    </div>
                    {allProductsChecked() ?
                        <div
                            className="sticky top-0 text-sm flex items-center font-bold py-2 px-6 bg-[#EFFEF4] border border-[#EFFEF4] text-[#156534]">
                            <img
                                className="w-[18px] min-w-[18px] mr-1"
                                src="/assets/images/svg/circle-check.svg"
                                alt="check"
                            />
                            {getLangText('allProductsPackedLabel')}
                        </div> :
                        <div
                            className="sticky top-0 text-sm flex items-center font-medium py-2 px-6 bg-[#F0F3FE] border border-[transparent] text-[#3C61EA]">
                            {getLangText('scannedLabel')}:&nbsp;
                            <strong>{productsScannedLength}/{allProductsLength}</strong>
                        </div>
                    }
                </div>
                {isLoadingOrder ?
                    <div className="min-h-[130px] flex items-center justify-center">
                        <Loader isLoading={true} className="small-loader static-loader"/>
                    </div> :
                    <OrderInfo
                        fetchProducts={fetchProducts}
                        fetchData={fetchData}
                        values={values}
                    />
                }
                <div className="space-y-1 py-3">
                    {isLoading ?
                        <div className="animate-pulse space-y-1">
                            <div className="h-[54px] bg-gray-200 rounded-xl dark:bg-gray-700 w-full"></div>
                            <div className="h-[54px] bg-gray-200 rounded-xl dark:bg-gray-700 w-full"></div>
                        </div> :
                        <Products
                            fetchData={fetchProducts}
                            products={products}
                            currency={values?.currency?.text}
                        />
                    }
                </div>
            </>
        );
    }
;

export default Completing;
