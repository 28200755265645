import React, {useRef, useState} from 'react';
import useLang from "../../../../../src_shared/hooks/useLang";

const UploadPhoto = ({setFileValue, imgUrl, setImgUrl}) => {
    const [errorSizePhoto, setErrorSizePhoto] = useState(false)
    const imgUploadRef = useRef();
    const {getLangText} = useLang();

    const onChange = (e) => {
        setErrorSizePhoto(false)
        // if (e.target.files[0].size > 2097152) {
        //     setFileValue(null);
        //     return setErrorSizePhoto(true);
        // }
        setFileValue(e.target.files[0]);
        if (e.target.files[0].type === 'application/pdf') {
            setImgUrl(e.target.files[0])
        } else {
            setImgUrl(URL.createObjectURL(e.target.files[0]));
        }
    }

    const removePhoto = () => {
        setImgUrl(null);
        setFileValue(null);
    }

    return (
        <div className={``}>
            <div className="w-[22px] h-[22px] absolute left-[13px] bottom-[14px] cursor-pointer overflow-hidden">
                <label
                    htmlFor="filePhoto"
                    className="icon-btn add-photo tooltip-parent"
                >
                    <i className="icon-add"/>
                    <input
                        style={{display: 'none'}}
                        key={imgUrl}
                        ref={imgUploadRef}
                        onChange={onChange}
                        id="filePhoto"
                        className="match-file-input absolute top-0 left-0 w-[20px] h-[20px] right-0 bottom-0 opacity-0 z-[1]"
                        name="filePhoto"
                        type="file"
                    />
                </label>
            </div>
            {errorSizePhoto &&
                <div className="error-tooltip">
                    {getLangText('tooBigPhotoSizeLabel')}
                    <button
                        onClick={() => setErrorSizePhoto(null)}
                        type="button"
                    >
                        <i className="icon-cross"/>
                    </button>
                </div>
            }
            {imgUrl &&
                <div className="p-[13px] pb-0">
                    <div className="uploaded-photo relative inline-flex items-center">
                        <button
                            className="absolute top-[3px] right-[3px] rounded-full text-[#fff] bg-[#9ca3af] text-[8px] w-[25px] h-[25px] flex items-center justify-center"
                            type="button"
                            onClick={removePhoto}
                        >
                            <i className="icon-cross"/>
                        </button>
                        {imgUrl.type === 'application/pdf' ?
                            <div className="flex items-center text-xs font-medium bg-[#f9f9f9] rounded-[5px] p-2 mr-[30px]">
                                <i className="icon-summary text-[12px] mr-1"/>
                                {imgUrl.name}
                            </div> :
                            <img
                                className="w-[150px] rounded-[5px]"
                                src={imgUrl} alt=""/>
                        }
                    </div>
                </div>
            }
        </div>
    );
};

export default UploadPhoto;
