import React, {useEffect, useState} from 'react';
import useLang from "../../../../../src_shared/hooks/useLang";
import NewClientPromoModal from "./NewClinetPromoModal";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../routes/RouterPath";
import {useCountdown} from "../../../../../src_shared/hooks/useCountDown";
import moment from "moment";
import {useSelector} from "react-redux";
import {convertToUserTimeZone} from "../../helpers/dateHelpers";

const NewClientPromoBar = ({className, isNotVisible}) => {
    const {getLangText} = useLang();
    const {userData: {user_info, tutorial_done_date}} = useSelector((state) => state.session);

    const {minutes, seconds} = useCountdown(convertToUserTimeZone(tutorial_done_date).add(1, 'hours').add(1, 'minutes'));
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (!user_info.status && tutorial_done_date && minutes === 59 && seconds === 59) {
            setShowModal(true)
        }
    }, [minutes, seconds])

    if (isNotVisible) {
        return null;
    }

    return (
        <>
            <Link
                to={RouterPaths.Payments}
                className={`selmo-trial-info w-full ${className}`}
            >
                <div className="flex items-center">
                    <div
                        className="min-w-[20px] text-center text-[#fff] text-xs p-0.5 rounded-sm bg-[rgba(255,255,255,0.2)] mx-0.5">
                        {minutes}
                    </div>
                    :
                    <div
                        className="min-w-[20px] text-center text-[#fff] text-xs p-0.5 rounded-sm bg-[rgba(255,255,255,0.2)] mx-0.5">
                        {seconds}
                    </div>
                </div>
                <div className="ml-0.5">
                    {getLangText('toEndPromoLabel')}
                    <button className="ml-1 text-[#3b61ea]">{getLangText('paymentsSelectPlanLabel')}</button>
                </div>
            </Link>
            {showModal &&
                <NewClientPromoModal
                    show={showModal}
                    hide={() => setShowModal(false)}
                    minutes={minutes}
                    seconds={seconds}
                />
            }
        </>
    )
};

export default NewClientPromoBar;
