import createReducer from "../../shared/helpers/createReducer";
import Composition from "../../shared/helpers/Composition";
import FormReducer from "../../../../../../src_shared/form/FormReducer";
import ClientsModel from "../../../../modules/models/ClientsModel";

export const getStateRoot = (state) => state.admin.clients.form;
export const prefix = 'ADMIN_CLIENTS_FORM_';

const getInitState = () => FormReducer.getInitState(ClientsModel.getModel());

const getReduceMap = () => new Composition(
	FormReducer.getReduceMap(),
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

