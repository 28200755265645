import React from 'react';
import CheckboxField from "../../../../../../src_shared/components/form/checkboxField/CheckboxField";
import GenerateModal from "./generateLabelsModal/GenerateModal";
import {useDispatch, useSelector} from "react-redux";
import ListActions from "../../ListActions";
import {createSelector} from "reselect";
import {getStateRoot} from "../../listReducer";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {services} from "../../../../../RestServices";
import GenerateInvoiceModal from "./generateInvoiceModal/GenerateInvoiceModal";
import {FAKTUROWNIA_PERMISSION} from "../../../../shared/enums/SelmoPermissions";
import PermissionsWrapper from "../../../../../routes/PermissionsWrapper";
import DropdownWithPortal from "../../../../../../src_shared/components/dropdownPortal/DropdownWithPortal";
import StatusModal from "./statusModal/StatusModal";
import useLang from "../../../../../../src_shared/hooks/useLang";
import {SHOP_ORIGIN_FR, SHOP_ORIGIN_PL, SHOP_ORIGIN_SK} from "../../../../shared/enums/ShopOrigins";
import ExportModal from "../../../../../../src_shared/components/exportModal/ExportModal";
import {getGETParamsUrl} from "../../../../../../src_shared/list/ListActions";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../../routes/RouterPath";
import PaxyModalForm from "../../../details/paxyLabel/modal/ModalForm";
import PaxyBatchLabelModel from "../../../../../../modules/models/orders/PaxyBatchLabelModel";
import DropdownItem from "./DropdownItem";
import GenerateSkLabelModal from "./skBatchModalLabel/ModalForm";
import PrintLabelBatchModal from "../../../../shared/printLabelBatchModal/PrintLabelBatchModal";
import SendMessageModal from "./sendMessageModal/SendMessageModal";
import ProImageBox from "../../../../shared/components/ProImageBox/ProImageBox";
import useRedirectToSelmoPro from "../../../../../../src_shared/hooks/useRedirectToSelmoPro";

const GENERATE_LABEL_MODAL = 'GENERATE_LABEL_MODAL';
const GENERATE_PAXY_LABEL_MODAL = 'GENERATE_PAXY_LABEL_MODAL';
const GENERATE_SK_LABEL_MODAL = 'GENERATE_SK_LABEL_MODAL';
const GENERATE_INVOICE_MODAL = 'GENERATE_INVOICE_MODAL';
const PRINT_LABEL_MODAL = 'PRINT_LABEL_MODAL';
const PRINT_LABEL_PUSHER_MODAL = 'PRINT_LABEL_PUSHER_MODAL';
const PRINT_INVOICE_MODAL = 'PRINT_INVOICE_MODAL';
const STATUS_MODAL = 'STATUS_MODAL';
const SEND_MESSAGE_MODAL = 'SEND_MESSAGE_MODAL';

const SelectedRow = ({isSelected, selectedItems, selectAllItems, checkIfSomeExist, colSpan}) => {
    const dispatch = useDispatch();
    const setModalOpen = (modalType) => dispatch(ListActions.setModalVisible(modalType));
    const {getLangText} = useLang();
    const {userData} = useSelector((state) => state.session);
    const {checkSelmoPro} = useRedirectToSelmoPro()
    const {
        modalVisibleType,
        waitingForDocument,
        successGenerated,
        generatedOrdersCount
    } = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));
    const maxSelected = 25;
    const isMoreThanMax = selectedItems.length > maxSelected;

    const tooltipText = getLangText({key: 'youCanSelectMaxOrders', data: [maxSelected]});

    const DropdownPortal = () => {
        if (userData.origin === SHOP_ORIGIN_FR) {
            return (
                <DropdownWithPortal
                    ButtonToggle={() => <span>{getLangText('selectActionLabel')}</span>}
                    icon="icon-arrows"
                    buttonClassName="button border-button"
                    className="right"
                >
                    <button
                        type="button"
                        className="custom-option"
                        onClick={() => setModalOpen(STATUS_MODAL)}
                    >
                        {getLangText('changeStatusLabel')}
                    </button>
                    <PermissionsWrapper
                        NoPermissionComponent={() => (
                            <Link
                                className="custom-option"
                                to={RouterPaths.SettingsIntegrationFakturownia}
                            >
                                {getLangText('generateInvoiceLabel')}
                            </Link>
                        )}
                        allowedPermissions={[FAKTUROWNIA_PERMISSION]}
                    >
                        {isMoreThanMax ?
                            <OverlayTrigger
                                placement='top'
                                overlay={
                                    <Tooltip id="generate-invoice-tooltip">
                                        <span dangerouslySetInnerHTML={{__html: tooltipText}}/>
                                    </Tooltip>
                                }
                            >
                                <button
                                    type="button"
                                    className="custom-option disabled"
                                >
                                    {getLangText('generateInvoiceLabel')}
                                </button>
                            </OverlayTrigger> :
                            <button
                                type="button"
                                className="custom-option"
                                onClick={() => setModalOpen(GENERATE_INVOICE_MODAL)}
                                disabled={isMoreThanMax}
                            >
                                {getLangText('generateInvoiceLabel')}
                            </button>
                        }
                    </PermissionsWrapper>
                    <hr className="mt-1 mb-1"/>
                    <PermissionsWrapper
                        NoPermissionComponent={() => (
                            <Link
                                className="custom-option"
                                to={RouterPaths.SettingsIntegrationFakturownia}
                            >
                                {getLangText('printInvoiceLabel')}
                            </Link>
                        )}
                        allowedPermissions={[FAKTUROWNIA_PERMISSION]}
                    >
                        <button
                            type="button"
                            className="custom-option"
                            onClick={() => setModalOpen(PRINT_INVOICE_MODAL)}
                        >
                            {getLangText('printInvoiceLabel')}
                        </button>
                    </PermissionsWrapper>
                </DropdownWithPortal>
            )
        }
        if (userData.origin === SHOP_ORIGIN_SK) {
            return (
                <DropdownWithPortal
                    ButtonToggle={() => <span>{getLangText('selectActionLabel')}</span>}
                    icon="icon-arrows"
                    buttonClassName="button border-button"
                    className="right"
                >
                    <button
                        type="button"
                        className="custom-option"
                        onClick={() => setModalOpen(STATUS_MODAL)}
                    >
                        {getLangText('changeStatusLabel')}
                    </button>
                    {(+userData.id === 3917 || +userData.id === 4867) ?
                        <DropdownItem
                            isMoreThanMax={isMoreThanMax}
                            type={GENERATE_PAXY_LABEL_MODAL}
                            setModalOpen={setModalOpen}
                            buttonTitle="generateLabelLabel"
                        /> :
                        <DropdownItem
                            isMoreThanMax={isMoreThanMax}
                            type={GENERATE_SK_LABEL_MODAL}
                            setModalOpen={setModalOpen}
                            buttonTitle="generateLabelsLabel"
                        />
                    }
                    <PermissionsWrapper
                        NoPermissionComponent={() => (
                            <Link
                                className="custom-option"
                                to={RouterPaths.SettingsIntegrationFakturownia}
                            >
                                {getLangText('generateInvoiceLabel')}
                            </Link>
                        )}
                        allowedPermissions={[FAKTUROWNIA_PERMISSION]}
                    >
                        <DropdownItem
                            isMoreThanMax={isMoreThanMax}
                            type={GENERATE_INVOICE_MODAL}
                            setModalOpen={setModalOpen}
                            buttonTitle="generateInvoiceLabel"
                        />
                    </PermissionsWrapper>
                    <hr className="mt-1 mb-1"/>
                    {(+userData.id === 3917 || +userData.id === 4867) ?
                        <button
                            type="button"
                            className="custom-option"
                            onClick={() => setModalOpen(PRINT_LABEL_MODAL)}
                        >
                            {getLangText('printLabelLabel')}
                        </button> :
                        <DropdownItem
                            isMoreThanMax={isMoreThanMax}
                            type={PRINT_LABEL_PUSHER_MODAL}
                            setModalOpen={setModalOpen}
                            buttonTitle="printLabelsLabel"
                        />
                    }
                    <PermissionsWrapper
                        NoPermissionComponent={() => (
                            <Link
                                className="custom-option"
                                to={RouterPaths.SettingsIntegrationFakturownia}
                            >
                                {getLangText('printInvoiceLabel')}
                            </Link>
                        )}
                        allowedPermissions={[FAKTUROWNIA_PERMISSION]}
                    >
                        <button
                            type="button"
                            className="custom-option"
                            onClick={() => setModalOpen(PRINT_INVOICE_MODAL)}
                        >
                            {getLangText('printInvoiceLabel')}
                        </button>
                    </PermissionsWrapper>
                </DropdownWithPortal>
            )
        }
        if (userData.origin !== SHOP_ORIGIN_PL) {
            return (
                <DropdownWithPortal
                    ButtonToggle={() => <span>{getLangText('selectActionLabel')}</span>}
                    icon="icon-arrows"
                    buttonClassName="button border-button"
                    className="right"
                >
                    <button
                        type="button"
                        className="custom-option"
                        onClick={() => setModalOpen(STATUS_MODAL)}
                    >
                        {getLangText('changeStatusLabel')}
                    </button>
                </DropdownWithPortal>
            )
        }
        return (
            <DropdownWithPortal
                ButtonToggle={() => <span>{getLangText('selectActionLabel')}</span>}
                icon="icon-arrows"
                buttonClassName="button border-button"
                className="right"
            >
                {isMoreThanMax ?
                    <OverlayTrigger
                        placement='top'
                        overlay={
                            <Tooltip id="generate-label-tooltip">
                                <span dangerouslySetInnerHTML={{__html: tooltipText}}/>
                            </Tooltip>
                        }
                    >
                        <button
                            type="button"
                            className="custom-option disabled"
                        >
                            {getLangText('generateLabelLabel')}
                        </button>
                    </OverlayTrigger>
                    :
                    <button
                        type="button"
                        className="custom-option"
                        onClick={() => setModalOpen(GENERATE_LABEL_MODAL)}
                        disabled={isMoreThanMax}
                    >
                        {getLangText('generateLabelLabel')}
                    </button>
                }
                <PermissionsWrapper
                    NoPermissionComponent={() => (
                        <Link
                            className="custom-option"
                            to={RouterPaths.SettingsIntegrationFakturownia}
                        >
                            {getLangText('generateInvoiceLabel')}
                        </Link>
                    )}
                    allowedPermissions={[FAKTUROWNIA_PERMISSION]}
                >
                    {isMoreThanMax ?
                        <OverlayTrigger
                            placement='top'
                            overlay={
                                <Tooltip id="generate-invoice-tooltip">
                                    <span dangerouslySetInnerHTML={{__html: tooltipText}}/>
                                </Tooltip>
                            }
                        >
                            <button
                                type="button"
                                className="custom-option disabled"
                            >
                                {getLangText('generateInvoiceLabel')}
                            </button>
                        </OverlayTrigger> :
                        <button
                            type="button"
                            className="custom-option"
                            onClick={() => setModalOpen(GENERATE_INVOICE_MODAL)}
                            disabled={isMoreThanMax}
                        >
                            {getLangText('generateInvoiceLabel')}
                        </button>
                    }
                </PermissionsWrapper>
                <hr className="mt-1 mb-1"/>
                <DropdownItem
                    isMoreThanMax={isMoreThanMax}
                    type={PRINT_LABEL_MODAL}
                    setModalOpen={setModalOpen}
                    buttonTitle="printLabelLabel"
                />
                <PermissionsWrapper
                    NoPermissionComponent={() => (
                        <Link
                            className="custom-option"
                            to={RouterPaths.SettingsIntegrationFakturownia}
                        >
                            {getLangText('printInvoiceLabel')}
                        </Link>
                    )}
                    allowedPermissions={[FAKTUROWNIA_PERMISSION]}
                >
                    <button
                        type="button"
                        className="custom-option"
                        onClick={() => setModalOpen(PRINT_INVOICE_MODAL)}
                    >
                        {getLangText('printInvoiceLabel')}
                    </button>
                </PermissionsWrapper>
                <hr className="mt-1 mb-1"/>
                <button
                    type="button"
                    className="custom-option"
                    onClick={() => setModalOpen(STATUS_MODAL)}
                >
                    {getLangText('changeStatusLabel')}
                </button>
                <button
                    type="button"
                    className="custom-option"
                    onClick={() => checkSelmoPro(() => setModalOpen(SEND_MESSAGE_MODAL))}
                >
                    {getLangText('sendMessageLabel')}
                    <ProImageBox/>
                </button>
            </DropdownWithPortal>
        )
    }

    return (
        <th
            className={`checkbox-col ${isSelected ? 'selected' : ''}`}
            colSpan={colSpan}
        >
            <div className="selected-row">
                <div className="selected-checkbox">
                    <CheckboxField
                        showLabel={false}
                        name="selectAllItems"
                        className="mb-0"
                        setValue={selectAllItems}
                        value={checkIfSomeExist()}
                    />
                    {isSelected &&
                        <span>{selectedItems.length} {getLangText('selectedLabel')}</span>
                    }
                </div>
                {isSelected &&
                    <DropdownPortal/>
                }
            </div>
            {modalVisibleType === GENERATE_LABEL_MODAL &&
                <GenerateModal
                    successGenerated={successGenerated}
                    modalVisible={modalVisibleType === GENERATE_LABEL_MODAL}
                    hideModal={() => setModalOpen(null)}
                    waitingForDocument={waitingForDocument}
                    generatedOrdersCount={generatedOrdersCount}
                />
            }
            {modalVisibleType === GENERATE_PAXY_LABEL_MODAL &&
                <PaxyModalForm
                    show={modalVisibleType === GENERATE_PAXY_LABEL_MODAL}
                    hide={() => setModalOpen(null)}
                    loadData={() => dispatch(ListActions.loadData())}
                    submitRest={`${services.API_BATCH_LABELS}${getGETParamsUrl({'orders[]': selectedItems.map((i) => i.id)})}`}
                    optionsModel={PaxyBatchLabelModel}
                    selectedItems={selectedItems}
                />
            }
            {modalVisibleType === PRINT_LABEL_MODAL &&
                <ExportModal
                    title="Wydrukuj etykietę"
                    subTitle={getLangText('receivedFileInPDFFormatLabel')}
                    saveButton="Wydrukuj"
                    rest={`${services.API_PRINT_LABELS}${getGETParamsUrl({'orders[]': selectedItems.map((i) => i.id)})}`}
                    fileName="Etykieta"
                    show={modalVisibleType === PRINT_LABEL_MODAL}
                    hide={() => setModalOpen(null)}
                    initialCheckBigFile={false}
                />
            }
            {modalVisibleType === PRINT_LABEL_PUSHER_MODAL &&
                <PrintLabelBatchModal
                    rest={services.API_PRINT_LABELS}
                    orders={selectedItems}
                    show={modalVisibleType === PRINT_LABEL_PUSHER_MODAL}
                    hide={() => setModalOpen(null)}
                />
            }
            {modalVisibleType === GENERATE_INVOICE_MODAL &&
                <GenerateInvoiceModal
                    title="Wygeneruj fakturę"
                    SubTitle={() => <span>Otrzymasz plik w formacie <strong>*.PDF</strong></span>}
                    saveButton="Wygeneruj"
                    show={modalVisibleType === GENERATE_INVOICE_MODAL}
                    isWaiting={waitingForDocument}
                    successGenerated={successGenerated}
                    generatedOrdersCount={generatedOrdersCount}
                    onSubmit={() => dispatch(ListActions.generateInvoice())}
                    hide={() => setModalOpen(null)}
                />
            }
            {modalVisibleType === GENERATE_SK_LABEL_MODAL &&
                <GenerateSkLabelModal
                    modalVisible={modalVisibleType === GENERATE_SK_LABEL_MODAL}
                    hideModal={() => setModalOpen(null)}
                />
            }
            {modalVisibleType === PRINT_INVOICE_MODAL &&
                <ExportModal
                    title="Wydrukuj fakturę"
                    subTitle={getLangText('receivedFileInPDFFormatLabel')}
                    show={modalVisibleType === PRINT_INVOICE_MODAL}
                    saveButton="Wydrukuj"
                    rest={`${services.API_PRINT_INVOICES}${getGETParamsUrl({'orders[]': selectedItems.map((i) => i.id)})}`}
                    fileName="Faktura"
                    hide={() => setModalOpen(null)}
                    initialCheckBigFile={false}
                />
            }
            {modalVisibleType === STATUS_MODAL &&
                <StatusModal
                    modalVisible={modalVisibleType === STATUS_MODAL}
                />
            }
            {modalVisibleType === SEND_MESSAGE_MODAL &&
                <SendMessageModal
                    hideModal={() => setModalOpen(null)}
                    isOpen={modalVisibleType === SEND_MESSAGE_MODAL}
                />
            }
        </th>
    );
};

export default SelectedRow;
