import {combineReducers} from "redux";
import filtersReducer from "./filters/filtersReducer";
import listReducer from "./list/listReducer";
import filtersPanelReducer from "./filtersPanel/filtersPanelReducer";

export default combineReducers({
	list: listReducer,
	filters: filtersReducer,
	filtersPanel: filtersPanelReducer,
});
