export const PRODUCTS_SHOP_VISIBILITY_TYPE_ACTIVE_ALL = 'all';
export const PRODUCTS_SHOP_VISIBILITY_TYPE_ACTIVE = '1';
export const PRODUCTS_SHOP_VISIBILITY_TYPE_N0T_ACTIVE = '0';


export const PRODUCTS_SHOP_VISIBILITY_TYPES = [
	{
		value: PRODUCTS_SHOP_VISIBILITY_TYPE_ACTIVE_ALL, label: 'allLabel',
	},
	{
		value: PRODUCTS_SHOP_VISIBILITY_TYPE_ACTIVE, label: 'shopVisibility',
	},
	{
		value: PRODUCTS_SHOP_VISIBILITY_TYPE_N0T_ACTIVE, label: 'shopNotVisibility',
	},
]
