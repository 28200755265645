import { useCallback, useEffect, useState } from 'react';

const useDropdown = (buttonRef, dropdownRef, callBack = () => {}) => {
	const [open, setOpen] = useState(false);
	const handleClickOutside = useCallback((e) => {
		if (open
			&& buttonRef
			&& !buttonRef.current?.contains(e.target)) {
			setOpen(false)
			callBack();
		}
	}, [open]);

	useEffect(() => {
		document.body.addEventListener('click', handleClickOutside);
		return () => {
			document.body.removeEventListener('click', handleClickOutside);
		};
	}, [handleClickOutside]);

	return {
		open,
		setOpen,
	};
};

export default useDropdown;
