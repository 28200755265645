import {RouterPaths} from "../../../routes/RouterPath";

export const SHOP_SETTINGS_INTEGRATIONS_PIXEL_VIEW = 'SHOP_SETTINGS_INTEGRATIONS_PIXEL_VIEW';
export const SHOP_SETTINGS_INTEGRATIONS_GA_VIEW = 'SHOP_SETTINGS_INTEGRATIONS_GA_VIEW';
export const SHOP_SETTINGS_INTEGRATIONS_TIKTOK_VIEW = 'SHOP_SETTINGS_INTEGRATIONS_TIKTOK_VIEW';

const ShopSettingsRegulationViews = [
	{
		id: SHOP_SETTINGS_INTEGRATIONS_PIXEL_VIEW,
		label: 'pixelMetaLabel',
		path: RouterPaths.ShopIntegrationsPixel,
	},
	{
		id: SHOP_SETTINGS_INTEGRATIONS_GA_VIEW,
		label: 'googleAnalyticsTagLabel',
		path: RouterPaths.ShopIntegrationsGoogle,
	},
	// {
	// 	id: SHOP_SETTINGS_INTEGRATIONS_TIKTOK_VIEW,
	// 	label: 'tiktokAnalyticsTagLabel',
	// 	path: RouterPaths.ShopIntegrationsTiktok,
	// },
];

export default ShopSettingsRegulationViews;
