export const SELMO_APP_PERMISSION = 'SELMO_APP_PERMISSION';
export const LIVE_NOTIFICATION_PERMISSION = 'LIVE_NOTIFICATION_PERMISSION';
export const PRODUCT_CODE_PERMISSION = 'PRODUCT_CODE_PERMISSION';
export const ORDER_NOTIFICATIONS_PERMISSION = 'ORDER_NOTIFICATIONS_PERMISSION';
export const FAKTUROWNIA_PERMISSION = 'FAKTUROWNIA_PERMISSION';
export const SHOP_PERMISSION = 'SHOP_PERMISSION';

const SelmoPermissions = [
	{
		id: SELMO_APP_PERMISSION,
	},
	{
		id: LIVE_NOTIFICATION_PERMISSION,
	},
	{
		id: PRODUCT_CODE_PERMISSION,
		label: 'productsCodeLabel'
	},
	{
		id: FAKTUROWNIA_PERMISSION,
		label: 'Fakturownia'
	},
	{
		id: ORDER_NOTIFICATIONS_PERMISSION,
		label: 'automaticallyNotificationsLabel'
	},
	{
		id: SHOP_PERMISSION,
		label: 'shopLabel'
	},
];

export default SelmoPermissions;
