import React, {useEffect} from 'react';

const useEnterSubmitForm = (onSave, values) => {

	const listener = event => {
		if (event.code === "Enter") {
			onSave();
		}
	};

	useEffect(() => {
		document.addEventListener("keydown", listener);
		return () => {
			document.removeEventListener("keydown", listener);
		};
	}, [values]);
};

export default useEnterSubmitForm;
