export const HEADER_BOX_MESSENGER = 'HEADER_BOX_MESSENGER';
export const HEADER_BOX_PRODUCTS = 'HEADER_BOX_PRODUCTS';
export const HEADER_BOX_ORDERS = 'HEADER_BOX_ORDERS';
export const HEADER_BOX_LIVES = 'HEADER_BOX_LIVES';

export const FIRST_PAGE_PRODUCTS = 'FIRST_PAGE_PRODUCTS';
export const FIRST_PAGE_LIVES = 'FIRST_PAGE_LIVES';
export const FIRST_PAGE_AUTOMATION = 'FIRST_PAGE_AUTOMATION';
export const FIRST_PAGE_ORDERS = 'FIRST_PAGE_ORDERS';
export const DASHBOARD_MAIN_AFTER_TRIAL = 'DASHBOARD_MAIN_AFTER_TRIAL';
export const DASHBOARD_MAIN_TRIAL = 'DASHBOARD_MAIN_TRIAL';
export const DASHBOARD_CHECKBOX_PRODUCTS = 'DASHBOARD_CHECKBOX_PRODUCTS';
export const DASHBOARD_CHECKBOX_SEND_NOTIFICATIONS = 'DASHBOARD_CHECKBOX_SEND_NOTIFICATIONS';
export const DASHBOARD_CHECKBOX_MESSENGER = 'DASHBOARD_CHECKBOX_MESSENGER';
export const DASHBOARD_CHECKBOX_LIVE = 'DASHBOARD_CHECKBOX_LIVE';
export const SETTINGS_MONDIAL_RELAY = 'SETTINGS_MONDIAL_RELAY';
