import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

export const CharactersCounter = ({ value, max }) => {
    const length = useMemo(() => (value ? value.length : 0), [value]);

    return (
        <div className="characters-counter">
            {`${length}/${max}`}
        </div>
    );
};

CharactersCounter.propTypes = {
    value: PropTypes.string,
    max: PropTypes.number,
};

CharactersCounter.defaultProps = {
    value: '',
    max: 255,
};

export default CharactersCounter;
