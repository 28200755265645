import FormReducer from "../../../../../src_shared/form/FormReducer";
import createReducer from "../../../shared/helpers/createReducer";
import ClientInfoModel from "../../../../../modules/models/clients/info/ClientInfoModel";

export const getStateRoot = (state) => state.clients.details.info;
export const prefix = 'CLIENTS_DETAILS_INFO_';

export const getInitState = () => FormReducer.getInitState(ClientInfoModel.getModel())

export const getReduceMap = () => FormReducer.getReduceMap()

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();
