import {fetchTranslations} from "../../../../services/i18n/i18nAPI";
import {getFromLS, saveToLS} from "../helpers/ls";
import {batch} from "react-redux";
import {selmoUrl} from "../../../../src_shared/api/api";
import {services} from "../../../RestServices";
import moment from "moment/moment";
import api from "../../../../services/axios/axios";
import {supportedLangs} from "../../../../services/i18n/i18n";

export class LangsActions {
    setWaiting(toggle) {
        return {
            type: 'LANGS_SET_WAITING',
            toggle
        };
    }

    setTranslations(translations) {
        return {
            type: 'LANGS_SET_TRANSLATIONS',
            translations
        };
    }

    getBrowserLocales() {
        const browserLocales =
            navigator.languages === undefined
                ? [navigator.language]
                : navigator.languages;

        if (!browserLocales) {
            return false;
        }

        return browserLocales.map(locale => {
            const trimmedLocale = locale?.trim()?.toLowerCase();
            if (trimmedLocale?.split(/-|_/)[1] === 'us') {
                return 'us';
            }
            return trimmedLocale?.split(/-|_/)[0];
        })[0];
    }

    getLang() {
        return () => {
            const browserLocales = this.getBrowserLocales();
            const LSLang = getFromLS('lang');

            const prepareBrowserLang = () => {
                if (LSLang) return LSLang
                if (browserLocales === 'da') return 'dk';
                if (supportedLangs[browserLocales]) return browserLocales
                // if (browserLocales === 'sv') {
                // 	return 'se'
                // }
                return 'en'
            }

            return prepareBrowserLang();
        }
    }

    setServerLanguage(lang) {
        return async (dispatch) => {
            try {
                await api.post(`${selmoUrl}/${services.API_SERVER_LANGUAGE}`, {
                    lang,
                })
            } catch (e) {
                console.error(e)
            }
        }
    }

    setAsyncLang(lang) {
        return async (dispatch) => {
            dispatch(this.setWaiting(true));

            const resolvedLang = lang || dispatch(this.getLang())

            const preparedLang = !!supportedLangs[resolvedLang] ? resolvedLang : 'en'

            try {
                const translations = await fetchTranslations(preparedLang);
                batch(() => {
                    dispatch(this.setLang(preparedLang))
                    dispatch(this.setTranslations(translations))

                })
                moment.locale(preparedLang === 'us' ? 'en' : preparedLang)
                saveToLS('lang', preparedLang);

            } catch (e) {
                console.error('Cannot set server lang')
            } finally {
                dispatch(this.setWaiting(false));
            }
        }
    }

    setLang(lang) {
        return {
            type: 'LANGS_SET_LANG',
            lang
        };
    }
}

export const getInstance = () => new LangsActions();

export default getInstance();
