import {getStateRoot, prefix} from './listReducer.js';
import {RouterPaths} from "../../../../routes/RouterPath.js";
import {services} from "../../../../RestServices.js";
import ListActions from "../../../../../src_shared/list/ListActions";

export const getInstance = () => new ListActions({
	getStateRoot,
	restService: services.API_PERMISSIONS_EMPLOYEES,
	pathname: RouterPaths.SettingsTeamMembers,
	prefix,
});

export default getInstance();
