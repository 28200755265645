import React, {useEffect, useState} from 'react';
import useLang from "../../../hooks/useLang";

const CopyButton = ({
						copyText,
						copyButtonText,
						copiedButtonText,
						withIcon,
						buttonClassName,
						withTooltip,
						withoutTooltip
					}) => {

	const {getLangText} = useLang();
	const [copied, setCopied] = useState(false);

	const copyToClipboard = (e) => {
		e.stopPropagation();
		e.preventDefault();
		navigator.clipboard.writeText(copyText)
		setCopied(true);
	};

	useEffect(() => {
		const tooltipVisibleTimeout = setTimeout(() => {
			setCopied(false);

		}, 3000)
		return () => {
			clearTimeout(tooltipVisibleTimeout)
		}
	}, [copied])

	if (withoutTooltip) {
		return (
			<button
				type="button"
				onClick={copyToClipboard}
				className={`button small-size copy-btn ${buttonClassName}`}
			>
				{withIcon &&
					<i className="icon-copy ml-1"/>
				}
				{copied ? copiedButtonText : copyButtonText}
			</button>
		)
	}

	return (

		<div className={`tooltip-parent no-out-transition ${copied ? 'visible-hover' : ''}`}>
			{withTooltip &&
				<div className="tooltip-content">
					{copied ? getLangText('copiedLabel') : getLangText('copyButton')}
				</div>
			}
			<button
				type="button"
				onClick={copyToClipboard}
				className={`button small-size copy-btn ${buttonClassName}`}
			>
				{withIcon &&
					<i className="icon-copy"/>
				}
				{copied ? copiedButtonText : copyButtonText}
			</button>
		</div>
	);
};

CopyButton.defaultProps = {
	withIcon: true,
	withTooltip: true,
	copyButtonText: '',
	buttonClassName: 'border-button only-icon',
	copiedButtonText: '',
	withoutTooltip: false,
}

export default CopyButton;
