import React, {useState} from 'react';
import TeamWrapper from "../TeamWrapper";
import Table from "./components/Table";
import Pagination from "../../../app/shared/components/pagination/Pagination";
import useLang from "../../../../src_shared/hooks/useLang";
import useList from "../../../../src_shared/list/useList";
import {getStateRoot} from "./listReducer";
import ListActions from "./ListActions";
import ConfirmRemoveModal from "../../../../src_shared/components/modal/ConfirmRemoveModal";

const TeamInvitations = () => {
    const [showModal, setShowModal] = useState(false);
    const {getLangText} = useLang();

    const {
        list: {items, isWaiting},
        search,
        remove: {confirmItemRemoval, isModalVisible, cancelItemRemoval, markedToRemove,askForRemoveItem},
        pagination: {
            changePage,
            getPagination,
            itemsPerPage,
            nextPage,
            prevPage,
            totalItems,
            updateItemsPerPage
        },
        sort: {updateSortOptions, getSortClass},
    } = useList({
        ListActions,
        getStateRoot,
    });

    return (
        <TeamWrapper
            modalVisible={showModal}
            setShowModal={setShowModal}
            title="invitationsLabel"
        >
            <Table
                isWaiting={isWaiting}
                items={items}
                updateSortOptions={updateSortOptions}
                getSortClass={getSortClass}
                askForRemoveItem={askForRemoveItem}
            />
            <Pagination
                getLangText={getLangText}
                pages={getPagination()}
                items={items}
                itemsPerPage={itemsPerPage}
                totalItems={totalItems}
                onPrevPage={prevPage}
                onChangePage={changePage}
                onNextPage={nextPage}
                onChangeItemsPerPage={updateItemsPerPage}
                classes={{
                    prevIcon: 'icon-chevron left',
                    nextIcon: 'icon-chevron right',
                }}
            />
            <ConfirmRemoveModal
                show={isModalVisible}
                title={getLangText('wantToRemoveInvitations')}
                isWaiting={isWaiting}
                hide={cancelItemRemoval}
                confirm={confirmItemRemoval}
                confirmButton={getLangText('removeButton')}
                dialogClassName="small-modal"
            />
        </TeamWrapper>
    );
};

export default TeamInvitations;
