import React from 'react';
import Modal from "react-bootstrap/Modal";
import FacebookCircle from "../../../../src_shared/components/ui/Iocns/FacebookCircle";

const AfterSubmitModal = ({
                              showModal,
                              hideModal,
                              openShareModal,
                          }) => {
    const imageLink = '/assets/images/dpd-graphic-promo.png'

    const handleShare = () => {
        hideModal()
        openShareModal();
    }

    return (
        <Modal
            dialogClassName={`default-modal w-450`}
            show={showModal}
            onHide={hideModal}
        >
            <Modal.Header>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            <Modal.Body className="pt-0">
                <div className="text-center">
                    <i className="icon-tick-c text-[32px] text-[#3cd9a0]"/>
                    <div className="text-xl font-bold my-2">Udało się!</div>
                    <div className="font-medium text-[#1F2937] text-sm">
                        <strong>Opublikuj grafikę i poinformuj klientów</strong>, że mogą zamawiać do <strong>30
                        000</strong> punktów i automatów paczkowych
                    </div>
                </div>
                <div className="border flex-wrap rounded-[8px] py-2 pl-3 pr-2 flex items-center mt-4">
                    <div className="sm:order-1 order-2">
                        <div className="font-bold">Opublikuj grafikę na Facebooku</div>
                        <div className="flex items-center mt-2 space-x-2">
                            <button
                                onClick={handleShare}
                                type="button"
                                className="button sm:grow-0 grow border-button min-w-[110px]"
                            >
                                <FacebookCircle className="mr-1" width={14} height={14} fill="#1a94e6"/>
                                Udostępnij
                            </button>
                            <a
                                download
                                href={imageLink}
                                target="_blank"
                                className="button border-button sm:grow-0 grow left-icon min-w-[110px]"
                            >
                                <i className="icon-download"/>
                                Pobierz
                            </a>
                        </div>
                    </div>
                    <div
                        className="block sm:order-2 sm:ml-auto sm:mb-0 mb-2.5 w-[90px] h-[90px] rounded-[8px] overflow-hidden">
                        <img src={imageLink} className="w-full h-full object-cover" alt=""/>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default AfterSubmitModal;
