import Model, {convertObjectProperties} from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";
import {MONDIAL_RELAY_COLLECTIONS_REL} from "../../../components/app/shared/enums/MondialRelayCollectionsEnumts";

class SettingsMondialRelayModel extends Model {
	getModel() {
		return {
			mode_col: MONDIAL_RELAY_COLLECTIONS_REL,
			merchant_key: '',
			private_key: '',
			api_customer_id: '',
			api_login: '',
			api_pwd: '',
			active: '0',
			country_info: {},
		};
	}

	parseDTO(data) {
		return convertObjectProperties(
			data,
			{
				mode_col: () => MONDIAL_RELAY_COLLECTIONS_REL,
			},
			{
				country_info: {},
				api_customer_id: null,
				api_login: null,
				api_pwd: null,
				mode_col: MONDIAL_RELAY_COLLECTIONS_REL,
			}
		);
	}

	getValidators() {
		return {
			merchant_key: ValidationError.notEmpty,
			private_key: ValidationError.notEmpty,
			api_customer_id: ValidationError.notEmpty,
			api_login: ValidationError.notEmpty,
			api_pwd: ValidationError.notEmpty,
			mode_col: ValidationError.notEmpty,
			col_rel: ValidationError.notEmpty,
			active: ValidationError.skip,
		};
	}
}

export default new SettingsMondialRelayModel();
