import React, {useEffect} from "react";
import useForm from "../../../../../src_shared/form/useForm";
import FormActions from "./FormActions";
import Loader from "../../../shared/Loader";
import {useParams} from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Field from "../../../../../src_shared/components/form/field/Field";
import SelectField from "../../../../../src_shared/components/form/selectField/SelectField";
import {useDispatch} from "react-redux";
import useEnterSubmitForm from "../../../../../src_shared/hooks/useEnterFormSubmit";
import ZipCodeField from "./components/ZipCodeInputs";
import useLang from "../../../../../src_shared/hooks/useLang";
import InvoiceData from "../../../basket/deliveryAddress/components/InvoiceData";
import {services} from "../../../../RestServices";
import AsyncSearchSelectField
    from "../../../../../src_shared/components/form/selectField/asyncSearchSelectFieldNew/AsyncSearchSelectField";

const DeliveryAddressForm = ({data}) => {
    const params = useParams()
    const dispatch = useDispatch();
    const {getLangText} = useLang();

    const {
        data: {values, setValue},
        form: {isWaiting, onSave, getFieldProps},
        modal: {hideModal, modalVisible},
    } = useForm({
        FormActions,
        params,
        preventAutoLoad: true,
    });

    const countryName = values.countries?.find((i) => +i.id === +values.country)?.name || '';
    const isPoland = countryName && countryName.toLowerCase().trim() === 'polska';
    const isUsa = countryName && countryName.toLowerCase()?.includes(getLangText('unitedStates')?.toLowerCase());

    useEffect(() => {
        dispatch(FormActions.loadCountries())
    }, [])

    const options = values.countries?.map((i) => ({
        value: i.id,
        label: i.name,
    }))

    useEnterSubmitForm(onSave, values);

    return (
        <Modal
            show={modalVisible}
            className="add-order-modal"
            dialogClassName="default-modal"
            onHide={hideModal}
        >
            <Modal.Header>
                <Modal.Title>{getLangText('editDeliveryAddressLabel')}</Modal.Title>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            <Modal.Body>
                <div className="form-row">
                    {!values.only_digital &&
                        <div className="w-50">
                            <Field
                                {...getFieldProps('first_name')}
                                value={values.first_name}
                                label={getLangText('firstNameLabel')}
                                setValue={setValue}
                            />
                        </div>
                    }
                    {!values.only_digital &&
                        <div className="w-50">
                            <Field
                                {...getFieldProps('last_name')}
                                value={values.last_name}
                                label={getLangText('lastNameLabel')}
                                setValue={setValue}
                            />
                        </div>
                    }
                    <div className="w-50">
                        <Field
                            {...getFieldProps('email')}
                            value={values.email}
                            label={getLangText('emailLabel')}
                            setValue={setValue}
                        />
                    </div>
                    <div className="w-50">
                        <SelectField
                            {...getFieldProps('country')}
                            label={getLangText('countryLabel')}
                            defaultValue={{
                                value: values.country,
                                label: values.country_name
                            }}
                            options={options}
                            setValue={setValue}
                            className="default-size"
                            placeholder={getLangText('selectCountryLabel')}
                        />
                    </div>
                    {!values.only_digital &&
                        <>
                            <div className="w-50">
                                <Field
                                    {...getFieldProps('street')}
                                    value={values.street}
                                    label={getLangText('streetLabel')}
                                    setValue={setValue}
                                />
                            </div>
                            <div className="w-50">
                                <Field
                                    {...getFieldProps('building_number')}
                                    value={values.building_number}
                                    label={getLangText('buildingNumberLabel')}
                                    setValue={setValue}
                                />
                            </div>
                            {isUsa &&
                                <div className="w-100">
                                    <AsyncSearchSelectField
                                        {...getFieldProps('state')}
                                        label={getLangText('state')}
                                        setValue={setValue}
                                        rest={services.API_US_STATES}
                                        defaultValue={{
                                            id: values.state,
                                            name: values.state_name
                                        }}
                                        selectedOption={(selectedItem) => <div className="text-sm">{selectedItem.name}</div>}
                                        searchOnFocus
                                        isSearchable={false}
                                        withSearchInput
                                    />
                                </div>
                            }
                            <div className="w-50">
                                <Field
                                    {...getFieldProps('city')}
                                    value={values.city}
                                    label={getLangText('cityLabel')}
                                    setValue={setValue}
                                />
                            </div>
                            <div className="w-25 mobile-w-100">
                                <ZipCodeField
                                    {...getFieldProps('zip_code')}
                                    value={values.zip_code}
                                    label={getLangText('zipCodeLabel')}
                                    setValue={setValue}
                                    isPoland={isPoland}
                                />
                            </div>
                            <div className="w-25 mobile-w-100">
                                <Field
                                    {...getFieldProps('phone')}
                                    value={values.phone}
                                    label={getLangText('phoneLabel')}
                                    setValue={setValue}
                                    extraProps={{
                                        maxLength: isPoland ? 9 : 100
                                    }}
                                />
                            </div>
                        </>
                    }
                    {data.extra_input_enabled === '1' &&
                        <div className="w-100">
                            <Field
                                {...getFieldProps('extra_description')}
                                value={values.extra_description}
                                label={getLangText('additionalInformationLabel')}
                                setValue={setValue}
                            />
                        </div>
                    }
                    {!!+data.company_invoice_enabled &&
                        <InvoiceData
                            getFieldProps={getFieldProps}
                            setValue={setValue}
                            values={values}
                            inOrder
                        />
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button border-button"
                >
                    {getLangText('cancelButton')}
                </button>
                <button
                    type="button"
                    className="button primary"
                    onClick={onSave}
                >
                    {isWaiting && <Loader isLoading/>}
                    {getLangText('saveButton')}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeliveryAddressForm;



