import React from 'react';
import * as ReactDOM from 'react-dom'
import useWindowDimensions from "../hooks/useWindowDimensions/useWindowDimensions";

const DropdownPortal = ({children, basedOn, item, className, offsetY = 0, target = document.body}) => {

    const {height} = useWindowDimensions();

    if (!basedOn?.current[item.helperId]) {
        return null;
    }

    const {top, left, width, bottom} = basedOn?.current[item.helperId].getBoundingClientRect();
    const maxHeight = height - bottom;

	const preparedTop = () => {
		if (target?.scrollTop > 0) {
			return top + target?.scrollTop;
		}
		return top;
	}

    if (!target) return null;

    return ReactDOM.createPortal(
        <div
            style={{top: preparedTop() + offsetY, left: left + 1, minWidth: width, maxHeight: maxHeight}}
            className={`custom-hint-dropdown ${className}`}
        >
            {children}
        </div>, target
	)
}

export default DropdownPortal;
