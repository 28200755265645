import React from 'react';
import EmptyList from "../../../shared/EmptyList";
import useLang from "../../../../../src_shared/hooks/useLang";
import {useSelector} from "react-redux";
import Pagination from "../../../shared/components/pagination/Pagination";
import ListActions from "./ListActions";
import {getStateRoot} from "./listReducer";
import useList from "../../../../../src_shared/list/useList";
import PagesModalForm from "../modalForm/Form";
import {createSelector} from "reselect";
import SearchInput from "../../../shared/components/form/SearchInput";
import {useHistory} from "react-router-dom";
import {RouterPaths} from "../../../../routes/RouterPath";
import {Helmet} from "react-helmet";
import ShopNameBar from "../../../../routes/shopSettingsRoute/ShopNameBar";

const ShopPagesList = () => {
	const {getLangText} = useLang();
	const history = useHistory();

	const {
		pagination: {
			changePage,
			getPagination,
			itemsPerPage,
			nextPage,
			prevPage,
			totalItems,
			updateItemsPerPage,
		},
		list: {items, isWaiting},
		search,
		sort: {updateSortOptions, getSortClass},
		remove: {cancelItemRemoval, askForRemoveItem}
	} = useList({
		ListActions,
		getStateRoot,
	});

	const {modalVisible} = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));

	return (
		<>
			<ShopNameBar/>
			<div className="table-list-wrapper">
				<Helmet>
					<meta charSet="utf-8"/>
					<title>{getLangText('shopSettingsPagesPageLabel')}Selmo</title>
				</Helmet>
				<div className="default-shadow-box pb-0 pt-sm-3">
					<div className="table-header">
						<div className="top-part">
							<div>
								<h1 className="title">
									{getLangText('asidePagesLabel')}
									{totalItems > 0 &&
										<div className="badge">{totalItems}</div>
									}
								</h1>
								<div className="small-text sub-title">
									{getLangText('createCustomPageInShopLabel')}
								</div>
							</div>
							<SearchInput
								placeholder={getLangText('searchPlaceholder')}
								className="mr-0 ml-auto"
								search={search}
							/>
							<button
								onClick={askForRemoveItem}
								type="button"
								className="button primary small-size ml-2"
							>
								{getLangText('addPageLabel')}
							</button>
						</div>
					</div>
					<EmptyList
						items={items}
						isLoading={isWaiting}
						className="with-padd"
						imgSrc="/assets/images/empty/orders-empty.svg"
						title={getLangText('emptyHereLabel')}
						text={getLangText('changeSearchOrAddPageLabel')}
					>
						<div className="table-wrapper">
							<div className="table-responsive overflow-visible">
								<table className="table live-table smaller-padd">
									<thead>
									<tr>
										<th
											name="name"
											onClick={updateSortOptions}
											className={getSortClass('name')}
										>
										<span name="name">
											{getLangText('pageNameLabel')}
										</span>
										</th>
										<th
											name="url"
											onClick={updateSortOptions}
											className={getSortClass('url')}
										>
										<span name="url">
											{getLangText('pageLinkLabel')}
										</span>
										</th>
									</tr>
									</thead>
									<tbody>
									{items.map((item) => (
										<tr
											key={item.id}
											onClick={() => history.push(`${RouterPaths.ShopPages}/${item.id}/`)}
										>
											<td
												data-thead={getLangText('pageNameLabel')}
												className="name"
											>
												{item.name}
											</td>
											<td
												data-thead={`${getLangText('linkLabel')}:`}
												className="with-mobile-thead inline-thead"
											>
												{item.url}
											</td>
										</tr>
									))}
									</tbody>
								</table>
							</div>
						</div>
					</EmptyList>
				</div>
				<Pagination
					getLangText={getLangText}
					pages={getPagination()}
					items={items}
					itemsPerPage={itemsPerPage}
					totalItems={totalItems}
					onPrevPage={prevPage}
					onChangePage={changePage}
					onNextPage={nextPage}
					onChangeItemsPerPage={updateItemsPerPage}
					classes={{
						prevIcon: 'icon-chevron left',
						nextIcon: 'icon-chevron right',
					}}
					showItemsPerPageSelect
				/>
				{modalVisible &&
					<PagesModalForm
						modalVisible={modalVisible}
						hideModal={cancelItemRemoval}
					/>
				}
			</div>
		</>
	);
};

export default ShopPagesList;
