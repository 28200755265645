import {useState} from 'react';

const usePhotoPreview = () => {
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const hidePreviewModal = () => setShowPreviewModal(false);
    const openPreviewModal = (src) => setShowPreviewModal(src || true);

    return {
        showPreviewModal,
        hidePreviewModal,
        openPreviewModal,
    }
};

export default usePhotoPreview;
