import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {batch, useDispatch, useSelector} from "react-redux";
import {ALL_ORDERS_VIEW, INTERVAL} from "../enums/OrderViewTypes";
import {createSelector} from "reselect";
import useDropdown from "./useDropdown";

const useDaterangePicker = (actions, getFiltersStateRoot, FiltersActions, loadData) => {
	const dispatch = useDispatch();
	const dropdownRef = useRef(null);
	const buttonRef = useRef(null);

	const onCloseClick = () => {
		closeDateRangePickerFilters();
		setOpen(false);
	};

	const {open, setOpen} = useDropdown(buttonRef, dropdownRef, onCloseClick);

	const [fromDate, toDate, viewType] = useSelector(createSelector(
		getFiltersStateRoot,
		(stateRoot) => [
			stateRoot.data.fromDate,
			stateRoot.data.toDate,
			stateRoot.data.viewType,
		],
	));

	const [localViewType, setLocalViewType] = useState(viewType);
	const [dateFilter, setDateFilter] = useState({
		fromDate,
		toDate,
	});

	const localFromDate = dateFilter.fromDate;
	const localToDate = dateFilter.toDate;

	const closeDateRangePickerFilters = () => {
		setDateFilter({
			fromDate,
			toDate,
		});
		setLocalViewType(viewType);
	};

	const saveDateRangePickerFilters = (id) => {
		batch(() => {
			dispatch(FiltersActions.data.updateValues({
				fromDate: localFromDate,
				toDate: localToDate,
				viewType: localViewType,
			}));
			if (id) {
				dispatch(actions.loadPage(1, id));
			} else {
				if (loadData) {
					loadData()
				} else {
					dispatch(actions.loadPage(1));
				}
			}
		});
	};

	useEffect(() => {
		closeDateRangePickerFilters()
	}, [open]);

	const selectionRange = useMemo(() => {
		if (localFromDate && localToDate) {
			return {
				startDate: localFromDate,
				endDate: localToDate,
				key: 'selection',
			};
		}

		return {
			key: 'selection',
		};
	}, [localFromDate, localToDate]);

	const handleDateSelect = ({selection: {startDate, endDate}}) => {
		batch(() => {
			setLocalViewType(INTERVAL);
			setDateFilter({
				fromDate: new Date(startDate),
				toDate: new Date(endDate),
			});
		});
	};

	const renderStaticRangeLabel = (props, id) => (
		<span
			className="extra-range"
			onClick={(e) => {
				e.stopPropagation();
				dispatch(FiltersActions.data.setValue('viewType', props.value))
				if (props.range() && props.value !== ALL_ORDERS_VIEW) {
					dispatch(FiltersActions.data.updateValues({
						fromDate: props.range().startDate,
						toDate: props.range().endDate,
					}));
				} else if (props.value === ALL_ORDERS_VIEW) {
					dispatch(FiltersActions.data.updateValues({
						fromDate: null,
						toDate: null,
					}));
				}
				if (id) {
					dispatch(actions.loadPage(1, id));
				} else {
					if (loadData) {
						loadData()
					} else {
						dispatch(actions.loadPage(1));
					}
				}
				setOpen(false);
			}}
		>
			{props.label}
		</span>
	);

	return {
		buttonRef,
		dropdownRef,
		onCloseClick,
		open,
		setOpen,
		fromDate,
		toDate,
		selectionRange,
		handleDateSelect,
		closeDateRangePickerFilters,
		saveDateRangePickerFilters,
		viewType,
		localViewType,
		setLocalViewType,
		setDateFilter,
		renderStaticRangeLabel,
	}
};

export default useDaterangePicker;
