import ListActions, {getGETParamsUrl} from "../../../../../src_shared/list/ListActions.js";
import {getStateRoot, prefix} from './listReducer.js';
import {RouterPaths} from "../../../../routes/RouterPath.js";
import {services} from "../../../../RestServices.js";
import {getStateRoot as getFiltersStateRoot} from "../../filters/filtersReducer.js";
import history from "../../../../history.js";
import {PRODUCTS_TYPE_ALL} from "../../../shared/enums/ProductsType.js";
import {PRODUCTS_TYPE_ACTIVE_ALL} from "../../../shared/enums/ProductsActiveType.js";
import CheckboxMultiselectActions
	from "../../../../../src_shared/list/checkboxMultiselect/CheckboxMultiselectActions";
import {PRODUCTS_SHOP_VISIBILITY_TYPE_ACTIVE_ALL} from "../../../shared/enums/ProductsShopVisibilityType";
import {PRODUCTS_MOBILE_APP_VISIBILITY_TYPE_ACTIVE_ALL} from "../../../shared/enums/ProductsMobileAppVisibilityType";

export class ProductsListActions extends ListActions {
	constructor(config) {
		super(config);
		this.selectedItems = new CheckboxMultiselectActions({
			prefix: this.prefix,
			name: 'selectedItems',
		});
	}

	getParams() {
		return (dispatch, getState) => {
			return getGETParamsUrl(this.getLoadParams(getState()));
		}
	}

	getLoadParams(state) {
		const params = super.getLoadParams(state);
		const type = getFiltersStateRoot(state).data.type;
		const activeType = getFiltersStateRoot(state).data.activeType;
		const shopActive = getFiltersStateRoot(state).data.shopActive;
		const mobileAppActive = getFiltersStateRoot(state).data.mobileAppActive;
		const categories = getFiltersStateRoot(state).data.categories;
		const manufacturers = getFiltersStateRoot(state).data.manufacturers;

		if (type && type !== PRODUCTS_TYPE_ALL) {
			params.type = type
		}

		if (activeType && activeType !== PRODUCTS_TYPE_ACTIVE_ALL) {
			params.active = activeType
		}

		if (shopActive && shopActive !== PRODUCTS_SHOP_VISIBILITY_TYPE_ACTIVE_ALL) {
			params.is_shop_active = shopActive
		}

		if (mobileAppActive && mobileAppActive !== PRODUCTS_MOBILE_APP_VISIBILITY_TYPE_ACTIVE_ALL) {
			params.is_mobile_app_active = mobileAppActive
		}

		if (categories.length) {
			params['categories[]'] = categories
		}

		if (manufacturers.length) {
			params['manufacturers[]'] = manufacturers
		}

		history.replace({
			pathname: this.pathname,
			search: getGETParamsUrl(params)
		});

		return params;
	}
}

export const getInstance = () => new ProductsListActions({
	getStateRoot,
	restService: services.API_PRODUCTS,
	pathname: RouterPaths.ProductsList,
	preventPushParams: true,
	prefix,
	defaultSortBy: 'shop_product_id'
});

export default getInstance();
