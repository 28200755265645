import React, {useRef} from 'react';
import useDropdown from "../../hooks/useDropdown";
import useLang from "../../../../../src_shared/hooks/useLang";

const FiltersDropdown = ({
							 children, onSave, onClose, buttonTittle, className, onSecondButtonClick, tooltipTitle
						 }) => {
	const dropdownRef = useRef(null);
	const buttonRef = useRef(null);
	const {getLangText} = useLang();


	const onSaveClick = () => {
		onSave();
		setOpen(false);
	};

	const onCloseClick = () => {
		onClose();
		setOpen(false);
	};

	const {open, setOpen} = useDropdown(buttonRef, dropdownRef, onCloseClick);

	return (
		<div className={`filters-wrapper ${className}`}>
			<button
				ref={buttonRef}
				type="button"
				onClick={() => setOpen(!open)}
				className={`button filters-button ${tooltipTitle ? 'tooltip-parent' : ''} ${buttonTittle.className} ${open ? 'active' : ''}`}
			>
				{tooltipTitle &&
					<div className="tooltip-content">
						{tooltipTitle}
					</div>
				}
				<div className="d-flex align-items-center flex-grow-1"
					 dangerouslySetInnerHTML={{__html: `${buttonTittle.text}`}}/>
			</button>
			<div
				ref={dropdownRef}
				className={`dropdown-menu ${open ? 'show' : ''}`}
			>
				{children}
				<div className="filters-buttons">
					<button
						type="button"
						className="reset-filter"
						onClick={onSecondButtonClick}
					>
						<span>{getLangText('clearLabel')}</span>
					</button>
					<button
						className="button primary"
						type="button"
						onClick={onSaveClick}
					>
						{getLangText('saveButton')}
					</button>
				</div>
			</div>
		</div>
	);
};

export default FiltersDropdown;
