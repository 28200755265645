import {getStateRoot, prefix} from './listReducer';
import {services} from "../../../../RestServices";
import ListActions from "../../../../../src_shared/list/ListActions";
import {RouterPaths} from "../../../../routes/RouterPath";

export const getInstance = () => new ListActions({
	getStateRoot,
	restService: services.API_STORE_PAGES,
	pathname: RouterPaths.ShopPages,
	prefix,
});

export default getInstance();
