import createReducer from "../../shared/helpers/createReducer";
import Composition from "../../shared/helpers/Composition";
import ListReducer from "../../../../../../src_shared/list/ListReducer";

export const getStateRoot = (state) => state.admin.dashboard.list;
export const prefix = 'ADMIN_DASHBOARD_';

const getInitState = () => ListReducer.getInitState({
	items: {
		bmCommission: {},
		transactions: [],
		users: {
			premiumRegisteredUsers: [],
			registeredFacebookShops: [],
			registeredUsers: [],
			lostClients: [],
		},
		summaries: {
			transactionsSummary: 0,
			premiumRegisteredUsersSummary: 0,
			registeredUsersSummary: 0,
			bmSummary: 0,
		}
	}
});

const getReduceMap = () => new Composition(
	ListReducer.getReduceMap(),
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

