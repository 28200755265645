import ListReducer from "../../../../../../src_shared/list/ListReducer.js";
import createReducer from "../../../../shared/helpers/createReducer.js";
import Composition from "../../../../shared/helpers/Composition.js";

export const getStateRoot = (state) => state.products.categories.products.list;
export const prefix = 'SUB_CATEGORIES_PRODUCTS_LIST_';

const defaultState = {
	sortBy: '',
	sortDir: '',
}

const getInitState = () => ListReducer.getInitState(defaultState);

const getReduceMap = () =>  new Composition(
	ListReducer.getReduceMap(),
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

