import React from 'react';
import {Dropdown} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {
	setMoreActionsClientToggleVisibility
} from "../../../LiveDetailsActions.js";
import MoreActionsDropdown from "./MoreActionsDropdown.jsx";

const MoreActions = ({client, isOrderCreated}) => {

	const dispatch = useDispatch();
	const {moreActionsClientDropdown} = useSelector((state) => state.lives.details)

	const toggleButtonClick = (e) => {
		e.stopPropagation();
		if (+moreActionsClientDropdown === +client.shop_client_id) {
			dispatch(setMoreActionsClientToggleVisibility(null))
			return;
		}
		dispatch(setMoreActionsClientToggleVisibility(client.shop_client_id))
	}

	return (
		<>
			<Dropdown
				className="react-dropdown"
				show={+moreActionsClientDropdown === +client.shop_client_id}
				onClick={(e) => e.stopPropagation()}
			>
				<Dropdown.Toggle
					onClick={(e) => toggleButtonClick(e)}
					id="dropdown-basic"
					className={`dots-horizontal-button ${+moreActionsClientDropdown === +client.shop_client_id ? 'show' : ''}`}
				>
					<i className="icon-dots"/>
				</Dropdown.Toggle>
				{+moreActionsClientDropdown === +client.shop_client_id &&
					<MoreActionsDropdown
						isOrderCreated={isOrderCreated}
						client={client}
					/>
				}
			</Dropdown>
		</>
	)
};

export default MoreActions;
