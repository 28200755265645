import {combineReducers} from "redux";
import listReducer from './list/listReducer'
import reserveListReducer from './reserveList/listReducer'
import removedListReducer from './removedList/listReducer'
import formReducer from "./form/formReducer";
import dataReducer from "./info/dataReducer";
import paymentReceivedFormReducer from "./info/clientPaymentReceived/formReducer";

export default combineReducers({
	list: listReducer,
	reserveList: reserveListReducer,
	removedList: removedListReducer,
	form: formReducer,
	info: dataReducer,
	paymentReceived: paymentReceivedFormReducer,
});
