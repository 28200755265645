export const CUSTOMER_BLOCK = 1;
export const CUSTOMER_PER_PAGE = 2;

const OrdersProductsExport = [
	{
		id: CUSTOMER_BLOCK,
		label: 'clientsUnderneathLabel',
	},
	{
		id: CUSTOMER_PER_PAGE,
		label: 'clientsPerPageLabel',
	},
];

export default OrdersProductsExport;
