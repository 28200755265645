import React, {useState} from 'react';
import AfterCreatedButtons from "../AfterCreatedButtons";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import cn from "clsx";

const Stage = ({setOpenConfirmInvoiceModal, index, data, setStage}) => {
    const {getLangText} = useLang();
    const [open, setOpen] = useState(false);
    const stage = index + 1;
    const created = data.invoices && data.invoices[stage];

    const openConfirmModal = () => {
        setStage(stage);
        setOpenConfirmInvoiceModal(true);
    }

    return (
        <div className="border p-3 rounded-[5px]">
            <button
                style={{cursor: !!created ? '' : 'default'}}
                onClick={() => setOpen(!open)}
                type="button"
                className={cn('flex items-center w-full')}
            >
                <div
                    className={cn('w-[20px] h-[20px] rounded-full flex items-center justify-center bg-[#F3F4F6] text-[8px] mr-2', !!created ? 'bg-[#eaeffd] text-[#3c61ea]' : 'bg-[#f3f4f6] text-[#979797]')}>
                    <i className="icon-new"/>
                </div>
                <div className="font-bold text-sm flex items-center">
                    {getLangText('purchaseLabel')}&nbsp;
                    {stage}
                </div>
                {!!created ?
                    <div
                        className="text-desc ml-auto">
                        <i className="icon-arrows text-[8px]"/>
                    </div> :
                    <button
                        type="button"
                        onClick={openConfirmModal}
                        className="button border-button ml-auto"
                    >
                        {getLangText('generateDocumentLabel')}
                        <i className="icon-generate ml-1"/>
                    </button>
                }
            </button>
            {(open && !!created) &&
                <div className="mt-3">
                    <AfterCreatedButtons
                        stage={created?.order_stage}
                        data={data}/>
                </div>
            }
        </div>
    )
};

export default Stage;