import ListActions from "../../../../../../src_shared/list/ListActions.js";
import {getStateRoot, prefix} from './listReducer.js';
import {RouterPaths} from "../../../../../routes/RouterPath.js";
import {services} from "../../../../../RestServices.js";
import {getStateRoot as getFiltersStateRoot} from "../filters/filtersReducer.js";
import {PRODUCTS_TYPE_ACTIVE_ALL} from "../../../../shared/enums/ProductsActiveType.js";
import axios from "axios";
import {selmoUrl} from "../../../../../../src_shared/api/api";
import {showAlert} from "../../../../shared/components/alert/AlertActions";
import api from "../../../../../../services/axios/axios";

export class ProductsInCategoryListActions extends ListActions {
	getLoadParams(state) {
		const params = super.getLoadParams(state);
		const activeType = getFiltersStateRoot(state).data.activeType;
		const stateRoot = this.getStateRoot(state);

		const superSearch = stateRoot.search;

		if (activeType && activeType !== PRODUCTS_TYPE_ACTIVE_ALL) {
			params.active = activeType
		}

		if (superSearch) {
			params.superSearch = superSearch;
		}

		// history.push({
		// 	pathname: this.pathname,
		// 	search: getGETParamsUrl(params)
		// });

		return params;
	}

	updateItemsOnServer(items, categoryId) {
		return async (dispatch) => {

			dispatch(this.table.updateAllItems(items))

			try {
				await api.post(`${selmoUrl}/${services.API_STORAGE_PRODUCT_ORDER}/${categoryId}`, {items})
				dispatch(showAlert('yuhuLabel', 'successfulSavedOrderLabel'))

			} catch (e) {
				dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
				console.error(e)
			} finally {
			}
		}
	}
}

export const getInstance = () => new ProductsInCategoryListActions({
	getStateRoot,
	restService: services.API_SHOP_SETTINGS_PRODUCTS,
	pathname: RouterPaths.SubCategoryProducts,
	preventPushParams: true,
	prefix,
});

export default getInstance();
