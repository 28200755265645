import React, {useRef} from 'react';
import {Dropdown} from "react-bootstrap";
import useOutsideClick from "../../messenger/hooks/useOutsideClick/useOutsideClick";

const CustomDropdown = ({children, visible, setVisible, className = ''}) => {
	const dropdownRef = useRef(null);

	const toggleButtonClick = (e) => {
		setVisible(!visible)
	}

	useOutsideClick(dropdownRef, () => {
		if (dropdownRef) {
			setVisible(false)
		}
	});

	return (
		<Dropdown
			className={`react-dropdown ${className}`}
			show={visible}
			onClick={(e) => e.stopPropagation()}
		>
			<Dropdown.Toggle
				onClick={(e) => toggleButtonClick(e)}
				id="dropdown-basic"
				className={`dots-horizontal-button ${visible ? 'show' : ''}`}
			>
				<i className="icon-dots"/>
			</Dropdown.Toggle>
			{visible &&
			<Dropdown.Menu ref={dropdownRef}>
				{children}
			</Dropdown.Menu>
			}
		</Dropdown>
	);
};

export default CustomDropdown;
