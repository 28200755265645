import Model from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

export class CategoryModel extends Model {
	getModel() {
		return {
			id: 0,
			name: '',
			description: '',
			shop_homepage_active: '0',
			shop_menu_active: '0',
			shop_active: '0',
			is_from_newest: '0',
			parent_id: null,
			parent_name: '',
		};
	}

	getValidators() {
		return {
			name: ValidationError.notEmpty,
			description: ValidationError.skip,
			shop_homepage_active: ValidationError.skip,
			shop_menu_active: ValidationError.skip,
			shop_active: ValidationError.skip,
			is_from_newest: ValidationError.skip,
			parent_id: ValidationError.skip,
		};
	}

	buildDTO(data) {
		delete data.parent_name;
		return data;
	}
}

export default new CategoryModel();
