import React from 'react';
import SelmoProLandingIntroBox from "../components/SelmoProLandingIntroBox";
import SelmoProLandingTwoColumnBox from "../components/SelmoProLandingTwoColumnBox";
import SelmoProLandingMoreFunctionsBox from "../components/SelmoProLandingMoreFunctionsBox";
import SelmoProLandingMrAutomateBox from "../components/SelmoProLandingMrAutomateBox";
import SelmoProLandingSingleVideoBox from "../components/SelmoProLandingSingleVideoBox";
import SelmoProLandingBenefitsBox from "../components/SelmoProLandingBenefitsBox";

const benefits = [
    {value: '32%', label: 'averageIncreaseInSales',},
    {value: '288h', label: 'timeSavedOnManualActivities'},
]

const MrAutomateLanding = () => {
    return (
        <div>
            <SelmoProLandingIntroBox
                title="mrAutomateWillDoJobForYour"
                imgClassName="sm:-mb-12 -mb-6 sm:mt-0 mt-6 h-[240px] sm:h-[416px] mx-auto"
                imgSrc="/assets/images/robo-landing.webp"
                videoSrc="https://www.youtube.com/embed/tQxPwJ0TP9Q?si=N5exweOZaZmeCJgu&autoplay=1"
            />
            <SelmoProLandingSingleVideoBox
                title="watchVideoAndSeeHowItWorks"
                imgSrc="/assets/images/multiLanguage/pl/selmoProLanding/automate-1.webp"
                videoSrc="https://www.youtube.com/embed/tQxPwJ0TP9Q?si=N5exweOZaZmeCJgu&autoplay=1"
            />
            <SelmoProLandingMrAutomateBox/>
            <SelmoProLandingTwoColumnBox
                label="panAutomacikLabel"
                title="notificationsAboutNotCompletedBaskets"
                desc="notificationsAboutNotCompletedBasketsSubLabel"
                imgSrc="/assets/images/multiLanguage/pl/selmoProLanding/automate-1.webp"
                videoSrc="https://www.youtube.com/embed/tQxPwJ0TP9Q?si=6a46fX5uwxNXlTZ8&autoplay=1&amp;start=27"
            />
            <SelmoProLandingTwoColumnBox
                label="panAutomacikLabel"
                title="cancellingNotPaidOrders"
                desc="cancellingNotPaidOrdersSubLabel"
                imgSrc="/assets/images/multiLanguage/pl/selmoProLanding/automate-2.webp"
                videoSrc="https://www.youtube.com/embed/tQxPwJ0TP9Q?si=6a46fX5uwxNXlTZ8&autoplay=1&amp;start=70"
                rightText
            />
            <SelmoProLandingTwoColumnBox
                label="panAutomacikLabel"
                title="messagesToReserveListClients"
                desc="messagesToReserveListClientsSubLabel"
                imgSrc="/assets/images/multiLanguage/pl/selmoProLanding/automate-3.webp"
                videoSrc="https://www.youtube.com/embed/tQxPwJ0TP9Q?si=6a46fX5uwxNXlTZ8&autoplay=1&amp;start=141"
            />
            <SelmoProLandingTwoColumnBox
                label="panAutomacikLabel"
                title="automaticClosingOfOpenPackage"
                desc="automaticClosingOfOpenPackageSubLabel"
                imgSrc="/assets/images/multiLanguage/pl/selmoProLanding/automate-4.webp"
                videoSrc="https://www.youtube.com/embed/tQxPwJ0TP9Q?si=6a46fX5uwxNXlTZ8&autoplay=1&amp;start=192"
                rightText
            />
            <SelmoProLandingBenefitsBox
                benefits={benefits}
                imgSrc='/assets/images/multiLanguage/pl/selmoProLanding/chart.svg'
            />
            <SelmoProLandingMoreFunctionsBox
                currentPage="mrAutomate"
            />
        </div>
    );
};

export default MrAutomateLanding;
