import ListActions, {getGETParamsUrl} from "../../../../src_shared/list/ListActions";
import {getStateRoot, prefix} from './listReducer';
import {batch} from "react-redux";
import axios from "axios";
import {selmoUrl} from "../../../../src_shared/api/api";
import LangsActions from "../../shared/langs/LangsActions";
import SessionActions from "../../shared/session/SessionActions";
import {services} from "../../../RestServices";
import api from "../../../../services/axios/axios";

export class NewsListActions extends ListActions {

	getLoadParams(state) {
		const stateRoot = this.getStateRoot(state);

		const params = {
			page: stateRoot.page,
		};

		const superSearch = stateRoot.search;
		if (superSearch) {
			params.superSearch = superSearch;
		}

		return params;
	}

	loadData(id) {
		return async (dispatch, getState) => {
			const {firstInit} = getState().session;
			dispatch(this.setScrollPage(1));

			dispatch(this.waiting.startWaiting())
			if (firstInit) {
				dispatch(this.onFirstInit());
			}

			const params = this.getLoadParams(getState(), id)

			try {
				const response = await api.get(`${selmoUrl}/${this.restService}${getGETParamsUrl(params)}`)
				const {items, total, hasMore} = response.data;
				dispatch(this.table.updateAllItems(items))
				dispatch(this.pagination.updateTotalItems(total))


				if (!hasMore) {
					dispatch(this.setHasMorePages(false));
				}

			} catch (e) {
				dispatch(this.table.getListError(e))
				console.error(e)
			} finally {
				if (firstInit) {
					dispatch(SessionActions.setFirstInit());
				}
				dispatch(this.waiting.stopWaiting())
			}
		}
	}

	loadNewPage() {
		return async (dispatch, getState) => {
			try {
				const state = this.getStateRoot(getState());

				dispatch(this.waiting.startWaiting());

				const params = getGETParamsUrl({
					page: state.scrollPage + 1,
				});

				const {data} = await api.get(`${selmoUrl}/${this.restService}${params}`)

				const {items} = state;
				const updatedItems = items.concat(data.items);

				batch(() => {
					dispatch(this.table.updateAllItems(updatedItems));
					dispatch(this.setScrollPage(state.scrollPage + 1));
				});

				if (!data.hasMore) {
					dispatch(this.setHasMorePages(false));
				}
			} finally {
				dispatch(this.waiting.stopWaiting());
			}
		};
	}

	setScrollPage(scrollPage) {
		return {
			type: `${this.prefix}SET_SCROLL_PAGE`,
			scrollPage,
		};
	}

	setHasMorePages(toggle) {
		return {
			type: `${this.prefix}SET_HAS_MORE_PAGES`,
			toggle,
		};
	}
}

export const getInstance = () => new NewsListActions({
	getStateRoot,
	restService: services.API_NEWS,
	prefix,
	preventPushParams: true,
});

export default getInstance();
