import React from 'react';
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../routes/RouterPath";
import useLang from "../../../src_shared/hooks/useLang";
import {useSelector} from "react-redux";
import {TEST_ACCOUNT_SHOP_ID} from "../../../src_shared/enums/TrustedShopsIds";

const AutomationsDashboard = () => {
    const {getLangText} = useLang();
    const {userData} = useSelector((state) => state.session);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getLangText('panAutomacikLabel')} - Selmo</title>
            </Helmet>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <Link to={RouterPaths.Settings}>{getLangText('asideSettingsLabel')}</Link>
                    </li>
                    <li className="active">
                        <div>{getLangText('panAutomacikLabel')}</div>
                    </li>
                </ul>
            </div>
            <div className="settings-page px-sm-0 px-3">
                <section>
                    <div className="section-title">{getLangText('panAutomacikLabel')}</div>
                    <div className="settings-box-wrapper">
                        <div className="settings-box">
                            <Link to={RouterPaths.SettingsAutomaticallyNotifications}>
                                <div className="title">
                                    <img src="/assets/images/svg/notifications-s.svg" alt="overview"/>
                                    {getLangText('automaticallyNotificationsLabel')}
                                </div>
                                <div className="desc">
                                    {getLangText('turnOnAutomaticallyNotificationsLabel')}
                                </div>
                                <div className="button-place">
                                    <div className="button border-button">
                                        {getLangText('button_go_to')}
                                        <i className="icon-arrows"/>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="settings-box">
                            <Link to={RouterPaths.SettingsAutomationsReserveList}>
                                <div className="title">
                                    {/*<img src="/assets/images/svg/facebook-logo-c.svg" alt="overview"/>*/}
                                    {getLangText('automaticReserveList')}
                                </div>
                                <div className="desc">
                                    {/*Manage your Facebook Posts*/}
                                </div>
                                <div className="button-place">
                                    <div className="button border-button">
                                        {getLangText('button_go_to')}
                                        <i className="icon-arrows"/>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        {+userData.id === TEST_ACCOUNT_SHOP_ID &&
                            <>
                                <div className="settings-box">
                                    <Link to={RouterPaths.SettingsAutomationsOrderCancellationConfiguration}>
                                        <div className="title">
                                            {/*<img src="/assets/images/svg/facebook-logo-c.svg" alt="overview"/>*/}
                                            {getLangText('orderCancellation')}
                                        </div>
                                        <div className="desc">
                                            {getLangText('automaticOrderCancellation')}
                                        </div>
                                        <div className="button-place">
                                            <div className="button border-button">
                                                {getLangText('button_go_to')}
                                                <i className="icon-arrows"/>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="settings-box">
                                    <Link to={RouterPaths.SettingsAutomationsOpenPackageClosingConfiguration}>
                                        <div className="title">
                                            {/*<img src="/assets/images/svg/facebook-logo-c.svg" alt="overview"/>*/}
                                            {getLangText('closingOpenPackage')}
                                        </div>
                                        <div className="desc">
                                            {getLangText('automaticClosingOpenPackage')}
                                        </div>
                                        <div className="button-place">
                                            <div className="button border-button">
                                                {getLangText('button_go_to')}
                                                <i className="icon-arrows"/>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </>
                        }
                    </div>
                </section>
            </div>
        </>
    );
};

export default AutomationsDashboard;
