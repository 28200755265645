import React from 'react';
import useFormLocal from "../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../RestServices";
import useLang from "../../../../src_shared/hooks/useLang";
import {Link, useParams} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import useDataApi from "../../../../src_shared/hooks/useDataApi";
import Loader from "../../../app/shared/Loader";
import PackagesForm from "../components/PackagesForm";
import SettingsPackagesModel from "../../../../modules/models/settings/SettingsPackagesModel";
import {Helmet} from "react-helmet";

const PackagesList = () => {
	const {getLangText} = useLang();
	const [{fetchData, data, isLoading}] = useDataApi(services.API_SETTINGS_PACKAGES, [])

	const afterSubmit = async () => {
		updateValues(SettingsPackagesModel.getModel())
		await fetchData();
	}

	const [{
		onSave,
		isWaiting,
		getFieldProps,
		updateValues,
	}] = useFormLocal({
		rest: services.API_SETTINGS_PACKAGES,
		model: SettingsPackagesModel,
		initialAutoLoad: false,
		afterSubmit,
	});

	return (
		<>
			<Helmet>
				<meta charSet="utf-8"/>
				<title>{getLangText('settings_nav_package_types')} - Selmo</title>
			</Helmet>
			<div className="header-bar breadcrumbs-in-react-app">
				<ul className="breadcrumbs">
					<li>
						<Link to={RouterPaths.Settings}>{getLangText('asideSettingsLabel')}</Link>
					</li>
					<li>
						<Link to={RouterPaths.SettingsShop}>{getLangText('settings_nav_shop_settings')}</Link>
					</li>
					<li className="active">
						<div>{getLangText('settings_nav_package_types')}</div>
					</li>
				</ul>
			</div>
			<div className="settings-page sub-settings-page">
				<div className="header-wrapper">
					<h1>
						<img src="/assets/images/svg/packs-s.svg" alt="delivery"/>
						{getLangText('settings_nav_package_types')}
					</h1>
					<div className="description">{getLangText('settings_nav_package_types_description')}</div>
				</div>
				<div className="settings-form">
					<section>
						<div className="left-side">
							<h2 className="label">
								{getLangText('settings_package_types_text_1')}
								<span>{getLangText('settings_package_types_text_2')}</span>
							</h2>
						</div>
						<div className="form-col">
							<PackagesForm
								getFieldProps={getFieldProps}
								onSave={onSave}
								isWaiting={isWaiting}
							/>
						</div>
					</section>
					<section>
						<div className="left-side">
							<h2 className="label">
								{getLangText('input_label_transfer_added_accounts_1')}
								<span>{getLangText('settings_package_types_text_3')}</span>
							</h2>
						</div>
						<div className="form-col loader-parent">
							<Loader isLoading={isLoading}/>
							{data?.length ?
								<div className="div-table">
									<div className="table-head">
										<div className="table-row">
											<div className="name flex-grow-1">{getLangText('name')}</div>
											<div className="small-col">{getLangText('active_label')}</div>
											<div className="action-button-col"/>
										</div>
									</div>
									<div className="table-body">
										{data?.map((item) => (
											<Link
												key={item.id}
												to={`${RouterPaths.SettingsPackagesEdit}/${item.id}/`}
												className="table-row"
											>
												<div className="name flex-grow-1">
													{item.name}
												</div>
												<div className="small-col icon-status">
													{!!+item.active ?
														<i className="icon-tick"/>
														:
														<i className="icon-cross"/>
													}
												</div>
												<div className="action-button-col">
													<div className="button border-button no-hover small-size">
														{getLangText('edit')}
														<i className="icon-arrow"/>
													</div>
												</div>
											</Link>
										))}
									</div>
								</div> :
								<div className="danger-sellfie-alert">
									<i className="icon-info"/>
									<div>
										{getLangText('settings_package_types_text_4')}
									</div>
								</div>
							}
						</div>
					</section>
				</div>
			</div>
		</>
	);
};

export default PackagesList;
