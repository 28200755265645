import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

const usePaginationSelector = (getStateRoot) => {
    const selectPagination = createSelector(getStateRoot,
        (stateRoot) => ({
            currentPage: stateRoot.page,
            itemsPerPage: stateRoot.itemsPerPage,
            totalItems: stateRoot.totalItems,
        }),
    );

    return useSelector(selectPagination);
};

const usePaginationActions = (actionCreators) => {
    const dispatch = useDispatch();
    const changePage = useCallback(
        (nextPage) => dispatch(actionCreators.changePage(nextPage)),
        [actionCreators, dispatch],
    );
    const updateTotalItems = useCallback(
        (total) => dispatch(actionCreators.updateTotalItems(total)),
        [actionCreators, dispatch],
    );
    const setItemPerPage = useCallback(
        (itemsPerPage) => dispatch(actionCreators.setItemPerPage(itemsPerPage)),
        [actionCreators, dispatch],
    );

    return {
        changePage,
        updateTotalItems,
        setItemPerPage,
    };
};

const usePaginationMethods = (dispatchActions, state) => {
    const getLastPage = () => Math.ceil(state.totalItems / state.itemsPerPage) || 1;
    // eslint-disable-next-line prefer-spread
    const getPagination = () => Array.apply(null, { length: getLastPage() })
        .map((item, idx) => ({
            number: idx + 1,
            active: (idx + 1 === state.currentPage),
        }));
    const prevPage = () => {
        const newPage = state.currentPage - 1;

        if (newPage < 1) {
            return;
        }

        dispatchActions.changePage(newPage);
    };
    const nextPage = () => {
        const newPage = state.currentPage + 1;

        if (newPage > getLastPage()) {
            return;
        }

        dispatchActions.changePage(newPage);
    };
    const changePage = (page) => {
        if (page.active) {
            return;
        }

        dispatchActions.changePage(page.number);
    };

    return {
        getLastPage,
        getPagination,
        prevPage,
        nextPage,
        changePage,
    };
};

export const usePaginationReloadEffect = ({
    currentPage, itemsPerPage, loadData, parentId,
}) => {
    const isInitialMount = useRef(true);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            return;
        }

        loadData(parentId || null);
    }, [currentPage, itemsPerPage, loadData, parentId]);
};

export default (actionCreators, getStateRoot) => {
    const state = usePaginationSelector(getStateRoot);
    const dispatchActions = usePaginationActions(actionCreators);
    const methods = usePaginationMethods(dispatchActions, state);

    return {
        ...state,
        ...methods,
    };
};
