export const ORDER_PACKED_FILTER_ALL = 'all';
export const ORDER_PACKED_FILTER = '1';
export const ORDER_UNPACKED_FILTER = '0';


export const ORDER_PACKED_STATUES = [
	{
		value: ORDER_PACKED_FILTER_ALL, label: 'allLabel', color: 'waiting',
	},
	{
		value: ORDER_PACKED_FILTER, label: 'packedLabel', color: 'waiting',
	},
	{
		value: ORDER_UNPACKED_FILTER, label: 'unPackedLabel', color: 'waiting',
	},
]

export const findOrderPackedStatusById = (id) => ORDER_PACKED_STATUES.find((status) => status.value === id);
