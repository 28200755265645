export const checkAndUpdateNotReadConversations = (data) => {
    return (dispatch, getState) => {
        const {activeConversation} = getState().messenger.chatBox;
        if (+activeConversation.id !== +data?.conversationId) {
            dispatch(updateNotReadConversations(data?.count))
        }
    }
}

export const updateNotReadConversations = (count) => {
    return {
        type: 'UPDATE_NOT_READ_CONVERSATIONS',
        count,
    }
}

export const asideSubMenuSetVisible = (toggle, width) => {
    return {
        type: 'SET_SUB_MENU_OPEN_STATE',
        toggle,
        width,
    }
}
