import Composition from "../../shared/helpers/Composition";
import createReducer from "../../shared/helpers/createReducer";
import FormReducer from "../../../../src_shared/form/FormReducer";
import BasketDeliveryMethodModel from "../../../../modules/models/basket/BasketDeliveryMethodModel";

export const getStateRoot = (state) => state.basket.paymentMethods;

export const prefix = 'BASKET_PAYMENT_METHODS_FORM_';

export const getInitState = () => FormReducer.getInitState(BasketDeliveryMethodModel.getModel(), {
	paymentValidationActive: false,
	waiting: false,
})

const getReduceMap = () => new Composition(
	FormReducer.getReduceMap(),
	{
		SET_PAYMENT_METHODS_VALIDATION: (state, action) => ({...state, paymentValidationActive: action.toggle})
	}
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();
