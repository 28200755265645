import React from 'react';
import useLang from "../../../src_shared/hooks/useLang";
import ComponentLoader from "./components/componentLoader/ComponentLoader";

const EmptyList = ({items, isLoading, text, children, title = 'noResultsLabel', className, imgSrc, ExtraComponent}) => {
    const {getLangText} = useLang();

    if (isLoading) return <ComponentLoader/>;

    if (!!items?.length && !isLoading) {
        return children;
    }

    return (
        <div className={`empty-state ${className}`}>
            {imgSrc &&
                <div className="img-icon">
                    <img src={imgSrc}
                         alt=""/>
                </div>
            }
            <div className="title" dangerouslySetInnerHTML={{__html: `${getLangText(title) || title}`}}/>
            <div className="desc">{getLangText(text) || text}</div>
            {ExtraComponent && <ExtraComponent/>}
        </div>
    );
};

export default EmptyList;
