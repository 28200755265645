import React, {useEffect, useRef, useState} from 'react';
import useLang from "../../../../../../src_shared/hooks/useLang";
import CopyButton from "../../../../../../src_shared/components/form/copyButton/CopyButton";
import SwitchField from "../../../../../../src_shared/components/form/switchField/SwitchField";
import ValidationError from "../../../../../../src_shared/form/validation/ValidationError";
import api from "../../../../../../services/axios/axios";
import {services} from "../../../../../RestServices";
import Templates from "./templates/Templates";

const DescriptionField = ({setValue, data, updateValues}) => {
    const {getLangText} = useLang();

    const [showModal, setShowModal] = useState(false);
    const [waitingForSelect, setWaitingForSelect] = useState(false);
    const isInitialMount = useRef(data.facebook_post_description);

    const setSelectedTemplateId = (id) => setValue('facebook_template_id', id);
    const selectedTemplateId = data.facebook_template_id;

    const defaultValues = {
        inStock: 0,
        showPrices: 0,
        withVariants: 1,
    };

    const [values, setValues] = useState(defaultValues);

    const selectTemplate = async (templateId) => {
        setWaitingForSelect(true);
        try {
            const response = await api.post(`${services.API_VIDEO_CHOSEN_TEMPLATE}/${data?.id}`, {
                id: templateId,
                in_stock: values.inStock,
                show_prices: values.showPrices,
                with_variants: values.withVariants,
                products: data.products.map((product) => product.id),
            })
            updateValues({
                facebook_template_id: templateId,
                facebook_post_description: response.data.item?.text,
            })
            setShowModal(false);
        } catch (error) {
            // onError(error)
        } finally {
            setWaitingForSelect(false);
        }
    };

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            return
        }

        if (data.facebook_template_id) {
            selectTemplate(data.facebook_template_id)
        }
    }, [values.inStock, values.showPrices, values.withVariants, data.facebook_template_id, data.products]);

    return (
        <div className="form-group">
            <div className="flex items-end mb-2">
                <label className="control-label mb-0">{getLangText('input_label_package_type_description')}</label>
                <button
                    onClick={() => setShowModal(true)}
                    className="button border-button small-size ml-auto left-icon"
                    type="button"
                >
                    <i className="icon-messenger"/>
                    {getLangText('selectTemplateLabel')}
                </button>
            </div>
            <div className="border-area">
                <textarea
                    placeholder={getLangText('enterDescriptionToPost')}
                    className="form-control mb-0"
                    name="facebook_post_description"
                    rows="10"
                    value={data.facebook_post_description}
                    onChange={(e) => setValue('facebook_post_description', e.target.value)}
                />
                <div className="p-3 pt-4 rounded-b-[5px] bg-[#F9FAFB] mt-3">
                    <SwitchField
                        className="switch-form inline-label align-items-start"
                        label={getLangText('variantsInStockLabel')}
                        subLabel={getLangText('variantsInStockSubLabel')}
                        setValue={(field, value) => setValues({...values, inStock: value})}
                        value={values.inStock}
                        validateErrors={false}
                        getError={ValidationError.skip}
                    />
                    <div>
                        <SwitchField
                            className="switch-form inline-label align-items-start"
                            label={getLangText('showPricesLabel')}
                            subLabel={getLangText('showPricesInVariantsLabel')}
                            setValue={(field, value) => setValues({...values, showPrices: value})}
                            value={values.showPrices}
                            validateErrors={false}
                            getError={ValidationError.skip}
                        />
                    </div>
                    <div>
                        <SwitchField
                            className="switch-form inline-label align-items-start mb-0"
                            label={getLangText('productsWithVariants')}
                            subLabel={getLangText('showProductsWithVariants')}
                            setValue={(field, value) => setValues({...values, withVariants: value})}
                            value={values.withVariants}
                            validateErrors={false}
                            getError={ValidationError.skip}
                        />
                    </div>
                    <div className="bottom-part justify-content-end">
                        <CopyButton
                            copyButtonText={getLangText('copyButton')}
                            copiedButtonText={getLangText('copiedLabel')}
                            copyText={data.facebook_post_description}
                            withIcon={false}
                            withTooltip={false}
                            buttonClassName="border-button copied"
                        />
                    </div>
                </div>
            </div>
            {showModal &&
                <Templates
                    isVisible={showModal}
                    onClose={() => setShowModal(false)}
                    setSelectedTemplateId={setSelectedTemplateId}
                    selectTemplate={selectTemplate}
                    selectedTemplateId={selectedTemplateId}
                />
            }
        </div>
    );
};

export default DescriptionField;
