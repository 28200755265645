import React from 'react';
import CopyButton from "../../../../../src_shared/components/form/copyButton/CopyButton";
import useLang from "../../../../../src_shared/hooks/useLang";

const ProductLink = ({values}) => {
    const {getLangText} = useLang();

    const shopLink = values.checkout_link;

    return (
        <div className="overflow-hidden">
            <h2 className="font-bold mb-3.5">{getLangText('paymentLinkLabel')}</h2>
            <div className="shop-name-bar ml-auto only-link mt-sm-1 mt-3 overflow-hidden">
                <div
                    className="client-link border-0 pb-0 flex-grow overflow-hidden">
                    <div
                        className="form-group with-copy-btn with-preview overflow-visible order-sm-2 order-1 flex-grow">
                        <div className="control-label">{getLangText('basketCartLabel')}</div>
                        <div className="form-control">
                            <a
                                href={shopLink}
                                target="_blank"
                                className="short-link"
                            >
                                {shopLink}
                            </a>
                            <CopyButton
                                buttonClassName="ml-sm-auto border-button only-icon"
                                copyText={shopLink}
                            />
                            <a
                                href={shopLink}
                                className="button border-button small-size mb-0 ml-1 d-lg-flex d-none"
                                target="_blank"
                            >
                                <i className="icon-eye"/>
                                {getLangText('previewLabel')}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductLink;
