export const ADMIN_ADVERTS_FACEBOOK = 'FACEBOOK'
export const ADMIN_ADVERTS_GOOGLE = 'GOOGLE'
export const ADMIN_ADVERTS_LINKEDIN = 'LINKEDIN'
export const ADMIN_ADVERTS_TIKTOK = 'TIKTOK'

export const ADMIN_ADVERTS = [
	{
		value: ADMIN_ADVERTS_FACEBOOK, label: 'Facebook',
	},
	{
		value: ADMIN_ADVERTS_GOOGLE, label: 'Google',
	},
	{
		value: ADMIN_ADVERTS_LINKEDIN, label: 'LinkedIn',
	},
	{
		value: ADMIN_ADVERTS_TIKTOK, label: 'TikTok',
	}
]

export const findSellerById = (value) => ADMIN_ADVERTS.find((i) => i.value === +value);
