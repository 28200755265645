import Model from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

class SettingsPaxyModel extends Model {
	getModel() {
		return {
			api_key: '',
			api_token: '',
			active: '1',
		};
	}

	getValidators() {
		return {
			api_key: ValidationError.notEmpty,
			api_token: ValidationError.notEmpty,
			active: ValidationError.skip,
		};
	}
}

export default new SettingsPaxyModel();
