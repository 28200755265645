import React, {useState} from 'react';
import useList from "../../../../../src_shared/list/useList.js";
import ListActions from "./ListActions.js";
import {getStateRoot} from "./listReducer.js";
import SearchInput from "../../../shared/components/form/SearchInput.jsx";
import FiltersActions from "../../filters/FiltersActions.js";
import ConfirmRemoveModal from "../../../../../src_shared/components/modal/ConfirmRemoveModal";
import useLang from "../../../../../src_shared/hooks/useLang";
import Sortable from "./Sortable";
import AddCategoriesModal from "../modalForm/Form";

const CategoriesList = () => {
	const {getLangText} = useLang();
	const [openAddCategoryModal, setOpenAddCategoryModal] = useState(false);

	const {
		pagination: {totalItems},
		list: {items, isWaiting},
		search,
		remove: {confirmItemRemoval, isModalVisible, cancelItemRemoval, markedToRemove},
	} = useList({
		ListActions,
		getStateRoot,
		FiltersActions,
	});

	return (
		<div className="table-list-wrapper">
			<div className="default-shadow-box pb-0">
				<div className="table-header">
					<div className="top-part flex-wrap">
						<h1 className="title d-block mb-sm-0 mb-3">
							<div className="d-flex align-items-center">
								{getLangText('categoriesListLabel')}
								{totalItems > 0 &&
									<div className="badge">{totalItems}</div>
								}
							</div>
							<div className="sub-title">{getLangText('setCategoryOrderLabel')}</div>
						</h1>
						<SearchInput
							placeholder={getLangText('searchPlaceholder')}
							className="ml-sm-auto mr-0"
							search={search}
						/>
						<button
							type="button"
							onClick={() => setOpenAddCategoryModal(true)}
							className="button primary small-size ml-sm-2 d-lg-inline-block d-none"
						>
							{getLangText('addCategoryLabel')}
						</button>
						<button
							type="button"
							onClick={() => setOpenAddCategoryModal(true)}
							className="button border-button small-size ml-auto d-lg-none left-icon"
						>
							<i className="icon-plus"/>
							{getLangText('addButton')}
						</button>
					</div>
				</div>
				<div className="form-info-box w-100 mb-3">
					<div>
						<i className="icon-info-c"/>
					</div>
					<div>
						<div className="title mb-0">
							{getLangText('whatIsCategoryLabel')}
						</div>
						<div className="desc">
							{getLangText('whatIsCategorySubLabel')}
						</div>
					</div>
				</div>
				<Sortable
					items={items}
					isWaiting={isWaiting}
				/>
			</div>
			{openAddCategoryModal &&
				<AddCategoriesModal
					modalVisible={openAddCategoryModal}
					hideModal={() => setOpenAddCategoryModal(false)}
				/>
			}
			{isModalVisible &&
				<ConfirmRemoveModal
					show={isModalVisible}
					title={getLangText('wantToRemoveCategoryLabel')}
					subTitle={`${markedToRemove?.name}`}
					hide={cancelItemRemoval}
					confirm={confirmItemRemoval}
				/>
			}
		</div>
	);
};

export default CategoriesList;
