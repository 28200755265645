import Model from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

class QRCodeProductModel extends Model {
    getModel() {
        return {
            show_product_name: '0',
            show_product_code: '0',
            show_price: '0',
            show_variant: '0',
            show_date: '0',
            show_weight: '0',
            product_code_font_size: '',
            weight_font_size: '',
        };
    }

    getValidators() {
        return {
            product_code_font_size: ValidationError.combine(
                ValidationError.notEmpty,
                ValidationError.greaterThan(0),
            ),
            show_product_name: ValidationError.skip,
            show_product_code: ValidationError.skip,
            show_price: ValidationError.skip,
            show_variant: ValidationError.skip,
            show_date: ValidationError.skip,
            show_weight: ValidationError.skip,
            weight_font_size: ValidationError.skip,
        };
    }
}

export default new QRCodeProductModel();
