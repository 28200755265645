import Model from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

class SettingsShipmondoDataModel extends Model {
	getModel() {
		return {
			name: '',
			address: '',
			city: '',
			zipcode: '',
			phone: '',
			email: ''
		};
	}

	getValidators() {
		return {
			name: ValidationError.notEmpty,
			address: ValidationError.notEmpty,
			email: ValidationError.notEmpty,
			city: ValidationError.notEmpty,
			zipcode: ValidationError.notEmpty,
			phone: ValidationError.notEmpty,
		};
	}

	buildDTO(data) {
		const {name, address, email, city, zipcode, phone} = data;
		return {
			name,
			address,
			email,
			city,
			zipcode,
			phone,
		}
	}
}

export default new SettingsShipmondoDataModel();
