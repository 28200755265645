import React from 'react';
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../../routes/RouterPath";
import useLang from "../../../../../../src_shared/hooks/useLang";

const AutomaticTransferBigBox = ({data}) => {
    const {getLangText} = useLang();

    return (
        <div className="settings-box blue-media col-md-4">
            <Link to={RouterPaths.SettingsAutomaticTransferLanding}>
                <div
                    style={{fontSize: 16}}
                    className="title flex align-items-center">
                    Automatyczne przelewy standardowe
                    <img style={{width: 60}} className="ml-2 w-[60px]" src="/assets/images/payments/bluemedia.svg" alt=""/>
                </div>
                <div className="desc">
                    Uwolnij się od ręcznego sprawdzania przelewów
                </div>
                <div className="benefits mb-3">
                    <div className="flex">
                        <i className="icon-tick-c"/>
                        <span>Przelew księguje się <strong>automatycznie</strong></span>
                    </div>
                    <div className="flex">
                        <i className="icon-tick-c"/>
                        <span><strong>Status w zamówieniu</strong> zmienia się na “opłacone”</span>
                    </div>
                    <div className="flex">
                        <i className="icon-tick-c"/>
                        <span>
                            <strong>Środki są zwracane</strong> w przypadku błędnych danych'
                        </span>
                    </div>
                </div>
                <div className="button-place">
                    <div className="flex-grow-1">
                        {!!+data.bm_transfer_active ?
                            <div className="small-status green">
                                <i className="icon-tick"/>
                                {getLangText('active_label')}
                            </div>
                            :
                            <div className="small-status red">
                                <i className="icon-cross"/>
                                {getLangText('not_active_label')}
                            </div>
                        }
                    </div>
                    <div className="button primary small-size">
                        {getLangText('button_go_to')}
                        <i className="icon-arrows" style={{position: 'relative', top: '-2px'}}/>
                    </div>
                </div>
            </Link>
        </div>
    );
};

export default AutomaticTransferBigBox;
