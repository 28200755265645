import createReducer from "../../../shared/helpers/createReducer.js";

export const getStateRoot = (state) => state.shared.shopSettingsLoader;

const getInitState = {
	isChanging: null,
}

const getReduceMap = {
	SHOP_SETTINGS_LOADER_VISIBILITY: (state, action) => ({...state, isChanging: action.date}),
}

export const getInstance = () => createReducer(
	getInitState,
	getReduceMap,
);

export default getInstance();

