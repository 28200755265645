import React from "react";
import Field from "../../../../../src_shared/components/form/field/Field";
import TextArea from "../../../../../src_shared/components/form/textarea/TextArea";
import Tags from "./components/Tags";
import useLang from "../../../../../src_shared/hooks/useLang";

const NoteForm = ({values, getFieldProps, onSave, setValue}) => {
	const {getLangText} = useLang();

	return (
		<>
			<section className="delivery-section">
				<div className="tracking-link">
					<div className="form-group with-save-btn absolute-btn">
						<h3 className="mb-2">{getLangText('linkToTracking')}</h3>
						<Field
							{...getFieldProps('tracking_link')}
							value={values.tracking_link}
							showLabel={false}
							setValue={setValue}
							placeholder={getLangText('pasteLinkLabel')}
						/>
						<button
							type="button"
							className="button border-button"
							onClick={onSave}
						>
							{getLangText('saveButton')}
						</button>
					</div>
				</div>
			</section>
			<section className="delivery-section">
				<div className="tracking-link">
					<div className="form-group">
						<h3 className="mb-2">{getLangText('orderNoteLabel')}</h3>
						<TextArea
							{...getFieldProps('note')}
							value={values.note}
							showLabel={false}
							setValue={setValue}
							placeholder={getLangText('textNoteLabel')}
						/>
						<Tags
							tags={values.tags}
							setValue={setValue}
						/>
						<div className="d-flex justify-content-end mt-2">
							<button
								onClick={onSave}
								type="button"
								className="button border-button"
							>
								{getLangText('saveButton')}
							</button>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default NoteForm;



