import {getStateRoot, prefix} from "./formReducer.js";
import FormActions from "../../../../src_shared/form/FormActions.js";
import {services} from "../../../RestServices.js";
import ShopSettingsMainPageModel from "../../../../modules/models/shopSettings/MainPage";
import {showAlert} from "../../shared/components/alert/AlertActions";
import axios from "axios";
import {selmoUrl} from "../../../../src_shared/api/api";
import {batch} from "react-redux";
import LangsActions from "../../shared/langs/LangsActions";
import api from "../../../../services/axios/axios";

export class ShopSettingsMainPageFormActions extends FormActions {

	loadData(id) {
		return async (dispatch) => {
			dispatch(this.waiting.startWaiting());
			dispatch(this.onFirstInit());
			try {
				const {data} = await api.get(`${selmoUrl}/${this.restService}`)
				const parsedItem = dispatch(this.parseData(data.item));
				dispatch(this.data.updateValues({
					banners: parsedItem.banners,
					defaultBanners: parsedItem.banners,
				}))
			} catch (e) {
				dispatch(this.onErrorLoad(e))
				console.error('Error loadData')
			} finally {
				dispatch(this.waiting.stopWaiting());
			}
		}
	}

	submitEditForm(values, afterSubmit) {
		return async (dispatch) => {

			dispatch(this.validation.activateValidation());

			if (values.banners.some((i) => !i.photo)) {
				dispatch(showAlert('fillRequireFieldWarning', '', 'alert-fail'))
				console.error('Validation failed!');
				return;
			}

			const filteredData = Object.entries(values).filter(([key]) => key !== 'id');
			const finalData = Object.fromEntries(filteredData)

			const preparedData = this.dataModel.buildDTO(finalData)

			dispatch(this.waiting.startWaiting());

			try {
				await api.post(`${selmoUrl}/${this.restService}`, preparedData)
				batch(() => {
					dispatch(showAlert('yuhuLabel', 'changesWillBeVisibleInFewMinutesLabel'))
					dispatch(this.validation.deactivateValidation())
					dispatch(this.loadData())
					afterSubmit();
				});
			} catch (e) {
				dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
				console.error('Error submitEditForm')
			} finally {
				dispatch(this.waiting.stopWaiting());
			}
		}
	}

	addNewVariant() {
		return async (dispatch, getState) => {

			try {
				const {data} = await api.post(`${selmoUrl}/${services.API_STORE_BANNER}`)
				const stateRoot = this.getStateRoot(getState());
				const variant = {
					id: data.id,
					url: '',
					photo: null,
					theme: 'light',
				};

				const oldVariantList = stateRoot.data.banners;
				const newVariantsList = [...oldVariantList, variant]

				dispatch(this.data.setValue('banners', newVariantsList));
			} catch (e) {

			}
		};
	}

	removeVariant(id) {
		return (dispatch, getState) => {
			const stateRoot = this.getStateRoot(getState());

			const oldVariantList = stateRoot.data.banners;
			const preparedVariantList = oldVariantList.filter((i) => i.id !== id)

			// if (!preparedVariantList.length) {
			// 	const newId = this.dataModel.createNewId();
			//
			// 	const variant = {
			// 		id: newId,
			// 		url: '',
			// 		photo: null,
			// 	};
			// 	const newVariantsList = [variant]
			//
			// 	dispatch(this.data.setValue('banners', newVariantsList));
			// 	return;
			// }

			dispatch(this.data.setValue('banners', preparedVariantList));
		};
	}
}

export const getInstance = () => new ShopSettingsMainPageFormActions({
	getStateRoot,
	prefix,
	model: ShopSettingsMainPageModel,
	restService: services.API_STORE_BANNERS,
});

export default getInstance();
