import React, {useState} from 'react';
import {Menu} from "@headlessui/react";
import Dropdown from "../../../../../../../src_shared/components/ui/Dropdown/Dropdown";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import useSubmitData from "../../../../../../../src_shared/hooks/useSubmitData";
import {services} from "../../../../../../RestServices";
import {useParams} from "react-router-dom";
import ConfirmModal from "../../../../../orders/list/components/export/ConfirmModal";
import {filesSetModalVisibility} from "../../../../../shared/header/files/filesDropdown/FilesActions";
import {useDispatch, useSelector} from "react-redux";
import PrintSelectedVariantsModal from "./PrintSelectedVariants";
import {SHOP_BAZAR_SHOP_ID, TEST_ACCOUNT_SHOP_ID} from "../../../../../../../src_shared/enums/TrustedShopsIds";

const MoreActions = () => {
    const {getLangText} = useLang();
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [showPrintQrCodeModal, setShowPrintQrCodeModal] = useState(false);
    const {id} = useParams();
    const {userData} = useSelector((state) => state.session);

    const [{onSave, isWaiting}] = useSubmitData(
        {
            method: 'get',
            rest: `${services.API_EXPORT_PRINT_SIZES_QR_CODES}/${id}`,
            afterSubmit: () => {
                dispatch(filesSetModalVisibility(true));
                setShowModal(false);
            }
        });

    const HeaderButton = () => (
        <div className="button border-button small-size left-icon">
            <i
                style={{top: 0}}
                className="icon-plus"/>
            {getLangText('moreLabel')}
        </div>
    );

    return (
        <>
            <Dropdown
                Button={HeaderButton}
                dropdownClassName="ml-auto mb-3"
                dropdownContentClassName="ml-auto"
            >
                <Menu.Item>
                    <button
                        onClick={() => setShowModal(true)}
                        type="button"
                        className="text-left p-2.5 min-h-[36px] whitespace-nowrap w-full hover:bg-gray-100 rounded-sm px-2 py-[6px] transition-all flex items-center text-[#4B5563] text-xs hover:text-[#1F2937] font-semibold hover:no-underline"
                    >
                        {getLangText('printQrCodes')}
                    </button>
                </Menu.Item>
                {!!+userData.selmo_pro_active &&
                    <Menu.Item>
                        <button
                            onClick={() => setShowPrintQrCodeModal(true)}
                            type="button"
                            className="text-left p-2.5 min-h-[36px] whitespace-nowrap w-full hover:bg-gray-100 rounded-sm px-2 py-[6px] transition-all flex items-center text-[#4B5563] text-xs hover:text-[#1F2937] font-semibold hover:no-underline"
                        >
                            {getLangText('printSelectedQrCodes')}
                        </button>
                    </Menu.Item>
                }
            </Dropdown>
            {showModal &&
                <ConfirmModal
                    title={getLangText('printVariants')}
                    SubTitle={() => getLangText('wantToPrintQRCodesForEveryVariants')}
                    saveButton={getLangText('yesButton')}
                    show={showModal}
                    isWaiting={isWaiting}
                    onSubmit={onSave}
                    hide={() => setShowModal(false)}
                />
            }
            {showPrintQrCodeModal &&
                <PrintSelectedVariantsModal
                    hideModal={() => setShowPrintQrCodeModal(false)}
                    modalVisible={showPrintQrCodeModal}
                    id={id}
                />
            }
        </>
    );
};

export default MoreActions;
