export const CLIENT_STRIPE = 'stripe'
export const CLIENT_TRANSFER = 'transfer'
export const CLIENT_CARD = 'stripe_card'
export const CLIENT_PAYPAL = 'paypal'

export const paymentTypes = [
	{
		value: CLIENT_STRIPE, label: 'Stripe'
	},
	{
		value: CLIENT_TRANSFER, label: 'Przelew tradycyjny'
	},
	{
		value: CLIENT_CARD, label: 'Płatność kartą'
	},
	{
		value: CLIENT_PAYPAL, label: 'Paypal'
	},
]

