import React, {useState} from 'react';
import useLang from "../../../../../../src_shared/hooks/useLang";
import {services} from "../../../../../RestServices";
import Loader from "../../../../shared/Loader";
import ConfirmModal from "../../../../orders/list/components/export/ConfirmModal";
import {usePrintFile} from "../../../../../../src_shared/hooks/usePrintFile";

const PrintAllProductCard = () => {
    const {getLangText} = useLang();
    const [openModal, setOpenModal] = useState(false);

    const {print, waiting} = usePrintFile({
        rest: services.API_EXPORT_PRODUCTS_CARD_INFO,
        afterSubmit: () => setOpenModal(false)
    })

    return (
        <>
            <button
                style={{height: 33}}
                type="button"
                onClick={() => setOpenModal(true)}
                className={`button border-button ml-2 mr-2 sm:my-0 my-2.5`}
            >
                {waiting && <Loader isLoading/>}
                {getLangText('printAllProductCards')}
            </button>
            {openModal &&
                <ConfirmModal
                    title={getLangText('wantToPrintAllProductCards')}
                    saveButton={getLangText('yesButton')}
                    show={openModal}
                    isWaiting={waiting}
                    onSubmit={print}
                    hide={() => setOpenModal(false)}
                />
            }
        </>
    );
};

export default PrintAllProductCard;
