import React, {useEffect} from 'react';
import useDataApi from "../../../src_shared/hooks/useDataApi";
import {services} from "../../RestServices";

const InstagramCallback = ({location}) => {
	const params = location.hash.replace('#access_token=', '');

	const [{fetchData}] = useDataApi(services.API_SETTINGS_INSTAGRAM_CONFIGURE, {}, [], false, {access_token: params})


	useEffect(() => {
		if (!!params) {
			fetchData()
		}
	}, [params])


	return (
		<div>
			<h1>Waiting for Instagram</h1>
		</div>
	);
};

export default InstagramCallback;
