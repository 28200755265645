import React, {useState} from 'react';
import moment from "../../messenger/helpers/moment";
import ServerImage from "../../shared/components/serverImage/ServerImage";
import Loader from "../../shared/Loader";
import api from "../../../../services/axios/axios";
import {selmoUrl} from "../../../../src_shared/api/api";
import {convertToUserTimeZone} from "../../shared/helpers/dateHelpers";

const Post = ({item}) => {
    const [isWaiting, setIsWaiting] = useState(false);

    async function downloadVideoFromSubdomain() {
        const videoUrl = `${selmoUrl}/api/appVideo?file_link=${item.link}`;
        const originalFileName = item.link.split('/').pop();

        setIsWaiting(true);

        try {
            const response = await api.get(videoUrl, { responseType: 'blob' });

            const url = window.URL.createObjectURL(response.data);
            const a = document.createElement('a');
            a.href = url;
            a.download = originalFileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        } finally {
            setIsWaiting(false);
        }
    }

    return (
        <div
            key={item.id}
            className="news dashboard-card mb-3"
        >
            <div className="top-bar">
                <div className="text-[#6B7280] text-xs">
                    <i className="icon-date mr-1"/>
                    {convertToUserTimeZone(item.date).format('DD.MM.YYYY')}
                </div>
                {!!item.link &&
                    (!!+item.is_download ?
                        <button
                            type="button"
                            onClick={downloadVideoFromSubdomain}
                            className={`button primary small-size ${(isWaiting) ? 'loading-button' : ''}`}
                        >
                            <Loader isLoading={isWaiting}/>
                            {item.link_text}
                            <i className="icon-arrows"/>
                        </button> :
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href={item.link}
                            className="button primary small-size"
                        >
                            {item.link_text}
                            <i className="icon-arrows"/>
                        </a>)
                }
            </div>
            <div className="dashboard-card-title mb-3 mt-2">{item.title}</div>
            <div className="dashboard-card-subtitle" dangerouslySetInnerHTML={{__html: item.description}}/>
            {item.photo && item.photo !== '0' &&
                (item.link ?
                        (!!+item.is_download ?
                            <button
                                type="button"
                                onClick={downloadVideoFromSubdomain}
                                className="d-block w-100 my-4"
                            >
                                <figure className="my-0">
                                    <ServerImage src={item.photo}/>
                                </figure>
                            </button> :
                            <a
                                rel="noreferrer"
                                target="_blank"
                                href={item.link}
                                className="d-block w-100 my-4"
                            >
                                <figure className="my-0">
                                    <ServerImage src={item.photo}/>
                                </figure>
                            </a>
                        ) :
                        <figure className="my-4">
                            <ServerImage src={item.photo}/>
                        </figure>
                )
            }
        </div>
    );
};

export default Post;
