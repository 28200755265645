import ListReducer from "../../../../src_shared/list/ListReducer";
import createReducer from "../../shared/helpers/createReducer";
import Composition from "../../shared/helpers/Composition";
import CheckboxMultiselectReducer from "../../../../src_shared/list/checkboxMultiselect/CheckboxMultiselectReducer";

const multiSelectReducer = CheckboxMultiselectReducer
	.buildReducer('selectedItems', 'selectedItems');

export const getStateRoot = (state) => state.orders.list;
export const prefix = 'ORDERS_';

const getInitState = () => ListReducer.getInitState({
	selectedRows: [],
	hasTags: false,
	selectedItems: [],
	modalVisibleType: null,
	waitingForDocument: false,
	successGenerated: false,
	generatedOrdersCount: {},
	isTemplateSelectedError: false,
});

const getReduceMap = () =>  new Composition(
	ListReducer.getReduceMap(),
	multiSelectReducer.reduceMap,
	{
		SET_SELECTED_TABLE_ROW: (state, action) => ({...state, selectedRows: [...state.selectedRows, action.row]}),
		SET_HAS_TAGS: (state, action) => ({...state, hasTags: action.hasTags}),
		SET_DOCUMENT_MODAL_VISIBLE: (state, action) => ({...state, modalVisibleType: action.modalType}),
		SET_WAITING_FOR_DOCUMENT: (state, action) => ({...state, waitingForDocument: action.toggle}),
		SET_SUCCESS_GENERATE_VIEW: (state, action) => ({...state, successGenerated: action.toggle}),
		SET_GENERATED_ORDERS_COUNT: (state, action) => ({...state, generatedOrdersCount: action.ordersCount}),
		SET_GENERATE_LABEL_TEMPLATE_ERROR: (state, action) => ({...state, isTemplateSelectedError: action.toggle}),
	}
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

