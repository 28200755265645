import Composition from "../../../../shared/helpers/Composition";
import FormReducer from "../../../../../../src_shared/form/FormReducer";
import createReducer from "../../../../shared/helpers/createReducer";

export const getStateRoot = (state) => state.orders.details.inpostShipping;
export const prefix = 'ORDERS_DETAILS_INPOST_SHIPPING_';

const getInitState = () => FormReducer.getInitState();

const getReduceMap = () => new Composition(
	FormReducer.getReduceMap(),
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

