import React, {useState} from 'react';
import Field from "../../../src_shared/components/form/field/Field";
import useLang from "../../../src_shared/hooks/useLang";
import axios from "axios";
import {selmoUrl} from "../../../src_shared/api/api";
import {services} from "../../RestServices";
import {showAlert} from "../shared/components/alert/AlertActions";
import {useDispatch} from "react-redux";
import Loader from "../shared/Loader";
import {Link, useHistory} from "react-router-dom";
import {RouterPaths} from "../../routes/RouterPath";
import {Helmet} from "react-helmet";
import api from "../../../services/axios/axios";
import Button from "../../../src_shared/components/ui/Button/Button";

const ForgetPasswordPage = () => {
    const {getLangText} = useLang();
    const dispatch = useDispatch();
    const [waiting, setWaiting] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [values, setValues] = useState({
            email: '',
        }
    );

    const setValue = (field, value) => setValues({...values, [field]: value})

    const onSubmit = async (e) => {
        e.preventDefault();
        setWaiting(true);
        setSuccess(false);
        try {
            await api.post(`${selmoUrl}/${services.API_FORGET_PASSWORD}`, {
                email: values.email,
            })
            setSuccess(true)
            dispatch(showAlert('successfullySentMessageOnEmailLabel', ''));

        } catch (e) {
            setError(e.response.data.message)
            dispatch(showAlert(e.response.data.message, '', 'alert-fail'))
        } finally {
            setWaiting(false)
        }
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getLangText('remindPasswordLabel')} - Selmo</title>
            </Helmet>
            <a href="https://selmo.io">
                <img alt="selmo" width={107} src="/assets/images/logo.svg"/>
            </a>
            <h1 className="text-3xl font-bold mt-6 mb-8"
                dangerouslySetInnerHTML={{__html: getLangText('remindPasswordLabel')}}/>
            <form
                id="forget-password-form"
                onSubmit={onSubmit} method="post">
                {success &&
                    <div className="form-info-box green w-100 mb-2">
                        <div>
                            <i className="icon-tick-c"/>
                        </div>
                        <div className="title">
                            <span
                                className="text-[#0EB981]"> {getLangText('successfullySentMessageOnEmailLabel')}</span>
                        </div>
                    </div>
                }
                {error &&
                    <div className="form-info-box alert-style red w-100 mb-2">
                        <div>
                            <i className="icon-info-c"/>
                        </div>
                        <div className="title">
                            {getLangText(error)}
                        </div>
                    </div>
                }
                <Field
                    value={values.email}
                    label={getLangText('emailLabel')}
                    setValue={setValue}
                    type="email"
                    name="email"
                    extraProps={{required: true}}
                    className="big-size"
                    floatedLabel
                />
                <div className="button-place">
                    <Button
                        type="submit"
                        isWaiting={waiting}
                        title="remindPasswordLabel"
                        className="button primary btn-proceed w-full"
                    />
                </div>
            </form>
            <div className="change-page">
                <Link to={RouterPaths.Login} className="special-link">
                    {getLangText('backToLoginLabel')}
                </Link>
            </div>
        </>
    );
};

export default ForgetPasswordPage;
