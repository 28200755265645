import React from 'react';
import Field from "../../../../../../../src_shared/components/form/field/Field";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import CheckboxField from "../../../../../../../src_shared/components/form/checkboxField/CheckboxField";
import {Link} from "react-router-dom";

const SelmoPaczkaDataForm = ({getFieldProps, values}) => {
    const {getLangText} = useLang();

    const checkboxLabel = () => {
        return (
            <>
                {getLangText('customerBasketAgreementLabel1')}
                &nbsp;
                <Link
                    target="_blank"
                    to="/selmo-paczka-terms"
                    style={{position: "relative", zIndex: '1', textDecoration: 'underline'}}
                >
                    {getLangText('customerBasketAgreementLabel2')}
                </Link>
            </>
        )
    }

    return (
        <div>
            <div className="grid grid-cols-2 gap-x-5">
                <Field
                    {...getFieldProps('first_name')}
                    label={getLangText('firstNameLabel')}
                    placeholder={getLangText('enterName')}
                />
                <Field
                    {...getFieldProps('last_name')}
                    label={getLangText('lastNameLabel')}
                    placeholder={getLangText('enterSurname')}
                />
                {/*<div className="col-span-2">*/}
                    <Field
                        {...getFieldProps('company_name')}
                        label={getLangText('companyNameLabel')}
                        placeholder={getLangText('enterCompanyName')}
                    />
                {/*</div>*/}
                <Field
                    {...getFieldProps('nip')}
                    label="NIP"
                    placeholder={getLangText('enterNip')}
                />
                <Field
                    {...getFieldProps('street')}
                    label={getLangText('streetLabel')}
                    placeholder={getLangText('enterStreetName')}
                />
                <Field
                    {...getFieldProps('building_number')}
                    label={getLangText('houseNumber')}
                    placeholder={getLangText('enterHouseNumber')}
                />
                <Field
                    {...getFieldProps('city')}
                    label={getLangText('cityLabel')}
                    placeholder={getLangText('enterCity')}
                />
                <Field
                    {...getFieldProps('zip_code')}
                    label={getLangText('zipCodeLabel')}
                    placeholder={getLangText('enterZipCode')}
                />
                <Field
                    {...getFieldProps('phone')}
                    label={getLangText('phoneLabel')}
                    placeholder={getLangText('typePhoneNumberLabel')}
                />
                <Field
                    {...getFieldProps('email')}
                    label={getLangText('emailLabel')}
                    placeholder={getLangText('textEmailLabel')}
                />
            </div>
            <CheckboxField
                {...getFieldProps('agreement')}
                LabelComponent={checkboxLabel()}
            />
        </div>
    );
};

export default SelmoPaczkaDataForm;
