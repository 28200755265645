import React from 'react';
import PropTypes from 'prop-types';
import {Route, Redirect} from 'react-router-dom';
import {RouterPaths} from "./RouterPath";
import {useSelector} from "react-redux";

export const PrivateRoute = ({component: Component, ...rest}) => {
	const {logged, userData} = useSelector((state) => state.session);

	if (!logged) {
		if (+userData.client_type === 2) {
			return <Redirect to={RouterPaths.LoginInstagram}/>;
		}
		return <Redirect to={RouterPaths.Auth}/>;
	}

	return (
		<Route
			component={Component}
			{...rest}
		/>
	);
};

PrivateRoute.propTypes = {
	component: PropTypes.elementType,
};

PrivateRoute.defaultProps = {
	component: undefined,
};

export default PrivateRoute;
