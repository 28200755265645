import createReducer from "../../shared/helpers/createReducer";
import DataReducer from "../../../../src_shared/form/data/DataReducer";
import {getReduceMap} from "../../../../src_shared/filters/filterReducer";
import history from "../../../history";
import {parseParams} from "../../../../src_shared/list/ListActions";
import moment from "moment";
import {ALL_ORDERS_VIEW, INTERVAL} from "../../shared/enums/OrderViewTypes";

const queryParams = history.location.search;
const parsedParams = parseParams(queryParams)

const preparedViewType = !!queryParams ? (parsedParams.dateFrom ? INTERVAL : ALL_ORDERS_VIEW) : INTERVAL;
const preparedDate = (initialState, paramsDate) => {
	if (!!queryParams) {
		if (parsedParams[paramsDate]) {
			return moment(parsedParams[paramsDate]).toDate()
		} else {
			return null;
		}
	} else {
		return initialState;
	}
}

export const getStateRoot = (state) => state.selmoPaczka.filters;

export const getInitState = () => DataReducer.getInitState({
	fromDate: preparedDate(moment(new Date()).subtract(1, 'M').toDate(), 'dateFrom'),
	toDate: preparedDate(new Date(), 'dateTo'),
	viewType: preparedViewType,
}, {
	fromDate: moment(new Date()).subtract(1, 'M').toDate(),
	toDate: moment().toDate(),
	viewType: INTERVAL,
})

export const prefix = 'SELMO_PACZKA_TRANSACTIONS_FILTERS_';

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();
