import {prefix} from "./formReducer";
import {getStateRoot} from "./formReducer";
import PaymentsModel from "../../../modules/models/PaymentsModel";
import {services} from "../../RestServices";
import {selmoUrl} from "../../../src_shared/api/api";
import PaymentsSharedFormActions from "./shared/PaymentsSharedFormActions";
import api from "../../../services/axios/axios";

export class PaymentFormActions extends PaymentsSharedFormActions {

	setSummaryInfo(item) {
		return {
			type: `${this.prefix}SET_SUMMARY_INFO`,
			item,
		};
	}

	getTotalPrice() {
		return async (dispatch, getState) => {
			const {data} = getStateRoot(getState());
			const {user_info} = getState().session.userData;

			const preparedData = {
				...data,
				selected_subscription: !!+data.extend_plan ? data.selected_subscription : null,
			}

			delete preparedData.functionalities;
			delete preparedData.subscriptions;
			delete preparedData.nip;
			delete preparedData.extend_plan;

			const url = !!user_info.premium_date ? services.API_PAYMENTS_EXTEND_TOTAL_PRICE : services.API_PAYMENTS_TOTAL_PRICE

			dispatch(this.setWaitingForTotalPrice(true))

			try {
				const response = await api.post(`${selmoUrl}/${url}`, preparedData)
				const {item} = response.data
				dispatch(this.setSummaryInfo({
					...item,
					functionalities: item.functionalities || {},
				}))

			} catch (e) {

			} finally {
				dispatch(this.setWaitingForTotalPrice(false))
			}
		}
	}
}

export const getInstance = () => new PaymentFormActions({
	prefix,
	getStateRoot,
	restService: services.API_PAYMENTS,
	model: PaymentsModel,
});

export default getInstance();

