import React from 'react';
import Chart from "react-apexcharts";
import EmptyList from "../../../../../shared/EmptyList";
import useLang from "../../../../../../../src_shared/hooks/useLang";

const PaymentChart = ({items}) => {
    const {getLangText} = useLang();

    const getPercentValue = (value) => Math.round((100 * +value) / totalSum);
    const totalSum = Object.values(items.paymentInfo).reduce((prev, curr) => +prev + +curr, []);

    const preparedPaymentsInfoLabels = Object.keys(items.paymentInfo).map((i) => i);
    const preparedPaymentsInfo = Object.values(items.paymentInfo).map((i) => getPercentValue(i));

    const options = {
        chart: {
            id: "payments-donuts",
            type: 'donut',
        },
        colors: [
            '#F29486',
            '#8578F1',
            '#FFC164'
        ],
        opacity: 1,
        stroke: {
            width: 3,
        },
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 5,
                dataLabels: {
                    orientation: 'vertical',
                    position: 'center',
                    enable: false,
                    show: false,
                },
                columnWidth: 35,
            },
        },
        fill: {
            type: 'gradient',
            gradient: {
                gradientToColors: [
                    "#E86272",
                    "#B78EFA",
                    "#FFD492",
                ],
            }
        },
        legend: {
            show: false
        },
        dataLabels: {
            enabled: false
        },
        labels: Object.keys(items.paymentInfo).map((i) => i),
        tooltip: {
            custom: function ({series, seriesIndex, dataPointIndex, w}) {
                return `<div class="chart-tooltip">
<div class="label">${w.config.labels[seriesIndex]}</div>
<div class="value">${series[seriesIndex]} %</div>
</div>`
            }
        }
    };

    return (
        <div className="border h-full border-[#E5E7EB] rounded-[5px] px-[20px] py-6">
            <div className="donuts-chart-wrapper">
                <div className="title">
                    Źródła interakcji
                </div>
                <EmptyList
                    className="full-size"
                    imgSrc="/assets/images/empty/chart-3.svg"
                    items={items.ordersInfo}
                    title=""
                >
                    <div className="donuts-chart bigger-size">
                        <Chart
                            options={options}
                            series={preparedPaymentsInfo}
                            type="donut"
                            width="100%"
                        />
                    </div>
                </EmptyList>
                <div className="custom-legend">
                    {preparedPaymentsInfoLabels.map((i) => (
                        <div
                            key={i}
                        >
                            <div
                                className="label">{i}</div>
                            <div className="value">
                                {getPercentValue(items.paymentInfo[i])} %<strong>{items.paymentInfo[i]}</strong>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PaymentChart;
