import React from 'react';
import Modal from "react-bootstrap/Modal";
import {useDispatch, useSelector} from "react-redux";
import {filesSetModalVisibility} from "../filesDropdown/FilesActions";
import useLang from "../../../../../../src_shared/hooks/useLang";
import Loader from "../../../Loader";

const FilesModal = () => {
    const {getLangText} = useLang();
    const dispatch = useDispatch();
    const {isModalOpen} = useSelector((state) => state.shared.files);
    const isWaiting = false;

    const hideModal = () => dispatch(filesSetModalVisibility(false));

    return (
        <Modal
            dialogClassName={`default-modal w-380`}
            show={isModalOpen}
            onHide={hideModal}
        >
            <Modal.Header>

                <Modal.Title>
                    {/*{title}*/}
                    {/*{SubTitle &&*/}
                    {/*    <div className="description">*/}
                    {/*        <SubTitle/>*/}
                    {/*    </div>*/}
                    {/*}*/}
                </Modal.Title>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            <Modal.Body className="mb-3 pt-0">
                <div className="flex items-center justify-center flex-col">
                    <Loader isLoading={true}
                            className="w-[12px] static-loader small-loader mt-[6px] transform-none"/>
                    <div className="font-bold text-sm mt-3">Trwa pobieranie dokumentu</div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button border-button mr-0"
                >
                    {getLangText('closeButton')}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default FilesModal;