import ListActions from "../../../../../src_shared/list/ListActions.js";
import {getStateRoot, prefix} from "./listReducer.js";
import {RouterPaths} from "../../../../routes/RouterPath.js";
import {services} from "../../../../RestServices.js";

export const getInstance = () => new ListActions({
	getStateRoot,
	restService: services.API_STORAGE_REELS,
	prefix,
	pathname: RouterPaths.MobileReelsList
});

export default getInstance();
