import ListReducer from "../../../../../src_shared/list/ListReducer";
import createReducer from "../../../shared/helpers/createReducer";
import Composition from "../../../shared/helpers/Composition";

export const getStateRoot = (state) => state.clients.details.reserveList;
export const prefix = 'CLIENTS_DETAILS_RESERVE_LIST_';

const getInitState = () => ListReducer.getInitState({
	itemsPerPage: 10,
});

const getReduceMap = () =>  new Composition(
	ListReducer.getReduceMap(),
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

