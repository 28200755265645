const getHasErrorsFn = (data, validateErrors, model) => Object.keys(data)
    .some((field) => {
        const allData = model || data;
        const validation = validateErrors[field];
        const value = data[field];

        if (!validation) {
			return false;
        }

        if (typeof validation !== 'function') {
			return getHasErrorsFn(value, validation, allData);
        }

        return validation(value, allData);
    });

export const runValidation = (data, validateErrors) => !getHasErrorsFn(data, validateErrors);

export default {
    runValidation,
};
