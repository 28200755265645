const initialState = {
    isOpen: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'SELMO_PACZKA_SUCCESS_DEPOSIT_MODAL_VISIBLE':
            return {
                ...state,
                isOpen: action.toggle
            };
        default:
            return state;
    }
}
