import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import {RouterPaths} from "../../RouterPath";
import TeamInvitations from "../../../settings/team/invitations/TeamInvitations";
import TeamMembers from "../../../settings/team/members/list/TeamMembers";
import MemberForm from "../../../settings/team/members/form/MemberForm";
import UserPermissionRoute from "../../UserPermissionRoute";
import {
    USER_PERMISSIONS_TEAM_EDIT_PERMISSIONS, USER_PERMISSIONS_TEAM_STATISTICS_VISIBILITY_PERMISSIONS,
} from "../../../app/shared/enums/UserPermissionsTypes";
import MemberHistory from "../../../settings/team/members/history/MemberHistory";
import TeamContextWrapper from "./TeamContext";
import TeamActivityHistory from "../../../settings/team/history/TeamActivityHistory";
import TeamStatistics from "../../../settings/team/statistics/page/TeamStatistics";
import SelmoProRoute from "../../SelmoProRoute";

const TeamRoute = ({path}) => {
    return (
        <SelmoProRoute
            allowField="employees_accounts"
            path={path}
        >
            <Switch>
                <Route
                    path={RouterPaths.SettingsTeamMembers}
                    exact
                    component={TeamMembers}
                />
                <Route
                    path={RouterPaths.SettingsTeamInvitations}
                    exact
                    component={TeamInvitations}
                />
                <UserPermissionRoute
                    allowedPermissions={[USER_PERMISSIONS_TEAM_STATISTICS_VISIBILITY_PERMISSIONS]}
                    path={RouterPaths.SettingsTeamStatistics}
                    exact
                    component={TeamStatistics}
                />
                <UserPermissionRoute
                    allowedPermissions={[USER_PERMISSIONS_TEAM_STATISTICS_VISIBILITY_PERMISSIONS]}
                    path={RouterPaths.SettingsTeamActivityHistory}
                    exact
                    component={TeamActivityHistory}
                />
                <Route>
                    <TeamContextWrapper>
                        <Switch>
                            <UserPermissionRoute
                                allowedPermissions={[USER_PERMISSIONS_TEAM_EDIT_PERMISSIONS]}
                                path={RouterPaths.SettingsTeamMember}
                                exact
                                component={MemberForm}
                            />
                            <UserPermissionRoute
                                allowedPermissions={[USER_PERMISSIONS_TEAM_STATISTICS_VISIBILITY_PERMISSIONS]}
                                path={RouterPaths.SettingsTeamMemberHistory}
                                exact
                                component={MemberHistory}
                            />
                            <Redirect to={RouterPaths.SettingsTeamMembers}/>
                        </Switch>
                    </TeamContextWrapper>
                </Route>
                <Redirect to={RouterPaths.SettingsTeamMembers}/>
            </Switch>
        </SelmoProRoute>
    );
};

export default TeamRoute;
