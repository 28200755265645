import React, {useEffect, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import Loader from "../../../../../messenger/loader/Loader";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import useFormLocal from "../../../../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../../../../RestServices";
import Field from "../../../../../../../src_shared/components/form/field/Field";
import Model from "../../../../../../../src_shared/modules/model/Model";
import {useSelector} from "react-redux";

const proposedOptions = [
    {id: 1, amount: 100},
    {id: 2, amount: 200},
    {id: 3, amount: 500},
    {id: 4, amount: 1000},
]

const BalanceModal = ({show, hideModal, extraValues = null}) => {
    const {getLangText} = useLang()
    const {userData} = useSelector((state) => state.session);
    const [selectedProposedAmount, setSelectedProposedAmount] = useState(null)

    const minValue = extraValues ? extraValues.amount : 10;

    const [{getFieldProps, onSave, isWaiting, updateValues, setValue}] = useFormLocal({
        model: new Model(),
        rest: services.API_PACKAGE_BALANCE_TRANSACTION,
        initialAutoLoad: false,
        afterSubmit: (response) => {
            window.location.href = response
        },
    });

    useEffect(() => {
        if (extraValues) {
            updateValues({
                ...extraValues,
                path: window.location.pathname
            })
        } else {
            setValue('path', window.location.pathname)
        }
    }, [extraValues])

    const onAmountChange = (field, value) => {
        setValue(field, value)
        setSelectedProposedAmount(null)
    }

    return (
        <Modal
            show={show}
            dialogClassName="default-modal w-420"
            className="higher-index"
            backdropClassName="higher-index"
            onHide={hideModal}
        >
            <Modal.Header className="relative">
                <div>
                    {extraValues ?
                        <>
                            <div className="font-bold text-lg">{getLangText('orderCourier')}</div>
                            <div
                                className="text-desc font-medium text-sm -mt-1">{getLangText('generateLabelAndOrderCourier')}</div>
                        </> :
                        <>
                            <div
                                className="font-bold text-lg">{getLangText('balanceState')}:&nbsp;{userData.sp_balance}&nbsp;zł
                            </div>
                            <div
                                className="text-desc font-medium text-sm -mt-1">{getLangText('increaseBalanceForShipping')}</div>
                        </>
                    }

                </div>
                <div className="ml-auto text-right -mt-2">
                    <button
                        onClick={hideModal}
                        type="button"
                        className="button text-right text-[#6b7280] text-[11px]"
                    >
                        <i className="icon-cross"/>
                    </button>
                    <img className="w-[95px] mt-2" src="/assets/images/svg/selmo-paczka-logo.svg" alt="Selmo Paczka"/>
                </div>
            </Modal.Header>
            <Modal.Body className="mt-2">
                <div>
                    <div className="font-bold text-sm mb-2">
                        {getLangText(extraValues ? 'topUpYourBalanceToPayForShipping' : 'enterDepositAmount')}
                    </div>
                    <form onSubmit={onSave}>
                        <div className="flex items-center">
                            <div className={`relative grow ${extraValues ? 'mr-3' : ''}`}>
                                <Field
                                    {...getFieldProps('amount')}
                                    setValue={onAmountChange}
                                    type="number"
                                    placeholder={getLangText('enterAmount')}
                                    className={`mb-0 grow big-size hidden-arrows smaller`}
                                    showLabel={false}
                                    extraProps={{
                                        required: true,
                                        autoFocus: true,
                                        min: minValue,
                                        step: 0.01
                                    }}
                                />
                                <div
                                    className="text-[#9CA3AF] text-xs font-medium absolute top-1/2 -translate-y-1/2 right-2">{getLangText('min')}. {minValue} zł
                                </div>
                            </div>
                            {extraValues &&
                                <button
                                    type="submit"
                                    style={{fontSize: 14, borderRadius: 8}}
                                    className="button primary small-size align-self-stretch ml-auto whitespace-nowrap bigger-radius"
                                >
                                    {isWaiting && <Loader/>}
                                    {getLangText('deposit')}
                                </button>
                            }
                        </div>
                        {!extraValues &&
                            <div className="mb-2">
                                <div
                                    className="text-[#101827] font-medium mt-3 text-sm mb-2">{getLangText('orSelectFromProposed')}</div>
                                <div className="grid grid-cols-4 gap-3">
                                    {proposedOptions.map((option) => (
                                        <button
                                            type="button"
                                            style={{
                                                boxShadow: selectedProposedAmount === option.id ? '0 0 0 1px #3C61EA' : '0 0 0 1px #eff0f1'
                                            }}
                                            onClick={() => {
                                                setSelectedProposedAmount(option.id);
                                                setValue('amount', option.amount)
                                            }}
                                            key={option.id}
                                            className={`border text-sm rounded-[5px] py-[10px] transition-all duration-200 w-full font-medium ${selectedProposedAmount === option.id ? 'text-[#000] border-[#3C61EA] bg-[#f7f9fe]' : 'text-[#6B7280]'}`}
                                        >
                                            {option.amount} zł
                                        </button>
                                    ))}
                                </div>
                            </div>
                        }
                        {!extraValues &&
                            <button
                                type="submit"
                                style={{fontSize: 14, borderRadius: 8, paddingTop: 18, paddingBottom: 18}}
                                className="button primary mt-3 align-self-stretch w-full whitespace-nowrap bigger-radius"
                            >
                                {isWaiting && <Loader/>}
                                {getLangText('deposit')}
                            </button>
                        }
                    </form>
                    {extraValues &&
                        <div className="form-info-box w-100 mt-4 mb-4">
                            <div>
                                <i className="icon-info-c"/>
                            </div>
                            <div>
                                <div className="title">{getLangText('canEnterHigherAmount')}</div>
                                <div className="desc">{getLangText('itWillBeSentAtTheBeginningOfShipments')}</div>
                            </div>
                        </div>
                    }
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default BalanceModal;
