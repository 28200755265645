import React, {useState} from 'react';
import useLang from "../../../../../src_shared/hooks/useLang";
import ConfirmModal from "../../list/components/export/ConfirmModal";
import useSubmitData from "../../../../../src_shared/hooks/useSubmitData";
import {services} from "../../../../RestServices";

const StopAutomation = ({values, fetchData}) => {
    const {getLangText} = useLang();

    const [showModal, setShowModal] = useState(false);

    const [{onSave, isWaiting}] = useSubmitData({
        rest: `${services.API_PAUSE_ORDER}/${values.id}`,
        afterSubmit: () => {
            fetchData();
            setShowModal(false);
        },
    });

    if (!!+values.mr_automate_paused) return null;

    return (
        <div className="summary-section mb-3">
            <div className="flex items-center justify-between">
                <div>
                    <div className="font-bold">
                        {getLangText('panAutomacikLabel')}
                    </div>
                    <div className="text-[13px] leading-[17px] font-medium text-desc">
                        {getLangText('stopWorkForThisOrder')}
                    </div>
                </div>
                <img className="w-[50px] ml-3 rounded-[3px]" src="/assets/images/automate-mini.webp" alt="automate"/>
            </div>
            <button
                className="button border-button big-size mt-3 w-full"
                type="button"
                onClick={() => setShowModal(true)}
            >
                {getLangText('stopWorks')}
            </button>
            {showModal &&
                <ConfirmModal
                    SubTitle={() => getLangText('wantToStopAllActionsInThisOrder')}
                    title={getLangText('panAutomacikLabel')}
                    saveButton={getLangText('yesButton')}
                    dialogClassName="w-420 small-modal"
                    show={showModal}
                    isWaiting={isWaiting}
                    onSubmit={onSave}
                    hide={() => setShowModal(false)}
                />
            }
        </div>
    );
};

export default StopAutomation;
