import React from 'react';
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../routes/RouterPath";
import {services} from "../../../../RestServices";
import useLang from "../../../../../src_shared/hooks/useLang";
import useFormLocal from "../../../../../src_shared/hooks/useFormLocal";
import SwitchField from "../../../../../src_shared/components/form/switchField/SwitchField";
import Loader from "../../../../app/shared/Loader";
import Wrapper from "../Wrapper";
import Field from "../../../../../src_shared/components/form/field/Field";
import SettingsGLSFrModel from "../../../../../modules/models/settings/SettingsGLSFrModel";

const GlsFranceConfiguration = () => {
    const {getLangText} = useLang();

    const [{onSave, isWaiting, getFieldProps, isLoading}] = useFormLocal({
        rest: services.API_SETTINGS_GLS_FR,
        model: SettingsGLSFrModel,
    });

    return (
        <>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <Link to={RouterPaths.Settings}>
                            {getLangText('asideSettingsLabel')}
                        </Link>
                    </li>
                    <li>
                        <Link to={RouterPaths.SettingsIntegration}>{getLangText('settings_nav_integrations')}</Link>
                    </li>
                    <li className="active">
                        GLS
                    </li>
                </ul>
            </div>
            <Wrapper>
                <div className="loader-parent">
                    <Loader isLoading={isLoading}/>
                    <div className="settings-form pt-4">
                        <div className="steps-wrapper">
                            <div className="step">
                                <div className="step-header align-items-start">
                                    <div className="title d-block flex-grow-1">
                                        <div className="">{getLangText('pasteKeysInFieldsBelowLabel')}</div>
                                        <div className="text-desc text-sm font-medium mb-3">
                                            {getLangText('glsFranceSubTitle')}
                                        </div>
                                        <div className="grid grid-cols-2 gap-x-5">
                                            <Field
                                                {...getFieldProps('login')}
                                                label={getLangText('glsLogin')}
                                            />
                                            <Field
                                                {...getFieldProps('password')}
                                                label={getLangText('glsPassword')}
                                            />
                                        </div>
                                        <div className="grid grid-cols-5 gap-x-5">
                                            <div className="col-span-2">
                                                <Field
                                                    {...getFieldProps('contact_id')}
                                                    label={getLangText('glsContactId')}
                                                />
                                            </div>
                                            <div className="col-span-3">
                                                <Field
                                                    {...getFieldProps('url')}
                                                    label={getLangText('glsRestUrl')}
                                                    className="mb-0"
                                                />
                                                <div
                                                    className="text-xs font-medium text-desc bg-[#f9f9f9] p-1 rounded-lg mt-1 mb-3">
                                                    {getLangText('example')}:
                                                    <div className="font-bold">
                                                        https://shipit-wbm-test01.gls-group.eu:8443
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex items-center mt-2">
                                            <SwitchField
                                                {...getFieldProps('active')}
                                                label={getLangText('activeLabel')}
                                                className="switch-form inline-label align-items-start big-label light-version mt-0 mb-0"
                                            />
                                            <button
                                                type="button"
                                                onClick={onSave}
                                                className={`button primary ml-auto ${isWaiting ? 'loading-button' : ''}`}
                                            >
                                                <Loader isLoading={isWaiting}/>
                                                {getLangText('saveButton')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Wrapper>
        </>
    );
};

export default GlsFranceConfiguration;
