import OrderProductsFormActions from "../../shared/components/form/orderProductsForm/OrderProductsFormActions";
import {prefix} from "./formReducer";
import {getStateRoot} from "./formReducer";
import axios from "axios";
import {showAlert} from "../../shared/components/alert/AlertActions";
import {selmoUrl} from "../../../../src_shared/api/api";
import {RouterPaths} from "../../../routes/RouterPath";
import CountDownNotificationActions from "../../shared/components/notification/CountDownNotificationActions";
import {ADD_NEW_ORDER_NOTIFICATION} from "../../shared/enums/CountDownNotificationsTypes";
import api from "../../../../services/axios/axios";
import {services} from "../../../RestServices";
import SessionActions from "../../shared/session/SessionActions";
import {batch} from "react-redux";

export class AddOrderFormActions extends OrderProductsFormActions {

	validateData(data) {
		const isEmpty = Object.values(data).some(x => !x);
		return !!isEmpty;
	}

	submit(history) {
		return async (dispatch, getState) => {

			const {data, manualProducts} = getStateRoot(getState());
			const {last_name, name} = data;

			const requiredField = {
				name,
				last_name: last_name || name,
			}

			if (this.validateData(requiredField)) {
				dispatch(this.updateField('activeValidation', true))
				return
			}

			if (this.validateOrderProducts(manualProducts)) {
				dispatch(this.updateField('productActiveValidation', true))
				return
			}

			dispatch(this.waiting.startWaiting())

			const preparedValues = () => {
				if (data.shop_client_id) {
					return {
						name: data.name,
						customer_facebook_id: data.facebook_id,
						products: manualProducts,
					}
				}
				return  {
					first_name: data.name,
					last_name: data.last_name,
					products: manualProducts,
				}
			}

			try {
				const response = await api.post(`${selmoUrl}/${services.API_SINGLE_ORDER}`, preparedValues())
				batch(() => {
					dispatch(showAlert('yuhuLabel', 'successfulSavedLabel'))
					dispatch(this.afterSubmit())
					dispatch(CountDownNotificationActions.setNotificationVisibility(true, ADD_NEW_ORDER_NOTIFICATION));
					dispatch(SessionActions.updateUserData('first_step_orders', false))
				})
				this.updateRoute(response.data, history)
			} catch (e) {
				console.warn('submit `api/order` action failed')
				dispatch(this.waiting.stopWaiting())
				//	TODO
				dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
			}
			finally {
				dispatch(this.updateField('activeValidation', false))
				dispatch(this.updateField('productActiveValidation', false))
			}
		}
	}

	updateRoute(data, history) {
		history.push(`${RouterPaths.OrdersList}/${data.id}/`);
	}
}

export const getInstance = () => new AddOrderFormActions({
	prefix,
	getStateRoot
});

export default getInstance();

