import React, {useMemo, useRef, useState} from 'react';
import SendInput from "./components/SendInput.jsx";
import {useSelector} from "react-redux";
import GalleryModal from "./galleryModal/GalleryModal.jsx";
import MessagesList from "./components/MessagesList";
import Loader from "../../../../src_shared/components/loader/Loader";
import ChatHeader from "./components/chatHeader/ChatHeader";

const ChatBox = () => {
		const messageScrollRef = useRef();

		const [statusVisible, setStatusVisible] = useState(false)

		const {items, isLoading, activeConversation} = useSelector((state) => state.messenger.chatBox)

		const lastId = useMemo(() => Number(items[0]?.id), [items.length]);

		return (
			<div className={`right-panel loader-parent`}>
				<Loader isLoading={isLoading}/>
				<ChatHeader
					setStatusVisible={setStatusVisible}
					messageScrollRef={messageScrollRef}
					lastId={lastId}
				/>
				<MessagesList
					statusVisible={statusVisible}
					setStatusVisible={setStatusVisible}
					messageScrollRef={messageScrollRef}
					lastId={lastId}
				/>
				<SendInput
					setStatusVisible={setStatusVisible}
					lastId={lastId}
					activeConversation={activeConversation}
					messageScrollRef={messageScrollRef}
				/>
				<GalleryModal/>
			</div>
		);
	}
;

export default ChatBox;
