import React, {useState} from 'react';
import Header from "./components/header/Header";
import {services} from "../../RestServices";
import {useHistory, useLocation, useParams} from "react-router-dom";
import useFormLocal from "../../../src_shared/hooks/useFormLocal";
import DeliveryData from "./components/deliveryData/DeliveryData";
import PaymentMethods from "./components/paymentMethods/PaymentMethods";
import DeliveryMethods from "./components/deliveryMethods/DeliveryMethods";
import CheckoutModel from "../../../modules/models/checkout/CheckoutModel";
import api from "../../../services/axios/axios";
import Summary from "./components/summary/Summary";
import {getGETParamsUrl, parseParams} from "../../../src_shared/list/ListActions";
import BuyNowButton from "./components/buyNowButton/BuyNowButton";
import SelectVariant from "./components/selectVariant/SelectVariant";
import {
    ORDER_PAYMENT_PAYPAL,
    ORDER_PAYMENT_PICKUP,
    ORDER_PAYMENT_SHOP_PICKUP,
    ORDER_PAYMENT_TRANSFER
} from "../shared/enums/PaymentStatuses";
import {showAlert} from "../shared/components/alert/AlertActions";
import {batch, useDispatch} from "react-redux";
import {CUSTOMER_BASKET_DELIVERY_STATUS_DETAILS_VIEW} from "../shared/enums/CustomerBasketViews";
import BasketPageFormActions from "../basket/page/FormActions";
import {RouterPaths} from "../../routes/RouterPath";
import Skeleton from "./Skeleton";
import {Helmet} from "react-helmet";

const Checkout = () => {
    const {shopName, productName} = useParams();
    const shopId = shopName.split('-')[shopName.split('-').length - 1];
    const dispatch = useDispatch();
    const history = useHistory();
    const {search} = useLocation();
    const query = parseParams(search);
    const rest = `${services.API_CHECKOUT_INFO}?shop_id=${shopId}&product=${productName}&hash=${query.hash}`

    const afterSubmit = (response) => {
        if (values.payment === ORDER_PAYMENT_PAYPAL && !!values.overall.paypal_link) {
            window.location.href = `${values.overall.paypal_link}/${values.overall.total_price}${values.shopInfo?.currency_short_name}`;
            return;
        }

        const redirectToPayment = (values.payment === ORDER_PAYMENT_TRANSFER || values.payment === ORDER_PAYMENT_PAYPAL || values.payment?.includes(`${ORDER_PAYMENT_TRANSFER}_`))

        if (redirectToPayment) {
            dispatch(showAlert('yuhuLabel', 'payForOrderLabel'))
            history.push(`${RouterPaths.BasketSingleEN}/${shopName}/${response.magic_url}/platnosc/`)
            return;
        }

        if (values.payment === ORDER_PAYMENT_SHOP_PICKUP || values.payment === ORDER_PAYMENT_PICKUP) {
            batch(() => {
                history.push(`/${RouterPaths.BasketSingleEN}/${shopName}/${response.magic_url}/`)
                dispatch(showAlert('yuhuLabel', 'informationHasBeenSubmittedLabel'))
                dispatch(BasketPageFormActions.setBasketView(CUSTOMER_BASKET_DELIVERY_STATUS_DETAILS_VIEW))
                // dispatch(BasketPageFormActions.loadData(magic_url, shop_id))
            })
            return;
        }

        window.location.href = response.redirect_link;
    }

    const [{values, isLoading, getFieldProps, updateValues, onSave, setValue, isWaiting}] = useFormLocal({
        rest,
        model: CheckoutModel,
        afterSubmit,
    });

    const [controller, setController] = useState(null);

    const updatePriceAndValues = async (field, value) => {
        if (controller) {
            controller.abort();
        }

        const newController = new AbortController();
        setController(newController);

        const signal = newController.signal;

        const preparedValue = typeof field === 'object' ? field : {[field]: value}

        const params = {
            shop_id: shopId,
            product: productName,
            payment: values.payment,
            shipping: values.shipping,
            ...preparedValue,
        }

        if (preparedValue.payment) {
            updateValues(preparedValue)
        }

        try {
            const {data} = await api.get(`/${services.API_CHECKOUT_OVERALL}${getGETParamsUrl(params)}`, {signal})
            updateValues({
                ...preparedValue,
                overall: {
                    ...values.overall,
                    ...data.item
                }
            })
        } catch (e) {
            console.error('Error send value on server')
        }
    }

    return (
        <div className="min-h-[100vh] flex flex-col sm:py-6">
            {values.shopInfo?.name &&
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>{`${values?.productInfo?.name} - ${values.shopInfo?.name}`}</title>
                </Helmet>
            }
            <div className="dashboard-card m-auto w-full md:w-[750px]">
                {isLoading ?
                    <Skeleton/> :
                    <>
                        <Header values={values}/>
                        <form
                            id="checkout-form"
                            onSubmit={onSave}
                        >
                            <div className="space-y-4">
                                {!!+values.productInfo.has_sizes &&
                                    <SelectVariant
                                        values={values}
                                        setValue={setValue}
                                    />
                                }
                                <DeliveryData
                                    values={values}
                                    getFieldProps={getFieldProps}
                                    updateValues={updateValues}
                                />
                                {values.country &&
                                    <PaymentMethods
                                        rest={rest}
                                        values={values}
                                        updateValues={updateValues}
                                        updatePriceAndValues={updatePriceAndValues}
                                    />
                                }
                                {!+values.productInfo?.digital && values.payment &&
                                    <DeliveryMethods
                                        getFieldProps={getFieldProps}
                                        values={values}
                                        updateValues={updateValues}
                                        shopId={shopId}
                                        updatePriceAndValues={updatePriceAndValues}
                                    />
                                }
                                <Summary values={values}/>
                                <BuyNowButton isWaiting={isWaiting}/>
                            </div>
                        </form>
                    </>
                }
            </div>
        </div>
    );
};

export default Checkout;
