import ListActions from "../../../../../../src_shared/list/ListActions.js";
import axios from "axios";
import {selmoUrl} from "../../../../../../src_shared/api/api.js";
import {services} from "../../../../../RestServices.js";
import {showAlert} from "../../../../shared/components/alert/AlertActions.js";
import api from "../../../../../../services/axios/axios";

class ShownSharedListActions extends ListActions {
	setActiveProduct(item, id) {
		return async (dispatch) => {
			try {
				await api.put(`${selmoUrl}/${services.API_LIVE_PRODUCTS_EXTRA}/${id}`, {
					product_id: item.product_id,
					live_product_id: item.live_product_id,
				})
				dispatch(this.loadData(id))
				// dispatch(this.table.updateItem(item.id, 'active', '1'))
				dispatch(showAlert())
			} catch (error) {
				dispatch(showAlert('Coś poszło nie tak', '', 'alert-fail'))
			}
		}
	}
}

export default ShownSharedListActions;
