import Model from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

class SettingsGLFrSModel extends Model {
    getModel() {
        return {
            login: '',
            password: '',
            contact_id: '',
            url: '',
            active: '0',
        };
    }

    getValidators() {
        return {
            login: ValidationError.notEmpty,
            password: ValidationError.notEmpty,
            contact_id: ValidationError.notEmpty,
            url: ValidationError.notEmpty,
            active: ValidationError.skip,
        };
    }

    buildDTO(data) {
        return {
            login: data.login,
            password: data.password,
            contact_id: data.contact_id,
            url: data.url,
            active: data.active,
        }
    }
}

export default new SettingsGLFrSModel();
