import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import LiveList, {firstPageBenefits} from "../app/live/list/LiveList";
import LiveDetails from "../app/live/details/default/LiveDetails.jsx";
import {RouterPaths} from "./RouterPath";
import AddNewLive from "../app/live/addNewLive/NewLiveModal";
import {useSelector} from "react-redux";
import LiveBlankPage from "../app/live/LiveBlankPage";
import {SELMO_APP_PERMISSION} from "../app/shared/enums/SelmoPermissions";
import PermissionRoute from "./PermissionRoute";
import TutorialPageBox from "../../src_shared/components/ui/TutorialPageBox/TutorialPageBox";
import {createSelector} from "reselect";
import {getStateRoot as getLivesListStateRoot} from "../app/live/list/listReducer";
import {HEADER_BOX_LIVES} from "../app/shared/enums/TutorialVideoStatsTypes";

const LiveRoute = ({path}) => {

    const {visibility} = useSelector((state) => state.lives.newLiveModal)
    const {userData, waitingForResponse} = useSelector((state) => state.session);
    const {isFirstStepPage} = useSelector(createSelector(getLivesListStateRoot, (stateRoot) => stateRoot));

    if ((!userData.facebook_id && !userData.instagram_id) && !waitingForResponse) {
        return <LiveBlankPage buttonLink={userData.facebookLoginUrl}/>
    }

    return (
        <PermissionRoute allowedPermissions={[SELMO_APP_PERMISSION]}>
            <Route path={path}>
                <div className="live-transmissions-wrapper">
                    {(!isFirstStepPage && !userData.first_step_live) &&
                        <TutorialPageBox
                            videoStatsName={HEADER_BOX_LIVES}
                            page='live'
                            benefits={firstPageBenefits}
                            title="asideLivesLabel"
                        />
                    }
                    <Switch>
                        <Route
                            exact
                            path={path}
                            component={LiveList}
                        />
                        {/*<Route*/}
                        {/*    exact*/}
                        {/*    path={RouterPaths.LivePlatformStorageListFull}*/}
                        {/*    component={LivePortalStorageList}*/}
                        {/*/>*/}
                        {/*<Route*/}
                        {/*    exact*/}
                        {/*    path={RouterPaths.LivePlatformActiveListFull}*/}
                        {/*    component={LiveActiveList}*/}
                        {/*/>*/}
                        {/*<Route*/}
                        {/*    exact*/}
                        {/*    path={RouterPaths.LivePlatformNotShownListFull}*/}
                        {/*    component={LivePortalNotShownList}*/}
                        {/*/>*/}
                        {/*<Route*/}
                        {/*    exact*/}
                        {/*    path={RouterPaths.LivePlatformShownListFull}*/}
                        {/*    component={LivePortalShownList}*/}
                        {/*/>*/}
                        <Route
                            path={`${path}/:id`}
                            component={LiveDetails}
                        />
                        <Redirect to={RouterPaths.LiveList}/>
                    </Switch>
                </div>
            </Route>
            {visibility &&
                <AddNewLive/>
            }
        </PermissionRoute>
    );
};

export default LiveRoute;
