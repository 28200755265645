import createReducer from "../../../shared/helpers/createReducer";
import Composition from "../../../shared/helpers/Composition";
import FormReducer from "../../../../../src_shared/form/FormReducer";
import OrderModel from "../../../../../modules/models/order/OrderModel";

export const getStateRoot = (state) => state.orders.details.form;
export const prefix = 'ORDERS_DETAILS_FORM_';

const getInitState = () => FormReducer.getInitState(OrderModel.getModel());

const getReduceMap = () => new Composition(
	FormReducer.getReduceMap(),
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

