import {combineReducers} from "redux";
import listReducer from "./list/listReducer";
import filtersReducer from "./filters/filtersReducer";
import freshmenReducer from "./form/formReducer";

export default combineReducers({
	list: listReducer,
	form: freshmenReducer,
	filters: filtersReducer,
});
