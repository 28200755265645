import Model from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

class MobileReelsModel extends Model {
	getModel() {
		return {
			name: '',
			description: '',
			active: '0',
			products: [],
			attachment_name: null,
			attachment_path: null,
		};
	}

	getValidators() {
		return {
			name: ValidationError.notEmpty,
			description: ValidationError.skip,
			active: ValidationError.skip,
			products: ValidationError.skip,
		};
	}

	buildDTO(data) {
		const {name, description, url, active, products, attachment_name, attachment_path} = data;
		return {
			name,
			description,
			active,
			products: products.map((i) => i.id),
			attachment_name,
			attachment_path,
		}
	}
}

export default new MobileReelsModel();
