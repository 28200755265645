import React, {useEffect, useState} from "react";
import Loader from "../../../messenger/loader/Loader";
import FormActions from "./FormActions";
import useForm from "../../../../../src_shared/form/useForm";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import ConfirmModal from "../../list/components/export/ConfirmModal";
import {useRemoveModal} from "../../../shared/hooks/useRemoveModal";
import {services} from "../../../../RestServices";
import EditOrderFormActions from "../form/FormActions";
import CustomDropdown from "../../../shared/components/dropdown/CustomDropdown";
import {selmoUrl} from "../../../../../src_shared/api/api";
import {showAlert} from "../../../shared/components/alert/AlertActions";
import StylesLoader from "../../../shared/Loader";
import {ERROR_FURGONETKA} from "../../../../../src_shared/enums/ErrorEnums";
import useLang from "../../../../../src_shared/hooks/useLang";
import {SHOP_ORIGIN_PL} from "../../../shared/enums/ShopOrigins";
import api from "../../../../../services/axios/axios";
import {useDownloadFile} from "../../../../../src_shared/hooks/useDownloadFile";
import {usePrintFile} from "../../../../../src_shared/hooks/usePrintFile";
import {convertToUserTimeZone} from "../../../shared/helpers/dateHelpers";
import {RouterPaths} from "../../../../routes/RouterPath";

const FurgonetkaLabelForm = ({data}) => {
	const dispatch = useDispatch();
	const params = useParams()
	const {getLangText} = useLang();
	const {userData} = useSelector((state) => state.session);

	const {
		data: {values, setValue},
		form: {isWaiting},
	} = useForm({
		FormActions,
		params,
		preventAutoLoad: true,
	});

	const loadData = () => dispatch(EditOrderFormActions.loadData(data.shop_order_id))

	const [modalOpen, setModalOpen, waiting, remove] = useRemoveModal(`${services.API_INTEGRATIONS_FURGONETKA}/${data.id}`, loadData)

	useEffect(() => {
		if (data.package_templates) {
			setValue('template', data.package_templates[0]?.id)
		}
	}, [data])

	const createLabel = () => dispatch(FormActions.createLabel());

	const [waitingFoLabel, setWaitingFoLabel] = useState(false);

	const checkIsReady = async (callBack) => {
		setWaitingFoLabel(true)
		try {
			await api.get(`${selmoUrl}/${services.API_ORDER_FURGONETKA_LABEL_READY}/${data.shippingData.furgonetka_package_id}`)
			callBack();
		} catch (e) {
			if (e.response.data.message === ERROR_FURGONETKA) {
				dispatch(showAlert(e.response.data.text ? e.response.data.text : 'sthWentWrongWarning', '', 'alert-fail'))
				return;
			}
			dispatch(showAlert(getLangText('labelIsGeneratingLabel'), 'tryAgainInWhileLabel', 'alert-info'))
		} finally {
			setWaitingFoLabel(false);
		}
	}

	const {download, waiting: waitingForPreviewLabel} = useDownloadFile({
		rest: `${services.API_INTEGRATIONS_FURGONETKA}/${data.id}`,
		fileName: 'Furgonetka',
	})

	const {print, waiting: waitingForPrint} = usePrintFile({
		rest: `${services.API_INTEGRATIONS_FURGONETKA}/${data.id}`,
	})


	if (userData.origin !== SHOP_ORIGIN_PL || !+userData.enable_shipping_int) {
		return null;
	}

	if (data.shippingData.shop_furgonetka_active === '0') {
		return null
	}

	if (data.shippingData.shop_furgonetka_active === null) {
		return (
			<section className="summary-section mb-3">
				<div className="section-header mb-3">
					<div className="flex-grow-1">
						<h2>{getLangText('configureFurgonetka')}</h2>
					</div>
					<div>
						<img
							style={{height: '22px'}}
							src="/assets/images/export/furgonetka-logo.svg"
							alt="Furgonetka"
						/>
					</div>
					<CustomDropdown
						className="ml-3"
					>
						<button
							type="button"
							className="custom-option"
							onClick={() => dispatch(EditOrderFormActions.removeIntegrationBox('furgonetka'))}
						>
							{getLangText('hideLabel')}
						</button>
					</CustomDropdown>
				</div>
				<a
					className="button border-button big-width w-100"
					href={RouterPaths.SettingsIntegrationFurgonetka}
					target="_blank"
				>
					{getLangText('configureLabel')}
				</a>
			</section>
		)
	}


	return (
		<>
			{!data.package_templates?.length &&
				<section className="summary-section mb-3">
					<div className="section-header mb-3">
						<div className="flex-grow-1">
							<h2>{getLangText('generateLabelLabel')}</h2>
							<div className="section-header__description">
								{getLangText('addLabelByFurgonetkaLabel')}
							</div>
						</div>
						<div>
							<img
								style={{height: '22px'}}
								src="/assets/images/export/furgonetka-logo.svg"
								alt="Furgonetka"
							/>
						</div>
					</div>
					<a
						className="button border-button big-width w-100"
						href="https://furgonetka.pl/konto/szablony_przesylek/"
						target="_blank"
					>
						{getLangText('configureTemplateLabel')}
					</a>
				</section>
			}
			{!!data.package_templates?.length && !data.shippingData.furgonetka_package_id &&
				<section className="summary-section mb-3">
					<div className="section-header mb-3">
						<div className="flex-grow-1">
							<h2>{getLangText('generateLabelLabel')}</h2>
							<div className="section-header__description">
								{getLangText('addLabelByFurgonetkaLabel')}
							</div>
						</div>
						<div>
							<img
								style={{height: '22px'}}
								src="/assets/images/export/furgonetka-logo.svg"
								alt="Furgonetka"
							/>
						</div>
					</div>
					{!data.shipping_address_exist ?
						<div
							className="form-info-box align-items-center w-100 small-line-height">
							<div>
								<i className="icon-info-c"/>
							</div>
							<div>
								<div className="title">{getLangText('noDeliveryAddressLabel')}</div>
							</div>
						</div> :
						<div className="inpost-chooser">
							{data.package_templates.map((box) => (
								<div
									key={box.id}
									id={box.id}
									className={`inpost-chooser__box furgonetka-chooser ${values.template === box.id ? 'active' : ''}`}
								>
									<input
										onChange={() => setValue('template', box.id)}
										checked={values.template === box.id}
										type="radio"
										value={values.template}
									/>
									<span className="checkmark"/>
									<div className="inpost-chooser__box__text-wrapper">
										<div className="inpost-chooser__box__text-wrapper__top">
											Szablon {box.name}
										</div>
										<div className="inpost-chooser__box__text-wrapper__bottom">
											max. {box.width} x {box.height} x {box.depth} do {box.weight} kg
										</div>
									</div>
								</div>
							))}
							<div className="button-place">
								<button
									onClick={createLabel}
									type="button"
									className={`button furgonetka-button position-relative ${isWaiting ? 'pointer-events-none' : ''}`}
								>
									{isWaiting && <Loader />}
									{getLangText('createLabelLabel')}
								</button>
							</div>
						</div>
					}
				</section>
			}
			{data.shippingData.furgonetka_package_id &&
				<section className="summary-section mb-3">
					<div className="section-header mb-3">
						<div className="flex-grow-1">
							<h2>{getLangText('furgonetkaLabelsLabel')}</h2>
							<div className="section-header__description">
								{getLangText('manageFurgonetkaLabel')}
							</div>
						</div>
						<div>
							<a
								href="https://furgonetka.pl/konto/partner/1763980?url=https://furgonetka.pl"
								target="_blank"
								rel="nofollow"
							>
								<img
									style={{height: '22px'}}
									src="/assets/images/export/furgonetka-logo.svg"
									alt="Furgonetka"
								/>
							</a>
						</div>
					</div>
					<div className="loader-parent">
						<StylesLoader
							className="small-loader"
							isLoading={waitingFoLabel || waitingForPrint || waitingForPreviewLabel}
						/>
						<div className="tripple-buttons">
							<div>
								<button
									onClick={() => checkIsReady(print)}
									type="button"
									className="button border-button big-width position-relative"
								>
									{getLangText('printButton')}
									<i className="icon-print"/>
								</button>
							</div>
							<div>
								<button
									onClick={() => checkIsReady(download)}
									className="button border-button big-width"
								>
									{getLangText('previewButton')}
									<i className="icon-eye"/>
								</button>
							</div>
							<div>
								<button
									onClick={() => setModalOpen(true)}
									type="button"
									className="button border-button big-width"
								>
									{getLangText('removeButton')}
									<i className="icon-bin"/>
								</button>
							</div>
						</div>
					</div>
					{data.shippingData.furgonetka_label_date &&
						<div className="info-label mt-3">
							<i className="icon-tick-c"/>
							{getLangText('labelHasBennGeneratedLabel')}
							<div className="date-box">
								<span>{convertToUserTimeZone(data.shippingData.furgonetka_label_date).format('DD.MM.YYYY')}</span>
								<span>{convertToUserTimeZone(data.shippingData.furgonetka_label_date).format('HH:mm:ss')}</span>
							</div>
						</div>
					}
				</section>
			}
			{modalOpen &&
				<ConfirmModal
					title={getLangText('askForRemovingFurgonetkaLabelLabel')}
					SubTitle={() => <>{getLangText('delete_modal_text')}</>}
					saveButton={getLangText('removeButton')}
					show={modalOpen}
					isWaiting={waiting}
					onSubmit={remove}
					hide={() => setModalOpen(false)}
					submitButtonClass="danger"
				/>
			}
		</>
	);
};

export default FurgonetkaLabelForm;



