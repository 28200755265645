import React from 'react';
import {useDispatch} from "react-redux";
import useLang from "../../../../../../src_shared/hooks/useLang";
import moment from "moment/moment";
import EmptyList from "../../../../shared/EmptyList";
import {findExpenditureLabelById} from "../../../../shared/enums/ExpendituresTypes";
import CustomDropdown from "../../../../shared/components/dropdown/CustomDropdown";
import {convertToUserTimeZone} from "../../../../shared/helpers/dateHelpers";

const Table = ({items, isWaiting, updateSortOptions, getSortClass, askForRemoveItem, setShowEditModal}) => {

    const {getLangText} = useLang();
    const isVariantExist = items.some((item) => !!item.size_name)

    return (
        <EmptyList
            items={items}
            isLoading={isWaiting}
            className="with-padd pt-5"
            imgSrc="/assets/images/empty/orders-icon-illustration.svg"
            title={getLangText('emptyHereLabel')}
            text={getLangText('emptyExpendituresListDescription')}
        >
            <div className="table-wrapper">
                <div className="table-responsive overflow-visible">
                    <table className="table live-table smaller-padd">
                        <thead>
                        <tr>
                            {isVariantExist &&
                                <th
                                    name="size_name"
                                    onClick={updateSortOptions}
                                    className={getSortClass('size_name')}
                                >
								<span name="size_name">
									{getLangText('variantLabel')}
								</span>
                                </th>
                            }
                            <th
                                name="reason"
                                onClick={updateSortOptions}
                                className={getSortClass('reason')}
                            >
								<span name="reason">
									{getLangText('reasonLabel')}
								</span>
                            </th>
                            <th
                                name="quantity"
                                onClick={updateSortOptions}
                                className={getSortClass('quantity')}
                            >
								<span name="quantity">
									{getLangText('quantityLabel')}
								</span>
                            </th>
                            <th>
                                {getLangText('noteLabel')}
                            </th>
                            <th
                                name="date"
                                onClick={updateSortOptions}
                                className={`text-right ${getSortClass('date')}`}
                            >
							<span name="date">
								{getLangText('addedDateLabel')}
							</span>
                            </th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {items.map((item) => (
                            <tr
                                className="not-clicked-tr"
                                key={item.id}>
                                {isVariantExist &&
                                    <td>
                                        {item.size_name || getLangText('lackLabel')}
                                    </td>
                                }
                                <td
                                    className="w-lg-auto w-100 with-mobile-thead inline-thead"
                                    data-thead={`${getLangText('reasonLabel')}:`}
                                >
                                    {getLangText(findExpenditureLabelById(item.reason))}
                                </td>
                                <td
                                    className="w-lg-auto w-100 with-mobile-thead inline-thead"
                                    data-thead={`${getLangText('quantityLabel')}:`}
                                >
                                    {item.quantity}
                                </td>
                                <td>
                                    {item.note}
                                </td>
                                <td
                                    data-thead={`${getLangText('addedLabel')}:`}
                                    className="text-sm-right date w-lg-auto w-100 order-lg-0 order-4 with-mobile-thead inline-thead mb-0 mr-0"
                                >
                                    <span>{convertToUserTimeZone(item.date).format('DD.MM.YYYY')}</span>
                                    <span>{convertToUserTimeZone(item.date).format('HH:mm:ss')}</span>
                                </td>
                                <td className="text-right absolute-dropdown-dots max-w-[70px] w-[70px]">
                                    <CustomDropdown
                                        icon="icon-dots"
                                    >
                                        <button
                                            onClick={() => setShowEditModal(item.id)}
                                            type="button"
                                            className="custom-option d-block"
                                        >
                                            {getLangText('editButton')}
                                        </button>
                                        <button
                                            onClick={() => askForRemoveItem(item)}
                                            type="button"
                                            className="custom-option d-block"
                                        >
                                            {getLangText('removeButton')}
                                        </button>
                                    </CustomDropdown>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </EmptyList>
    );
};

export default Table;
