import createReducer from "../../shared/helpers/createReducer";
import DataReducer from "../../../../src_shared/form/data/DataReducer";
import {getReduceMap} from "../../../../src_shared/filters/filterReducer";
import {parseParams} from "../../../../src_shared/list/ListActions";
import history from "../../../admin/components/history";
import moment from "moment/moment";
import {ALL_ORDERS_VIEW, INTERVAL} from "../../shared/enums/OrderViewTypes";

const queryParams = history.location.search;
const parsedParams = parseParams(queryParams)
const preparedViewType = !!queryParams ? (parsedParams.dateFrom ? INTERVAL : ALL_ORDERS_VIEW) : INTERVAL;

const preparedDate = (initialState, paramsDate) => {
    if (!!queryParams) {
        if (parsedParams[paramsDate]) {
            return moment(parsedParams[paramsDate]).toDate()
        } else {
            return null;
        }
    } else {
        return initialState;
    }
}

export const getStateRoot = (state) => state.salesPost.filters;

export const getInitState = () => DataReducer.getInitState({
        fromDate: preparedDate(moment(new Date()).subtract(1, 'M').toDate(), 'dateFrom'),
        toDate: preparedDate(new Date(), 'dateTo'),
        viewType: preparedViewType,
    },
    {
        fromDate: moment(new Date()).subtract(1, 'M').toDate(),
        toDate: moment().toDate(),
        viewType: INTERVAL,
    }
)

export const prefix = 'SALES_POST_FILTERS_';

export const getInstance = () => createReducer(
    getInitState(),
    getReduceMap(),
    prefix,
);

export default getInstance();
