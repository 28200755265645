import React, {useCallback} from 'react';
import debounce from "lodash.debounce";
import {resetAndUpdateClients, updateFieldInLiveDetails} from "../../LiveDetailsActions.js";
import {useDispatch, useSelector} from "react-redux";
import useLang from "../../../../../../../src_shared/hooks/useLang";

const SearchClients = () => {
	const dispatch = useDispatch();
	const {clientSearch, liveInfo: {id}} = useSelector((state) => state.lives.details)
	const {getLangText} = useLang();

	const onChange = (e) => {
		dispatch(updateFieldInLiveDetails('clientSearch', e.target.value))
		debouncedSave()
	}

	const debouncedSave = useCallback(
		debounce(() => dispatch(resetAndUpdateClients(id)), 500),
		[id]
	);

	return (
		<div className="form-group search-form mr-sm-2 mt-sm-0 mt-3">
			<input
				className="form-control"
				type="text"
				onChange={onChange}
				value={clientSearch}
				placeholder={getLangText('searchPlaceholder')}
			/>
			<i className="icon-magnifier"/>
		</div>
	);
};

export default SearchClients;
