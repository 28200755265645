import React, {useState} from 'react';
import useList from "../../../../../src_shared/list/useList.js";
import ListActions from "./ListActions.js";
import {getStateRoot} from "./listReducer.js";
import FiltersActions from "../../filters/FiltersActions.js";
import {getStateRoot as getFiltersStateRoot} from '../../filters/filtersReducer.js'
import useWindowDimensions from "../../../messenger/hooks/useWindowDimensions/useWindowDimensions.js";
import ProductsTable from "./components/table/Table.jsx";
import Pagination from "../../../shared/components/pagination/Pagination.jsx";
import StorageWrapper from "../../Page";
import DateRangePicker from "../../../shared/components/form/DateRangePicker";
import useLang from "../../../../../src_shared/hooks/useLang";
import SearchInput from "../../../shared/components/form/SearchInput";
import StatusFilter from "../../../../../src_shared/components/filters/statusFilter/StatusFilter";
import {ProductsSourceTypes} from "../../../shared/enums/ProductSourceType";

const ProductsRemovedList = () => {

    const [isFiltersVisible, setFiltersVisible] = useState(false);
    const {width} = useWindowDimensions();
    const {getLangText} = useLang();

    const {
        pagination: {
            changePage,
            getPagination,
            itemsPerPage,
            nextPage,
            prevPage,
            totalItems,
            updateItemsPerPage
        },
        list: {items, isWaiting},
        search,
        sort: {updateSortOptions, getSortClass},
    } = useList({
        ListActions,
        getStateRoot,
        FiltersActions,
    });

    return (
        <StorageWrapper helmetTitle={`${getLangText('removedProductsLabel')} - Selmo`}>
            <div className="table-list-wrapper">
                <div className="default-shadow-box pb-0">
                    <div className="table-header">
                        <div className="top-part">
                            <div>
                                <h1 className="title">
                                    {getLangText('removedProductsLabel')}
                                    {totalItems > 0 &&
                                        <div className="badge">{totalItems}</div>
                                    }
                                </h1>
                            </div>
                            <button
                                type="button"
                                className='toggle-filters'
                                onClick={() => setFiltersVisible(!isFiltersVisible)}
                            >
                                <i className='icon-filter'/>
                            </button>
                        </div>
                        {(width > 767 || isFiltersVisible) &&
                            <div className="filters-part mt-sm-3">
                                <StatusFilter
                                    ListActions={ListActions}
                                    FiltersActions={FiltersActions}
                                    getFiltersStateRoot={getFiltersStateRoot}
                                    options={ProductsSourceTypes.map((i) => ({...i, value: i.id}))}
                                    name="source"
                                    selectAllName="sourceSelectAll"
                                    defaultSelectedLabel={getLangText('sourceLabel')}
                                    optionLabel={(item) => (
                                        <label className="control-label text-nowrap">
                                            <img
                                                className="source-icon inline-block"
                                                src={`/assets/images/svg/product-type/${item.icon}.svg`}
                                                width={14}
                                                alt=""
                                            />
                                            {getLangText(item.lang)}
                                        </label>
                                    )}
                                />
                                <SearchInput
                                    placeholder={getLangText('searchPlaceholder')}
                                    className="mr-md-2 ml-auto"
                                    search={search}
                                />
                                <DateRangePicker
                                    actions={ListActions}
                                    getFiltersStateRoot={getFiltersStateRoot}
                                    FiltersActions={FiltersActions}
                                />
                            </div>
                        }
                    </div>
                    <ProductsTable
                        getSortClass={getSortClass}
                        updateSortOptions={updateSortOptions}
                        items={items}
                        isWaiting={isWaiting}
                    />
                </div>
                <Pagination
                    getLangText={getLangText}
                    pages={getPagination()}
                    items={items}
                    itemsPerPage={itemsPerPage}
                    totalItems={totalItems}
                    onPrevPage={prevPage}
                    onChangePage={changePage}
                    onNextPage={nextPage}
                    onChangeItemsPerPage={updateItemsPerPage}
                    classes={{
                        prevIcon: 'icon-chevron left',
                        nextIcon: 'icon-chevron right',
                    }}
                    showItemsPerPageSelect
                />
            </div>
        </StorageWrapper>
    );
};

export default ProductsRemovedList;
