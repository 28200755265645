import {getStateRoot, prefix} from './listReducer.js';
import ListActions from "../../../../../src_shared/list/ListActions";
import {services} from "../../../../RestServices";
import {RouterPaths} from "../../../../routes/RouterPath";

export const getInstance = () => new ListActions({
	getStateRoot,
	restService: services.API_WAREHOUSE_SHELVES,
	pathname: RouterPaths.Storage,
	prefix,
});
export default getInstance();
