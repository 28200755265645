import {combineReducers} from "redux";
import listReducer from './list/listReducer'
import chartsReducer from './charts/listReducer'
import filtersReducer from "./filters/filtersReducer";

export default combineReducers({
	list: listReducer,
	charts: chartsReducer,
	filters: filtersReducer
});
