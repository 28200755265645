import React from 'react';
import {Menu} from "@headlessui/react";
import cn from "clsx";
import useLang from "../../../../../../src_shared/hooks/useLang";
import Dropdown from "../../../../../../src_shared/components/ui/Dropdown/Dropdown";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../../routes/RouterPath";

const DropdownButtons = ({askForRemoveItem, item}) => {
    const {getLangText} = useLang();

    const Button = (open) =>  (
        <div
            className={`rounded-full  inline-flex justify-center items-center w-[28px] h-[28px] text-[#9CA3AF] hover:bg-[#F3F4F6] transition-all ${open ? 'bg-[#F3F4F6]' : ''}`}>
            <i className="icon-dots text-[4px]"/>
        </div>
    )
    return (
        <Dropdown
            dropdownContentClassName=""
            Button={Button}>
            <Menu.Item>
                {({active}) => (
                    <Link
                        to={`${RouterPaths.SettingsTeamMembers}/${item.id}`}
                        className={cn(
                            active ? 'bg-gray-100' : '',
                            'p-2.5 rounded-sm px-2 py-[10px] transition-all flex items-center text-xs text-[#697281] hover:text-[#1F2937] font-medium hover:no-underline'
                        )}
                    >
                        {getLangText('seeDetails')}
                    </Link>
                )}
            </Menu.Item>
            <Menu.Item>
                {({active}) => (
                    <button
                        type="button"
                        onClick={askForRemoveItem}
                        className={cn(
                            active ? 'bg-gray-100' : '',
                            'p-2.5 rounded-sm px-2 py-[10px] transition-all flex items-center text-xs text-[#697281] hover:text-[#1F2937] font-medium hover:no-underline w-full'
                        )}
                    >
                        {getLangText('removeMember')}
                    </button>
                )}
            </Menu.Item>
        </Dropdown>
    );
};

export default DropdownButtons;
