import {combineReducers} from "redux";
import listsReducer from './lists/listsReducer'
import modalFormReducer from "./modalForm/formReducer";
import filtersReducer from "./filters/filtersReducer";
import editProductReducer from "./form/editProductReducer";
import categoriesReducer from "./categories/categoriesReducer";
import collectionsReducer from "./collections/collectionsReducer";
import reelsReducer from "./reels/reelsReducer";
import mobileReelsReducer from "./mobileReels/mobileReelsReducer";
import manufacturesReducer from "./manufactures/manufacturesReducer";

export default combineReducers({
	lists: listsReducer,
	form: editProductReducer,
	modalForm: modalFormReducer,
	filters: filtersReducer,
	categories: categoriesReducer,
	collections: collectionsReducer,
	manufactures: manufacturesReducer,
	reels: reelsReducer,
	mobileReels: mobileReelsReducer
});
