import Model from "../../../../src_shared/modules/model/Model";
import ValidationError from "../../../../src_shared/form/validation/ValidationError";

export class FunctionalitiesModel extends Model {
	getModel() {
		return {
			id: 0,
			name: '',
			active: '1',
			price: '',
			old_price: '',
			description: '',
			selmo_name: '',
			lang: '',
		};
	}

	getValidators() {
		return {
			name: ValidationError.notEmpty,
			description: ValidationError.notEmpty,
			lang: ValidationError.notEmpty,
			price: ValidationError.notEmpty,
			old_price: ValidationError.skip,
			selmo_name: ValidationError.notEmpty,
			active: ValidationError.skip,
		};
	}
}

export default new FunctionalitiesModel();
