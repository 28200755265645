import React, {useCallback, useEffect, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {filesSetDropdownVisibility} from "./FilesActions";
import Dropdown from "./components/Dropdown";

const FilesDropdown = () => {
    const dispatch = useDispatch()
    const {isOpen, items} = useSelector((state) => state.shared.files);

    const buttonRef = useRef(null)
    const dropdownRef = useRef(null)

    const handleClickOutside = useCallback((e) => {
        if (isOpen
            && buttonRef
            && dropdownRef
            && buttonRef.current !== e.target
            && dropdownRef.current !== e.target
            && !buttonRef.current?.contains(e.target)
            && !dropdownRef.current?.contains(e.target)) {
            dispatch(filesSetDropdownVisibility(false))
        }
    }, [isOpen]);

    useEffect(() => {
        document.body.addEventListener('click', handleClickOutside);
        return () => {
            document.body.removeEventListener('click', handleClickOutside);
        };
    }, [handleClickOutside]);

    return (
        <div
            ref={dropdownRef}
            className="relative mr-2">
            <button
                type="button"
                ref={buttonRef}
                onClick={() => dispatch(filesSetDropdownVisibility(!isOpen))}
                className={`rounded-2xl w-[30px] h-[30px] flex items-center justify-center font-semibold text-[#4B5563] hover:bg-gray-200 transition-all text-xs ${isOpen ? 'bg-gray-200' : ''}`}
            >
                <i className="icon-download text-sm -mt-0.5"/>
            </button>
            {isOpen &&
                <Dropdown
                    items={items}
                    close={() => dispatch(filesSetDropdownVisibility(false))}
                />
            }
        </div>
    );
};

export default FilesDropdown;
