import React from 'react';
import {RouterPaths} from "../../../routes/RouterPath";
import {Link} from "react-router-dom";
import useLang from "../../../../src_shared/hooks/useLang";
import cn from "clsx";
import {Menu} from "@headlessui/react";
import Dropdown from "../../../../src_shared/components/ui/Dropdown/Dropdown";
import {useIntercom} from "react-use-intercom";
import {useSelector} from "react-redux";
import {SHOP_ORIGIN_PL} from "../enums/ShopOrigins";

const HelpDropdown = () => {
    const {getLangText} = useLang();
    const {show} = useIntercom();
    const {userData} = useSelector((state) => state.session);

    const HeaderButton = (open) => (
        <div
            className={`rounded-2xl mr-2 inline-flex items-center py-[5px] pl-[7px] pr-[12px] font-semibold text-[#4B5563] hover:bg-gray-200 transition-all text-xs ${open ? 'bg-gray-200' : ''}`}>
            <i className="icon-help text-sm mr-1 -mt-0.5"/>
            {getLangText('asideHelpLabel')}
        </div>
    );

    return (
        <Dropdown Button={HeaderButton}>
            <Menu.Item>
                {({active}) => (
                    <button
                        onClick={show}
                        className={cn(
                            active ? 'bg-gray-100' : '',
                            'w-full p-2.5 rounded-sm px-2 py-[6px] transition-all flex items-center text-xs text-[#1F2937] hover:text-[#1F2937] font-bold hover:no-underline'
                        )}
                    >
                        <i className="w-[16px] icon-support text-[#6b7280] text-base mr-2"/>
                        {getLangText('technicalSupportLabel')}
                    </button>
                )}
            </Menu.Item>
            {userData.origin === SHOP_ORIGIN_PL &&
                <Menu.Item>
                    {({active}) => (
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={RouterPaths.KnowledgeBase}
                            className={cn(
                                active ? 'bg-gray-100' : '',
                                'p-2.5 rounded-sm px-2 py-[6px] transition-all flex items-center text-xs text-[#1F2937] hover:text-[#1F2937] font-bold hover:no-underline'
                            )}
                        >
                            <i className="w-[16px] icon-knowledge-base text-[#6b7280] text-base mr-2"/>
                            {getLangText('knowledgeBaseTitle')}
                        </a>
                    )}
                </Menu.Item>
            }
            {/*<Menu.Item>*/}
            {/*    {({active}) => (*/}
            {/*        <Link*/}
            {/*            to={RouterPaths.News}*/}
            {/*            className={cn(*/}
            {/*                active ? 'bg-gray-100' : '',*/}
            {/*                'p-2.5 rounded-sm px-2 py-[6px] transition-all flex items-center text-xs text-[#1F2937] hover:text-[#1F2937] font-bold hover:no-underline'*/}
            {/*            )}*/}
            {/*        >*/}
            {/*            <i className="icon-downloads text-[#6b7280] text-base mr-2" />*/}
            {/*            {getLangText('materialsToDownloadLabel')}*/}
            {/*        </Link>*/}
            {/*    )}*/}
            {/*</Menu.Item>*/}
            <Menu.Item>
                {({active}) => (
                    <Link
                        to={RouterPaths.News}
                        className={cn(
                            active ? 'bg-gray-100' : '',
                            'p-2.5 rounded-sm px-2 py-[6px] transition-all flex items-center text-xs text-[#1F2937] hover:text-[#1F2937] font-bold hover:no-underline'
                        )}
                    >
                        <i className="w-[16px] icon-news text-[#6b7280] text-base mr-2"/>
                        {getLangText('whatNewLabel')}
                    </Link>
                )}
            </Menu.Item>
            <Menu.Item>
                {({active}) => (
                    <Link
                        to={RouterPaths.Help}
                        className={cn(
                            active ? 'bg-gray-100' : '',
                            'p-2.5 rounded-sm px-2 py-[6px] transition-all flex items-center text-xs text-[#1F2937] hover:text-[#1F2937] font-bold hover:no-underline'
                        )}
                    >
                        <i className="w-[16px] icon-live text-[#6b7280] text-base mr-2"/>
                        {getLangText('videoTutorialLabel')}
                    </Link>
                )}
            </Menu.Item>
            <Menu.Item>
                {({active}) => (
                    <Link
                        to={RouterPaths.PartnersProgram}
                        className={cn(
                            active ? 'bg-gray-100' : '',
                            'p-2.5 rounded-sm px-2 py-[6px] transition-all flex items-center text-xs text-[#1F2937] hover:text-[#1F2937] font-bold hover:no-underline'
                        )}
                    >
                        <i className="w-[16px] icon-gift text-[#6b7280] text-base mr-2"/>
                        {getLangText('asidePartnersProgramLabel')}
                    </Link>
                )}
            </Menu.Item>
        </Dropdown>
    );
};

export default HelpDropdown;
