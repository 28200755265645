import React, {useEffect} from 'react';
import Modal from "react-bootstrap/Modal";
import {services} from "../../../../../../../RestServices";
import useFormLocal from "../../../../../../../../src_shared/hooks/useFormLocal";
import {useHistory, useParams} from "react-router-dom";
import useLang from "../../../../../../../../src_shared/hooks/useLang";
import Field from "../../../../../../../../src_shared/components/form/field/Field";
import Loader from "../../../../../../../../src_shared/components/loader/Loader";
import Model from "../../../../../../../../src_shared/modules/model/Model";
import {RouterPaths} from "../../../../../../../routes/RouterPath";
import ClientInput from "../../../../../../orders/form/components/ClientInput";

const CreateOrderModal = ({modalVisible, hideModal, fetchData, selectedVariants, product}) => {

    const {id} = useParams();
    const {getLangText} = useLang();
    const history = useHistory();

    const afterSubmit = async (response) => {
        hideModal();
        history.push(`${RouterPaths.OrdersList}/${response.id}`)
    }

    const [{isWaiting, onSave, updateValues, values, setNestedValue, setValue, getFieldProps}] = useFormLocal({
        rest: `${services.API_WAREHOUSE_SHOP_PRODUCT}/${id}`,
        model: new Model(),
        afterSubmit,
        initialAutoLoad: false,
    });

    useEffect(() => {
        if (!!+product.has_sizes) {
            updateValues({
                variants: selectedVariants.map((i) => ({
                    id: i,
                    squ: +product.sizes?.find((size) => size.id === i)?.squ > 0 ? 1 : 0,
                    maxSqu: product.sizes?.find((size) => size.id === i)?.squ,
                    name: product.sizes?.find((size) => size.id === i)?.size
                }))
            })
        } else {
            updateValues({
                squ: +product.squ > 0 ? 1 : 0,
                maxSqu: product.squ,
            })
        }
    }, [])

    return (
        <Modal
            dialogClassName="default-modal h-full"
            contentClassName="md:h-auto h-full"
            show={modalVisible}
            onHide={hideModal}
        >
            <Modal.Header>
                <Modal.Title>
                    {getLangText('createOrderWithSelectedProduct')}
                </Modal.Title>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            <form
                className="flex flex-col grow"
                onSubmit={onSave}>
                <Modal.Body>
                    <div className="font-bold mb-2">{getLangText('customerDataLabel')}</div>
                    <div className="grid grid-cols-2 gap-3">
                    {/*    <div className="flex space-x-3">*/}
                    {/*        <div className="grow">*/}
                    {/*            <ClientInput*/}
                    {/*                name="name"*/}
                    {/*                updateField={setValue}*/}
                    {/*                customer={values}*/}
                    {/*                autoFocus={true}*/}
                    {/*                customerId={values.id}*/}
                    {/*                hasCustomerError={false}*/}
                    {/*                showErrorAlert*/}
                    {/*                label={getLangText('firstNameLabel')}*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*        {!values.shop_client_id &&*/}
                    {/*            <div className="grow">*/}
                    {/*                <Field*/}
                    {/*                    label={getLangText('lastNameLabel')}*/}
                    {/*                    name="last_name"*/}
                    {/*                    value={values.last_name}*/}
                    {/*                    setValue={setValue}*/}
                    {/*                    activeValidation={false}*/}
                    {/*                    required*/}
                    {/*                />*/}
                    {/*            </div>*/}
                    {/*        }*/}
                    {/*    </div>*/}
                        <Field
                            {...getFieldProps('name')}
                            label={getLangText('firstNameLabel')}
                            setValue={setValue}
                            extraProps={{autoFocus: true}}
                        />
                        <Field
                            {...getFieldProps('last_name')}
                            label={getLangText('lastNameLabel')}
                            setValue={setValue}
                        />
                    </div>
                    <div className="font-bold mb-2 mt-3">{getLangText('asideProductsLabel')}</div>
                    <div className="bg-[#f9f9f9] sm:p-4 p-[8px] rounded-[8px] space-y-3">
                        {!!+product.has_sizes ?
                            values?.variants?.map((item) => (
                                <div className="flex items-center justify-between">
                                    <div>
                                        <div className="form-label">{getLangText('nameLabel')}</div>
                                        <div className="font-bold">{item.name}</div>
                                    </div>
                                    <Field
                                        name="squ"
                                        setValue={(field, value) => setNestedValue('variants', item.id, field, value)}
                                        value={item.squ}
                                        label={getLangText('quantityLabel')}
                                        className="max-w-[110px] min-w-[80px] mb-0"
                                        type="number"
                                        placeholder={getLangText('enterQuantityLabel')}
                                        extraProps={{
                                            min: 0,
                                            max: item.maxSqu,
                                        }}
                                    />
                                </div>
                            )) :
                            <div className="flex items-center justify-between">
                                <div>
                                    <div className="form-label">{getLangText('nameLabel')}</div>
                                    <div className="font-bold">{product.name}</div>
                                </div>
                                <Field
                                    {...getFieldProps('squ')}
                                    label={getLangText('quantityLabel')}
                                    className="max-w-[110px] min-w-[80px] mb-0"
                                    type="number"
                                    placeholder={getLangText('enterQuantityLabel')}
                                    extraProps={{
                                        min: 0,
                                        max: values.maxSqu,
                                    }}
                                />
                            </div>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        onClick={hideModal}
                        type="button"
                        className="button border-button"
                    >
                        {getLangText('cancelButton')}
                    </button>
                    <button
                        type="submit"
                        className={`button primary ${isWaiting ? 'loading-button' : ''}`}
                    >
                        {isWaiting && <Loader isLoading/>}
                        {getLangText('saveButton')}
                    </button>
                </Modal.Footer>
            </form>
            {/*<ExpendituresModalForm*/}
            {/*    getFieldProps={getFieldProps}*/}
            {/*    isWaiting={isWaiting}*/}
            {/*    onSave={onSave}*/}
            {/*    hideModal={hideModal}*/}
            {/*    maxQuantity={product.size_squ || product.squ}*/}
            {/*/>*/}
        </Modal>
    );
};

export default CreateOrderModal;
