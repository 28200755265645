import React, {useState} from "react";
import moment from "moment";
import useLang from "../../../../../src_shared/hooks/useLang";
import ConfirmModal from "../../../live/details/default/summary/export/ConfirmModal";
import useRemoveData from "../../../../../src_shared/hooks/useRemoveData";
import {services} from "../../../../RestServices";
import PaxyModalForm from "./modal/ModalForm";
import {useDispatch, useSelector} from "react-redux";
import {showAlert} from "../../../shared/components/alert/AlertActions";
import {useDownloadFile} from "../../../../../src_shared/hooks/useDownloadFile";
import StylesLoader from "../../../../../src_shared/components/loader/Loader";
import {usePrintFile} from "../../../../../src_shared/hooks/usePrintFile";
import PaxyOptionsModel from "../../../../../modules/models/order/PaxyOptionsModel";
import {convertToUserTimeZone} from "../../../shared/helpers/dateHelpers";

const PaxyLabel = ({data, loadData}) => {
    const dispatch = useDispatch();
    const {userData} = useSelector((state) => state.session);
    const {getLangText} = useLang();
    const [selectCourierModalOpen, setSelectCourierModalOpen] = useState(false);

    const [{
        openModal: openRemoveModal,
        hideModal: hideRemoveModal,
        confirmModalVisible,
        isDeleting,
        removeItem,
    }] = useRemoveData({
        rest: services.API_PAXY_LABEL,
        afterSubmit: () => {
            loadData(data.shop_order_id);
            dispatch(showAlert('yuhuLabel', 'successfulRemovedLabel'))
        },
        onError: () => dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
    });

    const {download, waiting: waitingForPreviewLabel} = useDownloadFile({
        rest: `${services.API_PAXY_LABEL}/${data.id}`,
        fileName: 'paxy',
    })

    const {print, waiting: waitingForPrint} = usePrintFile({
        rest: `${services.API_PAXY_LABEL}/${data.id}`,
    })


    if (!+userData.shop_paxy_active) {
        return null;
    }

    if (!data.shipping_address_exist) {
        return (
            <section className="summary-section mb-3">
                <div className="section-header mb-3">
                    <div className="flex-grow-1">
                        <h2>{getLangText('generateLabelLabel')}</h2>
                        <div className="section-header__description">
                            {getLangText('addLabelByPaxyProLabel')}
                        </div>
                    </div>
                    <div>
                        <img
                            style={{height: '22px'}}
                            src="/assets/images/paxy-logo.png"
                            alt="Paxy"
                        />
                    </div>
                </div>
                <div
                    className="form-info-box align-items-center w-100 small-line-height">
                    <div>
                        <i className="icon-info-c"/>
                    </div>
                    <div>
                        <div className="title">{getLangText('noDeliveryAddressLabel')}</div>
                    </div>
                </div>
            </section>
        )
    }

    return (
        <>
            {!data.shippingData.paxy_tracking_id ?
                <section className="summary-section mb-3">
                    <div className="section-header mb-3">
                        <div className="flex-grow-1">
                            <h2>{getLangText('generateLabelLabel')}</h2>
                            <div className="section-header__description">
                                {getLangText('addLabelByPaxyProLabel')}
                            </div>
                        </div>
                        <div>
                            <img
                                style={{height: '22px'}}
                                src="/assets/images/paxy-logo.png"
                                alt="Paxy"
                            />
                        </div>
                    </div>
                    <div className="button-place">
                        <button
                            onClick={() => setSelectCourierModalOpen(true)}
                            type="button"
                            className={`button primary w-100`}
                        >
                            {getLangText('generateLabelLabel')}
                        </button>
                    </div>
                </section> :
                <section className="summary-section mb-3">
                    <div className="section-header mb-3">
                        <div className="flex-grow-1">
                            <h2>{getLangText('labelLabel')}</h2>
                            <div className="section-header__description">
                                {getLangText('manageLabelLabel')}
                            </div>
                        </div>
                        <div>
                            <a
                                href="https://furgonetka.pl/konto/partner/1763980?url=https://furgonetka.pl"
                                target="_blank"
                                rel="nofollow"
                            >
                                <img
                                    style={{height: '22px'}}
                                    src="/assets/images/paxy-logo.png"
                                    alt="Paxy"
                                />
                            </a>
                        </div>
                    </div>
                    <div className="tripple-buttons">
                        <div>
                            <button
                                onClick={print}
                                type="button"
                                className={`button border-button big-width w-100 ${waitingForPrint ? 'loading-button' : ''}`}
                            >
                                {waitingForPrint && <StylesLoader isLoading/>}
                                {getLangText('printButton')}
                                <i className="icon-print"/>
                            </button>
                        </div>
                        <div>
                            <button
                                onClick={download}
                                type="button"
                                className={`button border-button big-width w-100 ${waitingForPreviewLabel ? 'loading-button' : ''}`}
                            >
                                {waitingForPreviewLabel && <StylesLoader isLoading/>}
                                {getLangText('previewButton')}
                                <i className="icon-eye"/>
                            </button>
                        </div>
                        <div>
                            <button
                                onClick={openRemoveModal}
                                type="button"
                                className="button border-button big-width w-100"
                            >
                                {getLangText('removeButton')}
                                <i className="icon-bin"/>
                            </button>
                        </div>
                    </div>
                    {data.shippingData.paxy_label_date &&
                        <div className="info-label mt-3">
                            <i className="icon-tick-c"/>
                            {getLangText('labelHasBennGeneratedLabel')}
                            <div className="date-box">
                                <span>{convertToUserTimeZone(data.shippingData.paxy_label_date).format('DD.MM.YYYY')}</span>
                                <span>{convertToUserTimeZone(data.shippingData.paxy_label_date).format('HH:mm:ss')}</span>
                            </div>
                        </div>
                    }
                </section>
            }
            {confirmModalVisible &&
                <ConfirmModal
                    dialogClassName="w-380"
                    title={getLangText('wantToRemoveLabelLabel')}
                    saveButton={getLangText('removeButton')}
                    show={confirmModalVisible}
                    isWaiting={isDeleting}
                    onSubmit={() => removeItem(data.id)}
                    hide={hideRemoveModal}
                    saveButtonClass="danger"
                />
            }
            {selectCourierModalOpen &&
                <PaxyModalForm
                    show={selectCourierModalOpen}
                    hide={() => setSelectCourierModalOpen(false)}
                    loadData={() => loadData(data.shop_order_id)}
                    submitRest={`${services.API_PAXY_OPTIONS}/${data.id}`}
                    optionsModel={PaxyOptionsModel}
                />
            }
        </>
    );
};

export default PaxyLabel;



