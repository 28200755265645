import ListActions, {getGETParamsUrl} from "../../../../../../../src_shared/list/ListActions.js";
import {getStateRoot, prefix} from './listReducer.js';
import {batch} from "react-redux";

import {selmoUrl} from "../../../../../../../src_shared/api/api.js";
import {services} from "../../../../../../RestServices.js";
import {showAlert} from "../../../../../shared/components/alert/AlertActions.js";
import api from "../../../../../../../services/axios/axios";

export class LivesProductsListActions extends ListActions {

	getLoadParams(state) {
		const stateRoot = this.getStateRoot(state);

		const params = {
			page: stateRoot.page,
		};

		const superSearch = stateRoot.search;
		const sortBy = stateRoot.sortBy;

		if (superSearch) {
			params.superSearch = superSearch;
		}

		if (sortBy) {
			params.sortBy = sortBy;
		}

		return params;
	}

	loadNewPage(id) {
		return async (dispatch, getState) => {
			try {
				const state = this.getStateRoot(getState());
				const extraParams = this.getLoadParams(getState())

				const params = getGETParamsUrl({
					...extraParams,
					page: state.scrollPage + 1,
				});


				const {data} = await api.get(`${selmoUrl}/${this.restService}/${id}${params}`)

				const {items} = state;
				const updatedItems = items.concat(data.items);

				batch(() => {
					dispatch(this.table.updateAllItems(updatedItems));
					dispatch(this.setScrollPage(state.scrollPage + 1));
				});

				if (!data.hasMore) {
					dispatch(this.setHasMorePages(false));
				}
			} catch (e) {
				console.warn('Cannot get next page')
			}
		};
	}

	updateFieldInClient(productId, clientId, field, value) {
		return {
			type: `${this.prefix}UPDATE_FIELD_IN_CLIENT`,
			productId,
			clientId,
			field,
			value,
		};
	}

	addNewCustomer(productId) {
		return {
			type: `${this.prefix}ADD_NEW_CUSTOMER`,
			productId,
		};
	}

	setScrollPage(scrollPage) {
		return {
			type: `${this.prefix}SET_SCROLL_PAGE`,
			scrollPage,
		};
	}

	setCustomerDuplicateId(productId, duplicatedId) {
		return {
			type: `${this.prefix}SET_CUSTOMER_DUPLICATED_ID`,
			productId,
			duplicatedId,
		};
	}

	setHasMorePages(toggle) {
		return {
			type: `${this.prefix}SET_HAS_MORE_PAGES`,
			toggle,
		};
	}

	setValidationActive(toggle) {
		return {
			type: `${this.prefix}SET_VALIDATION_ACTIVE`,
			toggle,
		};
	}

	setWaitingForAdding(toggle) {
		return {
			type: `${this.prefix}SET_WAITING_FOR_ADDING`,
			toggle,
		};
	}

	addCustomersToProduct(productId) {
		return async (dispatch, getState) => {
			const {items} = getStateRoot(getState());

			const product = items.find((client) => client.id === productId)

			const isEmptyVariant = (client) => !!product.sizes?.length && !client.variant_id;

			const {live_id, id, name, product_id, price, code} = product;

			const preparedClient = product.clients.map((client) => ({
				live_clients_id: client.id,
				name: client.name,
				quantity: client.quantity,
				shop_client_id: client.shop_client_id || null,
				is_deleted: client.is_deleted || null,
				variant_id: client.variant_id || null,
				color: client.color || null,
				price: client.price || null,
				code: client.code || null,
				size: client.size || null,
				live_products_id: !client.id ? null : client.live_products_id,
			}))

			const preparedData = {
				name,
				saved_product_id: id,
				product_id,
				clients: preparedClient,
				price,
				code,
			}

			const isEmptySomeCustomerProductField = product.clients.some((client) => !+client.is_deleted && (!client.quantity || client.quantity <= 0 || !client.name || isEmptyVariant(client)))

			if (isEmptySomeCustomerProductField) {
				dispatch(this.setValidationActive(true))
				dispatch(showAlert('sthWentWrongWarning', 'fillRequireFieldWarning', 'alert-fail'))
				return;
			}

			dispatch(this.setWaitingForAdding(true))

			try {
				await api.put(`${selmoUrl}/${services.API_LIVE_DETAILS_SAVED_PRODUCTS_LIST}/${live_id}`, preparedData)
				dispatch(showAlert());
				dispatch(this.loadData(live_id))
				dispatch(this.resetPageParams())
				dispatch(this.setValidationActive(false))
			} catch (e) {
				dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
			} finally {
				dispatch(this.setWaitingForAdding(false))
			}
		}
	}

	resetPageParams() {
		return (dispatch) => {
			batch(() => {
				dispatch(this.setHasMorePages(true))
				dispatch(this.setScrollPage(1))
			})
		}
	}
}

export const getInstance = () => new LivesProductsListActions({
	getStateRoot,
	restService: services.API_LIVE_DETAILS_SAVED_PRODUCTS_LIST,
	prefix,
	preventPushParams: true,
});

export default getInstance();
