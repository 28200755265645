import React from 'react';
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import useLang from "../../../../src_shared/hooks/useLang";
import {useSelector} from "react-redux";
import {SHOP_ORIGIN_PL} from "../../shared/enums/ShopOrigins";

const options = [
    {
        id: 'shop_bm_active',
        title: 'configureFastPaymentsLabel',
        desc: 'configureFastPaymentsSubLabel',
        imgs: ['/assets/images/payments/autopay.webp', '/assets/images/payments/blik.svg'],
        link: RouterPaths.SettingsPaymentsOnlineLanding
    },
    {
        id: 'shop_furgonetka_active',
        title: 'generateLabelsAutomaticallyLabel',
        desc: 'configureFastPaymentsSubLabel',
        imgs: ['/assets/images/export/furgonetka-logo.svg'],
        link: RouterPaths.SettingsIntegrationFurgonetka
    },
]

const DoMoreBoxes = () => {
    const {getLangText} = useLang();
    const {userData} = useSelector((state) => state.session);

    if (options.every((option) => !!+userData[option.id])) {
        return null;
    }

    if (userData.origin !== SHOP_ORIGIN_PL) {
        return null;
    }

    return (
        <div>
            <div className="flex items-center mb-3 mt-10">
                <h2 className="grow text-xl font-bold">{getLangText('doMoreWithSelmoLabel')}</h2>
                <Link
                    className="text-[#3C61EA] text-sm font-bold hover:text-[#1c4ed8] hover:no-underline transition-all"
                    to={RouterPaths.Settings}>
                    {getLangText('goToSettingsLabel')}
                    <i className="icon-arrows ml-1 text-[10px] inline-block -rotate-[90deg]"/>
                </Link>
            </div>
            <div className="space-y-5">
                {options.map((option) => (
                    !+userData[option.id] &&
                    <Link
                        to={option.link}
                        key={option.id}
                        className="dashboard-card hover:no-underline w-full block"
                    >
                        <div className="flex items-center mb-2">
                            {option.imgs.map((src) => (
                                <img
                                    key={src}
                                    className="mr-2 h-[22px]" height={22} src={src} alt="logo"
                                />
                            ))}
                            <div className="button ml-auto primary small-size">
                                {getLangText('configureLabel')}
                            </div>
                        </div>
                        <div>
                            <div className="text-lg font-bold mb-1">
                                {getLangText(option.title)}
                            </div>
                            <div className="text-sm text-[#6B7280] pb-2">
                                {getLangText(option.desc)}
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default DoMoreBoxes;
