const initialState = {
    items: [],
    isOpen: false,
    isModalOpen: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'FILES_TOGGLE_VISIBILITY':
            return {...state, isOpen: action.toggle};
        case 'FILES_MODAL_TOGGLE_VISIBILITY':
            return {...state, isModalOpen: action.toggle};
        case 'FILES_ADD_FILE':
            return {...state, items: [action.file, ...state.items]};
        case 'FILES_UPDATE_FILE':
            return {
                ...state,
                items: [...state.items.map((item) => +item.id === +action.file.id ? action.file : item)],
                isOpen: true,
            };
        case 'FILES_UPDATE_FILES':
            return {
                ...state,
                items: action.files,
            };
        default:
            return state;
    }
}
