import {useState} from "react";
import {selmoUrl} from "../api/api";
import {useDispatch} from "react-redux";
import {getGETParamsUrl} from "../list/ListActions";
import {useHistory} from "react-router-dom";
import {showAlert} from "../../components/app/shared/components/alert/AlertActions";
import api from "../../services/axios/axios";

const useRemoveData = (config = {}) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [response, setResponse] = useState({});
	const [isDeleting, setIsDeleting] = useState(false);
	const [confirmModalVisible, setConfirmModalVisible] = useState(false);
	const [itemToRemove, setItemToRemove] = useState({});

	const hideModal = () => setConfirmModalVisible(false);
	const openModal = (item) => {
		setItemToRemove(item);
		setConfirmModalVisible(true)
	};

	const {
		rest = '',
		method = 'delete',
		redirectRoute = '',
		params = {},
		afterSubmit = () => {
			if (!!redirectRoute) {
				history.push(redirectRoute)
			}
			dispatch(showAlert('yuhuLabel', 'successfulRemovedLabel'))
		},
		onError = () => dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail')),
	} = config;

	const removeItem = async (id) => {
		setIsDeleting(true);

		try {
			const {data} = await api[method](`${selmoUrl}/${rest}/${itemToRemove.id || id}${getGETParamsUrl(params)}`);
			setResponse(data?.item || data?.items);
			hideModal()
			afterSubmit(data, dispatch);

		} catch (error) {
			onError(dispatch)
		} finally {
			setIsDeleting(false);
		}
	};

	return [{
		response,
		isDeleting,
		setIsDeleting,
		removeItem,
		setResponse,
		hideModal,
		openModal,
		setConfirmModalVisible,
		confirmModalVisible,
		setItemToRemove,
		itemToRemove
	}];
};

export default useRemoveData;
