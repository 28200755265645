import React, {useState} from 'react';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import ListActions from "../ListActions";
import {useDispatch} from "react-redux";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import SearchInput from "./SearchInput";
import useWindowDimensions from "../../../../../shared/hooks/useWindowDimensions";

const CommentsHeader = ({setFullScreen, fullScreen, setZoomValue, zoomValue, search, listRef}) => {
	const dispatch = useDispatch();
	const [searchView, setSearchView] = useState(false);
	const {width} = useWindowDimensions();
	const {getLangText} = useLang();

	const hideCommentsPanel = () => dispatch(ListActions.showCommentsPanel(false));

	const callSearch = (searchText) => {
		dispatch(ListActions.table.updateSearch(searchText));
		dispatch(ListActions.loadPage(1));
		dispatch(ListActions.loadReversedData());
	}

	const onCloseClick = () => {
		setSearchView(false)
		callSearch('')
	}

	return (
		<div className="comments-header">
			{searchView ?
				<div className="search-wrapper">
					<SearchInput
						placeholder={getLangText('searchCommentOrClient')}
						search={search}
						callSearch={callSearch}
						autoFocus={true}
					/>
					<button
						onClick={onCloseClick}
						type="button"
						className="icon-button"
					>
						<i className="icon-cross"/>
					</button>
				</div> :
				<>
					<button
						onClick={hideCommentsPanel}
						type="button"
						className=" icon-button"
					>
						<i className=" icon-cross"/>
					</button>
					<div className="title">{getLangText('commentsLabel')}</div>
					<div className="right-side-buttons">
						<OverlayTrigger
							placement='bottom'
							overlay={
								<Tooltip id="search">
									{getLangText('searchPlaceholder')}
								</Tooltip>
							}
						>
							<button
								type="button"
								className="icon-button search-icon-button"
								onClick={() => setSearchView(true)}
							>
								<i className="icon-magnifier"/>
							</button>
						</OverlayTrigger>
						<OverlayTrigger
							placement='bottom'
							overlay={
								<Tooltip id="full-screen">
									{getLangText('fullScreenLabel')}
								</Tooltip>
							}
						>
							<button
								type="button"
								onClick={() => setFullScreen(!fullScreen)}
								className=" icon-button right-button d-lg-block d-none"
							>
								{fullScreen ?
									<i className=" icon-default-screen"/> :
									<i className=" icon-full-screen"/>
								}
							</button>
						</OverlayTrigger>
					</div>
					{(fullScreen || width < 991) &&
						<div className=" zoom-buttons">
							<button
								className={`no-bg-hover ${zoomValue === 1 ? 'hidden' : ''}`}
								type=" button"
								onClick={() => setZoomValue(1)}
							>
								{getLangText('revertButton')}
							</button>
							<OverlayTrigger
								placement='bottom'
								overlay={
									<Tooltip id=" zoom-out">
										{getLangText('zoomOutLabel')}
									</Tooltip>
								}
							>
								<button
									className=" minus"
									type=" button"
									onClick={() => setZoomValue(zoomValue - 0.05)}
								>
									<span>-</span>
								</button>
							</OverlayTrigger>
							<OverlayTrigger
								placement='bottom'
								overlay={
									<Tooltip id=" zoom-in">
										{getLangText('zoomInLabel')}
									</Tooltip>
								}
							>
								<button type=" button" onClick={() => setZoomValue(zoomValue + 0.05)}>
									<i className=" icon-plus"/>
								</button>
							</OverlayTrigger>
						</div>
					}
				</>
			}
		</div>
	);
};

export default CommentsHeader;
