import React from 'react';
import MultiAsyncSelectField from "../../../../../src_shared/components/form/selectField/MultiAsyncSelectField";
import {services} from "../../../../RestServices";
import useLang from "../../../../../src_shared/hooks/useLang";
import PermissionsWrapper from "../../../../routes/PermissionsWrapper";
import {SHOP_PERMISSION} from "../../../shared/enums/SelmoPermissions";
import SwitchField from "../../../../../src_shared/components/form/switchField/SwitchField";

const Categories = ({getFieldProps, updateValues, values, inModal, setValue}) => {
    const {getLangText} = useLang();

    const formatOptionLabel = (value) => (
        <div className={`${value.parent_id ? 'pl-3 sub-category-element' : 'font-weight-bold'}`}>
            {value.label}
        </div>)

    const optionParser = (options) => {
        return options.reduce((arr, option) => {
            arr.push({value: option.id, label: option.name})
            option?.subcategories?.length && option?.subcategories.forEach((sub) => {
                arr.push({value: sub.id, label: sub.name, parent_id: true})
            })
            return arr
        }, [])
    }


    return (
        <section className={!inModal ? 'default-shadow-box big-padd  mb-3' : ''}>
            {!inModal &&
                <div className="left-side">
                    <h2 className="font-bold mb-3.5">{getLangText('categoryLabel')}</h2>
                </div>
            }
            <div className="form-col">
                <MultiAsyncSelectField
                    {...getFieldProps('categories')}
                    label={getLangText('selectCategoryLabel')}
                    updateValues={updateValues}
                    placeholder={getLangText('selectButton')}
                    rest={services.API_CATEGORIES_WITH_SUBCATEGORIES}
                    defaultValue={values.categories}
                    formatOptionLabel={formatOptionLabel}
                    optionsParser={optionParser}
                    className="default-size mb-0"
                    extraProps={{
                        isClearable: false,
                        noOptionsMessage: () => getLangText('noResultsLabel'),
                        loadingMessage: () => getLangText('loadingLabel')
                    }}
                />
                {!inModal &&
                    <PermissionsWrapper
                        allowedPermissions={[SHOP_PERMISSION]}
                    >
                        <div className="mt-3">
                            <SwitchField
                                {...getFieldProps('is_shop_active')}
                                setValue={setValue}
                                label={getLangText('activeProductInShopLabel')}
                                subLabel={getLangText('activeProductInShopSubLabel')}
                                id="is_shop_active"
                                className="switch-form inline-label big-label light-version mb-0"
                            />
                        </div>
                    </PermissionsWrapper>
                }
            </div>
        </section>
    );
};

export default Categories;
