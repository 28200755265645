const initialState = {
	isOpen: false, product: {}, id: null,
}
export default (state = initialState, action) => {
	switch (action.type) {
		case 'SELECT_SIZES_MODAL_TOGGLE_VISIBILITY':
			const {toggle, product, id} = action;
			return {...state, isOpen: toggle, product, id};
		default:
			return state;
	}
};
