import React, {useEffect, useState} from 'react';
import ComponentLoader from "../../../../../src_shared/components/ui/componentLoader/ComponentLoader";
import {Scanner as QRScanner} from "@yudiel/react-qr-scanner";
import CustomScanner from "../../../storage/scanner/CustomScanner";
import useLang from "../../../../../src_shared/hooks/useLang";
import {useSelector} from "react-redux";
import {TEST_ACCOUNT_SHOP_ID, TIM_SHOP_ID} from "../../../../../src_shared/enums/TrustedShopsIds";

const QrScanner = ({
                       handleResult,
                       height = '50vh',
                       waitingForScan = false,
                       defaultActiveScanner = 2,
                       products,
                       checkProducts = false
                   }) => {
    const [devices, setDevices] = useState([]);
    const [waitingForCamera, setWaitingForCamera] = useState(true);
    const [activeScanner, setActiveScanner] = useState(defaultActiveScanner);
    const {getLangText} = useLang()
    const [cameraFacingMode, setCameraFacingMode] = useState('environment');
    const {userData} = useSelector((state) => state.session);

    const getDevices = async () => {
        const devices = await navigator.mediaDevices.enumerateDevices();
        setDevices(devices);
        setWaitingForCamera(false);
    }

    const androidCameraDevice = devices.find((i) => cameraFacingMode === 'environment' ? i.label.includes('camera2 0, ') : i.label.includes('camera2 3, facing front'));

    useEffect(() => {
        if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
            getDevices()
        }
    }, [navigator])

    return (
        waitingForCamera ?
            <ComponentLoader/> :
            <div>
                <div className="grid grid-cols-2 px-3 pb-2 gap-x-3">
                    <button
                        type="button"
                        className={`border flex items-center justify-center relative rounded-[5px] p-2 text-xs transition-all font-bold ${activeScanner === 2 ? 'border-[#3C61EA] text-[#3C61EA] bg-[#0000ff0d]' : 'text-desc border-[#dadada]'}`}
                        onClick={() => setActiveScanner(2)}
                    >
                        {getLangText('scanner')}&nbsp;1
                        {/*<div*/}
                        {/*    className="ml-2 bg-[#279bff] text-[white] text-[8px] rounded-[3px] px-[3px] py-[1px] tracking-[0.5px] font-bold">*/}
                        {/*    NEW*/}
                        {/*</div>*/}
                    </button>
                    <button
                        type="button"
                        className={`border rounded-[5px] p-2 text-xs transition-all font-bold ${activeScanner === 1 ? 'border-[#3C61EA] text-[#3C61EA] bg-[#0000ff0d]' : 'text-desc border-[#dadada]'}`}
                        onClick={() => setActiveScanner(1)}
                    >
                        {getLangText('scanner')}&nbsp;2
                    </button>
                </div>
                <div className="completing-scanner">
                    <div className="relative">
                        {(+userData.id === TIM_SHOP_ID || +userData.id === TEST_ACCOUNT_SHOP_ID) &&
                            <button
                                type="button"
                                onClick={() => setCameraFacingMode((prevState) => prevState === 'user' ? 'environment' : 'user')}
                                className="absolute text-desc top-5 right-5 z-10 bg-[rgba(255,255,255,0.3)] backdrop-blur-sm flex justify-center items-center w-[35px] h-[35px] rounded-full">
                                <i className="icon-refresh"/>
                            </button>
                        }
                        {activeScanner === 1 &&
                            (androidCameraDevice?.deviceId ?
                                    <QRScanner
                                        styles={{
                                            video: {
                                                // objectFit: 'cover',
                                                height: height,
                                            },
                                            container: {
                                                height: height,
                                                paddingTop: 0,
                                                width: '100%'
                                            },
                                        }}
                                        formats={['code_128', 'aztec', 'data_matrix', 'qr_code', 'micro_qr_code', 'ean_8', 'ean_13', 'code_39', 'code_93']}
                                        enabled={true}
                                        components={{
                                            torch: true,
                                            finder: false
                                        }}
                                        constraints={{
                                            deviceId: androidCameraDevice.deviceId,
                                        }}
                                        onScan={(result) => handleResult(result[0].rawValue)}
                                        onError={(error) => alert(error?.message)}
                                        allowMultiple={true}
                                        scanDelay={1500}
                                        options={{
                                            deviceId: androidCameraDevice?.deviceId,
                                            delayBetweenScanSuccess: 1500,
                                            delayBetweenScanAttempts: 1500,
                                        }}
                                    /> :
                                    <QRScanner
                                        styles={{
                                            video: {
                                                // objectFit: 'cover',
                                                height: height,
                                            },
                                            container: {
                                                height: height,
                                                paddingTop: 0,
                                                width: '100%'
                                            },
                                        }}
                                        enabled={true}
                                        components={{
                                            torch: true,
                                            finder: false
                                        }}
                                        allowMultiple={true}
                                        formats={['code_128', 'aztec', 'data_matrix', 'qr_code', 'micro_qr_code', 'ean_8', 'ean_13', 'code_39', 'code_93']}
                                        constraints={{facingMode: cameraFacingMode}}
                                        onScan={(result) => handleResult(result[0].rawValue)}
                                        onError={(error) => alert(error?.message)}
                                        scanDelay={1500}
                                        options={{
                                            delayBetweenScanSuccess: 1500,
                                            delayBetweenScanAttempts: 1500,
                                        }}
                                    />
                            )
                        }
                        {activeScanner === 2 &&
                            <CustomScanner
                                cameraFacingMode={cameraFacingMode}
                                height={height}
                                waitingForScan={waitingForScan}
                                cameraId={androidCameraDevice?.deviceId}
                                handleResult={handleResult}
                                products={products}
                                checkProducts={checkProducts}
                            />
                        }
                    </div>
                </div>
            </div>
    );
};

export default QrScanner;
