import Model, {convertObjectProperties} from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

class SettingsInpostModel extends Model {
	getModel() {
		return {
			organization_id: '',
			token: '',
			active: '0',
		};
	}

	getValidators() {
		return {
			organization_id: ValidationError.notEmpty,
			token: ValidationError.notEmpty,
			active: ValidationError.skip,
		};
	}
}

export default new SettingsInpostModel();
