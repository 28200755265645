import React from 'react';
import { useSelector} from "react-redux";
import useLogoutAsClient from "../../../../../src_shared/hooks/useLogoutAsClient";

const ClientAccountBar = () => {
    const {userData} = useSelector((state) => state.session);
    const {logoutAsClient} = useLogoutAsClient();

    if (!+userData.old_user_id) {
        return null;
    }

    return (
        <button
            onClick={logoutAsClient}
            type="button"
            className="bg-gradient-to-r
from-red-500
to-red-800
animate-gradient-x
 text-white p-2.5 text-sm text-center w-full underline z-10 relative"
        >
            Uwaga, jesteś na koncie klienta, kliknij, aby wrócić do swojego konta
        </button>
    );
};

export default ClientAccountBar;
