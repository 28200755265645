import React, {useState} from 'react';
import useLang from "../../../../../../../src_shared/hooks/useLang";
import ConfirmModal from "../../../../../../../src_shared/components/modal/ConfirmModal";
import useSubmitData from "../../../../../../../src_shared/hooks/useSubmitData";
import {services} from "../../../../../../RestServices";

const UnpackOrderButton = ({values, fetchProducts}) => {
    const {getLangText} = useLang();
    const [showModal, setShowModal] = useState(false);

    const [{onSave, isWaiting}] = useSubmitData({
        rest: `${services.API_WAREHOUSE_UNPACK_ALL_ORDERS}/${values.id}`,
        afterSubmit: () => {
            fetchProducts();
            setShowModal(false);
        },
    });

    return (
        <>
            <button
                onClick={() => setShowModal(true)}
                type="button"
                className="button primary w-full mt-2"
            >
                {getLangText('unpackOrder')}
            </button>
            {showModal &&
                <ConfirmModal
                    dialogClassName="w-380 small-modal"
                    title={getLangText('unpackOrder')}
                    SubTitle={() => <div className="text-sm text-desc font-medium mt-1">{getLangText('wantUnPackAllProducts')}</div>}
                    saveButton={getLangText('yesButton')}
                    show={showModal}
                    isWaiting={isWaiting}
                    onSubmit={onSave}
                    hide={() => setShowModal(false)}
                />
            }
        </>
    );
};

export default UnpackOrderButton;
