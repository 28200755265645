import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

export default (actions, getStateRoot) => {
    const [search, items, sortBy, sortDir, markedToRemove] = useSelector(
        createSelector(
            getStateRoot,
            (stateRoot) => [
                stateRoot.search,
                stateRoot.items,
                stateRoot.sortBy,
                stateRoot.sortDir,
                stateRoot.markedToRemove,
            ],
        ),
    );

    const dispatch = useDispatch();

    const updateSearchText = useCallback(
        (searchText) => dispatch(actions.updateSearch(searchText)), [actions, dispatch],
    );
    const markItemToRemove = useCallback(
        (itemId) => dispatch(actions.markItemToRemove(itemId)), [actions, dispatch],
    );
    const removeItem = useCallback(
        (itemId) => dispatch(actions.removeItem(itemId)), [actions, dispatch],
    );

    return {
        remove: {
            markItemToRemove,
            removeItem,
			markedToRemove,
        },

        search: {
            updateSearchText,
            searchText: search,
        },

        sort: {
            sortBy,
            sortDir,
        },

        collection: {
            items,
        },
    };
};
