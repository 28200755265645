export const variants = (count, variantsLang) => {
	const { singular, plural, genitive } = variantsLang;

	const safeCount = Math.abs(count);
	if (count === 1) return singular;
	const rest10 = safeCount % 10;
	const rest100 = safeCount % 100;
	if (rest10 > 4 || rest10 < 2 || (rest100 < 15 && rest100 > 11)) return genitive;
	return plural;
};
