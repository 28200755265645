import React, {useEffect, useMemo, useState} from 'react';
import CollapseStep from "./CollapseStep";
import {RouterPaths} from "../../../routes/RouterPath";
import {
    DASHBOARD_CHECKBOX_LIVE,
    DASHBOARD_CHECKBOX_MESSENGER,
    DASHBOARD_CHECKBOX_PRODUCTS, DASHBOARD_CHECKBOX_SEND_NOTIFICATIONS
} from "../../shared/enums/TutorialVideoStatsTypes";
import {useSelector} from "react-redux";
import {DASHBOARD_CHECKBOX_AUTOMATION} from "../../shared/enums/TutorialsVideo";

const collapseSteps = [
    {id: 'firstMessengerOrder', title: 'editBasketInConversationsLabel', desc: 'editBasketInConversationsSubLabel', buttonLink: RouterPaths.Messenger, buttonTitle: 'goToConversationButton', videoStatsName: DASHBOARD_CHECKBOX_MESSENGER, previewSrc: 'messenger'},
    {id: 'firstProduct', title: 'addYourFirstProductLabel', desc: 'addYourFirstProductSubLabel', buttonLink: RouterPaths.ProductsList, buttonTitle: 'addProductLabel', videoStatsName: DASHBOARD_CHECKBOX_PRODUCTS, previewSrc: 'products'},
    {id: 'firstBatchSummary', title: 'sendSummariesOneClickLabel', desc: 'sendSummariesOneClickSubLabel', buttonLink: RouterPaths.LiveList, buttonTitle: 'createBroadcastLabel', videoStatsName: DASHBOARD_CHECKBOX_LIVE, previewSrc: 'live-summary'},
    {id: 'firstNotifications', title: 'sendNotificationsLabel', desc: 'askToClientsToTypeSelmoLabel', buttonLink: RouterPaths.ClientsSubmittedList, buttonTitle: 'sendNotificationsLabel', videoStatsName: DASHBOARD_CHECKBOX_SEND_NOTIFICATIONS, previewSrc: 'notifications'},
]

const instagramCollapseSteps = [
    {id: 'firstAutomation', title: 'createFirstAutomation', desc: 'createKeyWordLabel', buttonLink: RouterPaths.Automation, buttonTitle: 'addAutomationLabel', videoStatsName: DASHBOARD_CHECKBOX_AUTOMATION, previewSrc: 'automation'},
    {id: 'firstProduct', title: 'addYourFirstProductLabel', desc: 'addYourFirstProductSubLabel', buttonLink: RouterPaths.ProductsList, buttonTitle: 'addProductLabel', videoStatsName: DASHBOARD_CHECKBOX_PRODUCTS, previewSrc: 'products'},
]

const Collapses = ({data}) => {
    const [open, setOpen] = useState(null)
    const {userData} = useSelector((state) => state.session);

    const preparedOptions = useMemo(() => !!+userData.instagram_automation ? instagramCollapseSteps : collapseSteps, [userData.instagram_automation]);

    useEffect(() => {

        if (data?.trialPoints) {
            setOpen(collapseSteps.find((i) => !data.trialPoints[i.id])?.id || null)
        }

    }, [data.trialPoints])

    return (
        <div className="mt-3.5">
            {preparedOptions.map((step) => (
                <CollapseStep
                    key={step.id}
                    title={step.title}
                    desc={step.desc}
                    buttonLink={step.buttonLink}
                    buttonTitle={step.buttonTitle}
                    checked={data.trialPoints[step.id]}
                    videoStatsName={step.videoStatsName}
                    previewSrc={step.previewSrc}
                    setOpen={setOpen}
                    open={open}
                    id={step.id}
                />
            ))}
        </div>
    );
};

export default Collapses;
