import React from 'react';
import useLang from "../../../../../../../src_shared/hooks/useLang";
import CustomDropdown from "../../../../../shared/components/dropdown/CustomDropdown";

const TemplateItem = ({item, selectedTemplateId, setSelectedTemplateId, selectTemplate, setActiveView, openRemoveModal}) => {
    const {getLangText} = useLang();

    return (
        <button
            onClick={() => {
                if (+selectedTemplateId === +item.id) {
                    selectTemplate(item.id)
                } else {
                    setSelectedTemplateId(item.id)
                }
            }}
            type="button"
            className="flex text-left w-full p-[10px] hover:bg-[#f9f9f9] transition-all rounded-[15px]"
        >
            <div>
                <div className="pr-2 text-sm font-bold">
                    {item.name}
                </div>
                <div
                    dangerouslySetInnerHTML={{__html: item.text}}
                    className="text-xs text-desc font-medium whitespace-pre-wrap pr-2"
                />
            </div>
            <CustomDropdown
                onClick={(e) => e.stopPropagation()}
                className="ml-auto mr-2"
                icon="icon-dots"
            >
                <button
                    className="custom-option"
                    type="button"
                    onClick={() => setActiveView(item.id)}
                >
                    {getLangText('editTemplate')}
                </button>
                <button
                    className="custom-option"
                    type="button"
                    onClick={() => openRemoveModal(item)}
                >
                    {getLangText('removeTemplate')}
                </button>
            </CustomDropdown>
            {/*<Menu*/}
            {/*    style={{position: 'absolute', top: -20, right: 0, zIndex: 1}}>*/}
            {/*    <MenuTrigger*/}
            {/*        customStyles={{*/}
            {/*            triggerTouchable: {*/}
            {/*                underlayColor: 'white',*/}
            {/*            }*/}
            {/*        }}*/}
            {/*        style={{*/}
            {/*            backgroundColor: 'transparent',*/}
            {/*            paddingVertical: 20,*/}
            {/*            paddingHorizontal: 15,*/}
            {/*            marginRight: -10,*/}
            {/*        }}>*/}
            {/*        <Icon name="dots" color="#9CA3AF" size={4}/>*/}
            {/*    </MenuTrigger>*/}
            {/*    <MenuOptions*/}
            {/*        optionsContainerStyle={{*/}
            {/*            marginTop: -30,*/}
            {/*            shadowColor: 'transparent',*/}
            {/*            borderWidth: 1,*/}
            {/*            borderColor: '#E5E7EB',*/}
            {/*            borderRadius: 7,*/}
            {/*            width: 'auto',*/}
            {/*            right: 10,*/}
            {/*        }}>*/}
            {/*        <MenuOption*/}
            {/*            style={{padding: 12, width: 'auto'}}*/}
            {/*            onSelect={() => setActiveView(item.id)}*/}
            {/*        >*/}
            {/*            <Text style={{*/}
            {/*                fontSize: 12,*/}
            {/*                fontWeight: '500',*/}
            {/*                color: '#1F2937',*/}
            {/*                textAlign: 'right',*/}
            {/*                fontFamily: "PlusJakartaSans-Medium",*/}
            {/*            }}>*/}
            {/*                {t('editTemplate')}*/}
            {/*            </Text>*/}
            {/*        </MenuOption>*/}
            {/*    </MenuOptions>*/}
            {/*</Menu>*/}
        </button>
    )
};

export default TemplateItem;
