import React from 'react';
import PickupPointSelected from "../shared/PickupPointSelected";
import {services} from "../../../../../../RestServices";

const DpdSelmoPaczka = ({data, options, fetchData, isCourier, showDataModal, isQualified, isOpen, setIsOpen}) => {
    const selectedPointName = data.pointMachineCode ? data.pointMachineName : null

    const getSelectedPointData = () => {
        if (isCourier) {
            return {
                selectedPointName: 'od 18.79 zł',
                imgSrc: '/assets/images/svg/dpd-logo.svg',
                imgWidth: 40,
                selectedPointLabel: 'DPD Kurier'
            }
        }
        return {
            selectedPointName: selectedPointName,
            imgSrc: '/assets/images/svg/dpd-pick-up-logo.svg',
            imgWidth: 80,
            selectedPointLabel: 'selectedPoint'
        }
    }

    return (
        <PickupPointSelected
            {...getSelectedPointData()}
            rest={services.API_PACKAGE_DPD_PICKUP_LABEL}
            orderId={data.id}
            fetchData={fetchData}
            options={options}
            showDataModal={showDataModal}
            isQualified={isQualified}
            type={isCourier ? 'dpd' : 'dpd_box'}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
        />
    );
};

export default DpdSelmoPaczka;
