import React, {useState} from 'react';
import useLang from "../../../src_shared/hooks/useLang";
import DeliveryAddressFormModal from "./form/Form";

const Guest = ({hash, values}) => {
	const {getLangText} = useLang();
	const [showModal, setShowModal] = useState(false);

	return (
		<>
			<button
				type="button"
				onClick={() => setShowModal(true)}
				className={`button border-button w-100`}
			>
				{getLangText('continueAsGuestLabel')}
			</button>
			{showModal &&
				<DeliveryAddressFormModal
					data={values}
					hash={hash}
					modalVisible={showModal}
					hideModal={() => setShowModal(false)}
				/>
			}
		</>
	);
};

export default Guest;
