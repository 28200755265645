import {SELMO_APP_PERMISSION} from "../enums/SelmoPermissions";

const initialState = {
    logged: true,
    isTutorial: false,
    isMaintenancePage: false,
    isErrorPage: false,
    userData: {
        facebook_id: false,
        instagram_id: false,
        user_info: {
            status: true,
        },
        notValid: false,
        access_without_fanpage: false,
        currency_info: {},
        permissions: [SELMO_APP_PERMISSION],
        tutorialBoxes: {
            hidden_orders: false,
            hidden_products: false,
            hidden_live: false,
            hidden_messenger: false,
            hidden_automation: false,
        },
        functionalities: [],
        user_permissions: [],
        first_step_sales_posts: false,
        first_step_orders: false,
        first_step_products: false,
        first_step_live: false,
        first_step_automation: false,
        globalAlert: {
            active: '0',
        },
    },
    waitingForResponse: true,
    checked: false,
    firstInit: true,
    token: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case 'SESSION_CHECKED':
            return {...state, checked: true};
        case 'SESSION_UNCHECKED':
            return {...state, checked: false};
        case 'SESSION_START_WAITING':
            return {...state, waitingForResponse: true};
        case 'SESSION_STOP_WAITING':
            return {...state, waitingForResponse: false};
        case 'SESSION_TUTORIAL':
            return {...state, isTutorial: action.toggle};
        case 'SESSION_SET_MAINTENANCE_PAGE':
            return {...state, isMaintenancePage: action.toggle};
        case 'SESSION_SET_ERROR_PAGE':
            return {...state, isErrorPage: action.toggle};
        case 'SESSION_LOGGED':
            return {...state, logged: action.toggle};
        case 'SESSION_SET_TOKEN':
            return {...state, token: action.token};
        case 'SESSION_LOGIN':
            if (action.userData == null) {
                console.error('sessionReducer error: action SESSION_LOGIN has invalid data!');
                console.info(action);
                return state;
            }

            return {
                ...state,
                logged: true,
                userData: action.userData,
            }
        case 'SESSION_SET_FIRST_INIT':
            return {...state, firstInit: false}
        case 'SESSION_UPDATE_TUTORIAL_BOX_VISIBILITY':
            return {
                ...state,
                userData: {
                    ...state.userData,
                    tutorialBoxes: {
                        ...state.userData.tutorialBoxes,
                        [action.field]: action.value
                    }
                }
            };
        case 'SESSION_UPDATE_USER_DATA':
            return {
                ...state,
                userData: {
                    ...state.userData,
                    [action.field]: action.value,
                }
            };

        case 'SESSION_UPDATE_GLOBAL_ALERT':
            return {
                ...state,
                userData: {
                    ...state.userData,
                    globalAlert: action.object,
                }
            };
        case 'SESSION_LOGOUT':
            return {...state, userData: initialState.userData, logged: false}
            // return {...state, logged: false}
        default:
            return state;
    }
}
