import Composition from "../../shared/helpers/Composition";
import createReducer from "../../shared/helpers/createReducer";
import FormReducer from "../../../../src_shared/form/FormReducer";
import OverlayModel from "../../../../modules/models/overlay/OverlayModel";

export const getStateRoot = (state) => state.streamOverlay.form;

export const prefix = 'STREAM_OVERLAY_SHELVES_FORM_';

export const getInitState = () => FormReducer.getInitState(OverlayModel.getModel(), {waiting: true})

const getReduceMap = () => new Composition(
	FormReducer.getReduceMap(),
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();
