import React, {useEffect} from 'react';
import MultiRowListTemplates from "./MultiRowListTemplates";
import useLang from "../../../../src_shared/hooks/useLang";
import useFormLocal from "../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../RestServices";
import {showAlert} from "../../../app/shared/components/alert/AlertActions";
import {useDispatch} from "react-redux";
import SettingsAddToReserveListTemplatesModel
    from "../../../../modules/models/settings/SettingsAddToReserveListTemplatesModel";

const AddToReserveListRows = ({
                                  values,
                                  defaultRowLength,
                                  model = SettingsAddToReserveListTemplatesModel,
                                  rest = services.API_SETTINGS_MESSENGER_TEMPLATES_ADD_TO_RESERVE_LIST,
                                  place = ''
                              }) => {
    const {getLangText} = useLang();
    const dispatch = useDispatch();

    const [{
        onSave,
        isWaiting,
        getFieldProps,
        setValue,
        isLoading,
        updateValues
    }] = useFormLocal({
        rest,
        model,
        initialAutoLoad: false,
        afterSubmit: () => {
            dispatch(showAlert())
        }
    });

    return (
        <section id="add-to-reserve-list">
            <div className="left-side">
                <h2 className="label">{getLangText('settings_messenger_description_3')}</h2>
            </div>
            <MultiRowListTemplates
                getFieldProps={getFieldProps}
                onSave={onSave}
                isWaiting={isWaiting}
                setValue={setValue}
                field="reserve_list"
                label="inputLabelReserveListVariable"
                updateValues={updateValues}
                data={values}
                defaultRowLength={defaultRowLength}
                place={place}
            />
        </section>
    );
};

export default AddToReserveListRows;
