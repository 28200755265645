import React, {useEffect, useState} from 'react';
import useLang from "../../../../src_shared/hooks/useLang";

const CopyInput = ({copyText, setClicked}) => {
	const {getLangText} = useLang();

	const [copied, setCopied] = useState(false);

	const copyToClipboard = () => {
		navigator.clipboard.writeText(copyText)
		setCopied(true);
		setClicked(true);
	};

	useEffect(() => {
		const tooltipVisibleTimeout = setTimeout(() => {
			setCopied(false);
		}, 3000)
		return () => {
			clearTimeout(tooltipVisibleTimeout)
		}
	}, [copied])

	return (
		<div className={`tooltip-parent no-out-transition ${copied ? 'visible-hover' : ''}`}>
			<div className="tooltip-content">
				{copied ? getLangText('copiedLabel') : getLangText('copyButton')}
			</div>
			<div
				className={`form-group with-copy-btn with-preview overflow-visible`}>
				<div className="form-control bg-[#F7F9FE] border border-[#3C61EA]">
					<div className="short-link">
						{copyText}
					</div>
					<button
						onClick={copyToClipboard}
						className={`button primary small-size copy-btn`}
					>
						<i className="icon-copy"/>
						{copied ? getLangText('copiedLabel') : getLangText('copyButton')}
					</button>
				</div>
			</div>
			<div className="form-info-box">
				<div>
					<i className="icon-info-c" />
				</div>
				<div className="text-sm font-medium text-[#0D3364] leading-[18px]">
					{getLangText('copiedShopTextInformationLabel')}
				</div>
			</div>
		</div>
	);
};

export default CopyInput;
