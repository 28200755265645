import React from 'react';
import useLang from "../../../../../src_shared/hooks/useLang";
import CodeName from "./components/defaultInformation/CodeName";
import useFormLocal from "../../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../../RestServices";
import AutomationModel from "../../../../../modules/models/automation/AutomationModel";
import CodeRangeWorking from "./components/defaultInformation/codeRangeWorking/CodeRangeWorking";
import Message from "./components/Message";
import CommentReply from "./components/commentReply/CommentReply";
import Loader from "../../../shared/Loader";
import {RouterPaths} from "../../../../routes/RouterPath";
import {useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import SwitchField from "../../../../../src_shared/components/form/switchField/SwitchField";
import SelectTemplate from "./components/selectTemplate/SelectTemplate";

const AutomationEditPage = () => {
    const {getLangText} = useLang();
    const {id} = useParams();

    const rest = !!+id ? `${services.API_INSTAGRAM_AUTOMATIONS}/${id}` : services.API_INSTAGRAM_AUTOMATIONS;

    const [{
        isLoading,
        setValue,
        values,
        onSave,
        isWaiting,
        getFieldProps,
        updateValues,
        isValidationActive,
        setNestedValue,
        setIsValidationActive
    }] = useFormLocal({
        rest,
        model: AutomationModel,
        method: !!+id ? 'put' : 'post',
        initialAutoLoad: !!+id,
        redirectRoute: RouterPaths.AutomationEdit
    });

    const noInteractionSelectedError = isValidationActive && (!+values.reply_comment && !+values.reply_message);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getLangText('automationEditLabel')} - Selmo</title>
            </Helmet>
            <div className="bg-[white] rounded-b-[5px] p-6">
                <div className="max-w-[550px] mx-auto space-y-12 mt-[36px]">
                    {isLoading ?
                        <div>
                            <div className="animate-pulse">
                                <div className="h-7 bg-gray-200 rounded-md dark:bg-gray-700 w-56 mb-6"></div>
                                <div className="h-60 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-3"></div>
                                <div className="h-60 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-3"></div>
                            </div>
                        </div> :
                        <div>
                            <div className="mb-[20px]">
                                <div
                                    className="sm:text-xl font-bold">{getLangText('completeBasicInformationLabel')}</div>
                                <div
                                    className="text-sm text-desc font-medium">{getLangText('completeBasicInformationSubLabel')}</div>
                            </div>
                            <div className="space-y-5 mb-5">
                                <CodeName
                                    values={values}
                                    setValue={setValue}
                                    isValidationActive={isValidationActive}
                                />
                                <CodeRangeWorking
                                    values={values}
                                    setValue={setValue}
                                    updateValues={updateValues}
                                />
                            </div>
                            <SelectTemplate
                                values={values}
                                setValue={setValue}
                                updateValues={updateValues}
                            />
                            <div className="space-y-5 mt-5">
                                <div className="mb-3">
                                    <div
                                        className="sm:text-xl font-bold">{getLangText('addInteractionLabel')}</div>
                                    <div
                                        className="text-sm text-desc font-medium">{getLangText('addInteractionSubLabel')}</div>
                                    {noInteractionSelectedError &&
                                        <div
                                            className="form-info-box red align-items-center small-line-height w-100 mb-0 mt-3">
                                            <div>
                                                <i className="icon-info-c"/>
                                            </div>
                                            <div
                                                className="title">{getLangText('selectAtleastOneInteractionLabel')}</div>
                                        </div>
                                    }
                                </div>
                                <Message
                                    values={values}
                                    setValue={setValue}
                                    updateValues={updateValues}
                                    isValidationActive={isValidationActive}
                                    setIsValidationActive={setIsValidationActive}
                                    noInteractionSelectedError={noInteractionSelectedError}
                                    getFieldProps={getFieldProps}
                                />
                                <CommentReply
                                    values={values}
                                    setValue={setValue}
                                    setNestedValue={setNestedValue}
                                    updateValues={updateValues}
                                    noInteractionSelectedError={noInteractionSelectedError}
                                />
                            </div>
                            <div className="flex items-center justify-content-between mb-3 mt-4">
                                <SwitchField
                                    {...getFieldProps('active')}
                                    label={getLangText('active_label')}
                                    id="active"
                                    className="switch-form inline-label big-label light-version flex mb-0"
                                />
                                <button
                                    type="button"
                                    onClick={onSave}
                                    className={`button small-size primary ${isWaiting ? 'loading-button' : ''}`}
                                >
                                    {isWaiting && <Loader isLoading/>}
                                    {getLangText('saveButton')}
                                </button>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    );
};

export default AutomationEditPage;
