import React from 'react';
import QuestionsToggle from "./QuestionsToggle";
import useDataApi from "../../../src_shared/hooks/useDataApi";
import {services} from "../../RestServices";
import {useSelector} from "react-redux";
import Loader from "../shared/Loader";

const Questions = () => {

	const {lang} = useSelector((state) => state.i18n);
	const [{data, isLoading}] = useDataApi(services.API_FAQ, [], [lang], lang)

	return (
		<div className="faq-section loader-parent">
			<Loader isLoading={isLoading} />
			{data.map((item) => (
				<QuestionsToggle
					title={item.title}
				>
					<div dangerouslySetInnerHTML={{__html: item.description}} />
				</QuestionsToggle>
			))}
			{/*<QuestionsToggle*/}
			{/*	title="Jak mogę połączyć swoją stronę na Facebooku z Selmo?"*/}
			{/*>*/}
			{/*	Możesz połączyć swoją stronę na Facebooku z Selmo, klikając przycisk połącz w podstronie:*/}
			{/*	<a href="https://app.selmo.io/ustawienia/facebook/">https://app.selmo.io/ustawienia/facebook/</a>*/}
			{/*</QuestionsToggle>*/}
			{/*<QuestionsToggle*/}
			{/*	title="Dlaczego połączenie mojej strony na Facebooku jest takie istotne?"*/}
			{/*>*/}
			{/*	W momencie, kiedy połączysz swoją stronę na Facebooku z Selmo, otrzymujesz dostęp do wszystkich*/}
			{/*	najważniejszych funkcji sprzedażowych m.in. do Messengera, w którym możesz prowadzić konwersacje z*/}
			{/*	klientami*/}
			{/*	oraz tworzyć i wysyłać do nich zamówienia.*/}
			{/*</QuestionsToggle>*/}
			{/*<QuestionsToggle*/}
			{/*	title="Czy połączenie z Facebookiem jest bezpieczne?"*/}
			{/*>*/}
			{/*	Tak, nasza aplikacja została oficjalnie zweryfikowana przez Facebooka, co sprawia, że korzystanie z*/}
			{/*	niej jest w pełni bezpieczne.*/}
			{/*</QuestionsToggle>*/}
			{/*<QuestionsToggle*/}
			{/*	title="Jak działa Messenger na Selmo i czy mogę z niego odpisywać moim klientom na wiadomości?"*/}
			{/*>*/}
			{/*	Messenger na Selmo importuje wszystkie Twoje aktualne wiadomości z klientami. Bezpośrednio z poziomu*/}
			{/*	Selmo możesz odpisywać swoim klientom, tworzyć oraz zarządzać zamówieniami oraz ich historią.*/}
			{/*</QuestionsToggle>*/}
			{/*<QuestionsToggle*/}
			{/*	title="Jak działa proces zakupowy na Selmo?"*/}
			{/*>*/}
			{/*	W momencie, kiedy utworzysz zamówienie i wyślesz je do klienta, otrzyma on wiadomość z podsumowaniem*/}
			{/*	wszystkich produktów w koszyku i łączną kwotą do zapłaty. Następnie klient klika w Messengerze*/}
			{/*	przycisk*/}
			{/*	“Przejdź do zamówienia”, samodzielnie wypełnia dane osobowe, wybiera sposób dostawy i opłaca*/}
			{/*	zamówienie. Na*/}
			{/*	koniec przechodzi do podsumowania, w którym widzi status swojego zamówienia oraz otrzymuje*/}
			{/*	potwierdzenie*/}
			{/*	zakupu na podany przez niego adres e-mail.*/}
			{/*</QuestionsToggle>*/}
			{/*<QuestionsToggle*/}
			{/*	title="Jak utworzyć zamówienie?"*/}
			{/*>*/}
			{/*	Zamówienie możesz utworzyć w Messengerze: <a*/}
			{/*	href="https://app.selmo.io/wiadomosci/">https://app.selmo.io/wiadomosci/</a>,*/}
			{/*	wystarczy że otworzysz konwersację z klientem i klikniesz ikonę ”+” w prawym górnym rogu okna*/}
			{/*	Messengera.*/}
			{/*</QuestionsToggle>*/}
			{/*<QuestionsToggle*/}
			{/*	title="Jak mogę wysłać do klienta zamówienie utworzone w Messengerze?"*/}
			{/*>*/}
			{/*	W otwartej konwersacji z klientem, kliknij niebieską ikonę koszyka, w prawym górnym rogu ekranu, a*/}
			{/*	następnie*/}
			{/*	w zakładce "Podsumowanie" przycisk “Wyślij”. Do klienta zostanie wysłana wiadomość z przyciskiem. Po*/}
			{/*	jego*/}
			{/*	kliknięciu, klient wypełni wszystkie dane osobowe, wybierze sposób dostawy i opłaci zamówienie.*/}
			{/*</QuestionsToggle>*/}
			{/*<QuestionsToggle*/}
			{/*	title="Jak dodać sposoby wysyłki?"*/}
			{/*>*/}
			{/*	Swoje wysyłki dodasz w podstronie: <a*/}
			{/*	href="https://app.selmo.io/ustawienia/wysylki">https://app.selmo.io/ustawienia/wysylki</a>*/}
			{/*	Możesz dowolnie nazywać sposoby wysyłek oraz ustalić ich cenę. W przypadku jeśli wysyłka jest do*/}
			{/*	paczkomatu,*/}
			{/*	zaznacz opcję Paczkomat do odbioru przesyłki – wtedy Twoi klienci będą mogli wybrać, do jakiego*/}
			{/*	paczkomatu*/}
			{/*	ma zostać wysłane zamówienie.*/}
			{/*</QuestionsToggle>*/}
			{/*<QuestionsToggle*/}
			{/*	title="Jak dodać płatności?"*/}
			{/*>*/}
			{/*	Płatności dla swojego sklepu możesz dodać w podstronie: <a*/}
			{/*	href="https://app.selmo.io/ustawienia/platnosci">https://app.selmo.io/ustawienia/platnosci</a>*/}
			{/*	Po wypełnieniu wszystkich danych, zaznacz opcję ”Aktywuj płatność” i kliknij “Zapisz”.*/}

			{/*</QuestionsToggle>*/}
			{/*<QuestionsToggle*/}
			{/*	title="Czy mogę dodać płatności BLIK do swojego sklepu?"*/}
			{/*>*/}
			{/*	Tak, aby umożliwić swoim klientom płatności za pomocą BLIK, utwórz swoje konto u naszego operatora*/}
			{/*	płatności*/}
			{/*	Blue Media. Instrukcja i wymagane kroki tutaj:*/}
			{/*	<a href="https://app.selmo.io/ustawienia/platnosci/przelewy-online/">https://app.selmo.io/ustawienia/platnosci/przelewy-online/</a>*/}
			{/*</QuestionsToggle>*/}
			{/*<QuestionsToggle*/}
			{/*	title="Jak mogę wysłać aktualny stan zamówienia klientowi?"*/}
			{/*>*/}
			{/*	W momencie kiedy chcesz podsumować obecny stan zamówienia klientowi, wejdź w Messengera*/}
			{/*	<a href="https://app.selmo.io/wiadomosci/">https://app.selmo.io/wiadomosci/</a>, wybierz*/}
			{/*	konwersację,*/}
			{/*	otwórz koszyk i zakładkę “Podsumowanie”. Znajdziesz tam sekcję “Aktualny stan koszyka”, w której*/}
			{/*	kliknij*/}
			{/*	przycisk “Wyślij”. Do klienta zostanie wysłana wiadomość z zestawieniem wszystkich produktów i*/}
			{/*	łączną kwotą*/}
			{/*	do zapłaty.*/}
			{/*</QuestionsToggle>*/}
			{/*<QuestionsToggle*/}
			{/*	title="Czy moi klienci mogą skorzystać z otwartej paczki?"*/}
			{/*>*/}
			{/*	Tak, wystarczy że włączysz taką możliwość w ustawieniach: <a*/}
			{/*	href="https://app.selmo.io/ustawienia/ogolne/">https://app.selmo.io/ustawienia/ogolne/</a>*/}
			{/*	W momencie, kiedy klient otworzy podstronę swojego zamówienia, wypełni dane osobowe oraz wybierze*/}
			{/*	sposób*/}
			{/*	dostawy, pokaże mu się pole z możliwością wskazania, czy woli, aby paczka została otwarta i*/}
			{/*	kontynuuje*/}
			{/*	zakupy, czy życzy sobie, żeby sprzedawca wysłał paczkę w momencie płatności za zamówienie.*/}
			{/*</QuestionsToggle>*/}
			{/*<QuestionsToggle*/}
			{/*	title="Jak wygenerować etykiety kurierskie?"*/}
			{/*>*/}
			{/*	W podstronie integracji, wybierz firmę, z której chcesz skorzystać:*/}
			{/*	<a href="https://app.selmo.io/ustawienia/integracje/">https://app.selmo.io/ustawienia/integracje/</a>*/}
			{/*	Sugerujemy integrację z Furgonetką, gdyż daje ona najkorzystniejsze warunki do wysyłek kurierskich i*/}
			{/*	paczkomatów InPost, a na dodatek umożliwia dodanie własnej umowy z przewoźnikami i swoich stawek za*/}
			{/*	wysyłki.*/}
			{/*	Po integracji, do wszystkich Twoich zamówień będzie można automatycznie wygenerować listy*/}
			{/*	przewozowe, bez*/}
			{/*	konieczności przepisywania danych ręcznie.*/}
			{/*</QuestionsToggle>*/}
			{/*<QuestionsToggle*/}
			{/*	title="Czy mogę zintegrować z Selmo InPost?"*/}
			{/*>*/}
			{/*	Tak, możesz to zrobić w podstronie: <a*/}
			{/*	href="https://app.selmo.io/ustawienia/integracje/inpost/">https://app.selmo.io/ustawienia/integracje/inpost/</a>*/}
			{/*	Po integracji, do wszystkich Twoich zamówień będzie można automatycznie wygenerować listy przewozowe*/}
			{/*	bez*/}
			{/*	konieczności przepisywania danych ręcznie.*/}
			{/*</QuestionsToggle>*/}
			{/*<QuestionsToggle*/}
			{/*	title="Czy mam możliwość ustalenia czasu na opłacenie zamówienia?"*/}
			{/*>*/}
			{/*	Tak, oczywiście. Wystarczy, że wejdziesz do podstrony ustawień*/}
			{/*	<a href="https://app.selmo.io/ustawienia/ogolne/">https://app.selmo.io/ustawienia/ogolne/</a>,*/}
			{/*	wybierzesz ilość godzin na opłacenie zamówienia i włączysz opcję odliczania. Od tego momentu, po*/}
			{/*	wejściu w*/}
			{/*	zamówienie Twoim klientom pokaże się odliczanie na dokonanie płatności.*/}
			{/*</QuestionsToggle>*/}
			{/*<QuestionsToggle*/}
			{/*	title="Czy mogę dodać kwotę, od której moi klienci mogą skorzystać z darmowej dostawy?"*/}
			{/*>*/}
			{/*	Tak, w podstronie ustawień <a*/}
			{/*	href="https://app.selmo.io/ustawienia/ogolne/">https://app.selmo.io/ustawienia/ogolne/</a>*/}
			{/*	wybierz kwotę i zaznacz opcję “Darmowa dostawa od wybranej kwoty”, a Twoi klienci po osiągnięciu*/}
			{/*	wymaganej*/}
			{/*	sumy, skorzystają z darmowej dostawy.*/}
			{/*</QuestionsToggle>*/}
			{/*<QuestionsToggle*/}
			{/*	title="Czy moi klienci mogą sami usuwać produkty z koszyka?"*/}
			{/*>*/}
			{/*	Tak, jeżeli w podstronie ustawień <a*/}
			{/*	href="https://app.selmo.io/ustawienia/ogolne/">https://app.selmo.io/ustawienia/ogolne/</a>*/}
			{/*	zaznaczona będzie opcja “Edytowanie koszyka przez klientów”. Po aktywowaniu tej opcji, Twoi klienci*/}
			{/*	będą*/}
			{/*	mogli usuwać produkty z koszyka, klikając przycisk “Usuń” przy każdym z nich.*/}
			{/*</QuestionsToggle>*/}
			{/*<QuestionsToggle*/}
			{/*	title="Czy mogę dodać własny regulamin dla mojego sklepu?"*/}
			{/*>*/}
			{/*	Tak, oczywiście. Regulamin możesz dodać tutaj <a*/}
			{/*	href="https://app.selmo.io/ustawienia/regulamin/">https://app.selmo.io/ustawienia/regulamin/</a>,*/}
			{/*	Twoi klienci będą mogli go zobaczyć w podstronie zamówienia.*/}
			{/*</QuestionsToggle>*/}
		</div>
	);
};

export default Questions;
