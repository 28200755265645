export const getInitState = (model = {}, defaultModel = null) => ({
	data: model,
	emptyData: defaultModel ? defaultModel : model,
	dataPreloaded: false,
	saved: false,
});

export const reduceMap = {
	DATA_SET_VALUE: (state, action) => (
		{...state, data: {...state.data, [action.field]: action.value}, saved: true}
	),
	DATA_SET_NESTED_VALUE: (state, action) => {
		const {parent, id, field, value} = action;
		return {
			...state,
			data: {
				...state.data,
				[parent]: state.data[parent]?.map((i) => i.id === id ? {
					...i,
					[field]: value,
				} : i)
			}
		}
	},
	DATA_UPDATE_VALUES: (state, action) => (
		{...state, data: {...state.data, ...action.data}, saved: false}
	),
	DATA_SET_SAVED: (state) => ({...state, saved: true}),
	DATA_SET_PRELOADED: (state, action) => {
		if (action.preloaded == null) {
			console.error('dataReducer error: action '
				+ 'DATA_SET_PRELOADED has invalid data!');
			console.info(action);
			return state;
		}
		return {...state, dataPreloaded: action.preloaded};
	},
	DATA_RESTORE_EMPTY: (state) => ({...state, data: state.emptyData}),
	DATA_UPDATE_EMPTY_VALUES: (state, action) => ({...state, emptyData: {...state.emptyData, ...action.data}}),
};

export default {
	getInitState,
	reduceMap,
};
