import React, {useEffect} from 'react';
import TextArea from "../../../../../../../../src_shared/components/form/textarea/TextArea";
import ValidationError from "../../../../../../../../src_shared/form/validation/ValidationError";
import Field from "../../../../../../../../src_shared/components/form/field/Field";
import useLang from "../../../../../../../../src_shared/hooks/useLang";
import {useSelector} from "react-redux";
import {createSelector} from "reselect";
import {getStateRoot} from "../../../listReducer";
import {SHOP_ORIGIN_PL} from "../../../../../../shared/enums/ShopOrigins";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../../../../routes/RouterPath";
import SwitchField from "../../../../../../../../src_shared/components/form/switchField/SwitchField";

const FacebookNotificationsForm = ({values, setValues, data, messageNotificationApp}) => {
    const {getLangText} = useLang();
    const {validationActive} = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));
    const {userData} = useSelector((state) => state.session);

    useEffect(() => {
        if (!!+values.mobile_app) {
            setValues({
                ...values,
                message: values.message + messageNotificationApp
            })
        } else {
            setValues({
                ...values,
                message: values.message.replace(messageNotificationApp, '')
            })
        }
    }, [values.mobile_app])

    useEffect(() => {
        if (data.notification_message) {
            setValues({
                ...values,
                message: !!+values.mobile_app ? data.notification_message + messageNotificationApp : data.notification_message,
            })
        }
    }, [data.notification_message])


    return (
        <>
            <TextArea
                label={getLangText('messageToClientLabel')}
                name="message"
                value={values.message}
                setValue={(field, value) => setValues({
                    ...values,
                    message: value
                })}
                rows={5}
                validateErrors={validationActive}
                getError={() => ValidationError.notEmpty(values.message)}
                required
            />
            <Field
                label={getLangText('linkToLiveOrPostLabel')}
                name="link"
                value={values.link}
                setValue={(field, value) => setValues({
                    ...values,
                    link: value
                })}
                className="mt-2 mb-4"
                optional
            />
            <div className="p-3 rounded-[10px] bg-[#F6FCFF] -mt-2 mb-4 flex">
                <div>
                    <div className="text-sm text-[#0D3364] font-bold mb-1">{getLangText('doYouKnowThatLabel')}</div>
                    <div
                        className="text-sm text-[#0E276D] font-medium mb-1">{getLangText('doYouKnowThatSubLabel')}</div>
                    <Link
                        target="_blank"
                        className="text-[#3C61EA] text-xs font-bold hover:text-[#1c4ed8] hover:no-underline transition-all"
                        to={RouterPaths.Notifications}
                    >
                        {getLangText('goToNotificationsLabel')}
                        <i className="icon-arrows -rotate-90 inline-block text-[8px] ml-1 relative bottom-[1px]"/>
                    </Link>
                </div>
                <div className="ml-auto">
                    <img
                        width={32}
                        height={32}
                        className="w-[32px] min-w-[32px] h-[32px] rounded-[5px] overflow-hidden"
                        src="/assets/images/selmo-square.png"/>
                </div>
            </div>
            <SwitchField
                className="switch-form inline-label big-label light-version align-items-start"
                value={values.mobile_app}
                setValue={(field, value) => setValues({
                    ...values,
                    mobile_app: value
                })}
                label={getLangText('addTextAndButtonToDownloadSelmoLabel')}
                subLabel={getLangText('addTextAndButtonToDownloadSelmoSubLabel')}
                id="mobile_app"
            />
        </>
    );
};

export default FacebookNotificationsForm;
