import React from 'react';
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../routes/RouterPath";
import moment from "moment";
import {findOrderStatusById} from "../../../shared/enums/OrderStatuses";
import useLang from "../../../../../src_shared/hooks/useLang";
import useRemoveData from "../../../../../src_shared/hooks/useRemoveData";
import {services} from "../../../../RestServices";
import ConfirmRemoveModal from "../../../../../src_shared/components/modal/ConfirmRemoveModal";
import {convertToUserTimeZone} from "../../../shared/helpers/dateHelpers";

const Orders = ({data, fetchData}) => {
    const {getLangText} = useLang();

    const [{
        openModal,
        hideModal,
        confirmModalVisible,
        itemToRemove,
        isDeleting,
        removeItem
    }] = useRemoveData({
        rest: services.API_WAREHOUSE_ORDER,
        afterSubmit: fetchData,
    });

    const takeOfOrderHandle = (e, item) => {
        e.preventDefault();
        openModal(item)
    }

    if (!data.orders.length) {
        return null;
    }

    return (
        <div className="default-shadow-box bigger-x-padd">
            <div className="filters-part">
                <div className="title flex-grow-1">
                    {getLangText('asideOrdersLabel')}
                    {/*{totalItems > 0 &&*/}
                    {/*    <div className="badge">{totalItems}</div>*/}
                    {/*}*/}
                </div>
            </div>
            <div className="boxes-wrapper">
                {data.orders.map((item) => (
                    <Link
                        key={item.id}
                        to={`${RouterPaths.StorageScanner}/order/${item.id}?fromShelf=1`}
                        className="box flex-nowrap"
                    >
                        <div className="flex flex-wrap items-center w-full">
                            <div className="left-side sm:w-auto w-full ">
                                <div
                                    className="title">{getLangText('customerBasketOrderLabel')}&nbsp;{item.shop_order_id}</div>
                                <div className="bottom-text">
                                    <span>{convertToUserTimeZone(item.date).format('DD.MM.YYYY HH:mm')}</span>
                                    {/*{item.shipping_name &&*/}
                                    {/*    <span>{shippingLang(item, getLangText)}</span>*/}
                                    {/*}*/}
                                    {/*{item.payment &&*/}
                                    {/*    <span><div>{getLangText(findOrderPaymentStatusById(item.payment)?.label)}</div></span>*/}
                                    {/*}*/}
                                </div>
                            </div>
                            <div className="right-side">
                                <div className={`status-bg ${findOrderStatusById(item.status)?.color}`}>
                                    {getLangText(findOrderStatusById(item.status)?.label)}
                                </div>
                                {/*<div className="price">{getOrderPrice(item.total_price, item.currency_text)}</div>*/}
                            </div>
                        </div>
                        <button
                            onClick={(e) => takeOfOrderHandle(e, item)}
                            type="button"
                            className="button whitespace-nowrap ml-2 rounded-[5px] text-[10px] h-[32px] px-2 font-bold bg-[#FEF2F2] grow text-[#EF4444]"
                        >
                            {getLangText('removeFromShelfLabel')}
                        </button>
                    </Link>
                ))}
            </div>
            {confirmModalVisible &&
                <ConfirmRemoveModal
                    show={confirmModalVisible}
                    subTitle={itemToRemove.name}
                    dialogClassName="small-modal"
                    title={getLangText('removeOrderFromShelfLabel')}
                    hide={hideModal}
                    confirm={removeItem}
                    isWaiting={isDeleting}
                    confirmButton={getLangText('yesButton')}
                    confirmButtonClassName="primary"
                />
            }
        </div>
    );
};

export default Orders;
