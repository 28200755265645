import React from 'react';
import Modal from "react-bootstrap/Modal";
import {services} from "../../../../RestServices";
import DeliveryType from "./DeliveryType";
import useLang from "../../../../../src_shared/hooks/useLang";

const DeliveryTypeModal = ({modalVisible, hideModal, values, data, getFieldProps, setIsPointModalOpen, setActiveShippingId, updateValues, setValueOnServer}) => {
	const {getLangText} = useLang();

	return (
		<Modal
			dialogClassName="default-modal"
			show={modalVisible}
			onHide={hideModal}
		>
			<Modal.Header>
				<Modal.Title>
					{getLangText('editDeliveryMethodLabel')}
				</Modal.Title>
				<button
					onClick={hideModal}
					type="button"
					className="button"
				>
					<i className="icon-cross"/>
				</button>
			</Modal.Header>
			<Modal.Body className="pt-3 pb-5">
				<DeliveryType
					{...getFieldProps('shipping')}
					showSelectedPoint={false}
					values={values}
					data={data}
					updateValues={updateValues}
					setValue={(field, value) => setValueOnServer(services.API_CART_SHIPPING_METHODS, field, value)}
					setIsPointModalOpen={setIsPointModalOpen}
					setActiveShippingId={setActiveShippingId}
				/>
			</Modal.Body>
		</Modal>
	);
};

export default DeliveryTypeModal;
