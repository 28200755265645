import Composition from "../../shared/helpers/Composition";
import createReducer from "../../shared/helpers/createReducer";
import OrderProductsFormReducer from "../../shared/components/form/orderProductsForm/OrderProductsFormReducer";
import ModalReducer from "../../../../src_shared/modal/ModalReducer";

export const getStateRoot = (state) => state.orders.form;

export const prefix = 'ORDERS_FORM_';

export const getInitState = () => OrderProductsFormReducer.getInitState({
	...ModalReducer.initState,
	data: {
		first_name: '',
		last_name: '',
	}
})

export const getReduceMap = () => new Composition(
	OrderProductsFormReducer.getReduceMap(),
	ModalReducer.reduceMap,
	{
		UPDATE_FIELD: (state, action) => {
			return {
				...state,
				data: {...state.data, [action.field]: action.value}
			}
		}
	},
	{
		RESET_DATA: (state, action) => {
			return getInitState();
		}
	}
);

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();
