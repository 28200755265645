import {combineReducers} from "redux";
import desktopPageReducer from "./desktop/formReducer";
import mainPageReducer from "./mainPage/formReducer";
import generalReducer from "./general/formReducer";
import shopsSettingsPromotionsReducer from "./promotions/shopsSettingsPromotionsReducer";
import shopsSettingsPagesReducer from "./pages/shopsSettingsPagesReducer";
import domainSettingsReducer from "./domain/formReducer";

export default combineReducers({
	desktop: desktopPageReducer,
	mainPage: mainPageReducer,
	general: generalReducer,
	promotions: shopsSettingsPromotionsReducer,
	pages: shopsSettingsPagesReducer,
	domain: domainSettingsReducer,
});
