import React from 'react';
import useTableColumn from "../../../../../orders/list/components/table/useTableColumn.js";
import {CLIENTS_TABLE_COLUMN, CLIENTS_TABLE_COLUMN_BALANCE} from "./ClientsTableColumnEnums.js";
import {getStateRoot as getFiltersStateRoot} from "../../../../filters/filtersReducer.js";
import {useSelector} from "react-redux";
import useLang from "../../../../../../../src_shared/hooks/useLang";

const TableHeader = ({updateSortOptions, getSortClass}) => {

	const {preparedTableColumn, findTableColumnById} = useTableColumn(CLIENTS_TABLE_COLUMN, 'clientsTableColumn', getFiltersStateRoot)
	const {userData} = useSelector((state) => state.session);
	const {getLangText} = useLang();

	const filteredTableColumn = !+userData.show_payment_received ? preparedTableColumn.filter((i) => i !== CLIENTS_TABLE_COLUMN_BALANCE) : preparedTableColumn;

	return (
		<thead>
		<tr>
			{filteredTableColumn.map((column) => (
				column === CLIENTS_TABLE_COLUMN_BALANCE ?
					<th
						key={column}
						className={`${findTableColumnById(column)?.className}`}
					>
						{getLangText(findTableColumnById(column)?.label)}
					</th> :
					<th
						key={column}
						name={column}
						onClick={updateSortOptions}
						className={`${findTableColumnById(column)?.className} ${getSortClass(column)}`}
					>
						<span name={column}>
							{getLangText(findTableColumnById(column)?.label)}
						</span>
					</th>
			))}
		</tr>
		</thead>);
};

export default TableHeader;
