import {combineReducers} from "redux";
import listReducer from './list/listReducer.js'
import formReducer from "./form/formReducer.js";
import filtersReducer from "./filters/filtersReducer";

export default combineReducers({
	list: listReducer,
	filters: filtersReducer,
	form: formReducer,
});
