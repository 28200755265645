import React from 'react';
import Modal from "react-bootstrap/Modal";
import {useDispatch} from "react-redux";
import LangsActions from "../langs/LangsActions";
import {supportedOptionLangs} from "../../../../services/i18n/i18n";
import useLang from "../../../../src_shared/hooks/useLang";

const LanguageModal = ({openModal, hideModal, lang, afterSubmit = () => {},  options = supportedOptionLangs, langsActions = LangsActions, modalTitle}) => {
	const {getLangText} = useLang();
	const dispatch = useDispatch();

	const setLang = async (lang) => {
		try {
			await dispatch(langsActions.setAsyncLang(lang));
			await dispatch(langsActions.setServerLanguage(lang));
		} catch (e) {

		}
		finally {
			afterSubmit();
			hideModal();
		}
	}

	return (
		<Modal
			dialogClassName="default-modal"
			contentClassName="rounded-xl"
			show={openModal}
			onHide={hideModal}
		>
			<Modal.Header>
				<Modal.Title>
					<span className="text-xl">{modalTitle || getLangText('languageModalTitle')}</span>
				</Modal.Title>
				<button
					onClick={hideModal}
					type="button"
					className="button"
				>
					<i className="icon-cross text-lg font-normal opacity-50"/>
				</button>
			</Modal.Header>
			<Modal.Body>
				<div className="language-wrapper my-[10px]">
					<div className="language-row">
						{options.map((option) => (
							<div key={option.value}>
								<button
									onClick={() => setLang(option.value)}
									type="button"
									className={`lang-box ${lang === option.value ? 'active' : ''}`}
								>
									<img width="20" src={`/assets/images/flags/${option.flag}.svg`} alt=""/>
									<div className="name">{option.label}</div>
								</button>
							</div>
						))}
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default LanguageModal;
