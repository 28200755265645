import {getStateRoot, prefix} from "./formReducer.js";
import FormActions from "../../../../../../../../src_shared/form/FormActions";
import {batch} from "react-redux";
import {INPOST_LABEL_CUSTOM} from "../../../../../../shared/enums/InpostLabelsTypes";
import {showAlert} from "../../../../../../shared/components/alert/AlertActions";
import {services} from "../../../../../../../RestServices";
import GenerateLabelsInpostModel from "../../../../../../../../modules/models/orders/GenerateLabelsInpostModel";

export class OrderGenerateLabelsInpostLabelFormActions extends FormActions {

	submitEditForm(values, history) {
		return async (dispatch) => {

			dispatch(this.validation.activateValidation());

			if (!this.dataModel.validateData(values)) {
				dispatch(showAlert('fillRequireFieldWarning', '', 'alert-fail'))
				console.error('Validation failed!');
				return;
			}

			dispatch(this.afterSubmit(values))

		}
	}

	afterSubmit(data) {
		return (dispatch) => {
			batch(() => {
				dispatch(this.modal.hideModal())
				dispatch(this.validation.deactivateValidation());
				dispatch(this.data.setValue('template', INPOST_LABEL_CUSTOM))
			})
		};
	}
}

export const getInstance = () => new OrderGenerateLabelsInpostLabelFormActions({
	getStateRoot,
	prefix,
	model: GenerateLabelsInpostModel,
	restService: services.API_ORDER_INPOST_SHIPPMENT_LABEL,
});

export default getInstance();
