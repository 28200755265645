import React from 'react';
import useLang from "../../../../../src_shared/hooks/useLang";

const logos = ['dpd', 'zabka', 'dino', 'kaufland', 'shell'];

const DpdPickupLogos = () => {
    const {getLangText} = useLang();

    return (
        <div
            className="bg-[#F9FAFB] rounded-[5px] p-2 flex items-center mx-[15px] sm:mx-[20px] mb-[16px] sm:mb-[12px] sm:mt-0 -mt-2">
            <div className="text-[#4B5563] font-medium text-xs">{getLangText('availablePickups')}</div>
            <div className="sm:space-x-4 space-x-2 ml-auto flex items-center">
                {logos.map((logo) => (
                    <img
                        className="sm:h-[24px] h-[22px]"
                        src={`/assets/images/svg/dpdPickup/${logo}.svg`}
                        alt={logo}
                    />
                ))}
            </div>
        </div>
    );
};

export default DpdPickupLogos;
