import React from 'react';
import moment from "moment";
import useLang from "../../../../../../src_shared/hooks/useLang";
import EmptyList from "../../../../../app/shared/EmptyList";
import Chart from "react-apexcharts";
import {convertToUserTimeZone} from "../../../../../app/shared/helpers/dateHelpers";

export const labelStyle = {
    style: {
        fontSize: '12px',
        colors: '#9CA3AF',
        fontFamily: 'Plus Jakarta Sans',
    },
}

export const formattedMonth = (monthNumber) => convertToUserTimeZone().month(monthNumber).format('MMM');
export const formattedNumberMonth = (monthNumber) => convertToUserTimeZone().month(monthNumber - 1).format('MM');
export const formattedYear = (monthNumber) => convertToUserTimeZone().year(monthNumber).format('YY');

const DailyChart = ({data = [], currency}) => {

    const {getLangText} = useLang();

    const options = {
        chart: {
            id: "basic-bar",
            toolbar: {
                show: false,
            }
        },
        xaxis: {
            categories: data?.map((i) => i.day ? `${i.day}.${formattedNumberMonth(i.month)}` : `${formattedMonth(i.month)} ${formattedYear(i.year)}`),
            labels: {
                ...labelStyle,
                hideOverlappingLabels: true,
            },
            tooltip: {
                enabled: false
            },
            axisTicks: {
                show: false,
            },
        },
        yaxis: {
            labels: {
                style: {
                    fontSize: '12px',
                    colors: '#9CA3AF',
                    fontFamily: 'Plus Jakarta Sans',
                },
            }
        },
        grid: {
            borderColor: '#D1D5DB',
            strokeDashArray: 5,
        },
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 5,
                dataLabels: {
                    orientation: 'vertical',
                    position: 'center',
                    enable: false,
                    show: false,
                },
                columnWidth: 45,
            },
        },
        dataLabels: {
            enabled: false
        },
        colors: ['#1C4ED8', '#5FA5FA', '#90E8EE', '#3ee1f6'],
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'dark',
                type: "vertical",
                gradientToColors: undefined,
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                colorStops: [
                    {
                        offset: 0,
                        color: "#BEDDFF",
                        opacity: 1
                    },
                    {
                        offset: 60,
                        color: "#4C63FF",
                        opacity: 1
                    },
                ]
            }
        },
        legend: {
            show: false
        },
        tooltip: {
            custom: function ({series, seriesIndex, dataPointIndex, w}) {
                return `<div class="chart-tooltip">
                            <div class="date-label">
						        ${w.config.series[0].data[dataPointIndex].x}
						    </div>
                            <div class="label">${getLangText('totalProductsSold')}</div>
                            <div class="value">${w.config.series[0].data[dataPointIndex].c}</div>
                            <div class="label">${getLangText('totalPriceLabel')}</div>
                            <div class="value">${series[0][dataPointIndex]} ${currency}</div>
                        </div>`
            }
        }
    };

    const series = [
        {
            name: getLangText('incomesLabel'),
            data: data?.map((i) => ({
                y: Math.round(+i.yes_count_price),
                c: Math.round(+i.yes_count),
                x: i.day ? `${i.day}.${formattedNumberMonth(i.month)}` : `${formattedMonth(i.month)} ${formattedYear(i.year)}`,
            })),
        },
    ]


    return (
        <div className="bg-[white] shadow-default rounded-[5px] px-[20px] py-6 pb-2">
            <EmptyList
                className="full-size"
                imgSrc="/assets/images/empty/chart-2.svg"
                items={data}
                title=""
            >
                <div
                    className="overflow-x-auto horizontal-custom-scroll"
                >
                    <div
                        style={{minWidth: data.length * 60}}
                        className="chart-wrapper"
                    >
                        <Chart
                            series={series}
                            options={options}
                            type="bar"
                            height={265}
                        />
                    </div>
                </div>
            </EmptyList>
        </div>
    );
};

export default DailyChart;
