import React from 'react';
import useLang from "../../../src_shared/hooks/useLang";
import ComponentLoader from "../../app/shared/components/componentLoader/ComponentLoader";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../routes/RouterPath";
import {Helmet} from "react-helmet";
import useFetchData from "../../../src_shared/hooks/useFetchData";
import {services} from "../../RestServices";
import BillingData from "./components/BillingData";
import PaymentHistory from "./components/paymentHistory/PaymentHistory";
import ConfirmModal from "../../app/live/details/default/summary/export/ConfirmModal";
import {useRemoveModal} from "../../app/shared/hooks/useRemoveModal";
import SelmoPermissions from "../../app/shared/enums/SelmoPermissions";

const Settlements = () => {
    const {getLangText} = useLang();
    const [{data, isLoading, fetchData}] = useFetchData({
        rest: services.API_SETTINGS_BILLING,
        initialData: {
            billing: [],
            cardInfo: null,
        },
    })

    const [modalOpen, setModalOpen, waiting, remove] = useRemoveModal(`${services.API_SETTINGS_CANCEL_SUBSCRIPTION}`, fetchData)

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getLangText('settlementsLabel')} - Selmo</title>
            </Helmet>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <Link to={RouterPaths.Settings}>{getLangText('asideSettingsLabel')}</Link>
                    </li>
                    <li>
                        <Link to={RouterPaths.SettingsShop}>{getLangText('settings_nav_shop_settings')}</Link>
                    </li>
                    <li className="active">
                        <div>{getLangText('settlementsLabel')}</div>
                    </li>
                </ul>
            </div>
            <div className="grid sm:grid-cols-5 gap-4">
                <div className="sm:col-span-3">
                {isLoading ? <ComponentLoader/> :
                        <>
                            <div className="default-shadow-box mb-3">
                                <h1 className="text-xl font-bold">
                                    {getLangText('settlementsLabel')}
                                </h1>
                                <div className="text-desc text-sm">{getLangText('settlementsSubLabel')}</div>
                                <div className="border rounded-md p-3 mt-3 w-full">
                                    <div className="flex mb-2 items-center">
                                        <div
                                            className="grow text-sm font-bold">{getLangText('paymentsCurrentPlanLabel')}</div>
                                        <Link
                                            to={RouterPaths.Payments}
                                            className={`button border-button small-size`}
                                        >
                                            {getLangText('editButton')}
                                        </Link>
                                    </div>
                                    <div className="space-y-2">
                                        {data.billing.map((item) => (
                                            <div
                                                className="text-sm font-medium px-3.5 py-2.5 bg-[#F3F4F6] rounded-md flex flex-wrap items-center space-x-1">
                                                <div>
                                                    {getLangText(SelmoPermissions.find((i) => i.id === item.name)?.label) ||
                                                        SelmoPermissions.find((i) => i.id === item.name)?.label ||
                                                        item.name
                                                    }
                                                </div>
                                                <span className="text-[#6B7280]">({getLangText({
                                                    key: item.stripe ? 'renewsInLabel' : 'endsLabel',
                                                    data: [item.date || item.next_payment_date]
                                                })})</span>
                                            </div>
                                        ))}
                                    </div>
                                    {/*{data.cardInfo &&*/}
                                    {/*    <div className="mt-3">*/}
                                    {/*        <div className="text-sm font-bold">{getLangText('paymentSourceLabel')}</div>*/}
                                    {/*        <div className="flex items-center mt-2 flex-wrap space-y-2">*/}
                                    {/*            <div*/}
                                    {/*                className="border rounded-md overflow-hidden w-[90px] h-[55px] flex items-center justify-center">*/}
                                    {/*                <img className="w-[50px]" alt="card"*/}
                                    {/*                     src={`/assets/images/card/${data.cardInfo.type}.jpg`}/>*/}
                                    {/*            </div>*/}
                                    {/*            <div className="text-sm ml-2 grow">*/}
                                    {/*                <div className="font-bold">*/}
                                    {/*                    {getLangText({*/}
                                    {/*                        key: 'cardVariantLabel',*/}
                                    {/*                        data: [data.cardInfo?.type?.toUpperCase(), data.cardInfo.last_digits]*/}
                                    {/*                    })}*/}
                                    {/*                </div>*/}
                                    {/*                <div*/}
                                    {/*                    className="font-medium text-desc">{getLangText('expireLabel')} {data.cardInfo.month}/{data.cardInfo.year}*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*            <button*/}
                                    {/*                onClick={() => setModalOpen(true)}*/}
                                    {/*                type="button"*/}
                                    {/*                className="button border-button small-size"*/}
                                    {/*            >*/}
                                    {/*                {getLangText('removeCardLabel')}*/}
                                    {/*            </button>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*}*/}
                                </div>
                            </div>
                            <PaymentHistory data={data}/>
                        </>
                    }
                </div>
                <BillingData/>
            </div>
            {/*{modalOpen &&*/}
            {/*    <ConfirmModal*/}
            {/*        dialogClassName="w-380"*/}
            {/*        title={getLangText('wantToRemoveCardLabel')}*/}
            {/*        saveButton={getLangText('removeButton')}*/}
            {/*        show={modalOpen}*/}
            {/*        isWaiting={waiting}*/}
            {/*        onSubmit={remove}*/}
            {/*        hide={() => setModalOpen(false)}*/}
            {/*        saveButtonClass="danger"*/}
            {/*    />*/}
            {/*}*/}
        </>
    );
};

export default Settlements;
