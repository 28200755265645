import React from 'react';
import useFormLocal from "../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../RestServices";
import useLang from "../../../../src_shared/hooks/useLang";
import SwitchField from "../../../../src_shared/components/form/switchField/SwitchField";
import Loader from "../../../app/shared/Loader";
import ComponentLoader from "../../../app/shared/components/componentLoader/ComponentLoader";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import Field from "../../../../src_shared/components/form/field/Field";
import SettingsPaypalAutomationModel from "../../../../modules/models/settings/SettingsPaypalAutomationModel";
import SelectField from "../../../../src_shared/components/form/selectField/SelectField";
import {CommissionPriceTypes} from "../../../app/shared/enums/CommissionPriceTypes";
import {Helmet} from "react-helmet";
import useRemoveData from "../../../../src_shared/hooks/useRemoveData";
import ConfirmModal from "../../../app/live/details/default/summary/export/ConfirmModal";
import moment from "moment";
import AsyncSearchSelectField
    from "../../../../src_shared/components/form/selectField/asyncSearchSelectField/AsyncSearchSelectField";
import AccountTypeSelect from "./components/AccountTypeSelect";

const PaypalAutomation = () => {
    const {getLangText} = useLang();
    const [{
        onSave,
        isWaiting,
        getFieldProps,
        isLoading,
        values,
        fetchData
    }] = useFormLocal({
        rest: services.API_SETTINGS_PAYMENT_PAYPAL_ONBOARDING,
        model: SettingsPaypalAutomationModel,
    });

    const [{
        setConfirmModalVisible,
        hideModal: hideRemoveModal,
        confirmModalVisible,
        isDeleting,
        removeItem,
    }] = useRemoveData({
        rest: services.API_SETTINGS_PAYMENT_PAYPAL_ONBOARDING,
        afterSubmit: fetchData,
    });

    const formatOptionLabel = (option) => (
        <div style={{display: "flex"}}>
            <div className="mr-1">{option.flag}</div>
            <div>{option.name}</div>
        </div>
    );

    if (isLoading) return <ComponentLoader/>;

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getLangText('settings_payments_paypal_payment')} - Selmo</title>
            </Helmet>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <Link to={RouterPaths.Settings}>
                            {getLangText('asideSettingsLabel')}
                        </Link>
                    </li>
                    <li>
                        <Link to={RouterPaths.SettingsPayments}>
                            {getLangText('paymentsLabel')}
                        </Link>
                    </li>
                    <li className="active">
                        <div>{getLangText('settings_payments_paypal_payment')}</div>
                    </li>
                </ul>
                <Link
                    to={RouterPaths.SettingsPayments}
                    className="button back-to-panels mb-3">
                    <i className="icon-arrow-left"/>
                    {getLangText('button_back_to_payment')}
                </Link>
            </div>
            <div className="settings-page sub-settings-page">
                <div className="header-wrapper">
                    <img style={{height: 20}} src="/assets/images/payments/paypal.svg"
                         alt=""/>
                    <div className="description">
                        {getLangText('settings_payments_paypal_payment_subtitle')}
                    </div>
                </div>
                {!!values.onboarding_link ?
                    <div className="settings-form">
                        <div className="steps-wrapper full-filled-numbers max-w-[500px]">
                            <div className="step-group">
                                <div className="step">
                                    <div className="step-header flex-wrap">
                                        <div className="d-flex">
                                            <div className="number">1</div>
                                            <div className="mb-3">
                                                <div className="title">
                                                    {getLangText('clickButtonBellowConfigurePaypal')}
                                                </div>
                                                <div className="sub-title">
                                                    {getLangText('completeFieldsInRegistrationForm')}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-100 pl-4 ml-3">
                                            <a href={values.onboarding_link}
                                               className="button primary w-100 text-center py-3 small-text"
                                            >
                                                {getLangText('configurePaypal')}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="settings-form mb-3">
                        {values.active &&
                            <div className="sm:max-w-[300px]">
                                <AsyncSearchSelectField
                                    {...getFieldProps('paypal_country_id')}
                                    label={getLangText('countryLabel')}
                                    className="default-size"
                                    rest={services.API_SETTINGS_SHIPPING_COUNTRIES}
                                    formatOptionLabel={formatOptionLabel}
                                    defaultValue={{
                                        id: values.paypal_country_id,
                                        name: values.country_name
                                    }}
                                    placeholder={getLangText('countryLabel')}
                                    optionsParser={(options) => options?.map((i) => ({
                                        ...i,
                                        id: i.id,
                                    }))}
                                    searchOnFocus
                                />
                            </div>
                        }
                        {!values?.primary_email_confirmed &&
                            <div className="form-info-box w-full red w-100 mb-2">
                                <div>
                                    <i className="icon-info-c"/>
                                </div>
                                <div>
                                    <div className="title">{getLangText('attentionLabel')}</div>
                                    <div className="desc"
                                         dangerouslySetInnerHTML={{__html: getLangText('emailNotConfirmedPaypal')}}/>
                                </div>
                            </div>
                        }
                        {!values?.payments_receivable &&
                            <div className="form-info-box w-full red w-100">
                                <div>
                                    <i className="icon-info-c"/>
                                </div>
                                <div>
                                    <div className="title">{getLangText('attentionLabel')}</div>
                                    <div className="desc"
                                         dangerouslySetInnerHTML={{__html: getLangText('cannotReceivedPaymentsPaypal')}}/>
                                </div>
                            </div>
                        }
                        <div className="flex items-center mb-3 mt-4 sm:max-w-[72%]">
                            <div className="grow">
                                <button
                                    type="button"
                                    onClick={() => setConfirmModalVisible(true)}
                                    className={`button border-button big-size`}
                                >
                                    {getLangText('disconnect')}
                                </button>
                            </div>
                            <SwitchField
                                {...getFieldProps('active')}
                                label={getLangText('active_label')}
                                id="active"
                                className="switch-form inline-label big-label light-version flex mb-0 mr-3"
                            />
                            <button
                                type="button"
                                onClick={onSave}
                                className={`button small-size primary ${isWaiting ? 'loading-button' : ''}`}
                            >
                                {isWaiting && <Loader isLoading/>}
                                {getLangText('saveButton')}
                            </button>
                        </div>
                        <div className="sm:max-w-[72%]">
                            <AccountTypeSelect
                                data={values}
                            />
                        </div>
                    </div>
                }
            </div>
            {confirmModalVisible &&
                <ConfirmModal
                    dialogClassName="w-380"
                    title={getLangText('disconnect')}
                    SubTitle={() => getLangText('disconnectPaypalConfirmLabel')}
                    saveButton={getLangText('disconnect')}
                    show={confirmModalVisible}
                    isWaiting={isDeleting}
                    onSubmit={() => removeItem('')}
                    hide={hideRemoveModal}
                    saveButtonClass="danger"
                />
            }
        </>
    );
};

export default PaypalAutomation;
