import React from 'react';
import moment from "../../../../messenger/helpers/moment.js";
import {useHistory} from "react-router-dom";
import EmptyList from "../../../../shared/EmptyList.jsx";
import useLang from "../../../../../../src_shared/hooks/useLang";
import {RouterPaths} from "../../../../../routes/RouterPath";
import {convertToUserTimeZone} from "../../../../shared/helpers/dateHelpers";

const Table = ({updateSortOptions, isWaiting, getSortClass, items}) => {
	const history = useHistory();
	const {getLangText} = useLang();

	return (
		<EmptyList
			items={items}
			isLoading={isWaiting}
			className="with-padd"
			imgSrc="/assets/images/empty/live-empty.svg"
			title={getLangText('emptyHereLabel')}
			text={getLangText('changeSearchOrAddReelsLabel')}
		>
			<div className="table-wrapper">
				<div className="table-responsive">
					<table className="table live-table">
						<thead>
						<tr>
							<th
								name="name"
								onClick={updateSortOptions}
								className={getSortClass('name')}
							>
							<span name="name">
								{getLangText('nameLabel')}
							</span>
							</th>
							<th
								name="active"
								onClick={updateSortOptions}
								className={`${getSortClass('active')} text-sm-right`}
							>
							<span name="active">
								{getLangText('activeLabel')}
							</span>
							</th>
							<th
								name="date"
								onClick={updateSortOptions}
								className={`text-right ${getSortClass('date')}`}
							>
							<span name="date">
								{getLangText('addedDateLabel')}
							</span>
							</th>
						</tr>
						</thead>
						<tbody>
						{items.map((item) => (
							<tr onClick={() => history.push(`${RouterPaths.MobileReelsList}/${item.id}`)}
								key={item.id}>
								<td className="name-td">
									{item.name}
								</td>
								<td
									data-thead={`${getLangText('activeLabel')}:`}
									className="with-mobile-thead text-right icon-status text-sm-right order-lg-0 order-4"
								>
									{+item.active ? <img className="ml-auto" src="/assets/images/svg/paid.svg" width={18} alt=""/> :
										<i className="icon-cross"/>}
								</td>
								<td
									data-thead={`${getLangText('addedLabel')}:`}
									className="text-sm-right date w-lg-auto w-100 order-lg-0 order-4 with-mobile-thead inline-thead mb-0 left-mobile-margin mr-0"
								>
									<span>{convertToUserTimeZone(item.date).format('DD.MM.YYYY')}</span>
									<span>{convertToUserTimeZone(item.date).format('HH:mm:ss')}</span>
								</td>
							</tr>
						))}

						</tbody>
					</table>
				</div>
			</div>
		</EmptyList>
	);
};

export default Table;
