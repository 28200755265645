import React from 'react';
import {useSelector} from "react-redux";
import Landing from "./landing/Landing";
import {HEADER_BOX_MOBILE_NOTIFICATIONS} from "../shared/enums/TutorialsVideo";
import TutorialPageBox from "../../../src_shared/components/ui/TutorialPageBox/TutorialPageBox";

const NotificationsWrapper = ({children}) => {
    const {userData} = useSelector((state) => state.session);

    if (!+userData.mobile_app_landing) {
        return <Landing/>
    }

    return (
        <>
            <TutorialPageBox
                page='mobile_notifications'
                videoStatsName={HEADER_BOX_MOBILE_NOTIFICATIONS}
                title="asideClientsLabel"
            />
            {children}
        </>
    );
};

export default NotificationsWrapper;
