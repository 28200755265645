import api from "../../services/axios/axios";
import {selmoUrl} from "../api/api";
import {batch, useDispatch, useSelector} from "react-redux";
import {showAlert} from "../../components/app/shared/components/alert/AlertActions";
import SessionActions from "../../components/app/shared/session/SessionActions";
import {useHistory} from "react-router-dom";
import {RouterPaths} from "../../components/admin/components/routes/RouterPath";
import {services} from "../../components/RestServices";

const useLogoutAsClient = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {userData} = useSelector((state) => state.session);

    const logoutAsClient = async () => {

        try {
            const {data} = await api.get(`${selmoUrl}/${services.API_BACK_TO_PANEL}?user_id=${userData.old_user_id}`)
            batch(() => {
                dispatch(SessionActions.setAndSaveInStorageToken(data.token))
                dispatch(SessionActions.uncheckedAndCheckSession());
                dispatch(showAlert('Udało się ', 'Zostałeś wylogowany jako klient'))
            })
            history.push(RouterPaths.AdminUsers)

        } catch (e) {
            dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
        }
    }

    return {
        logoutAsClient,
    }
};

export default useLogoutAsClient;
