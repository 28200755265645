import createReducer from "../../../shared/helpers/createReducer";
import FormReducer, {getReduceMap} from "../../../../../../../src_shared/form/FormReducer";

export const getStateRoot = (state) => state.admin.sales.dashboard.cards;

export const getInitState = () => FormReducer.getInitState({
	waiting: true,
})
export const prefix = 'ADMIN_SALES_DASHBOARD_CARDS';

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();
