import Model from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

export class AutomaticallyNotificationsModel extends Model {
	getModel() {
		return {
			first_message_text: '',
			first_message_active: '0',
			second_message_text: '',
			second_message_active: '0',
			third_message_text: '',
			third_message_active: '0',
			fourth_message_text: '',
			fourth_message_active: '0',
			fifth_message_text: '',
			fifth_message_active: '0',
		};
	}

	getValidators() {
		return {
			first_message_text: (value, data) => !!+data.first_message_active ? ValidationError.notEmpty(value) : false,
			second_message_text: (value, data) => !!+data.second_message_active ? ValidationError.notEmpty(value) : false,
			third_message_text: (value, data) => !!+data.third_message_active ? ValidationError.notEmpty(value) : false,
			fourth_message_text: (value, data) => !!+data.fourth_message_active ? ValidationError.notEmpty(value) : false,
			fifth_message_text: (value, data) => !!+data.fifth_message_active ? ValidationError.notEmpty(value) : false,
			first_message_active: ValidationError.skip,
			second_message_active: ValidationError.skip,
			third_message_active: ValidationError.skip,
			fourth_message_active: ValidationError.skip,
			fifth_message_active: ValidationError.skip,
		};
	}

	buildDTO(data) {
		const {first_message_text, second_message_text, third_message_text, first_message_active, second_message_active, third_message_active,  fourth_message_text, fourth_message_active, fifth_message_text, fifth_message_active} = data;
		return {
			first_message_text,
			second_message_text,
			third_message_text,
			first_message_active,
			second_message_active,
			third_message_active,
			fourth_message_active,
			fourth_message_text,
			fifth_message_active,
			fifth_message_text,
		};
	}
}

export default new AutomaticallyNotificationsModel();
