import React from 'react';
import Modal from "react-bootstrap/Modal";
import TextArea from "../../../../../../src_shared/components/form/textarea/TextArea";
import SwitchField from "../../../../../../src_shared/components/form/switchField/SwitchField";
import Loader from "../../../../messenger/loader/Loader";
import useLang from "../../../../../../src_shared/hooks/useLang";

const SendSummaryMessage = ({getFieldProps, hideModal, isWaiting, sendMessage}) => {
    const {getLangText} = useLang();

    return (
        <>
            <Modal.Header className="with-border">
                <Modal.Title>
                    <Modal.Title>
                        {getLangText('sendMessageLabel')}
                    </Modal.Title>
                </Modal.Title>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            <Modal.Body>
                <TextArea
                    {...getFieldProps('message')}
                    showLabel={false}
                    label={getLangText('messageToClientLabel')}
                />
                <SwitchField
                    {...getFieldProps('with_link')}
                    className="switch-form inline-label align-items-start"
                    label={getLangText('orderLinkLabel')}
                    subLabel={getLangText('orderLinkTooltipLabel')}
                />
            </Modal.Body>
            <Modal.Footer>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button border-button"
                >
                    {getLangText('cancelButton')}
                </button>
                <button
                    onClick={sendMessage}
                    type="button"
                    className={`button primary px-0 ${isWaiting ? 'loading-button' : ''}`}
                >
                    {isWaiting && <Loader/>}
                    {getLangText('sendButton')}
                </button>
            </Modal.Footer>
        </>
    );
};

export default SendSummaryMessage;
