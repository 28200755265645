import {getStateRoot, prefix} from './listReducer';
import {services} from "../../../../RestServices";
import {SharedEditProductListActions} from "../shared/SharedEditProductListActions";

export class ExpendituresListActions extends SharedEditProductListActions {

}
export const getInstance = () => new ExpendituresListActions({
	getStateRoot,
	restService: services.API_WAREHOUSE_EXPENDITURES,
	prefix,
	preventPushParams: true,
});

export default getInstance();
