import Model, {convertObjectProperties} from "../../src_shared/modules/model/Model";
import ValidationError from "../../src_shared/form/validation/ValidationError";
import {
	FUNCTIONALITY_RANGE_TO_END_PLAN
} from "../../components/app/payments/functionalityModal/functionalityPaymentRange/FunctionalityPaymentRangeEnums";
import {PAYMENTS_SUBSCRIPTION_TYPE} from "../../components/app/shared/enums/PaymentsType";
import {validateInvoiceData, validateInvoiceDataNip, validateInvoiceDataZipCode} from "./PaymentsModel";
import {INVOICE_DATA_COMPANY} from "../../components/app/shared/enums/InvoiceDataEnums";

export class FunctionalityPaymentsModel extends Model {
	getModel() {
		return {
			nip: '',
			selected_subscription: FUNCTIONALITY_RANGE_TO_END_PLAN,
			selected_payment: PAYMENTS_SUBSCRIPTION_TYPE[0].value,
			invoice: INVOICE_DATA_COMPANY,
		};
	}

	getValidators() {
		return {
			selected_subscription: ValidationError.notEmpty,
			selected_payment: ValidationError.notEmpty,
			nip: validateInvoiceDataNip,
			country_id: validateInvoiceData,
			company_name: validateInvoiceData,
			address: validateInvoiceData,
			zip_code: validateInvoiceDataZipCode,
			city: validateInvoiceData,
		};
	}

	parseDTO(data) {
		const preparedInvoiceData = data.invoiceData ? {
			country: {
				name: data.invoiceData?.country_data?.name,
				id: data.invoiceData?.country_data?.id,
			},
			country_id: data.invoiceData?.country_id,
			nip: data.invoiceData?.nip,
			zip_code: data.invoiceData?.zip_code,
			city: data.invoiceData?.city,
			company_name: data.invoiceData?.name,
			address: data.invoiceData?.address,
		} : {};

		return convertObjectProperties(
			data,
			{
			},
			{
				...preparedInvoiceData,
				functionality_name: data.name,
			},
		);
	}

	buildDTO(data) {
		const invoice_data = {
			country_id: data.country_id,
			nip: data.nip,
			name: data.company_name,
			address: data.address,
			city: data.city,
			zip_code: data.zip_code,
		}

		if (data.invoice === INVOICE_DATA_COMPANY) {
			return {
				invoice_data,
				selected_subscription: data.selected_subscription,
				selected_payment: data.selected_payment,
				[data.selmo_name]: '1',
			}
		}

		return {
			selected_subscription: data.selected_subscription,
			selected_payment: data.selected_payment,
			[data.selmo_name]: '1',
		};
	}
}

export default new FunctionalityPaymentsModel();
