import React, {useEffect, useRef, useState} from 'react';
import axios from "axios";
import useStatusFilters from "../statusFilter/useStatusFilters";
import {selmoUrl} from "../../../api/api";
import useDropdown from "../../../hooks/useDropdown";
import useLang from "../../../hooks/useLang";
import Loader from "../../loader/Loader";
import api from "../../../../services/axios/axios";

const AsyncFilter = ({
						 ListActions,
						 FiltersActions,
						 getFiltersStateRoot,
						 className,
						 extraProps,
						 name,
						 selectAllName,
						 filterTitle,
						 defaultSelectedLabel,
						 rest,
						 optionLabel,
						 optionsParser,
						 dropdownClassName,
					 }) => {

	const [shippingData, setShippingData] = useState([]);
	const [shippingDataLoading, setShippingDataLoading] = useState(true);
	const {
		statuses,
		selectAll,
		isSelectAll,
		saveFilters,
		filterButtonTitle,
		handleCheckBoxClick,
		allItemsClick,
		updateLocallyStatusesState,
		getCheckboxValue,
		setIsSelectAll,
	} = useStatusFilters(
		ListActions,
		getFiltersStateRoot,
		FiltersActions,
		name,
		selectAllName,
		shippingData,
		defaultSelectedLabel
	);

	const buttonTitle = filterTitle ? filterTitle() : filterButtonTitle();

	const dropdownRef = useRef(null);
	const buttonRef = useRef(null);
	const {getLangText} = useLang();

	const onSaveClick = () => {
		saveFilters(extraProps.id);
		setOpen(false);
	};

	const onCloseClick = () => {
		updateLocallyStatusesState(statuses, selectAll);
		setOpen(false);
	};

	const {open, setOpen} = useDropdown(buttonRef, dropdownRef, onCloseClick);

	const preparedOption = (items) => {
		const data = optionsParser(items);
		setShippingData(data);
	}


	const getShippingStatuses = async () => {
		setShippingDataLoading(true)
		try {
			const {data} = await api.get(`${selmoUrl}/${rest}`)
			preparedOption(data.items)
			if (data.items.length === statuses.length) {
				setIsSelectAll(true)
			}
		} catch (e) {
			console.warn('Fail get async filters statuses')
		} finally {
			setShippingDataLoading(false)
		}
	}

	useEffect(() => {
		if (open) {
			getShippingStatuses();
		}
	}, [open])


	return (
		<div className={`filters-wrapper ${className}`}>
			<button
				ref={buttonRef}
				type="button"
				onClick={() => setOpen(!open)}
				className={`button filters-button ${buttonTitle.className} ${open ? 'active' : ''}`}
			>
				<div className="d-flex align-items-center flex-grow-1 font-weight-bold"
					 dangerouslySetInnerHTML={{__html: `${buttonTitle.text}`}}/>

			</button>
			<div
				ref={dropdownRef}
				className={`dropdown-menu ${dropdownClassName} loader-parent ${open ? 'show' : ''}`}
			>
				<Loader className="small-loader" isLoading={shippingDataLoading}/>
				<div className="checkbox-filter-wrapper">
					<div className="form-group checkbox-form">
						<input
							className="checkbox-input form-control"
							type="checkbox"
							onChange={allItemsClick}
							checked={isSelectAll}
						/>
						<span className="checkbox-checkmark"/>
						<label className="control-label">{getLangText('allLabel')}</label>
					</div>
					{shippingData.map((item) => (
						<div
							key={item.value}
							className="form-group checkbox-form"
						>
							<input
								className="checkbox-input form-control"
								type="checkbox"
								onChange={() => handleCheckBoxClick(item.value)}
								checked={getCheckboxValue(item.value)}
							/>
							<span className="checkbox-checkmark"/>
							{optionLabel(item)}
						</div>
					))}
				</div>
				<div className="filters-buttons">
					<button
						type="button"
						className="reset-filter"
						onClick={() => updateLocallyStatusesState()}
					>
						<span>{getLangText('clearLabel')}</span>
					</button>
					<button
						className="button primary"
						type="button"
						onClick={onSaveClick}
					>
						{getLangText('saveButton')}
					</button>
				</div>
			</div>
		</div>
	);
};

AsyncFilter.defaultProps = {
	className: '',
	extraProps: {},
	options: [],
	filterTitle: null,
	dropdownClassName: '',
	defaultSelectedLabel: 'Filtruj',
	optionLabel: (item) => (
		<label className="control-label">
			{item.label}
		</label>
	),
	optionsParser: (items) => items.map((i) => ({
		value: i.id,
		label: i.name,
		country: i.country_name,
	}))
}

export default AsyncFilter;
