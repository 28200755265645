import React from 'react';
import Chart from "react-apexcharts";
import EmptyList from "../../../shared/EmptyList";
import {getCurrenciesShortcut} from "../../list/List";
import useLang from "../../../../../src_shared/hooks/useLang";

const PackageValuesChart = ({items, currency}) => {
	const {getLangText} = useLang();

	const getPercentValue = (value) => Math.round((100 * +value) / items.packagesValuesInfo.overall);

	const preparedPaymentsInfoLabels = Object.keys(items.packagesValuesInfo)?.filter((a) => a !== 'overall').map((i) => i);
	const preparedPaymentsInfo = Object.values(items.packagesValuesInfo)?.filter((a) => +a !== +items.packagesValuesInfo.overall).map((i) => getPercentValue(i));

	const options = {
		chart: {
			id: "payments-donuts",
			type: 'donut',
		},
		colors: ['#1C4ED8', '#5FA5FA', '#90E8EE', '#FF8100', '#FFD133', '#3B29CC'],
		opacity: 1,
		stroke: {
			width: 0,
		},
		legend: {
			show: false
		},
		dataLabels: {
			enabled: false
		},
		labels: Object.keys(items.packagesValuesInfo),
		tooltip: {
			custom: function ({series, seriesIndex, dataPointIndex, w}) {
				return `<div class="chart-tooltip">
<div class="label">${w.config.labels[seriesIndex]} ${getCurrenciesShortcut(currency)}</div>
<div class="value">${series[seriesIndex]} %</div>
</div>`
			}
		}
	};

	return (
		<div className="default-shadow-box mb-3">
			<div className="donuts-chart-wrapper">
				<div className="title">{getLangText('ordersValueLabel')}</div>
				<EmptyList
					className="full-size"
					imgSrc="/assets/images/empty/chart-3.svg"
					items={items.ordersInfo}
					title=""
				>
					<div className="donuts-chart">
						<Chart
							options={options}
							series={preparedPaymentsInfo}
							type="donut"
							width="100%"
						/>
					</div>
				</EmptyList>
				<div className="custom-legend">
					{preparedPaymentsInfoLabels?.map((i) => (
						<div key={i}>
							<div
								className="label">{i} {getCurrenciesShortcut(currency)}</div>
							<div className="value">
								{getPercentValue(items.packagesValuesInfo[i])} %<strong>{+items.packagesValuesInfo[i]}</strong>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default PackageValuesChart;
