import React, {useState} from 'react';
import useWindowDimensions from "../../../messenger/hooks/useWindowDimensions/useWindowDimensions";
import useLang from "../../../../../src_shared/hooks/useLang";

const MobileToggle = ({children, title, className = ''}) => {
	const {width} = useWindowDimensions();
	const {getLangText} = useLang();

	const [isVisible, setIsVisible] = useState(false);

	return (
		<div className={`mobile-toggle-wrapper ${className}`}>
			<div className="mobile-toggle-header">
				<div
					onClick={() => setIsVisible(!isVisible)}
					className={`top-part ${isVisible ? 'open' : ''}`}
				>
					<h2>{title}</h2>
					<div className="button text-only">
						{isVisible ? getLangText('collapseLabel') : getLangText('expandLabel')}
						<i className="icon-arrows"/>
					</div>
				</div>
			</div>
			{(width > 991 || isVisible) &&
				children
			}
		</div>
	);
};

export default MobileToggle;
