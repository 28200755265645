import * as React from "react"
const FacebookCircle = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        style={{
            enableBackground: "new 0 0 18 18",
        }}
        viewBox="0 0 18 18"
        {...props}
    >
        <path
            d="M18 9c0 4.464-3.253 8.159-7.516 8.867v-6.305h1.98l.405-2.52h-2.385V7.287c0-.72.27-1.26 1.35-1.26h1.17V3.732c-.63-.09-1.35-.18-1.98-.18-2.07 0-3.51 1.26-3.51 3.51v1.98h-2.25v2.52h2.25v6.305C3.252 17.158 0 13.463 0 9a9 9 0 0 1 9-9 9 9 0 0 1 9 9z"
            style={{
                fill: props.fill,
            }}
        />
    </svg>
)
export default FacebookCircle
