import React from 'react';
import useLang from "../../../../../../src_shared/hooks/useLang";
import {
    DPD_POINT_MODAL, GLS_POINT_MODAL,
    INPOST_POINT_MODAL,
    MONDIAL_RELAY_POINT_MODAL,
    ORLEN_POINT_MODAL
} from "../../../../basket/secondStep/SecondStep";

const SelectedPointMachineBox = ({values}) => {
    const {getLangText} = useLang();
    return (
        <>
            {values.pointMachineCode &&
                <div className="section-value mt-3">
                    <div className="mb-2">
                        {values.pointMachineType === INPOST_POINT_MODAL &&
                            <img src="/assets/images/inpost.svg" alt=""/>
                        }
                        {values.pointMachineType === MONDIAL_RELAY_POINT_MODAL &&
                            <img width={80} src="/assets/images/svg/logomondial-relay.svg" alt=""/>
                        }
                        {values.pointMachineType === ORLEN_POINT_MODAL &&
                            <img width={30} src="/assets/images/svg/orlen-paczka.svg" alt=""/>
                        }
                        {values.pointMachineType === DPD_POINT_MODAL &&
                            <img width={90} src="/assets/images/svg/dpd-pick-up-logo.svg" alt=""/>
                        }
                        {values.pointMachineType === GLS_POINT_MODAL &&
                            <img width={50} src="/assets/images/gls.png" alt=""/>
                        }
                    </div>
                    <div>
                        {values.pointMachineType === INPOST_POINT_MODAL &&
                            getLangText('customerBasketSelectedInpostLabel')
                        }
                        {values.pointMachineType === MONDIAL_RELAY_POINT_MODAL &&
                            getLangText('selectedMondialRelayPointLabel')
                        }
                        {values.pointMachineType === ORLEN_POINT_MODAL &&
                            getLangText('selectedOrlenPointLabel')
                        }
                        {values.pointMachineType === DPD_POINT_MODAL &&
                            getLangText('selectDpdPickupPoint')
                        }
                        {values.pointMachineType === GLS_POINT_MODAL &&
                            getLangText('selectedGlsPickupPoint')
                        }
                    </div>
                    {(values.pointMachineType === DPD_POINT_MODAL || values.pointMachineType === GLS_POINT_MODAL) &&
                        <div className="font-bold">{values.pointMachineName}</div>
                    }
                    <div>{values.pointMachineCity}</div>
                    <div>{values.pointMachineStreet}</div>
                    <div>{values.pointMachineCode}</div>
                </div>
            }
        </>
    );
};

export default SelectedPointMachineBox;
