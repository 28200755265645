const initialState = {
    not_read_conversations: 0,
    subMenuOpen: null,
    subMenuWidth: 0,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_NOT_READ_CONVERSATIONS':
            return {
                ...state,
                not_read_conversations: action.count
            };
        case 'SET_SUB_MENU_OPEN_STATE':
            return {
                ...state,
                subMenuOpen: action.toggle,
                subMenuWidth: action.width || state.subMenuWidth
            };
        default:
            return state;
    }
}
