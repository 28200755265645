import React from 'react';
import Chart from "react-apexcharts";
import EmptyList from "../../../../../shared/EmptyList";
import moment from "moment";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import {convertToUserTimeZone} from "../../../../../shared/helpers/dateHelpers";

export const labelStyle = {
	style: {
		fontSize: '12px',
		colors: '#9CA3AF',
		fontFamily: 'Plus Jakarta Sans',
	},
}

export const formattedMonth = (monthNumber) => convertToUserTimeZone().month(monthNumber).format('MMM');
export const formattedNumberMonth = (monthNumber) => convertToUserTimeZone().month(monthNumber - 1).format('MM');
export const formattedYear = (monthNumber) => convertToUserTimeZone().year(monthNumber).format('YY');

const IncomesChart = ({items, currency = 'PLN'}) => {
	const {getLangText} = useLang();

	const options = {
		chart: {
			id: "basic-bar",
			toolbar: {
				show: false,
			},
		},
		yaxis: {
			labels: {
				style: {
					fontSize: '12px',
					colors: '#9CA3AF',
					fontFamily: 'Plus Jakarta Sans',
				},
			}
		},
		grid: {
			borderColor: '#D1D5DB',
			strokeDashArray: 5,
		},
		xaxis: {
			categories: items.ordersInfo.map((i) => i.day ? `${i.day}.${formattedNumberMonth(i.month)}` : `${formattedMonth(i.month)} ${formattedYear(i.year)}`),
			labels: {
				...labelStyle,
				hideOverlappingLabels: true,
			},
			tooltip: {
				enabled: false
			},
			axisTicks: {
				show: false,
			},
		},
		stroke: {
			curve: 'smooth',
			width: 3,
		},
		legend: {
			show: false
		},
		colors: ['#FF2B59', '#FB8090'],
		fill: {
			type: 'gradient',
			gradient: {
				shade: 'dark',
				type: "horizontal",
				gradientToColors: undefined,
				inverseColors: true,
				opacityFrom: 1,
				opacityTo: 1,
				colorStops: [
					{
						offset: 0,
						color: "#FB8090",
						opacity: 1
					},
					{
						offset: 50,
						color: "#FF2B59",
						opacity: 1
					},
				]
			}
		},
		tooltip: {
			custom: function ({series, dataPointIndex, seriesIndex, w}) {
				return `<div class="chart-tooltip">
						<div class="date-label">
						${w.config.series[0].data[dataPointIndex].x}
						</div>
						<div class="label">${getLangText('incomeLabel')}</div>
						<div class="value">${series[0][dataPointIndex]} zł</div>
					</div>`
			}
		}
	};

	const series = [
		{
			name: getLangText('incomesLabel'),
			data: items.ordersInfo.map((i) => ({
				y: Math.round(+i.price),
				x: i.day ? `${i.day}.${formattedNumberMonth(i.month)}` : `${formattedMonth(i.month)} ${formattedYear(i.year)}`,
			})),
		},
	]

	return (
		<div className="border border-[#E5E7EB] rounded-[5px] px-[20px] py-6 pb-2">
			<div className="title title-with-legend">
				Przychód
			</div>
			<EmptyList
				className="full-size"
				imgSrc="/assets/images/empty/chart-1.svg"
				items={items.ordersInfo}
				title=""
			>
				<div className="chart-wrapper">
					<Chart
						options={options}
						series={series}
						type="line"
						width="100%"
						height={265}
					/>
				</div>
			</EmptyList>
		</div>
	);
};

export default IncomesChart;
