export const SHOP_SOURCE_MESSENGER = 1
export const SHOP_SOURCE_ADD = 2
export const SHOP_SOURCE_WOODPECKER = 3
export const SHOP_SOURCE_REFERRAL = 3
export const SHOP_SOURCE_COLD_CALL = 4
export const SHOP_SOURCE_NULL = '0'


export const ADMIN_SHOP_SOURCES = [
	{
		value: SHOP_SOURCE_MESSENGER, label: 'Messenger', icon: 'messenger',
	}
	,{
		value: SHOP_SOURCE_ADD, label: 'Reklama', icon: 'ads',
	},
	{
		value: SHOP_SOURCE_REFERRAL, label: 'Polecenie', icon: 'referral',
	},
	{
		value: SHOP_SOURCE_COLD_CALL, label: 'Cold Call', icon: 'cold-calling',
	},
	{
		value: SHOP_SOURCE_NULL, label: 'Brak', icon: '',
	},
]
