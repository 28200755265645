import React, {useEffect} from 'react';
import useForm from "../../../../src_shared/form/useForm";
import FormActions from "./FormActions";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {createSelector} from "reselect";
import {getStateRoot} from "./formReducer";
import {getStateRoot as getDeliveryMethodsStateRoot} from "../deliveryMethods/formReducer";
import Loader from "../../shared/Loader";
import PaymentMethodsForm from "../../shared/components/paymentMethods/PaymentMethodsForm";
import PaymentMethodsNew from "./components/paymentMethodsNew/PaymentMethodsNew";

const PaymentMethods = ({data}) => {

    const dispatch = useDispatch();
    const {magicUrl, shopName} = useParams();
    const shopId = shopName.split('-')[shopName.split('-').length - 1];

    const {paymentValidationActive} = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));
    const deliveryMethodsData = useSelector(createSelector(getDeliveryMethodsStateRoot, (stateRoot) => stateRoot.data));
    const isOrderPickupSelected = deliveryMethodsData?.deliveries?.some((i) => +i.id === +deliveryMethodsData?.shipping && i.name.toLowerCase()?.includes('odbiór osobisty'))
    const setValueOnServer = (field, value) => dispatch(FormActions.setValueOnServer(field, value, magicUrl, shopId))

    const {
        data: {values, updateValues},
        validation: {validateErrors},
        form: {isWaiting}
    } = useForm({
        FormActions,
        params: {},
        requireParentId: false,
        preventAutoLoad: true,
        beforeDestroy: () => {
        }
    });

    const isError = () => {
        const errorKey = validateErrors.payment(values.payment, values);
        return errorKey ? errorKey : false;
    }
    const hasError = !!isError() && paymentValidationActive;

    const hiddenPickupMethod = !!+deliveryMethodsData.open_cart_status && !+data.shopInfo.open_cart_pickup;

    useEffect(() => {
        dispatch(FormActions.setPaymentMethodsValidation(false))
    }, [])

    return (
        <div className="loader-parent">
            <Loader isLoading={isWaiting}/>
            {data.overall.new_payments_view ?
                <PaymentMethodsNew
                    shopInfo={data.shopInfo}
                    onPaymentChange={setValueOnServer}
                    values={values}
                    items={values.payment_methods}
                    updateValues={updateValues}
                    hasError={hasError}
                    setPaymentMethodsValidation={(toggle) => dispatch(FormActions.setPaymentMethodsValidation(toggle))}
                    shopPayment={data?.shopPayment}
                    paymentValidationActive={paymentValidationActive}
                    isOrderPickupSelected={isOrderPickupSelected}
                    hiddenPickupMethod={hiddenPickupMethod}
                    separatedTransferMethods={true}
                /> :
                <PaymentMethodsForm
                    shopInfo={data.shopInfo}
                    onPaymentChange={setValueOnServer}
                    values={values}
                    items={values.payment_methods}
                    updateValues={updateValues}
                    hasError={hasError}
                    setPaymentMethodsValidation={(toggle) => dispatch(FormActions.setPaymentMethodsValidation(toggle))}
                    shopPayment={data?.shopPayment}
                    paymentValidationActive={paymentValidationActive}
                    isOrderPickupSelected={isOrderPickupSelected}
                    hiddenPickupMethod={hiddenPickupMethod}
                />
            }
        </div>
    );
};

export default PaymentMethods;
