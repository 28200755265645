import {getReduceMap} from "../../../../../src_shared/filters/filterReducer";
import DataReducer from "../../../../../src_shared/form/data/DataReducer";
import createReducer from "../../../shared/helpers/createReducer";
import moment from "moment/moment";
import history from "../../../../history";
import {parseParams} from "../../../../../src_shared/list/ListActions";
import {ALL_ORDERS_VIEW, INTERVAL} from "../../../shared/enums/OrderViewTypes";
import {convertToUserTimeZone} from "../../../shared/helpers/dateHelpers";

const queryParams = history.location.search;
const parsedParams = parseParams(queryParams)

const preparedViewType = !!queryParams ? (parsedParams.dateFrom ? INTERVAL : ALL_ORDERS_VIEW) : INTERVAL;
const preparedDate = (initialState, paramsDate) => {
	if (!!queryParams) {
		if (parsedParams[paramsDate]) {
			return moment(parsedParams[paramsDate]).toDate()
		} else {
			return null;
		}
	} else {
		return initialState;
	}
}
export const getStateRoot = (state) => state.automation.lists.filters;
export const prefix = 'INTERACTIONS_FILTERS_';
export const getInitState = () => DataReducer.getInitState({
	fromDate: preparedDate(moment(new Date()).subtract(1, 'M').toDate(), 'dateFrom'),
	toDate: preparedDate(new Date(), 'dateTo'),
	viewType: preparedViewType,
}, {
	fromDate: moment(new Date()).subtract(1, 'M').toDate(),
	toDate: moment().toDate(),
	viewType: INTERVAL,
})


export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();
