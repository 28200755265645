import {getStateRoot, prefix} from "./formReducer.js";
import {services} from "../../../../RestServices.js";
import FormActions from "../../../../../src_shared/form/FormActions";
import {RouterPaths} from "../../../../routes/RouterPath";
import CollectionModel from "../../../../../modules/models/CollectionModel";

export class CollectionFormActions extends FormActions {
	afterDeleteSubmit(history) {
		return () => {
			history.push(RouterPaths.CollectionsList)
		};
	}
}

export const getInstance = () => new CollectionFormActions({
	getStateRoot,
	prefix,
	model: CollectionModel,
	restService: services.API_COLLECTIONS,
	route: RouterPaths.CategoriesList
});

export default getInstance();
