import React, {useState} from 'react';
import useLang from "../../../../../../../src_shared/hooks/useLang";
import useFormLocal from "../../../../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../../../../RestServices";
import Modal from "react-bootstrap/Modal";
import Loader from "../../../../../shared/Loader";
import SelmoPaczkaDataForm from "./SelmoPaczkaDataForm";
import SelmoPaczkaActivateModal from "../../../../../../../modules/models/selmoPaczka/SelmoPaczkaActivateModal";
import {useDispatch} from "react-redux";
import SessionActions from "../../../../../shared/session/SessionActions";

const whyWorthItOptions = [
    {
        id: 0,
        title: 'selmoPaczkaBenefits1',
        desc: 'selmoPaczkaBenefitsSubLabel1',
    },
    {
        id: 1,
        title: 'selmoPaczkaBenefits2',
        desc: 'selmoPaczkaBenefitsSubLabel2',
    },
    {
        id: 2,
        title: 'selmoPaczkaBenefits3',
        desc: 'selmoPaczkaBenefitsSubLabel3',
    },
]

const SelmoPaczkaDataModal = ({showModal, hide, fetchData, setShowCreateShippingModal = null}) => {
    const {getLangText} = useLang();
    const dispatch = useDispatch();

    const afterSubmit = () => {
        hide();
        if (setShowCreateShippingModal) {
            setShowCreateShippingModal(true)
        } else {
            fetchData();
        }
        dispatch(SessionActions.updateUserData('sp_active', '1'))
    }

    const [{getFieldProps, onSave, isWaiting, values}] = useFormLocal({
        model: SelmoPaczkaActivateModal,
        rest: services.API_PACKAGE_ACTIVATE,
        initialAutoLoad: false,
        afterSubmit,
    });

    return (
        <Modal
            dialogClassName={`default-modal small-modal huge-modal`}
            show={showModal}
            onHide={hide}
        >
            <form onSubmit={onSave}>
                <div className="grid sm:grid-cols-5">
                    <div className="p-8 sm:col-span-3 relative">
                        <button
                            onClick={hide}
                            type="button"
                            className="text-[11px] sm:hidden text-desc absolute top-6 right-6"
                        >
                            <i className="icon-cross"/>
                        </button>
                        <div className="font-bold text-lg">{getLangText('activateSelmoPaczka')}</div>
                        <div className="text-desc font-medium text-sm">
                            {getLangText('sendParcelCheaper')}
                        </div>
                        <div className="font-bold mb-2 mt-4">
                            {getLangText('setTransmittingData')}
                        </div>
                        <SelmoPaczkaDataForm
                            getFieldProps={getFieldProps}
                            values={values}
                        />
                        <button
                            type="submit"
                            style={{fontSize: 14, paddingBottom: 16, paddingTop: 16}}
                            className={`button px-0 w-full mt-2 primary ${isWaiting ? 'loading-button' : ''}`}
                        >
                            {isWaiting && <Loader isLoading/>}
                            {getLangText('activeSelmoPaczka')}
                        </button>
                    </div>
                    <div
                        className="sm:col-span-2 p-8 flex flex-col rounded-r-[8px] bg-[#F6FCFF] border-l border-[#D7EAFE] relative">
                        <button
                            onClick={hide}
                            type="button"
                            className="text-[11px] sm:block hidden text-desc absolute top-4 right-4"
                        >
                            <i className="icon-cross"/>
                        </button>
                        <div
                            className="text-[#4B5563] font-bold text-sm">{getLangText('knowTheBenefitsSelmoPaczka')}</div>
                        <div className="space-y-4 mt-3 mb-4">
                            {whyWorthItOptions.map((i) => (
                                <div
                                    key={i.id}
                                    className="flex items-start"
                                >
                                    <div className="mr-2 -mt-1">
                                        <i className="icon-tick-c text-[#5FA5FA] text-lg"/>
                                    </div>
                                    <div>
                                        <div
                                            className="text-[#101827] font-bold text-sm">{getLangText(i.title)}</div>
                                        <div className="text-desc text-sm font-medium">{getLangText(i.desc)}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <img
                            className="w-[270px] mt-auto mr-sm-0 mr-auto ml-auto"
                            src="/assets/images/selmo-box-01.webp" alt=""/>
                    </div>
                </div>
            </form>
        </Modal>
    );
};

export default SelmoPaczkaDataModal;
