import React from 'react';
import {useSelector} from "react-redux";
import useLang from "../../../../src_shared/hooks/useLang";

const EmptyState = () => {
	const {getLangText} = useLang();
	const {hasItems} = useSelector((state) => state.messenger.chatList)

	return (
		!hasItems ?
			<div className="empty-state">
				<div className="img-icon">
					<img src="/assets/images/illustration/all-messages.svg" alt=""/>
				</div>
				<div className="title">{getLangText('messageWillAppearHereLabel')}</div>
				<div className="desc">{getLangText('willTextOnFanPageLabel')}</div>
			</div> :
			<div className="empty-state">
				<div className="img-icon">
					<img src="https://app.selmo.io/assets/images/illustration/choose-conversation.svg"
						 alt="choose conversation"/>
				</div>
				<div className="title">{getLangText('selectConversationLabel')}</div>
				<div className="desc">{getLangText('clickOnListOnLeftLabel')}</div>
			</div>

	);
};

export default EmptyState;
