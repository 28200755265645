import React from 'react';
import useLang from "../../../../../../src_shared/hooks/useLang";

const SelmoProLandingBenefitsBox = ({benefits = [], imgSrc}) => {
    const {getLangText} = useLang();

    return (
        <div className="sm:px-[180px] sm:py-12 p-6 mt-12 relative">
            <div className="text-center mb-16">
                <img
                    style={{width: 'auto', height: 24}}
                    className="mb-2 mx-auto"
                    src="/assets/images/selmo-pro-logo.png"
                    alt="logo-selmo"
                />
                <div
                    className="text-[#fff] inline font-bold selmo-pro-landing-title text-[30px] leading-[34px] sm:text-[36px] sm:leading-[42px] mb-3"
                    dangerouslySetInnerHTML={{__html: getLangText('whatDoYouGainGrey')}}
                />
            </div>
            <div className="grid sm:grid-cols-2 items-center">
                <div className="text-[#fff] space-y-5 sm:mr-6">
                    {benefits.map((benefit) => (
                        <div key={benefit.value}>
                            <div className="flex items-center sm:justify-start justify-center text-[50px] leading-[50px] sm:text-[72px] sm:leading-[70px] font-bold">
                                <span className="text-[46px] leading-[26px]">+</span>
                                {benefit.value}
                            </div>
                            <div
                                className="grey-subtitle text-sm-left text-center whitespace-pre-wrap font-bold mt-2"
                                dangerouslySetInnerHTML={{__html: getLangText(benefit.label)}}
                            />
                        </div>
                    ))}
                </div>
                <div className="sm:absolute sm:left-1/2 sm:-ml-16">
                    <img
                        alt=""
                        src={imgSrc}
                    />
                </div>
            </div>
        </div>
    );
};

export default SelmoProLandingBenefitsBox;
