import React from 'react';
import FunctionalityPurchasePage from "../../../app/shared/FunctionalityPurchasePage";
import FunctionalityModal from "../../../app/payments/functionalityModal/Form";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {getStateRoot as getFunctionalityModalStateRoot} from "../../../app/payments/functionalityModal/formReducer";
import FunctionalityModalActions from "../../../app/payments/functionalityModal/FormActions";
import {FAKTUROWNIA_PERMISSION} from "../../../app/shared/enums/SelmoPermissions";
import Loader from "../../../app/shared/Loader";
import PermissionsWrapper from "../../../routes/PermissionsWrapper";
import moment from "moment";
import useLang from "../../../../src_shared/hooks/useLang";
import {SHOP_ORIGIN_FR} from "../../../app/shared/enums/ShopOrigins";
import {ANGEL_GLADYS_SHOP_ID} from "../../../../src_shared/enums/TrustedShopsIds";
import {convertToUserTimeZone} from "../../../app/shared/helpers/dateHelpers";

const NoPermissionFakturownia = () => {
	const dispatch = useDispatch();
	const {modalVisible} = useSelector(createSelector(getFunctionalityModalStateRoot, (stateRoot) => stateRoot));
	const {waitingForResponse} = useSelector((state) => state.session);
	const {getLangText} = useLang();
	const {userData} = useSelector((state) => state.session);

	const showModal = () => dispatch(FunctionalityModalActions.modal.showModal());

	const NoPermissionFakturownia = () => (
		<div className="loader-parent">
			<Loader isLoading={waitingForResponse}/>
			<FunctionalityPurchasePage
				title={getLangText('generateSalesDocumentLabelWithSpanLabel')}
				imgSrc={(userData.origin === SHOP_ORIGIN_FR && +userData.id !== ANGEL_GLADYS_SHOP_ID) ? '/assets/images/fakturownia-logo-fr.png' : '/assets/images/fakturownia-logo.svg'}
				className="with-shadow"
				onClick={showModal}
			/>
		</div>
	)

	const activeFunctionality = userData?.functionalities_expiration?.find((i) => i.selmo_name === FAKTUROWNIA_PERMISSION);

	return (
		<div className="mb-1">
			<PermissionsWrapper
				NoPermissionComponent={NoPermissionFakturownia}
				allowedPermissions={[FAKTUROWNIA_PERMISSION]}
			>
				<div className="default-shadow-box bigger-y-padd payment-benefits-wrapper mb-3">
					<div className="label">{getLangText('subscriptionStatusLabel')}</div>
					<div className="active-plan-date d-flex align-items-center">
						<div className="date">{getLangText('paymentsActivePlanToLabel')} <strong>{convertToUserTimeZone(activeFunctionality?.expires_in).format('DD.MM.YYYY')}</strong>
						</div>
						<button
							type="button"
							onClick={showModal}
							className="button primary small-size ml-2"
						>
							{getLangText('extendLabel')}
						</button>
					</div>
				</div>
			</PermissionsWrapper>
			{modalVisible &&
				<FunctionalityModal
					desc={getLangText('generateSalesDocument')}
					title={getLangText('buyAccessToFakturownia')}
					functionalityName={FAKTUROWNIA_PERMISSION}
				/>
			}
		</div>
	);
};

export default NoPermissionFakturownia;
