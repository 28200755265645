import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

export default (actions, getStateRoot) => {
    const selectWaiting = createSelector(
        getStateRoot,
        stateRoot => stateRoot.waiting,
    );
    const waiting = useSelector(selectWaiting);
    const dispatch = useDispatch();
    const startWaiting = useCallback(() => dispatch(actions.startWaiting()), [actions, dispatch]);
    const stopWaiting = useCallback(() => dispatch(actions.stopWaiting()), [actions, dispatch]);

    return {
        startWaiting,
        stopWaiting,
        waiting,
    };
};
