import ListActions, {getGETParamsUrl} from "../../../../src_shared/list/ListActions";
import {getStateRoot, prefix} from './listReducer';
import {getStateRoot as getFiltersStateRoot} from '../filters/filtersReducer'
import {convertDate} from "../../shared/helpers/dateHelpers";
import history from "../../../history";
import {RouterPaths} from "../../../routes/RouterPath";
import {services} from "../../../RestServices";

class MrAutomateListActions extends ListActions {
    getLoadParams(state) {
        const params = {};
        const dateFrom = getFiltersStateRoot(state).data.fromDate;
        const dateTo = getFiltersStateRoot(state).data.toDate;

        if (dateFrom) {
            params.dateFrom = convertDate(dateFrom);
        }

        if (dateTo) {
            params.dateTo = convertDate(dateTo);
        }


        history.replace({
            pathname: this.pathname,
            search: getGETParamsUrl(params)
        });

        return params;
    }

}

export const getInstance = () => new MrAutomateListActions({
    getStateRoot,
    restService: services.API_SETTINGS_AUTOMATE_SUMMARY,
    pathname: RouterPaths.MrAutomate,
    prefix,
});

export default getInstance();
