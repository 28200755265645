import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {getStateRoot as getFiltersPanelStateRoot} from '../form/formReducer';
import FiltersActions from "../../../filters/FiltersActions";
import OrderListFiltersPanelActions from "../form/FormActions";
import ListActions from "../../ListActions";
import useWindowDimensions from "../../../../messenger/hooks/useWindowDimensions/useWindowDimensions";
import useLang from "../../../../../../src_shared/hooks/useLang";

const ToggleFilterButton = ({buttonTitle, children, onClick, name, selectAllName, getFiltersStateRoot}) => {

	const [visible, setVisible] = useState(false);
	const dispatch = useDispatch();
	const {width} = useWindowDimensions();
	const {getLangText} = useLang();

	const [field, locallyField, locallySelectAllField, locallyDefaultField] = useSelector(createSelector(getFiltersStateRoot, getFiltersPanelStateRoot, (stateRoot, filtersPanelStateRoot) => [
		stateRoot.data[name],
		filtersPanelStateRoot.data[name],
		filtersPanelStateRoot.data[selectAllName],
		filtersPanelStateRoot.emptyData[name]
	]));

	const handleClick = () => {
		setVisible(!visible)
		onClick()
	}

	const FiltersCount = () => {
		if (Array.isArray(field)) {
			if (!+field.length) return null;
			return (
				<div className="badge">
					<span>{field.length}</span>
				</div>
			)
		} else {
			if (field !== 'all') {
				return (
					<div className="badge">
						<span>1</span>
					</div>
				)
			}
		}
		return null;
	}

	useEffect(() => {
		if (Array.isArray(field)) {
			if (!!+field.length) {
				setVisible(true);
			}
		} else {
			if (field !== 'all') {
				setVisible(true);
			}
		}
	}, [])

	const saveFilters = () => {
		dispatch(FiltersActions.data.updateValues({
			[name]: locallyField,
			[selectAllName]: locallySelectAllField,

		}))
		dispatch(ListActions.loadPage(1));
		if (width < 767) {
			dispatch(OrderListFiltersPanelActions.modal.hideModal());
		}
	};

	const clearFilter = () => {
		if (Array.isArray(field)) {
			dispatch(OrderListFiltersPanelActions.data.updateValues({
				[name]: locallyDefaultField,
				[selectAllName]: false,
			}))
		} else {
			dispatch(OrderListFiltersPanelActions.data.setValue(name, 'all'))
		}
	};

	return (
		<div className="toggle-filter-wrapper">
			<button
				type="button"
				className={`toggle-filter-button ${visible ? 'open' : ''}`}
				onClick={handleClick}
			>
				{buttonTitle}
				<FiltersCount/>
				<i className="icon-arrows"/>
			</button>
			{visible &&
				<>
					<div className="toggle-filter-body">
						{children}
					</div>
					<div className="toggle-filter-footer">
						<button
							className="clear-button button text-only"
							onClick={clearFilter}
						>
							<span>{getLangText('clearLabel')}</span>
						</button>
						<button
							className="button primary"
							type="button"
							onClick={saveFilters}
						>
							{getLangText('filterButton')}
						</button>
					</div>
				</>
			}
		</div>
	);
};

ToggleFilterButton.defaultProps = {
	onClick: () => {
	},
	buttonTitle: '',
}

export default ToggleFilterButton;
