import React, {useRef} from 'react';
import useList from "../../../../../src_shared/list/useList.js";
import ListActions from "./ListActions.js";
import {getStateRoot} from "./listReducer.js";
import Pagination from "../../../shared/components/pagination/Pagination.jsx";
import SearchInput from "../../../shared/components/form/SearchInput.jsx";
import Table from "./components/table/Table.jsx";
import ClientsWrapper from "../../Page";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import SendNotificationsModal from "./components/sendNotificationsModal/SendNotificationsModal";
import DisabledOption from "../../../shared/DisabledOption";
import useLang from "../../../../../src_shared/hooks/useLang";
import WaitingForSent from "./components/waitingForSent/WaitingForSent";
import PromoNotificationBox from "./components/PromoNotificationBox";
import {RouterPaths} from "../../../../routes/RouterPath";

const ClientsSubmittedList = () => {
    const dispatch = useDispatch();
    const {userData} = useSelector((state) => state.session);

    const {
        waitingForNotificationSent,
        waitingForNotifications
    } = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));

    const {getLangText} = useLang();

    const {
        pagination: {
            changePage,
            getPagination,
            itemsPerPage,
            nextPage,
            prevPage,
            totalItems,
            updateItemsPerPage
        },
        remove: {askForRemoveItem, isModalVisible, cancelItemRemoval},
        list: {items, isWaiting},
        search,
        sort: {updateSortOptions, getSortClass},
    } = useList({
        ListActions,
        getStateRoot,
    });

    const sendRef = useRef();

    const sendNotifications = () => dispatch(ListActions.sendNotifications());

    return (
        <ClientsWrapper helmetTitle={`${getLangText('submittedCustomersLabel')} - Selmo`}>
            <DisabledOption
                title={getLangText('optionDisabledLabel')}
                text={getLangText('activateNotifyOptionLabel')}
                className="with-padd with-bg"
                buttonText={getLangText('goToSettingsLabel')}
                link={RouterPaths.SettingsFacebook}
                option={userData.notification_status}
                imgSrc="/assets/images/empty/clients-empty.svg"
            >
                <div className="table-list-wrapper">
                    <div className="default-shadow-box pb-0">
                        <div className="table-header">
                            <div className="top-part flex-sm-nowrap flex-wrap justify-content-between">
                                <h1 className="title mb-sm-0 mb-2">
                                    {getLangText('submittedCustomersLabel')}
                                    {totalItems > 0 &&
                                        <div className="badge">{totalItems}</div>
                                    }
                                    <div className="desc">
                                        {getLangText('sendNotificationOnceIn24HoursLabel')}
                                    </div>
                                </h1>
                                <SearchInput
                                    placeholder={getLangText('searchPlaceholder')}
                                    search={search}
                                    className="mr-sm-2 ml-sm-auto"
                                />
                                <button
                                    ref={sendRef}
                                    type="button"
                                    onClick={askForRemoveItem}
                                    disabled={waitingForNotifications}
                                    style={{opacity: waitingForNotifications ? '0.6' : '1'}}
                                    className="button primary ml-0"
                                >
                                    {getLangText('sendNotificationsLabel')}
                                </button>
                            </div>
                        </div>
                        {waitingForNotifications &&
                            <div className="row">
                                <div className="col-lg-6">
                                    <WaitingForSent/>
                                </div>
                            </div>
                        }
                        <PromoNotificationBox/>
                        <Table
                            getSortClass={getSortClass}
                            updateSortOptions={updateSortOptions}
                            items={items}
                            isWaiting={isWaiting}
                        />
                    </div>
                    <Pagination
                        getLangText={getLangText}
                        pages={getPagination()}
                        items={items}
                        itemsPerPage={itemsPerPage}
                        totalItems={totalItems}
                        onPrevPage={prevPage}
                        onChangePage={changePage}
                        onNextPage={nextPage}
                        onChangeItemsPerPage={updateItemsPerPage}
                        classes={{
                            prevIcon: 'icon-chevron left',
                            nextIcon: 'icon-chevron right',
                        }}
                        showItemsPerPageSelect
                    />
                </div>
            </DisabledOption>
            {isModalVisible &&
                <SendNotificationsModal
                    isModalVisible={isModalVisible}
                    waitingForNotificationSent={waitingForNotificationSent}
                    sendNotifications={sendNotifications}
                    cancelItemRemoval={cancelItemRemoval}
                />
            }
        </ClientsWrapper>
    );
};

export default ClientsSubmittedList;
