import {getStateRoot, prefix} from "./formReducer.js";
import {services} from "../../../../../RestServices.js";
import FormActions from "../../../../../../src_shared/form/FormActions";
import AssignProductsInReelsModel from "../../../../../../modules/models/reels/AssignProductsModel";

export const getInstance = () => new FormActions({
	getStateRoot,
	prefix,
	model: AssignProductsInReelsModel,
	restService: services.API_ASSIGN_PRODUCTS_TO_REELS,
	route: null,
});

export default getInstance();
