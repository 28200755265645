import React from 'react';
import useLang from "../../../../../../src_shared/hooks/useLang";
import ActivateButton from "./ActivateButton";
import usePhotoPreview from "../../../../../../src_shared/components/ui/photoPreview/usePhotoPreview";
import VideoPreview from "../../../../../../src_shared/components/ui/photoPreview/VideoPreview";

const SelmoProLandingTwoColumnBox = ({label, title, desc, rightText = false, videoSrc = null, imgSrc}) => {
    const {getLangText} = useLang();
    const {showPreviewModal, hidePreviewModal, openPreviewModal} = usePhotoPreview();

    return (
        <div className="sm:px-[100px] sm:py-12 p-6">
            <div className="grid sm:grid-cols-2 gap-8 items-center">
                <div className={rightText ? 'sm:order-2' : ''}>
                    <div className="text-[#5a8bf4] whitespace-pre-wrap uppercase text-[10px] font-bold tracking-[0.83px] mb-2">
                        {getLangText(label)}
                    </div>
                    <div className="text-[#fff] font-bold text-[24px] leading-[30px] sm:text-[32px] sm:leading-[37px] mb-3">
                        {getLangText(title)}
                    </div>
                    <div className="text-[#97979B] font-semibold text-sm leading-[24px]">
                        {getLangText(desc)}
                    </div>
                    <ActivateButton
                        className="mt-3"
                    />
                </div>
                <div className="relative">
                    {videoSrc ?
                        <div
                            className="before:content-[''] before:pointer-events-none before:absolute before:bottom-0 before:top-0 before:left-0 before:right-0 before:bg-[rgba(0,0,0,0.65)]">
                            <img
                                className="cursor-pointer rounded-[15px]"
                                onClick={openPreviewModal}
                                src={imgSrc}
                                alt=""
                            />
                        </div> :
                        <img
                            src={imgSrc}
                            alt=""
                        />
                    }
                    {videoSrc &&
                        <img
                            className="pointer-events-none absolute sm:w-auto sm:h-auto w-[62px] h-[62px] left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 group-hover:scale-105 transition-all duration-200"
                            src="/assets/images/svg/play-white.svg" alt="play" width={84} height={84}
                        />
                    }
                </div>
            </div>
            {videoSrc &&
                <VideoPreview
                    src={videoSrc}
                    hide={hidePreviewModal}
                    show={showPreviewModal}
                    bigSize={true}
                />
            }
        </div>
    );
};

export default SelmoProLandingTwoColumnBox;
