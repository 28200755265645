import React, {useCallback} from "react";
import CheckboxField from "../checkboxField/CheckboxField";
import {useDispatch} from "react-redux";
import ServerImage from "../../../../components/app/shared/components/serverImage/ServerImage";

const Photo = ({photo, margin, direction, top, left, setSelectedGalleryPhoto, selectedPhotos, setModalVisible}) => {
	const imgStyle = {
		margin: margin,
		width: '108px',
		height: '108px',
		objectFit: 'cover',
		borderRadius: '5px',
		cursor: 'grab',
	};
	if (direction === "column") {
		imgStyle.position = "absolute";
		imgStyle.left = left;
		imgStyle.top = top;
	}

	const onClick = (e) => {
		setSelectedGalleryPhoto(photo);
		setModalVisible();
	}

	return (
		<div className="photo gallery-photo">
			<CheckboxField
				showLabel={false}
				name="selectedItems"
				onClick={onClick}
				className="mb-0 position-absolute"
				value={false}
				setValue={() => setSelectedGalleryPhoto(photo)}
			/>
			<CheckboxField
				showLabel={false}
				name="selectedItems"
				onClick={() => window.open(photo.src)}
				className="mb-0 position-absolute download-photo"
				value={false}
				setValue={() => {}}
			/>
			{/*<button*/}
			{/*	className="icon-button remove-icon"*/}
			{/*	onClick={onClick}*/}
			{/*	type="button"*/}
			{/*>*/}
			{/*	<i className="icon-bin" />*/}
			{/*</button>*/}
			<div className="icon-button drag-icon">
				<i className="icon-drag" />
			</div>
			<ServerImage
				style={imgStyle}
				{...photo}
				alt="img"
			/>
		</div>
	);
};

export default Photo;
