import React, {useState} from 'react';
import useLang from "../../../../../../../../src_shared/hooks/useLang";
import PostModal from "./PostModal";

export const RANGE_FULL_ACCOUNT = 'RANGE_FULL_ACCOUNT';
export const RANGE_SELECTED_POST = 'RANGE_SELECTED_POST';

const ranges = [
    {id: RANGE_FULL_ACCOUNT, label: 'fullAccountLabel', description: 'includesAllPostReelsLabel',},
    {id: RANGE_SELECTED_POST, label: 'selectedPostLabel', description: 'codeWorksOnSelectedPostLabel'},
]
const CodeRangeWorking = ({values, setValue, updateValues}) => {
    const {getLangText} = useLang();
    const [isModalVisible, setModalVisible] = useState(false);

    const checkSelectedItemVisibility = (id) => id === RANGE_SELECTED_POST && values.selectedPost && values.codeRange === RANGE_SELECTED_POST

    const onChange = (item) => {
        if (item.id === RANGE_SELECTED_POST) {
            onClick(item);
            return;
        }
        setValue('codeRange', item.id)
    }

    const onClick = (item) => {
        if (item.id === RANGE_SELECTED_POST) {
            setModalVisible(true);
        }
    }

    const onModalSave = (value) => updateValues({
        selectedPost: value,
        codeRange: RANGE_SELECTED_POST
    });

    return (
        <div className="rounded-[8px] border border-[#DFE1E4] p-3">
            <div className="font-bold">{getLangText('selectCodeRangeWorkingLabel')}</div>
            <div className="text-desc font-medium">{getLangText('selectCodeRangeWorkingSubLabel')}</div>
            <div className="data-boxes-wrapper separated-boxes light-version small-padd mt-3">
                {ranges.map((item) => (
                    <div key={item.id}>
                        <div className={`content ${values.codeRange === item.id ? 'active' : ''}`}>
                            <div className={`content__top-part ${item.id === RANGE_SELECTED_POST ? 'pb-default' : ''}`}>
                                <input
                                    id={item.id}
                                    checked={values.codeRange === item.id}
                                    onClick={() => onClick(item)}
                                    onChange={() => onChange(item)}
                                    type="radio"
                                    name="codeRange"
                                    required
                                />
                                <span className="checkmark"/>
                                <div className="flex-grow-1">
                                    <div className="label">
                                        {getLangText(item.label)}
                                    </div>
                                    <div className="data">
                                        {getLangText(item.description)}
                                    </div>
                                </div>
                                {item.id === RANGE_SELECTED_POST &&
                                    <div className="button border-button sm:w-auto sm:mt-0 mt-2.5 sm:static absolute left-5 bottom-3 right-5">
                                        {getLangText('selectPostLabel')}
                                    </div>
                                }
                            </div>
                            {checkSelectedItemVisibility(item.id) &&
                                <div className="p-6 pl-[50px] pt-0">
                                    <div className="w-[80px] h-[80px] rounded-[8px] overflow-hidden">
                                        {/*<ServerImage src="/ase"/>*/}
                                        <img className="w-full h-full object-cover"
                                             src={values.selectedPost?.ig_media_photo}/>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                ))}
            </div>
            {isModalVisible &&
                <PostModal
                    modalVisible={isModalVisible}
                    hideModal={() => setModalVisible(false)}
                    onSave={onModalSave}
                    values={values}
                />
            }
        </div>
    );
};

export default CodeRangeWorking;
