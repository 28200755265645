import React, {useState} from 'react';
import useLang from "../../hooks/useLang";

const ToggleContent = ({children, title, className = ''}) => {

	const {getLangText} = useLang();
	const [isVisible, setIsVisible] = useState(false);

	return (
		<div className={`toggle-content-wrapper ${className}`}>
			<div
				onClick={() => setIsVisible(!isVisible)}
				className={`toggle-header ${isVisible ? 'open' : ''}`}
			>
				<div className="toggle-title">{title}</div>
				<div className="button text-only">
					{isVisible ? getLangText('collapseLabel') : getLangText('expandLabel')}
					<i className="icon-arrows"/>
				</div>
			</div>
			{isVisible &&
				children
			}
		</div>
	);
};

export default ToggleContent;
