import React from 'react';
import OrderProductsForm from "../../../shared/components/form/orderProductsForm/OrderProductsForm";
import FormActions from "./FormActions";
import {getStateRoot} from "./formReducer";
import Loader from "../../../shared/Loader";
import {Modal} from "react-bootstrap";
import useLang from "../../../../../src_shared/hooks/useLang";

const EditProductModal = ({isOpen, waiting, saveOrder, hideModal, waitingForSubmit}) => {
    const {getLangText} = useLang();

    return (
        <Modal
            show={true}
            className="add-order-modal"
            dialogClassName="default-modal"
            backdropClassName={`add-order-modal-backdrop ${isOpen ? 'with-extra-modal' : ''}`}
            onHide={hideModal}
        >
                <Modal.Header className="with-border">
                    <Modal.Title>{getLangText('editProductsLabel')}</Modal.Title>
                    <button
                        onClick={hideModal}
                        type="button"
                        className="button"
                    >
                        <i className="icon-cross"/>
                    </button>
                </Modal.Header>
                <Modal.Body className="add-order-modal-body">
                    <div className="loader-parent">
                        <Loader isLoading={waiting}/>
                        <OrderProductsForm
                            dropdownPortalTarget={document?.querySelector('.add-order-modal')}
                            actions={FormActions}
                            getStateRoot={getStateRoot}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        onClick={hideModal}
                        type="button"
                        className="button border-button"
                    >
                        {getLangText('cancelButton')}
                    </button>
                    <button
                        onClick={saveOrder}
                        type="button"
                        className={`button primary ${waitingForSubmit ? 'loading-button' : ''}`}
                    >
                        {waitingForSubmit && <Loader isLoading/>}
                        {getLangText('saveButton')}
                    </button>
                </Modal.Footer>
        </Modal>
    );
};

export default EditProductModal;
