export const createActions = prefix => ({
    startWaiting: () => ({
        type: `${prefix}START_WAITING`,
    }),
    stopWaiting: () => ({
        type: `${prefix}STOP_WAITING`,
    }),
});

export default {
    createActions,
};
