import React from 'react';
import ProductsListViews from "../shared/enums/ProductsListViews";
import {Link, NavLink, useLocation} from "react-router-dom";
import {Helmet} from "react-helmet";
import useLang from "../../../src_shared/hooks/useLang";
import {useSelector} from "react-redux";
import {RouterPaths} from "../../routes/RouterPath";
import UserPermissionsWrapper from "../../routes/UserPermissionsWrapper";
import {USER_PERMISSIONS_PRODUCTS_EXPENDITURE_TAB_VISIBILITY} from "../shared/enums/UserPermissionsTypes";
import {BUTIK_MADAME_SHOP_ID, CHIQUE_SHOP_ID} from "../../../src_shared/enums/TrustedShopsIds";

const StorageWrapper = ({children, helmetTitle}) => {

    const {pathname} = useLocation();
    const {getLangText} = useLang();
    const {depot_system, id, edit_cart_enabled} = useSelector((state) => state.session.userData);

    return (
        <div className={`settings-page sub-settings-page loader-parent list-with-tabs separated-box`}>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{helmetTitle}</title>
            </Helmet>
            <div className="tabs-header">
                <div className="page-title">
                    {getLangText('asideProductsLabel')}
                </div>
                <div className="tabs-wrapper">
                    {ProductsListViews.map((i) => (
                        <UserPermissionsWrapper allowedPermissions={[i.permission]}>
                            <Link
                                to={i.path}
                                key={i.id}
                                className={pathname === i.path ? 'active' : ''}
                            >
                                {getLangText(i.label)}
                            </Link>
                        </UserPermissionsWrapper>
                    ))}
                    <UserPermissionsWrapper allowedPermissions={[USER_PERMISSIONS_PRODUCTS_EXPENDITURE_TAB_VISIBILITY]}>
                        {(!!+depot_system && +id === BUTIK_MADAME_SHOP_ID) &&
                            <Link
                                to={RouterPaths.ProductsExpendituresList}
                                className={pathname === RouterPaths.ProductsExpendituresList ? 'active' : ''}
                            >
                                {getLangText('expendituresLabel')}
                            </Link>
                        }
                    </UserPermissionsWrapper>
                    {(!!+edit_cart_enabled || +id === CHIQUE_SHOP_ID) &&
                        <NavLink
                            to={RouterPaths.ProductsRemovedList}
                            activeClassName="active"
                        >
                            {getLangText('removedProductsLabel')}
                        </NavLink>
                    }
                </div>
            </div>
            {children}
        </div>
    );
};

export default StorageWrapper;
