import { memo } from 'react';
import isEqual from 'lodash/isEqual';

export const arePropsEqual = (prevProps, nextProps) => Object.keys(prevProps).every((key) => {
    const prevValue = prevProps[key];
    const nextValue = nextProps[key];

    // skip comparing the functions
    if (typeof prevValue === 'function' && typeof nextValue === 'function') {
        return prevValue.toString() === nextValue.toString();
    }

    // compare array and object values
    if (Array.isArray(prevValue) || typeof prevValue === 'object') {
        return isEqual(prevValue, nextValue);
    }

    // compare primitive values
    return prevValue === nextValue;
});

export default (Component) => memo(Component, arePropsEqual);
