import React from 'react';
import {ORDER_PAYMENT_BLIK, ORDER_PAYMENT_TRANSFER} from "../../../enums/PaymentStatuses";
import useLang from "../../../../../../src_shared/hooks/useLang";
import {OUI_OUI_FASHION_SHOP_ID, SCRILU_SHOP_ID} from "../../../../../../src_shared/enums/TrustedShopsIds";

const AdditionalTransferAccounts = ({account, onTransferAccountChange, isPopularBoxActive, values, shopInfo}) => {

    const {getLangText} = useLang();

    return (
        <div
            key={account.id}
            className={`content payment-content with-left-border ${values.payment === `${ORDER_PAYMENT_TRANSFER}_${account.id}` ? 'active' : ''}`}>
            <div className="content__top-part">
                <input
                    checked={values.payment === `${ORDER_PAYMENT_TRANSFER}_${account.id}`}
                    onChange={() => onTransferAccountChange(account.id)}
                    type="radio"
                    required
                    name="payment"/>
                <span className="checkmark"/>
                <div>
                    {(+shopInfo?.id === SCRILU_SHOP_ID || +shopInfo?.id === OUI_OUI_FASHION_SHOP_ID || !!+account.is_custom) ?
                        <div className="label">{account.transfer_account_name}</div> :
                        account.transfer_account_name ?
                            <div className="label">{account.transfer_account_name}
                                -
                                <span style={{textTransform: 'lowercase'}}>&nbsp;{getLangText('paymentMethodTransferLabel')}</span>
                            </div> :
                            <div className="label">{getLangText('paymentMethodTransferLabel')}</div>

                    }
                    {(isPopularBoxActive && !+account.is_custom) &&
                        <div className="data">
                            <div className="popular-text grey">
                                <i className="icon-time"/>
                                {getLangText('paymentWillArriveWithinTwoDaysLabel')}
                            </div>
                        </div>
                    }
                    {!!+account.is_custom &&
                        <div className="data">
                            {getLangText('freeLabel')}
                        </div>
                    }
                </div>
                {(!!+account.is_custom && !!account.transfer_logo) &&
                    <figure className="ml-auto">
                        <img
                            style={{height: '18px', maxWidth: 80, objectFit: 'contain'}}
                            src={account.transfer_logo}
                        />
                    </figure>
                }
            </div>
        </div>
    );
};

export default AdditionalTransferAccounts;
