import Model from "../../../src_shared/modules/model/Model";
import {INPOST_LABEL_CUSTOM, INPOST_LABEL_SMALL} from "../../../components/app/shared/enums/InpostLabelsTypes";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

class InpostLabelModel extends Model {
	getModel() {
		return {
			inpost_size: INPOST_LABEL_SMALL,
			length: '',
			width: '',
			height: '',
			weight: '',
		};
	}

	getValidators() {
		return {
			length: ValidationError.notEmpty,
			width: ValidationError.notEmpty,
			height: ValidationError.notEmpty,
			weight: ValidationError.notEmpty,
		};
	}

	buildDTO(data) {

		const preparedSizes = {
			length: data.length,
			width: data.width,
			height: data.height,
			weight: data.weight,
		}

		if (data.inpost_size === INPOST_LABEL_CUSTOM) {
			return {
				...preparedSizes,
				inpost_size: data.inpost_size,
			}
		}

		return {
			inpost_size: data.inpost_size,

		};
	}
}

export default new InpostLabelModel();
