import { useSelector} from "react-redux";

const useIsPremium = () => {

    const {userData} = useSelector((state) => state.session);

    return {
        isPremium: userData.user_info.status || userData.user_info.isTrial,
        isOnTrial: userData.user_info.isTrial,
    };
};

export default useIsPremium;
