import {combineReducers} from "redux";
import deliveryAddressFormReducer from "./deliveryAddress/formReducer";
import deliveryMethodsFormReducer from "./deliveryMethods/formReducer";
import paymentMethodsFormReducer from "./paymentMethods/formReducer";
import paidProductsListReducer from "./productsSummary/paidProducts/listReducer";
import formReducer from "./page/formReducer";

export default combineReducers({
	deliveryAddress: deliveryAddressFormReducer,
	deliveryMethods: deliveryMethodsFormReducer,
	paymentMethods: paymentMethodsFormReducer,
	paidProductsList: paidProductsListReducer,
	page: formReducer,
});
