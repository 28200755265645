import {getStateRoot, prefix} from "./formReducer.js";
import {services} from "../../../../RestServices.js";
import FormActions from "../../../../../src_shared/form/FormActions";
import {RouterPaths} from "../../../../routes/RouterPath";
import Model from "../../../../../src_shared/modules/model/Model";

export class ManufacturerFormActions extends FormActions {
	afterDeleteSubmit(history) {
		return () => {
			history.push(RouterPaths.ManufacturersList)
		};
	}
}

export const getInstance = () => new ManufacturerFormActions({
	getStateRoot,
	prefix,
	model: new Model(),
	restService: services.API_STORAGE_MANUFACTURERS,
	route: RouterPaths.ManufacturersList
});

export default getInstance();
