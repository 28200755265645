import createReducer from "../../../shared/helpers/createReducer.js";
import Composition from "../../../shared/helpers/Composition.js";
import FormReducer from "../../../../../src_shared/form/FormReducer.js";
import ProductModel from "../../../../../modules/models/ProductModel.js";

export const getStateRoot = (state) => state.products.form.edit;
export const prefix = 'PRODUCTS_FORM_';

const getInitState = () => FormReducer.getInitState(ProductModel.getModel(), {
    uniqueProductCodeError: null,
    selectedPhotos: [],
    openSaveConfirmModal: false,
    automaticReservationListModal: {
        visible: false,
        items: [],
    },
});

const getReduceMap = () => new Composition(
    FormReducer.getReduceMap(),
    {
        SET_UNIQUE_PRODUCT_CODE_ERROR: (state, action) => ({...state, uniqueProductCodeError: action.toggle})
    },
    {
        SET_SELECTED_GALLERY_PHOTO: (state, action) => {
            const {photo} = action;
            return {...state, selectedPhotos: [photo.id]}
        }
    }, {
        SET_SELECTED_GALLERY_PHOTOS: (state, action) => {
            const {photos} = action;
            return {...state, selectedPhotos: photos}
        }
    },
    {
        OPEN_SAVE_CONFIRM_MODAL: (state, action) => {
            return {...state, openSaveConfirmModal: action.toggle}
        }
    },
    {
        OPEN_AUTOMATIC_RESERVE_LIST_MODAL: (state, action) => {
            return {
                ...state,
                automaticReservationListModal: {
                    visible: action.visible,
                    items: action.data,
                }
            }
        }
    },
)

export const getInstance = () => createReducer(
    getInitState(),
    getReduceMap(),
    prefix,
);

export default getInstance();

