import React from 'react';
import AsyncSearchSelectField
    from "../../../../../../src_shared/components/form/selectField/asyncSearchSelectField/AsyncSearchSelectField";
import {services} from "../../../../../RestServices";
import useLang from "../../../../../../src_shared/hooks/useLang";
import {useParams} from "react-router-dom";

const CountryField = ({getFieldProps, values, updateValues}) => {
    const {getLangText} = useLang();
    const {shopName} = useParams();
    const shopId = shopName.split('-')[shopName.split('-').length - 1];
    const formatOptionLabel = (option) => (
        <div style={{display: "flex"}}>
            <div className="mr-1">{option.flag}</div>
            <div>{option.name}</div>
        </div>
    );

    return (
        <AsyncSearchSelectField
            id="custom-dropdown-default"
            {...getFieldProps('country')}
            label={getLangText('countryLabel')}
            rest={`${services.API_CHECKOUT_SHOP_COUNTRIES}?shop_id=${shopId}`}
            formatOptionLabel={formatOptionLabel}
            defaultValue={{
                id: values.country,
                name: values.country_name
            }}
            placeholder={getLangText('countryLabel')}
            setExtraValues={({name, id}) => updateValues({
                country: id,
                country_name: name,
            })}
            className="big-size default-size"
            floatedLabel
            searchOnFocus
            required={true}
        />
    );
};

export default CountryField;