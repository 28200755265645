import createReducer from "../../../shared/helpers/createReducer.js";
import Composition from "../../../shared/helpers/Composition.js";
import FormReducer from "../../../../../src_shared/form/FormReducer.js";
import AssignCustomerModel from "../../../../../modules/models/order/AssignCustomerModel";

export const getStateRoot = (state) => state.orders.details.assignCustomer;
export const prefix = 'ORDERS_DETAILS_ASSIGN_CUSTOMER_MODAL_';

const getInitState = () => FormReducer.getInitState(AssignCustomerModel.getModel());

const getReduceMap = () => new Composition(
	FormReducer.getReduceMap(),
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

