import React, {useState} from 'react';
import axios from "axios";
import {selmoUrl} from "../../../../../src_shared/api/api";
import {services} from "../../../../RestServices";
import Loader from "../../../messenger/loader/Loader";
import {useDispatch} from "react-redux";
import {showAlert} from "../../../shared/components/alert/AlertActions";
import EditOrderFormActions from "../form/FormActions";
import Select, {components} from "react-select";
import StylesLoader from "../../../shared/Loader";
import {
	KOKARDECZKA_SHOP_ID,
	PRZEMO_SHOP_ID,
	TEST_ACCOUNT_SHOP_ID
} from "../../../../../src_shared/enums/TrustedShopsIds";
import useLang from "../../../../../src_shared/hooks/useLang";
import api from "../../../../../services/axios/axios";

const SendVoiceMessage = ({values, inMessenger = false, className = '', menuPlacement = 'top'}) => {

		const [waiting, setWaiting] = useState(false)
		const [waitingForOptions, setWaitingForOptions] = useState(false);
		const {getLangText} = useLang();

		const [data, setData] = useState([]);
		const [selectedAudio, setSelectedAudio] = useState(null);

		const dispatch = useDispatch();
		const sendEmail = async () => {
			setWaiting(true)
			try {
				await api.post(`${selmoUrl}/${services.API_ORDER_AUDIO_MESSAGE}`, {
					recipient: values.customer_facebook_id,
					message_id: selectedAudio
				})
				dispatch(showAlert('sentMessageVoiceLabel', ''))
				dispatch(EditOrderFormActions.loadData(values.shop_order_id))

			} catch (e) {
				console.warn('Error send email')
				dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
			} finally {
				setWaiting(false)
			}
		}

		const getAudioMessages = async () => {
			setWaitingForOptions(true)
			try {
				const response = await api.get(`${selmoUrl}/${services.API_ORDER_AUDIO_MESSAGE}`)
				setData(response.data.item);

			} catch (e) {
				console.warn('Error get audio types')
				dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
			} finally {
				setWaitingForOptions(false);

			}
		}

		const preparedOptions = data.map((i) => ({
			label: i.name,
			value: i.id,
		}))

		if (+values.shop_id !== TEST_ACCOUNT_SHOP_ID && +values.shop_id !== PRZEMO_SHOP_ID && +values.shop_id !== KOKARDECZKA_SHOP_ID) {
			return null;
		}

		if (!values.customer_facebook_id) {
			return null;
		}

		const NoOptionsMessage = props => {
			return (
				<components.NoOptionsMessage {...props}>
					{waitingForOptions ? <StylesLoader className="mt-2 static-loader" isLoading={true}/> :
						<span className="custom-css-class">{getLangText('noDefinedMessageVoices')}</span>
					}
				</components.NoOptionsMessage>
			);
		};

		return (
			<section className={`summary-section email-status-wrapper ${className}`}>
				<h2>{getLangText('sendVoiceMessageLabel')}</h2>
				<Select
					id="dropdown-default"
					defaultValue={{value: null, label: getLangText('selectVoiceMessageLabel')}}
					options={waitingForOptions ? [] : preparedOptions}
					onFocus={getAudioMessages}
					onChange={(item) => setSelectedAudio(item.value)}
					hideSelectedOptions={false}
					isSearchable={false}
					components={{
						NoOptionsMessage,
					}}
					menuPlacement={menuPlacement}
				/>
				<div className="mt-3">
					<div className="tooltip-parent">
						{!selectedAudio &&
							<div className="tooltip-content">
								{getLangText('selectVoiceMessageLabel')}
							</div>
						}
						<button
							disabled={!selectedAudio}
							type="button"
							onClick={sendEmail}
							className={`button border-button w-100 ${waiting ? 'loading-button' : ''}`}
						>
							{waiting && <Loader/>}
							{getLangText('sendVoiceMessageLabel')}
						</button>
					</div>
				</div>
			</section>
		);
	}
;

export default SendVoiceMessage;
