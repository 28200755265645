import Model from "../../../src_shared/modules/model/Model";
import {createNewId} from "../../../components/app/live/details/default/liveDetailsReducer";

export const newCommentReplyItem = {
    id: createNewId(), shelf_3: '', shelf_2: '', shelf_1: ''
}
class ShelvesModel extends Model {
    getModel() {
        return {
            row: [newCommentReplyItem],
        };
    }

    buildDTO(data) {
        return data.row;
    }
}

export default new ShelvesModel();
