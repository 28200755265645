import React from 'react';
import useLang from "../../../../src_shared/hooks/useLang";
import useSubmitData from "../../../../src_shared/hooks/useSubmitData";
import {services} from "../../../RestServices";
import Modal from "react-bootstrap/Modal";
import Loader from "../../messenger/loader/Loader";

const CancelStatusConfirmModal = ({modalVisible, hideModal, orderId, fetchData, afterSubmit = () => {}}) => {

    const {getLangText} = useLang();

    const [{onSave, isWaiting}] = useSubmitData({
        rest: `${services.API_CANCEL_ORDER}/${orderId}`,
        afterSubmit: async () => {
            await fetchData()
            hideModal();
            afterSubmit();
        },
    });

    return (
        <Modal
            className="higher-index"
            dialogClassName={`default-modal w-420`}
            show={modalVisible}
            onHide={hideModal}
        >
            <Modal.Header className="align-items-start">
                <Modal.Title>
                    {getLangText('removeProductsFromOrderLabel')}
                    <div className="text-desc text-sm font-medium mb-4">
                        {getLangText('askForRemoveProductsFromOrderLabel')}
                    </div>
                </Modal.Title>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            <Modal.Footer>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button border-button px-3"
                    style={{width: 'auto'}}
                >
                    {getLangText('noRemoveProductsLabel')}
                </button>
                <button
                    onClick={onSave}
                    type="button"
                    style={{width: 'auto'}}
                    className={`button primary px-3 ${isWaiting ? 'loading-button' : ''}`}
                >
                    {isWaiting && <Loader/>}
                    {getLangText('yesRemoveProductsLabel')}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default CancelStatusConfirmModal;
