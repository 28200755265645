import Model from "../../src_shared/modules/model/Model";

export class VariantsSquModalModel extends Model {
	getModel() {
		return {
			sizes: [],
		};
	}

	buildDTO(data) {
		return data.sizes.filter((i) => +i.new_squ > 0).map((y) => ({
			id: y.id,
			old_squ: y.squ,
			new_squ: y.new_squ,
		}));
	}
}

export default new VariantsSquModalModel();
