import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import {RouterPaths} from "../../RouterPath";
import MobileReelsList from "../../../app/products/mobileReels/list/List";
import MobileReelsForm from "../../../app/products/mobileReels/form/Form";
import TestRoute from "../../TestRoute";

const MobileReelsRoute = ({path}) => {

	return (
		<TestRoute path={path}>
			<Switch>
				<Route
					exact
					path={RouterPaths.MobileReelsList}
					component={MobileReelsList}
				/>
				<Route
					exact
					path={RouterPaths.MobileReelsForm}
					component={MobileReelsForm}
				/>
				<Redirect to={RouterPaths.MobileReelsList}/>
			</Switch>
		</TestRoute>
	);
};

export default MobileReelsRoute;
