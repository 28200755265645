import Model from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

export class NoteModel extends Model {
	getModel() {
		return {
			tracking_link: '',
			note: '',
			tags: [],
		};
	}

	getValidators() {
		return {
			tracking_link: ValidationError.skip,
			note: ValidationError.skip,
			tags: ValidationError.skip,
		};
	}

	buildDTO(data) {
		return {
			tracking_link: data.tracking_link,
			note: data.note,
			tags: data.tags,
		};
	}
}

export default new NoteModel();
