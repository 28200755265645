import Model from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

class SettingsProductWrongCodeTemplatesModel extends Model {
	getModel() {
		return {
			wrong_code_1: '',
			wrong_code_2: '',
			wrong_code_3: '',
		};
	}

	getValidators() {
		return {
			wrong_code_1: ValidationError.notEmpty,
			wrong_code_2: ValidationError.notEmpty,
			wrong_code_3: ValidationError.notEmpty,
		};
	}

	buildDTO = (data) => {
		const filteredData = Object.entries(data)
			.filter(([key, value]) => !!value && key.startsWith('wrong_code_'))
			.sort(([keyA], [keyB]) => Number(keyA.split('_')[2]) - Number(keyB.split('_')[2]));

		return filteredData.reduce((acc, [_, value], index) => {
			acc[`wrong_code_${index + 1}`] = value;
			return acc;
		}, {});
	};
}

export default new SettingsProductWrongCodeTemplatesModel();
