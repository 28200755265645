export const initState = {
	page: 1,
	itemsPerPage: 25,
	totalItems: 0,
};

export const reduceMap = {
    PAGINATION_CHANGE_PAGE: (state, action) => {
        if (action.page == null) {
            console.error('listReducer error: action '
                + 'LIST_CHANGE_PAGE has invalid data!');
            console.info(action);
            return state;
        }
        return {...state, page: action.page};
    },
    PAGINATION_UPDATE_TOTAL_ITEMS: (state, action) => {
        if (action.total === null) {
            console.error('listReducer error: action '
                + 'LIST_UPDATE_TOTAL_ITEMS has invalid data!');
            console.info(action);
            return state;
        }
		return {...state, totalItems: action.total};
    },
    PAGINATION_SET_ITEM_PER_PAGE: (state, action) => {
        if (action.itemsPerPage == null) {
            console.error(`listReducer error: action 
                SET_ITEM_PER_PAGE has invalid data!`);
            console.info(action);
            return state;
        }
		return {...state, itemsPerPage: action.itemsPerPage};
    },
};

export default {
    initState,
    reduceMap,
};
