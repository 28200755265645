import createReducer from "../../../../shared/helpers/createReducer.js";
import Composition from "../../../../shared/helpers/Composition.js";
import FormReducer from "../../../../../../src_shared/form/FormReducer.js";
import AssignProductsInReelsModel from "../../../../../../modules/models/reels/AssignProductsModel";

export const getStateRoot = (state) => state.products.reels.assignProducts;
export const prefix = 'REELS_ASSIGN_PRODUCTS_MODAL_FORM_';

const getInitState = () => FormReducer.getInitState(AssignProductsInReelsModel.getModel());

const getReduceMap = () => new Composition(
	FormReducer.getReduceMap(),
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

