import Model, {convertObjectProperties} from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

class SettingsShippingModel extends Model {
	getModel() {
		return {
			shippingList: [],
			countriesWithoutShipping: [],
			name: '',
			price: '',
			is_inpost: '0',
			is_mondial: '0',
			is_orlen: '0',
			is_dpd: '0',
			is_gls: '0',
			active: '0',
			country: null,
			weight_to: '0'
		};
	}

	getValidators() {
		return {
			name: ValidationError.notEmpty,
			price: ValidationError.notEmpty,
			is_inpost: ValidationError.skip,
			is_mondial: ValidationError.skip,
			is_orlen: ValidationError.skip,
			is_dpd: ValidationError.skip,
			is_gls: ValidationError.skip,
		};
	}

	parseDTO(data) {
		return convertObjectProperties(
			data,
			{
			},
			{
				name: '',
				price: '',
				is_inpost: '0',
				is_mondial: '0',
				is_orlen: '0',
				is_dpd: '0',
				is_gls: '0',
				country: data.countries && data.countries[0]?.id,
				countriesWithoutShipping: [],
			},
		);
	}

	buildDTO(data) {
		const {name, price, is_inpost, is_orlen, id, active, country, is_mondial, weight_to, is_dpd, is_gls} = data;

		if (id) {
			return {
				name,
				price,
				is_inpost,
				is_orlen,
				is_mondial,
				is_dpd,
				is_gls,
				active,
				country,
				weight_to,
			}
		}

		return {
			name,
			price,
			is_inpost,
			is_orlen,
			is_mondial,
			is_dpd,
			is_gls,
			country,
			weight_to,
		}
	}
}

export default new SettingsShippingModel();
