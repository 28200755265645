import React, {useEffect, useState} from "react";
import api from "../../services/axios/axios";
import {getGETParamsUrl} from "../list/ListActions";

const useScrollList = (config) => {
    const {
        rest = "",
        initialData = [],
        initialAutoLoad = true,
        params = {},
        afterSubmit = () => {
        },
        onError = (e) => {
            // setIsError(true);
            // setErrorMessage(e?.response?.data?.message);
        },
        isLoadingDefault = true,
        responseFieldName = 'items',
        simpleArray = false,
    } = config;

    const [page, setPage] = useState(1);
    const [data, setData] = useState(initialData);
    const [isLoading, setIsLoading] = useState(isLoadingDefault);
    const [isNewPageLoading, setIsNewPageLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [total, setTotal] = useState(0);
    const [refreshing, setRefreshing] = useState(false);
    const [extraData, setExtraData] = useState({});

    const fetchData = async (search) => {
        setIsLoading(true);

        if (typeof search === "string") {
            params.superSearch = search;
        }

        try {
            const {data} = await api.get(`${rest}${getGETParamsUrl({page, ...params})}`);
            if (simpleArray) {
                setData((prevData) => page === 1 ? data.items : [...prevData, ...data.items]);
            } else {
                setData((prevData) => page === 1 ? data.item[responseFieldName] : [...prevData, ...data.item[responseFieldName]]);
            }

            setHasMore(data.hasMore)
            setTotal(data?.total)
            setExtraData(data.item);
            // if (!!+data.hasMore) {
            setPage(page + 1);
            // }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setRefreshing(false)
            setIsLoading(false)
        }
    };

    const getNewPage = async () => {
        // if (isNewPageLoading || isLoading || !hasMore) return;
        if (isNewPageLoading || isLoading) return;
        setIsNewPageLoading(true);
        try {
            const {data} = await api.get(`${rest}${getGETParamsUrl({page, ...params})}`);
            if (simpleArray) {
                setData((prevData) => [...prevData, ...data.items]);
            } else {
                setData((prevData) => [...prevData, ...data.item[responseFieldName]]);
            }
            setHasMore(data.hasMore || data?.item?.hasMore)
            setExtraData(data.item);
            // if (!!+data.hasMore) {
            setPage(page + 1);
            // }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsNewPageLoading(false)
        }
    };

    useEffect(() => {
        if (page === 1 && initialAutoLoad) {
            fetchData()
        }
    }, [page, initialAutoLoad])

    return {
        data,
        page,
        setPage,
        setData,
        isLoading,
        setIsLoading,
        isNewPageLoading,
        hasMore,
        refreshing,
        total,
        setTotal,
        extraData,
        getNewPage,
        fetchData,
    }
};

export default useScrollList;
