import React, {memo} from 'react';
import useLang from "../../../../../src_shared/hooks/useLang";
import {Link, NavLink} from "react-router-dom";
import {RouterPaths} from "../../../../routes/RouterPath";
import {Helmet} from "react-helmet";

const ShippyProWrapper = ({children}) => {
    const {getLangText} = useLang()

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>ShippyPro - Selmo</title>
            </Helmet>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <Link to={RouterPaths.Settings}>{getLangText('asideSettingsLabel')}</Link>
                    </li>
                    <li className="active">
                        <div>ShippyPro</div>
                    </li>
                </ul>
            </div>
            <div className="row small-padd-row">
                <div className="col-lg-7">
                    <div className="settings-page sub-settings-page export-sub-settings">
                        <div className="header-wrapper no-border pb-0">
                            <h1>
                                <img style={{width: 'auto', height: '20px'}} src="/assets/images/shippypro.png"
                                     alt="ShippyPro"/>
                            </h1>
                            <div className="description">
                                {getLangText('realizeOrdersFasterLabel')}
                            </div>
                        </div>
                        <div className="tabs-wrapper">
                            <NavLink
                                exact
                                activeClassName="active"
                                to={RouterPaths.SettingsIntegrationShippyPro}
                            >
                                {getLangText('configurationLabel')}
                            </NavLink>
                            <NavLink
                                activeClassName="active"
                                to={RouterPaths.SettingsIntegrationShippyProData}
                            >
                                {getLangText('senderDataLabel')}
                            </NavLink>
                            <NavLink
                                activeClassName="active"
                                to={RouterPaths.SettingsIntegrationShippyProTemplates}
                            >
                                {getLangText('templatesLabel')}
                            </NavLink>
                        </div>
                        {children}
                    </div>
                </div>
                <div className="col-md-5 mt-md-0 mt-3">
                    <div className="default-shadow-box bigger-y-padd payment-benefits-wrapper">
                        <div className="label">{getLangText('paymentsHowItWorksLabel')}</div>
                        <ul className="benefits-list">
                            <li>
                                <i className="icon-tick-c"/>
                                {getLangText('oneCLickGenerateLabelsLabel')}
                            </li>
                            <li>
                                <i className="icon-tick-c"/>
                                {getLangText('accessToMoreCarriersLabel')}
                            </li>
                            <li>
                                <i className="icon-tick-c"/>
                                {getLangText('automaticTrackingLinksLabel')}
                            </li>
                            <li>
                                <i className="icon-tick-c"/>
                                {getLangText('saveTimeLabel')}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default memo(ShippyProWrapper);
