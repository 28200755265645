import React from 'react';
import Modal from "react-bootstrap/Modal";
import Loader from "../../../../shared/Loader";
import useLang from "../../../../../../src_shared/hooks/useLang";
import Summary from "./Summary";

const ModalWithSuccessPage = ({
								  show,
								  hide,
								  isWaiting,
								  title,
								  SubTitle,
								  saveButton,
								  onSubmit,
								  successGenerated,
								  generatedCount,
								  children,
								  summaryContent,
								  submitButtonClass = 'primary',
								  ListActions,
							  }) => {
	const {getLangText} = useLang();

	return (
		<Modal
			dialogClassName="default-modal small-modal"
			show={show}
			onHide={hide}
		>
			{successGenerated ?
				<Summary
					waitingForDocument={isWaiting}
					hideModal={hide}
					generatedOrdersCount={generatedCount}
					onPrint={() => {
					}}
					labels={false}
					summaryContent={summaryContent}
					ListActions={ListActions}
				/> :
				<>
					<Modal.Header>
						<Modal.Title>
							{title}
							<div className="description">
								{SubTitle && <SubTitle/>}
							</div>
						</Modal.Title>
						<button
							onClick={hide}
							type="button"
							className="button"
						>
							<i className="icon-cross"/>
						</button>
					</Modal.Header>
					<Modal.Body>
						{children}
					</Modal.Body>
					<Modal.Footer>
						<button
							onClick={hide}
							type="button"
							className="button border-button"
						>
							{getLangText('cancelButton')}
						</button>
						<button
							onClick={onSubmit}
							type="button"
							className={`button ${submitButtonClass} px-0 ${isWaiting ? 'loading-button' : ''}`}
						>
							{isWaiting && <Loader isLoading/>}
							{saveButton}
						</button>
					</Modal.Footer>
				</>
			}
		</Modal>
	);
};

export default ModalWithSuccessPage;
