import React from 'react';
import ConfirmModal from "../../../../../orders/list/components/export/ConfirmModal";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import Modal from "react-bootstrap/Modal";
import Loader from "../../../../../shared/Loader";
import Field from "../../../../../../../src_shared/components/form/field/Field";
import useFormLocal from "../../../../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../../../../RestServices";
import useFetchData from "../../../../../../../src_shared/hooks/useFetchData";
import {filesSetModalVisibility} from "../../../../../shared/header/files/filesDropdown/FilesActions";
import {useDispatch} from "react-redux";
import PrintSelectedVariantsModel from "../../../../../../../modules/models/products/PrintSelectedVariantsModel";

const PrintSelectedVariantsModal = ({modalVisible, hideModal, id}) => {
    const {getLangText} = useLang();
    const dispatch = useDispatch();

    const [{
        onSave,
        isWaiting,
        getFieldProps,
        updateValues,
        values,
        setNestedValue
    }] = useFormLocal({
        rest: `${services.API_STORAGE_PRINT_PRODUCT_SIZES_QR_CODES}/${id}`,
        model: PrintSelectedVariantsModel,
        initialAutoLoad: false,
        afterSubmit: () => {
            dispatch(filesSetModalVisibility(true));
            hideModal();
        }
    });

    const [{isLoading}] = useFetchData({
        rest: `${services.API_STORAGE_PRODUCT_SIZE_STOCK}/${id}`,
        afterSubmit: updateValues
    });

    return (
        <Modal
            dialogClassName="default-modal"
            show={modalVisible}
            onHide={hideModal}
        >
            <Modal.Header className="with-border">
                <Modal.Title>
                    {getLangText('printSelectedQrCodes')}
                </Modal.Title>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            <div className="loader-parent">
                <Loader isLoading={isLoading}/>
                <Modal.Body>
                    {values?.sizes?.map((size) => (
                        <div
                            key={size.id}
                            className="grid grid-cols-3 gap-2 sm:gap-4"
                        >
                            <div className="col-span-2">
                                <Field
                                    {...getFieldProps('size')}
                                    value={size.size}
                                    label={getLangText('variantNameLabel')}
                                    placeholder={getLangText('variantExampleLabel')}
                                    disabled
                                />
                            </div>
                            <Field
                                {...getFieldProps('squ')}
                                value={size.squ}
                                label={getLangText('quantityLabel')}
                                type="number"
                                setValue={(field, value) => setNestedValue('sizes', size.id, field, value)}
                            />
                        </div>
                    ))}
                </Modal.Body>
                <Modal.Footer className="justify-content-start">
                    <button
                        type="button"
                        className="button border-button ml-auto"
                        onClick={hideModal}
                    >
                        {getLangText('cancelButton')}
                    </button>
                    <button
                        type="button"
                        className={`button primary ${isWaiting ? 'loading-button' : ''}`}
                        onClick={onSave}
                    >
                        {isWaiting && <Loader isLoading/>}
                        {getLangText('printButton')}
                    </button>
                </Modal.Footer>
            </div>
        </Modal>
    );
};

export default PrintSelectedVariantsModal;
