import React from 'react';
import Pagination from "../../../shared/components/pagination/Pagination";
import useLang from "../../../../../src_shared/hooks/useLang";
import Table from "./Table";
import SearchInput from "../../../shared/components/form/SearchInput";
import DateRangePicker from "../../../shared/components/form/DateRangePicker";

const InteractionsTable = ({
                               updateSortOptions,
                               isWaiting,
                               getSortClass,
                               items,
                               pagination,
                               search,
                               ListActions,
                               getFiltersStateRoot,
                               FiltersActions
                           }) => {
    const {getLangText} = useLang();

    return (
        <div className="table-list-wrapper">
            <div className="default-shadow-box pb-0">
                <div className="table-header">
                    <div className="top-part flex-sm-nowrap flex-wrap">
                        <h1 className="title mb-sm-0 mb-2">
                            {getLangText('interactionsLabel')}
                            <div className="desc">{getLangText('dataUpdatedEveryOneHourLabel')}</div>
                        </h1>
                        <SearchInput
                            placeholder={getLangText('searchPlaceholder')}
                            search={search}
                            className="sm:ml-auto sm:mr-2"
                        />
                        <DateRangePicker
                            actions={ListActions}
                            getFiltersStateRoot={getFiltersStateRoot}
                            FiltersActions={FiltersActions}
                        />
                    </div>
                </div>
                <Table
                    isWaiting={isWaiting}
                    items={items}
                    updateSortOptions={updateSortOptions}
                    getSortClass={getSortClass}
                />
            </div>
            <Pagination
                pages={pagination.getPagination()}
                items={items}
                itemsPerPage={pagination.itemsPerPage}
                getLangText={getLangText}
                totalItems={pagination.totalItems}
                onPrevPage={pagination.prevPage}
                onChangePage={pagination.changePage}
                onNextPage={pagination.nextPage}
                onChangeItemsPerPage={pagination.updateItemsPerPage}
                classes={{
                    prevIcon: 'icon-chevron left',
                    nextIcon: 'icon-chevron right',
                }}
                showItemsPerPageSelect
            />
        </div>
    );
};

export default InteractionsTable;
