import React, {useState} from 'react';
import useRemoveData from "../../../../../../../src_shared/hooks/useRemoveData";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import useSubmitData from "../../../../../../../src_shared/hooks/useSubmitData";
import {services} from "../../../../../../RestServices";
import {useDispatch} from "react-redux";
import {showAlert} from "../../../../../shared/components/alert/AlertActions";
import ReturnProductModal from "./ReturnProductModal";
import FormActions from "../../../form/FormActions";

const ReturnProduct = ({product, id, shopOrderId}) => {
    const {getLangText} = useLang();
    const dispatch = useDispatch();
    const [quantity, setQuantity] = useState(1);
    const [returnSqu, setReturnSqu] = useState('1');

    const loadData = () => dispatch(FormActions.loadData(shopOrderId));

    const [{
        openModal: openRemoveModal,
        hideModal: hideRemoveModal,
        confirmModalVisible,
        itemToRemove,
    }] = useRemoveData({
        rest: '',
    });

    const afterSubmit = () => {
        hideRemoveModal();
        dispatch(showAlert('yuhuLabel', 'successfullySentEmail'))
        loadData();
    }

    const [{onSave, isWaiting}] = useSubmitData(
        {
            rest: `${services.API_RETURN_ORDER_PRODUCT}/${id}`,
            afterSubmit,
            values: {
                id: itemToRemove.id,
                quantity,
                return_squ: returnSqu
            }
        });

    return (
        <>
            <button
                type="button"
                style={{paddingTop: 5, paddingBottom: 5}}
                onClick={() => openRemoveModal(product)}
                className="button border-button left-icon ml-2 mt-[6px]"
            >
                {getLangText('refund')}
            </button>
            {confirmModalVisible &&
                <ReturnProductModal
                    isWaiting={isWaiting}
                    setQuantity={setQuantity}
                    itemToRemove={itemToRemove}
                    confirmModalVisible={confirmModalVisible}
                    hideRemoveModal={hideRemoveModal}
                    onSave={onSave}
                    quantity={quantity}
                    setReturnSqu={setReturnSqu}
                    returnSqu={returnSqu}
                />
            }

        </>
    );
};

export default ReturnProduct;
