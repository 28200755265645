import {getStateRoot, prefix} from "./formReducer.js";
import {services} from "../../../RestServices.js";
import DomainModel from "../../../../modules/models/shopSettings/DomainModel";
import {SharedShopSettingsActions} from "../shared/SharedShopSettingsActions";

export const getInstance = () => new SharedShopSettingsActions({
	getStateRoot,
	prefix,
	model: DomainModel,
	restService: services.API_STORE_DOMAIN,
});

export default getInstance();
