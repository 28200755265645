import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../routes/RouterPath";
import {convertToUserTimeZone} from "../../helpers/dateHelpers";
import {useSelector} from "react-redux";
import {useCountdown} from "../../../../../src_shared/hooks/useCountDown";
import moment from "moment";
import {SHOP_ORIGIN_PL} from "../../enums/ShopOrigins";
import {getFromLS, saveToLS} from "../../../../../src_shared/helpers/LS";

const RedTopBar = () => {
    const {origin} = useSelector((state) => state.session.userData);
    const newDate = convertToUserTimeZone(moment('30.10.2024 23:59:59', 'DD.MM.YYYY HH:mm:ss').toDate()).toDate()
    const todayDate = convertToUserTimeZone(moment(new Date(), 'DD.MM.YYYY HH:mm:ss').toDate()).toDate()
    const {hours, minutes, seconds, days} = useCountdown(newDate);

    const countDownVisible = newDate >= todayDate

    const isHidden = getFromLS('dpdPromoHidden')
    const [hidden, setHidden] = useState(false);

    useEffect(() => {
        saveToLS('dpdPromoHidden', hidden);
    }, [hidden])

    useEffect(() => {
        if (isHidden) {
            setHidden(true)
        }
    }, [isHidden])


    const countBox = (time) => (
        <div
            className="text-center w-[20px] h-[16px] flex items-center justify-center text-[#fff] rounded-sm bg-[rgba(255,255,255,0.35)]">
            <div className="text-xs font-bold">
                {time}
            </div>
        </div>
    )

    if (hidden) return null;

    if (origin !== SHOP_ORIGIN_PL) return null;

    if (!countDownVisible) return null

    return (
        <Link
            to={RouterPaths.DPDPromo}
            className="relative selmo-app-bar w-full bg-gradient-to-l from-[#DE1639] to-[#C81239] sm:flex-wrap flex text-[#fff] items-center font-semibold text-xs sm:justify-center min-h-[40px] py-1 px-2 sm:px-6 hover:text-[#fff] hover:no-underline group">
            <div className="sm:flex items-center sm:grow-0 grow">
                <div className="sm:flex hidden items-center space-x-0.5 mr-1">
                    {!!+days && <>
                        {countBox(days)}
                        <div>:</div>
                    </>}
                    {countBox(hours)}
                    <div>:</div>
                    {countBox(minutes)}
                    <>
                        <div>:</div>
                        {countBox(seconds)}

                    </>
                </div>
                <span className="sm:block hidden">do końca promocji! Generuj etykiety DPD za 5 zł</span>
                <span className="sm:hidden">Generuj etykiety DPD za 5 zł</span>
            </div>
            <div
                className={`bg-[#fff] relative button white px-[8px] py-[3px] font-bold rounded-[5px] whitespace-nowrap ml-2 text-[#C81239] group-hover:text-red-700 transition-all`}
            >
                Skorzystaj z oferty
            </div>
            <button
                type="button"
                className="ml-auto absolute right-4 top-1/2 -translate-y-1/2"
                onClick={() => setHidden(true)}
            >
                <i className="icon-cross"/>
            </button>
        </Link>
    );
};

export default RedTopBar;
