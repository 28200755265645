import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import CountDownNotificationActions from "./CountDownNotificationActions";
import {
    ADD_NEW_ORDER_NOTIFICATION,
    ADD_NEW_PRODUCT_NOTIFICATION,
    GO_TO_ORDER_NOTIFICATION, PUSHER_NOTIFICATION_NEW_VERSION, PUSHER_SINGLE_NOTIFICATION
} from "../../enums/CountDownNotificationsTypes";
import EditProductModalFormActions from "../../../products/modalForm/FormActions";
import {getStateRoot} from "./countDownNotificationReducer";
import AddOrderFormActions from "../../../orders/form/FormActions";
import _ from "lodash";
import useLang from "../../../../../src_shared/hooks/useLang";

const ExtraNotification = () => {
    const dispatch = useDispatch();
    const {isVisible, type, data} = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot.notification));
    const {getLangText} = useLang();

    const hideBox = () => dispatch(CountDownNotificationActions.setNotificationVisibility(false, null));

    const onTimeoutHideBox = () => {
        if (type === PUSHER_NOTIFICATION_NEW_VERSION || type === PUSHER_SINGLE_NOTIFICATION) {
            return;
        }
        dispatch(CountDownNotificationActions.setNotificationVisibility(false, null))
    };

    const showAddNewProductModal = () => {
        hideBox();
        dispatch(EditProductModalFormActions.modal.showModal());
    }

    const showAddNewOrderModal = () => {
        hideBox();
        dispatch(AddOrderFormActions.modal.showModal());
    }


    useEffect(() => {
        const intervalHideBox = setTimeout(() => onTimeoutHideBox(), 7000);

        return () => clearTimeout(intervalHideBox);
    }, [isVisible]);

    if (!isVisible) {
        return null;
    }

    return (
        <div className="fixed-notification-wrapper">
            {type === ADD_NEW_ORDER_NOTIFICATION &&
                <div className="fixed-notification">
                    <button
                        className="icon-button close-btn"
                        type="button"
                        onClick={hideBox}
                    >
                        <i className="icon-cross"/>
                    </button>
                    <div>
                        <div className="title">{getLangText('takeShortcutsLabel')}</div>
                        <div className="desc">
                            {getLangText('askToAddNewOrderLabel')}
                            <button
                                className="button primary small-size mt-3"
                                onClick={showAddNewOrderModal}
                                type="button"
                            >
                                {getLangText('addNextOrderLabel')}
                            </button>
                        </div>
                    </div>
                    <div className="progress-bar">
                        <div/>
                    </div>
                </div>
            }
            {type === ADD_NEW_PRODUCT_NOTIFICATION &&
                <div className="fixed-notification">
                    <button
                        className="icon-button close-btn"
                        type="button"
                        onClick={hideBox}
                    >
                        <i className="icon-cross"/>
                    </button>
                    <div>
                        <div className="title">{getLangText('takeShortcutsLabel')}</div>
                        <div className="desc">
                            {getLangText('askToAddNewProductLabel')}
                            <button
                                className="button primary small-size mt-3"
                                onClick={showAddNewProductModal}
                                type="button"
                            >
                                {getLangText('addNextProductLabel')}
                            </button>
                        </div>
                    </div>
                    <div className="progress-bar">
                        <div/>
                    </div>
                </div>
            }
            {type === GO_TO_ORDER_NOTIFICATION &&
                <div className="fixed-notification">
                    <button
                        className="icon-button close-btn"
                        type="button"
                        onClick={hideBox}
                    >
                        <i className="icon-cross"/>
                    </button>
                    <div>
                        <div className="title">{getLangText('takeShortcutsLabel')}</div>
                        <div className="desc">
                            {getLangText('askToMoveToNewOrderLabel')}
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href={`/zamowienia/${data.shop_order_id}`}
                                className="button primary small-size mt-3"
                            >
                                {getLangText('goToOrderButton')}
                            </a>
                        </div>
                    </div>
                    <div className="progress-bar">
                        <div/>
                    </div>
                </div>
            }
            {type === PUSHER_NOTIFICATION_NEW_VERSION &&
                <div className="fixed-notification">
                    <button
                        className="icon-button close-btn"
                        type="button"
                        onClick={hideBox}
                    >
                        <i className="icon-cross"/>
                    </button>
                    {_.isEmpty(data) &&
                        <div>
                            <div className="title">{getLangText('newVersionAvailableTitle')}</div>
                            <div className="desc">
                                {getLangText('newVersionAvailableDescription')}
                                <button
                                    type="button"
                                    onClick={() => window.location.reload()}
                                    className="button primary small-size mt-3"
                                >
                                    {getLangText('refreshPageLabel')}
                                </button>
                            </div>
                        </div>
                    }
                </div>
            }
            {type === PUSHER_SINGLE_NOTIFICATION &&
                <div className="fixed-notification">
                    <button
                        className="icon-button close-btn"
                        type="button"
                        onClick={hideBox}
                    >
                        <i className="icon-cross"/>
                    </button>
                    <div>
                        <div className="title">{data.title}</div>
                        <div className="desc">
                            {data.description}
                        </div>
                        <button
                            type="button"
                            onClick={() => window.location.reload()}
                            className="button primary small-size mt-3"
                        >
                            {getLangText('refreshPageLabel')}
                        </button>
                    </div>
                </div>
            }
        </div>
    );
};

export default ExtraNotification;
