import React, {useCallback, useRef, useState} from 'react';
import {OverlayTrigger} from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import {getProductCount} from "../../../messenger/addOrderModal/components/hintDropdown.jsx";
import useOutsideClick from "../../../messenger/hooks/useOutsideClick/useOutsideClick.js";
import {updateFieldInLiveDetails} from "../../details/default/LiveDetailsActions.js";
import debounce from "lodash.debounce";
import {selmoUrl} from "../../../../../src_shared/api/api.js";
import {services} from "../../../../RestServices.js";
import {useDispatch, useSelector} from "react-redux";
import ProductDropdown from "./ProductDropdown.jsx";
import optimizedMemo from "../../../../../src_shared/helpers/optimizedMemo";
import useLang from "../../../../../src_shared/hooks/useLang";
import api from "../../../../../services/axios/axios";
import ServerImage from "../../../shared/components/serverImage/ServerImage";
import moment from "moment";
import {convertToUserTimeZone} from "../../../shared/helpers/dateHelpers";

const ProductSearchInput = ({
								product,
								onNameChange,
								onHintSelect,
								removeProduct,
								className,
								tabIndex,
								disabled,
								inputValue,
								label,
								isSelectedProduct,
								autoFocus = true,
								isOrderCreated,
								rest = services.API_STORAGE_PRODUCTS
							}) => {
	const dispatch = useDispatch();
	const {getLangText} = useLang();

	const productHintsRef = useRef();
	const {storageProducts, liveInfo} = useSelector((state) => state.lives.details)
	const [visibleDropdown, setVisibleDropdown] = useState(false);

	const getProductsHints = useCallback(
		debounce(async (value) => {
			if (value) {
				try {
					const {data} = await api.get(`${selmoUrl}/${rest}?search=${value}`)
					dispatch(updateFieldInLiveDetails('storageProducts', data));
				} catch (e) {
					console.warn('Cannot get storage products')
				}
			}
		}, 500), []);

	const onProductNameChange = (e) => {
		if (+liveInfo.storage_products) {
			setVisibleDropdown(true);
			getProductsHints(e.target.value)
		}
		onNameChange(e)
	}

	useOutsideClick(productHintsRef, () => {
		setVisibleDropdown(false);
		if (storageProducts.length) {
			dispatch(updateFieldInLiveDetails('storageProducts', []));
		}
	});

	return (
		<div className="product-input-col">
			<div
				ref={productHintsRef}
				className={`${className}`}
			>
				<label className="control-label">{label}</label>
				<input
					onChange={onProductNameChange}
					type="text"
					autoFocus={autoFocus}
					value={inputValue}
					className={`form-control ${isOrderCreated ? 'order-created' : ''}`}
					name={`product_name_${convertToUserTimeZone().unix()}`}
					required
					tabIndex={tabIndex}
					disabled={disabled}
				/>
				{!isOrderCreated && !!isSelectedProduct &&
					<OverlayTrigger
						placement='top'
						overlay={
							<Tooltip id="remove-product">
								{getLangText('removeProductLabel')}
							</Tooltip>
						}
					>
						<button
							type="button"
							className="remove-product"
							onClick={removeProduct}
						>
							<i className="icon-cross"/>
						</button>
					</OverlayTrigger>
				}
				{!!isSelectedProduct &&
					<>
						<figure>
							{product.photo && product.photo !== '0' ?
								<ServerImage src={product.photo}/> :
								<img src="/assets/images/default/product.svg"/>
							}
						</figure>
						<div className="small-label">
							{getProductCount(product, getLangText)}
							{product.code && ` • ${getLangText('codeLabel')}: ${product.code}`}

						</div>
					</>
				}
				<ProductDropdown
					visibleDropdown={visibleDropdown}
					onHintSelect={onHintSelect}
					storageProducts={storageProducts}
				/>
			</div>
		</div>
	);
};

export default optimizedMemo(ProductSearchInput);
