import {RouterPaths} from "../../../routes/RouterPath";
import {
	USER_PERMISSIONS_PRODUCTS_BOUGHT_TAB_VISIBILITY,
	USER_PERMISSIONS_PRODUCTS_MENU_VISIBILITY,
	USER_PERMISSIONS_PRODUCTS_RESERVE_LIST_TAB_VISIBILITY
} from "./UserPermissionsTypes";

export const PRODUCTS_LIST_VIEW = 'PRODUCTS_LIST_VIEW';
export const LATEST_PRODUCTS_VIEW = 'LATEST_PRODUCTS_VIEW';
export const RESERVE_PRODUCTS_VIEW = 'RESERVE_PRODUCTS_VIEW';

const ProductsListViews = [
	{
		id: PRODUCTS_LIST_VIEW,
		label: 'allLabel',
		path: RouterPaths.ProductsList,
		permission: USER_PERMISSIONS_PRODUCTS_MENU_VISIBILITY,
	},
	{
		id: LATEST_PRODUCTS_VIEW,
		label: 'boughtLabel',
		path: RouterPaths.ProductsLatestList,
		permission: USER_PERMISSIONS_PRODUCTS_BOUGHT_TAB_VISIBILITY,
	},
	{
		id: RESERVE_PRODUCTS_VIEW,
		label: 'reserveListLabel',
		path: RouterPaths.ProductsReserveList,
		permission: USER_PERMISSIONS_PRODUCTS_RESERVE_LIST_TAB_VISIBILITY,
	},
];

export default ProductsListViews;
