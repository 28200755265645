import ListActions, {getGETParamsUrl} from "../../../../src_shared/list/ListActions";
import {getStateRoot, prefix} from './listReducer';
import {getStateRoot as getFiltersStateRoot} from '../filters/filtersReducer'
import {convertDate} from "../../shared/helpers/dateHelpers";
import history from "../../../history";
import {ORDER_PACKED_FILTER_ALL} from "../../shared/enums/PackedStatuses";
import {selmoUrl} from "../../../../src_shared/api/api";
import {ORDER_PRODUCTS_PAID_FILTER_ALL} from "../../shared/enums/PaidStatuses";
import {RouterPaths} from "../../../routes/RouterPath";
import {services} from "../../../RestServices";
import {batch} from "react-redux";
import SessionActions from "../../shared/session/SessionActions";
import CheckboxMultiselectActions from "../../../../src_shared/list/checkboxMultiselect/CheckboxMultiselectActions";
import {showAlert} from "../../shared/components/alert/AlertActions";
import {ORDER_LABEL_ALL} from "../../shared/enums/OrdersLabelStatuses";
import {ORDER_INVOICE_ALL} from "../../shared/enums/OrdersInvoiceStatuses";
import api from "../../../../services/axios/axios";

export class OrdersListActions extends ListActions {
    constructor(config) {
        super(config);
        this.selectedItems = new CheckboxMultiselectActions({
            prefix: this.prefix,
            name: 'selectedItems',
        });
    }

    getLoadParams(state) {
        const params = super.getLoadParams(state);
        const statuses = getFiltersStateRoot(state).data.statuses;
        const tag = getFiltersStateRoot(state).data.tag;
        const discounts = getFiltersStateRoot(state).data.discounts;
        const country = getFiltersStateRoot(state).data.country;
        const payments = getFiltersStateRoot(state).data.payments;
        const shipping = getFiltersStateRoot(state).data.shipping;
        const isPacked = getFiltersStateRoot(state).data.isPacked;
        const isPaid = getFiltersStateRoot(state).data.isPaid;
        const label = getFiltersStateRoot(state).data.label;
        const invoice = getFiltersStateRoot(state).data.invoice;
        const mr_automate_paused = getFiltersStateRoot(state).data.mr_automate_paused;
        const dateFrom = getFiltersStateRoot(state).data.fromDate
        const dateTo = getFiltersStateRoot(state).data.toDate
        const priceFrom = getFiltersStateRoot(state).data.priceFrom;
        const priceTo = getFiltersStateRoot(state).data.priceTo;
        const productsFrom = getFiltersStateRoot(state).data.productsFrom;
        const productsTo = getFiltersStateRoot(state).data.productsTo;
        const productType = getFiltersStateRoot(state).data.productType;

        if (dateFrom) {
            params.dateFrom = convertDate(dateFrom);
        }

        if (dateTo) {
            params.dateTo = convertDate(dateTo);
        }

        if (isPacked && isPacked !== ORDER_PACKED_FILTER_ALL) {
            params.isPacked = isPacked
        }

        if (isPaid && isPaid !== ORDER_PRODUCTS_PAID_FILTER_ALL) {
            params.products_paid = isPaid
        }

        if (label && label !== ORDER_LABEL_ALL) {
            params.label = label
        }

        if (invoice && invoice !== ORDER_INVOICE_ALL) {
            params.invoice = invoice
        }

        if (mr_automate_paused && mr_automate_paused !== 'all') {
            params.mr_automate_paused = mr_automate_paused
        }

        if (statuses.length) {
            params['status[]'] = statuses
        }

        if (tag.length) {
            params['tag[]'] = tag
        }

        if (discounts.length) {
            params['discounts[]'] = discounts
        }

        if (country.length) {
            params['country[]'] = country
        }

        if (payments.length) {
            params['payment[]'] = payments
        }

        if (shipping.length) {
            params['shipping[]'] = shipping
        }

        if (productType.length) {
            params['productType[]'] = productType
        }

        if (priceFrom) {
            params.priceFrom = priceFrom;
        }

        if (priceTo) {
            params.priceTo = priceTo;
        }

        if (productsFrom) {
            params.productsFrom = productsFrom;
        }

        if (productsTo) {
            params.productsTo = productsTo;
        }

        history.replace({
            pathname: this.pathname,
            search: getGETParamsUrl(params)
        });

        return params;
    }

    setOrderPackingStatus(item) {
        return async (dispatch) => {
            const id = item.shop_order_id;

            const isPacked = item.is_packed;
            const oppositePacked = isPacked === '1' ? '0' : '1';

            try {
                await api.post(`${selmoUrl}/api/orderPacked`, {
                    shop_order_id: id,
                    is_packed: isPacked,
                })
                dispatch(this.updateValueInItem(item.id, 'is_packed', oppositePacked));
            } catch (e) {
                dispatch(this.updateValueInItem(item.id, 'is_packed', isPacked));
                console.error(e)
            }
        }
    }

    setWaitingForDocument(toggle) {
        return {
            type: `${this.prefix}SET_WAITING_FOR_DOCUMENT`,
            toggle,
        }
    }

    setSuccessGenerateView(toggle) {
        return {
            type: `${this.prefix}SET_SUCCESS_GENERATE_VIEW`,
            toggle,
        }
    }

    setGenerateOrdersCount(ordersCount) {
        return {
            type: `${this.prefix}SET_GENERATED_ORDERS_COUNT`,
            ordersCount,
        }
    }

    setGenerateLabelTemplateError(toggle) {
        return {
            type: `${this.prefix}SET_GENERATE_LABEL_TEMPLATE_ERROR`,
            toggle,
        }
    }


    afterGenerate(item, withLoad = true) {
        return async (dispatch) => {
            batch(() => {
                if (withLoad) dispatch(this.loadData());
                dispatch(this.setGenerateOrdersCount(item))
                dispatch(this.setSuccessGenerateView(true));
            })
        }
    }

    generateLabels(values, type) {
        return async (dispatch, getState) => {
            const {selectedItems} = getStateRoot(getState());
            if (!values.template) {
                dispatch(this.setGenerateLabelTemplateError(true));
                return;
            }
            dispatch(this.setWaitingForDocument(true))
            try {
                const {data} = await api.post(`${selmoUrl}/${services.API_BATCH_LABELS}`, {
                    orders: selectedItems.map((i) => i.id),
                    ...values,
                    type,
                })
                dispatch(this.afterGenerate(data.item))
            } catch (e) {
                dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
                console.error(e)
            } finally {
                dispatch(this.setWaitingForDocument(false))
            }
        }
    }

    generateInvoice() {
        return async (dispatch, getState) => {
            const {selectedItems} = getStateRoot(getState());
            dispatch(this.setWaitingForDocument(true))
            try {
                const {data} = await api.post(`${selmoUrl}/${services.API_BATCH_INVOICES}`, {
                    orders: selectedItems.map((i) => i.id),
                })
                dispatch(this.afterGenerate(data.item))
            } catch (e) {
                dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
                console.error(e)
            } finally {
                dispatch(this.setWaitingForDocument(false))
            }
        }
    }

    setModalVisible(modalType) {
        return {
            type: `${this.prefix}SET_DOCUMENT_MODAL_VISIBLE`,
            modalType,
        }
    }

    printDocuments(rest) {
        const windowReference = window.open();

        return async (dispatch, getState) => {
            const {selectedItems} = getStateRoot(getState());
            dispatch(this.setWaitingForDocument(true))
            try {
                const {data} = await api.post(`${selmoUrl}/${rest}`, {
                    orders: selectedItems.map((i) => i.id),
                })

                windowReference.location = data.item;

                batch(() => {
                    dispatch(this.setModalVisible(null))
                    dispatch(this.loadData())
                })
            } catch (e) {
                if (e.response.data.message === 'not ready') {
                    dispatch(showAlert('labelIsGeneratingLabel', 'tryAgainInWhileLabel', 'alert-info'))
                    return;
                }
                dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
                console.error(e)
            } finally {
                dispatch(this.setWaitingForDocument(false))
            }
        }
    }

    setSelectedTableRow(row) {
        return {
            type: `${this.prefix}SET_SELECTED_TABLE_ROW`,
            row,
        }
    }

    setHasTags(hasTags) {
        return {
            type: `${this.prefix}SET_HAS_TAGS`,
            hasTags,
        }
    }

    loadData(id, signal) {
        return async (dispatch, getState) => {
            const {firstInit} = getState().session;
            const {itemsPerPage, sortBy} = getStateRoot(getState());
            dispatch(this.waiting.startWaiting())
            if (firstInit) {
                dispatch(this.onFirstInit());
            }
            const params = this.getLoadParams(getState(), id)

            const restUrl = +id ? `/${id}${getGETParamsUrl(params)}` : getGETParamsUrl(params);
            try {
                const response = await api.get(`${selmoUrl}/${this.restService}${restUrl}`, {signal})
                const {items, total, hasTags, isFirstStepPage} = response.data;
                if (+total <= +itemsPerPage && this.defaultSortBy) {
                    items.sort((a, b) => parseFloat(b[this.defaultSortBy]) - parseFloat(a[this.defaultSortBy]))
                }
                if (!sortBy) {
                    // dispatch(this.table.updateSort(this.defaultSortBy, 'desc'));
                }
                batch(() => {
                    dispatch(this.table.updateAllItems(items))
                    dispatch(this.setFirstStepPage(isFirstStepPage))
                    dispatch(this.pagination.updateTotalItems(total))
                    dispatch(this.setHasTags(hasTags))
                })
            } catch (e) {
                dispatch(this.table.getListError(e))
                console.error(e)
            } finally {
                if (firstInit) {
                    dispatch(SessionActions.setFirstInit());
                }
                dispatch(this.waiting.stopWaiting())
            }
        }
    }

}

export const getInstance = () => new OrdersListActions({
    getStateRoot,
    restService: services.API_ORDERS,
    pathname: RouterPaths.OrdersList,
    prefix,
    defaultSortBy: 'shop_order_id',
});

export default getInstance();
