import React, {useEffect, useMemo, useState} from 'react';
import SwitchField from "../../../../../../src_shared/components/form/switchField/SwitchField";
import useLang from "../../../../../../src_shared/hooks/useLang";
import Permission from "./Permission";

const Group = ({values, group, updateValues}) => {
    const {getLangText} = useLang();
    const [isOpen, setIsOpen] = useState(false);

    const selectedGroup = useMemo(() => values.selectedOptions?.find((option) => option.id === group.id) || {}, [values.selectedOptions]);
    const isAllPermissionsSelected = useMemo(() => group.permissions?.some((permission) => selectedGroup.permissions?.find((selectedPermission) => selectedPermission.id === permission.id)), [group.permissions, selectedGroup.permissions])

    const setValue = () => updateValues({
        selectedOptions: values.selectedOptions.map((option) => option.id === group.id ? {
            ...option,
            permissions: isAllPermissionsSelected ? [] : group.permissions
        } : option)
    })

    const toggleOpen = () => {
        setIsOpen(!isOpen)
    }

    useEffect(() => {
        if (!!selectedGroup.permissions?.length) {
            setIsOpen(true);
        }
    }, [selectedGroup.permissions?.length])


    return (
        <div className="rounded-[8px] border border-[#DFE1E4] p-3 pt-[18px]">
            <div
                className="flex w-full items-center"
            >
                <SwitchField
                    name="add_button"
                    setValue={setValue}
                    value={isAllPermissionsSelected}
                    className="switch-form inline-label big-label blue-version mb-0"
                    label={getLangText(group.name)}
                />
                <button
                    onClick={toggleOpen}
                    type="button"
                    className="flex ml-auto items-center grow justify-end"
                >
                    <div
                        className="text-xs font-bold text-[#6B7280] ">{selectedGroup.permissions?.length}/{group.permissions?.length}</div>
                    <i className="icon-arrows text-desc text-[8px] ml-2"/>
                </button>
            </div>
            {isOpen &&
                <div className="animate-tutorialSlideAndFadeIn mt-2 space-y-3 pl-[42px]">
                    {group.permissions.map((permission) => (
                        <Permission
                            setIsOpen={setIsOpen}
                            group={group}
                            selectedGroup={selectedGroup}
                            key={permission.id}
                            permission={permission}
                            updateValues={updateValues}
                            values={values}
                        />
                    ))}
                </div>
            }
        </div>
    );
};

export default Group;
