import {getStateRoot, prefix} from "./formReducer.js";
import {services} from "../../../../RestServices.js";
import FormActions from "../../../../../src_shared/form/FormActions";
import CategoryModel from "../../../../../modules/models/category/CategoryModel";
import {batch} from "react-redux";
import {showAlert} from "../../../shared/components/alert/AlertActions";
import ListActions from "../list/ListActions";
import {RouterPaths} from "../../../../routes/RouterPath";

export class EditCategoryFormActions extends FormActions {
	afterSubmit(id) {
		return (dispatch) => {
			batch(() => {
				dispatch(showAlert())
				dispatch(this.modal.hideModal())
				dispatch(ListActions.loadData())
			})
		}
	}
}

export const getInstance = () => new EditCategoryFormActions({
	getStateRoot,
	prefix,
	model: CategoryModel,
	restService: services.API_CATEGORIES,
	route: RouterPaths.CategoriesList
});

export default getInstance();
