import React from 'react';
import SwitchField from "../../../../../src_shared/components/form/switchField/SwitchField";
import useLang from "../../../../../src_shared/hooks/useLang";
import {services} from "../../../../RestServices";
import FileUpload from "../../../../../src_shared/components/form/fileUpload/FileUpload";

const DigitalFile = ({getFieldProps, setValue, inModal, values, updateValues}) => {
    const {getLangText} = useLang();

    return (
        <section>
            <div className="form-col">
                <SwitchField
                    {...getFieldProps('digital')}
                    setValue={setValue}
                    label={getLangText('checkIfDigitalProductLabel')}
                    subLabel={getLangText('checkIfDigitalProductSubLabel')}
                    id="digital"
                    className="switch-form inline-label big-label light-version"
                />
                {!!+values.digital &&
                    <FileUpload
                        values={values}
                        updateValues={updateValues}
                        validation={false}
                        showLabel={false}
                        internalLabel="insertDigitalFileLabel"
                        restService={services.API_STORAGE_DIGITAL_PRODUCT}
                    />
                }
            </div>
        </section>
    );
};

export default DigitalFile;
