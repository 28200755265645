import Model, {convertObjectProperties} from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

class SettingsShippingCountryModel extends Model {
	getModel() {
		return {
			// name: '',
			active: '0',
		};
	}

	getValidators() {
		return {
			country_id: ValidationError.notEmpty,
			country_name: ValidationError.notEmpty,
			active: ValidationError.skip,
		};
	}

	parseDTO(data) {
		return convertObjectProperties(
			data,
			{},
			{
				// name: '',
				active: '0',
			},
		);
	}

	buildDTO(data) {
		const {country_name, country_id, id, active} = data;

		const preparedCountry = () => {
			if (!!country_id) {
				return {country_id};
			} else {
				return {country_name}
			}
		}

		if (id) {
			return {
				active,
				...preparedCountry(),
			}
		}

		return {
			...preparedCountry(),
		}
	}
}

export default new SettingsShippingCountryModel();
