import React, {useState} from 'react';
import Faq from "../landing/components/Faq/Faq";
import useLang from "../../../../src_shared/hooks/useLang";
import {questions} from "../landing/components/Faq/FaqSection";

const FaqSection = () => {
    const {getLangText} = useLang();
    const [isActive, setActive] = useState(0);
    return (
        <div className="default-shadow-box bigger-y-padd mb-3">
            <h2 className="font-bold mb-3.5">{getLangText('faqPageTitle')}</h2>
            <Faq
                smallSize={true}
                setActive={setActive}
                isActive={isActive}
                questions={questions}
            />
        </div>
    );
};

export default FaqSection;
