export const createActions = (prefix) => ({
    setValue: (field, value) => ({
        type: `${prefix}DATA_SET_VALUE`,
        field,
        value,
    }),
	setNestedValue: (parent, id, field, value) => ({
        type: `${prefix}DATA_SET_NESTED_VALUE`,
		parent,
		id,
        field,
        value,
    }),
    updateValues: (data) => ({
        type: `${prefix}DATA_UPDATE_VALUES`,
        data,
    }),
    updateEmptyValues: (data) => ({
        type: `${prefix}DATA_UPDATE_EMPTY_VALUES`,
        data,
    }),
    restoreEmptyData: () => ({
        type: `${prefix}DATA_RESTORE_EMPTY`,
    }),
    setSaved: () => ({
        type: `${prefix}DATA_SET_SAVED`,
    }),
    setDataPreloaded: (preloaded) => ({
        type: `${prefix}DATA_SET_PRELOADED`,
        preloaded,
    }),
});

export default {
    createActions,
};
