import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {addOrderModalToggleVisibility
} from "./AddOrderModalActions";
import {setActiveConversation, updateFieldInItem} from "../chatList/ChatListActions";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import NewAddOrderModalActions from "./NewAddOrderModalActions";
import {selmoUrl} from "../../../../src_shared/api/api";
import OrderModal from "./modal/Modal";
import useLang from "../../../../src_shared/hooks/useLang";
import api from "../../../../services/axios/axios";

const AddOrderModal = ({activeConversation, lastId, setStatusVisible, messageScrollRef}) => {

	const dispatch = useDispatch();
	const {getLangText} = useLang();

	const [name, setName] = useState(activeConversation.user_name);

	const getOrderProducts = (id, viewType) => dispatch(NewAddOrderModalActions.getOrderProducts(id, activeConversation, viewType));
	const {backUrl, isOpen} = useSelector((state) => state.messenger.addOrderModal);

	useEffect(() => {
		setName(activeConversation.user_name)
	}, [activeConversation])

	const createOrder = async () => {
		dispatch(addOrderModalToggleVisibility(true));
		await api.post(`${selmoUrl}/api/order`, {
			name,
			customer_facebook_id: activeConversation.user_id,
			customer_conversation_id: activeConversation.id,
			facebook_page_id: activeConversation.page_id,

		})
			.then((res) => {
				getOrderProducts(res.data.message.current_order_id)
				dispatch(setActiveConversation(res.data.message))
				dispatch(updateFieldInItem('orders_count', res.data.message.orders_count, res.data.message.id))
			})
			.catch((e) => {
				console.log(e)
			})

	}

	const onBasketClick = (id) => {
		dispatch(addOrderModalToggleVisibility(true));
		getOrderProducts(id)
	}

	return (
		<>
			<OverlayTrigger
				placement='bottom'
				overlay={<Tooltip id="full-screen">{getLangText('createOrderButton')}</Tooltip>}
			>
				<button
					type="button"
					className="orders-number"
					onClick={createOrder}
				>
					<i className="icon-plus"/>
					<div className="lg:hidden text-xs font-bold ml-2">{getLangText('createOrderButton')}</div>
				</button>
			</OverlayTrigger>
			{activeConversation.current_order_id &&
				<OverlayTrigger
					placement='bottom'
					overlay={<Tooltip id="full-screen">{getLangText('basketLabel')}</Tooltip>}
				>
					<button
						type="button"
						className="orders-number blue"
						onClick={() => onBasketClick(activeConversation.current_order_id)}
					>
						<i className="icon-cart"/>
						<div className="lg:hidden text-xs font-bold ml-2">{getLangText('basketLabel')}</div>
					</button>
				</OverlayTrigger>
			}
			{isOpen &&
				<OrderModal
					lastId={lastId}
					setStatusVisible={setStatusVisible}
					messageScrollRef={messageScrollRef}
					getOrderProducts={getOrderProducts}
					activeConversation={activeConversation}
					backUrl={backUrl}
					isOpen={isOpen}
				/>
			}
		</>
	);
};

export default AddOrderModal;
