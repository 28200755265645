import {useState, useEffect} from 'react';
import {batch, useDispatch, useSelector} from 'react-redux';
import {createSelector} from 'reselect';

export default (actions, getFiltersStateRoot, filterActions, field, selectAllField, filtersLength,) => {
	const dispatch = useDispatch();

	const [statuses, selectAll] = useSelector(createSelector(getFiltersStateRoot, (stateRoot) => [
		stateRoot.data[field],
		stateRoot.data[selectAllField],
	]));

	const [statusFilter, setStatusFilter] = useState([]);
	const [isSelectAll, setIsSelectAll] = useState(selectAll);
	const isAllSelected = statusFilter.length === filtersLength;

	const resetHoursAvailabilities = () => {
		batch(() => {
			dispatch(filterActions.data.setValue(selectAllField, true));
			dispatch(filterActions.data.setValue(field, []));
		});
	};

	const saveFilters = (id) => {
		if (isAllSelected) {
			resetHoursAvailabilities();
		} else {
			batch(() => {
				dispatch(filterActions.data.setValue(selectAllField, isSelectAll));
				dispatch(filterActions.data.setValue(field, statusFilter));
			});
		}
		if (id) {
			dispatch(actions.loadPage(1, id));
		} else {
			dispatch(actions.loadPage(1));
		}
	};

	const filterButtonTitle = () => {
		if (selectAll) {
			return {
				text: 'Status',
				className: '',
			};
		}
		return {
			text: `Filtry <div class="badge"><span>${statuses.length}</span></div>`,
			className: 'filtered',
		};
	};

	const handleCheckBoxClick = (field) => {
		setIsSelectAll(false);
		const existed = statusFilter.find((i) => i === field);
		if (existed) {
			const filteredHourAvailabilities = statusFilter.filter((i) => i !== field);
			setStatusFilter(filteredHourAvailabilities);
			if (filteredHourAvailabilities.length === 0) {
				setIsSelectAll(true);
			}
		} else {
			setIsSelectAll(false);
			setStatusFilter([
				...statusFilter,
				field,
			]);
		}
	};

	const allItemsClick = () => {
		if (!isSelectAll) {
			setIsSelectAll(true);
			setStatusFilter([]);
		}
	};

	const updateLocallyStatusesState = (status, allSelect) => {
		setStatusFilter(status);
		setIsSelectAll(allSelect);
	};

	const getCheckboxValue = (name) => !!statusFilter.find((status) => status == name);

	useEffect(() => {
		setIsSelectAll(selectAll);
		setStatusFilter(statuses);
	}, [statuses]);

	return {
		statuses,
		selectAll,
		isSelectAll,
		saveFilters,
		filterButtonTitle,
		handleCheckBoxClick,
		allItemsClick,
		updateLocallyStatusesState,
		getCheckboxValue,
		isAllSelected,
		locallyStatusFilter: statusFilter,
	};
};
