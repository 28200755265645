import {prefix, getStateRoot} from "./formReducer";
import FormActions from "../../../../../../src_shared/form/FormActions";
import {services} from "../../../../../RestServices";
import ClientPaymentReceivedModel from "../../../../../../modules/models/ClientPaymentReceivedModel";
import InfoClientFormActions from "../DataActions";
import EditOrderFormActions from "../../../../orders/details/form/FormActions";
import {getStateRoot as getEditOrderStateRoot} from "../../../../orders/details/form/formReducer";
import {showAlert} from "../../../../shared/components/alert/AlertActions";
import axios from "axios";
import {selmoUrl} from "../../../../../../src_shared/api/api";
import api from "../../../../../../services/axios/axios";

export class ClientPaymentReceivedFormActions extends FormActions {
	submitEditForm(values, history) {
		return async (dispatch, getState) => {
			const {id} = values;
			const {data} = getStateRoot(getState());

			dispatch(this.validation.activateValidation());

			if (!this.dataModel.validateData(values)) {
				dispatch(showAlert('fillRequireFieldWarning', '', 'alert-fail'))
				console.error('Validation failed!');
				return;
			}

			const filteredData = Object.entries(values).filter(([key]) => key !== 'id');
			const finalData = Object.fromEntries(filteredData)

			const preparedData = this.dataModel.buildDTO(finalData)

			dispatch(this.waiting.startWaiting());

			try {
				if (id) {
					await api.put(`${selmoUrl}/${this.restService}/${id}`, preparedData)
					dispatch(this.afterSubmit(id))
				} else {
					const {data} = await api.post(`${selmoUrl}/${this.restService}`, preparedData)
					dispatch(this.afterSubmit(data.id, data))
					this.updateRoute(data, history)
				}
				dispatch(this.validation.deactivateValidation())
				if (!data.editPaymentReceived) {
					return;
				}
				dispatch(showAlert())
			} catch (e) {
				dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
				console.error('Error submitEditForm')
			} finally {
				dispatch(this.waiting.stopWaiting());
			}
		}
	}

	afterSubmit(id) {
		return (dispatch, getState) => {
			const {shop_order_id} = getEditOrderStateRoot(getState()).data;
			const {data} = getStateRoot(getState());
			dispatch(this.modal.hideModal());

			if (shop_order_id) {
				dispatch(EditOrderFormActions.loadData(shop_order_id))
			} else {
				dispatch(InfoClientFormActions.loadData(id))
			}
			if (!data.editPaymentReceived) {
				dispatch(this.showSendVoiceMessageModal(true));
			} else {
				dispatch(this.data.setValue('new_payment_received', ''))
			}
		}
	}

	showSendVoiceMessageModal(toggle) {
		return {
			type: `${this.prefix}SHOW_SEND_VOICE_MESSAGE_MODAL`,
			toggle,
		}
	}

	waitingForSendingMessage(toggle) {
		return {
			type: `${this.prefix}WAITING_FOR_SENDING_MESSAGE`,
			toggle,
		}
	}

	setNewPaymentAndSendMessage(value) {
		return async (dispatch, getState) => {
			const {new_payment_received, id} = getStateRoot(getState()).data;

			dispatch(this.waitingForSendingMessage(true));

			try {
				await api.post(`${selmoUrl}/${services.API_CLIENTS_PAYMENTS_AUDIO}/${id}`, {
					new_payment_received,
					message: value,
				})
				dispatch(this.showSendVoiceMessageModal(false));
				dispatch(showAlert('successfulSentMessageLabel', ''))
				dispatch(this.data.setValue('new_payment_received', ''))

			} catch (e) {
				dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
				console.error('Error setNewPaymentAndSendMessage')
			} finally {
				dispatch(this.waitingForSendingMessage(false));
			}
		}
	}
}

export const getInstance = () => new ClientPaymentReceivedFormActions({
	prefix,
	getStateRoot,
	restService: services.API_CLIENTS_PAYMENTS_RECEIVED,
	model: ClientPaymentReceivedModel,
});

export default getInstance();

