import React from 'react';
import useFormLocal from "../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../RestServices";
import useLang from "../../../../src_shared/hooks/useLang";
import SwitchField from "../../../../src_shared/components/form/switchField/SwitchField";
import Loader from "../../../app/shared/Loader";
import ComponentLoader from "../../../app/shared/components/componentLoader/ComponentLoader";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import Field from "../../../../src_shared/components/form/field/Field";
import {useSelector} from "react-redux";
import SettingsShopPickupModel from "../../../../modules/models/settings/SettingsShopPickupModel";
import {Helmet} from "react-helmet";

const ShopPickup = () => {
	const {userData} = useSelector((state) => state.session);
	const {getLangText} = useLang();
	const [{
		onSave,
		isWaiting,
		getFieldProps,
		isLoading,
	}] = useFormLocal({
		rest: services.API_SETTINGS_PAYMENT_SHOP_PICKUP,
		model: SettingsShopPickupModel,
	});

	if (isLoading) return <ComponentLoader/>;

	return (
		<>
			<Helmet>
				<meta charSet="utf-8"/>
				<title>{getLangText('settings_payments_shop_pickup_title')} - Selmo</title>
			</Helmet>
			<div className="header-bar breadcrumbs-in-react-app">
				<ul className="breadcrumbs">
					<li>
						<Link to={RouterPaths.Settings}>
							{getLangText('asideSettingsLabel')}
						</Link>
					</li>
					<li>
						<Link to={RouterPaths.SettingsPayments}>
							{getLangText('paymentsLabel')}
						</Link>
					</li>
					<li className="active">
						<div>{getLangText('settings_payments_shop_pickup_title')}</div>
					</li>
				</ul>
				<Link
					to={RouterPaths.SettingsPayments}
					className="button back-to-panels mb-3">
					<i className="icon-arrow-left"/>
					{getLangText('button_back_to_payment')}
				</Link>
			</div>
			<div className="settings-page sub-settings-page">
				<div className="header-wrapper">
					<h1>
						{getLangText('settings_payments_shop_pickup_title')}
					</h1>
					<div className="description">
						{getLangText('settings_payments_shop_pickup_subtitle')}
					</div>
				</div>
				<div className="settings-form">
					<div className="d-flex align-items-center flex-sm-nowrap flex-wrap">
						<Field
							{...getFieldProps('shop_pickup_address')}
							className="mr-3"
							label={getLangText('input_label_shop_address')}
							style={{minWidth: '275px'}}
						/>
						<Field
							{...getFieldProps('shop_pickup_price')}
							className="hidden-arrows mr-3 small-width"
							label={getLangText('input_label_price')}
							addon={userData.currency_info.text}
							type="number"
						/>
						<SwitchField
							{...getFieldProps('shop_pickup_active')}
							label={getLangText('active_payment')}
							className="switch-form inline-label align-items-start big-label light-version mb-0 mt-1 mr-3"

						/>
						<button
							type="button"
							onClick={onSave}
							className={`button primary w-sm-auto mt-sm-0 mt-3 ${isWaiting ? 'loading-button' : ''}`}
						>
							<Loader isLoading={isWaiting}/>
							{getLangText('saveButton')}
						</button>
					</div>
					<div className="form-info-box w-100">
						<div>
							<i className="icon-info-c"/>
						</div>
						<div>
							<div className="title">{getLangText('settings_payments_pickup_info_1')}</div>
							<div className="desc">
								{getLangText('settings_payments_pickup_info_2')}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ShopPickup;
