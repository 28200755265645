import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import ListActions from "../../../../ListActions";
import Loader from "../../../../../../shared/Loader";
import useLang from "../../../../../../../../src_shared/hooks/useLang";
import useFetchData from "../../../../../../../../src_shared/hooks/useFetchData";
import {services} from "../../../../../../../RestServices";

const Summary = ({
                     hideModal,
                     generatedOrdersCount,
                     waitingForDocument,
                     onPrint,
                     labels = true,
                     summaryContent,
                     withCloseIcon = true,
                     className = ''
                 }) => {

    const dispatch = useDispatch();
    const {getLangText} = useLang();

    const [{fetchData: checkIsLabelReady}] = useFetchData({
        rest: `${services.API_PACKAGE_BATCH_LABEL_STATUS}/${generatedOrdersCount.log_id}`,
        initialAutoLoad: false,
        afterSubmit: (response) => dispatch(ListActions.afterGenerate(response)),
        onError: () => {},
    })

    useEffect(() => {
        let intervalId;

        if (generatedOrdersCount.waiting_for_labels) {
            intervalId = setInterval(() => {
                checkIsLabelReady();
            }, 4000);
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [generatedOrdersCount.waiting_for_labels, checkIsLabelReady]);

    useEffect(() => {
        return () => dispatch(ListActions.setSuccessGenerateView(false));
    }, [])

    return (
        <div className={`summary-generated-view ${className}`}>
            {withCloseIcon &&
                <button
                    onClick={hideModal}
                    type="button"
                    className="button close-modal"
                >
                    <i className="icon-cross"/>
                </button>
            }
            {summaryContent(generatedOrdersCount, hideModal, waitingForDocument, labels, onPrint, getLangText)}
        </div>
    );
};

Summary.defaultProps = {
    summaryContent: (generatedCount, hideModal, waiting, labels, onPrint, getLangText) => {

        if (generatedCount.waiting_for_labels) {
            return (
                <div className="flex items-center justify-center flex-col">
                    <Loader
                        isLoading={true}
                        className="w-[12px] static-loader small-loader mt-[6px] transform-none"
                    />
                    <div className="font-bold text-sm mt-3">
                        {getLangText('generatingFiles')}
                    </div>
                    <div className="text-desc text-xs font-medium">
                        {getLangText('itMakeTakeAWhile')}
                    </div>
                </div>
            )
        }

        return (
            !!+generatedCount?.success_orders ?
                <>
                    <div className="circle-icon">
                        <i className="icon-tick"/>
                    </div>
                    <div className="title">Gotowe!</div>
                    <div className="desc">
                        Udało się Ci się
                        wygenerować <strong>{generatedCount?.success_orders}/{generatedCount?.all_orders}</strong> {labels ? 'etykiet' : 'faktur'}
                    </div>
                    <div className="modal-footer">
                        <button
                            onClick={onPrint}
                            type="button"
                            className={`button primary ${waiting ? 'loading-button' : ''}`}
                        >
                            {waiting && <Loader isLoading/>}
                            Wydrukuj {labels ? 'etykiety' : 'faktury'}
                        </button>
                        <button
                            onClick={hideModal}
                            type="button"
                            className="button border-button"
                        >
                            Zamknij
                        </button>
                    </div>
                </> :
                <>
                    <div className="circle-icon red">
                        <i className="icon-cross"/>
                    </div>
                    <div className="title">Ups!</div>
                    <div className="desc">Nie udało Ci się wygenerować {labels ? 'etykiet' : 'faktur'}</div>
                    <div className="modal-footer">
                        <button
                            onClick={hideModal}
                            type="button"
                            className="button border-button"
                        >
                            Zamknij
                        </button>
                    </div>
                </>
        )
    }
}

export default Summary;
