import React from 'react';
import {Route} from "react-router-dom";
import StatsPage from "../app/stats/StatsPage";
import UserPermissionRoute from "./UserPermissionRoute";
import {USER_PERMISSION_STATS_MENU_VISIBILITY} from "../app/shared/enums/UserPermissionsTypes";

const StatsRoute = ({path}) => {
	return (
		<UserPermissionRoute
			path={path}
			allowedPermissions={[USER_PERMISSION_STATS_MENU_VISIBILITY]}
		>
			<Route path={path} exact>
				<StatsPage/>
			</Route>
		</UserPermissionRoute>
	);
};

export default StatsRoute;
