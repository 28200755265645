import Model from "../../../src_shared/modules/model/Model";
import {ASSIGN_ORDER_TYPE_EXIST} from "../../../components/app/shared/enums/AssignOrderTypes";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

const orderValidator = (value, data) => {
	if (data.orderType !== ASSIGN_ORDER_TYPE_EXIST) {
		return false;
	}
	return ValidationError.notEmpty(value);
}

export class AssignCustomerModel extends Model {
	getModel() {
		return {
			orderType: ASSIGN_ORDER_TYPE_EXIST,
			shop_client_id: null,
			new_order_id: null,
		};
	}

	getValidators() {
		return {
			shop_client_id: ValidationError.notEmpty,
			new_order_id: orderValidator,
		};
	}

	buildDTO(data) {
		if (data.orderType !== ASSIGN_ORDER_TYPE_EXIST) {
			return {
				client_facebook_id: data.facebook_id,
			}
		}

		return {
			client_facebook_id: data.facebook_id,
			new_order_id: data.new_order_id,
		};
	}
}

export default new AssignCustomerModel();
