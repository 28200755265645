import FormActions from "../../../../../../../src_shared/form/FormActions.js";
import {getStateRoot, prefix} from "./formReducer.js";

export const getInstance = () => new FormActions({
	getStateRoot,
	prefix,
});

export default getInstance();

