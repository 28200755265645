import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import {RouterPaths} from "../../RouterPath";
import CategoryForm from "../../../app/products/categories/form/Form";
import CategoryWrapper from "../../../app/products/categories/Page";
import CategoriesProductsList from "../../../app/products/categories/products/list/Products";
import SEOForm from "../../../app/products/categories/seo/Form";

const CategoryRoute = ({path}) => {

	return (
		<Route path={path}>
			<CategoryWrapper>
				<Switch>
					<Route
						exact
						path={RouterPaths.CategoryEdit}
						component={CategoryForm}
					/>
					<Route
						exact
						path={RouterPaths.CategoryProducts}
						component={CategoriesProductsList}
					/>
					<Route
						exact
						path={RouterPaths.CategorySEO}
						component={SEOForm}
					/>
					<Redirect to={RouterPaths.CategoryEdit}/>
				</Switch>
			</CategoryWrapper>
		</Route>
	);
};

export default CategoryRoute;
