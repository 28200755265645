import createReducer from "../../../shared/helpers/createReducer";
import history from "../../../../history";
import moment from "moment";
import {ALL_ORDERS_VIEW, INTERVAL} from "../../../shared/enums/OrderViewTypes";
import {parseParams} from "../../../../../../../src_shared/list/ListActions";
import DataReducer from "../../../../../../../src_shared/form/data/DataReducer";
import {getReduceMap} from "../../../../../../../src_shared/filters/filterReducer";

const queryParams = history.location.search;

const parsedParams = parseParams(queryParams)

const ALL_VALUE = 'all'

const preparedDate = (initialState, paramsDate) => {
	if (!!queryParams) {
		if (parsedParams[paramsDate]) {
			return moment(parsedParams[paramsDate]).toDate()
		} else {
			return null;
		}
	} else {
		return initialState;
	}
}

const viewType = !!queryParams ? (parsedParams.dateFrom ? INTERVAL : ALL_ORDERS_VIEW) : INTERVAL;
const preparedType = parsedParams['type[]'] || [];
const owner_id = parsedParams['owner_id[]'] || [];
const preparedSegmentationType = +parsedParams.segmentation_type || 'all';
const has_live = +parsedParams.has_live || ALL_VALUE;
const has_ecommerce = +parsedParams.has_ecommerce || ALL_VALUE;
const is_professional = +parsedParams.is_professional || ALL_VALUE;
const has_posts = +parsedParams.has_posts || ALL_VALUE;
const has_comments = +parsedParams.has_comments || ALL_VALUE;
const messenger = +parsedParams.messenger || ALL_VALUE;
const note = +parsedParams.note || ALL_VALUE;
const social_type = parsedParams.social_type || ALL_VALUE;
const owner = +parsedParams.owner || ALL_VALUE;
const user_id = +parsedParams.user_id || null;

const startOfMonth = moment().startOf('month').toDate();
const endOfMonth   = moment().endOf('month').toDate()

export const getStateRoot = (state) => state.admin.sales.stats.filters;

export const getInitState = () => DataReducer.getInitState({
	fromDate: preparedDate(startOfMonth, 'dateFrom'),
	toDate: preparedDate(endOfMonth, 'dateTo'),
	viewType,
	type: preparedType || [],
	owner_id,
	segmentationType: preparedSegmentationType,
	has_live,
	has_ecommerce,
	is_professional,
	has_posts,
	has_comments,
	messenger,
	note,
	social_type,
	owner,
	user_id: user_id

}, {
	fromDate: startOfMonth,
	toDate: endOfMonth,
	viewType: INTERVAL,
	type: [],
	owner_id: [],
	segmentationType: ALL_VALUE,
	has_live: ALL_VALUE,
	has_ecommerce: ALL_VALUE,
	is_professional: ALL_VALUE,
	has_posts: ALL_VALUE,
	has_comments: ALL_VALUE,
	messenger: ALL_VALUE,
	note: ALL_VALUE,
	social_type: ALL_VALUE,
	owner: 1,
	user_id: null
})

export const prefix = 'ADMIN_SALES_STATS_FILTERS';

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();
