import React from 'react';
import {SHOP_ORIGIN_PL} from "../../../app/shared/enums/ShopOrigins";
import NipChooser from "./nipChooser/NipChooser";
import {useSelector} from "react-redux";
import Dashboard from "./dashboard/Dashboard";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import useLang from "../../../../src_shared/hooks/useLang";
import useDataApi from "../../../../src_shared/hooks/useDataApi";
import {services} from "../../../RestServices";
import ComponentLoader from "../../../app/shared/components/componentLoader/ComponentLoader";
import {Helmet} from "react-helmet";

const PaymentsDashboard = () => {
	const {userData} = useSelector((state) => state.session);
	const {getLangText} = useLang();
	const [{data, isLoading, fetchData}] = useDataApi(services.API_SETTINGS_PAYMENTS_INFO, {
		gusData: null,
	})

	if (isLoading) return <ComponentLoader/>;

	return (
		<>
			<Helmet>
				<meta charSet="utf-8"/>
				<title>{getLangText('paymentsLabel')} - Selmo</title>
			</Helmet>
			<div className="header-bar breadcrumbs-in-react-app">
				<ul className="breadcrumbs">
					<li>
						<Link to={RouterPaths.Settings}>{getLangText('asideSettingsLabel')}</Link>
					</li>
					<li>
						<Link to={RouterPaths.SettingsPaymentsShipments}>
							{getLangText('settings_nav_payment_shipping')}
						</Link>
					</li>
					<li className="active">
						<div>{getLangText('paymentsLabel')}</div>
					</li>
				</ul>
			</div>
			<div className="settings-page">
				{userData.origin === SHOP_ORIGIN_PL && !data?.gusData ?
					<NipChooser fetchData={fetchData}/> :
					<Dashboard data={data} isLoading={isLoading}/>
				}
			</div>
		</>
	);
};

export default PaymentsDashboard;
