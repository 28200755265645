import React from 'react';
import useStatusFilters from "../../../../hooks/useStatusFilters";
import useLang from "../../../../hooks/useLang";

const DefaultFilter = ({
                           ListActions,
                           FiltersActions,
                           getFiltersStateRoot,
                           options,
                           name,
                           selectAllName,
                           FormActions,
                           getStateRoot,
                           optionLabel,
                       }) => {

    const {getLangText} = useLang();


    const {
        isSelectAll,
        handleCheckBoxClick,
        allItemsClick,
        getCheckboxValue,
    } = useStatusFilters(
        ListActions,
        getFiltersStateRoot,
        FiltersActions,
        name,
        selectAllName,
        options,
        FormActions,
        getStateRoot,
    );

    return (
        <div className="checkbox-filter-wrapper">
            <div className="form-group checkbox-form">
                <input
                    className="checkbox-input form-control"
                    type="checkbox"
                    onChange={allItemsClick}
                    checked={isSelectAll}
                />
                <span className="checkbox-checkmark"/>
                <label className="control-label">{getLangText('allLabel')}</label>
            </div>
            {options.map((status) => (
                <div
                    key={status.value}
                    className="form-group checkbox-form"
                >
                    <input
                        className="checkbox-input form-control"
                        type="checkbox"
                        onChange={() => handleCheckBoxClick(status.value)}
                        checked={getCheckboxValue(status.value)}
                    />
                    <span className="checkbox-checkmark"/>
                    {optionLabel(status, getLangText)}
                </div>
            ))}
        </div>
    );
};

DefaultFilter.defaultProps = {
    className: '',
    extraProps: {},
    options: [],
    filterTitle: null,
    optionLabel: (item, getLangText) => (
        <label className="control-label">{getLangText(item.label) || item.label}</label>
    )
}

export default DefaultFilter;
