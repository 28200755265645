import {combineReducers} from "redux";
import chatListReducer from "./chatList/ChatListReducer";
import chatBoxReducer from "./chatBox/ChatBoxReducer";
import orderModalReducer from "./orderModal/OrderModalReducer";
import addOrderModalReducer from "./addOrderModal/AddOrderModalReducer";
import tabsReducer from "./addOrderModal/components/tabs/tabsReducer";
import galleryModalReducer from "./chatBox/galleryModal/GalleryModalReducer";
import newAddOrderModalReducer from "./addOrderModal/newAddOrderModalReducer";

export default combineReducers({
	chatList: chatListReducer,
	chatBox: chatBoxReducer,
	orderModal: orderModalReducer,
	addOrderModal: addOrderModalReducer,
	newAddOrderModal: newAddOrderModalReducer,
	ordersViewType: tabsReducer,
	galleryModal: galleryModalReducer,
});
