import ListActions, {getGETParamsUrl} from "../../../../../../src_shared/list/ListActions.js";
import {getStateRoot, prefix} from './listReducer.js';
import {batch} from "react-redux";
import axios from "axios";
import {selmoUrl} from "../../../../../../src_shared/api/api.js";
import {services} from "../../../../../RestServices.js";
import _ from "lodash";
import SessionActions from "../../../../shared/session/SessionActions.js";
import jwt_decode from "jwt-decode";
import LangsActions from "../../../../shared/langs/LangsActions";
import api from "../../../../../../services/axios/axios";

export class NewLiveCommentsListActions extends ListActions {
	updateLiveCommentsFromSocket(data) {
		return (dispatch, getState) => {
			const {innerWidth: width} = window;
			const {scrollTouched, search} = this.getStateRoot(getState());
			const isCommentNotIncludeInSearch = this.isCommentNotIncludeInSearch(data, search?.toLowerCase());

			if (!!search.length && isCommentNotIncludeInSearch) {
				return;
			}

			if (data.fromPianoComment) {
				dispatch(this.table.updateItem(data.id, 'has_response', "1"))
			} else {
				dispatch(this.table.addItemToBegin({...data, fromSocket: true}))
				if (!scrollTouched && width < 767) {
					document.querySelector('.comments-wrapper .scroll-content')?.scrollTo(0, -1)
					document.querySelector('.comments-wrapper .scroll-content')?.scrollTo(0, 0)
				}
			}
		}
	}

	loadData(id) {
		return async (dispatch, getState) => {
			const {firstInit} = getState().session;
			dispatch(this.waiting.startWaiting())
			if (firstInit) {
				dispatch(this.onFirstInit());
			}

			try {
				const response = await api.get(`${selmoUrl}/${this.restService}`)
				const {items, total, videoId, paging, info, is_finished} = response.data;
				dispatch(this.table.updateAllItems(items))
				dispatch(this.setScrollPage(paging?.cursors?.after))
				dispatch(this.pagination.updateTotalItems(total))

				const decodedAccessToken = jwt_decode(info)
				if (!+is_finished) {
					var source = new EventSource("https://streaming-graph.facebook.com/" + videoId + "/live_comments?access_token=" + decodedAccessToken?.access_token + "&comment_rate=one_per_two_seconds&fields=from{name,id,picture},id,message,created_time");

					source.onmessage = (event) => {
						let commentInfo = JSON.parse(event.data);
						if (commentInfo) {
							dispatch(this.updateLiveCommentsFromSocket(commentInfo))
						}
					};
				}
			} catch (e) {
				dispatch(this.table.getListError(e))
				console.error(e)
			} finally {
				if (firstInit) {
					dispatch(SessionActions.setFirstInit());
				}
				dispatch(this.waiting.stopWaiting())
			}
		}
	}

	setExtraField(field, value) {
		return {
			type: `${this.prefix}SET_EXTRA_FIELD`,
			field,
			value
		};
	}

	isCommentNotIncludeInSearch(comment, search) {
		const userName = comment?.from?.name.toLowerCase();
		const message = comment?.message.toLowerCase();
		const includeUserName = userName.includes(search);
		const includeMessageContent = message.includes(search);
		return !(includeUserName || includeMessageContent);
	}

	showCommentsPanel(toggle) {
		return {
			type: `${this.prefix}SHOW_COMMENTS_PANEL`, toggle,
		};
	}

	setScrollTouched(toggle) {
		return {
			type: `${this.prefix}SET_SCROLL_TOUCHED`, toggle,
		};
	}

	replyToComment(comment_id) {
		return async () => {
			try {
				await api.post(`${selmoUrl}/${services.API_LIVE_COMMENTS_REPLY_TO_COMMENT}`, {
					comment_id
				})
			} catch (e) {
				console.error('Cannot reply to comment')
			}
		}
	}

	loadNewPage() {
		return async (dispatch, getState) => {
			try {
				const state = this.getStateRoot(getState());

				const params = getGETParamsUrl({
					page: state.scrollPage,
				});

				const {data} = await api.get(`${selmoUrl}/${this.restService}${params}`)

				const {items} = state;
				const updatedItems = items.concat(data.items);
				const uniq = _.uniqBy(updatedItems, 'id')

				batch(() => {
					dispatch(this.table.updateAllItems(uniq));
					dispatch(this.setScrollPage(data.paging?.cursors?.after));
				});

				if (!data.paging) {
					dispatch(this.setHasMorePages(false));
				}
			} catch (e) {
				console.warn('Cannot get next page')
			}
		};
	}

	setScrollPage(scrollPage) {
		return {
			type: `${this.prefix}SET_SCROLL_PAGE`, scrollPage,
		};
	}

	setHasMorePages(toggle) {
		return {
			type: `${this.prefix}SET_HAS_MORE_PAGES`, toggle,
		};
	}

	updateLiveCommentsFromPusher(data) {
		return (dispatch, getState) => {
			const {commentsPanelVisible} = this.getStateRoot(getState());

			if (!commentsPanelVisible) {
				return;
			}

			dispatch(this.table.updateItem(data.id, 'removed', true))
		}
	}
}

export const getInstance = () => new NewLiveCommentsListActions({
	getStateRoot, restService: services.API_LIVE_NEW_COMMENTS, prefix, preventPushParams: true,
});

export default getInstance();
