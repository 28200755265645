import React from 'react';
import useLang from "../../../../src_shared/hooks/useLang";
import {notificationsBenefits} from "../landing/Landing";

const WhyWorthIt = () => {
    const {getLangText} = useLang();

    return (
        <div className="default-shadow-box bigger-y-padd mb-3">
            <h2 className="font-bold mb-3.5">{getLangText('pricingWhyWorthyTitle')}</h2>
            <div className="space-y-3 mt-3">
                {notificationsBenefits.map((i) => (
                    <div
                        key={i.id}
                        className="flex items-start"
                    >
                        <div className="mr-2 -mt-1">
                            <i className="icon-tick-c text-[#5FA5FA] text-lg"/>
                        </div>
                        <div>
                            <div
                                className="text-[#101827] font-medium text-sm">{getLangText(i.title)}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default WhyWorthIt;