import {getStateRoot, prefix} from './listReducer';
import {services} from "../../../../RestServices";
import ListActions from "../../../../../src_shared/list/ListActions";
import {RouterPaths} from "../../../../routes/RouterPath";


export class ShopSettingsNewsletterListActions extends ListActions {
	afterDelete() {
		return (dispatch) => {
			dispatch(this.loadPage(1))
		}
	}
}

export const getInstance = () => new ShopSettingsNewsletterListActions({
	getStateRoot,
	restService: services.API_STORE_NEWSLETTER,
	pathname: RouterPaths.ShopPromotionsNewsletter,
	prefix,
});

export default getInstance();
