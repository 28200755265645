import React, {useState} from 'react';
import useSubmitData from "../../../../../../src_shared/hooks/useSubmitData";
import {services} from "../../../../../RestServices";
import {showAlert} from "../../../../shared/components/alert/AlertActions";
import {useDispatch} from "react-redux";
import useLang from "../../../../../../src_shared/hooks/useLang";
import ConfirmModal from "../../../list/components/export/ConfirmModal";

const RefundPaymentButton = ({loadData, id}) => {
    const {getLangText} = useLang()
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [{onSave, isWaiting}] = useSubmitData({
        rest: `${services.API_REFUND_STRIPE_PAYMENT}/${id}`,
        afterSubmit: () => {
            dispatch(showAlert('yuhuLabel', 'refundHasBeenSuccessfullyRequested'))
            loadData();
            setShowModal(false);
        },
    });

    return (
        <>
            <button
                type="button"
                onClick={() => setShowModal(true)}
                className={`underline ml-auto font-bold hover:text-[#000] text-[#4B5563] transition-all duration-200 text-xs mr-2 opacity-0 pointer-events-none group-hover:opacity-100 group-hover:pointer-events-auto`}
                style={{paddingTop: 2, paddingBottom: 2, paddingLeft: 5, paddingRight: 5, fontSize: 10}}
            >
                {getLangText('refund')}
            </button>
            {showModal &&
                <ConfirmModal
                    title={getLangText('wantToRefund')}
                    saveButton={getLangText('yesButton')}
                    show={showModal}
                    isWaiting={isWaiting}
                    onSubmit={onSave}
                    hide={() => setShowModal(false)}
                />
            }
        </>
    );
};

export default RefundPaymentButton;
