import createReducer from "../../shared/helpers/createReducer";
import history from "../../../history";
import moment from "moment";
import {ALL_ORDERS_VIEW, INTERVAL} from "../../shared/enums/OrderViewTypes";
import {parseParams} from "../../../../../../src_shared/list/ListActions";
import DataReducer from "../../../../../../src_shared/form/data/DataReducer";
import {getReduceMap} from "../../../../../../src_shared/filters/filterReducer";
import {ORIGIN_TYPES} from "../../shared/enums/OriginTypes";

const queryParams = history.location.search;
const parsedParams = parseParams(queryParams)

const preparedViewType = !!queryParams ? (parsedParams.dateFrom ? INTERVAL : ALL_ORDERS_VIEW) : ALL_ORDERS_VIEW;
const preparedOrigin = parsedParams['origin[]'] || [];

const preparedDate = (initialState, paramsDate) => {
	if (!!queryParams) {
		if (parsedParams[paramsDate]) {
			return moment(parsedParams[paramsDate]).toDate()
		} else {
			return null;
		}
	} else {
		return initialState;
	}
}

export const getStateRoot = (state) => state.admin.dashboard.filters;

export const getInitState = () => DataReducer.getInitState({
		fromDate: preparedDate(moment(new Date()).subtract(1, 'M').toDate(), 'dateFrom'),
		toDate: preparedDate(new Date(), 'dateTo'),
		viewType: preparedViewType,
		origin: preparedOrigin || [],
		originSelectAll: preparedOrigin.length === ORIGIN_TYPES.length,
	},
	{
		fromDate: moment(new Date()).subtract(1, 'M').toDate(),
		toDate: new Date(),
		viewType: ALL_ORDERS_VIEW,
		origin: [],
		originSelectAll: false,
	})

export const prefix = 'CMS_DASHBOARD_FILTERS_';

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();
