import {selmoUrl} from "../../../../src_shared/api/api";
import SessionActions from "../../shared/session/SessionActions";
import api from "../../../../services/axios/axios";

export const orderModalToggleVisibility = (toggle) => {
    return {
        type: 'ORDER_MODAL_TOGGLE_VISIBILITY',
        toggle,
    };
}

const getOrdersRequest = () => {
    return {
        type: 'GET_ORDERS_REQUEST'
    };
}

const updateOrders = (orders) => {
    return {
        type: 'UPDATE_ORDERS',
        orders,
    };
}

const getOrdersError = () => {
    return {
        type: 'GET_ORDERS_ERROR',
    };
}

const notOrdersFound = () => {
    return {
        type: 'NOT_ORDERS_FOUND',
    };
}

export const messengerOrdersReset = () => {
    return {
        type: 'MESSENGER_ORDERS_RESET',
    };
}


export const getOrders = (userId, shopId) => async (dispatch) => {

    dispatch(getOrdersRequest());

    try {
        const {data} = await api.get(`${selmoUrl}/api/orders?user=${userId}&shop=${shopId}`)
        dispatch(updateOrders(data || []))
    } catch (e) {
        if (e.message === "canceled") {
            return;
        }
        dispatch(notOrdersFound())
        console.error(e)
    }
}
