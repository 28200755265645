import React from 'react';
import useLang from "../../../../../src_shared/hooks/useLang";
import EmptyList from "../../../../app/shared/EmptyList";
import moment from "../../../../app/shared/moment";
import CustomDropdown from "../../../../app/shared/components/dropdown/CustomDropdown";
import {
    USER_PERMISSIONS_TEAM_ADD_MEMBER,
} from "../../../../app/shared/enums/UserPermissionsTypes";
import UserPermissionsWrapper from "../../../../routes/UserPermissionsWrapper";
import DropdownWithPortal from "../../../../../src_shared/components/dropdownPortal/DropdownWithPortal";
import {Menu} from "@headlessui/react";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../routes/RouterPath";
import cn from "clsx";
import LanguagePicker from "../../../../app/shared/languagePicker/LanguagePicker";
import Dropdown from "../../../../../src_shared/components/ui/Dropdown/Dropdown";
import DropdownButtons from "./Dropdown";
import {convertToUserTimeZone} from "../../../../app/shared/helpers/dateHelpers";

const Table = ({updateSortOptions, isWaiting, getSortClass, items, askForRemoveItem}) => {
    const {getLangText} = useLang();

    return (
        <EmptyList
            items={items}
            isLoading={isWaiting}
            className="with-padd with-bg rounded-b-[5px]"
            imgSrc="/assets/images/empty/clients-empty.svg"
            title={getLangText('emptyHereLabel')}
            text={getLangText('inviteMemberToTeam')}
        >
            <div className="table-wrapper">
                <div className="table-responsive" style={{overflow: 'visible'}}>
                    <table className="table live-table">
                        <thead>
                        <tr>
                            <th
                                name="employee_email"
                                onClick={updateSortOptions}
                                className={getSortClass('employee_email')}
                            >
							<span name="employee_email">
								{getLangText('emailLabel')}
							</span>
                            </th>
                            <th
                                name="admin_permissions"
                                onClick={updateSortOptions}
                                className={getSortClass('admin_permissions')}
                            >
							<span name="admin_permissions">
								{getLangText('permissions')}
							</span>
                            </th>
                            <th>
                                {getLangText('statusLabel')}
                            </th>
                            <th
                                name="date"
                                onClick={updateSortOptions}
                                className={`${getSortClass('date')} text-right`}
                            >
							<span name="date">
								{getLangText('dateLabel')}
							</span>
                            </th>
                            <UserPermissionsWrapper allowedPermissions={[USER_PERMISSIONS_TEAM_ADD_MEMBER]}>
                                <th/>
                            </UserPermissionsWrapper>
                        </tr>
                        </thead>
                        <tbody>
                        {items.map((item) => (
                            <tr
                                className="not-clicked-tr"
                                key={item.id}
                            >
                                <td className="name-td">
                                    <strong>{item.employee_email}</strong>
                                </td>
                                <td>
                                    {getLangText(!!+item.admin_permissions ? 'admin' : 'employee')}
                                </td>
                                <td>
                                    <div className={`status-bg ${item.expired ? 'red' : 'blue'}`}>
                                        {getLangText(item.expired ? 'expired' : 'sent')}
                                    </div>
                                    {item.expired ? '' : ''}
                                </td>
                                <td className="date-td date text-right">
                                    <div>{convertToUserTimeZone(item.date).format('DD.MM.YYYY HH:mm')}</div>
                                </td>
                                <UserPermissionsWrapper allowedPermissions={[USER_PERMISSIONS_TEAM_ADD_MEMBER]}>
                                    <td className="text-right text-nowrap max-w-[70px] w-[70px]">
                                        <DropdownButtons askForRemoveItem={() => askForRemoveItem(item)} />
                                    </td>
                                </UserPermissionsWrapper>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </EmptyList>
    );
};

export default Table;
