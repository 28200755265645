import React from "react";
import FormActions from "./FormActions";
import {useDispatch} from "react-redux";
import Modal from "react-bootstrap/Modal";
import useLang from "../../../../../src_shared/hooks/useLang";
import useForm from "../../../../../src_shared/form/useForm";
import Loader from "../../../shared/Loader";
import Field from "../../../../../src_shared/components/form/field/Field";

const CollectionFormModal = ({hideModal, modalVisible}) => {
	const dispatch = useDispatch();
	const {getLangText} = useLang();

	const {
		data: {values, setValue, setNestedValue, updateValues},
		form: {isWaiting, onSave, getFieldProps},
		validation,
	} = useForm({
		FormActions,
		params: {}
	});


	return (
		<Modal
			show
			className="add-order-modal"
			dialogClassName="default-modal"
			onHide={hideModal}
		>
			<Modal.Header className="with-border">
				<Modal.Title>{getLangText('addCollectionLabel')}</Modal.Title>
				<button
					onClick={hideModal}
					type="button"
					className="button"
				>
					<i className="icon-cross"/>
				</button>
			</Modal.Header>
			<Modal.Body className="add-product-modal-body">
				<Field
					{...getFieldProps('name')}
					setValue={setValue}
					label={getLangText('collectionNameLabel')}
					placeholder={getLangText('enterCollectionNameLabel')}
				/>
			</Modal.Body>
			<Modal.Footer>
				<button
					onClick={hideModal}
					type="button"
					className="button border-button"
				>
					{getLangText('cancelButton')}
				</button>
				<button
					type="button"
					className="button primary"
					onClick={onSave}
				>
					{isWaiting && <Loader isLoading/>}
					{getLangText('saveButton')}
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default CollectionFormModal;



