import Model from "../../../src_shared/modules/model/Model";

class PrintSelectedVariantsModel extends Model {
	getModel() {
		return {
			sizes: [],
		};
	}

	buildDTO(data) {
		return data.sizes.map((y) => ({
			id: y.id,
			squ: y.squ,
		}));
	}
}

export default new PrintSelectedVariantsModel();
