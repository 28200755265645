import React from 'react';
import {useSelector} from "react-redux";
import {createSelector} from "reselect";
import {getStateRoot} from "./formReducer";
import TransferPage from "./transferPage/TransferPage";
import Payments from "./Form";
import useForm from "../../../src_shared/form/useForm";
import FormActions from "./FormActions";
import NewClientPromoBar from "../shared/components/NewClientPromoBar/NewClientPromoBar";
import moment from "moment";
import {convertToUserTimeZone} from "../shared/helpers/dateHelpers";

const PaymentsPage = () => {
    const {isTransferPage, summaryInfo} = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));
    const {userData: {user_info, tutorial_done_date, client_on_trial}} = useSelector((state) => state.session);
    const isNotVisible = !!+client_on_trial || user_info.status || !tutorial_done_date || (convertToUserTimeZone(tutorial_done_date).add(1, 'hours').add(1, 'minutes') <= convertToUserTimeZone(new Date())) || (convertToUserTimeZone(tutorial_done_date).add(1, 'minutes') > convertToUserTimeZone(new Date()));


    const {
        data: {values, setValue, updateValues},
        form: {isWaiting, onSave, getFieldProps},
    } = useForm({
        FormActions,
        params: {},
        requireParentId: false,
    });

    return (
        isTransferPage ?
            <div className="main-container" style={{background: 'white'}}>
                <TransferPage
                    FormActions={FormActions}
                    summaryInfo={summaryInfo}
                    values={values}
                />
            </div> :
            <>
                <NewClientPromoBar
                    isNotVisible={isNotVisible}
                    className="relative z-10"
                />
                <div className="main-container" style={{background: 'white'}}>
                    <Payments
                        updateValues={updateValues}
                        values={values}
                        setValue={setValue}
                        isWaiting={isWaiting}
                        onSave={onSave}
                        getFieldProps={getFieldProps}
                    />
                </div>
            </>

    );
};

export default PaymentsPage;
