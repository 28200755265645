import paginationActions from './pagination/PaginationActions.js';
import tableActions from './table/TableActions.js';
import waitingActions from './waiting/WaitingActions.js';
import history from "../../components/history.js";
import {selmoUrl} from "../api/api";
import ModalActions from "../modal/ModalActions";
import {showAlert} from "../../components/app/shared/components/alert/AlertActions";
import SessionActions from "../../components/app/shared/session/SessionActions";
import LangsActions from "../../components/app/shared/langs/LangsActions";
import api from "../../services/axios/axios";
import {batch} from "react-redux";

export const parseParams = (querystring) => {

    const params = new URLSearchParams(querystring);

    const obj = {};

    for (const key of params.keys()) {
        if (key.includes('[]')) {
            obj[key] = params.getAll(key);
        } else if (params.getAll(key).length > 1) {
            obj[key] = params.getAll(key);
        } else {
            obj[key] = params.get(key);
        }
    }


    return obj;
};

const convertParamToURLFormat = (
    key,
    value
) => {
    if (Array.isArray(value)) {
        return value
            .filter((i) => i !== 'id')
            .map((singleValue) => `${key}=${encodeURIComponent(singleValue)}`)
            .join('&');
    }

    return `${key}=${encodeURIComponent(value)}`;
};


export const filterNullValues = (obj) => {
    return Object.keys(obj).reduce((filteredObj, key) => {
        if (obj[key] !== null) {
            filteredObj[key] = obj[key];
        }
        return filteredObj;
    }, {});
}
export const getGETParamsUrl = (params) => {
    const urlParams = Object.keys(params)
        .map((key) => convertParamToURLFormat(key, params[key]))
        .join('&');

    return !!urlParams ? `?${urlParams}` : '';
};

export class ListActions {
    constructor(config = {}) {
        const {
            getStateRoot,
            restService,
            getTotalItems,
            prefix,
            pathname,
            model,
            preventPushParams,
            defaultSortBy,
        } = {...config};

        this.getStateRoot = getStateRoot;
        this.restService = restService;
        this.getTotalItems = getTotalItems;
        this.prefix = prefix;
        this.pathname = pathname;
        this.preventPushParams = preventPushParams;
        this.dataModel = model;
        this.defaultSortBy = defaultSortBy;

        this.pagination = paginationActions.createActions(this.prefix);
        this.table = tableActions.createActions(this.prefix);
        this.waiting = waitingActions.createActions(this.prefix);
        this.modal = ModalActions.createActions(this.prefix);
    }

    getLoadParams(state, id) {
        const stateRoot = this.getStateRoot(state);

        const params = {
            page: stateRoot.page,
            limit: stateRoot.itemsPerPage,
        };

        const superSearch = stateRoot.search;
        const sortBy = stateRoot.sortBy;
        const sortDir = stateRoot.sortDir

        if (sortBy && sortDir) {
            params.sortBy = sortBy;
            params.sortDir = sortDir;
        }

        if (superSearch) {
            params.superSearch = superSearch;
        }

        if (!this.preventPushParams) {
            history.replace({
                pathname: id ? `${this.pathname.replace(':id', id)}` : this.pathname,
                search: getGETParamsUrl(params)
            });
        }

        return params;
    }

    updateFieldsFromQueryParams() {
        const parsedParams = parseParams(window.location.search)
        return (dispatch) => {
            if (parsedParams.superSearch) {
                dispatch(this.table.updateSearch(parsedParams.superSearch))
            }
            if (parsedParams.page) {
                dispatch(this.pagination.changePage(+parsedParams.page))
            }
            if (parsedParams.limit) {
                dispatch(this.pagination.setItemPerPage(+parsedParams.limit))
            }
            if (parsedParams.sortBy && parsedParams.sortDir) {
                dispatch(this.table.updateSort(parsedParams.sortBy, parsedParams.sortDir))
            }
        }
    }

    onFirstInit() {
        return (dispatch) => {
            api.defaults.headers.common['lang'] = dispatch(LangsActions.getLang());
            dispatch(this.updateFieldsFromQueryParams())
        }
    }

    loadData(id, signal) {
        return async (dispatch, getState) => {
            const {firstInit} = getState().session;
            const {itemsPerPage, sortBy} = this.getStateRoot(getState());
            dispatch(this.waiting.startWaiting())
            if (firstInit) {
                dispatch(this.onFirstInit());
            }
            const params = this.getLoadParams(getState(), id)

            const restUrl = +id ?
                `/${id}${getGETParamsUrl(params)}` :
                getGETParamsUrl(params);
            try {
                const response = await api.get(`${selmoUrl}/${this.restService}${restUrl}`, {signal})
                const {items, total, isFirstStepPage} = response.data;
                if (+total <= +itemsPerPage && this.defaultSortBy) {
                    items.sort((a, b) => parseFloat(b[this.defaultSortBy]) - parseFloat(a[this.defaultSortBy]))
                }
                if (!sortBy) {
                    // dispatch(this.table.updateSort(this.defaultSortBy, 'desc'));
                }

                batch(() => {
                    dispatch(this.table.updateAllItems(items))
                    dispatch(this.setFirstStepPage(isFirstStepPage))
                    dispatch(this.pagination.updateTotalItems(total))
                })

            } catch (e) {
                dispatch(this.table.getListError(e))
                console.error(e)
            } finally {
                if (firstInit) {
                    dispatch(SessionActions.setFirstInit());
                }
                dispatch(this.waiting.stopWaiting())
            }
        }
    }


    onErrorLoad(e) {
        return (dispatch, getState) => {
            // const session = getState().session;
            // console.log(session)
            // dispatch(SessionActions.sessionUnChecked());
            // dispatch(SessionActions.checkSession());
        }
    }

    reset() {
        return {
            type: `${this.prefix}RESET`
        };
    }

    setFirstStepPage(toggle) {
        return {
            type: `${this.prefix}SET_FIRST_STEP_PAGE`,
            toggle,
        };
    }

    callSearch(searchText, id, signal) {
        return (dispatch) => {
            dispatch(this.table.updateSort(null, 'desc'));
            dispatch(this.table.updateSearch(searchText));
            dispatch(this.loadPage(1, id, signal));
        };
    }

    callSort(sortBy, sortDir, id) {
        return (dispatch, getState) => {
            const {items, totalItems, itemsPerPage, page} = this.getStateRoot(getState());
            dispatch(this.table.updateSort(sortBy, sortDir));

            if (totalItems > itemsPerPage) {
                if (+page === 1) {
                    dispatch(this.loadPage(1, id));
                } else {
                    dispatch(this.pagination.changePage(1));
                }
            } else {
                items.sort((a, b) => {
                    const numberFieldsArray = ['price', 'code', 'id', 'count', 'discount_name', 'money', 'trial_clients', 'this_month_clients', 'this_week_clients', 'selmo_pro_clients', 'still_active_clients']

                    let parsedA = a[sortBy]?.toString()?.toLowerCase() || '';
                    let parsedB = b[sortBy]?.toString()?.toLowerCase() || '';

                    // if (sortBy === 'date') {
                    // 	parsedA = new Date(a[sortBy]);
                    // 	parsedB = new Date(b[sortBy]);
                    // }

                    if (numberFieldsArray.some(field => sortBy.includes(field))) {
                        parsedA = parseFloat(a[sortBy]);
                        parsedB = parseFloat(b[sortBy]);
                    }


                    if (parsedA > parsedB) {
                        return sortDir === 'asc' ? 1 : -1;
                    } else if (parsedA < parsedB) {
                        return sortDir === 'asc' ? -1 : 1;
                    } else {
                        return 0;
                    }
                });
            }
        };
    }

    updateItemsPerPage(name, value) {
        return (dispatch) => {
            const itemsPerPage = Number(value);
            dispatch(this.pagination.setItemPerPage(itemsPerPage));
            dispatch(this.pagination.changePage(1));
        };
    }

    updateValueInItem(id, field, value) {
        return (dispatch) => {
            dispatch(this.table.updateItem(id, field, value));
        };
    }

    loadPage(page, id, signal) {
        return (dispatch) => {
            dispatch(this.pagination.changePage(page));
            dispatch(this.loadData(id, signal));
        };
    }

    askForRemoveItem(item) {
        return (dispatch, getState) => {
            dispatch(this.table.markItemToRemove(item));
            dispatch(this.modal.showModal());
        };
    }

    cancelItemRemoval() {
        return (dispatch) => {
            dispatch(this.table.markItemToRemove({}));
            dispatch(this.modal.hideModal());
        };
    }

    confirmItemRemoval() {
        return (dispatch, getState) => {
            const stateRoot = this.getStateRoot(getState());
            const itemToRemove = stateRoot.markedToRemove;

            dispatch(this.waiting.startWaiting());
            dispatch(this.modal.hideModal());
            dispatch(this.deleteItemOnServer(itemToRemove.id))
        };
    }

    afterDelete() {

    }

    deleteItemOnServer(itemToRemove) {
        return async (dispatch) => {
            try {
                await api.delete(`${selmoUrl}/${this.restService}/${itemToRemove}`)
                dispatch(this.table.removeItem(itemToRemove));
                dispatch(showAlert())
                // dispatch(this.afterDelete())
            } catch (e) {
                dispatch(showAlert(e.response?.data?.message || 'sthWentWrongWarning', '', 'alert-fail'))
                console.error(e)
            } finally {
                dispatch(this.waiting.stopWaiting());
            }
        }
    }
}

export default ListActions;
