import React, {useContext, useEffect, useRef} from 'react';
import CreateShopLayout from "../../shared/CreateShopLayout";
import {useSelector} from "react-redux";
import ComponentLoader from "../../../../../src_shared/components/ui/componentLoader/ComponentLoader";
import {RouterPaths} from "../../../../routes/RouterPath";
import Pusher from "pusher-js";
import {Redirect, useHistory} from "react-router-dom";
import AbsoluteShopName from "../../shared/AbsoluteShopName";
import {TutorialFinalStepsContext} from "../FinalStepsContext";
import Cookies from "js-cookie";
import Loader from "../../../../../src_shared/components/loader/Loader";
import useLang from "../../../../../src_shared/hooks/useLang";
import {Helmet} from "react-helmet";
import {usePostHog} from "posthog-js/react";
import useFetchData from "../../../../../src_shared/hooks/useFetchData";
import {services} from "../../../../RestServices";
import useWindowDimensions from "../../../messenger/hooks/useWindowDimensions/useWindowDimensions";

const FirstVariant = ({data, getLangText}) => {
    const channelName = data?.shop_id;
    const history = useHistory();
    const posthog = usePostHog();
    const {width} = useWindowDimensions();

    const redirectToTutorialOrder = () => history.push(RouterPaths.TutorialOrder);
    const tutorialMessageSentEvent = (channel) => {
        channel.bind('tutorial_message_sent', redirectToTutorialOrder);
    }
    const getHeaderIcon = () => {
        if (data?.isInstagram) {
            return <img width={30} className="mx-auto mb-3" src="/assets/images/svg/instagram-icon-new.svg" alt="instagram"/>
        }
        return <img width={30} className="mx-auto mb-3" src="/assets/images/svg/facebook-logo-c.svg" alt="facebook"/>
    }

    const [{fetchData: checkIsSelmoStartSent}] = useFetchData({
        rest: services.API_TUTORIAL_START_STATUS,
        afterSubmit: redirectToTutorialOrder,
        onError: () => {},
    });

    const getHeaderTitle = () => {
        if (data?.isInstagram) {
            return 'selmoStartIgLabel'
        }
        return 'selmoStartLabel';
    }

    useEffect(() => {
        if (channelName) {
            const pusher = new Pusher('b4bb0f71c257b28c7212', {
                cluster: 'eu'
            });

            const channel = pusher.subscribe(`${channelName}`);

            tutorialMessageSentEvent(channel)

            return () => {
                pusher.unsubscribe(`${channelName}`)
                channel.unbind();
                pusher.disconnect();
            }
        }

    }, [channelName])

    useEffect(() => {
        if (posthog) {
            posthog?.capture(`ONBOARDING - 'selmo start' page visit`);
        }
    }, [posthog])

    useEffect(() => {
        if (width < 991) {
            document.addEventListener("visibilitychange", checkIsSelmoStartSent);
            return () => {
                document.removeEventListener("visibilitychange", checkIsSelmoStartSent);
            };
        }
    }, [width]);

    return (
        <CreateShopLayout
            withAnimation={false}
            Img={getHeaderIcon()}
            title={getHeaderTitle()}
        >
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getLangText('startWithSelmoLabel')} - Selmo</title>
            </Helmet>
            <div
                className="max-w-[500px] mx-auto w-full animate-tutorialSlideAndFadeInDelay2 opacity-0 mt-auto">
                <div className="relative w-[250px] sm:w-auto mx-auto">
                    <AbsoluteShopName data={data}/>
                    <video className="max-w-[400px] w-full sm:w-auto mx-auto" muted controls={false} playsInline
                           autoPlay loop>
                        <source src="/assets/videos/message-send-03.mp4" type="video/mp4"/>
                    </video>
                    <div className="flex items-center justify-center absolute bottom-8 left-1/2 -translate-x-1/2">
                        <Loader isLoading className="w-auto extra-small-loader static-loader mr-1.5"/>
                        <div
                            className="font-bold text-sm whitespace-nowrap">{getLangText('waitingForMessageLabel')}</div>
                    </div>
                </div>
            </div>
        </CreateShopLayout>
    )
}

const SecondVariant = ({data, getLangText}) => {
    const getHeaderIcon = () => {
        if (data?.isInstagram) {
            return <img width={30} className="mx-auto" src="/assets/images/svg/instagram-icon-new.svg" alt="instagram"/>
        }
        return <img width={30} className="mx-auto" src="/assets/images/svg/facebook-logo-c.svg" alt="facebook"/>
    }

    const getHeaderTitle = () => {
        if (data?.isInstagram) {
            return 'selmoStartIgLabel'
        }
        return 'selmoStartLabel';
    }


    return (
        <CreateShopLayout
            Img={getHeaderIcon()}
            title={getHeaderTitle()}
            withAnimation={false}
        >
            <div
                className="max-w-[500px] mx-auto w-full animate-tutorialSlideAndFadeInDelay2 opacity-0 mt-auto">
                <div className="text-2xl">Wariant 2</div>
                <div className="relative">
                    <AbsoluteShopName data={data}/>
                    <video className="max-w-[400px] sm:w-auto w-full mx-auto" muted controls={false} playsInline
                           autoPlay loop>
                        <source src="/assets/videos/message-send-03.mp4" type="video/mp4"/>
                    </video>
                    <div className="flex items-center justify-center absolute bottom-8 left-1/2 -translate-x-1/2">
                        <Loader isLoading className="w-auto extra-small-loader static-loader mr-1.5"/>
                        <div
                            className="font-bold text-sm whitespace-nowrap">{getLangText('waitingForMessageLabel')}</div>
                    </div>
                </div>
            </div>
        </CreateShopLayout>
    )
}

const ThirdVariant = ({data, getLangText}) => {
    const getHeaderIcon = () => {
        if (data?.isInstagram) {
            return <img width={30} className="mx-auto" src="/assets/images/svg/instagram-icon-new.svg" alt="instagram"/>
        }
        return <img width={30} className="mx-auto" src="/assets/images/svg/facebook-logo-c.svg" alt="facebook"/>
    }

    const getHeaderTitle = () => {
        if (data?.isInstagram) {
            return 'selmoStartIgLabel'
        }
        return 'selmoStartLabel';
    }


    return (
        <CreateShopLayout
            Img={getHeaderIcon()}
            title={getHeaderTitle()}
            withAnimation={false}
        >
            <div
                className="max-w-[500px] mx-auto w-full animate-tutorialSlideAndFadeInDelay2 opacity-0 mt-auto">
                <div className="text-2xl">Wariant 3</div>
                <div className="relative">
                    <AbsoluteShopName data={data}/>
                    <video className="max-w-[400px] sm:w-auto w-full mx-auto" muted controls={false} playsInline
                           autoPlay loop>
                        <source src="/assets/videos/message-send-03.mp4" type="video/mp4"/>
                    </video>
                    <div className="flex items-center justify-center absolute bottom-8 left-1/2 -translate-x-1/2">
                        <Loader isLoading className="w-auto extra-small-loader static-loader mr-1.5"/>
                        <div
                            className="font-bold text-sm whitespace-nowrap">{getLangText('waitingForMessageLabel')}</div>
                    </div>
                </div>
            </div>
        </CreateShopLayout>
    )
}

const SelectedVariant = ({data, isLoading, lang}) => {
    const tutorialVariant = Cookies.get('tutorialStartVariant');
    const {getLangText} = useLang();

    if (isLoading) {
        return (
            <CreateShopLayout>
                <ComponentLoader className="small-loader higher-border-radius"/>
            </CreateShopLayout>
        )
    }

    // if (+tutorialVariant === 2) {
    //     return <SecondVariant data={data} getLangText={getLangText} isLoading={isLoading} lang={lang}/>
    // }
    // if (+tutorialVariant === 3) {
    //     return <ThirdVariant data={data} getLangText={getLangText} isLoading={isLoading} lang={lang}/>
    // }
    return <FirstVariant data={data} getLangText={getLangText} isLoading={isLoading} lang={lang}/>

}

const TutorialStart = () => {
    const {lang} = useSelector((state) => state.i18n);
    const {data, isLoading} = useContext(TutorialFinalStepsContext);

    if ((!data?.isFacebook && !data?.isInstagram) && !isLoading) {
        return <Redirect to={RouterPaths.TutorialSocials}/>
    }

    return <SelectedVariant data={data} isLoading={isLoading} lang={lang}/>
};

export default TutorialStart;
