import React, {useEffect, useMemo} from 'react';
import Field from "../../../../../src_shared/components/form/field/Field";
import useFormLocal from "../../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../../RestServices";
import useLang from "../../../../../src_shared/hooks/useLang";
import {useDispatch, useSelector} from "react-redux";
import {KOKARDECZKA_SHOP_ID, TEST_ACCOUNT_SHOP_ID} from "../../../../../src_shared/enums/TrustedShopsIds";
import SwitchField from "../../../../../src_shared/components/form/switchField/SwitchField";
import SelectField from "../../../../../src_shared/components/form/selectField/SelectField";
import SettingsTransferModel from "../../../../../modules/models/settings/SettingsTransferModel";
import useRemoveData from "../../../../../src_shared/hooks/useRemoveData";
import {useParams} from "react-router-dom";
import Loader from "../../../../app/shared/Loader";
import ComponentLoader from "../../../../app/shared/components/componentLoader/ComponentLoader";
import ConfirmModal from "../../../../app/live/details/default/summary/export/ConfirmModal";
import {RouterPaths} from "../../../../routes/RouterPath";
import {showAlert} from "../../../../app/shared/components/alert/AlertActions";

const TransferForm = ({extraAccount = false, defaultData, getAdditionalAccounts = () => {}}) => {
	const {getLangText} = useLang();
	const dispatch = useDispatch();
	const {userData} = useSelector((state) => state.session);
	const {id} = useParams()
	const rest = !!+id ? `${services.API_SETTINGS_TRANSFER}/${id}` : services.API_SETTINGS_TRANSFER;

	const afterAddTransfer = () => {
		getAdditionalAccounts()
		updateValues({})
		dispatch(showAlert());
	}

	const afterSubmit = async () => {
		await fetchData()
		dispatch(showAlert());
	}

	const [{
		onSave,
		isWaiting,
		getFieldProps,
		isLoading,
		values,
		updateValues,
		fetchData,
	}] = useFormLocal({
		rest,
		method: !!+id ? 'put' : 'post',
		model: SettingsTransferModel,
		initialAutoLoad: !!+id,
		afterSubmit: () => !!+id ? afterSubmit() : afterAddTransfer(),
	});

	const [{
		openModal: openRemoveModal,
		hideModal: hideRemoveModal,
		confirmModalVisible,
		itemToRemove,
		isDeleting,
		removeItem,
	}] = useRemoveData({
		rest,
		redirectRoute: RouterPaths.SettingsPaymentsTransfer
	});

	const currenciesOptions = useMemo(() => values.currencies?.map((i) => ({
		label: i.name,
		value: i.id,
	})), [values.currencies])

	useEffect(() => {
		if (defaultData) {
			updateValues({
				...defaultData,
				extraAccount,
			})
		}
	}, [defaultData])

	if (isLoading) return <ComponentLoader/>;

	return (
		<>
			<section>
				<div className="left-side">
					<h2 className="label">{getLangText('settings_payments_transfer_data')}</h2>
				</div>
				<div className="form-col pb-2">
					{extraAccount &&
						<Field
							{...getFieldProps('transfer_account_name')}
							label={getLangText('input_label_transfer_account_name')}
							placeholder={getLangText('input_placeholder_transfer_account_name')}
							type="text"
						/>
					}
					<Field
						{...getFieldProps('transfer_name')}
						label={getLangText('input_label_transfer_name')}
						placeholder={getLangText('input_placeholder_transfer_name')}
						type="text"
					/>
					<Field
						{...getFieldProps('transfer_address')}
						label={getLangText('input_label_transfer_address')}
						placeholder={getLangText('input_label_transfer_address')}
						type="text"
					/>
					<Field
						{...getFieldProps('transfer_account_number')}
						label={getLangText('input_label_transfer_account_number')}
						placeholder={getLangText('input_placeholder_transfer_account_number')}
						type="text"
					/>
					{/*TODO z backnedu dostać defaultValue*/}
					<Field
						{...getFieldProps('transfer_title')}
						label={getLangText('input_label_transfer_title')}
						placeholder={getLangText('input_placeholder_transfer_title')}
						type="text"
					/>
					{!extraAccount && (+userData.id === TEST_ACCOUNT_SHOP_ID || +userData.id === KOKARDECZKA_SHOP_ID) &&
						<Field
							{...getFieldProps('transfer_swift')}
							label={getLangText('input_label_transfer_swift')}
							placeholder={getLangText('input_placeholder_transfer_swift')}
							type="text"
						/>
					}
					{extraAccount && !!values.currencies?.length &&
						<SelectField
							{...getFieldProps('transfer_currency')}
							label={getLangText('input_label_currency')}
							className="default-size"
							options={currenciesOptions}
							defaultValue={values.defaultCurrency}
						/>
					}
					<div className="form-info-box w-100">
						<div>
							<i className="icon-info-c"/>
						</div>
						<div>
							<div className="title">{getLangText('settings_payments_transfer_number_info_title')}</div>
							<div className="desc">
								{getLangText('settings_payments_transfer_number_info_description')}
								<div className="tag">{getLangText('number_tag')}</div>
							</div>
						</div>
					</div>
					<div
						className="d-flex align-items-center justify-content-between mt-4 pt-2 flex-sm-nowrap flex-wrap">
						<SwitchField
							{...getFieldProps('transfer_active')}
							label={getLangText('active_payment')}
							className="switch-form inline-label big-label light-version mb-0 mt-0"
						/>
						<button
							type="button"
							onClick={onSave}
							className={`button primary mt-3 ${isWaiting ? 'loading-button' : ''}`}
						>
							<Loader isLoading={isWaiting}/>
							{getLangText('saveButton')}
						</button>
					</div>
				</div>
			</section>
			{id &&
				<div className="remove-delivery-section">
					<button
						type="button"
						onClick={() => openRemoveModal(values)}
						className="button border-button left-icon big-size"
					>
						<i className="icon-bin"/>
						{getLangText('button_delete_transfer_account')}
					</button>
				</div>
			}
			{confirmModalVisible &&
				<ConfirmModal
					dialogClassName="w-380"
					title={getLangText('delete_modal_accept')}
					SubTitle={() => <span dangerouslySetInnerHTML={{
						__html: getLangText({
							key: 'wantToRemovePaymentTransferLabel',
							data: [itemToRemove.transfer_account_name]
						})
					}}/>}
					saveButton={getLangText('removeButton')}
					show={confirmModalVisible}
					isWaiting={isDeleting}
					onSubmit={removeItem}
					hide={hideRemoveModal}
					saveButtonClass="danger"
				/>
			}
		</>
	);
};

export default TransferForm;
