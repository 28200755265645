import React from 'react';
import {Helmet} from "react-helmet";
import KnowledgeBaseAside from "./aside/List";
import useCSSClass from "../messenger/hooks/useCSSClass/useCSSClass";
import {Link, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {getStateRoot} from "./searchModal/listReducer";
import SearchModal from "./searchModal/SearchModal";
import KnowledgeBaseSearchListActions from "./searchModal/ListActions";
import {RouterPaths} from "../../routes/RouterPath";
import useLang from "../../../src_shared/hooks/useLang";
import LanguagePicker from "../shared/languagePicker/LanguagePicker";

const KnowledgeBasePage = ({children}) => {

	const dispatch = useDispatch();
	const {modalVisible} = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));
	const {getLangText} = useLang();
	const history = useHistory();

	const showModal = () => dispatch(KnowledgeBaseSearchListActions.modal.showModal());
	const body = document.querySelector('body')

	const toggleMobileMenu = () => {
		if (body.classList.contains('open-menu')) {
			body.classList.remove('open-menu')
		} else {
			body.classList.add('open-menu')
		}
	}

	const afterLanguageChange = () => {
		history.push(RouterPaths.KnowledgeBase)
		window.location.reload();
	}

	const closeMobileMenu = () => body.classList.remove('open-menu');

	useCSSClass('knowledge-base-page');

	return (
		<div className="knowledge-base-wrapper">
			<Helmet>
				<meta charSet="utf-8"/>
				<title>{getLangText('knowledgeBaseTitle')} - Selmo</title>
			</Helmet>
			<nav>
				<div
					onClick={toggleMobileMenu}
					className="hamburger-btn"
				>
					<div className="bar1"/>
					<div className="bar3"/>
				</div>
				<div className="d-flex align-items-center">
					<Link
						className="brand-name"
						to={RouterPaths.Dashboard}
					>
						<img
							src="/assets/images/logo.svg"
							alt="logo-selmo"
						/>
					</Link>
					<Link
						to={RouterPaths.KnowledgeBase}
						className="knowledge-base-label"
					>
						{getLangText('knowledgeBaseTitle')}
					</Link>
				</div>
				{modalVisible &&
					<SearchModal/>
				}
				<div
					onClick={showModal}
					className={`button form-group search-form ml-lg-auto ml-0`}
				>
					<div className="form-control">
						{getLangText('searchPlaceholder')}
					</div>
					<i className="icon-magnifier"/>
				</div>
				<LanguagePicker
					afterSubmit={afterLanguageChange}
					buttonClassName="ml-3" />
			</nav>
			<div className="d-flex">
				<KnowledgeBaseAside closeMobileMenu={closeMobileMenu}/>
				<article className="knowledge-base-container">
					{children}
				</article>
			</div>
		</div>
	);
};

export default KnowledgeBasePage;
