import React, {useEffect, useState} from 'react';
import useLang from "../../../../src_shared/hooks/useLang";
import useForm from "../../../../src_shared/form/useForm";
import FormActions from "./FormActions";
import {Link, useParams} from "react-router-dom";
import CurrentProduct from "./components/currentProduct/CurrentProduct";
import TopSummary from "./components/TopSummary";
import LiveSale from "./components/liveSale/LiveSale";
import OverlayPreview from "./components/OverlayPreview";
import {RouterPaths} from "../../../routes/RouterPath";
import ComponentLoader from "../../shared/components/componentLoader/ComponentLoader";

const LivesOverlayForm = () => {

    const [dataLoaded, setDataLoaded] = useState(false);
    const {getLangText} = useLang();
    const {id} = useParams();

    const {
        data: {values, updateValues},
        form: {isWaiting},
    } = useForm({
        FormActions,
        params: {
            id,
        },
    });

    useEffect(() => {
        if (!isWaiting) {
            setDataLoaded(true);
        }
    }, [isWaiting]);

    if (!dataLoaded) {
        return <ComponentLoader/>;
    }
    return (
        <>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <Link to={RouterPaths.LiveOverlay}>
                            {getLangText('overlay')}
                        </Link>
                    </li>
                    <li className="active">
                        <div>{getLangText('editionLabel')}</div>
                    </li>
                </ul>
                <Link
                    to={RouterPaths.LiveOverlay}
                    className="button back-to-panels mb-3">
                    <i className="icon-arrow-left"/>
                    {getLangText('backToList')}
                </Link>
            </div>
            <div className="grid sm:grid-cols-5 gap-5">
                <div className="sm:col-span-3">
                    <TopSummary
                        currency={values.currency}
                        values={values}
                    />
                    <LiveSale
                        live_views={values?.live_views}
                        currency={values.currency}
                        items={values.live_purchase}
                    />
                </div>
                <div className="sm:col-span-2">
                    <CurrentProduct
                        product={values.active_product_info}
                        updateMainValues={updateValues}
                        inStock={values.in_stock}
                        activeCollectionId={values.active_collection_id}
                        withVariants={values.with_variants}
                        showPrice={values.show_price}
                        show_product_name={values.show_product_name}
                    />
                    <OverlayPreview values={values}/>
                </div>
            </div>
        </>
    );
};

export default LivesOverlayForm;
