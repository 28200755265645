import useLang from "../../../src_shared/hooks/useLang";
import {Helmet} from "react-helmet";
import {Link, NavLink} from "react-router-dom";
import {RouterPaths} from "../../routes/RouterPath";
import React from "react";

const SettingsQrCodesWrapper = ({children}) => {
    const {getLangText} = useLang()

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getLangText('automaticReserveList')} - Selmo</title>
            </Helmet>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <Link to={RouterPaths.Settings}>{getLangText('asideSettingsLabel')}</Link>
                    </li>
                    <li>
                        <Link to={RouterPaths.SettingsShop}>{getLangText('settings_nav_shop_settings')}</Link>
                    </li>
                    <li className="active">
                        <div>{getLangText('qrProductCode')}</div>
                    </li>
                </ul>
            </div>
            <div>
                <div className="settings-page sub-settings-page export-sub-settings list-with-tabs separated-box">
                    <div className="tabs-header">
                        <div className="header-wrapper no-border pb-0">
                            <h1>
                                {getLangText('qrProductCode')}
                                {/*<img style={{width: 'auto', height: '20px'}} src="/assets/images/shippypro.png"*/}
                                {/*     alt="ShippyPro"/>*/}
                            </h1>
                            <div className="description">
                                {getLangText('manageQRCodeAppearance')}
                            </div>
                        </div>
                    </div>
                    {children}
                </div>
            </div>
        </>
    );
};

export default SettingsQrCodesWrapper;
