import createReducer from "../../../shared/helpers/createReducer";
import history from "../../../../history";
import moment from "moment";
import {ALL_ORDERS_VIEW, INTERVAL} from "../../../shared/enums/OrderViewTypes";
import {parseParams} from "../../../../../../../src_shared/list/ListActions";
import DataReducer from "../../../../../../../src_shared/form/data/DataReducer";
import {getReduceMap} from "../../../../../../../src_shared/filters/filterReducer";
import {ADMIN_SHOP_STATUSES} from "../../../shared/enums/AdminShopStatus";

const queryParams = history.location.search;
const parsedParams = parseParams(queryParams)

const viewType = !!queryParams ? (parsedParams.dateFrom ? INTERVAL : ALL_ORDERS_VIEW) : INTERVAL;
const fb = parsedParams.fb || '0';
const status = parsedParams['status[]']?.map((i) => +i) || [];
const pipe = +parsedParams.pipe || 'all'
const user_id = parsedParams.user_id || ''
const is_deleted = +parsedParams.is_deleted || 2

const preparedDate = (initialState, paramsDate) => {
	if (!!queryParams) {
		if (parsedParams[paramsDate]) {
			return moment(parsedParams[paramsDate]).toDate()
		} else {
			return null;
		}
	} else {
		return initialState;
	}
}

const startOfMonth = moment().startOf('month').toDate();
const endOfMonth   = moment().endOf('month').toDate()

export const getStateRoot = (state) => state.admin.sales.dashboard.filters;

export const getInitState = () => DataReducer.getInitState({
	user_id,
	pipe,
	status,
	is_deleted,
	statusSelectAll: status.length === ADMIN_SHOP_STATUSES.length,
	fromDate: preparedDate(startOfMonth, 'dateFrom'),
	toDate: preparedDate(endOfMonth, 'dateTo'),
	viewType,
	fb,
}, {
	user_id: '',
	pipe: 'all',
	is_deleted: 2,
	status: [],
	statusSelectAll: false,
	fromDate: startOfMonth,
	toDate: endOfMonth,
	viewType: INTERVAL,
	fb: '0',
})

export const prefix = 'ADMIN_SALES_DASHBOARD_FILTERS';

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();
