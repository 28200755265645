import React, {useState} from 'react';
import useLang from "../../../../../src_shared/hooks/useLang";
import OrderHistoryList from "./OrderHistoryList";
import OrderHistoryModal from "./OrderHistoryModal";
import SelmoProBoxWrapper from "../../../shared/components/SelmoProBox/SelmoProBox";
import {useSelector} from "react-redux";

const OrderHistory = ({values}) => {
    const {getLangText} = useLang();
    const [showModal, setShowModal] = useState(false);
    const {userData} = useSelector((state) => state.session);

    if (!!+userData.selmo_pro_active && !values.employees_history?.length) return null;

    return (
        <SelmoProBoxWrapper
            className="mt-3"
            title="activityHistory"
            desc="seeActivitiesWhichYourTeamHasDoneInOrder"
        >
            <div className="summary-section mt-3">
                <div className="font-bold">{getLangText('activityHistory')}</div>
                <div className="text-desc text-sm mb-3">{getLangText('seeActivityHistory')}</div>
                <OrderHistoryList items={values.employees_history}/>
                <button
                    onClick={() => setShowModal(true)}
                    type="button"
                    className="button border-button mt-3 w-full"
                >
                    {getLangText('openFullHistory')}
                </button>
                {showModal &&
                    <OrderHistoryModal
                        show={showModal}
                        hide={() => setShowModal(false)}
                        values={values}
                    />
                }
            </div>
        </SelmoProBoxWrapper>
    );
};

export default OrderHistory;
