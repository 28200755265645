import createReducer from "../../../shared/helpers/createReducer";
import Composition from "../../../shared/helpers/Composition";
import FormReducer from "../../../../../src_shared/form/FormReducer";
import DeliveryTypesModel from "../../../../../modules/models/order/DeliveryTypesModel";

export const getStateRoot = (state) => state.orders.details.deliveryType;
export const prefix = 'ORDERS_DETAILS_DELIVERY_TYPE_';

const getInitState = () => FormReducer.getInitState(DeliveryTypesModel.getModel());

const getReduceMap = () => new Composition(
	FormReducer.getReduceMap(),
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

