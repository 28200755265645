import React from 'react';
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../routes/RouterPath";
import Alert from "../../../shared/components/alert/Alert";
import useLang from "../../../../../src_shared/hooks/useLang";
import {LottieLoading} from "../../../../../App";

const SocialLoginPage = ({isError}) => {
    const {getLangText} = useLang();

    return (
        <div className="w-[100vw] h-[100vh] flex align-items-center justify-center bg-[white]">
            {isError ?
                <div>
                    <img alt="selmo" width={80} src="/assets/images/selmo-logo.png"/>
                    <h1 className="text-xl font-medium mt-6">{getLangText('sthWentWrongWarning')}</h1>
                    <Link
                        className="text-gray-600 text-sm flex items-center mt-2 hover:no-underline"
                        to={RouterPaths.Auth}>
                        <i className="icon-arrows mr-1 inline-block rotate-90 text-[8px]"/>
                        {getLangText('backButton')}</Link>
                </div> :
                <LottieLoading />
            }
            <Alert/>
        </div>
    );
};

export default SocialLoginPage;
