import React, {useState} from 'react';
import StatusFilter from "../../../../../../src_shared/components/filters/statusFilter/StatusFilter";
import FiltersActions from "../../../filters/FiltersActions";
import {getStateRoot as getFiltersStateRoot} from "../../../filters/filtersReducer";
import SearchInput from "../../../../shared/components/form/SearchInput";
import DateRangePicker from "../../../../shared/components/form/DateRangePicker";
import {useSelector} from "react-redux";
import useWindowDimensions from "../../../../messenger/hooks/useWindowDimensions/useWindowDimensions";
import useLang from "../../../../../../src_shared/hooks/useLang";
import ListActions from "../ListActions";
import {ExpendituresReasonTypes} from "../../../../shared/enums/ExpendituresTypes";

const Header = ({totalItems, search}) => {
    const [isFiltersVisible, setFiltersVisible] = useState(false);
    const {width} = useWindowDimensions();
    const {getLangText} = useLang();

    return (
        <div className="table-header">
            <div className="top-part">
                <div>
                    <h1 className="title">
                        {getLangText('expendituresLabel')}
                        {totalItems > 0 &&
                            <div className="badge">{totalItems}</div>
                        }
                    </h1>
                </div>
                <button
                    type="button"
                    className='toggle-filters'
                    onClick={() => setFiltersVisible(!isFiltersVisible)}
                >
                    <i className='icon-filter'/>
                </button>
            </div>
            {(width > 767 || isFiltersVisible) &&
                <div className="filters-part mt-sm-3">
                    <StatusFilter
                        ListActions={ListActions}
                        FiltersActions={FiltersActions}
                        getFiltersStateRoot={getFiltersStateRoot}
                        options={ExpendituresReasonTypes}
                        name="reasons"
                        selectAllName="reasonsSelectAll"
                        defaultSelectedLabel={getLangText('reasonLabel')}
                        className="grow"
                    />
                    <SearchInput
                        placeholder={getLangText('searchPlaceholder')}
                        className="mr-md-2 ml-0"
                        search={search}
                    />
                    <DateRangePicker
                        actions={ListActions}
                        getFiltersStateRoot={getFiltersStateRoot}
                        FiltersActions={FiltersActions}
                    />
                </div>
            }
        </div>
    );
};

export default Header;