import Composition from "../../../../../shared/helpers/Composition.js";
import createReducer from "../../../../../shared/helpers/createReducer.js";
import FormReducer from "../../../../../../../src_shared/form/FormReducer.js";

export const getStateRoot = (state) => state.lives.export;
export const prefix = 'LIVES_EXPORT_';

const getInitState = () => FormReducer.getInitState({
	displayType: 1,
	exportType: 1,
})

const getReduceMap = () => new Composition(
	FormReducer.getReduceMap(),
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

