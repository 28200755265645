import React from 'react';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {getSummaryText, setConfirmModalToggleVisibility} from "../details/default/LiveDetailsActions.js";
import {CONFIRM_MODAL_TYPES} from "../details/default/clients/confirmModals/ConfirmModalTypes";
import {useDispatch} from "react-redux";
import useLang from "../../../../src_shared/hooks/useLang";

const OrderStatus = ({cartSent, orderCreated, summarySent, client}) => {

	const dispatch = useDispatch();
	const {getLangText} = useLang();

	const getActiveStatusClass = (status) => status === '1' ? 'active' : '';

	const isOrderCreated = orderCreated > 0;

	const isOrderCreatedActiveClass = isOrderCreated ? 'active' : ''

	const onBasketStateActionClick = (e, type, rest) => {
		e.stopPropagation();
		dispatch(setConfirmModalToggleVisibility(type, client))
		dispatch(getSummaryText(rest))
	}

	return (
		<div className="statuses-icons">
			{isOrderCreated &&
				<button
					onClick={(e) => e.stopPropagation()}
					type="button"
					disabled
				>
					<OverlayTrigger
						placement='bottom'
						overlay={
							<Tooltip
								className="small-size"
								id="order-created"
							>
								{getLangText('orderCreatedLabel')}
							</Tooltip>
						}
					>
						<i className={`icon-new ${isOrderCreatedActiveClass}`}/>
					</OverlayTrigger>
				</button>
			}
			{isOrderCreated && client.facebook_id &&
				<>
					<button
						onClick={(e) => onBasketStateActionClick(e, CONFIRM_MODAL_TYPES.BASKET_STATE, 'cartText')}
						type="button"
						disabled={cartSent === '1'}
					>
						<OverlayTrigger
							placement='bottom'
							overlay={
								<Tooltip
									className="small-size"
									id="cart-sent"
								>
									{cartSent === '1' ? getLangText('basketStateSentLabel') : getLangText('sendBasketStateButton')}
								</Tooltip>
							}
						>
							<i className={`icon-cart-fill ${getActiveStatusClass(cartSent)}`}/>
						</OverlayTrigger>
					</button>
					<button
						onClick={(e) => onBasketStateActionClick(e, CONFIRM_MODAL_TYPES.SUMMARY, 'summaryText')}
						type="button"
						disabled={summarySent === '1'}
					>
						<OverlayTrigger
							placement='bottom'
							overlay={
								<Tooltip
									className="small-size"
									id="summary-sent"
								>
									{summarySent === '1' ? getLangText('summarySentLabel') : getLangText('sendSummaryButton')}
								</Tooltip>
							}
						>

							<i className={`icon-summary ${getActiveStatusClass(summarySent)}`}/>
						</OverlayTrigger>
					</button>
				</>
			}
		</div>
	);
};

export default OrderStatus;
