import React from 'react';

const PointItem = ({item, setValue, values}) => {
    return (
        <div
            style={{background: '#fff'}}
            className="animate-[fadeIn_.3s_ease_forwards]"
            key={item.id}
        >
            <div className="content payment-content">
                <div className="content__top-part">
                    <input
                        type="radio"
                        name="price"
                        checked={values.price === item.id}
                        onChange={() => setValue('price', item.id)}
                    />
                    <span className="checkmark"/>
                    <div className="flex-grow-1 flex items-center">
                        <div className="font-bold">
                            {item.description}
                        </div>
                        <div className="ml-auto font-medium text-desc">
                            {item.price} zł
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PointItem;