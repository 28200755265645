export default (initialState, actionReducerMap, prefix = '') => (
	(state = initialState, action) => {
		const actionType = action ? action.type : '';
		const noPrefixRegExp = new RegExp(`^${prefix}`);
		const noPrefixActionType = actionType.replace(noPrefixRegExp, '');
		const reduceFn = actionReducerMap[noPrefixActionType];
		if (reduceFn) {
			return reduceFn(state, action);
		}

		if (noPrefixActionType === 'RESET') {
			return initialState;
		}

		return state;
	}
);
