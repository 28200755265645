import Model from "../../../src_shared/modules/model/Model";

class FurgonetkaLabelModel extends Model {
	getModel() {
		return {
			template: 0,
		};
	}

	buildDTO(data) {
		return data;
	}
}

export default new FurgonetkaLabelModel();
