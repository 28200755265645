import React from 'react';
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../../routes/RouterPath";
import useLang from "../../../../../../src_shared/hooks/useLang";

const PickupBox = ({data}) => {
    const {getLangText} = useLang();

    return (
        <div className="settings-box">
            <Link to={RouterPaths.SettingsPaymentsPickup}>
                <div className="title">
                    {getLangText('settings_payments_pickup_title')}
                </div>
                <div className="desc">
                    {getLangText('settings_payments_pickup_description')}
                </div>
                <div className="button-place">
                    <div className="flex-grow-1">
                        {!!+data.pickup_active ?
                            <div className="small-status green">
                                <i className="icon-tick"/>
                                {getLangText('active_label')}
                            </div>
                            :
                            <div className="small-status red">
                                <i className="icon-cross"/>
                                {getLangText('not_active_label')}
                            </div>
                        }
                    </div>
                    <div className="button border-button">
                        {getLangText('button_go_to')}
                        <i className="icon-arrows"/>
                    </div>
                </div>
            </Link>
        </div>
    );
};

export default PickupBox;