import Model from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

class EmployeeSignupModel extends Model {
	getModel() {
		return {
			email: '',
			name: '',
			password: '',
		};
	}

	getValidators() {
		return {
			email: ValidationError.notEmpty,
			name: ValidationError.notEmpty,
			password: ValidationError.combine(
				ValidationError.notEmpty,
				ValidationError.validatePassword,
			),
		};
	}

	buildDTO(data) {
		return {
			email: data.email,
			name: data.name,
			password: data.password,
		}
	}
}

export default new EmployeeSignupModel();
