import React, {useState} from 'react';
import useLang from "../../../../../../../src_shared/hooks/useLang";
import SimpleTags from "../../../../../../../src_shared/components/form/tags/SimpleTags";

const CodeName = ({values, setValue, isValidationActive}) => {
    const {getLangText} = useLang();
    const [isInputFocus, setInputFocus] = useState(false);

    const onBlur = (text) => {
        const newTag = {text, id: text, className: 'grey big-size'}
        if (!!text?.length && values.code.every((i) => i?.text?.toLowerCase() !== text?.toLowerCase()) ) {
            setValue('code', [...values.code, newTag])
        }
        setInputFocus(false)
    }

    return (
        <div className="rounded-[8px] border border-[#DFE1E4] p-3">
            <div className="font-bold">{getLangText('enterCodeNameLabel')}</div>
            <div className="text-desc font-medium">{getLangText('createUniqCodeLabel')}</div>
            {(isValidationActive && !values.code?.length) &&
                <div
                    className="form-info-box red align-items-center small-line-height w-100 mb-0 mt-2">
                    <div>
                        <i className="icon-info-c"/>
                    </div>
                    <div className="title">{getLangText('enterAtleastOneCodeLabel')}</div>
                </div>
            }
            <div className="tooltip-parent">
                {!values.code.filter((i) => !+i.is_deleted).length && isInputFocus &&
                    <div className="tooltip-content left-position">
                        {getLangText('clickEnterToCodeLabel')}
                    </div>
                }
                <div className="react-tag-container in-order big-size">
                    <SimpleTags
                        tags={values.code}
                        setValue={(value) => setValue('code', value)}
                        placeholder={`${getLangText('addButton')} +`}
                        onFocus={() => setInputFocus(true)}
                        onBlur={onBlur}
                    />
                </div>
            </div>
            <div className="form-info-box w-100 mt-3">
                <div>
                    <i className="icon-info-c"/>
                </div>
                <div>
                    <div className="title">{getLangText('provideThisCodeToClientLabel')}</div>
                    <div className="desc">{getLangText('provideThisCodeToClientSubLabel')}</div>
                </div>
            </div>
        </div>
    );
};

export default CodeName;
