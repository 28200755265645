import React from 'react';
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import useLang from "../../../../src_shared/hooks/useLang";

const PremiumInfoBar = () => {
    const {getLangText} = useLang();
    const {userData} = useSelector((state) => state.session);

    if (+userData.client_type === 2) return null;

    if (!userData?.user_info?.premium_date_info && !userData?.user_info?.daysLeft?.trial_day1) {
        return null;
    }

    if (userData?.user_info.status && +userData?.user_info?.daysLeft?.normalAmount >= 4) {
        return null
    }


    if (userData?.user_info.status && +userData?.user_info?.daysLeft?.normalAmount < 4) {
        return (
            <Link to={RouterPaths.Payments} className="selmo-trial-info red">
                {+userData?.user_info?.daysLeft?.normalAmount === 0 ?
                    <>
                        <span className="text-[#fff] text-xs sm:p-1.5 rounded-md sm:bg-[rgba(0,0,0,0.2)] mr-1">
						{getLangText('trial_info_text_5')}
						</span>
                        <span className="mobile-hidden">&nbsp;{getLangText('trial_info_text_6')}</span>
                        <button>&nbsp;{getLangText('trial_info_text_3')}</button>
                    </> :
                    +userData?.user_info?.daysLeft?.normalAmount < 4 &&
                    <>
                        {getLangText('inFewDaysLabel')}&nbsp;
                        <div className="selmo-trial-info__days">
                            <div>{+userData?.user_info?.daysLeft?.normalAmount}</div>
                        </div>
                        {+userData?.user_info?.daysLeft?.normalAmount === 1 ?
                            getLangText('trial_info_text_7') :
                            getLangText('trial_info_text_8')
                        }&nbsp;
                        {getLangText('trial_info_text_9')}
                        <span className="mobile-hidden">&nbsp;{getLangText('trial_info_text_6')}</span>
                        <button>{getLangText('trial_info_text_3')}</button>
                    </>
                }
            </Link>
        )
    }

    return (
        <Link to={RouterPaths.Payments}
              className={`selmo-trial-info ${+userData?.user_info?.daysLeft?.trial_day1 < 4 ? 'red' : ''}`}>
            {+userData?.user_info?.daysLeft?.trial_day1 === 0 ?
                <>
                <span className="text-[#fff] text-xs sm:p-1.5 rounded-md sm:bg-[rgba(0,0,0,0.2)] mr-1">
				    {getLangText('trial_info_text_1')}</span>&nbsp;
                    <span className="mobile-hidden">&nbsp;{getLangText('trial_info_text_2')}</span>
                    <button>&nbsp;{getLangText('trial_info_text_3')}</button>
                </>
                :
                <>
                    <div className="selmo-trial-info__days">
                        {!!userData?.user_info?.daysLeft?.trial_day1 &&
                            <div>{+userData?.user_info?.daysLeft?.trial_day1 + 1}</div>
                        }
                    </div>
                    {getLangText('trial_info_text_4')}
                    <button>{getLangText('trial_info_text_3')}</button>
                </>
            }
        </Link>
    );
};

export default PremiumInfoBar;
