import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import {RouterPaths} from "./RouterPath";
import ProductsList, {firstPageBenefits} from "../app/products/lists/productsList/List";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {getStateRoot as getModalFormStateRoot} from "../app/products/modalForm/formReducer";
import {getStateRoot as getProductsListStateRoot} from "../app/products/lists/productsList/listReducer";
import ProductFormPage from "../app/products/form/Page";
import ProductsLatestList from "../app/products/lists/latestProductsList/List";
import ProductsReserveList from "../app/products/lists/reserveProductsList/List";
import {SELMO_APP_PERMISSION} from "../app/shared/enums/SelmoPermissions";
import PermissionRoute from "./PermissionRoute";
import CategoriesList from "../app/products/categories/list/List";
import CategoryRoute from "./products/categories/CategoryRoute";
import CollectionsRoute from "./products/collections/CollectionsRoute";
import ReelsRoute from "./products/reels/ReelsRoute";
import TutorialPageBox from "../../src_shared/components/ui/TutorialPageBox/TutorialPageBox";
import {HEADER_BOX_PRODUCTS} from "../app/shared/enums/TutorialVideoStatsTypes";
import ProductModalForm from "../app/products/modalForm/ModalForm";
import EditProductModalFormActions from "../app/products/modalForm/FormActions";
import ExpendituresList from "../app/products/lists/expenditures/List";
import ManufacturersRoute from "./products/manufacturers/ManufacturersRoute";
import {
    USER_PERMISSIONS_PRODUCTS_BOUGHT_TAB_VISIBILITY,
    USER_PERMISSIONS_PRODUCTS_EXPENDITURE_TAB_VISIBILITY,
    USER_PERMISSIONS_PRODUCTS_MENU_CATEGORIES_VISIBILITY,
    USER_PERMISSIONS_PRODUCTS_MENU_COLLECTIONS_VISIBILITY,
    USER_PERMISSIONS_PRODUCTS_MENU_MANUFACTURERS_VISIBILITY, USER_PERMISSIONS_PRODUCTS_MENU_VIDEO_VISIBILITY,
    USER_PERMISSIONS_PRODUCTS_RESERVE_LIST_TAB_VISIBILITY
} from "../app/shared/enums/UserPermissionsTypes";
import UserPermissionRoute from "./UserPermissionRoute";
import MobileReelsRoute from "./products/mobileReels/MobileReelsRoute";
import ProductsRemovedList from "../app/products/lists/removedList/List";

const ProductsRoute = ({path}) => {
    const dispatch = useDispatch();
    const [{modalVisible}, {isFirstStepPage}] = useSelector(createSelector(getModalFormStateRoot, getProductsListStateRoot, (stateRoot, listStateRoot) => [
        stateRoot,
        listStateRoot
    ]));
    const {first_step_products} = useSelector((state) => state.session.userData);

    const hideModal = () => dispatch(EditProductModalFormActions.modal.hideModal());

    return (
        <PermissionRoute allowedPermissions={[SELMO_APP_PERMISSION]}>
            {(!isFirstStepPage && !first_step_products) &&
                <TutorialPageBox
                    page='products'
                    videoStatsName={HEADER_BOX_PRODUCTS}
                    benefits={firstPageBenefits}
                    title="asideProductsLabel"
                />
            }
            <Route path={path}>
                <Switch>
                    <Route
                        exact
                        path={path}
                        component={ProductsList}
                    />
                    <UserPermissionRoute
                        path={RouterPaths.ProductsLatestList}
                        allowedPermissions={[USER_PERMISSIONS_PRODUCTS_BOUGHT_TAB_VISIBILITY]}
                    >
                        <Route
                            exact
                            path={RouterPaths.ProductsLatestList}
                            component={ProductsLatestList}
                        />
                    </UserPermissionRoute>
                    <UserPermissionRoute
                        path={RouterPaths.ProductsReserveList}
                        allowedPermissions={[USER_PERMISSIONS_PRODUCTS_RESERVE_LIST_TAB_VISIBILITY]}
                    >
                        <Route
                            exact
                            path={RouterPaths.ProductsReserveList}
                            component={ProductsReserveList}
                        />
                    </UserPermissionRoute>
                    <UserPermissionRoute
                        path={RouterPaths.ProductsExpendituresList}
                        allowedPermissions={[USER_PERMISSIONS_PRODUCTS_EXPENDITURE_TAB_VISIBILITY]}
                    >
                        <Route
                            exact
                            path={RouterPaths.ProductsExpendituresList}
                            component={ExpendituresList}
                        />
                    </UserPermissionRoute>
                    <Route
                        exact
                        path={RouterPaths.ProductsRemovedList}
                        component={ProductsRemovedList}
                    />
                    <Route
                        exact
                        path={RouterPaths.Product}
                        component={ProductFormPage}
                    />
                    <UserPermissionRoute
                        path={RouterPaths.CategoriesList}
                        allowedPermissions={[USER_PERMISSIONS_PRODUCTS_MENU_CATEGORIES_VISIBILITY]}
                    >
                        <Route
                            exact
                            path={RouterPaths.CategoriesList}
                            component={CategoriesList}
                        />
                        <CategoryRoute path={RouterPaths.Category}/>
                    </UserPermissionRoute>
                    <UserPermissionRoute
                        path={RouterPaths.CollectionsList}
                        allowedPermissions={[USER_PERMISSIONS_PRODUCTS_MENU_COLLECTIONS_VISIBILITY]}
                    >
                        <CollectionsRoute path={RouterPaths.CollectionsList}/>
                    </UserPermissionRoute>
                    <UserPermissionRoute
                        path={RouterPaths.ManufacturersList}
                        allowedPermissions={[USER_PERMISSIONS_PRODUCTS_MENU_MANUFACTURERS_VISIBILITY]}
                    >
                        <ManufacturersRoute path={RouterPaths.ManufacturersList}/>
                    </UserPermissionRoute>
                    <UserPermissionRoute
                        path={RouterPaths.ReelsList}
                        allowedPermissions={[USER_PERMISSIONS_PRODUCTS_MENU_VIDEO_VISIBILITY]}
                    >
                        <ReelsRoute path={RouterPaths.ReelsList}/>
                    </UserPermissionRoute>
                    <MobileReelsRoute path={RouterPaths.MobileReelsList}/>
                    <Redirect to={RouterPaths.ProductsList}/>
                </Switch>
            </Route>
            {modalVisible &&
                <ProductModalForm
                    modalVisible={modalVisible}
                    hideModal={hideModal}
                />
            }
        </PermissionRoute>
    );
};

export default ProductsRoute;
