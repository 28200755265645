import React from 'react';
import {useUnitPrice} from "../../../../../shared/helpers/Price";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import ServerImage from "../../../../../shared/components/serverImage/ServerImage";

const Product = ({item, setActiveProduct, activeProduct, values}) => {
    const {getPrice} = useUnitPrice();
    const {getLangText} = useLang();

    return (
        <div
            onClick={() => !+item.collection_id && setActiveProduct(item)}
            key={item.id}
            className={`content ${!+item.collection_id ? 'cursor-pointer' : ''} payment-content d-block w-full text-left hover:bg-gray-50 transition-all ${+activeProduct?.id === +item.id ? 'active' : ''}`}
        >
            <div
                style={{paddingTop: !!+item.collection_id ? 8 : 15, paddingBottom: !!+item.collection_id ? 8 : 15}}
                className="content__top-part"
            >
                <div className="flex-grow-1 d-flex align-items-center">
                    <figure className="product-avatar">
                        {item.photo_small && item.photo_small !== '0' ?
                            <ServerImage src={item.photo_small}/> :
                            <img src="/assets/images/default/product.svg"/>
                        }
                    </figure>
                    <div>
                        <div className="label">
                            {item.name}
                        </div>
                        <div className="data">
                            {getPrice(item.price)}{' • '}
                            {getLangText('codeLabel')}&nbsp;
                            <strong>{item.selmo_code}</strong>
                        </div>
                    </div>
                </div>
                <div className="space-y-1 flex flex-col">
                    <button
                        onClick={() => setActiveProduct(item)}
                        type="button"
                        className={`text-right whitespace-nowrap text-xs h-[31px] transition-all font-bold ${(+activeProduct?.id === +item.id && !+values.active_collection_id) ? 'text-[white] bg-[#3C61EA] rounded-[5px] px-[12px]' : 'button border-button'}`}
                    >
                        {getLangText((+activeProduct?.id === +item.id && !+values.active_collection_id) ? 'selectedProductLabel' : 'selectProductLabel')}
                    </button>
                    {!!+item.collection_id &&
                        <button
                            onClick={() => setActiveProduct(item, item.collection_id)}
                            type="button"
                            className={`text-right whitespace-nowrap h-[31px] transition-all text-xs font-bold ${(+activeProduct?.id === +item.id && +values.active_collection_id === +item.collection_id) ? 'text-[white] bg-[#3C61EA] rounded-[5px] px-[12px]' : 'button border-button'}`}
                        >
                            {getLangText((+activeProduct?.id === +item.id && +values.active_collection_id === +item.collection_id) ? 'selectedCollection' : 'selectCollection')}
                        </button>
                    }
                </div>
            </div>
        </div>
    );
};

export default Product;
