import Model from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

class SettingsPaypalAutomationModel extends Model {
	getModel() {
		return {
			active: '0',
			paypal_country_id: null,
		};
	}

	getValidators() {
		return {
			active: ValidationError.skip,
			paypal_country_id: ValidationError.notEmpty,
		};
	}

	buildDTO(data) {
		const {active, paypal_country_id} = data;
		return {
			active,
			paypal_country_id,
		}
	}
}

export default new SettingsPaypalAutomationModel();
