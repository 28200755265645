import React from 'react';
import Modal from "react-bootstrap/Modal";
import useLang from "../../../../../src_shared/hooks/useLang";
import Loader from "../../../../../src_shared/components/loader/Loader";
import useSubmitData from "../../../../../src_shared/hooks/useSubmitData";
import {services} from "../../../../RestServices";
import {RouterPaths} from "../../../../routes/RouterPath";
import {useHistory} from "react-router-dom";
import useCreatePost from "./useCreatePost";

const benefits = [
    {id: 1, title: 'planAhead', desc: 'planAheadSubLabel'},
    {id: 2, title: 'analyzeStats', desc: 'analyzeStatsSubLabel'},
    {id: 3, title: 'improveYourPosts', desc: 'improveYourPostsSubLabel'},
]

const NoDataModal = ({modalVisible, hideModal}) => {
    const {getLangText} = useLang();
    const history = useHistory();

    const createPostCallback = (response) => {
        if (modalVisible) {
            hideModal(false);
        }
        history.push(`${RouterPaths.SalesPost}/${response.id}`)
    }

    const {createPost, isWaitingForPost} = useCreatePost(createPostCallback)

    return (
        <Modal
            dialogClassName="default-modal small-modal w-850"
            show={modalVisible}
            onHide={hideModal}
        >
            <Modal.Body>
                <div className="sm:flex items-center">
                    <div className="p-3 py-4 mr-sm-4">
                        <button
                            onClick={hideModal}
                            type="button"
                            className="absolute sm:hidden top-3 right-3 w-[24px] h-[24px] bg-[#fff] text-[#6B7280] rounded-full flex items-center justify-center text-[10px]"
                        >
                            <i className="icon-cross block ml-[1px] -mt-[1px]"/>
                        </button>
                        <div className="text-2xl font-bold mb-3">{getLangText('addPostsBySelmo')}</div>
                        <div
                            className="text-sm font-medium text-desc mb-3">{getLangText('addPostsBySelmoSubLabel')}</div>
                        <div className="mt-4">
                            <div
                                className="font-bold text-sm text-[#6B7280] mb-3">{getLangText('pricingWhyWorthyTitle')}</div>
                            <div className="space-y-3">
                                {benefits.map((benefit) => (
                                    <div
                                        key={benefit.id}
                                        className="flex text-sm"
                                    >
                                        <i className="icon-tick-c text-[#3CD9A0] text-lg -mt-1 mr-2"/>
                                        <div>
                                            <div className="text-[#101827] font-bold">{getLangText(benefit.title)}</div>
                                            <div
                                                className="font-medium text-desc">{getLangText(benefit.desc)}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="flex flex-wrap items-center sm:justify-start justify-center sm:space-x-5 mt-5">
                            <button
                                onClick={createPost}
                                type="button"
                                style={{fontSize: 12}}
                                className="bg-[#000000] sm:w-auto w-full text-[#fff] rounded-[5px] text-xs py-[10px] px-4 font-bold button black"
                            >
                                {isWaitingForPost && <Loader isLoading/>}
                                {getLangText('addPost')}
                            </button>
                            <button
                                onClick={hideModal}
                                type="button"
                                className="text-xs sm:w-auto w-full text-desc font-bold sm:mt-0 mt-3.5 sm:p-0 p-2.5"
                            >
                                {getLangText('nextTime')}
                            </button>
                        </div>
                    </div>
                    <div className="relative">
                        <img
                            className="sm:w-[370px] rounded-[8px] sm:min-w-[370px] sm:-mr-[10px]"
                            src="/assets/images/multiLanguage/pl/salesPosts/no-data.webp" alt=""/>
                        <button
                            onClick={hideModal}
                            type="button"
                            className="absolute sm:flex hidden top-3 right-3 w-[24px] h-[24px] bg-[#fff] text-[#2464EB] rounded-full items-center justify-center text-[10px]"
                        >
                            <i className="icon-cross block ml-[1px] -mt-[1px]"/>
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default NoDataModal;
