import FormReducer from "../../../../../../../../src_shared/form/FormReducer.js";
import Composition from "../../../../shared/helpers/Composition.js";
import createReducer from "../../../../shared/helpers/createReducer.js";

export const getStateRoot = (state) => state.admin.sales.stats.filtersPanel.form;

const ALL_VALUE = 'all'
export const prefix = 'ADMIN_SALES_STATS_FILTERS_PANEL_FORM';

const model = {
	owner: ALL_VALUE,
	type: [],
	owner_id: [],
	segmentationType: ALL_VALUE,
	has_live: ALL_VALUE,
	has_ecommerce: ALL_VALUE,
	is_professional: ALL_VALUE,
	has_posts: ALL_VALUE,
	has_comments: ALL_VALUE,
	messenger: ALL_VALUE,
	note: ALL_VALUE,
	social_type: ALL_VALUE,
}

export const getInitState = () => FormReducer.getInitState(model,  model)

export const getReduceMap = () => new Composition(
	FormReducer.getReduceMap(),
);

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();
