import React from 'react';
import {
	FUNCTIONALITY_RANGE_MONTHLY,
	FUNCTIONALITY_RANGE_TO_END_PLAN
} from "../functionalityPaymentRange/FunctionalityPaymentRangeEnums";
import {variants} from "../../../../../src_shared/helpers/helper";
import {dniVariants} from "../../Form";
import useLang from "../../../../../src_shared/hooks/useLang";
import {useUnitPrice} from "../../../shared/helpers/Price";

const FunctionalitySummary = ({values, selectedSubscription}) => {
	const {getLangText} = useLang();
	const {getPrice} = useUnitPrice();

	return (
		<div className="prices-wrapper border-top-0 mt-4">
			<div className="section-label mb-0">
				{getLangText('summaryLabel')}
			</div>
			{selectedSubscription === FUNCTIONALITY_RANGE_TO_END_PLAN &&
				<div className="partials-prices">
					<div>
						<div className="label">
							{values.functionality_name}
						</div>
						<div className="value">
							{getPrice(values.price, values.currencyText)}{' / '}{getLangText('monthLabel')}
						</div>
					</div>
				</div>
			}
			<div className="total-price">
				<div>
					{selectedSubscription === FUNCTIONALITY_RANGE_MONTHLY ?
						<div className="label">{getLangText('paymentNetPriceToPayLabel')}</div>:
						<div className="label">{getLangText('paymentNetPriceToPayTodayLabel')}</div>
					}
					{selectedSubscription === FUNCTIONALITY_RANGE_TO_END_PLAN ?
						<div className="value">
							{getPrice(values.total_price, values.currencyText)}
							<div className="sub-value">
								{getLangText({
									key: 'paymentsPaymentForLabel',
									data: [values.days_left, getLangText(variants(values.days_left, dniVariants))]
								})}
							</div>
						</div> :
						<div className="value">
							{getPrice(values.price, values.currencyText)}
						</div>
					}
				</div>
			</div>
		</div>
	);
};

export default FunctionalitySummary;
