import ListActions from "../../../../../../src_shared/list/ListActions";
import axios from "axios";
import {showAlert} from "../../alert/AlertActions";
import ModalActions from "../../../../../../src_shared/modal/ModalActions";
import WaitingActions from "../../../../../../src_shared/list/waiting/WaitingActions";
import {addOrderModalToggleVisibility} from "../../../../messenger/addOrderModal/AddOrderModalActions";
import {setViewType} from "../../../../messenger/addOrderModal/components/tabs/TabsActions";
import OrdersListActions from "../../../../orders/list/ListActions";
import {selmoUrl} from "../../../../../../src_shared/api/api";
import api from "../../../../../../services/axios/axios";
import {SKLEP_ODZIEZOWY_U_EMI_SHOP_ID, TEST_ACCOUNT_SHOP_ID} from "../../../../../../src_shared/enums/TrustedShopsIds";

export class OrderProductsFormActions extends ListActions {
    constructor(config) {
        super();
        this.prefix = config.prefix;
        this.getStateRoot = config.getStateRoot;
        this.modal = ModalActions.createActions(this.prefix);
        this.waiting = WaitingActions.createActions(this.prefix);

    }

    updateFieldInProduct(field, value, id) {
        return {
            type: `${this.prefix}UPDATE_FIELD_IN_PRODUCT`,
            field,
            value,
            id,
        }
    }

    updateFieldsInProduct(fields, id) {
        return {
            type: `${this.prefix}UPDATE_FIELDS_IN_PRODUCT`,
            fields,
            id,
        }
    }

    addNewProduct() {
        return {
            type: `${this.prefix}ADD_NEW_PRODUCT`,
        }
    }

    removeProduct(id) {
        return {
            type: `${this.prefix}REMOVE_PRODUCT`,
            id,
        }
    }

    updateOrderData(data) {
        return {
            type: `${this.prefix}UPDATE_ORDER_DATA`,
            data
        }
    }

    showCancelOrderModal(toggle) {
        return {
            type: `${this.prefix}SHOW_CANCEL_ORDER_MODAL`,
            toggle
        }
    }

    resetOrderProducts() {
        return {
            type: `${this.prefix}RESET_ORDER_PRODUCTS`,
        }
    }

    getOrderProducts(id, activeConversation, viewType = 'edit', withOpen) {
        return async (dispatch) => {
            dispatch(this.waiting.startWaiting())
            dispatch(setViewType(viewType))

            try {
                const response = await api.get(`${selmoUrl}/api/order/?order_id=${id}&user=${activeConversation.user_id}`)
                const {message} = response.data

                dispatch(this.updateOrderData(message))

            } catch (e) {
                if (e.message === "canceled") {
                    return;
                }
                dispatch(addOrderModalToggleVisibility(false));
                dispatch(showAlert('theOrderDoesNotExistLabel', '', 'alert-fail'))
            } finally {
                dispatch(this.waiting.stopWaiting())
            }
        }
    }

    setEditOrderWaiting(waiting) {
        return {
            type: `${this.prefix}SET_EDIT_ORDER_WAITING`,
            waiting,
        }
    }

    validateOrderProducts(products) {
        return products.some((product) => product.product_name && product.product_price === '')
    }

    saveOrder() {
        return async (dispatch, getState) => {
            const {innerWidth: width} = window;
            const {data, manualProducts} = this.getStateRoot(getState())
            // const {activeConversation} = getState().messenger.chatList;
            const {id} = getState().session.userData;

            if (this.validateOrderProducts(manualProducts)) {
                dispatch(this.updateField('productActiveValidation', true))
                return
            }

            dispatch(this.setEditOrderWaiting(true))

            try {
                await api.put(`${selmoUrl}/api/order`, {
                    status: data.status,
                    products: manualProducts,
                    order_id: data.shop_order_id,
                })
                dispatch(this.getOrderProducts(data.shop_order_id, {user_id: data.customer_facebook_id}))
                if (width < 767) {
                    if (+id !== SKLEP_ODZIEZOWY_U_EMI_SHOP_ID && +id !== TEST_ACCOUNT_SHOP_ID) {
                        dispatch(addOrderModalToggleVisibility(false))
                        dispatch(setViewType('edit'))
                    }
                }
                if (+data.status === 9) {
                    dispatch(this.showCancelOrderModal(true));
                } else {
                    dispatch(showAlert('yuhuLabel', 'successfulSavedLabel'))
                }
            } catch (e) {
                console.warn('editOrder `api/order` action failed')
                dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
                //	TODO
            } finally {
                dispatch(this.updateField('productActiveValidation', false))
                dispatch(this.setEditOrderWaiting(false))
            }
        }
    }

    updateField(field, value) {
        return {
            type: `${this.prefix}UPDATE_FIELD`,
            field,
            value,
        }
    }

    resetData() {
        return {
            type: `${this.prefix}RESET_DATA`,
        }
    }

    hideModal() {
        return (dispatch) => {
            dispatch(this.modal.hideModal())
            dispatch(this.resetData())
            dispatch(this.resetOrderProducts())
        }
    }

    afterSubmit() {
        return (dispatch) => {
            dispatch(this.waiting.stopWaiting())
            dispatch(this.hideModal())
            dispatch(OrdersListActions.loadData())
        }
    }
}

export default OrderProductsFormActions;
