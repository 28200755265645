import React, {useEffect, useState} from "react";
import useForm from "../../../../../src_shared/form/useForm";
import FormActions from "./FormActions";
import {useParams} from "react-router-dom";
import {Modal} from "react-bootstrap";
import Loader from "../../../shared/Loader";
import InpostShippingModal from "./inpostShipping/Form";
import useLang from "../../../../../src_shared/hooks/useLang";
import MondialRelayPointModal from "../../../shared/components/MondialRelaisPointModal/MondialRelaisPointModal";
import {services} from "../../../../RestServices";
import OrlenPointModal from "../../../shared/components/OrlenPointModal/OrlenPointModal";
import {
    DPD_POINT_MODAL, GLS_POINT_MODAL,
    INPOST_POINT_MODAL,
    MONDIAL_RELAY_POINT_MODAL,
    ORLEN_POINT_MODAL
} from "../../../basket/secondStep/SecondStep";
import DeliveryType from "../../../basket/deliveryMethods/components/DeliveryType";
import DpdPointModal from "../../../shared/components/DpdPointModal/DpdPointModal";
import GlsPointModal from "../../../shared/components/GlsPointModal/GlsPointModal";

const DeliveryTypeForm = ({data}) => {
    const params = useParams()
    const {getLangText} = useLang();
    const [activeShippingId, setActiveShippingId] = useState(data.shippingData.shipping);
    const [isPointModalOpen, setIsPointModalOpen] = useState(null);

    const {
        data: {values, setValue, updateValues},
        form: {isWaiting, onSave, getFieldProps},
        modal: {hideModal, modalVisible},
    } = useForm({
        FormActions,
        params,
    });

    useEffect(() => {
        if (!data.shippingData.shipping) {
            setValue('shipping', values.deliveries[0]?.id)
        }
    }, [values.deliveries])

    return (
        <>
            <Modal
                show={modalVisible}
                className="add-order-modal"
                dialogClassName="default-modal medium-modal"
                backdropClassName={`add-order-modal-backdrop ${!!isPointModalOpen ? 'with-extra-modal' : ''}`}
                onHide={hideModal}
            >
                <Modal.Header>
                    <Modal.Title>{getLangText('editDeliveryMethodLabel')}</Modal.Title>
                    <button
                        onClick={hideModal}
                        type="button"
                        className="button"
                    >
                        <i className="icon-cross"/>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <DeliveryType
                        {...getFieldProps('shipping')}
                        values={values}
                        data={data}
                        updateValues={updateValues}
                        setValue={setValue}
                        setIsPointModalOpen={setIsPointModalOpen}
                        setActiveShippingId={setActiveShippingId}
                        currency={data.currency_text}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <button
                        onClick={hideModal}
                        type="button"
                        className="button border-button"
                    >
                        {getLangText('cancelButton')}
                    </button>
                    {!!values.deliveries.length &&
                        <button
                            type="button"
                            className="button primary"
                            onClick={onSave}
                        >
                            {isWaiting && <Loader isLoading/>}
                            {getLangText('saveButton')}
                        </button>
                    }
                </Modal.Footer>
            </Modal>
            {isPointModalOpen === INPOST_POINT_MODAL &&
                <InpostShippingModal
                    data={data}
                    formValues={values}
                    shippingId={activeShippingId}
                    updateValues={updateValues}
                    show={isPointModalOpen === INPOST_POINT_MODAL}
                    hideModal={() => setIsPointModalOpen(null)}
                />
            }
            {isPointModalOpen === MONDIAL_RELAY_POINT_MODAL &&
                <MondialRelayPointModal
                    params={{
                        city: data.shippingData.city,
                        country: data.shippingData.country,
                        zip_code: data.shippingData.zip_code,
                    }}
                    updateValues={updateValues}
                    show={isPointModalOpen === MONDIAL_RELAY_POINT_MODAL}
                    hideModal={() => setIsPointModalOpen(null)}
                    shippingId={activeShippingId}
                    rest={services.API_SHIPMENT_PARCEL_POINTS}
                />
            }
            {isPointModalOpen === ORLEN_POINT_MODAL &&
                <OrlenPointModal
                    updateValues={updateValues}
                    show={isPointModalOpen === ORLEN_POINT_MODAL}
                    hideModal={() => setIsPointModalOpen(null)}
                    shippingId={activeShippingId}
                />
            }
            {isPointModalOpen === DPD_POINT_MODAL &&
                <DpdPointModal
                    updateValues={updateValues}
                    show={isPointModalOpen === DPD_POINT_MODAL}
                    hideModal={() => setIsPointModalOpen(null)}
                    shippingId={activeShippingId}
                    params={{
                        city: data.shippingData.city,
                        zip_code: data.shippingData.zip_code,
                        street: data.shippingData.street,
                    }}
                />
            }
            {isPointModalOpen === GLS_POINT_MODAL &&
                <GlsPointModal
                    updateValues={updateValues}
                    show={isPointModalOpen === GLS_POINT_MODAL}
                    hideModal={() => setIsPointModalOpen(null)}
                    shippingId={activeShippingId}
                    params={{
                        city: data.shippingData.city,
                        zip_code: data.shippingData.zip_code,
                        street: data.shippingData.street,
                    }}
                />
            }
        </>
    );
};

export default DeliveryTypeForm;



