import FormReducer from "../../../../../src_shared/form/FormReducer";
import Composition from "../../../shared/helpers/Composition";
import createReducer from "../../../shared/helpers/createReducer";
import EditClientModel from "../../../../../modules/models/EditClientModel";

export const getStateRoot = (state) => state.clients.details.form;
export const prefix = 'CLIENTS_DETAILS_FORM_';

export const getInitState = () => FormReducer.getInitState(EditClientModel.getModel(), {
	clientModalVisible: false,
})

export const getReduceMap = () => new Composition(
	FormReducer.getReduceMap(),
	{
		SHOW_CLIENT_MODAL: (state, action) => ({...state, clientModalVisible: action.toggle})
	}
);

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();
