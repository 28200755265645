import React, {useState} from 'react';
import useFormLocal from "../../../src_shared/hooks/useFormLocal";
import {services} from "../../RestServices";
import Field from "../../../src_shared/components/form/field/Field";
import Button from "../../../src_shared/components/ui/Button/Button";
import DPDPromoModel from "../../../modules/models/dpd/DPDPromoModel";
import FacebookCircle from "../../../src_shared/components/ui/Iocns/FacebookCircle";
import SharePostModal from "../dashboard/components/SharePostModal";
import PhotoPreview from "../../../src_shared/components/ui/photoPreview/PhotoPreview";
import usePhotoPreview from "../../../src_shared/components/ui/photoPreview/usePhotoPreview";
import AfterSubmitModal from "./components/AfterSubmitModal";

const DpdPromo = () => {

    const [showModal, setShowModal] = useState(false);
    const [showAfterSubmitModal, setShowAfterSubmitModal] = useState(false);
    const {showPreviewModal, hidePreviewModal, openPreviewModal} = usePhotoPreview();
    const imageLink = '/assets/images/dpd-graphic-promo.png'
    const [{onSave, isWaiting, getFieldProps, values}] = useFormLocal({
        rest: services.API_DPD_PICKUP_PROMO,
        model: DPDPromoModel,
        afterSubmit: () => {
            setShowAfterSubmitModal(true);
        }
    });

    return (
        <>
            <div className="bg-[#fff] rounded-[5px] shadow-default-small p-6 sm:p-12">
                <div className="sm:mt-0 -mt-6 sm:mx-0 -mx-6">
                    <img
                        className="sm:rounded-[12px]"
                        src="/assets/images/dpd-selmo-promo.webp"
                        alt=""
                    />
                </div>
                <div className="max-w-[540px] mx-auto mt-6 sm:mt-12">
                    <div className="flex items-center justify-center mb-4">
                        <img className="" alt="selmo" width={100} src="/assets/images/svg/selmo-paczka-logo.svg"/>
                        <div className="sm:mx-10 mx-6 h-[50px] w-[1px] bg-gray-200"/>
                        <img style={{width: 'auto', height: '30px'}} src="/assets/images/svg/dpd-pick-up-logo.svg"
                             alt="Shopify"/>
                    </div>
                    <h1 className="sm:text-3xl text-xl text-center font-bold whitespace-pre-wrap sm:mb-[24px] mb-3.5">
                        Skorzystaj z promocji{'\n'}
                        5 zł za przesyłkę DPD Pickup
                    </h1>
                    <div
                        className="form-info-box w-100 mt-3 mb-3">
                        <div>
                            <i className="icon-info-c"/>
                        </div>
                        <div>
                            <div className="title">Promocja trwa w okresie 24-31 października 2024</div>
                            <div className="desc">W trakcie trwania promocji generujesz paczki za 4.99 zł</div>
                        </div>
                    </div>
                    <form onSubmit={onSave}>
                        <div className="border rounded-[8px] sm:p-[24px] p-[12px] mb-3">
                            <div className="font-bold text-[16px] sm:text-xl mb-3.5 sm:mb-7">
                                Cena brutto za etykietę DPD Pickup
                            </div>
                            <div className="grid grid-cols-2 gap-3 sm:gap-5">
                                <div>
                                    <div className="text-sm mb-1.5">Twój koszt <strong>w czasie promocji</strong></div>
                                    <Field
                                        showLabel={false}
                                        disabled
                                        value={4.99}
                                        addon="zł"
                                    />
                                </div>
                                <div>
                                    <div className="text-sm mb-1.5">Twój koszt <strong>po promocji</strong></div>
                                    <Field
                                        disabled={true}
                                        addon="zł"
                                        value={11.49}
                                        showLabel={false}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center bg-[#F0FAFF] rounded-[8px] py-2 px-3">
                                <div className="text-xs sm:text-sm font-bold grow">Ustal koszt dla klienta w koszyku</div>
                                <Field
                                    {...getFieldProps('price')}
                                    className="mb-0 max-w-[150px]"
                                    showLabel={false}
                                    extraProps={{required: true, autoFocus: true}}
                                    addon="zł"
                                />
                            </div>
                        </div>
                        <Button
                            type="submit"
                            isWaiting={isWaiting}
                            title={values.id ? 'editDeliveryMethodCost' : 'activateDeliveryMethod'}
                            className="button primary btn-proceed big-font w-full mt-3"
                        />
                    </form>
                    <div className="font-medium text-sm text-[#4B5563] mt-4 leading-[24px]">
                        Po aktywowaniu promocji Twoi klienci będą mogli wybrać DPD Pickup w koszyku, a Ty możesz
                        wygenerować przesyłkę w Selmo Paczce
                    </div>
                </div>
            </div>
            <div className="bg-[#fff] rounded-[5px] shadow-default-small mt-3 p-6 sm:p-16">
                <div className="max-w-[540px] mx-auto">
                    <div className="text-xl sm:text-2xl font-bold">
                        Wstaw materiały promocyjne
                    </div>
                    <div className="border flex-wrap rounded-[8px] p-3 flex items-center mt-3">
                        <div className="sm:order-1 order-2">
                            <div className="font-bold">Opublikuj grafikę na Facebooku</div>
                            <div className="flex items-center mt-2 space-x-2">
                                <button
                                    onClick={() => setShowModal(true)}
                                    type="button"
                                    className="button sm:grow-0 grow border-button min-w-[110px]"
                                >
                                    <FacebookCircle className="mr-1" width={14} height={14} fill="#1a94e6"/>
                                    Udostępnij
                                </button>
                                <a
                                    download
                                    href={imageLink}
                                    target="_blank"
                                    className="button border-button sm:grow-0 grow left-icon min-w-[110px]"
                                >
                                    <i className="icon-download"/>
                                    Pobierz
                                </a>
                            </div>
                        </div>
                        <button
                            onClick={openPreviewModal}
                            type="button"
                            className="block sm:order-2 sm:ml-auto sm:mb-0 mb-2.5 w-[90px] h-[90px] rounded-[8px] overflow-hidden"
                        >
                            <img src={imageLink} className="w-full h-full object-cover" alt=""/>
                        </button>
                    </div>
                </div>
            </div>
            {showAfterSubmitModal &&
                <AfterSubmitModal
                    showModal={showAfterSubmitModal}
                    hideModal={() => setShowAfterSubmitModal(false)}
                    openShareModal={() => setShowModal(true)}
                />
            }
            {showModal &&
                <SharePostModal
                    previewSrc={imageLink}
                    showModal={showModal}
                    hideModal={() => setShowModal(false)}
                    videoLink={imageLink}
                    type="img"
                    rest={services.API_DPD_PROMO_SHARE}
                    initialAutoLoad={true}
                />
            }
            {showPreviewModal &&
                <PhotoPreview
                    src={imageLink}
                    hide={hidePreviewModal}
                    show={showPreviewModal}
                />
            }
        </>
    );
};

export default DpdPromo;
