class SelectSizesModalActions {

	toggleModalVisibility(toggle, product, id) {
		return {
			type: 'SELECT_SIZES_MODAL_TOGGLE_VISIBILITY',
			toggle,
			product,
			id,
		};
	}
}

export const getInstance = () => new SelectSizesModalActions();

export default getInstance();
