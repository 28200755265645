import React from 'react';
import useLang from "../../../../src_shared/hooks/useLang";
import {Link, NavLink} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import {Helmet} from "react-helmet";
import {useSelector} from "react-redux";

const BlueMediaWrapper = ({children}) => {
    const {getLangText} = useLang();
    const {userData} = useSelector((state) => state.session);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getLangText('settings_menu_bluemedia')} - Selmo</title>
            </Helmet>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <Link to={RouterPaths.Settings}>
                            {getLangText('asideSettingsLabel')}
                        </Link>
                    </li>
                    <li>
                        <Link to={RouterPaths.SettingsPayments}>
                            {getLangText('paymentsLabel')}
                        </Link>
                    </li>
                    <li className="active">
                        <div>{getLangText('settings_menu_bluemedia')}</div>
                    </li>
                </ul>
                <Link
                    to={RouterPaths.SettingsPayments}
                    className="button back-to-panels mb-3">
                    <i className="icon-arrow-left"/>
                    {getLangText('button_back_to_payment')}
                </Link>
            </div>
            <div className="settings-page sub-settings-page loader-parent"
                 style={{background: 'transparent', boxShadow: 'none'}}>
                <div className="white-bg rounded-lg" style={{background: 'white'}}>
                    <div className="header-wrapper pb-1 pt-4 border-bottom-0 d-flex align-items-center">
                        <h1>{getLangText('settings_menu_bluemedia')}</h1>
                        <img className="ml-auto" width="55" src="/assets/images/payments/bluemedia.svg"
                             alt="Autopay"/>
                    </div>
                    <ul className="tabs-wrapper px-2 border-0 mb-0">
                        <NavLink
                            exact
                            activeClassName="active"
                            to={RouterPaths.SettingsPaymentsOnlineLanding}
                        >
                            Dlaczego warto
                        </NavLink>
                        <NavLink
                            exact
                            activeClassName="active"
                            to={RouterPaths.SettingsPaymentsOnline}
                        >
                            Konfiguracja
                        </NavLink>
                        {!!+userData.automatic_transfer &&
                            <NavLink
                                exact
                                activeClassName="active"
                                to={RouterPaths.SettingsAutomaticTransfer}
                            >
                                Automatyczne przelewy
                            </NavLink>
                        }
                    </ul>
                </div>
                {children}
            </div>
        </>
    );
};

export default BlueMediaWrapper;
