import React from 'react';
import {NavLink} from "react-router-dom";
import PermissionsWrapper from "../../../../routes/PermissionsWrapper";
import {SHOP_PERMISSION} from "../../enums/SelmoPermissions";
import {RouterPaths} from "../../../../routes/RouterPath";
import {
    BUTIK_MADAME_SHOP_ID,
    PIANO_FASHION_SHOP_ID,
    TEST_ACCOUNT_SHOP_ID,
    TIM_SHOP_ID
} from "../../../../../src_shared/enums/TrustedShopsIds";
import SubMenu from "../SubMenu";
import useLang from "../../../../../src_shared/hooks/useLang";
import {useSelector} from "react-redux";
import {
    USER_PERMISSIONS_PRODUCTS_MENU_CATEGORIES_VISIBILITY,
    USER_PERMISSIONS_PRODUCTS_MENU_COLLECTIONS_VISIBILITY,
    USER_PERMISSIONS_PRODUCTS_MENU_MANUFACTURERS_VISIBILITY,
    USER_PERMISSIONS_PRODUCTS_MENU_VIDEO_VISIBILITY, USER_PERMISSIONS_PRODUCTS_MENU_VISIBILITY,
} from "../../enums/UserPermissionsTypes";
import UserPermissionsWrapper from "../../../../routes/UserPermissionsWrapper";

const ProductsMenu = ({closeMobileMenu}) => {
    const {getLangText} = useLang();
    const {userData} = useSelector((state) => state.session);
    const {lang} = useSelector((state) => state.i18n);

    return (
        <UserPermissionsWrapper allowedPermissions={[USER_PERMISSIONS_PRODUCTS_MENU_VISIBILITY]}>
            <SubMenu
                includeRoute="/magazyn"
                route="/magazyn"
                name={lang === 'fr' ? 'Inventaire' : getLangText('asideProductsLabel')}
                icon="icon-products-1"
            >
                <li>
                    <NavLink
                        exact
                        onClick={closeMobileMenu}
                        activeClassName="active"
                        to="/magazyn"
                    >
                        <span>{getLangText('allLabel')}</span>
                    </NavLink>
                </li>
                <UserPermissionsWrapper allowedPermissions={[USER_PERMISSIONS_PRODUCTS_MENU_COLLECTIONS_VISIBILITY]}>
                    <PermissionsWrapper
                        allowedPermissions={[SHOP_PERMISSION]}
                    >
                        <li>
                            <NavLink
                                onClick={closeMobileMenu}
                                activeClassName="active"
                                to={RouterPaths.CollectionsList}
                            >
                                <span>{getLangText('collectionsLabel')}</span>
                            </NavLink>
                        </li>
                    </PermissionsWrapper>
                </UserPermissionsWrapper>
                <UserPermissionsWrapper allowedPermissions={[USER_PERMISSIONS_PRODUCTS_MENU_CATEGORIES_VISIBILITY]}>
                    <li>
                        <NavLink
                            onClick={closeMobileMenu}
                            activeClassName="active"
                            to="/magazyn/kategorie"
                        >
                            <span>{getLangText('categoriesListLabel')}</span>
                        </NavLink>
                    </li>
                </UserPermissionsWrapper>
                <UserPermissionsWrapper allowedPermissions={[USER_PERMISSIONS_PRODUCTS_MENU_VIDEO_VISIBILITY]}>
                    <PermissionsWrapper
                        allowedPermissions={[SHOP_PERMISSION]}
                    >
                        <li>
                            <NavLink
                                onClick={closeMobileMenu}
                                activeClassName="active"
                                to={RouterPaths.ReelsList}
                            >
                                <span>{getLangText('reelsLabel')}</span>
                            </NavLink>
                        </li>
                    </PermissionsWrapper>
                </UserPermissionsWrapper>
                {+userData.id === TEST_ACCOUNT_SHOP_ID &&
                    <li>
                        <NavLink
                            onClick={closeMobileMenu}
                            activeClassName="active"
                            to={RouterPaths.MobileReelsList}
                        >
                            <span>Mobile Reels</span>
                        </NavLink>
                    </li>
                }
                <UserPermissionsWrapper allowedPermissions={[USER_PERMISSIONS_PRODUCTS_MENU_MANUFACTURERS_VISIBILITY]}>
                    {(+userData.id === TEST_ACCOUNT_SHOP_ID || +userData.id === PIANO_FASHION_SHOP_ID || +userData.id === TIM_SHOP_ID || +userData.id === BUTIK_MADAME_SHOP_ID) &&
                        <li>
                            <NavLink
                                onClick={closeMobileMenu}
                                activeClassName="active"
                                to={RouterPaths.ManufacturersList}
                            >
                                <span>{getLangText('manufacturersLabel')}</span>
                            </NavLink>
                        </li>
                    }
                </UserPermissionsWrapper>
            </SubMenu>
        </UserPermissionsWrapper>
    );
};

export default ProductsMenu;
