import Model from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

class SettingsDPDModel extends Model {
    getModel() {
        return {
            email: '',
            client_key: '',
            delis_id: '',
            address_id: '',
            bank_id: '',
            active: '0',
        };
    }

    getValidators() {
        return {
            email: ValidationError.combine(
                ValidationError.notEmpty,
                ValidationError.validateEmail,
            ),
            client_key: ValidationError.notEmpty,
            delis_id: ValidationError.notEmpty,
            address_id: ValidationError.notEmpty,
            bank_id: ValidationError.notEmpty,
            active: ValidationError.skip,
        };
    }

    buildDTO(data) {
        return {
            email: data.email,
            client_key: data.client_key,
            delis_id: data.delis_id,
            address_id: data.address_id,
            bank_id: data.bank_id,
            active: data.active,
        }
    }
}

export default new SettingsDPDModel();
