import ListActions, {getGETParamsUrl} from "../../../../../src_shared/list/ListActions.js";
import {getStateRoot, prefix} from './listReducer.js';
import {RouterPaths} from "../../../../routes/RouterPath.js";
import {services} from "../../../../RestServices.js";
import {getStateRoot as getFiltersStateRoot} from "../../filters/filtersReducer.js";
import history from "../../../../history.js";
import {PRODUCTS_TYPE_ACTIVE_ALL} from "../../../shared/enums/ProductsActiveType.js";
import axios from "axios";
import {selmoUrl} from "../../../../../src_shared/api/api";
import CategoriesListModel from "../../../../../modules/models/shopSettings/ProductsList";
import {showAlert} from "../../../shared/components/alert/AlertActions";
import api from "../../../../../services/axios/axios";

export class CategoriesListActions extends ListActions {

	parseData(items, response, ...args) {
		return () => items.map((item) => this.dataModel.parseDTO(item, response, ...args));
	}
	getLoadParams(state) {
		const params = super.getLoadParams(state);
		const activeType = getFiltersStateRoot(state).data.activeType;

		if (activeType && activeType !== PRODUCTS_TYPE_ACTIVE_ALL) {
			params.active = activeType
		}

		history.replace({
			pathname: this.pathname,
			search: getGETParamsUrl(params)
		});

		return params;
	}

	updateItemsOnServer(items) {
		return async (dispatch) => {
			dispatch(this.table.updateAllItems(items))

			try {
				await api.post(`${selmoUrl}/${services.API_STORAGE_CATEGORY_ORDER}`, {items})
				dispatch(showAlert('yuhuLabel', 'successfulSavedOrderLabel'))

			} catch (e) {
				dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
				console.error(e)
			} finally {
			}
		}
	}

	updateSubCategoriesOnServer(categoryId, items) {
		return async (dispatch) => {
			dispatch(this.table.updateItem(categoryId, 'children', items))
			try {
				await api.post(`${selmoUrl}/${services.API_STORAGE_CATEGORY_ORDER}/${categoryId}`, {items})
				dispatch(showAlert('yuhuLabel', 'successfulSavedOrderLabel'))

			} catch (e) {
				dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
				console.error(e)
			} finally {
			}
		}
	}

	getSubCategories(category) {
		return async (dispatch) => {
			if (category.expanded) {
				dispatch(this.table.updateItem(category.id, 'expanded', false))
				return;
			}
			try {
				const {data} = await api.get(`${selmoUrl}/${services.API_SHOP_SETTINGS_CATEGORIES}/${category.id}`)
				const parsedItems = dispatch(this.parseData(data.items));
				dispatch(this.table.updateItem(category.id, 'children', parsedItems))
				dispatch(this.table.updateItem(category.id, 'expanded', true))

			} catch (e) {
				console.error(e)
			} finally {

			}
		}
	}
}

export const getInstance = () => new CategoriesListActions({
	getStateRoot,
	restService: services.API_SHOP_SETTINGS_CATEGORIES,
	pathname: RouterPaths.CategoriesList,
	preventPushParams: true,
	prefix,
	model: CategoriesListModel,
});

export default getInstance();
