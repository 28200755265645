import React from 'react';
import useFetchData from "../../../../src_shared/hooks/useFetchData";
import {services} from "../../../RestServices";
import {Link, useParams} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import useLang from "../../../../src_shared/hooks/useLang";
import Orders from "./components/Orders";
import Products from "./components/Products";
import ComponentLoader from "../../shared/components/componentLoader/ComponentLoader";

const Shelf = () => {
    const {id} = useParams();
    const {getLangText} = useLang();

    const [{data, isLoading, fetchData}] = useFetchData({
        rest: `${services.API_WAREHOUSE_SHELVES}/${id}`,
        initialData: {
            orders: [],
            products: [],
        }
    });

    if (isLoading) {
        return <ComponentLoader />
    }

    return (
        <div>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <Link to={RouterPaths.Storage}>
                            {getLangText('storageLabel')}
                        </Link>
                    </li>
                    <li className="active">
                        {data.shelf_name}
                    </li>
                </ul>
                <Link
                    to={RouterPaths.Storage}
                    className="button back-to-panels mb-sm-3 mt-sm-0 mt-3">
                    <i className="icon-arrow-left"/>
                    {getLangText('backButton')}
                </Link>
            </div>
            {(!data.orders.length && !data.products.length) &&
                <div className={`empty-state default-shadow-box  big-padd small-size min-h-[250px]`}>
                    <div className="img-icon mx-auto">
                        <img src="/assets/images/empty/products-empty.svg"
                             alt=""/>
                    </div>
                    <div className="title">
                        {getLangText('shelfIsEmptyLabel')}
                    </div>
                </div>
            }
            <div className="table-box-wrapper space-y-3">
                <Orders
                    fetchData={fetchData}
                    data={data}/>
                <Products
                    fetchData={fetchData}
                    data={data}/>
            </div>
        </div>
    );
};

export default Shelf;
