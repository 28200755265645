import Model, {convertObjectProperties} from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

class SettingsVoiceMessagesModel extends Model {
	getModel() {
		return {
			name: '',
			description: '',
			active: '0',
		};
	}

	getValidators() {
		return {
			name: ValidationError.notEmpty,
			description: ValidationError.skip,
			active: ValidationError.skip,
		};
	}

	parseDTO(data) {
		return convertObjectProperties(
			data,
			{},
			{
				name: '',
				description: '',
				active: '0',
			},
		);
	}

	buildDTO(data) {
		const {name, description, id, active} = data;

		if (id) {
			return {
				name,
				description,
				active,
			}
		}

		return {
			name,
			description,
		}
	}
}

export default new SettingsVoiceMessagesModel();
