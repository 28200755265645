import React from 'react';
import TransferForm from "../transferForm/TransferForm";
import {RouterPaths} from "../../../../routes/RouterPath";
import {Link} from "react-router-dom";
import useLang from "../../../../../src_shared/hooks/useLang";

const EditTransfer = () => {
	const {getLangText} = useLang();

	return (
		<>
			<div className="header-bar breadcrumbs-in-react-app">
				<ul className="breadcrumbs">
					<li>
						<Link to={RouterPaths.Settings}>
							{getLangText('asideSettingsLabel')}
						</Link>
					</li>
					<li>
						<Link to={RouterPaths.SettingsPayments}>
							{getLangText('paymentsLabel')}
						</Link>
					</li>
					<li>
						<Link to={RouterPaths.SettingsPaymentsTransfer}>
							{getLangText('settings_payments_transfer_title')}
						</Link>
					</li>
					<li className="active">
						<div>{getLangText('editButton')}</div>
					</li>
				</ul>
				<Link
					to={RouterPaths.SettingsPaymentsTransfer}
					className="button back-to-panels mb-3">
					<i className="icon-arrow-left"/>
					{getLangText('button_back_to_transfer_payment')}
				</Link>
			</div>
			<div className="settings-page sub-settings-page">
				<div className="header-wrapper">
					<h1>
						{getLangText('settings_payments_transfer_title')}
					</h1>
					<div className="description">{getLangText('settings_payments_transfer_subtitle')}</div>
				</div>
				<div className="settings-form">
					<TransferForm extraAccount />
				</div>
			</div>
		</>
	);
};

export default EditTransfer;
