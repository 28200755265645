import React from 'react';
import useLang from "../../../../../../src_shared/hooks/useLang";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../../routes/RouterPath";

const StripeBox = ({data}) => {
    const {getLangText} = useLang();

    return (
        <div className="settings-box">
            <Link to={RouterPaths.SettingsPaymentsStripe}>
                <div className="title flex align-items-center">
                    {getLangText('settings_menu_stripe')}
                    <img
                        style={{width: 'auto', height: '20px'}}
                        className="ml-auto" src="/assets/images/payments/stripe.png" alt=""/>
                </div>
                <div className="desc">
                    {getLangText('addOnlinePaymentsToYourShopLabel')}
                </div>
                <div className="button-place">
                    <div className="flex-grow-1">
                        {!!+data.stripe_active ?
                            <div className="small-status green">
                                <i className="icon-tick"/>
                                {getLangText('active_label')}
                            </div> :
                            <div className="small-status red">
                                <i className="icon-cross"/>
                                {getLangText('not_active_label')}
                            </div>
                        }
                    </div>
                    <div className="button border-button">
                        {getLangText('button_go_to')}
                        <i className="icon-arrows"/>
                    </div>
                </div>
            </Link>
        </div>
    );
};

export default StripeBox;