import {getStateRoot, prefix} from "./formReducer.js";
import {services} from "../../../../RestServices.js";
import {RouterPaths} from "../../../../routes/RouterPath.js";
import ProductModel from "../../../../../modules/models/ProductModel.js";
import SharedEditProductFormActions from "../../shared/SharedEditProductFormActions.js";
import {selmoUrl} from "../../../../../src_shared/api/api";
import {showAlert} from "../../../shared/components/alert/AlertActions";
import api from "../../../../../services/axios/axios";

export class EditProductFormActions extends SharedEditProductFormActions {
	showExtraAddNewButton(toggle) {
		return {
			type: `${this.prefix}SHOW_EXTRA_ADD_NEW_BUTTON`,
			toggle,
		}
	}

	setUniqueProductCodeError(toggle) {
		return {
			type: `${this.prefix}SET_UNIQUE_PRODUCT_CODE_ERROR`,
			toggle,
		}
	}

	afterDeleteSubmit(history) {
		return () => {
			history.push(RouterPaths.ProductsList)
		}
	}

	updateProductsOrdersOnServer(items, id) {
		return async (dispatch) => {
			try {
				await api.post(`${selmoUrl}/${services.API_PRODUCT_PHOTOS_ORDERS}/${id}`, {items})
				dispatch(showAlert('yuhuLabel', 'successfulSavedOrderLabel'))
			} catch (e) {
				dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
				console.error(e)
			}
		}
	}

	setSelectedGalleryPhoto(photo) {
		return {
			type: `${this.prefix}SET_SELECTED_GALLERY_PHOTO`,
			photo,
		}
	}

	setSelectedGalleryPhotos(photos) {
		return {
			type: `${this.prefix}SET_SELECTED_GALLERY_PHOTOS`,
			photos,
		}
	}
}

export const getInstance = () => new EditProductFormActions({
	getStateRoot,
	prefix,
	model: ProductModel,
	restService: services.API_PRODUCTS,
	route: RouterPaths.ProductsList
});

export default getInstance();
