import React from 'react';
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../../routes/RouterPath";
import useLang from "../../../../../../src_shared/hooks/useLang";
import ClientAvatar from "../../../../live/shared/Avatar";

const ClientName = ({values, userData}) => {
	const {getLangText} = useLang();

	if (values.client.name) {
		return (
			<div className="avatar-row">
				<ClientAvatar
					photo={values.client.photo}
					type={values.client.social_type}
					fbLogo={userData.facebook_id && userData.instagram_id}
					appStatus={values?.mobile_app_status?.status}
				/>
				<div className="name">
					<Link
						to={`${RouterPaths.ClientsList}/${values.client.shop_client_id}/`}
					>
						{values.client.name}
					</Link>
					{values.client.shop_client_id &&
						<div className="client-number">
							{getLangText('clientNumberFullLabel')}: <strong>{values.client.shop_client_id}</strong>
						</div>
					}
				</div>
			</div>
		)
	}
	if (values.shippingData.first_name) {
		return (
			<div className="avatar-row">
				<figure>
					<img src="/assets/images/default/profile.svg" alt={values.shippingData.first_name}/>
				</figure>
				<div className="name">{`${values.shippingData.first_name} ${values.shippingData.last_name}`}</div>
			</div>
		)
	}
	return (
		<div className="avatar-row">
			<figure>
				<img src="/assets/images/default/fb-default.jpeg"
					 alt="Brak danych"/>
			</figure>
			<div className="name">{getLangText('noDataLabel')}</div>
		</div>
	)
};

export default ClientName;
