import React from 'react';
import useFetchData from "../../../../../src_shared/hooks/useFetchData";
import {services} from "../../../../RestServices";
import moment from "../../../shared/moment";
import useLang from "../../../../../src_shared/hooks/useLang";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../routes/RouterPath";
import ServerImage from "../../../shared/components/serverImage/ServerImage";
import TimeAgo from "../../../../../src_shared/helpers/TimeAgo";
import {useSelector} from "react-redux";
import {convertToUserTimeZone} from "../../../shared/helpers/dateHelpers";


const redirectBoxes = [
    {
        id: 'order_notifications',
        icon: 'notifications',
        label: 'orderNotifications',
        path: RouterPaths.SettingsAutomaticallyNotifications
    },
    {
        id: 'automatic_reserve_list',
        icon: 'reserved',
        label: 'automaticReserveList',
        path: RouterPaths.SettingsAutomationsReserveList
    },
    {
        id: 'order_cancellation',
        icon: 'cancelled',
        label: 'orderCancellation',
        path: RouterPaths.SettingsAutomationsOrderCancellationConfiguration
    },
    {
        id: 'open_package_closing',
        icon: 'closed',
        label: 'closingOpenPackages',
        path: RouterPaths.SettingsAutomationsOpenPackageClosingConfiguration
    },
    // {
    //     id: 'order_payment_reminder',
    //     icon: 'cancelled',
    //     label: 'noPaymentReminder',
    //     path: RouterPaths.SettingsAutomationsOrderPaymentReminderConfiguration
    // },
    // {
    //     id: 'soon',
    //     icon: 'closed',
    //     label: 'soonAvailable',
    //     path: "#"
    // },
]

const ActivityHistory = () => {
    const {getLangText} = useLang();
    const {lang} = useSelector((state) => state.i18n);

    const [{data, isLoading}] = useFetchData({
        rest: services.API_SETTINGS_LAST_AUTOMATE_ACTIVITIES,
        initialData: {
            activity_history: [],
            activities_info: {},
        },
    })

    if (isLoading) {
        return (
            <div>
                <div className="grid grid-cols-2 gap-4 mb-3 animate-pulse">
                    <div className="w-full h-40 bg-gray-200 rounded-md dark:bg-gray-700"/>
                    <div className="w-full h-40 bg-gray-200 rounded-md dark:bg-gray-700"/>
                    <div className="w-full h-40 bg-gray-200 rounded-md dark:bg-gray-700"/>
                    <div className="w-full h-40 bg-gray-200 rounded-md dark:bg-gray-700"/>
                </div>
                <div className="p-6 animate-pulse bg-gray-200 rounded-md dark:bg-gray-700">
                    <div className="animate-pulse mb-4">
                        <div className="w-[50%] h-7 bg-gray-300 rounded-md dark:bg-gray-800 mb-1"/>
                        <div className="w-[70%] h-5 bg-gray-300 rounded-md dark:bg-gray-800 mb-1"/>
                    </div>
                    <div className="space-y-3">
                        <div className="animate-pulse flex">
                            <div>
                                <div
                                    className="w-[16px] h-[16px] rounded-full bg-gray-300 dark:bg-gray-800 mr-1"/>
                            </div>
                            <div className="w-full">
                                <div className="w-[60%] h-4 bg-gray-300 rounded-md dark:bg-gray-800 mb-1"/>
                                <div className="w-[60%] h-4 bg-gray-300 rounded-md dark:bg-gray-800 mb-1"/>
                            </div>
                        </div>
                        <div className="animate-pulse flex">
                            <div>
                                <div
                                    className="w-[16px] h-[16px] rounded-full bg-gray-300 dark:bg-gray-800 mr-1"/>
                            </div>
                            <div className="w-full">
                                <div className="w-[60%] h-4 bg-gray-300 rounded-md dark:bg-gray-800 mb-1"/>
                                <div className="w-[60%] h-4 bg-gray-300 rounded-md dark:bg-gray-800 mb-1"/>
                            </div>
                        </div>
                        <div className="animate-pulse flex">
                            <div>
                                <div
                                    className="w-[16px] h-[16px] rounded-full bg-gray-300 dark:bg-gray-800 mr-1"/>
                            </div>
                            <div className="w-full">
                                <div className="w-[60%] h-4 bg-gray-300 rounded-md dark:bg-gray-800 mb-1"/>
                                <div className="w-[60%] h-4 bg-gray-300 rounded-md dark:bg-gray-800 mb-1"/>
                            </div>
                        </div>
                        <div className="animate-pulse flex">
                            <div>
                                <div
                                    className="w-[16px] h-[16px] rounded-full bg-gray-300 dark:bg-gray-800 mr-1"/>
                            </div>
                            <div className="w-full">
                                <div className="w-[60%] h-4 bg-gray-300 rounded-md dark:bg-gray-800 mb-1"/>
                                <div className="w-[60%] h-4 bg-gray-300 rounded-md dark:bg-gray-800 mb-1"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="grid grid-cols-2 gap-2 sm:gap-4 mb-3 sm:px-0 px-[8px]">
                {redirectBoxes.map((item) => (
                    item.id === 'soon' ?
                        <div
                            className="hover:no-underline relative hover:text-[#000] transition-all shadow-default rounded-[5px] bg-[#f9f9f9] p-3 border"
                        >
                            <img
                                className="absolute bottom-0 right-0 w-[75px]"
                                src="/assets/images/robo-banner_1.webp" alt=""/>
                            <div className="pt-4">
                                <div className="text-desc text-xs">
                                    {getLangText('newFunctionalities')}
                                </div>
                                <div className="font-bold text-sm leading-[18px]">
                                    {getLangText(item.label)}
                                </div>
                            </div>
                        </div> :
                        <Link
                            target="_blank"
                            to={item.path}
                            key={item.id}
                            className="hover:no-underline hover:text-[#000] hover:bg-[#F9FAFB] transition-all shadow-default rounded-[5px] bg-[white] p-3 border"
                        >
                            <div className="flex items-center -mb-1">
                                <div className="font-bold text-sm leading-[18px]">
                                    {getLangText(item.label)}
                                </div>
                                <div className="ml-2 min-w-[20px] w-[20px]">
                                    <img className="w-full" src={`/assets/images/mrAutomate/${item.icon}.svg`}
                                         alt={item.icon}/>
                                </div>
                            </div>
                            {!!+data?.activities_info[`${item.id}_active`] ?
                                <div className="small-status green">
                                    <i className="icon-tick"/>
                                    {getLangText('active_label')}
                                </div> :
                                <div className="small-status red">
                                    <i className="icon-cross"/>
                                    {getLangText('not_active_label')}
                                </div>
                            }
                            <div className="font-bold text-[32px] leading-[35px] mt-2">
                                {data?.activities_info[item.id]}
                            </div>
                            <div className="text-xs text-desc mb-3 mt-1">
                                {getLangText('activitiesPerformed')}
                            </div>
                            <div className="button border-button small-size w-full">
                                {getLangText('goToLabel')}
                                <i className="icon-arrows"/>
                            </div>
                        </Link>
                ))}
            </div>
            <div className="shadow-default rounded-[5px] bg-[white] p-6">
                <div className="font-bold">
                    {getLangText('lastActionsPerformed')}
                </div>
                <div className="text-desc text-sm font-medium mb-3">{getLangText('seeMrAutomateActivities')}</div>
                <div className="employee-history">
                    {data?.activity_history?.map((item, index) => (
                        <div
                            key={index}
                            className="flex"
                        >
                            <a

                                className="w-[20px] h-[20px] min-w-[20px] rounded-full mr-2 overflow-hidden">
                                {!!item.photo ?
                                    <ServerImage
                                        alt={item.name}
                                        src={item.photo}
                                        className="w-full h-full object-cover"
                                    /> :
                                    <img className="w-full h-full object-cover"
                                         src="/assets/images/default/fb-default.jpeg"
                                         alt={item.name}/>
                                }
                            </a>
                            <div className="text-xs">
                                <div>
                                    <a
                                        target="_blank"
                                        className="inline-flex hover:text-[#000] items-center font-bold"
                                        href={`${RouterPaths.ClientsList}/${item.shop_client_id}`}>
                                        {item.name}
                                    </a>
                                    <span className="text-[8px] text-desc mx-1 align-middle">•</span>
                                    <span className="font-medium"
                                          dangerouslySetInnerHTML={{__html: getLangText(item.type)}}/>
                                </div>
                                <div
                                    className="text-desc font-medium">
                                    <TimeAgo
                                        datetime={convertToUserTimeZone(item.date).format('YYYY-MM-DD HH:mm:ss')}
                                        locale={lang}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default ActivityHistory;
