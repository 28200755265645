import useLang from "../../../../src_shared/hooks/useLang";
import {Helmet} from "react-helmet";
import {Link, NavLink} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import React, {memo} from "react";

const AutomaticReserveListWrapper = ({children}) => {
    const {getLangText} = useLang()

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getLangText('automaticReserveList')} - Selmo</title>
            </Helmet>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <Link to={RouterPaths.Settings}>{getLangText('asideSettingsLabel')}</Link>
                    </li>
                    {/*<li>*/}
                    {/*    <Link to={RouterPaths.SettingsAutomations}>{getLangText('panAutomacikLabel')}</Link>*/}
                    {/*</li>*/}
                    <li className="active">
                        <div>{getLangText('orderCancellation')}</div>
                    </li>
                </ul>
            </div>
            <div>
                <div className="settings-page sub-settings-page export-sub-settings list-with-tabs separated-box">
                    <div className="tabs-header">

                        <div className="header-wrapper no-border pb-0">
                            <h1>
                                {getLangText('orderCancellation')}
                                {/*<img style={{width: 'auto', height: '20px'}} src="/assets/images/shippypro.png"*/}
                                {/*     alt="ShippyPro"/>*/}
                            </h1>
                            <div className="description">
                                {getLangText('automaticOrderCancellation')}
                            </div>
                        </div>
                        <div className="tabs-wrapper">
                            <NavLink
                                exact
                                activeClassName="active"
                                to={RouterPaths.SettingsAutomationsOrderCancellationConfiguration}
                            >
                                {getLangText('configurationLabel')}
                            </NavLink>
                            <NavLink
                                exact
                                activeClassName="active"
                                to={RouterPaths.SettingsAutomationsOrderCancellationOrders}
                            >
                                {getLangText('asideOrdersLabel')}
                            </NavLink>
                        </div>
                    </div>
                    {children}
                </div>
            </div>
            {/*<div className="col-md-5 mt-md-0 mt-3">*/}
            {/*    <div className="default-shadow-box bigger-y-padd payment-benefits-wrapper">*/}
            {/*        <div className="label">{getLangText('paymentsHowItWorksLabel')}</div>*/}
            {/*        <ul className="benefits-list">*/}
            {/*            <li>*/}
            {/*                <i className="icon-tick-c"/>*/}
            {/*                {getLangText('oneCLickGenerateLabelsLabel')}*/}
            {/*            </li>*/}
            {/*            <li>*/}
            {/*                <i className="icon-tick-c"/>*/}
            {/*                {getLangText('accessToMoreCarriersLabel')}*/}
            {/*            </li>*/}
            {/*            <li>*/}
            {/*                <i className="icon-tick-c"/>*/}
            {/*                {getLangText('automaticTrackingLinksLabel')}*/}
            {/*            </li>*/}
            {/*            <li>*/}
            {/*                <i className="icon-tick-c"/>*/}
            {/*                {getLangText('saveTimeLabel')}*/}
            {/*            </li>*/}
            {/*        </ul>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </>
    );
};

export default AutomaticReserveListWrapper;
