import React from 'react';
import useLang from "../../../../../../src_shared/hooks/useLang";
import useSubmitData from "../../../../../../src_shared/hooks/useSubmitData";
import {services} from "../../../../../RestServices";
import {showAlert} from "../../../../shared/components/alert/AlertActions";
import {useDispatch} from "react-redux";
import Loader from "../../../../shared/Loader";

const GeneratePZButton = () => {
    const {getLangText} = useLang();
    const dispatch = useDispatch();

    const [{onSave, isWaiting}] = useSubmitData(
        {
            rest: services.API_STORAGE_GENERATE_PZ,
            afterSubmit: () => {
                dispatch(showAlert('yuhuLabel', 'successfullyGeneratePZLabel'))
            }
        });

    return (
        <button
            style={{height: 33}}
            type="button"
            onClick={onSave}
            className={`button border-button ml-2 ${isWaiting ? 'loading-button' : ''}`}
        >
            {isWaiting && <Loader isLoading/>}
            <span className="sm:block hidden">
                {getLangText('generatePZLabel')}
            </span>
            <span className="sm:hidden">PZ</span>
        </button>
    );
};

export default GeneratePZButton;
