import { useEffect, useState } from 'react';

const useCountdown = (targetDate) => {
	const countDownDate = new Date(targetDate).getTime();

	const [countDown, setCountDown] = useState(
		countDownDate - new Date().getTime()
	);

	useEffect(() => {
		const interval = setInterval(() => {
			setCountDown(countDownDate - new Date().getTime());
		}, 1000);

		return () => clearInterval(interval);
	}, [countDownDate]);

	return getReturnValues(countDown);
};

const getReturnValues = (countDown) => {
	const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
	const totalHours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
	const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
	const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

	const preparedDays = days < 10 ? `0${days}` : days;
	const preparedHours = totalHours < 10 ? `0${totalHours}` : totalHours;
	const preparedMinutes = minutes < 10 ? `0${minutes}` : minutes;
	const preparedSeconds = seconds < 10 ? `0${seconds}` : seconds;

	return {
		days: preparedDays,
		hours: preparedHours,
		minutes: preparedMinutes,
		seconds: preparedSeconds,
	};
};

export { useCountdown };
