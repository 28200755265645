import React from 'react';
import {Link, NavLink, useHistory, useLocation} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import useLang from "../../../../src_shared/hooks/useLang";
import {Helmet} from "react-helmet";
import useFetchData from "../../../../src_shared/hooks/useFetchData";
import {services} from "../../../RestServices";
import FirstStepPage from "../../../../src_shared/components/ui/FirstStepPage/FirstStepPage";
import {FIRST_PAGE_AUTOMATION, FIRST_PAGE_LIVES} from "../../shared/enums/TutorialVideoStatsTypes";
import ComponentLoader from "../../shared/components/componentLoader/ComponentLoader";

const AUTOMATION_LIST_ALL_VIEW = 'AUTOMATION_LIST_ALL_VIEW';
const AUTOMATION_INTERACTIONS_LIST_VIEW = 'AUTOMATION_INTERACTIONS_LIST_VIEW';
const AUTOMATION_NEWSLETTER_LIST_VIEW = 'AUTOMATION_NEWSLETTER_LIST_VIEW';
const AUTOMATION_STATS_LIST_VIEW = 'AUTOMATION_STATS_LIST_VIEW';

const AutomationViews = [
    {
        id: AUTOMATION_LIST_ALL_VIEW,
        label: 'allLabel',
        path: RouterPaths.Automation,
    },
    {
        id: AUTOMATION_INTERACTIONS_LIST_VIEW,
        label: 'interactionsLabel',
        path: RouterPaths.AutomationInteractions,
    },
    {
        id: AUTOMATION_STATS_LIST_VIEW,
        label: 'statisticsLabel',
        path: RouterPaths.AutomationStatistics,
    },
];

const firstPageBenefits = [
    {id: 0, title: 'increaseEngagement', desc: 'increaseEngagementSubLabel'},
    {id: 1, title: 'sellWithoutShop', desc: 'sellWithoutShopSubLabel'},
    {id: 1, title: 'buildContactList', desc: 'buildContactListSubLabel'},
]

const ListsWrapper = ({children}) => {
    const {getLangText} = useLang();
    const {pathname} = useLocation();
    const history = useHistory();

    const [{data, isLoading}] = useFetchData({
        rest: services.API_INSTAGRAM_AUTOMATIONS_INTERACTIONS_COUNTER,
        initialData: [],
    })

    const redirectToAutomationEdit = () => history.push(`${RouterPaths.AutomationEdit}/0`)

    if (isLoading) return <ComponentLoader />

    return (
        <div className={`settings-page sub-settings-page loader-parent list-with-tabs separated-box`}>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getLangText('automationLabel')} - Selmo</title>
            </Helmet>
            {!+data?.automation_count ?
                <FirstStepPage
                    title="automationLabel"
                    shortDesc="automationSubLabel"
                    icon="broadcast-icon"
                    fullDesc="createKeyWordLabel"
                    buttonClick={redirectToAutomationEdit}
                    headerButtonTitle="addAutomationLabel"
                    mainButtonTitle="createFirstAutomation"
                    benefits={firstPageBenefits}
                    videoStatsName={FIRST_PAGE_AUTOMATION}
                    smallMainButton
                /> :
                <>
                    <div className="tabs-header">
                        <div className="page-title">
                            {getLangText('automationLabel')}
                            <div className="text-desc text-sm font-medium">
                                {getLangText('automationSubLabel')}
                            </div>
                        </div>
                        <div className="flex items-end relative border-b">
                            <div className="tabs-wrapper border-0">
                                {AutomationViews.map((i) => (
                                    <NavLink
                                        exact
                                        activeClassName="active"
                                        to={i.path}
                                        key={i.id}
                                    >
                                        {getLangText(i.label)}
                                        {i.id === AUTOMATION_INTERACTIONS_LIST_VIEW &&
                                            <span>{data?.interaction_count || data}</span>
                                        }
                                    </NavLink>
                                ))}
                            </div>
                            {pathname === RouterPaths.Automation &&
                                <div className="ml-auto pb-2 pr-4">
                                    <Link
                                        className="button primary small-size"
                                        to={`${RouterPaths.AutomationEdit}/0`}>
                                        {getLangText('addAutomationLabel')}
                                    </Link>
                                </div>
                            }
                        </div>
                    </div>
                    {children}
                </>
            }
        </div>
    )
};

export default ListsWrapper;
