import React, {useEffect} from 'react';
import {Link, useHistory, useLocation} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import {LottieLoading} from "../../../../App";
import Alert from "../../../app/shared/components/alert/Alert";
import {parseParams} from "../../../../src_shared/list/ListActions";
import useLang from "../../../../src_shared/hooks/useLang";
import {services} from "../../../RestServices";
import useSubmitData from "../../../../src_shared/hooks/useSubmitData";

const PaypalCallback = () => {
    const {getLangText} = useLang();
    const {search} = useLocation();
    const query = parseParams(search);
    const history = useHistory();

    const values = {
        merchantId: query.merchantId,
        merchantIdInPayPal: query.merchantIdInPayPal,
    };

    const [{isError, onSave}] = useSubmitData({
        rest: services.API_SETTINGS_PAYPAL_CONFIG,
        values,
        afterSubmit: () => history.push(RouterPaths.SettingsPaymentsPaypalAutomation),
    });

    useEffect(() => {
        onSave();
    }, [])

    return (
        <div className="w-[100vw] h-[100vh] flex align-items-center justify-center bg-[white]">
            {isError ?
                <div>
                    <img alt="selmo" width={80} src="/assets/images/selmo-logo.png"/>
                    <h1 className="text-xl font-medium mt-6">{getLangText('sthWentWrongWarning')}</h1>
                    <Link
                        className="text-gray-600 text-sm flex items-center mt-2 hover:no-underline"
                        to={RouterPaths.SettingsPayments}
                    >
                        <i className="icon-arrows mr-1 inline-block rotate-90 text-[8px]"/>
                        {getLangText('backButton')}
                    </Link>
                </div> :
                <LottieLoading />
            }
            <Alert/>
        </div>
    );
};

export default PaypalCallback;
