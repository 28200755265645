export const filesSetDropdownVisibility = (toggle) => {
    return {
        type: 'FILES_TOGGLE_VISIBILITY',
        toggle,
    }
}
export const filesAddFile = (file) => {
    return {
        type: 'FILES_ADD_FILE',
        file,
    }
}

export const filesUpdateFile = (file) => {
    return {
        type: 'FILES_UPDATE_FILE',
        file,
    }
}

export const filesUpdateFilesFromPusher = (files) => {
    return (dispatch, getState) => {
        const {isOpen} = getState().shared.files;
        if (isOpen) {
            dispatch(filesUpdateFiles(files));
        } else {
            dispatch(filesSetDropdownVisibility(true));
        }
    }
}

export const filesDownloadFile = (data) => {
    return (dispatch, getState) => {
        const {isModalOpen} = getState().shared.files;
        if (isModalOpen) {
            dispatch(filesSetModalVisibility(false))
            window.open(data.link, '_blank')
        } else {
            // filesUpdateFilesFromPusher(data.files)
        }
    }
}

export const filesUpdateFiles = (files) => {
    return {
        type: 'FILES_UPDATE_FILES',
        files,
    }
}

export const filesSetModalVisibility = (toggle) => {
    return {
        type: 'FILES_MODAL_TOGGLE_VISIBILITY',
        toggle,
    }
}