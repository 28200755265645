import React, {useEffect} from 'react';
import {Helmet} from "react-helmet";
import useLang from "../../../../../src_shared/hooks/useLang";
import IncomesChart from "./charts/chartTypes/IncomesChart";
import OrdersChart from "./charts/chartTypes/OrdersChart";
import useDateRangePicker from "../../../../../src_shared/components/ui/DateRangePicker/useDateRangePicker";
import useFetchData from "../../../../../src_shared/hooks/useFetchData";
import {convertDate} from "../../../shared/helpers/dateHelpers";
import {useLocation} from "react-router-dom";
import {parseParams} from "../../../../../src_shared/list/ListActions";
import {services} from "../../../../RestServices";
import DateRangePicker from "../../../../../src_shared/components/ui/DateRangePicker/DateRangePicker";
import ComponentLoader from "../../../../../src_shared/components/ui/componentLoader/ComponentLoader";
import InteractionsChart from "./charts/chartTypes/InteractionsChart";
import PaymentsDonutChart from "./charts/chartTypes/PaymentsDonutChart";

const ordersInfo = [
    {
        "count": "1",
        "price": "2111",
        "day": "15",
        "month": "1",
        "year": "2024"
    },
    {
        "count": "7",
        "price": "7866",
        "day": "16",
        "month": "1",
        "year": "2024"
    },
    {
        "count": "3",
        "price": "2816",
        "day": "17",
        "month": "1",
        "year": "2024"
    },
    {
        "count": "1",
        "price": "12",
        "day": "18",
        "month": "12",
        "year": "2023"
    },
    {
        "count": "2",
        "price": "297",
        "day": "27",
        "month": "12",
        "year": "2023"
    },
    {
        "count": "4",
        "price": "476",
        "day": "28",
        "month": "12",
        "year": "2023"
    },
]
const interactionsInfo = [
    {
        "count": "50",
        "price": "2111",
        "day": "15",
        "month": "1",
        "year": "2024"
    },
    {
        "count": "70",
        "price": "7866",
        "day": "16",
        "month": "1",
        "year": "2024"
    },
    {
        "count": "40",
        "price": "2816",
        "day": "17",
        "month": "1",
        "year": "2024"
    },
    {
        "count": "30",
        "price": "12",
        "day": "18",
        "month": "12",
        "year": "2023"
    },
    {
        "count": "76",
        "price": "297",
        "day": "27",
        "month": "12",
        "year": "2023"
    },
    {
        "count": "42",
        "price": "476",
        "day": "28",
        "month": "12",
        "year": "2023"
    },
]
const paymentInfo = {
    "Post": 8,
    "Stories": 3,
    "Wiadomość": 2,
}

const AutomationStatisticsPage = ({route}) => {
    const {getLangText} = useLang();

    const {search} = useLocation();
    const query = parseParams(search);

    const {selectionRange, setSelectionRange} = useDateRangePicker();

    const [{data, isLoading, fetchData}] = useFetchData({
        rest: services.API_STATS_INFO,
        initialAutoLoad: false,
        initialData: {
            ordersInfo: [],
        },
        extraParams: {
            // dateFrom: !!selectionRange.startDate ? convertDate(selectionRange.startDate) : null,
            dateFrom: convertDate(new Date()),
            dateTo: convertDate(selectionRange.endDate),
        }
    })


    useEffect(() => {
        fetchData()
    }, [query.dateFrom, query.dateTo])

    if (isLoading) return <ComponentLoader/>

    return (
        <div className="stats-wrapper">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getLangText('asideStatsLabel')} - Selmo</title>
            </Helmet>
            <div className="table-list-wrapper">
                <div className="default-shadow-box" style={{paddingLeft: 22, paddingRight: 22}}>
                    <div className="table-header">
                        <div className="top-part flex-sm-nowrap flex-wrap">
                            <h1 className="title mb-sm-0 mb-2 grow">
                                {getLangText('asideStatsLabel')}
                            </h1>
                            <DateRangePicker
                                setSelectionRange={setSelectionRange}
                                selectionRange={selectionRange}
                            />

                        </div>
                    </div>
                    <div className="charts-section">
                        <div className="grid grid-cols-5 gap-6 mb-6">
                            <div className="col-span-3">
                                <InteractionsChart currency="PLN" items={{ordersInfo: interactionsInfo}}/>
                            </div>
                            <div className="col-span-2">
                                <PaymentsDonutChart items={{paymentInfo, ordersInfo}}/>
                            </div>
                        </div>
                        <div className="grid grid-cols-2 gap-6">
                            <IncomesChart currency="PLN" items={{ordersInfo}}/>
                            <OrdersChart currency="PLN" items={{ordersInfo}}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AutomationStatisticsPage;
