import React, {useEffect, useRef} from 'react';
import Chart from "react-apexcharts";
import useLang from "../../../../../../src_shared/hooks/useLang";
import EmptyList from "../../../../../app/shared/EmptyList";

export const labelStyle = {
    style: {
        fontSize: '12px',
        colors: '#9CA3AF',
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 500
    },
}
const EmployeeChart = ({data = []}) => {

    const {getLangText} = useLang();
    const chartWrapper = useRef(null)

    const options = {
        chart: {
            id: "basic-bar",
            toolbar: {
                show: false,
            }
        },
        xaxis: {
            categories: data?.map((i) => i.employee_name.split(' ')[0]),
            labels: {
                ...labelStyle,
                hideOverlappingLabels: true,
            },
            tooltip: {
                enabled: false
            },
            axisTicks: {
                show: false,
            },
        },
        yaxis: {
            labels: {
                style: {
                    fontSize: '12px',
                    colors: '#9CA3AF',
                    fontFamily: 'Plus Jakarta Sans',
                },
            }
        },
        grid: {
            borderColor: '#D1D5DB',
            strokeDashArray: 5,
        },
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 5,
                dataLabels: {
                    orientation: 'vertical',
                    position: 'center',
                    enable: false,
                    show: false,
                },
                columnWidth: 45,
            },
        },
        dataLabels: {
            enabled: false
        },
        colors: ['#1C4ED8', '#5FA5FA', '#90E8EE', '#3ee1f6'],
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'dark',
                type: "vertical",
                gradientToColors: undefined,
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                colorStops: [
                    {
                        offset: 0,
                        color: "#F2C1FB",
                        opacity: 1
                    },
                    {
                        offset: 60,
                        color: "#6B47E6",
                        opacity: 1
                    },
                ]
            }
        },
        legend: {
            show: false
        },
        tooltip: {
            custom: function ({series, seriesIndex, dataPointIndex, w}) {
                return `<div class="chart-tooltip">
<div class="date-label">
						${w.config.series[0].data[dataPointIndex].x}
						</div>
<div class="label">${getLangText('activityCount')}</div>
						<div class="value">${series[0][dataPointIndex]}</div>

</div>`
            }
        }
    };

    const series = [
        {
            name: getLangText('incomesLabel'),
            data: data?.map((i) => ({
                y: Math.round(+i.value),
                x: i.employee_name,
            })),
        },
    ]

    useEffect(() => {
        if (chartWrapper?.current) {
            console.log(chartWrapper.current?.getBoundingClientRect())
        }
    }, [chartWrapper])

    useEffect(() => {
        const handleScroll = () => {
            if (chartWrapper.current) {
                const currentPosition = chartWrapper.current.scrollLeft;
                const width = chartWrapper.current.clientWidth;
                console.log(currentPosition, width)
            }
        };

        if (chartWrapper.current) {
            chartWrapper.current.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (chartWrapper.current) {
                chartWrapper.current.removeEventListener('scroll', handleScroll);
            }
        };
    }, [chartWrapper]);



    return (
        <div className="bg-[white] shadow-default rounded-[5px] px-[20px] py-6 pb-2 mb-3">
            <div className="title title-with-legend font-bold">
                {getLangText('teamActivityCount')}
            </div>
            <EmptyList
                className="full-size"
                imgSrc="/assets/images/empty/chart-2.svg"
                items={data}
                title=""
            >
                <div
                    className="overflow-x-auto horizontal-custom-scroll"
                    ref={chartWrapper}
                >
                    <div
                        style={{minWidth: data.length * 60}}
                        className="chart-wrapper"
                    >
                        <Chart
                            series={series}
                            options={options}
                            type="bar"
                            height={265}
                        />
                    </div>
                </div>
            </EmptyList>
        </div>
    )
        ;
};

export default EmployeeChart;
