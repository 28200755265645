import React from 'react';
import {services} from "../../../RestServices";
import useLang from "../../../../src_shared/hooks/useLang";
import useFormLocal from "../../../../src_shared/hooks/useFormLocal";
import Field from "../../../../src_shared/components/form/field/Field";
import Model from "../../../../src_shared/modules/model/Model";
import Modal from "react-bootstrap/Modal";
import Loader from "../../../app/messenger/loader/Loader";

const CustomIntegrationModal = ({showModal, fetchData, id, closeModal, data}) => {
    const {getLangText} = useLang();

    const [{onSave, isWaiting, getFieldProps}] = useFormLocal({
        rest: `${services.API_SETTINGS_INTEGRATIONS}/${id}`,
        initialAutoLoad: false,
        model: new Model(),
        afterSubmit: async () => {
            closeModal();
            await fetchData();
        },
    });

    return (
        <Modal
            dialogClassName={`default-modal small-modal w-420`}
            show={showModal}
            onHide={closeModal}
        >
            <Modal.Header>
                <Modal.Title>
                    {getLangText('wouldYouLikeToCheckIfYouQualify')}
                </Modal.Title>
                <button
                    onClick={closeModal}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            <form onSubmit={onSave}>
                <Modal.Body>
                    {data.fields?.map((field) => (
                        <Field
                            {...getFieldProps(field.name)}
                            label={field.label}
                            extraProps={{
                                required: field.required,
                            }}
                        />
                    ))}
                </Modal.Body>
                <Modal.Footer>
                    <button
                        onClick={closeModal}
                        type="button"
                        className="button border-button"
                    >
                        {getLangText('cancelButton')}
                    </button>
                    <button
                        type="submit"
                        className={`button px-0 primary ${isWaiting ? 'loading-button' : ''}`}
                    >
                        {isWaiting && <Loader/>}
                        {getLangText('yesButton')}
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default CustomIntegrationModal;
