import React, {useEffect, useState} from 'react';
import ServerImage from "../../../../shared/components/serverImage/ServerImage";
import ClientAvatar from "../../../../live/shared/Avatar";
import moment from "../../../../shared/moment";
import useLang from "../../../../../../src_shared/hooks/useLang";
import {RouterPaths} from "../../../../../routes/RouterPath";
import {convertToUserTimeZone} from "../../../../shared/helpers/dateHelpers";

const LiveSaleItem = ({item, currency, allStats}) => {
    const {getLangText} = useLang();

    const [isNew, setIsNew] = useState(false);

    useEffect(() => {
        const now = convertToUserTimeZone();
        const itemDate = convertToUserTimeZone(item.date);
        const diffSeconds = now.diff(itemDate, 'seconds');

        setIsNew(Math.abs(diffSeconds) <= 10);
        const timeoutId = setTimeout(() => {
            setIsNew(false);
        }, 5000);

        return () => clearTimeout(timeoutId);
    }, [item.date]);

    return (
        <div
            className={`border rounded-[5px] p-[12px] flex items-center opacity-0 animate-[slideAndShow_.5s_ease_forwards] duration-700 transition-all ${isNew ? !!+item.is_reserve_list ? 'bg-orange-50 border-[#FCD34C]' : 'bg-[#ECFEF2] border-[#0EB981]' : 'bg-transparent'}`}>
            <div className="rounded-[5px] w-[40px] h-[40px] mr-[12px] relative">
                {!!item.product_photo ?
                    <ServerImage className="w-full h-full object-cover rounded-[5px]" src={item.product_photo}/> :
                    <img
                        className="w-full h-full object-cover rounded-[5px]"
                        src="/assets/images/default/product.svg"
                        alt="product"
                    />
                }
                {+item.product_quantity > 1 &&
                    <div className="absolute -left-1 -top-1">
                        <div
                            className="text-[10px] bg-[#5fa5fa] text-[#fff] leading-[10px] font-bold w-[20px] h-[20px] rounded-full flex justify-center items-center">
                            {item.product_quantity}
                        </div>
                    </div>
                }
                <div className="absolute -right-1 -bottom-1">
                    {!+item.is_reserve_list ?
                        <img
                            className="w-[14px] min-w-[14px]"
                            src="/assets/images/svg/circle-check.svg"
                            alt="check"
                        /> :
                        <img
                            className="w-[16px] min-w-[16px]"
                            src="/assets/images/svg/time.svg"
                            alt="check"
                        />
                    }
                </div>
            </div>
            <div>
                <div className="flex items-center space-x-1 text-sm mb-1">
                    <div className="font-bold">
                       <span>
                            {item.product_name}
                       </span>
                        {item.size_name &&
                            <>
                                <span className="text-[#9CA3AF] text-[10px]">•</span>
                                <span className="font-bold">
                                    {item.size_name}
                                </span>
                            </>
                        }
                    </div>
                </div>
                <div className="flex items-center space-x-2">
                    <a
                        href={`${RouterPaths.ClientsList}/${item.shop_client_id}`}
                        target="_blank"
                        className="flex items-center hover:text-[#000]"
                    >
                        <ClientAvatar
                            className="small-size"
                            photo={item.client_photo}
                            type={item.social_type}
                        />
                        <div className="font-bold text-xs">
                            {item.client_name}
                        </div>
                    </a>
                    <div
                        className="text-desc font-medium text-[10px] leading-[12px] capitalize">
                        {convertToUserTimeZone(item.date).format('dddd HH:mm')}
                    </div>
                    {(allStats && item.shop_post_id) &&
                        <>
                            <span className="text-[8px]">•</span>
                            <a
                                href={`${RouterPaths.SalesPostsStats}/${item.post_id}`}
                                target="_blank"
                                className="font-bold text-[10px] leading-[12px] underline"
                            >
                                Post #{item.shop_post_id}
                            </a>
                        </>
                    }
                </div>
            </div>
            <div className="ml-auto">
                <div className="font-bold text-right">
                    +{item.product_price}&nbsp;{currency}
                </div>
                <div className="font-bold text-[10px] leading-[11px] text-right whitespace-nowrap">
                    {!+item.is_reserve_list ?
                        <div className="text-[#067857]">{getLangText('addedToBasket')}</div> :
                        <div className="text-[#854E0F]">{getLangText('submittedToReserveList')}</div>
                    }
                </div>
            </div>
        </div>
    );
};

export default LiveSaleItem;
