import React, {useMemo} from 'react';
import {Helmet} from "react-helmet";
import {useModal} from "../shared/hooks/useModal";
import Modal from "react-bootstrap/Modal";
import useLang from "../../../src_shared/hooks/useLang";
import {useSelector} from "react-redux";

const NewsPage = ({children}) => {
    const [openTutorialVideoModal, setOpenTutorialVideoModal] = useModal();
    const {getLangText} = useLang();
    const {lang} = useSelector((state) => state.i18n);

    const getVideoSrc = useMemo(() => {
        if (lang === 'fr') {
            return 'https://onelineplayer.com/player.html?autoplay=false&autopause=false&muted=false&loop=true&url=https%3A%2F%2Fwww.dropbox.com%2Fs%2F83kweva0jfm8t9p%2FHow-to-FR.mp4%3Fraw%3D1&poster=&time=true&progressBar=true&overlay=true&muteButton=true&fullscreenButton=true&style=light&quality=auto&playButton=true'
        }
        return 'https://onelineplayer.com/player.html?autoplay=false&autopause=false&muted=false&loop=false&url=https%3A%2F%2Fwww.dropbox.com%2Fs%2F5j38ytuu70pr6lx%2Fselmo-tutorial-movie.mp4%3Fraw%3D1&poster=https%3A%2F%2Fwww.dropbox.com%2Fs%2Forn60l3itbh1ty7%2Fiphone.jpeg%3Fraw%3D1&time=true&progressBar=true&overlay=true&muteButton=true&fullscreenButton=true&style=light&quality=auto&playButton=true'
    }, [lang])

    return (
        <div className="selmo-dashboard">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${getLangText('newsTitle')} - Selmo`}</title>
            </Helmet>
            <div className="max-w-[600px] mx-auto w-full sm:p-0 p-6">
                <div className="dashboard-card mb-3">
                    <div className="dashboard-card-title">
                        {getLangText('newsTitle')}
                    </div>
                    <div className="dashboard-card-subtitle">
                        {getLangText('newsSubTitle')}
                    </div>
                </div>
                {children}
            </div>
            {/*<div className="col-md-5 pl-sm-2 order-sm-2">*/}
            {/*	<section className="sticky-top">*/}
            {/*		<div className="default-shadow-box help-box">*/}
            {/*			<h2 className="title">*/}
            {/*				{getLangText('newsNeedHelpLabel')}*/}
            {/*			</h2>*/}
            {/*			<div className="help-box__description">*/}
            {/*				{getLangText('newsNeedHelpDescription')}*/}
            {/*			</div>*/}
            {/*			<div className="help-box__buttons">*/}
            {/*				<a*/}
            {/*					href={userData.origin !== SHOP_ORIGIN_PL ? 'https://www.facebook.com/selmo.global' : 'https://www.facebook.com/selmo.polska/'}*/}
            {/*					target="_blank"*/}
            {/*					className="button primary"*/}
            {/*				>*/}
            {/*					<i className="icon-messenger-2-f"/>*/}
            {/*					{getLangText('newsTextToMessengerButton')}*/}
            {/*				</a>*/}
            {/*				{userData.origin === SHOP_ORIGIN_PL &&*/}
            {/*					<a*/}
            {/*						className="button border-button mt-2"*/}
            {/*						href="tel:123457755"*/}
            {/*					>*/}
            {/*						{getLangText('callLabel')}:*/}
            {/*						+12 345 77 55*/}
            {/*					</a>*/}
            {/*				}*/}
            {/*				<a*/}
            {/*					href="/pomoc/"*/}
            {/*					className="button border-button mt-2"*/}
            {/*				>*/}
            {/*					{getLangText('newsGoToHelpButton')}*/}
            {/*					<i className="icon-arrows"/>*/}
            {/*				</a>*/}
            {/*			</div>*/}
            {/*		</div>*/}
            {/*		{userData.origin === SHOP_ORIGIN_PL &&*/}
            {/*			<div className="selmo-dashboard-box mt-3">*/}
            {/*				<div className="main-tutorial-box">*/}
            {/*					<div className="left-side">*/}
            {/*						<h2 className="mb-0">*/}
            {/*							{getLangText('videoForYourClientsLabel')}*/}
            {/*						</h2>*/}
            {/*						<div className="main-tutorial-box__description">*/}
            {/*							{getLangText('downloadAndPinTutorialLabel')}*/}
            {/*						</div>*/}
            {/*						<a href={`/assets/videos/${lang === 'fr' ? 'fr' : 'pl'}/selmo-tutorial-movie.mp4`} download*/}
            {/*						   className="button border-button small-size">*/}
            {/*							{getLangText('downloadVideoLabel')} <i className="icon-download"/>*/}
            {/*						</a>*/}
            {/*					</div>*/}
            {/*					<div className="right-side">*/}
            {/*						<div*/}
            {/*							className="main-tutorial-box__thumbnail mt-2"*/}
            {/*							data-bs-toggle="modal"*/}
            {/*							data-bs-target="#tutorialVideoModal"*/}
            {/*							onClick={() => setOpenTutorialVideoModal(true)}*/}
            {/*						>*/}
            {/*							<img src="/assets/images/pulpit/tutorial.jpeg"*/}
            {/*								 alt="Poradnik dla Twoich klientów"/>*/}
            {/*						</div>*/}
            {/*					</div>*/}
            {/*				</div>*/}
            {/*			</div>*/}
            {/*		}*/}
            {/*		<div className="selmo-dashboard-box mt-3">*/}
            {/*			<a*/}
            {/*				className="main-tutorial-box"*/}
            {/*				href="https://www.selmo.io/blog/koniec-live-commerce-mamy-dobra-wiadomosc"*/}
            {/*				target="_blank"*/}
            {/*			>*/}
            {/*				<div className="left-side">*/}
            {/*					<h2 className="mb-0">*/}
            {/*						{getLangText('notEndOfLiveLabel')}*/}
            {/*					</h2>*/}
            {/*					<div className="main-tutorial-box__description">*/}
            {/*						{getLangText('notEndOfLiveDescription')}*/}
            {/*					</div>*/}
            {/*					<div*/}
            {/*						className="button border-button small-size">*/}
            {/*						{getLangText('read1Label')}*/}
            {/*						<i className="icon-arrows"/>*/}
            {/*					</div>*/}
            {/*				</div>*/}
            {/*				<div className="right-side">*/}
            {/*					<div className="main-tutorial-box__thumbnail no-play">*/}
            {/*						<img src="/assets/images/pulpit/fb.jpeg"*/}
            {/*							 alt="To nie koniec live!"/>*/}
            {/*					</div>*/}
            {/*				</div>*/}
            {/*			</a>*/}
            {/*		</div>*/}
            {/*		{userData.origin === SHOP_ORIGIN_PL &&*/}
            {/*			<div className="selmo-dashboard-box mt-3">*/}
            {/*				<a*/}
            {/*					className="main-tutorial-box"*/}
            {/*					href="https://mamstartup.pl/krakowski-startup-selmo-io-pozyskal-1-mln-zl-rozwinie-platforme-profesjonalizujaca-prowadzenie-sprzedazy-przez-facebooka/"*/}
            {/*					target="_blank"*/}
            {/*				>*/}
            {/*					<div className="left-side">*/}
            {/*						<h2 className="mb-0">*/}
            {/*							{getLangText('oneMillionRaisedLabel')}*/}
            {/*						</h2>*/}
            {/*						<div className="main-tutorial-box__description">*/}
            {/*							{getLangText('oneMillionRaisedDescription')}*/}
            {/*						</div>*/}
            {/*						<div*/}
            {/*							className="button border-button small-size">*/}
            {/*							{getLangText('read1Label')}*/}
            {/*							<i className="icon-arrows"/>*/}
            {/*						</div>*/}
            {/*					</div>*/}
            {/*					<div className="right-side">*/}
            {/*						<div className="main-tutorial-box__thumbnail no-play">*/}
            {/*							<img src="/assets/images/pulpit/ceo.jpeg"*/}
            {/*								 alt="Krakowski startup Selmo.io"/>*/}
            {/*						</div>*/}
            {/*					</div>*/}
            {/*				</a>*/}
            {/*			</div>*/}
            {/*		}*/}
            {/*	</section>*/}
            {/*</div>*/}
            <Modal
                dialogClassName="default-modal video-tutorial-square"
                show={openTutorialVideoModal}
                onHide={() => setOpenTutorialVideoModal(false)}
                centered
            >
                <Modal.Body className="p-0">
                    <div style={{paddingBottom: '100%', position: 'relative', overflow: 'hidden'}}>
                        <iframe
                            frameBorder="0"
                            allowFullScreen=""
                            scrolling="no"
                            allow="autoplay;fullscreen"
                            src={getVideoSrc}
                            style={{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: '0',
                                top: '0'
                            }}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default NewsPage;
