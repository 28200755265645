import React from 'react';
import {Link} from "react-router-dom";
import {RouterPaths} from "../../routes/RouterPath";
import useLang from "../../../src_shared/hooks/useLang";
import {useSelector} from "react-redux";
import useFetchData from "../../../src_shared/hooks/useFetchData";
import {services} from "../../RestServices";
import Loader from "./Loader";

const NoPermissionPage = ({className = ''}) => {
	const {getLangText} = useLang();
	const {userData} = useSelector((state) => state.session);

	const [{isLoading, fetchData: getUrl}] = useFetchData({
		rest: services.API_SELMO_PRO_FEE,
		afterSubmit: (response) => window.location.href = response.url,
		initialAutoLoad: false,
	})

	if (!!+userData.selmo_pro_fee_not_paid) {
		return (
			<div className={`empty-state with-bg with-padd no-permission ${className}`}>
				<div className="img-icon">
					<img
						src="/assets/images/svg/circle-cross.svg"
						alt=""
					/>
				</div>
				<div className="title">{getLangText('regulatePayment')}</div>
				<div className="desc whitespace-pre-wrap" dangerouslySetInnerHTML={{__html: getLangText('payForSelmoProToUnlockAccess')}} />
				<button
					type="button"
					onClick={getUrl}
					className={`button primary small-size mt-3 ${isLoading ? 'loading-button' : ''}`}
				>
					{isLoading && <Loader isLoading/>}
					{getLangText('goToPayment')}
				</button>
			</div>
		)
	}

	return (
		<div className={`empty-state with-bg with-padd no-permission ${className}`}>
			<div className="img-icon">
				<img
					src="/assets/images/svg/circle-cross.svg"
					alt=""
				/>
			</div>
			<div className="title">{getLangText('purchaseAccessLabel')}</div>
			<div className="desc">{getLangText('buyToUnLockFunctionLabel')}</div>
			<Link
				to={RouterPaths.Payments}
				className="button primary small-size mt-3"
			>
				{getLangText('purchaseAccessLabel')}
			</Link>
		</div>
	);
};

export default NoPermissionPage;
