import Model from "../../../src_shared/modules/model/Model";
import {createNewId} from "../../../components/app/live/details/default/liveDetailsReducer";
import ValidationError from "../../../src_shared/form/validation/ValidationError";
import {ROLE_MEMBERS_EMPLOYEE} from "../../../components/app/shared/enums/RoleMembers";

const rowValidator = (value, data) => {
    return data.row.some((item) => ValidationError.validateEmail(item.email)) ? 'Error' : false;
}

export const newItem = {
    id: createNewId(), email: '', role: ROLE_MEMBERS_EMPLOYEE,
}
class AddMemberModel extends Model {
    getModel() {
        return {
            row: [newItem],
        };
    }

    getValidators() {
        return {
            row: rowValidator,
            email: ValidationError.validateEmail,
            role:  ValidationError.skip,
        };
    }

    buildDTO(data) {
        return data.row;
    }
}

export default new AddMemberModel();
