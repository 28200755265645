import React from 'react';
import useLang from "../../../../../../../src_shared/hooks/useLang";
import {useDispatch} from "react-redux";
import useFormLocal from "../../../../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../../../../RestServices";
import ShipmondoLabelModel from "../../../../../../../modules/models/order/ShipmondoLabelModel";
import {showAlert} from "../../../../../shared/components/alert/AlertActions";
import useFetchData from "../../../../../../../src_shared/hooks/useFetchData";
import ComponentLoader from "../../../../../shared/components/componentLoader/ComponentLoader";
import Loader from "../../../../../shared/Loader";

const DpdForm = ({generateLabels, waitingForGenerate}) => {

    const {getLangText} = useLang();
    const dispatch = useDispatch();

    const [{setValue, values, updateValues}] = useFormLocal({
        model: ShipmondoLabelModel,
        initialAutoLoad: false,
        // afterSubmit,
        onError: (e) => dispatch(showAlert(e.response?.data?.message, '', 'alert-fail'))
    });

    const [{isLoading}] = useFetchData({
        rest: services.API_SHIPMENT_DPD_SK_LABEL_TEMPLATES,
        afterSubmit: (items) => updateValues({items})
    })

    return (
        isLoading ?
            <ComponentLoader height={200}/> :
            !!values.items?.length ?
                <form onSubmit={(e) => generateLabels(4, values.template_id, e)}>
                    <div className="form-label">{getLangText('selectTemplateLabel')}</div>
                    <div className="data-boxes-wrapper separated small-padd">
                        {values.items?.map((item) => (
                            <div
                                key={item.id}
                                className={`content ${values.template_id === item.id ? 'active' : ''}`}
                            >
                                <div className="content__top-part">
                                    <input
                                        name="orlen-label"
                                        onChange={() => setValue('template_id', item.id)}
                                        checked={values.template_id === item.id}
                                        type="radio"
                                        required
                                    />
                                    <span className="checkmark"/>
                                    <div className="flex-grow-1 text-xs">
                                        <div className="label text-xs">{item.name}</div>
                                        {/*<div className="text-desc font-normal">{item.sizes}</div>*/}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <button
                        type="submit"
                        className={`button primary w-full ${waitingForGenerate ? 'loading-button' : ''}`}
                    >
                        {waitingForGenerate && <Loader isLoading/>}
                        {getLangText('generateLabelsLabel')}
                    </button>
                </form>
                :
                <div
                    className="form-info-box align-items-center w-100 small-line-height">
                    <div>
                        <i className="icon-info-c"/>
                    </div>
                    <div>
                        <div className="title">{getLangText('noTemplates')}</div>
                    </div>
                </div>
    );
};

export default DpdForm;
