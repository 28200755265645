import {RouterPaths} from "../../../routes/RouterPath";

export const CLIENTS_LIST_VIEW = 'CLIENTS_LIST_VIEW';
export const SUBMITTED_LIST_VIEW = 'SUBMITTED_LIST_VIEW';

const ClientsListViews = [
	{
		id: CLIENTS_LIST_VIEW,
		label: 'asideClientsLabel',
		path: RouterPaths.ClientsList,
	},
	{
		id: SUBMITTED_LIST_VIEW,
		label: 'submittedLabel',
		path: RouterPaths.ClientsSubmittedList,
	},
];

export default ClientsListViews;
