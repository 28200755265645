import Model from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

class NewReelsModel extends Model {
	getModel() {
		return {
			name: '',
			description: '',
		};
	}

	getValidators() {
		return {
			name: ValidationError.notEmpty,
			description: ValidationError.skip,
		};
	}

	buildDTO(data) {
		const {name, description} = data;
		return {
			name,
			description,
		}
	}
}

export default new NewReelsModel();
