import {useEffect} from 'react';
import useDataApi from "./useDataApi";
import {useLocation} from "react-router-dom";
import {parseParams} from "../list/ListActions";

const useCallbackUrl = (config) => {
    const {search} = useLocation();
    const query = parseParams(search);

    const {
        queryName = '',
        rest = '',
        afterSubmit = () => {},
    } = config;

    const [{isLoading: waitingForCallbackResponse, fetchData, isError}] = useDataApi(rest, {}, [], false, {}, afterSubmit);

    useEffect(() => {
        if (!!query[queryName]) {
            fetchData()
        }
    }, [])

    return {
        waitingForCallbackResponse,
        query: query[queryName],
        isError,
    }
};

export default useCallbackUrl;
