import createReducer from "../../shared/helpers/createReducer";
import Composition from "../../shared/helpers/Composition";
import ListReducer from "../../../../../../src_shared/list/ListReducer";
export const getStateRoot = (state) => state.admin.sales.ranking;
export const prefix = 'ADMIN_SALES_STATS';

const getInitState = () => ListReducer.getInitState({
	sortBy: 'this_week_clients',
	sortDir: 'desc'
});

const getReduceMap = () =>  new Composition(
	ListReducer.getReduceMap(),
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

