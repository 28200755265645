import React, {useState} from 'react';
import axios from "axios";
import {selmoUrl} from "../../../api/api";
import Select from "react-select";
import Group from "../group/Group";
import useLang from "../../../hooks/useLang";
import api from "../../../../services/axios/axios";

const MultiAsyncSelectField = (props) => {
	const {getLangText} = useLang();
	const {
		value,
		className,
		placeholder,
		updateValues,
		rest,
		optionsParser,
		isSearchable,
		name,
		defaultValueParser,
		defaultValue,
		formatOptionLabel,
		extraProps
	} = props;

	const [options, setOptions] = useState([{name: `${getLangText('loadingLabel')}...`, id: '1'}])

	const getOptions = async () => {
		try {
			const {data} = await api.get(`${selmoUrl}/${rest}`)
			setOptions(data.items);
		} catch (e) {
			console.warn('Cannot get options')
		}
	}

	const preparedOptions = optionsParser(options);
	const preparedDefaultValue = defaultValueParser(defaultValue);
	const preparedValue = value || defaultValue;
	const setValue = (selectValue) => updateValues({
		[name]: selectValue
	})

	return (
		<Group {...props}>
			<Select
				id={'custom-dropdown-default'}
				className={className}
				defaultValue={preparedDefaultValue}
				options={preparedOptions}
				onChange={setValue}
				hideSelectedOptions={false}
				value={preparedValue}
				formatOptionLabel={formatOptionLabel}
				placeholder={placeholder}
				isMulti
				onFocus={getOptions}
				isSearchable={isSearchable}
				{...extraProps}
			/>
		</Group>

	);
};

MultiAsyncSelectField.defaultProps = {
	className: 'default-size',
	placeholder: '',
	optionsParser: (options) => options?.map((i) => ({
		value: i.id,
		label: i.name,
	})),
	defaultValueParser: (defaultValue) => ({
		value: defaultValue.id,
		label: defaultValue.name,
	}),
	isSearchable: false,
}

export default MultiAsyncSelectField;
