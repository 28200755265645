import React from 'react';
import EmptyList from "../../../../../shared/EmptyList.jsx";
import {RouterPaths} from "../../../../../../routes/RouterPath.js";
import useTableRowClick from "../../../../../shared/hooks/useTableRowClick";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import {useUnitPrice} from "../../../../../shared/helpers/Price";
import CheckboxField from "../../../../../../../src_shared/components/form/checkboxField/CheckboxField";
import {useSelector} from "react-redux";
import {createSelector} from "reselect";
import {getStateRoot} from "../../listReducer";
import useCheckboxMultiselect from "../../../../../../../src_shared/list/checkboxMultiselect/useCheckboxMultiselect";
import ListActions from "../../ListActions";
import SelectedRow from "../../../components/selectedRow/SelectedRow";
import ServerImage from "../../../../../shared/components/serverImage/ServerImage";
import {
    PIANO_FASHION_SHOP_ID, SHOP_BAZAR_SHOP_ID,
    TEST_ACCOUNT_SHOP_ID,
    TIM_SHOP_ID
} from "../../../../../../../src_shared/enums/TrustedShopsIds";
import {convertToUserTimeZone} from "../../../../../shared/helpers/dateHelpers";

const ProductsTable = ({items, isWaiting, updateSortOptions, getSortClass, totalItems}) => {

    const {getLangText} = useLang();
    const onTableRowClick = useTableRowClick(`${RouterPaths.ProductsList}/edytuj`)
    const {selectedItems} = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));
    const {userData} = useSelector((state) => state.session);

    const {
        selectAllItems,
        toggleSelection,
        checkIfSomeExist,
        isSelected,
        updateSelection,
        isAllSelected,
        setIsAllSelectedItems,
        setIsAllSelected,
    } = useCheckboxMultiselect(ListActions.selectedItems, selectedItems, items);

    const {getPrice} = useUnitPrice();

    const colSpan = !!selectedItems.length ? 7 : 1

    const getStatusBox = (squ, isReserveList = false) => {
        if (+squ === 0 || +squ < 0) {
            return (
                <div
                    style={{minWidth: 80}}
                    className="status-bg grey">
                    {getLangText('lackLabel')}
                </div>
            )
        }
        if (+squ === 1) {
            return (
                <div
                    style={{minWidth: 80}}
                    className={`status-bg ${isReserveList ? 'blue' : 'green'}`}>
                    1&nbsp;{getLangText('pieceLabel')}
                </div>
            )
        }
        if (+squ > 1 && +squ < 5) {
            return (
                <div
                    style={{minWidth: 80}}
                    className={`status-bg ${isReserveList ? 'blue' : 'green'}`}>
                    {squ}&nbsp;{getLangText('piecesLabel')}
                </div>
            )
        }
        return (
            <div
                style={{minWidth: 80}}
                className={`status-bg ${isReserveList ? 'blue' : 'green'}`}>
                {squ}&nbsp;{getLangText('piecesGenitiveLabel')}
            </div>
        )
    }

    return (
        <EmptyList
            items={items}
            isLoading={isWaiting}
            className="with-padd"
            imgSrc="/assets/images/empty/products-empty.svg"
            title={getLangText('emptyHereLabel')}
            text={getLangText('changeSearchOrAddProductsLabel')}
        >
            <div className="table-wrapper">
                <div className="table-responsive">
                    <table className="table live-table smaller-padd">
                        <thead>
                        <tr>
                            <SelectedRow
                                ListActions={ListActions}
                                getStateRoot={getStateRoot}
                                colSpan={colSpan}
                                selectAllItems={selectAllItems}
                                selectedItems={selectedItems}
                                isSelected={!!selectedItems.length}
                                checkIfSomeExist={checkIfSomeExist}
                                updateSelection={updateSelection}
                                totalItems={totalItems}
                                setIsAllSelectedItems={setIsAllSelectedItems}
                                isAllSelected={isAllSelected}
                                setIsAllSelected={setIsAllSelected}
                            />
                            {!selectedItems.length &&
                                <>
                                    <th
                                        name="shop_product_id"
                                        onClick={updateSortOptions}
                                        className={getSortClass('shop_product_id')}
                                    >
                                        <span name="shop_product_id">
                                            {getLangText('numberShortLabel')}
                                        </span>
                                    </th>
                                    {(+userData.id === 1300 || +userData.id === 1179) ?
                                        <th
                                            name="selmo_code"
                                            onClick={updateSortOptions}
                                            className={getSortClass('selmo_code')}
                                        >
                                            <span name="selmo_code">
                                                {getLangText('codeLabel')}
                                            </span>
                                        </th> :
                                        <th>
                                            {getLangText('codeLabel')}
                                        </th>
                                    }
                                    <th
                                        name="name"
                                        onClick={updateSortOptions}
                                        className={getSortClass('name')}
                                    >
                                        <span name="name">
                                            {getLangText('productLabel')}
                                        </span>
                                    </th>
                                    <th
                                        name="price"
                                        onClick={updateSortOptions}
                                        className={getSortClass('price')}
                                    >
                                        <span name="price">
                                            {getLangText('priceLabel')}
                                        </span>
                                    </th>
                                    <th
                                        name="squ"
                                        onClick={updateSortOptions}
                                        className={getSortClass('squ')}
                                    >
                                        <span name="squ">
                                            {getLangText('statusLabel')}
                                        </span>
                                    </th>
                                    {(!!+userData.selmo_pro_active || +userData.id === PIANO_FASHION_SHOP_ID) &&
                                        <th
                                            name="reserve_list_sum"
                                            onClick={updateSortOptions}
                                            className={`text-right ${getSortClass('reserve_list_sum')}`}
                                        >
                                            <span className="whitespace-nowrap" name="reserve_list_sum">
                                                {getLangText('reserveListLabel')}
                                            </span>
                                        </th>
                                    }
                                    <th
                                        name="active"
                                        onClick={updateSortOptions}
                                        className={`text-right ${getSortClass('active')}`}
                                    >
                                        <span name="active">
                                            {getLangText('activeLabel')}
                                        </span>
                                    </th>
                                    <th
                                        name="date"
                                        onClick={updateSortOptions}
                                        className={`text-right ${getSortClass('date')}`}
                                    >
                                        <span name="date">
                                            {getLangText('dateLabel')}
                                        </span>
                                    </th>
                                </>
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {items.map((item) => (
                            <tr {...onTableRowClick(item.id)}>
                                <td className="checkbox-col">
                                    <CheckboxField
                                        showLabel={false}
                                        name="selectedItems"
                                        onClick={(e) => e.stopPropagation()}
                                        className="mb-0"
                                        value={isSelected(item.id)}
                                        setValue={(field, value) => toggleSelection(item, field, value)}
                                    />
                                </td>
                                <td
                                    data-thead={`${getLangText('numberLabel')}:`}
                                    className="number short-number with-mobile-thead d-none d-lg-table-cell">
									<span className="id-number">
										{item.shop_product_id}
									</span>
                                </td>
                                <td
                                    data-thead={`${getLangText('codeLabel')}:`}
                                    className="number with-mobile-thead inline-thead order-lg-0 order-1 my-lg-0 my-1"
                                >
                                    {item.selmo_code}
                                </td>
                                <td className="with-photo w-lg-auto w-100">
                                    <div className="d-flex align-items-lg-center mt-sm-0 mt-2">
                                        <div>
                                            <figure>
                                                {item.photo_small && item.photo_small !== '0' ?
                                                    <ServerImage src={item.photo_small} alt={item.name}/> :
                                                    <img src="/assets/images/default/product.svg" alt=""/>
                                                }
                                            </figure>
                                        </div>
                                        <div>
                                            <div className="product-name">
                                                {item.name}
                                                {item.manufacturer_id &&
                                                    <div className="text-xs text-desc">{item.manufacturer_name}</div>
                                                }
                                            </div>
                                            <div className="d-lg-none mobile-price">
                                                {getPrice(item.price)}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td className="w-lg-auto w-100 d-none whitespace-nowrap d-lg-table-cell">
                                    {getPrice(item.price)}
                                </td>
                                <td className="order-lg-0 order-3">
                                    {getStatusBox(item.squ)}
                                </td>
                                {(!!+userData.selmo_pro_active || +userData.id === PIANO_FASHION_SHOP_ID) &&
                                    <td
                                        data-thead={`${getLangText('reserveListLabel')}`}
                                        className="order-lg-0 order-5 text-sm-right w-lg-auto w-100 with-mobile-thead sm:mt-0 mt-1.5 mobile-small-font-size">
                                        {getStatusBox(item.reserve_list_sum, true)}
                                    </td>
                                }
                                <td
                                    data-thead={`${getLangText('activeLabel')}`}
                                    className="with-mobile-thead icon-status text-sm-right order-lg-0 order-4 sm:mr-0 mr-1"
                                >
                                    {+item.active ? <img src="/assets/images/svg/paid.svg"
                                                         className="sm:w-[18px] ml-auto w-[14px] sm:mr-0 mr-1.5"
                                                         alt=""/> :
                                        <i className="icon-cross"/>}
                                </td>
                                <td
                                    data-thead={`${getLangText('activeProductInShopLabel')}`}
                                    className="with-mobile-thead icon-status text-sm-right order-lg-0 order-4 sm:hidden"
                                >
                                    {+item.is_shop_active ? <img className="sm:w-[18px] w-[14px] sm:mr-0 mr-1.5"
                                                                 src="/assets/images/svg/paid.svg" width={18} alt=""/> :
                                        <i className="icon-cross"/>}
                                </td>
                                <td
                                    data-thead={`${getLangText('addedLabel')}:`}
                                    className="text-sm-right date w-lg-auto w-100 order-lg-0 order-2 with-mobile-thead inline-thead mb-lg-0 mb-2"
                                >
                                    {convertToUserTimeZone(item.date).format('DD.MM.YYYY HH:mm')}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </EmptyList>
    );
};

export default ProductsTable;
