import React from 'react';
import SalesPostWrapper from "../SalesPostWrapper";
import HowItWorksComponent from "./HowItWorksComponent";

const HowItWorks = () => {

    return (
        <SalesPostWrapper title="paymentsHowItWorksLabel">
           <HowItWorksComponent />
        </SalesPostWrapper>
    );
};

export default HowItWorks;
