import React from 'react';
import useLang from "../../../../../../../src_shared/hooks/useLang";
import Field from "../../../../../../../src_shared/components/form/field/Field";
import CharactersCounter from "../../../../../../../src_shared/components/ui/CharactersCounter";
import ButtonDestination from "./buttonDestination/ButtonDestination";
import SwitchField from "../../../../../../../src_shared/components/form/switchField/SwitchField";

const AddButton = ({setValue, values, updateValues, isValidationActive,setIsValidationActive, getFieldProps}) => {
    const {getLangText} = useLang();

    return (
        <div className="rounded-[8px] border border-[#DFE1E4] p-3 pt-[18px]">
            <SwitchField
                name="add_button"
                setValue={setValue}
                value={values.add_button}
                className="switch-form align-items-start inline-label big-label light-version mb-0"
                label={getLangText('addButtonLabel')}
                subLabel={getLangText('buttonAppearsOnBottomLabel')}
            />
            {!!+values.add_button &&
                <div className="animate-tutorialSlideAndFadeIn mt-3 sm:pl-[46px]">
                    <div className="characters-counter-parent">
                        <Field
                            name="button_text"
                            setValue={setValue}
                            label={getLangText('buttonContentLabel')}
                            placeholder={getLangText('enterButtonContentLabel')}
                            value={values.button_text}
                            extraProps={{
                                maxLength: 50,
                                required: true,
                            }}
                        />
                        <CharactersCounter value={values.button_text} max={50}/>
                    </div>
                    <ButtonDestination
                        values={values}
                        setValue={setValue}
                        updateValues={updateValues}
                        isValidationActive={isValidationActive}
                        setIsValidationActive={setIsValidationActive}
                        getFieldProps={getFieldProps}
                    />
                </div>
            }
        </div>
    );
};

export default AddButton;
