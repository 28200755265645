export const LIVES_CLIENTS_LIST_VIEW = 'LIVES_CLIENTS_LIST_VIEW';
export const LIVES_PRODUCTS_LIST_VIEW = 'LIVES_PRODUCTS_LIST_VIEW';

const LivesClientsListViews = [
	{
		id: LIVES_CLIENTS_LIST_VIEW,
		label: 'asideClientsLabel',
	},
	{
		id: LIVES_PRODUCTS_LIST_VIEW,
		label: 'asideProductsLabel',
	},
];

export default LivesClientsListViews;
