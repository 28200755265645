import React from 'react';
import useLang from "../../../src_shared/hooks/useLang";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../routes/RouterPath";

const MessengerBlankPage = ({buttonLink}) => {
	const {getLangText} = useLang();

	return (
		<div className="empty-state-container full-height messenger-container">
			<div className="empty-state">
				<div className="img-icon">
					<img src="/assets/images/illustration/all-messages.svg" alt=""/>
				</div>
				<div className="title">{getLangText('connectAccountWithFacebookTitle')}</div>
				<div className="desc">
					{getLangText('automateYourSalesLabel')}
				</div>
				<Link to={RouterPaths.SettingsMeta} className="button primary small-size">
					{getLangText('goToSettingsLabel')}
				</Link>
			</div>
		</div>
	)
}
export default MessengerBlankPage;
