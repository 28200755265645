import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import {RouterPaths} from "./RouterPath";
import PartnersProgram from "../app/partnersProgram/Page";
import Layout from "../app/Layout";

const PartnersRoute = ({path}) => {
	return (
		<Route path={path}>
			<PartnersProgram/>
		</Route>
	);
};

export default PartnersRoute;
