import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import FormActions from "./FormActions";
import {createSelector} from "reselect";
import {getStateRoot} from "./formReducer";
import {useParams} from "react-router-dom";
import EditProductModal from "./Modal";
import useLang from "../../../../../src_shared/hooks/useLang";
import CustomDropdown from "../../../shared/components/dropdown/CustomDropdown";
import PaymentStatusModal from "./components/PaymentStatusModal";
import {BUTIK_MADAME_SHOP_ID, TEST_ACCOUNT_SHOP_ID} from "../../../../../src_shared/enums/TrustedShopsIds";

const EditProductsForm = ({values}) => {

    const dispatch = useDispatch();
    const {userData} = useSelector((state) => state.session);
    const {getLangText} = useLang();
    const {modalVisible, waiting, orderWaiting} = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));
    const {isOpen} = useSelector((state) => state.shared.selectSizesModal)
    const [showPaymentStatusModal, setShowPaymentStatusModal] = useState(false);

    const {id} = useParams();

    const hideModal = () => dispatch(FormActions.hideModal());
    const saveOrder = (e) => dispatch(FormActions.submit(e))
    const showAddOrderModal = () => dispatch(FormActions.showModal(id));

    return (
        <>
            <CustomDropdown
                className="mr-3"
                icon="icon-dots">
                <button
                    type="button"
                    className="custom-option"
                    onClick={() => setShowPaymentStatusModal(true)}
                >
                    {getLangText('changePaymentStatus')}
                </button>
            </CustomDropdown>
            <button
                type="button"
                className="button border-button big-width w-xs-100"
                onClick={showAddOrderModal}

            >
                {getLangText('editProductsLabel')}
            </button>
            {modalVisible &&
                <EditProductModal
                    isOpen={isOpen}
                    waiting={waiting}
                    saveOrder={saveOrder}
                    hideModal={hideModal}
                    waitingForSubmit={orderWaiting}
                />
            }
            {showPaymentStatusModal &&
                <PaymentStatusModal
                    values={values}
                    isOpen={setShowPaymentStatusModal}
                    hideModal={() => setShowPaymentStatusModal(false)}
                />
            }
        </>
    );
};

export default EditProductsForm;
