import Model, {convertObjectProperties} from "../../../src_shared/modules/model/Model";

class InstagramModel extends Model {
	getModel() {
		return {

		};
	}

	parseDTO(data) {
		return convertObjectProperties(
			data,
			{},
			{
				list: [],
			},
		);
	}

	buildDTO(data) {
		return {
			page_id: data.page_id,
		}
	}
}

export default new InstagramModel();
