import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

export default (actions, getStateRoot) => {
    const [data, emptyData, dataPreloaded, saved] = useSelector(
        createSelector(
            getStateRoot,
            (stateRoot) => [
                stateRoot.data,
                stateRoot.emptyData,
                stateRoot.dataPreloaded,
                stateRoot.saved,
            ],
        ),
    );

    const dispatch = useDispatch();

    const setValue = (field, value) => dispatch(actions.setValue(field, value));
    const setNestedValue = (parent, id, field, value) => dispatch(actions.setNestedValue(parent, id, field, value));
    const updateValues = (dataObj) => dispatch(actions.updateValues(dataObj));
    const restoreEmptyData = () => dispatch(actions.restoreEmptyData());

    return {
        setValue,
		setNestedValue,
        updateValues,
        restoreDefaultValues: restoreEmptyData,
        values: data,
        defaultValues: emptyData,
        isPreloaded: dataPreloaded,
        isSaved: saved,
    };
};
