import React, {useEffect, useRef, useState} from 'react';
import cn from "clsx";
import useLang from "../../../../../src_shared/hooks/useLang";

const Description = ({description}) => {
    const [isOpen, setOpen] = useState(false);
    const {getLangText} = useLang();
    const descRef = useRef(null);
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        if (descRef.current) {
            if (descRef.current.getBoundingClientRect().height > 78) {
                setShowButton(true);
            }
        }
    }, [descRef.current])

    return (
        <div className="flex flex-col">
            <div className="relative">
                <div
                    ref={descRef}
                    className={cn(
                        'text-sm leading-[26px] transition-all overflow-hidden after:absolute after:left-0 after:right-0 after:w-full after:bottom-0 whitespace-pre-wrap',
                        isOpen ? 'max-h-[100%]' : 'max-h-[80px] after:bg-[linear-gradient(180deg,rgba(0,0,0,0)_0%,rgba(255,255,255,1)_100%)]',
                        showButton ? 'after:h-[30px] after:content-[""]' : 'after:h-0'
                    )}>
                    {description}
                </div>
            </div>
            {showButton &&
                <button
                    type="button"
                    onClick={() => setOpen(!isOpen)}
                    className="text-xs font-bold text-[#4B5563] mt-1 space-x-0.5"
                >
                    {isOpen ?
                        <>
                            <span>{getLangText('collapseLabel')}</span>
                            <i className="icon-arrows inline-block rotate-180 text-[8px]"/>
                        </> :
                        <>
                            <span>{getLangText('expandLabel')}</span>
                            <i className="icon-arrows text-[8px]"/>
                        </>
                    }
                </button>
            }
        </div>
    );
};

export default Description;
