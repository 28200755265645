import React from 'react';
import useLang from "../../../../../src_shared/hooks/useLang";
import useDataApi from "../../../../../src_shared/hooks/useDataApi";
import {services} from "../../../../RestServices";
import EmptyList from "../../../../app/shared/EmptyList";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../routes/RouterPath";
import Wrapper from "../Wrapper";

const DpdTemplates = () => {
	const {getLangText} = useLang();

	const rest = services.API_SETTINGS_DPD_SK_TEMPLATE
	const [{isLoading, data}] = useDataApi(rest, []);

	return (
		<>
			<div className="header-bar breadcrumbs-in-react-app">
				<ul className="breadcrumbs">
					<li>
						<a href={RouterPaths.Settings}>
							{getLangText('asideSettingsLabel')}
						</a>
					</li>
					<li>
						<Link to={RouterPaths.SettingsIntegration}>{getLangText('settings_nav_integrations')}</Link>
					</li>
					<li className="active">
						DPD
					</li>
				</ul>
			</div>
			<Wrapper>
				<div className="settings-form pt-3">
					<div className="d-flex align-items-center mb-3">
						<h2 className="mt-3 mb-2">
							{getLangText('templatesLabel')}
						</h2>
						<Link
							className="button primary small-size ml-auto"
							to={`${RouterPaths.SettingsIntegrationDpdTemplates}/0`}
						>
							{getLangText('settings_package_types_text_1')}
						</Link>
					</div>
					<EmptyList
						items={data}
						isLoading={isLoading}
						className="with-padd functionality-purchase"
						imgSrc="/assets/images/settings/waybills.svg"
						title={getLangText('emptyHereLabel')}
					>
						<div className="div-table">
							<div className="table-head">
								<div className="table-row">
									<div className="name">
										{getLangText('nameLabel')}
									</div>
									<div className="ml-auto">
										{getLangText('sizes')}
									</div>
									<div className="small-col">
										{getLangText('activeLabel')}
									</div>
									<div className="action-button-col"/>
								</div>
							</div>
							<div className="table-body">
								{data.map((template) => (
									<Link
										to={`${RouterPaths.SettingsIntegrationDpdTemplates}/${template.id}`}
										key={template.id}
										className="table-row"
									>
										<div className="name">
											{template.name}
										</div>
										<div className="text-nowrap ml-auto">
											max. {template.width} x {template.height} x {template.length} x {template.weight} kg
										</div>
										<div className="small-col icon-status">
											{!!+template.active ?
												<i className="icon-tick"/> :
												<i className="icon-cross"/>
											}
										</div>
										<div className="d-flex align-items-center">
											<div className="button border-button no-hover small-size">
												{getLangText('editButton')}
											</div>
										</div>
									</Link>
								))}
							</div>
						</div>
					</EmptyList>
				</div>
			</Wrapper>
		</>
	);
};

export default DpdTemplates;
