import ValidationError from "../../src_shared/form/validation/ValidationError";
import Model from "../../src_shared/modules/model/Model";

export class AssignProductsCollectionModel extends Model {
	getModel() {
		return {
			products: [],
		};
	}

	getValidators() {
		return {
			products: ValidationError.arrayNotEmpty,
		};
	}
}

export default new AssignProductsCollectionModel();
