import ListReducer from "../../../../../src_shared/list/ListReducer.js";
import createReducer from "../../../shared/helpers/createReducer.js";
import Composition from "../../../shared/helpers/Composition.js";

export const getStateRoot = (state) => state.clients.lists.clientsLSubmittedList;
export const prefix = 'CLIENTS_SUBMITTED_LIST_';

const getInitState = () => ListReducer.getInitState({
	waitingForNotificationSent: false,
	waitingForNotifications: false,
	validationActive: false,
	isSuccessFormView: false,
	sortBy: 'selmo_user_notifications.date',
	sortDir: 'desc',
});

const getReduceMap = () => new Composition(
	ListReducer.getReduceMap(),
	{
		SET_WAIT_FOR_NOTIFICATION_SENT: (state, action) => ({...state, waitingForNotificationSent: action.toggle})
	},
	{
		SET_VALIDATION_ACTIVE: (state, action) => ({...state, validationActive: action.toggle})
	},
	{
		SET_SUCCESS_FORM_VIEW: (state, action) => ({...state, isSuccessFormView: action.toggle})
	},
	{
		SET_SEND_BATCH_WAIT_FOR_NOTIFICATION_SENT: (state, action) => ({...state, waitingForNotifications: action.toggle})
	},
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

