export const getAllMethodsNames = (source) => {
	const obj = typeof source === 'function' ? source.prototype : source;

	if (!obj || obj === Object.prototype) {
		return [];
	}

	const methods = Object.getOwnPropertyNames(obj)
		.filter(
			name => typeof obj[name] === 'function'
		)
		.filter(
			name => obj[name] !== source
		);

	return methods.concat(getAllMethodsNames(Object.getPrototypeOf(obj)));
};

export default class Composition {
	constructor(...sourceObjects) {
		sourceObjects.forEach(
			source => {
				getAllMethodsNames(source)
					.forEach(
						methodName => {
							const methodsHolder = typeof source === 'function'
								? source.prototype
								: source;
							this[methodName] = methodsHolder[methodName];
						}
					);
			}
		);
	}
	hasMethodsOf(target, skipMethods = []) {
		const targetMethodsNames = getAllMethodsNames(target);

		return targetMethodsNames.every(
			name => this[name] === target[name]
				|| skipMethods.indexOf(name) !== -1
		);
	}
}
