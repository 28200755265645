import {getStateRoot, prefix} from "./formReducer.js";
import {services} from "../../../../../RestServices.js";
import {RouterPaths} from "../../../../../routes/RouterPath.js";
import FormActions from "../../../../../../src_shared/form/FormActions.js";
import DefinedVariantsModel from "../../../../../../modules/models/lives/DefinedVariantsModel.js";

export class DefinedVariantModalActions extends FormActions {
	showExtraAddNewButton(toggle) {
		return {
			type: `${this.prefix}SHOW_EXTRA_ADD_NEW_BUTTON`,
			toggle,
		}
	}

	setUniqueProductCodeError(toggle) {
		return {
			type: `${this.prefix}SET_UNIQUE_PRODUCT_CODE_ERROR`,
			toggle,
		}
	}

}

export const getInstance = () => new DefinedVariantModalActions({
	getStateRoot,
	prefix,
	model: DefinedVariantsModel,
	restService: services.API_PRODUCTS,
	route: RouterPaths.ProductsList
});

export default getInstance();
