import React from "react";
import useForm from "../../../../src_shared/form/useForm";
import FormActions from "./FormActions";
import PaymentTypes from "../paymentTypes/PaymentTypes";
import {useSelector} from "react-redux";
import Modal from "react-bootstrap/Modal";
import FunctionalityPaymentRange from "./functionalityPaymentRange/FunctionalityPaymentRange";
import {FUNCTIONALITY_RANGE_MONTHLY} from "./functionalityPaymentRange/FunctionalityPaymentRangeEnums";
import FunctionalitySummary from "./functionalitySummary/FunctionalitySummary";
import {createSelector} from "reselect";
import {getStateRoot} from "./formReducer";
import TransferPage from "../transferPage/TransferPage";
import PaymentsSelectSkeleton from "../skeletons/PaymentsSelectSkeleton";
import InvoiceData from "../invoiceData/InvoiceData";
import useLang from "../../../../src_shared/hooks/useLang";
import {getDaysFromToday} from "../../../../src_shared/helpers/date";

const FunctionalityModal = ({title, desc, functionalityName, hiddenRange, openModal, setModalOpen}) => {

	const {userData} = useSelector((state) => state.session);
	const {isTransferPage} = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));
	const {getLangText} = useLang();


	const {
		data: {values, setValue, updateValues},
		form: {isWaiting, getFieldProps, onSave},
		modal: {modalVisible, hideModal}
	} = useForm({
		FormActions,
		params: {
			name: functionalityName,
		},
		requireParentId: false,
	});

	const closeModal = () => openModal ? setModalOpen(false) : hideModal();

	const setValueAndUpdatePrice = (field, value) => {
		setValue(field, value)
	}

	const preparedHiddenRange = hiddenRange || (!values.more_than_month && FUNCTIONALITY_RANGE_MONTHLY);

	const getCorrectDaysName = (functionality) => {
		if (!getDaysFromToday(functionality.expires_in)) {
			return <div className="title font-weight-normal">
				{getLangText('yourAccessIsEndingLabel')}&nbsp;<strong>{getLangText('todayLabel')}</strong></div>
		}
		if (getDaysFromToday(functionality.expires_in) === 1) {
			return <div className="title font-weight-normal">
				{getLangText('yourAccessIsEndingLabel')}&nbsp;<strong>{getLangText('tomorrowLabel')}</strong></div>
		}
		return <div className="title font-weight-normal">{getLangText('yourAccessIsEndingInLabel')}<strong>{getDaysFromToday(functionality.expires_in)}&nbsp;{getLangText('daysLabel')}</strong></div>
	}

	const activeFunctionality = userData?.functionalities?.find((i) => i.selmo_name === functionalityName);

	return (
		<Modal
			show={modalVisible || openModal}
			dialogClassName="default-modal"
			onHide={closeModal}
		>
			<Modal.Header className="with-border flex-wrap">
				<figure className="brand-logo">
					<img src="/assets/images/logo.svg" alt=""/>
				</figure>
				<button
					onClick={closeModal}
					type="button"
					className="button"
				>
					<i className="icon-cross"/>
				</button>
				<Modal.Title className="w-100">
					{title}
					<div className="sub-title">{desc}</div>
				</Modal.Title>
				{activeFunctionality?.expires_in &&
					<div className="form-info-box align-items-center mt-3 red w-100 mb-2 small-line-height">
						<div>
							<i className="icon-info-c"/>
						</div>
						{getCorrectDaysName(activeFunctionality)}
					</div>
				}
			</Modal.Header>
			<Modal.Body className="functional-payments-modal-body">
				{isTransferPage ?
					<TransferPage
						FormActions={FormActions}
						inModal
						values={values}
						summaryInfo={{
							totalPrice: values.total_price,
							totalPriceGross: values.totalPriceGross,
							taxRate: values.taxRate,
						}}
					/> :
					<div className="payments-container">
						<PaymentsSelectSkeleton
							withoutTop={true}
							hidden={!isWaiting}
						>
							<FunctionalityPaymentRange
								className="mt-2"
								setValue={setValueAndUpdatePrice}
								values={values}
								userData={userData}
								isWaiting={isWaiting}
								hiddenRange={preparedHiddenRange}
							/>
							<PaymentTypes
								setValue={setValue}
								values={values}
							/>
							<InvoiceData
								updateValues={updateValues}
								setValue={setValue}
								values={values}
								getFieldProps={getFieldProps}
								setValueAndUpdatePrice={setValueAndUpdatePrice}
							/>
							<FunctionalitySummary
								selectedSubscription={values.selected_subscription}
								values={values}
								userData={userData}
								isWaiting={isWaiting}
							/>
							<div className="submit-place">
								<button
									className="button primary w-100"
									type="button"
									onClick={onSave}
								>
									{getLangText('purchaseAccessLabel')}
								</button>
							</div>
						</PaymentsSelectSkeleton>
					</div>
				}
			</Modal.Body>
		</Modal>
	);
};

export default FunctionalityModal;



