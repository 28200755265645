import Model from "../../../src_shared/modules/model/Model";

class PackageTypesModel extends Model {
	getModel() {
		return {
			packageTypes: [],
			package_type_id: 0,
		};
	}

	buildDTO(data) {
		return {
			package_type_id: data.package_type_id,
		};
	}
}

export default new PackageTypesModel();
