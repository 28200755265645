import React, {useState} from 'react';
import PhotoPreviewButton from "../photoPreview/PhotoPreviewButton";
import useLang from "../../../hooks/useLang";
import usePhotoPreview from "../photoPreview/usePhotoPreview";
import VideoPreview from "../photoPreview/VideoPreview";
import HideDropdown from "./HideDropdown";
import {useSelector} from "react-redux";
import TutorialsVideo from "../../../../components/app/shared/enums/TutorialsVideo";

const TutorialPageBox = ({previewSrc, page, videoStatsName, benefits, title, className = ''}) => {
    const {showPreviewModal, hidePreviewModal, openPreviewModal} = usePhotoPreview();
    const {getLangText} = useLang();
    const [isVisible, setIsVisible] = useState(true);
    const {tutorialBoxes} = useSelector((state) => state.session.userData);
    const {lang} = useSelector((state) => state.i18n);

    const getMatchedBoxData = () => {
        const matched = TutorialsVideo.find((i) => i.id === videoStatsName);

        return {
            videoSrc: matched[`src_${lang}`] || null,
            headerTitle: matched.header,
        };
    }

    const isBoxHidden = !isVisible
        || (sessionStorage.getItem(`${page}TutorialBoxVisible`) && !+sessionStorage.getItem(`${page}TutorialBoxVisible`))
        || !!+tutorialBoxes[`hidden_${page}`];

    if (isBoxHidden || !getMatchedBoxData().videoSrc) {
        return null
    }

    return (
        <div
            className={`flex items-center sm:h-[90px] relative z-[1] rounded-[5px] bg-[#F6FCFF] border border-[#D7EAFE] tutorial-page-box sm:mb-3 p-[10px] group ${className}`}>
            <button
                type="button"
                onClick={openPreviewModal}
                className="grow text-left flex items-center"
            >
                <PhotoPreviewButton
                    src={previewSrc || `/assets/images/multiLanguage/${lang}/tutorialPageBox/${page.toLowerCase()}.webp`}
                    className="h-[45px] sm:h-[70px] mr-2.5 sm:mr-3 sm:w-auto w-[100px]"
                />
                <div className="sm:flex items-center grow">
                    <div className="sm:mb-0">
                        <div
                            className="text-xs text-[#6B7280] font-medium sm:block hidden">{getLangText('tutorialHelmetTitle')}</div>
                        <div
                            className="sm:text-lg text-sm sm:leading-6 leading-4 font-bold">{getLangText(getMatchedBoxData().headerTitle)}</div>
                    </div>
                    <div className="sm:block hidden ml-auto">
                        <div className="button border-button small-size bg-[white]">
                            {getLangText('watchVideoLabel')}
                        </div>
                    </div>
                </div>
            </button>
            <HideDropdown
                page={page}
                setIsVisible={setIsVisible}
            />
            <VideoPreview
                hide={hidePreviewModal}
                show={showPreviewModal}
                src={getMatchedBoxData().videoSrc}
                videoStatsName={videoStatsName}
                benefits={benefits}
                title={title}
            />
        </div>
    );
};

export default TutorialPageBox;
