import React, {useEffect, useState} from 'react';
import DeliveryType from "../../../basket/deliveryMethods/components/DeliveryType";
import useLang from "../../../../../src_shared/hooks/useLang";
import InpostShippingModal from "../../../orders/details/deliveryType/inpostShipping/Form";
import MondialRelayPointModal from "../../../shared/components/MondialRelaisPointModal/MondialRelaisPointModal";
import OrlenPointModal from "../../../shared/components/OrlenPointModal/OrlenPointModal";
import {services} from "../../../../RestServices";
import {
    DPD_POINT_MODAL, GLS_POINT_MODAL,
    INPOST_POINT_MODAL,
    MONDIAL_RELAY_POINT_MODAL,
    ORLEN_POINT_MODAL
} from "../../../basket/secondStep/SecondStep";
import useFetchData from "../../../../../src_shared/hooks/useFetchData";
import DpdPointModal from "../../../shared/components/DpdPointModal/DpdPointModal";
import GlsPointModal from "../../../shared/components/GlsPointModal/GlsPointModal";

const DeliveryMethods = ({getFieldProps, values, updateValues, shopId, updatePriceAndValues}) => {
    const {getLangText} = useLang();
    const [isPointModalOpen, setIsPointModalOpen] = useState(null);
    const [activeShippingId, setActiveShippingId] = useState(null);

    const updateValuesInPointsModalOnServer = async (obj) => {
        // await setValueOnServer(services.API_CART_SHIPPING_METHODS, 'shipping', obj)
        updateValues(obj);
    }

    const [{data, fetchData}] = useFetchData({
        rest: services.API_CHECKOUT_SHIPPING_METHODS,
        initialData: [],
        extraParams: {
            shop_id: shopId,
            country: values.country,
        },
        initialAutoLoad: false,
    });

    useEffect(() => {
        if (values.country) {
            fetchData()
        }
    }, [values.country])

    return (
        <div>
            <div className="font-bold mb-2">{getLangText('customerBasketDeliveryTypeLabel')}</div>
            <DeliveryType
                {...getFieldProps('shipping')}
                setValue={() => {}}
                values={{
                    ...values,
                    deliveries: data
                }}
                data={data}
                updateValues={updatePriceAndValues}
                setIsPointModalOpen={setIsPointModalOpen}
                setActiveShippingId={setActiveShippingId}
                currency={values.shopInfo?.currency_shortcut}
                className="popular-black-border"
            />
            {isPointModalOpen === INPOST_POINT_MODAL &&
                <InpostShippingModal
                    data={{
                        shippingData: {
                            zip_code: values.zip_code,
                        }
                    }}
                    updateValues={updateValuesInPointsModalOnServer}
                    formValues={values}
                    show={isPointModalOpen === INPOST_POINT_MODAL}
                    shippingId={activeShippingId}
                    hideModal={() => setIsPointModalOpen(null)}
                />
            }
            {isPointModalOpen === MONDIAL_RELAY_POINT_MODAL &&
                <MondialRelayPointModal
                    params={{
                        city: values.city,
                        country: values.country,
                        zip_code: values.zip_code,
                        shop_id: shopId,
                    }}
                    updateValues={updateValuesInPointsModalOnServer}
                    show={isPointModalOpen === MONDIAL_RELAY_POINT_MODAL}
                    hideModal={() => setIsPointModalOpen(null)}
                    shippingId={activeShippingId}
                />
            }
            {isPointModalOpen === ORLEN_POINT_MODAL &&
                <OrlenPointModal
                    updateValues={updateValuesInPointsModalOnServer}
                    show={isPointModalOpen === ORLEN_POINT_MODAL}
                    hideModal={() => setIsPointModalOpen(null)}
                    shippingId={activeShippingId}
                />
            }
            {isPointModalOpen === DPD_POINT_MODAL &&
                <DpdPointModal
                    updateValues={updateValuesInPointsModalOnServer}
                    show={isPointModalOpen === DPD_POINT_MODAL}
                    hideModal={() => setIsPointModalOpen(null)}
                    shippingId={activeShippingId}
                    params={{
                        city: values.city,
                        zip_code: values.zip_code,
                        street: values.street,
                    }}
                />
            }
            {isPointModalOpen === GLS_POINT_MODAL &&
                <GlsPointModal
                    updateValues={updateValuesInPointsModalOnServer}
                    show={isPointModalOpen === GLS_POINT_MODAL}
                    hideModal={() => setIsPointModalOpen(null)}
                    shippingId={activeShippingId}
                    params={{
                        city: data.shippingData.city,
                        zip_code: data.shippingData.zip_code,
                        street: data.shippingData.street,
                    }}
                />
            }
        </div>
    );
};

export default DeliveryMethods;
