import React from 'react';
import useLang from "../../../../../../src_shared/hooks/useLang";

const CollectionVariants = ({product}) => {
    const {getLangText} = useLang();

    return (
        <div className="products-list">
            <div className="products-header">
                <div className="name">{getLangText('variantNameLabel')}</div>
                <div className="count">{getLangText('availableLabel')}</div>
            </div>
            <div className="space-y-2 pb-2">
                {product?.sizes_extra?.map((sizeExtra) => (
                    <div
                        key={sizeExtra.id}
                        className="border rounded-[5px] p-2"
                    >
                        <div className="font-bold text-sm">{sizeExtra.selmo_code}</div>
                        {!!sizeExtra?.sizes?.length ?
                            <div className="">
                                {sizeExtra.sizes?.map((i) => (
                                    <div
                                        className="text-sm flex items-center border-b last:border-b-0 py-1"
                                        key={i.id}
                                    >
                                        <div className="name font-medium">
                                            {i.size}
                                        </div>
                                        <div className="count ml-auto font-bold">{i.squ}</div>
                                    </div>
                                ))}
                            </div> :
                            <div className="">
                                <div className="text-sm flex items-center py-1">
                                    <div className="name font-medium">
                                        {product.name}
                                    </div>
                                    <div className="count ml-auto font-bold">{sizeExtra.squ}</div>
                                </div>
                            </div>
                        }
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CollectionVariants;
