import {Fragment} from 'react'
import {Menu, Transition} from '@headlessui/react'

export default function Dropdown({Button, dropdownClassName, children, dropdownContentClassName}) {
    return (
        <Menu
            as="div"
            className={`relative inline-block text-left ${dropdownClassName}`}
        >
            <div>
                <Menu.Button
                    onClick={(e) => e.stopPropagation()}
                >
                    {({open}) => (
                        Button(open)
                    )}
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items
                    onClick={(e) => e.stopPropagation()}
                    className={`absolute right-0 z-10 mt-2 origin-top-right rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${dropdownContentClassName}`}>
                    <div
                        onClick={(e) => e.stopPropagation()}
                        className="p-1.5"
                    >
                        {children}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

Dropdown.defaultProps = {
    Button: (open) => (
        <div
            className={`rounded-full ml-3 inline-flex justify-center items-center w-[28px] h-[28px] text-[#9CA3AF] hover:bg-[#ECF0F8] transition-all mr-2 ${open ? 'bg-[#ECF0F8]' : ''}`}>
            <i className="icon-dots text-[3px]"/>
        </div>
    ),
    dropdownClassName: '',
    dropdownContentClassName: 'min-w-[210px]'
}
