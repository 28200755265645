import React, {useEffect} from 'react';
import {Redirect, Route, Switch, useLocation} from "react-router-dom";
import {RouterPaths} from "./RouterPath";
import LoginPage from "../app/login/Login";
import {useSelector} from "react-redux";
import ForgetPassword from "../app/login/ForgetPassword";
import LoginWrapper from "../app/login/LoginWrapper";
import Signup from "../app/login/signup/Signup";
import ResetPasswordPage from "../app/login/ResetPassword";
import CheckSessionWrapper from "../app/CheckSessionWrapper";
import {hotjar} from "react-hotjar";
import AuthSinglePage from "../app/login/singlePage/SinglePage";
import EmployeeSignupForm from "../app/login/employee/EmployeeSignupForm";
import RegisterInstagramPage from "../app/login/signup/SignupInstagram";
import LoginInstagramPage from "../app/login/LoginInstagram";
import AuthInstagramWrapper from "../app/login/instagram/AuthInstagramContext";
import ConnectInstagram from "../app/login/instagram/ConnectInstagram";
import InstagramWaitingForLogin from "../app/login/instagram/InstagramWaitingForLogin";
import {parseParams} from "../../src_shared/list/ListActions";

const AuthRoute = (props) => {
    const {logged, waitingForResponse} = useSelector((state) => state.session);
    const {search} = useLocation();
    const query = parseParams(search);

    useEffect(() => {
        hotjar.initialize(3450973, 6);
    }, [])

    if (logged && !waitingForResponse) {
        if (!!+query.fromSelmoPro) return <Redirect to={RouterPaths.SelmoPro}/>;
         return <Redirect to={RouterPaths.Dashboard}/>;

    }

    return (
        <Route path={props.path}>
            <Switch>
                <Route exact path={RouterPaths.AuthEmployeeFull} component={EmployeeSignupForm}/>
                {/*<Route path={RouterPaths.AuthInstagram}>*/}
                {/*    <AuthInstagramWrapper>*/}
                {/*        <Switch>*/}
                {/*            <Route exact path={RouterPaths.RegisterInstagram} component={RegisterInstagramPage}/>*/}
                {/*            <Route exact path={RouterPaths.LoginInstagram} component={LoginInstagramPage}/>*/}
                {/*            <Route exact path={RouterPaths.AuthInstagramConnect} component={ConnectInstagram}/>*/}
                {/*            <Route exact path={RouterPaths.AuthInstagramProgress} component={InstagramWaitingForLogin}/>*/}
                {/*            <Redirect to={RouterPaths.RegisterInstagram}/>*/}
                {/*        </Switch>*/}
                {/*    </AuthInstagramWrapper>*/}
                {/*</Route>*/}
                <CheckSessionWrapper>
                    <Switch>
                        <Route exact path={RouterPaths.AuthSinglePage} component={AuthSinglePage}/>
                        <LoginWrapper>
                            <Switch>
                                <Route exact path={RouterPaths.Login} component={LoginPage}/>
                                <Route exact path={RouterPaths.ForgetPassword} component={ForgetPassword}/>
                                <Route exact path={RouterPaths.Register} component={Signup}/>
                                <Route exact path={RouterPaths.ResetPassword} component={ResetPasswordPage}/>
                                <Redirect to={RouterPaths.Login}/>
                            </Switch>
                        </LoginWrapper>
                    </Switch>
                </CheckSessionWrapper>
            </Switch>
        </Route>
    );
};

export default AuthRoute;
