import React from 'react';
import SwitchField from "../../../../../../src_shared/components/form/switchField/SwitchField";
import useLang from "../../../../../../src_shared/hooks/useLang";
import Loader from "../../../../shared/Loader";

const AdditionalSettings = ({getFieldProps, onSave, isWaiting, activeCollectionId}) => {
    const {getLangText} = useLang();

    return (
        <div className="default-shadow-box summary-box loader-parent mt-3">
            <div className="mb-3">
                <div className="font-bold">
                    {getLangText('additionalSettings')}
                </div>
                <div className="text-desc text-sm">
                    {getLangText('adjustOverlay')}
                </div>
            </div>
            <SwitchField
                {...getFieldProps('with_variants')}
                className="switch-form inline-label big-label light-version"
                label={getLangText('showProductVariants')}
                id="with_variants"
            />
            <SwitchField
                {...getFieldProps('in_stock')}
                className="switch-form inline-label big-label light-version"
                label={getLangText('showOnlyAvailable')}
                id="in_stock"
            />
            <SwitchField
                {...getFieldProps('show_price')}
                className="switch-form inline-label big-label light-version"
                label={getLangText('showProductPrice')}
                id="show_price"
            />
            <SwitchField
                {...getFieldProps('show_product_name')}
                className="switch-form inline-label big-label light-version"
                label={getLangText(activeCollectionId ? 'showCollectionName' : 'showProductName')}
                id="show_product_name"
            />
            <div className="text-right">
                <button
                    onClick={onSave}
                    type="button"
                    className={`button primary ml-auto small-size ${isWaiting ? 'loading-button' : ''}`}
                >
                    {isWaiting && <Loader isLoading={true}/>}
                    {getLangText('update')}
                </button>
            </div>
        </div>
    );
};

export default AdditionalSettings;
