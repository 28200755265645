import React, {useState} from 'react';
import ConfirmModal from "../../../../live/details/default/summary/export/ConfirmModal";
import useLang from "../../../../../../src_shared/hooks/useLang";
import useSubmitData from "../../../../../../src_shared/hooks/useSubmitData";
import {services} from "../../../../../RestServices";
import {showAlert} from "../../../../shared/components/alert/AlertActions";
import {useDispatch} from "react-redux";
import SwitchField from "../../../../../../src_shared/components/form/switchField/SwitchField";

const ChangeProductPriceInOrdersModal = ({show, hide, id}) => {
    const {getLangText} = useLang();
    const dispatch = useDispatch();
    const [allOrders, setAllOrders] = useState(0);

    const [{onSave, isWaiting}] = useSubmitData(
        {
            rest: `${services.API_STORAGE_ORDER_PRODUCTS}/${id}`,
            values: {
                all_orders: !!+allOrders
            },
            afterSubmit: () => {
                hide();
                dispatch(showAlert('yuhuLabel', 'successfullyProductPriceChangedLabel'))
            }
        });

    return (
        <ConfirmModal
            title={getLangText('askToChangeProductPriceInOrders')}
            saveButton={getLangText('yesButton')}
            show={show}
            isWaiting={isWaiting}
            onSubmit={onSave}
            hide={hide}
            dialogClassName="sm-size"
        >
            <div className="form-info-box w-100 mb-3">
                <div>
                    <i className="icon-info-c"/>
                </div>
                <div>
                    <div className="title">{getLangText('attentionLabel')}</div>
                    <div className="desc" dangerouslySetInnerHTML={{__html: getLangText('rememberToSaveChangeBeforeSetUpProductPriceLabel')}} />
                </div>
            </div>
            <SwitchField
                label={getLangText('allOrdersLabel')}
                subLabel={getLangText('allOrdersSubLabel')}
                name="all_orders"
                setValue={(name, value) => setAllOrders(value)}
                value={allOrders}
                className="switch-form inline-label big-label light-version align-items-start"
            />
        </ConfirmModal>
    );
};

export default ChangeProductPriceInOrdersModal;
