import React, {useContext, useEffect} from 'react';
import {useHistory, useLocation} from "react-router-dom";
import useLang from "../../../../src_shared/hooks/useLang";
import {Helmet} from "react-helmet";
import Field from "../../../../src_shared/components/form/field/Field";
import Button from "../../../../src_shared/components/ui/Button/Button";
import {RouterPaths} from "../../../routes/RouterPath";
import useFormLocal from "../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../RestServices";
import CreateInstagramModel from "../../../../modules/models/auth/CreateInstagramModel";
import ComponentLoader from "../../../../src_shared/components/ui/componentLoader/ComponentLoader";
import {AuthInstagramContext} from "./AuthInstagramContext";
import useLogout from "../../shared/hooks/useLogout";
import {getFromLS, saveToLS} from "../../../../src_shared/helpers/LS";
import SessionActions from "../../shared/session/SessionActions";
import useFetchData from "../../../../src_shared/hooks/useFetchData";
import {getGETParamsUrl, parseParams} from "../../../../src_shared/list/ListActions";
import {useDispatch} from "react-redux";
import {showAlert} from "../../shared/components/alert/AlertActions";

const InstagramWaitingForLogin = () => {
    const {getLangText} = useLang();
    const dispatch = useDispatch();
    const history = useHistory();
    const {logout} = useLogout();
    const {search, pathname} = useLocation();
    const query = parseParams(search);

    // const {isLoading} = useContext(AuthInstagramContext);

    // const afterSubmit = () => history.push(RouterPaths.Automation)
    //
    // const [{onSave, isWaiting, getFieldProps}] = useFormLocal({
    //     rest: services.API_TUTORIAL_CREATE_INSTAGRAM_USER,
    //     model: CreateInstagramModel,
    //     initialAutoLoad: false,
    //     afterSubmit,
    // });

    const afterSubmit = (response) => {
        history.push(RouterPaths.Automation)

        // if (response.selmo_token) {
            // setSelmoAuthToken(response.selmo_token)
            // saveToLS('selmoAuthToken', response.selmo_token);
        // }
        // if (response.token) {
        //     dispatch(SessionActions.setAndSaveInStorageToken(response.token))
            // history.push(RouterPaths.Automation)
        // }
    }

    const [{isLoading, data}] = useFetchData({
        rest: `${services.API_TUTORIAL_CONNECT_INSTAGRAM}${getGETParamsUrl({
            ...query,
            selmo_token: getFromLS('selmoAuthToken'),
        })}`,
        afterSubmit,
        onError: (e) => {
            if (e?.response?.data?.alreadyConnected) {
                dispatch(showAlert('instagramAlreadyConnectedWithOtherAccount', '', 'alert-fail'))
                // history.push(RouterPaths.TutorialInstagramConnect)
            }
            history.push(RouterPaths.TutorialInstagramConnect)
        },
    });


    return (
        <div
            className={`sm:py-0 py-6 sm:px-0 px-6 w-[100vw] min-h-[100vh] flex sm:items-center sm:justify-center`}>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getLangText('accountConnecting')} - Selmo</title>
            </Helmet>
            <div
                className={`w-full sm:my-0 my-auto md:px-0 px-6 rounded-[12px] shadow-default bg-white md:w-[800px] min-h-[450px] md:min-h-[600px] flex flex-col ${true ? 'animate-tutorialSlideAndFadeIn' : ''}`}>
                <div
                    className={`text-center animate-tutorialSlideAndFadeInDelay1 opacity-0 mt-10 sm:mt-20 sm:mb-12 mb-8`}>
                    <img width={30} className="mx-auto mb-2" src="/assets/images/svg/plus-icon.svg"
                         alt="plus icon"/>
                    <h1 className="sm:text-2xl text-xl font-bold"
                        dangerouslySetInnerHTML={{__html: getLangText('accountConnecting')}}/>
                </div>
                <ComponentLoader className="small-loader higher-border-radius"/>
            </div>
            <button
                onClick={logout}
                type="button"
                className="absolute bottom-2 left-2 text-desc text-xs underline"
            >
                {getLangText('changeAccountLabel')}
            </button>
        </div>
    )
    //
    // return (
    //     <div
    //         className={`sm:py-0 py-6 sm:px-0 px-6 w-[100vw] flex sm:items-center sm:justify-center`}>
    //         <Helmet>
    //             <meta charSet="utf-8"/>
    //             <title>{getLangText('createYourShopLabel')} - Selmo</title>
    //         </Helmet>
    //         <button
    //             onClick={logout}
    //             type="button"
    //             className="absolute bottom-2 left-2 text-desc text-xs underline"
    //         >
    //             {getLangText('changeAccountLabel')}
    //         </button>
    //         <div
    //             className={`w-full sm:my-0 my-auto md:px-0 px-6 rounded-[12px] shadow-default bg-white md:w-[800px] min-h-[450px] md:min-h-[600px] flex flex-col`}>
    //             <div
    //                 className={`text-center animate-tutorialSlideAndFadeInDelay1 opacity-0 mt-10 sm:mt-20 sm:mb-12 mb-8`}>
    //                 <img width={30} className="mx-auto mb-2" src="/assets/images/svg/plus-icon.svg" alt="plus icon"/>
    //                 <h1 className="sm:text-2xl text-xl font-bold"
    //                     dangerouslySetInnerHTML={{__html: getLangText('createYourShopLabel')}}/>
    //                 <h2 className="text-[#9CA3AF] text-sm font-medium"
    //                     dangerouslySetInnerHTML={{__html: getLangText('addBasicInformationLabel')}}/>
    //             </div>
    //             <div
    //                 className="animate-tutorialSlideAndFadeIn opacity-0 w-full grow flex flex-col">
    //                 <form
    //                     id="onboarding-first-step-form"
    //                     className="w-full grow flex flex-col"
    //                     onSubmit={onSave}
    //                 >
    //                     <div
    //                         className={`max-w-[380px] mx-auto w-full animate-tutorialSlideAndFadeIn opacity-0`}>
    //                         <Field
    //                             {...getFieldProps('email')}
    //                             label={getLangText('emailAddressLabel')}
    //                             placeholder={getLangText('enterAddressEmailLabel')}
    //                             className="big-size mt-8"
    //                             type="email"
    //                             extraProps={{required: true}}
    //                         />
    //                     </div>
    //                     <div
    //                         className="mt-auto sm:mx-0 -mx-6 bg-[#F9FAFB] sm:w-full p-[20px] rounded-b-[12px] text-right">
    //                         <Button
    //                             isWaiting={isWaiting}
    //                             type="submit"
    //                             title="goNextLabel"
    //                             className="bg-[#3C61EA] overflow-hidden  button text-xs sm:text-sm font-semibold rounded-[8px] px-[20px] py-[11px] text-white transition-all hover:bg-[#1c4ed8]"
    //                         />
    //                     </div>
    //                 </form>
    //             </div>
    //         </div>
    //     </div>
    // );
};

export default InstagramWaitingForLogin;
