import React, {useEffect} from 'react';
import Modal from "react-bootstrap/Modal";
import useLang from "../../../../src_shared/hooks/useLang";
import useForm from "../../../../src_shared/form/useForm";
import FormActions from "./FormActions";
import DeliveryAddressForm, {isValidatedData} from "../../basket/deliveryAddress/components/Form";
import Loader from "../../shared/Loader";
import InvoiceData from "../../basket/deliveryAddress/components/InvoiceData";

const DeliveryAddressFormModal = ({hideModal, modalVisible, data, hash}) => {

    const {getLangText} = useLang();

    const {
        data: {values, setValue, updateValues},
        form: {isWaiting, onSave, getFieldProps},
    } = useForm({
        FormActions,
        params: {},
        requireParentId: false,
        preventAutoLoad: true,
    });

    useEffect(() => {
        updateValues({
            hash,
            selectedCountry: {
                id: data.country.country_id,
                name: data.country.country_name
            },
            country: data.country.country_id,
            shipping_cart: data.shipping_cart,
        })
    }, [modalVisible])

    return (
        <Modal
            show={modalVisible}
            className="add-order-modal"
            dialogClassName="default-modal"
            onHide={hideModal}
        >
            <Modal.Header className="with-border">
                <Modal.Title>{getLangText('customerBasketDeliveryDataLabel')}</Modal.Title>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            <Modal.Body className="pt-4 delivery-address-form-modal">
                <DeliveryAddressForm
                    magicUrl="guest"
                    shopId={data.id}
                    data={{
                        shopInfo: {
                            extra_input_enabled: data.extra_input_enabled
                        },
                        shipping_cart: data.shipping_cart,
                    }}
                    values={values}
                    setValue={setValue}
                    updateValues={updateValues}
                    onSave={onSave}
                    getFieldProps={getFieldProps}
                    withButton={false}
                />
                <InvoiceData
                    getFieldProps={getFieldProps}
                    setValue={setValue}
                    values={values}
                />
            </Modal.Body>
            <Modal.Footer>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button border-button"
                >
                    {getLangText('cancelButton')}
                </button>
                <button
                    type="button"
                    className={`button primary ${isWaiting ? 'loading-button' : ''} ${isValidatedData(values, data) ? '' : 'not-active'}`}
                    onClick={onSave}
                >
                    {isWaiting && <Loader isLoading/>}
                    {getLangText('saveButton')}
                </button>
            </Modal.Footer>
        </Modal>
    )
        ;
};

export default DeliveryAddressFormModal;
