import React from 'react';
import useLang from "../../../../../../../src_shared/hooks/useLang";
import {
    BUTTON_DESTINATION_LINK,
    BUTTON_DESTINATION_NEWSLETTER,
    BUTTON_DESTINATION_PRODUCT
} from "../addButton/buttonDestination/ButtonDestination";

const SALE_TEMPLATE = 'SALE_TEMPLATE';
const MAILING_LIST_TEMPLATE = 'MAILING_LIST_TEMPLATE';
const CUSTOM_LINK_TEMPLATE = 'CUSTOM_LINK_TEMPLATE';
const OWN_TEMPLATE = 'OWN_TEMPLATE';

const templates = [
    {id: SALE_TEMPLATE, label: 'sale', description: 'saleAutomationSubLabel', icon: 'cart1'},
    {id: MAILING_LIST_TEMPLATE, label: 'mailingList', description: 'mailingListAutomationSubLabel', icon: 'mail1'},
    {id: CUSTOM_LINK_TEMPLATE, label: 'customLink', description: 'customLinkAutomationSubLabel', icon: 'link'},
    {id: OWN_TEMPLATE, label: 'own', description: 'ownAutomationSubLabel', icon: 'custom'},
]

const SelectTemplate = ({values, updateValues}) => {

    const {getLangText} = useLang();

    const setValue = (id) => {
        const reply_message = id !== OWN_TEMPLATE;
        const add_button = id !== OWN_TEMPLATE;
        const reply_comment = id !== OWN_TEMPLATE;

        const getMessage = () => {
            if (id === SALE_TEMPLATE) {
                return getLangText('heyClickButtonToBuyLabel')
            }
            if (id === MAILING_LIST_TEMPLATE) {
                return getLangText('heyClickButtonNewsletterLabel')
            }
            if (id === CUSTOM_LINK_TEMPLATE) {
                return getLangText('heyClickButtonCustomLinkLabel')
            }
            return '';
        }
        const getButtonText = () => {
            if (id === SALE_TEMPLATE) {
                return getLangText('buyNowEmojiLabel')
            }
            if (id === MAILING_LIST_TEMPLATE) {
                return getLangText('joiningLabel')
            }
            if (id === CUSTOM_LINK_TEMPLATE) {
                return getLangText('moveOnLabel')
            }
            return '';
        }

        const getNewsletterTitle = () => {
            if (id === MAILING_LIST_TEMPLATE) {
                return getLangText('registerToNewsletterAutomationLabel')
            }
            return '';
        }

        const getNewsletterDescription = () => {
            if (id === MAILING_LIST_TEMPLATE) {
                return getLangText('registerToNewsletterAutomationSubLabel')
            }
            return '';
        }

        const getButtonDestination = () => {
            if (id === CUSTOM_LINK_TEMPLATE) {
                return BUTTON_DESTINATION_LINK;
            }
            if (id === MAILING_LIST_TEMPLATE) {
                return BUTTON_DESTINATION_NEWSLETTER
            }
            return BUTTON_DESTINATION_PRODUCT;
        }

        updateValues({
            template: id,
            reply_message,
            message: getMessage(),
            add_button,
            reply_comment,
            button_text: getButtonText(),
            buttonDestination: getButtonDestination(),
            newsletter_title: getNewsletterTitle(),
            newsletter_description: getNewsletterDescription(),
        })
    }

    return (
        <div>
            <div className="font-bold sm:text-xl">{getLangText('selectAutomationTemplate')}</div>
            <div className="data-boxes-wrapper separated-boxes light-version small-padd mt-3.5">
                {templates.map((item) => (
                    <div key={item.id}>
                        <div className={`content ${values.template === item.id ? 'active' : ''}`}>
                            <div className="content__top-part">
                                <input
                                    id={item.id}
                                    checked={values.template === item.id}
                                    onChange={() => setValue(item.id)}
                                    type="radio"
                                    name="template"
                                    required
                                />
                                <span className="checkmark"/>
                                <div className="flex-grow-1">
                                    <div className="label">
                                        {getLangText(item.label)}
                                    </div>
                                    <div className="data">
                                        {getLangText(item.description)}
                                    </div>
                                </div>
                                <div>
                                    <i className={`text-[21px] icon-${item.icon} ${item.id === values.template ? 'text-[#3C61EA]' : 'text-[#9CA3AF]'}`}/>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SelectTemplate;