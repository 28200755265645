import React from 'react';
import useFormLocal from "../../../src_shared/hooks/useFormLocal";
import {services} from "../../RestServices";
import useLang from "../../../src_shared/hooks/useLang";
import SwitchField from "../../../src_shared/components/form/switchField/SwitchField";
import Loader from "../../app/shared/Loader";
import ComponentLoader from "../../app/shared/components/componentLoader/ComponentLoader";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../routes/RouterPath";
import Model from "../../../src_shared/modules/model/Model";
import {Helmet} from "react-helmet";

const Platform = () => {
	const {getLangText} = useLang();
	const [{
		onSave,
		isWaiting,
		getFieldProps,
		isLoading,
	}] = useFormLocal({
		rest: services.API_SETTINGS_LIVE_PLATFORM,
		model: new Model(),
	});

	if (isLoading) return <ComponentLoader/>;

	return (
		<>
			<Helmet>
				<meta charSet="utf-8"/>
				<title>{getLangText('platformLabel')} - Selmo</title>
			</Helmet>
			<div className="header-bar breadcrumbs-in-react-app">
				<ul className="breadcrumbs">
					<li>
						<Link to={RouterPaths.Settings}>{getLangText('asideSettingsLabel')}</Link>
					</li>
					<li className="active">
						<div>{getLangText('platformLabel')}</div>
					</li>
				</ul>
			</div>
			<div className="settings-page sub-settings-page">
				<div className="header-wrapper">
					<h1>
						<img src="/assets/images/settings/live-settings.svg" alt="live-settings"/>
						Platforma <a href="https://www.selmo.live" target="_blank">&nbsp;selmo.live</a>
					</h1>
					<div className="description">
						Włącz wyświetlanie Twoich transmisji na naszej platformie sprzedażowej
					</div>
				</div>
				<div className="settings-form">
					<section>
						<div className="left-side">
							<h2 className="label">Widoczność <span>transmisji</span></h2>
						</div>
						<div className="form-col">
							<SwitchField
								{...getFieldProps('selmo_live_single')}
								label="Aktywuj możliwość sprzedaży na platformie"
								subLabel="Pozwoli Ci Twoim klientom na dokonywanie zakupów za pomocą kliknięcia przycisku “Dodaj
								do koszyka”.
								Jeśli prowadzisz transmisję sprzedażową, poproś klientów o napisanie w wiadomości
								prywatnej “selmo live”. Otrzymają link do Twojej transmisji na platformie."
								className="switch-form inline-label align-items-start big-label light-version mb-0 mt-0"

							/>
							<SwitchField
								{...getFieldProps('selmo_live')}
								label="Pokazuj moje transmisje na stronie głównej selmo.live"
								subLabel="Twoje transmisje na żywo pojawią się na platformie sprzedażowej"
								className="switch-form inline-label align-items-start big-label light-version mb-0 mt-4"

							/>
						</div>
					</section>
					<div className="button-place">
						<button
							type="button"
							onClick={onSave}
							className={`button primary ${isWaiting ? 'loading-button' : ''}`}
						>
							<Loader isLoading={isWaiting}/>
							{getLangText('saveButton')}
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default Platform;
