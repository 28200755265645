export const ALL_DAYS = 'all'
export const THREE_DAYS = 3
export const SEVEN_DAYS = 7

export const ADMIN_NOT_ACTIVE_FILTERS = [
	{
		value: ALL_DAYS, label: 'Wszystkie',
	},
	{
		value: THREE_DAYS, label: 'Nieaktywny ponad 3 dni',
	}
	,{
		value: SEVEN_DAYS, label: 'Nieaktywny ponad 7 dni'
	},
]
