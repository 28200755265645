import React from 'react';
import {useLocation} from "react-router-dom";
import {useDispatch} from "react-redux";
import ListActions from "../../../settings/searchModal/ListActions";
import {RouterPaths} from "../../../routes/RouterPath";

const SearchModalButton = () => {
    const {pathname} = useLocation();
    const hiddenButtonPaths = [RouterPaths.Settings, RouterPaths.SettingsShop, RouterPaths.SettingsPaymentsShipments, RouterPaths.SettingsMeta, RouterPaths.SettingsIntegration, RouterPaths.SettingsAccount]
    const dispatch = useDispatch();
    const showModal = (e) => {
        e?.currentTarget?.blur();
        dispatch(ListActions.modal.showModal())
    }

    if (hiddenButtonPaths.some((item) => item === pathname)) {
        return null
    }

    return (
        <button
            type="button"
            onClick={showModal}
            className={`rounded-2xl w-[30px] animate-tutorialFadeInLeft mr-2 h-[30px] flex items-center justify-center font-semibold text-[#4B5563] hover:bg-gray-200 transition-all text-xs ${true ? 'bg-gray-200' : ''}`}
        >
            <i className="icon-magnifier text-sm -mt-0.5"/>
        </button>
    );
};

export default SearchModalButton;
