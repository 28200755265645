import React from 'react';
import {Helmet} from "react-helmet";
import {useDispatch, useSelector} from "react-redux";
import CopyButton from "../../../../src_shared/components/form/copyButton/CopyButton";
import {INVOICE_DATA_COMPANY} from "../../shared/enums/InvoiceDataEnums";
import useLang from "../../../../src_shared/hooks/useLang";
import {useUnitPrice} from "../../shared/helpers/Price";
import {SHOP_ORIGIN_EN, SHOP_ORIGIN_IT, SHOP_ORIGIN_PL} from "../../shared/enums/ShopOrigins";

const TransferPage = ({values, summaryInfo, inModal = false, FormActions}) => {
    const dispatch = useDispatch();
    const {userData} = useSelector((state) => state.session);
    const {getLangText} = useLang();
    const {getPrice} = useUnitPrice();

    const invoiceTitle = `ID/${1400 + +userData.id}`

    return (
        <div className={`payments-page transfer-payment-page mb-sm-5 mb-3 ${inModal ? 'in-modal' : ''}`}>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getLangText('paymentTransferConfirmedLabel')} - Selmo</title>
            </Helmet>
            <button
                onClick={() => dispatch(FormActions.setTransferPage(false))}
                className="go-back"
            >
                <i className="icon-arrow-left"/>
                {getLangText('backLabel')}
            </button>
            <div className="payments-container">
                <div className="transfer-page-header">
                    <div>
                        <div className="label">{getLangText('customerBasketPaymentLabel')}</div>
                        <h1>{getLangText('paymentMethodTransferLabel')}</h1>
                    </div>
                    {!inModal &&
                        <div className="brand-name ml-auto">
                            <img src="/assets/images/logo.svg" alt=""/>
                        </div>
                    }
                </div>
                <section className="standard-transfer">
                    <div className="standard-transfer__bottom">
                        <div className="standard-transfer__bottom__title">
                            {getLangText('paymentTransferMakeTransfer')}
                        </div>
                        <div className="standard-transfer__bottom__content">
                            <div className="standard-transfer-box">
                                <div className="flex-grow-1">
                                    <div className="top-text">{getLangText('paymentTransferRecipientLabel')}</div>
                                    <div className="bottom-text">Selmo Sp. z o.o.</div>
                                </div>
                                <div>
                                    <CopyButton
                                        copyText="Selmo Sp. z o.o."
                                        copyButtonText={getLangText('copyButton')}
                                        copiedButtonText={getLangText('copyButton')}
                                        buttonClassName="border-button copied"
                                    />
                                </div>
                            </div>
                            <div className="standard-transfer-box">
                                <div className="flex-grow-1">
                                    <div className="top-text">{getLangText('paymentTransferAccountNumberLabel')}</div>
                                    <div className="bottom-text">
                                        {userData.origin === SHOP_ORIGIN_PL ?
                                            '77 2490 0005 0000 4600 4226 0582' :
                                            'PL 69 2490 0005 0000 4600 6892 3019'
                                        }
                                    </div>
                                </div>
                                <div>
                                    <CopyButton
                                        copyText={userData.origin === SHOP_ORIGIN_PL ?
                                            '77 2490 0005 0000 4600 4226 0582' :
                                            'PL 69 2490 0005 0000 4600 6892 3019'
                                        }
                                        copyButtonText={getLangText('copyButton')}
                                        copiedButtonText={getLangText('copyButton')}
                                        buttonClassName="border-button copied"
                                    />
                                </div>
                            </div>
                            {userData.origin !== SHOP_ORIGIN_PL &&
                                <div className="standard-transfer-box">
                                    <div className="flex-grow-1">
                                        <div className="top-text">SWIFT</div>
                                        <div className="bottom-text">
                                            ALBPPLPW
                                        </div>
                                    </div>
                                    <div>
                                        <CopyButton
                                            copyText="ALBPPLPW"
                                            copyButtonText={getLangText('copyButton')}
                                            copiedButtonText={getLangText('copyButton')}
                                            buttonClassName="border-button copied"
                                        />
                                    </div>
                                </div>
                            }
                            <div className="standard-transfer-box">
                                <div className="flex-grow-1">
                                    <div className="top-text">{getLangText('paymentTransferTitleLabel')}</div>
                                    {values.invoice === INVOICE_DATA_COMPANY ?
                                        <div
                                            className="bottom-text">{`ID/${1400 + +userData.id} - ${userData.name}`}</div> :
                                        <div className="bottom-text">{invoiceTitle}</div>
                                    }
                                </div>
                                <div>
                                    <CopyButton
                                        copyText={invoiceTitle}
                                        copyButtonText={getLangText('copyButton')}
                                        copiedButtonText={getLangText('copyButton')}
                                        buttonClassName="border-button copied"
                                    />
                                </div>
                            </div>
                            <div className="standard-transfer-box with-prices">
                                <div className="bottom-text mr-0">
                                    {getLangText('paymentNetPrice')}
                                    <div className="price">
                                        {getPrice(summaryInfo.totalPrice, values.currencyText)}
                                    </div>
                                </div>
                                <div className="bottom-text">
                                    {getLangText('paymentVatLabel')}
                                    <div className="price">
                                        {getPrice(summaryInfo.taxRate, values.currencyText)}
                                    </div>
                                </div>
                                {+userData.id == 2776 ? (
                                    <>
                                        <div className="bottom-text">
                                            {getLangText('paymentTransferPriceLabel')}
                                            <div className="price huge">
                                                {getPrice(summaryInfo.totalPrice, values.currencyText)}
                                            </div>
                                        </div>
                                        <div className="ml-auto">
                                            <CopyButton
                                                copyText={summaryInfo.totalPrice}
                                                copyButtonText={getLangText('copyButton')}
                                                copiedButtonText={getLangText('copyButton')}
                                                buttonClassName="border-button copied"
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="bottom-text">
                                            {getLangText('paymentTransferPriceLabel')}
                                            <div className="price huge">
                                                {getPrice(summaryInfo.totalPriceGross, values.currencyText)}
                                            </div>
                                        </div>
                                        <div className="ml-auto">
                                            <CopyButton
                                                copyText={summaryInfo.totalPriceGross}
                                                copyButtonText={getLangText('copyButton')}
                                                copiedButtonText={getLangText('copyButton')}
                                                buttonClassName="border-button copied"
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </section>
                <div className="form-info-box w-100 mt-4">
                    <div>
                        <i className="icon-info-c"></i>
                    </div>
                    <div>
                        <div className="title">{getLangText('paymentTransferPaidInfoHeader')}</div>
                        <div className="desc">
                            {getLangText('paymentTransferPaidInfoDescription')}
                            {userData.origin === SHOP_ORIGIN_EN &&
                                <a href="mailto: hello@selmo.io">
                                    hello@selmo.io
                                </a>
                            }
                            {userData.origin === SHOP_ORIGIN_PL &&
                                <a href="mailto: kontakt@selmo.io">
                                    kontakt@selmo.io
                                </a>
                            }
                            {userData.origin === SHOP_ORIGIN_IT &&
                                <a href="mailto: hello@selmo.io">
                                    hello@selmo.io
                                </a>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TransferPage;
