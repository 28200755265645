import React from 'react';
import ServerImage from "../../shared/components/serverImage/ServerImage";

const AbsoluteShopName = ({data, className = 'left-[37px] sm:left-[106px] top-[25px] sm:top-[46px]'}) => {
    return (
        <div className={`absolute flex items-center ${className}`}>
            <ServerImage src={data.photo} alt={data.name} className="sm:w-[30px] sm:h-[30px] w-[25px] h-[25px] rounded-full mr-1.5 sm:mr-2.5" />
            <div className="text-[#101827] sm:text-base text-sm font-bold">{data.name}</div>
        </div>
    );
};

export default AbsoluteShopName;
