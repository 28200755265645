import createReducer from "../../../shared/helpers/createReducer.js";
import Composition from "../../../shared/helpers/Composition.js";
import FormReducer from "../../../../../src_shared/form/FormReducer.js";
import AssignProductsCollectionModel from "../../../../../modules/models/AssignProductsCollectionModel";

export const getStateRoot = (state) => state.products.collections.assignProducts;
export const prefix = 'PRODUCTS_COLLECTIONS_ASSIGN_PRODUCTS_FORM_';

const getInitState = () => FormReducer.getInitState(AssignProductsCollectionModel.getModel());

const getReduceMap = () => new Composition(
	FormReducer.getReduceMap(),
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

