import React, {useState} from 'react';
import Field from "../../../src_shared/components/form/field/Field";
import useLang from "../../../src_shared/hooks/useLang";
import axios from "axios";
import {selmoUrl} from "../../../src_shared/api/api";
import {services} from "../../RestServices";
import {showAlert} from "../shared/components/alert/AlertActions";
import {useDispatch} from "react-redux";
import Loader from "../shared/Loader";
import {Link, useHistory, useParams} from "react-router-dom";
import {RouterPaths} from "../../routes/RouterPath";
import {Helmet} from "react-helmet";
import api from "../../../services/axios/axios";
import Button from "../../../src_shared/components/ui/Button/Button";

const ResetPasswordPage = () => {
    const {getLangText} = useLang();
    const dispatch = useDispatch();
    const history = useHistory();
    const [waiting, setWaiting] = useState(false);
    const [error, setError] = useState(null);
    const [values, setValues] = useState({
            password1: '',
            password2: '',
        }
    );

    const {id} = useParams();

    const setValue = (field, value) => setValues({...values, [field]: value})

    const onSubmit = async (e) => {
        e.preventDefault();
        if (values.password1 !== values.password2) {
            return setError('settings_form_validation_text_2')
        }
        setWaiting(true);
        try {
            await api.post(`${selmoUrl}/${services.API_RESET_PASSWORD}`, {
                password1: values.password1,
                password2: values.password2,
                hash: id,
            })
            setError(null)
            dispatch(showAlert('settings_form_validation_text_3', ''))
            history.push(RouterPaths.Login);

        } catch (e) {
            setError(e.response.data.message)
            dispatch(showAlert(e.response.data.message, '', 'alert-fail'))
        } finally {
            setWaiting(false)
        }
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getLangText('setNewPasswordLabel')} - Selmo</title>
            </Helmet>
            <a href="https://selmo.io">
                <img alt="selmo" width={107} src="/assets/images/logo.svg"/>
            </a>
            <h1 className="text-3xl font-bold mt-6 mb-8"
                dangerouslySetInnerHTML={{__html: getLangText('setNewPasswordLabel')}}/>
            <form
                id="reset-password-form"
                onSubmit={onSubmit} method="post">
                {error &&
                    <div className="form-info-box alert-style red w-100 mb-2">
                        <div>
                            <i className="icon-info-c"/>
                        </div>
                        <div className="title">
                            {getLangText(error)}
                        </div>
                    </div>
                }
                <Field
                    value={values.password1}
                    label={getLangText('input_label_new_password')}
                    setValue={setValue}
                    type="password"
                    name="password1"
                    extraProps={{required: true}}
                    className="big-size"
                    floatedLabel
                />
                <Field
                    value={values.password2}
                    label={getLangText('repeatNewPasswordLabel')}
                    setValue={setValue}
                    type="password"
                    name="password2"
                    extraProps={{required: true}}
                    className="big-size"
                    floatedLabel
                />
                <div className="button-place">
                    <Button
                        type="submit"
                        isWaiting={waiting}
                        title="setNewPasswordLabel"
                        className="button primary btn-proceed w-full"
                    />
                </div>
            </form>
            <div className="change-page">
                <Link to={RouterPaths.Login} className="special-link">{getLangText('loginButton')}</Link>
            </div>
        </>
    );
};

export default ResetPasswordPage;
