import React, {useState} from 'react';
import CustomDropdown from "../../../../../shared/components/dropdown/CustomDropdown.jsx";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {getStateRoot} from "./formReducer.js";
import FormActions from "./FormActions.js";
import OrdersProductsExport from "../../../../../shared/enums/OrdersProductsExport.js";
import {getGETParamsUrl} from "../../../../../../../src_shared/list/ListActions.js";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import ExportModal from "../../../../../../../src_shared/components/exportModal/ExportModal";
import {services} from "../../../../../../RestServices";
import {
    TNK_SHOP_ID,
    TEST_ACCOUNT_SHOP_ID,
    TIM_SHOP_ID
} from "../../../../../../../src_shared/enums/TrustedShopsIds";

const EXPORT_ORDERS = 'EXPORT_ORDERS';
const EXPORT_ORDERED_PRODUCTS = 'EXPORT_ORDERED_PRODUCTS';
const EXPORT_CLIENTS = 'EXPORT_CLIENTS';
const EXPORT_SALE_STATEMENTS = 'EXPORT_SALE_STATEMENTS';
const EXPORT_STOS_TIM = 'EXPORT_STOS_TIM';
const EXPORT_TIM = 'EXPORT_TIM';
const EXPORT_TNK = 'EXPORT_TNK';

const Export = ({liveInfoId}) => {
    const dispatch = useDispatch();
    const {getLangText} = useLang();
    const {userData} = useSelector((state) => state.session);

    const {data} = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));

    const [openExportModal, setOpenExportModal] = useState('');

    const setValue = (field, value) => dispatch(FormActions.data.setValue(field, value));
    const getParams = () => {
        const params = {}
        if (data.displayType === 2) {
            params.client_per_page = '1';
        }
        return getGETParamsUrl(params);
    }

    return (
        <div className="ml-sm-auto order-export-wrapper">
            <CustomDropdown
                icon="icon-print"
                tooltipTitle={getLangText('exportLabel')}
            >
                <button
                    type="button"
                    className="custom-option"
                    onClick={() => setOpenExportModal(EXPORT_ORDERS)}
                >
                    {getLangText('printOrdersPDFLabel')}
                </button>
                <button
                    type="button"
                    className="custom-option"
                    onClick={() => setOpenExportModal(EXPORT_ORDERED_PRODUCTS)}
                >
                    {getLangText('printOrderedProductsPDFLabel')}
                </button>
                <button
                    type="button"
                    className="custom-option"
                    onClick={() => setOpenExportModal(EXPORT_CLIENTS)}
                >
                    {getLangText('printClientsListPDFLabel')}
                </button>
                <button
                    type="button"
                    className="custom-option"
                    onClick={() => setOpenExportModal(EXPORT_SALE_STATEMENTS)}
                >
                    {getLangText('printSalesStatementPDFLabel')}
                </button>
                {(+userData.id === TIM_SHOP_ID || +userData.id === TEST_ACCOUNT_SHOP_ID) &&
                    <>
                        <button
                            type="button"
                            className="custom-option"
                            onClick={() => setOpenExportModal(EXPORT_STOS_TIM)}
                        >
                           STOS
                        </button>
                        <button
                            type="button"
                            className="custom-option"
                            onClick={() => setOpenExportModal(EXPORT_TIM)}
                        >
                           Prywatny eksport (TIM)
                        </button>
                    </>
                }
                {(+userData.id === TNK_SHOP_ID || +userData.id === TEST_ACCOUNT_SHOP_ID) &&
                    <button
                        type="button"
                        className="custom-option"
                        onClick={() => setOpenExportModal(EXPORT_TNK)}
                    >
                        Prywatny eksport (TNK)
                    </button>
                }
            </CustomDropdown>
            {openExportModal === EXPORT_ORDERS &&
                <ExportModal
                    title={getLangText('printOrdersLabel')}
                    subTitle={getLangText('printOrderListLivePDFLabel')}
                    saveButton={getLangText('printPDFLabel')}
                    rest={`${services.API_EXPORT_LIVE_PDF}/${liveInfoId}${getParams()}`}
                    fileName="ORDERS"
                    show={openExportModal === EXPORT_ORDERS}
                    hide={() => setOpenExportModal('')}
                >
                    <div className="form-label mt-2">
                        {getLangText('displayMethodLabel')}
                    </div>
                    <div className="data-boxes-wrapper">
                        {OrdersProductsExport.map((item) => (
                            <div
                                key={item.id}
                                className="content content-shipping">
                                <div className="content__top-part">
                                    <input
                                        type="radio"
                                        name="clients"
                                        checked={data.displayType === item.id}
                                        onChange={() => setValue('displayType', item.id)}
                                    />
                                    <span className="checkmark"/>
                                    <div className="flex-grow-1">
                                        <div className="label">{getLangText(item.label)}</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </ExportModal>
            }
            {openExportModal === EXPORT_ORDERED_PRODUCTS &&
                <ExportModal
                    title={getLangText('printOrderedProductsPDFLabel')}
                    subTitle={getLangText('receivedFileInPDFFormatLabel')}
                    saveButton={getLangText('exportLabel')}
                    rest={`${services.API_EXPORT_LIVE_PRODUCTS_PDF}/${liveInfoId}${getParams()}`}
                    fileName="PRODUCTS"
                    show={openExportModal === EXPORT_ORDERED_PRODUCTS}
                    hide={() => setOpenExportModal('')}
                >
                    <div className="form-label mt-2">
                        {getLangText('displayMethodLabel')}
                    </div>
                    <div className="data-boxes-wrapper">
                        {OrdersProductsExport.map((item) => (
                            <div
                                key={item.id}
                                className="content content-shipping">
                                <div className="content__top-part">
                                    <input
                                        type="radio"
                                        name="clients"
                                        checked={data.displayType === item.id}
                                        onChange={() => setValue('displayType', item.id)}
                                    />
                                    <span className="checkmark"/>
                                    <div className="flex-grow-1">
                                        <div className="label">{getLangText(item.label)}</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </ExportModal>
            }
            {openExportModal === EXPORT_CLIENTS &&
                <ExportModal
                    title={getLangText('printClientsListPDFLabel')}
                    subTitle={getLangText('printOrderListLivePDFLabel')}
                    saveButton={getLangText('exportLabel')}
                    rest={`${services.API_EXPORT_LIVE_CLIENTS_PDF}/${liveInfoId}${getParams()}`}
                    fileName="CLIENTS"
                    show={openExportModal === EXPORT_CLIENTS}
                    hide={() => setOpenExportModal('')}
                >
                    <div className="form-label mt-2">
                        {getLangText('displayMethodLabel')}
                    </div>
                    <div className="data-boxes-wrapper">
                        {OrdersProductsExport.map((item) => (
                            <div
                                key={item.id}
                                className="content content-shipping">
                                <div className="content__top-part">
                                    <input
                                        type="radio"
                                        name="clients"
                                        checked={data.displayType === item.id}
                                        onChange={() => setValue('displayType', item.id)}
                                    />
                                    <span className="checkmark"/>
                                    <div className="flex-grow-1">
                                        <div className="label">{getLangText(item.label)}</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </ExportModal>
            }
            {openExportModal === EXPORT_SALE_STATEMENTS &&
                <ExportModal
                    title={getLangText('printSalesStatementPDFLabel')}
                    subTitle={getLangText('receivedFileInXLSFormatLabel')}
                    saveButton={getLangText('exportLabel')}
                    rest={`${services.API_EXPORT_LIVE_SALES_STATEMENT_XLS}/${liveInfoId}${getParams()}`}
                    fileName="SUMMARY"
                    fileType="xlsx"
                    show={openExportModal === EXPORT_SALE_STATEMENTS}
                    hide={() => setOpenExportModal('')}
                />
            }
            {openExportModal === EXPORT_STOS_TIM &&
                <ExportModal
                    title="STOS"
                    subTitle={getLangText('receivedFileInPDFFormatLabel')}
                    saveButton={getLangText('exportLabel')}
                    rest={`${services.API_EXPORT_LIVE_STOS_PDF}/${liveInfoId}`}
                    fileName="stos"
                    show={openExportModal === EXPORT_STOS_TIM}
                    hide={() => setOpenExportModal('')}
                />
            }
            {openExportModal === EXPORT_TIM &&
                <ExportModal
                    title="Prywatny eksport"
                    subTitle={getLangText('receivedFileInPDFFormatLabel')}
                    saveButton={getLangText('exportLabel')}
                    rest={`${services.API_EXPORT_LIVE_TIM_PDF}/${liveInfoId}`}
                    fileName="prywatny-eksport"
                    show={openExportModal === EXPORT_TIM}
                    hide={() => setOpenExportModal('')}
                />
            }
            {openExportModal === EXPORT_TNK &&
                <ExportModal
                    title="Prywatny eksport"
                    subTitle={getLangText('receivedFileInPDFFormatLabel')}
                    saveButton={getLangText('exportLabel')}
                    rest={`${services.API_EXPORT_LIVE_TNK_PDF}/${liveInfoId}`}
                    fileName="prywatny-eksport"
                    show={openExportModal === EXPORT_TNK}
                    hide={() => setOpenExportModal('')}
                />
            }
        </div>
    );
};

export default Export;
