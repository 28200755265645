import React, {useState} from 'react';
import LivesClientsListViews, {
	LIVES_CLIENTS_LIST_VIEW,
	LIVES_PRODUCTS_LIST_VIEW
} from "../../../../shared/enums/LivesClientsViews.js";
import ClientsList from "./clientsList/ClientsList.jsx";
import ProductsList from "./productsList/List.jsx";
import {useSelector} from "react-redux";
import useLang from "../../../../../../src_shared/hooks/useLang";

const ClientsProductsContainer = ({id}) => {

	const [activeView, setActiveView] = useState(LIVES_CLIENTS_LIST_VIEW)
	const {liveInfo} = useSelector((state) => state.lives.details)
	const {getLangText} = useLang();

	return (
		<div className="settings-page clients-products-wrapper">
			{+id > 6011 && !!+liveInfo.storage_products &&
				<div className="tabs-wrapper">
					<button
						type="button"
						onClick={() => setActiveView(LIVES_CLIENTS_LIST_VIEW)}
						className={activeView === LIVES_CLIENTS_LIST_VIEW ? 'active' : ''}
					>
						{getLangText('asideClientsLabel')}
					</button>
					<button
						type="button"
						onClick={() => setActiveView(LIVES_PRODUCTS_LIST_VIEW)}
						className={activeView === LIVES_PRODUCTS_LIST_VIEW ? 'active' : ''}
					>
						{getLangText('asideProductsLabel')}
					</button>
				</div>
			}
			{activeView === LIVES_CLIENTS_LIST_VIEW ?
				<ClientsList id={id}/> :
				<ProductsList id={id}/>
			}
		</div>
	);
};

export default ClientsProductsContainer;
