import React, {useState, useRef, useEffect} from 'react';
import {useDispatch, batch, useSelector} from "react-redux";
import {
	addNewMessageToConversation,
	setSendStatusMessage
} from "../ChatBoxActions";
// import 'emoji-mart/css/emoji-mart.css'
// TODO
import UploadPhoto from "./UploadPhoto.jsx";
import EmojiPicker from "./EmojiPicker.jsx";
import moment from "moment";
import {selmoUrl} from "../../../../../src_shared/api/api";
import useWindowDimensions from "../../hooks/useWindowDimensions/useWindowDimensions";
import {updateItemInList} from "../../chatList/ChatListActions";
import {showAlert} from "../../../shared/components/alert/AlertActions";
import api from "../../../../../services/axios/axios";
import {SKLEP_ODZIEZOWY_U_EMI_SHOP_ID} from "../../../../../src_shared/enums/TrustedShopsIds";
import MessageTemplates from "./messageTemlates/MessageTemplates";

const SendInput = ({activeConversation, lastId, setStatusVisible, messageScrollRef}) => {

	const dispatch = useDispatch();
	const [inputValue, setInputValue] = useState('')
	const [fileValue, setFileValue] = useState(null);
	const [imgUrl, setImgUrl] = useState(null);
	const textAreaRef = useRef(null);
	const [enterClick, setEnterClick] = useState(false);
	const [textAreaHeight, setTextAreaHeight] = useState("auto");
	const {width} = useWindowDimensions();
	const {userData} = useSelector((state) => state.session);

	const resetTextArea = () => {
		setInputValue('')
		setImgUrl(null)
		setFileValue(null)
		setTextAreaHeight("50px");
	}

	useEffect(() => {
		if (!enterClick) {
			textAreaRef.current?.scrollTo(0, textAreaRef.current?.scrollHeight)
			setTextAreaHeight(`${textAreaRef.current?.scrollHeight}px`);
		} else {
			resetTextArea()
		}
		setTimeout(() => {
			setEnterClick(false)
		}, 0)
	}, [inputValue, enterClick]);

	const sendMessage = (message) => {
		const preparedId = lastId ? lastId + 1 : (lastId === 0 ? 1 : 0);

		const preparedMessage = () => {
			if (+userData.id === SKLEP_ODZIEZOWY_U_EMI_SHOP_ID) {
				return message.charAt(0).toUpperCase() + message.slice(1);
			} else
				return message;
		}

		const preparedData = {
			message: preparedMessage(),
			recipient: activeConversation.user_id,
			file: fileValue,
			instagramMessage: !!activeConversation.instagram_conversation_id
		}

		const preparedLastMessage = {
			...activeConversation,
			last_message: message,
			last_update: moment().format("YYYY-MM-DD HH:mm:ss"),
		}

		batch(() => {
			dispatch(addNewMessageToConversation({
				id: preparedId,
				date: moment().format("YYYY-MM-DD HH:mm:ss"),
				is_sent: false,
				sender: activeConversation.page_id,
				image: imgUrl,
				file_name: imgUrl?.name,
				file_size: imgUrl?.size,
				...preparedData,
			}))
			dispatch(updateItemInList(preparedLastMessage, activeConversation.id))

		})
		setStatusVisible(true)
		setTimeout(() => {
			messageScrollRef.current.scrollIntoView(
				{
					behavior: 'smooth',
				})
		})

		const setStatusDelay = () => {
			setTimeout(() => {
				setSendStatusMessage(preparedId, true)
			}, 700)
		}

		const getFormData = (object) => {
			const formData = new FormData();
			Object.keys(object).forEach(key => formData.append(key, object[key]));
			return formData;
		};

		api.post(`${selmoUrl}/api/message`, getFormData(preparedData))
			.then(() => {
				dispatch(setSendStatusMessage(preparedId, 'filled'))
				setStatusDelay()
			})
			.catch((e) => {
				dispatch(setSendStatusMessage(preparedId, false))
				if (e?.response?.data?.message) {
					dispatch(showAlert('sthWentWrongWarning', e?.response?.data?.message, 'alert-fail'))
				}
			})
			.finally(() => setStatusDelay())
	}

	const handleKeyDown = (e) => {
		const num = textAreaHeight.replace(/[^0-9]/g, '')
		const isNotEmptyValue = (inputValue.replace(/\s+/g, '').length !== 0 && inputValue.length !== 0) || !!fileValue
		if (e.key === 'Enter' && /\s/g.test(inputValue)) {
			inputValue.replace(/\s/g, "")
		}

		if (e.key === 'Enter' && !e.shiftKey && width > 991) {
			e.preventDefault();
		}

		if (textAreaRef.current && textAreaRef.current.selectionStart === 0 && e.keyCode === 8 && num > 50) {
			setTextAreaHeight(`${num - 24}px`)
		}

		if (isNotEmptyValue && e.key === 'Enter' && !e.shiftKey && width > 991) {
			setEnterClick(true)
			sendMessage(inputValue)
		}
	}

	const sendFromButton = () => {
		const isNotEmptyValue = (inputValue.replace(/\s+/g, '').length !== 0 && inputValue.length !== 0) || !!fileValue

		if (isNotEmptyValue) {
			setEnterClick(true)
			sendMessage(inputValue)
		}
	}

	const onChangeHandler = (e) => {
		// setTextAreaHeight("auto");
		setInputValue(e.target.value);
	};

	return (
		<div className="send-input-wrapper p-0 d-block">
			<UploadPhoto
				setImgUrl={setImgUrl}
				imgUrl={imgUrl}
				setFileValue={setFileValue}
			/>
			<div className="textarea-wrapper">
                <textarea
					ref={textAreaRef}
					rows={1}
					style={{
						height: textAreaHeight,
					}}
					onKeyDown={handleKeyDown}
					value={inputValue}
					onChange={onChangeHandler}
				/>
			</div>
			<div className="right-side-buttons">
				<MessageTemplates
					setInputValue={setInputValue}
					textAreaRef={textAreaRef}
				/>
				<EmojiPicker
					setInputValue={setInputValue}
					inputValue={inputValue}
				/>
				<button
					className="send-message icon-btn"
					onClick={sendFromButton}
				>
					<i className="icon-send"/>
				</button>
			</div>
		</div>
	);
};

export default SendInput;
