import {combineReducers} from "redux";
import listReducer from './list/listReducer'
import subListReducer from './subList/listReducer'
import tagListReducer from './tagList/listReducer'

export default combineReducers({
	list: listReducer,
	subList: subListReducer,
	tagList: tagListReducer,
});
