import Model, {convertObjectProperties} from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

class SettingsMondialRelaySenderDataModel extends Model {
	getModel() {
		return {
			first_name: '',
			last_name: '',
			street: '',
			city: '',
			zip_code: '',
			language: '',
			phone: '0',
			country_info: {},
		};
	}

	parseDTO(data) {
		return convertObjectProperties(
			data,
			{

			},
			{
				country_info: {},
				language: data?.country_info?.id,
			}
		);
	}

	getValidators() {
		return {
			first_name: ValidationError.notEmpty,
			last_name: ValidationError.notEmpty,
			street: ValidationError.notEmpty,
			city: ValidationError.notEmpty,
			zip_code: ValidationError.notEmpty,
			language: ValidationError.notEmpty,
			phone: ValidationError.notEmpty,
		};
	}
}

export default new SettingsMondialRelaySenderDataModel();
