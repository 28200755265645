import React, {useEffect} from 'react';
import Loader from "../shared/Loader";
import useLang from "../../../src_shared/hooks/useLang";
import {useHistory, useLocation} from "react-router-dom";
import {parseParams} from "../../../src_shared/list/ListActions";
import {services} from "../../RestServices";
import useFetchData from "../../../src_shared/hooks/useFetchData";

const PaymentProcessing = () => {
    const {getLangText} = useLang();
    const history = useHistory();

    const {search} = useLocation();
    const query = parseParams(search);
    const afterSubmit = (response) => history.push(response.redirectUrl);

    const [{fetchData}] = useFetchData({
        rest: services.API_CART_ORDER_PAYMENT_PROCESSING,
        afterSubmit,
        initialAutoLoad: false,
        extraParams: query,
        onError: () => {},
    });

    useEffect(() => {
        const intervalId = setInterval(() => {
            fetchData();
        }, 5000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className="w-[100vw] h-[100vh] flex flex-col items-center justify-center bg-white">
            <img className="w-[100px] mb-3" src="/assets/images/selmo-logo.svg" alt="selmo"/>
            <div className="flex">
                <Loader className="small-loader static-loader mr-2.5 mt-0.5 w-auto bg-transparent" isLoading/>
                {getLangText('processingPaymentLabel')}
            </div>
        </div>
    );
};

export default PaymentProcessing;
