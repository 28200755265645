export const TODAY_ORDERS_VIEW = 'todayOrders';
export const ALL_ORDERS_VIEW = 'allOrders';
export const INTERVAL = 'intervalOrders';
export const LAST_SEVEN_DAYS = 'lastSevenDays';
export const LAST_MONTH = 'lastMonth';

const OrderExportTypes = [
    {
        id: TODAY_ORDERS_VIEW,
        value: 'Dzisiaj',
    },
    {
        id: ALL_ORDERS_VIEW,
        value: 'Wszystkie zamówienia',
    },
    {
        id: INTERVAL,
        value: 'Własny przedział',
    },
    {
        id: LAST_SEVEN_DAYS,
        value: 'Ostatnie 7 dni',
    },
    {
        id: LAST_MONTH,
        value: 'Ostatnie 30 dni',
    },
];

export default OrderExportTypes;
