import Model, {convertObjectProperties} from "../../../../src_shared/modules/model/Model";
import ValidationError from "../../../../src_shared/form/validation/ValidationError";

export class UserModel extends Model {
	getModel() {
		return {
			id: 0,
			created_at: null,
			premium: null,
			promotion_date: null,
			test_account: '0',
			payment_type: null,
			selmo_active: '0',
			selmo_pro: '0',
			trialInfo: {
				status: false,
				daysLeft: {
					normalAmount: 0,
				},
				isTrial: false,
			},
		}
	}

	getValidators() {
		return {
			created_at: ValidationError.skip,
			email: ValidationError.notEmpty,
			premium: ValidationError.skip,
			promotion_date: ValidationError.skip,
			test_account: ValidationError.skip,
			selmo_active: ValidationError.skip,
			fakturownia_due_date: ValidationError.skip,
			product_code_due_date: ValidationError.skip,
			notifications_due_date: ValidationError.skip,
			store_due_date: ValidationError.skip,
			selmo_pro: ValidationError.skip,
			payment_type: ValidationError.skip,
			selmo_pro_active: ValidationError.skip,
			depot_system: ValidationError.skip,
			automatic_reserve_list: ValidationError.skip,
			employees_accounts: ValidationError.skip,
			selmo_pro_qualification: ValidationError.skip,
			automatic_transfer: ValidationError.skip,
			block_users_enabled: ValidationError.skip,
		};
	}

	buildDTO(data) {
		return {
			created_at: data.created_at,
			premium: data.premium,
			promotion_date: data.promotion_date,
			test_account: data.test_account,
			selmo_active: data.selmo_active,
			fakturownia_due_date: data.fakturownia_due_date,
			product_code_due_date: data.product_code_due_date,
			notifications_due_date: data.notifications_due_date,
			store_due_date: data.store_due_date,
			email: data.email,
			client_on_trial: data.client_on_trial,
			access_without_fanpage: data.access_without_fanpage,
			selmo_pro: data.selmo_pro,
			payment_type: data.payment_type,
			selmo_pro_active: data.selmo_pro_active,
			depot_system: data.depot_system,
			automatic_reserve_list: data.automatic_reserve_list,
			employees_accounts: data.employees_accounts,
			selmo_pro_qualification: data.selmo_pro_qualification,
			automatic_transfer: data.automatic_transfer,
			block_users_enabled: data.block_users_enabled,
		}
	}
}

export default new UserModel();
