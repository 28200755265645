import Model, {convertObjectProperties} from "../../src_shared/modules/model/Model";
import ValidationError from "../../src_shared/form/validation/ValidationError";

export class EditClientModel extends Model {
	getModel() {
		return {
			id: 0,
			name: '',
			localPhoto: null,
			photo: null,
			phone: '',
			email: '',
			is_deleted: '0',
			transfer_account_id: null,
		};
	}

	getValidators() {
		return {
			name: ValidationError.notEmpty,
			email: ValidationError.validateEmail,
			phone: ValidationError.skip,
			is_deleted: ValidationError.skip,
		};
	}

	parseDTO(data) {
		return convertObjectProperties(
			data,
			{
				id: () => data.shop_client_id,
				is_deleted: () => +data.is_deleted === 2 ? '1' : '0',
			},
			{},
		);
	}

	buildDTO(data) {
		return {
			name: data.name,
			photo: data.photo,
			phone: data.phone,
			email: data.email,
			transfer_account_id: data.transfer_account_id,
			is_deleted: !!+data.is_deleted ? '2' : '0',
		};
	}
}

export default new EditClientModel();
