import React from 'react';
import ConfirmModal from "../../../../../live/details/default/summary/export/ConfirmModal";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import Field from "../../../../../../../src_shared/components/form/field/Field";
import SwitchField from "../../../../../../../src_shared/components/form/switchField/SwitchField";

const ReturnProductModal = ({
                                hideRemoveModal,
                                itemToRemove,
                                confirmModalVisible,
                                onSave,
                                isWaiting,
                                setQuantity,
                                quantity,
                                returnSqu,
                                setReturnSqu,

                            }) => {
    const {getLangText} = useLang();
    return (
        <ConfirmModal
            dialogClassName="w-420"
            title={getLangText('returnOfProduct')}
            SubTitle={() => (
                <span
                    dangerouslySetInnerHTML={{
                        __html: getLangText({
                            key: 'wantToReturnProduct',
                            data: [itemToRemove.product_name]
                        })
                    }}
                />
            )}
            saveButton={getLangText('refund')}
            show={confirmModalVisible}
            isWaiting={isWaiting}
            onSubmit={onSave}
            hide={hideRemoveModal}
        >
            <Field
                value={quantity}
                setValue={(field, value) => setQuantity(value)}
                label={getLangText('quantityLabel')}
                type="number"
                extraProps={{
                    min: 1,
                    required: true,
                    max: itemToRemove.quantity
                }}
            />
            <SwitchField
                value={returnSqu}
                setValue={(field, value) => setReturnSqu(value)}
                label={getLangText('backToStorage')}
                subLabel={getLangText('productWillBackToStock')}
                className="switch-form inline-label align-items-start big-label light-version d-flex mb-0"
            />
        </ConfirmModal>
    );
};

export default ReturnProductModal;