import React, {useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {batch, useDispatch} from "react-redux";
import axios from "axios";
import {updateFieldInItem} from "../../../chatList/ChatListActions";
import Loader from "../../../loader/Loader.jsx";
import {addOrderModalToggleVisibility, showSuccessRemovedInfo} from "../../AddOrderModalActions";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {showAlert} from "../../../../shared/components/alert/AlertActions";
import {selmoUrl} from "../../../../../../src_shared/api/api";
import useLang from "../../../../../../src_shared/hooks/useLang";
import {chatBoxSetActiveConversation} from "../../../chatBox/ChatBoxActions";
import api from "../../../../../../services/axios/axios";

const RemoveOrder = ({orderData}) => {
	const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false)
	const {getLangText} = useLang();

	const dispatch = useDispatch();
	const [isWaiting, setIsWaiting] = useState(false);
	const removeOrder = () => {
		setIsWaiting(true)
		api.post(`${selmoUrl}/api/orderDelete`, {
			order_id: orderData.shop_order_id,
			customer_facebook_id: orderData.customer_facebook_id,
			facebook_page_id: orderData.facebook_page_id,
		})
			.then((res) => {
				batch(() => {
					dispatch(chatBoxSetActiveConversation(res.data.message))
					dispatch(showSuccessRemovedInfo())
					dispatch(showAlert('yuhuLabel', 'orderHasBeenRemovedLabel'))
					dispatch(addOrderModalToggleVisibility(false))
					dispatch(updateFieldInItem('orders_count', res.data.message.orders_count, res.data.message.id))
				})
				setIsConfirmModalVisible(false)
			})
			.catch((e) => {
				dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
				console.error(e)
			})
			.finally(() => setIsWaiting(false))
	}
	return (
		<>
			<OverlayTrigger
				placement='bottom'
				overlay={
					<Tooltip id={`tooltip-remove-products`}>
						{getLangText('removeOrderLabel')}
					</Tooltip>
				}
			>
				<button
					onClick={() => setIsConfirmModalVisible(true)}
					className="button"
				>
					<i className="icon-bin"/>
				</button>
			</OverlayTrigger>
			<Modal
				className="confirm-modal"
				show={isConfirmModalVisible}
				onHide={() => setIsConfirmModalVisible(false)}
			>
				<Modal.Header>
					<Modal.Title>
						<div dangerouslySetInnerHTML={{
							__html: getLangText({
								key: 'askForRemoveOrderWithNumberLabel',
								data: [orderData.shop_order_id]
							})
						}} />

					</Modal.Title>
					<button
						onClick={() => setIsConfirmModalVisible(false)}
						type="button"
					>
						<i className="icon-cross"/>
					</button>
				</Modal.Header>
				<Modal.Body>{getLangText('actionCannotBeUnDoneLabel')}</Modal.Body>
				<Modal.Footer>
					<button
						onClick={() => setIsConfirmModalVisible(false)}
						type="button"
						className="button border-button"
					>
						{getLangText('cancelButton')}
					</button>
					<button
						onClick={removeOrder}
						type="button"
						className="button red"
					>
						{isWaiting &&
							<Loader/>
						}
						{getLangText('removeButton')}
					</button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default RemoveOrder;
