import React from 'react';
import useLang from "../../../../src_shared/hooks/useLang";
import {useDispatch} from "react-redux";
import {filesSetModalVisibility} from "../header/files/filesDropdown/FilesActions";
import useFetchData from "../../../../src_shared/hooks/useFetchData";
import {getGETParamsUrl} from "../../../../src_shared/list/ListActions";
import Modal from "react-bootstrap/Modal";
import Loader from "../Loader";

const PrintLabelBatchModal = ({orders, rest, show, hide}) => {
    const {getLangText} = useLang();
    const dispatch = useDispatch();

    const afterSubmit = () => {
        hide();
        dispatch(filesSetModalVisibility(true));
    }

    const [{isLoading, fetchData: downloadFile}] = useFetchData({
        rest: `${rest}${getGETParamsUrl({'orders[]': orders.map((i) => i.id)})}`,
        initialAutoLoad: false,
        afterSubmit
    })

    return (
        <Modal
            dialogClassName={`default-modal small-modal`}
            show={show}
            onHide={hide}
        >
            <Modal.Header>
                <Modal.Title>
                    {getLangText('printLabelsLabel')}
                    <div className="description" dangerouslySetInnerHTML={{__html: getLangText('receivedFileInPDFFormatLabel')}}/>
                </Modal.Title>
                <button
                    onClick={hide}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            <Modal.Footer className="mt-3">
                <button
                    onClick={hide}
                    type="button"
                    className="button border-button"
                >
                    {getLangText('cancelButton')}
                </button>
                <button
                    onClick={downloadFile}
                    type="button"
                    className={`button px-0 primary ${isLoading ? 'loading-button' : ''}`}
                >
                    {isLoading && <Loader isLoading/>}
                    {getLangText('printLabel')}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default PrintLabelBatchModal;
