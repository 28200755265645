export const CLIENTS_TABLE_COLUMN_NUMBER = 'shop_client_id'
export const CLIENTS_TABLE_COLUMN_NAME = 'name'
export const CLIENTS_TABLE_COLUMN_BALANCE = 'balance'
export const CLIENTS_TABLE_COLUMN_EMAIL = 'email'
export const CLIENTS_TABLE_COLUMN_PHONE = 'phone'
export const CLIENTS_TABLE_COLUMN_ORDERS_COUNT = 'orders_count'
export const CLIENTS_TABLE_COLUMN_MONEY_SPENT = 'money_spent'

export const defaultTableColumn	= [CLIENTS_TABLE_COLUMN_NUMBER, CLIENTS_TABLE_COLUMN_NAME, CLIENTS_TABLE_COLUMN_BALANCE, CLIENTS_TABLE_COLUMN_EMAIL, CLIENTS_TABLE_COLUMN_PHONE, CLIENTS_TABLE_COLUMN_ORDERS_COUNT, CLIENTS_TABLE_COLUMN_MONEY_SPENT];

export const CLIENTS_TABLE_COLUMN = [
	{
		id: 0, value: CLIENTS_TABLE_COLUMN_NUMBER, label: 'numberShortLabel', className: ''
	},
	{
		id: 1, value: CLIENTS_TABLE_COLUMN_NAME, label: 'nameAndSurnameLabel', className: '',
	},
	{
		id: 2, value: CLIENTS_TABLE_COLUMN_BALANCE, label: 'balanceLabel', className: 'text-nowrap',
	},
	{
		id: 3, value: CLIENTS_TABLE_COLUMN_EMAIL, label: 'emailLabel', className: 'text-nowrap',
	},
	{
		id: 4, value: CLIENTS_TABLE_COLUMN_PHONE, label: 'phoneShortLabel', className: '',
	},
	{
		id: 5, value: CLIENTS_TABLE_COLUMN_ORDERS_COUNT, label: 'ordersLabel', className: '',
	},
	{
		id: 7, value: CLIENTS_TABLE_COLUMN_MONEY_SPENT, label: 'spentMoneyLabel', className: 'text-sm-right text-nowrap',
	},
]
