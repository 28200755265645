export const ORDER_TABLE_COLUMN_NUMBER = 'shop_order_id'
export const ORDER_TABLE_COLUMN_CLIENT = 'client'
export const ORDER_TABLE_COLUMN_STATUS = 'status'
export const ORDER_TABLE_COLUMN_PRICE = 'all_products_price'
export const ORDER_TABLE_COLUMN_TAGS = 'tags'
export const ORDER_TABLE_COLUMN_PAYMENT_DATE = 'payment_date'
export const ORDER_TABLE_COLUMN_PAYMENT = 'payment'
export const ORDER_TABLE_COLUMN_SHIPPING = 'shipping_name'
export const ORDER_TABLE_COLUMN_LABEL = 'label'
export const ORDER_TABLE_COLUMN_INVOICE = 'invoice'
export const ORDER_TABLE_COLUMN_DISCOUNT = 'discount_name'
export const ORDER_TABLE_NOTE = 'note'
export const ORDER_TABLE_LAST_MODIFIED = 'last_modified'
export const ORDER_TABLE_COLUMN_DATE = 'date'

export const defaultTableColumn	= [ORDER_TABLE_COLUMN_NUMBER, ORDER_TABLE_COLUMN_CLIENT, ORDER_TABLE_COLUMN_STATUS, ORDER_TABLE_COLUMN_PRICE, ORDER_TABLE_COLUMN_PAYMENT, ORDER_TABLE_COLUMN_DATE];

export const ORDER_TABLE_COLUMN = [
	{
		id: 0, value: ORDER_TABLE_COLUMN_NUMBER, label: 'numberShortLabel', className: 'number'
	},
	{
		id: 1, value: ORDER_TABLE_COLUMN_CLIENT, label: 'clientLabel', className: 'biggest-col',
	},
	{
		id: 2, value: ORDER_TABLE_COLUMN_STATUS, label: 'statusLabel', className: 'status-col',
	},
	{
		id: 3, value: ORDER_TABLE_COLUMN_PRICE, label: 'valueLabel', className: '',
	},
	{
		id: 4, value: ORDER_TABLE_COLUMN_TAGS, label: 'tagsLabel', className: '',
	},
	{
		id: 5, value: ORDER_TABLE_COLUMN_PAYMENT, label: 'customerBasketPaymentLabel', className: '',
	},
	{
		id: 6, value: ORDER_TABLE_COLUMN_SHIPPING, label: 'shipmentLabel', className: '',
	},
	{
		id: 7, value: ORDER_TABLE_COLUMN_LABEL, label: 'labelLabel', className: '',
	},
	{
		id: 8, value: ORDER_TABLE_COLUMN_INVOICE, label: 'invoiceLabel', className: '',
	},
	{
		id: 9, value: ORDER_TABLE_COLUMN_DISCOUNT, label: 'discountCodeLabel', className: 'text-nowrap',
	},
	{
		id: 10, value: ORDER_TABLE_NOTE, label: 'input_label_package_type_description', className: '',
	},
	{
		id: 11, value: ORDER_TABLE_LAST_MODIFIED, label: 'lastModifiedLabel', className: 'date text-nowrap text-right',
	},
	{
		id: 12, value: ORDER_TABLE_COLUMN_PAYMENT_DATE, label: 'lastDepositLabel', className: 'date text-nowrap text-right',
	},
	{
		id: 13, value: ORDER_TABLE_COLUMN_DATE, label: 'dateLabel', className: 'date text-right',
	},

]

export const findOrderTableColumnById = (id) => ORDER_TABLE_COLUMN.find((status) => status.value === id);
