import React, {useState} from 'react';
import ConfirmRemoveModal from "../../../src_shared/components/modal/ConfirmRemoveModal";
import useLang from "../../../src_shared/hooks/useLang";
import axios from "axios";
import {selmoUrl} from "../../../src_shared/api/api";
import {showAlert} from "../../app/shared/components/alert/AlertActions";
import {batch, useDispatch} from "react-redux";
import {services} from "../../RestServices";
import SessionActions from "../../app/shared/session/SessionActions";
import {Link} from "react-router-dom";
import {RouterPaths} from "../RouterPath";
import FunctionalityModal from "../../app/payments/functionalityModal/Form";
import {SHOP_PERMISSION} from "../../app/shared/enums/SelmoPermissions";

const NoShopPermissionComponentAfterTrial = ({className}) => {
	const [openModal, setOpenModal] = useState(false);
	const {getLangText} = useLang();

	return (
		<>
			<div className={`empty-state with-bg with-padd no-permission ${className}`}>
				<div className="img-icon">
					<img
						src="/assets/images/svg/circle-cross.svg"
						alt=""
					/>
				</div>
				<div className="title">{getLangText('noAccessLabel')}</div>
				<div className="desc">{getLangText('buyToUnLockFunctionLabel')}</div>
				<button
					type="button"
					onClick={() => setOpenModal(true)}
					className="button primary small-size mt-3"
				>
					{getLangText('purchaseAccessLabel')}
				</button>
			</div>
			{openModal &&
				<FunctionalityModal
					openModal={openModal}
					setModalOpen={setOpenModal}
					title={getLangText('shopModalTitleLabel')}
					desc={getLangText('shopModalTitleSubLabel')}
					functionalityName={SHOP_PERMISSION}
				/>
			}
		</>
	)
}

export default NoShopPermissionComponentAfterTrial;
