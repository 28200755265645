import {combineReducers} from "redux";
import detailsReducer from "./details/detailsReducer";
import filtersReducer from "./filters/filtersReducer";
import listsReducer from "./lists/listsReducer";

export default combineReducers({
	lists: listsReducer,
	details: detailsReducer,
	filters: filtersReducer,
});
