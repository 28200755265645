import React from 'react';
import useLang from "../../../../../../src_shared/hooks/useLang";
import ActivateButton from "./ActivateButton";
import usePhotoPreview from "../../../../../../src_shared/components/ui/photoPreview/usePhotoPreview";
import VideoPreview from "../../../../../../src_shared/components/ui/photoPreview/VideoPreview";

const SelmoProLandingIntroBox = ({title, imgSrc, videoSrc = null, imgClassName = ''}) => {
    const {getLangText} = useLang();
    const {showPreviewModal, hidePreviewModal, openPreviewModal} = usePhotoPreview();

    return (
        <div className="sm:px-[100px] sm:py-12 p-6 bg-[linear-gradient(180deg,rgba(41,41,41,0)_0%,_#333333_100%)] sm:min-h-[464px]">
            <div className="grid sm:grid-cols-2 items-center">
                <div>
                    <img
                        style={{width: 'auto', height: 24}}
                        className="mb-3"
                        src="/assets/images/selmo-pro-logo.png"
                        alt="logo-selmo"
                    />
                    <div
                        className="text-[#fff] font-bold leading-[32px] sm:leading-[41px] text-[26px] sm:text-[32px] selmo-pro-landing-title whitespace-pre-wrap"
                        dangerouslySetInnerHTML={{__html: getLangText(title)}}/>
                    <div className="sm:flex items-center mt-3">
                        <ActivateButton/>
                        {videoSrc &&
                            <button
                                onClick={openPreviewModal}
                                type="button"
                                className="font-semibold sm:w-auto justify-center sm:mt-0 mt-3.5 w-full text-[#fff] text-sm sm:ml-3 flex items-center mr-2"
                            >
                                <img
                                    className="h-[26px]"
                                    src="/assets/images/svg/play-white.svg"
                                    alt="play"
                                />
                                {getLangText('watchVideoLabel')}
                            </button>
                        }
                    </div>
                </div>
                <div className="h-full">
                    <img
                        className={imgClassName}
                        src={imgSrc}
                        alt=""
                    />
                </div>
            </div>
            {videoSrc &&
                <VideoPreview
                    src={videoSrc}
                    hide={hidePreviewModal}
                    show={showPreviewModal}
                    bigSize={true}
                />
            }
        </div>
    );
};

export default SelmoProLandingIntroBox;
