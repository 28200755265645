import {combineReducers} from "redux";
import productsListReducer from './productsList/listReducer'
import latestProductsListReducer from './latestProductsList/listReducer'
import reserveProductsListReducer from './reserveProductsList/listReducer'
import expendituresListReducer from './expenditures/listReducer'
import removedListReducer from './removedList/listReducer'

export default combineReducers({
	productsList: productsListReducer,
	latestProductsList: latestProductsListReducer,
	reserveProductsList: reserveProductsListReducer,
	expendituresList: expendituresListReducer,
	removedList: removedListReducer,
});
