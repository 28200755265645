import React, {useContext} from 'react';
import useLang from "../../../../../../src_shared/hooks/useLang";
import {SelmoProLandingContext} from "../SelmoProLandingWrapper";

const ActivateButton = ({className = ''}) => {
    const {data, isSubmittedToQualified, setShowModal} = useContext(SelmoProLandingContext);
    const {getLangText} = useLang();

    const classNames = `px-[18px] py-[14px] inline-block text-sm-left text-center text-sm button white rounded-[8px] hover:no-underline sm:w-auto w-full duration-300 relative transition-all bg-[white] font-bold text-[#000] shadow-2xl hover:shadow-[#5f87f3] hover:text-[#000] ${className}`

    const CheckIfYouQualifyButton = () => (
        <button
            onClick={() => setShowModal(true)}
            type="button"
            className={`${classNames} ${isSubmittedToQualified ? 'pointer-events-none' : ''}`}
        >
            {getLangText(isSubmittedToQualified ? 'yourApplicationHasBeenSubmitted' : 'checkIfYouQualify')}
        </button>
    )

    const ActivateButton = () => (
        <a
            href={data?.payment_link}
            className={classNames}
        >
            {getLangText('activeSelmoPro')}
        </a>
    )

    return (
        !!+data?.selmo_pro_qualification ?
            <ActivateButton/> :
            <CheckIfYouQualifyButton/>
    );
};

export default ActivateButton;
