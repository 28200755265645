import React, {useCallback, useRef, useState} from 'react';
import ClientAvatar from "../../../shared/Avatar.jsx";
import ClientHintDropdown from "./CustomerSelect.jsx";
import axios from "axios";
import debounce from "lodash.debounce";
import {selmoUrl} from "../../../../../../src_shared/api/api.js";
import optimizedMemo from "../../../../../../src_shared/helpers/optimizedMemo.js";
import {OverlayTrigger} from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import useLang from "../../../../../../src_shared/hooks/useLang";
import api from "../../../../../../services/axios/axios";

const ClientInput = ({
						 updateField,
						 customer,
						 extraClass = '',
						 rest = '/apilive/clients',
						 hintItem,
						 label,
						 clientAvatar,
						 hasCustomerError = false,
						 updateProductOnNameChange = () => {
						 },
						 isLiveFinished,
						 customerId,
						 showErrorAlert,
					 }) => {
	const inputRef = useRef(null)
	const [hints, setHints] = useState([]);
	const [hintsVisible, setHintsVisible] = useState(null);
	const {getLangText} = useLang();

	const hintDropdownFocus = (e) => {
		if (document.querySelector('.custom-dropdown .select-custom-option:first-child') && e.keyCode === 40) {
			document.querySelector('.custom-dropdown .select-custom-option:first-child').focus()
		}
	}

	const searchHints = async (name) => {
		if (name.length > 0) {
			try {
				const {data} = await api.get(`${selmoUrl}${rest}?search=${name}`)
				setHints(data)
				setHintsVisible(customerId)

			} catch (e) {
				setHints([])
				console.warn('Cannot get clients')
			}
		}
	}

	const debouncedSave = useCallback(
		debounce((name) => searchHints(name), 500),
		[]
	);

	const removeClient = () => {
		updateField('photo', null)
		updateField('name', '')
		updateField('shop_client_id', null)
		updateField('notSelmoClient', false)
		updateField('facebook_id', null)
		updateField('social_type', null)
		setTimeout(() => {
			inputRef.current?.focus()
		})
	}

	const onCustomerNameChange = (e) => {
		updateField('name', e.target.value)
		debouncedSave(e.target.value)
	}

	const isError = hasCustomerError && !customer.shop_client_id;

	const customerInputFormClassNames = () => {
		const errorClass = isError ? 'error-group' : '';
		const customerNumberClass = customer.shop_client_id ? 'with-client-number' : '';
		const errorAlertClass = showErrorAlert ? 'with-error-alert' : '';
		return `form-group ${extraClass} ${errorClass} ${customerNumberClass} ${errorAlertClass}`
	}

	return (
		<div className="tooltip-parent">
			{isError &&
				<div className="tooltip-content">
					{getLangText('selectClientFromListLabel')}
				</div>
			}
			<div className={customerInputFormClassNames()}>
				<label className="control-label">{getLangText(label) || label}</label>
				{clientAvatar(customer)}
				<input
					ref={inputRef}
					onChange={(e) => onCustomerNameChange(e)}
					onKeyDown={hintDropdownFocus}
					type="text"
					value={customer.name}
					tabIndex={customer.tabIndex}
					autoFocus={customer.tabIndex > 1}
					className={`form-control ${isLiveFinished ? 'order-created' : ''}`}
					autoComplete="off"
					required
					disabled={isLiveFinished || !!customer.shop_client_id || customer.notSelmoClient}
				/>
				{customer.shop_client_id &&
					<div className="client-number">
						{getLangText('clientNumberFullLabel')}: {customer.shop_client_id}
					</div>
				}
				{!isLiveFinished && (!!customer.shop_client_id || customer.notSelmoClient) &&
					<OverlayTrigger
						placement='top'
						overlay={
							<Tooltip id="remove-product">
								{getLangText('clientsRemoveClientTitle')}
							</Tooltip>
						}
					>
						<button
							type="button"
							className="remove-product"
							onClick={removeClient}
						>
							<i className="icon-cross"/>
						</button>
					</OverlayTrigger>
				}
				{(isError && showErrorAlert) &&
					<div className="error-text">
						{getLangText('fieldIsRequiredLabel')}
					</div>
				}
				<ClientHintDropdown
					onChange={updateField}
					customer={customer}
					hints={hints}
					setHints={setHints}
					hintsVisible={hintsVisible}
					setHintsVisible={setHintsVisible}
					hintItem={hintItem}
					updateProductOnNameChange={updateProductOnNameChange}
					customerId={customerId}
				/>
			</div>
		</div>
	);
};

ClientInput.defaultProps = {
	label: 'clientLabel',
	clientAvatar: (client) => (
		<ClientAvatar
			photo={client.photo}
			type={client.social_type}
		/>
	),
	showErrorAlert: false,

}

export default optimizedMemo(ClientInput);
