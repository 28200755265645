import React, {useContext, useEffect} from 'react';
import ImageUpload from "../../../../../../src_shared/components/form/imageUpload/ImageUpload";
import useFormLocal from "../../../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../../../RestServices";
import Model from "../../../../../../src_shared/modules/model/Model";
import Field from "../../../../../../src_shared/components/form/field/Field";
import useLang from "../../../../../../src_shared/hooks/useLang";
import Loader from "../../../../../admin/components/app/shared/Loader";
import {TeamContext} from "../../../../../routes/settings/teamRoute/TeamContext";

const Profile = ({id, defaultValues, fetchData}) => {
    const {getLangText} = useLang();
    const {fetchData: getMemberInfo} = useContext(TeamContext);

    const afterSubmit = () => {
        fetchData();
        getMemberInfo();
    }

    const [{
        getFieldProps,
        isLoading,
        setValue,
        values,
        onSave,
        isWaiting,
        updateValues,
    }] = useFormLocal({
        rest: `${services.API_PERMISSIONS_EMPLOYEES}/${id}`,
        model: new Model(),
        initialAutoLoad: false,
        afterSubmit,
        method: 'put',
    });

    useEffect(() => {
        updateValues({
            photo: defaultValues?.userInfo?.photo,
            name: defaultValues?.userInfo?.name,
            email: defaultValues?.userInfo?.email,
        })
    }, [defaultValues])

    return (
        <div className="bg-white rounded-[5px] mt-3 mb-3 p-6 shadow-default">
            <div className="flex items-center mb-4">
                <div>
                    <div className="form-label">
                        {getLangText('profilePicture')}
                    </div>
                    <ImageUpload
                        circleImage
                        defaultImage="avatar.svg"
                        values={values}
                        updateValues={updateValues}
                        imagePath="employees"
                        restService={`api/media/${id}`}
                        field="photo"
                        formats="correctFormatsPngJpgLabel"
                    />
                </div>
            </div>
            <div className="grid grid-cols-2 gap-5">
                <Field
                    {...getFieldProps('name')}
                    label={getLangText('nameAndSurnameLabel')}
                    setValue={setValue}
                />
                <Field
                    {...getFieldProps('email')}
                    label={getLangText('emailLabel')}
                    setValue={setValue}
                    type="email"
                />
            </div>
            <div className="text-right">
                <button
                    type="button"
                    onClick={onSave}
                    className={`button primary small-size ${isWaiting ? 'loading-button' : ''}`}
                >
                    {isWaiting && <Loader isLoading={true}/>}
                    {getLangText('saveChangesButton')}
                </button>
            </div>
        </div>
    );
};

export default Profile;
