import React from 'react';
import {Link, Redirect, Route, Switch} from "react-router-dom";
import {RouterPaths} from "./RouterPath";
import KnowledgeBasePage from "../app/knowledgeBase/KnowledgeBasePage";
import KnowledgeBaseDetail from "../app/knowledgeBase/detail/Detail";
import {useSelector} from "react-redux";
import {createSelector} from "reselect";
import {getStateRoot} from "../app/knowledgeBase/aside/listReducer";
import useLang from "../../src_shared/hooks/useLang";

const KnowledgeBaseRoute = ({path}) => {

	const {items} = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));
	const {getLangText} = useLang();

	const StartPage = () => {
		return (
			<div className="knowledge-base-hello-page">
				<div className="row">
					<div className="col-md-7">
						<h1>{getLangText('welcomeInKnowledgeBaseLabel')}</h1>
						<p>{getLangText('welcomeInKnowledgeBaseSubTitle')}</p>
						<div className="intro-img">
							<img src="/assets/images/svg/selmo-top.svg" alt=""/>
						</div>
						<div className="boxes-wrapper">
							{items.slice(0, 5).map((item) => (
								<Link
									to={`${RouterPaths.KnowledgeBase}/${item.first_article_name_url}`}
									key={item.id}
									className="box-with-border"
								>
									<div>
										<div className="title">
											{item.name}
										</div>
										<div className="desc">
											{getLangText('findHereLotOfInformationLabel')}
										</div>
									</div>
									<div className="button-place">
										<div className="button">
											<span className="d-sm-inline-block d-none">{getLangText('goToLabel')}</span>
											<i className="icon-arrows"/>
										</div>
									</div>
								</Link>
							))}
						</div>
					</div>
					<div className="col-md-5 mt-md-0 mt-3">
						<div className="boxes-wrapper">
							<div className="box-with-border d-block">
								<div>
									<div className="title">
										{getLangText('helpTitle')}
									</div>
									<div className="desc">
										{getLangText('writeToUsOrCallUsLabel')}
									</div>
								</div>
								<div className="contact-boxes">
									<a
										href="https://www.facebook.com/selmo.io/"
										target="_blank" className="messenger-btn">
										<i className="icon-messenger-2-f"/>
										{getLangText('newsTextToMessengerButton')}
									</a>
									<a href="mailto: kontakt@selmo.io">
										<i className="icon-mail"/>
										<span>kontakt@selmo.io</span>
									</a>
									<a href="tel: +48 790 700 424">
										<i className="icon-tel"/>
										<span>790 700 424</span>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		)
	}

	return (
		<KnowledgeBasePage>
			<Route path={path}>
				<Switch>
					<Route
						exact
						path={path}
						component={StartPage}
					/>
					<Route
						exact
						path={RouterPaths.KnowledgeBaseDetail}
						component={KnowledgeBaseDetail}
					/>
					<Redirect to={path}/>
				</Switch>
			</Route>
		</KnowledgeBasePage>
	);
};

export default KnowledgeBaseRoute;
