import React, {useState} from 'react';
import useLang from "../../../../src_shared/hooks/useLang";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import ActivateButton from "../../shared/components/ActivateButton/ActivateButton";
import FaqSection from "./components/Faq/FaqSection";
import usePhotoPreview from "../../../../src_shared/components/ui/photoPreview/usePhotoPreview";
import VideoPreview from "../../../../src_shared/components/ui/photoPreview/VideoPreview";

export const notificationsBenefits = [{id: 0, title: 'notificationBenefit1Label'}, {
    id: 1,
    title: 'notificationBenefit2Label'
}, {
    id: 2,
    title: 'notificationBenefit3Label'
},]

export const notificationsSteps = [
    {id: 1, title: 'askToTextSelmoApkaLabel'},
    {id: 2, title: 'clientInstallingAppLabel'},
    {id: 3, title: 'clientLookingForYourShopLabel'},
]

const Landing = () => {
    const {getLangText} = useLang();
    const {lang} = useSelector((state) => state.i18n);
    const {showPreviewModal, hidePreviewModal, openPreviewModal} = usePhotoPreview();
    const {userData} = useSelector((state) => state.session);

    return (
        <div className="shop-landing-wrapper px-6 lg:px-[110px] animate-[fadeIn_.5s_ease_forwards]">
            <section className="padd-section px-0">
                <div className="grid sm:grid-cols-2 gap-5">
                    <div className="">
                        <div className="section-sub-title">
                            {getLangText('applicationLabel')}
                        </div>
                        <div className="section-title mb-1">
                            <strong>{getLangText('sendNotificationsInSelmoLabel')}</strong>
                        </div>
                        <div
                            className="text-[#101827] text-sm font-bold mb-3 mt-4">{getLangText('pricingWhyWorthyTitle')}</div>
                        <div className="space-y-3 mt-3 mb-4">
                            {notificationsBenefits.map((i) => (<div
                                    key={i.id}
                                    className="flex items-start"
                                >
                                    <div className="mr-2 -mt-1">
                                        <i className="icon-tick-c text-[#5FA5FA] text-lg"/>
                                    </div>
                                    <div>
                                        <div
                                            className="text-[#101827] font-medium text-sm">{getLangText(i.title)}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <ActivateButton/>
                    </div>
                    <div className="relative flex">
                        <img
                            className="rounded-[15px] w-full object-cover"
                            src={`/assets/images/multiLanguage/${lang}/app/app-photo.webp`} alt=""/>
                        <div className="absolute left-0 right-0 bottom-6 flex justify-center items-center space-x-3">
                            <a
                                target="_blank"
                                rel="noreferrer"
                                className="block"
                                href={`https://onelink.to/selmo?utm_source=selmo&utm_medium=panel&utm_campaign=panel_landing&utm_content=${userData.id}`}
                            >
                                <img
                                    className="min-h-[35px]"
                                    src={`/assets/images/svg/app-store.svg`}
                                    alt=""/>
                            </a>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                className="block"
                                href={`https://onelink.to/selmo?utm_source=selmo&utm_medium=panel&utm_campaign=panel_landing&utm_content=${userData.id}`}
                            >
                                <img
                                    className="min-h-[35px]"
                                    src={`/assets/images/svg/google-play.svg`} alt=""/>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <section className="padd-section border-t px-0">
                <div className="">
                    <div className="section-sub-title">
                        {getLangText('applicationLabel')}
                    </div>
                    <div className="section-title mb-1">
                        <strong>{getLangText('howClientsCanSignupLabel')}</strong>
                    </div>
                    <div
                        className="mt-4 p-3 rounded-[10px] bg-[#F6FCFF] border border-[#D7EAFE] flex items-center flex-wrap">
                        <div className="max-w-[410px]">
                            <div className="text-lg font-bold mb-2">{getLangText('downloadAndSharedVideoLabel')}</div>
                            <div
                                className="text-sm text-desc font-medium">{getLangText('downloadAndSharedVideoSubLabel')}</div>
                            <a
                                download
                                href={`/assets/videos/${lang}/notifications.mp4`}
                                className="button primary small-size px-3 mt-[20px] text-center"
                            >
                                <i className="icon-download mr-1"/>
                                <span className="text-xs">{getLangText('downloadVideoLabel')}</span>
                            </a>
                        </div>
                        <div className="sm:ml-auto sm:w-auto w-full sm:mt-0 mt-3.5">
                            <div
                                onClick={openPreviewModal}
                                className={`relative h-[150px] w-full sm:w-[150px] cursor-pointer rounded-[10px] overflow-hidden after:content-[''] after:bg-[rgba(0,0,0,0.3)] after:absolute after:top-0 after:left-0 after:right-0 after:bottom-0 after:w-full after:h-full`}>
                                <img
                                    className="h-full w-full sm:w-auto object-cover"
                                    src={`/assets/images/multiLanguage/${lang}/notifications-video.png`}
                                    alt="preview"
                                />
                                <div
                                    className="absolute z-10 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 w-[54px] h-[54px] bg-[white] rounded-full flex items-center justify-center">
                                    <img
                                        style={{width: '14px'}}
                                        className="pointer-events-none  group-hover:scale-[115%] transition-all"
                                        src="/assets/images/svg/play-black.svg"
                                        alt="play"
                                    />
                                </div>
                            </div>
                            <VideoPreview
                                hide={hidePreviewModal}
                                show={showPreviewModal}
                                src={`/assets/videos/${lang}/notifications.mp4`}
                            />
                        </div>
                    </div>
                    <div className="mt-5">
                        <div className="text-lg font-bold mb-3">{getLangText('whatHaveToDoYourClientsLabel')}</div>
                        <div className="space-y-3">
                            {notificationsSteps.map((step) => (
                                <div className="p-[22px] rounded-[10px] flex items-center space-x-3 bg-[#F9FAFB]">
                                    <div
                                        className="sm:min-w-[32px] sm:text-[16px] text-sm min-w-[22px] h-[22px] sm:h-[32px] bg-[#E5E7EB] rounded-[5px] flex items-center justify-center font-bold">
                                        {step.id}
                                    </div>
                                    <div
                                        className="sm:text-lg"
                                        dangerouslySetInnerHTML={{__html: getLangText(step.title)}}/>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
            <FaqSection />
        </div>
    );
};

export default Landing;
