import createReducer from "../../../shared/helpers/createReducer";
import Composition from "../../../shared/helpers/Composition";
import FormReducer from "../../../../../src_shared/form/FormReducer";
import FurgonetkaLabelModel from "../../../../../modules/models/order/FurgonetkaLabelModel";

export const getStateRoot = (state) => state.orders.details.furgonetkaLabel;
export const prefix = 'ORDERS_DETAILS_FURGONETKA_LABEL_';

const getInitState = () => FormReducer.getInitState(FurgonetkaLabelModel.getModel());

const getReduceMap = () => new Composition(
	FormReducer.getReduceMap(),
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

