const initialState = {
	visibility: false,
	data: {
		status: 0,
		source: 0,
		note: '',
	},
	isLoading: false,
	isError: false,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case 'EDIT_SHOP_MODAL_TOGGLE_VISIBILITY':
			return {
				...state,
				visibility: action.toggle,
				isLoading: false,
				data: {...initialState.data}
			}
		case 'EDIT_SHOP_UPDATE_FIELD_IN_MODAL_FORM':
			return {...state, data: {...state.data, [action.field]: action.value}}
		case 'EDIT_SHOP_UPDATE_VALUES_IN_MODAL_FORM':
			return {...state, data: action.values}
		case 'EDIT_SHOP_SUBMIT_REQUESTED':
			return {...state, isLoading: true, isError: false};
		case 'EDIT_SHOP_SUBMIT_ERROR':
			return {...state, isLoading: false, isError: true}
		default:
			return state;
	}
}
