import React from 'react';
import ListActions from "./ListActions";
import {getStateRoot} from "./listReducer";
import useList from "../../../../../src_shared/list/useList";
import Pagination from "../../../shared/components/pagination/Pagination";
import {useParams} from "react-router-dom";
import useLang from "../../../../../src_shared/hooks/useLang";
import SearchInput from "../../../shared/components/form/SearchInput";
import Table from "./components/Table";

const ClientReserveList = () => {
	const params = useParams();
	const {getLangText} = useLang();

	const {
		pagination: {
			changePage,
			getPagination,
			itemsPerPage,
			nextPage,
			prevPage,
			totalItems,
			updateItemsPerPage
		},
		search,
		list: {items, isWaiting, loadData},
	} = useList({
		ListActions,
		getStateRoot,
		hasParentId: true,
		params: {
			id: params.id
		}
	});

	return (
		<div className="table-box-wrapper">
			<div
				style={{borderTopLeftRadius: 0, borderTopRightRadius: 0, overflow: 'visible'}}
				className="default-shadow-box bigger-x-padd">
				<div className="filters-part">
					<h1 className="title flex-grow-1">
						{getLangText('reserveListLabel')}
						{totalItems > 0 &&
							<div className="badge">{totalItems}</div>
						}
					</h1>
					<SearchInput
						className="ml-auto"
						placeholder={getLangText('searchPlaceholder')}
						search={search}
					/>
				</div>
				<div className="boxes-wrapper">
					<Table
						items={items}
						isWaiting={isWaiting}
						fetchData={() => loadData(params.id)}
					/>
				</div>
			</div>
			<Pagination
				getLangText={getLangText}
				pages={getPagination()}
				items={items}
				itemsPerPage={itemsPerPage}
				totalItems={totalItems}
				onPrevPage={prevPage}
				onChangePage={changePage}
				onNextPage={nextPage}
				onChangeItemsPerPage={updateItemsPerPage}
				classes={{
					prevIcon: 'icon-chevron left',
					nextIcon: 'icon-chevron right',
				}}
				showItemsPerPageSelect
			/>
		</div>
	);
};

export default ClientReserveList;
