import {getStateRoot, prefix} from "./formReducer.js";
import {services} from "../../../RestServices.js";
import GeneralModel from "../../../../modules/models/shopSettings/GeneralModel";
import {SharedShopSettingsActions} from "../shared/SharedShopSettingsActions";

export const getInstance = () => new SharedShopSettingsActions({
	getStateRoot,
	prefix,
	model: GeneralModel,
	restService: services.API_STORE_GENERAL,
});

export default getInstance();
