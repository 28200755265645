import React, {useState} from 'react';
import Modal from "react-bootstrap/Modal";
import useLang from "../../../../../../src_shared/hooks/useLang";
import SelectCountryForm from "./SelectCountryForm";
import SelectOptionForm from "./SelectOptionForm";

export const SELECT_COUNTRY_FORM_VIEW = 'SELECT_COUNTRY_FORM_VIEW';
export const SELECT_COURIER_FORM_VIEW = 'SELECT_COURIER_FORM_VIEW';
const PaxyModalForm = ({show, hide, submitRest, loadData, optionsModel, selectedItems}) => {
	const [activeView, setActiveView] = useState(SELECT_COUNTRY_FORM_VIEW)
	const [selectedCountry, setSelectedCountry] = useState(null);
	return (
		<Modal
			dialogClassName={`default-modal small-modal`}
			show={show}
			onHide={hide}
		>
			{activeView === SELECT_COUNTRY_FORM_VIEW &&
				<SelectCountryForm
					setSelectedCountry={setSelectedCountry}
					hide={hide}
					setActiveView={setActiveView}
				/>
			}
			{activeView === SELECT_COURIER_FORM_VIEW &&
				<SelectOptionForm
					hide={hide}
					selectedCountry={selectedCountry}
					setActiveView={setActiveView}
					loadData={loadData}
					submitRest={submitRest}
					optionsModel={optionsModel}
					selectedItems={selectedItems}
				/>
			}
		</Modal>
	);
};

export default PaxyModalForm;
