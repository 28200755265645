import Model from "../../../src_shared/modules/model/Model";

class SettingsStripeModel extends Model {
    getModel() {
        return {
            stripe_active: '0',
        };
    }

    buildDTO(data) {
        const {stripe_active, stripe_price, stripe_price_type} = data;
        return {
            stripe_active,
            stripe_price,
            stripe_price_type,
        }
    }
}

export default new SettingsStripeModel();
