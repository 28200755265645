import React from 'react';
import {useSelector} from "react-redux";
import StorageProductRow from "./StorageProductRow.jsx";
import DefaultProductRow from "./DefaultProductRow.jsx";

const ProductRow = () => {

	const {liveInfo} = useSelector((state) => state.lives.details)

	return (
		<div className="inputs-row form-row single-product-row small-inputs flex-nowrap">
			{+liveInfo.storage_products ?
				<StorageProductRow/> :
				<DefaultProductRow/>
			}
		</div>
	);
};

export default ProductRow;
