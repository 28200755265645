import moment from "moment/moment";
import React from "react";
import {RouterPaths} from "../../../../routes/RouterPath";
import useTableRowClick from "../../../shared/hooks/useTableRowClick";
import useLang from "../../../../../src_shared/hooks/useLang";
import EmptyList from "../../../shared/EmptyList";
import {convertToUserTimeZone} from "../../../shared/helpers/dateHelpers";

const Table = ({
                   items,
                   isWaiting,
                   updateSortOptions,
                   getSortClass,
                   askForRemoveItem,
               }) => {

    const {getLangText} = useLang();
    const onTableRowClick = useTableRowClick(RouterPaths.LiveOverlay);


    return (
        <EmptyList
            items={items}
            isLoading={isWaiting}
            className="with-padd"
            imgSrc="/assets/images/empty/products-empty.svg"
            title={getLangText('emptyHereLabel')}
            text={getLangText('changeSearchOrAddProductsLabel')}
        >
            <div className="table-wrapper">
                <div className="table-responsive">
                    <table className="table live-table smaller-padd">
                        <thead>
                        <tr>
                            <th>
                                {getLangText('nameLabel')}
                            </th>
                            <th
                                name="date"
                                onClick={updateSortOptions}
                                className={`text-right ${getSortClass('date')}`}
                            >
                                    <span name="date">
                                        {getLangText('dateLabel')}
                                    </span>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {items.map((item) => (
                            <tr
                                className={!!+item.live_broadcast ? 'bg-blue-50' : ''}
                                {...onTableRowClick(item.id)}>
                                <td
                                    data-thead={`${getLangText('nameLabel')}:`}
                                    style={{color: 'black'}}
                                    className="w-100 with-mobile-thead inline-thead text-black sm:mb-0 mb-1"
                                >
                                    {!!+item.live_broadcast &&
                                        <div className="flex items-center">
                                            <div className="relative flex h-2.5 w-2.5">
                                                <div
                                                    className="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-400 opacity-75"/>
                                                <div
                                                    className="relative inline-flex rounded-full h-2.5 w-2.5 bg-blue-500"/>
                                            </div>
                                            <div className="font-bold text-xs text-blue-500 ml-1">
                                                {getLangText('live')}
                                            </div>
                                        </div>
                                    }
                                    {item.title || getLangText('noNameLabel')}
                                </td>
                                <td
                                    data-thead={`${getLangText('createdLabel')}`}
                                    className="text-sm-right date w-lg-auto w-100 with-mobile-thead mb-lg-0 text-nowrap"
                                >
                                    {convertToUserTimeZone(item.date).format('DD.MM.YYYY HH:mm')}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </EmptyList>
    );
};

export default Table;
