import React from 'react';
import useLang from "../../../../src_shared/hooks/useLang";
import {useDispatch, useSelector} from "react-redux";
import Modal from "react-bootstrap/Modal";
import {selmoPaczkaSuccessDepositModalSetVisible} from "./SelmoPaczkaSuccessDepositModalActions";

const SelmoPaczkaSuccessDepositModal = () => {
    const {getLangText} = useLang();
    const dispatch = useDispatch();
    const {isOpen} = useSelector((state) => state.shared.selmoPaczkaSuccessDepositModal);
    const {userData} = useSelector((state) => state.session);

    const hideModal = () => dispatch(selmoPaczkaSuccessDepositModalSetVisible(false));

    // const [{isLoading, fetchData: downloadFile}] = useFetchData({
    //     rest: `${data.rest}${getGETParamsUrl({'orders[]': data.items.map((i) => i.id)})}`,
    //     initialAutoLoad: false,
    // })

    return (
        <Modal
            dialogClassName={`default-modal w-380`}
            show={isOpen}
            onHide={hideModal}
        >
            <Modal.Header>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            <Modal.Body className="mb-3 pt-0">
                <div>
                    <div className="flex items-center justify-center flex-col">
                        <img
                            className="w-[28px] min-w-[28px]"
                            src="/assets/images/svg/circle-check.svg"
                            alt="check"
                        />
                        <div className="font-bold text-xl mt-1">
                            {getLangText('yuhuLabel')}
                        </div>
                        <div className="text-desc text-sm font-medium">{getLangText('successfullyDepositBalance')}</div>
                    </div>
                    <div className="flex items-center bg-[#F9FAFB] rounded-[5px] p-3.5 text-sm mt-3 mb-3">
                        <div className="text-medium text-desc">
                            {getLangText('yourBalance')}
                        </div>
                        <div className="ml-auto font-medium text-[#101827]">{userData.sp_balance} zł</div>
                    </div>
                    <button
                        onClick={hideModal}
                        type="button"
                        style={{fontSize: 14}}
                        className="button primary w-full"
                    >
                        {getLangText('readyLabel')}!
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default SelmoPaczkaSuccessDepositModal;
