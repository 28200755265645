import Model, {convertObjectProperties} from "../../../src_shared/modules/model/Model";

class SettingsTermsModel extends Model {
    getModel() {
        return {
            terms: '',
            policy: ''
        };
    }

    parseDTO(data) {
        return convertObjectProperties(
            data,
            {},
            {
                terms: '',
                policy: ''
            },
        );
    }
}

export default new SettingsTermsModel();
