import Composition from "../../../shared/helpers/Composition";
import createReducer from "../../../shared/helpers/createReducer";
import FormReducer from "../../../../../../../src_shared/form/FormReducer";
import TranslationsModel from "../../../../../modules/models/TranslationsModel";

export const getStateRoot = (state) => state.admin.posts.translations.form;
export const prefix = 'ADMIN_TRANSLATIONS_FORM_';

const getInitState = () => FormReducer.getInitState(TranslationsModel.getModel());

const getReduceMap = () => new Composition(
	FormReducer.getReduceMap(),
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

