import Model, {convertObjectProperties} from "../../../src_shared/modules/model/Model";
import {getPointMachineType} from "../../../components/app/basket/secondStep/SecondStep";

export class OrderModel extends Model {
	getModel() {
		return {
			id: 0,
			products: [],
			shippingData: {},
			client: {},
			orderProductsExtra: [],
			productsPayment: [],
			currency: {},
			package_templates: [],
			email_history: [],
			chosen_package_type: {},
			liveProducts: [],
			discount_code_info: {},
		};
	}
	parseDTO(data) {
		return convertObjectProperties(
			data,
			{
			},
			{
				current_cart: [],
				finished_cart: [],
				reserve_list: [],
				shippingData: {},
				chosen_package_type: {},
				liveProducts: [],
				discount_code_info: {},
				pointMachineCode: data?.shippingData?.inpost_box_code || data?.shippingData?.mondial_box_code || data?.shippingData?.orlen_paczka_box_psd_code || data?.shippingData?.dpd_box_code || data?.shippingData?.box_code,
				pointMachineStreet: data?.shippingData?.inpost_box_street || data?.shippingData?.mondial_box_street || data?.shippingData?.orlen_paczka_box_street || data?.shippingData?.dpd_box_street || data?.shippingData?.box_street,
				pointMachineCity: data?.shippingData?.inpost_box_city || data?.shippingData?.mondial_box_city || data?.shippingData?.orlen_paczka_box_city || data?.shippingData?.dpd_box_city || data?.shippingData?.box_city,
				pointMachineName: data?.shippingData?.box_name || data?.shippingData?.dpd_box_name || data?.shippingData?.name,
				pointMachineZipCode: data?.shippingData?.box_zipcode,
				pointMachineId: data?.shippingData?.orlen_paczka_box_code,
				pointMachineType: getPointMachineType(data?.shippingData),
			}
		);
	}
}

export default new OrderModel();
