import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import {RouterPaths} from "../../RouterPath";
import CollectionsList from "../../../app/products/collections/list/List";
import CollectionForm from "../../../app/products/collections/form/Form";

const CollectionsRoute = ({path}) => {

	return (
		<Route path={path}>
			<Switch>
				<Route
					exact
					path={RouterPaths.CollectionsList}
					component={CollectionsList}
				/>
				<Route
					exact
					path={RouterPaths.Collection}
					component={CollectionForm}
				/>
				<Redirect to={RouterPaths.CollectionsList}/>
			</Switch>
		</Route>
	);
};

export default CollectionsRoute;
