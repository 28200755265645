import React, {useState} from 'react';
import useLang from "../../../src_shared/hooks/useLang";
import TextArea from "../../../src_shared/components/form/textarea/TextArea";
import {RouterPaths} from "../../routes/RouterPath";
import {useDispatch, useSelector} from "react-redux";
import useSubmitData from "../../../src_shared/hooks/useSubmitData";
import {services} from "../../RestServices";
import {showAlert} from "../shared/components/alert/AlertActions";
import Loader from "../messenger/loader/Loader";


const Content = ({HeaderComponent, title, desc, FooterComponent, children}) => {
    const {getLangText} = useLang();
    const {userData} = useSelector((state) => state.session);
    const isSelmoProActive = !!+userData.selmo_pro_active;

    if (isSelmoProActive && children) return children;

    return (
        <div className="bg-[#fff] sm:py-20 py-12 rounded-[5px] shadow-default rounded-b block hover:no-underline group">
            {HeaderComponent && HeaderComponent()}
            <div className={`max-w-[300px] mx-auto text-center block w-full`}>
                {isSelmoProActive ?
                    <div>
                        <div className="mx-auto w-[36px] mb-2">
                            <img className="w-full" src="/assets/images/svg/locked.svg" alt=""/>
                        </div>
                        <div>
                            <div className="font-bold text-xl text-[#101827] mb-1">{getLangText(title)}</div>
                            <div
                                className="font-medium text-sm text-[#6B7280] leading-[21px] whitespace-pre-wrap">{getLangText(desc)}</div>
                        </div>
                        <div
                            style={{fontSize: 14, borderRadius: 8}}
                            className="button not-active primary w-full small-size mt-4 mb-2"
                        >
                            {getLangText('soonAvailable')}
                        </div>
                    </div> :
                    <a
                        href={RouterPaths.SelmoPro}
                        target="_blank"
                        className="hover:no-underline"
                    >
                        <div className="mx-auto w-[36px] mb-2">
                            <img className="w-full" src="/assets/images/svg/locked.svg" alt=""/>
                        </div>
                        <div>
                            <div className="font-bold text-xl text-[#101827] mb-1">{getLangText(title)}</div>
                            <div
                                className="font-medium text-sm text-[#6B7280] leading-[21px] whitespace-pre-wrap">{getLangText(desc)}</div>
                        </div>
                        <div
                            style={{fontSize: 14, borderRadius: 8}}
                            className="button primary w-full small-size mt-4 group-hover:bg-[#1c4ed8]"
                        >
                            {getLangText('seeMoreButton')}
                        </div>
                        <div className="inline-block text-xs font-medium mb-2 hover:text-[#000] text-[#000]">{getLangText('soonAvailable')}</div>
                        <div className="flex items-center">
                            <div className="mr-1">
                                <i className="icon-info-c text-[#5FA5FA]"/>
                            </div>
                            <div
                                className="text-xs text-[#1F2937]"
                                dangerouslySetInnerHTML={{__html: getLangText('functionalityAvailableInSelmoPro')}}
                            />
                        </div>
                    </a>
                }
                {FooterComponent && FooterComponent()}
            </div>
        </div>
    )
}
const AnalyticsPage = () => {
    const {getLangText} = useLang();
    const [showTextField, setShowTextField] = useState(false);
    const [value, setValue] = useState('');
    const [success, setSuccess] = useState(false);
    const dispatch = useDispatch();

    const [{onSave, isWaiting}] = useSubmitData({
        rest: services.API_ANALYTICS_SUGGESTION,
        values: {text: value},
        afterSubmit: () => {
            dispatch(showAlert('yuhuLabel', 'successfulSubmitSuggestion'))
            setSuccess(true);
            setShowTextField(false);
            setValue('')
        },
    });


    const HeaderComponent = () => (
        <a
            href={RouterPaths.SelmoPro}
            target="_blank"
            className="px-6 block sm:px-10 sm:-mt-10 mb-6 sm:mb-10 -mt-4"
        >
            <div className="relative rounded-[10px] overflow-hidden">
                <img src="/assets/images/analytics-banner.webp" alt="analytics"/>
                <img src="/assets/images/svg/selmo-pro-white.svg" className="absolute top-3 left-3" alt=""/>
            </div>
        </a>
    )

    const HeaderProComponent = () => (
        <div
            className="px-6 block sm:px-10 sm:-mt-10 mb-6 sm:mb-10 -mt-4"
        >
            <div className="relative rounded-[10px] overflow-hidden">
                <img src="/assets/images/analytics-banner.webp" alt="analytics"/>
                <img src="/assets/images/svg/selmo-pro-white.svg" className="absolute top-3 left-3" alt=""/>
            </div>
        </div>
    )
    const FooterComponent = () => !success ? (
        <div className="form-info-box w-full mt-3">
            <div className="text-left w-full">
                <div
                    style={{fontSize: 12}}
                    className="title">{getLangText('haveIdeaForAnalyticsWorks')}</div>
                <div
                    style={{fontSize: 12}}
                    className="desc">{getLangText('shareUsWithYourSuggestions')}</div>
                {showTextField ?
                    <form
                        onSubmit={onSave}
                        className="mt-2"
                    >
                        <TextArea
                            value={value}
                            setValue={(field, value) => setValue(value)}
                            placeholder={getLangText('enterSuggestion')}
                            extraProps={{autoFocus: true, required: true}}
                            showLabel={false}
                        />
                        <button
                            type="submit"
                            style={{borderRadius: 8, paddingTop: 12, paddingBottom: 12}}
                            className={`button border-button w-full ${isWaiting ? 'loading-button' : ''}`}
                        >
                            {isWaiting && <Loader/>}
                            {getLangText('sendButton')}
                        </button>
                    </form> :
                    <button
                        onClick={() => setShowTextField(true)}
                        type="button"
                        style={{borderRadius: 8, paddingTop: 12, paddingBottom: 12}}
                        className="button border-button w-full mt-2"

                    >
                        {getLangText('addSuggestion')}
                    </button>
                }
            </div>
        </div>
    ) : null


    return (
        <Content
            title="saleAnalytics"
            desc="saleAnalyticsSubLabel"
            variant={2}
            HeaderComponent={HeaderComponent}
            FooterComponent={FooterComponent}
        >
            <Content
                title="saleAnalytics"
                desc="saleAnalyticsSubLabel"
                variant={2}
                HeaderComponent={HeaderProComponent}
                FooterComponent={FooterComponent}
            />
        </Content>
    );
};

export default AnalyticsPage;
