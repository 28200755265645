import React, {useState} from 'react';
import Product from "../Product.jsx";
import Toggle from "./Toggle";
import PhotoPreview from "../../../../../../src_shared/components/ui/photoPreview/PhotoPreview";
import usePhotoPreview from "../../../../../../src_shared/components/ui/photoPreview/usePhotoPreview";
import PaymentStatusModal from "../../editProducts/components/PaymentStatusModal";

const OpenCartProducts = ({values, getTotalPrice, inClientsOrders, editable}) => {
	const {showPreviewModal, hidePreviewModal, openPreviewModal} = usePhotoPreview();
	const [showPaymentStatusModal, setShowPaymentStatusModal] = useState(false);
	const [stage, setStage] = useState(null);

	const openModal = (currentStage) => {
		setShowPaymentStatusModal(true);
		setStage(currentStage)
	}

	return (
		<div className="open-cart-products-group">
			{values.orderProductsExtra.map((group, index) => (
				<Toggle
					values={values}
					key={index}
					index={index}
					group={group}
					getTotalPrice={getTotalPrice}
					openModal={openModal}
				>
					<div className="open-cart-products__hidden hidden-part">
						<ul className="order-summary__products">
							{group.products.map((product) => (
								<Product
									getTotalPrice={getTotalPrice}
									key={product.id}
									values={values}
									product={product}
									inClientsOrders={inClientsOrders}
									openPreviewModal={openPreviewModal}
									editable={editable}
								/>
							))}
						</ul>
					</div>
				</Toggle>
			))}
			<PhotoPreview
				hide={hidePreviewModal}
				show={showPreviewModal}
			/>
			{showPaymentStatusModal &&
				<PaymentStatusModal
					values={values}
					isOpen={setShowPaymentStatusModal}
					hideModal={() => setShowPaymentStatusModal(false)}
					stage={stage}
				/>
			}
		</div>
	);
};

export default OpenCartProducts;
