import React, {useState} from 'react';

import {selmoUrl} from "../../../../../src_shared/api/api";
import {services} from "../../../../RestServices";
import {showAlert} from "../../../shared/components/alert/AlertActions";
import {useDispatch} from "react-redux";
import useLang from "../../../../../src_shared/hooks/useLang";
import Loader from "../../../shared/Loader";
import BasketPageFormActions from "../../page/FormActions";
import api from "../../../../../services/axios/axios";

const DiscountCode = ({magicUrl, shopId, values}) => {
	const [value, setValue] = useState('');
	const [isError, setIsError] = useState(false);
	const dispatch = useDispatch();
	const {getLangText} = useLang();
	const [waitingForCode, setWaitingForCode] = useState(false);

	const setDiscountCode = async (e) => {
		e.preventDefault();
		if (!value) {
			setIsError(true);
			return;
		}

		setWaitingForCode(true)

		try {
			await api.post(`${selmoUrl}/${services.API_CART_DISCOUNT_CODE}`, {
				name: value,
				magic_url: magicUrl,
				shop_id: shopId,
			})
			dispatch(showAlert('discountCodeSuccessTitle', ''));
			dispatch(BasketPageFormActions.getAndUpdatePrices(magicUrl, shopId))
			setIsError(false);
			setValue('')

		} catch (e) {
			setIsError(true);
			dispatch(showAlert('discountCodeFailTitle', '', 'alert-fail'))
		} finally {
			setWaitingForCode(false)
		}
	}

	const deleteCode = async () => {
		try {
			await api.post(`${selmoUrl}/${services.API_CART_DELETE_DISCOUNT_CODE}`, {
				discount_id: values.overall.discount?.name,
				magic_url: magicUrl,
				shop_id: shopId,
			})
			dispatch(showAlert('discountCodeRemovedTitle', ''));
			dispatch(BasketPageFormActions.getAndUpdatePrices(magicUrl, shopId))

		} catch (e) {
			dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
		}
	}

	return (
		<form
			onSubmit={setDiscountCode}
			className={`discount-code-row ${values.overall.discount?.name ? 'with-border' : ''}`}
		>
			<div className="form-group discount-code-field mb-sm-0 mb-3">
				<input
					placeholder={getLangText('discountCodeTextDiscountCodeLabel')}
					type="text"
					className="form-control"
					value={value}
					onChange={(e) => setValue(e.target.value)}
				/>
				{isError &&
					<div className="error-text">{getLangText('discountCodeWrongCodeWarning')}</div>
				}
				{values.overall.discount?.name &&
					<div className="discount-tags tags-row">
						<div className="tag grey m-0">
							{values.overall.discount?.name}
							<button
								type="button"
								className="hide-tag-input"
								onClick={deleteCode}
							>
								<i className="icon-cross"/>
							</button>
						</div>
					</div>
				}
			</div>
			<button
				type="submit"
				className={`button discount-code-button primary ${!value ? 'not-active' : ''} ${waitingForCode ? 'loading-button' : ''}`}
			>
				{(waitingForCode) &&
					<Loader isLoading/>
				}
				{getLangText('enterButton')}
			</button>
		</form>
	);
};

export default DiscountCode;
