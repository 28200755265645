import React, {useEffect, useRef, useState} from 'react';
// import {Picker} from 'emoji-mart';
// import data from '@emoji-mart/data'
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'

import useLang from "../../../../../src_shared/hooks/useLang";

const EmojiPicker = ({setInputValue, inputValue}) => {

    const [isVisiblePicker, setIsVisiblePicker] = useState(false)
    const emojiRef = useRef(null);
    const buttonRef = useRef(null);
	const {getLangText} = useLang();

    const handleClickOutside = (e) => {
        if (isVisiblePicker
            && buttonRef
            && emojiRef
            && buttonRef.current !== e.target
            && emojiRef.current !== e.target
            && !buttonRef.current.contains(e.target)
            && !emojiRef.current.contains(e.target)
        ) {
            setIsVisiblePicker(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    },);

    return (
        <div className="emoji-wrapper">
            <button
                ref={buttonRef}
                type="button"
                className="icon-btn emoji tooltip-parent"
                onClick={() => setIsVisiblePicker(!isVisiblePicker)}
            >
                {/*<Tooltip*/}
                {/*    title="Wstaw emoji"*/}
                {/*/>*/}
                <i className="icon-emoji1"/>
            </button>
            {isVisiblePicker &&
            <div ref={emojiRef} className="emoji-dropdown">
                <Picker
                    locale="pl"
                    theme="light"
                    onEmojiSelect={(i) => setInputValue(inputValue + i.native + '  ')}
                    showPreview={false}
                    showSkinTones={false}
                    data={data}
                />
            </div>
            }
        </div>
    );
};

export default EmojiPicker;
