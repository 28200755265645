import React, {useRef} from 'react';
import useLang from "../../../../../src_shared/hooks/useLang";
import useDropdown from "../../../shared/hooks/useDropdown";

const sortOptions = [
    {id: 'date', label: 'newest', sortDir: 'desc'},
    {id: 'qty_overall', label: 'soldPieces', sortDir: 'desc'},
    {id: 'qty_rl_overall', label: 'reserveListLabel', sortDir: 'desc'},
    {id: 'facebook_publish_date', label: 'visibleOnFacebook', sortDir: 'desc'},
    {id: 'selmo_app_publish_date', label: 'visibleInSelmoApp1', sortDir: 'desc'},
]

const SortDropdown = ({sortBy, callSort}) => {

        const buttonRef = useRef(null)
        const dropdownRef = useRef(null)
        const {getLangText} = useLang();
        const hideDropdown = () => setOpen(false)

        const {open, setOpen} = useDropdown(buttonRef, dropdownRef, hideDropdown);

        const onButtonClick = (value, sortDir) => {
            setOpen(false);
            callSort(value, sortDir)
        }

        return (
            <div ref={dropdownRef} className="react-dropdown right position-relative">
                <button
                    className={`bg-[white] flex items-center px-[12px] py-[8px] font-bold text-xs text-[#6B7280] rounded-[5px] border ${open ? 'show' : ''}`}
                    onClick={() => setOpen(!open)}
                    ref={buttonRef}
                >
                    {sortBy ?
                        getLangText(sortOptions.find((i) => i.id === sortBy).label) :
                        getLangText('sortLabel')
                    }
                    <i className="icon-arrows text-[8px] ml-1" />
                </button>
                {open &&
                    <div className="dropdown-menu show">
                        {sortOptions.map((option) => (
                            <button
                                type="button"
                                className={`custom-option ${sortBy === option.id ? 'active' : ''}`}
                                onClick={() => onButtonClick(option.id, option.sortDir)}
                            >
                                {getLangText(option.label)}
                            </button>
                        ))}
                    </div>
                }
            </div>
        );
    }
;

export default SortDropdown;
