import React from 'react';
import useCallbackUrl from "../../../../src_shared/hooks/useCallbackUrl";
import {services} from "../../../RestServices";
import {useHistory, useLocation} from "react-router-dom";
import {getGETParamsUrl, parseParams} from "../../../../src_shared/list/ListActions";
import {useDispatch} from "react-redux";
import SessionActions from "../../shared/session/SessionActions";
import {RouterPaths} from "../../../routes/RouterPath";
import SocialLoginPage from "../components/socialLoginPage/SocialLoginPage";
import Cookies from "js-cookie";

const LoginFacebook = ({isExpansion}) => {
    const {search} = useLocation();
    const query = parseParams(search);
    const dispatch = useDispatch();
    const history = useHistory();

    const afterSubmit = (response) => {
        dispatch(SessionActions.setAndSaveInStorageToken(response.token))

        const params = {
            email: response.email,
            origin: response.origin,
            emailNotExist: response.emailNotExist,
            selmo_token: response.selmo_token,
        }

        if (!!response.shopify_hash) {
            history.push(`${RouterPaths.IntegrationsShopify}/${response.shopify_hash}`)
            return;
        }

        if (response.isTutorialInstagram) {
            history.push(`${RouterPaths.TutorialInstagramConnect}${getGETParamsUrl(params)}`)
        } else if (response.isTutorial) {
            history.push(`${RouterPaths.Tutorial}${getGETParamsUrl(params)}`)
        } else {
            history.push(RouterPaths.Dashboard)
        }
    }

    const preparedGETParams = () => {
        const utm = Cookies.get('utms');
        const fromInstagram = Cookies.get('fromInstagramParam')
        const campaignId = Cookies.get('campaignId')
        const partner = Cookies.get('partner')

        const getParams = {};
        if (query.code) {
            getParams.code = query.code;
        }
        if (isExpansion) {
            getParams.expansion = '1';
        }
        if (utm) {
            getParams.utm = utm;
        }
        if (!!+fromInstagram) {
            getParams.fromInstagram = 1
        }

        if (campaignId) {
            getParams.campaign_id = campaignId
        }

        if (partner) {
            getParams.partner = partner
        }

        return getParams;
    }

    const {isError} = useCallbackUrl({
        queryName: 'code',
        rest: `${services.API_LOGIN_FACEBOOK}${getGETParamsUrl(preparedGETParams())}`,
        afterSubmit,
    });

    return (
        <SocialLoginPage isError={isError}/>
    )
};

export default LoginFacebook;
