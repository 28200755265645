import ListActions, {getGETParamsUrl} from "../../../../../src_shared/list/ListActions.js";
import {getStateRoot, prefix} from "./listReducer.js";
import {RouterPaths} from "../../../../routes/RouterPath.js";
import {services} from "../../../../RestServices.js";
import {convertDate} from "../../../shared/helpers/dateHelpers";
import history from "../../../../history";
import {getStateRoot as getFiltersStateRoot} from '../filters/filtersReducer'

export class InteractionsEditListActions extends ListActions {
	getLoadParams(state, id) {
		const params = super.getLoadParams(state);
		const dateFrom = getFiltersStateRoot(state).data.fromDate
		const dateTo = getFiltersStateRoot(state).data.toDate

		if (dateFrom) {
			params.dateFrom = convertDate(dateFrom);
		}

		if (dateTo) {
			params.dateTo = convertDate(dateTo);
		}

		if (!this.preventPushParams) {
			history.replace({
				pathname: id ? `${this.pathname.replace(':id', id)}` : this.pathname,
				search: getGETParamsUrl(params)
			});
		}

		return params;
	}
}

export const getInstance = () => new InteractionsEditListActions({
	getStateRoot,
	restService: services.API_INSTAGRAM_AUTOMATIONS_INTERACTIONS,
	prefix,
	pathname: RouterPaths.AutomationInteractionFull
});

export default getInstance();
