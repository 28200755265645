import React, {useEffect} from 'react';
import CreateShopLayout from "../../shared/CreateShopLayout";
import {services} from "../../../../RestServices";
import {Redirect, useHistory} from "react-router-dom";
import Button from "../../../../../src_shared/components/ui/Button/Button";
import useFormLocal from "../../../../../src_shared/hooks/useFormLocal";
import ComponentLoader from "../../../../../src_shared/components/ui/componentLoader/ComponentLoader";
import ServerImage from "../../../shared/components/serverImage/ServerImage";
import InstagramModel from "../../../../../modules/models/tutorial/InstagramModel";
import {RouterPaths} from "../../../../routes/RouterPath";
import useFetchData from "../../../../../src_shared/hooks/useFetchData";
import {useDispatch} from "react-redux";
import {showAlert} from "../../../shared/components/alert/AlertActions";
import EmptyFanpages from "../components/emptyFanpages/EmptyFanpages";
import {usePostHog} from "posthog-js/react";
import useLang from "../../../../../src_shared/hooks/useLang";

const Instagram = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const posthog = usePostHog();
    const {getLangText} = useLang();

    const afterSubmit = (response) => {
        if (response.notFirstConnection || !!response.tutorial_done_date) {
            history.push(RouterPaths.Dashboard)
            dispatch(showAlert('successfullyConnectedLabel'))
            return;
        }
        if (!!+response.client_on_trial) {
            history.push(RouterPaths.TutorialBuilding)
        } else {
            history.push(RouterPaths.TutorialStart)
        }
        dispatch(showAlert('successfullyConnectedLabel'))
    }

    const [{onSave, isWaiting, values, setValue, updateValues}] = useFormLocal({
        rest: services.API_TUTORIAL_INSTAGRAM,
        model: InstagramModel,
        initialAutoLoad: false,
        afterSubmit
    });

    const params = window.location.hash.replace('#access_token=', '');

    const [{isLoading: waitingForCallback, isError}] = useFetchData({
        rest: params ? `${services.API_SETTINGS_INSTAGRAM_CONFIGURE}?access_token=${params}` : services.API_SETTINGS_INSTAGRAM_CONFIGURE,
        afterSubmit: updateValues
    })

    useEffect(() => {
        if (posthog) {
            posthog?.capture(`ONBOARDING - Instagram fanpage list visit`);
        }
    }, [posthog])

    if (isError) {
        return <Redirect to={RouterPaths.TutorialSocials}/>
    }

    return (
        <CreateShopLayout
            Img={<img width={30} className="mx-auto mb-2" src="/assets/images/svg/instagram-icon-new.svg"
                      alt="instagram"/>}
            title="selectFanpageLabel1"
            desc="selectFanpageWhichWantToUseOnSelmoLabel"
        >
            {waitingForCallback ?
                <ComponentLoader className="small-loader higher-border-radius"/> :
                <form
                    id="onboarding-select-fanpage-instagram-form"
                    onSubmit={onSave} className="sm:-mt-8 grow flex flex-col animate-tutorialSlideAndFadeIn">
                    <div className="fanpages-wrapper space-y-2 max-w-[450px] mx-auto w-full sm:mb-0 mb-8">
                        {!!values.fanpages?.length ?
                            values.fanpages.map((item) => (
                                <div className={`fanpage ${+values.page_id === +item.id ? 'active' : ''}`}>
                                    <input
                                        type="radio"
                                        value={+item.id}
                                        name="page_id"
                                        checked={+values.page_id === +item.id}
                                        onChange={() => setValue('page_id', item.id)}
                                        required
                                    />
                                    <span className="checkmark"/>
                                    <figure>
                                        <ServerImage
                                            src={item.profile_picture_url || '/assets/images/default/default.jpeg'}/>
                                    </figure>
                                    <div className="name">{item.username}</div>
                                </div>
                            )) :
                            <EmptyFanpages/>
                        }
                    </div>
                    {!!values.fanpages?.length &&
                        <div
                            className="mt-auto sm:mx-0 -mx-6 bg-[#F9FAFB] sm:w-full p-[20px] rounded-b-[12px] text-right">
                            <div className="tooltip-parent inline-block">
                                {!values.page_id &&
                                    <div className="tooltip-content">
                                        {getLangText('selectPageToGoNextLabel')}
                                    </div>
                                }
                                <Button
                                    isWaiting={isWaiting}
                                    type="submit"
                                    title="goNextLabel"
                                    className={`overflow-hidden button text-xs sm:text-sm font-semibold rounded-[8px] px-[20px] py-[11px] text-white transition-all ${!values.page_id ? 'bg-[#D1D5DB]' : 'bg-[#3C61EA] hover:bg-[#1c4ed8]'}`}
                                />
                            </div>
                        </div>
                    }
                </form>
            }
        </CreateShopLayout>
    );
};

export default Instagram;
