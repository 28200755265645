import React from 'react';
import useLang from "../../../../../src_shared/hooks/useLang";

const NoDataPlaceholder = ({setShowNoStatsModal, inline = false}) => {
    const {getLangText} = useLang();

    return (
        <button
            type="button"
            onClick={(e) => {
                e.preventDefault();
                setShowNoStatsModal(true);
            }}
            className={`absolute bg-[#fff] left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-[8px] min-w-[210px] shadow-selmo p-3 ${inline ? 'text-left flex items-center w-[80%]' : 'block text-center'}`}>
            <img
                className={inline ? 'h-[40px] mr-2' : 'h-[40px] mx-auto'}
                src="/assets/images/svg/fb-cross.svg" alt=""/>
            <div>
                <div className="text-[#101827] font-bold text-sm">{getLangText('noDataLabel')}</div>
                <div className="font-medium text-xs"
                     dangerouslySetInnerHTML={{__html: getLangText('postAddedByFacebook')}}/>
            </div>
            <div
                style={{fontSize: 12}}
                className={`bg-[#000000] text-nowrap text-[#fff] rounded-[5px] text-xs py-2 px-3 font-bold button black ${inline ? 'ml-auto' : 'w-full mt-2'}`}
            >
                {getLangText('findOutMoreButton')}
            </div>
        </button>
    );
};

export default NoDataPlaceholder;