import React, {useEffect, useState} from 'react';
import LanguagePicker from "../../shared/languagePicker/LanguagePicker";
import {useDispatch, useSelector} from "react-redux";
import cn from "clsx";
import SignUpModal from "./SignUpModal";
import {parseParams} from "../../../../src_shared/list/ListActions";
import LangsActions from "../../shared/langsAuthSinglePage/LangsActions";
import useAuthLang from "../../../../src_shared/hooks/useAuthLang";
import {supportedOptionLangs} from "../../../../services/i18nAuthSInglePage/i18n";
import Plausible from "plausible-tracker";
import ReactPixel from "react-facebook-pixel";
import {removeFromLS} from "../../shared/helpers/ls";
import {hotjar} from "react-hotjar";

const benefits = ['moreFreeTimeLabel', 'satisfiedCustomersLabel', 'lessManualWorkLabel'];

export const EXPANSION_VISIT = 'EXPANSION_VISIT';
export const EXPANSION_CLICK = 'EXPANSION_CLICK';
export const EXPANSION_SIGNUP = 'EXPANSION_SIGNUP';

const videoSrc = (lang) => {
    if (lang === 'it') {
        return 'https://onelineplayer.com/player.html?autoplay=true&autopause=false&muted=false&loop=false&url=https%3A%2F%2Fwww.dropbox.com%2Fs%2F8ezm9zzl7so093b%2Fmain%2520movie_IT.mp4%3Fraw%3D1&poster=https%3A%2F%2Fwww.dropbox.com%2Fs%2F98ngy9ccng5qp0k%2Fhero_spot.webp%3Fraw%3D1&time=true&progressBar=true&overlay=true&muteButton=true&fullscreenButton=true&style=light&quality=auto&playButton=true'
    }
    if (lang === 'pl') {
        return 'https://onelineplayer.com/player.html?autoplay=true&autopause=false&muted=false&loop=true&url=https%3A%2F%2Fwww.dropbox.com%2Fs%2Fxyeywsb7buj9fdn%2Fmain-movie-PL-lektor.mp4%3Fraw%3D1&poster=https%3A%2F%2Fwww.dropbox.com%2Fs%2Fttgxh4ipm8m70k9%2Fhero-spot.webp%3Fraw%3D1&time=true&progressBar=true&overlay=true&muteButton=true&fullscreenButton=true&style=light&quality=auto&playButton=true'
    }
    if (lang === 'pt') {
        return 'https://onelineplayer.com/player.html?autoplay=true&autopause=false&muted=false&loop=false&url=https%3A%2F%2Fwww.dropbox.com%2Fs%2Ft3fmmypgeeb3037%2Fmain_movie_pt-compressed.mp4%3Fraw%3D1&poster=https%3A%2F%2Fwww.dropbox.com%2Fs%2Fw60qnustnzsv688%2Fhero_spot.webp%3Fraw%3D1&time=true&progressBar=true&overlay=true&muteButton=true&fullscreenButton=true&style=light&quality=auto&playButton=true'
    }
    if (lang === 'fr') {
        return 'https://onelineplayer.com/player.html?autoplay=true&autopause=false&muted=false&loop=false&url=https%3A%2F%2Fwww.dropbox.com%2Fs%2Fmkd9aftd7iilteb%2Fmain_movie_FR.mp4%3Fraw%3D1&poster=https%3A%2F%2Fwww.dropbox.com%2Fs%2Fywqatfxsx3x1w7x%2Fhero_spot.webp%3Fraw%3D1&time=true&progressBar=true&overlay=true&muteButton=true&fullscreenButton=true&style=light&quality=auto&playButton=true'
    }

    return 'https://onelineplayer.com/player.html?autoplay=true&autopause=false&muted=false&loop=false&url=https%3A%2F%2Fwww.dropbox.com%2Fs%2Fq2lxqw8p6d0m2aa%2Fmain%2520movie_en_compressed.mp4%3Fraw%3D1&poster=https%3A%2F%2Fwww.dropbox.com%2Fs%2Ff44enstoa7027ss%2Fhero_spot.webp%3Fraw%3D1&time=true&progressBar=true&overlay=true&muteButton=true&fullscreenButton=true&style=light&quality=auto&playButton=true'
}

const AuthSinglePage = () => {
    const {getLangText} = useAuthLang();
    const dispatch = useDispatch();
    const {lang} = useSelector((state) => state.i18nAuthSinglePage);
    const [modalVisible, setModalVisible] = useState(false);
    const showModal = () => setModalVisible(true);

    const plausible = Plausible({
        trackLocalhost: false,
        domain: 'app.selmo.io',
    })

    const [showVideo, setShowVideo] = useState(false);
    const handleShowVideo = () => setShowVideo(true);
    const queryLang = parseParams(window.location.search);

    const preparedLang = queryLang.lang ? queryLang.lang : dispatch(LangsActions.getLang());

    useEffect(() => {
        dispatch(LangsActions.setAsyncLang(preparedLang))

        ReactPixel.init('1019873831899249');
        plausible.trackPageview();
        hotjar.initialize(3450973, 6);
        plausible.trackEvent(EXPANSION_VISIT);

        return () => removeFromLS('lang_auth')
    }, [])

    return (
        <div className="min-h-[100vh] bg-white py-12 relative">
            <img
                className="w-full absolute top-0 left-0 right-0 h-[350px] macbook:h-[500px] object-cover sm:block hidden"
                src="/assets/images/authSinglePage/bg-desktop.svg" alt="bg desktop"/>
            <img
                className="w-full absolute top-0 left-0 right-0 h-[350px] object-cover sm:hidden"
                src="/assets/images/authSinglePage/bg-rwd.svg" alt="bg mobile"/>
            <div className="max-w-[1200px] mx-auto px-6 relative z-[1]">
                <div className="flex items-center">
                    <img alt="selmo" width={107} src="/assets/images/logo.svg"/>
                    <LanguagePicker
                        modalTitle={getLangText('languageModalTitle')}
                        langsActions={LangsActions}
                        reduxPlace="i18nAuthSinglePage"
                        options={supportedOptionLangs}
                        buttonClassName="ml-auto big-size"
                    />
                </div>
                <div className="lg:text-center mt-8 sm:mt-20">
                    <div className="relative lg:inline-block">
                        <h1
                            className="text-with-gradient"
                            dangerouslySetInnerHTML={{__html: getLangText('facebookSaleSystemLabel')}}
                        />
                        <div className="lg:block hidden">
                            <img
                                className="w-[145px] h-[145px] absolute top-[70px] left-[calc(100%+40px)]"
                                src="/assets/images/authSinglePage/facebook-desktop.svg" alt="facebook"/>
                            <img
                                className="w-[145px] h-[145px] absolute top-[calc(100%-10px)] right-[calc(100%-30px)]"
                                src="/assets/images/authSinglePage/live-desktop.svg" alt="live"/>
                        </div>
                        <img
                            className="sm:w-[145px] sm:h-[145px] w-[100px] h-[100px] absolute top-[80px] right-[-36px] lg:hidden"
                            src="/assets/images/authSinglePage/facebook-rwd.svg" alt="facebook live"/>
                    </div>
                    <div className="sm:h-[100px] h-[84px] relative my-6 sm:my-10">
                        <ul className="space-y-3 mx-auto lg:absolute left-1/2 lg:-translate-x-1/2">
                            {benefits.map((i) => (
                                <li
                                    key={i}
                                    className="flex items-center">
                                    <div className="mr-2">
                                        <img className="w-[16px] sm:w-[20px]"
                                             src="/assets/images/authSinglePage/check.svg" alt=""/>
                                    </div>
                                    <div
                                        className="font-bold text-[#4B5563] sm:text-[16px] text-sm">{getLangText(i)}</div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="text-center sm:mt-0 mt-12">
                        <button
                            type="button"
                            onClick={showModal}
                            className="font-bold bg-[#3C61EA] sm:w-auto w-full rounded-[12px] py-[19px] px-12 text-white inline-block no-underline transition-all hover:bg-[#1c4ed8] hover:no-underline"
                        >
                            {getLangText('tryForFreeButton')}
                        </button>
                        <div className="text-xs text-[#9CA3AF] font-bold mt-3.5">
                            <i className="icon-tick font-normal mr-1 text-[10px] opacity-50"/>
                            {getLangText('noObligationLabel')}
                        </div>
                    </div>
                </div>
                <div className="relative mt-28 sm:mt-32 max-w-[650px] mx-auto">
                    <img
                        className="absolute -top-12 right-0 h-[30px]"
                        src={`/assets/images/authSinglePage/en/handwritten.svg`}
                        alt="see how it works"
                    />
                    <div
                        className="cursor-pointer"
                        style={{paddingTop: '56.25%', position: 'relative', overflow: 'hidden'}}>
                        {!showVideo ?
                            <div
                                onClick={handleShowVideo}
                                className={cn('absolute top-0 left-0 bottom-0 right-0 group')}>
                                <img
                                    className="absolute sm:w-auto sm:h-auto w-[62px] h-[62px] left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 group-hover:scale-105 transition-all duration-200"
                                    src="/assets/images/authSinglePage/play-new.svg" alt="play" width={84} height={84}
                                />
                                <img
                                    className="rounded-[20px]"
                                    src={`/assets/images/authSinglePage/en/hero_spot.webp`}
                                    alt="intro image"
                                    width={1200}
                                    height={620}
                                />
                            </div> :
                            <iframe
                                frameBorder="0" allowFullScreen="" scrolling="no"
                                allow="autoplay;fullscreen"
                                src={videoSrc(lang)}
                                className={cn('rounded-[20px]')}
                                style={{
                                    position: 'absolute',
                                    height: '100%',
                                    width: '100%',
                                    left: 0,
                                    top: 0
                                }}
                            />
                        }
                    </div>
                    <div className="text-center mt-6 sm:mt-12">
                        <button
                            type="button"
                            onClick={showModal}
                            className="font-bold bg-[#3C61EA] sm:w-auto w-full rounded-[12px] py-[19px] px-12 text-white inline-block no-underline transition-all hover:bg-[#1c4ed8] hover:no-underline"
                        >
                            {getLangText('tryForFreeButton')}
                        </button>
                        <div className="text-xs text-[#9CA3AF] font-bold mt-3.5">
                            <i className="icon-tick font-normal mr-1 text-[10px] opacity-50"/>
                            {getLangText('noObligationLabel')}
                        </div>
                    </div>
                </div>
            </div>
            {modalVisible &&
                <SignUpModal
                    hideModal={() => setModalVisible(false)}
                    modalVisible={modalVisible}
                    getLangText={getLangText}
                    trackEvent={plausible.trackEvent}
                />
            }
        </div>
    );
};

export default AuthSinglePage;