import DataActions from "../form/data/DataActions.js";

export class SharedFiltersActions {
    constructor(config) {
        this.prefix = config.prefix;
        this.data = DataActions.createActions(this.prefix);
    }

    updateList(field, value) {
        return (dispatch) => {
            dispatch(this.data.setValue([field], value));
            dispatch(this.reloadParentList());
        };
    }

    resetAllFilters() {
        return {
            type: `${this.prefix}RESET_ALL_FILTERS`,
        };
    }

    reloadParentList() {
        return {
            type: `${this.prefix}RELOAD_PARENT_LIST`,
        };
    }

    resetFiltersAndUpdateList() {
        return (dispatch) => {
            dispatch(this.resetAllFilters());
            dispatch(this.reloadParentList());
        };
    }
}

export default SharedFiltersActions;
