import React, {useState} from 'react';
import useList from "../../../../../src_shared/list/useList.js";
import ListActions from "./ListActions.js";
import {getStateRoot} from "./listReducer.js";
import Pagination from "../../../shared/components/pagination/Pagination.jsx";
import SearchInput from "../../../shared/components/form/SearchInput.jsx";
import Table from "../components/table/Table.jsx";
import useLang from "../../../../../src_shared/hooks/useLang.js";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../routes/RouterPath";
import FormModal from "./components/FormModal";

const MobileReelsList = () => {

	const {getLangText} = useLang();

	const [showModal, setShowModal] = useState(false);

	const {
		pagination: {
			changePage,
			getPagination,
			itemsPerPage,
			nextPage,
			prevPage,
			totalItems,
			updateItemsPerPage
		},
		list: {items, isWaiting},
		search,
		sort: {updateSortOptions, getSortClass},
	} = useList({
		ListActions,
		getStateRoot,
	});

	return (
		<div className="live-list-wrapper table-list-wrapper">
			<Helmet>
				<meta charSet="utf-8"/>
				<title>{getLangText('reelsLabel')} - Selmo</title>
			</Helmet>
			<div className="default-shadow-box pb-0">
				<div className="header">
					<div>
						<div className="title">
							{getLangText('reelsLabel')}
							{totalItems > 0 && <div className="badge">{totalItems}</div>}
						</div>
						<div className="desc">
							{getLangText('reelsSubTitle')}
						</div>
					</div>
					<SearchInput
						placeholder={getLangText('searchPlaceholder')}
						search={search}
					/>
					<button
						type="button"
						onClick={() => setShowModal(true)}
						className="button primary small-size"
					>
						{getLangText('addReelsLabel')}
					</button>
				</div>
				<Table
					isWaiting={isWaiting}
					items={items}
					updateSortOptions={updateSortOptions}
					getSortClass={getSortClass}
				/>
			</div>
			<Pagination
				pages={getPagination()}
				items={items}
				itemsPerPage={itemsPerPage}
				getLangText={getLangText}
				totalItems={totalItems}
				onPrevPage={prevPage}
				onChangePage={changePage}
				onNextPage={nextPage}
				onChangeItemsPerPage={updateItemsPerPage}
				classes={{
					prevIcon: 'icon-chevron left',
					nextIcon: 'icon-chevron right',
				}}
				showItemsPerPageSelect
			/>
			{showModal &&
				<FormModal
					modalVisible={showModal}
					hideModal={() => setShowModal(false)}
				/>
			}
		</div>
	);
};

export default MobileReelsList;
