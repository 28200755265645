import Model from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

class CreateShopSecondStepModel extends Model {
	getModel() {
		return {
			orders_count: null,
			know_us: null
		};
	}

	getValidators() {
		return {
			orders_count: ValidationError.notEmpty,
			know_us: ValidationError.notEmpty,
			other: ValidationError.skip,
		};
	}
}

export default new CreateShopSecondStepModel();
