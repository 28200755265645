import { useEffect } from 'react';

const addBodyClass = (className, container) => document[container].classList.add(className);
const removeBodyClass = (className, container) => document[container].classList.remove(className);

const useCSSClass = (className, container = 'body') => {
	useEffect(
		() => {
			addBodyClass(className, container);
			return () => {
				removeBodyClass(className, container);
			};
		},
		[className, container],
	);
};

export default useCSSClass;
