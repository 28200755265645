import {Model} from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";
import {getPreparedPointMachineData} from "../../../components/app/basket/secondStep/SecondStep";
import {ORDER_PAYMENT_STRIPE_CARD} from "../../../components/app/shared/enums/PaymentStatuses";

const validatePhone = (value, data) => {
    if (!!+data?.productInfo?.digital) {
        return false;
    }
    const countryName = data.country_name?.trim();
    if (countryName && countryName.toLowerCase() === 'polska') {
        return ValidationError.combine(
            ValidationError.notEmpty,
            ValidationError.validatePhone,
            ValidationError.maxLength(9, 'Niepoprawny numer telefonu'),
        )(value, data)
    }
    return ValidationError.notEmpty(value);
}

const validateZipCode = (value, data) => {
    if (!!+data?.productInfo?.digital) {
        return false;
    }
    const countryName = data.country_name?.trim();
    if (countryName && countryName.toLowerCase() === 'polska') {
        return ValidationError.combine(
            ValidationError.notEmpty,
            ValidationError.validateZipCode,
        )(value, data)
    }
    return ValidationError.notEmpty(value);
}

const digitalValidate = (value, data) => {
    if (!!+data?.productInfo?.digital) {
        return false;
    }
    return ValidationError.notEmpty(value);
}

const validateEmail = (value, data) => {
    if (!!+data?.productInfo?.digital) {
        return false;
    }
    return ValidationError.combine(
        ValidationError.notEmpty,
        ValidationError.validateEmail,
    )(value, data);
}

class CheckoutModel extends Model {
    getModel() {
        return {
            shipping: null,
            payment: null,
            paymentMethods: [],
            overall: {},
            productInfo: {},
            countries: [],
            first_name: '',
            last_name: '',
            email: '',
            country: null,
            street: '',
            building_number: '',
            city: '',
            zip_code: '',
            phone: '',
            extra_description: '',
            product_size_id: null,
            country_name: ''
        };
    }

    getValidators() {
        return {
            first_name: digitalValidate,
            last_name: digitalValidate,
            email: validateEmail,
            country: digitalValidate,
            street: digitalValidate,
            building_number: digitalValidate,
            city: digitalValidate,
            zip_code: validateZipCode,
            phone: validatePhone,
            extra_description: ValidationError.skip,
            shipping: ValidationError.skip,
        };
    }

    buildDTO(data) {
        return {
            payment: data.payment,
            shipping: data.shipping,
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            country: data.country,
            street: data.street,
            building_number: data.building_number,
            city: data.city,
            zip_code: data.zip_code,
            phone: data.phone,
            extra_description: data.extra_description,
            product_size_id: data.product_size_id?.value,
            ...getPreparedPointMachineData(data),
            card_details: data.payment === ORDER_PAYMENT_STRIPE_CARD ? data.card_details : null,
        }
    }
}

export default new CheckoutModel();
