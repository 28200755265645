import React from 'react';
import useLang from "../../../../../../../src_shared/hooks/useLang";
import {useUnitPrice} from "../../../../../shared/helpers/Price";

const ProductVariants = ({product, customers, withPrice = false, isLiveFinished = false}) => {
	const {getLangText} = useLang();
	const {getPrice} = useUnitPrice();

	const safetyQuantity = (item) => item.is_deleted === '1' ? 0 : +item.quantity

	const getTotalVariantsCount = (variantId) => {

		const selectedCustomerWithTheSameSize = customers.filter((i) => i.shop_client_id && i.variant_id === variantId);
		return selectedCustomerWithTheSameSize.reduce((prev, curr) => {
			return +prev + safetyQuantity(curr)
		}, 0);
	}

	const getTotalCount = () => {
		const selectedCustomers = customers.filter((i) => i.shop_client_id);
		return selectedCustomers.reduce((prev, curr) => {
			return +prev + safetyQuantity(curr)
		}, 0);
	}

	const getVariantSoldCount = (item) => {
		const sold = getTotalVariantsCount(item.id) + (+item.sold_qty || 0);
		if (sold > item.squ) {
			return (
				<div className={`count ${!isLiveFinished ? 'red' : ''}`}>
					{sold}
				</div>
			)
		}
		return (
			<div className="count">
				{sold}
			</div>
		)
	}

	const getSoldCount = (item) => {
		const sold = getTotalCount() + (+item.sold_qty || 0);
		if (sold > item.squ) {
			return (
				<div className={`count ${!isLiveFinished ? 'red' : ''}`}>
					{sold}
				</div>
			)
		}
		return (
			<div className="count">
				{sold}
			</div>
		)
	}

	return (
		<div className="products-list">
			<div className="products-header">
				<div className="name">{getLangText('variantNameLabel')}</div>
				{withPrice && <div className="count">{getLangText('priceLabel')}</div>}
				{!isLiveFinished &&
					<div className="count">{getLangText('availableLabel')}</div>
				}
				<div className="count">{getLangText('soldLabel')}</div>
			</div>
			{!!product.sizes.length ?
				<div className="products-body">
					{product.sizes.map((i) => (
						<div key={i.id}>
							<div className="name">
								{i.size}
							</div>
							{withPrice && <div className="count">{getPrice(i.price)}</div>}
							{!isLiveFinished &&
								<div className="count">{i.squ}</div>
							}
							{getVariantSoldCount(i)}
						</div>
					))}
				</div> :
				<div className="products-body">
					<div>
						<div className="name">
							{product.name}
						</div>
						{withPrice && <div className="count">{getPrice(product.price)}</div>}
						{!isLiveFinished &&
							<div className="count">{product.squ}</div>
						}
						{getSoldCount(product)}
					</div>
				</div>
			}
		</div>
	);
};

export default ProductVariants;
