import Model from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

class FacebookModel extends Model {
	getModel() {
		return {
			page_id: null,
		};
	}

	getValidators() {
		return {
			page_id: ValidationError.notEmpty,
		};
	}

	buildDTO(data) {
		return {
			page_id: data.page_id,
		}
	}
}

export default new FacebookModel();
