import React from 'react';
import {Modal} from "react-bootstrap";
import Loader from "../../shared/Loader";
import useLang from "../../../../src_shared/hooks/useLang";
import useFormLocal from "../../../../src_shared/hooks/useFormLocal";
import InvoiceData from "../../payments/invoiceData/InvoiceData";
import {services} from "../../../RestServices";
import SummerPromoModel from "../../../../modules/models/summerPromo/SummerPromo";

const InvoiceDataModal = ({show, hideModal}) => {
    const {getLangText} = useLang();

    const [{
        values,
        isLoading,
        getFieldProps,
        updateValues,
        onSave,
        setValue,
        isWaiting,
    }] = useFormLocal({
        rest: `${services.API_SUMMER_PROMO}?half_year=1`,
        model: SummerPromoModel,
        afterSubmit: (response) => {
            window.location.href = response.url
        },
    });

    return (
        <Modal
            show={show}
            className="add-order-modal"
            dialogClassName="default-modal"
            onHide={hideModal}
        >
            <Modal.Header>
                <Modal.Title>{getLangText('paymentsChoseLegalFormLabel')}</Modal.Title>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            <Modal.Body
                style={{
                    margin: 0,
                }}
                className="defined-variants-modal-body payments-container">
                <Loader isLoading={isLoading}/>
                <InvoiceData
                    withLabel={false}
                    getFieldProps={getFieldProps}
                    setValue={setValue}
                    updateValues={updateValues}
                    values={values}
                    setValueAndUpdatePrice={setValue}
                />
            </Modal.Body>
            <Modal.Footer>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button border-button"
                >
                    {getLangText('cancelButton')}
                </button>
                <button
                    onClick={onSave}
                    type="button"
                    className={`button primary ${isWaiting ? 'loading-button' : ''}`}
                >
                    {isWaiting && <Loader isLoading/>}
                    {getLangText('goToPayment')}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default InvoiceDataModal;
