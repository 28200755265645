import React, {useState} from 'react';
import useLang from "../../../../src_shared/hooks/useLang";
import MarketingRegulationModal from "./agreements/MarketingRegulationModal";
import RegulationModal from "../deliveryAddress/components/RegulationModal";
import {services} from "../../../RestServices";
import {useModal} from "../../shared/hooks/useModal";

const BasketClause = ({data}) => {
    const {getLangText} = useLang();
    const [showMore, setShowMore] = useState(false)
    const agreementVisible = !!+data.shopInfo?.agreements_enabled;
    const [openRegulationsModal, setOpenRegulationsModal] = useModal(false);
    const [openShopRegulationsModal, setOpenShopRegulationsModal] = useModal(false);

    const regulationModalButton = () => {
        return (
            data?.shopInfo.has_terms ?
                <button
                    style={{position: "relative", zIndex: '9', textDecoration: 'underline'}}
                    type="button"
                    className="hover:text-[#0056b3]"
                    onClick={() => setOpenShopRegulationsModal(true)}
                >
                    {getLangText('terms1')}
                </button> :
                <button
                    className="hover:text-[#0056b3]"
                    style={{position: "relative", zIndex: '9', textDecoration: 'underline'}}
                    type="button"
                    onClick={() => setOpenRegulationsModal(true)}
                >
                    {getLangText('terms1')}
                </button>
        )
    }

    const checkboxLabel = () => {
        return (
            <>
                <span dangerouslySetInnerHTML={{
                    __html: getLangText({
                        key: 'selmoBasketClauseExpanded1',
                        data: [data?.shopInfo?.name]
                    })
                }}/>
                &nbsp;
                {regulationModalButton()}
                &nbsp;
                <span dangerouslySetInnerHTML={{__html: getLangText('selmoBasketClauseExpanded2')}}/>
            </>
        )
    }

    if (!agreementVisible) return null

    return (
        <div className="text-xs text-[#6B7280] font-medium border-t border-[#F3F4F6] pt-4 mt-5 leading-[20px]">
            <div>{getLangText('selmoBasketClause')}</div>
            <button
                onClick={() => setShowMore(true)}
                type="button"
                className="block mt-2 transition-all hover:text-[#000]"
            >
                <i className="icon-plus text-[8px] mr-1"/>
                {getLangText('readMoreButton')}
            </button>
            {showMore &&
                <MarketingRegulationModal
                    modalVisible={showMore}
                    hideModal={() => setShowMore(false)}
                    DescComponent={checkboxLabel()}
                />
            }
            {openShopRegulationsModal &&
                <RegulationModal
                    modalVisible={openShopRegulationsModal}
                    hideModal={() => setOpenShopRegulationsModal(false)}
                    rest={services.API_CART_SHOP_TERMS}
                    shopId={data?.shopInfo?.id}
                    title={`${getLangText('customerBasketShopRegulationsModalTitle')}${data?.shopInfo?.name}`}
                />
            }
            {openRegulationsModal &&
                <RegulationModal
                    modalVisible={openRegulationsModal}
                    hideModal={() => setOpenRegulationsModal(false)}
                    rest={services.API_CART_TERMS}
                    shopId={data?.shopInfo?.id}
                    title={getLangText('customerBasketRegulationsModalTitle')}
                />
            }
        </div>
    );
};

export default BasketClause;
