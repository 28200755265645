import Composition from "../../../../shared/helpers/Composition";
import createReducer from "../../../../shared/helpers/createReducer";
import FormReducer from "../../../../../../src_shared/form/FormReducer";

export const getStateRoot = (state) => state.orders.export;
export const prefix = 'ORDERS_EXPORT_';

const getInitState = () => FormReducer.getInitState({
	displayType: 1,
}, {
	productsExportVisible: false,
})

const getReduceMap = () => new Composition(
	FormReducer.getReduceMap(),
	{
		SHOW_EXPORT_PRODUCT_MODAL: (state, action) => ({...state, productsExportVisible: action.toggle})
	}
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

