import React from 'react';
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../../routes/RouterPath";
import useLang from "../../../../../../src_shared/hooks/useLang";

const MobilePayBigBox = ({data}) => {
    const {getLangText} = useLang();

    return (
        <div className="settings-box blue-media col-md-4">
            <Link to={RouterPaths.SettingsPaymentsMobilePay}>
                <img className="w-[220px]" src="/assets/images/payments/mobilepay.svg" alt=""/>
                <div className="desc">
                    {getLangText('addOnlinePaymentsToYourShopLabel')}
                </div>
                <div className="benefits">
                    <div className="flex">
                        <i className="icon-tick-c"/>
                        <span dangerouslySetInnerHTML={{__html: getLangText('fastPaymentsLabel')}}/>
                    </div>
                    <div className="flex">
                        <i className="icon-tick-c"/>
                        <span
                            dangerouslySetInnerHTML={{__html: getLangText('automaticPaymentsStatusChange')}}/>
                    </div>
                    <div className="flex">
                        <i className="icon-tick-c"/>
                        <span dangerouslySetInnerHTML={{__html: getLangText('easyForClientsPayments')}}/>
                    </div>
                </div>
                <div className="button-place">
                    <div className="flex-grow-1">
                        {!!+data.mobilepay_active ?
                            <div className="small-status green">
                                <i className="icon-tick"/>
                                {getLangText('active_label')}
                            </div>
                            :
                            <div className="small-status red">
                                <i className="icon-cross"/>
                                {getLangText('not_active_label')}
                            </div>
                        }
                    </div>
                    <div className="button primary small-size">
                        {getLangText('button_go_to')}
                        <i className="icon-arrows" style={{position: 'relative', top: '-2px'}}/>
                    </div>
                </div>
            </Link>
        </div>
    );
};

export default MobilePayBigBox;
