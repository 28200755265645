import React from 'react';
import BlueMediaWrapper from "../BlueMediaWrapper";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../routes/RouterPath";

const BlueMediaLanding = () => {

	return (
		<BlueMediaWrapper>
			<div className="mt-sm-3" style={{background: 'white'}}>
				<section className="blue-media-landing">
					<div className="blue-media-header">
						<img src="/assets/images/bm/bm-00.svg"
							 alt="header image" className="img-bg"
						/>
						<div className="title">Szybkie i bezpieczne płatności</div>
						<div className="sub-title">Daj swoim klientom kolejny powód, by kupowali u Ciebie</div>
						<Link to={RouterPaths.SettingsPaymentsOnline}
						   className="button configure-blue-media-btn">
							Skonfiguruj za darmo
						</Link>
					</div>
					<div className="blue-media-partners">
						<img src="/assets/images/settings/banks.svg" alt="partners"/>
					</div>
					<div className="blue-media-content">
						<div className="sub-label text-center">Płatności online</div>
						<div className="title smaller text-center"><strong
							className="d-inline">Dlaczego</strong> warto?
						</div>
						<div className="row mt-5">
							<div className="col-md-5 pr-sm-4">
								<div className="sub-label">
									Płatności online
								</div>
								<div className="title">
									<strong>Natychmiast</strong>
									otrzymujesz wpłaty
								</div>
								<div className="desc">
									Koniec z wydłużającym się oczekiwaniem na płatność od klientów. Otrzymujesz
									ją
									dosłownie
									po kilku sekundach i możesz skupić się na realizacji zamówienia.
								</div>
								<Link to={RouterPaths.SettingsPaymentsOnline}
								   className="button primary small-size mt-3 configure-blue-media-btn">
									Skonfiguruj
								</Link>
							</div>
							<div className="col-md-7 pl-sm-5">
								<figure>
									<img src="/assets/images/bm/bm-01.svg"
										 alt="header image" className="Wiesz, które paczki możesz wysyłać"
									/>
								</figure>
							</div>
						</div>
						<div className="row">
							<div className="col-md-7 pr-sm-5">
								<figure>
									<img src="/assets/images/bm/bm-02.svg"
										 alt="header image" className="Natychmiast otrzymujesz wpłaty"
									/>
								</figure>
							</div>
							<div className="col-md-5">
								<div className="sub-label">
									Płatności online
								</div>
								<div className="title">
									<strong>Wiesz, które paczki</strong>
									możesz wysyłać
								</div>
								<div className="desc">
									Już nie musisz wchodzić na swoje konto bankowe by upewnić się, że wpłata od
									klienta
									dotarła. Statusy zamówień aktualizują się automatycznie, a Ty wiesz, które
									paczki możesz
									wysyłać.
								</div>
								<Link to={RouterPaths.SettingsPaymentsOnline}
								   className="button primary small-size mt-3 configure-blue-media-btn">
									Skonfiguruj
								</Link>
							</div>
						</div>
						<div className="row">
							<div className="col-md-5 pr-sm-0">
								<div className="sub-label">
									Płatności online
								</div>
								<div className="title">
									<strong>Twojemu klientowi,</strong>
									łatwiej jest zapłacić
								</div>
								<div className="desc">
									Minimalizujesz ryzyko błędów, które dość często zdarzają się podczas
									wypełniania
									lub
									kopiowania danych do przelewu. Gwarancja płatności pozwala uniknąć takich
									pomyłek.
								</div>
								<Link to={RouterPaths.SettingsPaymentsOnline}
								   className="button primary small-size mt-3 configure-blue-media-btn">
									Skonfiguruj
								</Link>
							</div>
							<div className="col-md-7 pl-sm-5">
								<figure>
									<img src="/assets/images/bm/bm-03.svg"
										 alt="header image" className="wojemu klientowi, łatwiej jest zapłacić"
									/>
								</figure>
							</div>
						</div>
						<div className="row">
							<div className="col-md-7 pr-sm-5">
								<figure>
									<img src="/assets/images/bm/bm-04.svg"
										 alt="header image" className="Masz przewagę nad konkurencją"
									/>
								</figure>
							</div>
							<div className="col-md-5 pl-sm-4">
								<div className="sub-label">
									Płatności online
								</div>
								<div className="title">
									<strong>Masz przewagę</strong>
									nad konkurencją
								</div>
								<div className="desc">
									Daj klientom możliwość zapłaty za zamówienie, przy pomocy ich ulubionego
									sposobu
									płatności. Im szersza gama opcji płatności, tym szybciej rośnie przychód
									Twojego
									sklepu.
								</div>
								<Link to={RouterPaths.SettingsPaymentsOnline}
								   className="button primary small-size mt-3 configure-blue-media-btn">
									Skonfiguruj
								</Link>
							</div>
						</div>
					</div>
				</section>
			</div>
		</BlueMediaWrapper>
	);
};

export default BlueMediaLanding;
