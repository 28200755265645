import Composition from "../../../shared/helpers/Composition";
import createReducer from "../../../shared/helpers/createReducer";
import FormReducer from "../../../../../../../src_shared/form/FormReducer";
import CategoryModel from "../../../../../modules/models/CategoryModel";

export const getStateRoot = (state) => state.admin.posts.categories.form;
export const prefix = 'ADMIN_NEWS_FORM_';

const getInitState = () => FormReducer.getInitState(CategoryModel.getModel());

const getReduceMap = () => new Composition(
	FormReducer.getReduceMap(),
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

