import React, {useState} from 'react';
import useList from "../../../../../src_shared/list/useList.js";
import ListActions from "./ListActions";
import {getStateRoot} from "./listReducer";
import SearchInput from "../../../shared/components/form/SearchInput.jsx";
import FiltersActions from "../../filters/FiltersActions.js";
import {getStateRoot as getFiltersStateRoot} from '../../filters/filtersReducer.js'
import useWindowDimensions from "../../../messenger/hooks/useWindowDimensions/useWindowDimensions.js";
import ProductsTable from "./components/table/Table.jsx";
import Pagination from "../../../shared/components/pagination/Pagination.jsx";
import StorageWrapper from "../../Page";
import DateRangePicker from "../../../shared/components/form/DateRangePicker";
import {useSelector} from "react-redux";
import {createSelector} from "reselect";
import useLang from "../../../../../src_shared/hooks/useLang";
import {useUnitPrice} from "../../../shared/helpers/Price";
import {convertDate} from "../../../shared/helpers/dateHelpers";
import {getGETParamsUrl} from "../../../../../src_shared/list/ListActions";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import CustomDropdown from "../../../shared/components/dropdown/CustomDropdown";
import {
    BUTIK_MADAME_SHOP_ID,
    PIANO_FASHION_SHOP_ID,
    TEST_ACCOUNT_SHOP_ID, TIM_SHOP_ID,
    WIKA_EWA_FASHION_ID
} from "../../../../../src_shared/enums/TrustedShopsIds";
import ExportModal from "../../../../../src_shared/components/exportModal/ExportModal";
import {services} from "../../../../RestServices";
import AsyncFilter from "../../../../../src_shared/components/filters/asyncFilter/AsyncFilter";
import {USER_PERMISSIONS_PRODUCT_TOTAL_PRICES_VISIBILITY} from "../../../shared/enums/UserPermissionsTypes";
import UserPermissionsWrapper from "../../../../routes/UserPermissionsWrapper";
import {PRODUCTS_SHOP_VISIBILITY_TYPES} from "../../../shared/enums/ProductsShopVisibilityType";
import SingleSelectFiltersStatus
    from "../../../../../src_shared/components/filters/singleSelectFiltersStatus/SingleSelectFiltersStatus";
import {
    PRODUCTS_RESERVE_LIST_REMOVED_FROM_TYPES_TYPES
} from "../../../shared/enums/ProductsReserveListRemovedFromAppTypes";

const XLS_EXPORT_MODAL = 'XLS_EXPORT_MODAL';
const PDF_EXPORT_MODAL = 'PDF_EXPORT_MODAL';

const ProductsReserveList = () => {

    const {userData} = useSelector((state) => state.session);
    const [isFiltersVisible, setFiltersVisible] = useState(false);
    const {width} = useWindowDimensions();
    const {
        fromDate,
        toDate,
        categories,
        manufacturers,
        deleted,
    } = useSelector(createSelector(getFiltersStateRoot, (stateRoot) => stateRoot.data));

    const {totalPrice} = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot))
    const {getLangText} = useLang();
    const {getPrice} = useUnitPrice();

    const [openExportModal, setOpenExportModal] = useState('');

    const {
        pagination: {
            changePage,
            getPagination,
            itemsPerPage,
            nextPage,
            prevPage,
            totalItems,
            updateItemsPerPage
        },
        list: {items, isWaiting, loadData},
        search,
        sort: {updateSortOptions, getSortClass},
    } = useList({
        ListActions,
        getStateRoot,
        FiltersActions,
    });

    const preparedDateParams = () => {
        const params = {}
        if (fromDate) {
            params.dateFrom = convertDate(fromDate);
        }

        if (toDate) {
            params.dateTo = convertDate(toDate);
        }

        if (search.searchText) {
            params.superSearch = search.searchText;
        }

        if (categories.length) {
            params['categories[]'] = categories
        }

        if (manufacturers.length) {
            params['manufacturers[]'] = manufacturers
        }

        if (!!+deleted) {
            params.deleted = deleted
        }

        return getGETParamsUrl(params);
    }

    return (
        <StorageWrapper helmetTitle={`${getLangText('reserveListLabel')} - Selmo`}>
            <div className="table-list-wrapper">
                <div className="default-shadow-box pb-0">
                    <div className="table-header">
                        <div className="top-part w-lg-auto w-100 mb-lg-auto mb-2">
                            <div>
                                <h1 className="title">
                                    {getLangText('reserveListLabel')}
                                    {totalItems > 0 &&
                                        <div className="badge">{totalItems}</div>
                                    }
                                </h1>
                                <UserPermissionsWrapper
                                    allowedPermissions={[USER_PERMISSIONS_PRODUCT_TOTAL_PRICES_VISIBILITY]}>
                                    {(totalPrice && +userData.id !== WIKA_EWA_FASHION_ID) &&
                                        <div className="total-price extra-small-text">
                                            {getLangText('totalPriceLabel')}: <strong>{getPrice(totalPrice)}</strong>
                                        </div>
                                    }
                                </UserPermissionsWrapper>
                            </div>
                            <button
                                type="button"
                                className='toggle-filters'
                                onClick={() => setFiltersVisible(!isFiltersVisible)}
                            >
                                <i className='icon-filter'/>
                            </button>
                        </div>
                        {(width > 767 || isFiltersVisible) &&
                            <div className="filters-part mt-sm-3">
                                <AsyncFilter
                                    ListActions={ListActions}
                                    FiltersActions={FiltersActions}
                                    getFiltersStateRoot={getFiltersStateRoot}
                                    name="categories"
                                    selectAllName="categoriesSelectAll"
                                    defaultSelectedLabel={getLangText('categoriesListLabel')}
                                    rest={services.API_CATEGORIES}
                                />
                                {(+userData.id === TEST_ACCOUNT_SHOP_ID || +userData.id === PIANO_FASHION_SHOP_ID || +userData.id === BUTIK_MADAME_SHOP_ID || +userData.id === TIM_SHOP_ID) &&
                                    <AsyncFilter
                                        ListActions={ListActions}
                                        FiltersActions={FiltersActions}
                                        getFiltersStateRoot={getFiltersStateRoot}
                                        name="manufacturers"
                                        selectAllName="manufacturersSelectAll"
                                        defaultSelectedLabel={getLangText('manufacturersLabel')}
                                        className="ml-sm-2"
                                        rest={services.API_STORAGE_SEARCH_MANUFACTURERS}
                                    />
                                }
                                {!!+userData.mobile_app &&
                                    <SingleSelectFiltersStatus
                                        ListActions={ListActions}
                                        FiltersActions={FiltersActions}
                                        getFiltersStateRoot={getFiltersStateRoot}
                                        options={PRODUCTS_RESERVE_LIST_REMOVED_FROM_TYPES_TYPES}
                                        name="deleted"
                                        className="ml-sm-2"
                                        defaultSelectedLabel={getLangText('removedFromSelmoApp')}
                                        withClear={false}
                                    />
                                }
                                <OverlayTrigger
                                    placement='top'
                                    overlay={
                                        <Tooltip
                                            className="small-size"
                                            id="print-products">
                                            {getLangText('printProductsPDFLabel')}
                                        </Tooltip>
                                    }
                                >
                                    <CustomDropdown
                                        ButtonToggle={() => <span
                                            className="d-md-none">{getLangText('exportLabel')}</span>}
                                        icon="icon-print"
                                        className="ml-auto"
                                        buttonClassName="mr-sm-3 default-size"
                                        tooltipTitle={getLangText('exportLabel')}
                                    >
                                        <button
                                            type="button"
                                            onClick={() => setOpenExportModal(PDF_EXPORT_MODAL)}
                                            className="custom-option"
                                        >
                                            {getLangText('printProductsPDFLabel')}
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => setOpenExportModal(XLS_EXPORT_MODAL)}
                                            className="custom-option"
                                        >
                                            {getLangText('printProductsXLSLabel')}
                                        </button>
                                    </CustomDropdown>
                                </OverlayTrigger>
                                <SearchInput
                                    placeholder={getLangText('searchPlaceholder')}
                                    className="mr-md-2 ml-0"
                                    search={search}
                                />
                                <DateRangePicker
                                    actions={ListActions}
                                    getFiltersStateRoot={getFiltersStateRoot}
                                    FiltersActions={FiltersActions}
                                />
                            </div>
                        }
                    </div>
                    <ProductsTable
                        getSortClass={getSortClass}
                        updateSortOptions={updateSortOptions}
                        items={items}
                        isWaiting={isWaiting}
                        loadData={loadData}
                        hiddenActions={!!+deleted}
                    />
                </div>
                <Pagination
                    getLangText={getLangText}
                    pages={getPagination()}
                    items={items}
                    itemsPerPage={itemsPerPage}
                    totalItems={totalItems}
                    onPrevPage={prevPage}
                    onChangePage={changePage}
                    onNextPage={nextPage}
                    onChangeItemsPerPage={updateItemsPerPage}
                    classes={{
                        prevIcon: 'icon-chevron left',
                        nextIcon: 'icon-chevron right',
                    }}
                    showItemsPerPageSelect
                />
            </div>
            {openExportModal === PDF_EXPORT_MODAL &&
                <ExportModal
                    title={getLangText('exportLabel')}
                    subTitle={getLangText('receivedFileInPDFFormatLabel')}
                    saveButton={getLangText('exportLabel')}
                    rest={`${services.API_EXPORT_STORAGE_RESERVE_LIST_PDF}${preparedDateParams()}`}
                    fileName={getLangText('boughtProductsLabel')}
                    show={openExportModal === PDF_EXPORT_MODAL}
                    hide={() => setOpenExportModal('')}
                />
            }
            {openExportModal === XLS_EXPORT_MODAL &&
                <ExportModal
                    title={getLangText('exportLabel')}
                    subTitle={getLangText('receivedFileInXLSFormatLabel')}
                    saveButton={getLangText('exportLabel')}
                    rest={`${services.API_EXPORT_STORAGE_RESERVE_LIST_XLS}${preparedDateParams()}`}
                    fileName={getLangText('boughtProductsLabel')}
                    fileType='xlsx'
                    show={openExportModal === XLS_EXPORT_MODAL}
                    hide={() => setOpenExportModal('')}
                />
            }
        </StorageWrapper>
    );
};

export default ProductsReserveList;
