import React, {useState} from 'react';
import TextArea from "../../../../../src_shared/components/form/textarea/TextArea";
import Modal from "react-bootstrap/Modal";
import Loader from "../../../messenger/loader/Loader";
import ValidationError from "../../../../../src_shared/form/validation/ValidationError";
import axios from "axios";
import {selmoUrl} from "../../../../../src_shared/api/api";
import {services} from "../../../../RestServices";
import {showAlert} from "../../../shared/components/alert/AlertActions";
import SwitchField from "../../../../../src_shared/components/form/switchField/SwitchField";
import {useDispatch} from "react-redux";
import useLang from "../../../../../src_shared/hooks/useLang";
import api from "../../../../../services/axios/axios";

const SendSummaryMessageModal = ({defaultValue, hideModal, afterSubmit, modalVisible}) => {
	const dispatch = useDispatch();
	const {getLangText} = useLang();

	const [values, setValue] = useState({message: '', with_link: true, ...defaultValue});
	const [isError, setIsError] = useState(false);
	const [isWaiting, setIsWaiting] = useState(false);

	const onSave = async () => {
		if (!values.message) {
			setIsError(true);
			return;
		}
		setIsWaiting(true)
		try {
			await api.post(`${selmoUrl}/${services.API_MESSAGE}`, values)
			afterSubmit()
			hideModal(true)
		} catch (e) {
			dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
			console.warn('cannot send message')
		}
		finally {
			setIsWaiting(false);
		}
	}

	return (
		<Modal
			dialogClassName={`default-modal small-modal`}
			show={modalVisible}
			onHide={() => hideModal(false)}
		>
			<Modal.Header className="with-border">
				<Modal.Title>
					<Modal.Title>
						{getLangText('sendMessageLabel')}
					</Modal.Title>
				</Modal.Title>
				<button
					onClick={() => hideModal(false)}
					type="button"
					className="button"
				>
					<i className="icon-cross"/>
				</button>
			</Modal.Header>
			<Modal.Body>
				<TextArea
					value={values.message}
					showLabel={false}
					setValue={(field, value) => setValue({...values, message: value})}
					label={getLangText('messageToClientLabel')}
					validateErrors={isError}
					getError={() => ValidationError.notEmpty(values.message)}
				/>
				<SwitchField
					className="switch-form inline-label align-items-start"
					label={getLangText('orderLinkLabel')}
					subLabel={getLangText('orderLinkTooltipLabel')}
					setValue={(field, value) => setValue({...values, with_link: value})}
					value={values.with_link}
					validateErrors={isError}
					getError={() => ValidationError.skip(values.with_link)}

				/>
			</Modal.Body>
			<Modal.Footer>
				<button
					onClick={() => hideModal(false)}
					type="button"
					className="button border-button"
				>
					{getLangText('cancelButton')}
				</button>
				<button
					onClick={onSave}
					type="button"
					className={`button primary px-0 ${isWaiting ? 'loading-button' : ''}`}
				>
					{isWaiting && <Loader/>}
					{getLangText('sendButton')}
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default SendSummaryMessageModal;
