import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import {RouterPaths} from "../../RouterPath";
import ReelsList from "../../../app/products/reels/list/List";
import ReelsForm from "../../../app/products/reels/form/Form";

const ReelsRoute = ({path}) => {

	return (
		<Route path={path}>
			<Switch>
				<Route
					exact
					path={RouterPaths.ReelsList}
					component={ReelsList}
				/>
				<Route
					exact
					path={RouterPaths.ReelsForm}
					component={ReelsForm}
				/>
				<Redirect to={RouterPaths.ReelsList}/>
			</Switch>
		</Route>
	);
};

export default ReelsRoute;
