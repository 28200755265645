import {batch} from "react-redux";
import {selmoUrl} from "../../../../src_shared/api/api";
import axios from "axios";
import SessionActions from "../../shared/session/SessionActions";
import api from "../../../../services/axios/axios";

export const chatBoxReset = () => {
	return {
		type: 'CHAT_BOX_RESET'
	};
}

const getConversationRequested = () => {
	return {
		type: 'GET_CONVERSATION_REQUESTED'
	};
}

const setChatBoxLoaderWaiting = (toggle) => {
	return {
		type: 'CHAT_BOX_SET_LOADER_WAITING',
		toggle,
	};
}

export const updateConversation = items => {
	return {
		type: 'UPDATE_CONVERSATION',
		items,
	};
}

export const setConversationPage = (page) => {
	return {
		type: 'SET_CONVERSATION_PAGE',
		page
	};
}

export const updateNextParameter = (next) => {
	return {
		type: 'CHAT_BOX_UPDATE_NEXT_PARAMETER',
		next
	};
}

const getConversationError = () => {
	return {
		type: 'GET_CONVERSATION_ERROR'
	};
}

export const setConversationNoResults = () => {
	return {
		type: 'SET_CONVERSATION_NO_RESULTS',
	};
}

export const chatBoxSetActiveConversation = (data) => {
	return {
		type: 'CHAT_BOX_SET_ACTIVE_CONVERSATION',
		data,
	};
}

export const getConversation = (id, withLoader, signal) => async (dispatch, getState) => {
	const {page, next} = getState().messenger.chatBox;

	if (withLoader) {
		dispatch(getConversationRequested());
	}

	try {
		let currentPage = next ? next : (withLoader ? '1' : page)
		const {data} = await api.get(`${selmoUrl}/api/conversations/id/${id}?page=${currentPage}`, {signal})

		batch(() => {
			dispatch(updateConversation(data.messages))
			dispatch(chatBoxSetActiveConversation(data.conversation_data))
			if (data.next === null) {
				dispatch(setConversationNoResults())
				return
			}
			if (data.next) {
				dispatch(updateNextParameter(data.next))
			}
		})
	} catch (e) {
		if (e.response?.data?.message === 'Not found') {
			dispatch(setConversationNoResults())
			return;
		}

		if (e.message === "canceled") {
			return;
		}

		dispatch(getConversationError())
		console.error(e)
	}
}

export const addNewMessageToConversation = (msg) => {
	return {
		type: 'ADD_NEW_MESSAGE_TO_CONVERSATION',
		msg,
	};
}

export const setSendStatusMessage = (id, status) => {
	return {
		type: 'SET_SEND_STATUS_MESSAGE',
		id,
		status
	};
}

export const setRightSideVisible = (toggle) => {
	return {
		type: 'CHAT_BOX_SET_RIGHT_SIDE_VISIBLE',
		toggle,
	}
}
