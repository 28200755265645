import Model, {convertObjectProperties} from "../../../src_shared/modules/model/Model";

export class CategoriesListModel extends Model {
	parseDTO(data) {
		return convertObjectProperties(
			data,
			{

			},
			{
				title: data.name,
				children: [],
				expanded: false,
				type: 'PRODUCT',
			},
		);
	}
}

export default new CategoriesListModel();
