import React, {useEffect} from 'react';
import InpostLabels, {INPOST_LABEL_CUSTOM} from "../../../../../../shared/enums/InpostLabelsTypes";
import {useDispatch} from "react-redux";
import ListActions from "../../../../ListActions";
import CustomSizeModal from "../../../../../details/inpostLabel/customSizeModal/CustomSizeModal";
import useForm from "../../../../../../../../src_shared/form/useForm";
import FormActions from "./FormActions";
import Loader from "../../../../../../shared/Loader";
import useLang from "../../../../../../../../src_shared/hooks/useLang";

const Inpost = ({hideGenerateModal, waitingForDocument, validationActive}) => {
	const dispatch = useDispatch();
	const {getLangText} = useLang();

	const onSubmit = () => dispatch(ListActions.generateLabels(values, 2));

	const {
		data: {values, setValue},
		form: {onSave, getFieldProps},
		modal: {hideModal, showModal, modalVisible},
	} = useForm({
		FormActions,
		params: {},
		preventAutoLoad: true,
	});

	const isError = validationActive && !values.template;

	useEffect(() => {
		return () => dispatch(ListActions.setGenerateLabelTemplateError(false));
	}, [])

	return (
		<>
			<div className="labels-header">
				<div className="labels-title">
					{getLangText('selectTemplateLabel')}
				</div>
				<img
					width={46}
					src="/assets/images/inpost.svg"
					alt="inpost"
				/>
			</div>
			<div className={`inpost-chooser pb-5 ${isError ? 'error-group' : ''}`}>
				{InpostLabels.map((box) => (
					<div
						key={box.id}
						id={box.id}
						className={`inpost-chooser__box ${values.template === box.id ? 'active' : ''}`}
					>
						<input
							onChange={() => setValue('template', box.id)}
							checked={values.template === box.id}
							type="radio"
							value={values.template}
						/>
						<span className="checkmark"/>
						<div className="inpost-chooser__box__text-wrapper">
							<div className="inpost-chooser__box__text-wrapper__top">
								{getLangText(box.label)}
							</div>
							<div className="inpost-chooser__box__text-wrapper__bottom">
								{box.sizes}
							</div>
						</div>
						<div>
							<img
								src={`https://inpost.pl/SzybkieNadania/assets/icons/${box.img}.svg?v=1.11.2`}
								height="31px"
							/>
						</div>
					</div>
				))}
				<div
					onClick={showModal}
					className={`inpost-chooser__box ${values.template === INPOST_LABEL_CUSTOM ? 'active' : ''}`}
				>
					<input
						checked={values.template === INPOST_LABEL_CUSTOM}
						type="radio"
						value={values.template}
					/>
					<span className="checkmark"/>
					<div className="inpost-chooser__box__text-wrapper">
						<div className="inpost-chooser__box__text-wrapper__top">
							{getLangText('customSizeLabel')}
						</div>
						<div className="inpost-chooser__box__text-wrapper__bottom">
							{values.length ?
								`max. ${values.length} x ${values.width} x ${values.height} ${getLangText('toLabel')} ${values.weight} kg` :
								getLangText('selectSizeLabel')
							}
						</div>
					</div>
					<div>
						<img
							src="/assets/images/svg/inpost-custom.svg"
							height="31px"
						/>
					</div>
				</div>
			</div>
			<div className="modal-footer">
				<button
					onClick={hideGenerateModal}
					type="button"
					className="button border-button"
				>
					{getLangText('closeButton')}
				</button>
				<button
					onClick={onSubmit}
					type="button"
					className={`button primary ${waitingForDocument ? 'loading-button' : ''}`}
				>
					{waitingForDocument && <Loader isLoading={true}/>}
					{getLangText('generateLabel')}
				</button>
			</div>
			{modalVisible &&
				<CustomSizeModal
					className="higher-index"
					backdropClassName="higher-index"
					modalVisible={modalVisible}
					hideModal={hideModal}
					getFieldProps={getFieldProps}
					setValue={setValue}
					onSave={onSave}
				/>
			}
		</>
	);
};

export default Inpost;
