import React from 'react';
import {Route, Switch} from "react-router-dom";
import PaymentsPage from "../app/payments/PaymentsPage";
import Alert from "../app/shared/components/alert/Alert";
import {RouterPaths} from "./RouterPath";
import ThankYouPage from "../app/payments/thankYouPage/Page";

const PaymentsRoute = ({path}) => {

    return (
        <Route path={path}>
            <Switch>
                <Route
                    exact
                    path={RouterPaths.PaymentsThankYou}
                    component={ThankYouPage}
                />
                <Route
                    exact
                    path={path}
                    component={PaymentsPage}
                />
            </Switch>
            <Alert/>
        </Route>
    );
};

export default PaymentsRoute;
