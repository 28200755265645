import createReducer from "../../../../shared/helpers/createReducer.js";
import Composition from "../../../../shared/helpers/Composition.js";
import FormReducer from "../../../../../../src_shared/form/FormReducer.js";
import DefinedVariantsModel from "../../../../../../modules/models/lives/DefinedVariantsModel.js";

export const getStateRoot = (state) => state.lives.definedVariants;
export const prefix = 'LIVE_DETAILS_DEFINED_VARIANTS_';

const getInitState = () => FormReducer.getInitState(DefinedVariantsModel.getModel());

const getReduceMap = () => new Composition(
	FormReducer.getReduceMap(),
	{
		SET_UNIQUE_PRODUCT_CODE_ERROR: (state, action) => ({...state, uniqueProductCodeError: action.toggle})
	}
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

