import {combineReducers} from "redux";
import membersListsReducer from './members/list/listReducer'
import invitationsListReducer from './invitations/listReducer'
import statisticsListReducer from './history/listReducer'
import filtersReducer from './filters/filtersReducer'
import memberHistory from './members/history/listReducer'
import statisticsReducer from "./statistics/statisticsReducer";

export default combineReducers({
    members: membersListsReducer,
    invitations: invitationsListReducer,
    statistics: statisticsListReducer,
    memberHistory: memberHistory,
    filters: filtersReducer,
    stats: statisticsReducer,
});
