import React from 'react';
import EmptyList from "../../../../../../shared/EmptyList.jsx";
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import ListActions from "../../ListActions";
import useLang from "../../../../../../../../src_shared/hooks/useLang";
import {useUnitPrice} from "../../../../../../shared/helpers/Price";
import ServerImage from "../../../../../../shared/components/serverImage/ServerImage";
export const getStatusBox = (squ, getLangText) => {

	if (+squ === 0) {
		return (
			<div className="status-bg grey">
				{getLangText('lackLabel')}
			</div>
		)
	}
	if (+squ === 1) {
		return (
			<div className="status-bg green">
				1 {getLangText('pieceLabel')}
			</div>
		)
	}
	if (+squ > 1 && +squ < 5) {
		return (
			<div className="status-bg green">
				{squ} {getLangText('piecesLabel')}
			</div>
		)
	}
	return (
		<div className="status-bg green">
			{squ} {getLangText('piecesGenitiveLabel')}
		</div>
	)
}

const ProductsTable = ({items, isWaiting, updateSortOptions, getSortClass}) => {
	const dispatch = useDispatch();
	const {id} = useParams();
	const {getLangText} = useLang();
	const {getPrice} = useUnitPrice();

	const setActiveProduct = (item) => dispatch(ListActions.setActiveProduct({...item, live_product_id: item.id}, id));

	return (
		<EmptyList
			items={items}
			isLoading={isWaiting}
			className="with-padd"
			imgSrc="/assets/images/empty/products-empty.svg"
			title={getLangText('emptyHereLabel')}
			text={getLangText('changeSearchOrAddProductsLabel')}
		>
			<div className="table-wrapper">
				<div className="table-responsive">
					<table className="table live-table smaller-padd">
						<thead>
						<tr>
							<th>
								{getLangText('codeLabel')}
							</th>
							<th
								name="name"
								onClick={updateSortOptions}
								className={getSortClass('name')}
							>
							<span name="name">
								{getLangText('productLabel')}
							</span>
							</th>
							<th
								name="price"
								onClick={updateSortOptions}
								className={getSortClass('price')}
							>
							<span name="price">
								{getLangText('priceLabel')}
							</span>
							</th>
							<th>
								{getLangText('statusLabel')}
							</th>
							<th/>
						</tr>
						</thead>
						<tbody>
						{items.map((item) => (
							<tr className="not-clicked-tr">
								<td
									data-thead="Kod:"
									className="number with-mobile-thead inline-thead order-lg-0 order-1 my-lg-0 my-1"
								>
									{item.selmo_code}
								</td>
								<td className="with-photo w-lg-auto w-100">
									<div className="d-flex align-items-lg-center mt-sm-0 mt-2">
										<figure>
											{item.photo_small && item.photo_small !== '0' ?
												<ServerImage src={item.photo_small} alt={item.name}/> :
												<img src="/assets/images/default/product.svg" alt=""/>
											}
										</figure>
										<div>
											<div className="product-name">
												{item.name}
											</div>
											<div className="d-lg-none mobile-price">
												{getPrice(item.price)}
											</div>
										</div>
									</div>
								</td>
								<td className="w-lg-auto w-100 d-none d-lg-table-cell">
									{getPrice(item.price)}
								</td>
								<td className="order-lg-0 order-3">
									{getStatusBox(item.squ, getLangText)}
								</td>
								<td className="text-right">
									{!+item.active ?
										<button
											onClick={() => setActiveProduct(item)}
											className="button primary with-icon"
										>
											{getLangText('presentLabel')}
										</button>:
										<div className="button green-button">{getLangText('currentPresentingPluralLabel')}</div>
									}
								</td>
							</tr>
						))}
						</tbody>
					</table>
				</div>
			</div>
		</EmptyList>
	);
};

export default ProductsTable;
