import React, {useEffect, useState} from 'react';
import {services} from "../../../../../../RestServices";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import useScrollList from "../../../../../../../src_shared/hooks/useScrollData";
import SearchInput from "../../../../../shared/components/form/SearchInput";
import Loader from "../../../../../../../src_shared/components/loader/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import TemplateItem from "./TemplateItem";
import useRemoveData from "../../../../../../../src_shared/hooks/useRemoveData";
import ConfirmModal from "../../../../../../../src_shared/components/modal/ConfirmModal";

const TemplatesList = ({setActiveView, setSelectedTemplateId, selectTemplate, selectedTemplateId}) => {
    const {getLangText} = useLang();

    const [selectedParams, setSelectedParams] = useState({
        superSearch: '',
    })

    const {
        getNewPage,
        hasMore,
        isNewPageLoading,
        isLoading,
        data,
        page,
        setPage,
        fetchData,
    } = useScrollList({
        simpleArray: true,
        params: selectedParams,
        initialAutoLoad: false,
        rest: services.API_VIDEO_DESCRIPTION_TEMPLATES,
    });

    const search = {
        updateSearchText: (value) => setSelectedParams((prevState) => ({...prevState, superSearch: value})),
        callSearch: fetchData,
        searchText: selectedParams.superSearch,
    }

    const [{
        openModal: openRemoveModal,
        hideModal: hideRemoveModal,
        confirmModalVisible,
        isDeleting,
        removeItem,
    }] = useRemoveData({
        rest: services.API_VIDEO_DESCRIPTION_TEMPLATES,
        afterSubmit: () => setPage(1),
    });

    useEffect(() => {
        if (page === 1) {
            fetchData()
        }
    }, [page])

    useEffect(() => {
        setPage(1)
    }, [selectedParams.superSearch])

    return (
        <div className="-mt-2 mb-2">
            <SearchInput
                className="full-width-input"
                placeholder={getLangText('searchPlaceholder')}
                search={search}
            />
            <div className="flex items-center mt-3 mb-1">
                <div className="font-bold text-sm">{getLangText('selectTemplateLabel')}</div>
                <button
                    type="button"
                    className="ml-auto text-xs text-[#3C61EA] font-bold"
                    onClick={() => setActiveView(0)}
                >
                    + {getLangText('settings_package_types_text_1')}
                </button>
            </div>
            {isLoading && !data.length ?
                <Loader isLoading={true}/> :
                !!data.length ?
                    <div style={{flex: 1}}
                    >
                        <InfiniteScroll
                            dataLength={data.length}
                            next={getNewPage}
                            loader={isNewPageLoading && <Loader isLoading={true} className="static-loader"/>}
                            hasMore={hasMore}
                            className="-mx-[10px]"
                        >
                            {data?.map((item) => (
                                <TemplateItem
                                    selectTemplate={selectTemplate}
                                    selectedTemplateId={selectedTemplateId}
                                    setSelectedTemplateId={setSelectedTemplateId}
                                    setActiveView={setActiveView}
                                    openRemoveModal={openRemoveModal}
                                    key={item.id}
                                    item={item}
                                />
                            ))}
                        </InfiniteScroll>
                        {/*<FlatList*/}
                        {/*    ListHeaderComponent={() => (*/}
                        {/*        <View style={{flexDirection: 'row', marginBottom: 25}}>*/}
                        {/*            <Text style={{*/}
                        {/*                color: '#000',*/}
                        {/*                fontSize: 14,*/}
                        {/*                fontFamily: "PlusJakartaSans-Bold",*/}
                        {/*            }}>*/}
                        {/*                {t('salesPosts')}*/}
                        {/*            </Text>*/}
                        {/*            <Text*/}
                        {/*                onPress={() => setActiveView(0)}*/}
                        {/*                // onPress={() => setModalVisible(true)}*/}
                        {/*                style={{*/}
                        {/*                    fontFamily: "PlusJakartaSans-Bold",*/}
                        {/*                    marginLeft: 'auto',*/}
                        {/*                    fontSize: 12,*/}
                        {/*                    color: '#3c61ea',*/}
                        {/*                }}*/}
                        {/*            >*/}
                        {/*                + {t('addTemplate')}*/}
                        {/*            </Text>*/}
                        {/*        </View>*/}
                        {/*    )}*/}
                        {/*    data={data}*/}
                        {/*    keyExtractor={(item) => item.id}*/}
                        {/*    renderItem={item}*/}
                        {/*    onEndReachedThreshold={0.2}*/}
                        {/*    onEndReached={hasMore ? getNewPage : () => {*/}
                        {/*    }}*/}
                        {/*    refreshControl={*/}
                        {/*        <RefreshControl*/}
                        {/*            tintColor="#dadada"*/}
                        {/*            refreshing={refreshing}*/}
                        {/*            onRefresh={onRefresh}*/}
                        {/*        />*/}
                        {/*    }*/}
                        {/*/>*/}
                        {/*{isNewPageLoading && <ActivityIndicator/>}*/}
                    </div> :
                    <div className="text-center py-4">
                        <div className="font-bold">{getLangText('noTemplates')}</div>
                        <button
                            type="button"
                            className="button primary small-size mt-2"
                            onClick={() => setActiveView(0)}
                        >
                            {getLangText('settings_package_types_text_1')}
                        </button>
                    </div>
            }
            {confirmModalVisible &&
                <ConfirmModal
                    modalClasName="higher-index"
                    backdropClassName="higher-index"
                    dialogClassName="small-modal w-380"
                    show={confirmModalVisible}
                    isWaiting={isDeleting}
                    onSubmit={removeItem}
                    hide={hideRemoveModal}
                    saveButtonClass="danger"
                    title={getLangText('doYouWantRemoveTemplate')}
                    saveButton={getLangText('yesButton')}
                />
            }
        </div>
    );
};

export default TemplatesList;
