import ValidationError from "../../../src_shared/form/validation/ValidationError";
import {Model} from "../../../src_shared/modules/model/Model";

class SelmoPaczkaPointActivationModal extends Model {
    getModel() {
        return {
            dpd_active: '1',
            dpd_price: '12',
            op_active: '1',
            op_price: '12',
            inpost_active: '1',
            inpost_price: '16',
        };
    }

    getValidators() {
        return {
            dpd_price: (value, data) => !!+data.dpd_active ? ValidationError.greaterThan(0)(value, data) : ValidationError.skip() ,
            op_price: (value, data) => !!+data.op_active ? ValidationError.greaterThan(0)(value, data) : ValidationError.skip() ,
            inpost_price: (value, data) => !!+data.inpost_active ? ValidationError.greaterThan(0)(value, data) : ValidationError.skip() ,
            dpd_active: ValidationError.skip,
            op_active: ValidationError.skip,
            inpost_active: ValidationError.skip,
        };
    }
}

export default new SelmoPaczkaPointActivationModal();
