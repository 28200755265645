import React from 'react';
import useLang from "../../../../src_shared/hooks/useLang";
import SwitchField from "../../../../src_shared/components/form/switchField/SwitchField";
import useFormLocal from "../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../RestServices";
import SettingsAutopayAutomaticTransferModel
    from "../../../../modules/models/settings/SettingsAutopayAutomaticTransferModel";
import ComponentLoader from "../../../../src_shared/components/ui/componentLoader/ComponentLoader";
import TextArea from "../../../../src_shared/components/form/textarea/TextArea";
import Loader from "../../../app/shared/Loader";
import Wrapper from "./Wrapper";

const AutomaticTransferConfiguration = () => {
    const {getLangText} = useLang();

    const [{
        onSave,
        isWaiting,
        getFieldProps,
        isLoading,
        values,
    }] = useFormLocal({
        rest: services.API_SETTINGS_BM_TRANSFER_INFO,
        model: SettingsAutopayAutomaticTransferModel,
    });

    return (
        <Wrapper>
            {(isLoading || isWaiting) ?
                <ComponentLoader/> :
                <div className="mt-sm-3">
                    <div className="row small-padd-row">
                        <div className="col-md-7">
                            <form onSubmit={onSave}>
                                <div className="settings-page blue-media-settings-page">
                                    <div className="settings-form mb-3">
                                        <div className="steps-wrapper full-filled-numbers">
                                            <div className="step-group">
                                                <div className="step optional-step pb-4">
                                                    <div className="form-info-box small-line-height w-100">
                                                        <div>
                                                            <i className="icon-info-c"/>
                                                        </div>
                                                        <div>
                                                            <div className="title">Jak to działa?</div>
                                                            <div className="desc">
                                                                Po włączeniu tej opcji tradycyjny przelew
                                                                standardowy zostanie zastąpiony przez przelew
                                                                standardowy reazilowany w systemie AutoPay.
                                                                Dzięki temu płatność zostanie zaksięgowania w
                                                                zamówieniu automatczynie po około dwóch dnia
                                                                roboczych.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="step-header mt-4 pb-0">
                                                        <SwitchField
                                                            {...getFieldProps('bm_transfer_active')}
                                                            label="Aktywuj automatyczne przelewy standardowe"
                                                            className="switch-form inline-label big-label light-version mb-0 mt-0"
                                                        />
                                                    </div>
                                                    <div className="text-right">
                                                        <button
                                                            type="submit"
                                                            className={`button ml-auto primary mt-3 ${isWaiting ? 'loading-button' : ''}`}
                                                        >
                                                            <Loader isLoading={isWaiting}/>
                                                            {getLangText('saveButton')}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="step-group mt-3">
                                                <div className="step optional-step">
                                                    <h2 className="font-bold mb-4">Dodatkowe opcje</h2>
                                                    <div>
                                                        <div className="step-header">
                                                            <SwitchField
                                                                {...getFieldProps('bm_first_message_active')}
                                                                label="Automatyczna wiadomość informująca klienta o sposobie płatności"
                                                                subLabel="Klient otrzyma natychmiastową wiadomość przy złożeniu zamówienia o szczegółach płatności"
                                                                className="switch-form inline-label big-label light-version mb-0 mt-0 align-items-start"
                                                            />
                                                        </div>
                                                        {!!+values.bm_first_message_active &&
                                                            <div className="step-body mt-0">
                                                                <div className="animate-tutorialSlideAndFadeIn">
                                                                    <TextArea
                                                                        {...getFieldProps('bm_first_message')}
                                                                        name="bm_first_message"
                                                                        label="Wiadomość"
                                                                        rows={4}
                                                                        extraProps={{required: true}}
                                                                    />
                                                                    <div className="form-info-box w-100 mb-3">
                                                                        <div>
                                                                            <i className="icon-info-c"/>
                                                                        </div>
                                                                        <div>
                                                                            <div className="title">Wiadomość</div>
                                                                            <div className="desc">
                                                                                <div className="mb-1">
                                                                                    <div className="tag">#numer</div>
                                                                                    w tym miejscu pojawi się numer
                                                                                    zamówienia
                                                                                </div>
                                                                                <div>
                                                                                    <div className="tag">#zakup</div>
                                                                                    w tym miejscu pojawi się numer
                                                                                    zakupu w zamówieniu
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div>
                                                        <div className="step-header">
                                                            <SwitchField
                                                                {...getFieldProps('bm_second_message_active')}
                                                                label="Automatyczna wiadomość przypominająca o opłaceniu zamówienia"
                                                                subLabel="W przypadku nie otzymania wpłaty, klient otrzyma wiadomość o zaległej wpłacie po 3 dniach od złożenia zamówienia"
                                                                className="switch-form inline-label big-label light-version mb-0 mt-0 align-items-start"
                                                            />
                                                        </div>
                                                        <div className="step-body mt-0">
                                                            {!!+values.bm_second_message_active &&
                                                                <div className="animate-tutorialSlideAndFadeIn">
                                                                    <TextArea
                                                                        {...getFieldProps('bm_second_message')}
                                                                        name="bm_second_message"
                                                                        label="Wiadomość"
                                                                        rows={4}
                                                                        extraProps={{required: true}}
                                                                    />
                                                                    <div className="form-info-box w-100 mb-3">
                                                                        <div>
                                                                            <i className="icon-info-c"/>
                                                                        </div>
                                                                        <div>
                                                                            <div className="title">Wiadomość</div>
                                                                            <div className="desc">
                                                                                <div className="mb-1">
                                                                                    <div className="tag">#numer</div>
                                                                                    w tym miejscu pojawi się numer
                                                                                    zamówienia
                                                                                </div>
                                                                                <div>
                                                                                    <div className="tag">#zakup</div>
                                                                                    w tym miejscu pojawi się numer
                                                                                    zakupu w zamówieniu
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                            <div className="text-right">
                                                                <button
                                                                    type="submit"
                                                                    className={`button ml-auto primary mt-3 ${isWaiting ? 'loading-button' : ''}`}
                                                                >
                                                                    <Loader isLoading={isWaiting}/>
                                                                    {getLangText('saveButton')}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-5">
                            <div
                                className="default-shadow-box help-box bigger-y-padd payment-benefits-wrapper">
                                <h2 className="title mb-1">
                                    Potrzebujesz pomocy?
                                </h2>
                                <div className="help-box__description">
                                    Napisz do nas lub zadzwoń, aby uzyskać odpowiedzi na Twoje pytania
                                </div>
                                <div className="help-box__buttons">
                                    <a href="https://www.facebook.com/selmo.io/" target="_blank"
                                       className="button border-button">
                                        <i className="icon-messenger-2-f"></i>
                                        Napisz na Messengerze
                                    </a>
                                    <a className="button border-button mt-2" href="tel:123457755">
                                        Zadzwoń: +12 345 77 55
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Wrapper>
    );
};

export default AutomaticTransferConfiguration;
