import React from 'react';
import useLang from "../../../../../../src_shared/hooks/useLang";
import ActivateButton from "./ActivateButton";
import usePhotoPreview from "../../../../../../src_shared/components/ui/photoPreview/usePhotoPreview";
import VideoPreview from "../../../../../../src_shared/components/ui/photoPreview/VideoPreview";


const workScopes = [
    {icon: 'status.svg', label: 'changingOrderStatus'},
    {icon: 'open.svg', label: 'closingOpenPackageStrong'},
    {icon: 'waiting.svg', label: 'automaticReserveListStrong'},
    {icon: 'alert.svg', label: 'notificationsAboutNotCompletedOrders'},
    {icon: 'cancel.svg', label: 'cancelNotCompletedOrders'},
    {icon: 'restore.svg', label: 'restoreNotSoldProducts'},
]

const SelmoProLandingMrAutomateBox = ({videoSrc = null, imgSrc}) => {
    const {getLangText} = useLang();
    const {showPreviewModal, hidePreviewModal, openPreviewModal} = usePhotoPreview();

    const getBoundaries = (index) => {
        if (index === 0) {
            return 'bottom-[calc(100%+30px)] left-1/2 sm:-translate-x-1/2'
        }
        if (index === 1) {
            return 'bottom-[calc(100%-90px)] -right-4'
        }
        if (index === 2) {
            return 'top-[calc(100%-90px)] -right-4'
        }
        if (index === 3) {
            return 'top-[calc(100%-100px)] -left-6'
        }
        if (index === 4) {
            return 'bottom-[calc(100%-100px)] -left-6'
        }
        if (index === 5) {
            return 'top-[calc(100%-30px)] left-1/2 sm:-translate-x-1/2'
        }
    }

    return (
        <div className="sm:py-12 p-6">
            <div className="text-center mb-3 sm:px-[215px]">
                <img
                    style={{width: 'auto', height: 24}}
                    className="mb-2 mx-auto"
                    src="/assets/images/selmo-pro-logo.png"
                    alt="logo-selmo"
                />
                <div
                    className="text-[#fff] font-bold selmo-pro-landing-title text-[30px] leading-[34px] sm:text-[36px] sm:leading-[42px]"
                    dangerouslySetInnerHTML={{__html: getLangText('learnAboutScopeOfWorkMrAutomate')}}
                />
            </div>
            <div className="sm:h-[550px] flex items-center relative">
                <img
                    className="absolute sm:block hidden ml-3 left-0 -top-2 w-full h-full object-cover"
                    src={`/assets/images/multiLanguage/pl/selmoProLanding/lines-bg.svg`} alt=""/>
                <div className="sm:px-[215px] w-full">
                    <div className="sm:h-[300px] w-full relative">
                        <img
                            className="sm:h-[240px] h-[220px] sm:pt-[10px] mt-8 mx-auto" src="/assets/images/robo-landing.webp" alt=""/>
                        {workScopes.map((item, index) => (
                            <div
                                className={`sm:text-center sm:mb-0 mb-3.5 items-center flex sm:block static sm:absolute ${getBoundaries(index)}`}
                                key={item.label}
                            >
                                <img
                                    className="w-[32px] rounded-[7px] sm:mx-auto h-[32px] sm:mb-2 object-cover"
                                    src={`/assets/images/multiLanguage/pl/selmoProLanding/${item.icon}`} alt=""/>
                                <div
                                    className="text-sm sm:ml-0 ml-2.5 sm:whitespace-pre-wrap text-[#fff] font-normal"
                                    dangerouslySetInnerHTML={{__html: getLangText(item.label)}}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SelmoProLandingMrAutomateBox;
