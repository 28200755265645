import React, {useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import useLang from "../../../../../../src_shared/hooks/useLang";
import Loader from "../../../../messenger/loader/Loader";
import {parseParams} from "../../../../../../src_shared/list/ListActions";

const FACEBOOK_TYPE = 'fb'
const INSTAGRAM_TYPE = 'ig'

const Content = ({item, getLangText, isWaiting}) => (
    <>
        <div className="mr-2.5 min-w-[30px] sm:mr-6 sm:self-center self-start sm:mt-0 mt-0.5">
            <img className="w-[36px]" width={36} src={item.img} alt={item.title}/>
        </div>
        <div className="mr-2">
            <div className="font-bold text-[#1F2937]">{item.title}</div>
            <div className="text-xs text-[#9CA3AF] font-medium">{getLangText(item.desc)}</div>
        </div>
        <div className={`button primary small-size ml-auto py-2 px-3 ${isWaiting ? 'loading-button' : ''}`}>
            {isWaiting && <Loader/>}
            {getLangText('connectLabel')}
        </div>
    </>
)

const SocialButton = ({item, data, variant = 1}) => {
        const {getLangText} = useLang();
        const [isWaiting, setIsWaiting] = useState(false);
        const {search} = useLocation();
        const query = parseParams(search);

        const className = `flex w-full items-center text-left border border-[#E5E7EB] rounded-[8px] hover:no-underline hover:bg-[#F9FAFB] transition-all ${variant !== 1 ? 'shadow-button p-[16px] sm:px-5 sm:py-[20px]' : 'p-[16px] sm:p-6'}`

        if (!+query.connectAgain && ((data.facebookConnected && item.id === FACEBOOK_TYPE) || (data.instagramConnected && item.id === INSTAGRAM_TYPE))) {
            return (
                <Link
                    to={item.redirect}
                    className={className}
                    key={item.id}
                >
                    <Content
                        getLangText={getLangText}
                        item={item}
                        isWaiting={isWaiting}
                    />
                </Link>
            )
        }

        return (
            <a
                onClick={() => setIsWaiting(true)}
                rel="noreferrer"
                href={item.id === FACEBOOK_TYPE ? data.facebookUrl : data.instagramUrl}
                className={className}
                key={item.id}
            >
                <Content
                    getLangText={getLangText}
                    item={item}
                    isWaiting={isWaiting}
                />
            </a>
        );
    }
;

export default SocialButton;
