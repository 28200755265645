import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import useLang from "../../../../hooks/useLang";

const SingleSelectDefaultFilter = ({
									   getFiltersStateRoot,
									   options,
									   name,
									   getStateRoot,
									   FormActions,
								   }) => {

	const dispatch = useDispatch();
	const {getLangText} = useLang();

	const [selectedState, locallySelected] = useSelector(createSelector(getFiltersStateRoot, getStateRoot, (stateRoot, formStateRoot) => [
		stateRoot.data[name],
		formStateRoot.data[name],
	]));

	const setPackageStateFilter = (value) => dispatch(FormActions.data.setValue(name, value))

	useEffect(() => {
		setPackageStateFilter(selectedState);
	}, [selectedState]);


	const getCheckedValue = (status) => locallySelected === status;

	return (
		<div className="checkbox-filter-wrapper">
			{options.map((status) => (
				<div
					key={status.value}
					className="form-group checkbox-form"
				>
					<input
						className="checkbox-input form-control"
						type="checkbox"
						onChange={() => setPackageStateFilter(status.value)}
						checked={getCheckedValue(status.value)}
					/>
					<span className="checkbox-checkmark"/>
					<label className="control-label">{getLangText(status.label) || status.label}</label>
				</div>
			))}
		</div>
	);
};

SingleSelectDefaultFilter.defaultProps = {
	options: [],
}

export default SingleSelectDefaultFilter;
