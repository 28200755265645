import React, {useEffect} from 'react';
import {
	updateMessengerFromPusher
} from "../../messenger/chatList/ChatListActions";
import {useDispatch, useSelector} from "react-redux";
import Pusher from "pusher-js";
import CountDownNotificationActions from "../components/notification/CountDownNotificationActions";
import {PUSHER_NOTIFICATION_NEW_VERSION, PUSHER_SINGLE_NOTIFICATION} from "../enums/CountDownNotificationsTypes";
import LiveCommentsListActions from "../../live/details/default/comments/ListActions";
import NewLiveCommentsListActions from "../../live/details/default/newComments/ListActions";
import LivePlatformActiveListActions from "../../live/details/platform/activeList/ListActions";
import {updateLiveSendBatchFromPusher} from "../../live/details/default/LiveDetailsActions";
import SubmittedClientsListActions from "../../clients/lists/submittedClientsList/ListActions";
import ProductReserveListActions from "../../products/form/reserveList/ListActions";
import SessionActions from "../session/SessionActions";
import {setWaitingForSendAction} from "../../../../src_shared/components/ui/waitingForSend/WaitingForSendActions";
import {checkAndUpdateNotReadConversations} from "../aside/AsideActions";
import {filesDownloadFile, filesUpdateFilesFromPusher} from "../header/files/filesDropdown/FilesActions";
import {setGenerateLabelModalSuccess} from "../generateLabelModal/generateLabelModalActions";
import LivesOverlayFormActions from "../../liveOverlay/form/FormActions";
import LivesOverlayListActions from "../../liveOverlay/list/ListActions";
import SalesPostFormActions from "../../salesPost/form/FormActions";

const useMessageEvent = () => {
	const dispatch = useDispatch();
	const {userData} = useSelector((state) => state.session);
	const channelName = userData.id;

	const messageEvent = (channel) => {
		channel.bind('message', (data) => {
			dispatch(updateMessengerFromPusher(data));
		});
	}

	const newVersionEvent = (channel) => {
		channel.bind('new_version', (data) => {
			dispatch(CountDownNotificationActions.setNotificationVisibility(true, PUSHER_NOTIFICATION_NEW_VERSION, data));
		});
	}

	const singleNotificationEvent = (channel) => {
		channel.bind('single_notification', (data) => {
			dispatch(CountDownNotificationActions.setNotificationVisibility(true, PUSHER_SINGLE_NOTIFICATION, data));
		});
	}

	const liveCommentsEvent = (channel) => {
		channel.bind('live_comments', (data) => {
			dispatch(LiveCommentsListActions.updateLiveCommentsFromPusher(data))
		});
	}

	const liveCommentsRemoveEvent = (channel) => {
		channel.bind('live_comments_remove', (data) => {
			dispatch(NewLiveCommentsListActions.updateLiveCommentsFromPusher(data))
		});
	}

	const newPlatformOrders = (channel) => {
		channel.bind('new_orders', (data) => {
			dispatch(LivePlatformActiveListActions.updateFromPusher())
		});
	}

	const liveSendingEvent = (channel) => {
		channel.bind('live_send_batch', (data) => {
			dispatch(updateLiveSendBatchFromPusher(data))
		});
	}

	const clientsNotificationsSendingEvent = (channel) => {
		channel.bind('notifications_send_batch', () => {
			dispatch(SubmittedClientsListActions.updateSendBatchFromPusher())
		});
	}

	const reserveListSendBatchEvent = (channel) => {
		channel.bind('reserve_list_send_batch', () => {
			dispatch(ProductReserveListActions.updateSendBatchFromPusher())
		});
	}

	const updateConversationCount = (channel) => {
		channel.bind('conversations_count', (data) => {
			dispatch(checkAndUpdateNotReadConversations(data))
		});
	}

	const mobileNotificationsSendingEvent = (channel) => {
		channel.bind('mobile_notifications_send_batch', (data) => {
			dispatch(setWaitingForSendAction('waitingForSendMobileNotifications', false))
			dispatch(SalesPostFormActions.updateWaitingForSend(data))
		});
	}

	const updateSalesPostsImportEvent = (channel) => {
		channel.bind('sales_posts_import', () => {
			dispatch(setWaitingForSendAction('waitingForSalesPostsImport', false))
		});
	}

	const updateFirstPageView = (channel) => {
		channel.bind('update_first_page_view', (data) => {
			dispatch(SessionActions.updateUserData(data.name, false))
		});
	}

	const updateFileInDropdown = (channel) => {
		channel.bind('update_file', (data) => {
			dispatch(filesUpdateFilesFromPusher(data));
		});
	}

	const mobileAppVideoUploaded = (channel) => {
		channel.bind('mobile_app_video_uploaded', (data) => {
			dispatch(SalesPostFormActions.updateVideoStatus(data));
		});
	}

	const overlayUpdateData = (channel) => {
		channel.bind('overlay_data_update', (data) => {
			dispatch(LivesOverlayFormActions.updateData(data));
		});
	}

	const overlayUpdateList = (channel) => {
		channel.bind('overlay_new_live', (data) => {
			dispatch(LivesOverlayListActions.loadData());
		});
	}

	const updateGenerateLabelModalState = (channel) => {
		channel.bind('update_label_modal_state', () => {
			dispatch(setGenerateLabelModalSuccess(true));
		});
	}

	const exportPdf = (channel) => {
		channel.bind('export_pdf', (link) => {
			dispatch(filesDownloadFile(link));
		});
	}

	const updateGlobalAlert = (channel) => {
		channel.bind('global_alert', (data) => {
			dispatch(SessionActions.updateGlobalAlert(data))
		});
	}


	useEffect(() => {

		if (channelName) {
			const pusher = new Pusher('b4bb0f71c257b28c7212', {
				cluster: 'eu'
			});

			const channel = pusher.subscribe(`${channelName}`);
			const generalChannel = pusher.subscribe('GENERAL');

			messageEvent(channel)
			liveCommentsEvent(channel)
			liveCommentsRemoveEvent(channel)
			newPlatformOrders(channel)
			liveSendingEvent(channel)
			clientsNotificationsSendingEvent(channel)
			reserveListSendBatchEvent(channel)
			singleNotificationEvent(channel)
			updateFirstPageView(channel)
			mobileNotificationsSendingEvent(channel)
			updateConversationCount(channel)
			updateFileInDropdown(channel)
			exportPdf(channel)
			updateGenerateLabelModalState(channel)
			mobileAppVideoUploaded(channel)
			overlayUpdateData(channel)
			overlayUpdateList(channel)
			updateSalesPostsImportEvent(channel)

			newVersionEvent(generalChannel);
			updateGlobalAlert(generalChannel);


			return () => {
				pusher.unsubscribe(`${channelName}`)
				channel.unbind();
				pusher.disconnect();
			}
		}

	}, [channelName])
};

export default useMessageEvent;
