import ListReducer from "../../../../../src_shared/list/ListReducer";
import Composition from "../../../../app/shared/helpers/Composition";
import createReducer from "../../../../app/shared/helpers/createReducer";

export const getStateRoot = (state) => state.settings.team.stats.page;
export const prefix = 'SETTINGS_TEAM_STATS_PAGE_';

const getInitState = () => ListReducer.getInitState({
	items: {
		teamActivities: [],
		dailyActivities: [],
		activities: [],
	},
});

const getReduceMap = () =>  new Composition(
	ListReducer.getReduceMap(),
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

