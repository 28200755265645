import React, {useEffect} from 'react';
import useDataApi from "../../../../src_shared/hooks/useDataApi";
import {services} from "../../../RestServices";
import {Link, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import KnowledgeBaseAsideActions from "../aside/ListActions";
import {createSelector} from "reselect";
import {getStateRoot} from "../aside/listReducer";
import moment from "moment";
import Loader from "../../shared/Loader";
import {RouterPaths} from "../../../routes/RouterPath";
import useLang from "../../../../src_shared/hooks/useLang";
import {convertToUserTimeZone} from "../../shared/helpers/dateHelpers";

const KnowledgeBaseDetail = () => {

	const {nameUrl} = useParams();
	const {getLangText} = useLang();
	const dispatch = useDispatch();
	const {items} = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));

	const [{data, isLoading}] = useDataApi(`${services.API_KNOWLEDGE_BASE_ARTICLE}?name_url=${nameUrl}`, {}, [nameUrl])

	useEffect(() => {
		const findActiveCategory = items.find((i) => i.id === data.category_id)
		if (data.category_id && findActiveCategory) {
			dispatch(KnowledgeBaseAsideActions.getArticleByCategory(findActiveCategory))
		}
	}, [data.category_id])

	return (
		<main>
			<div className={`post-container loader-parent ${isLoading ? 'active-loader-parent' : ''}`}>
				<Loader isLoading={isLoading}/>
				<Link to={RouterPaths.KnowledgeBase} className="go-back">
					<i className="icon-arrow3" />
					{getLangText('backButton')}
				</Link>
				<h1>{data.title}</h1>
				<div className="date">{convertToUserTimeZone(data.date).format('DD MMMM YYYY')}</div>
				<div dangerouslySetInnerHTML={{__html: data.description}}/>
			</div>
		</main>
	);
};

export default KnowledgeBaseDetail;
