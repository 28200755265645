import React, {useCallback, useRef} from 'react';
import {batch, useDispatch, useSelector} from "react-redux";
import {updateFieldInLiveDetails, updateFieldInProduct} from "../../LiveDetailsActions.js";
import NumberInput from "../../../../../shared/components/form/NumberInput.jsx";
import axios from "axios";
import {selmoUrl} from "../../../../../../../src_shared/api/api.js";
import {services} from "../../../../../../RestServices.js";
import useOutsideClick from "../../../../../messenger/hooks/useOutsideClick/useOutsideClick.js";
import debounce from "lodash.debounce";
import DefinedVariantModalActions from "../../definedVariantsModal/FormActions.js";
import {
	EMILY_SHOP_ID,
	KOKARDECZKA_SHOP_ID, MERCERIA_SHOP_ID,
	PRZEMO_SHOP_ID, TEST_ACCOUNT_SHOP_ID,
} from "../../../../../../../src_shared/enums/TrustedShopsIds";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import {useUnitPrice} from "../../../../../shared/helpers/Price";
import api from "../../../../../../../services/axios/axios";

const DefaultProductRow = () => {
	const dispatch = useDispatch();
	const {userData} = useSelector((state) => state.session);
	const {product, liveInfo, historyProductHints} = useSelector((state) => state.lives.details)
	const productHintsRef = useRef();
	const {getLangText} = useLang();
	const {getPrice} = useUnitPrice();

	const getProductsHints = useCallback(
		debounce(async (value) => {
			if ((+userData.id === KOKARDECZKA_SHOP_ID || +userData.id === MERCERIA_SHOP_ID || +userData.id === EMILY_SHOP_ID || +userData.id === PRZEMO_SHOP_ID || +userData.id === TEST_ACCOUNT_SHOP_ID) && value) {
				try {
					const {data} = await api.get(`${selmoUrl}/${services.API_LIVE_PRODUCT_HISTORY}?live_id=${liveInfo.id}&superSearch=${value}`)
					dispatch(updateFieldInLiveDetails('historyProductHints', data.items));
				} catch (e) {
					console.warn('Cannot get product hints')
				}
			}
		}, 500), [userData.id]);

	const onProductNameChange = (e) => {
		getProductsHints(e.target.value)
		batch(() => {
			dispatch(updateFieldInProduct('name', e.target.value))
			dispatch(updateFieldInProduct('hasError', false))
		});
	}

	const onProductPriceChange = (e) => {
		dispatch(updateFieldInProduct('price', e.target.value))
		dispatch(updateFieldInProduct('hasPriceError', false))
	}

	const onHintSelect = (item) => {

		const sizes = JSON.parse(item.sizes);
		const colors = JSON.parse(item.colors);

		batch(() => {
			dispatch(updateFieldInProduct('name', item.name))
			dispatch(updateFieldInProduct('code', item.code))
			dispatch(updateFieldInProduct('price', item.price))
			dispatch(DefinedVariantModalActions.data.updateValues({
				sizes,
				colors,
			}))
		})
		dispatch(updateFieldInLiveDetails('historyProductHints', []));
	}

	useOutsideClick(productHintsRef, () => {
		if (historyProductHints.length) {
			dispatch(updateFieldInLiveDetails('historyProductHints', []));
		}
	});

	return (
		<>
			<div className="product-input-col">
				<div
					ref={productHintsRef}
					className={`form-group ${product.hasError && !product.name ? 'error-group' : ''}`}
				>
					<label className="control-label">{getLangText('nameLabel')}</label>
					<input
						onChange={onProductNameChange}
						type="text"
						autoFocus
						value={product.name}
						className="form-control"
						name="product_name"
						required
						tabIndex="1"
					/>
					<div className="custom-dropdown">
						{!!historyProductHints.length && historyProductHints.map((item) => (
							<div
								onClick={() => onHintSelect(item)}
								className="select-custom-option"
								key={item.id}
							>
								<div className="name">
									{item.name}
								</div>
								<div className={'bottom-bar'}>
									{item.code && (<div>{getLangText('codeLabel')}: {item.code}</div>)}<div>{getPrice(item.price)}</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
			<div>
				<div className="form-group">
					<label className="control-label">{getLangText('barCodeLabel')}</label>
					<input
						onChange={(e) => dispatch(updateFieldInProduct('code', e.target.value))}
						type="text"
						value={product.code}
						className="form-control"
						name="code"
						required
						tabIndex="1"
					/>
				</div>
			</div>
			<div>
				<div className={`form-group ${product.hasPriceError ? 'error-group' : ''}`}>
					<label className="control-label">{getLangText('priceLabel')}</label>
					<NumberInput
						onChange={onProductPriceChange}
						name="price"
						value={product.price}
						min={0}
					/>
				</div>
			</div>
		</>
	);
};

export default DefaultProductRow;
