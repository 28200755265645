import React from 'react';
import {DateRange, DefinedRange} from 'react-date-range';
import * as rdrLocales from 'react-date-range/dist/locale';
import {
	addDays,
	endOfDay,
	startOfDay,
	startOfWeek,
	endOfWeek,
} from 'date-fns';
import {convertTimestampToDate, isTodayDay} from "../../helpers/dateHelpers";
import {
	ALL_ORDERS_VIEW, INTERVAL,
} from '../../enums/OrderViewTypes';
import useDaterangePicker from "../../hooks/useDaterangePicker";
import useWindowDimensions from "../../../messenger/hooks/useWindowDimensions/useWindowDimensions";
import useLang from "../../../../../src_shared/hooks/useLang";
import {useSelector} from "react-redux";

const DateRangePicker = ({actions, getFiltersStateRoot, FiltersActions, className, extraProps = {}, loadData = null}) => {
	const {
		buttonRef,
		dropdownRef,
		onCloseClick,
		open,
		setOpen,
		fromDate,
		toDate,
		selectionRange,
		handleDateSelect,
		saveDateRangePickerFilters,
		viewType,
		renderStaticRangeLabel
	} = useDaterangePicker(actions, getFiltersStateRoot, FiltersActions, loadData);

	const {getLangText} = useLang();
	const {lang} = useSelector((state) => state.i18n);

	const defineds = {
		startOfWeek: startOfWeek(new Date()),
		endOfWeek: endOfWeek(new Date()),
		startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
		endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
		startOfToday: startOfDay(new Date()),
		startOfLastSevenDay: startOfDay(addDays(new Date(), -7)),
		startOfLastThirtyDay: startOfDay(addDays(new Date(), -30)),
		endOfToday: endOfDay(new Date()),
	};

	const {width} = useWindowDimensions();

	const onSaveClick = () => {
		saveDateRangePickerFilters(extraProps.id);
		setOpen(false);
	};

	const dateInput = () => {
		const convertedFromDate = convertTimestampToDate(fromDate, 'DD MMM', lang);
		const convertedToDate = convertTimestampToDate(toDate, 'DD MMM', lang);
		const FromDateContent = () => {
			if (isTodayDay(fromDate)) {
				return (
					<span>
						{getLangText('todayLabel')}
						{' '}
						<span className="small-date">
                            (
							{convertedFromDate}
							)
                        </span>
                    </span>
				);
			}
			return (
				<span>
					{convertedFromDate}
                </span>
			);
		};

		if (viewType === ALL_ORDERS_VIEW) {
			return (
				<span>
					{getLangText('allLabel')}
				</span>
			);
		}

		if (convertedToDate === convertedFromDate) {
			return <FromDateContent/>;
		}
		return <span>{`${convertedFromDate} - ${convertedToDate}`}</span>;
	};

	return (
		<div
			className={`daterange-wrapper ${className}`}
		>
			<button
				ref={buttonRef}
				type="button"
				onClick={() => setOpen(!open)}
				className="date-input"
			>
				{dateInput()}
				<i className="icon-calendar"/>
			</button>
			{open
			&& (
				<div className="daterange-absolute-place">
					<div
						ref={dropdownRef}
						className="daterange-absolute-wrapper"
					>
						<div className="calendar-row">
							<DefinedRange
								onChange={handleDateSelect}
								ranges={[selectionRange]}
								inputRanges={[]}
								renderStaticRangeLabel={(props) => renderStaticRangeLabel(props, extraProps.id)}
								staticRanges={[
									{
										label: getLangText('allLabel'),
										value: ALL_ORDERS_VIEW,
										hasCustomRendering: true,
										range: () => ({
											startDate: defineds.startOfToday,
											endDate: defineds.endOfToday,
										}),
										isSelected() {
											return false;
										},
									},
									{
										hasCustomRendering: true,
										label: getLangText('todayLabel'),
										value: INTERVAL,
										range: () => ({
											startDate: defineds.startOfToday,
											endDate: defineds.endOfToday,
										}),
										isSelected() {
											return false;
										},
									},
									{
										hasCustomRendering: true,
										value: INTERVAL,
										label: getLangText('lastSevenDaysLabel'),
										range: () => ({
											startDate: defineds.startOfLastSevenDay,
											endDate: defineds.endOfToday,
										}),
										isSelected() {
											return false;
										},
									},
									{
										hasCustomRendering: true,
										value: INTERVAL,
										label: getLangText('lastThirtyDaysLabel'),
										range: () => ({
											startDate: defineds.startOfLastThirtyDay,
											endDate: defineds.endOfToday,
										}),
										isSelected() {
											return false;
										},
									},
								]}
							/>
							<DateRange
								onChange={handleDateSelect}
								ranges={[selectionRange]}
								disabled
								weekStartsOn={1}
								showDateDisplay={false}
								locale={rdrLocales[lang]}
								months={width > 766 ? 2 : 1}
								direction="horizontal"
								{...extraProps}
							/>
						</div>
						<div className="bottom-buttons ml-0">
							<button
								className="button primary"
								type="button"
								onClick={onSaveClick}
							>
								{getLangText('confirmLabel')}
							</button>
							<button
								onClick={onCloseClick}
								className="button border-button"
								type="button"
							>
								{getLangText('cancelButton')}
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default DateRangePicker;
