import {getStateRoot, prefix} from "./formReducer";
import {services} from "../../../../RestServices";
import FormActions from "../../../../../src_shared/form/FormActions";
import NoteModel from "../../../../../modules/models/order/NoteModel";
import {batch} from "react-redux";
import {getStateRoot as getEditProductStateRoot} from "../form/formReducer";
import EditProductFormActions from "../form/FormActions";

class OrderNoteFormActions extends FormActions {
	setTagsInputVisible(toggle) {
		return {
			type: `${this.prefix}SET_TAGS_INPUT_VISIBLE`,
			toggle,

		}
	}

	afterSubmit(id, data) {
		return (dispatch, getState) => {
			const {data} = getEditProductStateRoot(getState());
			batch(() => {
				dispatch(EditProductFormActions.loadData(data.shop_order_id))
				dispatch(this.setTagsInputVisible(false))
			})
		};
	}
}

export const getInstance = () => new OrderNoteFormActions({
	getStateRoot,
	prefix,
	model: NoteModel,
	restService: services.API_ORDER_DETAILS,
});

export default getInstance();
