import React, {useEffect, useState} from 'react';
import CreateShopLayout from "../shared/CreateShopLayout";
import Field from "../../../../src_shared/components/form/field/Field";
import useFormLocal from "../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../RestServices";
import useLang from "../../../../src_shared/hooks/useLang";
import Button from "../../../../src_shared/components/ui/Button/Button";
import {useHistory, useLocation} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import {Helmet} from "react-helmet";
import {usePostHog} from "posthog-js/react";
import {parseParams} from "../../../../src_shared/list/ListActions";
import CreateShopModel from "../../../../modules/models/onboarding/CreateShopModel";
import AccountType from "./components/AccountType";
import {useSelector} from "react-redux";
import PhoneInput from "./components/PhoneInput";

export const ONBOARDING_CREATE_SHOP_VIEW = 'CREATE_SHOP_VIEW';
export const ONBOARDING_ACCOUNT_TYPE_VIEW = 'ACCOUNT_TYPE_VIEW';
export const ONBOARDING_CUSTOMER_TYPE_VIEW = 'CUSTOMER_TYPE_VIEW';

const CreateShopFirstStep = () => {

    const {getLangText} = useLang();
    const {lang} = useSelector((state) => state.i18n);
    const history = useHistory();
    const posthog = usePostHog();
    const {search} = useLocation();
    const query = parseParams(search);
    const [activeEmailField, setActiveEmailField] = useState(false);
    const [activeView, setActiveView] = useState(lang === 'pl' ? ONBOARDING_ACCOUNT_TYPE_VIEW : ONBOARDING_CREATE_SHOP_VIEW)

    const afterSubmit = (response) => {
        if (+response.fromInstagram === 2) {
            history.push(RouterPaths.TutorialSocials)
        } else {
            history.push(RouterPaths.TutorialDetails)
        }
    };

    const [{onSave, isWaiting, getFieldProps, setValue, values}] = useFormLocal({
        rest: services.API_TUTORIAL_SHOP,
        model: CreateShopModel,
        initialAutoLoad: false,
        afterSubmit,
    });

    useEffect(() => {
        if (posthog) {
            posthog?.capture('ONBOARDING - First step visit');
        }
    }, [posthog])

    useEffect(() => {
        if (!!+query.emailNotExist) {
            setActiveEmailField(true);
            history.replace({
                search: '?emailNotExist=1'
            })
        }
    }, [query.emailNotExist])

    useEffect(() => {
        if (!!+query.fromInstagram) {
            setActiveView(ONBOARDING_CREATE_SHOP_VIEW);
        }
    }, [query.fromInstagram])

    useEffect(() => {
        setValue('lang', lang);
    }, [lang])

    return (
        <>
            {activeView === ONBOARDING_CREATE_SHOP_VIEW &&
                <CreateShopLayout
                    title="createYourShopLabel"
                    desc="addBasicInformationLabel"
                >
                    <Helmet>
                        <meta charSet="utf-8"/>
                        <title>{getLangText('createYourShopLabel')} - Selmo</title>
                    </Helmet>
                    <form
                        id="onboarding-first-step-form"
                        className="w-full grow flex flex-col"
                        onSubmit={onSave}
                    >
                        <div
                            className={`max-w-[380px] mx-auto w-full animate-tutorialSlideAndFadeInDelay2 opacity-0 ${activeEmailField ? 'pb-16' : ''}`}>
                            <Field
                                {...getFieldProps('name')}
                                label={getLangText('shopNameOrProfile')}
                                placeholder={getLangText('typeName')}
                                className="big-size"
                                extraProps={{required: true, autoFocus: true}}
                            />
                            {activeEmailField &&
                                <Field
                                    {...getFieldProps('email')}
                                    label={getLangText('emailAddressLabel')}
                                    placeholder={getLangText('enterAddressEmailLabel')}
                                    className="big-size mt-8"
                                    type="email"
                                    extraProps={{required: true}}
                                />
                            }
                            <PhoneInput
                                getFieldProps={getFieldProps}
                                lang={lang}
                                setValue={setValue}
                                values={values}
                            />
                        </div>
                        <div
                            className="sm:mt-auto mt-8 sm:mx-0 -mx-6 bg-[#F9FAFB] sm:w-full p-[20px] rounded-b-[12px] text-right">
                            {lang === 'pl' &&
                                <button
                                    type="button"
                                    onClick={() => setActiveView(ONBOARDING_ACCOUNT_TYPE_VIEW)}
                                    className="text-sm text-[#9CA3AF] mr-4 font-bold transition-all hover:text-[#575d68]"
                                >
                                    {getLangText('backButton')}
                                </button>
                            }
                            <Button
                                isWaiting={isWaiting}
                                type="submit"
                                title="goNextLabel"
                                className="bg-[#3C61EA] overflow-hidden  button text-xs sm:text-sm font-semibold rounded-[8px] px-[20px] py-[11px] text-white transition-all hover:bg-[#1c4ed8]"
                            />
                        </div>
                    </form>
                </CreateShopLayout>
            }
            {activeView === ONBOARDING_ACCOUNT_TYPE_VIEW &&
                <AccountType
                    activeView={activeView}
                    setActiveView={setActiveView}
                />
            }
        </>
    );
};

export default CreateShopFirstStep;
