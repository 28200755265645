import React from 'react';
import useLang from "../../../hooks/useLang";
import {usePostHog} from "posthog-js/react";
import Iframe from 'react-iframe-click';
import TutorialsVideo from "../../../../components/app/shared/enums/TutorialsVideo";
import {useSelector} from "react-redux";

const FirstStepPage = ({
                           title,
                           shortDesc,
                           icon,
                           fullDesc,
                           headerButtonTitle,
                           mainButtonTitle,
                           buttonClick,
                           benefits = [],
                           videoStatsName,
                           smallMainButton,
                       }) => {
    const {getLangText} = useLang();
    const posthog = usePostHog();
    const {lang} = useSelector((state) => state.i18n);

    const getVideoSource = () => {
        const matched = TutorialsVideo.find((i) => i.id === videoStatsName);

        return matched[`src_${lang}`] || null;
    }
    const setPosthogEvent = () => {
        if (posthog && videoStatsName) {
            posthog?.capture('TUTORIAL VIDEO', {type: videoStatsName})
        }
    };

    return (
        <div className="md:space-y-4">
            <div className="md:block hidden">
                <div className="default-shadow-box bigger-y-padd sm:h-[90px]">
                    <div className="flex items-center">
                        <div>
                            <div className="title mb-0">
                                {getLangText(title)}
                            </div>
                            <div className="text-desc text-sm font-medium mt-1">
                                {getLangText(shortDesc)}
                            </div>
                        </div>
                        <button
                            type="submit"
                            className="button primary small-size sm:ml-auto sm:w-auto w-full"
                            onClick={buttonClick}
                        >
                            {getLangText(headerButtonTitle)}
                        </button>
                    </div>
                </div>
            </div>
            <div className="default-shadow-box bigger-y-padd">
                <div className="md:grid grid-cols-5 gap-10 md:px-20 md:py-20">
                    <div className="md:col-span-2 sm:mb-0 mb-4">
                        <img className="sm:w-[44px] mb-3" src={`/assets/images/svg/${icon}.svg`} alt="icon"/>
                        <div className="sm:text-3xl text-2xl font-bold">
                            {getLangText(title)}
                        </div>
                        <div className="text-sm text-desc font-medium mt-3.5">
                            {getLangText(fullDesc)}
                        </div>
                        {mainButtonTitle &&
                            <button
                                type="submit"
                                style={{fontSize: smallMainButton ? 14 : 16}}
                                className="button primary medium-size mt-4 w-full"
                                onClick={buttonClick}
                            >
                                {getLangText(mainButtonTitle)}
                            </button>
                        }
                    </div>
                    <div className="col-span-3 md:pl-20">
                        <div className="bg-[#F6FCFF] rounded-xl border border-[rgba(95,165,250,0.2)] p-4">
                            {!!getVideoSource() &&
                                <div className="mb-3">
                                    <div className="font-bold">{getLangText('howItWorksAndWatchVideoLabel')}</div>
                                    <div className="selmo-video-wrapper mt-3">
                                        <Iframe
                                            autoPlay={true}
                                            frameBorder="0" allowFullScreen="" scrolling="no"
                                            onInferredClick={setPosthogEvent}
                                            allow="autoplay;fullscreen"
                                            src={getVideoSource()}
                                            style={{
                                                position: 'absolute',
                                                height: '100%',
                                                width: '100%',
                                                left: '0px',
                                                top: '0px'
                                            }}
                                        />
                                    </div>
                                </div>
                            }
                            <div className="relative">
                            <span
                                className="text-[#4B5563] font-semibold relative z-10 text-sm bg-[#F6FCFF] pr-2">{getLangText('knowTheBenefitsLabel')}</span>
                                <span
                                    className="after:content-[''] after:bg-[#D7EAFE] after:absolute after:top-1/2 after:left-0 after:h-[1px] after:-translate-Y-1/2 after:w-full"/>
                            </div>
                            <div className="space-y-4 mt-3 mb-4">
                                {benefits.map((i) => (
                                    <div
                                        key={i.id}
                                        className="flex items-start"
                                    >
                                        <div className="mr-2 -mt-1">
                                            <i className="icon-tick-c text-[#5FA5FA] text-lg"/>
                                        </div>
                                        <div>
                                            <div
                                                className="text-[#101827] font-bold text-sm">{getLangText(i.title)}</div>
                                            <div className="text-desc text-sm font-medium">{getLangText(i.desc)}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
        ;
};

export default FirstStepPage;
