import React, {useCallback, useEffect, useState} from 'react';
import axios from "axios";
import {selmoUrl} from "../../../api/api";
import {showAlert} from "../../../../components/app/shared/components/alert/AlertActions";
import {useDispatch} from "react-redux";
import useLang from "../../../hooks/useLang";
import PhotosGallery from "./PhotosGallery";
import CheckboxField from "../checkboxField/CheckboxField";
import Loader from "../../loader/Loader";
import api from "../../../../services/axios/axios";

const ImageUploadGallery = ({
								values,
								imagePath,
								restService,
								FormActions,
								updateAllItemsOnServer,
								setSelectedGalleryPhoto,
								selectedPhotos,
								setSelectedGalleryPhotos,
							}) => {
	const dispatch = useDispatch();

	const [modalVisible, setModalVisible] = useState(false);
	const [isWaiting, setIsWaiting] = useState(false);

	const {getLangText} = useLang();

	const loadData = () => dispatch(FormActions.loadData(values.id));

	const uploadFileHandler = async (event) => {
		const files = Object.values(event.target.files);
		if (!!files?.length) {
			await onSubmit(files);
		}
	};

	const onSubmit = async (files) => {
		setIsWaiting(true)

		const formData = new FormData();
		values?.photos?.forEach((i) => {
			formData.append('photo[]', i);
		})
		files.forEach((i) => {
			formData.append('photo[]', i);
		})
		formData.append('path', imagePath);

		try {
			if (!!+values.id) {
				await api.post(`${selmoUrl}/${restService}/${values.id}`, formData)
				// setLocalPhotos([...localPhotos, ...files])
				loadData()
			} else {
				await api.post(`${selmoUrl}/${restService}`, formData)
			}
			dispatch(showAlert('imageSaveSuccessLabel'))

		} catch (e) {
			dispatch(showAlert('sthWentWrongWarning', 'maxImageSizeLabel', 'alert-fail'))
			console.error('Error submitEditForm')
		} finally {
			setIsWaiting(false)
		}
	}

	return (
		<div className="gallery-upload-wrapper mt-3">
			<div className="gallery-upload loader-parent" style={{minHeight: 'auto'}}>
				<Loader isLoading={isWaiting} className="small-loader" />
				<button
					type="button"
					className="upload-btn"
				>
					<input
						type="file"
						onChange={uploadFileHandler}
						className="hidden-file-input"
						multiple
					/>
					{getLangText('addPhotosButton')}
				</button>
				{!!values.photos?.length &&
					<PhotosGallery
						setModalVisible={() => setModalVisible(true)}
						hideModal={() => setModalVisible(false)}
						modalVisible={modalVisible}
						setSelectedGalleryPhoto={setSelectedGalleryPhoto}
						selectedPhotos={selectedPhotos}
						imagePath={imagePath}
						photos={values.photos}
						updateAllItemsOnServer={updateAllItemsOnServer}
						loadData={loadData}
						setSelectedGalleryPhotos={setSelectedGalleryPhotos}
					/>
				}
			</div>
		</div>
	);
};

ImageUploadGallery.defaultProps = {
	defaultImage: 'product.svg',
	imagePath: 'products',
	restService: 'api/mediaBulk',
	circleImage: false,
	updateAllItemsOnServer: (items) => {
	}
}

export default ImageUploadGallery;
