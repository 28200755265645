import React, {memo, useEffect, useState} from 'react';
import Loader from "../../../shared/Loader";
import ReactPlayer from "react-player";
import useLang from "../../../../../src_shared/hooks/useLang";

const ReactPlayerVideo = ({url}) => {
	const {getLangText} = useLang();
	const [waitingForVideo, setWaitingForVideo] = useState(true);
	const [errorVideo, setErrorVideo] = useState(false);

	const onError = () => {
		setTimeout(() => {
			setErrorVideo(true)
			setWaitingForVideo(false)
		}, 700)
	}

	useEffect(() => {
		if (url) {
			setWaitingForVideo(true);
			setErrorVideo(false)
		}
	}, [url])

	return (
		url &&
		<div className="mb-3">
			<div className="form-label">
				{getLangText('previewLabel')}
			</div>
			<div>
				{errorVideo ?
					<div className="form-info-box red mb-3">
						<div>
							<i className="icon-info-c"/>
						</div>
						<div className="title extra-small-text">
							{getLangText('incorrectUrlLabel')}
						</div>
					</div> :
					<div className='player-wrapper'>
						<Loader className="small-loader" isLoading={waitingForVideo}/>
						<ReactPlayer
							className='react-player'
							url={url}
							width='100%'
							height='100%'
							onReady={() => setTimeout(() => setWaitingForVideo(false), 700)}
							onError={onError}
						/>
					</div>
				}
			</div>
		</div>
	);
};

export default memo(ReactPlayerVideo);
