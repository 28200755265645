const initialState = {
    isOpen: false,
   	images: [],
	startIndex: 0,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'GALLERY_MODAL_TOGGLE_VISIBILITY':
            return {...state, isOpen: action.toggle};
        case 'MESSENGER_GALLERY_SET_IMAGES':
            return {...state, images: action.images, startIndex: action.startIndex};
        default:
            return state;
    }
};
