import React from 'react';
import {useSelector} from "react-redux";

const GlobalAlert = () => {
    const {userData} = useSelector((state) => state.session);
    const {lang} = useSelector((state) => state.i18n);

    if (!+userData.globalAlert?.active) {
        return null
    }

    if (!userData.globalAlert[`message_${lang}`]) {
        return null
    }

    return (
        <div
            className="bg-[#db0000] text-[white] p-3 text-center font-medium text-sm"
            dangerouslySetInnerHTML={{__html: userData.globalAlert[`message_${lang}`]}}
        />
    );
};

export default GlobalAlert;
