import React, {useState} from 'react';
import useLang from "../../../../../../src_shared/hooks/useLang";
import SwitchField from "../../../../../../src_shared/components/form/switchField/SwitchField";
import TimePicker from "react-time-picker";
import {useSelector} from "react-redux";
import DatePicker from "react-datepicker";
import DescriptionField from "./DescriptionField";
import useSubmitData from "../../../../../../src_shared/hooks/useSubmitData";
import ConfirmModal from "../../../../orders/list/components/export/ConfirmModal";
import {services} from "../../../../../RestServices";
import {convertToUserTimeZone} from "../../../../shared/helpers/dateHelpers";
import {prepareRealLang} from "../../../../../../services/i18n/i18nAPI";

const PublishOnFacebook = ({isActive, getFieldProps, setValue, values, loadData, updateValues}) => {
    const {getLangText} = useLang();
    const {lang} = useSelector((state) => state.i18n);
    const [showModal, setShowModal] = useState(false);

    const [{onSave: cancelPost, isWaiting: waitingForCancel}] = useSubmitData(
        {
            rest: `${services.API_VIDEO_CANCEL_POST}/${values.id}`,
            afterSubmit: () => loadData(values.id)
        });

    if (values.refresh_token_link) return (
        <section className="bg-[#fff] rounded-[5px] mt-3 p-[25px] tooltip-parent">
            {!isActive &&
                <div className="tooltip-content small-size leading-[16px] text-center">
                    {getLangText('assignProductToGoNext')}
                </div>
            }
            <div
                onClick={() => isActive && setValue('facebook_active', !+values.facebook_active)}
                className={`flex items-center ${(isActive && !values.facebook_published_date) ? 'cursor-pointer' : 'cursor-not-allowed'}`}
            >
                <div>
                    <img
                        className={`w-[24px] h-[24px] min-w-[24px] mr-2 ${isActive ? '' : 'grayscale opacity-40'}`}
                        src="/assets/images/svg/fb-circle-blue.svg"
                        alt="selmo"
                    />
                </div>
                <div className={`font-bold text-lg flex flex-wrap items-center ${isActive ? '' : 'text-[#9CA3AF]'}`}>
                    {getLangText(!!values.facebook_published_date ? 'publishedOnFacebook' : 'publishOnFacebook')}
                </div>
                {(!values.facebook_published_date && !values.facebook_publishing) &&
                    <SwitchField
                        {...getFieldProps('facebook_active')}
                        name="facebook_active"
                        className="switch-form ml-auto mb-0"
                        setValue={setValue}
                        showLabel={false}
                        disabled={!isActive}
                    />
                }
            </div>
            {(!!+values.facebook_active && isActive) &&
                <div className="animate-[slideAndShow_.3s_ease_forwards] mt-3">
                    <a
                        href={values.refresh_token_link}
                        className="bg-[#F6FCFF] rounded-xl border border-[rgba(95,165,250,0.2)] p-3 mt-3 block hover:no-underline hover:text-[#000]"
                    >
                        <div className="font-bold">{getLangText('addNewPermissions')}</div>
                        <div className="text-sm text-desc">
                            {getLangText('facebookRequireNewPermissions')}
                        </div>
                        <div
                            style={{
                                fontSize: 14,
                                paddingTop: 16,
                                paddingBottom: 16,
                                borderRadius: 8,
                            }}
                            className="button primary small-size mt-3 text-center w-full hover:text-[#fff]"
                        >
                            {getLangText('addPermissions')}
                        </div>
                    </a>
                </div>
            }
        </section>
    )

    return (
        <section className="bg-[#fff] rounded-[5px] mt-3 p-[25px] tooltip-parent">
            {!isActive &&
                <div className="tooltip-content small-size leading-[16px] text-center">
                    {getLangText('assignProductToGoNext')}
                </div>
            }
            <div
                onClick={() => isActive && setValue('facebook_active', !+values.facebook_active)}
                className={`flex items-center ${(isActive && !values.facebook_published_date) ? 'cursor-pointer' : 'cursor-not-allowed'}`}
            >
                <div>
                    <img
                        className={`w-[24px] h-[24px] min-w-[24px] mr-2 ${isActive ? '' : 'grayscale opacity-40'}`}
                        src="/assets/images/svg/fb-circle-blue.svg"
                        alt="selmo"
                    />
                </div>
                <div className={`font-bold text-lg flex flex-wrap items-center ${isActive ? '' : 'text-[#9CA3AF]'}`}>
                    {getLangText(!!values.facebook_published_date ? 'publishedOnFacebook' : 'publishOnFacebook')}
                </div>
                {(!values.facebook_published_date && !values.facebook_publishing) &&
                    <SwitchField
                        {...getFieldProps('facebook_active')}
                        name="facebook_active"
                        className="switch-form ml-auto mb-0"
                        setValue={setValue}
                        showLabel={false}
                        disabled={!isActive}
                    />
                }
            </div>
            {!!values.facebook_published_date ?
                <div className="space-y-2 mt-3">
                    <div className="form-info-box green w-100 small-line-height">
                        <div>
                            <i className="icon-tick-c"/>
                        </div>
                        <div>
                            <div className="title">
                                <span className="text-sm"> {getLangText('successfullyPublishedPost')}</span>
                            </div>
                        </div>
                    </div>
                    <div className="bg-[#F9FAFB] rounded-[7px] text-sm p-3 py-2">
                        <div className="font-bold">{getLangText('publishDate')}</div>
                        <div
                            className="text-desc font-medium">{convertToUserTimeZone(values.facebook_published_date).format('YYYY-MM-DD HH:mm')}</div>
                    </div>
                    <div className="bg-[#F9FAFB] rounded-[7px] text-sm p-3 py-2">
                        <div className="font-bold">{getLangText('postLink')}</div>
                        <a
                            target="_blank"
                            href={values.facebook_post_url}
                            className="text-desc font-medium hover:text-[#000] underline"
                        >
                            {values.facebook_post_url}
                        </a>
                    </div>
                </div> :
                values.facebook_publishing ?
                    <div className="p-3 rounded-[7px] bg-[#FFF7F1] flex mt-3">
                        <div className="mr-2 mt-1">
                            <img className="min-w-[18px] h-[18px]" src="/assets/images/svg/time-orange.svg"
                                 alt="time"/>
                        </div>
                        <div>
                            <div
                                className="font-bold text-sm text-[#994700]">{getLangText('postBeingPublished')}</div>
                            <div className="text-xs font-medium text-[#C8640F]">
                                {getLangText('willBePublishedInFewMinutes')}
                            </div>
                        </div>
                        <button
                            onClick={() => setShowModal(true)}
                            className="self-center ml-auto button border-button">
                            {getLangText('cancelButton')}
                        </button>
                    </div> :
                    (!!+values.facebook_active && isActive) &&
                    <div className="animate-[slideAndShow_.3s_ease_forwards] mt-3">
                        <DescriptionField
                            data={values}
                            updateValues={updateValues}
                            setValue={setValue}
                        />
                        <SwitchField
                            {...getFieldProps('facebook_publish_later')}
                            name="facebook_publish_later"
                            className="switch-form inline-label big-label light-version mb-0"
                            setValue={setValue}
                            label={getLangText('publishLater')}
                        />
                        {!!+values.facebook_publish_later &&
                            <div className="grid grid-cols-2 gap-4 mt-3 animate-[slideAndShow_.3s_ease_forwards]">
                                <div className="form-group calendar-input">
                                    <div className="control-label">
                                        {getLangText('dateLabel')}
                                    </div>
                                    <DatePicker
                                        portalId="my-popper"
                                        className="form-control"
                                        selected={values.facebook_publish_date}
                                        onChange={(value) => setValue('facebook_publish_date', value)}
                                        locale={lang}
                                        dateFormat="dd.MM.y"
                                    />
                                    <i className="icon-calendar"/>
                                </div>
                                <div className="form-group">
                                    <div className="control-label">
                                        {getLangText('hour')}
                                    </div>
                                    <div className="react-time-picker-wrapper">
                                        <TimePicker
                                            clearIcon={false}
                                            className="form-control flex items-center"
                                            disableClock={true}
                                            locale={prepareRealLang(lang)}
                                            onChange={(value) => setValue('facebook_publish_time', value)}
                                            value={values.facebook_publish_time}
                                            format="HH:mm"
                                        />
                                        <i className="icon-time"/>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
            }
            {showModal &&
                <ConfirmModal
                    title={getLangText('wantToCancelPost')}
                    saveButton={getLangText('yesButton')}
                    show={showModal}
                    isWaiting={waitingForCancel}
                    onSubmit={cancelPost}
                    hide={() => setShowModal(false)}
                />
            }
        </section>
    );
};

export default PublishOnFacebook;
