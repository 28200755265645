import React, {useEffect, useState} from 'react';
import useList from "../../../../src_shared/list/useList";
import ListActions from "./ListActions";
import {getStateRoot} from "./listReducer";
import {batch, useDispatch, useSelector} from "react-redux";
import SwitchField from "../../../../src_shared/components/form/switchField/SwitchField";
import {getStateRoot as getFiltersStateRoot} from "../filters/filtersReducer";
import DateRangePicker from "../../shared/components/form/DateRangePicker";
import FiltersActions from "../filters/FiltersActions";
import {createSelector} from "reselect";
import Loader from "../../shared/Loader";
import ChartsStatsListActions from "../charts/ListActions";
import useWindowDimensions from "../../messenger/hooks/useWindowDimensions/useWindowDimensions";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import OrdersFiltersActions from '../../orders/filters/FiltersActions';
import useLang from "../../../../src_shared/hooks/useLang";
import AsyncSelectField from "../../../../src_shared/components/form/selectField/AsyncSelectField";
import {services} from "../../../RestServices";
import {useUnitPrice} from "../../shared/helpers/Price";

export const currencies = [
    {value: 'PLN', label: 'PLN', shortcut: 'zł'},
    {value: 'EUR', label: 'EUR', shortcut: '€'},
    {value: 'USD', label: 'USD', shortcut: '$'},
    {value: 'GBP', label: 'GBP', shortcut: '£'},
    {value: 'DKK', label: 'DKK', shortcut: 'kr'},
]

export const getCurrenciesShortcut = (value) => currencies.find((i) => i.value === value)?.shortcut;
export const getCurrenciesByShortcut = (value) => currencies.find((i) => i.shortcut === value)?.value;

const BoxesStats = () => {

    const {getLangText} = useLang();
    const dispatch = useDispatch();
    const [isFiltersVisible, setFiltersVisible] = useState(false);
    const {getPrice} = useUnitPrice();

    const {width} = useWindowDimensions();
    const {
        list: {items, isWaiting},
    } = useList({
        ListActions,
        getStateRoot,
        FiltersActions,
    })

    const {
        isRealized,
        currency,
        fromDate,
        toDate,
        viewType
    } = useSelector(createSelector(getFiltersStateRoot, (stateRoot) => stateRoot.data));

    const setRealizedStatus = (field, value) => {
        batch(() => {
            dispatch(FiltersActions.data.setValue(field, value))
            loadData();
        })
    }

    const loadData = () => {
        batch(() => {
            dispatch(ListActions.loadData())
            // dispatch(ChartsStatsListActions.loadData())
        })
    }


    const progressValue = (value) => {
        if (!+value) {
            return null;
        }
        if (+value >= 0) {
            return (
                <div className="progress-value">
                    <strong>
                        <i className="icon-arrow-up"/>
                        {value} %
                    </strong>
                </div>
            )
        }
        return (
            <div className="progress-value red">
                <strong>
                    <i className="icon-arrow-down"/>
                    {value} %
                </strong>
            </div>
        )
    }

    const setOrdersFiltersDate = () => {
        dispatch(OrdersFiltersActions.data.updateValues({
            fromDate,
            toDate,
            viewType,
        }))
    }

    const OrdersListLink = ({children}) => {
        return (
            <Link
                onClick={setOrdersFiltersDate}
                to={RouterPaths.OrdersList}
            >
                {children}
            </Link>
        )
    }

    return (
        <section className="default-shadow-box stats-box loader-parent">
            <Loader isLoading={isWaiting}/>
            <div className="header-bar">
                <div className="title">{getLangText('asideStatsLabel')}</div>
                <button
                    type="button"
                    className='toggle-filters mr-0'
                    onClick={() => setFiltersVisible(!isFiltersVisible)}
                >
                    <i className='icon-filter'/>
                </button>
                {(width > 767 || isFiltersVisible) &&
                    <div className="filters-part d-flex align-items-center">
                        <SwitchField
                            className={`switch-form inline-label mr-2 mb-sm-0 mb-2`}
                            label={getLangText('showRealizedLabel')}
                            name="isRealized"
                            setValue={setRealizedStatus}
                            value={isRealized}
                        />
                        <AsyncSelectField
                            className="mb-0 min-w-[180px]"
                            name="currency"
                            setValue={(field, value) => setRealizedStatus(field, value)}
                            value={isRealized}
                            showLabel={false}
                            rest={services.API_CURRENCY_FILTER}
                            defaultValue={items.currency || {}}
                            withLackOption={false}
                            extraProps={{
                                isSearchable: false,
                            }}
                        />
                        <DateRangePicker
                            actions={ListActions}
                            getFiltersStateRoot={getFiltersStateRoot}
                            FiltersActions={FiltersActions}
                            className="ml-2"
                            loadData={loadData}
                        />
                    </div>
                }
            </div>
            <div className="stats-row">
                <div>
                    <OrdersListLink>
                        <div className="label">
                            <img src="/assets/images/svg/income.svg" alt=""/>
                            {getLangText('incomeLabel')}
                        </div>
                        <div className="value">
                            {getPrice(items.incomeOverall, items?.currency?.shortcut)}
                            {!!+items.incomeOverall && progressValue(items.lastPeriodIncomeOverall)}
                        </div>
                    </OrdersListLink>
                </div>
                <div>
                    <OrdersListLink>
                        <div className="label">
                            <img src="/assets/images/svg/orders.svg" alt=""/>
                            {getLangText('totalOrdersCountLabel')}
                        </div>
                        <div className="value">{items.ordersCountOverall}
                            {!!+items.ordersCountOverall && progressValue(items.lastPeriodOrdersCountOverall)}
                        </div>
                    </OrdersListLink>
                </div>
                <div>
                    <OrdersListLink>
                        <div className="label">
                            <img src="/assets/images/svg/order-value.svg" alt=""/>
                            {getLangText('averageOrderValueLabel')}
                        </div>
                        <div className="value">
                            {getPrice(items.averageOrderValue, items?.currency?.shortcut)}
                            {!!+items.averageOrderValue && progressValue(items.lastPeriodAverageOrderValue)}
                        </div>
                    </OrdersListLink>
                </div>
                <div>
                    <div>
                        <div className="label">
                            <img src="/assets/images/svg/product-value.svg" alt=""/>
                            {getLangText('averageProductValueLabel')}
                        </div>
                        <div className="value">
                            {getPrice(items.averageProductsValue, items?.currency?.shortcut)}
                            {!!+items.averageProductsValue && progressValue(items.lastPeriodAverageProductsValue)}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BoxesStats;
