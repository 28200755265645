import React from 'react';
import moment from "../../../../../messenger/helpers/moment.js";
import {useHistory} from "react-router-dom";
import EmptyList from "../../../../../shared/EmptyList.jsx";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import {RouterPaths} from "../../../../../../routes/RouterPath";
import {convertToUserTimeZone} from "../../../../../shared/helpers/dateHelpers";

const Table = ({updateSortOptions, isWaiting, getSortClass, items}) => {
    const history = useHistory();
    const {getLangText} = useLang();

    return (
        <EmptyList
            items={items}
            isLoading={isWaiting}
            className="with-padd functionality-purchase"
            imgSrc="/assets/images/default/shop-logo.svg"
            title={getLangText('emptyHereLabel')}
            text={getLangText('addNewAutomation')}
        >
            <div className="table-wrapper">
                <div className="table-responsive">
                    <table className="table live-table">
                        <thead>
                        <tr>
                            <th>
                                {getLangText('keyWordsLabel')}
                            </th>
                            <th
                                name="ig_media_id"
                                onClick={updateSortOptions}
                                className={getSortClass('ig_media_id')}
                            >
							<span name="ig_media_id">
								{getLangText('attributedPostLabel')}
							</span>
                            </th>
                            <th
                                name="reaction_count"
                                onClick={updateSortOptions}
                                className={`text-right ${getSortClass('reaction_count')}`}
                            >
							<span name="reaction_count">
								{getLangText('reactionCountLabel')}
							</span>
                            </th>
                            <th
                                name="active"
                                onClick={updateSortOptions}
                                className={`${getSortClass('active')}`}
                            >
							<span name="active">
								{getLangText('statusLabel')}
							</span>
                            </th>
                            <th
                                name="date"
                                onClick={updateSortOptions}
                                className={`text-right ${getSortClass('date')}`}
                            >
							<span name="date">
								{getLangText('addedDateLabel')}
							</span>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {items.map((item) => (
                            <tr onClick={() => history.push(`${RouterPaths.AutomationEdit}/${item.id}`)}
                                key={item.id}>
                                <td className="name-td">
                                    <div className="flex items-center flex-wrap">
                                        {item.code?.map((i) => (
                                            <div className="tag grey">{i}</div>
                                        ))}
                                    </div>
                                </td>
                                <td className="with-photo w-lg-auto w-100">
                                    {item.ig_media_id ?
                                        <div className="d-flex align-items-lg-center mt-sm-0 mt-2 max-w-[200px]">
                                            <figure>
                                                <img src={item.ig_media_photo} alt=""/>
                                            </figure>
                                            <div className="overflow-hidden text-ellipsis text-nowrap">
                                                {item.ig_media_description}
                                            </div>
                                        </div> :
                                        <div className="d-flex align-items-lg-center mt-sm-0 mt-2">
                                            <figure>
                                                <img src="/assets/images/default/product.svg" alt=""/>
                                            </figure>
                                            <div>
                                                {getLangText('wholeFanpageLabel')}
                                            </div>
                                        </div>
                                    }
                                </td>
                                <td className="text-right client-td">
                                    {item.reaction_count}
                                </td>
                                <td>
                                    <div>
                                        {+item.active ?
                                            <div className="status-bg green small-status">
                                                {getLangText('activeLabel')}
                                            </div> : <div className="status-bg grey small-status">
                                                {getLangText('notActiveLabel')}
                                            </div>
                                        }
                                    </div>
                                </td>
                                <td className="date-td date text-right">
                                    <div>{convertToUserTimeZone(item.date).format('DD.MM.YYYY HH:mm')}</div>
                                </td>
                            </tr>
                        ))}

                        </tbody>
                    </table>
                </div>
            </div>
        </EmptyList>
    );
};

export default Table;
