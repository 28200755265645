import Model from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

class ShippyProSenderDataModel extends Model {
	getModel() {
		return {
			name: '',
			company: '',
			street: '',
			city: '',
			zip_code: '',
			phone: '',
			email: '',
			country: '',
		};
	}

	getValidators() {
		return {
			name: ValidationError.notEmpty,
			company: ValidationError.notEmpty,
			street: ValidationError.notEmpty,
			city: ValidationError.notEmpty,
			zip_code: ValidationError.notEmpty,
			phone: ValidationError.notEmpty,
			email: ValidationError.notEmpty,
			country: ValidationError.notEmpty,
		};
	}

	buildDTO(data) {
		const {name, company, street, city, zip_code, phone, country, email} = data;
		return {
			name,
			company,
			street,
			city,
			zip_code,
			phone,
			email,
			country,
		}
	}
}

export default new ShippyProSenderDataModel();
