import {useEffect, useState} from "react";
import api from "../../services/axios/axios";
import {selmoUrl} from "../api/api";
import {useDispatch} from "react-redux";
import {showAlert} from "../../components/app/shared/components/alert/AlertActions";

export const usePrintFile = ({
                                 rest,
                                 fileType = 'pdf',
                                 afterSubmit = () => {
                                 },
                                 onError = (e) => {
                                 },
                                 values = null,
                                 params = "",
                             }) => {
    const [waiting, setWaiting] = useState(false);
    const dispatch = useDispatch();
    const [fileUrl, setFileUrl] = useState('');
    const [activeIframe, setActiveIframe] = useState(null);
    const print = async (e, id) => {
        e?.stopPropagation();
        setWaiting(true);
        try {
            const preparedRest = id ? `${selmoUrl}/${rest}/${id}${params}` : `${selmoUrl}/${rest}${params}`
            const {data} = values ? await api.post(preparedRest, values, {
                responseType: "blob",
            }) : await api.get(preparedRest, {
                responseType: "blob",
            });
            const blob = new Blob([data], {type: `application/${fileType}`});

            const url = URL.createObjectURL(blob);
            setFileUrl(url);

            const iframe = document.createElement('iframe');
            setActiveIframe(iframe);
            iframe.src = url;
            iframe.style.display = 'none';
            document.body.appendChild(iframe);

            const iframeWindow = iframe.contentWindow || iframe;
            setTimeout(() => {
                iframe.focus();
                iframeWindow.print();
            }, 1000)

            afterSubmit();
        } catch (error) {
            onError(error)
            dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
        } finally {
            setWaiting(false);
        }
    };

    useEffect(() => {
        if (!!activeIframe) {
            return () => document.body.removeChild(activeIframe)
        }
    }, [activeIframe])


    return {print, waiting, fileUrl};
};
