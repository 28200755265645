import React, {useEffect} from 'react';
import Field from "../../../../../../src_shared/components/form/field/Field.jsx";
import moment from "moment";
import EditPaymentReceivedModal from "./EditPaymentReceived.jsx";
import useForm from "../../../../../../src_shared/form/useForm";
import FormActions from "./FormActions";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {getStateRoot} from "./formReducer";
import SendVoiceMessageModal from "./SendVoiceMessageModal";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import useLang from "../../../../../../src_shared/hooks/useLang";
import {convertToUserTimeZone} from "../../../../shared/helpers/dateHelpers";

export const getPaymentReceivedCount = (balance, currencyText, getLangText) => {
	const preparedBalance = balance.replace(',', '.');
	if (+preparedBalance > 0) {
		return (
			<div className="status-bg blue text-nowrap">
				{getLangText('excessPaymentLabel')}: {balance} {currencyText}
			</div>
		)
	} else if (+preparedBalance < 0) {
		return (
			<div className="status-bg red text-nowrap">
				{getLangText('underPaymentLabel')}: {balance} {currencyText}
			</div>
		)
	}
	return <div className="status-bg green text-nowrap">
		{getLangText('allPaidLabel')}
	</div>
}

const ClientPaymentReceived = ({data, className = '', showHistory = true}) => {

	const params = useParams();
	const dispatch = useDispatch();
	const {getLangText} = useLang();

	const {
		sendVoiceMessageVisible,
		waitingForSendingMessage
	} = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));

	const hideSendVoiceMessageModal = () => {
		dispatch(FormActions.data.setValue('new_payment_received', ''))
		dispatch(FormActions.showSendVoiceMessageModal(false))
	};
	const {
		data: {values, setValue},
		form: {onSave, getFieldProps, isWaiting},
		modal: {showModal, hideModal, modalVisible}
	} = useForm({
		FormActions,
		params,
		preventAutoLoad: true,
	});

	const getHistoryType = (type) => {
		if (type === 'SUM') {
			return getLangText('paymentReceivedChangeSumLabel')
		}
		return getLangText('paymentReceivedNewPaymentLabel')
	}

	const {userData} = useSelector((state) => state.session);

	useEffect(() => {
		setValue('id', data.shop_client_id || data.client?.shop_client_id)
	}, [data])

	return (
		<section className={`summary-section data-part payment-received-wrapper ${className}`}>
			<div className="d-flex align-items-center mb-0">
				<div className="d-flex align-items-center flex-grow-1">
					<h2 className="mr-2 mb-0 ">{getLangText('paymentDepositedFundsLabel')}</h2>
					<button
						className="icon-button grey small-font-size"
						onClick={showModal}
					>
						<i className="icon-edit"/>
					</button>
				</div>
				{getPaymentReceivedCount(data.balance, userData.currency_info.text, getLangText)}
			</div>
			<div className="payment-received-value">
				{data.payment_received} {userData.currency_info.text}
			</div>
			<div>
				<div className="form-group with-save-btn absolute-btn">
					<Field
						className="mt-2"
						label={getLangText('paymentReceivedNewPaymentLabel')}
						{...getFieldProps('new_payment_received')}
						value={values.new_payment_received}
						setValue={setValue}
						type="number"
						extraProps={{
							min: 0,
						}}
					/>
					{values.new_payment_received <= 0 ?
						<OverlayTrigger
							placement='top'
							overlay={
								<Tooltip id="paymentReceived">
									{getLangText('amountGreaterThanZeroLabel')}
								</Tooltip>
							}
						>
							<button
								type="button"
								className="button border-button disabled"
							>
								{getLangText('saveButton')}
							</button>
						</OverlayTrigger> :
						<button
							type="button"
							className="button border-button"
							onClick={onSave}
						>
							{getLangText('saveButton')}
						</button>
					}
				</div>
			</div>

			{!!data.payment_received_logs.length && showHistory &&
				<div className="email-status-wrapper">
					<div className="sent-emails">
						<h3>{getLangText('paymentReceivedHistoryPaymentLabel')}</h3>
						<div className="emails-sent-wrapper">
							{data.payment_received_logs.map((i) => (
								<div
									className="email-row">
									<div className="flex-grow-1 history-type">
										{getHistoryType(i.type)}
										<strong>
											{i.value} {userData.currency_info.text}
										</strong>
									</div>
									<div className="date flex-grow-0">
										{convertToUserTimeZone(i.date).format('DD.MM.YYYY HH:mm')}
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			}

			{modalVisible &&
				<EditPaymentReceivedModal
					setValue={setValue}
					isWaiting={isWaiting}
					onSave={onSave}
					getFieldProps={getFieldProps}
					modalVisible={modalVisible}
					hideModal={hideModal}
					values={values}
					data={data}
				/>
			}
			{sendVoiceMessageVisible &&
				<SendVoiceMessageModal
					modalVisible={sendVoiceMessageVisible}
					hideModal={hideSendVoiceMessageModal}
					values={values}
					userData={userData}
					isWaiting={waitingForSendingMessage}
				/>
			}
		</section>
	);
};

export default ClientPaymentReceived;
