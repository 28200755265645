import React from 'react';
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../routes/RouterPath";
import {services} from "../../../../RestServices";
import useLang from "../../../../../src_shared/hooks/useLang";
import useFormLocal from "../../../../../src_shared/hooks/useFormLocal";
import SwitchField from "../../../../../src_shared/components/form/switchField/SwitchField";
import Loader from "../../../../app/shared/Loader";
import Wrapper from "../Wrapper";
import SettingsDPDModel from "../../../../../modules/models/settings/SettingsDPDModel";
import Field from "../../../../../src_shared/components/form/field/Field";

const DpdConfiguration = () => {
    const {getLangText} = useLang();

    const [{onSave, isWaiting, getFieldProps, isLoading}] = useFormLocal({
        rest: services.API_SETTINGS_DPD_SK,
        model: SettingsDPDModel,
    });

    return (
        <>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <Link to={RouterPaths.Settings}>
                            {getLangText('asideSettingsLabel')}
                        </Link>
                    </li>
                    <li>
                        <Link to={RouterPaths.SettingsIntegration}>{getLangText('settings_nav_integrations')}</Link>
                    </li>
                    <li className="active">
                        DPD
                    </li>
                </ul>
            </div>
            <Wrapper>
                <div className="loader-parent">
                    <Loader isLoading={isLoading}/>
                    <div className="settings-form pt-4">
                        <div className="steps-wrapper">
                            <div className="step">
                                <div className="step-header align-items-start">
                                    <div className="title d-block flex-grow-1">
                                        <div className="mb-2">{getLangText('pasteKeysInFieldsBelowLabel')}</div>
                                        <Field
                                            {...getFieldProps('email')}
                                            label={getLangText('emailLabel')}
                                        />
                                        <Field
                                            {...getFieldProps('client_key')}
                                            label={getLangText('clientId')}
                                        />
                                        <Field
                                            {...getFieldProps('delis_id')}
                                            label={getLangText('delisId')}
                                        />
                                        <Field
                                            {...getFieldProps('address_id')}
                                            label={getLangText('addressId')}
                                        />
                                        <Field
                                            {...getFieldProps('bank_id')}
                                            label={getLangText('bankId')}
                                        />
                                        <div className="flex items-center mt-2">
                                            <SwitchField
                                                {...getFieldProps('active')}
                                                label={getLangText('activeLabel')}
                                                className="switch-form inline-label align-items-start big-label light-version mt-0 mb-0"
                                            />
                                            <button
                                                type="button"
                                                onClick={onSave}
                                                className={`button primary ml-auto ${isWaiting ? 'loading-button' : ''}`}
                                            >
                                                <Loader isLoading={isWaiting}/>
                                                {getLangText('saveButton')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Wrapper>
        </>
    );
};

export default DpdConfiguration;
