import React from 'react';
import useLang from "../../../../../src_shared/hooks/useLang";

const OverlayPreview = ({values}) => {
    const {getLangText} = useLang();

    if (!values.base) {
        return null
    }

    return (
        <div className="default-shadow-box summary-box loader-parent mt-3 pb-0">
            <div className="flex items-center">
                <div>
                    <div className="font-bold">
                        {getLangText('overlayPreview')}
                    </div>
                    <div className="text-desc text-sm">
                        {getLangText('thisIsWhatYourCustomerSees')}
                    </div>
                </div>
            </div>
            <div className="mt-3">
                <div className="relative">
                    <img
                        className="w-full"
                        src="/assets/images/svg/phone.svg" alt="phone"/>
                    <img
                        className="absolute top-4 p-[20px]"
                        src={`data:image/jpeg;base64,${values.base}`}
                        alt="Base64 Image"
                    />
                </div>
            </div>
        </div>
    );
};

export default OverlayPreview;
