export const createActions = prefix => ({
    showModal: () => ({
        type: `${prefix}MODAL_SHOW`,
    }),
    hideModal: () => ({
        type: `${prefix}MODAL_HIDE`,
    }),
});

export default {
    createActions,
};
