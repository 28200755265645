import {getStateRoot, prefix} from "./formReducer.js";
import {services} from "../../../../RestServices.js";
import FormActions from "../../../../../src_shared/form/FormActions";
import CategoryModel from "../../../../../modules/models/category/CategoryModel";
import {batch} from "react-redux";
import {RouterPaths} from "../../../../routes/RouterPath";
import {showAlert} from "../../../shared/components/alert/AlertActions";
import axios from "axios";
import {selmoUrl} from "../../../../../src_shared/api/api";
import api from "../../../../../services/axios/axios";

export class EditCategoryFormActions extends FormActions {
	showModal(id) {
		return (dispatch) => {
			batch(() => {
				dispatch(this.loadData(id))
				dispatch(this.modal.showModal())
			})
		}
	}
	afterDeleteSubmit(history) {
		return () => {
			history.push(RouterPaths.CategoriesList)
		}
	}

	submitEditForm(values, afterSave) {
		return async (dispatch) => {
			const {id} = values;

			dispatch(this.validation.activateValidation());

			if (!this.dataModel.validateData(values)) {
				dispatch(showAlert('fillRequireFieldWarning', '', 'alert-fail'))
				console.error('Validation failed!');
				return;
			}

			const filteredData = Object.entries(values).filter(([key]) => key !== 'id');
			const finalData = Object.fromEntries(filteredData)

			const preparedData = this.dataModel.buildDTO(finalData)

			dispatch(this.waiting.startWaiting());

			try {
				await api.put(`${selmoUrl}/${this.restService}/${id}`, preparedData)
				dispatch(this.afterSubmit(id))
				dispatch(showAlert())
				afterSave();
				dispatch(this.validation.deactivateValidation())
			} catch (e) {
				dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
				console.error('Error submitEditForm')
			} finally {
				dispatch(this.waiting.stopWaiting());
			}
		}
	}
}

export const getInstance = () => new EditCategoryFormActions({
	getStateRoot,
	prefix,
	model: CategoryModel,
	restService: services.API_CATEGORIES,
	route: RouterPaths.CategoriesList
});

export default getInstance();
