import React, {useEffect} from 'react';
import MultiRowListTemplates from "./MultiRowListTemplates";
import useLang from "../../../../src_shared/hooks/useLang";
import useFormLocal from "../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../RestServices";
import {showAlert} from "../../../app/shared/components/alert/AlertActions";
import {useDispatch} from "react-redux";
import SettingsProductHasVariantsTemplatesModel
    from "../../../../modules/models/settings/SettingsProductHasVariantsTemplatesModel";

const ProductHasVariantsRows = ({values}) => {
    const {getLangText} = useLang();
    const dispatch = useDispatch();

    const [{
        onSave,
        isWaiting,
        getFieldProps,
        setValue,
        isLoading,
        updateValues
    }] = useFormLocal({
        rest: services.API_SETTINGS_MESSENGER_TEMPLATES_PRODUCT_HAS_VARIANTS,
        model: SettingsProductHasVariantsTemplatesModel,
        initialAutoLoad: false,
        afterSubmit: () => {
            dispatch(showAlert())
        }
    });

    return (
        <section id="product-variants">
            <div className="left-side">
                <h2 className="label">{getLangText('input_label_cart_variants')}</h2>
            </div>
            <MultiRowListTemplates
                getFieldProps={getFieldProps}
                onSave={onSave}
                isWaiting={isWaiting}
                setValue={setValue}
                field="product_has_variants"
                label="inputLabelProductHasVariants"
                updateValues={updateValues}
                data={values}
            />
        </section>
    );
};

export default ProductHasVariantsRows;
