import {useState} from "react";
import {selmoUrl} from "../api/api";
import {useDispatch} from "react-redux";
import {showAlert} from "../../components/app/shared/components/alert/AlertActions";
import api from "../../services/axios/axios";

const useSubmitData = (options) => {
    const [response, setResponse] = useState({});
    const [isError, setIsError] = useState(false);
    const [isWaiting, setIsWaiting] = useState(false);
    const dispatch = useDispatch();

    const {
        rest,
        values = null,
        method = 'post',
        afterSubmit = () => {
            dispatch(showAlert())
        },
        onError = (e) => {
            setIsError(true);
            dispatch(showAlert(e.response?.data?.message || 'sthWentWrongWarning', '', 'alert-fail'))
        },
    } = options;

    const onSave = async (e, extraValues = {}) => {
        e?.preventDefault();
        setIsWaiting(true);

        try {
            const {data} = await api[method](`${selmoUrl}/${rest}`, {
                ...values,
                ...extraValues
            })
            setResponse(data?.item || data?.items);
            afterSubmit(data, {
                ...values,
                ...extraValues
            });

        } catch (error) {
            onError(error)
        } finally {
            setIsWaiting(false);
        }
    };

    return [{response, isWaiting, onSave, setResponse, isError}];
};

export default useSubmitData;
