import React, {useEffect} from 'react';
import useLang from "../../../../../src_shared/hooks/useLang";
import ConfirmModal from "../../../../../src_shared/components/modal/ConfirmModal";
import useFormLocal from "../../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../../RestServices";
import {showAlert} from "../../../shared/components/alert/AlertActions";
import {useDispatch} from "react-redux";
import Field from "../../../../../src_shared/components/form/field/Field";
import TextArea from "../../../../../src_shared/components/form/textarea/TextArea";
import SendNotificationsModel from "../../../../../modules/models/notifications/SendNotificationsModel";

const SendNotificationsModal = ({showModal, hideModal, fetchData, usersCount}) => {
	const {getLangText} = useLang();
	const dispatch = useDispatch();

	const [{onSave, isWaiting, getFieldProps, setValue}] = useFormLocal({
		rest: services.API_MOBILE_CLIENTS_NOTIFICATIONS,
		model: SendNotificationsModel,
		afterSubmit: () => {
			fetchData()
			dispatch(showAlert('successfulSentNotificationsLabel'))
			hideModal();
		},
		initialAutoLoad: false,
	});

	useEffect(() => {
		setValue('users', usersCount);
	}, [])

	return (
		<ConfirmModal
			title={getLangText('sendNotificationsInAppLabel')}
			SubTitle={() => <span className="mt-1 d-block">{getLangText('allSubmittedWillGetMessages')}</span>}
			saveButton={getLangText('sendButton')}
			show={showModal}
			isWaiting={isWaiting}
			onSubmit={onSave}
			hide={hideModal}
		>
			<div className="loader-parent">
				<TextArea
					{...getFieldProps('message')}
					label={getLangText('notificationMessageLabel')}
				/>
				<Field
					{...getFieldProps('url')}
					label={getLangText('linkToLiveOrPostLabel')}
				/>
			</div>
		</ConfirmModal>
	);
};

export default SendNotificationsModal;
