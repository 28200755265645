import React, {useState} from 'react';
import TeamWrapper from "../TeamWrapper";
import Table from "./components/Table";
import Pagination from "../../../app/shared/components/pagination/Pagination";
import useLang from "../../../../src_shared/hooks/useLang";
import useList from "../../../../src_shared/list/useList";
import {getStateRoot} from "./listReducer";
import ConfirmRemoveModal from "../../../../src_shared/components/modal/ConfirmRemoveModal";
import FiltersActions from "../filters/FiltersActions";
import {getStateRoot as getFiltersStateRoot} from "../filters/filtersReducer";
import SearchInput from "../../../admin/components/app/shared/components/form/SearchInput";
import useWindowDimensions from "../../../app/messenger/hooks/useWindowDimensions/useWindowDimensions";
import AsyncFilter from "../../../../src_shared/components/filters/asyncFilter/AsyncFilter";
import {services} from "../../../RestServices";
import DateRangePicker from "../../../app/shared/components/form/DateRangePicker";
import ListActions from "./ListActions";

const TeamActivityHistory = () => {
    const [showModal, setShowModal] = useState(false);
    const {getLangText} = useLang();

    const [isFiltersVisible, setFiltersVisible] = useState(false);
    const {width} = useWindowDimensions();

    const {
        list: {items, isWaiting, loadData},
        search,
        remove: {confirmItemRemoval, isModalVisible, cancelItemRemoval, markedToRemove, askForRemoveItem},
        pagination: {
            changePage, getPagination, itemsPerPage, nextPage, prevPage, totalItems, updateItemsPerPage
        },
        sort: {updateSortOptions, getSortClass},
    } = useList({
        ListActions,
        getStateRoot,
        FiltersActions,
    });

    return (
        <TeamWrapper
            modalVisible={showModal}
            setShowModal={setShowModal}
            hideButton={true}
            title="activityHistory"
        >
            <div className="table-list-wrapper">
                <div className="default-shadow-box">
                    <div className="table-header">
                        <div className="filters-part d-block mt-0">
                            <div className="top-part">
                                <h1 className="title">
                                    {getLangText('activityHistory')}
                                    {/*{totalItems > 0 && <div className="badge">{totalItems}</div>}*/}
                                </h1>
                                <button
                                    type="button"
                                    className='toggle-filters'
                                    onClick={() => setFiltersVisible(!isFiltersVisible)}
                                >
                                    <i className='icon-filter'/>
                                </button>
                            </div>
                            {(width > 767 || isFiltersVisible) &&
                                <div className="filters-part">
                                    <AsyncFilter
                                        ListActions={ListActions}
                                        FiltersActions={FiltersActions}
                                        getFiltersStateRoot={getFiltersStateRoot}
                                        name="employees"
                                        selectAllName="employeesSelectAll"
                                        defaultSelectedLabel={getLangText('membersLabel')}
                                        rest={services.API_PERMISSIONS_EMPLOYEES}
                                    />
                                    <AsyncFilter
                                        ListActions={ListActions}
                                        FiltersActions={FiltersActions}
                                        getFiltersStateRoot={getFiltersStateRoot}
                                        name="types"
                                        selectAllName="typesSelectAll"
                                        defaultSelectedLabel={getLangText('typeLabel')}
                                        className="ml-sm-2"
                                        dropdownClassName="big-min-width"
                                        rest={services.API_PERMISSIONS_EMPLOYEES_ACTIONS}
                                        optionsParser={(items) => items.map((i) => ({
                                            value: i.id,
                                            label: getLangText({
                                                key: i.id === 'ORDER_STATUS_CHANGED' ? 'ORDER_STATUS_CHANGED_FILTER' : i.id,
                                                data: ['', '']
                                            }),
                                        }))}
                                        optionLabel={(item) => (
                                            <label
                                                className="control-label"
                                                dangerouslySetInnerHTML={{__html: item.label}}
                                            />
                                        )}
                                    />
                                    <DateRangePicker
                                        actions={ListActions}
                                        getFiltersStateRoot={getFiltersStateRoot}
                                        FiltersActions={FiltersActions}
                                        className="ml-sm-auto"
                                        loadData={loadData}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                    <Table
                        isWaiting={isWaiting}
                        items={items}
                        updateSortOptions={updateSortOptions}
                        getSortClass={getSortClass}
                        askForRemoveItem={askForRemoveItem}
                        loadData={loadData}
                    />
                </div>
            </div>
            <Pagination
                getLangText={getLangText}
                pages={getPagination()}
                items={items}
                itemsPerPage={itemsPerPage}
                totalItems={totalItems}
                onPrevPage={prevPage}
                onChangePage={changePage}
                onNextPage={nextPage}
                onChangeItemsPerPage={updateItemsPerPage}
                classes={{
                    prevIcon: 'icon-chevron left', nextIcon: 'icon-chevron right',
                }}
            />
            <ConfirmRemoveModal
                show={isModalVisible}
                title={getLangText('wantToRemoveInvitations')}
                isWaiting={isWaiting}
                hide={cancelItemRemoval}
                confirm={confirmItemRemoval}
                confirmButton={getLangText('removeButton')}
                dialogClassName="small-modal"
            />
        </TeamWrapper>);
};

export default TeamActivityHistory;
