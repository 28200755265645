import React from 'react';
import {WithContext as ReactTags} from "react-tag-input";

const SimpleTags = ({setValue, tags, placeholder, onFocus = () => {}, onBlur = () => {}}) => {

    const KeyCodes = {
        comma: 188,
        enter: 13,
    };

    const delimiters = [KeyCodes.comma, KeyCodes.enter];

    const handleAddition = (newTag) => {
        setValue([...tags, {...newTag, className: 'grey big-size'}])
    }

    const handleDelete = (i) => {
        const filtered = tags.filter((tag, index) => index !== i);
        setValue(filtered);
    }


    return (
        <ReactTags
            inline
            tags={tags}
            delimiters={delimiters}
            handleDelete={handleDelete}
            handleAddition={handleAddition}
            handleInputFocus={onFocus}
            handleInputBlur={onBlur}
            allowDragDrop={false}
            className="min-h-[51px]"
            placeholder={placeholder}
            inputProps={{
                autoComplete: "off",
            }}
        />
    );
};

export default SimpleTags;
