import {combineReducers} from "redux";
import listReducer from "./automation/listReducer";
import interactionsListReducer from './interactions/listReducer';
import filtersReducer from "./filters/filtersReducer";
import newsletterListReducer from "./newsletter/listReducer";

export default combineReducers({
    automationList: listReducer,
    interactionsList: interactionsListReducer,
    newsletterList: newsletterListReducer,
    filters: filtersReducer,
})
