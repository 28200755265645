import React from 'react';
import {Link, NavLink} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import useLang from "../../../../src_shared/hooks/useLang";
import useWindowDimensions from "../../messenger/hooks/useWindowDimensions/useWindowDimensions";
import Profile from "../header/Profile";
import ServerImage from "../components/serverImage/ServerImage";
import AdminPermissionsWrapper from "../../../../src_shared/components/adminPermissionsWrapper/AdminPermissionsWrapper";
import MessengerLink from "./components/MessengerLink";
import UserPermissionsWrapper from "../../../routes/UserPermissionsWrapper";
import {
    USER_PERMISSIONS_AUTOMATION_MENU_VISIBILITY,
    USER_PERMISSIONS_CLIENTS_MENU_VISIBILITY,
    USER_PERMISSIONS_ORDERS_MENU_VISIBILITY,
    USER_PERMISSIONS_SETTINGS_MENU_VISIBILITY,
} from "../enums/UserPermissionsTypes";
import ProductsMenu from "./components/ProductsMenu";
import ShopMenu from "./components/ShopMenu";

const InstagramAutomationAside = ({userData}) => {
    const {getLangText} = useLang();
    const {width} = useWindowDimensions();


    const body = document.querySelector('body')

    const toggleMobileMenu = () => {
        if (body.classList.contains('open-menu')) {
            body.classList.remove('open-menu')
        } else {
            body.classList.add('open-menu')
        }
    }

    const closeMobileMenu = () => {
        body.classList.remove('open-menu');
    }

    return (
        <aside className="main-aside">
            <div>
                <Link
                    to="/"
                    className="brand-name"
                >
                    <img
                        src="/assets/images/logo.svg"
                        alt="logo-selmo"
                    />
                </Link>
                <div
                    onClick={toggleMobileMenu}
                    className="hamburger-btn"
                >
                    <div className="bar1"/>
                    <div className="bar3"/>
                </div>
                {width < 991 &&
                    <div className="right-aside-wrapper">
                        {!!+userData.depot_system &&
                            <Link className="ml-auto mr-3 flex items-center" to={RouterPaths.StorageScanner}>
                                <i className="icon-qr-code"/>
                            </Link>
                        }
                        <Profile userData={userData}/>
                    </div>
                }
                <Link
                    onClick={closeMobileMenu}
                    to="/"
                    className="shop-info-box"
                >
                    <div>
                        <figure>
                            {userData.logo && userData.logo !== '0'
                                ? <ServerImage src={userData.logo}
                                               alt={userData.name}/> :
                                <img src="/assets/images/default/shop.svg" alt={userData.name}/>}
                        </figure>
                    </div>
                    {userData.name}
                </Link>
                <ul>
                    <li>
                        <NavLink
                            onClick={closeMobileMenu}
                            exact
                            activeClassName="active"
                            to="/"
                        >
                            <i className="icon-home"/>
                            <span>{getLangText('asideDashboardLabel')}</span>
                        </NavLink>
                    </li>
                    <UserPermissionsWrapper allowedPermissions={[USER_PERMISSIONS_AUTOMATION_MENU_VISIBILITY]}>
                        <li>
                            <NavLink
                                onClick={closeMobileMenu}
                                activeClassName="active"
                                to={RouterPaths.Automation}
                            >
                                <i className="icon-automate"/>
                                <span>{getLangText('automationLabel')}</span>
                            </NavLink>
                        </li>
                    </UserPermissionsWrapper>
                    <MessengerLink
                        closeMobileMenu={closeMobileMenu}
                    />
                    {/*<li>*/}
                    {/*    <NavLink*/}
                    {/*        onClick={closeMobileMenu}*/}
                    {/*        activeClassName="active"*/}
                    {/*        to={RouterPaths.DMList}*/}
                    {/*    >*/}
                    {/*        <i className="icon-analytics"/>*/}
                    {/*        {getLangText('dmList')}*/}
                    {/*    </NavLink>*/}
                    {/*</li>*/}
                    <li>
                        <div
                            className="text-xs font-bold text-[#9CA3AF] p-[8px] mt-3.5">{getLangText('salesLabel')}</div>
                    </li>
                    <UserPermissionsWrapper allowedPermissions={[USER_PERMISSIONS_ORDERS_MENU_VISIBILITY]}>
                        <li>
                            <NavLink
                                onClick={closeMobileMenu}
                                activeClassName="active"
                                to="/zamowienia"
                            >
                                <i className="icon-orders"/>
                                <span>{getLangText('asideOrdersLabel')}</span>
                            </NavLink>
                        </li>
                    </UserPermissionsWrapper>
                    <ProductsMenu
                        closeMobileMenu={closeMobileMenu}
                    />
                    <UserPermissionsWrapper allowedPermissions={[USER_PERMISSIONS_CLIENTS_MENU_VISIBILITY]}>
                        <li>
                            <NavLink
                                onClick={closeMobileMenu}
                                activeClassName="active"
                                to={RouterPaths.ClientsList}
                            >
                                <i className="icon-clients"/>
                                <span>
								{getLangText('asideClientsLabel')}
							</span>
                            </NavLink>
                        </li>
                    </UserPermissionsWrapper>
                    <ShopMenu
                        closeMobileMenu={closeMobileMenu}
                    />
                    <UserPermissionsWrapper allowedPermissions={[USER_PERMISSIONS_SETTINGS_MENU_VISIBILITY]}>
                        <li className="hidden-desktop">
                            <NavLink
                                activeClassName="active"
                                to={RouterPaths.Settings}
                                onClick={closeMobileMenu}
                            >
                                <i className="icon-settings"/>
                                <span>
								{getLangText('asideSettingsLabel')}
							</span>
                            </NavLink>
                        </li>
                    </UserPermissionsWrapper>
                    <li className="hidden-desktop hidden-mobile-navbar">
                        <NavLink
                            onClick={closeMobileMenu}
                            activeClassName="active"
                            to="/pomoc/"
                        >
                            <i className="icon-help"/>
                            <div>
                                <div>
									<span>
										{getLangText('asideHelpLabel')}
									</span>
                                </div>
                                <div className="bottom-text">
                                    {getLangText('watchTheVideoLabel')}
                                </div>
                            </div>
                        </NavLink>
                    </li>
                    {/*<ImproveSelmo hiddenOnDesktop/>*/}
                </ul>
                <div className="bottom-part">
                    <ul>
                        {/*<ImproveSelmo/>*/}
                        {!userData.user_info.status &&
                            <li>
                                <Link
                                    onClick={closeMobileMenu}
                                    to={RouterPaths.Payments}
                                    data-text="Wybierz plan"
                                    className="choose-plan"
                                >
									<span>
										{getLangText('asideSelectPlanLabel')}
									</span>
                                </Link>
                            </li>
                        }
                        <AdminPermissionsWrapper>
                            <li>
                                <NavLink
                                    className="d-flex align-items-center"
                                    onClick={closeMobileMenu}
                                    activeClassName="active"
                                    to={RouterPaths.Admin}
                                >
                                    {/*<img className="d-inline-block mr-2"*/}
                                    {/*     style={{borderRadius: '50%', objectFit: 'cover'}} width={30} height={30}*/}
                                    {/*     src="/assets/images/flags/przemo.png" alt=""/>*/}
                                    <div
                                        className="w-[22px] h-[22px] rounded-full bg-[#697281] text-white flex items-center justify-center mr-2">A
                                    </div>
                                    <span>Admin</span>
                                </NavLink>
                            </li>
                        </AdminPermissionsWrapper>
                        <UserPermissionsWrapper allowedPermissions={[USER_PERMISSIONS_SETTINGS_MENU_VISIBILITY]}>
                            <li className="active">
                                <NavLink
                                    to={RouterPaths.Settings}
                                    activeClassName="active"
                                    onClick={closeMobileMenu}
                                >
                                    <i className="icon-settings"/>
                                    <span>
									{getLangText('asideSettingsLabel')}
								</span>
                                </NavLink>
                            </li>
                        </UserPermissionsWrapper>
                        <li>
                            <NavLink
                                className="help-link"
                                onClick={closeMobileMenu}
                                activeClassName="active"
                                to="/pomoc/"
                            >
                                <i className="icon-help"/>
                                <div>
                                    <div>
                                        <span>{getLangText('asideHelpLabel')}</span>
                                    </div>
                                    <div className="bottom-text">
                                        {getLangText('watchTheVideoLabel')}
                                    </div>
                                </div>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </aside>
    );
};

export default InstagramAutomationAside;
