import {combineReducers} from "redux";
import listsReducer from './list/listReducer'
import modalFormReducer from "./modalForm/formReducer";
import editProductReducer from "./form/formReducer";
import assignProductsReducer from "./assignProducts/formReducer";

export default combineReducers({
	list: listsReducer,
	form: editProductReducer,
	modalForm: modalFormReducer,
	assignProducts: assignProductsReducer,
});
