import {combineReducers} from "redux";
import listReducer from './list/listReducer'
import formReducer from "./form/formReducer";
import seoFormReducer from "./seo/formReducer";
import modalFormReducer from "./modalForm/formReducer";
import assignProductsReducer from "./assignProducts/formReducer";
import productsReducer from "./products/productsReducer";

export default combineReducers({
	list: listReducer,
	form: formReducer,
	seoForm: seoFormReducer,
	assignProducts: assignProductsReducer,
	modalForm: modalFormReducer,
	products: productsReducer,
});
