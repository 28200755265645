import Composition from "../../../../shared/helpers/Composition";
import createReducer from "../../../../shared/helpers/createReducer";
import FormReducer from "../../../../../../src_shared/form/FormReducer";
import ClientPaymentReceivedModel from "../../../../../../modules/models/ClientPaymentReceivedModel";

export const getStateRoot = (state) => state.clients.details.paymentReceived;
export const prefix = 'CLIENTS_PAYMENT_RECEIVED_';

export const getInitState = () => FormReducer.getInitState(ClientPaymentReceivedModel.getModel(), {
	sendVoiceMessageVisible: false,
	waitingForSendingMessage: false,
})

export const getReduceMap = () => new Composition(
	FormReducer.getReduceMap(),
	{
		SHOW_SEND_VOICE_MESSAGE_MODAL: (state, action) => ({...state, sendVoiceMessageVisible: action.toggle}),
		WAITING_FOR_SENDING_MESSAGE: (state, action) => ({...state, waitingForSendingMessage: action.toggle}),
	}
);

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();
