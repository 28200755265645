import useLang from "../../../../src_shared/hooks/useLang";
import useFormLocal from "../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../RestServices";
import QRCodeProductModel
    from "../../../../modules/models/settings/QRCodeProductModel";
import Loader from "../../../app/shared/Loader";
import React from "react";
import Wrapper from "../Wrapper";
import {useSelector} from "react-redux";
import Item from "./components/Item";
import {convertToUserTimeZone} from "../../../app/shared/helpers/dateHelpers";

const QRProductSettings = () => {
    const {getLangText} = useLang()
    const {product_code, currency_info} = useSelector((state) => state.session.userData);

    const [{onSave, isWaiting, getFieldProps, isLoading, values}] = useFormLocal({
        rest: services.API_SETTINGS_PRODUCT_QR_CODE,
        model: QRCodeProductModel,
    });

    const productCodeFontSize = +values.product_code_font_size / 1.7857;
    const productNameFontSize = +values.product_name_font_size / 1.7857;
    const productPriceFontSize = +values.price_font_size / 1.7857;
    const productVariantFontSize = +values.variant_font_size / 1.7857;
    const productDateFontSize = +values.date_font_size / 1.7857;
    const productWeightFontSize = +values.weight_font_size / 1.7857;

    return (
        <Wrapper>
            <div className="loader-parent">
                <Loader isLoading={isLoading}/>
                <div className="settings-form pt-4 bg-[white] rounded-b-[5px]">
                    <div className="grid sm:grid-cols-2 gap-8">
                        <form onSubmit={onSave}>
                            <div className="steps-wrapper space-y-1">
                                <Item
                                    getFieldProps={getFieldProps}
                                    values={values}
                                    name="show_product_code"
                                    label="showProductCode"
                                    sizeName="product_code_font_size"
                                />
                                <Item
                                    getFieldProps={getFieldProps}
                                    values={values}
                                    name="show_product_name"
                                    label="showProductName"
                                    sizeName="product_name_font_size"
                                />
                                <Item
                                    getFieldProps={getFieldProps}
                                    values={values}
                                    name="show_price"
                                    label="showProductPrice"
                                    sizeName="price_font_size"
                                />
                                <Item
                                    getFieldProps={getFieldProps}
                                    values={values}
                                    name="show_variant"
                                    label="showProductVariant"
                                    sizeName="variant_font_size"
                                />
                                <Item
                                    getFieldProps={getFieldProps}
                                    values={values}
                                    name="show_date"
                                    label="showGenerateDateCode"
                                    sizeName="date_font_size"
                                />
                                <Item
                                    getFieldProps={getFieldProps}
                                    values={values}
                                    name="show_weight"
                                    label="showProductWeight"
                                    sizeName="weight_font_size"
                                />
                                <div className="flex mt-3">
                                    <button
                                        type="submit"
                                        className={`button sm:w-auto w-full sm:ml-auto primary ${isWaiting ? 'loading-button' : ''}`}
                                    >
                                        {isWaiting && <Loader isLoading/>}
                                        {getLangText('saveButton')}
                                    </button>
                                </div>
                            </div>
                        </form>
                        <div
                            className="qr-code-preview max-w-[220px]"
                        >
                            <div className="font-bold text-sm mb-2">{getLangText('previewLabel')}</div>
                            <div>
                                <img src="/assets/images/qrcode.png"/>
                            </div>
                            <div className="mt-2 text-sm" style={{lineHeight: '18px'}}>
                                {!!+values.show_product_code &&
                                    <div
                                        style={{
                                            lineHeight: productCodeFontSize + 2 + 'px',
                                            fontSize: productCodeFontSize
                                        }}
                                        className="font-bold">
                                        {product_code} 123 {getLangText('black')} M
                                    </div>
                                }
                                {!!+values.show_product_name &&
                                    <div style={{
                                        lineHeight: productNameFontSize + 2 + 'px',
                                        fontSize: productNameFontSize
                                    }}
                                    >
                                        {getLangText('testProduct')}
                                    </div>
                                }
                                {!!+values.show_price &&
                                    <div
                                        style={{
                                            lineHeight: productPriceFontSize + 2 + 'px',
                                            fontSize: productPriceFontSize
                                        }}
                                    >
                                        {getLangText('priceLabel')}: <strong>99&nbsp;{currency_info?.text}</strong>
                                    </div>
                                }
                                {!!+values.show_variant &&
                                    <div
                                        style={{
                                            lineHeight: productVariantFontSize + 2 + 'px',
                                            fontSize: productVariantFontSize
                                        }}
                                    >
                                        {getLangText('variantLabel')}: <strong>{getLangText('black')} M</strong>
                                    </div>
                                }
                                {!!+values.show_date &&
                                    <div
                                        style={{
                                            lineHeight: productDateFontSize + 2 + 'px',
                                            fontSize: productDateFontSize
                                        }}
                                    >
                                        {getLangText('dateOfIssue')}: <strong>{convertToUserTimeZone(new Date()).format('YYYY-MM-DD')}</strong>
                                    </div>
                                }
                                {!!+values.show_weight &&
                                    <div
                                        style={{
                                            lineHeight: productWeightFontSize + 2 + 'px',
                                            fontSize: productWeightFontSize
                                        }}
                                    >
                                        {getLangText('weightLabel')}: <strong>0.5</strong>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};

export default QRProductSettings;
