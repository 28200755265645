import ListReducer from "../../../../../src_shared/list/ListReducer.js";
import createReducer from "../../../shared/helpers/createReducer.js";
import Composition from "../../../shared/helpers/Composition.js";
import CategoriesListModel from "../../../../../modules/models/shopSettings/ProductsList";

export const getStateRoot = (state) => state.products.categories.list;
export const prefix = 'CATEGORIES_LIST_';

const defaultState = {
	sortBy: '',
	sortDir: '',
}

const getInitState = () => ListReducer.getInitState(CategoriesListModel.getModel(), defaultState);

const getReduceMap = () =>  new Composition(
	ListReducer.getReduceMap(),
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

