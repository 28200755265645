import React from 'react';
import OrdersBoxes from "./components/OrdersBoxes";
import OrdersChart from "./components/OrdersChart";
import useLang from "../../../src_shared/hooks/useLang";
import {DASHBOARD_MAIN_TRIAL} from "../shared/enums/TutorialVideoStatsTypes";
import Iframe from "react-iframe-click";
import {usePostHog} from "posthog-js/react";
import Collapses from "./components/Collapeses";
import TutorialsVideo from "../shared/enums/TutorialsVideo";
import {useSelector} from "react-redux";
import SelmoAppLanding from "./components/SelmoAppLanding";

const TrialView = ({data}) => {
    const {getLangText} = useLang();
    const posthog = usePostHog();
    const {lang} = useSelector((state) => state.i18n);
    const {userData} = useSelector((state) => state.session);

    const getVideoSrc = () => {
        const matched = TutorialsVideo.find((i) => i.id === DASHBOARD_MAIN_TRIAL);

        if (!!+userData.instagram_automation && +userData.client_type === 2) {
            return matched[`src_ig_${lang}`] || null;
        }

        return matched[`src_${lang}`] || null;
    }

    const setPosthogEvent = () => {
        if (posthog) {
            posthog?.capture('TUTORIAL VIDEO', {type: DASHBOARD_MAIN_TRIAL})
        }
    };

    return (
        <div className="fadeInProgressAnimation">
            {/*<div className="bg-[#fff] rounded-[5px] shadow-default-small p-6 sm:p-12">*/}
            {/*    <SelmoAppLanding/>*/}
            {/*</div>*/}
            <div className="max-w-[600px] sm:p-0 py-6 px-3 mx-auto space-y-6">
                {!!getVideoSrc() &&
                    <div className="dashboard-card">
                        <div className="dashboard-card-title">{getLangText('seeTheTutorialVideoLabel')}</div>
                        <div className="dashboard-card-subtitle">{getLangText('learnMoreSelmoLabel')}</div>
                        <div className="selmo-video-wrapper -mx-2 -mb-2 mt-3">
                            <Iframe
                                autoPlay={false}
                                onInferredClick={setPosthogEvent}
                                frameBorder="0" allowFullScreen="" scrolling="no"
                                allow="fullscreen"
                                src={getVideoSrc()}
                                style={{
                                    position: 'absolute',
                                    height: '100%',
                                    width: '100%',
                                    left: '0px',
                                    top: '0px'
                                }}
                            />
                        </div>
                    </div>
                }
                <div className="dashboard-card">
                    <div className="dashboard-card-title">{getLangText('startWithSelmoLabel')}</div>
                    <div className="dashboard-card-subtitle">{getLangText('startWithSelmoSubLabel')}</div>
                    <Collapses data={data}/>
                </div>
                <OrdersBoxes data={data.orderStatusSummary}/>
                <OrdersChart data={data.ordersCounter}/>
            </div>
        </div>
    );
};

export default TrialView;
