import ListActions from "../../../../../src_shared/list/ListActions";
import {getStateRoot, prefix} from './listReducer';
import {services} from "../../../../RestServices";

export const getInstance = () => new ListActions({
	getStateRoot,
	restService: services.API_CLIENT_RESERVE_LIST,
	prefix,
	preventPushParams: true,
});

export default getInstance();
