import React, {useRef} from 'react';
import useDropdown from "./useDropdown.js";

const ProductDropdown = ({children, icon = 'icon-dots'}) => {

	const buttonRef = useRef(null)
	const dropdownRef = useRef(null)

	const {open, setOpen} = useDropdown(buttonRef, dropdownRef);

	return (
		<>
			<div ref={dropdownRef} className="react-dropdown position-relative">
				<button
					className="dropdown-toggle"
					onClick={() => setOpen(!open)}
					ref={buttonRef}
				>
					<i className={icon}/>
				</button>
				{open &&
					<div className="dropdown-menu show">
						{children}
					</div>
				}
			</div>
		</>
	)
};

export default ProductDropdown;
