import React from 'react';
import useFormLocal from "../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../RestServices";
import useLang from "../../../../src_shared/hooks/useLang";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import SettingsDiscountCodesModel from "../../../../modules/models/settings/SettingsDiscountCodesModel";
import {useSelector} from "react-redux";
import DiscountCodeForm from "../components/DiscountCodeForm";
import useDataApi from "../../../../src_shared/hooks/useDataApi";
import Loader from "../../../app/shared/Loader";
import {Helmet} from "react-helmet";
import {SHOP_ORIGIN_PL} from "../../../app/shared/enums/ShopOrigins";
import {TEST_ACCOUNT_SHOP_ID} from "../../../../src_shared/enums/TrustedShopsIds";

const DiscountCodes = () => {
    const {getLangText} = useLang();
    const {lang} = useSelector((state) => state.i18n);
    const [{fetchData, data, isLoading}] = useDataApi(services.API_SETTINGS_DISCOUNT_CODES, [])
    const {userData} = useSelector((state) => state.session);

    const afterSubmit = async () => {
        await fetchData()
        updateValues(SettingsDiscountCodesModel.getModel());
        setIsValidationActive(false);
    }

    const [{
        onSave,
        isWaiting,
        getFieldProps,
        updateValues,
        values,
        setIsValidationActive,
    }] = useFormLocal({
        rest: services.API_SETTINGS_DISCOUNT_CODES,
        model: SettingsDiscountCodesModel,
        initialAutoLoad: false,
        afterSubmit,
    });

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getLangText('discountCodesLabel')} - Selmo</title>
            </Helmet>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <Link to={RouterPaths.Settings}>{getLangText('asideSettingsLabel')}</Link>
                    </li>
                    <li>
                        <Link to={RouterPaths.SettingsShop}>{getLangText('settings_nav_shop_settings')}</Link>
                    </li>
                    <li className="active">
                        <div>{getLangText('discountCodesLabel')}</div>
                    </li>
                </ul>
            </div>
            <div className="settings-page sub-settings-page">
                <div className="header-wrapper">
                <div className="d-flex flex-wrap">
                        <div className="flex-grow-1">
                            <h1>
                                <img src="/assets/images/settings/discount.svg" alt="discount"/>
                                {getLangText('discountCodesLabel')}
                            </h1>
                            <div className="description">{getLangText('settings_discount_text_1')}</div>
                        </div>
                        {lang === SHOP_ORIGIN_PL &&
                            <div>
                                <a href="https://www.selmo.io/blog/jak-wykorzystac-kody-rabatowe-w-sprzedazy"
                                   target="_blank"
                                   rel="nofollow noreferrer"
                                   className="button border-button small-size how-it-works"
                                >
                                    {getLangText('paymentsHowItWorksLabel')}
                                </a>
                            </div>
                        }
                    </div>
                </div>
                <div className="settings-form">
                    <section>
                        <div className="left-side">
                            <h2 className="label">{getLangText('discountCodesLabel')}</h2>
                        </div>
                        <div className="form-col">
                            <div className="form-info-box red w-100 mb-4">
                                <div>
                                    <i className="icon-info-c"/>
                                </div>
                                <div>
                                    <div className="title">{getLangText('settings_discount_text_2')}</div>
                                    <div className="desc">{getLangText('settings_discount_text_3')}</div>
                                </div>
                            </div>
                            <DiscountCodeForm
                                getFieldProps={getFieldProps}
                                onSave={onSave}
                                isWaiting={isWaiting}
                                values={values}
                            />
                        </div>
                    </section>
                    <section>
                        <div className="left-side">
                            <h2 className="label">
                                {getLangText('settings_discount_text_6')}
                                <span>{getLangText('discountCodesLabel')}</span>
                            </h2>
                        </div>
                        <div className="form-col loader-parent">
                            <Loader isLoading={isLoading}/>
                            {data?.length ?
                                <div className="div-table">
                                    <div className="table-head">
                                        <div className="table-row">
                                            <div className="name">{getLangText('name')}</div>
                                            <div
                                                className="small-col">{getLangText('input_label_discount_value')}</div>
                                            <div
                                                className="small-col">{getLangText('input_label_free_shipping')}</div>
                                            {(+userData.id === TEST_ACCOUNT_SHOP_ID || +userData.id === 5316) &&
                                                <div className="medium-col">{getLangText('withoutShippingLabel')}</div>
                                            }
                                            <div className="small-col">{getLangText('active_label_2')}</div>
                                            <div className="action-button-col"/>
                                        </div>
                                    </div>
                                    <div className="table-body">
                                        {data?.map((code) => (
                                            <Link
                                                key={code.id}
                                                to={`${RouterPaths.SettingsDiscountCodesEdit}/${code.id}/`}
                                                className="table-row">
                                                <div className="name">
                                                    {code.name}
                                                </div>
                                                <div className="small-col">
                                                    {!!+code.free_shipping ?
                                                        '-' :
                                                        `${code.value} %`
                                                    }
                                                </div>
                                                <div className="small-col icon-status">
                                                    {!!+code.free_shipping ?
                                                        <i className="icon-tick"/>
                                                        :
                                                        <i className="icon-cross"/>
                                                    }
                                                </div>
                                                {(+userData.id === TEST_ACCOUNT_SHOP_ID || +userData.id === 5316) &&
                                                    <div className="medium-col icon-status">
                                                        {!!+code.discount_without_shipping ?
                                                            <i className="icon-tick"/>
                                                            :
                                                            <i className="icon-cross"/>
                                                        }
                                                    </div>
                                                }
                                                <div className="small-col icon-status">
                                                    {!!+code.is_active ?
                                                        <i className="icon-tick"/>
                                                        :
                                                        <i className="icon-cross"/>
                                                    }
                                                </div>
                                                <div className="action-button-col">
                                                    <div className="button border-button no-hover small-size">
                                                        {getLangText('edit')}
                                                        <i className="icon-arrow"/>
                                                    </div>
                                                </div>
                                            </Link>
                                        ))}
                                    </div>
                                </div> :
                                <div className="danger-sellfie-alert">
                                    <i className="icon-info"/>
                                    <div>
                                        {getLangText('settings_discount_text_7')}
                                    </div>
                                </div>
                            }
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
};

export default DiscountCodes;
