import React from 'react';
import Chart from "react-apexcharts";
import {ORDER_PAYMENT_STATUES} from "../../../shared/enums/PaymentStatuses";
import EmptyList from "../../../shared/EmptyList";
import useLang from "../../../../../src_shared/hooks/useLang";

const PaymentChart = ({items}) => {
	const {getLangText} = useLang();

	const getPercentValue = (value) => Math.round((100 * +value) / totalSum);
	const totalSum = items?.paymentInfo?.reduce((prev, curr) => +prev + +curr.value, []);

	const preparedPaymentsInfo = items?.paymentInfo?.map((i) => getPercentValue(i.value));

	const options = {
		chart: {
			id: "payments-donuts",
			type: 'donut',
		},
		colors: ['#1C4ED8', '#5FA5FA', '#90E8EE', '#FF8100', '#FFD133', '#3B29CC'],
		opacity: 1,
		stroke: {
			width: 0,
		},
		legend: {
			show: false
		},
		dataLabels: {
			enabled: false
		},
		labels: items?.paymentInfo?.map((i) => getLangText(ORDER_PAYMENT_STATUES.find((payment) => i.name === payment.value)?.label)),
		tooltip: {
			custom: function ({series, seriesIndex, dataPointIndex, w}) {
				return `<div class="chart-tooltip">
<div class="label">${w.config.labels[seriesIndex]}</div>
<div class="value">${series[seriesIndex]} %</div>
</div>`
			}
		}
	};

	return (
		<div className="default-shadow-box mb-3">
			<div className="donuts-chart-wrapper">
				<div className="title">{getLangText('paymentsLabel')}</div>
				<EmptyList
					className="full-size"
					imgSrc="/assets/images/empty/chart-3.svg"
					items={items.ordersInfo}
					title=""
				>
					<div className="donuts-chart">
						<Chart
							options={options}
							series={preparedPaymentsInfo}
							type="donut"
							width="100%"
						/>
					</div>
				</EmptyList>
				<div className="custom-legend">
					{items?.paymentInfo?.map((i) => (
						<div key={i.name}>
							<div
								className="label">{getLangText(ORDER_PAYMENT_STATUES.find((payment) => payment.value === i.name)?.label)}</div>
							<div className="value">
								{getPercentValue(i.value)} %<strong>{i.value}</strong>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default PaymentChart;
