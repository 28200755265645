import ListActions, {getGETParamsUrl} from "../../../../../../src_shared/list/ListActions";
import {getStateRoot, prefix} from './listReducer';
import {batch} from "react-redux";
import axios from "axios";
import {selmoUrl} from "../../../../../../src_shared/api/api";
import {services} from "../../../../../RestServices";
import _ from "lodash";
import jwt_decode from "jwt-decode";
import SessionActions from "../../../../shared/session/SessionActions";
import LangsActions from "../../../../shared/langs/LangsActions";
import api from "../../../../../../services/axios/axios";

export class LiveCommentsListActions extends ListActions {


	startFacebookStreaming(info, is_finished, videoId) {
		return (dispatch) => {
			const decodedAccessToken = jwt_decode(info)
			if (videoId) {
				dispatch(this.setVideoId(videoId))
			}
			if (!+is_finished) {
				var source = new EventSource("https://streaming-graph.facebook.com/" + videoId + "/live_comments?access_token=" + decodedAccessToken?.access_token + "&comment_rate=one_per_two_seconds&fields=from{name,id,picture},id,message,created_time");

				source.onmessage = (event) => {
					let commentInfo = JSON.parse(event.data);
					const preparedComment = {
						id: commentInfo.id,
						message: commentInfo.message,
						user_name: commentInfo?.from?.name,
						user_avatar: commentInfo.from?.picture?.data?.url,
						date: commentInfo.created_time,
						fromFacebookSocket: true,
					}
					if (commentInfo) {
						dispatch(this.updateLiveCommentsFromPusher(preparedComment))
					}
				};
			}
		}
	}

	tryToStartFacebookStreaming(id) {
		return async (dispatch, getState) => {
			const params = this.getLoadParams(getState(), id);

			try {
				const response = await api.get(`${selmoUrl}/${this.restService}${getGETParamsUrl(params)}`)
				const {videoId, info, is_finished} = response.data;
				dispatch(this.startFacebookStreaming(info, is_finished, videoId))
			} catch (e) {
				console.error('Cannot update streaming')
			}
		}
	}

	loadData(id) {
		return async (dispatch, getState) => {
			const {firstInit} = getState().session;
			dispatch(this.waiting.startWaiting())
			if (firstInit) {
				dispatch(this.onFirstInit());
			}

			const params = this.getLoadParams(getState(), id)

			try {
				const response = await api.get(`${selmoUrl}/${this.restService}${getGETParamsUrl(params)}`)
				const {items, total, videoId, info, is_finished} = response.data;

				batch(() => {
					dispatch(this.table.updateAllItems(items))
					dispatch(this.setReversedItems(items))
					dispatch(this.pagination.updateTotalItems(total))
				})

				dispatch(this.startFacebookStreaming(info, is_finished, videoId))


			} catch (e) {
				dispatch(this.table.getListError(e))
				console.error(e)
			} finally {
				if (firstInit) {
					dispatch(SessionActions.setFirstInit());
				}
				dispatch(this.waiting.stopWaiting())
			}
		}
	}

	isCommentNotIncludeInSearch(comment, search) {
		const userName = comment?.user_name.toLowerCase();
		const message = comment?.message.toLowerCase();
		const includeUserName = userName.includes(search);
		const includeMessageContent = message.includes(search);
		return !(includeUserName || includeMessageContent);
	}

	updateLiveCommentsFromPusher(data) {
		return (dispatch, getState) => {
			const {innerWidth: width} = window;
			const {commentsPanelVisible, scrollTouched, search} = this.getStateRoot(getState());
			const isCommentNotIncludeInSearch = this.isCommentNotIncludeInSearch(data, search?.toLowerCase());

			if (!commentsPanelVisible) {
				return;
			}

			if (!!search.length && isCommentNotIncludeInSearch) {
				return;
			}

            if (data.removed) {
                return;
            }

			if (data.fromPianoComment) {
				dispatch(this.table.updateItem(data.id, 'has_response', "1"))
			} else {
				dispatch(this.table.addItemToBegin(data))
				dispatch(this.addCommentFromSocket(data))
				dispatch(this.updateScrollAfterSocketMessage(true))

				if (!scrollTouched && width < 767) {
					document.querySelector('.comments-wrapper .scroll-content')?.scrollTo(0, -1)
					document.querySelector('.comments-wrapper .scroll-content')?.scrollTo(0, 0)
				}
			}
		}
	}

	showCommentsPanel(toggle) {
		return {
			type: `${this.prefix}SHOW_COMMENTS_PANEL`, toggle,
		};
	}

	setScrollTouched(toggle) {
		return {
			type: `${this.prefix}SET_SCROLL_TOUCHED`, toggle,
		};
	}

	updateScrollAfterSocketMessage(toggle) {
		return {
			type: `${this.prefix}UPDATE_SCROLL_AFTER_SOCKET_MESSAGE`,
			toggle,
		};
	}

	setScrollToBottom(toggle) {
		return {
			type: `${this.prefix}SCROLL_TO_BOTTOM`,
			toggle,
		};
	}

	setWaitingForFirstPage(toggle) {
		return {
			type: `${this.prefix}SET_WAITING_FOR_FIRST_PAGE`,
			toggle,
		};
	}

	replyToComment(comment_id) {
		return async () => {
			try {
				await api.post(`${selmoUrl}/${services.API_LIVE_COMMENTS_REPLY_TO_COMMENT}`, {
					comment_id
				})
			} catch (e) {
				console.error('Cannot reply to comment')
			}
		}
	}

	loadNewPage(id) {
		return async (dispatch, getState) => {
			try {
				const state = this.getStateRoot(getState());

				const params = getGETParamsUrl({
					page: state.scrollPage + 1,
					superSearch: state.search,
				});

				const {data} = await api.get(`${selmoUrl}/${this.restService}/${id}${params}`)

				const {items} = state;
				const updatedItems = items.concat(data.items);
				const uniq = _.uniqBy(updatedItems, 'id')

				batch(() => {
					dispatch(this.table.updateAllItems(uniq));
					dispatch(this.setScrollPage(state.scrollPage + 1));
				});

				if (!data.hasMore) {
					dispatch(this.setHasMorePages(false));
				}
			} catch (e) {
				console.warn('Cannot get next page')
			}
		};
	}

	loadReversedData() {
		return async (dispatch, getState) => {
			const {firstInit} = getState().session;
			dispatch(this.setWaitingForFirstPage(true));
			if (firstInit) {
				dispatch(this.onFirstInit());
			}

			dispatch(this.pagination.changePage(1));
			dispatch(this.setScrollPage(1));

			const params = this.getLoadParams(getState())

			try {
				const response = await api.get(`${selmoUrl}/${services.API_LIVE_REVERTED_COMMENTS}${getGETParamsUrl(params)}`)
				const {items, total, videoId, info, is_finished} = response.data;

				batch(() => {
					dispatch(this.setReversedItems(items))
					dispatch(this.pagination.updateTotalItems(total))
				})

				dispatch(this.setScrollToBottom(true))
				dispatch(this.startFacebookStreaming(info, is_finished, videoId))


			} catch (e) {
				dispatch(this.table.getListError(e))
				console.error(e)
			} finally {
				if (firstInit) {
					dispatch(SessionActions.setFirstInit());
				}
				dispatch(this.setWaitingForFirstPage(false));
			}
		}
	}

	setReversedItems(items) {
		return {
			type: `${this.prefix}SET_REVERSED_ITEMS`,
			items,
		};
	}

	loadNewPageVirtualized(id, listRef) {
		return async (dispatch, getState) => {
			const state = this.getStateRoot(getState());
			const {reversedItems} = state;

			const lastIndex = reversedItems[0]?.id || null;

			dispatch(this.waiting.startWaiting())
			try {
				const params = getGETParamsUrl({
					page: state.scrollPage + 1,
					lastId: lastIndex,
					superSearch: state.search,
				});

				const {data} = await api.get(`${selmoUrl}/${services.API_LIVE_REVERTED_COMMENTS}/${id}${params}`);

				const uniq = _.uniqBy([...data.items, ...reversedItems], 'id')

				batch(() => {
					dispatch(this.setReversedItems(uniq))
					dispatch(this.setScrollPage(state.scrollPage + 1));
				});

				if (listRef) {
					setTimeout(() => {
						listRef.current.scrollToItem(data.items.length, 'start')
					}, 0)
				}

				if (!data.hasMore) {
					dispatch(this.setHasMorePages(false));
				}
			} catch (e) {
				console.warn('Cannot get next page')
			} finally {
				dispatch(this.waiting.stopWaiting())
			}
		};
	}


	addCommentFromSocket(item) {
		return {
			type: `${this.prefix}ADD_COMMENT_FROM_SOCKET`,
			item,
		};
	}

	setScrollPage(scrollPage) {
		return {
			type: `${this.prefix}SET_SCROLL_PAGE`, scrollPage,
		};
	}

	setHasMorePages(toggle) {
		return {
			type: `${this.prefix}SET_HAS_MORE_PAGES`, toggle,
		};
	}

	setVideoId(id) {
		return {
			type: `${this.prefix}SET_VIDEO_ID`,
			id,
		}
	}
}

export const getInstance = () => new LiveCommentsListActions({
	getStateRoot, restService: services.API_LIVE_COMMENTS, prefix, preventPushParams: true,
});

export default getInstance();
