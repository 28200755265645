import React from 'react';
import useFormLocal from "../../../src_shared/hooks/useFormLocal";
import {services} from "../../RestServices";
import useLang from "../../../src_shared/hooks/useLang";
import SwitchField from "../../../src_shared/components/form/switchField/SwitchField";
import Loader from "../../app/shared/Loader";
import ComponentLoader from "../../app/shared/components/componentLoader/ComponentLoader";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../routes/RouterPath";
import Model from "../../../src_shared/modules/model/Model";
import {Helmet} from "react-helmet";
import Field from "../../../src_shared/components/form/field/Field";

const MobileApp = () => {
	const {getLangText} = useLang();

	const [{
		onSave,
		isWaiting,
		getFieldProps,
		isLoading,
		values,
	}] = useFormLocal({
		rest: services.API_MOBILE_NOTIFICATIONS,
		model: new Model(),
	});

	if (isLoading) return <ComponentLoader/>;

	return (
		<>
			<Helmet>
				<meta charSet="utf-8"/>
				<title>{getLangText('mobileAppSelmo')} - Selmo</title>
			</Helmet>
			<div className="header-bar breadcrumbs-in-react-app">
				<ul className="breadcrumbs">
					<li>
						<Link to={RouterPaths.Settings}>{getLangText('asideSettingsLabel')}</Link>
					</li>
					<li>
						<Link to={RouterPaths.SettingsShop}>{getLangText('settings_nav_shop_settings')}</Link>
					</li>
					<li className="active">
						<div>{getLangText('mobileAppSelmo')}</div>
					</li>
				</ul>
			</div>
			<div className="settings-page sub-settings-page">
				<div className="header-wrapper">
					<h1>
						{/*<img src="/assets/images/settings/live-settings.svg" alt="live-settings"/>*/}
						{getLangText('mobileAppSelmo')}
					</h1>
					<div className="description">
						{getLangText('mobileAppSelmoSubLabel')}
					</div>
				</div>
				<div className="settings-form">
					<section>
						<div className="left-side">
							<h2 className="label">
								{getLangText('visibilityInApplication')}
							</h2>
						</div>
						<div className="form-col">
							<SwitchField
								{...getFieldProps('releasing_active')}
								label={getLangText('automaticProductRelease')}
								subLabel={getLangText('productWillReleaseAfterSpecificTime')}
								id="releasing_active"
								className="switch-form inline-label big-label align-items-start light-version mb-3"
							/>
							{!!+values.releasing_active &&
								<>
									<div className="ml-[44px]">
										<div className="form-label">
											{getLangText('minutesWillProductRelease')}
										</div>
										<Field
											{...getFieldProps('releasing_minutes')}
											className="hidden-arrows mt-2"
											style={{maxWidth: '170px',}}
											showLabel={false}
											addon={getLangText('minutesLabel')}
											type="number"
											extraProps={{
												min: 0,
											}}
										/>
									</div>
									<div className="ml-[44px] mb-4 mt-3">
										<div className="form-label">
											{getLangText('minutesWillSentPushAboutProductsRelease')}
										</div>
										<Field
											{...getFieldProps('notification_minutes')}
											className="hidden-arrows mt-2"
											showLabel={false}
											style={{maxWidth: '170px',}}
											addon={getLangText('minutesLabel')}
											type="number"
											extraProps={{
												min: 0,
											}}
										/>
									</div>
								</>
							}
							<SwitchField
								{...getFieldProps('mobile_app_orders')}
								label={getLangText('orderHistoryVisible')}
								subLabel={getLangText('orderHistoryVisibleSubLabel')}
								id="mobile_app_orders"
								className="switch-form inline-label big-label align-items-start light-version mb-3"
							/>
							<SwitchField
								{...getFieldProps('mobile_app_reserve_list')}
								label={getLangText('reserveListVisible')}
								subLabel={getLangText('reserveListVisibleSubLabel')}
								id="mobile_app_reserve_list"
								className="switch-form inline-label align-items-start big-label light-version mb-3"
							/>
							<SwitchField
								{...getFieldProps('mobile_app')}
								label={getLangText('shopVisibleInAppLabel')}
								id="mobile_app"
								className="switch-form inline-label  big-label light-version mb-0"
							/>
						</div>
					</section>
					<section>
						<div className="left-side">
							<h2 className="label">
								{getLangText('automaticMessage')}
							</h2>
						</div>
						<div className="form-col">
							<SwitchField
								{...getFieldProps('mobile_app_daily_message')}
								label={getLangText('automaticResponseForMessage')}
								subLabel={getLangText('automaticResponseForMessageSubLabel')}
								id="mobile_app_daily_message"
								className="switch-form inline-label big-label align-items-start light-version mb-3"
							/>
						</div>
					</section>
					<div className="flex items-center w-full">
						<button
							type="button"
							className={`button primary ml-auto small-size ${isWaiting ? 'loading-button' : ''}`}
							onClick={onSave}
						>
							{isWaiting && <Loader isLoading/>}
							{getLangText('saveButton')}
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default MobileApp;
