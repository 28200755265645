import React from 'react';
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import useLang from "../../../../src_shared/hooks/useLang";
import {Helmet} from "react-helmet";

const Wrapper = ({children}) => {
    const {getLangText} = useLang();

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>MobilePay - Selmo</title>
            </Helmet>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <Link to={RouterPaths.Settings}>
                            {getLangText('asideSettingsLabel')}
                        </Link>
                    </li>
                    <li>
                        <Link to={RouterPaths.SettingsPayments}>
                            {getLangText('paymentsLabel')}
                        </Link>
                    </li>
                    <li className="active">
                        <div>MobilePay</div>
                    </li>
                </ul>
                <Link
                    to={RouterPaths.SettingsPayments}
                    className="button back-to-panels mb-3">
                    <i className="icon-arrow-left"/>
                    {getLangText('button_back_to_payment')}
                </Link>
            </div>
            <div className="row small-padd-row">
                <div className="col-lg-7">
                    <div className="settings-page sub-settings-page export-sub-settings">
                        <div className="header-wrapper no-border pb-0">
                            <h1>
                                <a
                                    rel="noreferrer"
                                    href="https://vippsmobilepay.com/"
                                    target="_blank"
                                    className="no-underline"
                                >
                                    <img style={{width: 'auto', height: '30px'}}
                                         src="/assets/images/payments/mobilepay.svg"
                                         alt="MobilePay"/>
                                </a>
                            </h1>

                        </div>
                        {children}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Wrapper;
