import {getStateRoot, prefix} from './listReducer';
import {services} from "../../../../RestServices";
import {SharedEditProductListActions} from "../shared/SharedEditProductListActions";
import CheckboxMultiselectActions
	from "../../../../../src_shared/list/checkboxMultiselect/CheckboxMultiselectActions";

export class ProductReserveListActions extends SharedEditProductListActions {
	constructor(config) {
		super(config);
		this.selectedItems = new CheckboxMultiselectActions({
			prefix: this.prefix,
			name: 'selectedItems',
		});
	}

	updateSendBatchFromPusher = () => {
		return (dispatch, getState) => {
			const {waitingForSend} = this.getStateRoot(getState());
			if (waitingForSend) {
				dispatch(this.setWaitingForSend(false));
			}
		}
	}
}
export const getInstance = () => new ProductReserveListActions({
	getStateRoot,
	restService: services.API_PRODUCT_RESERVE_LIST,
	prefix,
	preventPushParams: true,
});

export default getInstance();
