import React, {useState} from 'react';
import useLang from "../../../../../../../src_shared/hooks/useLang";
import {services} from "../../../../../../RestServices";
import {Modal} from "react-bootstrap";
import Loader from "../../../../../../../src_shared/components/loader/Loader";
import TextArea from "../../../../../../../src_shared/components/form/textarea/TextArea";
import useSubmitData from "../../../../../../../src_shared/hooks/useSubmitData";
import {useSelector} from "react-redux";
import {createSelector} from "reselect";
import {getStateRoot} from "../../../listReducer";
import useFormValues from "../../../../../../../src_shared/hooks/useFormValues";
import SwitchField from "../../../../../../../src_shared/components/form/switchField/SwitchField";

const SendMessageModal = ({isOpen, hideModal}) => {

    const {getLangText} = useLang();
    const [success, setSuccess] = useState(false);
    const {selectedItems} = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));

    const [values, setValues] = useState({
        message: '',
        order_link: '0',
    });
    const setValue = (field, value) => setValues({...values, [field]: value})
    const getFieldProps = (source) => {

        return {
            name: source,
            value: values[source],
            validationData: values,
            setValue,
        };
    };

    const afterSubmit = () => setSuccess(true);

    const [{onSave, isWaiting}] = useSubmitData({
        rest: services.API_BATCH_ORDER_MESSAGES,
        values: {
            ...values,
            orders: selectedItems.map((i) => i.id),
        },
        afterSubmit,
    });

    return (
        <Modal
            show={isOpen}
            className="add-order-modal"
            dialogClassName="default-modal small-modal w-420"
            onHide={hideModal}
        >
            {success ?
                <div className={`summary-generated-view`}>
                    <button
                        onClick={hideModal}
                        type="button"
                        className="button close-modal"
                    >
                        <i className="icon-cross"/>
                    </button>
                    <div className="circle-icon">
                        <i className="icon-tick"/>
                    </div>
                    <div className="title">{getLangText('readyLabel')}!</div>
                    <div className="desc whitespace-pre-wrap">
                        {getLangText('sentMessageHasBeenRequested')}
                    </div>
                    <div className="modal-footer">
                        <button
                            onClick={hideModal}
                            type="button"
                            className="button w-full m-0 border-button"
                        >
                            {getLangText('closeButton')}
                        </button>
                    </div>
                </div> :
                <React.Fragment>
                    <Modal.Header>
                        <Modal.Title>
                            {getLangText('sendBatchMessages')}
                            <div className="text-desc text-sm font-medium">
                                {getLangText('sendBatchMessagesSubLabel')}
                            </div>
                        </Modal.Title>
                        <button
                            onClick={hideModal}
                            type="button"
                            className="button"
                        >
                            <i className="icon-cross"/>
                        </button>
                    </Modal.Header>
                    <form onSubmit={onSave}>
                        <Modal.Body className="add-order-modal-body">
                            <TextArea
                                {...getFieldProps('message')}
                                label={getLangText('messageLabel')}
                                placeholder={getLangText('enterMessageLabel')}
                                extraProps={{required: true}}
                            />
                            <SwitchField
                                {...getFieldProps('order_link')}
                                className="switch-form align-items-start inline-label big-label light-version mb-0 mt-0 p-3 border border-gray-200 rounded-md w-full"
                                label={getLangText('orderLinkLabel')}
                                subLabel={getLangText('orderLinkTooltipLabel')}
                                id="order_link"
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                onClick={hideModal}
                                type="button"
                                className="button border-button"
                            >
                                {getLangText('cancelButton')}
                            </button>
                            <button
                                type="submit"
                                className={`button primary ${isWaiting ? 'loading-button' : ''}`}
                            >
                                {isWaiting && <Loader isLoading/>}
                                {getLangText('sendButton')}
                            </button>
                        </Modal.Footer>
                    </form>
                </React.Fragment>
            }
        </Modal>
    );
};

export default SendMessageModal;
