import Model from "../../../src_shared/modules/model/Model";

class SettingsFacebookModel extends Model {
    getModel() {
        return {
            notification_status: '0',
            feed_status: '0',
            buy_message_status: '0',
            import_status: '0',
        };
    }

    buildDTO(data) {
        const {notification_status, feed_status, buy_message_status, import_status} = data;
        return {notification_status, feed_status, buy_message_status, import_status}
    }
}

export default new SettingsFacebookModel();
