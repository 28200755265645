import React from 'react';
import PropTypes from 'prop-types';
import {Route, Redirect} from 'react-router-dom';
import {RouterPaths} from "./RouterPath";
import {useSelector} from "react-redux";
import {TEST_ACCOUNT_SHOP_ID} from "../../src_shared/enums/TrustedShopsIds";

export const PrivateTestRoute = ({component: Component, ...rest}) => {

	const {userData, waitingForResponse} = useSelector((state) => state.session);

	const isTestUser = +userData.id === TEST_ACCOUNT_SHOP_ID;

	if (!isTestUser && !waitingForResponse) {
		return <Redirect to={RouterPaths.Dashboard}/>;
	}

	return (
		<Route
			component={Component}
			{...rest}
		/>
	);
};

PrivateTestRoute.propTypes = {
	component: PropTypes.elementType,
};

PrivateTestRoute.defaultProps = {
	component: undefined,
};

export default PrivateTestRoute;
