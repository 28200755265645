import ListActions from "../../../../../src_shared/list/ListActions.js";
import {getStateRoot, prefix} from './listReducer.js';
import {RouterPaths} from "../../../../routes/RouterPath.js";
import {services} from "../../../../RestServices.js";

export class CollectionsListActions extends ListActions {
}

export const getInstance = () => new CollectionsListActions({
	getStateRoot,
	restService: services.API_COLLECTIONS,
	pathname: RouterPaths.CollectionsList,
	prefix,
});

export default getInstance();
