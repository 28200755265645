import React, {useEffect, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {useDispatch, useSelector} from "react-redux";
import SelectSizesModalActions from "./SelectSizesModalActions";
import useLang from "../../../../../src_shared/hooks/useLang";
import {useUnitPrice} from "../../helpers/Price";
import ServerImage from "../serverImage/ServerImage";

const SelectSizesModal = ({updateFieldsInProduct}) => {

	const dispatch = useDispatch();
	const [selectedSize, selectSize] = useState(null);
	const {product, isOpen, id} = useSelector((state) => state.shared.selectSizesModal)
	const {getLangText} = useLang();
	const {getPrice} = useUnitPrice();

	const hideModal = () => dispatch(SelectSizesModalActions.toggleModalVisibility(false, {}))

	const resetState = () => {
		selectSize(null);
	}

	const onSave = () => {
		const newProductFields = {
			product_price: +selectedSize?.price,
			product_size: selectedSize?.size,
			product_size_id: selectedSize?.id,
			product_code: product?.selmo_code,
			product_photo: product?.photo,
			product_id: product?.id,
			product_max_count: selectedSize?.squ
		}

		updateFieldsInProduct(newProductFields, id);

		hideModal();
		resetState()
	}

	const filteredProductSizes = product.sizes?.filter((i) => +i.squ);

	const productSizeDisabled = (size) => !+size.squ;

	const isVariantsExist = filteredProductSizes?.length > 0;

	const onChangeVariant = (variantId) => {
		selectSize(variantId);
	}

	useEffect(() => {
		if (product.sizes) {
			selectSize(product.sizes.find((i) => +i.squ));
		}
	}, [product.sizes])


	return (
		<Modal
			className="higher-index"
			show={isOpen}
			dialogClassName="default-modal small-modal"
			onHide={hideModal}
		>
			<Modal.Header className="with-border">
				<Modal.Title>{getLangText('selectVariantLabel')}</Modal.Title>
				<button
					onClick={hideModal}
					type="button"
					className="button"
				>
					<i className="icon-cross"/>
				</button>
			</Modal.Header>
			<Modal.Body>
				<div className="select-size-wrapper">
					<div className="product-details">
						<figure>
							{!product?.photo || product?.photo === '0' ?
								<img
									src="/assets/images/default/product.svg"
									alt={product?.product_name}
									className="img-preview"/>
								:
								<ServerImage
									src={product?.photo}
									alt={product?.product_name}
								/>
							}
						</figure>
						<div>
							<div className="product-name">{product?.name}</div>
							<div className="code">{getLangText('codeLabel')}: <strong>{product?.selmo_code}</strong></div>
						</div>
					</div>
					<div className="sizes-wrapper">
						{product.sizes?.map((size) => (
							<div
								key={size.id}
								className="tooltip-parent"
							>
								{productSizeDisabled(size) &&
									<div className="tooltip-content">
										{getLangText('noVariantAvailableLabel')}
									</div>
								}
								<button
									type="button"
									onClick={() => onChangeVariant(size)}
									key={size?.id}
									disabled={productSizeDisabled(size)}
									className={`size-box ${selectedSize?.id === size?.id ? 'active' : ''}`}
								>
									<div>{size?.size}</div>
									<div className="count">{getLangText('availableLabel')}: {size?.squ}</div>
									<div className="price">{getPrice(size?.price)}</div>
								</button>
							</div>
						))}
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<button
					onClick={hideModal}
					type="button"
					className="button border-button"
				>
					{getLangText('cancelButton')}
				</button>
				{isVariantsExist &&
					<button
						type="button"
						className="button primary small-size"
						onClick={onSave}
					>
						{getLangText('saveButton')}
					</button>
				}
			</Modal.Footer>
		</Modal>
	);
};

export default SelectSizesModal;
