import {supportedLangs} from "../../../../services/i18nAuthSInglePage/i18n";

const initialState = {
	waiting: false,
	lang: 'en',
	supportedLangs: {...supportedLangs},
	translations: {}
};

export const selectTranslations = (state) => state.i18nAuthSinglePage.translations;

export default (state = initialState, action) => {
	switch (action.type) {
		case 'LANGS_AUTH_SINGLE_PAGE_SET_LANG':
			return {...state, lang: action.lang};
		case 'LANGS_AUTH_SINGLE_PAGE_SET_TRANSLATIONS':
			return {...state, translations: action.translations};
		default:
			return state;
	}
}
