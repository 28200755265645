import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import FormActions from "./FormActions";
import PaymentRange from "./paymentRange/PaymentRange";
import Functionalities from "./functionalities/Functionalities";
import PaymentTypes from "./paymentTypes/PaymentTypes";
import Summary from "./summary/Summary";
import {useDispatch, useSelector} from "react-redux";
import {Helmet} from "react-helmet";
import {getStateRoot} from "./formReducer";
import {createSelector} from "reselect";
import PaymentsSelectSkeleton from "./skeletons/PaymentsSelectSkeleton";
import InvoiceData from "./invoiceData/InvoiceData";
import useLang from "../../../src_shared/hooks/useLang";
import {usePostHog} from "posthog-js/react";
import {useUnitPrice} from "../shared/helpers/Price";
import useWindowDimensions from "../shared/hooks/useWindowDimensions";
import Loader from "../shared/Loader";
import useIsPremium from "../shared/hooks/useIsPremium";
import useLogout from "../shared/hooks/useLogout";
import ReactPixel from "react-facebook-pixel";

export const dniVariants = {
    singular: 'dayLabel',
    plural: 'daysLabel',
    genitive: 'daysLabel',
}

const Payments = ({values, setValue, isWaiting, onSave, getFieldProps, updateValues}) => {

    const dispatch = useDispatch();
    const posthog = usePostHog()
    const {logout} = useLogout();
    const {width} = useWindowDimensions();
    const {userData} = useSelector((state) => state.session);
    const {
        waitingForTotalPrice,
        summaryInfo,
        waitingForPayment
    } = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));

    const {isPremium} = useIsPremium();
    const {getLangText} = useLang();
    const {getPrice} = useUnitPrice();
    const [showSummaryMobile, setShowSummaryMobile] = useState(width > 767);

    const setValueAndUpdatePrice = (field, value) => {
        setValue(field, value)
        dispatch(FormActions.getTotalPrice())
    }

    useEffect(() => {
        if (posthog) {
            if (!!userData?.user_info?.premium_date) {
                posthog?.capture('Wizyta na stronie płatności')
            } else {
                posthog?.capture('ONBOARDING - Wizyta na stronie płatności')
            }
        }

    }, [posthog])

    useEffect(() => {
        setTimeout(() => ReactPixel.track('InitiateCheckout'));
    }, [])

    const handleSave = (e) => {
        e.preventDefault()
        if (posthog) {
            if (!!userData?.user_info?.premium_date) {
                posthog?.capture('Kliknięcie przycisku "Rozpocznij plan"')
            } else {
                posthog?.capture('ONBOARDING - Kliknięcie przycisku "Rozpocznij plan"')
            }
        }
        onSave();
    }

    return (
        <div className="payments-page mb-sm-5 mb-0">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getLangText('customerBasketPaymentLabel')} - Selmo</title>
            </Helmet>
            {isPremium &&
                <Link
                    to="/"
                    className="go-back">
                    <i className="icon-arrow-left"/>
                    {getLangText('backLabel')}
                </Link>
            }
            <div className={`payments-container ${!isPremium ? 'mt-sm-5 mt-3' : ''}`}>
                <form id="purchase-form" onSubmit={handleSave}>
                    <div className="custom-row">
                        <div className="left-column">
                            <PaymentsSelectSkeleton hidden={!isWaiting}>
                                <div className="brand-name">
                                    <img src="/assets/images/logo.svg" alt=""/>
                                </div>
                                {(userData.user_info.afterPremium || userData.user_info.status) ?
                                    <div className="title mt-2">
                                        <h1>{getLangText('paymentsExtendPlanHeader')}</h1>
                                        <p>{getLangText('paymentsExtendPlanDescription')}</p>
                                    </div> :
                                    <div className="title mt-2">
                                        <h1>{getLangText('paymentsNewPlanHeader')}</h1>
                                        <p>{getLangText('paymentsNewPlanDescription')}</p>
                                    </div>
                                }
                                {userData.user_info.afterPremium &&
                                    <div className="form-info-box red w-100 mt-2">
                                        <div>
                                            <i className="icon-info-c"/>
                                        </div>
                                        <div>
                                            <div className="title">{getLangText('paymentsCurrentPlanEndedHeader')}</div>
                                            <div className="desc">
                                                {getLangText('paymentsCurrentPlanEndedDescription')}
                                            </div>
                                        </div>
                                    </div>
                                }
                                {userData.user_info.afterTrial &&
                                    <div className="form-info-box red w-100 mt-2">
                                        <div>
                                            <i className="icon-info-c"/>
                                        </div>
                                        <div>
                                            <div className="title">{getLangText('paymentsAfterTrialPlanHeader')}</div>
                                            <div className="desc">
                                                {getLangText('paymentsAfterTrialPlanDescription')}
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="boxes-wrapper">
                                    <PaymentRange
                                        setValue={setValueAndUpdatePrice}
                                        values={values}
                                        userData={userData}
                                        isWaiting={isWaiting}
                                    />
                                    <Functionalities
                                        setValue={setValueAndUpdatePrice}
                                        values={values}
                                        isWaiting={isWaiting}
                                    />
                                    <PaymentTypes
                                        setValue={setValue}
                                        values={values}
                                    />
                                    <InvoiceData
                                        setValueAndUpdatePrice={setValueAndUpdatePrice}
                                        updateValues={updateValues}
                                        setValue={setValue}
                                        values={values}
                                        getFieldProps={getFieldProps}
                                    />
                                </div>
                                <div className="submit-place d-md-block d-none mt-5">
                                    <button
                                        className="button primary w-100 big-size"
                                        type="submit"
                                    >
                                        {getLangText('paymentsNewPlanHeader')}
                                    </button>
                                    <div className="small-text">
                                        <i className="icon-tick"/>
                                        {getLangText('paymentsCancelInfoLabel')}
                                    </div>
                                </div>
                            </PaymentsSelectSkeleton>
                        </div>
                        <div className="right-column">
                            <div className={`toggle-content ${showSummaryMobile ? 'open' : ''}`}>
                                <button
                                    type="button"
                                    onClick={() => setShowSummaryMobile(!showSummaryMobile)}
                                    className="toggle-header d-md-none"
                                >
                                    {getLangText('customerBasketShowSummaryButton')}
                                    <i className="icon-arrows"/>
                                </button>
                                <div className="toggle-body">
                                    <Summary
                                        selectedSubscription={values.selected_subscription}
                                        values={values}
                                        isWaiting={waitingForTotalPrice}
                                    />
                                </div>
                            </div>
                            <div className="submit-place d-md-none mt-md-5 mt-0 mb-3">
                                <button
                                    className={`button primary w-100 big-size text-left ${waitingForPayment ? 'loading-button' : ''}`}
                                    type="submit"
                                >
                                    <Loader style={{borderRadius: '8px'}} isLoading={waitingForPayment}/>
                                    {getLangText('customerBasketPaymentButton')}
                                    <div className="price">
                                        {getPrice(summaryInfo.totalPrice, values.currencyText)}
                                    </div>
                                </button>
                                <div className="small-text mt-2">
                                    <i className="icon-safe"/>
                                    {getLangText('customerBasketSafetyPaymentButton')}
                                </div>
                            </div>
                            {!isPremium &&
                                <button
                                    className="go-back logout d-block text-left"
                                    type="button"
                                    onClick={logout}
                                >
                                    <div>{getLangText('notYourAccountLabel')}</div>
                                    <div>{getLangText('changeAccountLabel')}</div>
                                </button>
                            }
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Payments;



