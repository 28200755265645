import React, {useEffect, useState} from 'react';
import useLang from "../../../../src_shared/hooks/useLang";
import Modal from "react-bootstrap/Modal";
import AfterCopyView from "./AfterCopyView";
import BeforeCopyView from "./BeforeCopyView";

const Messenger = ({values, params}) => {

	const {getLangText} = useLang();
	const [modalVisible, setModalVisible] = useState(false);
	const [waitingForRedirect, setWaitingForRedirect] = useState(false);
	const [clicked, setClicked] = useState(false);

	const hideModal = () => setModalVisible(false);

	const redirectToMessenger = () => {
		setWaitingForRedirect(true);
		window.location.href = `https://m.me/${values.facebook_id}?ref=SHOP_CART_HASH_${params.hash}`
	}

	useEffect(() => {
		if (!modalVisible) {
			setClicked(false);
		}
	}, [modalVisible])

	return (
		<>
			<div className="button-with-info">
				<button
					type="button"
					className="button primary"
					onClick={() => setModalVisible(true)}
				>
					<i className="icon-messenger-2-f"/>
					{getLangText('continueOnMessengerLabel')}
				</button>
				<div
					className="form-info-box">
					<div>
						<i className="icon-info-c"/>
					</div>
					<div className="title">
						{getLangText('mergeOrdersAndContactWithShopLabel')}
					</div>
				</div>
			</div>
			{modalVisible &&
				<Modal
					dialogClassName="default-modal w-420 attention-basket-modal"
					show={modalVisible}
					onHide={hideModal}
				>
					{clicked ?
						<AfterCopyView
							hideModal={hideModal}
							waitingForRedirect={waitingForRedirect}
							redirectToMessenger={redirectToMessenger}
						/>:
						<BeforeCopyView
							hideModal={hideModal}
							waitingForRedirect={waitingForRedirect}
							clicked={clicked}
							setClicked={setClicked}
							values={values}
							redirectToMessenger={redirectToMessenger}
						/>
					}
				</Modal>
			}
		</>
	);
};

export default Messenger;
