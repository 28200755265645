import {useSelector} from "react-redux";
import {SHOP_ORIGIN_US} from "../enums/ShopOrigins";

export const useUnitPrice = () => {
    const {userData} = useSelector((state) => state.session);
    const getPrice = (priceAmount = 0, unit = userData?.currency_info?.text) => {
        if (userData.origin === SHOP_ORIGIN_US) {
            const preparedPrice = priceAmount?.toString()?.replace(',', '.')
            return `${unit}${preparedPrice}`
        }
        const preparedPrice = priceAmount?.toString().replace('.', ',')
        return `${preparedPrice} ${unit}`;
    }

    return {
        getPrice,
    }
}
