import {prefix} from "./formReducer";
import {getStateRoot} from "./formReducer";
import {services} from "../../../RestServices";
import ProductCodeModel from "../../../../modules/models/settings/ProductCodeModel";
import FormActions from "../../../../src_shared/form/FormActions";

export const getInstance = () => new FormActions({
	prefix,
	getStateRoot,
	restService: services.API_OWN_PRODUCT_CODE,
	model: ProductCodeModel,
});

export default getInstance();

