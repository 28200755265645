import {getStateRoot, prefix} from "./formReducer.js";
import {getStateRoot as getEditOrderStateRoot} from '../form/formReducer'
import {services} from "../../../../RestServices.js";
import FormActions from "../../../../../src_shared/form/FormActions";
import {batch} from "react-redux";
import {showAlert} from "../../../shared/components/alert/AlertActions";
import axios from "axios";
import {selmoUrl} from "../../../../../src_shared/api/api";
import EditOrderFormActions from "../form/FormActions";
import FurgonetkaLabelModel from "../../../../../modules/models/order/FurgonetkaLabelModel";
import {
	ERROR_FURGONETKA,
} from "../../../../../src_shared/enums/ErrorEnums";
import api from "../../../../../services/axios/axios";

export class OrderDetailsShippyproLabelFormActions extends FormActions {
	createLabel() {
		return async (dispatch, getState) => {

			const {id, shop_order_id} = getEditOrderStateRoot(getState()).data;
			const values = getStateRoot(getState()).data;

			const preparedData = this.dataModel.buildDTO(values)

			dispatch(this.waiting.startWaiting());

			try {
				await api.post(`${selmoUrl}/${this.restService}/${id}`, preparedData)
				batch(() => {
					dispatch(showAlert('Etykieta została poprawnie wygenerowana' , ''))
					dispatch(EditOrderFormActions.loadData(shop_order_id))
				})
			} catch (e) {
				dispatch(EditOrderFormActions.loadData(shop_order_id))
				if (e.response.data.message === ERROR_FURGONETKA) {
					dispatch(showAlert(e.response.data.text ? e.response.data.text : 'sthWentWrongWarning', '', 'alert-fail'))
					return;
				}
				dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
				console.error('Error submitEditForm')
			} finally {
				dispatch(this.waiting.stopWaiting());
			}
		}
	}
}

export const getInstance = () => new OrderDetailsShippyproLabelFormActions({
	getStateRoot,
	prefix,
	model: FurgonetkaLabelModel,
	restService: services.API_INTEGRATIONS_FURGONETKA,
});

export default getInstance();
