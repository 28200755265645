export const PAYMENT_SUBSCRIPTION_CARD = 'card';
export const PAYMENT_SUBSCRIPTION_TRANSFER_ONLINE = 'p24';
export const PAYMENT_SUBSCRIPTION_TRANSFER = 'transfer';
export const PAYMENT_SUBSCRIPTION_PAYPAL = 'paypal';


export const PAYMENTS_SUBSCRIPTION_TYPE = [
	{
		value: PAYMENT_SUBSCRIPTION_CARD, label: 'cardPaymentLabel', img: 'cards'
	},
	{
		value: PAYMENT_SUBSCRIPTION_TRANSFER_ONLINE, label: 'paymentMethodDefaultOnlineTransferLabel', img: 'online-payments'
	},
	{
		value: PAYMENT_SUBSCRIPTION_TRANSFER, label: 'paymentMethodTransferLabel', img: '',
	},
]

export const PAYMENTS_SUBSCRIPTION_TYPE_NOT_POLAND = [
	{
		value: PAYMENT_SUBSCRIPTION_CARD, label: 'cardPaymentLabel', img: 'cards'
	},
	{
		value: PAYMENT_SUBSCRIPTION_TRANSFER, label: 'paymentMethodTransferLabel', img: '',
	},
	{
		value: PAYMENT_SUBSCRIPTION_PAYPAL, label: 'paymentMethodPaypalLabel', img: 'paypal',
	},
]
