import React, {useContext, useState} from 'react';
import {Helmet} from "react-helmet";
import {Link, useLocation} from "react-router-dom";
import ShopSettingsRegulationViews from "../../shared/enums/ShopSettingsRegulationViews";
import useLang from "../../../../src_shared/hooks/useLang";
import axios from "axios";
import {selmoUrl} from "../../../../src_shared/api/api";
import {showAlert} from "../../shared/components/alert/AlertActions";
import {useDispatch} from "react-redux";
import Loader from "../../shared/Loader";
import {ShopSettingsContext} from "../../../routes/shopSettingsRoute/ShopSettingsRoute";
import api from "../../../../services/axios/axios";

const ShopSettingsWrapper = ({children, value, fetch, rest, isLoading}) => {
	const {pathname} = useLocation();
	const {getLangText} = useLang();
	const dispatch = useDispatch();
	const [waiting, setWaiting] = useState(false);
	const {setIsChanging} = useContext(ShopSettingsContext);

	const onSave = async () => {
		setWaiting(true);
		try {
			await api.post(`${selmoUrl}/${rest}`, value)
			dispatch(showAlert('yuhuLabel', 'changesWillBeVisibleInFewMinutesLabel'))
			fetch();
			setIsChanging(new Date());

		} catch (e) {
			if (e?.response?.data?.message) {
				dispatch(showAlert(e.response.data.message, '', 'alert-fail'))
			} else {
				dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
			}
		} finally {
			setWaiting(false)
		}
	}

	return (
		<div className={`settings-page sub-settings-page loader-parent`}>
			<Helmet>
				<meta charSet="utf-8"/>
				<title>{getLangText('shopSettingsTermsPageLabel')}Selmo</title>
			</Helmet>
			<div className="tabs-wrapper">
				{ShopSettingsRegulationViews.map((i) => (
					<Link
						to={i.path}
						key={i.id}
						className={pathname === i.path ? 'active' : ''}
					>
						{getLangText(i.label)}
					</Link>
				))}
			</div>
			<div className="settings-form loader-parent">
				<Loader isLoading={isLoading}/>
				{children}
				<div className="d-flex mt-3">
					<button
						type="button"
						className={`ml-sm-auto w-sm-auto w-100 button primary ${waiting ? 'loading-button' : ''}`}
						onClick={onSave}
					>
						{waiting && <Loader/>}
						{getLangText('saveButton')}

					</button>
				</div>
			</div>
		</div>
	);
};

export default ShopSettingsWrapper;
