import React from 'react';
import Pagination from "../../../../shared/components/pagination/Pagination";
import useLang from "../../../../../../src_shared/hooks/useLang";
import SearchInput from "../../../../shared/components/form/SearchInput";
import DateRangePicker from "../../../../shared/components/form/DateRangePicker";
import EmptyList from "../../../../shared/EmptyList";
import moment from "moment";
import CustomDropdown from "../../../../shared/components/dropdown/CustomDropdown";
import {convertToUserTimeZone} from "../../../../shared/helpers/dateHelpers";

const Table = ({
                               updateSortOptions,
                               isWaiting,
                               getSortClass,
                               items,
                               pagination,
                               search,
                               ListActions,
                               getFiltersStateRoot,
                               FiltersActions
                           }) => {
    const {getLangText} = useLang();

    return (
        <div className="table-list-wrapper">
            <div className="default-shadow-box pb-0">
                <div className="table-header">
                    <div className="top-part flex-sm-nowrap flex-wrap">
                        <h1 className="title mb-sm-0 mb-2 grow">
                            {getLangText('newsletterLabel')}
                        </h1>
                        <CustomDropdown
                            icon="icon-print"
                            buttonClassName="sm:ml-auto sm:mr-2"
                            tooltipTitle={getLangText('exportLabel')}
                        >
                            <button
                                type="button"
                                className={`custom-option`}
                            >
                                {`${getLangText('exportList')} (XLS)`}
                            </button>
                        </CustomDropdown>
                        <SearchInput
                            placeholder={getLangText('searchPlaceholder')}
                            search={search}
                            className="sm:mr-2"
                        />
                        <DateRangePicker
                            actions={ListActions}
                            getFiltersStateRoot={getFiltersStateRoot}
                            FiltersActions={FiltersActions}
                        />
                    </div>
                </div>
                <EmptyList
                    items={items}
                    isLoading={isWaiting}
                    className="with-padd"
                    imgSrc="/assets/images/empty/clients-empty.svg"
                    title={getLangText('emptyHereLabel')}
                    text={getLangText('emptyListInteractionsLabel')}
                >
                    <div className="table-wrapper">
                        <div className="table-responsive">
                            <table className="table live-table">
                                <thead>
                                <tr>
                                    <th
                                        name="email"
                                        onClick={updateSortOptions}
                                        className={`${getSortClass('email')}`}
                                    >
							<span name="email">
								{getLangText('emailLabel')}
							</span>
                                    </th>
                                    <th
                                        name="date"
                                        onClick={updateSortOptions}
                                        className={`text-right ${getSortClass('date')}`}
                                    >
							<span name="date">
								{getLangText('dateLabel')}
							</span>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {items.map((item) => (
                                    <tr
                                        className="not-clicked-tr"
                                        key={item.id}
                                    >
                                        <td className="name">
                                            {item.email}
                                        </td>
                                        <td className="date-td date sm:text-right">
                                            <div>{convertToUserTimeZone(item.date).format('DD.MM.YYYY HH:mm')}</div>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </EmptyList>
            </div>
            <Pagination
                pages={pagination.getPagination()}
                items={items}
                itemsPerPage={pagination.itemsPerPage}
                getLangText={getLangText}
                totalItems={pagination.totalItems}
                onPrevPage={pagination.prevPage}
                onChangePage={pagination.changePage}
                onNextPage={pagination.nextPage}
                onChangeItemsPerPage={pagination.updateItemsPerPage}
                classes={{
                    prevIcon: 'icon-chevron left',
                    nextIcon: 'icon-chevron right',
                }}
                showItemsPerPageSelect
            />
        </div>
    );
};

export default Table;
