import React, {useEffect, useState} from 'react';
import axios from "axios";
import {selmoUrl} from "../../../api/api";
import {showAlert} from "../../../../components/app/shared/components/alert/AlertActions";
import {useDispatch} from "react-redux";
import useLang from "../../../hooks/useLang";
import api from "../../../../services/axios/axios";
import ServerImage from "../../../../components/app/shared/components/serverImage/ServerImage";
import Loader from "../../loader/Loader";

const BigImageUpload = ({
                            values,
                            imagePath,
                            updateValues,
                            restService,
                            validation = false,
                            blockInfo = false,
                            field,
                            className,
                            label,
                            dimensionLabel,
                            withRemove,
                        }) => {
    const dispatch = useDispatch();
    const [isWaiting, setIsWaiting] = useState(false);
    const {getLangText} = useLang();

    const isValidationActive = validation && !values.photo

    const removeAvatar = async () => {
        try {
            await api.delete(`${selmoUrl}/${restService}/${values.id}`)
            updateValues({
                [field]: null,
            })
        } catch (e) {

        }
    }

    const onSubmit = async (file) => {
        setIsWaiting(true)

        const formData = new FormData();
        formData.append('photo', file);
        formData.append('path', imagePath);
        formData.append('id', values.id);

        try {
            const {data} = await api.post(`${selmoUrl}/${restService}`, formData)

            updateValues({
                [field]: data?.path,
                id: values.id,
            })
            dispatch(showAlert('imageSaveSuccessLabel'))

        } catch (e) {
            dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
            console.error('Error submitEditForm')
        } finally {
            setIsWaiting(false)
        }
    }

    const uploadFileHandler = async (event) => {
        const files = Object.values(event.target.files);
        if (files[0]) {
            await onSubmit(files[0]);
        }
    };

    return (
        <div
            className={`${className} logo-upload-wrapper form-group d-block big-image-wrapper ${isValidationActive ? 'error-group' : ''}`}>
            {values[field] && values[field] !== '0' ?
                <div className={`photo-box-wrapper ${blockInfo ? 'block' : ''}`}>
                    <div className="photo-wrapper">
                        {isWaiting &&
                            <Loader className="small-loader w-full h-full" isLoading/>
                        }
                        <input
                            type="file"
                            onChange={uploadFileHandler}
                            className="hidden-file-input"
                        />
                        <div className="label">
                            <i className="icon-upload"/>
                            {getLangText('changePhotoButton')}
                        </div>
                        <figure>
                            <ServerImage
                                src={values[field]}
                                alt={values.name}/>
                        </figure>
                    </div>
                    <div className="sizes-label">
                        {!!label && <div className="label">{getLangText(label)}</div>}
                        <div dangerouslySetInnerHTML={{__html: getLangText(dimensionLabel)}}/>
                        <div dangerouslySetInnerHTML={{__html: getLangText('correctFormatsLabel')}}/>
                    </div>
                </div>
                :
                <div className={`photo-box-wrapper ${blockInfo ? 'block' : ''}`}>
                    <div className={`photo-wrapper with-border`}>
                        <input
                            type="file"
                            onChange={uploadFileHandler}
                            className="hidden-file-input"
                        />
                        <div className="label">
                            <i className="icon-upload"/>
                            {getLangText('addPhotoButton')}
                        </div>
                    </div>
                    <div className="sizes-label">
                        {!!label && <div className="label">{getLangText(label)}</div>}
                        <div dangerouslySetInnerHTML={{__html: getLangText(dimensionLabel)}}/>
                        <div dangerouslySetInnerHTML={{__html: getLangText('correctFormatsLabel')}}/>
                    </div>
                </div>
            }
            {!!values[field] && withRemove &&
                <button
                    type="button"
                    className="button text-only mt-2"
                    onClick={removeAvatar}
                >
                    <i className="icon-bin mr-1"/>
                    {getLangText('removePhotoLabel')}
                </button>
            }
            {isValidationActive &&
                <div className="error-text">
                    {getLangText('photoCannotBeEmptyLabel')}
                </div>
            }
        </div>
    );
};

BigImageUpload.defaultProps = {
    defaultImage: 'product.svg',
    imagePath: 'products',
    restService: 'api/media',
    circleImage: false,
    loadData: (dispatch, FormActions, values) => dispatch(FormActions.loadData(values.id)),
    field: 'photo',
    className: '',
    label: '',
}

export default BigImageUpload;
