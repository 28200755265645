import React from "react";
import cn from "clsx";

const StylesLoader = ({className = '', isLoading, circleClassName = ''}) => {
	return (
		<div
			style={isLoading ? {display: 'block'} : {display: 'none'}}
			className={`loader-container ${className}`}
		>
			<div className={cn('circle-loader', circleClassName)}>
				<span className="circle"/>
			</div>
		</div>
	)
}

export default StylesLoader;
