import Model from "../../../src_shared/modules/model/Model";
import ValidationError, {minLength, validatePassword} from "../../../src_shared/form/validation/ValidationError";

class SignupModel extends Model {
	getModel() {
		return {
			email: '',
			password: '',
			agreement: null,
		};
	}

	getValidators() {
		return {
			email: ValidationError.combine(
				ValidationError.notEmpty,
				ValidationError.validateEmail,
			),
			password: ValidationError.combine(
				ValidationError.notEmpty,
				ValidationError.validatePassword,
			),
			agreement: ValidationError.notFalse,
		};
	}
}

export default new SignupModel();
