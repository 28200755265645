export const CUSTOMER_BASKET_OPEN_CART_STATUS = '1';
export const CUSTOMER_BASKET_CLOSED_CART_STATUS = '0';

const CustomerBasketPackageType = [
	{
		id: CUSTOMER_BASKET_OPEN_CART_STATUS,
		label: 'customerBasketOpenCartLabel',
		description: 'customerBasketOpenCartDescription',
		iconClassName: 'icon-unpacked lgrey-txt'
	},
	{
		id: CUSTOMER_BASKET_CLOSED_CART_STATUS,
		label: 'customerBasketClosedCartLabel',
		description: 'customerBasketClosedCartDescription',
		iconClassName: 'icon-packed lgrey-txt'
	},
];

export default CustomerBasketPackageType;
