import React, {useState} from 'react';
import {Helmet} from "react-helmet";
import useLang from "../../../../../src_shared/hooks/useLang";
import OrdersChart from "../charts/chartTypes/OrdersChart";
import PaymentsDonutChart from "../charts/chartTypes/PaymentsDonutChart";
import TeamWrapper from "../../TeamWrapper";
import useList from "../../../../../src_shared/list/useList";
import {getStateRoot} from "./listReducer";
import ListActions from "./ListActions";
import FiltersActions from "../filters/FiltersActions";
import {getStateRoot as getFiltersStateRoot} from '../filters/filtersReducer'
import DateRangePicker from "../../../../app/shared/components/form/DateRangePicker";
import AsyncFilter from "../../../../../src_shared/components/filters/asyncFilter/AsyncFilter";
import {services} from "../../../../RestServices";
import useWindowDimensions from "../../../../app/messenger/hooks/useWindowDimensions/useWindowDimensions";
import Skeleton from "../../members/form/components/Skeleton";
import EmployeeChart from "../charts/chartTypes/EmployeeChart";

const TeamStatistics = () => {
    const {getLangText} = useLang();

    const [isFiltersVisible, setFiltersVisible] = useState(false);
    const {width} = useWindowDimensions();

    const {
        list: {items, isWaiting, loadData},
    } = useList({
        ListActions,
        getStateRoot,
    });

    return (
        <TeamWrapper
            hideButton={true}
            title="asideStatsLabel"
        >
            <div className="stats-wrapper">
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>{getLangText('asideStatsLabel')} - Selmo</title>
                </Helmet>
                <div className="table-list-wrapper">
                    <div
                        style={{borderTopRightRadius: 0, borderTopLeftRadius: 0}}
                        className="default-shadow-box">
                        <div className="table-header mb-3">
                            <div className="filters-part d-block mt-0">
                                <div className="top-part">
                                    <h1 className="title">
                                        {getLangText('asideStatsLabel')}
                                    </h1>
                                    <button
                                        type="button"
                                        className='toggle-filters'
                                        onClick={() => setFiltersVisible(!isFiltersVisible)}
                                    >
                                        <i className='icon-filter'/>
                                    </button>
                                </div>
                                {(width > 767 || isFiltersVisible) &&
                                    <div className="filters-part">
                                        <AsyncFilter
                                            ListActions={ListActions}
                                            FiltersActions={FiltersActions}
                                            getFiltersStateRoot={getFiltersStateRoot}
                                            name="employees"
                                            selectAllName="employeesSelectAll"
                                            defaultSelectedLabel={getLangText('membersLabel')}
                                            rest={services.API_PERMISSIONS_EMPLOYEES}
                                        />
                                        <AsyncFilter
                                            ListActions={ListActions}
                                            FiltersActions={FiltersActions}
                                            getFiltersStateRoot={getFiltersStateRoot}
                                            name="types"
                                            selectAllName="typesSelectAll"
                                            defaultSelectedLabel={getLangText('typeLabel')}
                                            className="ml-sm-2"
                                            dropdownClassName="big-min-width"
                                            rest={services.API_PERMISSIONS_EMPLOYEES_ACTIONS}
                                            optionsParser={(items) => items.map((i) => ({
                                                value: i.id,
                                                label: getLangText({
                                                    key: i.id,
                                                    data: ['', '']
                                                }),
                                            }))}
                                            optionLabel={(item) => (
                                                <label
                                                    className="control-label"
                                                    dangerouslySetInnerHTML={{__html: item.label}}
                                                />
                                            )}
                                        />
                                        <DateRangePicker
                                            actions={ListActions}
                                            getFiltersStateRoot={getFiltersStateRoot}
                                            FiltersActions={FiltersActions}
                                            className="ml-auto"
                                            loadData={loadData}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    {isWaiting ?
                        <Skeleton/> :
                        <div className="charts-section">
                            <div className="grid grid-cols-5 gap-4">
                                <div className="col-span-3">
                                    <EmployeeChart data={items?.teamActivities}/>
                                    <OrdersChart data={items?.dailyActivities}/>
                                </div>
                                <div className="col-span-2">
                                    <PaymentsDonutChart data={items.activities}/>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </TeamWrapper>
    );
};

export default TeamStatistics;
