import React from 'react';
import useLang from "../../../src_shared/hooks/useLang";

const DownloadVideoPage = () => {
    const {getLangText} = useLang();

    return (
        <div
            className={`bg-[#eff2f6] sm:py-0 py-6 sm:px-0 px-6 sm:min-h-auto min-h-full sm:h-[100vh] w-[100vw] flex sm:items-center sm:justify-center`}>
            <div
                className={`w-full p-10 sm:p-16 my-auto rounded-[12px] shadow-default bg-white md:w-[650px] animate-tutorialSlideAndFadeIn`}>

                <div className={`animate-tutorialSlideAndFadeInDelay1 opacity-0 m-auto`}>
                    <img
                        className="mb-4 block w-[100px]"
                        src="/assets/images/selmo-logo.svg"
                        alt="selmo paczka"
                    />
                    <div className="font-bold text-xl sm:text-4xl mb-8 sm:mb-12 tracking-[-.7px] sm:whitespace-pre-wrap" dangerouslySetInnerHTML={{__html: getLangText('tapButtonBellowToDownloadVideo')}} />
                    <a
                        style={{fontSize: 18, borderRadius: 8}}
                        className="button primary w-full big-size text-center"
                        download
                        href="/assets/videos/pl/selmo-app-follow.mp4"
                    >
                        {getLangText('downloadVideoLabel')}
                    </a>
                </div>
            </div>
        </div>
    );
};

export default DownloadVideoPage;
