export const ORLEN_PACZKA_OWN_POINT_TYPE = ['PSD'];
export const ORLEN_PACZKA_PARTNER_POINT_TYPE = ['PSP', 'PPP', 'PSF', 'PPK'];
export const ORLEN_PACZKA_AUTOMAT_POINT_TYPE = ['APM'];
export const ORLEN_PACZKA_OBAJTEK_POINT_TYPE = ['PKN'];

export const OrlenPaczkaPointsTypes = [
	{
		id: ORLEN_PACZKA_OWN_POINT_TYPE,
		label: 'Własny',
	},
	{
		id: ORLEN_PACZKA_PARTNER_POINT_TYPE,
		label: 'Partnerski',
	},
	{
		id: ORLEN_PACZKA_AUTOMAT_POINT_TYPE,
		label: 'Automat paczkowy',
	},
	{
		id: ORLEN_PACZKA_OBAJTEK_POINT_TYPE,
		label: 'Stacja benzynowa Orlen',
	},
];
export const getOrlenPaczkaPointsTypeById = (id) => OrlenPaczkaPointsTypes.find((i) => i.id.includes(id))?.label
