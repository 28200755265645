import {combineReducers} from "redux";
import listReducer from './list/listReducer'
import filtersReducer from "./filters/filtersReducer";
import formReducer from "./form/formReducer";
import detailsFormReducer from "./details/ordersDetailsReducer";
import exportFormReducer from './list/components/export/formReducer'
import selectedRowReducer from './list/components/selectedRow/selectedRowReducer'
import filtersPanelReducer from "./list/filtersPanel/filtersPanelReducer";

export default combineReducers({
	list: listReducer,
	form: formReducer,
	export: exportFormReducer,
	filters: filtersReducer,
	details: detailsFormReducer,
	selectedRow: selectedRowReducer,
	filtersPanel: filtersPanelReducer,
});
