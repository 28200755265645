import React, {useMemo} from 'react';
import {useSelector} from "react-redux";
import useList from "../../../../../src_shared/list/useList.js";
import ListActions from "./ListActions.js";
import {getStateRoot} from "./listReducer.js";
import Pagination from "../../../shared/components/pagination/Pagination.jsx";
import Table from "./components/table/Table.jsx";
import useLang from "../../../../../src_shared/hooks/useLang.js";
import ComponentLoader from "../../../shared/components/componentLoader/ComponentLoader";

export const firstPageBenefits = [
    {id: 0, title: 'sendNotificationsOneClickLabel', desc: 'sendNotificationsOneClickSubLabel'},
    {id: 1, title: 'displayCommentsLabel', desc: 'displayCommentsSubLabel'},
    {id: 1, title: 'addProductsFromStorageOrWithoutLabel', desc: 'addProductsFromStorageOrWithoutSubLabel'},
]

const AutomationList = () => {

    const {getLangText} = useLang();

    const {
        pagination: {
            changePage,
            getPagination,
            itemsPerPage,
            nextPage,
            prevPage,
            totalItems,
            updateItemsPerPage
        },
        list: {items, isWaiting},
        sort: {updateSortOptions, getSortClass},
    } = useList({
        ListActions,
        getStateRoot,
    });


    if (isWaiting) return <ComponentLoader/>

    return (
        <>
            <div className="table-list-wrapper">
                <div className="default-shadow-box pb-0">
                    <Table
                        isWaiting={isWaiting}
                        items={items}
                        updateSortOptions={updateSortOptions}
                        getSortClass={getSortClass}
                    />
                </div>
                <Pagination
                    pages={getPagination()}
                    items={items}
                    itemsPerPage={itemsPerPage}
                    getLangText={getLangText}
                    totalItems={totalItems}
                    onPrevPage={prevPage}
                    onChangePage={changePage}
                    onNextPage={nextPage}
                    onChangeItemsPerPage={updateItemsPerPage}
                    classes={{
                        prevIcon: 'icon-chevron left',
                        nextIcon: 'icon-chevron right',
                    }}
                    showItemsPerPageSelect
                />
            </div>
        </>
    );
};

export default AutomationList;
