/* eslint-disable no-console */
/* global localStorage */

export const getFromLS = (field) => {
    try {
        const result = localStorage.getItem(field);
        return JSON.parse(result);
    } catch (error) {
        console.error(`Error parsing ${field} from local storage`);
        return null;
    }
};

export const saveToLS = (field, value) => {
    try {
        const stringified = JSON.stringify(value);
        localStorage.setItem(field, stringified);
    } catch (error) {
        console.error(`Error saving ${field} to local storage`);
    }
};

export const removeFromLS = (field) => {
    try {
        localStorage.removeItem(field);
    } catch (error) {
        console.error(`Error removing ${field} from local storage`);
    }
};

export default {
    getFromLS,
    saveToLS,
    removeFromLS,
};
