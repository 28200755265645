import Model, {convertObjectProperties} from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

class SettingsFurgonetkaModel extends Model {
	getModel() {
		return {
			active: '0',
			export_url: '',
		};
	}
}

export default new SettingsFurgonetkaModel();
