import useDefaultSelmoData from "./shared/hooks/useDefaultSelmoData";
import React from "react";
import {LottieLoading} from "../../App";

const CheckSessionWrapper = ({children}) => {
    const {waitingForResponse} = useDefaultSelmoData();

    if (waitingForResponse) return <LottieLoading className="higher-index"/>

    return children
};

export default CheckSessionWrapper;
