import React from 'react';
import DiscountCode from "../../../basket/productsSummary/discountCode/DiscountCode";
import {findOrderPaymentStatusById, ORDER_PAYMENT_TRANSFER} from "../../../shared/enums/PaymentStatuses";
import useLang from "../../../../../src_shared/hooks/useLang";
import {useUnitPrice} from "../../../shared/helpers/Price";

const Summary = ({values}) => {
    const {getLangText} = useLang();
    const {getPrice} = useUnitPrice();

    const getPriceWithCurrency = (price) => getPrice(price, values?.shopInfo?.currency_shortcut)

    return (
        <div>
            <div className="font-bold mb-2">{getLangText('summaryLabel')}</div>
            <div className="space-y-2">
                {/*{!isOrderFinished && values.shopInfo.discount_codes_enabled && +values.shopInfo?.order_stage <= 1 &&*/}
                {/*    <DiscountCode*/}
                {/*        magicUrl={magicUrl}*/}
                {/*        shopId={shopId}*/}
                {/*        values={values}*/}
                {/*    />*/}
                {/*}*/}
                <div className="flex text-sm font-medium">
                    <div className="text-[#4B5563]">{values.productInfo?.name}</div>
                    <div className="ml-auto text-nowrap">
                        {!!+values.overall?.products_value ?
                            getPriceWithCurrency(+values?.overall?.products_value) :
                            getLangText('freeLabel')
                        }
                    </div>
                </div>
                {values.overall?.shipping_value?.name &&
                    <div className="flex text-sm font-medium">
                        <div className="text-[#4B5563]">{values.overall?.shipping_value?.name}</div>
                        <div className="ml-auto text-nowrap">
                            {!!+values.overall?.shipping_value?.price && !+values?.overall.discount?.free_shipping ?
                                getPriceWithCurrency(values?.overall?.shipping_value.price) :
                                getLangText('freeLabel')
                            }
                        </div>
                    </div>
                }
                {values.overall?.payment_value?.type && !!+values.overall?.payment_value?.price &&
                    <div className="flex text-sm font-medium">
                        <div
                            className="text-[#4B5563]">
                            {values.overall?.payment_value?.type?.includes(`${ORDER_PAYMENT_TRANSFER}_`) ?
                                <>
                                    {values.overall?.payment_value?.name}
                                </> :
                                getLangText(findOrderPaymentStatusById(values.overall?.payment_value?.type)?.label)
                            }
                        </div>
                        <div className="ml-auto text-nowrap">
                            {getPriceWithCurrency(values?.overall?.payment_value.price)}
                        </div>
                    </div>
                }
                {/*{values.overall.discount?.name &&*/}
                {/*    <div className="price-row">*/}
                {/*        <div className="name">{getLangText('discountCodeLabel')}:</div>*/}
                {/*        <div className="value">*/}
                {/*            {getDiscountCodeValue()}*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*}*/}
                {!!values?.overall?.payment_commission &&
                    <div className="flex text-sm font-medium">
                        <div className="text-[#4B5563]">{getLangText('commissionLabel')}:</div>
                        <div className="ml-auto text-nowrap">
                            {getPriceWithCurrency(values?.overall?.payment_commission)}
                        </div>
                    </div>
                }
                {!!+values?.overall?.payment_service_fee &&
                    <div className="flex text-sm font-medium">
                        <div className="text-[#4B5563]">{getLangText('servicePayment')}:</div>
                        <div className="ml-auto text-nowrap">
                            {getPriceWithCurrency(values?.overall?.payment_service_fee)}
                        </div>
                    </div>
                }
            </div>
            <div className="flex border-t font-medium pt-2 mt-2">
                <div className="text-[#4B5563]">
                    {getLangText('totalPriceToPayLabel')}
                </div>
                <div className="ml-auto text-xl font-bold text-nowrap">
                    {getPriceWithCurrency(values?.overall?.total_price)}
                </div>
            </div>
        </div>
    );
};

export default Summary;
