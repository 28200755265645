export const createActions = (prefix) => ({
    activateValidation: () => ({
        type: `${prefix}VALIDATION_ACTIVATE`,
    }),
    deactivateValidation: () => ({
        type: `${prefix}VALIDATION_DEACTIVATE`,
    }),
});

export default {
    createActions,
};
