import React, {useState} from 'react';
import CreateShopLayout from "../../shared/CreateShopLayout";
import {
    ONBOARDING_ACCOUNT_TYPE_VIEW,
} from "../CreateShopFirstStep";
import CustomerType from "./CustomerType";
import AccountTypeOptions from "./AccountTypeOptions";



const AccountType = ({setActiveView}) => {

    const [internalView, setInternalView] = useState(ONBOARDING_ACCOUNT_TYPE_VIEW)

    return (
        <CreateShopLayout
            title={internalView === ONBOARDING_ACCOUNT_TYPE_VIEW ? 'selectAccountTypeLabel' : ''}
            desc="itMakesEasierNextConfigurationLabel"
            Img={<img width={30} className="mx-auto mb-2" src="/assets/images/svg/selmo-symbol.svg" alt="selmo icon"/>}
        >
            {internalView === ONBOARDING_ACCOUNT_TYPE_VIEW ?
                <AccountTypeOptions
                    setActiveView={setActiveView}
                    setInternalView={setInternalView}
                /> :
                <CustomerType
                    setInternalView={setInternalView}
                />
            }
        </CreateShopLayout>
    );
};

export default AccountType;
