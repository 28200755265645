import React from 'react';
import MessengerBlankPage from "./MessengerBlankPage";
import {useSelector} from "react-redux";

const MessengerPage = ({children}) => {

	const {userData, waitingForResponse} = useSelector((state) => state.session);

	if ((!userData.facebook_id && !userData.instagram_id) && !waitingForResponse) {
		return <MessengerBlankPage buttonLink={userData.facebookLoginUrl}/>
	}

	return (
		<>
			{children}
		</>
	);
};

export default MessengerPage;
