import createReducer from "../../shared/helpers/createReducer";
import FormReducer from "../../../../src_shared/form/FormReducer";
import Composition from "../../shared/helpers/Composition";
import FunctionalityPaymentsModel from "../../../../modules/models/FunctionalityPaymentsModel";

export const getStateRoot = (state) => state.payments.functionalityModal;
export const prefix = 'PAYMENTS_FUNCTIONALITY_MODAL_FORM_';

export const getInitState = () => FormReducer.getInitState(FunctionalityPaymentsModel.getModel(), {
	waitingForTotalPrice: false,
	waiting: true,
	isTransferPage: false,
})

export const getReduceMap = () => new Composition(
	FormReducer.getReduceMap(),
	{
		SET_SUMMARY_INFO: (state, action) => ({...state, summaryInfo: action.item}),
		WAITING_FOR_TOTAL_PRICE: (state, action) => ({...state, waitingForTotalPrice: action.toggle}),
		SET_TRANSFER_PAGE: (state, action) => ({...state, isTransferPage: action.toggle}),
	}
);

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();
