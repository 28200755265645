import {useEffect, useState} from "react";
import {selmoUrl} from "../api/api";
import {useDispatch, useSelector} from "react-redux";
import {getGETParamsUrl} from "../list/ListActions";
import api from "../../services/axios/axios";
import {showAlert} from "../../components/app/shared/components/alert/AlertActions";

const useDataApi = (rest, initialData, dependencies = [], condition = true, extraParams = {}, afterSubmit = (item) => {}) => {
	const [data, setData] = useState(initialData);
	const [isLoading, setIsLoading] = useState(condition);
	const [isError, setIsError] = useState(false);
	const {lang} = useSelector((state) => state.i18n);
	const [errorMessage, setErrorMessage] = useState('');
	const dispatch = useDispatch();
	const fetchData = async (search) => {
		const params = {
			...extraParams
		}
		setIsError(false);
		setIsLoading(true);

		if (search) {
			params.superSearch = search;
		}

		try {
			const {data} = await api.get(`${selmoUrl}/${rest}${getGETParamsUrl(params)}`)
			setData(data?.item || data?.items);
			afterSubmit(data?.item || data?.items);

		} catch (e) {
			setIsError(true);
			setErrorMessage(e?.response?.data?.message);
			dispatch(showAlert(e?.response?.data?.message || 'sthWentWrongWarning', '', 'alert-fail'))
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		api.defaults.headers.common['lang'] = lang
		if (condition && lang) {
			fetchData();
		}
	}, [rest, ...dependencies, condition, lang]);

	return [{data, isLoading, isError, fetchData, setData, errorMessage}];
};

export default useDataApi;
