import React, {useContext} from 'react';
import Modal from "react-bootstrap/Modal";
import useLang from "../../../../../src_shared/hooks/useLang";
import useForm from "../../../../../src_shared/form/useForm";
import FormActions from "./FormActions";
import Field from "../../../../../src_shared/components/form/field/Field";
import Loader from "../../../messenger/loader/Loader";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {ShopSettingsContext} from "../../../../routes/shopSettingsRoute/ShopSettingsRoute";

const PagesModalForm = ({modalVisible, hideModal}) => {

	const {getLangText} = useLang();
	const dispatch = useDispatch();
	const history = useHistory();
	const {setIsChanging} = useContext(ShopSettingsContext);

	const {
		data: {values, setValue},
		form: {isWaiting, getFieldProps},

	} = useForm({
		FormActions,
		params: {},
		preventAutoLoad: true
	});

	const onSave = () => dispatch(FormActions.submitEditForm(values, history, () => setIsChanging(new Date())));

	return (
		<Modal
			dialogClassName="default-modal small-size"
			show={modalVisible}
			onHide={hideModal}
		>
			<Modal.Header className="with-border">
				<Modal.Title>
					{getLangText('createPageLabel')}
				</Modal.Title>
				<button
					onClick={hideModal}
					type="button"
					className="button"
				>
					<i className="icon-cross"/>
				</button>
			</Modal.Header>
			<Modal.Body className="pt-3 pb-5">
				<Field
					{...getFieldProps('name')}
					placeholder={getLangText('typePageNameLabel')}
					label={getLangText('pageNameLabel')}
					setValue={setValue}
				/>
			</Modal.Body>
			<Modal.Footer>
				<button
					onClick={hideModal}
					type="button"
					className="button border-button"
				>
					{getLangText('cancelButton')}
				</button>
				<button
					onClick={onSave}
					type="button"
					className={`button primary px-4 ${isWaiting ? 'loading-button' : ''}`}
				>
					{isWaiting && <Loader/>}
					{getLangText('createPageLabel')}
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default PagesModalForm;
