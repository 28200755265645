const initialState = {
	isLoading: false,
	isError: false,
	items: [],
	page: 1,
	noResults: false,
	next: null,
	activeConversation: {},
	visibleRightSide: false,
}
export default (state = initialState, action) => {
	switch (action.type) {
		case 'GET_CONVERSATION_REQUESTED':
			return {...state, items: [], page: 1, isLoading: true, noResults: false};
		case 'CHAT_BOX_SET_LOADER_WAITING':
			return {...state, isLoading: action.toggle};
		case 'SET_CONVERSATION_PAGE':
			return {...state, page: action.page ? 1 : state.page + 1}
		case 'UPDATE_CONVERSATION':
			let newArray = state.page > 1 ? state.items.concat(action.items) : action.items;
			return {...state, isLoading: false, items: newArray, isError: false};
		case 'SET_CONVERSATION_NO_RESULTS':
			return {...state, noResults: true, isLoading: false};
		case 'CHAT_BOX_UPDATE_NEXT_PARAMETER':
			return {...state, next: action.next};
		case 'GET_CONVERSATION_ERROR':
			return {...state, isLoading: false, isError: true}
		case 'CHAT_BOX_SET_ACTIVE_CONVERSATION':
			return {...state, activeConversation: action.data}
		case 'ADD_NEW_MESSAGE_TO_CONVERSATION':
			const lastId = Number(state.items[0]?.id)
			return {...state, items: [{...action.msg, id: lastId + 1 || 0}, ...state.items]}
		case 'SET_SEND_STATUS_MESSAGE':
			return {...state, items: state.items.map((i) => i.id === action.id ? {...i, is_sent: action.status} : i)}
		case 'CHAT_BOX_SET_RIGHT_SIDE_VISIBLE':
			return {...state, visibleRightSide: action.toggle}
		case 'CHAT_BOX_RESET':
			return initialState;
		default:
			return state;
	}
};
