import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import SelmoPro from "../app/selmoPro/SelmoPro";
import {RouterPaths} from "./RouterPath";
import SelmoProLandingWrapper from "../app/shared/components/SelmoProBox/SelmoProLandingWrapper";
import LiveOverlayLanding from "../app/shared/components/SelmoProBox/pages/LiveOverlayLanding";
import DepotSystemLanding from "../app/shared/components/SelmoProBox/pages/DepotSystemLanding";
import MrAutomateLanding from "../app/shared/components/SelmoProBox/pages/MrAutomateLanding";

const SelmoProLandingRoute = ({path}) => {
    return (
        <Route path={path}>
            <SelmoProLandingWrapper>
                <Switch>
                    <Route
                        component={LiveOverlayLanding}
                        path={RouterPaths.SelmoProOverlay}
                        exact
                    />
                    <Route
                        component={DepotSystemLanding}
                        path={RouterPaths.SelmoProDepot}
                        exact
                    />
                    <Route
                        component={MrAutomateLanding}
                        path={RouterPaths.SelmoProMrAutomate}
                        exact
                    />
                    <Route
                        component={SelmoPro}
                        path={path}
                        exact
                    />
                    <Redirect to={path} />
                </Switch>
            </SelmoProLandingWrapper>
        </Route>
    );
};

export default SelmoProLandingRoute;
