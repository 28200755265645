import {getStateRoot, prefix} from "./formReducer";
import FormActions from "../../../../../src_shared/form/FormActions";
import {services} from "../../../../RestServices";
import Model from "../../../../../src_shared/modules/model/Model";
import {RouterPaths} from "../../../../routes/RouterPath";

export class CollectionFormModalActions extends FormActions {
}

export const getInstance = () => new CollectionFormModalActions({
	getStateRoot,
	prefix,
	model: new Model(),
	restService: services.API_COLLECTIONS,
	route: RouterPaths.CollectionsList
});

export default getInstance();
