import React from 'react';
import useLang from "../../../../../../src_shared/hooks/useLang";
import EmptyList from "../../../../../app/shared/EmptyList";
import moment from "../../../../../app/shared/moment";
import {
    USER_PERMISSIONS_TEAM_EDIT_PERMISSIONS
} from "../../../../../app/shared/enums/UserPermissionsTypes";
import UserPermissionsWrapper from "../../../../../routes/UserPermissionsWrapper";
import DropdownButtons from "./Dropdown";
import {useSelector} from "react-redux";
import ServerImage from "../../../../../app/shared/components/serverImage/ServerImage";
import {convertToUserTimeZone} from "../../../../../app/shared/helpers/dateHelpers";

const Table = ({updateSortOptions, isWaiting, getSortClass, items, askForRemoveItem}) => {
    const {getLangText} = useLang();
    const {userData} = useSelector((state) => state.session);

    return (
        <EmptyList
            items={items}
            isLoading={isWaiting}
            className="with-padd with-bg rounded-b-[5px]"
            imgSrc="/assets/images/empty/clients-empty.svg"
            title={getLangText('emptyHereLabel')}
            text={getLangText('addPersonLabel')}
        >
            <div className="table-wrapper">
                <div className="table-responsive" style={{overflow: 'visible'}}>
                    <table className="table live-table">
                        <thead>
                        <tr>
                            <th/>
                            <th
                                name="name"
                                onClick={updateSortOptions}
                                className={getSortClass('name')}
                            >
							<span name="name">
								{getLangText('nameLabel')}
							</span>
                            </th>
                            <th
                                name="email"
                                onClick={updateSortOptions}
                                className={getSortClass('email')}
                            >
							<span name="email">
								{getLangText('emailLabel')}
							</span>
                            </th>
                            <th
                                name="date"
                                onClick={updateSortOptions}
                                className={`${getSortClass('date')} text-right`}
                            >
							<span name="date">
								{getLangText('dateLabel')}
							</span>
                            </th>
                            <UserPermissionsWrapper allowedPermissions={[USER_PERMISSIONS_TEAM_EDIT_PERMISSIONS]}>
                                <th/>
                            </UserPermissionsWrapper>
                        </tr>
                        </thead>
                        <tbody>
                        {items.map((item) => (
                            <tr
                                className="not-clicked-tr"
                                key={item.id}>
                                <td className="w-[20px] max-w-[20px] pr-0">
                                    <div className="w-[20px] h-[20px] rounded-full overflow-hidden">
                                        {item.photo && item.photo !== '0' ?
                                            <ServerImage className="w-full h-full object-cover" src={item.photo}/> :
                                            <img className="w-full h-full object-cover"
                                                 src="/assets/images/default/avatar.svg" alt=""/>}
                                    </div>
                                </td>
                                <td className="name-td">
                                    <strong className="block">{item.name}</strong>
                                    {userData.employee_email === item.email &&
                                        <div className="status-bg blue small-status">{getLangText('currentlyLoggedIn')}</div>
                                    }
                                </td>
                                <td>
                                    {item.email}
                                </td>
                                <td className="date-td date text-right">
                                    <div>{convertToUserTimeZone(item.date).format('DD.MM.YYYY HH:mm')}</div>
                                </td>
                                <UserPermissionsWrapper allowedPermissions={[USER_PERMISSIONS_TEAM_EDIT_PERMISSIONS]}>
                                    <td className="text-right text-nowrap max-w-[70px] w-[70px]">
                                        <DropdownButtons
                                            askForRemoveItem={() => askForRemoveItem(item)}
                                            item={item}
                                        />
                                    </td>
                                </UserPermissionsWrapper>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </EmptyList>
    );
};

export default Table;
