import Composition from "../../shared/helpers/Composition";
import createReducer from "../../shared/helpers/createReducer";
import FormReducer from "../../../../src_shared/form/FormReducer";
import BasketDeliveryAddressModel from "../../../../modules/models/basket/BasketDeliveryAddressModel";

export const getStateRoot = (state) => state.shopCartAuth.form;

export const prefix = 'SHOP_CART_AUTH_DELIVERY_ADDRESS_FORM';

export const getInitState = () => FormReducer.getInitState(BasketDeliveryAddressModel.getModel())

const getReduceMap = () => new Composition(
	FormReducer.getReduceMap(),
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();
