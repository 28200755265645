import {getStateRoot, prefix} from './listReducer.js';
import {services} from "../../../RestServices";
import ListActions, {getGETParamsUrl} from "../../../../src_shared/list/ListActions";
import {RouterPaths} from "../../../routes/RouterPath";
import {getStateRoot as getFiltersStateRoot} from "../filters/filtersReducer";
import {convertDate} from "../../shared/helpers/dateHelpers";
import history from "../../../history";

export class SelmoPaczkaTransactionsListActions extends ListActions {
	getLoadParams(state) {
		const params = super.getLoadParams(state);
		const dateFrom = getFiltersStateRoot(state).data.fromDate;
		const dateTo = getFiltersStateRoot(state).data.toDate;

		if (dateFrom) {
			params.dateFrom = convertDate(dateFrom);
		}

		if (dateTo) {
			params.dateTo = convertDate(dateTo);
		}

		history.replace({
			pathname: this.pathname,
			search: getGETParamsUrl(params)
		});

		return params;
	}
}


export const getInstance = () => new SelmoPaczkaTransactionsListActions({
	getStateRoot,
	restService: services.API_PACKAGE_BALANCE_HISTORY,
	pathname: RouterPaths.SelmoPaczkaHistory,
	prefix,
});

export default getInstance();
