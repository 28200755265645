import ListReducer from "../../../../../src_shared/list/ListReducer";
import createReducer from "../../../shared/helpers/createReducer";
import Composition from "../../../shared/helpers/Composition";

export const getStateRoot = (state) => state.products.form.currentCart;
export const prefix = 'PRODUCTS_FORM_CURRENT_BASKET_';

const getInitState = () => ListReducer.getInitState();

const getReduceMap = () =>  new Composition(
	ListReducer.getReduceMap(),
	{
		SET_TOTAL_PRICE: (state, action) => ({...state, totalPrice: action.totalPrice}),
	},
	{
		SET_QUANTITY: (state, action) => ({...state, quantity: action.quantity})
	},
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

