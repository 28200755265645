import React from 'react';
import Loader from "../../../../../src_shared/components/loader/Loader";
import CustomDropdown from "../../../shared/components/dropdown/CustomDropdown";
import useLang from "../../../../../src_shared/hooks/useLang";
import {useSelector} from "react-redux";
import {BUTIK_MADAME_SHOP_ID, TEST_ACCOUNT_SHOP_ID} from "../../../../../src_shared/enums/TrustedShopsIds";

const SubmitButton = ({isWaiting, onSave}) => {
    const {getLangText} = useLang();
    const {userData} = useSelector((state) => state.session);

    return (
        <div className="flex button-with-arrow primary">
            <button
                type="submit"
                className={`button primary ${isWaiting ? 'loading-button' : ''}`}
            >
                {isWaiting && <Loader isLoading/>}
                {getLangText('saveButton')}
            </button>
            {!!+userData.depot_system &&
                <CustomDropdown
                    icon="icon-arrows"
                    onClick={(e) => e.stopPropagation()}
                >
                    <button
                        onClick={(e) => onSave(e, true)}
                        type="button"
                        className="custom-option"
                    >
                        {getLangText('saveAndGenerateQrCode')}
                    </button>
                </CustomDropdown>
            }
        </div>
    );
};

export default SubmitButton;
