import React, {useCallback, useEffect, useRef, useState} from 'react';
import axios from "axios";
import debounce from "lodash.debounce";
import optimizedMemo from "../../../../helpers/optimizedMemo";
import {selmoUrl} from "../../../../api/api";
import AsyncDropdown from "./AsyncDropdown";
import Group from "../../group/Group";
import Tooltip from "react-bootstrap/Tooltip";
import {OverlayTrigger} from "react-bootstrap";
import {getGETParamsUrl} from "../../../../list/ListActions";
import api from "../../../../../services/axios/axios";
import cn from "clsx";

const AsyncSearchSelectFieldNew = ({
                                       setValue,
                                       name,
                                       rest,
                                       label,
                                       formatOptionLabel,
                                       formatOptionValue,
                                       className,
                                       defaultValue,
                                       value,
                                       validateErrors,
                                       validationData,
                                       getError,
                                       required,
                                       showLabel,
                                       floatedLabel,
                                       placeholder,
                                       setExtraValues,
                                       isSearchable,
                                       optionsParser,
                                       selectedOption,
                                       searchOnFocus,
                                       withSearchInput,
                                       id = '',
                                       sort,
                                   }) => {
        const inputRef = useRef(null);
        const [isOpenDropdown, setIsOpenDropdown] = useState(false);
        const [waitingForOptions, setWaitingForOptions] = useState(true);
        const [inputValue, setInputValue] = useState({
            name: defaultValue.name,
        });

        const [options, setOptions] = useState([]);

        const hintDropdownFocus = (e) => {
            if (document.querySelector('.custom-dropdown .select-custom-option:first-child') && e.keyCode === 40) {
                document.querySelector('.custom-dropdown .select-custom-option:first-child').focus()
            }
        }

        const searchOptions = async (superSearch = '') => {

            const params = {}

            if (isOpenDropdown) {
                return;
            }

            if (superSearch?.length && isSearchable) {
                params.superSearch = superSearch;
            }

            if (superSearch?.length > 0 || !isSearchable || searchOnFocus) {
                setWaitingForOptions(true)
                setIsOpenDropdown(true)

                try {
                    const {data} = await api.get(`${selmoUrl}/${rest}${getGETParamsUrl(params)}`)
                    setOptions(sort(data.items));

                } catch (e) {
                    setOptions([])
                    console.warn('Cannot get options')
                } finally {
                    setWaitingForOptions(false);
                }
            }
        }

        const asyncSearchClassName = () => {
            const floatedLabelClass = floatedLabel ? 'floated-label-select' : '';
            const isOpen = isOpenDropdown ? 'open' : '';
            return `custom-async-search ${className} ${floatedLabelClass} ${isOpen}`
        }

        const preparedOptions = optionsParser(options);

        const isError = () => {
            const errorKey = getError(value, validationData);
            return errorKey ? errorKey : false;

        }

        const hasError = !!isError() && validateErrors;

        useEffect(() => {
            setInputValue({
                ...defaultValue,
                id: defaultValue.id,
                name: defaultValue.name,
            })
        }, [defaultValue.name])


        return (
            <div
                id={id}
                className={asyncSearchClassName()}>
                <Group
                    validateErrors={validateErrors}
                    validationData={validationData}
                    getError={getError}
                    showLabel={!floatedLabel && showLabel}
                    label={label}
                    value={value}
                    className={value ? 'selected-disabled' : ''}
                >
                    {floatedLabel &&
                        <span className={`floated-label ${inputValue.name?.length ? 'selected' : ''}`}>{label}</span>
                    }
                    <div
                        ref={inputRef}
                        className={cn('h-[50px] flex items-center px-[15px] rounded-[8px] font-medium cursor-pointer border', hasError ? 'border-[red]' : 'border')}
                        onClick={searchOptions}
                    >
                        {inputValue.id ?
                            selectedOption(inputValue) :
                            <div
                                tabIndex="0"
                                className=""
                            >
                                {placeholder}
                            </div>
                        }
                        <div className="ml-auto h-[14px] self-center relative pointer-events-none">
                            <div className="absolute rounded-[2px] top-0 right-0 w-0 h-0 border-l-[5px] border-l-transparent border-t-[6px] border-t-[#D8D8D8] border-r-[5px] border-r-transparent transform rotate-[180deg]" />
                            <div className="w-0 h-0 rounded-[2px] absolute bottom-0 right-0 border-l-[5px] border-l-transparent border-t-[6px] border-t-[#D8D8D8] border-r-[5px] border-r-transparent" />
                        </div>
                    </div>
                    {formatOptionValue(inputValue)}
                    <AsyncDropdown
                        setInputValue={setInputValue}
                        setValue={setValue}
                        options={preparedOptions}
                        setOptions={setOptions}
                        name={name}
                        formatOptionLabel={formatOptionLabel}
                        setExtraValues={setExtraValues}
                        inputRef={inputRef}
                        isOpenDropdown={isOpenDropdown}
                        setIsOpenDropdown={setIsOpenDropdown}
                        waitingForOptions={waitingForOptions}
                        withSearchInput={withSearchInput}
                    />
                </Group>
            </div>
        );
    }
;

AsyncSearchSelectFieldNew.defaultProps = {
    disabled: false,
    formatOptionValue: (inputValue) => (<></>),
    selectedOption: (inputValue) => (<>{inputValue.name}</>),
    optionsParser: (options) => options?.map((i) => i),
    sort: (options) => options.map((i) => i),
    className: '',
    defaultValue: {},
    rest: '',
    name: '',
    setValue: () => {
    },
    floatedLabel: false,
    isSearchable: true,
    searchOnFocus: false,
    withSearchInput: false,
}

export default AsyncSearchSelectFieldNew;
