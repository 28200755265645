import Model from "../../../../src_shared/modules/model/Model";
import ValidationError from "../../../../src_shared/form/validation/ValidationError";

export class FreshmanModel extends Model {
	getModel() {
		return {
			id: 0,
			freshmen_status: '',
			freshmen_note: ''
		};
	}

	getValidators() {
		return {
			freshmen_status: ValidationError.skip,
			freshmen_note: ValidationError.skip,
		};
	}

	buildDTO(data) {
		return {
			freshmen_status: data.freshmen_status,
			freshmen_note: data.freshmen_note,
		};
	}
}

export default new FreshmanModel();
