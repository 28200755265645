import React, {useCallback} from 'react';
import debounce from 'lodash.debounce';
import useLang from "../../../../../../../src_shared/hooks/useLang";

const SearchInput = ({search, placeholder, withIcon = true, className}) => {
	const {getLangText} = useLang();

	const onChange = (e) => {
		search.updateSearchText(e.target.value)
		debouncedSave(e)
	}

	const debouncedSave = useCallback(
		debounce((e) => search.callSearch(e.target.value), 500),
		[]
	);

	return (
		<div className={`form-group search-form ${className}`}>
			<input
				className="form-control"
				type="text"
				onChange={onChange}
				value={search.searchText}
				placeholder={getLangText(placeholder) || placeholder}
			/>
			{withIcon && <i className="icon-magnifier"/>}
		</div>
	);
};

export default SearchInput;
