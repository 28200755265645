import React from 'react';
import FunctionalityPaymentRangeTypes, {FUNCTIONALITY_RANGE_MONTHLY} from "./FunctionalityPaymentRangeEnums";
import moment from "moment";
import useLang from "../../../../../src_shared/hooks/useLang";
import {convertToUserTimeZone} from "../../../shared/helpers/dateHelpers";

const FunctionalityPaymentRange = ({values, setValue, className = '', hiddenRange, userData}) => {

	const options = FunctionalityPaymentRangeTypes.filter((i) => i.id !== hiddenRange);
	const {getLangText} = useLang();

	const PaymentRangeDate = ({item}) => {
		if (item.id === FUNCTIONALITY_RANGE_MONTHLY) {
			return (
				<div className="data">
					{getLangText('fromLabel')}&nbsp;{convertToUserTimeZone().format('DD.MM.YY')} r.
				</div>
			)
		}
		return (
			<div className="data">
				{getLangText('toLabel')}&nbsp;{convertToUserTimeZone(userData.user_info.premium_date).format('DD.MM.YY')} r.
			</div>
		)
	}

	return (
		<div className={`select-boxes-wrapper ${className}`}>
			<div className="section-label">
				{getLangText('paymentSelectPaymentMethodLabel')}
			</div>
			<div className={`data-boxes-wrapper flex-wrap small-padd inline ${options.length === 1 ? 'single-option' : ''}`}>
				{options.map((item) => (
					<div className="py-sm-0 w-sm-50 w-100">
						<div
							key={item.id}
							className="content payment-content"
						>
							<div className="content__top-part">
								<input
									onChange={() => setValue('selected_subscription', item.id)}
									checked={values.selected_subscription === item.id}
									type="radio"
									name="selected_functionality_subscription"
								/>
								<span className="checkmark"/>
								<div className="flex-grow-1">
									{getLangText(item.label) || item.label}
									<PaymentRangeDate item={item} />
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default FunctionalityPaymentRange;
