import React from 'react';

const Skeleton = () => {
    return (
        <>
            <div className="flex border-b border-[#E5E7EB] last:border-b-0 py-[10px] px-3">
                <div className="animate-pulse h-[34px] bg-gray-200 rounded-xl dark:bg-gray-700 w-[34px] mr-2"/>
                <div className="grow">
                    <div
                        className="animate-pulse h-[15px] mb-1 bg-gray-200 rounded-xl dark:bg-gray-700 w-full"/>
                    <div className="animate-pulse h-[10px] bg-gray-200 rounded-xl dark:bg-gray-700 w-[80px]"/>
                </div>
            </div>
            <div className="flex border-b border-[#E5E7EB] last:border-b-0 py-[10px] px-3">
                <div className="animate-pulse h-[34px] bg-gray-200 rounded-xl dark:bg-gray-700 w-[34px] mr-2"/>
                <div className="grow">
                    <div
                        className="animate-pulse h-[15px] mb-1 bg-gray-200 rounded-xl dark:bg-gray-700 w-full"/>
                    <div className="animate-pulse h-[10px] bg-gray-200 rounded-xl dark:bg-gray-700 w-[120px]"/>
                </div>
            </div>
        </>
    );
};

export default Skeleton;