import React, {useEffect, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import CopyButton from "../../../../../../src_shared/components/form/copyButton/CopyButton";
import useLang from "../../../../../../src_shared/hooks/useLang";
import {useUnitPrice} from "../../../../shared/helpers/Price";
import {useSelector} from "react-redux";
import {ANDRZEJ_GOLONKO_SHOP_ID} from "../../../../../../src_shared/enums/TrustedShopsIds";
import SwitchField from "../../../../../../src_shared/components/form/switchField/SwitchField";
import ValidationError from "../../../../../../src_shared/form/validation/ValidationError";

const PreparedPostText = ({modalVisible, hideModal, data, productCode}) => {

    const {getLangText} = useLang();
    const {userData} = useSelector((state) => state.session);
    const productTextCode = `${productCode} ${data.selmo_code}`
    const defaultFooter = getLangText('orderHandledBySelmoSystemLabel')
    const {getPrice} = useUnitPrice();

    const defaultValues = {
        text: '',
        inStock: true,
        showPrices: '1',
    }

    const [values, setValues] = useState(defaultValues)


    const samePriceInVariant = () => data.sizes.every((i) => +i.sizePrice === +data.price)

    const preparedVariants = !!+values.inStock ? data.sizes.filter((size) => !!+size.sizeSqu) : data.sizes;

    const variants = preparedVariants.map((size) => (size.deleted !== '1' && samePriceInVariant() ? `${productCode} ${data.selmo_code} ${size.sizeName}` : `${productCode} ${data.selmo_code} ${size.sizeName} ${!!+values.showPrices ? `- ${getPrice(size.sizePrice)}` : ''}`));

    const defaultTextWithVariant = `${getLangText({
        key: +userData.id === ANDRZEJ_GOLONKO_SHOP_ID ? 'toBuyProductTextInCommentLabel' : 'toOrderProductTextInCommentLabel',
        data: [data.name]
    })}\n\n${variants.join('\n')}\n${samePriceInVariant() ? `\n${getLangText('priceLabel')}: ${getPrice(data.price)}\n` : ''}\n${defaultFooter}`

    const defaultText = `${getLangText({
        key: +userData.id === ANDRZEJ_GOLONKO_SHOP_ID ? 'toBuyProductTextInCommentLabel' : 'toOrderProductTextInCommentLabel',
        data: [data.name]
    })}\n\n${productTextCode}\n\n${getLangText('priceLabel')}: ${getPrice(data.price)}\n\n${defaultFooter}`

    useEffect(() => {
        setValues({...values, text: !!+data.has_sizes ? defaultTextWithVariant : defaultText})
    }, [data.has_sizes, values.inStock, values.showPrices])

    return (
        <Modal
            dialogClassName="default-modal"
            show={modalVisible}
            onHide={hideModal}
        >
            <Modal.Header className="with-border">
                <Modal.Title>
                    {getLangText('prepareTextToPostLabel')}
                </Modal.Title>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group">
                    <label className="control-label">{getLangText('textLabel')}</label>
                    <div className="border-area">
						<textarea
                            className="form-control"
                            name="client-message"
                            rows="10"
                            value={values.text}
                            onChange={(e) => setValues({...values, text: e.target.value})}
                        />
                        <div className="bottom-part justify-content-end">
                            <CopyButton
                                copyButtonText={getLangText('copyButton')}
                                copiedButtonText={getLangText('copiedLabel')}
                                copyText={values.text}
                                withIcon={false}
                                withTooltip={false}
                                buttonClassName="primary copied"
                            />
                        </div>
                    </div>
                </div>
                {!!+data.has_sizes &&
                    <SwitchField
                        className="switch-form inline-label align-items-start"
                        label={getLangText('variantsInStockLabel')}
                        subLabel={getLangText('variantsInStockSubLabel')}
                        setValue={(field, value) => setValues({...values, inStock: value})}
                        value={values.inStock}
                        validateErrors={false}
                        getError={() => ValidationError.skip()}
                    />
                }
                {!samePriceInVariant() && !!+data.has_sizes &&
                    <div>
                        <SwitchField
                            className="switch-form inline-label align-items-start"
                            label={getLangText('showPricesLabel')}
                            subLabel={getLangText('showPricesInVariantsLabel')}
                            setValue={(field, value) => setValues({...values, showPrices: value})}
                            value={values.showPrices}
                            validateErrors={false}
                            getError={() => ValidationError.skip()}
                        />
                    </div>
                }
            </Modal.Body>
            <Modal.Footer>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button border-button"
                >
                    {getLangText('closeButton')}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default PreparedPostText;
