export const ADMIN_SIGNUP_STATUS_NEW = 'NEW'
export const ADMIN_SIGNUP_STATUS_QUALIFIED = 'QUALIFIED'
export const ADMIN_SIGNUP_STATUS_NOT_QUALIFIED = 'NOT_QUALIFIED'
export const ADMIN_SIGNUP_STATUS_POSTPONED = 'POSTPONED'

export const ADMIN_SIGNUP_STATUSES = [
	{
		value: ADMIN_SIGNUP_STATUS_NEW, label: 'New', color: 'blue',
	},
	{
		value: ADMIN_SIGNUP_STATUS_QUALIFIED, label: 'Qualified', color: 'green',
	},
	{
		value: ADMIN_SIGNUP_STATUS_NOT_QUALIFIED, label: 'Not qualified', color: 'red',
	},
	{
		value: ADMIN_SIGNUP_STATUS_POSTPONED, label: 'Postponed', color: 'orange',
	}
]

export const findSignupStatusByValue = (value) => ADMIN_SIGNUP_STATUSES.find((i) => i.value === value);
