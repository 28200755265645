import Model from "../../../src_shared/modules/model/Model";

export class DefinedVariantsModel extends Model {
	getModel() {
		return {
			sizes: [],
			colors: [],
		};
	}
}

export default new DefinedVariantsModel();
