import {prefix} from "./formReducer";
import {getStateRoot} from "./formReducer";
import {services} from "../../RestServices";
import FormActions from "../../../src_shared/form/FormActions";
import AutomaticallyNotificationsModel from "../../../modules/models/settings/AutomaticallyNotificationsModel";

export const getInstance = () => new FormActions({
	prefix,
	getStateRoot,
	restService: services.API_SETTINGS_NOTIFICATION,
	model: AutomaticallyNotificationsModel,
});

export default getInstance();

