import React from 'react';
import useLang from "../../../../src_shared/hooks/useLang";
import Modal from "react-bootstrap/Modal";
import CopyInput from "./CopyInput";

const BeforeCopyView = ({hideModal, setClicked, values}) => {
    const {getLangText} = useLang();
    return (
        <>
            <Modal.Header className="justify-content-center">
                <Modal.Title className="text-center">
                    <i className="icon-orders text-[28px] text-[#3C61EA] mb-3.5 block" />
                    <div className="title text-[22px]">
                        {getLangText('copyTextBellowLabel')}
                    </div>
                </Modal.Title>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            <Modal.Body className="pb-3 pt-4">
                <CopyInput
                    setClicked={setClicked}
                    copyText={values.copy_command}
                />
            </Modal.Body>
        </>
    );
};

export default BeforeCopyView;