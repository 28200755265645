import React, {useState} from 'react';
import CheckboxField from "../../../../../../src_shared/components/form/checkboxField/CheckboxField";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import DropdownWithPortal from "../../../../../../src_shared/components/dropdownPortal/DropdownWithPortal";
import useLang from "../../../../../../src_shared/hooks/useLang";
import {selmoUrl} from "../../../../../../src_shared/api/api";
import {services} from "../../../../../RestServices";
import {useDispatch} from "react-redux";
import {showAlert} from "../../../../shared/components/alert/AlertActions";
import ModalWithSuccessPage from "./ModalWithSuccessPage";
import SendSummaryModal from "./SendSummaryModal";
import {useParams} from "react-router-dom";
import api from "../../../../../../services/axios/axios";
import FormActions from "../../edit/FormActions";
import useWindowDimensions from "../../../../messenger/hooks/useWindowDimensions/useWindowDimensions";

const CREATE_ORDERS = 'CREATE_ORDERS';
const REMOVE_FROM_RESERVE_LIST = 'REMOVE_FROM_RESERVE_LIST';

const SelectedRow = ({
						 isSelected,
						 selectedItems,
						 selectAllItems,
						 checkIfSomeExist,
						 colSpan,
						 ListActions,
						 updateSelection,
					 }) => {
	const {getLangText} = useLang();
	const dispatch = useDispatch();
	const {id} = useParams();
	const [modalOpen, setModalOpen] = useState(null);
	const [successGenerated, setSuccessGenerated] = useState(false);
	const [generatedCount, setGeneratedCount] = useState(false);
	const [sendSummaryModalVisibility, setSendSummaryModalVisibility] = useState(false);
	const [summaryMessageData, setSummaryMessageData] = useState({message: ''})
	const [isWaiting, setIsWaiting] = useState(false);
	const maxSelected = 25;
	const isMoreThanMax = selectedItems.length > maxSelected;
	const {width} = useWindowDimensions();

	const tooltipText = getLangText({
		key: 'canSelectMaxClientsLabel',
		data: [maxSelected]
	});

	const hideSendSummaryModal = () => {
		setSendSummaryModalVisibility(false);
		dispatch(ListActions.loadData(id))
		updateSelection([])
	}

	const onSubmit = async (rest) => {
		setIsWaiting(true)
		try {
			const {data} = await api.post(`${selmoUrl}/${rest}`, {
				selected_items: selectedItems.map((i) => i.id),
			})

			setSuccessGenerated(true)
			setGeneratedCount(data.item);

			if (data.item.summary_message) {
				setSummaryMessageData({
					message: data.item.summary_message,
					selectedItems: selectedItems,
				})
			}

			if (modalOpen === REMOVE_FROM_RESERVE_LIST) {
				updateSelection([]);
				dispatch(FormActions.loadData(id))
			}

		} catch (e) {
			dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
			console.warn('Cannot send selected products')
		} finally {
			setIsWaiting(false);
		}
	}

	const openSendSummaryModal = () => {
		setModalOpen(null);
		setSendSummaryModalVisibility(true);
	}

	const closeSummaryContent = () => {
		dispatch(ListActions.loadData(id))
		setModalOpen(null);
	}

	const summaryContent = (description) => (
		!!+generatedCount?.success_count ?
			<>
				<div className="circle-icon">
					<i className="icon-tick"/>
				</div>
				<div className="title">{getLangText('doneLabel')}</div>
				<div className="desc" dangerouslySetInnerHTML={{__html: description}}/>
				<div className="modal-footer">
					{modalOpen === CREATE_ORDERS &&
						<button
							onClick={openSendSummaryModal}
							type="button"
							className="button primary small-size mb-2"
						>
							{getLangText('sendSummariesLabel')}
						</button>
					}
					<button
						onClick={closeSummaryContent}
						type="button"
						className="button border-button"
					>
						{getLangText('closeButton')}
					</button>
				</div>
			</> :
			<>
				<div className="circle-icon red">
					<i className="icon-cross"/>
				</div>
				<div className="title">{getLangText('upsLabel')}</div>
				<div className="desc" dangerouslySetInnerHTML={{__html: description}}/>
				<div className="modal-footer">
					<button
						onClick={() => setModalOpen(null)}
						type="button"
						className="button border-button"
					>
						{getLangText('closeButton')}
					</button>
				</div>
			</>
	)

	const DropdownPortal = () => {
		return (
			<DropdownWithPortal
				ButtonToggle={() => <span>{getLangText('selectActionLabel')}</span>}
				icon="icon-arrows"
				buttonClassName="button border-button"
				className="right"
			>
				{isMoreThanMax ?
					<OverlayTrigger
						placement='top'
						overlay={
							<Tooltip id="generate-label-tooltip">
								<span dangerouslySetInnerHTML={{__html: tooltipText}}/>
							</Tooltip>
						}
					>
						<button
							type="button"
							className="custom-option disabled"
						>
							{getLangText('createOrdersLabel')}
						</button>
					</OverlayTrigger>
					:
					<button
						type="button"
						className="custom-option"
						onClick={() => setModalOpen(CREATE_ORDERS)}
						disabled={isMoreThanMax}
					>
						{getLangText('createOrdersLabel')}
					</button>
				}
				{isMoreThanMax ?
					<OverlayTrigger
						placement='top'
						overlay={
							<Tooltip id="generate-label-tooltip">
								<span dangerouslySetInnerHTML={{__html: tooltipText}}/>
							</Tooltip>
						}
					>
						<button
							type="button"
							className="custom-option disabled"
						>
							{getLangText('removeButton')}
						</button>
					</OverlayTrigger>
					:
					<button
						type="button"
						className="custom-option"
						onClick={() => setModalOpen(REMOVE_FROM_RESERVE_LIST)}
						disabled={isMoreThanMax}
					>
						{getLangText('removeButton')}
					</button>
				}
			</DropdownWithPortal>
		)
	}
	return (
		<th
			className={`checkbox-col ${isSelected ? 'selected' : ''}`}
			colSpan={colSpan}
		>
			<div className="selected-row">
				<div className="selected-checkbox">
					<CheckboxField
						label={width <= 991 && !isSelected ? getLangText('selectAllLabel') : ''}
						name="selectAllItems"
						className="mb-0"
						setValue={selectAllItems}
						value={checkIfSomeExist()}
					/>
					{isSelected &&
						<span>{selectedItems.length} {getLangText('selectedLabel')}</span>
					}
				</div>
				{isSelected &&
					<DropdownPortal/>
				}
			</div>
			{modalOpen === CREATE_ORDERS &&
				<ModalWithSuccessPage
					summaryContent={() => summaryContent(getLangText({
						key: 'successCreateOrderFromProductsLabel',
						data: [generatedCount?.success_count, generatedCount?.all_count]
					}))}
					successGenerated={successGenerated}
					generatedCount={generatedCount}
					title={getLangText('createOrdersLabel')}
					SubTitle={() =>
						<span>{getLangText('wantToCreateOrdersForClientsLabel')} ({selectedItems.length})</span>}
					saveButton={getLangText('createButton')}
					show={modalOpen === CREATE_ORDERS}
					isWaiting={isWaiting}
					onSubmit={() => onSubmit(services.API_BATCH_CREATE_PRODUCT_ORDERS)}
					hide={() => setModalOpen(null)}
					ListActions={ListActions}
				/>
			}
			{modalOpen === REMOVE_FROM_RESERVE_LIST &&
				<ModalWithSuccessPage
					summaryContent={() => summaryContent(getLangText({
						key: 'successRemovedClientsFromReserveListLabel',
						data: [generatedCount?.success_count, generatedCount?.all_count]
					}))}
					successGenerated={successGenerated}
					generatedCount={generatedCount}
					title={getLangText('removeClientsLabel')}
					SubTitle={() =>
						<span>{getLangText('removeClientsFromReserveListLabel')} ({selectedItems.length})</span>}
					saveButton={getLangText('removeButton')}
					show={modalOpen === REMOVE_FROM_RESERVE_LIST}
					isWaiting={isWaiting}
					onSubmit={() => onSubmit(services.API_BATCH_REMOVE_RESERVE_LIST)}
					hide={() => setModalOpen(null)}
					submitButtonClass="danger"
					ListActions={ListActions}
				/>
			}
			{sendSummaryModalVisibility &&
				<SendSummaryModal
					modalVisible={sendSummaryModalVisibility}
					hide={hideSendSummaryModal}
					summaryMessageData={summaryMessageData}
				/>
			}
		</th>
	);
};

export default SelectedRow;
