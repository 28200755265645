import React, {useEffect, useState} from 'react';
import Loader from "../../../../../../messenger/loader/Loader";
import BalanceModal from "../../../../../details/selmoPaczka/components/shared/BalanceModal";
import useLang from "../../../../../../../../src_shared/hooks/useLang";
import useFetchData from "../../../../../../../../src_shared/hooks/useFetchData";
import {services} from "../../../../../../../RestServices";
import SelmoPaczkaHeader from "./components/SelmoPaczkaHeader";
import useFormLocal from "../../../../../../../../src_shared/hooks/useFormLocal";
import ComponentLoader from "../../../../../../../../src_shared/components/ui/componentLoader/ComponentLoader";
import SelmoPaczkaDataModal from "../../../../../details/selmoPaczka/components/dataForm/SelmoPaczkaDataModal";
import Model from "../../../../../../../../src_shared/modules/model/Model";
import {batch, useDispatch} from "react-redux";
import {showAlert} from "../../../../../../shared/components/alert/AlertActions";
import SessionActions from "../../../../../../shared/session/SessionActions";
import ListActions from "../../../../ListActions";


export const DPD = 'dpd';
export const DPD_PICKUP = 'dpd_pickup';
export const ORLEN = 'op';
const SelmoPaczka = ({hideModal, selectedItems}) => {
    const {getLangText} = useLang();
    const [showBalanceModal, setShowBalanceModal] = useState(false);
    const [showAll, setShowAll] = useState(false);
    const [showDataFormModal, setShowDataFormModal] = useState(false);
    const [activeView, setActiveView] = useState(DPD);
    const dispatch = useDispatch();
    const [minBalance, setMinBalance] = useState(0);

    const [{data, fetchData, isLoading}] = useFetchData({
        rest: `${services.API_PACKAGE_BATCH_SP_PRICES}?type=${activeView}`,
        initialData: {
            sp_active: 0,
            items: [],
        },
        initialAutoLoad: false,
        afterSubmit: (response) => setIsQualified(!!+response.sp_active)
    })

    const afterSubmit = (response) => {
        // fetchData()
        batch(() => {
            // TODO - otworzyc modal z udalo sie 5/10 i przycisk pobierz
            // dispatch(showAlert('labelHasBennGeneratedLabel', ''))
            dispatch(ListActions.afterGenerate(response));
            // hideModal();
            dispatch(SessionActions.updateUserData('sp_balance', response.balance_amount))
        })
    }

    const onError = (e) => {
        if (e?.response?.data?.message === 'NOT_ENOUGH_BALANCE') {
            setShowBalanceModal(true);
            setMinBalance(e?.response?.data?.item?.min_balance);
        } else {
            dispatch(showAlert(e?.response?.data?.message, '', 'alert-fail'))
        }
    }

    const [{onSave, isWaiting, values, setValue}] = useFormLocal({
        model: new Model(),
        rest: services.API_PACKAGE_BATCH_SP_LABELS,
        initialAutoLoad: false,
        onError,
        afterSubmit,
    });

    useEffect(() => {
        setValue('orders', selectedItems.map((i) => i.id))
    }, [])

    const [isQualified, setIsQualified] = useState(false);

    const itemsToShow = showAll ? data.items : data.items?.slice(0, 3);

    useEffect(() => {
        fetchData()
    }, [activeView])

    return (
        <>
            <SelmoPaczkaHeader
                setActiveView={setActiveView}
                activeView={activeView}
            />
            <form onSubmit={onSave}>
                {isLoading ?
                    <ComponentLoader
                        className="small-loader"
                        style={{minHeight: '150px'}}
                    /> :
                    <div className="mt-3">
                        <div className="font-bold text-sm mb-2">{getLangText('selectSize')}</div>
                        <div className="data-boxes-wrapper separated extra-small-padd">
                            {itemsToShow.map((item) => (
                                <div
                                    className="animate-[fadeIn_.3s_ease_forwards]"
                                    key={item.id}>
                                    <div className="content payment-content">
                                        <div className="content__top-part">
                                            <input
                                                type="radio"
                                                name="price"
                                                checked={values.price === item.id}
                                                onChange={() => setValue('price', item.id)}
                                            />
                                            <span className="checkmark"/>
                                            <div className="flex-grow-1 flex items-center">
                                                <div className="font-bold">
                                                    {item.description}
                                                </div>
                                                <div className="ml-auto font-medium text-desc">
                                                    {item.price} zł
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {data?.items?.length > 3 && (
                                <button
                                    type="button"
                                    className="button border-button w-full small-size mb-2"
                                    onClick={() => setShowAll(!showAll)}>
                                    {showAll ? 'Pokaż mniej' : 'Pokaż wszystkie gabaryty'}
                                </button>
                            )}
                            {activeView === ORLEN ?
                                <div
                                    className="form-info-box align-items-center w-100 small-line-height mb-3">
                                    <div>
                                        <i className="icon-info-c"/>
                                    </div>
                                    <div>
                                        <div className="title">
                                            Paczkę należy zanieść do automatu
                                        </div>
                                    </div>
                                </div> :
                                <div
                                    className="form-info-box align-items-center w-100 small-line-height mb-3">
                                    <div>
                                        <i className="icon-info-c"/>
                                    </div>
                                    <div>
                                        <div className="title">
                                            Kurier przyjedzie w następnym dniu roboczym
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }
                <div className="modal-footer">
                    <button
                        onClick={hideModal}
                        type="button"
                        className="button border-button"
                    >
                        {getLangText('closeButton')}
                    </button>
                    <div className="tooltip-parent">
                        {!values.price &&
                            <div className="tooltip-content">
                                {getLangText('selectSize')}
                            </div>
                        }
                        <button
                            disabled={!values.price}
                            onClick={(isQualified === null || !isQualified) ? () => setShowDataFormModal(true) : onSave}
                            type="button"
                            className={`button primary ${!values.price ? 'not-active' : ''} ${isWaiting ? 'loading-button' : ''}`}
                        >
                            {isWaiting && <Loader/>}
                            {getLangText('generateLabel')}
                        </button>
                    </div>
                </div>
            </form>
            {showBalanceModal &&
                <BalanceModal
                    hideModal={() => setShowBalanceModal(false)}
                    show={showBalanceModal}
                    extraValues={{
                        orders: selectedItems.map((i) => i.id),
                        price: values.price,
                        amount: minBalance,
                    }}
                />
            }
            {showDataFormModal &&
                <SelmoPaczkaDataModal
                    fetchData={() => setIsQualified(true)}
                    showModal={showDataFormModal}
                    hide={() => setShowDataFormModal(false)}
                />
            }
        </>
    );
};

export default SelmoPaczka;
