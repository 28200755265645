import React from 'react';
import StylesLoader from "../../../../shared/Loader.jsx";
import {useDispatch} from "react-redux";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {updateStorageProduct} from "../LiveDetailsActions.js";
import Loader from "../../../../shared/Loader.jsx";
import ProductVariants from "./components/ProductVariants.jsx";
import useLang from "../../../../../../src_shared/hooks/useLang";
import {useUnitPrice} from "../../../../shared/helpers/Price";
import ServerImage from "../../../../shared/components/serverImage/ServerImage";

const ProductsInBasket = ({product, customers, isWaiting}) => {
	const {getLangText} = useLang();
	const {getPrice} = useUnitPrice();

	const dispatch = useDispatch();
	const updateStorageProductOnNameChange = () => dispatch(updateStorageProduct())

	if (!product.id) {
		return null;
	}

	return (
		<div className="default-shadow-box summary-box loader-parent mt-3">
			<StylesLoader isLoading={false}/>
			<div className="top-part with-border">
				<div className="flex-grow-1">
					<div className="title">
						{getLangText('currentSellingLabel')}:
						<OverlayTrigger
							placement='top'
							overlay={
								<Tooltip
									className="small-size"
									id="refresh-price">
									{getLangText('refreshProductAvailabilityLabel')}
								</Tooltip>
							}
						>
							<button
								type="button"
								className="button refresh-price ml-auto"
								onClick={updateStorageProductOnNameChange}
								disabled={isWaiting}
							>
								<i className="icon-refresh"/>
							</button>
						</OverlayTrigger>
					</div>
				</div>
			</div>
			<div className="current-basket-products loader-parent">
				<Loader isLoading={isWaiting}/>
				<div className="product-box">
					<a
						target="_blank"
						href={`/magazyn/edytuj/${product.id}/`}
						className="d-flex align-items-center"
					>

						<figure>
							{product.photo && product.photo !== '0' ?
								<ServerImage src={product.photo}/> :
								<img src="/assets/images/default/product.svg"/>
							}
						</figure>
						<div className="product-name">{product.name}</div>
					</a>
					<div className="product-price">{getPrice(product.price)}</div>
				</div>
				<ProductVariants
					product={product}
					customers={customers}
				/>
			</div>
		</div>
	);
};

export default ProductsInBasket;
