import React from 'react';
import {Link} from "react-router-dom";

const DisabledOption = ({option, text, children, title, className, imgSrc, link, buttonText}) => {

	if (!!+option) {
		return children;
	}

	return (
		<div className={`empty-state ${className}`}>
			<div className="img-icon">
				<img src={imgSrc}
					 alt=""/>
			</div>
			<div className="title" dangerouslySetInnerHTML={{__html: `${title}`}}/>
			<div className="desc">{text}</div>
			<Link className="button primary small-size mt-3" to={link}>{buttonText}</Link>
		</div>
	);
};

export default DisabledOption;
