import React from 'react';
import {statuses} from "../ChatList.jsx";
import useLang from "../../../../../src_shared/hooks/useLang";

const StatusBox = ({statusId}) => {
	const {getLangText} = useLang();

    const getStatus = (statusId) => statuses.find((i) => i.id === Number(statusId))

    return (
        +statusId !== 0 &&
        <div className={`status ${getStatus(statusId)?.color}`}>
            {getLangText(getStatus(statusId)?.name) || getStatus(statusId)?.name}
        </div>
    );
};

export default StatusBox;
