export const MONDIAL_RELAY_LABEL_24R = '24R';
export const MONDIAL_RELAY_LABEL_24L = '24L';
export const MONDIAL_RELAY_LABEL_HOM = 'HOM';


export const MONDIAL_RELAY_LABEL = [
    {
        id: MONDIAL_RELAY_LABEL_24R, label: 'mondialRelayLabel24RLabel',
    },
    {
        id: MONDIAL_RELAY_LABEL_24L, label: 'mondialRelayLabel24LLabel',
    },
    {
        id: MONDIAL_RELAY_LABEL_HOM, label: 'mondialRelayLabelHOMLabel',
    },
]
