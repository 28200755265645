import React from 'react';
import ConfirmModal from "../../../../live/details/default/summary/export/ConfirmModal";
import useRemoveData from "../../../../../../src_shared/hooks/useRemoveData";
import useLang from "../../../../../../src_shared/hooks/useLang";
import useSubmitData from "../../../../../../src_shared/hooks/useSubmitData";
import {services} from "../../../../../RestServices";
import {useDispatch} from "react-redux";
import {showAlert} from "../../../../shared/components/alert/AlertActions";

const SendDigitalInEmail = ({product, id}) => {
    const {getLangText} = useLang();
    const dispatch = useDispatch();

    const [{
        openModal: openRemoveModal,
        hideModal: hideRemoveModal,
        confirmModalVisible,
        itemToRemove,
    }] = useRemoveData({
        rest: '',
    });

    const afterSubmit = () => {
        hideRemoveModal();
        dispatch(showAlert('yuhuLabel', 'successfullySentEmail'))
    }


    const [{onSave, isWaiting}] = useSubmitData(
        {
            rest: services.API_DIGITAL_PRODUCTS_EMAIL,
            afterSubmit,
            values: {
                order_id: id,
                product_id: itemToRemove.id
            }
        });

    return (
        <>
            <button
                type="button"
                onClick={() => openRemoveModal(product)}
                className="button border-button left-icon ml-3 mt-[5px]"
            >
                <i className="icon-mail"/>
                {getLangText('sendEmail')}
            </button>
            {confirmModalVisible &&
                <ConfirmModal
                    dialogClassName="w-420"
                    title={getLangText('sendEmail')}
                    SubTitle={() => (
                        <span
                            dangerouslySetInnerHTML={{
                                __html: getLangText({
                                    key: 'sendEmailWithDigitalProduct',
                                    data: [itemToRemove.product_name]
                                })
                            }}
                        />
                    )}
                    saveButton={getLangText('sendButton')}
                    show={confirmModalVisible}
                    isWaiting={isWaiting}
                    onSubmit={onSave}
                    hide={hideRemoveModal}
                />
            }

        </>
    );
};

export default SendDigitalInEmail;
