import React from 'react';
import Select from "react-select";
import {ORDER_STATUES} from "../../../shared/enums/OrderStatuses";
import useLang from "../../../../../src_shared/hooks/useLang";

const StatusSelect = ({statusId, setValue, className = ''}) => {

	const {getLangText} = useLang();
	const preparedOrderStatuses = ORDER_STATUES.map((i) => ({
		...i,
		label: getLangText(i.label)
	}))
	const status = preparedOrderStatuses.find((i) => i.value === Number(statusId))

	return (
		<Select
			className={`dropdown-default status-dropdown ${className} ${status?.color}`}
			id="dropdown-default"
			value={status}
			options={preparedOrderStatuses}
			onChange={(status) => setValue(status.value)}
			hideSelectedOctions={false}
			isSearchable={false}
			placeholder={getLangText('selectStatusLabel')}
		/>
	);
}
export default StatusSelect;
