import Model from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

class OrderCancellationPackageModel extends Model {
    getModel() {
        return {
            days: null,
            active: '0',
            message_active: '0',
            message_text: '0',
            warning_active: '0',
            warning_message: '',
            warning_days: null,
        };
    }

    getValidators() {
        return {
            days: ValidationError.combine(
                ValidationError.notEmpty,
                ValidationError.greaterThan(0),
            ),
            active: ValidationError.skip,
            message_active: ValidationError.skip,
            message_text: ValidationError.skip,
            warning_active: ValidationError.skip,
            warning_days: (value, data) => !!+data.warning_active ? ValidationError.smallerThan(data.days, 'amountHaveToBeSmallerThenAmountDaysAfterOrderWillBeCancelled')(value, data) : false,
            warning_message: (value, data) => !!+data.warning_active ? ValidationError.notEmpty(value) : false
        };
    }
}

export default new OrderCancellationPackageModel();
