import React, {useContext} from 'react';
import FormActions from "./FormActions";
import useForm from "../../../../../src_shared/form/useForm";
import Loader from "../../../shared/Loader";
import useLang from "../../../../../src_shared/hooks/useLang";
import Field from "../../../../../src_shared/components/form/field/Field";
import SwitchField from "../../../../../src_shared/components/form/switchField/SwitchField";
import {Helmet} from "react-helmet";
import ConfirmModal from "../../../live/details/default/summary/export/ConfirmModal";
import {Link, useHistory, useParams} from "react-router-dom";
import {RouterPaths} from "../../../../routes/RouterPath";
import Ckeditor from "../../../../../src_shared/components/form/ckeditor/Ckeditor";
import ShopNameBar from "../../../../routes/shopSettingsRoute/ShopNameBar";
import {useDispatch} from "react-redux";
import {ShopSettingsContext} from "../../../../routes/shopSettingsRoute/ShopSettingsRoute";

const ShopSettingsPage = () => {
	const {getLangText} = useLang();
	const {id} = useParams();
	const dispatch = useDispatch();
	const {setIsChanging} = useContext(ShopSettingsContext);
	const history = useHistory();

	const {
		data: {values, setValue},
		form: {isWaiting, getFieldProps},
		modal: {modalVisible, hideModal, showModal}
	} = useForm({
		FormActions,
		params: {
			id,
		},
	});

	const onSave = () => dispatch(FormActions.submitEditForm(values, history, () => setIsChanging(new Date())))
	const deleteWithoutAsk = () => dispatch(FormActions.deleteWithoutAsk(history, () => setIsChanging(new Date())))

	return (
		<>
			<Helmet>
				<meta charSet="utf-8"/>
				<title>{getLangText('shopSettingsPagesPageLabel')}Selmo</title>
			</Helmet>
			<div className="header-bar breadcrumbs-in-react-app">
				<ul className="breadcrumbs">
					<li>
						<Link to={RouterPaths.ShopPages}>{getLangText('asidePagesLabel')}</Link>
					</li>
					<li className="active">
						<div>{getLangText('editionLabel')}</div>
					</li>
				</ul>
			</div>
			<ShopNameBar redirect={`pages/${values.url}`}/>
			<div className={`settings-page sub-settings-page`}>
				<div className="settings-form loader-parent">
					<Loader isLoading={isWaiting}/>
					<h2 className="label">{getLangText('settings_navbar_general')}</h2>
					<section className="mt-2">
						<div className="form-col">
							<Field
								{...getFieldProps('name')}
								placeholder={getLangText('typePageNameLabel')}
								label={getLangText('pageNameLabel')}
								setValue={setValue}
							/>
							<Field
								{...getFieldProps('url')}
								label={getLangText('pageLinkLabel')}
								setValue={setValue}
								placeholder={getLangText('typePageLinkLabel')}
							/>
						</div>
					</section>
					<h2 className="label">{getLangText('pageContentLabel')}</h2>
					<Ckeditor
						{...getFieldProps('text')}
						value={values.text || ''}
						setValue={setValue}
						showLabel={false}
						className="big-min-height"
					/>
					<div className="d-flex flex-wrap mt-sm-3 mt-4">
						<SwitchField
							{...getFieldProps('active')}
							setValue={setValue}
							label={getLangText('activatePageLabel')}
							id="active"
							className="switch-form inline-label big-label light-version mb-0 mt-0"
						/>
						<button
							type="button"
							className="ml-auto button primary w-sm-auto w-100 mt-sm-0 mt-3"
							onClick={onSave}
						>
							{getLangText('saveButton')}
						</button>
					</div>
					<hr/>
					<button
						type="button"
						className="button border-button big-size left-icon"
						onClick={showModal}
					>
						<i className="icon-bin"/>
						{getLangText('removePageLabel')}
					</button>
					<ConfirmModal
						dialogClassName="w-380"
						title={getLangText('wantToRemovePageLabel')}
						SubTitle={() => <span dangerouslySetInnerHTML={{
							__html: getLangText({
								key: 'askForRemovePageWithNameLabel',
								data: [values.name]
							})
						}}/>}
						saveButton={getLangText('removeButton')}
						show={modalVisible}
						isWaiting={false}
						onSubmit={deleteWithoutAsk}
						hide={hideModal}
						saveButtonClass="danger"
					/>
				</div>
			</div>
		</>
	);
};

export default ShopSettingsPage;
