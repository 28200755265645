import Model from "../../../src_shared/modules/model/Model";

class ShippyProModel extends Model {
	getModel() {
		return {
			templates: [],
		};
	}

	getValidators() {
		return {
			// code: ValidationError.notEmpty,
		};
	}
}

export default new ShippyProModel();
