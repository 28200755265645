import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

export default (actions, getStateRoot) => {
    const selectModalVisible = createSelector(
        getStateRoot,
        stateRoot => stateRoot.modalVisible,
    );
    const modalVisible = useSelector(selectModalVisible);
    const dispatch = useDispatch();
    const showModal = () => dispatch(actions.showModal());
    const hideModal = () => dispatch(actions.hideModal());

    return {
        showModal,
        hideModal,
        modalVisible,
    };
};
