export const ADMIN_INVOICES_INVOICE_ISSUED = 'invoice_issued'
export const ADMIN_INVOICES_EMAIL_SENT = 'email_sent'

export const ADMIN_INVOICES_FILTERS = [
	{
		value: ADMIN_INVOICES_INVOICE_ISSUED, label: 'Faktura wystawiona',
	},
	{
		value: ADMIN_INVOICES_EMAIL_SENT, label: 'E-mail wysłany',
	},
]
