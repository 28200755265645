import React from 'react';
import {Link, NavLink} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import useLang from "../../../../src_shared/hooks/useLang";
import {Helmet} from "react-helmet";

const LusopayWrapper = ({children}) => {
    const {getLangText} = useLang();

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Lusopay - Selmo</title>
            </Helmet>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <Link to={RouterPaths.Settings}>
                            {getLangText('asideSettingsLabel')}
                        </Link>
                    </li>
                    <li>
                        <Link to={RouterPaths.SettingsPayments}>
                            {getLangText('paymentsLabel')}
                        </Link>
                    </li>
                    <li className="active">
                        <div>Lusopay</div>
                    </li>
                </ul>
                <Link
                    to={RouterPaths.SettingsPayments}
                    className="button back-to-panels mb-3">
                    <i className="icon-arrow-left"/>
                    {getLangText('button_back_to_payment')}
                </Link>
            </div>
            <div className="row small-padd-row">
                <div className="col-lg-7">
                    <div className="settings-page sub-settings-page export-sub-settings">
                        <div className="header-wrapper no-border pb-0">
                            <h1>
                                <a
                                    rel="noreferrer"
                                    href="https://subscribe.lusopay.com/subscribe_request.aspx?culture=pt-PT&codeP=SELMO-SE001"
                                    target="_blank"
                                    className="no-underline"
                                >
                                    <img style={{width: 'auto', height: '30px'}} src="/assets/images/payments/lusopay.png"
                                         alt="Lusopay"/>
                                </a>
                                Lusopay
                            </h1>
                            {/*<div className="description">*/}
                            {/*    {getLangText('realizeOrdersFasterLabel')}*/}
                            {/*</div>*/}
                        </div>
                        {children}
                    </div>
                </div>
                {/*<div className="col-md-5 mt-md-0 mt-3">*/}
                {/*    <div className="default-shadow-box bigger-y-padd payment-benefits-wrapper">*/}
                {/*        <div className="label">{getLangText('paymentsHowItWorksLabel')}</div>*/}
                {/*        <ul className="benefits-list">*/}
                {/*            <li>*/}
                {/*                <i className="icon-tick-c"/>*/}
                {/*                {getLangText('oneCLickGenerateLabelsLabel')}*/}
                {/*            </li>*/}
                {/*            <li>*/}
                {/*                <i className="icon-tick-c"/>*/}
                {/*                {getLangText('saveTimeLabel')}*/}
                {/*            </li>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </>
    );
};

export default LusopayWrapper;
