import {RouterPaths} from "../../../routes/RouterPath";

export const ORDERS_LIST_VIEW = 'ORDERS_LIST_VIEW';
export const ORDERS_PACKING_CLOSED_LIST_VIEW = 'ORDERS_PACKING_CLOSED_LIST_VIEW';

const OrdersListViews = [
	{
		id: ORDERS_LIST_VIEW,
		label: 'allLabel',
	},
	{
		id: ORDERS_PACKING_CLOSED_LIST_VIEW,
		label: 'closePackagesLabel',
	},
];

export default OrdersListViews;
