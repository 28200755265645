import Model from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

class CreateInstagramModel extends Model {
	getModel() {
		return {
			email: '',
		};
	}

	getValidators() {
		return {
			email: ValidationError.combine(
				ValidationError.notEmpty,
				ValidationError.validateEmail,
			),
		};
	}
}

export default new CreateInstagramModel();
