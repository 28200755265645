import React, {useEffect} from 'react';
import useLang from "../../../../src_shared/hooks/useLang";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import useDataApi from "../../../../src_shared/hooks/useDataApi";
import {services} from "../../../RestServices";
import ComponentLoader from "../../../app/shared/components/componentLoader/ComponentLoader";
import NotConnected from "./NotConnected";
import Connected from "./Connected";
import {Helmet} from "react-helmet";

const InstagramSettings = () => {
    const {getLangText} = useLang();
    const [{data, isLoading}] = useDataApi(services.API_SETTINGS_INSTAGRAM, {instagram_page_access_token: null})

    if (isLoading) return <ComponentLoader/>;

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Instagram - Selmo</title>
            </Helmet>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <Link to={RouterPaths.Settings}>{getLangText('asideSettingsLabel')}</Link>
                    </li>
                    <li>
                        <Link to={RouterPaths.SettingsMeta}>Meta</Link>
                    </li>
                    <li className="active">
                        <div>Instagram</div>
                    </li>
                </ul>
            </div>
            <div className="settings-page sub-settings-page">
                <div className="header-wrapper">
                    <h1>
                        <img style={{width: '24px', height: '24px', marginRight: '4px'}}
                             src="/assets/images/svg/instagram-icon-new.svg" alt="instagram"/>
                        Instagram
                    </h1>
                    <div className="description">
                        {getLangText('connectPageInstagramPossibilitiesLabel')}
                    </div>
                </div>
            </div>
            {data.instagram_page_access_token ?
                <Connected data={data}/> :
                <NotConnected data={data}/>
            }
        </>
    );
};

export default InstagramSettings;
