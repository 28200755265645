import React from "react";
import {useDispatch} from "react-redux";
import Loader from "../../../messenger/loader/Loader";
import {useRemoveModal} from "../../../shared/hooks/useRemoveModal";
import {services} from "../../../../RestServices";
import EditOrderFormActions from "../form/FormActions";
import ConfirmModal from "../../list/components/export/ConfirmModal";
import moment from "moment";
import StylesLoader from "../../../shared/Loader";
import useLang from "../../../../../src_shared/hooks/useLang";
import {useDownloadFile} from "../../../../../src_shared/hooks/useDownloadFile";
import {usePrintFile} from "../../../../../src_shared/hooks/usePrintFile";
import useFormLocal from "../../../../../src_shared/hooks/useFormLocal";
import {showAlert} from "../../../shared/components/alert/AlertActions";
import OrlenPaczkaLabels from "../../../shared/enums/OrlenPaczkaLabelsTypes";
import OrlenPaczkaLabelModel from "../../../../../modules/models/order/OrlenPaczkaLabelModel";
import {convertToUserTimeZone} from "../../../shared/helpers/dateHelpers";

const OrlenLabel = ({data}) => {
    const dispatch = useDispatch();
    const {getLangText} = useLang();

    const loadData = () => dispatch(EditOrderFormActions.loadData(data.shop_order_id))

    const [{onSave, isWaiting, setValue, values}] = useFormLocal({
        rest: `${services.API_SHIPMENT_ORLEN_PACZKA}/${data.id}`,
        model: OrlenPaczkaLabelModel,
        initialAutoLoad: false,
        afterSubmit: loadData,
        onError: (e) => dispatch(showAlert(e.response?.data?.message, '', 'alert-fail'))
    });


    const [modalOpen, setModalOpen, waiting, remove] = useRemoveModal(`${services.API_SHIPMENT_ORLEN_PACZKA}/${data.id}`, loadData)

    const {download, waiting: waitingForLabel} = useDownloadFile({
        rest: `${services.API_SHIPMENT_ORLEN_PACZKA}/${data.id}`,
        fileName: 'Mondial-Relay',
    })

    const {print, waiting: waitingForPrint} = usePrintFile({
        rest: `${services.API_SHIPMENT_ORLEN_PACZKA}/${data.id}`,
    })

    if (!+data.shippingData.shop_orlen_paczka_active) {
        return null
    }

    return (
        <>
            {!data.shippingData.orlen_paczka_tracking_id ?
                <section className="summary-section mb-3">
                    <div className="section-header mb-3">
                        <div className="flex-grow-1">
                            <h2>Orlen Paczka</h2>
                            <div className="section-header__description">
                                Dodaj etykietę poprzez Orlen Paczka
                            </div>
                        </div>
                        <div>
                            <img width={30} src="/assets/images/svg/orlen-paczka.svg" alt="Orlen Paczka"/>
                        </div>
                    </div>
                    {!data.shipping_address_exist ?
                        <div
                            className="form-info-box align-items-center w-100 small-line-height">
                            <div>
                                <i className="icon-info-c"/>
                            </div>
                            <div>
                                <div className="title">{getLangText('noDeliveryAddressLabel')}</div>
                            </div>
                        </div> :
                        <form id="orlen-label-form" onSubmit={onSave}>
                            <div className="data-boxes-wrapper separated small-padd">
                                {OrlenPaczkaLabels.map((box) => (
                                    <div
                                        key={box.id}
                                        className={`content ${values.type === box.id ? 'active' : ''}`}
                                    >
                                        <div className="content__top-part">
                                            <input
                                                name="orlen-label"
                                                onChange={() => setValue('type', box.id)}
                                                checked={values.type === box.id}
                                                type="radio"
                                                required
                                            />
                                            <span className="checkmark"/>
                                            <div className="flex-grow-1 text-xs">
                                                <div className="label text-xs">{box.label}</div>
                                                <div className="text-desc font-normal">{box.sizes}</div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <button
                                type="submit"
                                className={`button primary w-full ${isWaiting ? 'loading-button' : ''}`}
                            >
                                {isWaiting && <Loader/>}
                                {getLangText('createLabelLabel')}
                            </button>
                        </form>
                    }
                </section> :
                <section className="summary-section mb-3">
                    <div className="section-header mb-3">
                        <div className="flex-grow-1">
                            <h2>Orlen Paczka</h2>
                            <div className="section-header__description">
                                Zarządzaj etykietą Orlen Paczka
                            </div>
                        </div>
                        <div>
                            <a
                                href="https://www.orlenpaczka.pl/"
                                target="_blank"
                                rel="nofollow"
                            >
                                <img width={70} src="/assets/images/svg/orlen-paczka.svg" alt="Orlen paczka"/>
                            </a>
                        </div>
                    </div>
                    <div className="loader-parent">
                        <StylesLoader
                            className="small-loader"
                            isLoading={waitingForLabel || waitingForPrint}
                        />
                        <div className="tripple-buttons">
                            <div>
                                <button
                                    onClick={print}
                                    type="button"
                                    className="button border-button big-width position-relative"
                                >
                                    {getLangText('printButton')}
                                    <i className="icon-print"/>
                                </button>
                            </div>
                            <div>
                                <button
                                    onClick={download}
                                    type="button"
                                    className="button border-button big-width position-relative"
                                >
                                    {getLangText('previewButton')}
                                    <i className="icon-eye"/>
                                </button>
                            </div>
                            <div>
                                <button
                                    onClick={() => setModalOpen(true)}
                                    type="button"
                                    className="button border-button big-width"
                                >
                                    {getLangText('removeButton')}
                                    <i className="icon-bin"/>
                                </button>
                            </div>
                        </div>
                    </div>
                    {data.shippingData.orlen_paczka_label_date &&
                        <div className="info-label mt-3">
                            <i className="icon-tick-c"/>
                            {getLangText('labelHasBennGeneratedLabel')}
                            <div className="date-box">
                                <span>{convertToUserTimeZone(data.shippingData.orlen_paczka_label_date).format('DD.MM.YYYY')}</span>
                                <span>{convertToUserTimeZone(data.shippingData.orlen_paczka_label_date).format('HH:mm:ss')}</span>
                            </div>
                        </div>
                    }
                </section>
            }
            {modalOpen &&
                <ConfirmModal
                    title="Czy na pewno chcesz usunąć etykietę Orlen Paczka"
                    SubTitle={() => <>{getLangText('actionCannotBeUnDoneLabel')}</>}
                    saveButton={getLangText('removeButton')}
                    show={modalOpen}
                    isWaiting={waiting}
                    onSubmit={remove}
                    hide={() => setModalOpen(false)}
                    submitButtonClass="danger"
                />
            }
        </>
    );
};

export default OrlenLabel;



