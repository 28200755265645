import React from 'react';
import BlackConfirmModal from "./BlackConfirmModal";
import Field from "../../../../src_shared/components/form/field/Field";
import useFormLocal from "../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../RestServices";
import Model from "../../../../src_shared/modules/model/Model";
import {showAlert} from "../../shared/components/alert/AlertActions";
import {useDispatch} from "react-redux";
import useLang from "../../../../src_shared/hooks/useLang";
import Loader from "../../../../src_shared/components/loader/Loader";

const SelmoProSignUpModal = ({
                                 show,
                                 hide,
                                 setIsSubmittedToQualified,
                             }) => {
    const dispatch = useDispatch();
    const {getLangText} = useLang();

    const [{
        onSave,
        isWaiting,
        getFieldProps,
    }] = useFormLocal({
        rest: services.API_CHECK_SELMO_PRO,
        model: new Model(),
        initialAutoLoad: false,
        afterSubmit: () => {
            hide();
            dispatch(showAlert('yuhuLabel', 'yourApplicationHasBeenSubmitted'))
            setIsSubmittedToQualified(true);
        }
    });

    return (
        <BlackConfirmModal
            show={show}
            hide={hide}
            title="contactInformation"
            subTitle="provideYourPhoneNumber"
            saveButton="signUp"
            withModalFooter={false}
            dialogClassName="w-420"
        >
            <form
                onSubmit={onSave}
                className="pb-4"
            >
                <Field
                    {...getFieldProps('phone')}
                    label={getLangText('phoneLabel')}
                    className="dark hidden-arrows"
                    type="number"
                    extraProps={{
                        autoFocus: true,
                        required: true,
                    }}
                    placeholder={getLangText('textPhoneNumberLabel')}
                />
                <button
                    className={`text-[#000] button white bg-[#fff] py-[13px] hover:bg-[#eff9ff] transition-all w-full rounded-[5px] font-bold ${isWaiting ? 'loading-button pointer-events-none' : ''}`}
                    type="submit"
                >
                    {isWaiting && <Loader isLoading={true} />}
                    {getLangText('signUp')}
                </button>
            </form>

        </BlackConfirmModal>
    );
};

export default SelmoProSignUpModal;
