import React, {useState} from 'react';
import useLang from "../../../src_shared/hooks/useLang";
import {services} from "../../RestServices";
import ComponentLoader from "../../../src_shared/components/ui/componentLoader/ComponentLoader";
import Field from "../../../src_shared/components/form/field/Field";
import useFormLocal from "../../../src_shared/hooks/useFormLocal";
import ForgetPasswordModel from "../../../modules/models/mobile/ForgetPasswordModel";
import Loader from "../shared/Loader";
import {useParams} from "react-router-dom";

const ForgetPassword = () => {
    const {getLangText} = useLang();
    const [success, setSuccess] = useState(false);
    const {id} = useParams();

    const [{onSave, isWaiting, getFieldProps, error}] = useFormLocal({
        rest: `${services.API_MOBILE_PUBLIC_RESET_PASSWORD}/${id}`,
        model: ForgetPasswordModel,
        initialAutoLoad: false,
        afterSubmit: () => setSuccess(true)
    });

    return (
        <div
            className={`bg-[#eff2f6] sm:py-0 py-6 sm:px-0 px-6 sm:min-h-auto min-h-full sm:h-[100vh] w-[100vw] flex sm:items-center sm:justify-center`}>
            <div
                className={`w-full sm:my-0 my-auto md:px-0 px-6 rounded-[12px] shadow-default bg-white md:w-[800px] min-h-[450px] md:min-h-[600px] flex flex-col animate-tutorialSlideAndFadeIn`}>
                {isWaiting ?
                    <ComponentLoader/> :
                    <div
                        className={`text-center animate-tutorialSlideAndFadeInDelay1 opacity-0 m-auto sm:w-auto w-full`}>
                        <img
                            width={100}
                            className="mx-auto mb-4"
                            src="/assets/images/selmo-logo.svg"
                            alt="facebook"
                        />
                        {error ?
                            <>
                                <h1 className="sm:text-2xl text-xl font-bold"
                                    dangerouslySetInnerHTML={{__html: getLangText('sthWentWrongWarning')}}/>
                                <a href="mailto: hello@selmo.io" className="button primary mt-4"
                                   style={{paddingTop: 16, paddingBottom: 16}}>
                                    <span className="text-sm">{getLangText('footerContactWithUsLabel')}</span>
                                </a>
                            </> :
                            success ?
                                <div>
                                    <h1
                                        className="sm:text-2xl text-xl font-bold mb-1"
                                        dangerouslySetInnerHTML={{__html: getLangText('yuhuLabel')}}
                                    />
                                    <div className="text-desc">{getLangText('passwordHasBeenResetLabel')}</div>
                                    <a href="fb436029124507062://" className="button primary mt-4"
                                       style={{paddingTop: 16, paddingBottom: 16}}>
                                        <span className="text-sm">{getLangText('backToAppLabel')}</span>
                                    </a>
                                </div> :
                                <div className="sm:w-[400px] w-full">
                                    <h1
                                        className="sm:text-2xl text-xl font-bold mb-3"
                                        dangerouslySetInnerHTML={{__html: getLangText('resetPasswordLabel')}}
                                    />
                                    <form onSubmit={onSave} className="text-left w-full">
                                        <Field
                                            {...getFieldProps('newPassword')}
                                            className="big-size"
                                            label={getLangText('input_label_new_password')}
                                            placeholder={getLangText('input_placeholder_new_password')}
                                            type="password"
                                            extraProps={{
                                                minLength: 6,
                                            }}
                                        />
                                        <Field
                                            className="big-size"
                                            {...getFieldProps('repeatPassword')}
                                            label={getLangText('input_label_confirm_password')}
                                            placeholder={getLangText('input_label_confirm_password')}
                                            type="password"
                                            extraProps={{
                                                minLength: 6,
                                            }}
                                        />
                                        <button
                                            type="submit"
                                            className={`button big-size primary mt-2 w-full ${isWaiting ? 'loading-button' : ''}`}
                                        >
                                            {isWaiting && <Loader isLoading/>}
                                            {getLangText('sendButton')}
                                        </button>
                                    </form>
                                </div>
                        }
                    </div>
                }
            </div>
        </div>
    );
};

export default ForgetPassword;