import Model, {convertObjectProperties} from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

class SelectOriginModel extends Model {
	getModel() {
		return {
			country: null,
			country_name: null,
		};
	}

	parseDTO(data) {
		return convertObjectProperties(
			data,
			{},
			{
				country: data.id,
				country_name: data.name,
				flag: data.flag
			}
		);
	}

	getValidators() {
		return {
			country: ValidationError.notEmpty,
		};
	}

	buildDTO(data) {
		return {
			country: data.country,
		};
	}}

export default new SelectOriginModel();
