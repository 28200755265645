import React, {memo} from 'react';
import {NavLink} from "react-router-dom";
import {Helmet} from "react-helmet";
import useLang from "../../../../src_shared/hooks/useLang";
import {RouterPaths} from "../../../routes/RouterPath";
import Iframe from "react-iframe-click";
import {SETTINGS_MONDIAL_RELAY} from "../../../app/shared/enums/TutorialVideoStatsTypes";
import {usePostHog} from "posthog-js/react";

const MondialRelaySettingsWrapper = ({children}) => {
    const {getLangText} = useLang()
    const posthog = usePostHog();

    const setPosthogEvent = () => {
        if (posthog) {
            posthog?.capture('TUTORIAL VIDEO', {type: SETTINGS_MONDIAL_RELAY})
        }
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Mondial - Selmo</title>
            </Helmet>
            <div className="row small-padd-row">
                <div className="col-lg-7">
                    <div className="settings-page sub-settings-page export-sub-settings">
                        <div className="header-wrapper no-border pb-0">
                            <h1>
                                <img style={{width: 'auto', height: '30px'}} src="/assets/images/svg/logomondial-relay.svg"
                                     alt="ShippyPro"/>
                            </h1>
                            <div className="description">
                                {getLangText('realizeOrdersFasterLabel')}
                            </div>
                        </div>
                        <div className="tabs-wrapper">
                            <NavLink
                                exact
                                activeClassName="active"
                                to={RouterPaths.SettingsIntegrationMondialRelay}
                            >
                                {getLangText('configurationLabel')}
                            </NavLink>
                            <NavLink
                                activeClassName="active"
                                to={RouterPaths.SettingsIntegrationMondialRelayData}
                            >
                                {getLangText('senderDataLabel')}
                            </NavLink>
                        </div>
                        {children}
                    </div>
                </div>
                <div className="col-md-5 mt-md-0 mt-3">
                    <div className="default-shadow-box bigger-y-padd payment-benefits-wrapper">
                        <div className="label">{getLangText('paymentsHowItWorksLabel')}</div>
                        <ul className="benefits-list">
                            <li>
                                <i className="icon-tick-c"/>
                                {getLangText('oneCLickGenerateLabelsLabel')}
                            </li>
                            <li>
                                <i className="icon-tick-c"/>
                                {getLangText('saveTimeLabel')}
                            </li>
                        </ul>
                        <div className="selmo-video-wrapper mt-3">
                            <Iframe
                                autoPlay={false}
                                onInferredClick={setPosthogEvent}
                                frameBorder="0" allowFullScreen="" scrolling="no"
                                allow="fullscreen"
                                src="https://www.youtube.com/embed/osut-vvZozM?si=NSN2Nuncz3XGf8pB"
                                style={{
                                    position: 'absolute',
                                    height: '100%',
                                    width: '100%',
                                    left: '0px',
                                    top: '0px'
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default memo(MondialRelaySettingsWrapper);
