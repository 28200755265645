import React from 'react';
import Modal from "react-bootstrap/Modal";
import useLang from "../../../../../../src_shared/hooks/useLang";
import ExpendituresModel from "../../../../../../modules/models/warehouse/ExpendituresModel";
import ExpendituresModalForm
    from "../../../../storage/scanner/card/product/components/expendituresModal/ExpendituresModalForm";
import useFormLocal from "../../../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../../../RestServices";
import ComponentLoader from "../../../../shared/components/componentLoader/ComponentLoader";

const ExpendituresModal = ({modalVisible, hideModal, fetchData, id}) => {

    const {getLangText} = useLang();

    const afterSubmit = async () => {
        await fetchData();
        hideModal();
    }

    const [{isWaiting, onSave, getFieldProps, values, isLoading}] = useFormLocal({
        rest: `${services.API_WAREHOUSE_EXPENDITURE}/${id}`,
        method: 'put',
        model: ExpendituresModel,
        afterSubmit,
    });

    return (
        <Modal
            dialogClassName="default-modal h-full"
            contentClassName="md:h-auto h-full"
            show={modalVisible}
            onHide={hideModal}
        >
            <Modal.Header>
                <Modal.Title>
                    {getLangText('selectExpendituresReasonLabel')}
                </Modal.Title>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            {isLoading ?
                <ComponentLoader/> :
                <ExpendituresModalForm
                    getFieldProps={getFieldProps}
                    isWaiting={isWaiting}
                    onSave={onSave}
                    hideModal={hideModal}
                    maxQuantity={values.size_squ || values.squ}
                />
            }
        </Modal>
    );
};

export default ExpendituresModal;
