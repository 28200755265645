import useLang from "../../../../../src_shared/hooks/useLang";
import Loader from "../../../../app/shared/Loader";
import React from "react";
import Wrapper from "../Wrapper";
import useList from "../../../../../src_shared/list/useList";
import {getStateRoot} from "./listReducer";
import ListActions from "./ListActions";
import SearchInput from "../../../../app/shared/components/form/SearchInput";
import EmptyList from "../../../../app/shared/EmptyList";
import {RouterPaths} from "../../../../routes/RouterPath";
import moment from "moment/moment";
import Pagination from "../../../../app/shared/components/pagination/Pagination";
import ClientAvatar from "../../../../app/live/shared/Avatar";
import {convertToUserTimeZone} from "../../../../app/shared/helpers/dateHelpers";

const ClosingOpenPackageOrders = () => {
    const {getLangText} = useLang()

    const {
        pagination: {
            changePage,
            getPagination,
            itemsPerPage,
            nextPage,
            prevPage,
            totalItems,
            updateItemsPerPage
        },
        list: {items, isWaiting},
        search,
    } = useList({
        ListActions,
        getStateRoot,

    });
    return (
        <Wrapper>
            <div className="loader-parent">
                <Loader isLoading={isWaiting}/>
                <div className="table-box-wrapper">
                    <div
                        style={{borderTopLeftRadius: 0, borderTopRightRadius: 0}}
                        className="default-shadow-box bigger-x-padd">
                        <div className="filters-part">
                            <h1 className="title flex-grow-1">
                                {getLangText('asideOrdersLabel')}
                                {totalItems > 0 &&
                                    <div className="badge">{totalItems}</div>
                                }
                            </h1>
                            <SearchInput
                                className="ml-auto"
                                placeholder={getLangText('searchPlaceholder')}
                                search={search}
                            />
                        </div>
                        <div className="boxes-wrapper">
                            <div className="flex justify-between sm:px-[25px] px-[15px]">
                                <div className="text-xs font-bold text-[#6B7280]">
                                    {getLangText('clientLabel')}
                                </div>
                                <div className="text-xs font-bold text-[#6B7280]">
                                    {getLangText('removedData')}
                                </div>
                            </div>
                            <EmptyList
                                items={items}
                                isLoading={isWaiting}
                                className="with-padd with-bg"
                                imgSrc="/assets/images/empty/orders-empty.svg"
                                title={`<strong>${getLangText('noOrdersLabel')}</strong>`}
                            >
                                {items.map((item) => (
                                    <a
                                        href={`${RouterPaths.OrdersList}/${item.shop_order_id}/`}
                                        target="_blank"
                                        key={item.id}
                                        className="box group"
                                    >
                                        <div className="flex">
                                            <ClientAvatar
                                                className="big-size"
                                                photo={item.photo}
                                                type={item.social_type}
                                            />
                                            <div className="font-medium text-sm">
                                                <div className="font-bold text-[black] group-hover:text-[black]">
                                                    {item.name}
                                                </div>
                                                <div className="text-desc font-medium">
                                                    {getLangText('customerBasketOrderLabel')}&nbsp;{item.shop_order_id}&nbsp;•&nbsp;{item.price}&nbsp;{item.currency_text}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ml-auto text-sm font-medium">
                                            <div
                                                className="text-desc text-sm">{convertToUserTimeZone(item.date).format('DD.MM.YYYY HH:mm')}</div>
                                        </div>
                                    </a>
                                ))}
                            </EmptyList>
                        </div>
                    </div>
                    <Pagination
                        getLangText={getLangText}
                        pages={getPagination()}
                        items={items}
                        itemsPerPage={itemsPerPage}
                        totalItems={totalItems}
                        onPrevPage={prevPage}
                        onChangePage={changePage}
                        onNextPage={nextPage}
                        onChangeItemsPerPage={updateItemsPerPage}
                        classes={{
                            prevIcon: 'icon-chevron left',
                            nextIcon: 'icon-chevron right',
                        }}
                        showItemsPerPageSelect
                    />
                </div>
            </div>
        </Wrapper>
    );
};

export default ClosingOpenPackageOrders;
