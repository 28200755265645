import React, {useEffect, useState} from 'react';
import useLang from "../../../../../src_shared/hooks/useLang";
import usePhotoPreview from "../../../../../src_shared/components/ui/photoPreview/usePhotoPreview";
import {Link, useHistory} from "react-router-dom";
import useSubmitData from "../../../../../src_shared/hooks/useSubmitData";
import {services} from "../../../../RestServices";
import {RouterPaths} from "../../../../routes/RouterPath";
import Loader from "../../../messenger/loader/Loader";
import {getFromLS, saveToLS} from "../../../../../src_shared/helpers/LS";
import {useSelector} from "react-redux";
import useCreatePost from "./useCreatePost";

const profits = [
    {id: 1, label: 'increasePostReach'},
    {id: 2, label: 'quicklyReachYourAudience'},
]
const IntroBanner = () => {
    const {getLangText} = useLang();
    const salesPostListBannerHidden = getFromLS('salesPostListBannerHidden')
    const {lang} = useSelector((state) => state.i18n);

    const [hiddenBanner, setHiddenBanner] = useState(false);

    const {createPost, isWaitingForPost} = useCreatePost()

    useEffect(() => {
        saveToLS('salesPostListBannerHidden', hiddenBanner);
    }, [hiddenBanner])

    useEffect(() => {
        if (salesPostListBannerHidden) {
            setHiddenBanner(true)
        }
    }, [salesPostListBannerHidden])

    if (hiddenBanner) return null;

    return (
        <div className="p-3 pl-4 flex-wrap rounded-[10px] bg-[#F6FCFF] border border-[#D7EAFE] flex items-center mt-3">
            <div className="sm:order-1 order-2">
                <div className="text-lg text-[#101827] font-bold">{getLangText('addPostAndFollowSale')}</div>
                <div className="space-y-1 my-2">
                    {profits.map((i) => (
                        <div
                            key={i.id}
                            className="flex items-start"
                        >
                            <div className="mr-2 -mt-1">
                                <i className="icon-tick-c text-[#5FA5FA] text-lg"/>
                            </div>
                            <div>
                                <div
                                    className="text-[#101827] font-medium text-sm"
                                    dangerouslySetInnerHTML={{
                                        __html: getLangText(i.label)
                                    }}
                                />
                            </div>
                        </div>
                    ))}
                </div>
                <div className="flex items-center space-x-3 mt-2">
                    <button
                        onClick={createPost}
                        type="button"
                        style={{fontSize: 12}}
                        className="bg-[#000000] text-[#fff] rounded-[5px] text-xs py-2 px-3 font-bold button black"
                    >
                        {isWaitingForPost && <Loader/>}
                        {getLangText('addPost')}
                    </button>
                    <Link
                        to={RouterPaths.SalesPostsHowItWorks}
                        className="text-[#4B5563] text-xs font-bold"
                    >
                        {getLangText('findOutMoreButton')}
                    </Link>
                </div>
            </div>
            <div className="sm:ml-auto sm:w-auto w-full sm:order-2 order-1 sm:mb-0 mb-3.5">
                <div
                    className={`relative h-[150px] w-full sm:w-[150px] rounded-[10px] overflow-hidden`}>
                    <img
                        className="h-full w-full sm:w-auto object-cover"
                        src={`/assets/images/multiLanguage/${lang}/salesPosts/intro-mini.webp`}
                        alt="preview"
                    />
                    <button
                        type="button"
                        onClick={() => setHiddenBanner(true)}
                        className="shadow-default cursor-pointer absolute top-1 right-1 w-[20px] h-[20px] rounded-[5px] bg-[white] flex items-center justify-center"
                    >
                        <i className="icon-cross text-[6px] relative text-desc"/>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default IntroBanner;
