import React from 'react';
import Loader from "../../loader/Loader";

const ComponentLoader = ({style, className = ''}) => {
	return (
		<div className="loader-parent sm:min-h-[400px] min-h-[200px]" style={style}>
			<Loader className={className} isLoading />
		</div>
	);
};

export default ComponentLoader;
