import React, {memo} from 'react';

const SummarySkeleton = ({
						   count = 10, hidden, className = '', children
					   }) => {

	if (hidden) {
		return children;
	}

	return (
		<div className={`skeleton-box count-${count}`}>
			<div className={`skeleton mb-3 d-flex align-items-center`}>
				<div className="skeleton-box title"/>
				<div className="skeleton-box ml-auto price"/>
			</div>
			<div className={`skeleton mb-3 d-flex align-items-center`}>
				<div className="skeleton-box title"/>
				<div className="skeleton-box ml-auto price"/>
			</div>
			<div className={`skeleton mb-3 d-flex align-items-center`}>
				<div className="skeleton-box title"/>
				<div className="skeleton-box ml-auto price"/>
			</div>
			<div className={`skeleton mb-3 d-flex align-items-center`}>
				<div className="skeleton-box title"/>
				<div className="skeleton-box ml-auto price"/>
			</div>
		</div>
	);
};

export default memo(SummarySkeleton);
