import React from 'react';
import LanguageModal from "./LanguageModal";
import {useSelector} from "react-redux";
import {supportedOptionLangs} from "../../../../services/i18n/i18n";
import {useModal} from "../hooks/useModal";
import useLang from "../../../../src_shared/hooks/useLang";

const LanguagePicker = ({buttonClassName, withText = false, withCountryName = false, afterSubmit, reduxPlace = 'i18n', options = supportedOptionLangs, langsActions, modalTitle}) => {

    const [openModal, setOpenModal] = useModal(false);
    const {getLangText} = useLang();
    const {lang} = useSelector((state) => state[reduxPlace]);
    const activeLang = options.find((i) => i.value === lang);

    return (
        <>
            <button
                className={`language-picker ${buttonClassName}`}
                type="button"
                onClick={(e) => {
                    e.stopPropagation()
                    setOpenModal(true)
                }}
            >
                {withText &&
                    <div className="text-xs text-desc font-medium mr-2">
                        {getLangText('languageModalTitle')}
                    </div>
                }
                <img width="20" src={`/assets/images/flags/${activeLang?.flag}.svg`} alt=""/>
                {withCountryName &&
                    <div className="text-xs text-[#1F2937] font-bold ml-2">{activeLang?.label}</div>
                }
                <i className="icon-arrows"/>
            </button>
            {openModal &&
                <LanguageModal
                    lang={lang}
                    modalTitle={modalTitle}
                    hideModal={() => setOpenModal(false)}
                    openModal={openModal}
                    afterSubmit={afterSubmit}
                    langsActions={langsActions}
                    options={options}
                />
            }
        </>
    );
};

LanguagePicker.defaultProps = {
    buttonClassName: '',
}

export default LanguagePicker;
