import React from 'react';
import useLang from "../../../../src_shared/hooks/useLang";
import {Link, NavLink, useParams} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import useFetchData from "../../../../src_shared/hooks/useFetchData";
import {services} from "../../../RestServices";
import cn from "clsx";

const FormWrapper = ({children}) => {
    const {getLangText} = useLang();
    const {id} = useParams();

    const [{data}] = useFetchData({
        rest: `${services.API_INSTAGRAM_AUTOMATIONS_INTERACTIONS_COUNTER}/${id}`,
        initialData: 0,
    })

    return (
        <>
            <div className="header-bar breadcrumbs-in-react-app">
                <Link to={RouterPaths.Automation} className="button back-to-panels mb-lg-3 mt-lg-0 mt-3">
                    <i className="icon-arrow-left"/>
                    {getLangText('backButton')}
                </Link>
            </div>
            <div className={`settings-page sub-settings-page loader-parent list-with-tabs separated-box`}>
                <div className="tabs-header">
                    <div className="page-title">
                        {getLangText('automationLabel')}
                        <div className="text-desc text-sm font-medium">
                            {getLangText('automationSubLabel')}
                        </div>
                    </div>
                    <div className="tabs-wrapper">
                        <NavLink
                            exact
                            activeClassName="active"
                            to={`${RouterPaths.AutomationEdit}/${id}`}
                        >
                            {getLangText('editionLabel')}
                        </NavLink>
                        {!!+id &&
                            <NavLink
                                activeClassName="active"
                                to={`${RouterPaths.AutomationEdit}/${id}/interactions`}
                            >
                                {getLangText('interactionsLabel')}
                                <span>{data}</span>
                            </NavLink>
                        }
                        {!!+id &&
                            <NavLink
                                activeClassName="active"
                                to={`${RouterPaths.AutomationEdit}/${id}/statistics`}
                            >
                                {getLangText('asideStatsLabel')}
                            </NavLink>
                        }
                        {!!+id &&
                            <NavLink
                                activeClassName="active"
                                to={`${RouterPaths.AutomationEdit}/${id}/newsletter`}
                            >
                                {getLangText('newsletterLabel')}
                            </NavLink>
                        }
                    </div>
                </div>
                {children}
            </div>
        </>
    )
};

export default FormWrapper;
