const initialState = {
	isLoading: true,
	isError: false,
	items: [],
	hasItems: true,
	hasMoreMessage: false,
	page: 1,
	activeConversation: {},
	noResults: false,
	isNewMessageButtonVisible: false,
}
export default (state = initialState, action) => {
	const itemsArray = [...state.items];
	const index = state.items.findIndex(i => i.id === action.id);
	switch (action.type) {
		case 'GET_MESSAGES_LIST_REQUESTED':
			return {...state, isLoading: true, noResults: false};
		case 'MESSAGES_LIST_SET_VALUE':
			return {...state, [action.field]: action.value};
		case 'SET_LIST_PAGE':
			return {...state, page: action.page}
		case 'NEXT_LIST_PAGE':
			return {...state, page: state.page + 1}
		case 'SET_ACTIVE_CONVERSATION':
			return {
				...state, activeConversation: action.activeConversation,
				items: state.items.map((item) => item.id === action.activeConversation.id ? {
					...item,
					'last_message': action.activeConversation.last_message,
					'last_update': action.activeConversation.last_update
				} : item)
			}
		case 'UPDATE_TAG_IN_USER':
			itemsArray[index].tags = [...itemsArray[index].tags, action.value]
			return {...state, items: itemsArray}
		case 'REMOVE_TAG_IN_USER':
			itemsArray[index].tags = itemsArray[index].tags.filter((i) => i.id !== action.value.id)
			return {...state, items: itemsArray}
		case 'UPDATE_FIELD_IN_ITEM':
			return {
				...state,
				items: state.items.map((item) => item.id === action.id ? {...item, [action.field]: action.value} : item)
			}
		case 'CHAT_LIST_UPDATE_ITEM':
			return {
				...state,
				items: state.items.map((item) => item.id === action.id ? action.item : item)
			}
		case 'UPDATE_MESSAGES_LIST':
			const newArray = state.page > 1 ? state.items.concat(action.items) : action.items;
			return {...state, isLoading: false, items: newArray, isError: false, noResults: false};
		case 'REMOVE_ITEM_IN_MESSAGES_LIST':
			const newItems = state.items.filter((i) => i.id !== action.id);
			return {...state, items: newItems}
		case 'CHAT_MESSAGES_LIST_HAS_MORE':
			return {...state, hasMoreMessage: action.toggle};
		case 'GET_MESSAGES_ITEMS':
			return {...state, isLoading: false, items: action.items, isError: false, noResults: false, page: 1};
		case 'SET_NO_RESULTS':
			return {...state, noResults: true, isLoading: false};
		case 'GET_MESSAGES_LIST_ERROR':
			return {...state, isLoading: false, isError: true}
		case `SET_FIELD_${action.field?.toUpperCase()}_VALUE`:
			const {field, value} = action
			return {...state, [field]: value}
		case 'SHOW_NEW_MESSAGES_BUTTON':
			return {...state, isNewMessageButtonVisible: action.toggle}
		case 'MESSENGER_CHAT_LIST_RESET':
			return initialState;
		default:
			return state;
	}
};
