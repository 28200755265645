import React, {useEffect, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {services} from "../../../../RestServices";
import useLang from "../../../../../src_shared/hooks/useLang";
import useForm from "../../../../../src_shared/form/useForm";
import FormActions from "./FormActions";
import AsyncSelectField from "../../../../../src_shared/components/form/selectField/AsyncSelectField";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import SearchInput from "../../../shared/components/form/SearchInput";
import {selmoUrl} from "../../../../../src_shared/api/api";
import {getGETParamsUrl} from "../../../../../src_shared/list/ListActions";
import Loader from "../../../shared/Loader";
import {getStatusBox} from "../../../live/details/platform/notShownList/components/table/Table";
import {useUnitPrice} from "../../../shared/helpers/Price";
import api from "../../../../../services/axios/axios";
import ServerImage from "../../../shared/components/serverImage/ServerImage";

const AssignProductsModal = ({modalVisible, categoryName}) => {

	const {getLangText} = useLang();
	const {id} = useParams();
	const dispatch = useDispatch();
	const {getPrice} = useUnitPrice();

	const [isLoading, setIsLoading] = useState(true);
	const [searchText, setSearchText] = useState('');
	const [productsOptions, setProductsOptions] = useState([]);

	const {
		data: {values, setValue, updateValues},
		form: {isWaiting, onSave, getFieldProps},
	} = useForm({
		FormActions,
		params: {id},
		preventAutoLoad: true
	});

	const getProducts = async (search, categoryId = null) => {
		setIsLoading(true)
		const params = {
			limit: 5,
		}
		if (!!search) {
			params.superSearch = search;
		}
		params.category = categoryId || id;
		try {
			const {data} = await api.get(`${selmoUrl}/${services.API_STORAGE_CATEGORY_PRODUCTS}${getGETParamsUrl(params)}`)
			setProductsOptions(data.items)
		} catch (error) {
			console.error('Cannot get products')
		} finally {
			setIsLoading(false);
		}
	};
	const hideModal = () => dispatch(FormActions.modal.hideModal());
	const setProducts = (id) => {
		const matched = values.products.find((i) => i === id);
		if (matched) {
			const filtered = values.products.filter((i) => i !== id)
			setValue('products', filtered)
		} else {
			const preparedProducts = [...values.products, id]
			setValue('products', preparedProducts)
		}
	}

	const isDisabledProduct = (item) => item.inCategory;

	const search = {
		updateSearchText: setSearchText,
		searchText,
		callSearch: getProducts,
	}

	const setCategoryValue = (field, value) => {
		setValue(field, value)
		getProducts(searchText, value);
	}

	useEffect(() => {
		updateValues({
			category_name: categoryName,
			category_id: id,
		})
	}, [])

	useEffect(() => {
		getProducts();
	}, [])

	return (
		<Modal
			dialogClassName="default-modal"
			show={modalVisible}
			onHide={hideModal}
		>
			<Modal.Header className="with-border">
				<Modal.Title>
					{getLangText('assignProductsLabel')}
				</Modal.Title>
				<button
					onClick={hideModal}
					type="button"
					className="button"
				>
					<i className="icon-cross"/>
				</button>
			</Modal.Header>
			<Modal.Body className="pt-3 pb-5 assign-products-body">
				<AsyncSelectField
					{...getFieldProps('category_id')}
					label={getLangText('categoryLabel')}
					className="default-size"
					setValue={setCategoryValue}
					rest={services.API_CATEGORIES_WITH_SUBCATEGORIES}
					defaultValue={{id: values.category_id, name: values.category_name}}
				/>
				<div className="form-label d-flex align-items-center">
					{getLangText('selectProductsToAssignLabel')}
					<div className="ml-auto info-text">{getLangText('selectedLabel')}: {values.products.length}</div>
				</div>
				<SearchInput
					className="mb-2"
					placeholder={getLangText('searchPlaceholder')}
					search={search}
				/>
				<div className="loader-parent">
					<Loader isLoading={isLoading}/>
					<div className="data-boxes-wrapper small-padd products-boxes">
						{productsOptions.map((item) => (
							<div
								key={item.id}
								className={`content payment-content ${isDisabledProduct(item) ? 'disabled tooltip-parent' : ''} ${values.products.includes(item.id) ? 'active' : ''}`}
							>
								{isDisabledProduct(item) &&
									<div className="tooltip-content">
										{getLangText('productBelongToTheSelectedCategoryLabel')}
									</div>
								}
								<div className="content__top-part">
									<input
										checked={values.products.includes(item.id)}
										onChange={() => setProducts(item.id)}
										type="checkbox"
										disabled={isDisabledProduct(item)}
									/>
									<span className="checkmark"/>
									<div className="flex-grow-1 d-flex align-items-center">
										<figure className="product-avatar">
											{item.photo_small && item.photo_small !== '0' ?
												<ServerImage src={item.photo_small}/> :
												<img src="/assets/images/default/product.svg"/>
											}
										</figure>
										<div>
											<div className="label">
												{item.name}
											</div>
											<div className="data">
												{getPrice(item.price)}{' • '} {getLangText('codeLabel')} <strong>{item.selmo_code}</strong>
											</div>
										</div>
									</div>
									{getStatusBox(item.squ, getLangText)}
								</div>
							</div>
						))}
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<button
					onClick={hideModal}
					type="button"
					className="button border-button"
				>
					{getLangText('cancelButton')}
				</button>
				<button
					onClick={onSave}
					type="button"
					className={`button primary px-4 ${isWaiting ? 'loading-button' : ''}`}
				>
					{isWaiting && <Loader isLoading/>}
					{getLangText('assignProductsLabel')}
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default AssignProductsModal;
