import {combineReducers} from "redux";
import formReducer from "./form/formReducer";
import editProductFormReducer from "./editProducts/formReducer";
import deliveryAddressFormReducer from "./deliveryAddress/formReducer";
import deliveryTypeFormReducer from "./deliveryType/formReducer";
import paymentTypeFormReducer from "./paymentType/formReducer";
import currencyTypeFormReducer from "./currencyType/formReducer";
import noteFormReducer from "./note/formReducer";
import inpostLabelFormReducer from "./inpostLabel/formReducer";
import furgonetkaLabelFormReducer from "./furgonetkaLabel/formReducer";
import inpostShippingFormReducer from "./deliveryType/inpostShipping/formReducer";
import packagingTypeFormReducer from "./packagingType/formReducer";
import assignCustomerFormReducer from "./assignCustomer/formReducer";
import shippyproLabelFormReducer from "./shippyproLabel/formReducer";

export default combineReducers({
	form: formReducer,
	editProducts: editProductFormReducer,
	deliveryAddress: deliveryAddressFormReducer,
	deliveryType: deliveryTypeFormReducer,
	paymentType: paymentTypeFormReducer,
	currencyType: currencyTypeFormReducer,
	note: noteFormReducer,
	inpostLabel: inpostLabelFormReducer,
	furgonetkaLabel: furgonetkaLabelFormReducer,
	inpostShipping: inpostShippingFormReducer,
	packagingType: packagingTypeFormReducer,
	assignCustomer: assignCustomerFormReducer,
	shippyproLabel: shippyproLabelFormReducer,
});
