import {RouterPaths} from "../../../routes/RouterPath";

export const SHOP_SETTINGS_REGULATION_VIEW = 'SHOP_SETTINGS_REGULATION_VIEW';
export const SHOP_SETTINGS_PRIVACY_VIEW = 'SHOP_SETTINGS_PRIVACY_VIEW';

const ShopSettingsRegulationViews = [
	{
		id: SHOP_SETTINGS_REGULATION_VIEW,
		label: 'termsHelmetTitle',
		path: RouterPaths.ShopRegulation,
	},
	{
		id: SHOP_SETTINGS_PRIVACY_VIEW,
		label: 'policyHelmetTitle',
		path: RouterPaths.ShopPrivacy,
	},
];

export default ShopSettingsRegulationViews;
