import useLang from "../../../../src_shared/hooks/useLang";
import {useParams} from "react-router-dom";
import useFormLocal from "../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../RestServices";
import Modal from "react-bootstrap/Modal";
import TextArea from "../../../../src_shared/components/form/textarea/TextArea";
import Loader from "../../messenger/loader/Loader";
import React, {useEffect} from "react";
import Model from "../../../../src_shared/modules/model/Model";
import ServerImage from "../components/serverImage/ServerImage";

const SendInvitationToMobileAppModal = ({modalVisible, hideModal, loadData, activeConversation}) => {
    const {getLangText} = useLang();

    const [{onSave, isWaiting, getFieldProps, isLoading}] = useFormLocal({
        rest: `${services.API_MOBILE_INVITATION}/${activeConversation.facebook_id}`,
        model: new Model(),
        afterSubmit: () => {
            loadData();
            hideModal();
        }
    });

    return (
        <Modal
            show={modalVisible}
            dialogClassName="default-modal small-modal w-420"
            onHide={hideModal}
        >
            <Modal.Header>
                <Modal.Title>
                    {getLangText('sendInvitation')}
                    <div className="sub-title">{getLangText('increaseSelmoAppClientsDataBase')}</div>
                </Modal.Title>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            {isLoading ?
                <Loader className="static-loader my-32"/> :
                <>
                    <Modal.Body>
                        <div className="rounded-[5px] bg-[#F9FAFB] p-2 flex items-center mb-4">
                            <div className="w-[42px] h-[42px] min-w-[42px] mr-2 rounded-full relative">
                                {activeConversation?.user_avatar && activeConversation?.user_avatar !== '0' ?
                                    <ServerImage
                                        className="w-full h-full object-cover rounded-full"
                                        src={activeConversation?.user_avatar}/> :
                                    <img
                                        className="w-full h-full object-cover rounded-full"
                                        src="/assets/images/default/fb-default.jpeg"
                                    />
                                }
                            </div>
                            <div>
                                <div className="font-bold text-sm">{activeConversation.user_name}</div>
                                <div className="flex items-center text-xs text-desc">
                                    {getLangText('clientNumberFullLabel')}:
                                    <div className="font-bold ml-1">{activeConversation.shop_client_id}</div>
                                </div>

                            </div>
                        </div>
                        <TextArea
                            {...getFieldProps('message')}
                            label={getLangText('notificationMessageLabel')}
                            rows={6}
                        />
                        <div
                            className="form-info-box align-items-center w-100 small-line-height mb-3">
                            <div>
                                <i className="icon-info-c"/>
                            </div>
                            <div className="title">
                                <div className="font-medium"
                                     dangerouslySetInnerHTML={{__html: getLangText('linkToAppWillSentAutomatically')}}/>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            onClick={hideModal}
                            type="button"
                            className="button border-button"
                        >
                            {getLangText('cancelButton')}
                        </button>
                        <button
                            type="button"
                            className={`button primary small-size ${isWaiting ? 'loading-button' : ''}`}
                            onClick={onSave}
                        >
                            {isWaiting && <Loader/>}
                            {getLangText('sendButton')}
                        </button>
                    </Modal.Footer>
                </>
            }
        </Modal>
    );
};

export default SendInvitationToMobileAppModal;
