import {combineReducers} from "redux";
import listReducer from "./list/listReducer";
import formReducer from "./form/formReducer";
import modalFormReducer from "./modalForm/formReducer";

export default combineReducers({
	list: listReducer,
	form: formReducer,
	modalForm: modalFormReducer
});
