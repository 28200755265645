import {prefix} from "./formReducer";
import {getStateRoot} from "./formReducer";
import FormActions from "../../../../src_shared/form/FormActions";
import {services} from "../../../RestServices";
import OverlayModel from "../../../../modules/models/overlay/OverlayModel";

export class SalesPostsStatsFormActions extends FormActions {
	updateData = (pusherData) => {
		return (dispatch, getState) => {
			const {data} = getStateRoot(getState());
			if (+data.id === +pusherData.id) {
				dispatch(this.loadData(pusherData.id))
			}
		}
	}
}

export const getInstance = () => new SalesPostsStatsFormActions({
	prefix,
	getStateRoot,
	model: OverlayModel,
	restService: services.API_VIDEO_SOCIAL_SALES_STATS,
});

export default getInstance();

