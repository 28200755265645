import {useEffect} from 'react';

export default ({
					loadData,
					preventAutoLoad,
					id,
					name,
					requireParentId,
					dataPreloaded,
				}) => {
	useEffect(() => {
		if (preventAutoLoad) {
			return;
		}

		if (dataPreloaded) {
			return;
		}
		if (!!name) {
			loadData(name);
			return;
		}

		if (!!+id) {
			loadData(id);
			return;
		}

		if (requireParentId) {
			return;
		}

		loadData();
	}, [dataPreloaded, loadData, id, name, requireParentId, preventAutoLoad]);
};
