import ListReducer from "../../../../src_shared/list/ListReducer.js";
import createReducer from "../../shared/helpers/createReducer.js";
import Composition from "../../shared/helpers/Composition.js";

export const getStateRoot = (state) => state.lives.list;
export const prefix = 'LIVES_LIST_';

const getInitState = () => ListReducer.getInitState();

const getReduceMap = () =>  new Composition(
	ListReducer.getReduceMap(),
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();


