import React from 'react';
import Modal from "react-bootstrap/Modal";
import useLang from "../../../../../src_shared/hooks/useLang";
import useFormLocal from "../../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../../RestServices";
import Loader from "../../../../app/shared/Loader";
import AddMemberModel from "../../../../../modules/models/team/AddMemberModel";
import {createNewId} from "../../../../app/live/details/default/liveDetailsReducer";
import MemberRow from "./components/MemberRow";
import {ROLE_MEMBERS_EMPLOYEE} from "../../../../app/shared/enums/RoleMembers";
import {useDispatch} from "react-redux";
import {showAlert} from "../../../../app/shared/components/alert/AlertActions";

const AddMembersForm = ({modalVisible, hideModal}) => {
    const {getLangText} = useLang();
    const dispatch = useDispatch();

    const afterSubmit = () => {
        hideModal();
        dispatch(showAlert('yuhuLabel', 'successfullySentInvitation'))
    };

    const [{
        values,
        onSave,
        isWaiting,
        updateValues,
        setNestedValue,
        getFieldProps
    }] = useFormLocal({
        rest: services.API_PERMISSIONS_EMPLOYEES,
        model: AddMemberModel,
        afterSubmit,
        initialAutoLoad: false,
    });

    const addNewVariant = () => updateValues({
        row: [...values.row, {id: createNewId(), email: '', role: ROLE_MEMBERS_EMPLOYEE}]
    });

    return (
        <Modal
            dialogClassName="default-modal"
            show={modalVisible}
            onHide={hideModal}
        >
            <Modal.Header className="align-items-flex-start with-border">
                <Modal.Title>
                    {getLangText('addMembersToTeam')}
                    <div className="sub-title">
                        {getLangText('inviteToCooperation')}
                    </div>
                </Modal.Title>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            <form>
                <Modal.Body>
                    <div className="space-y-2">
                        {values.row.map((item) => (
                            <MemberRow
                                item={item}
                                key={item.id}
                                setNestedValue={setNestedValue}
                                getFieldProps={getFieldProps}
                            />
                        ))}
                    </div>
                    <button
                        className="button add-new-row text-blue-button"
                        onClick={addNewVariant}
                        type="button"
                    >
                        <i className="icon-plus"/>
                        {getLangText('addAnotherPerson')}
                    </button>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        onClick={hideModal}
                        type="button"
                        className="button border-button"
                    >
                        {getLangText('cancelButton')}
                    </button>
                    <button
                        type="submit"
                        className={`button primary ${isWaiting ? 'loading-button' : ''}`}
                        onClick={onSave}
                    >
                        {isWaiting && <Loader isLoading/>}
                        {getLangText('invite')}
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default AddMembersForm;