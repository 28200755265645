import ListActions, {getGETParamsUrl} from "../../../../../src_shared/list/ListActions";
import {getStateRoot as getFiltersStateRoot} from "../filters/filtersReducer";
import {convertDate} from "../../../shared/helpers/dateHelpers";
import axios from "axios";
import {selmoUrl} from "../../../../../src_shared/api/api";
import SessionActions from "../../../shared/session/SessionActions";
import LangsActions from "../../../shared/langs/LangsActions";
import api from "../../../../../services/axios/axios";

export class SharedEditProductListActions extends ListActions {
	getLoadParams(state) {
		const params = super.getLoadParams(state);
		const statuses = getFiltersStateRoot(state).data.statuses;
		const source = getFiltersStateRoot(state).data.source;
		const variants = getFiltersStateRoot(state).data.variants;
		const filtersSelectAll = getFiltersStateRoot(state).data.filtersSelectAll;
		const variantsSelectAll = getFiltersStateRoot(state).data.variantsSelectAll;
		const dateFrom = getFiltersStateRoot(state).data.fromDate
		const dateTo = getFiltersStateRoot(state).data.toDate
		const deleted = getFiltersStateRoot(state).data.deleted

		if (dateFrom) {
			params.dateFrom = convertDate(dateFrom);
		}

		if (dateTo) {
			params.dateTo = convertDate(dateTo);
		}

		if (statuses.length && !filtersSelectAll) {
			params['status[]'] = statuses
		}

		if (source.length) {
			params['source[]'] = source
		}


		if (variants.length && !variantsSelectAll) {
			params['variants[]'] = variants
		}

		if (!!+deleted) {
			params.deleted = deleted;
		}

		return params;
	}

	setTotalPrice(totalPrice) {
		return {
			type: `${this.prefix}SET_TOTAL_PRICE`,
			totalPrice,
		}
	}

	setQuantity(quantity) {
		return {
			type: `${this.prefix}SET_QUANTITY`,
			quantity,
		}
	}

	setWaitingForSend(toggle) {
		return {
			type: `${this.prefix}SET_WAITING_FOR_SEND`,
			toggle,
		}
	}

	loadData(id) {
		return async (dispatch, getState) => {
			const {firstInit} = getState().session;
			dispatch(this.waiting.startWaiting())
			if (firstInit) {
				dispatch(this.onFirstInit());
			}
			const params = this.getLoadParams(getState(), id)

			const restUrl = +id ?
				`/${id}${getGETParamsUrl(params)}` :
				getGETParamsUrl(params);
			try {
				const response = await api.get(`${selmoUrl}/${this.restService}${restUrl}`)
				const {items, total, total_price, waitingForSend, total_quantity} = response.data;
				dispatch(this.table.updateAllItems(items))
				dispatch(this.setTotalPrice(total_price))
				dispatch(this.setQuantity(total_quantity))
				dispatch(this.setWaitingForSend(waitingForSend))
				dispatch(this.pagination.updateTotalItems(total))
			} catch (e) {
				dispatch(this.table.getListError(e))
				console.error(e)
			} finally {
				if (firstInit) {
					dispatch(SessionActions.setFirstInit());
				}
				dispatch(this.waiting.stopWaiting())
			}
		}
	}
}
