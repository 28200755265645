import {combineReducers} from "redux";
import dashboardListReducer from "./messagesDashboard/list/listReducer";
import tabsReducer from "./messagesDashboard/tabs/tabsReducer";
import filtersReducer from "./messagesDashboard/filters/filtersReducer";

export default combineReducers({
	dashboard: dashboardListReducer,
	filters: filtersReducer,
	tabs: tabsReducer,
});
