import Model from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

class SettingsPaypalModel extends Model {
	getModel() {
		return {
			paypal_price: 0,
			paypal_price_type: '2',
			paypal_email: '',
			paypal_active: '0',
		};
	}

	getValidators() {
		return {
			paypal_email: ValidationError.combine(
				ValidationError.notEmpty,
				ValidationError.validateEmail,
			),
			paypal_price: ValidationError.greaterThan(-1),
			paypal_active: ValidationError.skip,
			paypal_price_type: ValidationError.notEmpty,
		};
	}

	buildDTO(data) {
		const {paypal_email, paypal_price, paypal_active, paypal_price_type} = data;
		return {
			paypal_email,
			paypal_price,
			paypal_price_type,
			paypal_active,
		}
	}
}

export default new SettingsPaypalModel();
