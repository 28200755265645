import React from 'react';
import Chart from "react-apexcharts";
import {formattedMonth, formattedNumberMonth, formattedYear, labelStyle} from "./IncomesChart";
import EmptyList from "../../../../../shared/EmptyList";
import useLang from "../../../../../../../src_shared/hooks/useLang";

const OrdersChart = ({items}) => {
	const {getLangText} = useLang();

	const options = {
		chart: {
			id: "basic-bar",
			toolbar: {
				show: false,
			}
		},
		xaxis: {
			categories: items.ordersInfo.map((i) => i.day ? `${i.day}.${formattedNumberMonth(i.month)}` : `${formattedMonth(i.month)} ${formattedYear(i.year)}`),
			labels: {
				...labelStyle,
				hideOverlappingLabels: true,
			},
			tooltip: {
				enabled: false
			},
			axisTicks: {
				show: false,
			},
		},
		yaxis: {
			labels: {
				style: {
					fontSize: '12px',
					colors: '#9CA3AF',
					fontFamily: 'Plus Jakarta Sans',
				},
			}
		},
		grid: {
			borderColor: '#D1D5DB',
			strokeDashArray: 5,
		},
		plotOptions: {
			bar: {
				horizontal: false,
				borderRadius: 5,
				dataLabels: {
					orientation: 'vertical',
					position: 'center',
					enable: false,
					show: false,
				},
				columnWidth: 45,
			},
		},
		dataLabels: {
			enabled: false
		},
		colors: ['#1C4ED8', '#5FA5FA', '#90E8EE', '#3ee1f6'],
		fill: {
			type: 'gradient',
			gradient: {
				shade: 'dark',
				type: "vertical",
				gradientToColors: undefined,
				inverseColors: true,
				opacityFrom: 1,
				opacityTo: 1,
				colorStops: [
					{
						offset: 0,
						color: "#BEDDFF",
						opacity: 1
					},
					{
						offset: 60,
						color: "#4C63FF",
						opacity: 1
					},
				]
			}
		},
		legend: {
			show: false
		},
		tooltip: {
			custom: function ({series, seriesIndex, dataPointIndex, w}) {
				return `<div class="chart-tooltip">
<div class="date-label">
						${w.config.series[0].data[dataPointIndex].x}
						</div>
<div class="label">${getLangText('ordersLabel')}</div>
						<div class="value">${series[0][dataPointIndex]}</div>

</div>`
			}
		}
	};

	const series = [
		{
			name: getLangText('incomesLabel'),
			data: items.ordersInfo.map((i) => ({
				y: Math.round(+i.count),
				x: i.day ? `${i.day}.${formattedNumberMonth(i.month)}` : `${formattedMonth(i.month)} ${formattedYear(i.year)}`,
			})),
		},
	]


	return (
		<div className="border border-[#E5E7EB] rounded-[5px] px-[20px] py-6 pb-2">
			<div className="title title-with-legend">
				Ilość zamówień
			</div>
			<EmptyList
				className="full-size"
				imgSrc="/assets/images/empty/chart-2.svg"
				items={items.ordersInfo}
				title=""
			>
				<div className="chart-wrapper">
					<Chart
						series={series}
						options={options}
						type="bar"
						width="100%"
						height={265}
					/>
				</div>
			</EmptyList>
		</div>
	);
};

export default OrdersChart;
