import {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import SessionActions from "../session/SessionActions";

const useDefaultSelmoData = () => {

	const dispatch = useDispatch();
	const history = useHistory();
	const {userData, waitingForResponse} = useSelector((state) => state.session);

	useEffect(() => {
		dispatch(SessionActions.checkSession(history));
	}, []);

	return {userData, waitingForResponse};
};

export default useDefaultSelmoData;
