import useLang from "../../../../../src_shared/hooks/useLang";
import EmptyList from "../../../shared/EmptyList";
import moment from "moment/moment";
import React from "react";
import {RouterPaths} from "../../../../routes/RouterPath";
import {convertToUserTimeZone} from "../../../shared/helpers/dateHelpers";

const Table = ({items, isWaiting, updateSortOptions, getSortClass}) => {

    const {getLangText} = useLang();

    return (
        <EmptyList
            items={items}
            isLoading={isWaiting}
            className="with-padd"
            // imgSrc="/assets/images/empty/clients-empty.svg"
            title={getLangText('emptyHereLabel')}
            text={getLangText('hereWillTransactionsHistory')}
        >
            <div className="table-wrapper">
                <div className="table-responsive overflow-visible">
                    <table className="table live-table smaller-padd">
                        <thead>
                        <tr>
                            <th
                                name="description"
                                onClick={updateSortOptions}
                                className={getSortClass('description')}
                            >
							<span name="description">
								{getLangText('input_label_package_type_description')}
							</span>
                            </th>
                            <th
                                name="amount"
                                onClick={updateSortOptions}
                                className={`text-sm-right ${getSortClass('amount')}`}
                            >
							<span name="amount">
								Kwota
							</span>
                            </th>
                            <th
                                name="amount_after"
                                onClick={updateSortOptions}
                                className={`text-sm-right ${getSortClass('amount_after')}`}
                            >
							<span name="amount_after">
								Saldo po
							</span>
                            </th>
                            <th
                                name="date"
                                onClick={updateSortOptions}
                                className={`text-sm-right ${getSortClass('date')}`}
                            >
							<span name="date">
								{getLangText('dateLabel')}
							</span>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {items.map((item) => (
                            <tr
                                className="not-clicked-tr"
                                key={item.id}
                            >
                                <td
                                    data-thead={`${getLangText('input_label_package_type_description')}:`}
                                    className="with-mobile-thead inline-thead sm:w-auto w-full"
                                >
                                    <div>
                                        <span className="text-sm" dangerouslySetInnerHTML={{__html: item.description}}/>
                                        {item.shop_order_id &&
                                            <a
                                                target="_blank"
                                                href={`/zamowienia/${item.shop_order_id}`}
                                                className="text-sm text-desc underline hover:text-[#000] transition-all"
                                            >&nbsp;(zamówienie {item.shop_order_id})</a>
                                        }
                                    </div>
                                </td>
                                <td
                                    data-thead={`Kwota:`}
                                    className="with-mobile-thead inline-thead text-right"
                                >
                                    <div>
                                        {item.type === 'WITHDRAW' ?
                                            <div className="text-[#EF4444]">
                                                -{Math.abs(+item.amount_after - +item.amount).toFixed(2)} zł
                                            </div> :
                                            <div className="text-[#0EB981]">
                                                +{Math.abs(+item.amount_after - +item.amount).toFixed(2)} zł
                                            </div>
                                        }
                                    </div>
                                </td>
                                <td
                                    data-thead={`Saldo po:`}
                                    className="with-mobile-thead inline-thead text-right"
                                >
                                    <div>{item.amount_after} zł</div>
                                </td>
                                <td
                                    data-thead={`${getLangText('dateLabel')}:`}
                                    className="text-sm-right date w-lg-auto w-100 order-lg-0 order-2 with-mobile-thead inline-thead mb-lg-0 text-nowrap"
                                >
                                    {convertToUserTimeZone(item.date).format('DD.MM.YYYY HH:mm')}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </EmptyList>
    );
};

export default Table;
