import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import {RouterPaths} from "./RouterPath";
import TransactionsHistoryList from "../app/selmoPaczkaPage/transactions/List";
import Configuration from "../app/selmoPaczkaPage/configuration/Configuration";
import Landing from "../app/selmoPaczkaPage/landing/Landing";

const SelmoPaczkaRoute = ({path}) => {

    return (
        <Route path={path}>
            <Switch>
                <Route
                    exact
                    path={RouterPaths.SelmoPaczkaHistory}
                    component={TransactionsHistoryList}
                />
                <Route
                    exact
                    path={RouterPaths.SelmoPaczkaConfiguration}
                    component={Configuration}
                />
                <Route
                    exact
                    path={path}
                    component={Landing}
                />
                <Redirect to={RouterPaths.SelmoPaczka}/>
            </Switch>
        </Route>
    );
};

export default SelmoPaczkaRoute;
