import Model from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

class SalesPostTemplateModel extends Model {
    getModel() {
        return {
            name: '',
            text: '',
        };
    }

    getValidators() {
        return {
            name:  ValidationError.notEmpty,
            text:  ValidationError.notEmpty,
        };
    }

    buildDTO(data) {
        return {
            name: data.name,
            text: data.text,
        };
    }
}

export default new SalesPostTemplateModel();
