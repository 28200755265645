import moment from "moment";

export const convertTimestampToDate = (isoStringOrTimestamp, dateFormat = 'DD/MM/YYYY') => {
	if (!isoStringOrTimestamp) {
		return '';
	}

	return moment(isoStringOrTimestamp).format(dateFormat);
}

export const isTodayDay = (timestamp) => (!!moment(timestamp).isSame(moment(), 'day'));

export const convertDate = (date) => moment(date).format('YYYY-MM-DD')
