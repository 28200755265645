import {prefix, getStateRoot} from "./assignModalReducer.js";
import axios from "axios";
import {showAlert} from "../../../../../shared/components/alert/AlertActions.js";
import {resetAndUpdateClients} from "../../LiveDetailsActions.js";
import {batch} from "react-redux";
import FormActions from "../../../../../../../src_shared/form/FormActions.js";
import {selmoUrl} from "../../../../../../../src_shared/api/api.js";
import api from "../../../../../../../services/axios/axios";

export class AssignModalActions extends FormActions {
	validateData(data) {
		const isEmpty = Object.values(data).some(x => !x);
		return !!isEmpty;
	}

	submit() {
		return async (dispatch, getState) => {
			const {productId, client_id, productName, name, price, quantity, shop_client_id} = getStateRoot(getState()).data;
			const liveId = getState().lives.details.liveInfo.id;

			const requiredFields = {
				productName,
				name,
				price,
				quantity,
				shop_client_id,
			}

			if (this.validateData(requiredFields)) {
				dispatch(this.data.setValue('activeValidation', true))
				dispatch(showAlert('sthWentWrongWarning', 'fillRequireFieldWarning', 'alert-fail'))
				return
			}

			try {
				await api.put(`${selmoUrl}/apilive/addProductToAnotherClient`, {
					live_id: liveId,
					product_id: productId,
					client_id: client_id,
					product_name: productName,
					client_name: name,
					price,
					quantity,
				})
				batch(() => {
					dispatch(showAlert('yuhuLabel', 'liveAssignProductSuccessLabel'))
					dispatch(resetAndUpdateClients(liveId))
					dispatch(this.data.restoreEmptyData())
					dispatch(this.modal.hideModal())
				})
			} catch (e) {
				dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
				console.warn('Post addProductToAnotherClient error')
			}
		}
	}
}

export const getInstance = () => new AssignModalActions({
	prefix,
	getStateRoot,
});

export default getInstance();

