import React from 'react';
import Modal from "react-bootstrap/Modal";
import Loader from "../../../../messenger/loader/Loader";
import useLang from "../../../../../../src_shared/hooks/useLang";

const CreateOrder = ({hideModal, createOrder, isWaiting}) => {
    const {getLangText} = useLang();

    return (
        <>
            <Modal.Header className="pb-4">
                <Modal.Title>
                    {getLangText('createOrderButton')}
                    <div className="description">
                        {getLangText('askForCreateOrderLabel')}
                    </div>
                </Modal.Title>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            <Modal.Footer>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button border-button"
                >
                    {getLangText('cancelButton')}
                </button>
                <button
                    onClick={createOrder}
                    type="button"
                    className={`button primary px-0 ${isWaiting ? 'loading-button' : ''}`}
                >
                    {isWaiting && <Loader/>}
                    {getLangText('createButton')}
                </button>
            </Modal.Footer>
        </>
    );
};

export default CreateOrder;
