import {getStateRoot, prefix} from "./formReducer.js";
import {services} from "../../../../RestServices.js";
import FormActions from "../../../../../src_shared/form/FormActions";
import PaymentTypesModel from "../../../../../modules/models/order/PaymentTypesModel";
import {getStateRoot as getEditProductStateRoot} from "../form/formReducer";
import {batch} from "react-redux";
import EditProductFormActions from "../form/FormActions";
import axios from "axios";
import {selmoUrl} from "../../../../../src_shared/api/api";
import {ERROR_NOT_FOUND} from "../../../../../src_shared/enums/ErrorEnums";
import api from "../../../../../services/axios/axios";

export class OrderDetailsPaymentTypeFormActions extends FormActions {
	loadData(id) {
		return async (dispatch) => {
			dispatch(this.waiting.startWaiting());
			dispatch(this.onFirstInit());

			try {
				const {data} = await api.get(`${selmoUrl}/${this.restService}/${id}`)
				const parsedItem = dispatch(this.parseData(data.item));
				dispatch(this.data.updateValues(parsedItem))
			} catch (e) {
				if (e.response.data.message === ERROR_NOT_FOUND) {
					dispatch(this.data.setValue('allEmptyPayments', true))
				}
				dispatch(this.onErrorLoad(e))
				console.error('Error loadData')
			} finally {
				dispatch(this.waiting.stopWaiting());
			}
		}
	}

	afterSubmit() {
		return (dispatch, state) => {
			const {data} = getEditProductStateRoot(state());
			batch(() => {
				dispatch(this.modal.hideModal());
				dispatch(EditProductFormActions.loadData(data.shop_order_id))
			})
		}
	}
}

export const getInstance = () => new OrderDetailsPaymentTypeFormActions({
	getStateRoot,
	prefix,
	model: PaymentTypesModel,
	restService: services.API_ORDER_PAYMENT_TYPE,
});

export default getInstance();
