import createReducer from "../../../../shared/helpers/createReducer";
import history from "../../../../../history";
import {parseParams} from "../../../../../../../../src_shared/list/ListActions";
import DataReducer from "../../../../../../../../src_shared/form/data/DataReducer";
import {getReduceMap} from "../../../../../../../../src_shared/filters/filterReducer";
import moment from "moment/moment";
import {ALL_ORDERS_VIEW, INTERVAL} from "../../../../../../../app/shared/enums/OrderViewTypes";

const queryParams = history.location.search;
const parsedParams = parseParams(queryParams)

const pipe = +parsedParams.pipe || 'all'
const status = +parsedParams.status || 'all'
const preparedViewType = !!queryParams ? (parsedParams.dateFrom ? INTERVAL : ALL_ORDERS_VIEW) : INTERVAL;
const preparedCountry = parsedParams['country[]'] || [];

const preparedDate = (initialState, paramsDate) => {
	if (!!queryParams) {
		if (parsedParams[paramsDate]) {
			return moment(parsedParams[paramsDate]).toDate()
		} else {
			return null;
		}
	} else {
		return initialState;
	}
}

export const getStateRoot = (state) => state.admin.sales.messages.filters;
export const prefix = 'ADMIN_SALES_MESSAGES_FILTERS_';

export const getInitState = () => DataReducer.getInitState({
	pipe,
	status,
	fromDate: preparedDate(moment(new Date()).subtract(1, 'M').toDate(), 'dateFrom'),
	toDate: preparedDate(new Date(), 'dateTo'),
	viewType: preparedViewType,
	country: preparedCountry,
	countrySelectAll: false,
}, {
	pipe: 'all',
	status: 'all',
	fromDate: moment(new Date()).subtract(1, 'M').toDate(),
	toDate: moment().toDate(),
	viewType: INTERVAL,
	country: [],
	countrySelectAll: false,
})


export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();
