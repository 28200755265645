import React from 'react';
import {OverlayTrigger} from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import useLang from "../../../hooks/useLang";

const PurchaseTypeIcon = ({isFromApp, className = ''}) => {
    const {getLangText} = useLang();

    return (
        <div className={`absolute -right-1.5 -bottom-1.5 border-2 border-[#fff] rounded-full ${className}`}>
            {isFromApp ?
                    <OverlayTrigger
                        placement='top'
                        overlay={
                            <Tooltip id="purchaseFromSelmoApp">
                                {getLangText('purchaseFromSelmoApp')}
                            </Tooltip>
                        }
                    >
                        <img
                            className={`w-[14px] h-[14px] min-w-[14px]`}
                            src="/assets/images/svg/selmo-circle-blue.svg"
                            alt="selmo"
                        />
                    </OverlayTrigger>
                    :
                    <OverlayTrigger
                        placement='top'
                        overlay={
                            <Tooltip id="purchaseFromFacebook">
                                {getLangText('purchaseFromFacebook')}
                            </Tooltip>
                        }
                    >
                        <img
                            className={`w-[14px] h-[14px] min-w-[14px]`}
                            src="/assets/images/svg/facebook-logo-c.svg"
                            alt="selmo"
                        />
                    </OverlayTrigger>
            }
        </div>
    )
};

export default PurchaseTypeIcon;
