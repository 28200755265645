import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../routes/RouterPath";
import moment from "moment";
import useLang from "../../../../../src_shared/hooks/useLang";
import ServerImage from "../../../shared/components/serverImage/ServerImage";
import useSubmitData from "../../../../../src_shared/hooks/useSubmitData";
import {services} from "../../../../RestServices";
import {showAlert} from "../../../shared/components/alert/AlertActions";
import useRemoveData from "../../../../../src_shared/hooks/useRemoveData";
import ConfirmRemoveModal from "../../../../../src_shared/components/modal/ConfirmRemoveModal";

const Products = ({data, fetchData}) => {
    const {getLangText} = useLang();
    const [selectedProduct, setSelectedProduct] = useState({});

    const [{
        openModal,
        hideModal,
        confirmModalVisible,
        itemToRemove,
        isDeleting,
        removeItem
    }] = useRemoveData({
        rest: services.API_WAREHOUSE_TAKE_OFF_PRODUCT,
        afterSubmit: fetchData,
        method: 'post',
        params: {
            product_size_id: selectedProduct.size_id
        }
    });

    const takeOffHandle = (e, item) => {
        e.preventDefault();
        setSelectedProduct(item)
        openModal(item)
    }

    if (!data.products?.length) {
        return null;
    }

    return (
        <div className="default-shadow-box bigger-x-padd">
            <div className="filters-part">
                <div className="title flex-grow-1">
                    {getLangText('asideProductsLabel')}
                    {/*{totalItems > 0 &&*/}
                    {/*    <div className="badge">{totalItems}</div>*/}
                    {/*}*/}
                </div>
            </div>
            <div className="boxes-wrapper">
                {data.products.map((item) => (
                    <Link
                        onClick={(e) => e.stopPropagation()}
                        key={item.id}
                        to={item.size_id ? `${RouterPaths.StorageScanner}/product/${item.id}/${item.size_id}?fromShelf=1` : `${RouterPaths.StorageScanner}/product/${item.id}?fromShelf=1`}
                        className="box flex-nowrap"
                    >
                        <figure className="product-avatar">
                            {item.photo_small && item.photo_small !== '0' ?
                                <ServerImage src={item.photo_small}/> :
                                <img src="/assets/images/default/product.svg"/>
                            }
                        </figure>
                        <div className="left-side w-full">
                            <div className="title">{item.name}</div>
                            <div className="text-xs text-desc">
                                {item.size_id &&
                                    <>
                                        {getLangText('variantLabel')}: <strong>{item.size_name}</strong>
                                        <span className="text-[8px] mx-1">{' • '}</span>
                                    </>
                                }
                                {getLangText('codeLabel')}: <strong>{item.selmo_code}</strong>
                            </div>
                        </div>
                        <button
                            onClick={(e) => takeOffHandle(e, item)}
                            type="button"
                            className="button whitespace-nowrap ml-auto rounded-[5px] text-[10px] h-[32px] px-2 font-bold bg-[#FEF2F2] grow text-[#EF4444]"
                        >
                            {getLangText('removeFromShelfLabel')}
                        </button>
                    </Link>
                ))}
            </div>
            {confirmModalVisible &&
                <ConfirmRemoveModal
                    show={confirmModalVisible}
                    subTitle={itemToRemove.name}
                    dialogClassName="small-modal"
                    title={getLangText('removeProductFromShelfLabel')}
                    hide={hideModal}
                    confirm={removeItem}
                    isWaiting={isDeleting}
                    confirmButton={getLangText('yesButton')}
                    confirmButtonClassName="primary"
                />
            }
        </div>
    );
};

export default Products;
