import React from 'react';
import {services} from "../../../RestServices";
import useLang from "../../../../src_shared/hooks/useLang";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import useDataApi from "../../../../src_shared/hooks/useDataApi";
import Loader from "../../../app/shared/Loader";
import TransferForm from "./transferForm/TransferForm";
import ComponentLoader from "../../../app/shared/components/componentLoader/ComponentLoader";
import {Helmet} from "react-helmet";

const initialData = {
	accounts: [],
	currencies: [],
}

const TransferPage = () => {
	const {getLangText} = useLang();
	const [{data, isLoading, fetchData}] = useDataApi(services.API_SETTINGS_TRANSFER, initialData)

	if (isLoading) return <ComponentLoader/>;

	return (
		<>
			<Helmet>
				<meta charSet="utf-8"/>
				<title>{getLangText('settings_payments_transfer_title')} - Selmo</title>
			</Helmet>
			<div className="header-bar breadcrumbs-in-react-app">
				<ul className="breadcrumbs">
					<li>
						<Link to={RouterPaths.Settings}>
							{getLangText('asideSettingsLabel')}
						</Link>
					</li>
					<li>
						<Link to={RouterPaths.SettingsPayments}>
							{getLangText('paymentsLabel')}
						</Link>
					</li>
					<li className="active">
						<div>{getLangText('settings_payments_transfer_title')}</div>
					</li>
				</ul>
				<Link
					to={RouterPaths.SettingsPayments}
					className="button back-to-panels mb-3">
					<i className="icon-arrow-left"/>
					{getLangText('button_back_to_payment')}
				</Link>
			</div>
			<div className="settings-page sub-settings-page">
				<div className="header-wrapper">
					<h1>
						{getLangText('settings_payments_transfer_title')}
					</h1>
					<div className="description">{getLangText('settings_payments_transfer_subtitle')}</div>
				</div>
				<div className="settings-form">
					<TransferForm
						defaultData={data}
					/>
				</div>
			</div>

			<div className="settings-page sub-settings-page mt-3">
				<div className="header-wrapper">
					<h1>
						{getLangText('settings_payments_transfer_additional_accounts')}
					</h1>
					<div className="description">{getLangText('settings_payments_transfer_subtitle')}</div>
				</div>
				<div className="settings-form">
					<TransferForm
						defaultData={{currencies: data.currencies, defaultCurrency: data.defaultCurrency}}
						extraAccount
						getAdditionalAccounts={fetchData}
					/>
					<section>
						<div className="left-side">
							<h2 className="label">{getLangText('input_label_transfer_added_accounts_1')}
								<span>{getLangText('input_label_transfer_added_accounts_2')}</span>
							</h2>
						</div>
						<div className="form-col loader-parent">
							<Loader isLoading={isLoading}/>
							{data?.accounts.length ?
								<div className="div-table">
									<div className="table-head">
										<div className="table-row">
											<div className="name flex-grow-1">{getLangText('name')}</div>
											<div className="small-col">{getLangText('active')}</div>
											<div className="action-button-col"/>
										</div>
									</div>
									<div className="table-body">
										{data?.accounts?.map((account) => (
											<Link
												key={account.id}
												to={`${RouterPaths.SettingsPaymentsTransfer}/${account.id}/`}
												className="table-row"
											>
												<div className="name flex-grow-1">
													{account.transfer_account_name || getLangText('no_name')}
												</div>
												<div className="small-col icon-status">
													{!!+account.transfer_active ?
														<i className="icon-tick"/>
														:
														<i className="icon-cross"/>
													}
												</div>
												<div className="action-button-col">
													<div className="button border-button no-hover small-size">
														{getLangText('edit')}
														<i className="icon-arrow"/>
													</div>
												</div>
											</Link>
										))}
									</div>
								</div> :
								<div className="form-info-box w-100">
									<div>
										<i className="icon-info-c"/>
									</div>
									<div>
										<div
											className="title">{getLangText('settings_payments_transfer_no_additional_accounts')}</div>
									</div>
								</div>
							}
						</div>
					</section>
				</div>
			</div>
		</>
	);
};

export default TransferPage;
