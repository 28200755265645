import Model from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

export class GeneralModel extends Model {
	getModel() {
		return {
			id: 0,
			photo: null,
			contact_email: '',
			contact_address: '',
			contact_phone: '',
			facebook_link: '',
			instagram_link: '',
			tiktok_link: '',
			twitter_link: '',
			guest_active: true,
			multi_language_active: false,
		};
	}

	getValidators() {
		return {
			photo: ValidationError.skip,
			contact_email: ValidationError.validateEmail,
			contact_address: ValidationError.skip,
			contact_phone: ValidationError.skip,
			facebook_link: ValidationError.skip,
			instagram_link: ValidationError.skip,
			tiktok_link: ValidationError.skip,
			twitter_link: ValidationError.skip,
			guest_active: ValidationError.skip,
			multi_language_active: ValidationError.skip,
		};
	}
}

export default new GeneralModel();
