import {getStateRoot, prefix} from './listReducer';
import {services} from "../../../../RestServices";
import {SharedEditProductListActions} from "../shared/SharedEditProductListActions";

export class RemovedListActions extends SharedEditProductListActions {

}
export const getInstance = () => new RemovedListActions({
	getStateRoot,
	restService: services.API_PRODUCTS_DELETED,
	prefix,
	preventPushParams: true,
});

export default getInstance();
