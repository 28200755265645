import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import {RouterPaths} from "../../RouterPath";
import AutomationsDashboard from "../../../settings/automations/Dashboard";
import Stats from "../../../settings/automations/automaticReserveList/stats/Stats";
import Configuration from "../../../settings/automations/automaticReserveList/configuration/Configuration";
import OrderCancellationConfiguration
    from "../../../settings/automations/ordrerCancellation/configuration/Configuration";
import ClosingOpenPackageConfiguration
    from "../../../settings/automations/closingOpenPackage/configuration/Configuration";
import OrderCancellationOrders from "../../../settings/automations/ordrerCancellation/orders/Orders";
import ClosingOpenPackageOrders from "../../../settings/automations/closingOpenPackage/orders/Orders";
import SelmoProRoute from "../../SelmoProRoute";
import OrderPaymentReminderConfiguration
    from "../../../settings/automations/ordrerPaymentReminder/configuration/Configuration";

const AutomationsRoute = ({path}) => {
    return (
        <SelmoProRoute
            allowField="automatic_reserve_list"
            path={path}
        >
            <Switch>
                <Route path={RouterPaths.SettingsAutomationsReserveList}>
                    <Switch>
                        <Route
                            path={RouterPaths.SettingsAutomationsReserveList}
                            exact
                            component={Stats}
                        />
                        <Route
                            path={RouterPaths.SettingsAutomationsReserveListConfiguration}
                            exact
                            component={Configuration}
                        />
                        <Redirect to={RouterPaths.SettingsAutomationsReserveList}/>
                    </Switch>
                </Route>
                <Route path={RouterPaths.SettingsAutomationsOrderCancellation}>
                    <Switch>
                        <Route
                            path={RouterPaths.SettingsAutomationsOrderCancellationConfiguration}
                            exact
                            component={OrderCancellationConfiguration}
                        />
                        <Route
                            path={RouterPaths.SettingsAutomationsOrderCancellationOrders}
                            exact
                            component={OrderCancellationOrders}
                        />
                        <Redirect to={RouterPaths.SettingsAutomationsOrderCancellationConfiguration}/>
                    </Switch>
                </Route>
                <Route path={RouterPaths.SettingsAutomationsOpenPackageClosing}>
                    <Switch>
                        <Route
                            path={RouterPaths.SettingsAutomationsOpenPackageClosingConfiguration}
                            exact
                            component={ClosingOpenPackageConfiguration}
                        />
                        <Route
                            path={RouterPaths.SettingsAutomationsOpenPackageClosingOrders}
                            exact
                            component={ClosingOpenPackageOrders}
                        />
                        <Redirect to={RouterPaths.SettingsAutomationsOpenPackageClosing}/>
                    </Switch>
                </Route>
                <Route path={RouterPaths.SettingsAutomationsOrderPaymentReminder}>
                    <Switch>
                        <Route
                            path={RouterPaths.SettingsAutomationsOrderPaymentReminderConfiguration}
                            exact
                            component={OrderPaymentReminderConfiguration}
                        />
                        <Redirect to={RouterPaths.SettingsAutomationsOrderPaymentReminder}/>
                    </Switch>
                </Route>
                <Route
                    path={path}
                    exact
                    component={AutomationsDashboard}
                />
                <Redirect to={path}/>
            </Switch>
        </SelmoProRoute>
    );
};

export default AutomationsRoute;
