import React, {useState} from 'react';
import _ from "lodash";
import DiscountCodeModal from "./DiscountCodeModal";
import useLang from "../../../../../src_shared/hooks/useLang";

export const getDiscountCodeValue = (data, getOrderPrice, getLangText) => {
	if (_.isEmpty(data.discount_code_info)) {
		return null;
	}
	if (+data.discount_code_info.free_shipping) {
		return (
			<>
				{getLangText('settings_general_free_shipping')}
				{/*{!isFreeShipping &&*/}
				{/*	<span*/}
				{/*		className="extra-small-font-size grey-txt ml-1">(-{getOrderPrice(data.shippingData.shipping_price)})</span>*/}
				{/*}*/}
			</>
		);
	}
	if (+data.discount_code_info.type === 1) {
		return <span>-{getOrderPrice(data.discount_code_info.value)}</span>
	}
	return (
		<>
			{`-${getOrderPrice(data.discount_code_info.discount_amount)}`}
			<span className="extra-small-font-size grey-txt ml-1">(-{data.discount_code_info.value}%)</span>
		</>
	)
}

const DiscountCode = ({data, getOrderPrice, isFreeShipping, loadData}) => {
	const {getLangText} = useLang();

	const [discountCodeModalVisible, setDiscountCodeModalVisible] = useState(false);

	const hideModal = () => setDiscountCodeModalVisible(false);

	return (
		<div className="order-summary__prices">
			<div className="section-header">
				<h2>{getLangText('discountCodeLabel')}</h2>
				<button
					onClick={() => setDiscountCodeModalVisible(true)}
					className="button border-button big-width w-xs-100"
				>
					<span className="d-lg-block d-none">{getLangText('editDiscountCodeLabel')}</span>
					<span className="d-lg-none d-block">{getLangText('editButton')}</span>
				</button>
				{discountCodeModalVisible &&
					<DiscountCodeModal
						values={data}
						modalVisible={discountCodeModalVisible}
						hideModal={hideModal}
						loadData={loadData}
					/>
				}
			</div>
			{!_.isEmpty(data.discount_code_info) ?
				<div className="section-value">
					<div>
						<strong>{data.discount_code_info.name}</strong>
						<div>{getDiscountCodeValue(data, getOrderPrice, getLangText)}</div>
					</div>
				</div> :
				<div
					className="form-info-box align-items-center w-100 mt-3 small-line-height">
					<div>
						<i className="icon-info-c"/>
					</div>
					<div>
						<div className="title">{getLangText('noSelectedDiscountCodeLabel')}</div>
					</div>
				</div>
			}
		</div>
	);
};

export default DiscountCode;
