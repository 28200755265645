import React, {useEffect} from 'react';
import useList from "../../../../src_shared/list/useList";
import ListActions from "./ListActions";
import {getStateRoot} from "./listReducer";
import {useDispatch, useSelector} from "react-redux";
import Loader from "../../messenger/loader/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import {createSelector} from "reselect";
import {useInView} from "react-hook-inview";
import useLang from "../../../../src_shared/hooks/useLang";
import Post from "../components/Post";


const NewsList = () => {
	const dispatch = useDispatch();

	const {
		list: {items, isWaiting},
	} = useList({
		ListActions,
		getStateRoot,
	})

	const {hasMore, page} = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));
	const {getLangText} = useLang();

	const loadNewPage = () => dispatch(ListActions.loadNewPage());
	const [ref, inView] = useInView()

	useEffect(() => {
		if (inView && hasMore && !isWaiting && page > 1) {
			loadNewPage()
		}
	}, [inView, hasMore, page])

	if (!items.length && isWaiting) {
		return (
			<div>
				<div className="animate-pulse">
					<div className="h-60 bg-gray-200 rounded-xl dark:bg-gray-700 w-full mb-3"></div>
					<div className="h-60 bg-gray-200 rounded-xl dark:bg-gray-700 w-full mb-3"></div>
				</div>
			</div>
		)
	}

	if (!items.length && !isWaiting) {
		return (
			<div className={`empty-state with-padd with-bg with-shadow`}>
				<div className="img-icon">
					{/*<img src={imgSrc}*/}
					{/*	 alt=""/>*/}
				</div>
				<div className="title" dangerouslySetInnerHTML={{__html: `${getLangText('lackOfNewsLabel')}`}}/>
			</div>
		);
	}

	return (
		<InfiniteScroll
			dataLength={items.length}
			next={loadNewPage}
			loader={<Loader className="static-loader"/>}
			hasMore={hasMore}
		>
			<div className="news-row">
				{items.map((item) => (
					<Post
						key={item.id}
						item={item}
					/>
				))}
			</div>
			<div
				style={{height: '1px'}}
				className="for-intersection" ref={ref}
			/>
		</InfiniteScroll>
	);
};

export default NewsList;
