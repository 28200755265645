import Model, {convertObjectProperties} from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";
import moment from "moment/moment";
import {convertToUserTimeZone, convertToWarsawTimeZone} from "../../../components/app/shared/helpers/dateHelpers";

class SalesNotificationPostModel extends Model {
    getModel() {
        return {
            message: null,
            notify_later: '0',
            notifications_send_date: null,
            notify_date: convertToUserTimeZone().toDate(),
            notify_time: convertToUserTimeZone().format('HH:mm'),
        };
    }

    getValidators() {
        return {
            message: ValidationError.notEmpty,
            notifications_send_date: ValidationError.skip,
        };
    }

    buildDTO(data) {
        const preparedDateTime = (date, time) => {
            const preparedDate = moment(date).format('YYYY-MM-DD');
            return convertToWarsawTimeZone({date: preparedDate, time}).format('YYYY-MM-DD HH:mm');
        };

        return {
            message: data.message,
            notifications_send_date: !!+data.notify_later ? preparedDateTime(data.notify_date, data.notify_time) : null,
        }
    }
}

export default new SalesNotificationPostModel();
