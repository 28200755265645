import React from 'react';
import Chart from "react-apexcharts";
import useLang from "../../../../../../src_shared/hooks/useLang";
import EmptyList from "../../../../../app/shared/EmptyList";
import useScanTranslations, {
    SCAN_TRANSLATIONS_WITHOUT_DETAILS,
} from "../../../../../app/shared/hooks/useScanTranslations";

const PaymentChart = ({data}) => {
    const {getLangText} = useLang();

    const {getScanTranslations} = useScanTranslations(SCAN_TRANSLATIONS_WITHOUT_DETAILS)

    const filteredData = data.filter((x) => !!+x.percent);

    const preparedPaymentsInfo = filteredData.map((i) => +i.percent);

    const options = {
        chart: {
            id: "payments-donuts",
            type: 'donut',
        },
        colors: [
            '#3C61EA',
            '#5FA5FA',
            '#90E8EE',
            '#FF8100',
            '#FFD133',
            '#3B29CC',
            '#D3D3D3',
            '#808080',
            '#FFFFFF',
            '#A9A9A9',
            '#C0C0C0',
        ],
        opacity: 1,
        stroke: {
            width: 3,
        },
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 5,
                dataLabels: {
                    orientation: 'vertical',
                    position: 'center',
                    enable: false,
                    show: false,
                },
                columnWidth: 35,
            },
        },
        fill: {
            type: 'gradient',
            gradient: {
                gradientToColors: [
                    '#3C61EA',
                    '#5FA5FA',
                    '#90E8EE',
                    '#FF8100',
                    '#FFD133',
                    '#3B29CC',
                    '#D3D3D3',
                    '#808080',
                    '#FFFFFF',
                    '#A9A9A9',
                    '#C0C0C0',
                ],
            }
        },
        legend: {
            show: false
        },
        dataLabels: {
            enabled: false
        },
        labels: filteredData,
        tooltip: {
            custom: function ({series, seriesIndex, dataPointIndex, w}) {
                return `<div class="chart-tooltip">
<div class="label">${getScanTranslations(w.config.labels[seriesIndex])}</div>
<div class="value">${series[seriesIndex]} %</div>
</div>`
            }
        }
    };

    return (
        <div className="bg-[white] shadow-default rounded-[5px] px-[20px] py-6 pb-2 mb-3">
            <div className="donuts-chart-wrapper">
                <div className="title font-bold">
                    {getLangText('activities')}
                </div>
                <EmptyList
                    className="full-size"
                    imgSrc="/assets/images/empty/chart-3.svg"
                    items={filteredData}
                    title=""
                >
                    <div className="donuts-chart bigger-size">
                        <Chart
                            options={options}
                            series={preparedPaymentsInfo}
                            type="donut"
                            width="100%"
                        />
                    </div>
                </EmptyList>
                <div className="custom-legend">
                    {filteredData.map((item, index) => (
                        <div
                            key={index}
                        >
                            <div className="label inline-styles"
                                 dangerouslySetInnerHTML={{__html: getScanTranslations(item)}}/>
                            <div className="value">
                                {item.percent}%
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PaymentChart;
