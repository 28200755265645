import React, {useEffect} from 'react';
import useLang from "../../../../src_shared/hooks/useLang";
import useFormLocal from "../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../RestServices";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import ShippingCountryForm from "../components/ShippingCountryForm";
import SettingsShippingCountryModel from "../../../../modules/models/settings/SettingsShippingCountryModel";

const ShippingCountryPage = ({countries, fetchData}) => {
	const {getLangText} = useLang();

	const [{
		onSave,
		isWaiting,
		getFieldProps,
		values,
		updateValues,
	}] = useFormLocal({
		rest: services.API_SETTINGS_SHIPPING_COUNTRY,
		model: SettingsShippingCountryModel,
		initialAutoLoad: false,
		afterSubmit: fetchData,
	});

	useEffect(() => {
		updateValues({countries})
	}, [countries])

	return (
		<section>
			<div className="left-side">
				<h2 className="label">{getLangText('settings_shipping_countries')}</h2>
			</div>
			<div className="form-col loader-parent">
				<ShippingCountryForm
					onSave={onSave}
					isWaiting={isWaiting}
					values={values}
					getFieldProps={getFieldProps}
				/>
				{values.countries?.length &&
					<div className="div-table">
						<div className="table-head">
							<div className="table-row">
								<div className="name flex-grow-1">{getLangText('name')}</div>
								<div className="small-col">{getLangText('active_label_2')}</div>
								<div className="action-button-col"/>
							</div>
						</div>
						<div className="table-body">
							{values.countries?.map((item) => (
								<Link
									key={item.id}
									to={`${RouterPaths.SettingsShippingCountryEdit}/${item.id}/`}
									className="table-row"
								>
									<div className="name flex-grow-1">
										{item.name}
									</div>
									<div className="small-col icon-status">
										{!!+item.active ?
											<i className="icon-tick"/>
											:
											<i className="icon-cross"/>
										}
									</div>
									<div className="action-button-col">
										<div className="button border-button no-hover small-size">
											{getLangText('edit')}
											<i className="icon-arrow"/>
										</div>
									</div>
								</Link>
							))}
						</div>
					</div>
				}
			</div>
		</section>
	);
};

export default ShippingCountryPage;
