export const CLIENTS_DELETED_TYPES = [
	{
		value: 'all', label: 'allLabel',
	},
	{
		value: '0', label: 'unblocked',
	},
	{
		value: '1', label: 'blocked',
	},
]
