import React from "react";
import useForm from "../../../../../src_shared/form/useForm";
import FormActions from "./FormActions";
import Loader from "../../../shared/Loader";
import {useParams} from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import useLang from "../../../../../src_shared/hooks/useLang";

const PackagingTypeForm = () => {
	const params = useParams()
	const {getLangText} = useLang();

	const {
		data: {values, setValue},
		form: {isWaiting, onSave},
		modal: {hideModal, modalVisible},
	} = useForm({
		FormActions,
		params,
	});

	return (
		<Modal
			show={modalVisible}
			className="add-order-modal"
			dialogClassName="default-modal sm-size"
			onHide={hideModal}
		>
			<Modal.Header>
				<Modal.Title>{getLangText('editPackedTypeLabel')}</Modal.Title>
				<button
					onClick={hideModal}
					type="button"
					className="button"
				>
					<i className="icon-cross"/>
				</button>
			</Modal.Header>
			<Modal.Body>
				<div className="loader-parent">
					<Loader isLoading={isWaiting}/>
					{values.packageTypes.length ?
							<div className="data-boxes-wrapper">
								{values.packageTypes.map((item) => (
									<div
										key={item.id}
										className="content payment-content"
									>
										<div className="content__top-part">
											<input
												checked={values.package_type_id === item.id}
												onChange={() => setValue('package_type_id', item.id)}
												type="radio"
												name="package_radio"
											/>
											<span className="checkmark"/>
											<div className="flex-grow-1">
												<div className="label">{item.name}</div>
												<div className="data">{item.description}</div>
											</div>
										</div>
									</div>
								))}
							</div> :
							<div className="form-info-box align-items-center w-100 mt-3 small-line-height">
								<div>
									<i className="icon-info-c"/>
								</div>
								<div>
									<div className="title">{getLangText('noPackedTypesLabel')}</div>
								</div>
							</div>
					}
				</div>
			</Modal.Body>
			<Modal.Footer>
				<button
					onClick={hideModal}
					type="button"
					className="button border-button"
				>
					{getLangText('cancelButton')}
				</button>
				<button
					type="button"
					className="button primary"
					onClick={onSave}
				>
					{isWaiting && <Loader isLoading/>}
					{getLangText('saveButton')}
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default PackagingTypeForm;



