import {getStateRoot, prefix} from './listReducer';
import {services} from "../../../../RestServices";
import {SharedEditProductListActions} from "../shared/SharedEditProductListActions";

export const getInstance = () => new SharedEditProductListActions({
	getStateRoot,
	restService: services.API_PRODUCT_FINISHED_CART,
	prefix,
	preventPushParams: true,
});

export default getInstance();
