import React from 'react';
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../../routes/RouterPath";
import useLang from "../../../../../../src_shared/hooks/useLang";

const BluemediaBigBox = ({data}) => {
    const {getLangText} = useLang();

    return (
        <div className="settings-box blue-media col-md-4">
            <Link to={RouterPaths.SettingsPaymentsOnlineLanding}>
                <div className="title flex align-items-center">
                    {getLangText('settings_menu_bluemedia')}
                    <img className="ml-auto" src="/assets/images/payments/blik.svg" alt=""/>
                    <img className="ml-2" src="/assets/images/payments/bluemedia.svg" alt=""/>
                </div>
                <div className="desc">
                    {getLangText('addOnlinePaymentsToYourShopLabel')}
                </div>
                <div className="benefits">
                    <div className="flex">
                        <i className="icon-tick-c"/>
                        <span dangerouslySetInnerHTML={{__html: getLangText('fastPaymentsLabel')}}/>
                    </div>
                    <div className="flex">
                        <i className="icon-tick-c"/>
                        <span
                            dangerouslySetInnerHTML={{__html: getLangText('automaticPaymentsStatusChange')}}/>
                    </div>
                    <div className="flex">
                        <i className="icon-tick-c"/>
                        <span dangerouslySetInnerHTML={{__html: getLangText('easyForClientsPayments')}}/>
                    </div>
                </div>
                <div className="button-place">
                    <div className="flex-grow-1">
                        {!!+data.bm_active ?
                            <div className="small-status green">
                                <i className="icon-tick"/>
                                {getLangText('active_label')}
                            </div>
                            :
                            <div className="small-status red">
                                <i className="icon-cross"/>
                                {getLangText('not_active_label')}
                            </div>
                        }
                    </div>
                    <div className="button primary small-size">
                        {getLangText('button_go_to')}
                        <i className="icon-arrows" style={{position: 'relative', top: '-2px'}}/>
                    </div>
                </div>
            </Link>
        </div>
    );
};

export default BluemediaBigBox;