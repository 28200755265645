import React, {useCallback, useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import _ from 'lodash';
import {getGETParamsUrl} from "../../../../../src_shared/list/ListActions";
import useLang from "../../../../../src_shared/hooks/useLang";
import {services} from "../../../../RestServices";
import Loader from "../../Loader";
import api from "../../../../../services/axios/axios";
import {MONDIAL_RELAY_POINT_MODAL} from "../../../basket/secondStep/SecondStep";

const MondialRelayPointModal = ({show, hideModal, params = {}, updateValues = (obj) => {}, shippingId, rest = services.API_CART_PARCEL_POINTS}) => {
    const [items, setItems] = useState([]);
    const [waiting, setWaiting] = useState(false);
    const {getLangText} = useLang();

    const selectPoint = (point) => {
        updateValues({
            pointMachineType: MONDIAL_RELAY_POINT_MODAL,
            pointMachineName: point.LgAdr1,
            pointMachineStreet: point.LgAdr3,
            pointMachineCode: point.Num,
            pointMachineCity: point.Ville,
            pointMachineCityCodeBox: point.CP,
            shipping: shippingId,
        })
        hideModal()
    }

    const getPointsList = async (inputValue = params.zip_code || '') => {
        params.zip_code = inputValue;
        setWaiting(true)
        try {
            const {data} = await api.get(`/${rest}${getGETParamsUrl(params)}`)
            setItems(data.items?.PointsRelais?.PointRelais_Details || [])

        } catch (e) {
            console.log("Error get points")
        } finally {
            setWaiting(false)
        }
    }

    useEffect(() => {
        getPointsList()
    }, [])


    const debouncedSave = useCallback(
        _.debounce((name) => getPointsList(name), 500),
        []
    );

    return (
        <Modal
            show={show}
            className="higher-index"
            dialogClassName="default-modal sm-size"
            backdropClassName="higher-index"
            onHide={hideModal}
            animation={false}
        >
            <Modal.Header>
                <Modal.Title>{getLangText('selectMondialRelayPointLabel')}</Modal.Title>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            <Modal.Body>
                <div id="easypack-widget" className="easypack-widget">
                    <div className="easypack-dropdown" data-open="true">
                        <div className="easypack-dropdown__select">
                            <span>{getLangText('customerBasketSelectInpostPointLabel')}</span>
                        </div>
                        <div className="easypack-dropdown__content">
                            <div className="search-input-loader-wrapper">
                                <input
                                    autoFocus={true}
                                    type="text"
                                    onChange={(e) => debouncedSave(e.target.value)}
                                    placeholder={getLangText('searchViaZipCodeLabel')}
                                />
                            </div>
                            <ul className="easypack-dropdown__list loader-parent">
                                {waiting ?
                                    <Loader className="static-loader" isLoading={true} />:
                                    items.map((i) => (
                                        <li
                                            key={i.Num}
                                            onClick={() => selectPoint(i)}
                                        >
                                            {i.Num} - {i.LgAdr1} {i.LgAdr2} {i.LgAdr3} {i.LgAdr4} - {i.Ville}
                                        </li>
                                    ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button border-button m-0"
                >
                    {getLangText('cancelButton')}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default MondialRelayPointModal;



