import React, {useEffect} from 'react';
import Modal from "react-bootstrap/Modal";
import {useDispatch, useSelector} from "react-redux";
import {CONFIRM_MODAL_TYPES} from "./ConfirmModalTypes.js";
import {
	getSummaryText,
	sendMessage,
	setConfirmModalToggleVisibility,
	updateFieldInConfirmMModal
} from "../../LiveDetailsActions.js";
import Loader from "../../../../../messenger/loader/Loader.jsx";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import useLang from "../../../../../../../src_shared/hooks/useLang";

const BasketStateModal = ({title, formLabel, saveButton, modalType}) => {

	const dispatch = useDispatch();
	const {getLangText} = useLang();
	const {clientsConfirmModal} = useSelector((state) => state.lives.details)

	return (
		<Modal
			dialogClassName="default-modal"
			show={clientsConfirmModal.modalType === modalType}
			onHide={() => dispatch(setConfirmModalToggleVisibility(null))}
		>
			<Modal.Header>
				<Modal.Title>{title}</Modal.Title>
				<button
					onClick={() => dispatch(setConfirmModalToggleVisibility(null))}
					type="button"
					className="button"
				>
					<i className="icon-cross"/>
				</button>
			</Modal.Header>
			<Modal.Body>
				<div className="form-group">
					<label className="control-label">{formLabel}</label>
					<div className="border-area">
						<textarea
							className="form-control"
							name="client-message"
							rows="10"
							onChange={(e) => dispatch(updateFieldInConfirmMModal('basketState', e.target.value))}
							value={clientsConfirmModal.data?.basketState}
						/>
						{modalType === CONFIRM_MODAL_TYPES.SUMMARY &&
						<div className="bottom-part">
							<div className="form-group switch-form inline-label">
								<input
									type="checkbox"
									className="bot-status-checkbox"
									checked={clientsConfirmModal.data.orderLink}
									onChange={(e) => dispatch(updateFieldInConfirmMModal('orderLink', e.target.checked))}
								/>
								<label className="switch">
									<span className="slider"/>
								</label>
								<div className="control-label font-weight-bold">
									{getLangText('orderLinkLabel')}
									<OverlayTrigger
										placement='top'
										overlay={
											<Tooltip id="link-to-client-info">
												{getLangText('orderLinkTooltipLabel')}
											</Tooltip>
										}
									>
										<i className="icon-info"/>
									</OverlayTrigger>
								</div>
							</div>
						</div>
						}
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<button
					onClick={() => dispatch(setConfirmModalToggleVisibility(null))}
					type="button"
					className="button border-button"
				>
					{getLangText('cancelButton')}
				</button>
				<button
					onClick={() => dispatch(sendMessage())}
					type="button"
					className="button primary small-size"
				>
					{clientsConfirmModal.isLoading && <Loader/>}
					{saveButton}
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default BasketStateModal;
