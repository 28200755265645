export const defaultLang = "pl";

export const supportedLangs = {
	pl: "Polski",
	en: "English",
	it: 'Italiano',
	es: 'Español',
	de: 'Deutsch',
	nl: 'Nederlands',
	fr: 'Français',
	se: 'Sverige',
	dk: 'Dansk',
};

export const supportedOptionLangs = [
	{
		label: 'Polski',
		value: 'pl',
		flag: 'pl',
	},
	{
		label: 'English',
		value: 'en',
		flag: 'en',
	},
	{
		label: 'Italiano',
		value: 'it',
		flag: 'it',
	},
	{
		label: 'Español',
		value: 'es',
		flag: 'es',
	},
	{
		label: 'Deutsch',
		value: 'de',
		flag: 'de',
	},
	{
		label: 'Nederlands',
		value: 'nl',
		flag: 'nl',
	},
	{
		label: 'Français',
		value: 'fr',
		flag: 'fr',
	},
]

export const langUrl = "/locales/{lang}.json?v=1";
