import React, {useRef, useState, useCallback} from 'react';
import {WithContext as ReactTags} from "react-tag-input";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import axios from "axios";
import {selmoUrl} from "../../../../../../src_shared/api/api";
import useOutsideClick from "../../../../messenger/hooks/useOutsideClick/useOutsideClick";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import FormActions from "../FormActions";
import {getStateRoot} from "../formReducer";
import {services} from "../../../../../RestServices";
import debounce from "lodash.debounce";
import useLang from "../../../../../../src_shared/hooks/useLang";
import api from "../../../../../../services/axios/axios";

const Tags = ({setValue, tags}) => {
	const dispatch = useDispatch();
	const [customSuggestions, setCustomSuggestions] = useState([])
	const suggestionsRef = useRef(null);
	const {tagsInputVisible} = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));
	const {getLangText} = useLang();

	const tagColors = ['green', 'yellow', 'red', 'blue', 'purple', 'pink'];
	const randomColor = tagColors[Math.floor(Math.random() * tagColors.length)]

	const KeyCodes = {
		comma: 188,
		enter: 13,
	};

	const setTagsInputVisible = (toggle) => dispatch(FormActions.setTagsInputVisible(toggle));

	const delimiters = [KeyCodes.comma, KeyCodes.enter];

	const handleAddition = (newTag) => {
		const tagExist = customSuggestions.find((suggestion) => suggestion.text === newTag.text)
		const generateColor = tagExist ? tagExist.className : (newTag.className || randomColor);

		if (tagExist) {
			setValue('tags', [...tags, {...newTag, className: generateColor}])
			return;
		}

		setValue('tags', [...tags, {...newTag, id: '', className: generateColor}])
	}

	const handleDelete = (i) => {
		setValue('tags', tags.map((tag, index) => index === i ? {
			...tag,
			is_deleted: '1',
		} : tag));
	}

	const TagsRow = () => {
		return (
			<div
				className="tags-row flex-wrap">
				{tags && tags.filter((i) => !+i.is_deleted).map((i, index) => (
					<div
						key={i.id}
						className={`tag ${i.className}`}
					>
						{i.text}
						<button
							type="button"
							className="hide-tag-input"
							onClick={() => handleDelete(index)}
						>
							<i className="icon-cross"/>
						</button>
					</div>
				))}
				<button
					type="button"
					className="add-new-tag"
					onClick={() => setTagsInputVisible(true)}
				>
					{getLangText('addTagLabel')}&nbsp;+
				</button>
			</div>
		)
	}

	const handleInputChange = async (value) => {
		if (!value.length) {
			setCustomSuggestions([])
		}
		if (!!value.length) {
			try {
				const {data} = await api.get(`${selmoUrl}/${services.API_ORDERS_TAGS}?search=${value}`);
				const filteredSuggestions = data.items.filter((suggestion) => !tags.some((i) => i.text === suggestion.text))
				setCustomSuggestions(filteredSuggestions)

			} catch (e) {
				console.error('Cannot get suggestions')
			}
		}
	}

	const debouncedSave = useCallback(
		debounce((value) => handleInputChange(value), 500),
		[tags]
	);

	const deleteSuggestion = async (tag) => {
		try {
			await api.delete(`${selmoUrl}/${services.API_ORDERS_TAGS}/${tag.id}`)
			const filteredSuggestions = customSuggestions.filter((i) => i.id !== tag.id);
			setCustomSuggestions(filteredSuggestions)

		} catch (e) {
			console.error('Cannot delete suggestions')
		}
	}

	useOutsideClick(suggestionsRef, () => {
		if (!!customSuggestions.length) {
			setCustomSuggestions([])
		}
	});

	return (
		<div>
			{tagsInputVisible ?
				<div className={`react-tag-container in-order ${tagsInputVisible ? 'active' : ''}`}>
					<ReactTags
						inline
						tags={tags.filter((i) => !+i.is_deleted)}
						delimiters={delimiters}
						handleDelete={handleDelete}
						handleAddition={handleAddition}
						handleInputChange={debouncedSave}
						allowDragDrop={false}
						placeholder=""
						inputProps={{
							autoComplete: "off"
						}}
					/>
					{!!customSuggestions.length &&
						<ul
							ref={suggestionsRef}
							className="custom-dropdown"
						>
							{customSuggestions.map((i) => (
								<li key={i.id}>
									<button
										onClick={() => {
											handleAddition(i);
											setCustomSuggestions([]);
											document.querySelector('.ReactTags__tagInputField').value = '';
										}}
										className={`tag ${i.className}`}
									>
										{i.text}
									</button>
									<OverlayTrigger
										placement='top'
										overlay={
											<Tooltip id={`tooltip-remove-products`}>
												{getLangText('removeSuggestionLabel')}
											</Tooltip>
										}
									>
										<button
											type="button"
											className="remove-suggestion"
											onClick={() => deleteSuggestion(i)}
										>
											<i className="icon-bin"/>
										</button>
									</OverlayTrigger>

								</li>
							))}
						</ul>
					}
					<button
						type="button"
						className="hide-tag-input"
						onClick={() => setTagsInputVisible(false)}
					>
						<i className="icon-cross"/>
					</button>
				</div>
				:
				<TagsRow/>
			}
		</div>
	);
};

export default Tags;
