const initialState = {
    isOpen: false,
    isSuccess: false,
    data: {
        title: 'generatingFiles',
    },
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'GENERATE_LABEL_MODAL_TOGGLE_VISIBILITY':
            return {...state, isOpen: action.toggle, data: action.data || initialState.data};
        case 'GENERATE_LABEL_MODAL_SUCCESS':
            return {...state, isSuccess: action.toggle};
        default:
            return state;
    }
}
