import React from 'react';
import {setViewType} from "./TabsActions";
import {useDispatch} from "react-redux";
import useLang from "../../../../../../src_shared/hooks/useLang";

const SwitchTabs = ({getOrderProducts, activeViewType, hasProductsChanged, showSafetyCloseModal}) => {
    const dispatch = useDispatch();
	const {getLangText} = useLang();

    const handleSummaryClick = () => {
        if (hasProductsChanged()) {
			showSafetyCloseModal({
				visible: true,
				toSummaryView: true,
			});
			return;
        }
		getOrderProducts()
        dispatch(setViewType('summary'))
    }

    return (
        <div className="switch-part">
            <button
                type="button"
                className={activeViewType === 'edit' ? 'active' : ''}
                onClick={() => dispatch(setViewType('edit'))}
            >
                <i className="icon-cart"/>
				{getLangText('editionLabel')}
            </button>
            <button
                type="button"
                className={activeViewType === 'summary' ? 'active' : ''}
                onClick={handleSummaryClick}
            >
                <i className="icon-messenger"/>
				{getLangText('summaryLabel')}
            </button>
        </div>
    );
};

export default SwitchTabs;
