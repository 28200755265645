import WaitingReducer from "../list/waiting/WaitingReducer";
import DataReducer from './data/DataReducer';
import ModalReducer from "../modal/ModalReducer";
import validationReducer from "./validation/ValidationReducer";
import Composition from "../../components/app/shared/helpers/Composition";

export const getInitState = (model, defaultStateValues) => ({
	...DataReducer.getInitState(model),
	...WaitingReducer.initState,
	...ModalReducer.initState,
	...validationReducer.initState,
	...defaultStateValues,
	waitingForRemoving: false,
});

export const getReduceMap = () => new Composition(
	DataReducer.reduceMap,
	WaitingReducer.reduceMap,
	ModalReducer.reduceMap,
	validationReducer.reduceMap,
	{
		WAITING_FOR_REMOVING: (state, action) => ({...state, waitingForRemoving: action.toggle})
	}
);

export default {
	getInitState,
	getReduceMap,
};
