import React from 'react';
import useList from "../../../../../src_shared/list/useList.js";
import ListActions from "./ListActions.js";
import {getStateRoot} from "./listReducer.js";
import SearchInput from "../../../shared/components/form/SearchInput.jsx";
import FiltersActions from "../../filters/FiltersActions.js";
import useLang from "../../../../../src_shared/hooks/useLang";
import {useDispatch} from "react-redux";
import Pagination from "../../../shared/components/pagination/Pagination";
import Table from "./Table";
import ManufacturerFormModal from "../modalForm/ModalForm";

const ManufacturesList = () => {
	const {getLangText} = useLang();
	const dispatch = useDispatch();

	const showModal = () => dispatch(ListActions.modal.showModal());

	const {
		list: {items, isWaiting},
		search,
		remove: {confirmItemRemoval, isModalVisible, cancelItemRemoval, markedToRemove},
		pagination: {
			changePage,
			getPagination,
			itemsPerPage,
			nextPage,
			prevPage,
			totalItems,
			updateItemsPerPage
		},
		sort: {updateSortOptions, getSortClass},
	} = useList({
		ListActions,
		getStateRoot,
		FiltersActions,
	});

	return (
		<div className="table-list-wrapper">
			<div className="default-shadow-box pb-0">
				<div className="table-header">
					<div className="top-part flex-wrap">
						<h1 className="title d-block mb-sm-0 mb-3">
							<div className="d-flex align-items-center">
								{getLangText('manufacturersLabel')}
								{totalItems > 0 &&
									<div className="badge">{totalItems}</div>
								}
							</div>
						</h1>
						<SearchInput
							placeholder={getLangText('searchPlaceholder')}
							className="ml-sm-auto mr-0"
							search={search}
						/>
						<button
							type="button"
							onClick={showModal}
							className="button primary small-size ml-2"
						>
							{getLangText('addManufacturerLabel')}
						</button>
					</div>
				</div>
				<Table
					isWaiting={isWaiting}
					items={items}
					updateSortOptions={updateSortOptions}
					getSortClass={getSortClass}
				/>
			</div>
			<Pagination
				getLangText={getLangText}
				pages={getPagination()}
				items={items}
				itemsPerPage={itemsPerPage}
				totalItems={totalItems}
				onPrevPage={prevPage}
				onChangePage={changePage}
				onNextPage={nextPage}
				onChangeItemsPerPage={updateItemsPerPage}
				classes={{
					prevIcon: 'icon-chevron left',
					nextIcon: 'icon-chevron right',
				}}
				showItemsPerPageSelect
			/>
			{isModalVisible &&
				<ManufacturerFormModal
					modalVisible={isModalVisible}
					hideModal={cancelItemRemoval}
				/>
			}
		</div>
	);
};

export default ManufacturesList;
