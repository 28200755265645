export const initState = {
    modalVisible: false,
};

export const reduceMap = {
    MODAL_SHOW: state => ({
		...state, modalVisible: true
	}),
	MODAL_HIDE: state => ({
		...state, modalVisible: false
	}),
};

export default {
    initState,
    reduceMap,
};
