import createReducer from "../../../shared/helpers/createReducer";
import history from "../../../../history";
import {parseParams} from "../../../../../../../src_shared/list/ListActions";
import DataReducer from "../../../../../../../src_shared/form/data/DataReducer";
import {getReduceMap} from "../../../../../../../src_shared/filters/filterReducer";
import {supportedOptionLangs} from "../../../../../../../services/i18n/i18n";

const queryParams = history.location.search;

const parsedParams = parseParams(queryParams)

const preparedLang = parsedParams['lang[]'] || [];
const preparedCategory = parsedParams['category[]'] || [];
const preparedKnowledgeBase = +parsedParams.knowledge_base || 2
export const getStateRoot = (state) => state.admin.posts.news.filters;

export const getInitState = () => DataReducer.getInitState({
	lang: preparedLang || [],
	category: preparedCategory || [],
	langSelectAll: preparedLang.length === supportedOptionLangs.length,
	knowledge_base: preparedKnowledgeBase,
}, {
	lang: [],
	category: [],
	langSelectAll: false,
	knowledge_base: 2,
})

export const prefix = 'ADMIN_NEWS_FILTERS_';

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();
