import React, {useEffect} from 'react';
import {Modal} from "react-bootstrap";
import useLang from "../../../../../../src_shared/hooks/useLang";
import Loader from "../../../../../../src_shared/components/loader/Loader";
import {components} from "react-select";
import SelectField from "../../../../../../src_shared/components/form/selectField/SelectField";
import {services} from "../../../../../RestServices";
import {useParams} from "react-router-dom";
import useFormLocal from "../../../../../../src_shared/hooks/useFormLocal";
import PaymentStatusModel from "../../../../../../modules/models/order/PaymentStatusModel";
import EditOrderFormActions from "../../form/FormActions";
import {useDispatch} from "react-redux";

const PaymentStatusModal = ({isOpen, hideModal, values, stage}) => {
    const {getLangText} = useLang();
    const {id} = useParams();
    const dispatch = useDispatch();

    const options = [
        {value: 'paid', label: getLangText('paidOptionLabel')},
        {value: 'not_paid', label: getLangText('notPaidOptionLabel')},
    ]

    const loadData = () => dispatch(EditOrderFormActions.loadData(id))
    const afterSubmit = () => {
        loadData();
        hideModal();
    }

    const [{values: localValues, setValue, onSave, isWaiting}] = useFormLocal({
        model: PaymentStatusModel,
        rest: `${services.API_ORDER_PRODUCTS_PAID_STATUS}/${values.id}`,
        initialAutoLoad: false,
        method: 'put',
        afterSubmit,
    });

    useEffect(() => {
        if (stage) {
            setValue('order_stage', stage)
        }
    }, [stage])

    return (
        <Modal
            show={isOpen}
            className="add-order-modal"
            dialogClassName="default-modal w-420"
            onHide={hideModal}
        >
            <Modal.Header>
                <Modal.Title>
                    {getLangText('changePaymentStatus')}
                    <div className="text-desc text-sm font-medium">
                        {getLangText('changeStatusForAllProductsLabel')}
                    </div>
                </Modal.Title>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            <Modal.Body className="add-order-modal-body">
                    <div className="form-label mb-0">
                        {getLangText('paymentStatusLabel')}
                    </div>
                <SelectField
                    name="status"
                    options={options}
                    setValue={setValue}
                    className="d-inline-block mt-0 mb-0 payment-status-select"
                    value={localValues.status}
                    extraProps={{
                        isSearchable: false,
                        components: {
                            Control: ({children, ...rest}) => (
                                <components.Control {...rest}>
                                    {localValues.status === 'paid' ?
                                        <div className="green-option chosen-option">
                                            <i className="icon-tick-c"/>
                                            {children}
                                        </div> :
                                        <div className="red-option chosen-option">
                                            <i className="icon-cross-c"/>
                                            {children}
                                        </div>
                                    }
                                </components.Control>
                            )
                        }
                    }}
                />
            </Modal.Body>
            <Modal.Footer>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button border-button"
                >
                    {getLangText('cancelButton')}
                </button>
                <button
                    type="button"
                    onClick={onSave}
                    className={`button primary ${isWaiting ? 'loading-button' : ''}`}
                >
                    {isWaiting && <Loader isLoading/>}
                    {getLangText('saveButton')}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default PaymentStatusModal;