import React, {useEffect, useState} from 'react';
import Field from "../../../../src_shared/components/form/field/Field";
import Button from "../../../../src_shared/components/ui/Button/Button";
import EmployeeSignupModel from "../../../../modules/models/login/EmployeeSignupModel";
import {services} from "../../../RestServices";
import {batch, useDispatch} from "react-redux";
import useFormLocal from "../../../../src_shared/hooks/useFormLocal";
import useLang from "../../../../src_shared/hooks/useLang";
import {RouterPaths} from "../../../routes/RouterPath";
import SessionActions from "../../shared/session/SessionActions";
import {useHistory, useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import ComponentLoader from "../../shared/components/componentLoader/ComponentLoader";
import {confettiSetVisibleActions} from "../../shared/components/confetti/ConfettiActions";
import LoginWrapper from "../LoginWrapper";
import useFetchData from "../../../../src_shared/hooks/useFetchData";

const EmployeeSignupForm = () => {
    const {getLangText} = useLang();
    const dispatch = useDispatch();
    const history = useHistory();
    const {hash} = useParams();
    const [errorMessage, setErrorMessage] = useState(null);
    const afterSubmit = (response) => {
        batch(() => {
            dispatch(SessionActions.setAndSaveInStorageToken(response.token))
            history.push(RouterPaths.Dashboard);
            dispatch(confettiSetVisibleActions(true));
        })
    }

    const [{getFieldProps, values, onSave, isWaiting, updateValues, setValue}] = useFormLocal({
        rest: `${services.API_REGISTER_EMPLOYEE}/${hash}`,
        model: EmployeeSignupModel,
        afterSubmit,
        initialAutoLoad: false,
    });

    const [{isLoading}] = useFetchData({
        rest: `${services.API_REGISTER_EMPLOYEE}/${hash}`,
        afterSubmit: updateValues,
        onError: (e) => setErrorMessage(e?.response?.data?.message),
    });

    useEffect(() => {
        setValue('email', values.email);
    }, [values.email])

    return (
        <LoginWrapper>
            <Helmet>
                <meta charSet="utf-8"/>
                {values.shop_name ?
                    <title>{`${getLangText('invitationLabel')} ${values.shop_name} - Selmo`}</title>:
                    <title>{getLangText('invitationLabel')} - Selmo</title>
                }
            </Helmet>
            {isLoading ?
                <ComponentLoader/> :
                (errorMessage === 'EXPIRED' || errorMessage === 'ERROR_NOT_FOUND') ?
                    <div>
                        <img alt="selmo" width={107} src="/assets/images/logo.svg"/>
                        <h1 className="text-3xl font-bold mt-6 mb-2 whitespace-break-spaces">
                            {getLangText(errorMessage === 'ERROR_NOT_FOUND' ? 'sthWentWrongWarning' : 'yourInvitationExpired')}
                        </h1>
                        <div className="text-desc">{getLangText('contactWithOwner')}</div>
                    </div> :
                    <>
                        <img alt="selmo" width={107} src="/assets/images/logo.svg"/>
                        <h1 className="text-3xl font-bold mt-6 mb-8 whitespace-break-spaces"
                            dangerouslySetInnerHTML={{
                                __html: getLangText({
                                    key: 'joinToTeam',
                                    data: [values.shop_name],
                                })
                            }}
                        />
                        <form id="employee-registration-form" onSubmit={onSave}>
                            <Field
                                {...getFieldProps('email')}
                                label={getLangText('emailLabel')}
                                type="email"
                                disabled
                                extraProps={{
                                    required: true,
                                }}
                                className="big-size mb-3"
                                floatedLabel
                            />
                            <Field
                                {...getFieldProps('name')}
                                label={getLangText('enterNameAndSurname')}
                                className="big-size mb-3"
                                extraProps={{required: true}}
                                floatedLabel
                            />
                            <Field
                                {...getFieldProps('password')}
                                label={getLangText('createPassword')}
                                className="big-size mb-3"
                                type="password"
                                extraProps={{required: true}}
                                floatedLabel
                            />
                            <div className="button-place mt-2">
                                <Button
                                    getLangText={getLangText}
                                    type="submit"
                                    isWaiting={isWaiting}
                                    title="register"
                                    className={`button w-full primary-login`}
                                />
                            </div>
                        </form>
                    </>
            }
        </LoginWrapper>
    );
};

export default EmployeeSignupForm;
