import { getActionPrefix } from './CheckboxMultiselectReducer';

export class CheckboxMultiselectActions {
    constructor(config) {
        const {
            prefix,
            name,
        } = config;

        this.prefix = `${prefix}${getActionPrefix(name)}`;
    }

    selectItem(data) {
        return {
            type: `${this.prefix}_SELECT_ITEM`,
            data,
        };
    }

    deselectItem(itemId) {
        return {
            type: `${this.prefix}_DESELECT_ITEM`,
            itemId,
        };
    }

    updateSelection(items) {
        return {
            type: `${this.prefix}_UPDATE_SELECTION`,
            items,
        };
    }
}

export default CheckboxMultiselectActions;
