import {combineReducers} from "redux";
import dashboardReducer from "./dashboard/dashboardReducer";
import statsReducer from "./stats/statsReducer";
import messagesReducer from "./messages/messagesReducer";
import rankingReducer from "./ranking/listReducer";

export default combineReducers({
	dashboard: dashboardReducer,
	stats: statsReducer,
	messages: messagesReducer,
	ranking: rankingReducer,
});
