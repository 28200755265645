import React, {useEffect} from 'react';
import TextArea from "../../../../src_shared/components/form/textarea/TextArea";
import useLang from "../../../../src_shared/hooks/useLang";
import Loader from "../../../../src_shared/components/loader/Loader";

const MultiRowListTemplates = ({
                                   getFieldProps,
                                   onSave,
                                   isWaiting,
                                   setValue,
                                   field,
                                   label,
                                   data,
                                   updateValues,
                                   defaultRowLength = 3,
                                   place,
                               }) => {
    const {getLangText} = useLang();

    const [rows, setRows] = React.useState([Array.from({length: defaultRowLength}, (_, i) => i + 1)]);
    const [isHidden, setIsHidden] = React.useState(true);

    const addNewRow = () => {
        setRows((prev) => [...prev, prev.length + 1]);
        setIsHidden(false)
    }

    const removeRow = (index) => {
        const filtered = rows.filter((i) => i !== index);
        setRows(filtered);
        setValue(`${field}_${index}`, '');
    }

    const toggleHidden = () => {
        setIsHidden((prev) => !prev);
    };

    useEffect(() => {
        const filteredData = Object.entries(data)
            .filter(([key, value]) => !!value && key.startsWith(field))
            .reduce((acc, [key, value]) => {
                acc[key] = value;
                return acc;
            }, {});

        const fieldLength = Object.entries(filteredData)?.length;
        if (!!fieldLength) {
            setRows(Array.from({length: fieldLength}, (_, i) => i + 1));
        }
        updateValues(filteredData)
    }, [data])

    return (
        <div className="form-col">
            {rows.slice(0, isHidden ? defaultRowLength : rows.length).map((row) => (
                <div className="relative mb-3" key={row}>
                    <TextArea
                        {...getFieldProps(`${field}_${row}`)}
                        label={getLangText({
                            key: label,
                            data: [row]
                        })}
                        rows={8}
                    />
                    {row > 3 && (
                        <div className="absolute right-1 bottom-1 p-1 bg-[white] rounded-sm">
                            <button
                                onClick={() => removeRow(row)}
                                type="button"
                                className="text-xs font-medium text-underline text-[#3C61EA]"
                            >
                                <i className="icon-bin mr-1"/>
                                {getLangText('removeMessage')}
                            </button>
                        </div>
                    )}
                </div>
            ))}

            {rows.length > defaultRowLength && isHidden && (
                <button
                    type="button"
                    onClick={toggleHidden}
                    className="flex justify-center hover:bg-[#fbfbfb] transition-all w-full items-center text-sm font-bold text-center text-[#000] border rounded-md p-2 mb-3"
                >
                    {getLangText('showAll')}
                    <span className="text-desc text-xs ml-1">({rows.length - defaultRowLength})</span>
                    <i className="icon-arrows text-desc text-[8px] ml-1 mt-0.5"/>
                </button>
            )}
            <button
                type="button"
                onClick={addNewRow}
                className="text-sm font-bold text-underline text-[#3C61EA] mb-3"
            >
                <i className="icon-plus mr-1 text-[10px]"/>
                {getLangText('addMessage')}
            </button>
            <div className="form-info-box small-line-height w-100">
                <div>
                    <i className="icon-info-c"/>
                </div>
                <div>
                    <div className="title">{getLangText('replyToComment')}</div>
                    <div className="desc">
                        <div>
                            <div className="tag">#{getLangText('clientLabel').toLowerCase()}</div>
                            {getLangText('inThisPlaceWillBeClientName')}
                        </div>
                    </div>
                </div>
            </div>
            {place !== 'PRODUCT' &&
                <div className="form-info-box red mb-3 mt-3">
                    <div>
                        <i className="icon-info-c"/>
                    </div>
                    <div>
                        <div className="title">
                            <strong>{getLangText('settings_messenger_summary_alert_1')}</strong>
                        </div>
                        <div className="desc">
                            {getLangText('settings_messenger_add_to_cart_alert_1')} <strong>3</strong>{' '}
                            {getLangText('settings_messenger_add_to_cart_alert_2')}
                        </div>
                    </div>
                </div>
            }
            <div className="button-place">
                <button
                    type="button"
                    onClick={onSave}
                    className={`button primary ${isWaiting ? 'loading-button' : ''}`}
                >
                    {isWaiting && <Loader isLoading/>}
                    {getLangText('saveButton')}
                </button>
            </div>
        </div>
    );
};

export default MultiRowListTemplates;
