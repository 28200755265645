import React, {useState} from 'react';
import useLang from "../../../../src_shared/hooks/useLang";
import {convertToUserTimeZone} from "../helpers/dateHelpers";
import SendInvitationToMobileAppModal from "./SendInvitationToMobileAppModal";

const SendInvitationToMobileApp = ({mobileAppStatus, activeConversation, block = false, loadData}) => {
    const {getLangText} = useLang();
    const notConnected = mobileAppStatus?.status === 'not_connected';
    const [showModal, setShowModal] = useState(false);

    if (!mobileAppStatus || mobileAppStatus?.status === 'connected') return null;

    return (
        <>
            <div
                className={`${notConnected ? 'bg-[#FEF2F2]' : 'bg-[#FEFCE8]'} ${block ? 'flex-wrap p-3 mt-3' : 'mt-1 px-2 py-[6px]'} rounded-[8px] items-center flex`}>
                <div className={`flex ${block ? '' : 'items-center'}`}>
                    <div className={block ? 'mt-0.5' : ''}>
                        {notConnected ?
                            <i className="icon-cross-c text-[18px] text-[#FF3456] mr-1.5"/> :
                            <i className="icon-info-c text-[18px] text-[#FBCC14] mr-1.5"/>
                        }
                    </div>
                    <div
                        className={`font-bold mt-0.5 ${block ? 'text-sm' : 'text-xs'} ${notConnected ? 'text-[#981B1B]' : 'text-[#854E0F]'}`}>
                        {getLangText(notConnected ? 'thisClientDoesNotFollowYou' : 'sentInvitationToSelmoApp')}
                        {!notConnected &&
                            (block ?
                                <div className="font-medium text-xs">
                                    {getLangText('sentDate')}:
                                    <span
                                        className={`font-bold ml-1`}>
                               {convertToUserTimeZone(mobileAppStatus?.date).format('DD.MM.YYYY HH:mm:ss')}
                           </span>
                                </div> :
                                <span
                                    className={`font-medium ml-1`}>
                               ({convertToUserTimeZone(mobileAppStatus?.date).format('DD.MM.YYYY HH:mm:ss')})
                           </span>)
                        }
                    </div>
                </div>
                <button
                    onClick={() => setShowModal(true)}
                    type="button"
                    className={`text-[#fff] rounded-[5px] px-2 font-bold text-xs ml-auto transition-all ${block ? 'w-full mt-[12px] py-2' : 'w-auto py-[5px]'} ${notConnected ? 'bg-[#FF3456] hover:bg-[#e92e4d]' : 'hover:bg-[#e4940c] bg-[#F59E0C]'}`}
                >
                    {getLangText(notConnected ? 'sendInvitation' : 'sendAgain')}
                </button>
            </div>
            {showModal &&
                <SendInvitationToMobileAppModal
                    modalVisible={showModal}
                    hideModal={() => setShowModal(false)}
                    activeConversation={activeConversation}
                    loadData={loadData}
                />
            }
        </>
    );
};

export default SendInvitationToMobileApp;
