import React from 'react';
import AddToCartRows from "../../../../settings/messengerTemplates/components/AddToCartRows";
import AddToReserveListRows from "../../../../settings/messengerTemplates/components/AddToReserveListRows";
import ProductAddToReserveListTemplatesModel
    from "../../../../../modules/models/products/ProductAddToReserveListTemplatesModel";
import ProductAddToCartTemplatesModel from "../../../../../modules/models/products/ProductAddToCartTemplatesModel";
import {services} from "../../../../RestServices";
import useFetchData from "../../../../../src_shared/hooks/useFetchData";
import Loader from "../../../../../src_shared/components/loader/Loader";

const Templates = ({data}) => {

    const [{data: values, isLoading}] = useFetchData({
        rest: `${services.API_STORAGE_PRODUCT_TEMPLATES}/${data.id}`,
        initialData: {}
    })
    return (
        <div className="settings-form bg-[#fff] rounded-b-md shadow-default">
            <div className="loader-parent">
                <Loader isLoading={isLoading} />
                <AddToCartRows
                    values={values}
                    defaultRowLength={1}
                    model={ProductAddToCartTemplatesModel}
                    rest={`${services.API_STORAGE_ADD_TO_CART_TEMPLATES}/${data.id}`}
                    place="PRODUCT"
                />
                <AddToReserveListRows
                    values={values}
                    defaultRowLength={1}
                    model={ProductAddToReserveListTemplatesModel}
                    rest={`${services.API_STORAGE_RESERVE_LIST_TEMPLATES}/${data.id}`}
                    place="PRODUCT"
                />
            </div>
        </div>
    );
};

export default Templates;
