import ListActions, {getGETParamsUrl} from "../../../../src_shared/list/ListActions";
import {getStateRoot, prefix} from './listReducer';
import {RouterPaths} from "../../../routes/RouterPath";
import {getStateRoot as getFiltersStateRoot} from "../filters/filtersReducer";
import {convertDate} from "../../shared/helpers/dateHelpers";
import history from "../../../history";
import {services} from "../../../RestServices";
import api from "../../../../services/axios/axios";
import {TEST_ACCOUNT_SHOP_ID} from "../../../../src_shared/enums/TrustedShopsIds";
import {selmoAPI2Url, selmoUrl} from "../../../../src_shared/api/api";
import {batch} from "react-redux";
import SessionActions from "../../shared/session/SessionActions";

export class BoxesStatsListActions extends ListActions {
	getLoadParams(state) {
		const params = {};
		const dateFrom = getFiltersStateRoot(state).data.fromDate;
		const dateTo = getFiltersStateRoot(state).data.toDate;
		const isRealized = getFiltersStateRoot(state).data.isRealized;
		const currency = getFiltersStateRoot(state).data.currency;

		if (dateFrom) {
			params.dateFrom = convertDate(dateFrom);
		}

		if (dateTo) {
			params.dateTo = convertDate(dateTo);
		}

		if (isRealized === '1') {
			params.isRealized = isRealized;
		}

		if (currency) {
			params.currency = currency;
		}

		history.replace({
			pathname: this.pathname,
			search: getGETParamsUrl(params)
		});

		return params;
	}

	loadData(id, signal) {
		return async (dispatch, getState) => {
			const {firstInit, userData} = getState().session;
			const {itemsPerPage, sortBy} = this.getStateRoot(getState());
			dispatch(this.waiting.startWaiting())
			if (firstInit) {
				dispatch(this.onFirstInit());
			}
			const params = this.getLoadParams(getState(), id)

			const restUrl = +id ?
				`/${id}${getGETParamsUrl(params)}` :
				getGETParamsUrl(params);
			try {
				const response = await api.get(`${selmoAPI2Url}/${this.restService}${restUrl}`, {signal})
				const {items, total, isFirstStepPage} = response.data;
				if (+total <= +itemsPerPage && this.defaultSortBy) {
					items.sort((a, b) => parseFloat(b[this.defaultSortBy]) - parseFloat(a[this.defaultSortBy]))
				}
				if (!sortBy) {
					// dispatch(this.table.updateSort(this.defaultSortBy, 'desc'));
				}

				batch(() => {
					dispatch(this.table.updateAllItems(items))
					dispatch(this.setFirstStepPage(isFirstStepPage))
					dispatch(this.pagination.updateTotalItems(total))
				})

			} catch (e) {
				dispatch(this.table.getListError(e))
				console.error(e)
			} finally {
				if (firstInit) {
					dispatch(SessionActions.setFirstInit());
				}
				dispatch(this.waiting.stopWaiting())
			}
		}
	}

}

export const getInstance = () => new BoxesStatsListActions({
	getStateRoot,
	restService: services.API_STATS_BOXES,
	prefix,
	pathname: RouterPaths.StatsNew
});

export default getInstance();
