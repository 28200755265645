import * as React from "react"
const ClientIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        style={{
            enableBackground: "new 0 0 34.768 32",
        }}
        viewBox="0 0 34.768 32"
        width={34}
        height={32}
        {...props}
    >
        <path
            d="M12.881 29.594a2.381 2.381 0 0 1-.699 1.697 2.393 2.393 0 0 1-1.704.709h-.007c-.64 0-1.243-.249-1.697-.702a2.38 2.38 0 0 1-.706-1.696 2.412 2.412 0 0 1 2.402-2.406h.005a2.41 2.41 0 0 1 2.406 2.398zm11.637-2.398h-.003a2.415 2.415 0 0 0-2.404 2.403 2.407 2.407 0 0 0 4.812 0 2.406 2.406 0 0 0-2.405-2.403zm7.526-17.152-1.532 10.697c-.279 1.98-1.985 3.472-3.97 3.473H8.913c-2.072 0-3.828-1.629-3.998-3.715L3.432 2.639l-2.515-.44a1.107 1.107 0 0 1-.9-1.282 1.102 1.102 0 0 1 1.282-.9l3.357.587c.496.087.871.498.913 1l.388 4.675h22.848c.155 0 .309.012.464.034 1.79.261 3.032 1.934 2.775 3.731zM29.85 9.73a1.075 1.075 0 0 0-1.046-1.236H6.142l.982 11.825c.076.944.864 1.679 1.793 1.679H26.54c.89 0 1.653-.674 1.779-1.568l1.531-10.7z"
            // style={{
            //     fill: "#9ca3af",
            // }}
        />
    </svg>
)
export default ClientIcon
