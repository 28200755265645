import React from 'react';
import {RouterPaths} from "../../../routes/RouterPath";
import {ORDER_DELETED} from "./useScanTranslations";

const useActivityHistoryRedirect = () => {

    const getRedirectPaths = (item) => {
        if ((item.type === 'QR_PRODUCT_SCAN' || item.type === 'ASSIGN_SHELF_TO_PRODUCT' || item.type === 'TAKE_OFF_PRODUCT_FROM_SHELF' || item.type === 'PRODUCT_INSERT' || item.type === 'PRODUCT_DELETE')) {
            return `${RouterPaths.ProductsList}/edytuj/${item.product_id}`
        }
        return `${RouterPaths.OrdersList}/${item.shop_order_id}`
    }

    const onTableRowClick = (item) => {
        const url = getRedirectPaths(item);
        if (item.type === ORDER_DELETED) return;
        window.open(url);
    }

    const onMouseDown = (item, e) => {
        const url = getRedirectPaths(item);
        if (e.button === 1 || e.buttons === 4) {
            if (item.type === ORDER_DELETED) return;
            window.open(url);
        }
    }

    return (id, setSelectedRow) => ({
        onClick: (e) => onTableRowClick(id, e, setSelectedRow),
        onContextMenu: (e) => onTableRowClick(id, e, setSelectedRow),
        onMouseDown: (e) => onMouseDown(id, e),
    })
};

export default useActivityHistoryRedirect;
