import React, {useEffect, useState} from "react";
import useForm from "../../../../../src_shared/form/useForm";
import FormActions from "./FormActions";
import Field from "../../../../../src_shared/components/form/field/Field";
import useLang from "../../../../../src_shared/hooks/useLang";
import {Link, useParams} from "react-router-dom";
import ConfirmModal from "../../../live/details/default/summary/export/ConfirmModal";
import {RouterPaths} from "../../../../routes/RouterPath";
import Loader from "../../../shared/Loader";
import {
    BUTIK_MADAME_SHOP_ID, TEST_ACCOUNT_SHOP_ID
} from "../../../../../src_shared/enums/TrustedShopsIds";
import {useSelector} from "react-redux";

const ManufacturerForm = () => {
    const {getLangText} = useLang();
    const {id} = useParams();
    const {userData} = useSelector((state) => state.session);
    const [name, setName] = useState('');

    const {
        data: {values, setValue},
        form: {isWaiting, getFieldProps, deleteWithoutAsk, onSave},
        modal: {showModal, modalVisible, hideModal},
    } = useForm({
        FormActions, params: {
            id,
        }
    });

    useEffect(() => {
        setName(values.name)
    }, [isWaiting])

    return (<>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <Link to={RouterPaths.ManufacturersList}>{getLangText('manufacturersLabel')}</Link>
                    </li>
                    <li className="active">
                        <div>{name}</div>
                    </li>
                </ul>
                <Link to={RouterPaths.ManufacturersList} className="button back-to-panels mb-lg-3 mt-lg-0 mt-3">
                    <i className="icon-arrow-left"/>
                    {getLangText('backToManufacturersLabel')}
                </Link>
            </div>
            <div
                className={`settings-page sub-settings-page list-with-tabs with-bg loader-parent ${isWaiting ? 'active-loader-parent' : ''}`}>
                <Loader isLoading={isWaiting}/>

                <div className="top-part">
                    <h1 className="title">
                        {name}
                    </h1>
                </div>
                <div className="table-list-wrapper">
                    <div className="default-shadow-box pb-4">
                        <div className="settings-form p-0">
                            <section>
                                <div className="left-side">
                                    <h2 className="label">{getLangText('basicDataLabel')}</h2>
                                </div>
                                <div className="form-col mb-3">
                                    <div className="d-flex align-items-end">
                                        <Field
                                            className="mb-0 flex-grow-1"
                                            label={getLangText('nameLabel')}
                                            {...getFieldProps('name')}
                                            setValue={setValue}
                                        />
                                        {(+userData.id === TEST_ACCOUNT_SHOP_ID || +userData.id === BUTIK_MADAME_SHOP_ID) &&
                                            <Field
                                                className="mb-0 flex-grow-1"
                                                label="Identyfikator z Fakturowni"
                                                {...getFieldProps('fakturownia_id')}
                                                setValue={setValue}
                                            />}
                                        <button
                                            style={{height: 45}}
                                            onClick={onSave}
                                            type="button"
                                            className={`ml-3 button primary`}
                                        >
                                            {getLangText('saveButton')}
                                        </button>
                                    </div>

                                </div>
                            </section>
                            <hr/>
                            <button
                                type="button"
                                className="button border-button big-size left-icon tooltip-parent"
                                onClick={showModal}
                            >
                                <i className="icon-bin"/>
                                {getLangText('removeManufacturerLabel')}
                            </button>
                        </div>
                    </div>
                    {modalVisible && <ConfirmModal
                        dialogClassName="w-380"
                        title={getLangText('wantToManufacturerLabel')}
                        SubTitle={() => <span dangerouslySetInnerHTML={{
                            __html: getLangText({
                                key: 'askForRemoveManufacturerWithNameLabel', data: [values.name]
                            })
                        }}/>}
                        saveButton={getLangText('removeButton')}
                        show={modalVisible}
                        isWaiting={false}
                        onSubmit={deleteWithoutAsk}
                        hide={hideModal}
                        saveButtonClass="danger"
                    />}
                </div>
            </div>
        </>)
};

export default ManufacturerForm;



