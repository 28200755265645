import React from 'react';
import EditProductsForm from "../editProducts/Form";
import OpenCartProducts from "./openCart/OpenCart.jsx";
import NormalProducts from "./Normal";
import useLang from "../../../../../src_shared/hooks/useLang";

const Products = ({values, getTotalPrice, inClientsOrders = false, editable = true}) => {
    const {getLangText} = useLang();

    return (
        <>
            {!inClientsOrders &&
                <div className="section-header d-lg-flex d-none">
                    <h2 className="font-bold">{getLangText('asideProductsLabel')}</h2>
                    {editable &&
                        <EditProductsForm values={values}/>
                    }
                </div>
            }
            {!values.products?.length ?
                <div className="form-info-box align-items-center w-100 mt-3 small-line-height">
                    <div>
                        <i className="icon-info-c"/>
                    </div>
                    <div>
                        <div className="title">{getLangText('productsListNoProductsInOrderLabel')}</div>
                    </div>
                </div> :
                values.is_open_cart === '1' ?
                    <OpenCartProducts
                        editable={editable}
                        inClientsOrders={inClientsOrders}
                        getTotalPrice={getTotalPrice}
                        values={values}
                    /> :
                    <NormalProducts
                        editable={editable}
                        inClientsOrders={inClientsOrders}
                        getTotalPrice={getTotalPrice}
                        values={values}
                    />
            }
        </>
    );
};

export default Products;
