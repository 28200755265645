import React, {useState} from 'react';
import Modal from "react-bootstrap/Modal";

const VideoPreview = ({show, hide, src, videoWrapperStyles = {}}) => {

    return (
        <Modal
            dialogClassName="default-modal preview-img-modal full-height-mobile preview-video-modal"
            show={show}
            onHide={hide}
            contentClassName="bg-transparent justify-center"
        >
            <div
                className={`relative p-0 rounded-[12px]`}>
                <button
                    onClick={hide}
                    type="button"
                    className="opacity-0 animate-fadeIn delay-700 fixed w-[50px] h-[50px] bg-[#f9f9f9] top-4 right-4 text-[12px] rounded-full text-[#717171]"
                >
                    <i className="icon-cross"/>
                </button>
                <div
                    style={videoWrapperStyles}
                    className="selmo-video-wrapper">
                    <iframe
                        frameBorder="0" allowFullScreen="" scrolling="no"
                        allow="autoplay;fullscreen"
                        src={src || show}
                        style={{
                            position: 'absolute',
                            height: '100%',
                            width: '100%',
                            left: '0px',
                            top: '0px',
                        }}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default VideoPreview;
