import React from 'react';
import {updateFieldInCustomer, updateFieldInLiveDetails, updateFieldInProduct} from "../../LiveDetailsActions.js";
import NumberInput from "../../../../../shared/components/form/NumberInput.jsx";
import {batch, useDispatch, useSelector} from "react-redux";
import ProductSearchInput from "../../../../shared/productSearch/ProductSearchInput.jsx";
import {services} from "../../../../../../RestServices.js";
import useLang from "../../../../../../../src_shared/hooks/useLang";

export const preparedProductObject = (item) => ({
	id: item.id,
	name: item.name,
	code: item.selmo_code,
	price: item.price,
	photo: item.photo,
	sizes: item.sizes,
	squ: item.squ,
	sold_qty: item.sold_qty,
})

const StorageProductRow = () => {
	const dispatch = useDispatch();
	const {product, customers, liveInfo} = useSelector((state) => state.lives.details)
	const {getLangText} = useLang();

	const onNameChange = (e) => {
		batch(() => {
			dispatch(updateFieldInProduct('name', e.target.value))
			dispatch(updateFieldInProduct('hasError', false))
		});
	}

	const onProductPriceChange = (e) => {
		dispatch(updateFieldInProduct('price', e.target.value))
		dispatch(updateFieldInProduct('hasPriceError', false))
	}

	const removeProduct = () => {
		dispatch(updateFieldInLiveDetails('product', {
			id: null,
			name: '',
			code: '',
			price: '',
			photo: null,
			sizes: [],
			squ: null,
		}))
		customers.forEach((i) => dispatch(updateFieldInCustomer('variant_id', null, i.id)))
	}

	const onHintSelect = (item) => {
		dispatch(updateFieldInLiveDetails('product', preparedProductObject(item)))
		dispatch(updateFieldInLiveDetails('storageProducts', []));
	}

	return (
		<>
			<ProductSearchInput
				isSelectedProduct={product.id}
				product={product}
				className={`form-group ${product.hasError && !product.name ? 'error-group' : ''} ${product.id ? 'storage-product' : ''}`}
				onHintSelect={onHintSelect}
				onNameChange={onNameChange}
				removeProduct={removeProduct}
				inputValue={product.name}
				disabled={product.id}
				tabIndex="1"
				label={getLangText('nameLabel')}
				rest={`${services.API_STORAGE_PRODUCTS}/${liveInfo.id}`}
			/>
			<div>
				<div className="form-group">
					<label className="control-label">{getLangText('barCodeLabel')}</label>
					<input
						onChange={(e) => dispatch(updateFieldInProduct('code', e.target.value))}
						type="text"
						value={product.code}
						className="form-control"
						name="code"
						required
						tabIndex="1"
						disabled={product.id}
					/>
				</div>
			</div>
			<div>
				<div className={`form-group ${product.hasPriceError ? 'error-group' : ''}`}>
					<label className="control-label">{getLangText('priceLabel')}</label>
					<NumberInput
						onChange={onProductPriceChange}
						name="price"
						value={product.price}
						min={0}
						disabled={product.id}
					/>
				</div>
			</div>
		</>
	);
};

export default StorageProductRow;
