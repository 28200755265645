export const addOrderModalToggleVisibility = (toggle, backUrl = false) => {
    return {
        type: 'ADD_ORDER_MODAL_TOGGLE_VISIBILITY',
        toggle,
        backUrl,
    };
}

export const getAddOrdersRequest = () => {
    return {
        type: 'GET_ADD_ORDERS_REQUEST'
    };
}

export const getAddOrdersError = () => {
    return {
        type: 'GET_ADD_ORDERS_ERROR',
    };
}

const showSuccessBox = () => {
    return {
        type: 'SHOW_SUCCESS_BOX',
    };
}

export const hideSuccessBox = () => {
    return {
        type: 'HIDE_SUCCESS_BOX',
    };
}

const showSuccessRemovedBox = () => {
    return {
        type: 'SHOW_SUCCESS_REMOVED_BOX',
    };
}

export const hideSuccessRemovedBox = () => {
    return {
        type: 'HIDE_SUCCESS_REMOVED_BOX',
    };
}

export const addOrderModalReset = () => {
    return {
        type: 'ADD_ORDER_MODAL_RESET',
    };
}

export const getOrderAction = (orderId) => {
    return {
        type: 'GET_ORDER',
        orderId,
    };
}

export const showSuccessInfo = () => {
    return (dispatch) => {
        dispatch(showSuccessBox())
        setTimeout(() => {
            dispatch(hideSuccessBox())
        }, 2500)
    }
}

export const showSuccessRemovedInfo = () => {
    return (dispatch) => {
        dispatch(showSuccessRemovedBox())
        setTimeout(() => {
            dispatch(hideSuccessRemovedBox())
        }, 2500)
    }
}
