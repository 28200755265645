import React, {useEffect} from 'react';
import AsyncSearchSelectField
    from "../../../../../src_shared/components/form/selectField/asyncSearchSelectField/AsyncSearchSelectField";
import {services} from "../../../../RestServices";
import useLang from "../../../../../src_shared/hooks/useLang";
import {showAlert} from "../../../../app/shared/components/alert/AlertActions";
import {useDispatch} from "react-redux";
import Loader from "../../../../app/shared/Loader";
import useFormLocal from "../../../../../src_shared/hooks/useFormLocal";
import Model from "../../../../../src_shared/modules/model/Model";

const SelectCountry = ({fetchData, countryValues}) => {
    const {getLangText} = useLang();
    const dispatch = useDispatch();

    const formatOptionLabel = (option) => (
        <div style={{display: "flex"}}>
            <div className="mr-1">{option.flag}</div>
            <div>{option.name}</div>
        </div>
    );

    const [{
        onSave,
        isWaiting,
        getFieldProps,
        values,
        updateValues
    }] = useFormLocal({
        rest: services.API_SETTINGS_STRIPE_COUNTRY,
        initialAutoLoad: false,
        model: new Model(),
        afterSubmit: () => {
            dispatch(showAlert())
            fetchData();
        },
    });

    useEffect(() => {
        if (countryValues) {
            updateValues(countryValues)
        }
    }, [countryValues])

    return (
        <div className="step-body border-0 mb-0">
            <form
                onSubmit={onSave}
                id="stripe-country-form"
            >
                <AsyncSearchSelectField
                    id="custom-dropdown-default"
                    {...getFieldProps('country')}
                    label={getLangText('selectCountryLabel')}
                    rest={services.API_SETTINGS_SHIPPING_COUNTRIES}
                    className="default-size big-size"
                    formatOptionLabel={formatOptionLabel}
                    defaultValue={{
                        id: values.country,
                        name: values.country_name
                    }}
                    placeholder={getLangText('countryLabel')}
                    searchOnFocus
                    floatedLabel
                />
                <div className="text-right">
                    <button
                        type="submit"
                        className={`button primary small-size ${isWaiting ? 'loading-button' : ''}`}
                    >
                        {isWaiting && <Loader isLoading/>}
                        {getLangText('saveButton')}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default SelectCountry;