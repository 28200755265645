import Model from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

class SettingsMobilePayModel extends Model {
	getModel() {
		return {
			client_id: '',
			client_secret: '',
			subscription_key: '',
			merchant_serial_number: '',
			active: '0',
		};
	}

	getValidators() {
		return {
			client_id: ValidationError.notEmpty,
			client_secret: ValidationError.notEmpty,
			subscription_key: ValidationError.notEmpty,
			merchant_serial_number: ValidationError.notEmpty,
			active: ValidationError.skip,
		};
	}
}

export default new SettingsMobilePayModel();
