import React from 'react';
import {Link, useLocation, useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import useLang from "../../../../src_shared/hooks/useLang";
import {RouterPaths} from "../../../routes/RouterPath";
import useDataApi from "../../../../src_shared/hooks/useDataApi";
import {services} from "../../../RestServices";
import Loader from "../../shared/Loader";
import AssignProductsModal from "./assignProducts/Form";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {getStateRoot} from "./assignProducts/formReducer";
import AssignProductsModalActions from "./assignProducts/FormActions";

export const CategoryContext = React.createContext({
	categoryInfo: {},
	loadCategoryInfo: () => {
	},
	isCategoryInfoLoading: false
});

const CategoryWrapper = ({
							 children,
							 helmetTitle,
							 isWaiting,
						 }) => {

	const {pathname} = useLocation();
	const {id} = useParams();
	const {getLangText} = useLang();
	const dispatch = useDispatch();
	const showAssignProductsModal = () => dispatch(AssignProductsModalActions.showModal(id));

	const {modalVisible} = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));

	const [{data, isLoading, fetchData}] = useDataApi(`${services.API_CATEGORY_INFO}/${id}`, {})

	const providerValue = {
		categoryInfo: data,
		loadCategoryInfo: fetchData,
		isCategoryInfoLoading: isLoading,
	}

	return (
		<div className={`settings-page sub-settings-page loader-parent list-with-tabs separated-box loader-parent`}>
			<Loader isLoading={isWaiting || isLoading}/>
			<Helmet>
				<meta charSet="utf-8"/>
				<title>{`${data?.name} - ${getLangText('categoryEditLabel')} - Selmo`}</title>
			</Helmet>
			<div className="header-bar breadcrumbs-in-react-app">
				<ul className="breadcrumbs">
					<li>
						<Link to="/magazyn/kategorie">{getLangText('categoriesListLabel')}</Link>
					</li>
					{data.parent_id ?
						<>
							<li>
								<Link to={`${RouterPaths.CategoriesList}/${data.parent_id}/`}>{data.parent_name}</Link>
							</li>
							<li className="active">
								<div>{data.name}</div>
							</li>
						</> :
						<li className="active">
							<div>{data.name}</div>
						</li>
					}
				</ul>
			</div>
			<div className="tabs-header">
				<ul className="breadcrumbs">
					{data.parent_id ?
						<>
							<li>
								<Link to={`${RouterPaths.CategoriesList}/${data.parent_id}/`}>{data.parent_name}</Link>
							</li>
							<li className="active">
								<div>{data.name}</div>
							</li>
						</> :
						<li className="active">
							<div>{data.name}</div>
						</li>
					}
				</ul>
				<div className="d-flex align-items-center mr-sm-1">
					<div className="tabs-wrapper flex-grow-1">
						<Link
							to={`${RouterPaths.CategoriesList}/${id}/`}
							className={pathname === `${RouterPaths.CategoriesList}/${id}/edytuj` ? 'active' : ''}
						>
							{getLangText('asideSettingsLabel')}
						</Link>
						<Link
							to={`${RouterPaths.CategoriesList}/${id}/produkty`}
							className={pathname.includes(`${RouterPaths.CategoriesList}/${id}/produkty`) ? 'active' : ''}
						>
							{getLangText('asideProductsLabel')}
						</Link>
						<Link
							to={`${RouterPaths.CategoriesList}/${id}/seo`}
							className={pathname.includes(`${RouterPaths.CategoriesList}/${id}/seo`) ? 'active' : ''}
						>
							SEO
						</Link>
					</div>
					{pathname === `${RouterPaths.CategoriesList}/${id}/produkty` &&
						<button
							type="button"
							onClick={showAssignProductsModal}
							className="button primary small-size ml-sm-auto mr-sm-3 position-absolute absolute-btn px-3 d-sm-block d-none"
						>
							{getLangText('assignProductsLabel')}
						</button>
					}
				</div>
			</div>
			<CategoryContext.Provider value={providerValue}>
				{children}
			</CategoryContext.Provider>
			{modalVisible &&
				<AssignProductsModal
					categoryName={data.name}
					modalVisible={modalVisible}
				/>
			}
		</div>
	);
};

export default CategoryWrapper;
