import React from 'react';
import Modal from "react-bootstrap/Modal";
import useLang from "../../../../../../src_shared/hooks/useLang";
import {useDispatch, useSelector} from "react-redux";
import BasketDeliveryMethodsFormActions from "../../../../basket/deliveryMethods/FormActions";
import {createSelector} from "reselect";
import {getStateRoot as getDeliveryMethodsStateRoot} from "../../../../basket/deliveryMethods/formReducer";
import {useHistory} from "react-router-dom";
import Loader from "../../../Loader";
import {ORDER_PAYMENT_BLIK, ORDER_PAYMENT_BLUEMEDIA} from "../../../enums/PaymentStatuses";
import {TEST_ACCOUNT_SHOP_ID, TIM_SHOP_ID} from "../../../../../../src_shared/enums/TrustedShopsIds";

const AutomaticTransfersModal = ({modalVisible, hideModal, shopId}) => {
    const {getLangText} = useLang();
    const dispatch = useDispatch();

    const {data, waitingForSubmit} = useSelector(createSelector(getDeliveryMethodsStateRoot, (stateRoot) => stateRoot));
    const history = useHistory();

    const onSave = () => dispatch(BasketDeliveryMethodsFormActions.submitEditForm(data, history, false));

    const setValue = (payment) => dispatch(BasketDeliveryMethodsFormActions.submitEditForm({
        ...data,
        payment
    }, history, false))

    return (
        <Modal
            dialogClassName="default-modal center-mobile"
            show={modalVisible}
            onHide={hideModal}
        >
            <Modal.Header>
                <Modal.Title>
                    {getLangText('paymentMethodLabel')}
                </Modal.Title>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            <Modal.Body className="pb-0 relative">
                <div
                    style={{display: waitingForSubmit ? 'block' : 'none'}}
                    className="absolute top-0 right-0 bottom-0 left-0 flex items-center justify-between">
                    <div
                        style={{background: '#ffffffdb'}}
                        className={`loader-container`}
                    >
                        <div className="circle-loader">
                            <span className="circle"/>
                        </div>
                    </div>
                </div>
                <div style={{pointerEvents: waitingForSubmit ? 'none' : 'auto'}}>
                    <div>
                        <div className="font-bold mb-2">{getLangText('payByTransfer')}</div>
                        <div
                            className="form-info-box d-block red small-line-height w-100 border border-[#ff2c2c] p-3">
                            <div className="flex items-center mb-1">
                                <div>
                                    <i className="icon-info-c"/>
                                </div>
                                <div className="text-sm text-[#981B1B]"
                                     dangerouslySetInnerHTML={{__html: getLangText('rememberToWriteTheData')}}/>
                            </div>
                            <ul className="text-sm font-bold text-[#981B1B] list-disc ml-[23px] space-y-0.5">
                                <li>
                                    {getLangText('transferTitleLabel')}
                                </li>
                                <li>
                                    {getLangText('accountNumberLabel')}
                                </li>
                                <li>
                                    {getLangText('amount')}
                                </li>
                            </ul>
                            <div
                                className="text-sm text-[#981B1B] mt-1">{getLangText('foundsMayNotBeTransferredToStoreAccount')}</div>
                        </div>
                    </div>
                    {(+shopId === TIM_SHOP_ID || +shopId === TEST_ACCOUNT_SHOP_ID) &&
                        <div className="font-bold mt-3 mb-2">{getLangText('wantPayNowOrLater')}</div>
                    }
                    <div className={`grid sm:grid-cols-2 gap-3 ${(+shopId === TIM_SHOP_ID || +shopId === TEST_ACCOUNT_SHOP_ID) ? '' : 'mt-3'}`}>
                        <button
                            className={`button primary w-full sm:order-2`}
                            onClick={onSave}
                            style={{minHeight: 54, fontSize: 14, borderRadius: 8}}
                        >
                            {getLangText('goAheadAndPay')}
                        </button>
                        {(+shopId === TIM_SHOP_ID || +shopId === TEST_ACCOUNT_SHOP_ID) &&
                            <button
                                className={`button border-button w-full`}
                                onClick={hideModal}
                                style={{minHeight: 54, fontSize: 14, borderRadius: 8}}
                            >
                                {getLangText('wantToAskLater')}
                            </button>
                        }
                    </div>
                    <div className="p-3 py-2 -mx-[25px] bg-[#f2f2f2] mt-4 rounded-b-[8px] border-t border-[#E5E7EB]">
                        <div
                            className="font-bold capitalize text-sm">{getLangText('optionalLabel')}</div>
                        <div
                            className="text-desc text-sm font-medium mb-2">{getLangText('selectFasterAndMoreSafetyPayment')}</div>
                        <div className="space-y-2">
                            <button
                                type="button"
                                className="border bg-[white] w-full flex items-center justify-between rounded-[5px] text-center text-sm px-3 py-[14px] font-bold"
                                onClick={() => setValue(ORDER_PAYMENT_BLIK)}
                            >
                                Blik
                                <figure className="text-center">
                                    <img
                                        className="m-auto"
                                        style={{height: '20px'}}
                                        src="/assets/images/payments/blik.svg"/>
                                </figure>
                            </button>
                            <button
                                className="border bg-[white] w-full flex items-center justify-between rounded-[5px] text-center text-sm px-3 py-[14px] font-bold"
                                type="button"
                                onClick={() => setValue(ORDER_PAYMENT_BLUEMEDIA)}
                            >
                                {getLangText('paymentMethodDefaultOnlineTransferLabel')}
                                <figure className="text-center">
                                    <img
                                        className="m-auto"
                                        style={{height: '20px'}}
                                        src="/assets/images/payments/bluemedia.svg"/>
                                </figure>
                            </button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default AutomaticTransfersModal;
