import ListActions, {getGETParamsUrl} from "../../../../src_shared/list/ListActions";
import {getStateRoot, prefix} from './listReducer';
import BoxesStatsListActions from "../list/ListActions";
import {services} from "../../../RestServices";
import api from "../../../../services/axios/axios";
import {TEST_ACCOUNT_SHOP_ID} from "../../../../src_shared/enums/TrustedShopsIds";
import {selmoAPI2Url, selmoUrl} from "../../../../src_shared/api/api";
import {batch} from "react-redux";
import SessionActions from "../../shared/session/SessionActions";

export class ChartsStatsListActions extends ListActions {
	getLoadParams(state) {
		return BoxesStatsListActions.getLoadParams(state);
	}

	loadData(id, signal) {
		return async (dispatch, getState) => {
			const {firstInit, userData} = getState().session;
			const {itemsPerPage, sortBy} = this.getStateRoot(getState());
			dispatch(this.waiting.startWaiting())
			if (firstInit) {
				dispatch(this.onFirstInit());
			}
			const params = this.getLoadParams(getState(), id)

			const restUrl = +id ?
				`/${id}${getGETParamsUrl(params)}` :
				getGETParamsUrl(params);
			try {
				const response = await api.get(`${selmoAPI2Url}/${this.restService}${restUrl}`, {signal})
				const {items, total, isFirstStepPage} = response.data;
				if (+total <= +itemsPerPage && this.defaultSortBy) {
					items.sort((a, b) => parseFloat(b[this.defaultSortBy]) - parseFloat(a[this.defaultSortBy]))
				}
				if (!sortBy) {
					// dispatch(this.table.updateSort(this.defaultSortBy, 'desc'));
				}

				batch(() => {
					dispatch(this.table.updateAllItems(items))
					dispatch(this.setFirstStepPage(isFirstStepPage))
					dispatch(this.pagination.updateTotalItems(total))
				})

			} catch (e) {
				dispatch(this.table.getListError(e))
				console.error(e)
			} finally {
				if (firstInit) {
					dispatch(SessionActions.setFirstInit());
				}
				dispatch(this.waiting.stopWaiting())
			}
		}
	}

}

export const getInstance = () => new ChartsStatsListActions({
	getStateRoot,
	restService: services.API_STATS_INFO,
	prefix,
});

export default getInstance();
