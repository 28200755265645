import React, {useCallback, useEffect} from 'react';
import TextArea from "../../../../../../../src_shared/components/form/textarea/TextArea";
import useFormLocal from "../../../../../../../src_shared/hooks/useFormLocal";
import Model from "../../../../../../../src_shared/modules/model/Model";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import {services} from "../../../../../../RestServices";
import api from "../../../../../../../services/axios/axios";
import {selmoUrl} from "../../../../../../../src_shared/api/api";
import {debounce} from "lodash";
import {showAlert} from "../../../../../shared/components/alert/AlertActions";
import {useParams} from "react-router-dom";

const Note = ({data}) => {

    const {getLangText} = useLang();
    const {id} = useParams();

    const [{getFieldProps, setValue}] = useFormLocal({
        model: new Model(),
        initialAutoLoad: false,
    });

    const onSave = async (value) => {
        try {
           await api.post(`/${services.API_WAREHOUSE_ORDER_NOTE}/${id}`, {note: value})
        } catch (error) {

        }
    };

    const debouncedSave = useCallback(
        debounce((value) => onSave(value), 600),
        []
    );


    const onChange = (field, value) => {
        setValue('note', value)
        debouncedSave(value)
    }

    useEffect(() => {
        if (!!data.note) {
            setValue('note', data.note)
        }
    }, [data.note])

    return (
        <TextArea
            {...getFieldProps('note')}
            setValue={onChange}
            label={getLangText('noteLabel')}
            placeholder={getLangText('textNoteLabel')}
            rows={3}
        />
    );
};

export default Note;