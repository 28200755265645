import React, {useState} from 'react';
import useLang from "../../../../src_shared/hooks/useLang";
import {Menu} from "@headlessui/react";
import Dropdown from "../../../../src_shared/components/ui/Dropdown/Dropdown";
import {services} from "../../../../components/RestServices";
import {batch, useDispatch} from "react-redux";
import api from "../../../../services/axios/axios";
import {selmoUrl} from "../../../api/api";
import SessionActions from "../../../../components/app/shared/session/SessionActions";
import {showAlert} from "../../../../components/admin/components/app/shared/SharedActions";
import useRemoveData from "../../../hooks/useRemoveData";
import ConfirmModal from "../../../../components/app/live/details/default/summary/export/ConfirmModal";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../components/routes/RouterPath";

const HideDropdown = ({page, setIsVisible}) => {
    const {getLangText} = useLang();
    const dispatch = useDispatch();
    const [waitingForHide, setWaitingForHide] = useState(false);

    const sessionHide = () => {
        setIsVisible(false);
        sessionStorage.setItem(`${page}TutorialBoxVisible`, '0')
    }

    const hideForever = async () => {
        setWaitingForHide(true);
        try {
            await api.post(`${selmoUrl}/${services.API_HIDE_TUTORIAL_VIDEO}`, {
                [`hidden_${page}`]: '1'
            })
            batch(() => {
                dispatch(showAlert());
                dispatch(SessionActions.updateTutorialBoxVisibility(`hidden_${page}`, true))
            })
        } catch (e) {
            console.error('Cannot hide box')
        } finally {
            setWaitingForHide(false);
        }
    }

    const [{
        openModal,
        hideModal: hideRemoveModal,
        confirmModalVisible,
    }] = useRemoveData();


    return (
        <>
            <Dropdown dropdownContentClassName="min-w-[160px]">
                <Menu.Item>
                    <Link
                        to={RouterPaths.Help}
                        className="text-left p-2.5 min-h-[36px] w-full hover:bg-gray-100 rounded-sm px-2 py-[6px] transition-all flex items-center text-[#4B5563] text-xs hover:text-[#1F2937] font-semibold hover:no-underline"
                    >
                        {getLangText('otherTutorialLabel')}
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <button
                        onClick={sessionHide}
                        type="button"
                        className="text-left p-2.5 min-h-[36px] w-full hover:bg-gray-100 rounded-sm px-2 py-[6px] transition-all flex items-center text-[#4B5563] text-xs hover:text-[#1F2937] font-semibold hover:no-underline"
                    >
                        {getLangText('hideLabel')}
                    </button>
                </Menu.Item>
                <Menu.Item>
                    <button
                        onClick={openModal}
                        type="button"
                        className="text-left p-2.5 min-h-[36px] w-full hover:bg-gray-100 rounded-sm px-2 py-[6px] transition-all flex items-center text-[#4B5563] text-xs hover:text-[#1F2937] font-semibold hover:no-underline"
                    >
                        {getLangText('hideForeverLabel')}
                    </button>
                </Menu.Item>
            </Dropdown>
            {confirmModalVisible &&
                <ConfirmModal
                    dialogClassName="w-380"
                    SubTitle={() => <div>{getLangText('hideVideoTutorialSubLabel')}</div>}
                    title={getLangText('hideVideoTutorialLabel')}
                    saveButton={getLangText('hideLabel')}
                    show={confirmModalVisible}
                    isWaiting={waitingForHide}
                    onSubmit={hideForever}
                    hide={hideRemoveModal}
                    saveButtonClass="danger"
                >
                    <div className="form-info-box w-100 small-line-height border border-[#D7EAFE] mb-3">
                        <div>
                            <i className="icon-info-c"/>
                        </div>
                        <div>
                            <div className="title">
                                <span className="text-sm">{getLangText('whereCanIFindTutorialsLabel')}</span>
                            </div>
                            <div className="text-desc text-sm font-medium">
                                {getLangText('whereCanIFindTutorialsSubLabel')}&nbsp;
                                <Link className="text-inherit underline hover:text-[black] transition-all" to={RouterPaths.KnowledgeBase}>{getLangText('knowledgeBaseVariantLabel')}</Link>
                            </div>
                        </div>
                    </div>
                </ConfirmModal>
            }
        </>
    );
};

export default HideDropdown;
