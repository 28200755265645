import createReducer from "../../../../shared/helpers/createReducer";
import Composition from "../../../../shared/helpers/Composition";
import ListReducer from "../../../../../../../../src_shared/list/ListReducer";
import {getInitSalesDashboardState} from "../../registration/list/listReducer";
export const getStateRoot = (state) => state.admin.sales.dashboard.leads;
export const prefix = 'ADMIN_SALES_LEADS_';

const getReduceMap = () =>  new Composition(
	ListReducer.getReduceMap(),
)

export const getInstance = () => createReducer(
	getInitSalesDashboardState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

