import React from 'react';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import useLang from "../../../../../../src_shared/hooks/useLang";

const DropdownItem = ({isMoreThanMax, type, buttonTitle, setModalOpen}) => {
    const {getLangText} = useLang();
    const maxSelected = 25
    const tooltipText = getLangText({key: 'youCanSelectMaxOrders', data: [maxSelected]});


    return (
        isMoreThanMax ?
                <OverlayTrigger
                    placement='top'
                    overlay={
                        <Tooltip id="generate-label-tooltip">
                            <span dangerouslySetInnerHTML={{__html: tooltipText}}/>
                        </Tooltip>
                    }
                >
                    <button
                        type="button"
                        className="custom-option disabled"
                    >
                        {getLangText(buttonTitle)}
                    </button>
                </OverlayTrigger>
                :
                <button
                    type="button"
                    className="custom-option"
                    onClick={() => setModalOpen(type)}
                    disabled={isMoreThanMax}
                >
                    {getLangText(buttonTitle)}
                </button>
    );
};

export default DropdownItem;
