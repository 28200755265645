import React, {useEffect, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import Loader from "../../../../shared/Loader";
import TextArea from "../../../../../../src_shared/components/form/textarea/TextArea";
import ValidationError from "../../../../../../src_shared/form/validation/ValidationError";
import SwitchField from "../../../../../../src_shared/components/form/switchField/SwitchField";
import useLang from "../../../../../../src_shared/hooks/useLang";
import axios from "axios";
import {selmoUrl} from "../../../../../../src_shared/api/api";
import {services} from "../../../../../RestServices";
import {useDispatch} from "react-redux";
import ListActions from "../../reserveList/ListActions";
import {showAlert} from "../../../../shared/components/alert/AlertActions";
import api from "../../../../../../services/axios/axios";

const defaultValues = {
	message: '',
	with_link: true,
};

const SendSummaryModal = ({modalVisible, hide, summaryMessageData}) => {
	const {getLangText} = useLang()

	const [values, setValues] = useState(defaultValues);
	const [waiting, setWaiting] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		setValues({...values, message: summaryMessageData.message})
	}, [summaryMessageData.message])

	const onSave = async () => {
		setWaiting(true);
		try {
			await api.post(`${selmoUrl}/${services.API_STORAGE_SEND_BATCH_RESERVE_LIST_MESSAGE}`, {
				selected_items: summaryMessageData?.selectedItems?.map((i) => i.id),
				with_link: values.with_link,
				message: values.message,
			})
			dispatch(ListActions.setWaitingForSend(true));
			dispatch(showAlert('yuhuLabel', 'summariesAreBeingSentLabel'))

			hide();
		} catch (e) {
			dispatch(showAlert(e.response.data.message))
		} finally {
			setWaiting(false);
		}
	}

	return (
		<Modal
			dialogClassName={`default-modal small-modal`}
			show={modalVisible}
			onHide={hide}
		>
			<Modal.Header className="with-border">
				<Modal.Title>
					{getLangText('sendSummariesLabel')}
					<div className="sub-title">
						{getLangText('sendToAllOrderSummariesLabel')}
					</div>
				</Modal.Title>
				<button
					onClick={hide}
					type="button"
					className="button"
				>
					<i className="icon-cross"/>
				</button>
			</Modal.Header>
			<Modal.Body>
				<TextArea
					value={values.message}
					showLabel={false}
					setValue={(field, value) => setValues({...values, message: value})}
					label={getLangText('messageToClientLabel')}
					validateErrors={false}
					getError={() => ValidationError.notEmpty(values.message)}
				/>
				<SwitchField
					className="switch-form inline-label align-items-start"
					label={getLangText('orderLinkLabel')}
					subLabel={getLangText('orderLinkTooltipLabel')}
					setValue={(field, value) => setValues({...values, with_link: value})}
					value={values.with_link}
					validateErrors={false}
					getError={() => ValidationError.skip(values.with_link)}

				/>
			</Modal.Body>
			<Modal.Footer>
				<button
					onClick={hide}
					type="button"
					className="button border-button"
				>
					{getLangText('cancelButton')}
				</button>
				<button
					onClick={onSave}
					type="button"
					className={`button primary px-0 ${waiting ? 'loading-button' : ''}`}
				>
					{waiting && <Loader isLoading/>}
					{getLangText('sendButton')}
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default SendSummaryModal;
