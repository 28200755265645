import fr from "timeago.js/lib/lang/fr";
import pl from "timeago.js/lib/lang/pl";
import it from "timeago.js/lib/lang/it";
import de from "timeago.js/lib/lang/de";
import pt from "timeago.js/lib/lang/pt_BR";
import * as React from 'react';
import TimeAgo from 'timeago-react';
import * as timeago from 'timeago.js';

timeago.register('pl', pl);
timeago.register('fr', fr);
timeago.register('it', it);
timeago.register('pt', pt);
timeago.register('de', de);
timeago.register('sk', pl);

export default TimeAgo;
