import React from 'react';
import Modal from "react-bootstrap/Modal";
import Loader from "../../messenger/loader/Loader";
import {useDispatch, useSelector} from "react-redux";
import FormActions from "./FormActions";
import {createSelector} from "reselect";
import {getStateRoot} from "./formReducer";
import OrderProductsForm from "../../shared/components/form/orderProductsForm/OrderProductsForm";
import Field from "../../shared/components/form/field/Field";
import useEnterSubmitForm from "../../../../src_shared/hooks/useEnterFormSubmit";
import useLang from "../../../../src_shared/hooks/useLang";
import ClientInput from "./components/ClientInput";

const AddOrderModal = ({saveOrder}) => {

    const dispatch = useDispatch();
    const {modalVisible, waiting, data} = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));
    const {isOpen} = useSelector((state) => state.shared.selectSizesModal)
    const {getLangText} = useLang();

    const updateField = (field, value) => dispatch(FormActions.updateField(field, value))
    useEnterSubmitForm(saveOrder, data);

    const hideModal = () => dispatch(FormActions.hideModal());

    return (
        <Modal
            show={modalVisible}
            className="add-order-modal"
            dialogClassName="default-modal"
            backdropClassName={`add-order-modal-backdrop ${isOpen ? 'with-extra-modal' : ''}`}
            onHide={hideModal}
        >
            <Modal.Header className="with-border">
                <Modal.Title>{getLangText('addOrderLabel')}</Modal.Title>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            <Modal.Body className="add-order-modal-body">
                <section>
                    <div className="subtitle">{getLangText('customerDataLabel')}</div>
                    <div className="flex space-x-3">
                        <div className="grow">
                            <ClientInput
                                name="name"
                                updateField={updateField}
                                customer={data}
                                autoFocus={true}
                                customerId={data.id}
                                hasCustomerError={data.activeValidation}
                                showErrorAlert
                                label={getLangText('firstNameLabel')}
                            />
                        </div>
                        {!data.shop_client_id &&
                            <div className="grow">
                                <Field
                                    label={getLangText('lastNameLabel')}
                                    name="last_name"
                                    value={data.last_name}
                                    setValue={updateField}
                                    activeValidation={data.activeValidation}
                                    required
                                />
                            </div>
                        }
                    </div>
                </section>
                <div className="subtitle">{getLangText('asideProductsLabel')}</div>
                <OrderProductsForm
                    actions={FormActions}
                    getStateRoot={getStateRoot}
                    nameInputAutoFocus={false}
                />
            </Modal.Body>
            <Modal.Footer>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button border-button"
                >
                    {getLangText('cancelButton')}
                </button>
                <button
                    onClick={saveOrder}
                    type="button"
                    className="button primary"
                >
                    {waiting && <Loader/>}
                    {getLangText('saveButton')}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddOrderModal;
