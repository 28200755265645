import React, {useState} from 'react';
import ListActions from "./ListActions";
import ProductsSortableTree from "../../components/ProductsSortableTree";
import useList from "../../../../../../src_shared/list/useList";
import {getStateRoot} from "./listReducer";
import FiltersActions from "../filters/FiltersActions";
import SingleSelectFiltersStatus
	from "../../../../../../src_shared/components/filters/singleSelectFiltersStatus/SingleSelectFiltersStatus";
import {getStateRoot as getFiltersStateRoot} from "../filters/filtersReducer";
import {PRODUCTS_ACTIVE_TYPES} from "../../../../shared/enums/ProductsActiveType";
import SearchInput from "../../../../shared/components/form/SearchInput";
import useWindowDimensions from "../../../../messenger/hooks/useWindowDimensions/useWindowDimensions";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import useLang from "../../../../../../src_shared/hooks/useLang";
import AssignProductsModalActions from "../../assignProducts/FormActions";

const CategoriesProductsList = () => {
	const dispatch = useDispatch();
	const {getLangText} = useLang();

	const [isFiltersVisible, setFiltersVisible] = useState(false);
	const {width} = useWindowDimensions();
	const {id} = useParams();
	const updateAllItemsOnServer = (updatedItems) => dispatch(ListActions.updateItemsOnServer(updatedItems, id))
	const showAssignProductsModal = () => dispatch(AssignProductsModalActions.showModal(id));

	const {
		pagination: {
			totalItems,
		},
		list: {items, isWaiting},
		search,
		sort: {updateSortOptions, getSortClass},
	} = useList({
		ListActions,
		getStateRoot,
		FiltersActions,
		hasParentId: true,
		params: {
			id,
		}
	});

	return (
			<div className="table-list-wrapper">
				<div className="default-shadow-box pb-0">
					<div className="table-header">
						<div className="top-part">
							<h1 className="title">
								{getLangText('asideProductsLabel')}
								{totalItems > 0 &&
									<div className="badge">{totalItems}</div>
								}
							</h1>
							<button
								type="button"
								onClick={showAssignProductsModal}
								className="button primary small-size ml-auto mr-2 px-3 d-sm-none"
							>
								{getLangText('assignProductsLabel')}
							</button>
							<button
								type="button"
								className='toggle-filters'
								onClick={() => setFiltersVisible(!isFiltersVisible)}
							>
								<i className='icon-filter'/>
							</button>
						</div>
						{(width > 767 || isFiltersVisible) &&
							<div className="filters-part mt-sm-3">
								<SingleSelectFiltersStatus
									ListActions={ListActions}
									FiltersActions={FiltersActions}
									getFiltersStateRoot={getFiltersStateRoot}
									options={PRODUCTS_ACTIVE_TYPES}
									name="activeType"
									defaultSelectedLabel={getLangText('activityEmojiLabel')}
									extraProps={{
										id,
									}}
								/>
								{/*<button className="button border-button small-size">*/}
								{/*	{getLangText('changeOrderLabel')}*/}
								{/*</button>*/}
								<SearchInput
									placeholder={getLangText('searchPlaceholder')}
									className="ml-sm-auto mr-0"
									search={search}
								/>
							</div>
						}
					</div>
					<ProductsSortableTree
						isWaiting={isWaiting}
						items={items}
						updateAllItemsOnServer={updateAllItemsOnServer}
						updateSortOptions={updateSortOptions}
						getSortClass={getSortClass}
						showAssignProductsModal={showAssignProductsModal}
					/>
				</div>
			</div>
	);
};

export default CategoriesProductsList;
