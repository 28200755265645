import React from 'react';
import Modal from "react-bootstrap/Modal";
import useLang from "../../../../../src_shared/hooks/useLang";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../routes/RouterPath";


const whyWorthItOptions = [
    {
        id: 0,
        title: 'saveMoneyModalLabel',
        desc: 'saveMoneyModalSubLabel',
    },
    {
        id: 1,
        title: 'createOrdersInConversationsLabel',
        desc: 'createOrdersInConversationsSubLabel',
    },
    {
        id: 2,
        title: 'onClickSummariesLabel',
        desc: 'onClickSummariesSubLabel',
    },
    {
        id: 3,
        title: 'sellByCodesLabel',
        desc: 'automateSalesLabel',
    },
]

const NewClientPromoModal = ({show, hide, minutes, seconds}) => {
    const {getLangText} = useLang();
    return (
        <Modal
            dialogClassName={`default-modal big-modal`}
            show={show}
            onHide={hide}
        >
            <Modal.Header className="px-sm-5">
                <button
                    onClick={hide}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            <Modal.Body className="pt-sm-0 pt-2 px-sm-5">
                <div className="flex items-center">
                    <div className="grow">
                        <div className="flex items-center">
                            <div className="text-[#5FA5FA] text-sm font-bold grow">
                                {`${getLangText('tryOfferLabel')}!`}
                            </div>
                            <div
                                className="sm:hidden rounded-[18px] bg-[rgba(52,210,153,0.08)] text-[#067857] text-xs font-bold py-0.5 px-1.5">
                                {getLangText('twentyPercentCheaperLabel')}
                            </div>
                        </div>
                        <div
                            className="text-[#101827] text-[26px] font-bold leading-[29px]">{getLangText('getCheaperAccessLabel')}</div>
                    </div>
                    <div
                        className="sm:block hidden rounded-[18px] bg-[rgba(52,210,153,0.08)] text-[#067857] text-sm font-bold p-1.5 px-2.5">
                        {getLangText('twentyPercentCheaperLabel')}
                    </div>
                </div>
                <div
                    className="border border-[#D7EAFE] bg-[#F6FCFF] sm:space-y-0 space-y-3 sm:justify-start justify-center rounded-md py-6 px-6 flex flex-wrap items-center mt-6 mb-8">
                    <div className="grow">
                        <div className="text-lg font-bold">{getLangText('discountEndsInLabel')}</div>
                        <div
                            className="text-sm text-[#6B7280] font-medium">{getLangText('twentyPercentForEveryPlansLabel')}</div>
                    </div>
                    <div className="flex items-center space-x-1 mr-2">
                        <div className="text-center w-[51px] h-[51px] p-1.5 text-[#101827] rounded-md bg-[#DBF3FF]">
                            <div className="text-xl font-bold">
                                {minutes}
                            </div>
                            <div className="uppercase text-[7px] font-bold leading-[8px] tracking-[1px]">
                                {getLangText('minutesLabel')}
                            </div>
                        </div>
                        <div>:</div>
                        <div className="text-center w-[51px] h-[51px] p-1.5 text-[#101827] rounded-md bg-[#DBF3FF]">
                            <div className="text-xl font-bold">
                                {seconds}
                            </div>
                            <div className="uppercase text-[7px] font-bold leading-[8px] tracking-[1px]">
                                {getLangText('secondsLabel')}
                            </div>
                        </div>
                    </div>
                    <Link
                        to={RouterPaths.Payments}
                        style={{fontSize: '14px'}}
                        className="button primary medium-size sm:w-auto w-full text-center"
                    >
                        {getLangText('tryOfferLabel')}
                    </Link>
                </div>
                <div className="mb-3">
                    <div className="font-bold text-sm text-[#6B7280]">{getLangText('pricingWhyWorthyTitle')}</div>
                    <div className="grid sm:grid-cols-2 gap-5 sm:gap-8 mt-3">
                        {whyWorthItOptions.map((option) => (
                            <div
                                className="flex"
                                key={option.id}
                            >
                                <div className="sm:mr-2 mr-1.5 mt-0.5">
                                    <img
                                        className="w-[18px] min-w-[18px]"
                                        src="/assets/images/svg/circle-check.svg"
                                        alt="check"
                                    />
                                </div>
                                <div>
                                    <div className="text-[#101827] font-bold text-sm">
                                        {getLangText(option.title)}
                                    </div>
                                    <div className="text-[#4B5563] text-sm">
                                        {getLangText(option.desc)}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="px-sm-5">
                <button
                    onClick={hide}
                    type="button"
                    className="button text-only mr-3"
                >
                    {getLangText('noThanksLabel')}
                </button>
                <Link
                    to={RouterPaths.Payments}
                    type="button"
                    style={{paddingLeft: '30px', paddingRight: '30px'}}
                    className="button border-button mr-0 small-size"
                >
                    {getLangText('tryOfferLabel')}
                </Link>
            </Modal.Footer>
        </Modal>
    );
};

export default NewClientPromoModal;
