import Model from "../../../src_shared/modules/model/Model";

class SelectProductModel extends Model {
    getModel() {
        return {
            selectedProduct: {},
            in_stock: false,
            active_collection_id: null,
            with_variants: true,
        };
    }

    buildDTO(data) {
        if (!!+data.active_collection_id) {
            return {
                product_id: data.selectedProduct.id,
                collection_id: data.active_collection_id,
                in_stock: data.in_stock,
                with_variants: data.with_variants,
                show_price: data.show_price,
                show_product_name: data.show_product_name,
            }
        }

        return {
            product_id: data.selectedProduct.id,
            in_stock: data.in_stock,
            with_variants: data.with_variants,
            show_price: data.show_price,
            show_product_name: data.show_product_name,
        }
    }
}

export default new SelectProductModel();
