import React from 'react';
import {NavLink} from "react-router-dom";
import useLang from "../../../../../src_shared/hooks/useLang";
import {useSelector} from "react-redux";
import {
    USER_PERMISSIONS_MESSENGER_MENU_VISIBILITY,
} from "../../enums/UserPermissionsTypes";
import UserPermissionsWrapper from "../../../../routes/UserPermissionsWrapper";

const MessengerLink = ({closeMobileMenu}) => {
    const {getLangText} = useLang();
    const {not_read_conversations} = useSelector((state) => state.shared.aside);

    return (
        <UserPermissionsWrapper allowedPermissions={[USER_PERMISSIONS_MESSENGER_MENU_VISIBILITY]}>
            <li>
                <NavLink
                    onClick={closeMobileMenu}
                    activeClassName="active"
                    to="/wiadomosci/"
                >
                    <i className="icon-messenger"/>
                    <div className="relative">
                        {getLangText('messagesLabel')}
                    </div>
                    {+not_read_conversations > 0 &&
                        <div
                            className="ml-auto bg-[#D7DAE0] text-[#4B5563] text-[10px] rounded-[3px] min-w-[22px] min-h-[18px] px-0.5 py-[1px] flex items-center justify-center font-bold mt-[1px]">
                            {not_read_conversations}
                        </div>
                    }
                </NavLink>
            </li>
        </UserPermissionsWrapper>
    );
};

export default MessengerLink;
