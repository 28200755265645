import {convertObjectProperties, Model} from "../../../src_shared/modules/model/Model";

class ExportModel extends Model {
	getModel() {
		return {
			email: '',
			bigFile: false,
		};
	}

	parseDTO(data) {
		return convertObjectProperties(
			data,
			{},
			{
				email: '',
			}
		);
	}
}

export default new ExportModel();
