export const ASSIGN_ORDER_TYPE_EXIST = 'ASSIGN_ORDER_TYPE_EXIST';
export const ASSIGN_ORDER_TYPE_NEW = 'ASSIGN_ORDER_TYPE_NEW';

const AssignOrderTypes = [
	{
		id: ASSIGN_ORDER_TYPE_EXIST,
		label: 'existingLabel',
	},
	{
		id: ASSIGN_ORDER_TYPE_NEW,
		label: 'newLabel',
	},
];

export default AssignOrderTypes;
