import React from 'react';
import TextArea from "../../../../../../../../src_shared/components/form/textarea/TextArea";
import Field from "../../../../../../../../src_shared/components/form/field/Field";
import useLang from "../../../../../../../../src_shared/hooks/useLang";

const MobileNotificationsForm = ({getFieldProps}) => {
    const {getLangText} = useLang();

    return (
        <div className="animate-[fadeIn_.3s_ease_forwards]">
            <div className="bg-[#EFFEF4] rounded-[5px] flex flex-col items-center justify-center p-4 mb-4">
                <img
                    className="w-[22px] min-w-[22px] mb-1"
                    src="/assets/images/svg/circle-check.svg"
                    alt="check"
                />
                <div className="text-[18px] font-bold text-[#156534]">{getLangText('yuhuLabel')}</div>
                <div className="text-sm text-[#419860] font-medium">{getLangText('notificationsHasSentLabel')}</div>
            </div>
            <div className="flex items-center mb-3">
                <div>
                    <div className="text-desc text-sm font-medium">
                        {getLangText('additionallyLabel')}
                    </div>
                    <div className="text-lg font-bold text-[#1F2937]">
                        {getLangText('sendNotificationsInAppLabel')}
                    </div>
                </div>
                <div className="ml-auto">
                    <img
                        width={32}
                        height={32}
                        className="w-[32px] min-w-[32px] h-[32px] rounded-[5px] overflow-hidden"
                        src="/assets/images/selmo-square.png"/>
                </div>
            </div>
            <TextArea
                {...getFieldProps('message')}
                label={getLangText('notificationMessageLabel')}
            />
            <Field
                {...getFieldProps('url')}
                label={getLangText('linkToLiveOrPostLabel')}
            />
        </div>
    );
};

export default MobileNotificationsForm;