import React from 'react';
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {useModal} from "../../../../shared/hooks/useModal";
import useLang from "../../../../../../src_shared/hooks/useLang";
import {services} from "../../../../../RestServices";
import ExportModal from "../../../../../../src_shared/components/exportModal/ExportModal";

const PrintOrderButton = ({shop_order_id}) => {

    const {getLangText} = useLang();
    const [openModal, setOpenModal] = useModal(false);

    return (
        <>
            <OverlayTrigger
                placement='top'
                overlay={
                    <Tooltip id="packed">
                        {getLangText('exportOrderLabel')}
                    </Tooltip>
                }
            >
                <button
                    onClick={() => setOpenModal(true)}
                    type="button"
                    className="icon-button packed-button print-order-button ml-sm-2 default-size"
                >
                    <i className="icon-print"/>
                </button>
            </OverlayTrigger>
            {openModal &&
                <ExportModal
                    title={getLangText('downloadOrderCartLabel')}
                    subTitle={getLangText('receivedFileInPDFFormatLabel')}
                    saveButton={getLangText('downloadLabel')}
                    rest={`${services.API_EXPORT_ORDER_PDF}/${shop_order_id}`}
                    fileName={getLangText('customerBasketOrderLabel')}
                    show={openModal}
                    hide={() => setOpenModal(false)}
                />
            }
        </>
    );
};

export default PrintOrderButton;
