import React from 'react';
import {useSelector} from "react-redux";
import {RouterPaths} from "../../routes/RouterPath";
import useLang from "../../../src_shared/hooks/useLang";

const FunctionalityPurchasePage = ({text, title, className, imgSrc, onClick}) => {
	const {userData} = useSelector((state) => state.session);
	const {getLangText} = useLang();

	return (
		<div className={`empty-state functionality-purchase with-bg with-padd ${className}`}>
			<div className="img-icon">
				<img
					src={imgSrc}
					alt=""
				/>
			</div>
			<div className="title" dangerouslySetInnerHTML={{__html: `${title}`}}/>
			<div className="desc">{text}</div>
			{!!userData.user_info.premium_date ?
				<button
					className="button primary small-size mt-3"
					type="button"
					onClick={onClick}
				>
					{getLangText('purchaseAccessLabel')}
				</button> :
				<a
					className="button primary small-size mt-4"
					href={RouterPaths.Payments}
				>
					{getLangText('purchaseAccessLabel')}
				</a>
			}
		</div>
	);
};

export default FunctionalityPurchasePage;
