import React from 'react';
import useLang from "../../../../../src_shared/hooks/useLang";
import useFormLocal from "../../../../../src_shared/hooks/useFormLocal";
import Group from "./components/Group";
import TeamMemberModel from "../../../../../modules/models/settings/TeamMemberModel";
import AllSelectSwitch from "./components/AllSelectSwitch";
import {services} from "../../../../RestServices";
import Loader from "../../../../admin/components/app/shared/Loader";
import Skeleton from "./components/Skeleton";
import {batch, useDispatch} from "react-redux";
import SessionActions from "../../../../app/shared/session/SessionActions";
import {showAlert} from "../../../../app/shared/components/alert/AlertActions";
import Profile from "./components/Profile";
import {
    USER_PERMISSIONS_TEAM_EDIT_PERMISSIONS
} from "../../../../app/shared/enums/UserPermissionsTypes";
import UserPermissionsWrapper from "../../../../routes/UserPermissionsWrapper";

const MemberForm = ({match: {params: {id}}}) => {
    const {getLangText} = useLang();
    const dispatch = useDispatch();

    const afterSubmit = (response) => {
        batch(() => {
            dispatch(SessionActions.updateUserData('user_permissions', response))
            dispatch(showAlert())
        })
    }

    const [{
        isLoading,
        setValue,
        values,
        onSave,
        isWaiting,
        updateValues,
        fetchData,
    }] = useFormLocal({
        rest: `${services.API_PERMISSIONS_PERMISSIONS}/${id}`,
        model: TeamMemberModel,
        method: 'put',
        afterSubmit,
    });

    return (
        <>
            {isLoading ?
                <Skeleton/> :
                <>
                    <Profile
                        defaultValues={values}
                        id={id}
                        fetchData={fetchData}
                    />
                    <UserPermissionsWrapper allowedPermissions={[USER_PERMISSIONS_TEAM_EDIT_PERMISSIONS]}>
                        <div className="bg-white rounded-[5px] p-6 shadow-default">
                            <form onSubmit={onSave}>
                                <div className="font-bold mb-2">{getLangText('managePermissions')}</div>
                                <AllSelectSwitch
                                    updateValues={updateValues}
                                    values={values}
                                    groups={values.permissionGroups}
                                />
                                <div className="space-y-2">
                                    {values.permissionGroups?.map((group) => (
                                        <Group
                                            key={group.id}
                                            group={group}
                                            setValue={setValue}
                                            values={values}
                                            updateValues={updateValues}
                                        />
                                    ))}
                                </div>
                                <div className="text-right">
                                    <button
                                        type="submit"
                                        className={`button primary mt-3 ${isWaiting ? 'loading-button' : ''}`}
                                    >
                                        {isWaiting && <Loader isLoading={true}/>}
                                        {getLangText('set')}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </UserPermissionsWrapper>
                </>
            }
        </>
    );
};

export default MemberForm;
