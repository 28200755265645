import ListReducer from "../../../../../../src_shared/list/ListReducer";
import Composition from "../../../helpers/Composition";

const generateId = () => new Date();

const manualProduct = {
    product_price: '',
    product_id: null,
    product_name: '',
    quantity: 1,
    helperId: generateId(),
    tabIndex: 1,
    to_repay: 0,
    type: 'new',
}

export const getInitState = (initState) => ListReducer.getInitState({
    manualProducts: [manualProduct],
    defaultProducts: [],
    data: {},
    orderWaiting: false,
    safetyCloseModalVisibility: {},
    orderCancelModalVisible: false,
    ...initState,
})

export const getReduceMap = () => new Composition(
    ListReducer.getReduceMap(),
    {
        // UPDATE_ORDER_DATA: (state, action) => {
        //     return {
        //         ...state,
        //         data: action.data,
        //         manualProducts: [...action.data.products.map((i) => ({
        //             ...i, helperId: i.id,
        //         }))],
        //         defaultProducts: action.data.products.map((i) => ({
        //             ...i, helperId: i.id,
        //         })),
        //     }
        // }
        UPDATE_ORDER_DATA: (state, action) => {
            return {
                ...state,
                data: action.data,
                manualProducts: [...action.data.products.map((i) => ({
                    ...i, helperId: i.id,
                })), manualProduct],
                defaultProducts: action.data.products.map((i) => ({
                    ...i, helperId: i.id,
                })),
            }
        }
    },
    {
        UPDATE_FIELD_IN_PRODUCT: (state, action) => {
            return {
                ...state,
                manualProducts: state.manualProducts.map((item) => item.helperId === action.id ? {
                    ...item,
                    [action.field]: action.value
                } : item)
            }
        },
    },
    {
        UPDATE_FIELDS_IN_PRODUCT: (state, action) => {
            return {
                ...state,
                manualProducts: state.manualProducts.map((item) => item.helperId === action.id ? {
                    ...item,
                    ...action.fields,
                } : item)
            }
        },
    },
    {
        ADD_NEW_PRODUCT: (state, action) => {
            const tabIndex = state?.manualProducts[state.manualProducts?.length - 1]?.tabIndex || 1
            return {
                ...state,
                manualProducts: [...state.manualProducts, {
                    ...manualProduct,
                    helperId: generateId(),
                    tabIndex: tabIndex + 1,
                }]
            }
        },
    },
    {
        REMOVE_PRODUCT: (state, action) => {
            const filteredManualProducts = state.manualProducts.filter((i) => i.helperId !== action.id);

            if (state.manualProducts.length === 1) {
                return {
                    ...state,
                    manualProducts: [manualProduct]
                }
            }

            return {
                ...state,
                manualProducts: [...filteredManualProducts],
            }
        },
    },
    {
        RESET_ORDER_PRODUCTS: (state) => {
            return {
                ...state,
                manualProducts: [manualProduct],
            }
        },
    },
    {
        SET_EDIT_ORDER_WAITING: (state, action) => {
            return {
                ...state,
                orderWaiting: action.waiting,
            }
        },
    },
    {
        SHOW_CANCEL_ORDER_MODAL: (state, action) => {
            return {
                ...state,
                orderCancelModalVisible: action.toggle,
            }
        },
    },
    {
        UPDATE_FIELD: (state, action) => {
            return {
                ...state,
                data: {...state.data, [action.field]: action.value}
            }
        }
    },
    {
        RESET_DATA: (state, action) => {
            return getInitState();
        }
    }
)

export default {
    getInitState,
    getReduceMap,
};
