import { prefix } from './filtersReducer';
import ListActions from '../list/ListActions';
import SharedFiltersActions from "../../../../src_shared/filters/FiltersActions";

export const getInstance = () => new SharedFiltersActions({
    ListActions,
    prefix,
});

export default getInstance();
