import React from 'react';
import useList from "../../../../src_shared/list/useList";
import ListActions from "./ListActions";
import {getStateRoot} from "./listReducer";
import {NavLink} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";

const KnowledgeBaseAside = ({closeMobileMenu}) => {

	const dispatch = useDispatch();
	const {categoryId, categoryArticles} = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));

	const {
		list: {items, isWaiting},
	} = useList({
		ListActions,
		getStateRoot,
	})

	const getArticleByCategory = (item) => dispatch(ListActions.getArticleByCategory(item));

	const onCategoryClick = (item) => {
		if (categoryId === item.id) {
			dispatch(ListActions.setActiveCategoryId(null))
			return;
		}
		getArticleByCategory(item)
	}

	return (
		<aside className="main-aside">
			<div>
				<ul>
					{items.map((item) => (
						<li
							key={item.id}
							className={`with-sub-menu ${categoryId === item.id && !isWaiting ? 'active' : ''}`}
						>
							<button
								type="button"
								className={`menu-name ${categoryId === item.id ? 'active' : ''}`}
								onClick={() => onCategoryClick(item)}
							>
								{item.name}
							</button>
							{item.articles &&
								<ul>
									{item.articles.map((i) => (
										<li
											key={i.id}
										>
											<NavLink
												onClick={closeMobileMenu}
												activeClassName="active"
												to={`${RouterPaths.KnowledgeBase}/${i.name_url}`}>{i.title}</NavLink>
										</li>
									))}
								</ul>
							}
						</li>
					))}
				</ul>
			</div>
		</aside>
	);
};

export default KnowledgeBaseAside;
