import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";

const useOrderProductsForm = (actions, getStateRoot) => {
	const dispatch = useDispatch();
	const [addRowTabIndex, setAddRowTabIndex] = useState(1)
	const {manualProducts} = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));

	const updateFieldInProduct = (field, value, id) => dispatch(actions.updateFieldInProduct(field, value, id));
	const updateFieldsInProduct = (fields, id) => dispatch(actions.updateFieldsInProduct(fields, id));
	const removeManualRow = (id) => dispatch(actions.removeProduct(id))

	const setManualFieldValue = (field, value, id) => {
		const safeCount = value <= 0 ? 1 : value;
		const safePrice = value < 0 ? 0 : value;
		let safeValue = value
		if (field === 'quantity') {
			safeValue = value === '' ? '' : (value <= 0 ? 1 : value);
		}
		// if (field === 'product_price') {
		// 	safeValue = safePrice
		// }

		updateFieldInProduct(field, safeValue, id)
	}

	const onAddNewRowClick = () => {
		const tabIndex = manualProducts[manualProducts?.length - 1]?.tabIndex || 1
		setAddRowTabIndex(tabIndex + 1);
		dispatch(actions.addNewProduct())
	}

	return {
		removeManualRow,
		onAddNewRowClick,
		manualProducts,
		setManualFieldValue,
		addRowTabIndex,
		updateFieldInProduct,
		updateFieldsInProduct,
	}
};

export default useOrderProductsForm;
