import React from 'react';
import PreparedPostText from "../../form/components/preparedPostText/PreparedPostText";
import useLang from "../../../../../src_shared/hooks/useLang";
import {useModal} from "../../../shared/hooks/useModal";

const PostText = ({inModal, values, product_code}) => {
    const {getLangText} = useLang();
    const [openPostTextModal, setOpenPostTextModal] = useModal(false);

    if (inModal) return null;

    return (
        <>
            <div className="left-side">
                <div className="sm:block flex items-center">
                    <h2 className="font-bold">{getLangText('productCodeLabel')}</h2>
                    <button
                        className="button border-button small-size left-icon mt-3 ml-sm-0 ml-auto"
                        type="button"
                        onClick={() => setOpenPostTextModal(true)}
                    >
                        <i className="icon-orders"/>
                        {getLangText('textToPostLabel')}
                    </button>
                </div>
            </div>
            {openPostTextModal &&
                <PreparedPostText
                    data={values}
                    modalVisible={openPostTextModal}
                    hideModal={() => setOpenPostTextModal(false)}
                    productCode={product_code}
                />
            }
        </>
    );
};

export default PostText;
