import React from 'react';
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../routes/RouterPath";
import useLang from "../../../../../src_shared/hooks/useLang";

const OrderNotFound = () => {
	const {getLangText} = useLang();

	return (
		<div className="empty-state with-padd default-shadow-box">
			<div className="img-icon">
				<img src="/assets/images/empty/orders-empty.svg"
					 alt=""/>
			</div>
			<div className="title" dangerouslySetInnerHTML={{__html: getLangText('notFoundYourOrderLabel')}}/>
			<Link to={RouterPaths.OrdersList} className="button primary small-size mt-3">{getLangText('backToOrdersLabel')}</Link>
		</div>
	);
};

export default OrderNotFound;
