import React, {useState} from 'react'
import s from './Collapse.module.css'
import {useSpring, a} from '@react-spring/web'
import useMeasure from 'react-use-measure'
import cn from "clsx";

const defaultHeader = (isActive, title) => (
    <div className={s.header}>
        <span className={s.label}>{title}</span>
    </div>
)

const Collapse = ({title, children, header = defaultHeader, isActive, setActive, id, styles = ''}) => {
    const [ref, {height: viewHeight}] = useMeasure()

    const animProps = useSpring({
        height: isActive === id ? viewHeight : 0,
        config: {tension: 250, friction: 32, clamp: true, duration: 150},
        opacity: isActive === id ? 1 : 0,
    })

    const toggle = () => setActive(() => id)

    return (
        <div
            className={cn(s.root, styles, isActive === id ? s.activeRoot : '')}
            role="button"
            tabIndex={0}
            aria-expanded={isActive === id}
            onClick={toggle}
        >
            {header(isActive, title)}
            <a.div style={{overflow: 'hidden', ...animProps}}>
                <div ref={ref} className={s.content}>
                    {children}
                </div>
            </a.div>
        </div>
    )
}

export default React.memo(Collapse)
