import React, {useEffect} from 'react';
import useFetchData from "../../../../src_shared/hooks/useFetchData";
import {services} from "../../../RestServices";
import {Helmet} from "react-helmet";
import {Link, NavLink, useLocation} from "react-router-dom";
import {RouterPaths} from "../../RouterPath";
import useLang from "../../../../src_shared/hooks/useLang";
import {
    USER_PERMISSIONS_TEAM_STATISTICS_VISIBILITY_PERMISSIONS
} from "../../../app/shared/enums/UserPermissionsTypes";
import UserPermissionsWrapper from "../../UserPermissionsWrapper";

export const TeamContext = React.createContext({
    data: {
        photo: '',
    },
    isLoading: false,
    fetchData: () => {
    },
});

const TeamContextWrapper = (props) => {
    const {pathname} = useLocation()
    const splittedPathname = pathname.split('/');
    const id = splittedPathname[splittedPathname.length - 1];
    const {getLangText} = useLang();

    const [{isLoading, data, fetchData}] = useFetchData({
        rest: `${services.API_PERMISSIONS_EMPLOYEE_INFO}/${id}`,
        initialData: {
            name: '',
        },
        initialAutoLoad: false,
    });

    useEffect(() => {
        if (id) {
            fetchData()
        }
    }, [id])

    return (
        <TeamContext.Provider
            value={{data, isLoading, fetchData}}
        >
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getLangText('permissions')} - Selmo</title>
            </Helmet>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <Link to={RouterPaths.Settings}>{getLangText('asideSettingsLabel')}</Link>
                    </li>
                    <li>
                        <Link to={RouterPaths.SettingsShop}>{getLangText('settings_nav_shop_settings')}</Link>
                    </li>
                    <li>
                        <Link to={RouterPaths.SettingsTeamMembers}>{getLangText('teamLabel')}</Link>
                    </li>
                    <li className="active">
                        {data.name}
                    </li>
                </ul>
                <Link to={RouterPaths.SettingsTeamMembers} className="button back-to-panels mb-lg-3 mt-lg-0 mt-3">
                    <i className="icon-arrow-left"/>
                    {getLangText('backToTeamLabel')}
                </Link>
            </div>
            <div
                className={`settings-page sub-settings-page list-with-tabs separated-box shadow-default`}>
                <div className="tabs-header">
                    <div className="page-title">
                        {isLoading ?
                            <div className="animate-pulse relative">
                                <div className="h-[23px] bg-gray-200 rounded-[5px] dark:bg-gray-700 w-[250px]"/>
                            </div> :
                            data.name
                        }
                    </div>
                    <div
                        style={{borderBottomLeftRadius: 5, borderBottomRightRadius: 5, borderBottomColor: '#fff'}}
                        className="tabs-wrapper"
                    >
                        <NavLink
                            exact
                            activeClassName="active"
                            to={`${RouterPaths.SettingsTeamMembers}/${id}`}
                        >
                            {getLangText('editionLabel')}
                        </NavLink>
                        <UserPermissionsWrapper allowedPermissions={[USER_PERMISSIONS_TEAM_STATISTICS_VISIBILITY_PERMISSIONS]}>
                            <NavLink
                                exact
                                activeClassName="active"
                                to={`${RouterPaths.SettingsTeamMembers}/history/${id}`}
                            >
                                {getLangText('activityHistory')}
                            </NavLink>
                        </UserPermissionsWrapper>
                    </div>
                </div>
            </div>
            {props.children}
        </TeamContext.Provider>
    );
};

export default TeamContextWrapper;
