import React from 'react';
import Modal from "react-bootstrap/Modal";
import Loader from "../../../../shared/Loader";
import useLang from "../../../../../../src_shared/hooks/useLang";
import {services} from "../../../../../RestServices";
import Field from "../../../../../../src_shared/components/form/field/Field";
import useFormLocal from "../../../../../../src_shared/hooks/useFormLocal";
import Model from "../../../../../../src_shared/modules/model/Model";
import TextArea from "../../../../../../src_shared/components/form/textarea/TextArea";
import ComponentLoader from "../../../../shared/components/componentLoader/ComponentLoader";
import CharactersCounter from "../../../../../../src_shared/components/ui/CharactersCounter";

const EditTemplatesModal = ({show, hide, fetchData}) => {
    const {getLangText} = useLang();

    const afterSubmit = () => {
        hide();
        fetchData();
    }

    const [{isLoading, getFieldProps, onSave, isWaiting, values}] = useFormLocal({
        method: !!show ? 'put' : 'post',
        model: new Model(),
        rest: `${services.API_MESSENGER_TEMPLATE}/${show}`,
        initialAutoLoad: !!show,
        afterSubmit,
    });

    return (
        <Modal
            dialogClassName={`default-modal small-modal w-450`}
            show={true}
            onHide={hide}
        >
            <Modal.Header>
                <Modal.Title>
                    {getLangText(show ? 'editTemplate' : 'addTemplateLabel')}
                </Modal.Title>
                <button
                    onClick={hide}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            {isLoading ?
                <ComponentLoader height={200}/> :
                <form onSubmit={onSave}>
                    <Modal.Body>
                        <Field
                            {...getFieldProps('name')}
                            label={getLangText('nameLabel')}
                            extraProps={{required: true}}
                        />
                        <div className="characters-counter-parent">
                            <TextArea
                                {...getFieldProps('text')}
                                label={getLangText('messageLabel')}
                                rows={10}
                                extraProps={{required: true}}
                            />
                            <CharactersCounter value={values.text} max={640}/>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="mt-3">
                        <button
                            onClick={hide}
                            type="button"
                            className="button border-button"
                        >
                            {getLangText('cancelButton')}
                        </button>
                        <button
                            type="submit"
                            className={`button px-0 primary ${isWaiting ? 'loading-button' : ''}`}
                        >
                            {isWaiting && <Loader isLoading/>}
                            {getLangText(show ? 'editButton' : 'saveButton')}
                        </button>
                    </Modal.Footer>
                </form>
            }
        </Modal>
    );
};

export default EditTemplatesModal;
