import React, {memo} from 'react';

const PaymentsSelectSkeleton = ({
									withoutTop = false, hidden, className = '', children
								}) => {

	if (hidden) {
		return children;
	}

	return (
		<div className={`skeleton-box count`}>
			{!withoutTop &&
				<div className={`skeleton mb-3 ${className}`}>
					<div className="skeleton-box logo mb-2"/>
					<div className="skeleton-box big-title mb-1"/>
					<div className="skeleton-box title normal"/>
				</div>
			}
			<div className={`skeleton mb-sm-5 mb-3 ${className}`}>
				<div className="skeleton-box title"/>
				<div className={`skeleton-box switch-toggle-skeleton`}/>
			</div>
			<div className={`skeleton mb-sm-5 mb-3 ${className}`}>
				<div className="skeleton-box title"/>
				<div className={`skeleton-box switch-toggle-skeleton`}/>
			</div>
		</div>
	);
};

export default memo(PaymentsSelectSkeleton);
