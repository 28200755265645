import {useState} from "react";
import axios from "axios";
import {selmoUrl} from "../../../../src_shared/api/api";
import {batch, useDispatch} from "react-redux";
import {showAlert} from "../components/alert/AlertActions";
import api from "../../../../services/axios/axios";

export const useRemoveModal = (rest, afterDelete = () => {}, initialMode) => {
	const dispatch = useDispatch();
	const [modalOpen, setModalOpen] = useState(initialMode)
	const [waiting, setWaiting] = useState(false);

	const remove = async () => {
		setWaiting(true);
		try {
			const {data} = await api.delete(`${selmoUrl}/${rest}`)

			batch(() => {
				dispatch(showAlert('yuhuLabel', 'successfulRemovedLabel'))
				setModalOpen(false)
				afterDelete(data.item);
			})
		} catch (e) {
			dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
		}
		finally {
			setWaiting(false);
		}
	}
	return [modalOpen, setModalOpen, waiting, remove]
}
