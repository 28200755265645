import ModalReducer from "../../../../../src_shared/modal/ModalReducer";
import OrderProductsFormReducer from "../../../shared/components/form/orderProductsForm/OrderProductsFormReducer";
import Composition from "../../../shared/helpers/Composition";
import createReducer from "../../../shared/helpers/createReducer";

export const getStateRoot = (state) => state.orders.details.editProducts;

export const prefix = 'ORDER_DETAILS_EDIT_PRODUCTS_';

export const getInitState = () => OrderProductsFormReducer.getInitState({
	...ModalReducer.initState,
})

export const getReduceMap = () => new Composition(
	OrderProductsFormReducer.getReduceMap(),
	ModalReducer.reduceMap,
	{
		RESET_DATA: () => {
			return getInitState();
		}
	}
);

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();
