import React from 'react';
import useLang from "../../../../src_shared/hooks/useLang";
import {ORDER_STATUES} from "../enums/OrderStatuses";
import {useSelector} from "react-redux";
import {TIM_SHOP_ID} from "../../../../src_shared/enums/TrustedShopsIds";

export const SCAN_TRANSLATIONS_WITHOUT_DETAILS = 'SCAN_TRANSLATIONS_WITHOUT_DETAILS';
export const SCAN_TRANSLATIONS_WITHOUT_ORDER = 'SCAN_TRANSLATIONS_WITHOUT_ORDER';
export const SCAN_TRANSLATIONS_WITHOUT_PRODUCT = 'SCAN_TRANSLATIONS_WITHOUT_PRODUCT';

export const QR_PRODUCT_SCAN = 'QR_PRODUCT_SCAN';
export const QR_ORDER_SCAN = 'QR_ORDER_SCAN';
export const ORDER_PRODUCT_PACKED = 'ORDER_PRODUCT_PACKED';
export const ORDER_PRODUCT_UNPACKED = 'ORDER_PRODUCT_UNPACKED';
export const ORDER_PRODUCT_PACKED_MANUAL = 'ORDER_PRODUCT_PACKED_MANUAL';
export const ORDER_PRODUCT_UNPACKED_MANUAL = 'ORDER_PRODUCT_UNPACKED_MANUAL';
export const ASSIGN_SHELF_TO_PRODUCT = 'ASSIGN_SHELF_TO_PRODUCT';
export const TAKE_OFF_PRODUCT_FROM_SHELF = 'TAKE_OFF_PRODUCT_FROM_SHELF';
export const ASSIGN_SHELF_TO_ORDER = 'ASSIGN_SHELF_TO_ORDER';
export const TAKE_OFF_ORDER_FROM_SHELF = 'TAKE_OFF_ORDER_FROM_SHELF';
export const LABEL_ORDER_SCAN = 'LABEL_ORDER_SCAN';
export const ORDER_MERGED = 'ORDER_MERGED';
export const ORDER_ASSIGNED = 'ORDER_ASSIGNED';
export const ORDER_DELETED = 'ORDER_DELETED';
export const ORDER_EMAIL_SENT = 'ORDER_EMAIL_SENT';
export const PRODUCT_INSERT = 'PRODUCT_INSERT';
export const PRODUCT_DELETE = 'PRODUCT_DELETE';
export const ORDER_STATUS_CHANGED = 'ORDER_STATUS_CHANGED';

const useScanTranslations = (type = null) => {
    const {getLangText} = useLang();
    const {userData} = useSelector((state) => state.session);

    const getStatusName = (status) => getLangText(ORDER_STATUES.find((i) => +i.value === +status)?.label)

    const getScanTranslations = (item) => {
        const getScannedProduct = () => {
            const productName = item.product_name;
            if (item.size_name && type === SCAN_TRANSLATIONS_WITHOUT_PRODUCT) {
                if (+userData.id === TIM_SHOP_ID) {
                    return ` (${getLangText('variantLabel')}: ${item.size_name}${item.product_code || item.size_code ? ` - ${item.product_code || item.size_code}` : ''})`
                }
                return ` (${getLangText('variantLabel')}: ${item.size_name})`
            }
            if (item.size_name) {
                if (+userData.id === TIM_SHOP_ID) {
                    return ` (${productName}, ${getLangText('variantLabel')}: ${item.size_name}${item.product_code || item.size_code ? ` - ${item.product_code || item.size_code}` : ''})`
                }
                return ` (${productName}, ${getLangText('variantLabel')}: ${item.size_name})`
            }

            if (type === SCAN_TRANSLATIONS_WITHOUT_PRODUCT) return ''

            return ` (${productName})`;
        }

        const getScannedOrder = () => {
            if (type === SCAN_TRANSLATIONS_WITHOUT_ORDER) {
                return ''
            }
            return ` (${item.shop_order_id})`
        }

        if (type === SCAN_TRANSLATIONS_WITHOUT_DETAILS) {
            return getLangText({
                key: item.type,
                data: ['', '']
            })
        }

        if (item.type === QR_PRODUCT_SCAN || item.type === PRODUCT_DELETE || item.type === PRODUCT_INSERT) {
            return getLangText({
                key: item.type,
                data: [getScannedProduct()]
            })
        }

        if (item.type === ORDER_DELETED) {
            return getLangText({
                key: item.type,
                data: [` (${item.old_shop_order_id})`]
            })
        }

        if (item.type === ORDER_MERGED) {
            return getLangText({
                key: item.type,
                data: [` (${item.old_shop_order_id})`, getScannedOrder()]
            })
        }

        if (item.type === QR_ORDER_SCAN || item.type === LABEL_ORDER_SCAN || item.type === ORDER_ASSIGNED) {
            return getLangText({
                key: item.type,
                data: [getScannedOrder()]
            })
        }

        if (item.type === ORDER_EMAIL_SENT) {
            return getLangText({
                key: item.type,
                data: [getStatusName(item.new_status), getScannedOrder()]
            })
        }

        if (item.type === ORDER_STATUS_CHANGED) {
            return getLangText({
                key: item.type,
                data: [getStatusName(item.old_status), getStatusName(item.new_status), getScannedOrder()]
            })
        }

        if (item.type === ASSIGN_SHELF_TO_PRODUCT) {
            return getLangText({
                key: item.type,
                data: [` (${item.shelf_name})`, getScannedProduct()]
            })
        }

        if (item.type === TAKE_OFF_PRODUCT_FROM_SHELF) {
            return getLangText({
                key: item.type,
                data: [getScannedProduct(), ` (${item.shelf_name})`]
            })
        }

        if (item.type === ASSIGN_SHELF_TO_ORDER) {
            return getLangText({
                key: item.type,
                data: [` (${item.shelf_name})`, getScannedOrder()]
            })
        }

        if (item.type === TAKE_OFF_ORDER_FROM_SHELF) {
            return getLangText({
                key: item.type,
                data: [getScannedOrder(), ` (${item.shelf_name})`]
            })
        }

        if (item.type.includes(ORDER_PRODUCT_PACKED) || item.type.includes(ORDER_PRODUCT_UNPACKED)) {
            return getLangText({
                key: item.type,
                data: [getScannedProduct(), getScannedOrder()]
            })
        }

        return getLangText(item.type)
    }

    return {
        getScanTranslations,
    }
};

export default useScanTranslations;
