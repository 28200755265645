import React from 'react';
import useLang from "../../../hooks/useLang";

const SelmoBoosterTag = () => {
    const {getLangText} = useLang();

    return (
        <div className="inline-flex items-center text-[10px] text-[#0D3364] font-bold leading-[12px] bg-[#f3fbff] rounded-[5px] px-1.5 py-1 mt-0.5">
            <img src="/assets/images/svg/thunder-ads.svg"
                 className="w-[10px] mr-1"
                 alt=""/>
            {getLangText('selmoAdd')}
        </div>
    );
};

export default SelmoBoosterTag;
