import ListActions from "../../../../src_shared/list/ListActions";
import {getStateRoot, prefix} from './listReducer';
import BoxesStatsListActions from "../list/ListActions";
import {services} from "../../../RestServices";

export class ChartsStatsListActions extends ListActions {
	getLoadParams(state) {
		return BoxesStatsListActions.getLoadParams(state);
	}
}

export const getInstance = () => new ChartsStatsListActions({
	getStateRoot,
	restService: services.API_STATS_INFO,
	prefix,
});

export default getInstance();
