import React, {useState} from 'react';
import ListActions from "../../../ListActions";
import StatusSelect from "../../../../../messenger/addOrderModal/components/StatusSelect";
import GenerateInvoiceModal from "../generateInvoiceModal/GenerateInvoiceModal";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {getStateRoot} from "../../../listReducer";
import {selmoUrl} from "../../../../../../../src_shared/api/api";
import {showAlert} from "../../../../../shared/components/alert/AlertActions";
import {services} from "../../../../../../RestServices";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import api from "../../../../../../../services/axios/axios";
import SwitchField from "../../../../../../../src_shared/components/form/switchField/SwitchField";
import {TEST_ACCOUNT_SHOP_ID} from "../../../../../../../src_shared/enums/TrustedShopsIds";

const StatusModal = ({modalVisible}) => {

        const [value, setValue] = useState();
        const [activeValidation, setActiveValidation] = useState(false);
        const {getLangText} = useLang();
        const dispatch = useDispatch();
        const [cancelProducts, setCancelProducts] = useState(false);
        const {userData} = useSelector((state) => state.session);

        const {
            waitingForDocument,
            successGenerated,
            generatedOrdersCount,
            selectedItems,
        } = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));

        const setModalOpen = (modalType) => dispatch(ListActions.setModalVisible(modalType));

        const setStatusOnServer = async () => {
            if (!value) {
                setActiveValidation(true);
                return;
            }
            dispatch(ListActions.setWaitingForDocument(true))
            try {
                const {data} = await api.post(`${selmoUrl}/${services.API_BATCH_STATUS}`, {
                    orders: selectedItems.map((i) => i.id),
                    status: value,
                    cancel_products: cancelProducts,
                })
                dispatch(ListActions.afterGenerate(data.item))
                setActiveValidation(false);

            } catch (e) {
                setActiveValidation(false);
                dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
                console.error(e)
            } finally {
                dispatch(ListActions.setWaitingForDocument(false))
            }
        }

        const summaryContent = (generatedCount, hideModal) => (
            !!+generatedCount?.success_orders ?
                <>
                    <div className="circle-icon">
                        <i className="icon-tick"/>
                    </div>
                    <div className="title">{getLangText('doneLabel')}</div>
                    <div
                        className="desc"
                        dangerouslySetInnerHTML={{
                            __html: getLangText({
                                key: 'successfulChangedStatusLabel',
                                data: [generatedCount?.success_orders, generatedCount?.all_orders]
                            })
                        }}
                    />
                    <div className="modal-footer">
                        <button
                            onClick={hideModal}
                            type="button"
                            className="button border-button"
                        >
                            {getLangText('closeButton')}
                        </button>
                    </div>
                </> :
                <>
                    <div className="circle-icon red">
                        <i className="icon-cross"/>
                    </div>
                    <div className="title">{getLangText('upsLabel')}</div>
                    <div className="desc">{getLangText('cannotChangeStatusLabel')}</div>
                    <div className="modal-footer">
                        <button
                            onClick={hideModal}
                            type="button"
                            className="button border-button"
                        >
                            {getLangText('closeButton')}
                        </button>
                    </div>
                </>
        )

        return (
            <>
                <GenerateInvoiceModal
                    title={getLangText('changeStatusLabel')}
                    SubTitle={() => <span>{getLangText('setStatusForSelectedOrdersLabel')}</span>}
                    saveButton={getLangText('customerBasketChangeButton')}
                    show={modalVisible}
                    isWaiting={waitingForDocument}
                    successGenerated={successGenerated}
                    generatedOrdersCount={generatedOrdersCount}
                    onSubmit={setStatusOnServer}
                    hide={() => setModalOpen(null)}
                    summaryContent={summaryContent}
                >
                    <StatusSelect
                        setValue={setValue}
                        statusId={value}
                        className={`w-sm-auto w-100 ${activeValidation && !value ? 'error-group' : ''}`}
                    />
                    {+value === 9 &&
                        <SwitchField
                            value={cancelProducts}
                            setValue={(field, value) => setCancelProducts(value)}
                            className="switch-form align-items-start inline-label big-label light-version mb-0 mt-3 w-full"
                            label={getLangText('removeProductsFromOrderLabel')}
                            subLabel={getLangText('productsWillBackToStock')}
                            id="cancelProducts"
                        />
                    }
                </GenerateInvoiceModal>
            </>
        );
    }
;

export default StatusModal;
