import React from 'react';
import Field from "../../../../../../src_shared/components/form/field/Field";
import useLang from "../../../../../../src_shared/hooks/useLang";
import ZipCodeField from "../../../../orders/details/deliveryAddress/components/ZipCodeInputs";

const NoDigitalProductInputs = ({getFieldProps, values}) => {
    const {getLangText} = useLang();
    const isPoland = values?.country_name?.trim()?.toLowerCase() === 'polska';

    return (
        <div className="grid grid-cols-2 gap-x-4">
            <Field
                {...getFieldProps('first_name')}
                value={values.first_name}
                label={getLangText('firstNameLabel')}
                className="big-size"
                floatedLabel
            />
            <Field
                {...getFieldProps('last_name')}
                value={values.last_name}
                label={getLangText('lastNameLabel')}
                className="big-size"
                floatedLabel
            />
            <Field
                {...getFieldProps('street')}
                value={values.street}
                label={getLangText('streetLabel')}
                className="big-size"
                floatedLabel
            />
            <Field
                {...getFieldProps('building_number')}
                value={values.building_number}
                label={getLangText('buildingNumberLabel')}
                className="big-size"
                floatedLabel
            />
            <Field
                {...getFieldProps('city')}
                value={values.city}
                label={getLangText('cityLabel')}
                className="big-size"
                floatedLabel
            />
            <ZipCodeField
                {...getFieldProps('zip_code')}
                value={values.zip_code}
                label={getLangText('zipCodeLabel')}
                isPoland={isPoland}
                className="big-size"
                floatedLabel
                maxLength={isPoland ? 6 : 100}
            />
            <div className="col-span-2">
                <Field
                    {...getFieldProps('phone')}
                    value={values.phone}
                    label={getLangText('phoneLabel')}
                    className="big-size"
                    floatedLabel
                    extraProps={{
                        maxLength: isPoland ? 9 : 100
                    }}
                />
            </div>
        </div>
    );
};

export default NoDigitalProductInputs;