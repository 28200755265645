import React from 'react';
import Field from "../../../../../src_shared/components/form/field/Field";
import ZipCodeField from "../../../orders/details/deliveryAddress/components/ZipCodeInputs";
import {services} from "../../../../RestServices";
import AsyncSearchSelectField
    from "../../../../../src_shared/components/form/selectField/asyncSearchSelectField/AsyncSearchSelectField";
import useLang from "../../../../../src_shared/hooks/useLang";
import {validateEmail} from "../../../../../src_shared/form/validation/ValidationError";
import {validatePhone, validateZipCode} from "../../../../../modules/models/basket/BasketDeliveryAddressModel";
import {MAKEUP_BY_IWONA} from "../../../../../src_shared/enums/TrustedShopsIds";
import Loader from "../../../shared/Loader";
import InvoiceData from "./InvoiceData";
import {SHOP_ORIGIN_US} from "../../../shared/enums/ShopOrigins";

export const isValidatedData = (values, data = {}) => {
    const isInValidEmail = !!values.email ? validateEmail(values.email) : true;
    const isInvalidZipCode = !!values.zip_code ? validateZipCode(values.zip_code, values) : true
    const isInvalidPhone = !!values.phone ? validatePhone(values.phone, values) : true

    if (data.shipping_cart) {
        return (!isInValidEmail
            && !!values.country
            && !!values.first_name
            && !!values.last_name
            && !!values.street
            && !!values.building_number
            && !!values.city
            && !isInvalidZipCode
            && !isInvalidPhone
        );
    } else {
        return (!isInValidEmail && !!values.country);
    }
}
const DeliveryAddressForm = ({
                                 magicUrl,
                                 shopId,
                                 data,
                                 values,
                                 setValue,
                                 updateValues,
                                 onSave = () => {
                                 },
                                 getFieldProps,
                                 withButton = true,
                                 isWaiting = false,
                             }) => {

    const {getLangText} = useLang();

    const isPoland = values?.selectedCountry?.name?.toLowerCase()?.trim() === 'polska';
    const isUsa = values?.selectedCountry?.name?.toLowerCase()?.includes(getLangText('unitedStates')?.toLowerCase());

    const formatOptionLabel = (option) => (<div style={{display: "flex"}}>
            <div className="mr-1">{option.flag}</div>
            <div>{option.name}</div>
        </div>
    );

    const updateCountryName = (option) => {
        updateValues({
            selectedCountry: {
                id: option.id,
                name: option.name
            }
        })
    }

    const updateStateName = (option) => setValue('state_name', option.name)

    return (
        <>
            <div className="form-row">
                <div className="w-100">
                    <Field
                        {...getFieldProps('email')}
                        label={getLangText('emailLabel')}
                        setValue={setValue}
                        type="email"
                        floatedLabel
                        extraProps={{
                            autoFocus: true
                        }}
                    />
                </div>
                <div className="w-100">
                    {+data.shopInfo.order_stage > 1 ?
                        <Field
                            label={getLangText('countryLabel')}
                            setValue={setValue}
                            floatedLabel
                            disabled
                            value={values.selectedCountry.name}
                        /> :
                        <AsyncSearchSelectField
                            {...getFieldProps('country')}
                            label={getLangText('countryLabel')}
                            setValue={setValue}
                            rest={`${services.API_CART_SHOP_COUNTRIES}?magic_url=${magicUrl}&shop_id=${shopId}`}
                            formatOptionLabel={formatOptionLabel}
                            defaultValue={values.selectedCountry}
                            floatedLabel
                            setExtraValues={updateCountryName}
                            searchOnFocus
                            isSearchable={false}
                            withSearchInput
                        />
                    }
                </div>
                {data.shipping_cart &&
                    <>
                        <div className="w-50">
                            <Field
                                {...getFieldProps('first_name')}
                                label={getLangText('firstNameLabel')}
                                setValue={setValue}
                                floatedLabel
                            />
                        </div>
                        <div className="w-50">
                            <Field
                                {...getFieldProps('last_name')}
                                label={getLangText('lastNameLabel')}
                                setValue={setValue}
                                floatedLabel
                            />
                        </div>
                        {data?.shopInfo?.origin === SHOP_ORIGIN_US ?
                            <>
                                <div className="w-50">
                                    <Field
                                        {...getFieldProps('building_number')}
                                        label={getLangText('buildingNumberLabel')}
                                        setValue={setValue}
                                        floatedLabel
                                    />
                                </div>
                                <div className="w-50">
                                    <Field
                                        {...getFieldProps('street')}
                                        label={getLangText('streetLabel')}
                                        setValue={setValue}
                                        floatedLabel
                                    />
                                </div>
                            </> :
                            <>
                                <div className="w-50">
                                    <Field
                                        {...getFieldProps('street')}
                                        label={getLangText('streetLabel')}
                                        setValue={setValue}
                                        floatedLabel
                                    />
                                </div>
                                <div className="w-50">
                                    <Field
                                        {...getFieldProps('building_number')}
                                        label={getLangText('buildingNumberLabel')}
                                        setValue={setValue}
                                        floatedLabel
                                    />
                                </div>
                            </>
                        }
                        {isUsa &&
                            <div className="w-100">
                                <AsyncSearchSelectField
                                    {...getFieldProps('state')}
                                    label={getLangText('state')}
                                    setValue={setValue}
                                    rest={`${services.API_CART_US_STATES}?magic_url=${magicUrl}&shop_id=${shopId}`}
                                    defaultValue={{
                                        id: values.state,
                                        name: values.state_name
                                    }}
                                    floatedLabel
                                    selectedOption={(selectedItem) => <div
                                        className="text-sm">{selectedItem.name}</div>}
                                    searchOnFocus
                                    setExtraValues={updateStateName}
                                    isSearchable={false}
                                    withSearchInput
                                />
                            </div>
                        }
                        <div className="w-50">
                            <Field
                                {...getFieldProps('city')}
                                label={getLangText('cityLabel')}
                                setValue={setValue}
                                floatedLabel
                            />
                        </div>
                        <div className="w-50">
                            <ZipCodeField
                                {...getFieldProps('zip_code')}
                                label={getLangText('zipCodeLabel')}
                                setValue={setValue}
                                isPoland={isPoland}
                                floatedLabel
                                maxLength={isPoland ? 6 : 100}
                            />
                        </div>
                        <div className="w-100">
                            <Field
                                {...getFieldProps('phone')}
                                value={values.phone}
                                label={getLangText('phoneLabel')}
                                setValue={setValue}
                                floatedLabel
                                asNumberField={isPoland}
                                extraProps={{
                                    maxLength: isPoland ? 9 : 100
                                }}
                            />
                        </div>
                    </>
                }
                {!!+data.shopInfo.extra_input_enabled &&
                    <div className="w-100">
                        <Field
                            {...getFieldProps('extra_description')}
                            label={+shopId === MAKEUP_BY_IWONA ? 'Wpisz tutaj imię i nazwisko Twojej Dyrektor Mary Kay:' : getLangText('extraDescriptionLabel')}
                            setValue={setValue}
                            floatedLabel
                        />
                    </div>
                }
                {!!+data.shopInfo.company_invoice_enabled &&
                    <InvoiceData
                        getFieldProps={getFieldProps}
                        setValue={setValue}
                        values={values}
                    />
                }
            </div>
            {withButton &&
                <div className="button-place mt-3">
                    <button
                        type="button"
                        className={`button primary sm:w-auto w-full btn-proceed ${isValidatedData(values, data) ? '' : 'not-active'} ${isWaiting ? 'loading-button' : ''}`}
                        onClick={onSave}
                    >
                        {isWaiting && <Loader isLoading/>}
                        {getLangText('saveButton')}
                    </button>
                </div>
            }
        </>
    );
};

export default DeliveryAddressForm;
