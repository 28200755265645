import React from 'react';
import useFormLocal from "../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../RestServices";
import useLang from "../../../../src_shared/hooks/useLang";
import SwitchField from "../../../../src_shared/components/form/switchField/SwitchField";
import Loader from "../../../app/shared/Loader";
import ComponentLoader from "../../../app/shared/components/componentLoader/ComponentLoader";
import Field from "../../../../src_shared/components/form/field/Field";
import Wrapper from "./Wrapper";
import SettingsMobilePayModel from "../../../../modules/models/settings/SettingsMobilePayModel";

const MobilePay = () => {
    const {getLangText} = useLang();
    const [{
        onSave,
        isWaiting,
        getFieldProps,
        isLoading,
    }] = useFormLocal({
        rest: services.API_SETTINGS_PAYMENT_MOBILEPAY,
        model: SettingsMobilePayModel,
    });

    if (isLoading) return <ComponentLoader/>;

    return (
        <Wrapper>
            <div className="settings-form pt-3">
                <div className="steps-wrapper">
                    <div className="step border-step">
                        <div className="step-header">
                            <div className="number">
                                1
                            </div>
                            <div className="title d-block">
                                <a
                                    rel="noreferrer"
                                    href="https://vippsmobilepay.com/"
                                    target="_blank"
                                    className="flex items-center"
                                >
                                    <img
                                        className="mr-0.5"
                                        style={{marginLeft: 0, height: '16px', width: 'auto', position: 'static'}}
                                        src="/assets/images/payments/mobilepay.svg" alt="MobilePay"/>
                                    <span className="text-sm font-medium"></span>
                                </a>
                                <div>
                                    {getLangText('settings_integrations_furgonetka_login_1')}
                                </div>
                            </div>
                            <a
                                rel="noreferrer"
                                target="_blank"
                                href="https://vippsmobilepay.com/"
                                className="button primary small-size ml-auto"
                            >
                                {getLangText('settings_integrations_fakturownia_description_4')}
                            </a>
                        </div>
                    </div>
                    <div className="step border-step mt-3 pb-lg-5 pb-3">
                        <div className="step-header align-items-start">
                            <div className="number">
                                2
                            </div>
                            <div className="title flex-grow-1 d-block">
                                <div className="mb-3 pt-1">{getLangText('copyDateFromMobilePayLabel')}</div>
                                <Field
                                    {...getFieldProps('client_id')}
                                    label={getLangText('clientId')}
                                />
                                <Field
                                    {...getFieldProps('client_secret')}
                                    label={getLangText('secretKey')}
                                />
                                <Field
                                    {...getFieldProps('subscription_key')}
                                    label={getLangText('subscriptionKey')}
                                />
                                <Field
                                    {...getFieldProps('merchant_serial_number')}
                                    label={getLangText('merchantSerialNumber')}
                                />
                                <div className="d-flex align-items-center flex-sm-nowrap flex-wrap">
                                    <SwitchField
                                        {...getFieldProps('active')}
                                        label={getLangText('active_payment')}
                                        className="switch-form inline-label mt-3 align-items-start big-label light-version mb-0 mr-3"
                                    />
                                    <button
                                        type="button"
                                        onClick={onSave}
                                        className={`button primary sm:ml-auto w-sm-auto w-100 mt-sm-3 mt-3 ${isWaiting ? 'loading-button' : ''}`}
                                    >
                                        <Loader isLoading={isWaiting}/>
                                        {getLangText('saveButton')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};

export default MobilePay;
