import React from "react";

const Loader = ({ref, className = ''}) => {
    return (
        <div ref={ref} className={`loader-container ${className}`}>
            <div className="circle-loader">
                <span className="circle" />
            </div>
        </div>
    )
}

export default Loader;
