import React from 'react';
import {Helmet} from "react-helmet";
import useLang from "../../../../../../src_shared/hooks/useLang";
import CreateShopLayout from "../../../shared/CreateShopLayout";
import {services} from "../../../../../RestServices";
import useFormLocal from "../../../../../../src_shared/hooks/useFormLocal";
import Button from "../../../../../../src_shared/components/ui/Button/Button";
import AsyncSearchSelectFieldNew
    from "../../../../../../src_shared/components/form/selectField/asyncSearchSelectFieldNew/AsyncSearchSelectField";
import SelectOriginModel from "../../../../../../modules/models/onboarding/SelectOriginModel";
import {ONBOARDING_BUILD_PANEL} from "../LastStepPage";
import useFetchData from "../../../../../../src_shared/hooks/useFetchData";

const SelectOrigin = ({setActiveView}) => {
    const {getLangText} = useLang();
    const afterSubmit = () => setActiveView(ONBOARDING_BUILD_PANEL);

    const [{onSave, isWaiting, getFieldProps, values, updateValues}] = useFormLocal({
        rest: services.API_TUTORIAL_USER_ORIGIN,
        model: SelectOriginModel,
        afterSubmit,
        initialAutoLoad: false,
    });

    const [{isLoading}] = useFetchData({
        rest: services.API_TUTORIAL_USER_ORIGIN,
        afterSubmit: (data) => updateValues(SelectOriginModel.parseDTO(data)),
    })

    const formatOptionLabel = (option) => (
        <div style={{display: "flex"}}>
            <div className="mr-1">{option.flag}</div>
            <div>{option.name}</div>
        </div>
    );

    const selectedOption = (option) => (
        <div style={{display: "flex"}}>
            <div className="mr-1">{option.flag}</div>
            <div className="font-medium">{option.name}</div>
        </div>
    );

    const selectedCountriesNames = ['103', '204', '174', '50', '84' ,'244'];

    const sortCountries = (countries) => countries.sort((a, b) => {
        const indexA = selectedCountriesNames.indexOf(a.id);
        const indexB = selectedCountriesNames.indexOf(b.id);

        if (indexA === -1 && indexB === -1) {
            return 0;
        } else if (indexA === -1) {
            return 1;
        } else if (indexB === -1) {
            return -1;
        }

        return indexA - indexB;
    });

    return (
        <CreateShopLayout
            title="whereYourBusinessIsLocatedLabel"
            desc="whereYourBusinessIsLocatedSubLabel"
            Img={<img width={30} className="mx-auto mb-2" src="/assets/images/svg/pin.svg" alt="pin"/>}
        >
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getLangText('selectBusinessLocationLabel')} - Selmo</title>
            </Helmet>
            <form
                id="onboarding-origin-step-form"
                className="w-full grow flex flex-col"
                onSubmit={onSave}
            >
                <div className="w-full sm:w-[380px] mx-auto animate-tutorialSlideAndFadeInDelay2 opacity-0">
                    {isLoading ?
                        <div className="animate-pulse">
                            <div className="h-[50px] bg-gray-200 rounded-xl dark:bg-gray-700 w-full relative">
                                <div className="absolute z-[1] left-[15px] top-[10px] bottom-[10px] bg-gray-300 rounded-xl dark:bg-gray-800 w-[150px]" />
                            </div>
                        </div> :
                        <AsyncSearchSelectFieldNew
                            id="custom-dropdown-default"
                            {...getFieldProps('country')}
                            label={getLangText('selectCountry2Label')}
                            rest={`${services.API_TUTORIAL_SEARCH_COUNTRIES}`}
                            formatOptionLabel={formatOptionLabel}
                            defaultValue={{
                                id: values.country,
                                name: values.country_name,
                                flag: values.flag,
                            }}
                            selectedOption={selectedOption}
                            className="p-0"
                            searchOnFocus
                            required={true}
                            withSearchInput={true}
                            sort={sortCountries}
                        />
                    }
                </div>
                <div
                    className="mt-auto sm:mx-0 -mx-6 bg-[#F9FAFB] sm:w-full p-[20px] rounded-b-[12px] text-right">
                    <Button
                        isWaiting={isWaiting}
                        type="submit"
                        title="goNextLabel"
                        className="bg-[#3C61EA] overflow-hidden  button text-xs sm:text-sm font-semibold rounded-[8px] px-[20px] py-[11px] text-white transition-all hover:bg-[#1c4ed8]"
                    />
                </div>
            </form>
        </CreateShopLayout>
    );
};

export default SelectOrigin;
