import React, {useEffect, useRef, useState} from 'react';
import {VariableSizeList} from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import Message from "./Message";
import ListActions from "../ListActions";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {getStateRoot} from "../listReducer";
import EmptyList from "../../../../../shared/EmptyList";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import _ from "lodash";
import {isMobile} from "../../../../../shared/helpers/isMobile";

const VirtualizedList = ({isWaiting, listRef, zoomValue, canAddClient}) => {
    const dispatch = useDispatch();
    const {userData} = useSelector((state) => state.session);
    const mobile = isMobile();

    const {
        reversedItems,
        scrollToBottom,
        updateScrollAfterSocketMessage,
        waitingForFirstPage,
    } = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));

    const innerRef = useRef({});
    const rowHeights = useRef({});
    const [scrollTouched, setScrollTouched] = useState(false);
    const facebookId = userData.facebook_id;
    const {getLangText} = useLang();

    useEffect(() => {
        dispatch(ListActions.loadReversedData())
    }, [])

    useEffect(() => {
        if (scrollToBottom && !!listRef?.current?.scrollToItem && reversedItems.length && !_.isEmpty(rowHeights.current)) {
            listRef.current?.scrollToItem(reversedItems.length - 1, 'end')
            setTimeout(() => {
                listRef.current?.scrollToItem(reversedItems.length - 1, 'end')
                dispatch(ListActions.setScrollToBottom(false))
            }, 100)
            dispatch(ListActions.setScrollToBottom(false))
        }
    }, [listRef.current, reversedItems.length, rowHeights.current, scrollToBottom])

    const MessageComponent = ({index, style}) => {
        const rowRef = useRef({})

        const item = reversedItems[index] || {};

        useEffect(() => {
            if (rowRef.current && !item.removed) {
                setRowHeight(index, rowRef.current.clientHeight)
            }
        }, [rowRef, reversedItems, item])

        if (item.removed) return null;

        return (
            <div style={style}>
                <div ref={rowRef}>
                    <Message
                        canAddClient={canAddClient}
                        key={item?.id}
                        item={item}
                    />
                </div>
            </div>
        );
    };

    const setRowHeight = (index, size) => {
        listRef.current.resetAfterIndex(0);
        rowHeights.current = {...rowHeights.current, [index]: size};
    };

    const getRowHeight = (index) => {
        return rowHeights.current[index] || 100;
    };

    const handleScroll = ({scrollDirection, scrollOffset, scrollUpdateWasRequested}) => {
        setScrollTouched(true)

        if (scrollDirection === 'backward' && scrollOffset === 0 && !isWaiting && !scrollUpdateWasRequested) {
            dispatch(ListActions.loadNewPageVirtualized(facebookId, listRef))
        }

        if (Math.round(scrollOffset) === (innerRef?.current?.clientHeight - listRef?.current?.props?.height)) {
            setScrollTouched(false)
        }
    };

    const scrollToLastElement = () => {
        listRef.current?.scrollToItem(reversedItems.length - 1, 'end');
        setTimeout(() => {
            listRef.current?.scrollToItem(reversedItems.length - 1, 'end')
        })
    }

    useEffect(() => {
        if (!scrollTouched && updateScrollAfterSocketMessage && !!listRef?.current?.scrollToItem) {
            listRef.current?.scrollToItem(reversedItems.length - 1, 'end');
            setTimeout(() => {
                listRef.current?.scrollToItem(reversedItems.length - 1, 'end')
                dispatch(ListActions.updateScrollAfterSocketMessage(false))
            }, 100)
            dispatch(ListActions.updateScrollAfterSocketMessage(false))
        }
    }, [scrollTouched, updateScrollAfterSocketMessage])


    return (
        <EmptyList
            items={reversedItems}
            isLoading={waitingForFirstPage}
            className="small-size pt-5"
            imgSrc="/assets/images/illustration/choose-conversation.svg"
            title={getLangText('noCommentsLabel')}
        >
            {(scrollTouched && reversedItems?.length > 20) &&
                <button
                    type="button"
                    onClick={scrollToLastElement}
                    className="text-xs max-w-[300px] mx-auto font-bold shadow-md absolute top-20 ring-1 ring-gray-100 left-16 right-16 bg-white rounded-xl z-[1] text-center p-2 animate-[fadeIn_.3s_ease_forwards]"
                >
                    {getLangText('seeTheNewest')}
                </button>
            }
            <AutoSizer>
                {({height, width}) => (
                    <div
                        id="scrollableDiv"
                        className="scroll-content pb-0 pt-0 overflow-visible"
                        style={{zoom: zoomValue, width, height: height - (mobile ? 52 : 62)}}
                    >
                        <VariableSizeList
                            className="virtualization-list"
                            ref={listRef}
                            height={height - (mobile ? 52 : 62)}
                            width={width}
                            itemCount={reversedItems.length}
                            itemSize={getRowHeight}
                            onScroll={handleScroll}
                            estimatedItemSize={100}
                            innerRef={innerRef}
                        >
                            {MessageComponent}
                        </VariableSizeList>
                    </div>
                )}
            </AutoSizer>
        </EmptyList>
    );
};

export default VirtualizedList;
