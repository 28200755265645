import React, {useState} from 'react';
import useLang from "../../../../src_shared/hooks/useLang";
import Collapse from "../../shared/components/Collapse/Collapse";
import moment from "moment/moment";
import s from '../../shared/components/Collapse/Collapse.module.css'
import cn from "clsx";
import {RouterPaths} from "../../../routes/RouterPath";
import {convertToUserTimeZone} from "../../shared/helpers/dateHelpers";

const FEED = 'selmoapp://feed/'

const getCorrectHistoryUrl = (url, getLangText) => {
    if (url.includes(FEED)) {
        return getLangText('redirectToVideoInApp')
    }
    return url;
}

const getCorrectRedirectUrl = (url) => {

    if (url.includes(FEED)) {
        const number = url.match(/(\d+)$/)[0];
        return `${RouterPaths.SalesPost}/${number}`
    }

    return url;
}

const SentHistory = ({history}) => {
    const {getLangText} = useLang();
    const [isActive, setActive] = useState(history[0]?.id)

    if (!history?.length) return null;

    return (
        <div className="default-shadow-box bigger-y-padd mb-3">
            <h2 className="font-bold mb-3.5">{getLangText('notificationsSentHistoryLabel')}</h2>
            <div className="w-full">
                {history.map((item, index) => (
                    <Collapse
                        key={index}
                        id={item.id}
                        setActive={setActive}
                        isActive={isActive}
                        styles={s.borderRoot}
                        header={(isActive) => (
                            <div className="flex items-center">
                                <div className="mr-2">
                                    <img width={14} src="/assets/images/svg/circle-check.svg"/>
                                </div>
                                <div className="grow">
                                    <div className="text-[#101827] font-bold text-sm flex items-center space-x-2">
                                        <span>{convertToUserTimeZone(item.date).format('DD.MM.YYYY')}</span>
                                        <span className="text-desc text-[8px] font-normal">•</span>
                                        <span
                                            className="text-desc font-medium">{convertToUserTimeZone(item.date).format('HH:mm:ss')}</span>
                                    </div>
                                </div>
                                <div className="ml-auto flex items-center">
                                    {item.users &&
                                        <div className="mr-2 hidden sm:flex items-center text-xs font-medium">
                                            <div
                                                className="w-[18px] h-[18px] rounded-full flex justify-center items-center bg-[#5FA5FA] mr-1">
                                                <i className="icon-clients text-[white] text-[10px]"/>
                                            </div>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: getLangText({
                                                        key: 'receivedClientsLabel',
                                                        data: [item.users],
                                                    })
                                                }}
                                            />
                                        </div>
                                    }
                                    <div
                                        className={cn('text-desc text-[8px]', isActive === item.id ? 'rotate-180' : '')}>
                                        <i className="icon-arrows"/>
                                    </div>
                                </div>
                            </div>
                        )}
                    >
                        <div className="text-xs font-medium mb-2">{getLangText('sentMessageContentLabel')}</div>
                        <div className="bg-[#F3F4F6] p-3 rounded-[5px]">
                            <div
                                className="text-xs font-medium text-[#4B5563]"
                                dangerouslySetInnerHTML={{__html: item.message}}
                            />
                            {item.url &&
                                <div className="bg-[#FFFFFF] flex items-center rounded-[5px] py-2 px-3 mt-2">
                                    <div className="overflow-hidden font-medium text-xs">
                                        <div
                                            className="mb-2">{getLangText('linkLabel')}</div>
                                        <div
                                            className="text-[#4B5563] underline text-ellipsis overflow-hidden">{getCorrectHistoryUrl(item.url, getLangText)}</div>
                                    </div>
                                    <a
                                        rel="noreferrer"
                                        target="_blank"
                                        className="button ml-auto border-button small-size"
                                        href={getCorrectRedirectUrl(item.url)}
                                    >
                                        {getLangText('previewLabel')}
                                    </a>
                                </div>
                            }
                        </div>
                    </Collapse>
                ))}
            </div>
        </div>
    );
};

export default SentHistory;
