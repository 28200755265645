import Model, {convertObjectProperties} from "../../src_shared/modules/model/Model";
import ValidationError from "../../src_shared/form/validation/ValidationError";
import {PAYMENTS_SUBSCRIPTION_TYPE} from "../../components/app/shared/enums/PaymentsType";
import {INVOICE_DATA_COMPANY} from "../../components/app/shared/enums/InvoiceDataEnums";

export const validateInvoiceData = (value, data) => {
	if (data.invoice !== INVOICE_DATA_COMPANY) {
		return false;
	}
	return ValidationError.notEmpty(value, data)
}
export const validateInvoiceDataNip = (value, data) => {
	if (data.invoice !== INVOICE_DATA_COMPANY) {
		return false;
	}

	if (+data.country_id !== 103) {
		return ValidationError.combine(
			ValidationError.notEmpty,
			ValidationError.minLength(5),
		)(value, data)
	}
	return ValidationError.combine(
		ValidationError.validateNip,
		ValidationError.minLength(5),
	)(value, data)
}

export const validateInvoiceDataZipCode = (value, data) => {
	if (data.invoice !== INVOICE_DATA_COMPANY) {
		return false;
	}
	if (+data.country_id !== 103) {
		return ValidationError.notEmpty(value, data);
	}
	return ValidationError.combine(
		ValidationError.notEmpty,
		ValidationError.validateZipCode,
	)(value, data)
}

export class PaymentsModel extends Model {
	getModel() {
		return {
			subscriptions: [],
			functionalities: [],
			activeSubscriptionInfo: {},
			invoiceData: {},
			selected_subscription: '1',
			selected_payment: PAYMENTS_SUBSCRIPTION_TYPE[0].value,
			extend_plan: '1',
			invoice: INVOICE_DATA_COMPANY,
			country_id: 0,
			nip: '',
			zip_code: '',
			company_name: '',
			city: '',
			country_data: {},
			currencyText: '',
		};
	}

	getValidators() {
		return {
			selected_subscription: ValidationError.notEmpty,
			selected_payment: ValidationError.notEmpty,
			country_id: ValidationError.notEmpty,
			nip: validateInvoiceDataNip,
			company_name: validateInvoiceData,
			address: validateInvoiceData,
			zip_code: validateInvoiceDataZipCode,
			city: ValidationError.notEmpty,
		};
	}

	parseDTO(data) {
		const preparedInvoiceData = data?.invoiceData ? {
			country: {
				name: data?.invoiceData?.country_data?.name,
				id: data?.invoiceData?.country_data?.id,
			},
			country_id: data?.invoiceData?.country_id,
			nip: data?.invoiceData?.nip,
			zip_code: data?.invoiceData?.zip_code,
			city: data?.invoiceData?.city,
			company_name: data?.invoiceData?.name,
			address: data?.invoiceData?.address,
		} : {};

		return convertObjectProperties(
			data,
			{},
			{
				...preparedInvoiceData,
				activeSubscriptionInfo: {},
				invoiceData: {},
				selected_subscription: data.default_subscription,
			},
		);
	}

	buildDTO(data) {
		data.selected_subscription = !!+data.extend_plan ? data.selected_subscription : null;
		const invoice_data = {
			country_id: data.country_id,
			nip: data.nip,
			name: data.company_name,
			address: data.address,
			city: data.city,
			zip_code: data.zip_code,
		}
		delete data.extend_plan;
		delete data.default_subscription;
		delete data.functionalities;
		delete data.subscriptions;
		delete data.activeSubscriptionInfo;
		delete data.invoiceData;
		delete data.nip;
		delete data.company_name;
		delete data.address;
		delete data.city;
		delete data.zip_code;
		delete data.country_data;
		delete data.country;

		if (data.invoice === INVOICE_DATA_COMPANY) {
			delete data.invoice;
			return {
				invoice_data,
				...data,
			}
		}

		return data;
	}
}

export default new PaymentsModel();
