import Model from "../../../../src_shared/modules/model/Model";
import ValidationError from "../../../../src_shared/form/validation/ValidationError";

export class CategoryModel extends Model {
	getModel() {
		return {
			id: 0,
			name: '',
			active: '1',
			is_knowledge_base: '1',
		};
	}

	getValidators() {
		return {
			name: ValidationError.notEmpty,
			active: ValidationError.skip,
			is_knowledge_base: ValidationError.skip,
		};
	}
}

export default new CategoryModel();
