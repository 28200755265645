import React from 'react';
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../../routes/RouterPath";
import {getStatusBox} from "../../../../live/details/platform/notShownList/components/table/Table";
import useLang from "../../../../../../src_shared/hooks/useLang";
import {useUnitPrice} from "../../../../shared/helpers/Price";
import ServerImage from "../../../../shared/components/serverImage/ServerImage";

const SelectedProducts = ({products, setValue}) => {
	const {getLangText} = useLang();
	const {getPrice} = useUnitPrice();

	const removeProduct = (item) => {
		const filtered = products.filter((i) => i.id !== item.id)
		setValue('products', filtered)
	}

	return (
		products.map((item) => (
			<div
				key={item.id}
				className="sortable-tree-item no-border no-drag p-0">
				<div className="category-row product-row">
					{/*<div className="number mr-2">*/}
					{/*	{item.shop_product_id}*/}
					{/*</div>*/}
					<Link to={`${RouterPaths.ProductsList}/edytuj/${item.id}`}
						  className="d-flex align-items-lg-center flex-grow-1">
						<figure>
							{item.photo_small && item.photo_small !== '0' ?
								<ServerImage src={item.photo_small} alt={item.name}/> :
								<img src="/assets/images/default/product.svg" alt=""/>
							}
						</figure>
						<div className="flex-grow-1">
							<div className="label">
								{item.name}
							</div>
							<div className="data text-xs font-normal">
								{getPrice(item.price)}<span className="text-[8px] text-gray-600 inline-block mx-0.5 align-bottom">{' • '}</span> {getLangText('codeLabel')} <strong>{item.selmo_code}</strong>
							</div>
						</div>
					</Link>
					{getStatusBox(item.squ, getLangText)}
					<button
						onClick={() => removeProduct(item)}
						className="button icon-button lgrey p-4"
					>
						<i className="icon-bin"/>
					</button>
				</div>
			</div>
		))
	);
};

export default SelectedProducts;
