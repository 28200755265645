export const setGenerateLabelModalVisibility = (toggle, data) => {
    return {
        type: 'GENERATE_LABEL_MODAL_TOGGLE_VISIBILITY',
        toggle,
        data,
    }
}

export const setGenerateLabelModalSuccess = (toggle) => {
    return {
        type: 'GENERATE_LABEL_MODAL_SUCCESS',
        toggle,
    }
}
