import {prefix} from "./formReducer.js";
import {getStateRoot} from "./formReducer.js";
import FormActions from "../../../../../../src_shared/form/FormActions.js";

export class OrderListFiltersPanelActions extends FormActions {

}

export const getInstance = () => new OrderListFiltersPanelActions({
	prefix,
	getStateRoot
});

export default getInstance();

