import React from 'react';
import useLang from "../../../src_shared/hooks/useLang";
import IntegrationBoxes from "../integrations/dashboard/IntegrationBoxes";
import MetaBoxes from "../meta/dashboard/MetaBoxes";
import {Helmet} from "react-helmet";
import TabsWrapper from "../components/TabsWrapper";
import GeneralBoxes from "../general/dashboard/GeneralBoxes";
import PaymentShipmentBoxes from "../shippings/dashboard/PaymentShipmentBoxes";
import ProfileBoxes from "../account/dashboard/ProfileBoxes";

const Dashboard = () => {
    const {getLangText} = useLang();

    return (
        <div
            className={`settings-page sub-settings-page loader-parent list-with-tabs separated-box no-borders`}>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getLangText('asideSettingsLabel')} - Selmo</title>
            </Helmet>
            <TabsWrapper>
                <div className="settings-wrapper">
                    <GeneralBoxes />
                    <PaymentShipmentBoxes />
                    <MetaBoxes/>
                    <IntegrationBoxes/>
                   <ProfileBoxes />
                </div>
            </TabsWrapper>
        </div>
    );
};

export default Dashboard;
