import React from 'react';
import Modal from "react-bootstrap/Modal";
import Loader from "../../messenger/loader/Loader";
import useLang from "../../../../src_shared/hooks/useLang";

const BlackConfirmModal = ({
                               show,
                               hide,
                               title,
                               subTitle,
                               children,
                               onSubmit,
                               saveButtonClass = 'primary',
                               isWaiting = false,
                               saveButton,
                               dialogClassName = '',
                               withModalFooter = true,
                           }) => {
    const {getLangText} = useLang();

    return (
        <Modal
            dialogClassName={`default-modal small-modal black-modal ${dialogClassName}`}
            show={show}
            onHide={hide}
        >
            <Modal.Header>
                <Modal.Title>
                    <div className="font-bold text-[#fff] text-lg mt-2">
                        {getLangText(title)}
                    </div>
                    <div className="text-sm text-[#C0C0C0] font-medium">
                        {getLangText(subTitle)}
                    </div>
                </Modal.Title>
                <button
                    onClick={hide}
                    type="button"
                    style={{color: '#8b8d92'}}
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            {withModalFooter &&
                <Modal.Footer>
                    <button
                        onClick={hide}
                        type="button"
                        className="button border-button"
                    >
                        {getLangText('cancelButton')}
                    </button>
                    <button
                        onClick={onSubmit}
                        type="button"
                        className={`button ${saveButtonClass} px-0 ${isWaiting ? 'loading-button' : ''}`}
                    >
                        {isWaiting && <Loader/>}
                        {getLangText(saveButton)}
                    </button>
                </Modal.Footer>
            }
        </Modal>
    );
};

export default BlackConfirmModal;
