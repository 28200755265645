import React, {useMemo} from 'react';
import {RouterPaths} from "../../../../../../routes/RouterPath.js";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {
	CLIENTS_TABLE_COLUMN,
	CLIENTS_TABLE_COLUMN_BALANCE, CLIENTS_TABLE_COLUMN_EMAIL, CLIENTS_TABLE_COLUMN_MONEY_SPENT,
	CLIENTS_TABLE_COLUMN_NAME,
	CLIENTS_TABLE_COLUMN_NUMBER, CLIENTS_TABLE_COLUMN_ORDERS_COUNT, CLIENTS_TABLE_COLUMN_PHONE
} from "./ClientsTableColumnEnums.js";
import useTableColumn from "../../../../../orders/list/components/table/useTableColumn.js";
import {getPaymentReceivedCount} from "../../../../details/info/clientPaymentReceived/ClientPaymentReceived.jsx";
import {getStateRoot as getFiltersStateRoot} from "../../../../filters/filtersReducer.js";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import {useUnitPrice} from "../../../../../shared/helpers/Price";
import ClientAvatar from "../../../../../live/shared/Avatar";

const TableBody = ({items}) => {
	const {userData} = useSelector((state) => state.session);
	const {getLangText} = useLang();
	const {getPrice} = useUnitPrice();

	const {preparedTableColumn} = useTableColumn(CLIENTS_TABLE_COLUMN, 'clientsTableColumn', getFiltersStateRoot)
	const history = useHistory();

	const onTableRowClick = (id, e) => {
		const url = `${RouterPaths.ClientsList}/${id}/`;
		if (e.type === 'click') {
			history.push(url);
		} else if (e.type === 'contextmenu') {
			e.preventDefault();
			window.open(url);
		}
	}

	const onMouseDown = (id, e) => {
		const url = `${RouterPaths.ClientsList}/${id}/`;
		if (e.button === 1 || e.buttons === 4) {
			window.open(url);
		}
	}

	const getFieldContent = useMemo(() => (field, item) => {
		switch (field) {
			case CLIENTS_TABLE_COLUMN_NUMBER:
				return (
					<td
						key={field}
						data-thead="Nr"
						className="number with-mobile-thead inline-thead mb-lg-0 mb-1"
					>
						{item.shop_client_id}
					</td>
				)
			case CLIENTS_TABLE_COLUMN_NAME:
				return (
					<td
						key={field}
						className="name">
						<div className="avatar-row">
							<ClientAvatar
								photo={item.photo}
								type={item.social_type}
							/>
							{item.name ? item.name : 'Brak nazwy'}
						</div>
					</td>
				)
			case CLIENTS_TABLE_COLUMN_BALANCE:
				return (
					!!+userData.show_payment_received &&
					<td
						key={field}
						className="with-mobile-thead inline-thead w-lg-auto w-100 pl-sm-2 pl-0 my-sm-0 my-1">{getPaymentReceivedCount(item.balance, userData.currency_info.text, getLangText)}
					</td>
				)
			case CLIENTS_TABLE_COLUMN_EMAIL:
				return (
					<td
						key={field}
						className="w-sm-auto w-100">
						{item.email}
					</td>
				)
			case CLIENTS_TABLE_COLUMN_PHONE:
				return (
					<td
						key={field}
						className="w-sm-auto w-100 ">{item.phone}</td>
				)
			case CLIENTS_TABLE_COLUMN_ORDERS_COUNT:
				return (
					<td
						key={field}
						data-thead={`${getLangText('ordersLabel')}:`}
						className="with-mobile-thead inline-thead ">{item.orders_count}</td>
				)
			case CLIENTS_TABLE_COLUMN_MONEY_SPENT:
				return (
					<td
						key={field}
						data-thead={`${getLangText('spentMoneyLabel')}`}
						className="text-sm-right with-mobile-thead inline-thead w-lg-auto w-100">{getPrice(item.money_spent)}
					</td>
				)
			default:
				return null;
		}
	}, [preparedTableColumn])

	return (
		<tbody>
		{items.map((item) => (
			<tr
				onClick={(e) => onTableRowClick(item.shop_client_id, e)}
				onContextMenu={(e) => onTableRowClick(item.shop_client_id, e)}
				onMouseDown={(e) => onMouseDown(item.shop_client_id, e)}
				key={item.shop_client_id}
			>
				{preparedTableColumn.map((column) => getFieldContent(column, item))}
			</tr>
		))}
		</tbody>
	);
};

export default TableBody;
