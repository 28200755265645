import React, {useMemo} from 'react';
import useLang from "../../../../../src_shared/hooks/useLang";

const steps = [
    {id: 0, title: 'addNewPermissions', desc: 'facebookRequireNewPermissions'},
    {id: 1, title: 'downloadSelmoForBusiness', desc: ''},
    {id: 2, title: 'loginButton', desc: 'liveOverlayStep2Desc'},
    {id: 3, title: 'startLive', desc: 'liveOverlayStep3Desc'},
];

const FirstStepPage = ({refresh_token_link}) => {
    const {getLangText} = useLang();

    return (
        <div className="md:space-y-4">
            <div className="default-shadow-box bigger-y-padd">
                <div className="md:grid grid-cols-5 gap-10 md:px-20 md:py-20">
                    <div className="md:col-span-2 sm:mb-0 mb-4">
                        <img className="sm:w-[44px] mb-3" src="/assets/images/svg/stream-overlay.svg" alt="icon"/>
                        <div className="sm:text-3xl text-2xl font-bold">
                            {getLangText('overlayForLive')}
                        </div>
                        <div className="text-sm text-desc font-medium mt-3.5">
                            {getLangText('overlayForLiveDesc')}
                        </div>
                    </div>
                    <div className="col-span-3 md:pl-20">
                        <div className="bg-[#F6FCFF] rounded-xl border border-[rgba(95,165,250,0.2)] p-4">
                            <div className="font-bold">{getLangText('howToStartLiveWithOverlay')}</div>
                            <img
                                className="rounded-[8px] mt-2 mb-2"
                                src="/assets/images/unlocked-banner.webp"
                                alt=""
                            />
                            <div className="space-y-4 mt-3 mb-4">
                                {steps?.map((i) => (
                                    <div
                                        key={i.id}
                                        className={`flex items-start`}
                                    >
                                        {(!refresh_token_link && i.id === 0) ?
                                            <img
                                                className="min-w-[18px] mr-2 mt-0.5"
                                                src="/assets/images/svg/circle-check.svg"
                                                alt="circle check"
                                            /> :
                                            <div
                                                className="w-[18px] min-w-[18px] h-[18px] rounded-full bg-[#5FA5FA] flex items-center justify-center text-[10px] text-[#fff] font-bold mr-2 mt-0.5">
                                                {i.id + 1}
                                            </div>
                                        }
                                        <div className={(!refresh_token_link && i.id === 0) ? 'opacity-50' : ''}>
                                            <div
                                                className="text-[#101827] font-bold text-sm"
                                                dangerouslySetInnerHTML={{__html: getLangText(i.title)}}/>
                                            <div className="text-desc text-sm font-medium">{getLangText(i.desc)}</div>
                                            {(i.id === 0 && refresh_token_link) &&
                                                <a
                                                    href={refresh_token_link}
                                                    style={{
                                                        paddingTop: 8,
                                                        paddingBottom: 8,
                                                        paddingLeft: 12,
                                                        paddingRight: 12
                                                    }}
                                                    className="button primary small-size mt-1"
                                                >
                                                    {getLangText('addPermissions')}
                                                </a>
                                            }
                                            {i.id === 1 &&
                                                <a
                                                    className="mt-1 block"
                                                    href="https://apps.apple.com/ie/app/selmo-for-business/id6448353534?platform=iphone"
                                                    target="_blank"
                                                >
                                                    <img
                                                        className="min-h-[35px]"
                                                        src={`/assets/images/svg/app-store.svg`}
                                                        alt="app store"
                                                    />
                                                </a>
                                            }
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FirstStepPage;
