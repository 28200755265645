import React from 'react';
import Modal from "react-bootstrap/Modal";
import Loader from "../../../../messenger/loader/Loader";
import useLang from "../../../../../../src_shared/hooks/useLang";

const ConfirmModal = ({
                          show,
                          hide,
                          dialogClassName = 'small-modal',
                          submitButtonClass = 'primary',
                          headerClassName = '',
                          isWaiting,
                          title,
                          SubTitle,
                          saveButton = 'saveButton',
                          onSubmit,
                          children,
                          backdropClassName,
                          modalClasName,
                          disabledSubmitButton = false,
                          withModalFooter = true,
                      }) => {
    const {getLangText} = useLang();

    return (
        <Modal
            dialogClassName={`default-modal ${dialogClassName}`}
            show={show}
            onHide={hide}
            backdropClassName={backdropClassName}
            className={modalClasName}
        >
            <Modal.Header className={headerClassName}>
                <Modal.Title>
                    {title}
                    <div className="description">
                        {SubTitle && <SubTitle/>}
                    </div>
                </Modal.Title>
                <button
                    onClick={hide}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            {withModalFooter &&
                <Modal.Footer>
                    <button
                        onClick={hide}
                        type="button"
                        className="button border-button"
                    >
                        {getLangText('cancelButton')}
                    </button>
                    <button
                        disabled={disabledSubmitButton}
                        onClick={onSubmit}
                        type="button"
                        className={`button px-0 ${submitButtonClass} ${isWaiting ? 'loading-button' : ''}`}
                    >
                        {isWaiting && <Loader/>}
                        {getLangText(saveButton) || saveButton}
                    </button>
                </Modal.Footer>
            }
        </Modal>
    );
};

export default ConfirmModal;
