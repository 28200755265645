import React, {useState} from 'react';
import SelectOrigin from "./selectOrigin/SelectOrigin";
import BuildingPanel from "./buildPanel/BuildingPanel";

const ONBOARDING_SELECT_ORIGIN = 'ONBOARDING_SELECT_ORIGIN';
export const ONBOARDING_BUILD_PANEL = 'ONBOARDING_BUILD_PANEL';

const LastStepPage = () => {

    const [activeView, setActiveView] = useState(ONBOARDING_SELECT_ORIGIN);

    return (
        <>
            {activeView === ONBOARDING_SELECT_ORIGIN && <SelectOrigin setActiveView={setActiveView}/>}
            {activeView === ONBOARDING_BUILD_PANEL && <BuildingPanel/>}
        </>
    );
};

export default LastStepPage;
