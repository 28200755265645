import React, {useEffect, useState} from 'react';
import {services} from "../../../../../RestServices";
import useSubmitData from "../../../../../../src_shared/hooks/useSubmitData";
import Modal from "react-bootstrap/Modal";
import CreateOrder from "./CreateOrder";
import useFormLocal from "../../../../../../src_shared/hooks/useFormLocal";
import Model from "../../../../../../src_shared/modules/model/Model";
import SendSummaryMessage from "./SendSummaryMessage";
import {batch, useDispatch} from "react-redux";
import CountDownNotificationActions from "../../../../shared/components/notification/CountDownNotificationActions";
import {GO_TO_ORDER_NOTIFICATION} from "../../../../shared/enums/CountDownNotificationsTypes";
import {showAlert} from "../../../../shared/components/alert/AlertActions";

const CREATE_ORDER_VIEW = 'CREATE_ORDER_VIEW';
const SEND_MESSAGE_VIEW = 'SEND_MESSAGE_VIEW';

const CreateOrderModal = ({modalVisible, hideModal, selectedProduct, fetchData}) => {
    const [activeView, setActiveView] = useState(CREATE_ORDER_VIEW)
    const dispatch = useDispatch();

    const hideModalWithNotifications = (shop_order_id) => {
        batch(() => {
            hideModal();
            fetchData();
            dispatch(CountDownNotificationActions.setNotificationVisibility(true, GO_TO_ORDER_NOTIFICATION, {shop_order_id}))
            dispatch(showAlert('yuhuLabel', 'successfulCreatedLabel'))
        })
    }

    const afterSubmit = (response) => {
        if (!!response.summary_message) {
            updateValues({
                ...response,
                order_id: response.message,
                message: response.summary_message,
            })
            setActiveView(SEND_MESSAGE_VIEW)
        } else {
            hideModalWithNotifications(response.shop_order_id)
        }
    }

    const afterSendMessage = (shop_order_id) => {
        batch(() => {
            hideModal();
            fetchData();
            dispatch(CountDownNotificationActions.setNotificationVisibility(true, GO_TO_ORDER_NOTIFICATION, {shop_order_id}));
            dispatch(showAlert('yuhuLabel', 'successfulOrderCreatedAndSentSummaryLabel'));
        })
    }

    const [{onSave: createOrder, isWaiting: isWaitingForCreateOrder}] = useSubmitData({
        rest: `${services.API_PRODUCT_ORDER_FROM_RESERVE_LIST}/${selectedProduct.id}`,
        afterSubmit,
    });

    const [{
        onSave: sendMessage,
        isWaiting: isWaitingForSend,
        getFieldProps,
        updateValues,
        values,
    }] = useFormLocal({
        rest: services.API_MESSAGE,
        model: new Model(),
        initialAutoLoad: false,
        afterSubmit: () => afterSendMessage(values.shop_order_id),
    });

    useEffect(() => {
        updateValues({
            recipient: selectedProduct.user_id,
            instagramMessage: selectedProduct.social_type === 'instagram'
        })
    }, [])

    return (
        <Modal
            dialogClassName={`default-modal small-modal`}
            show={modalVisible}
            onHide={hideModal}
        >
            {activeView === CREATE_ORDER_VIEW &&
                <CreateOrder
                    createOrder={createOrder}
                    hideModal={hideModal}
                    isWaiting={isWaitingForCreateOrder}
                />
            }
            {activeView === SEND_MESSAGE_VIEW &&
                <SendSummaryMessage
                    getFieldProps={getFieldProps}
                    sendMessage={sendMessage}
                    isWaiting={isWaitingForSend}
                    hideModal={() => hideModalWithNotifications(values.shop_order_id)}
                />
            }
        </Modal>
    );
};

export default CreateOrderModal;
