import React from 'react';
import SettingsBox from "../../components/SettingsBox";
import useLang from "../../../../src_shared/hooks/useLang";
import {RouterPaths} from "../../../routes/RouterPath";

export const SETTINGS_PROFILE = [
    {
        id: 0,
        title: 'settings_nav_subscription',
        desc: 'settings_nav_subscription_description',
        imgSrc: '/assets/images/settings/subscription.svg',
        path: RouterPaths.Payments
    },
    {
        id: 1,
        title: 'settings_nav_password',
        desc: 'settings_nav_password_description',
        imgSrc: '/assets/images/settings/preferences.svg',
        path: RouterPaths.SettingsAccountChangePassword
    },
]
const ProfileBoxes = () => {
    const {getLangText} = useLang();

    return (
        <section>
            <div className="section-title">{getLangText('settings_nav_profile_settings')}</div>
            <div className="settings-box-wrapper border-boxes">
                {SETTINGS_PROFILE.map((item) => (
                    <SettingsBox
                        key={item.id}
                        item={item}
                    />
                ))}
            </div>
        </section>
    );
};

export default ProfileBoxes;
