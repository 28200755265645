import React, {useState} from 'react';
import useFetchData from "../../../../src_shared/hooks/useFetchData";
import {services} from "../../../RestServices";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import useLang from "../../../../src_shared/hooks/useLang";
import ServerImage from "../../../app/shared/components/serverImage/ServerImage";
import ComponentLoader from "../../../../src_shared/components/ui/componentLoader/ComponentLoader";
import CustomIntegrationModal from "./CustomIntegrationModal";

const CustomIntegration = ({match: {params: {id}}}) => {
    const {getLangText} = useLang();
    const [showModal, setShowModal] = useState(false);

    const [{data, isLoading, fetchData}] = useFetchData({
        rest: `${services.API_SETTINGS_INTEGRATIONS}/${id}`,
        initialData: {
            fields: [],
            integration_info: {},
            has_requested: false,
        },
    })

    if (isLoading) return <ComponentLoader/>

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                {isLoading ?
                    <title>{`${getLangText('settings_nav_integrations')} - Selmo`}</title> :
                    <title>{`${data.integration_info.name} - Selmo`}</title>
                }
            </Helmet>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <Link to={RouterPaths.Settings}>{getLangText('asideSettingsLabel')}</Link>
                    </li>
                    <li>
                        <Link to={RouterPaths.SettingsIntegration}>{getLangText('settings_nav_integrations')}</Link>
                    </li>
                    <li className="active">
                        <div>{data.integration_info.name}</div>
                    </li>
                </ul>
            </div>
            <div className="row small-padd-row">
                <div className="col-lg-7">
                    <div className="settings-page sub-settings-page export-sub-settings">
                        <div className="header-wrapper no-border pb-0">
                            <ServerImage
                                className="h-[20px] mb-2"
                                src={data.integration_info.photo}
                            />
                            <h1>
                                {data.integration_info.name}
                            </h1>
                            <div className="description">
                                {data.integration_info.description}
                            </div>
                        </div>
                        <div className="settings-form pt-3">
                            <div className="steps-wrapper">
                                <div className="step border-step">
                                    {data.has_requested ?
                                        <div className="step-header">
                                            <div className="mr-2">
                                                <i className="icon-info-c text-orange-400 text-xl"/>
                                            </div>
                                            <div className="title d-block">
                                                <div>
                                                    {getLangText('duringTheVerification')}
                                                </div>
                                            </div>
                                        </div> :
                                        <div className="step-header">
                                            <div className="number">
                                                1
                                            </div>
                                            <div className="title d-block">
                                                <div>
                                                    {getLangText('checkIfYouQualify')}
                                                </div>
                                            </div>
                                            <button
                                                onClick={() => setShowModal(true)}
                                                type="button"
                                                className="button primary small-size ml-auto text-center"
                                            >
                                                {getLangText('check')}
                                            </button>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<div className="col-md-5 mt-md-0 mt-3">*/}
                {/*    <div className="default-shadow-box bigger-y-padd payment-benefits-wrapper">*/}
                {/*        <div className="label">{getLangText('paymentsHowItWorksLabel')}</div>*/}
                {/*        <ul className="benefits-list">*/}
                {/*            <li>*/}
                {/*                <i className="icon-tick-c"/>*/}
                {/*                {getLangText('oneCLickGenerateLabelsLabel')}*/}
                {/*            </li>*/}
                {/*            <li>*/}
                {/*                <i className="icon-tick-c"/>*/}
                {/*                {getLangText('accessToMoreCarriersLabel')}*/}
                {/*            </li>*/}
                {/*            <li>*/}
                {/*                <i className="icon-tick-c"/>*/}
                {/*                {getLangText('automaticTrackingLinksLabel')}*/}
                {/*            </li>*/}
                {/*            <li>*/}
                {/*                <i className="icon-tick-c"/>*/}
                {/*                {getLangText('saveTimeLabel')}*/}
                {/*            </li>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
            {showModal &&
                <CustomIntegrationModal
                    id={id}
                    data={data}
                    fetchData={fetchData}
                    closeModal={() => setShowModal(false)}
                    showModal={showModal}
                />
            }
        </>
    );
};

export default CustomIntegration;
