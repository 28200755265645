import Model from "../../../../../../src_shared/modules/model/Model";
import FormActions from "../../../../../../src_shared/form/FormActions";
import {getStateRoot, prefix} from "./formReducer";
import {services} from "../../../../../RestServices";

export class OrderDetailsInpostShippingFormActions extends FormActions {
}

export const getInstance = () => new OrderDetailsInpostShippingFormActions({
	getStateRoot,
	prefix,
	model: new Model(),
	restService: services.API_ORDER_SHIPPING_METHODS,
});

export default getInstance();
