import React from 'react';
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import useLang from "../../../../../../src_shared/hooks/useLang";

const PackedButton = ({item, setValue, className}) => {
	const isPacked = item.is_packed === '1';
	const {getLangText} = useLang();

	return (
		<button
			type="button"
			className={`icon-button packed-button ${isPacked ? 'packed' : ''} ${className}`}
			onClick={setValue}
		>
			{item.is_packed === '1' ?
				<OverlayTrigger
					placement='top'
					overlay={
						<Tooltip id="packed">
							{getLangText('markAsUnPackagedLabel')}
						</Tooltip>
					}
				>
					<i className="icon-packed"/>
				</OverlayTrigger>
				:
				<OverlayTrigger
					placement='top'
					overlay={
						<Tooltip id="unpacked">
							{getLangText('markAsPackedLabel')}
						</Tooltip>
					}
				>
					<i className="icon-unpacked"/>
				</OverlayTrigger>
			}
		</button>
	);
};

export default PackedButton;
