import React from 'react';
import Field from "../../../../../../src_shared/components/form/field/Field";
import useLang from "../../../../../../src_shared/hooks/useLang";
import Loader from "../../../../shared/Loader";
import {services} from "../../../../../RestServices";
import useFormLocal from "../../../../../../src_shared/hooks/useFormLocal";
import ShippyProSenderDataModel from "../../../../../../modules/models/settings/ShippyProSenderDataModel";
import AsyncSearchSelectField
	from "../../../../../../src_shared/components/form/selectField/asyncSearchSelectField/AsyncSearchSelectField";
import ShippyProWrapper from "../Form";
import {RouterPaths} from "../../../../../routes/RouterPath";

const SenderDataForm = () => {
	const {getLangText} = useLang()
	const rest = services.API_SETTINGS_SHIPPYPRO;

	const [{onSave, isWaiting, getFieldProps, isLoading, values}] = useFormLocal({
		rest,
		method: 'post',
		model: ShippyProSenderDataModel,
	});

	const formatOptionLabel = (option) => (
		<div style={{display: "flex"}}>
			<div className="mr-1">{option.flag}</div>
			<div>{option.name}</div>
		</div>
	);


	return (
		<>
			<div className="header-bar breadcrumbs-in-react-app">
				<ul className="breadcrumbs">
					<li>
						<a href={RouterPaths.Settings}>
							{getLangText('asideSettingsLabel')}
						</a>
					</li>
					<li className="active">
						<div>ShippyPro</div>
					</li>
				</ul>
			</div>
			<ShippyProWrapper>
				<div className="loader-parent">
					<Loader isLoading={isLoading}/>
					<div className="settings-form pt-3">
						<h2 className="mb-3">
							{getLangText('senderDataLabel')}
						</h2>
						<Field
							{...getFieldProps('name')}
							label={getLangText('nameAndSurnameLabel')}

						/>
						<Field
							{...getFieldProps('company')}
							label={getLangText('companyNameLabel')}

						/>
						<div className="row">
							<div className="col-md-6">
								<Field
									{...getFieldProps('street')}
									label={getLangText('streetNumberLabel')}

								/>
							</div>
							<div className="col-md-6">
								<Field
									{...getFieldProps('city')}
									label={getLangText('cityLabel')}

								/>
							</div>
							<div className="col-md-6">
								<Field
									{...getFieldProps('zip_code')}
									label={getLangText('zipCodeLabel')}

								/>
							</div>
							<div className="col-md-6">
								<AsyncSearchSelectField
									{...getFieldProps('country')}
									label={getLangText('countryLabel')}
									rest={services.API_COUNTRIES}
									formatOptionLabel={formatOptionLabel}
									defaultValue={{
										id: values.country,
										name: values.country_name
									}}
									placeholder={getLangText('countryLabel')}
									optionsParser={(options) => options?.map((i) => ({
										...i,
										id: i.shortcut,
									}))}
									// setExtraValues={updateCountryName}
									searchOnFocus
								/>
							</div>
							<div className="col-md-6">
								<Field
									{...getFieldProps('phone')}
									label={getLangText('phoneLabel')}

								/>
							</div>
							<div className="col-md-6">
								<Field
									{...getFieldProps('email')}
									label={getLangText('emailAddressLabel')}
								/>
							</div>
						</div>
						<div className="button-place mt-3">
							<button
								type="button"
								className={`button primary ${isWaiting ? 'loading-button' : ''}`}
								onClick={onSave}
							>
								{isWaiting && <Loader isLoading/>}
								{getLangText('saveButton')}
							</button>
						</div>
					</div>
				</div>
			</ShippyProWrapper>
		</>
	);
};

export default SenderDataForm;
