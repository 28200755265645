export const ORLEN_PACZKA_LABEL_SMALL = 'S';
export const ORLEN_PACZKA_LABEL_MEDIUM = 'M';
export const ORLEN_PACZKA_LABEL_BIG = 'L';

const OrlenPaczkaLabels = [
	{
		id: ORLEN_PACZKA_LABEL_SMALL,
		label: 'Rozmiar S',
		sizes: 'wymiary: 60x38x8, waga: do 25 kg',

	},
	{
		id: ORLEN_PACZKA_LABEL_MEDIUM,
		label: 'Rozmiar M',
		sizes: 'wymiary: 60x38x19, waga: do 25 kg',
	},
	{
		id: ORLEN_PACZKA_LABEL_BIG,
		label: 'Rozmiar L',
		sizes: 'wymiary: 60x41x38, waga: do 25 kg',
	},
];

export default OrlenPaczkaLabels;
