import React, {useEffect, useState} from 'react';
import axios from "axios";
import Loader from "../shared/Loader";
import {selmoUrl} from "../../../src_shared/api/api";
import useLang from "../../../src_shared/hooks/useLang";
import CopyButton from "../../../src_shared/components/form/copyButton/CopyButton";
import api from "../../../services/axios/axios";

const PartnersProgram = () => {
    const [partnerCode, setPartnerCode] = useState('');
    const [waiting, setWaiting] = useState(true);
    const {getLangText} = useLang();

    const getCode = () => {
        setWaiting(true)
        api.get(`${selmoUrl}/api/partnerCode`)
            .then(({data}) => setPartnerCode(data.partner_code))
            .finally(() => setWaiting(false));
    };

    useEffect(() => {
        getCode();
    }, [])

    return (
        <div className="settings-page sub-settings-page help-contact-box partners-page mb-sm-4">
            <div className="partners-page-container">
                <figure className="icon-place">
                    <img src="/assets/images/svg/refferal.svg" alt=""/>
                </figure>
                <h1>{getLangText('partnersTitle')}</h1>
                <div className="sub-title">
                    {getLangText('partnersSubTitle')}
                </div>
                <div className="border-step-box">
                    <div className="step-header">
                        <div className="step-circle"><span>1</span></div>
                        <div>
                            <div className="title">{getLangText('partnersStepOneTitle')}</div>
                            <div className="description">{getLangText('partnersStepOneDescription')}</div>
                        </div>
                    </div>
                    <div className="step-body">
                        <div className="form-group with-copy-btn">
                            <label className="control-label">{getLangText('partnersYourCodeLabel')}</label>
                            <div className="form-control loader-parent">
                                <Loader isLoading={waiting}/>
                                <span>{partnerCode}</span>
                                <CopyButton
                                    copyText={partnerCode}
                                    copyButtonText={getLangText('copyButton')}
                                    copiedButtonText={getLangText('copiedLabel')}
                                    buttonClassName="primary"
                                    withoutTooltip={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="border-step-box">
                    <div className="step-header">
                        <div className="step-circle"><span>2</span></div>
                        <div>
                            <div className="title">{getLangText('partnersStepTwoTitle')}</div>
                            <div className="description"
                                 dangerouslySetInnerHTML={{__html: getLangText('partnersStepTwoDescription')}}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PartnersProgram;
