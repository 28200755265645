import Composition from "../../components/app/shared/helpers/Composition";
import DataReducer from "../form/data/DataReducer";

export const getReduceMap = () => new Composition(
    DataReducer.reduceMap,
    {
        RESET_ALL_FILTERS: (state) => (
            {...state, data: state.emptyData}
        ),
    },
);

export default {
    getReduceMap,
};
