import React, {useState} from 'react';
import SwitchField from "../../../../../../src_shared/components/form/switchField/SwitchField";
import VariantsSquModal from "../../../form/variantsSquModal/VariantsSquModal";
import useLang from "../../../../../../src_shared/hooks/useLang";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {getStateRoot as getVariantSquModalStateRoot} from "../../../form/variantsSquModal/formReducer";
import VariantRow from "./VariantRow";
import EditProductModalFormActions from "../../../modalForm/FormActions";
import ReplenishStock from "./components/ReplenishStock";
import {
    USER_PERMISSIONS_PRODUCTS_ADD,
    USER_PERMISSIONS_PRODUCTS_EDIT
} from "../../../../shared/enums/UserPermissionsTypes";
import UserPermissionsWrapper from "../../../../../routes/UserPermissionsWrapper";

const Variants = ({
                      getFieldProps,
                      values,
                      setNestedValue,
                      inModal,
                      validation,
                      setValue,
                      FormActions,
                      userData,
                      defaultValues
                  }) => {
    const {getLangText} = useLang();
    const dispatch = useDispatch();
    const [hasVariantNameAutoFocus, setHasVariantNameAutoFocus] = useState(false);
    const {modalVisible} = useSelector(createSelector(getVariantSquModalStateRoot, (stateRoot) => stateRoot));

    const addNewVariant = () => dispatch(FormActions.addNewVariant());

    const onSwitchChange = (field, value) => {
        setValue(field, value)
        if (!!+value) {
            setHasVariantNameAutoFocus(true);
        }
        if (values.sizes.length === 1 && !values.sizes[0]?.sizePrice) {
            setNestedValue('sizes', values.sizes[0]?.id, 'sizePrice', values.price)
        }
    }

    const addProduct = () => {
        dispatch(EditProductModalFormActions.data.updateValues({
            name: values.name,
            price: values.price,
        }))
        dispatch(EditProductModalFormActions.modal.showModal())
    }

    return (
        <section className={!inModal ? 'default-shadow-box big-padd mb-3' : 'mt-2'}>
            {!inModal &&
                <div className="left-side">
                    <h2 className="font-bold mb-3.5">{getLangText('variantsLabel')}</h2>
                </div>
            }
            <div className="form-col">
                <SwitchField
                    {...getFieldProps('has_sizes')}
                    setValue={onSwitchChange}
                    label={getLangText('tickIfProductHasVariants1Label')}
                    subLabel={getLangText('sizeOrColorLabel')}
                    id="has_sizes"
                    className="switch-form align-items-start inline-label big-label light-version mb-0"
                />
                {!!+values.has_sizes &&
                    <>
                        <div className="mt-3">
                            {!inModal &&
                                <UserPermissionsWrapper allowedPermissions={[USER_PERMISSIONS_PRODUCTS_EDIT]}>
                                    <div className='flex items-center mb-2'>
                                        <h2 className="font-bold">{getLangText('variantsLabel')}</h2>
                                        {!!+defaultValues.has_sizes &&
                                            <div className="ml-auto">
                                                <ReplenishStock
                                                    values={values}
                                                />
                                            </div>
                                        }
                                    </div>
                                </UserPermissionsWrapper>
                            }
                            <div className="overflow-x-auto custom-thin-scrollbar">
                                {values.sizes.map((size, index) => (size.deleted !== '1' &&
                                    <VariantRow
                                        key={size.id}
                                        size={size}
                                        hasVariantNameAutoFocus={hasVariantNameAutoFocus}
                                        setNestedValue={setNestedValue}
                                        values={values}
                                        validation={validation}
                                        getFieldProps={getFieldProps}
                                        inModal={inModal}
                                        userData={userData}
                                        index={index}
                                    />
                                ))}
                            </div>
                            <button
                                type="button"
                                onClick={addNewVariant}
                                className="button add-new-row text-blue-button mt-1"
                            >
                                <i className="icon-plus"/> {getLangText('addAnotherVariantLabel')}
                            </button>
                        </div>
                        {!inModal &&
                            <UserPermissionsWrapper allowedPermissions={[USER_PERMISSIONS_PRODUCTS_ADD]}>
                                <div className="form-info-box with-button w-100 mt-3">
                                    <div>
                                        <i className="icon-info-c"/>
                                    </div>
                                    <div>
                                        <div className="title mb-0">
                                            {getLangText('differentColorsProductLabel')}
                                        </div>
                                        <div className="desc">{getLangText('suggestToCreateNewProductLabel')}</div>
                                    </div>
                                    <button
                                        className="button border-button small-size mt-sm-0 mt-3"
                                        onClick={addProduct}
                                        type="button"
                                    >
                                        {getLangText('addNewProductLabel')}
                                    </button>
                                </div>
                            </UserPermissionsWrapper>
                        }
                    </>
                }
            </div>
            {modalVisible && <VariantsSquModal/>}
        </section>
    );
};

export default Variants;
