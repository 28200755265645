import OrderProductsFormActions from "../../shared/components/form/orderProductsForm/OrderProductsFormActions";
import {prefix} from "./newAddOrderModalReducer";
import {getStateRoot} from "./newAddOrderModalReducer";

export class NewAddOrderModalActions extends OrderProductsFormActions {
	safetyCloseModalVisibility(data) {
		return {
			type: `${this.prefix}SAFETY_CLOSE_MODAL_TOGGLE_VISIBILITY`,
			data,

		}
	}
}

export const getInstance = () => new NewAddOrderModalActions({
	prefix,
	getStateRoot,
});

export default getInstance();

