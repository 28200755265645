import React from 'react';
import useLang from "../../../../src_shared/hooks/useLang";
import Field from "../../../../src_shared/components/form/field/Field";
import useFormLocal from "../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../RestServices";
import Loader from "../../../app/shared/Loader";
import SwitchField from "../../../../src_shared/components/form/switchField/SwitchField";
import ComponentLoader from "../../../app/shared/components/componentLoader/ComponentLoader";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import CopyButton from "../../../../src_shared/components/form/copyButton/CopyButton";
import SettingsInpostModel from "../../../../modules/models/settings/SettingsInpostModel";
import {Helmet} from "react-helmet";

const InPostSettings = () => {
		const {getLangText} = useLang();

		const [{
			onSave,
			isWaiting,
			getFieldProps,
			values,
			isLoading,
		}] = useFormLocal({
			rest: services.API_SETTINGS_INPOST,
			model: SettingsInpostModel,
		});

		if (isLoading) return <ComponentLoader/>;

		return (
			<>
				<Helmet>
					<meta charSet="utf-8"/>
					<title>InPost - Selmo</title>
				</Helmet>
				<div className="header-bar breadcrumbs-in-react-app">
					<ul className="breadcrumbs">
						<li>
							<Link to={RouterPaths.Settings}>{getLangText('asideSettingsLabel')}</Link>
						</li>
						<li>
							<Link to={RouterPaths.SettingsIntegration}>{getLangText('settings_nav_integrations')}</Link>
						</li>
						<li className="active">
							InPost
						</li>
					</ul>
				</div>
				<div className="settings-page sub-settings-page export-sub-settings">
					<div className="header-wrapper">
						<h1>
							<img src="/assets/images/settings/waybills.svg" alt="waybills"/>
							{getLangText('settings_integrations_inpost_title_1')}
						</h1>
						<div className="description">{getLangText('settings_integrations_inpost_description_1')}</div>
					</div>
					<div className="settings-form">
						<div className="steps-wrapper">
							<div className="step">
								<div className="step-header">
									<div className="title">{getLangText('settings_integrations_inpost_description_2')}</div>
								</div>
								<div className="step-body" style={{marginLeft: 0, marginTop: '20px', paddingBottom: 0}}>
									<a href="https://inpost.pl/sites/default/files/2022-03/instrukcja-konfiguracji-api-shipx.pdf"
									   className="link-info"
									   target="_blank">{getLangText('settings_integrations_inpost_description_3')}</a>
									<div className="d-flex align-items-center flex-sm-nowrap flex-wrap mb-3 mt-3">
										<Field
											{...getFieldProps('organization_id')}
											label={getLangText('input_label_org_id')}
											className="mb-sm-0 mb-3 mr-sm-3 mr-0 w-sm-auto w-100"
										/>
										<Field
											{...getFieldProps('token')}
											label={getLangText('input_label_token_api')}
											className="mb-0 mr-sm-3 mr-2 w-sm-auto w-100"
										/>
										<SwitchField
											{...getFieldProps('active')}
											style={{marginTop: '22px', marginRight: '15px'}}
											label={getLangText('active')}
											className="switch-form inline-label align-items-start big-label light-version mb-0"
										/>
										<div className="button-place" style={{marginTop: '22px'}}>
											<button
												type="button"
												onClick={onSave}
												className={`button primary ${isWaiting ? 'loading-button' : ''}`}
											>
												<Loader isLoading={isWaiting}/>
												{getLangText('saveButton')}
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="settings-page sub-settings-page export-sub-settings">
					<div className="header-wrapper">
						<h1>
							<img src="/assets/images/settings/waybills.svg" alt="waybills"/>
							{getLangText('settings_integrations_inpost_title_2')}
						</h1>
						<div className="description">
							{getLangText('settings_integrations_inpost_description_4')}
						</div>
					</div>
					<div className="settings-form">
						<div className="steps-wrapper">
							<div className="step">
								<div className="step-header">
									<div className="title">{getLangText('settings_integrations_inpost_description_2')}</div>
								</div>
								<div className="step-body" style={{marginLeft: 0, marginTop: '20px', paddingBottom: 0}}>
									<div>
										<a href="/assets/images/settings/inpost-webhook.jpeg" className="link-info"
										   target="_blank">{getLangText('settings_payments_where_to_paste_link')}</a>
									</div>
									<div className="form-group with-copy-btn mt-3">
										<label
											className="control-label">{getLangText('input_label_new_webhook_address')}</label>
										<div className="form-control">
											<div
												className="short-link flex-grow-1">{values.webhook_url}</div>
											<CopyButton
												copyText={values.webhook_url}
												copyButtonText={getLangText('copyButton')}
												copiedButtonText={getLangText('copiedButton')}
												withoutTooltip={true}
												buttonClassName="border-button"
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="settings-page sub-settings-page export-sub-settings">
					<div className="header-wrapper">
						<h1>
							<img src="/assets/images/settings/waybills.svg" alt="waybills"/>
							Inpost - ustawienia dodatkowe
						</h1>
						<div className="description">
							Skonfiguruj dodatkowe opcje związane z tą metodą dostawy
						</div>
					</div>
					<div className="settings-form">
						<div className="steps-wrapper">
							<Field
								{...getFieldProps('insurance_amount')}
								label="Kwota ubezpieczenia paczki"
								className="mb-sm-0 mr-0 w-100 mt-0"
							/>
							<Field
								{...getFieldProps('default_parcel_locker')}
								label="Paczkomat, do którego zanosisz paczki"
								className="mr-0 mt-3 w-100"
								placeholder="Wpisz numer paczkomatu np.: KRA120M"
							/>
							<SwitchField
								{...getFieldProps('parcel_locker_for_parcel')}
								label="Dotyczy paczek paczkomatowych"
								className="d-flex switch-form inline-label align-items-start big-label light-version mb-0 mt-3"
							/>
							<SwitchField
								{...getFieldProps('parcel_locker_for_courier')}
								label="Dotyczy paczek kurierskich"
								className="d-flex switch-form inline-label align-items-start big-label light-version mb-0 mt-3"
							/>
							<hr className="my-3"/>
							<SwitchField
								{...getFieldProps('courier_c2c')}
								label="Wysyłam paczki kurierskie bez umowy"
								className="d-flex switch-form inline-label align-items-start big-label light-version mb-0 mt-3"
							/>
							<SwitchField
								{...getFieldProps('parcel_c2c')}
								label="Wysyłam paczki paczkomatowe bez umowy"
								className="d-flex switch-form inline-label align-items-start big-label light-version mb-0 mt-3"
							/>
							<div
								className="button-place"
								style={{marginTop: '22px'}}
							>
								<button
									type="button"
									onClick={onSave}
									className={`button primary ${isWaiting ? 'loading-button' : ''}`}
								>
									<Loader isLoading={isWaiting}/>
									{getLangText('button_save_changes')}
								</button>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
;

export default InPostSettings;
