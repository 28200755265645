import Model, {convertObjectProperties} from "../../../src_shared/modules/model/Model";
import ValidationError, {validateRepeatPassword} from "../../../src_shared/form/validation/ValidationError";

class SettingsChangePasswordModel extends Model {
	getModel() {
		return {
			password: '',
			newPassword: '',
			repeatPassword: '',
		};
	}

	getValidators() {
		return {
			password: ValidationError.notEmpty,
			newPassword: ValidationError.notEmpty,
			repeatPassword: ValidationError.validateRepeatPassword
		};
	}
}

export default new SettingsChangePasswordModel();
