import React from 'react';
import Field from "../../../../../../src_shared/components/form/field/Field";
import {
    BUTIK_MADAME_SHOP_ID,
    PEWEXOWY_SHOP_ID, PIANO_FASHION_SHOP_ID, PRELOVED_SHOP_ID,
    SHOP_BAZAR_SHOP_ID,
    TEST_ACCOUNT_SHOP_ID, TIM_SHOP_ID
} from "../../../../../../src_shared/enums/TrustedShopsIds";
import CopyButton from "../../../../../../src_shared/components/form/copyButton/CopyButton";
import {useSelector} from "react-redux";
import useLang from "../../../../../../src_shared/hooks/useLang";
import cn from "clsx";

const VariantRow = ({
                        size,
                        getFieldProps,
                        setNestedValue,
                        values,
                        validation,
                        hasVariantNameAutoFocus,
                        inModal,
                        userData,
                        index,
                    }) => {
    const {getLangText} = useLang();
    const removeVariant = (id) => setNestedValue('sizes', id, 'deleted', '1');
    const {product_code} = useSelector((state) => state.session.userData);

    const isDuplicate = (sizeName) => {
        return !!(validation.isActive && values.sizes.filter((i) => !+i.deleted && (i.sizeName?.toLowerCase() === sizeName?.toLowerCase())).length > 1)
    }

    const withExternalId = (!inModal && (+userData.id === PEWEXOWY_SHOP_ID || +userData.id === PIANO_FASHION_SHOP_ID || +userData.id === TEST_ACCOUNT_SHOP_ID || +userData.id === SHOP_BAZAR_SHOP_ID || +userData.id === PRELOVED_SHOP_ID));

    return (
        <div
            key={size.id}
            className="flex items-center space-x-2 relative"
        >
            {(!!+userData.selmo_pro_active || +userData.id === PIANO_FASHION_SHOP_ID) &&
                <div
                    className={`min-w-[16px] tooltip-parent w-[16px] h-[16px] text-[white] rounded-[5px] flex items-center justify-center ${!+size.reserve_list_sum ? 'bg-[#a5a5a5]' : 'bg-[#3C61EA]'} ${index === 0 ? 'mt-[22px]' : 'mt-[6px]'}`}>
                    <div className="tooltip-content left-position left-arrow">
                        {getLangText('reserveListCountLabel')}
                    </div>
                    <div className="text-[8px] font-bold">
                        {size.reserve_list_sum || 0}
                    </div>
                </div>
            }
            <div className={cn(withExternalId ? 'min-w-[135px]' : 'min-w-[145px]', 'grow')}>
                <Field
                    {...getFieldProps('sizeName')}
                    value={size.sizeName}
                    extraProps={{
                        autoFocus: hasVariantNameAutoFocus,
                    }}
                    label={getLangText('variantNameLabel')}
                    placeholder={getLangText('variantExampleLabel')}
                    setValue={(name, value) => setNestedValue('sizes', size.id, 'sizeName', value)}
                    errorText={false}
                    className="mb-0"
                    labelClassName={index === 0 ? '' : 'opacity-0 h-0'}
                />
            </div>
            <div className={withExternalId ? 'min-w-[67px] max-w-[85px]' : 'min-w-[73px] max-w-[85px]'}>
                <Field
                    {...getFieldProps('sizePrice')}
                    value={size.sizePrice}
                    labelClassName={index === 0 ? '' : 'opacity-0 h-0'}
                    getError={validation.validateErrors.sizePrice}
                    setValue={(name, value) => setNestedValue('sizes', size.id, 'sizePrice', value)}
                    label={getLangText('priceLabel')}
                    type="number"
                    className="mb-0 hidden-arrows"
                    errorText={false}
                />
            </div>
            <div className="min-w-[73px] max-w-[85px]">
                <Field
                    {...getFieldProps('sizeSqu')}
                    value={size.sizeSqu}
                    label={getLangText(+userData.id === BUTIK_MADAME_SHOP_ID ? 'globalQuantityLabel' : 'quantityLabel')}
                    type="number"
                    labelClassName={index === 0 ? '' : 'opacity-0 h-0'}
                    setValue={(name, value) => setNestedValue('sizes', size.id, 'sizeSqu', value)}
                    className="mb-0"
                    errorText={false}
                />
            </div>
            <div
                className={`group grow ${withExternalId ? 'min-w-[170px]' : 'min-w-[180px]'}`}>
                <div
                    className={`form-group with-copy-btn mb-0 disabled-bg ${isDuplicate(size.sizeName) ? 'error-group' : ''}`}>
                    <label
                        className={cn('control-label', index === 0 ? '' : 'opacity-0 h-0')}>{getLangText('productCodeLabel')}</label>
                    <div className="form-control relative">
                        <input
                            tabIndex={-1}
                            className="dotted-text"
                            type="text"
                            value={`${product_code} ${values.selmo_code} ${size.sizeName}`}
                            disabled={true}
                        />
                        <CopyButton
                            copyText={`${product_code} ${values.selmo_code} ${size.sizeName}`}
                            buttonClassName="border-button extra-small-size only-icon ml-1 copied"
                            withoutTooltip
                        />
                    </div>
                </div>
            </div>
            {withExternalId &&
                <div className="min-w-[110px]">
                    <Field
                        {...getFieldProps('external_id')}
                        value={size.external_id}
                        setValue={(name, value) => setNestedValue('sizes', size.id, 'external_id', value)}
                        label="Kod magazynowy"
                        className="mb-0"
                        labelClassName={cn(index === 0 ? '' : 'opacity-0 h-0', 'text-nowrap')}
                    />
                </div>
            }
            <div
                className={cn('tooltip-parent min-w-[25px] bg-[white] sticky bottom-0 right-0  flex items-center justify-end', index === 0 ? 'h-[67px]' : 'h-[52px]')}>
                {!!size.is_product_used &&
                    <div className="tooltip-content text-sm-center">
                        {getLangText('toRemoveVariantCannotBeOnBasketLabel')}
                    </div>
                }
                <button
                    tabIndex={-1}
                    onClick={() => removeVariant(size.id)}
                    type="button"
                    className={cn('button text-desc text-sm remove-row-button', index === 0 ? 'mt-[17px]' : 'mt-[2px]')}
                    disabled={size.is_product_used}
                >
                    <i className="icon-bin"/>
                </button>
            </div>
        </div>
    );
};

export default VariantRow;
