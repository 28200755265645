import {getStateRoot, prefix} from './listReducer';
import {services} from "../../../../RestServices";
import {SharedEditProductListActions} from "../shared/SharedEditProductListActions";
import {getStateRoot as getFiltersStateRoot} from "./filters/filtersReducer";
import {convertDate} from "../../../shared/helpers/dateHelpers";

export class ActivityHistoryListActions extends SharedEditProductListActions {
	getLoadParams(state) {
		const params = super.getLoadParams(state);
		const dateFrom = getFiltersStateRoot(state).data.fromDate
		const dateTo = getFiltersStateRoot(state).data.toDate

		if (dateFrom) {
			params.dateFrom = convertDate(dateFrom);
		}

		if (dateTo) {
			params.dateTo = convertDate(dateTo);
		}

		return params;
	}
}
export const getInstance = () => new ActivityHistoryListActions({
	getStateRoot,
	restService: services.API_PERMISSIONS_PRODUCT_HISTORY,
	prefix,
	preventPushParams: true,
});

export default getInstance();
