import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

export default (actions, getStateRoot) => {
    const validationActive = useSelector(
        createSelector(
            getStateRoot,
            (stateRoot) => stateRoot.validationActive,
        ),
    );

    return {
        isActive: validationActive,
    };
};
