import React from 'react';
import {RouterPaths} from "../../../routes/RouterPath";
import SettingsBox from "../../components/SettingsBox";

export const SETTINGS_META = [
    {
        id: 0,
        title: 'Facebook',
        desc: 'settings_nav_facebook_automation_description',
        imgSrc: '/assets/images/svg/facebook-logo-c.svg',
        path: RouterPaths.SettingsFacebook
    },
    {
        id: 1,
        title: 'Instagram',
        desc: 'settings_nav_instagram_automation_description',
        imgSrc: '/assets/images/svg/instagram-icon-new.svg',
        path: RouterPaths.SettingsInstagram
    },
]

const MetaBoxes = () => {
    return (
        <section>
            <div className="section-title">Meta</div>
            <div className="settings-box-wrapper border-boxes">
                {SETTINGS_META.map((item) => (
                    <SettingsBox
                        key={item.id}
                        item={item}
                    />
                ))}
            </div>
        </section>
    );
};

export default MetaBoxes;
