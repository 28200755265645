import createReducer from "../../../shared/helpers/createReducer";
import DataReducer from "../../../../../src_shared/form/data/DataReducer";
import {getReduceMap} from "../../../../../src_shared/filters/filterReducer";
import {ALL_ORDERS_VIEW} from "../../../shared/enums/OrderViewTypes";
import {ProductsSourceTypes} from "../../../shared/enums/ProductSourceType";

export const getStateRoot = (state) => state.products.form.filters;

export const getInitState = () => DataReducer.getInitState({
	statuses: [],
	filtersSelectAll: true,
	source: [],
	sourceSelectAll: false,
	variants: [],
	variantsSelectAll: true,
	fromDate: null,
	toDate: null,
	viewType: ALL_ORDERS_VIEW,
	deleted: '0',
}, )

export const prefix = 'PRODUCTS_FORM_FILTERS_';

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();
