import React, {useState} from 'react';
import {RouterPaths} from "../../../../routes/RouterPath";
import {services} from "../../../../RestServices";
import useLang from "../../../../../src_shared/hooks/useLang";
import {Link, useParams} from "react-router-dom";
import ConfirmModal from "../../../live/details/default/summary/export/ConfirmModal";
import useRemoveData from "../../../../../src_shared/hooks/useRemoveData";
import AssignProductsModal from "./assignProducts/Form";
import ReelsFormComponent from "./components/ReelsForm";
import useForm from "../../../../../src_shared/form/useForm";
import FormActions from "./FormActions";

const MobileReelsForm = () => {
    const {getLangText} = useLang()
    const {id} = useParams();

    const [showAssignProductsModal, setShowAssignProductsModal] = useState(false);
    const rest = !!+id ? `${services.API_STORAGE_REELS}/${id}` : services.API_STORAGE_REELS;

    const [{
        openModal: openRemoveModal,
        hideModal: hideRemoveModal,
        confirmModalVisible,
        itemToRemove,
        isDeleting,
        removeItem,
    }] = useRemoveData({
        rest,
        redirectRoute: RouterPaths.ReelsList
    });

    const {
        data: {values, setValue, updateValues},
        form: {isWaiting, onSave, getFieldProps},
    } = useForm({
        FormActions,
        params: {
            id
        },
        requireParentId: true,
    });

    return (
        <>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <Link to={RouterPaths.MobileReelsList}>
                            {getLangText('reelsLabel')}
                        </Link>
                    </li>
                    <li className="active">
                        <div>{getLangText(!!id ? 'editionLabel' : 'newLabel')}</div>
                    </li>
                </ul>
                <Link
                    to={RouterPaths.MobileReelsList}
                    className="button back-to-panels mb-3">
                    <i className="icon-arrow-left"/>
                    {getLangText('backToReelsLabel')}
                </Link>
            </div>
            <div className="">
                <div className="grid grid-cols-5 gap-4">
                    <div className="col-span-3">
                        <div className="bg-[white] rounded-[5px] p-6">
                            <h1 className="font-bold text-lg">
                                {getLangText('reelsLabel')}
                            </h1>
                            <div className="text-desc text-sm font-medium mb-4">
                                {getLangText('reelsSubTitle')}
                            </div>
                            <ReelsFormComponent
                                setValue={setValue}
                                openRemoveModal={openRemoveModal}
                                updateValues={updateValues}
                                values={values}
                                onSave={onSave}
                                isWaiting={isWaiting}
                                isLoading={isWaiting}
                                getFieldProps={getFieldProps}
                                setShowAssignProductsModal={setShowAssignProductsModal}
                            />
                        </div>
                    </div>
                    <div className="col-span-2">
                        {values.video_poster &&
                            <div className="bg-[white] rounded-[5px] p-6 mb-3">
                                <div className="font-bold">
                                    {getLangText('previewLabel')}
                                </div>
                                <figure>
                                    <video controls src={values.attachment_path}/>
                                    {/*<img src={values.video_poster} alt=""/>*/}
                                </figure>
                            </div>
                        }
                        <div className="bg-[white] rounded-[5px] p-6">
                            <div className="font-bold">
                                {getLangText('asideStatsLabel')}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {showAssignProductsModal &&
                <AssignProductsModal
                    hideModal={() => setShowAssignProductsModal(false)}
                    modalVisible={showAssignProductsModal}
                    updateProducts={(products) => setValue('products', products)}
                    defaultSelectedProducts={values.products}
                />
            }
            {confirmModalVisible &&
                <ConfirmModal
                    dialogClassName="w-380"
                    title={getLangText('removeLabel')}
                    SubTitle={() => <span dangerouslySetInnerHTML={{
                        __html: getLangText({
                            key: 'wantToRemoveLabel',
                            data: [itemToRemove.name]
                        })
                    }}/>}
                    saveButton={getLangText('removeButton')}
                    show={confirmModalVisible}
                    isWaiting={isDeleting}
                    onSubmit={removeItem}
                    hide={hideRemoveModal}
                    saveButtonClass="danger"
                />
            }
        </>
    );
};

export default MobileReelsForm;
