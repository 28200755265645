import Model from "../../../src_shared/modules/model/Model";
import {createNewId} from "../../../components/app/live/details/default/liveDetailsReducer";

class AutomaticShelvesModel extends Model {
    getModel() {
        return {
            shelf_1_from: null,
            shelf_1_to: null,
            shelf_2_from: null,
            shelf_2_to: null,
            shelf_3_from: null,
            shelf_3_to: null,
        };
    }
}

export default new AutomaticShelvesModel();
