import Model from "../../src_shared/modules/model/Model";
import ValidationError from "../../src_shared/form/validation/ValidationError";

export class CollectionModel extends Model {
	getModel() {
		return {
			id: 0,
			name: '',
		};
	}

	getValidators() {
		return {
			name: ValidationError.notEmpty,
		};
	}
}

export default new CollectionModel();
