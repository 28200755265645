import createReducer from "../../shared/helpers/createReducer";
import Composition from "../../shared/helpers/Composition";
import FormReducer from "../../../../src_shared/form/FormReducer";
import ProductModel from "../../../../modules/models/ProductModel";

export const getStateRoot = (state) => state.products.modalForm;
export const prefix = 'PRODUCTS_MODAL_FORM_';

const getInitState = () => FormReducer.getInitState(ProductModel.getModel());

const getReduceMap = () => new Composition(
	FormReducer.getReduceMap(),
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

