import React, {useRef} from 'react';
import Modal from "react-bootstrap/Modal";
import useList from "../../../../src_shared/list/useList";
import ListActions from "./ListActions";
import {getStateRoot} from "./listReducer";
import SearchInput from "../../shared/components/form/SearchInput";
import useCSSClass from "../../messenger/hooks/useCSSClass/useCSSClass";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import {useDispatch} from "react-redux";
import Loader from "../../shared/Loader";
import useLang from "../../../../src_shared/hooks/useLang";

const SearchModal = () => {

	useCSSClass('with-blur')
	const dispatch = useDispatch();
	const searchRef = useRef(null);
	const {getLangText} = useLang();

	const {
		list: {items, isWaiting},
		remove: {isModalVisible, cancelItemRemoval},
		search
	} = useList({
		ListActions,
		getStateRoot,
		preventAutoLoad: true,
	})

	const isEmptyResult = ((!items.articles.length && !items.articlesDescription.length) || search.searchText.length < 1) && !isWaiting;

	const body = document.querySelector('body')
	const closeMobileMenu = () => body.classList.remove('open-menu');

	const hideModal = () => {
		closeMobileMenu()
		dispatch(ListActions.modal.hideModal())
	};

	const textWithSelectedPhrase = (value = '', inDescription = false) => {
		const letters = ['ą', 'ć', 'ę', 'ł', 'ń', 'ó', 'ś', 'ź', 'ż'];
		const replacement = ['a', 'c', 'e', 'l', 'n', 'o', 's', 'z', 'z'];
		let preparedValue = value;
		let preparedLettersSearch = search.searchText;

		for (let i = 0; i < letters.length; ++i) {
			preparedValue = preparedValue.replaceAll(letters[i], replacement[i]);
			preparedLettersSearch = preparedLettersSearch.replaceAll(letters[i], replacement[i]);
		}

		const textToReplace = preparedValue.toString().toLowerCase();
		const preparedSearch = preparedLettersSearch.replace(/[.*+?^${}()|[\]\\]/g, '\\$&').toString().toLowerCase();
		const match = textToReplace.match(preparedSearch);


		const startIndex = match && match.index;
		const matchLength = match && match[0]?.length;
		const valueToReplace = value.substr(startIndex, matchLength);

		if (inDescription && match) {
			const splitString = value.split(' ')
			const first2 = splitString.indexOf(splitString.find((i) => i.includes(match[0])));
			if (first2 === -1) {
				return value.replace(valueToReplace, `<span class="selected">${valueToReplace}</span>`)
			}
			if (first2 > 8) {
				return value.replace(valueToReplace, `<span class="selected">${valueToReplace}</span>`).split(' ').slice(first2 - 8, first2 + 8).join(' ')
			} else {
				return value.replace(valueToReplace, `<span class="selected">${valueToReplace}</span>`).split(' ').slice(0, first2 + 14).join(' ')
			}
		}
		return value.replace(valueToReplace, `<span class="selected">${valueToReplace}</span>`);
	};

	const EmptyStateContent = () => {
		if (search.searchText.length > 1) {
			return (
				<div className="list-place empty-results">
					{getLangText('noSearchResultLabel')}
				</div>
			)
		}
		return (
			<div className="list-place empty-results">
				{getLangText('textToSearchLabel')}
			</div>
		)
	}

	return (
		<Modal
			animation={false}
			tabIndex={0}
			show={isModalVisible}
			dialogClassName="default-modal"
			backdropClassName="with-blur"
			onHide={cancelItemRemoval}
		>
			<Modal.Body className="p-0">
				<div className="search-place">
					<SearchInput
						searchRef={searchRef}
						placeholder={getLangText('searchPlaceholder')}
						search={search}
						autoFocus={true}
					/>
					<button
						onClick={cancelItemRemoval}
						type="button"
						className="button"
					>
						<i className="icon-cross"/>
					</button>
				</div>
				{isEmptyResult ?
					<EmptyStateContent/> :
					<div className="list-place loader-parent">
						<Loader isLoading={isWaiting}/>
						{items.articles.map((item) => (
							<Link
								onClick={hideModal}
								to={`${RouterPaths.KnowledgeBase}/${item.name_url}`}
								className="result"
							>
								<div className="title"
									 dangerouslySetInnerHTML={{__html: textWithSelectedPhrase(item.title)}}/>
								<div className="desc"
									 dangerouslySetInnerHTML={{__html: textWithSelectedPhrase(item.prepared_description, true)}}/>
							</Link>
						))}
						{items.articlesDescription.map((item) => (
							<Link
								onClick={hideModal}
								to={`${RouterPaths.KnowledgeBase}/${item.name_url}`}
								className="result"
							>
								<div className="title"
									 dangerouslySetInnerHTML={{__html: textWithSelectedPhrase(item.title)}}/>
								<div className="desc"
									 dangerouslySetInnerHTML={{__html: textWithSelectedPhrase(item.prepared_description, true)}}/>
							</Link>
						))}
					</div>
				}
			</Modal.Body>
		</Modal>
	);
};

export default SearchModal;
