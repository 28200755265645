import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import {RouterPaths} from "./RouterPath";
import ClientsList from "../app/clients/lists/clientsList/List";
import ClientDetails from "../app/clients/details/ClientDetails";
import ClientsSubmittedList from "../app/clients/lists/submittedClientsList/List";
import {
    LIVE_NOTIFICATION_PERMISSION,
    SELMO_APP_PERMISSION,
} from "../app/shared/enums/SelmoPermissions";
import PermissionRoute from "./PermissionRoute";
import TutorialPageBox from "../../src_shared/components/ui/TutorialPageBox/TutorialPageBox";
import {HEADER_BOX_CLIENTS} from "../app/shared/enums/TutorialsVideo";

const ClientsRoute = ({path}) => {

    return (
        <PermissionRoute allowedPermissions={[SELMO_APP_PERMISSION, LIVE_NOTIFICATION_PERMISSION]}>
            <Route path={path}>
                <div className="orders-wrapper">
                    <TutorialPageBox
                        page='notifications'
                        videoStatsName={HEADER_BOX_CLIENTS}
                        title="asideClientsLabel"
                    />
                    <Switch>
                        <Route
                            exact
                            path={path}
                            component={ClientsList}
                        />
                        <Route
                            exact
                            path={RouterPaths.ClientsSubmittedList}
                            component={ClientsSubmittedList}
                        />
                        <Route
                            exact
                            path={RouterPaths.ClientDetails}
                            component={ClientDetails}
                        />
                        <Redirect to={RouterPaths.ClientsList}/>
                    </Switch>
                </div>
            </Route>
        </PermissionRoute>
    );
};

export default ClientsRoute;
