import React, {useRef} from 'react';
import Modal from 'react-bootstrap/Modal';
import {useDispatch, useSelector} from "react-redux";
import {getOrders, orderModalToggleVisibility} from "./OrderModalActions";
import Loader from "../loader/Loader.jsx";
import {odmiana} from "../helpers/odmiana";
import {addOrderModalToggleVisibility} from "../addOrderModal/AddOrderModalActions";
import moment from "../helpers/moment";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import NewAddOrderModalActions from "../addOrderModal/NewAddOrderModalActions";
import {findOrderStatusById} from "../../shared/enums/OrderStatuses";
import useLang from "../../../../src_shared/hooks/useLang";
import {useUnitPrice} from "../../shared/helpers/Price";
import {convertToUserTimeZone} from "../../shared/helpers/dateHelpers";

const OrderModal = ({activeConversation}) => {
    const dispatch = useDispatch();
	const {getLangText} = useLang();
	const {getPrice} = useUnitPrice();
	const {lang} = useSelector((state) => state.i18n);

    const {isOpen, isLoading, orders, noResults} = useSelector((state) => state.messenger.orderModal);
    const modalRef = useRef();

    const handleShow = () => {
        dispatch(orderModalToggleVisibility(true));
        dispatch(getOrders(activeConversation.user_id, activeConversation.page_id));
    }

    const handleClose = (id) => {
        modalRef.current.dialog.classList.add('hide-modal')
        setTimeout(() => {
            dispatch(orderModalToggleVisibility(false));
            dispatch(addOrderModalToggleVisibility(true, true));
			dispatch(NewAddOrderModalActions.getOrderProducts(id, activeConversation))
            // dispatch(getOrderAction(id));
        }, 300)
    }

    const handleCrossClick = () => {
        modalRef.current.dialog.classList.add('hide-modal')
        setTimeout(() => {
            dispatch(orderModalToggleVisibility(false))
        }, 300)
    }

    const getDate = (date) => convertToUserTimeZone(date).format('DD.MM.YYYY HH:mm');

    return (
        <>
            <OverlayTrigger
                placement='bottom'
                overlay={
                    <Tooltip id="full-screen">
						{getLangText('allOrdersLabel')}
                    </Tooltip>
                }
            >
                <button
                    type="button"
                    className="orders-number"
                    onClick={handleShow}
                >
                    {activeConversation.orders_count > 0 && <span className="count">{activeConversation.orders_count}</span>}
                    <i className="icon-orders"/>
                    <div className="lg:hidden text-xs font-bold ml-2">{getLangText('allOrdersLabel')}</div>
                </button>
            </OverlayTrigger>
            <Modal
                ref={modalRef}
                className="orders-messages-modal"
                show={isOpen}
                onHide={handleCrossClick}
                keyboard={false}
                backdrop={false}
                animation={false}
                enforceFocus={false}
            >
                <Modal.Header>
                    <button
                        type="button"
                        onClick={handleCrossClick}
                    >
                        <i className="icon-cross"/>
                    </button>
                    <div className="modal-title">
                        {getLangText('asideOrdersLabel')}
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {isLoading ?
                        <div className="loader-parent">
                            <Loader/>
                        </div> :
                        noResults ?
                            <div className="no-results-text text-center">
                                <img src="/assets/images/illustration/orders-empty.svg" alt=""/>
								{getLangText('noOrdersLabel')}
                            </div> :
                            <div className="orders-boxes">
                                {orders?.map((item) => (
                                    <button
                                        type="button"
                                        key={item.id}
                                        className="order-box"
                                        onClick={() => handleClose(item.shop_order_id)}
                                    >
                                        <div className="left-side">
                                            <div className="number">{getLangText('customerBasketOrderLabel')} {item.shop_order_id}</div>
                                            <div className="date-row">
                                                <div className="date">{getDate(item.date, lang)}</div>
                                                {item.payment && <div className="payment">{item.payment}</div>}
                                            </div>
                                            <div className="status-row">
                                                <div className={`status-bg ${findOrderStatusById(item.status)?.color}`}>{getLangText(findOrderStatusById(item.status)?.label)}</div>
                                                <div
                                                    className="products">{item.product_count} {odmiana(item.product_count, getLangText('productLabel')?.toLowerCase(), getLangText('asideProductsLabel')?.toLowerCase(), getLangText('productsGenitiveLabel'))}</div>
                                            </div>
                                        </div>
                                        <div className="right-side">
                                            <div className="price">{getPrice(item.all_products_price || 0, item.currency_text)}</div>
                                            <i className="icon-arrows"/>
                                        </div>
                                    </button>
                                ))}
                            </div>
                    }
                </Modal.Body>
            </Modal>
        </>
    );
};

export default OrderModal;
