import FormReducer from "../../../../../../src_shared/form/FormReducer.js";
import Composition from "../../../../shared/helpers/Composition.js";
import createReducer from "../../../../shared/helpers/createReducer.js";
import {ORDER_PACKED_FILTER_ALL} from "../../../../shared/enums/PackedStatuses";
import {ORDER_PRODUCTS_PAID_FILTER_ALL} from "../../../../shared/enums/PaidStatuses";
import {ORDER_LABEL_ALL} from "../../../../shared/enums/OrdersLabelStatuses";
import {ORDER_INVOICE_ALL} from "../../../../shared/enums/OrdersInvoiceStatuses";

export const getStateRoot = (state) => state.orders.filtersPanel.form;

export const prefix = 'ORDERS_LIST_FILTER_PANEL_FORM_';

const model = {
	statuses: [],
	filtersSelectAll: false,
	productType: [],
	productTypeAll: false,
	payments: [],
	paymentsSelectAll: false,
	shipping: [],
	shippingSelectAll: false,
	tag: [],
	discounts: [],
	country: [],
	tagSelectAll: false,
	discountsSelectAll: false,
	countrySelectAll: false,
	isPacked: ORDER_PACKED_FILTER_ALL,
	isPaid: ORDER_PRODUCTS_PAID_FILTER_ALL,
	label: ORDER_LABEL_ALL,
	invoice: ORDER_INVOICE_ALL,
	mr_automate_paused: 'all',
	priceFrom: '',
	priceTo: '',
	productsFrom: '',
	productsTo: '',
}

export const getInitState = () => FormReducer.getInitState(model,  model)

export const getReduceMap = () => new Composition(
	FormReducer.getReduceMap(),
);

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();
