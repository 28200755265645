import React from 'react';
import Wrapper from "../Wrapper";
import Loader from "../../../../app/shared/Loader";
import useLang from "../../../../../src_shared/hooks/useLang";
import DailyChart from "./components/DailyChart";
import {getStateRoot as getFiltersStateRoot} from "./filters/filtersReducer";
import useList from "../../../../../src_shared/list/useList";
import ListActions from "./ListActions";
import FiltersActions from './filters/FiltersActions'
import DateRangePicker from "../../../../app/shared/components/form/DateRangePicker";
import {getStateRoot} from "./listReducer";

const Stats = () => {

    const {getLangText} = useLang()

    const {
        list: {items, isWaiting, loadData},
    } = useList({
        ListActions,
        getStateRoot,
        FiltersActions,
    });

    return (
        <Wrapper>
            <div className="loader-parent">
                <Loader isLoading={isWaiting}/>
                <div className="settings-form px-0 pt-0">
                    <div
                        style={{borderTopRightRadius: 0, borderTopLeftRadius: 0}}
                        className="default-shadow-box">
                        <div className="table-header">
                            <div className="filters-part flex items-center mt-0">
                                <div className="top-part">
                                    <h1 className="title mb-0">
                                        {getLangText('asideStatsLabel')}
                                    </h1>
                                </div>
                                <DateRangePicker
                                    actions={ListActions}
                                    getFiltersStateRoot={getFiltersStateRoot}
                                    FiltersActions={FiltersActions}
                                    className="ml-auto"
                                    loadData={loadData}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="steps-wrapper mt-3">
                        <div className="grid grid-cols-3 gap-5">
                            <div className="col-span-2">
                                <DailyChart
                                    currency={items.currency}
                                    data={items.daily}/>
                            </div>
                            <div className="grid grid-cols-1 gap-5">
                                <div className="bg-[white] rounded-[5px] flex flex-col justify-content-center px-4">
                                    <div className="flex items-center font-medium text-sm text-[#4b5563]">
                                        <img style={{width: 20}} className="mr-1" src="/assets/images/svg/income.svg"
                                             alt=""/>
                                        {getLangText('incomeLabel')}
                                    </div>
                                    <div className="value font-bold text-[24px] mt-[5px]">
                                        {items?.totals?.total_price}&nbsp;{items.currency}
                                    </div>
                                </div>
                                <div className="bg-[white] rounded-[5px] flex flex-col justify-content-center px-4">
                                    <div className="flex items-center font-medium text-sm text-[#4b5563]">
                                        <img style={{width: 20}} className="mr-1" src="/assets/images/svg/orders.svg"
                                             alt=""/>
                                        {getLangText('totalProductsSold')}
                                    </div>
                                    <div className="value font-bold text-[24px] mt-[5px]">
                                        {items?.totals?.total_count}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};

export default Stats;
