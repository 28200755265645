import createReducer from "../../../shared/helpers/createReducer.js";

export const getStateRoot = (state) => state.shared.countDownNotification;

const getInitState = {
	notification: {
		isVisible: false,
		type: null,
		data: {},
	},
}

const getReduceMap = {
	SET_NOTIFICATION_VISIBILITY: (state, action) => ({...state, notification: {isVisible: action.toggle, type: action.notificationType, data: {...action.data}}}),
}

export const getInstance = () => createReducer(
	getInitState,
	getReduceMap,
);

export default getInstance();

