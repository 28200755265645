import React from 'react';
import useList from "../../../../../src_shared/list/useList.js";
import ListActions from "./ListActions.js";
import {getStateRoot} from "./listReducer.js";
import Pagination from "../../../shared/components/pagination/Pagination.jsx";
import ClientsTable from "./components/table/Table.jsx";
import SearchInput from "../../../shared/components/form/SearchInput.jsx";
import ColumnVisibilityFilter
    from "../../../../../src_shared/components/filters/columnVisibilityFilter/ColumnVisibilityFilter.jsx";
import {CLIENTS_TABLE_COLUMN} from "./components/table/ClientsTableColumnEnums.js";
import FiltersActions from "../../filters/FiltersActions.js";
import {getStateRoot as getFiltersStateRoot} from '../../filters/filtersReducer.js'
import ClientsWrapper from "../../Page";
import useLang from "../../../../../src_shared/hooks/useLang";
import SingleSelectFiltersStatus
    from "../../../../../src_shared/components/filters/singleSelectFiltersStatus/SingleSelectFiltersStatus";
import {CLIENTS_DELETED_TYPES} from "../../../shared/enums/ClientsDeletedTypes";
import {useSelector} from "react-redux";
import {CHIQUE_SHOP_ID, LA_MODE_SHOP_ID, TEST_ACCOUNT_SHOP_ID} from "../../../../../src_shared/enums/TrustedShopsIds";

const ClientsList = () => {
    const {getLangText} = useLang();
    const {userData} = useSelector((state) => state.session);

    const {
        pagination: {
            changePage,
            getPagination,
            itemsPerPage,
            nextPage,
            prevPage,
            totalItems,
            updateItemsPerPage
        },
        list: {items, isWaiting},
        search,
        sort: {updateSortOptions, getSortClass},
    } = useList({
        ListActions,
        getStateRoot,
    });

    return (
        <ClientsWrapper helmetTitle={`${getLangText('asideClientsLabel')} - Selmo`}>
            <div className="table-list-wrapper">
                <div className="default-shadow-box pb-0">
                    <div className="table-header">
                        <div className="top-part flex-sm-nowrap flex-wrap justify-content-between">
                            <h1 className="title mb-sm-0 mb-2">
                                {getLangText('asideClientsLabel')}
                                {totalItems > 0 &&
                                    <div className="badge">{totalItems}</div>
                                }
                                <div className="desc">{getLangText('dataUpdatedOnceEvery24HoursLabel')}</div>
                            </h1>
                            <ColumnVisibilityFilter
                                ListActions={ListActions}
                                FiltersActions={FiltersActions}
                                getFiltersStateRoot={getFiltersStateRoot}
                                options={CLIENTS_TABLE_COLUMN}
                                name="clientsTableColumn"
                                selectAllName="clientsTableColumnSelectAll"
                                className="ml-sm-auto"
                            />
                            {(+userData.id === CHIQUE_SHOP_ID || +userData.id === TEST_ACCOUNT_SHOP_ID || +userData.id === LA_MODE_SHOP_ID) &&
                                <SingleSelectFiltersStatus
                                    ListActions={ListActions}
                                    FiltersActions={FiltersActions}
                                    getFiltersStateRoot={getFiltersStateRoot}
                                    options={CLIENTS_DELETED_TYPES}
                                    name="is_deleted"
                                    className="ml-sm-2"
                                    defaultSelectedLabel={getLangText('asideClientsLabel')}
                                />
                            }
                            <SearchInput
                                placeholder={getLangText('searchPlaceholder')}
                                search={search}
                                className="ml-sm-2"
                            />
                        </div>
                    </div>
                    <ClientsTable
                        getSortClass={getSortClass}
                        updateSortOptions={updateSortOptions}
                        items={items}
                        isWaiting={isWaiting}
                    />
                </div>
                <Pagination
                    getLangText={getLangText}
                    pages={getPagination()}
                    items={items}
                    itemsPerPage={itemsPerPage}
                    totalItems={totalItems}
                    onPrevPage={prevPage}
                    onChangePage={changePage}
                    onNextPage={nextPage}
                    onChangeItemsPerPage={updateItemsPerPage}
                    classes={{
                        prevIcon: 'icon-chevron left',
                        nextIcon: 'icon-chevron right',
                    }}
                    showItemsPerPageSelect
                />
            </div>
        </ClientsWrapper>
    );
};

export default ClientsList;
