import React from 'react';
import useStatusFilters from "./useStatusFilters";
import FiltersDropdown from "../filtersDropdown/FiltersDropdown";
import useLang from "../../../hooks/useLang";

const StatusFilter = ({ListActions, FiltersActions, getFiltersStateRoot, className, extraProps, options, name, filterTitle, selectAllName, defaultSelectedLabel, optionLabel, withClear = true, defaultSelectedClassName}) => {

	const {
		statuses,
		selectAll,
		isSelectAll,
		saveFilters,
		filterButtonTitle,
		handleCheckBoxClick,
		allItemsClick,
		updateLocallyStatusesState,
		getCheckboxValue,
	} = useStatusFilters(
		ListActions,
		getFiltersStateRoot,
		FiltersActions,
		name,
		selectAllName,
		options,
		defaultSelectedLabel,
	);

	const buttonTitle = filterTitle ? filterTitle() : filterButtonTitle();
	const {getLangText} = useLang();

	return (
		<FiltersDropdown
			onClose={() => updateLocallyStatusesState(statuses, selectAll)}
			onSave={() => saveFilters(extraProps.id)}
			buttonTittle={buttonTitle}
			onSecondButtonClick={() => updateLocallyStatusesState()}
			className={className}
			withClear={withClear}
		>
			<div className="checkbox-filter-wrapper">
				<div className="form-group checkbox-form">
					<input
						className="checkbox-input form-control"
						type="checkbox"
						onChange={allItemsClick}
						checked={isSelectAll}
					/>
					<span className="checkbox-checkmark"/>
					<label className="control-label">{getLangText('allLabel')}</label>
				</div>
				{options.map((status) => (
					<div
						key={status.value}
						className="form-group checkbox-form"
					>
						<input
							className="checkbox-input form-control"
							type="checkbox"
							onChange={() => handleCheckBoxClick(status.value)}
							checked={getCheckboxValue(status.value)}
						/>
						<span className="checkbox-checkmark"/>
						{optionLabel(status, getLangText)}
					</div>
				))}
			</div>
		</FiltersDropdown>
	);
};

StatusFilter.defaultProps = {
	className: '',
	extraProps: {},
	options: [],
	filterTitle: null,
	optionLabel: (item, getLangText) => (
		<label className="control-label">
			{getLangText(item.label) || item.label}
		</label>
	),
}

export default StatusFilter;
