import Composition from "../../shared/helpers/Composition";
import createReducer from "../../shared/helpers/createReducer";
import OrderProductsFormReducer from "../../shared/components/form/orderProductsForm/OrderProductsFormReducer";

export const getStateRoot = (state) => state.messenger.newAddOrderModal;

export const prefix = 'MESSENGER_';

export const getInitState = () => OrderProductsFormReducer.getInitState({
	safetyCloseModalVisibility: {
		visible: false,
		toSummaryView: false,
	},
})

export const getReduceMap = () => new Composition(
	OrderProductsFormReducer.getReduceMap(),
	{
		SAFETY_CLOSE_MODAL_TOGGLE_VISIBILITY: (state, action) => {
			return {...state, safetyCloseModalVisibility: {...state.safetyCloseModalVisibility, ...action.data}, }
		}
	}
);

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();
