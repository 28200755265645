import {getStateRoot, prefix} from "./formReducer.js";
import {services} from "../../../../RestServices";
import Model from "../../../../../src_shared/modules/model/Model";
import {SharedShopSettingsActions} from "../../shared/SharedShopSettingsActions";

export const getInstance = () => new SharedShopSettingsActions({
	getStateRoot,
	prefix,
	model: new Model(),
	restService: services.API_STORE_PROMOTION_ANNOUNCEMENT,
});

export default getInstance();
