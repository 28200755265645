export const EXPENDITURE_REASON_INVALID_COUNT = 'EXPENDITURE_REASON_INVALID_COUNT'
export const EXPENDITURE_REASON_COMPLAINT = 'EXPENDITURE_REASON_COMPLAINT'
export const EXPENDITURE_REASON_DEFECTIVE_PRODUCT = 'EXPENDITURE_REASON_DEFECTIVE_PRODUCT'
export const EXPENDITURE_REASON_OTHER = 'EXPENDITURE_REASON_OTHER'

export const ExpendituresReasonTypes = [
	{
		value: EXPENDITURE_REASON_INVALID_COUNT, label: 'invalidCountReasonLabel'
	},
	{
		value: EXPENDITURE_REASON_COMPLAINT, label: 'complaintReasonLabel'
	},
	{
		value: EXPENDITURE_REASON_DEFECTIVE_PRODUCT, label: 'defectiveProductReasonLabel'
	},
	{
		value: EXPENDITURE_REASON_OTHER, label: 'howYouKnowUsOtherLabel'
	},
]

export const findExpenditureLabelById = (id) => ExpendituresReasonTypes.find((i) => i.value === id)?.label || {};

