import React, {useRef} from 'react';
import useDropdown from "./useDropdown";
import {useDispatch, useSelector} from "react-redux";
import {
	getLiveDetailsClients, resetClientListParams,
	updateFieldInLiveDetails
} from "../../LiveDetailsActions";
import useLang from "../../../../../../../src_shared/hooks/useLang";

const ClientListDropdown = ({liveId}) => {
	const dispatch = useDispatch();
	const buttonRef = useRef(null)
	const dropdownRef = useRef(null)
	const {getLangText} = useLang();

	const data = useSelector((state) => state.lives.details)

	const {open, setOpen} = useDropdown(buttonRef, dropdownRef);

	const setDefaultParams = () => {
		dispatch(resetClientListParams())
		dispatch(getLiveDetailsClients(liveId));
	}
	const setParam = (field, value) => {
		dispatch(resetClientListParams())
		dispatch(updateFieldInLiveDetails(field, value));
		dispatch(getLiveDetailsClients(liveId));
	};

	const getDropdownHeader = () => {
		if (data.isCancelled === '1') {
			return getLangText('liveCancelledProductsLabel')
		}
		if (data.isCartStatusSent === '1') {
			return getLangText('liveSentBasketStatesLabel')
		}
		if (data.isCartStatusSent === '0') {
			return getLangText('liveUnSentBasketStatesLabel')
		}
		if (data.isSummarySent === '1') {
			return getLangText('liveSentSummariesLabel')
		}
		if (data.isSummarySent === '0') {
			return getLangText('liveUnSentSummariesLabel')
		}
		if (data.isOrderCreated === '1') {
			return getLangText('liveCreatedOrdersLabel')
		}
		return getLangText('allUsersLabel');
	}

	return (
		<>
			<div ref={dropdownRef} className="react-dropdown no-hover position-relative mb-0 mt-sm-0 mt-2">
				<button
					className="dropdown-toggle title w-sm-auto w-100"
					onClick={() => setOpen(!open)}
					ref={buttonRef}
				>
					{getDropdownHeader()}
					<i className="icon-arrows" />
				</button>
				{open &&
					<div className="dropdown-menu show">
						<button
							type="button"
							className="custom-option"
							onClick={setDefaultParams}
						>
							{getLangText('allUsersLabel')}
						</button>
						<button
							type="button"
							className="custom-option"
							onClick={() => setParam('isCancelled', '1')}
						>
							{getLangText('liveCancelledProductsLabel')}
						</button>
						<button
							type="button"
							className="custom-option"
							onClick={() => setParam('isOrderCreated', '1')}
						>
							{getLangText('liveCreatedOrdersLabel')}
						</button>
						<button
							type="button"
							className="custom-option"
							onClick={() => setParam('isCartStatusSent', '1')}
						>
							{getLangText('liveSentBasketStatesLabel')}
						</button>
						<button
							type="button"
							className="custom-option"
							onClick={() => setParam('isCartStatusSent', '0')}
						>
							{getLangText('liveUnSentBasketStatesLabel')}
						</button>
						<button
							type="button"
							className="custom-option"
							onClick={() => setParam('isSummarySent', '1')}
						>
							{getLangText('liveSentSummariesLabel')}
						</button>
						<button
							type="button"
							className="custom-option"
							onClick={() => setParam('isSummarySent', '0')}
						>
							{getLangText('liveUnSentSummariesLabel')}
						</button>
					</div>
				}
			</div>
		</>
	)
};

export default ClientListDropdown;
