import React, {useState} from 'react';
import Wrapper from "../Wrapper";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../routes/RouterPath";
import ActivateTransferModal from "../activateTransferModal/ActivateTransferModal";
import VideoPreview from "../VideoPreview";
import {selmoUrl} from "../../../../../src_shared/api/api";
import api from "../../../../../services/axios/axios";
import Loader from "../../../../app/shared/Loader";

const benefits = [
    {id: 1, title: '<strong>Status w zamówieniu</strong> zmienia się na “opłacone”'},
    {id: 2, title: '<strong>Wiesz kiedy i jaką kwotę</strong> wpłacił klient'},
    {id: 3, title: '<strong>Środki są zwracane</strong> w przypadku błędnych danych'},
]

const problems = [
    {id: 1, title: 'Klienci płacą <strong class=\'block\'>w częściach</strong>'},
    {id: 2, title: 'Każdy przelew <strong class=\'block\'>w sprawdzasz ręcznie</strong>'},
    {id: 3, title: 'Klienci nie wysyłają <strong class=\'block\'>odpowiedniej kwoty</strong>'},
]


const AutomaticTransferLanding = () => {
    const [showModal, setShowModal] = useState(false);
    const [showVideoModal, setShowVideoModal] = useState(null);
    const [isWaiting, setIsWaiting] = useState(false);
    const ActiveButton = ({className = ''}) => {
        return (
            <button
                type="button"
                onClick={() => setShowModal(true)}
                className={`button primary small-size mt-[0px] sm:mt-[16px] sm:w-auto w-full text-center configure-blue-media-btn ${className}`}
            >
                Aktywuj
            </button>
        )
    }

    async function downloadVideoFromSubdomain(url) {
        const videoUrl = `${selmoUrl}/api/appVideo?file_link=${url}`;
        const originalFileName = url.split('/').pop();

        setIsWaiting(true);

        try {
            const response = await api.get(videoUrl, {responseType: 'blob'});

            const url = window.URL.createObjectURL(response.data);
            const a = document.createElement('a');
            a.href = url;
            a.download = originalFileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        } finally {
            setIsWaiting(false);
        }
    }

    return (
        <Wrapper>
            <div className="mt-sm-3" style={{background: 'white'}}>
                <section className="blue-media-landing automatic-transfers">
                    <div className="blue-media-header">
                        <img src="/assets/images/bm/bm-00.svg"
                             alt="header image" className="img-bg"
                        />
                        <div className="title">Automatyczne przelewy standardowe</div>
                        <div className="sub-title">Uwolnij się od ręcznego sprawdzania przelewów</div>
                        <button
                            type="button"
                            onClick={() => setShowModal(true)}
                            className="button configure-blue-media-btn"
                        >
                            Aktywuj za darmo
                        </button>
                    </div>
                    <div className="blue-media-content">
                        <div className="row mb-0">
                            <div className="col-md-6 sm:pr-[28px]">
                                <div className="sub-label">
                                    Automatyczne przelewy
                                </div>
                                <div className="title font-bold">
                                    <strong>Uwolnij się od ręcznego sprawdzania przelewów</strong>
                                </div>
                                <div className="desc">
                                    Aktywacja automatycznych przelewów, pozwoli Ci automatycznie księgować płatności
                                    i&nbsp;oszczędzać czas
                                </div>
                                <ActiveButton className="sm:block hidden"/>
                            </div>
                            <div className="col-md-6 pl-sm-0 sm:pr-0 sm:mt-0 mt-[25px]">
                                <div>
                                    <img className="w-[140px] mb-2"
                                         src="/assets/images/automaticTransfer/how-it-works.svg" alt="jak to działa?"/>
                                    <button
                                        type="button"
                                        onClick={() => setShowVideoModal('https://onelineplayer.com/player.html?autoplay=true&autopause=false&muted=false&loop=false&url=https%3A%2F%2Fwww.dropbox.com%2Fscl%2Ffi%2Fwhyl7x55kf5emxlzz4mqh%2Fselmo-autopay-movie-shop-owners.mp4%3Frlkey%3D39yft7btctpqblx9sbyg416ok%26raw%3D1&poster=https%3A%2F%2Fwww.dropbox.com%2Fscl%2Ffi%2Fgv6dsv8r37as9z2h8rq7q%2Fmain-movie-00.webp%3Frlkey%3D3itvumsiii38xq7v2pil50c1a%26raw%3D1&time=true&progressBar=true&overlay=true&muteButton=true&fullscreenButton=true&style=light&quality=auto&playButton=true')}
                                        className="relative group cursor-pointer">
                                        <figure>
                                            <img src="/assets/images/automaticTransfer/main-movie.webp"
                                                 alt="header image" className="Wiesz, które paczki możesz wysyłać"
                                            />
                                        </figure>
                                        <div
                                            className="absolute top-1/2 left-1/2 -translate-y-1/2 transition-all group-hover:scale-[105%] -translate-x-1/2 rounded-full  w-[60px] h-[60px] bg-[rgba(255,255,255,0.3)] backdrop-blur-sm flex items-center justify-center">
                                            <img
                                                className="sm:w-[18px] w-[14px] z-10"
                                                src="/assets/images/automaticTransfer/triangle.svg"
                                                alt="play"
                                            />
                                        </div>
                                    </button>

                                </div>
                            </div>
                        </div>
                        <ActiveButton className="sm:hidden mt-3"/>
                        <div className="py-24">
                            <div className="title font-bold text-center">
                                <strong>Czy te problemy <br/> wydają Ci się znajome?</strong>
                            </div>
                            <div
                                className="flex sm:flex-row flex-col sm:justify-between mt-5 max-w-[580px] sm:mx-auto sm:space-y-0 space-y-5">
                                {problems.map((problem) => (
                                    <div
                                        key={problem.id}
                                        className="sm:w-[33%] text-[#101827] font-medium text-center"
                                    >
                                        <img
                                            className="sm:min-w-[70px] w-[40px] min-w-[40px] sm:w-[70px] mx-auto sm:mb-[14px]"
                                            src="/assets/images/automaticTransfer/cross.svg"
                                            alt=""/>
                                        <div dangerouslySetInnerHTML={{__html: problem.title}}/>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="bg-[#DBF5FF] blue-media-content p-0">
                        <div className="row max-w-[820px] sm:mx-auto pt-[50px] px-6 mb-0">
                            <div className="col-md-6 pr-sm-4">
                                <div className="sub-label sm:mt-8">
                                    Rozwiązanie
                                </div>
                                <div className="title font-bold">
                                    <strong>Aktywuj automatyczne przelewy standardowe</strong>
                                </div>
                                <div className="desc">
                                    Jeśli klient wybierze płatność za pomocą automatycznego przelewu standardowego
                                    i&nbsp;poprawnie przepisze dane do płatności,
                                    <strong className="block">przelew&nbsp;zaksięguje się automatycznie</strong>
                                </div>
                                <ActiveButton className="sm:block hidden"/>
                            </div>
                            <div className="col-md-6 sm:mt-0 mt-[25px]">
                                <figure>
                                    <img src="/assets/images/automaticTransfer/standard-payment.webp"
                                         alt="header image" className="Wiesz, które paczki możesz wysyłać"
                                    />
                                </figure>
                            </div>
                        </div>
                        <div className="sm:mx-0 mx-6 sm:pb-0 pb-[20px] sm:mt-0 mt-3">
                            <ActiveButton className="sm:hidden sm:mt-3 mt-0"/>
                        </div>
                    </div>
                    <div className="blue-media-content">
                        <div className="row">
                            <div className="col-md-6 pr-sm-0">
                                <div className="sub-label">
                                    Automatyczne przelewy
                                </div>
                                <div className="title font-bold">
                                    <strong>Przelew księguje się automatycznie</strong>
                                </div>
                                <div className="space-y-3 mt-6">
                                    {benefits.map((option) => (
                                        <div
                                            className="flex items-center"
                                            key={option.id}
                                        >
                                            <div className="sm:mr-2 mr-1.5 mt-0.5">
                                                <img
                                                    className="w-[20px] min-w-[20px]"
                                                    src="/assets/images/svg/circle-check.svg"
                                                    alt="check"
                                                />
                                            </div>
                                            <div>
                                                <div className="text-[#4B5563] font-medium text-sm"
                                                     dangerouslySetInnerHTML={{__html: option.title}}/>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <ActiveButton className="sm:block hidden"/>
                            </div>
                            <div className="col-md-6 pl-sm-5 sm:mt-0 mt-[25px]">
                                <figure style={{borderRadius: 0}}>
                                    <img src="/assets/images/automaticTransfer/orders.webp"
                                         alt="header image" className="Wiesz, które paczki możesz wysyłać"
                                    />
                                </figure>
                            </div>
                        </div>
                        <ActiveButton className="sm:hidden"/>
                        <div className="sub-label text-center mt-24">Automatyczne przelewy</div>
                        <div className="title smaller text-center">
                            <strong>Jak pomagamy klientom?</strong>
                        </div>
                        <div className="row mt-5">
                            <div className="col-md-5 pr-sm-0">
                                <div className="sub-label">
                                    Automatyczne przelewy
                                </div>
                                <div className="title">
                                    <strong>
                                        Przypominamy o dokładnym przepisaniu danych</strong>
                                </div>
                                <div className="desc">
                                    Przed dokonaniem płatności klient zobaczy komunikat z przypomnieniem o dokładnym
                                    przepisaniu danych do przelewu
                                </div>
                                <ActiveButton className="sm:block hidden"/>
                            </div>
                            <div className="col-md-7 pl-sm-5">
                                <figure>
                                    <img src="/assets/images/automaticTransfer/phone-01.webp"
                                         alt="header image" className="wojemu klientowi, łatwiej jest zapłacić"
                                    />
                                </figure>
                            </div>
                        </div>
                        <ActiveButton className="sm:hidden"/>
                        <div className="row">
                            <div className="col-md-7 pr-sm-5 row sm:my-0 my-[20px]">
                                <figure>
                                    <img src="/assets/images/automaticTransfer/phone-02.webp"
                                         alt="header image" className="Masz przewagę nad konkurencją"
                                    />
                                </figure>
                            </div>
                            <div className="col-md-5 pl-sm-4">
                                <div className="sub-label">
                                    Automatyczne przelewy
                                </div>
                                <div className="title">
                                    <strong>Wysyłamy automatyczne wiadomości</strong>
                                </div>
                                <div className="desc sm:mb-0 mb-3">
                                    Po wybraniu płatności automatycznym przelewem, klient otrzymuje wiadomość z filmem
                                    instruktażowym
                                </div>
                                <ActiveButton/>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-md-5 pr-sm-0">
                                <div className="sub-label">
                                    Automatyczne przelewy
                                </div>
                                <div className="title">
                                    <strong>
                                        Przygotowaliśmy film instruktażowy dla Twoich klientów
                                    </strong>
                                </div>
                                <div className="desc sm:mb-0 mb-3">
                                    Możesz za darmo pobrać i wstawić film instruktażowy dla Twoich klientów
                                </div>
                                {/*<button*/}
                                {/*    type="button"*/}
                                {/*    onClick={() => downloadVideoFromSubdomain('https://r2.selmo.io/selmo-autopay-movie-shop-clients.mp4')}*/}
                                {/*    className={`button primary small-size mt-[0px] sm:mt-[16px] sm:w-auto w-full text-center configure-blue-media-btn ${isWaiting ? 'loading-button' : ''}`}*/}
                                {/*>*/}
                                {/*    {isWaiting &&*/}
                                {/*        <div*/}
                                {/*            style={{display: 'block', borderRadius: 8}}*/}
                                {/*            className={`loader-container`}*/}
                                {/*        >*/}
                                {/*            <div className="circle-loader">*/}
                                {/*                <span className="circle"/>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    }*/}
                                {/*    Pobierz*/}
                                {/*</button>*/}
                                <a
                                    href="https://r2.selmo.io/selmo-autopay-movie-shop-clients.mp4"
                                    target="_blank"
                                    // type="button"
                                    // onClick={() => downloadVideoFromSubdomain('https://r2.selmo.io/selmo-autopay-movie-shop-clients.mp4')}
                                    className={`button primary small-size mt-[0px] sm:mt-[16px] sm:w-auto w-full text-center configure-blue-media-btn ${isWaiting ? 'loading-button' : ''}`}
                                >
                                    {isWaiting &&
                                        <div
                                            style={{display: 'block', borderRadius: 8}}
                                            className={`loader-container`}
                                        >
                                            <div className="circle-loader">
                                                <span className="circle"/>
                                            </div>
                                        </div>
                                    }
                                    Pobierz
                                </a>
                            </div>
                            <div className="col-md-7 pl-sm-5 sm:mb-0 mb-[20px]">
                                <button
                                    type="button"
                                    onClick={() => setShowVideoModal('https://onelineplayer.com/player?autoplay=true&autopause=false&muted=false&loop=false&url=https%3A%2F%2Fwww.dropbox.com%2Fscl%2Ffi%2F8sjp4cszinn2k6usd0dj7%2Fselmo-autopay-movie-shop-clients.mp4%3Frlkey%3Dqmezcednztm5aezoxx4tfhcrg%26raw%3D1&poster=null&time=true&progressBar=true&overlay=true&muteButton=true&fullscreenButton=true&style=light&quality=auto&playButton=true')}
                                    className="relative group cursor-pointer">
                                    <figure>
                                        <img src="/assets/images/automaticTransfer/client-movie.webp"
                                             alt="header image" className="Wiesz, które paczki możesz wysyłać"
                                        />
                                    </figure>
                                    <div
                                        className="absolute top-1/2 left-1/2 -translate-y-1/2 transition-all group-hover:scale-[105%] -translate-x-1/2 rounded-full  w-[60px] h-[60px] bg-[rgba(255,255,255,0.3)] backdrop-blur-sm flex items-center justify-center">
                                        <img
                                            className="sm:w-[18px] w-[14px] z-10"
                                            src="/assets/images/automaticTransfer/triangle.svg"
                                            alt="play"
                                        />
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {showModal &&
                <ActivateTransferModal
                    hide={() => setShowModal(false)}
                    show={showModal}
                />
            }
            {!!showVideoModal &&
                <VideoPreview
                    videoWrapperStyles={{paddingTop: '100%'}}
                    show={showVideoModal}
                    hide={() => setShowVideoModal(null)}
                />
            }
        </Wrapper>
    );
};

export default AutomaticTransferLanding;
