import React from 'react';
import Field from "../../../../../src_shared/components/form/field/Field";
import useLang from "../../../../../src_shared/hooks/useLang";
import CountryField from "./components/CountryField";
import NoDigitalProductInputs from "./components/NoDigitalProductInputs";

const DeliveryData = ({getFieldProps, values, updateValues}) => {
    const {getLangText} = useLang();

    return (
        <div>
            <div className="font-bold mb-3.5">{getLangText('enterDeliveryDataLabel')}</div>
            <div className="grid">
                <Field
                    {...getFieldProps('email')}
                    value={values.email}
                    label={getLangText('emailLabel')}
                    className="big-size"
                    floatedLabel
                    extraProps={{required: true}}
                />
                <CountryField
                    getFieldProps={getFieldProps}
                    values={values}
                    updateValues={updateValues}
                />
                {!+values.productInfo?.digital &&
                    <NoDigitalProductInputs
                        values={values}
                        getFieldProps={getFieldProps}
                    />
                }
                {+values.shopInfo?.extra_input_enabled ?
                    (
                        <Field
                            {...getFieldProps('extra_description')}
                            value={values.extra_description}
                            label={getLangText('additionalInformationLabel')}
                            className="big-size"
                            floatedLabel
                        />
                    ) : null}
            </div>
        </div>
    );
};

export default DeliveryData;