import React, {useState} from 'react';
import ListActions from "./ListActions.js";
import {getStateRoot} from "./listReducer.js";
import SearchInput from "../../../shared/components/form/SearchInput";
import Pagination from "../../../shared/components/pagination/Pagination";
import useLang from "../../../../../src_shared/hooks/useLang";
import useList from "../../../../../src_shared/list/useList";
import Table from "./components/Table";
import ShelvesForm from "../form/ShelvesForm";
import ConfirmRemoveModal from "../../../../../src_shared/components/modal/ConfirmRemoveModal";
import CustomDropdown from "../../../shared/components/dropdown/CustomDropdown";
import {services} from "../../../../RestServices";
import {useExportFile} from "../../../../../src_shared/hooks/useExportFile";
import SelmoProBoxWrapper from "../../../shared/components/SelmoProBox/SelmoProBox";
import {RouterPaths} from "../../../../routes/RouterPath";

const ShelvesList = () => {
    const {getLangText} = useLang();
    const [showModal, setShowModal] = useState(false);

    const {
        pagination: {
            changePage,
            getPagination,
            itemsPerPage,
            nextPage,
            prevPage,
            totalItems,
            updateItemsPerPage
        },
        list: {items, isWaiting, loadData},
        search,
        sort: {updateSortOptions, getSortClass},
        remove: {askForRemoveItem, isModalVisible, cancelItemRemoval, markedToRemove, confirmItemRemoval},
    } = useList({
        ListActions,
        getStateRoot,
    });

    const {exportFile} = useExportFile({
        rest: services.API_WAREHOUSE_SHELVES_QR_CODE,
    })

    return (
        <SelmoProBoxWrapper
            title="storageLabel"
            desc="storageSelmoProDesc"
            variant={2}
            redirect={RouterPaths.SelmoProDepot}
        >
            <div className="table-list-wrapper">
                <div className="default-shadow-box pb-0">
                    <div className="table-header">
                        <div className="top-part">
                            <h1 className="title mb-sm-0 mb-2">
                                {getLangText('storageLabel')}
                                {totalItems > 0 &&
                                    <div className="badge">{totalItems}</div>
                                }
                            </h1>
                            <CustomDropdown
                                className="ml-auto"
                                icon="icon-print"
                                tooltipTitle={getLangText('exportLabel')}
                            >
                                <button
                                    type="button"
                                    className="custom-option"
                                    onClick={exportFile}
                                >
                                    {getLangText('printQrCodeShelvesLabel')}
                                </button>
                            </CustomDropdown>
                            <SearchInput
                                placeholder={getLangText('searchPlaceholder')}
                                search={search}
                            />
                            <button
                                className="button primary small-size ml-2"
                                type="button"
                                onClick={() => setShowModal(true)}
                            >
                                {getLangText('addButton')}
                            </button>
                        </div>
                    </div>
                    <Table
                        isWaiting={isWaiting}
                        items={items}
                        updateSortOptions={updateSortOptions}
                        getSortClass={getSortClass}
                        askForRemoveItem={askForRemoveItem}
                        markedToRemove={markedToRemove}
                    />
                </div>
                <Pagination
                    getLangText={getLangText}
                    pages={getPagination()}
                    items={items}
                    itemsPerPage={itemsPerPage}
                    totalItems={totalItems}
                    onPrevPage={prevPage}
                    onChangePage={changePage}
                    onNextPage={nextPage}
                    onChangeItemsPerPage={updateItemsPerPage}
                    classes={{
                        prevIcon: 'icon-chevron left',
                        nextIcon: 'icon-chevron right',
                    }}
                    showItemsPerPageSelect
                />
            </div>
            {isModalVisible &&
                <ConfirmRemoveModal
                    show={isModalVisible}
                    title={getLangText('delete_modal_accept')}
                    subTitle={`${markedToRemove?.shelf}`}
                    hide={cancelItemRemoval}
                    confirm={confirmItemRemoval}
                    dialogClassName="w-420"
                />
            }
            {showModal &&
                <ShelvesForm
                    modalVisible={showModal}
                    hideModal={() => setShowModal(false)}
                    loadData={loadData}
                />
            }
        </SelmoProBoxWrapper>
    );
};

export default ShelvesList;
