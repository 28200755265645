import React from 'react';
import {batch, useDispatch, useSelector} from "react-redux";
import useStatusFilters from "../statusFilter/useStatusFilters";
import {saveToLS} from "../../../helpers/LS";
import FiltersDropdown from "../filtersDropdown/FiltersDropdown";
import useLang from "../../../hooks/useLang";

const ColumnVisibilityFilter = ({
									ListActions,
									FiltersActions,
									getFiltersStateRoot,
									className,
									extraProps,
									options,
									name,
									filterTitle,
									selectAllName
								}) => {

	const dispatch = useDispatch();
	const {getLangText} = useLang();

	const {
		statuses,
		selectAll,
		isSelectAll,
		allItemsClick,
		handleCheckBoxClick,
		updateLocallyStatusesState,
		getCheckboxValue,
		locallyStatusFilter,
		setIsSelectAll,
	} = useStatusFilters(
		ListActions,
		getFiltersStateRoot,
		FiltersActions,
		name,
		selectAllName,
		options
	);

	const saveFilters = () => {
		if (locallyStatusFilter.length === 0) {
			saveToLS(selectAllName, false)
			saveToLS(name, [])
			dispatch(FiltersActions.data.setValue(selectAllName, false));
			dispatch(FiltersActions.data.setValue(name, []));
		} else if (locallyStatusFilter.length === options.length) {
			const preparedFilters = options.map((i) => i.value);
			saveToLS(selectAllName, true)
			saveToLS(name, preparedFilters)
			setIsSelectAll(true)
			dispatch(FiltersActions.data.setValue(selectAllName, true));
			dispatch(FiltersActions.data.setValue(name, preparedFilters));
		} else {
			batch(() => {
				saveToLS(selectAllName, false)
				saveToLS(name, locallyStatusFilter)
				dispatch(FiltersActions.data.setValue(selectAllName, false));
				dispatch(FiltersActions.data.setValue(name, locallyStatusFilter));
			});
		}
	};


	const filterButtonTitle = () => {
		return {
			text: `<div class="column-visibility-button">
						<span class="d-md-none">${getLangText('customizeTableLabel')}</span>
						<i class="icon-settings" />
					</div>`,
			className: '',
		};
	};

	const buttonTitle = filterTitle ? filterTitle() : filterButtonTitle();

	return (
		<FiltersDropdown
			onClose={() => updateLocallyStatusesState(statuses, selectAll)}
			onSave={saveFilters}
			buttonTittle={buttonTitle}
			onSecondButtonClick={() => updateLocallyStatusesState()}
			className={`column-filters ${className}`}
			tooltipTitle={getLangText('customizeTableLabel')}
		>
			<div className="filters-header">
				<div className="title">{getLangText('customizeTableLabel')}</div>
				<div className="sub-title">{getLangText('manageColumnDisplayLabel')}</div>
			</div>
			<div className="checkbox-filter-wrapper">
				<div className="form-group checkbox-form">
					<input
						className="checkbox-input form-control"
						type="checkbox"
						onChange={allItemsClick}
						checked={isSelectAll}
					/>
					<span className="checkbox-checkmark"/>
					<label className="control-label">{getLangText('allLabel')}</label>
				</div>
				{options.map((status) => (
					<div
						key={status.value}
						className="form-group checkbox-form"
					>
						<input
							className="checkbox-input form-control"
							type="checkbox"
							onChange={() => handleCheckBoxClick(status.value)}
							checked={getCheckboxValue(status.value)}
						/>
						<span className="checkbox-checkmark"/>
						<label className="control-label">{getLangText(status.label)}</label>
					</div>
				))}
			</div>
		</FiltersDropdown>
	);
};

ColumnVisibilityFilter.defaultProps = {
	className: '',
	extraProps: {},
	options: [],
	filterTitle: null,
}

export default ColumnVisibilityFilter;
