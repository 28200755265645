import Composition from "../../shared/helpers/Composition";
import createReducer from "../../shared/helpers/createReducer";
import FormReducer from "../../../../src_shared/form/FormReducer";
import BasketDeliveryMethodModel from "../../../../modules/models/basket/BasketDeliveryMethodModel";
import {CUSTOMER_BASKET_DELIVERY_ADDRESS_VIEW} from "../../shared/enums/CustomerBasketViews";

export const getStateRoot = (state) => state.basket.page;

export const prefix = 'BASKET_PAYMENT_PAGE_FORM_';

export const getInitState = () => FormReducer.getInitState(BasketDeliveryMethodModel.getModel(), {
	basketView: CUSTOMER_BASKET_DELIVERY_ADDRESS_VIEW,
})

const getReduceMap = () => new Composition(
	FormReducer.getReduceMap(),
	{
		SET_BASKET_VIEW: (state, action) => ({...state, basketView: action.view}),
	}
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();
