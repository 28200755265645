import createReducer from "../../shared/helpers/createReducer";
import DataReducer from "../../../../src_shared/form/data/DataReducer";
import {getReduceMap} from "../../../../src_shared/filters/filterReducer";
import {
    defaultTableColumn, CLIENTS_TABLE_COLUMN,
} from "../lists/clientsList/components/table/ClientsTableColumnEnums";
import {getFromLS} from "../../shared/helpers/ls";
import {parseParams} from "../../../../src_shared/list/ListActions";
import history from "../../../admin/components/history";

const queryParams = history.location.search;
const parsedParams = parseParams(queryParams)
const LSTableColumn = getFromLS('clientsTableColumn')
const preparedDefaultTableColumnSelectAll = getFromLS('clientsTableColumnSelectAll') || CLIENTS_TABLE_COLUMN.length === LSTableColumn?.length;
const preparedIdDeleted = parsedParams.is_deleted || 'all';

const getPreparedDefaultTableColumn = () => {
    if (LSTableColumn) {
        return LSTableColumn;
    } else {
        return defaultTableColumn;
    }
}

export const getStateRoot = (state) => state.clients.filters;

export const getInitState = () => DataReducer.getInitState({
        clientsTableColumn: getPreparedDefaultTableColumn(),
        clientsTableColumnSelectAll: preparedDefaultTableColumnSelectAll,
        is_deleted: preparedIdDeleted
    },
    {
        clientsTableColumn: getPreparedDefaultTableColumn(),
        clientsTableColumnSelectAll: preparedDefaultTableColumnSelectAll,
        is_deleted: 'all'
    }
)

export const prefix = 'CLIENTS_FILTERS_';

export const getInstance = () => createReducer(
    getInitState(),
    getReduceMap(),
    prefix,
);

export default getInstance();
