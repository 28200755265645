import React from 'react';
import useLang from "../../../../../../src_shared/hooks/useLang";
import TextArea from "../../../../../../src_shared/components/form/textarea/TextArea";
import CharactersCounter from "../../../../../../src_shared/components/ui/CharactersCounter";
import AddButton from "./addButton/AddButton";
import CheckboxField from "../../../../../../src_shared/components/form/checkboxField/CheckboxField";
import cn from "clsx";

const Message = ({
                     setValue,
                     values,
                     updateValues,
                     isValidationActive,
                     setIsValidationActive,
                     noInteractionSelectedError,
                     getFieldProps
                 }) => {
    const {getLangText} = useLang();

    const checkboxLabel = () => (
        <div className="text-sm -mt-[3px]">
            <div className="font-bold text-[#212529]">{getLangText('sendMessageLabel')}</div>
            <div className="text-desc font-medium">{getLangText('afterEnterCodeWillSendMessage')}</div>
        </div>
    )

    return (
        <div>
            <div
                className={cn('rounded-[8px] border p-3', noInteractionSelectedError ? 'border-[#F87171]' : 'border-[#DFE1E4]')}>
                <CheckboxField
                    className="align-items-start mb-0"
                    name="reply_message"
                    LabelComponent={checkboxLabel()}
                    setValue={setValue}
                    value={values.reply_message}
                />
                {!!+values.reply_message &&
                    <div className="sm:pl-[24px] animate-tutorialSlideAndFadeIn">
                        <div className="characters-counter-parent">
                            <TextArea
                                name="message"
                                label={getLangText('messageContentLabel')}
                                setValue={setValue}
                                value={values.message}
                                placeholder={getLangText('enterMessageLabel')}
                                rows={5}
                                className="mt-3"
                                extraProps={{
                                    maxLength: 640,
                                    required: true
                                }}
                                style={{resize: values.message?.length > 150 ? 'vertical' : 'none'}}
                            />
                            <CharactersCounter value={values.message} max={640}/>
                        </div>
                        <AddButton
                            values={values}
                            setValue={setValue}
                            updateValues={updateValues}
                            isValidationActive={isValidationActive}
                            setIsValidationActive={setIsValidationActive}
                            getFieldProps={getFieldProps}
                        />
                    </div>
                }
            </div>
        </div>
    );
};

export default Message;
