import React, {useState} from 'react';
import {RouterPaths} from "../../../../routes/RouterPath";
import {services} from "../../../../RestServices";
import useFormLocal from "../../../../../src_shared/hooks/useFormLocal";
import useLang from "../../../../../src_shared/hooks/useLang";
import ReelsModel from "../../../../../modules/models/reels/ReelsModel";
import {Link, useParams} from "react-router-dom";
import ConfirmModal from "../../../live/details/default/summary/export/ConfirmModal";
import useRemoveData from "../../../../../src_shared/hooks/useRemoveData";
import AssignProductsModal from "./assignProducts/Form";
import ReelsFormComponent from "./components/ReelsForm";
import {TEST_ACCOUNT_SHOP_ID} from "../../../../../src_shared/enums/TrustedShopsIds";
import {useSelector} from "react-redux";

const ReelsForm = () => {
    const {getLangText} = useLang()
    const {id} = useParams();
    const {userData} = useSelector((state) => state.session);

    const [showAssignProductsModal, setShowAssignProductsModal] = useState(false);
    const rest = !!+id ? `${services.API_STORAGE_REELS}/${id}` : services.API_STORAGE_REELS;
    const [{
        onSave,
        isWaiting,
        getFieldProps,
        isLoading,
        values,
        setValue,
        updateValues,
    }] = useFormLocal({
        rest,
        method: !!+id ? 'put' : 'post',
        model: ReelsModel,
        initialAutoLoad: !!+id,
        redirectRoute: RouterPaths.ReelsList
    });

    const [{
        openModal: openRemoveModal,
        hideModal: hideRemoveModal,
        confirmModalVisible,
        itemToRemove,
        isDeleting,
        removeItem,
    }] = useRemoveData({
        rest,
        redirectRoute: RouterPaths.ReelsList
    });

    return (
        <>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <Link to={RouterPaths.ReelsList}>
                            {getLangText('reelsLabel')}
                        </Link>
                    </li>
                    <li className="active">
                        <div>{getLangText(!!id ? 'editionLabel' : 'newLabel')}</div>
                    </li>
                </ul>
                <Link
                    to={RouterPaths.ReelsList}
                    className="button back-to-panels mb-3">
                    <i className="icon-arrow-left"/>
                    {getLangText('backToReelsLabel')}
                </Link>
            </div>
            <div className="settings-page sub-settings-page export-sub-settings">
                <div className="header-wrapper">
                    <h1>
                        {getLangText('reelsLabel')}
                    </h1>
                    <div className="description">
                        {getLangText('reelsSubTitle')}
                    </div>
                </div>
                <ReelsFormComponent
                    setValue={setValue}
                    openRemoveModal={openRemoveModal}
                    updateValues={updateValues}
                    values={values}
                    onSave={onSave}
                    isWaiting={isWaiting}
                    isLoading={isLoading}
                    getFieldProps={getFieldProps}
                    setShowAssignProductsModal={setShowAssignProductsModal}
                />
            </div>
            {showAssignProductsModal &&
                <AssignProductsModal
                    hideModal={() => setShowAssignProductsModal(false)}
                    modalVisible={showAssignProductsModal}
                    updateProducts={(products) => setValue('products', products)}
                    defaultSelectedProducts={values.products}
                />
            }
            {confirmModalVisible &&
                <ConfirmModal
                    dialogClassName="w-380"
                    title={getLangText('removeLabel')}
                    SubTitle={() => <span dangerouslySetInnerHTML={{
                        __html: getLangText({
                            key: 'wantToRemoveLabel',
                            data: [itemToRemove.name]
                        })
                    }}/>}
                    saveButton={getLangText('removeButton')}
                    show={confirmModalVisible}
                    isWaiting={isDeleting}
                    onSubmit={removeItem}
                    hide={hideRemoveModal}
                    saveButtonClass="danger"
                />
            }
        </>
    );
};

export default ReelsForm;
