import React from 'react';
import Button from "../../../../src_shared/components/ui/Button/Button";
import useLang from "../../../../src_shared/hooks/useLang";
import useLogout from "../../shared/hooks/useLogout";
import useFetchData from "../../../../src_shared/hooks/useFetchData";
import {services} from "../../../RestServices";
import {useLocation} from "react-router-dom";
import {parseParams} from "../../../../src_shared/list/ListActions";
import {saveToLS} from "../../../../src_shared/helpers/LS";


const whyWorthItOptions = [
    {
        id: 0,
        title: 'whyWorthItTutorialLabel1',
        desc: 'whyWorthItTutorialSubLabel1',
    },
    {
        id: 1,
        title: 'whyWorthItTutorialLabel2',
        desc: 'whyWorthItTutorialSubLabel2',
    },
    {
        id: 2,
        title: 'whyWorthItTutorialLabel3',
        desc: 'whyWorthItTutorialSubLabel3',
    },
    {
        id: 3,
        title: 'whyWorthItTutorialLabel4',
        desc: 'whyWorthItTutorialSubLabel4',
    },
]

const ConnectInstagram = () => {
    const {getLangText} = useLang();
    const {logout} = useLogout();
    const {search, pathname} = useLocation();
    const query = parseParams(search);

    const afterSubmit = (response) => {
        saveToLS('selmoAuthToken', query.selmo_token);
        window.location.href = response.instagramUrl
    }

    const [{isLoading: isWaitingForInstagram, fetchData: getInstagramLink}] = useFetchData({
        rest: `${services.API_TUTORIAL_SOCIAL_LINKS}?selmo_token=${query.selmo_token}`,
        afterSubmit,
        initialAutoLoad: false,
    })

    return (
        <div
            className={`sm:py-0 py-6 sm:px-0 px-6 w-[100vw] min-h-[100vh] flex sm:items-center sm:justify-center`}>
            <button
                onClick={logout}
                type="button"
                className="absolute bottom-2 left-2 text-desc text-xs underline"
            >
                {getLangText('changeAccountLabel')}
            </button>
            <div
                className={`w-full sm:my-0 my-auto md:px-0 px-6 rounded-[12px] shadow-default bg-white md:w-[800px] min-h-[450px] md:min-h-[600px] flex flex-col ${true ? 'animate-tutorialSlideAndFadeIn' : ''}`}>
                <div className={`text-center animate-tutorialSlideAndFadeInDelay1 opacity-0 ${"mt-10 sm:mb-10 mb-8"}`}>
                    <img width={60} className="mx-auto mb-2" src="/assets/images/svg/integration-icon.svg"
                         alt="home icon"/>
                    <h1 className="sm:text-2xl text-xl font-bold"
                        dangerouslySetInnerHTML={{__html: getLangText('connectYourPageOnInstagramLabel')}}/>
                    {/*<h2 className="text-[#9CA3AF] text-sm font-medium" dangerouslySetInnerHTML={{__html: getLangText(desc)}} />*/}
                </div>
                <div
                    className="animate-tutorialSlideAndFadeInDelay2 opacity-0 w-full grow flex flex-col">
                    <div className="max-w-[665px] mx-auto w-full mb-10 text-center">
                        <Button
                            getLangText={getLangText}
                            onClick={getInstagramLink}
                            isWaiting={isWaitingForInstagram}
                            title="connectWithInstagram"
                            className="button border-button google-button sm:max-w-[300px] w-full overflow-hidden"
                            Icon={<img width={18} height={18} className="mr-1" alt="google"
                                       src="/assets/images/instagram.png"/>}
                        />
                    </div>
                    <div
                        className="bg-[#FCFCFD] border-t border-[#F3F4F6] rounded-b-[12px] mt-auto pt-6 sm:mx-0 sm:px-0 px-6 -mx-6">
                        <div className="max-w-[665px] mx-auto w-full mb-8">
                            <div
                                className="font-bold text-sm text-[#6B7280]">{getLangText('pricingWhyWorthyTitle')}</div>
                            <div className="grid sm:grid-cols-2 sm:gap-8 gap-5 mt-6">
                                {whyWorthItOptions.map((option) => (
                                    <div
                                        className="flex"
                                        key={option.id}
                                    >
                                        <div className="mr-3">
                                            <img width={20} src="/assets/images/svg/check-icon.svg"/>
                                        </div>
                                        <div>
                                            <div className="text-[#101827] font-bold text-sm">
                                                {getLangText(option.title)}
                                            </div>
                                            <div className="text-[#4B5563] text-sm font-medium">
                                                {getLangText(option.desc)}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div
                                className="mt-7 flex sm:items-center border border-[#DFE1E4] rounded-[8px] bg-[#fff] p-3.5">
                                <div className="mr-2.5">
                                    <div className="w-[20px] sm:mt-0 mt-1">
                                        <img className="w-full" width={20} src="/assets/images/svg/proof.svg"
                                             alt="proof"/>
                                    </div>
                                </div>
                                <div>
                                    <div className="text-[#101827] text-sm"
                                         dangerouslySetInnerHTML={{__html: getLangText('whyYouShouldTrustUsStrongSubLabel')}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConnectInstagram;
