import React, {useEffect, useState} from 'react';
import EmptyList from "../../../../shared/EmptyList.jsx";
import moment from "moment";
import CustomDropdown from "../../../../shared/components/dropdown/CustomDropdown";
import {batch, useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {getStateRoot} from "../../edit/formReducer";
import {getStateRoot as getProductReserveListStateRoot} from "../../reserveList/listReducer";
import {getStateRoot as getSharedEditProductStateRoot} from "../../shared/sharedEditProductReducer";
import {
    CURRENT_BASKET_PRODUCT_VIEW,
    RESERVE_LIST_PRODUCT_VIEW
} from "../../../../shared/enums/EditProductViews";
import ConfirmModal from "../../../../live/details/default/summary/export/ConfirmModal";
import {useModal} from "../../../../shared/hooks/useModal";
import {selmoUrl} from "../../../../../../src_shared/api/api";
import {services} from "../../../../../RestServices";
import {showAlert} from "../../../../shared/components/alert/AlertActions";
import FormActions from "../../edit/FormActions";
import CountDownNotificationActions from "../../../../shared/components/notification/CountDownNotificationActions";
import {GO_TO_ORDER_NOTIFICATION} from "../../../../shared/enums/CountDownNotificationsTypes";
import {findOrderStatusById} from "../../../../shared/enums/OrderStatuses";
import FiltersActions from "../../filters/FiltersActions";
import SendSummaryMessageModal from "../../../shared/sendSummaryMessageModal/SendSummaryMessageModal";
import useLang from "../../../../../../src_shared/hooks/useLang";
import {useUnitPrice} from "../../../../shared/helpers/Price";
import ClientAvatar from "../../../../live/shared/Avatar";
import ProductTypeIcon from "../../../../shared/components/productType/ProductTypeIcon";
import useCheckboxMultiselect from "../../../../../../src_shared/list/checkboxMultiselect/useCheckboxMultiselect";
import ProductReserveListActions from "../../reserveList/ListActions";
import CheckboxField from "../../../../../../src_shared/components/form/checkboxField/CheckboxField";
import SelectedRow from "../selectedRow/SelectedRow";
import api from "../../../../../../services/axios/axios";
import {
    PIANO_FASHION_SHOP_ID,
    TEST_ACCOUNT_SHOP_ID,
    TIM_SHOP_ID
} from "../../../../../../src_shared/enums/TrustedShopsIds";
import {convertToUserTimeZone} from "../../../../shared/helpers/dateHelpers";

const Table = ({items, isWaiting, updateSortOptions, getSortClass, loadData, hiddenActions = false}) => {

    const dispatch = useDispatch();
    const {getLangText} = useLang();
    const {getPrice} = useUnitPrice();
    const getParsedPrice = (price) => {
        const preparedPrice = parseFloat(price.toString().replace(',', '.'))
        return getPrice(preparedPrice)
    }
    const {userData} = useSelector((state) => state.session);

    const [modalOpen, setModalOpen] = useModal(false);
    const [createOrderModalOpen, setCreateOrderModalOpen] = useModal(false)
    const [sendSummaryMessageModalOpen, setSendSummaryMessageModalOpen] = useModal(false);
    const [defaultTemplateMessage, setDefaultTemplateMessage] = useState('')
    const [selectedClient, setSelectedClient] = useState(null)
    const [waiting, setWaiting] = useState(false);
    const [data, editProductView] = useSelector(createSelector(getStateRoot, getSharedEditProductStateRoot, (x1, x2) => [
        x1.data,
        x2.editProductView,
    ]));

    const {selectedItems} = useSelector(createSelector(getProductReserveListStateRoot, (stateRoot) => stateRoot));

    const isReserveList = editProductView === RESERVE_LIST_PRODUCT_VIEW;
    const isCurrentCart = editProductView === CURRENT_BASKET_PRODUCT_VIEW;

    const {
        selectAllItems,
        toggleSelection,
        checkIfSomeExist,
        isSelected,
        updateSelection,
    } = useCheckboxMultiselect(ProductReserveListActions.selectedItems, selectedItems, items);

    const deleteClient = async () => {
        setWaiting(true)
        try {
            if (isCurrentCart) {
                await api.post(`${selmoUrl}/${services.API_PRODUCT_CURRENT_BASKET}`, {
                    order_product_id: selectedClient.order_product_id,
                })
            } else {
                await api.delete(`${selmoUrl}/${services.API_PRODUCT_RESERVE_LIST}/${selectedClient.id}`)
            }
            setModalOpen(false)
            loadData(data.id);
            batch(() => {
                dispatch(FormActions.loadData(data.id))
                dispatch(showAlert('yuhuLabel', 'successfulRemovedLabel'))
            })
        } catch (e) {
            dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
        } finally {
            setWaiting(false)
        }
    }

    const onButtonClick = (user) => {
        setSelectedClient(user)
        setModalOpen(true)
    };

    const afterSubmit = (shop_order_id, sentMessage = false) => {
        batch(() => {
            dispatch(FormActions.loadData(data.id));
            loadData(data.id);
            dispatch(CountDownNotificationActions.setNotificationVisibility(true, GO_TO_ORDER_NOTIFICATION, {shop_order_id}))
            if (sentMessage) {
                dispatch(showAlert('yuhuLabel', 'successfulOrderCreatedAndSentSummaryLabel'))
            } else {
                dispatch(showAlert('yuhuLabel', 'successfulCreatedLabel'))
            }
        })
    }

    const createOrder = async () => {
        setWaiting(true)
        try {
            const response = await api.post(`${selmoUrl}/${services.API_PRODUCT_ORDER_FROM_RESERVE_LIST}/${selectedClient.id}`)
            setCreateOrderModalOpen(false)
            if (response.data.summary_message) {
                setDefaultTemplateMessage({
                    ...defaultTemplateMessage,
                    message: response.data.summary_message,
                    order_id: response.data.message,
                    shop_order_id: response.data.shop_order_id,
                });
                setSendSummaryMessageModalOpen(true);
                return;
            }
            afterSubmit(response.data.shop_order_id);

        } catch (e) {
            if (e.response.data.message !== 'Something went wrong') {
                dispatch(showAlert(e.response.data.message, '', 'alert-fail'))
                return;
            }
            dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
        } finally {
            setWaiting(false)
        }
    }

    const onCreateOrderButtonClick = (user) => {
        setSelectedClient(user)
        setDefaultTemplateMessage({
            ...defaultTemplateMessage,
            recipient: user.user_id,
            instagramMessage: user.social_type === 'instagram'
        })
        setCreateOrderModalOpen(true)
    }

    const getCorrectName = (item) => {
        if (item.name) {
            return item.name
        }
        if (item.first_name) {
            return `${item.first_name} ${item.last_name}`
        }
        return getLangText('noNameLabel')
    }

    const colSpan = !!selectedItems.length ? (!!+data.has_size ? 6 : 5) : 1

    useEffect(() => {
        return () => dispatch(FiltersActions.resetAllFilters());
    }, [])

    return (
        <EmptyList
            items={items}
            isLoading={isWaiting}
            className="with-padd pt-5"
            imgSrc="/assets/images/empty/clients-empty.svg"
            title={getLangText('emptyHereLabel')}
            text={getLangText('emptyClientListDescription')}
        >
            {(+userData.id === TEST_ACCOUNT_SHOP_ID || +userData.id === PIANO_FASHION_SHOP_ID || +userData.id === TIM_SHOP_ID) && isReserveList &&
                <div className="sm:hidden">
                    <SelectedRow
                        ListActions={ProductReserveListActions}
                        getStateRoot={getStateRoot}
                        colSpan={colSpan}
                        selectAllItems={selectAllItems}
                        selectedItems={selectedItems}
                        isSelected={!!selectedItems.length}
                        checkIfSomeExist={checkIfSomeExist}
                        updateSelection={updateSelection}
                    />
                </div>
            }
            <div className="table-wrapper">
                <div className="table-responsive overflow-visible">
                    <table className="table live-table smaller-padd">
                        <thead>
                        <tr>
                            {(!hiddenActions && isReserveList) &&
                                <SelectedRow
                                    ListActions={ProductReserveListActions}
                                    getStateRoot={getStateRoot}
                                    colSpan={colSpan}
                                    selectAllItems={selectAllItems}
                                    selectedItems={selectedItems}
                                    isSelected={!!selectedItems.length}
                                    checkIfSomeExist={checkIfSomeExist}
                                    updateSelection={updateSelection}
                                />
                            }
                            {!selectedItems.length &&
                                <>
                                    <th
                                        name="name"
                                        onClick={updateSortOptions}
                                        className={getSortClass('name')}
                                    >
							<span name="name">
								{getLangText('nameAndSurnameLabel')}
							</span>
                                    </th>
                                    {!isReserveList &&
                                        <th
                                            name="status"
                                            onClick={updateSortOptions}
                                            className={getSortClass('status')}
                                        >
									<span name="status">
										{getLangText('statusLabel')}
									</span>
                                        </th>
                                    }
                                    {data.has_sizes === '1' &&
                                        <th
                                            name="sizes_size"
                                            onClick={updateSortOptions}
                                            className={getSortClass('sizes_size')}
                                        >
							<span name="sizes_size">
								{getLangText('variantLabel')}
							</span>
                                        </th>
                                    }
                                    <th
                                        name="price"
                                        onClick={updateSortOptions}
                                        className={getSortClass('price')}
                                    >
							<span name="price">
								{getLangText('priceLabel')}
							</span>
                                    </th>
                                    <th
                                        name="quantity"
                                        onClick={updateSortOptions}
                                        className={getSortClass('quantity')}
                                    >
								<span name="quantity">
									{getLangText('quantityLabel')}
								</span>
                                    </th>
                                    {!isReserveList &&
                                        <th>
                                            {getLangText('sourceLabel')}
                                        </th>
                                    }
                                    {isCurrentCart && +userData.id === TIM_SHOP_ID &&
                                        <th>
                                            Tagi
                                        </th>
                                    }
                                    {isReserveList && !!+userData.selmo_pro_active &&
                                        <th
                                            name="date"
                                            onClick={updateSortOptions}
                                            className={`text-right ${getSortClass('last_message_date')}`}
                                        >
                                        <span name="last_message_date">
                                            {getLangText('dateOfMessageSent')}
                                        </span>
                                        </th>
                                    }
                                    <th
                                        name="date"
                                        onClick={updateSortOptions}
                                        className={`text-right ${getSortClass('date')}`}
                                    >
                                        <span name="date">
                                            {getLangText('addedDateLabel')}
                                        </span>
                                    </th>
                                    <th/>
                                </>
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {items.map((item) => (
                            <tr
                                key={isReserveList ? item.id : item.order_product_id}
                                className="not-clicked-tr"
                            >
                                {(!hiddenActions && isReserveList) &&
                                    <td className="checkbox-col mobile-visible">
                                        <CheckboxField
                                            showLabel={false}
                                            name="selectedItems"
                                            onClick={(e) => e.stopPropagation()}
                                            className="mb-0"
                                            value={isSelected(item.id)}
                                            setValue={(field, value) => toggleSelection(item, field, value)}
                                        />
                                    </td>
                                }
                                <td className="name">
                                    <div className="avatar-row">
                                        <ClientAvatar
                                            photo={item.photo}
                                            type={item.social_type}
                                        />
                                        <div>
                                            {getCorrectName(item)}
                                            {item.shop_client_id &&
                                                <div className="client-number">
                                                    {getLangText('clientNumberFullLabel')}: <strong>{item.shop_client_id}</strong>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </td>
                                {!isReserveList &&
                                    <td className="status-col status-in-storage left-mobile-margin">
                                        <div
                                            className={`status-bg ${findOrderStatusById(item.status)?.color}`}>{getLangText(findOrderStatusById(item.status)?.label)}
                                        </div>
                                    </td>
                                }
                                {data.has_sizes === '1' &&
                                    <td className="small-font-size w-lg-auto w-100 left-mobile-margin order-lg-0 order-2">
                                        {item.sizes_size ?
                                            <div className="status-bg grey variant">
                                                {item.sizes_size}
                                            </div> :
                                            <div className="d-none d-lg-block">{getLangText('lackLabel')}</div>
                                        }
                                    </td>
                                }
                                <td className="small-font-size mobile-grey  my-lg-0 my-1 order-lg-0 order-3 left-mobile-margin circle-mobile-separator">
                                    {(item.price || item.sizes_price) ?
                                        (item.sizes_price ? `${getParsedPrice(+item.sizes_price)}` : `${getParsedPrice(+item.price)}`) :
                                        `${getPrice(0)}`
                                    }
                                </td>
                                <td
                                    data-thead={`${getLangText('quantityLabel')}:`}
                                    className="small-font-size with-mobile-thead inline-thead my-lg-0 my-1 order-lg-0 order-3"
                                >
                                    {item.quantity}
                                </td>
                                {!isReserveList &&
                                    <td className={`d-lg-table-cell d-flex text-center order-lg-0 order-4 ${item.type ? '' : 'p-0'}`}>
                                        <ProductTypeIcon product={item}/>
                                    </td>
                                }
                                {isCurrentCart && +userData.id === TIM_SHOP_ID &&
                                    <td
                                        data-thead="Tagi:"
                                        className={`d-lg-table-cell with-mobile-thead inline-thead small-col d-flex text-center order-lg-0 order-4`}>
                                        {!!+item.has_tags ?
                                            <img src="/assets/images/svg/paid.svg" width={14} alt=""/> :
                                            <i className="icon-cross text-[8px] sm:text-[10px] text-desc sm:mt-0 mt-0.5"/>
                                        }
                                    </td>
                                }
                                {isReserveList && !!+userData.selmo_pro_active &&
                                    <td
                                        data-thead={`${getLangText('dateOfMessageSent')}:`}
                                        className="text-sm-right date w-lg-auto w-100 order-lg-0 order-4 with-mobile-thead inline-thead mb-0 left-mobile-margin mr-0"
                                    >
                                        {!!item.last_message_date ?
                                            <>
                                                <span>{convertToUserTimeZone(item.last_message_date).format('DD.MM.YYYY')}</span>
                                                <span>{convertToUserTimeZone(item.last_message_date).format('HH:mm:ss')}</span>
                                            </> :
                                            getLangText('lackLabel')
                                        }
                                    </td>
                                }
                                <td
                                    data-thead={`${getLangText('addedLabel')}:`}
                                    className="text-sm-right date w-lg-auto w-100 order-lg-0 order-4 with-mobile-thead inline-thead mb-0 left-mobile-margin mr-0"
                                >
                                    <span>{convertToUserTimeZone(item.date).format('DD.MM.YYYY')}</span>
                                    <span>{convertToUserTimeZone(item.date).format('HH:mm:ss')}</span>
                                </td>
                                <td className="text-right absolute-dropdown-dots">
                                    {!hiddenActions &&
                                        <CustomDropdown
                                            icon="icon-dots"
                                        >
                                            {!isReserveList &&
                                                <a
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    href={`/zamowienia/${item.shop_order_id}`}
                                                    className="custom-option"
                                                >
                                                    {getLangText('goToOrderButton')}
                                                </a>
                                            }
                                            {item.conversation_id &&
                                                <a
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    href={`/wiadomosci/${item.conversation_id}`}
                                                    className="custom-option"
                                                >
                                                    {getLangText('goToConversationButton')}
                                                </a>
                                            }
                                            {isReserveList &&
                                                <button
                                                    className="custom-option"
                                                    onClick={() => onCreateOrderButtonClick(item)}
                                                >
                                                    {getLangText('createOrderButton')}
                                                </button>
                                            }
                                            {(isReserveList || isCurrentCart) &&
                                                <button
                                                    className="custom-option"
                                                    onClick={() => onButtonClick(item)}
                                                >
                                                    {getLangText('removeButton')}
                                                </button>
                                            }
                                        </CustomDropdown>
                                    }
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <ConfirmModal
                title={getLangText('clientsRemoveClientTitle')}
                SubTitle={() => <span dangerouslySetInnerHTML={{
                    __html: getLangText({
                        key: 'askForRemoveClientFromListLabel',
                        data: [selectedClient.name]
                    })
                }}/>}
                saveButton={getLangText('removeButton')}
                show={modalOpen}
                isWaiting={waiting}
                onSubmit={deleteClient}
                hide={() => setModalOpen(false)}
                saveButtonClass="danger"
            />
            {createOrderModalOpen &&
                <ConfirmModal
                    title={getLangText('createOrderButton')}
                    SubTitle={() => <span>{getLangText('askForCreateOrderLabel')}</span>}
                    saveButton={getLangText('createButton')}
                    show={createOrderModalOpen}
                    isWaiting={waiting}
                    onSubmit={createOrder}
                    hide={() => setCreateOrderModalOpen(false)}
                />
            }
            {sendSummaryMessageModalOpen &&
                <SendSummaryMessageModal
                    defaultValue={defaultTemplateMessage}
                    afterSubmit={() => afterSubmit(defaultTemplateMessage.shop_order_id)}
                    modalVisible={sendSummaryMessageModalOpen}
                    hideModal={(withSent = false) => {
                        setSendSummaryMessageModalOpen(false)
                        afterSubmit(defaultTemplateMessage.shop_order_id, withSent)
                    }}
                />
            }
        </EmptyList>
    );
};

export default Table;
