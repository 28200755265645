import React, {useMemo} from 'react';
import Modal from "react-bootstrap/Modal";
import Loader from "../../../../messenger/loader/Loader";
import useLang from "../../../../../../src_shared/hooks/useLang";
import SwitchBoxes from "../../../../../../src_shared/components/form/switchBoxes/SwitchBoxes";
import useFormLocal from "../../../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../../../RestServices";
import PaxyModel from "../../../../../../modules/models/order/PaxyModel";
import ComponentLoader from "../../../../../../src_shared/components/ui/componentLoader/ComponentLoader";
import {SELECT_COURIER_FORM_VIEW} from "./ModalForm";

const SelectCountryForm = ({hide, setSelectedCountry, setActiveView}) => {
	const {getLangText} = useLang();

	const [{isWaiting, getFieldProps, isLoading, values}] = useFormLocal({
		rest: services.API_PAXY_COUNTRIES,
		model: PaxyModel,
	});

	const options = useMemo(() => values.countries.map((i) => ({
		id: i.shortcut,
		label: i.name,
		price: i.flag
	})), [values.countries])

	const onSave = () => {
		setSelectedCountry(values.country)
		setActiveView(SELECT_COURIER_FORM_VIEW);
	}

	if (isLoading) {
		return <ComponentLoader />
	}

	return (
		<>
			<Modal.Header>
				<Modal.Title>
					<img
						style={{height: '22px'}}
						src="/assets/images/paxy-logo.png"
						alt="Paxy"
					/>
					<div className="description">{getLangText('realizeOrdersFasterLabel')}</div>
				</Modal.Title>
				<button
					onClick={hide}
					type="button"
					className="button"
				>
					<i className="icon-cross"/>
				</button>
			</Modal.Header>
			<Modal.Body>
				<div className="form-label">
					{getLangText('selectCountryLabel')}
				</div>
				<SwitchBoxes
					{...getFieldProps('country')}
					options={options}
					className="separated-boxes small-padd"
					ErrorComponent={() => (
						<div className="form-info-box alert-style red w-100 mb-2">
							<div>
								<i className="icon-info-c"/>
							</div>
							<div className="title">
								{getLangText('fillRequiredFieldWarning')}
							</div>
						</div>
					)}
				/>
			</Modal.Body>
			<Modal.Footer>
				<button
					onClick={hide}
					type="button"
					className="button border-button"
				>
					{getLangText('cancelButton')}
				</button>
				<button
					type="button"
					className={`button primary small-size ${values.country ? '' : 'not-active'} ${isWaiting ? 'loading-button' : ''}`}
					disabled={!values.country}
					onClick={onSave}
				>
					{isWaiting && <Loader/>}
					{getLangText('goNextLabel')}
				</button>
			</Modal.Footer>
		</>
	);
};

export default SelectCountryForm;
