import Model from "../../src_shared/modules/model/Model";
import ValidationError from "../../src_shared/form/validation/ValidationError";

export class ClientPaymentReceivedModel extends Model {
	getModel() {
		return {
			id: 0,
			payment_received: '',
			new_payment_received: '',
			editPaymentReceived: false,
		};
	}

	getValidators() {
		return {
			payment_received: ValidationError.skip,
			new_payment_received: ValidationError.skip,
		};
	}

	buildDTO(data) {
		if (data.editPaymentReceived) {
			return {
				payment_received: data.payment_received,
			}
		}
		return {
			new_payment_received: data.new_payment_received,
		};
	}
}

export default new ClientPaymentReceivedModel();
