import React from 'react';
import useLang from "../../../../../src_shared/hooks/useLang";
import {useSelector} from "react-redux";
import {SHOP_ORIGIN_DA, SHOP_ORIGIN_PL, SHOP_ORIGIN_PT} from "../../../../app/shared/enums/ShopOrigins";
import ComponentLoader from "../../../../app/shared/components/componentLoader/ComponentLoader";
import LusopayBigBox from "./components/LusopayBigBox";
import BluemediaBigBox from "./components/BluemediaBigBox";
import StripeBigBox from "./components/StripeBigBox";
import TransferBox from "./components/TransferBox";
import StripeBox from "./components/StripeBox";
import ShopPickupBox from "./components/ShopPickupBox";
import PaypalBox from "./components/PaypalBox";
import PickupBox from "./components/PickupBox";
import MobilePayBigBox from "./components/MobilePayBigBox";
import AutomaticTransferBigBox from "./components/AutomaticTransferBigBox";
import PaypalAutomationBigBox from "./components/PaypalAutomationBigBox";

const PaymentDashboard = ({data, isLoading}) => {
    const {getLangText} = useLang();
    const {userData} = useSelector((state) => state.session);

    const getBigBox = () => {
        if (userData.origin === SHOP_ORIGIN_PT) {
            return <LusopayBigBox data={data}/>
        }
        if (userData.origin === SHOP_ORIGIN_DA) {
            return <MobilePayBigBox data={data}/>
        }
        return <StripeBigBox data={data}/>
    }

    if (isLoading) return <ComponentLoader/>;

    return (
        <div className="settings-wrapper rounded-[5px]">
            <section>
                <div className="section-title sm:mt-0 mt-8">
                    {userData.origin === SHOP_ORIGIN_PL ?
                        'Szybkie płatności' :
                        getLangText('settings_menu_payment')
                    }
                </div>
                <div
                    className="settings-box-wrapper border-boxes row payments-settings-box-wrapper">
                    {userData.origin === SHOP_ORIGIN_PL ?
                        <>
                            <BluemediaBigBox data={data}/>
                            <AutomaticTransferBigBox data={data}/>
                            <div className="section-title col-md-12 mt-0">
                                <div className="mt-12">
                                    {getLangText('otherPayments')}
                                </div>
                            </div>
                            <div className="flex flex-wrap col-md-12">
                                <div className="row">
                                    <TransferBox data={data}/>
                                    <PickupBox data={data}/>
                                    <ShopPickupBox data={data}/>
                                    <PaypalBox data={data}/>
                                </div>
                            </div>
                        </> :
                        <>
                            {getBigBox()}
                            <PaypalAutomationBigBox data={data}/>
                            <div className="section-title col-md-12 mt-0">
                                <div className="mt-12">
                                    {getLangText('otherPayments')}
                                </div>
                            </div>
                            <div className="flex flex-wrap col-md-12">
                                <div className="row">
                                    {(userData.origin === SHOP_ORIGIN_PT || userData.origin === SHOP_ORIGIN_DA) &&
                                        <StripeBox data={data}/>
                                    }
                                    <TransferBox data={data}/>
                                    <PickupBox data={data}/>
                                    <ShopPickupBox data={data}/>
                                    <PaypalBox data={data}/>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </section>
        </div>
    );
};

export default PaymentDashboard;
