import React from 'react';
import Modal from "react-bootstrap/Modal";
import useSubmitData from "../../../../../../src_shared/hooks/useSubmitData";
import {services} from "../../../../../RestServices";
import useLang from "../../../../../../src_shared/hooks/useLang";
import Loader from "../../../../../../src_shared/components/loader/Loader";

const SetIsPaidModal = ({modalVisible, hideModal, orderId, afterSubmit = () => {}}) => {

    const {getLangText} = useLang();

    const [{onSave, isWaiting}] = useSubmitData({
        rest: `${services.API_SET_IS_PAID}/${orderId}`,
        afterSubmit: async () => {
            // await fetchData()
            hideModal();
            afterSubmit();
        },
    });

    return (
        <Modal
            className="higher-index"
            dialogClassName={`default-modal w-420`}
            show={modalVisible}
            onHide={hideModal}
        >
            <Modal.Header className="align-items-start">
                <Modal.Title>
                    {getLangText('wantToSetOrderAsPaid')}
                    <div className="text-desc text-sm font-medium mb-4">
                        {getLangText('askWantToSetOrderAsPaid')}
                    </div>
                </Modal.Title>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            <Modal.Footer>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button border-button px-3"
                    style={{width: 'auto'}}
                >
                    {getLangText('noButton')}
                </button>
                <button
                    onClick={onSave}
                    type="button"
                    style={{width: 'auto'}}
                    className={`button primary px-3 ${isWaiting ? 'loading-button' : ''}`}
                >
                    {isWaiting && <Loader isLoading={true}/>}
                    {getLangText('yesSetUp')}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default SetIsPaidModal;
