import React from 'react';
import ConfirmModal from "../../../live/details/default/summary/export/ConfirmModal";
import useLang from "../../../../../src_shared/hooks/useLang";
import useSubmitData from "../../../../../src_shared/hooks/useSubmitData";
import {services} from "../../../../RestServices";
import {showAlert} from "../../../shared/components/alert/AlertActions";
import {useDispatch} from "react-redux";

const AutomationReserveListModal = ({modalVisible, hideModal, values}) => {
    const {getLangText} = useLang();
    const dispatch = useDispatch();

    const [{onSave, isWaiting}] = useSubmitData(
        {
            rest: services.API_STORAGE_AUTOMATIC_RESERVE_LIST,
            values,
            afterSubmit: () => {
                hideModal();
                dispatch(showAlert('yuhuLabel', 'successfullySentMessageToReserveListClients'))
            }
        });

    return (
        <ConfirmModal
            title={getLangText('informClientsAboutProductAvailabilityLabel')}
            saveButton={getLangText('yesButton')}
            show={modalVisible}
            isWaiting={isWaiting}
            onSubmit={onSave}
            hide={hideModal}
        />
    );
};

export default AutomationReserveListModal;