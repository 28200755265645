import React from 'react';
import {services} from "../../RestServices";
import useLang from "../../../src_shared/hooks/useLang";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../routes/RouterPath";
import ComponentLoader from "../../app/shared/components/componentLoader/ComponentLoader";
import ShippingForm from "./components/ShippingForm";
import useFormLocal from "../../../src_shared/hooks/useFormLocal";
import ShippingList from "./components/ShippingList";
import SettingsShippingModel from "../../../modules/models/settings/SettingsShippingModel";
import ShippingCountryPage from "./shippingCountry/ShippingCountryPage";
import {Helmet} from "react-helmet";

const ShippingPage = () => {
    const {getLangText} = useLang();

    const [{
        onSave,
        isWaiting,
        getFieldProps,
        isLoading,
        values,
        fetchData,
    }] = useFormLocal({
        rest: services.API_SETTINGS_SHIPPING,
        model: SettingsShippingModel,
    });

    if (isLoading) return <ComponentLoader/>;

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getLangText('settings_nav_shipping')} - Selmo</title>
            </Helmet>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <Link to={RouterPaths.Settings}>
                            {getLangText('asideSettingsLabel')}
                        </Link>
                    </li>
                    <li>
                        <Link to={RouterPaths.SettingsPaymentsShipments}>
                            {getLangText('settings_nav_payment_shipping')}
                        </Link>
                    </li>
                    <li className="active">
                        <div>{getLangText('settings_nav_shipping')}</div>
                    </li>
                </ul>
            </div>
            <div className="settings-page sub-settings-page">
                <div className="header-wrapper">
                    <h1>
                        <img src="/assets/images/settings/delivery.svg" alt="delivery"/>
                        {getLangText('settings_nav_shipping')}
                    </h1>
                    <div className="description">{getLangText('settings_shipping_description')}</div>
                </div>
                <div className="settings-form">
                    <section>
                        <div className="left-side">
                            <h2 className="label">{getLangText('settings_shipping_add_new')}
                                <span>{getLangText('settings_shipping_shipping_method')}</span>
                            </h2>
                        </div>
                        <div className="form-col loader-parent">
                            {!!values.countriesWithoutShipping?.length &&
                                <div className="form-info-box red w-100 mb-4 country-error">
                                    <div>
                                        <i className="icon-info-c"/>
                                    </div>
                                    <div>
                                        <div className="title">{getLangText('settings_shipping_country_alert_1')}</div>
                                        <div className="desc">
                                            <div className="country-row">
                                                {getLangText('settings_shipping_country_alert_2')}
                                                {values.countriesWithoutShipping.map((i) => (
                                                    <span>{i}</span>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            <ShippingForm
                                onSave={onSave}
                                isWaiting={isWaiting}
                                getFieldProps={getFieldProps}
                                values={values}
                            />
                        </div>
                    </section>
                    <section>
                        <div className="left-side">
                            <h2 className="label">{getLangText('settings_shipping_added_shipping_methods_1')}
                                <span>{getLangText('settings_shipping_added_shipping_methods_2')}</span>
                            </h2>
                        </div>
                        <ShippingList
                            shippingList={values.shippingList}
                        />
                    </section>
                    <ShippingCountryPage
                        fetchData={fetchData}
                        countries={values.countries}
                    />
                </div>
            </div>
        </>
    );
};

export default ShippingPage;
