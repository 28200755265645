import {langUrl} from "./i18n";

const prepareLang = (lang) => {
	if (lang === 'us') return 'en'
	return lang;
}

export const prepareRealLang = (lang) => {
	console.log(lang)
	if (lang === 'us' || lang === 'en') return 'en'
	return lang;
}

export function fetchTranslations(lang, path = langUrl) {
	return new Promise((resolve) => {
		fetch(path.replace("{lang}", prepareLang(lang)))
			.then((response) => response.json())
			.then((data) => resolve(data));
	});
}
