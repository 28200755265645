import createReducer from "../../../shared/helpers/createReducer";
import Composition from "../../../shared/helpers/Composition";
import FormReducer from "../../../../../src_shared/form/FormReducer";
import NoteModel from "../../../../../modules/models/order/NoteModel";

export const getStateRoot = (state) => state.orders.details.note;
export const prefix = 'ORDERS_DETAILS_NOTE_';

const getInitState = () => FormReducer.getInitState(NoteModel.getModel(), {
	tagsInputVisible: false,
});

const getReduceMap = () => new Composition(
	FormReducer.getReduceMap(),
	{
		SET_TAGS_INPUT_VISIBLE: (state, action) => ({...state, tagsInputVisible: action.toggle})
	}
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

