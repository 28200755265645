import {combineReducers} from "redux";
import newsReducer from "./news/newsReducer";
import categoriesReducer from "./categories/categoriesReducer";
import translationsReducer from "./translations/translationsReducer";
import settingsReducer from "./settings/settingsReducer";

export default combineReducers({
	news: newsReducer,
	categories: categoriesReducer,
	translations: translationsReducer,
	settings: settingsReducer,
});
