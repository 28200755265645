import createReducer from "../../../../../../shared/helpers/createReducer";
import Composition from "../../../../../../shared/helpers/Composition";
import FormReducer from "../../../../../../../../src_shared/form/FormReducer";
import GenerateLabelsInpostModel from "../../../../../../../../modules/models/orders/GenerateLabelsInpostModel";

export const getStateRoot = (state) => state.orders.selectedRow.generateModal.inpost;
export const prefix = 'ORDERS_LIST_GENERATE_LABELS_INPOST';

const getInitState = () => FormReducer.getInitState(GenerateLabelsInpostModel.getModel());

const getReduceMap = () => new Composition(
	FormReducer.getReduceMap(),
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

