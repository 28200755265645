import Model from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

class SettingsShopPickupModel extends Model {
	getModel() {
		return {
			shop_pickup_address: '',
			shop_pickup_price: 0,
			shop_pickup_active: '0',
		};
	}

	getValidators() {
		return {
			shop_pickup_address: ValidationError.notEmpty,
			shop_pickup_price: ValidationError.greaterThan(-1),
			shop_pickup_active: ValidationError.skip,
		};
	}

	buildDTO(data) {
		const {shop_pickup_address, shop_pickup_price, shop_pickup_active} = data;
		return {
			shop_pickup_address,
			shop_pickup_price,
			shop_pickup_active,
		}
	}
}

export default new SettingsShopPickupModel();
