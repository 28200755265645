import React from 'react';
import Group from "../../../../../../src_shared/components/form/group/Group";

const ZipCodeField = (props) => {

	const {
		name,
		setValue,
		value,
		disabled,
		extraProps,
		placeholder,
		isPoland,
		maxLength,
	} = props;

	const onKeyDown = (e) => {
		const key = e.keyCode || e.charCode;
		const ctrlKey = e.metaKey || e.ctrlKey;

		if (isPoland) {
			if (key === 189 && key !== 8) {
				e.preventDefault()
				return;
			}
			if (!ctrlKey &&
				key !== 8 &&
				(
					key !== 46 &&
					key > 31 &&
					(key < 48 || key > 57) &&
					(key < 96 || key > 105) &&
					key !== 189) &&
				key !== 37 &&
				key !== 39
			) {
				e.preventDefault();
				return;
			}
			if (key === 8 && (value.length <= 3 && value.length > 2)) {
				setValue(name, `${value.split('')[0]}${value.split('')[1]}`)
			}
		}
	}

	const onChange = (e) => {
		const inputValue = e.target.value;
		if (isPoland) {
			if (inputValue.length === 2) {
				setValue(name, `${inputValue}-`)
				return;
			}
			setValue(name, inputValue)
		} else {
			setValue(name, inputValue)
		}
	}

	return (
		<Group {...props}>
			<input
				type="text"
				className="form-control"
				value={value}
				disabled={disabled}
				placeholder={placeholder}
				onKeyDown={onKeyDown}
				onChange={onChange}
				maxLength={maxLength}
				{...extraProps}
			/>
		</Group>
	);
}

ZipCodeField.defaultProps = {
	setValue: () => {
	},
	value: '',
	required: false,
	name: '',
	disabled: false,
	isPoland: false,
	extraProps: {},
}

export default ZipCodeField;
