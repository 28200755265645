import React from 'react';
import {Helmet} from "react-helmet";
import ListActions from "./ListActions";
import {getStateRoot} from "./listReducer";
import useList from "../../../../../src_shared/list/useList";
import Pagination from "../../../shared/components/pagination/Pagination";
import Table from "./components/Table";
import DateRangePicker from "../../../shared/components/form/DateRangePicker";
import {getStateRoot as getFiltersStateRoot} from "./filters/filtersReducer";
import FiltersActions from "./filters/FiltersActions";
import useLang from "../../../../../src_shared/hooks/useLang";
import {useParams} from "react-router-dom";

const ActivityHistoryList = () => {
    const {getLangText} = useLang();
    const params = useParams();

    const {
        pagination: {
            changePage,
            getPagination,
            itemsPerPage,
            nextPage,
            prevPage,
            totalItems,
            updateItemsPerPage
        },
        list: {items, isWaiting, loadData},
        sort: {updateSortOptions, getSortClass},
    } = useList({
        ListActions,
        getStateRoot,
        hasParentId: true,
        params,
    });

    return (
        <div className="table-list-wrapper">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getLangText('activityHistory')} - Selmo</title>
            </Helmet>
            <div className={`default-shadow-box pb-0`}>
                <div className="table-header">
                    <div className="top-part flex items-center">
                       <div className="font-bold text-lg">{getLangText('activityHistory')}</div>
                        <DateRangePicker
                            className="ml-auto"
                            actions={ListActions}
                            getFiltersStateRoot={getFiltersStateRoot}
                            FiltersActions={FiltersActions}
                            extraProps={{
                                id: params.id,
                            }}
                        />
                    </div>
                </div>
                <Table
                    getSortClass={getSortClass}
                    updateSortOptions={updateSortOptions}
                    items={items}
                    isWaiting={isWaiting}
                    loadData={loadData}
                />
            </div>
            {totalItems > itemsPerPage &&
                <Pagination
                    getLangText={getLangText}
                    pages={getPagination()}
                    items={items}
                    itemsPerPage={itemsPerPage}
                    totalItems={totalItems}
                    onPrevPage={prevPage}
                    onChangePage={changePage}
                    onNextPage={nextPage}
                    onChangeItemsPerPage={updateItemsPerPage}
                    classes={{
                        prevIcon: 'icon-chevron left',
                        nextIcon: 'icon-chevron right',
                    }}
                    showItemsPerPageSelect
                />
            }
        </div>
    );
};

export default ActivityHistoryList;
