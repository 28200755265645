import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {useRemoveModal} from "../../../shared/hooks/useRemoveModal";
import {services} from "../../../../RestServices";
import EditOrderFormActions from "../form/FormActions";
import ConfirmModal from "../../list/components/export/ConfirmModal";
import moment from "moment";
import StylesLoader from "../../../shared/Loader";
import useLang from "../../../../../src_shared/hooks/useLang";
import {useDownloadFile} from "../../../../../src_shared/hooks/useDownloadFile";
import {usePrintFile} from "../../../../../src_shared/hooks/usePrintFile";
import TemplateModal from "./TemplateModal";
import {convertToUserTimeZone} from "../../../shared/helpers/dateHelpers";

const ShipmondoLabel = ({data}) => {
    const dispatch = useDispatch();
    const {getLangText} = useLang();

    const loadData = () => dispatch(EditOrderFormActions.loadData(data.shop_order_id))
    const [showTemplateModal, setShowTemplateModal] = useState(false);

    const [modalOpen, setModalOpen, waiting, remove] = useRemoveModal(`${services.API_SHIPMENT_SHIPMONDO_LABEL}/${data.id}`, loadData)

    const {download, waiting: waitingForLabel} = useDownloadFile({
        rest: `${services.API_SHIPMENT_SHIPMONDO_LABEL}/${data.id}`,
        fileName: 'Shipmondo',
    })

    const {print, waiting: waitingForPrint} = usePrintFile({
        rest: `${services.API_SHIPMENT_SHIPMONDO_LABEL}/${data.id}`,
    })

    if (!+data.shippingData.shop_shipmondo_active) {
        return null
    }

    return (
        <>
            {!data.shippingData.shipmondo_label_id ?
                <section className="summary-section mb-3">
                    <div className="section-header mb-3">
                        <div className="flex-grow-1">
                            <div className="flex items-center">
                                <h2>Shipmondo</h2>
                                <div className="ml-auto">
                                    <a
                                        href="https://shipmondo.com/"
                                        target="_blank"
                                        rel="nofollow"
                                    >
                                        <img style={{height: 25}} height={25} src="/assets/images/shipmondo.png" alt="Shipmondo"/>
                                    </a>
                                </div>
                            </div>
                            <div className="section-header__description">
                                {getLangText({key: 'addLabelByLabel', data: ['Shipmondo']})}
                            </div>
                        </div>
                    </div>
                    {!data.shipping_address_exist ?
                        <div
                            className="form-info-box align-items-center w-100 small-line-height">
                            <div>
                                <i className="icon-info-c"/>
                            </div>
                            <div>
                                <div className="title">{getLangText('noDeliveryAddressLabel')}</div>
                            </div>
                        </div> :
                        <button
                            onClick={() => setShowTemplateModal(true)}
                            type="button"
                            className={`button primary w-full`}
                        >
                            {getLangText('selectTemplateLabel')}
                        </button>
                    }
                </section> :
                <section className="summary-section mb-3">
                    <div className="section-header mb-3">
                        <div className="flex-grow-1">
                            <div className="flex items-center">
                                <h2>Shipmondo</h2>
                                <div className="ml-auto">
                                    <a
                                        href="https://shipmondo.com/"
                                        target="_blank"
                                        rel="nofollow"
                                    >
                                        <img style={{height: 25}} height={25} src="/assets/images/shipmondo.png" alt="Shipmondo"/>
                                    </a>
                                </div>
                            </div>
                            <div className="section-header__description">
                                {getLangText('manageLabelLabel')}{' '}Shipmondo
                            </div>
                        </div>
                    </div>
                    <div className="loader-parent">
                        <StylesLoader
                            className="small-loader"
                            isLoading={waitingForLabel || waitingForPrint}
                        />
                        <div className="tripple-buttons">
                            <div>
                                <button
                                    onClick={print}
                                    type="button"
                                    className="button border-button big-width position-relative"
                                >
                                    {getLangText('printButton')}
                                    <i className="icon-print"/>
                                </button>
                            </div>
                            <div>
                                <button
                                    onClick={download}
                                    type="button"
                                    className="button border-button big-width position-relative"
                                >
                                    {getLangText('previewButton')}
                                    <i className="icon-eye"/>
                                </button>
                            </div>
                            <div>
                                <button
                                    onClick={() => setModalOpen(true)}
                                    type="button"
                                    className="button border-button big-width"
                                >
                                    {getLangText('removeButton')}
                                    <i className="icon-bin"/>
                                </button>
                            </div>
                        </div>
                    </div>
                    {data.shippingData.shipmondo_label_date &&
                        <div className="info-label mt-3">
                            <i className="icon-tick-c"/>
                            {getLangText('labelHasBennGeneratedLabel')}
                            <div className="date-box">
                                <span>{convertToUserTimeZone(data.shippingData.shipmondo_label_date).format('DD.MM.YYYY')}</span>
                                <span>{convertToUserTimeZone(data.shippingData.shipmondo_label_date).format('HH:mm:ss')}</span>
                            </div>
                        </div>
                    }
                </section>
            }
            {modalOpen &&
                <ConfirmModal
                    title={getLangText('askForRemovingShipmondoLabel')}
                    SubTitle={() => <>{getLangText('actionCannotBeUnDoneLabel')}</>}
                    saveButton={getLangText('removeButton')}
                    show={modalOpen}
                    isWaiting={waiting}
                    onSubmit={remove}
                    hide={() => setModalOpen(false)}
                    submitButtonClass="danger"
                />
            }
            {showTemplateModal &&
                <TemplateModal
                    shopOrderId={data.shop_order_id}
                    orderId={data.id}
                    loadData={loadData}
                    modalVisible={showTemplateModal}
                    hideModal={() => setShowTemplateModal(false)}
                />
            }
        </>
    );
};

export default ShipmondoLabel;



