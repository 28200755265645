import React from 'react';
import ImageUpload from "../../../../../src_shared/components/form/imageUpload/ImageUpload";
import FormActions from "../edit/FormActions";
import PermissionsWrapper from "../../../../routes/PermissionsWrapper";
import {SHOP_PERMISSION} from "../../../shared/enums/SelmoPermissions";
import ShopBox from "./ShopBox";
import ProductLink from "./ProductLink";
import useLang from "../../../../../src_shared/hooks/useLang";
import {useSelector} from "react-redux";
import {checkRoles} from "../../../../routes/PermissionRoute";

const RightSide = ({values, isWaiting, updateValues, className = '', inModal}) => {
    const {getLangText} = useLang();
    const {permissions} = useSelector((state) => state.session.userData);
    const permissionsMatched = checkRoles(permissions, [SHOP_PERMISSION]);

    return (
        <div className={className}>
            <section className="default-shadow-box big-padd mb-3">
                <div className="left-side">
                    <h2 className="font-bold mb-3.5">{getLangText('photoLabel')}</h2>
                </div>
                <div className="right-side">
                    <ImageUpload
                        values={values}
                        updateValues={updateValues}
                        FormActions={FormActions}
                        proportions="proportionLabel"
                        field="photo_small"
                        removePhotoField="photo"
                        withPreview
                    />
                </div>
            </section>
            {((permissionsMatched && !!+values.is_shop_active) || !!+values.digital) &&
                <div className="grid md:grid-cols-2 default-shadow-box big-padd mb-3 gap-4">
                    <PermissionsWrapper
                        allowedPermissions={[SHOP_PERMISSION]}
                    >
                        <ShopBox values={values} waiting={isWaiting}/>
                    </PermissionsWrapper>
                    {!!+values.digital &&
                        <ProductLink values={values}/>
                    }
                </div>
            }
        </div>
    );
};

export default RightSide;
