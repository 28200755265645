import React from 'react';
import useLang from "../../../../../../src_shared/hooks/useLang";
import {useDispatch, useSelector} from "react-redux";
import {BUTIK_MADAME_SHOP_ID, TEST_ACCOUNT_SHOP_ID} from "../../../../../../src_shared/enums/TrustedShopsIds";
import CustomDropdown from "../../../../shared/components/dropdown/CustomDropdown";
import FormActions from "../FormActions";
import {useHistory} from "react-router-dom";

const SubmitButton = ({handleSave, values}) => {
    const {getLangText} = useLang();
    const {userData} = useSelector((state) => state.session);
    const dispatch = useDispatch();
    const history = useHistory();
    const onSave = () => dispatch(FormActions.submitEditForm(values, history, true));

    return (
        <div className={`flex button-with-arrow primary sm:w-auto w-full col-span-2 ${!!+values.ecommerce_type ? 'not-active' : ''}`}>
            <div
                className="d-flex align-items-center grow">
                <div className="tooltip-parent">
                    {!!+values.ecommerce_type &&
                        <div className="tooltip-content">
                            {getLangText('productSourceFromIntegrations')}
                        </div>
                    }
                    <button
                        className={`button primary sm:w-auto w-full ${!!+values.ecommerce_type ? 'not-active' : ''}`}
                        type="button"
                        disabled={!!+values.ecommerce_type}
                        onClick={handleSave}
                    >
                        {getLangText('saveButton')}
                    </button>
                </div>
            </div>
            {(!!+userData.depot_system && !+values.ecommerce_type) &&
                <CustomDropdown
                    icon="icon-arrows"
                    onClick={(e) => e.stopPropagation()}
                >
                    <button
                        onClick={onSave}
                        type="button"
                        className="custom-option"
                    >
                        {getLangText('saveAndGenerateQrCode')}
                    </button>
                </CustomDropdown>
            }
        </div>
    );
};

export default SubmitButton;
