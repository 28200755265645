import React, {useEffect} from 'react';
import CreateShopLayout from "../shared/CreateShopLayout";
import {services} from "../../../RestServices";
import SocialButton from "./components/socialButton/SocialButton";
import useFetchData from "../../../../src_shared/hooks/useFetchData";
import {RouterPaths} from "../../../routes/RouterPath";
import Cookies from "js-cookie";
import ComponentLoader from "../../../../src_shared/components/ui/componentLoader/ComponentLoader";
import useLang from "../../../../src_shared/hooks/useLang";
import {Helmet} from "react-helmet";
import {usePostHog} from "posthog-js/react";

const socialOptions = [
    {
        id: 'fb',
        img: '/assets/images/svg/facebook-logo-c.svg',
        title: 'Facebook',
        desc: 'sellOnLivesLabel',
        redirect: RouterPaths.TutorialFacebook
    },
    {
        id: 'ig',
        img: '/assets/images/instagram.png',
        title: 'Instagram',
        desc: 'sellInDmStoriesReelsLabel',
        redirect: RouterPaths.TutorialInstagram
    },
]

const whyWorthItOptions = [
    {
        id: 0,
        title: 'whyWorthItTutorialLabel1',
        desc: 'whyWorthItTutorialSubLabel1',
    },
    {
        id: 1,
        title: 'whyWorthItTutorialLabel2',
        desc: 'whyWorthItTutorialSubLabel2',
    },
    {
        id: 2,
        title: 'whyWorthItTutorialLabel3',
        desc: 'whyWorthItTutorialSubLabel3',
    },
    {
        id: 3,
        title: 'whyWorthItTutorialLabel4',
        desc: 'whyWorthItTutorialSubLabel4',
    },
]

const FirstVariant = ({data}) => {
    const posthog = usePostHog();

    useEffect(() => {
        if (posthog) {
            posthog?.capture(`ONBOARDING - Connect your account page visit`, {variant: 1});
        }
    }, [posthog])
    return (
        <CreateShopLayout
            withAnimation={false}
            title="connectYourPageLabel"
            Img={<img width={60} className="mx-auto mb-2" src="/assets/images/svg/integration-icon.svg"
                      alt="home icon"/>}
        >
            <div
                className="animate-tutorialSlideAndFadeInDelay2 opacity-0 w-full grow flex flex-col">
                <div className="max-w-[450px] mx-auto w-full sm:mb-0 mb-12">
                    <div className="space-y-3">
                        {socialOptions.map((item) => (
                            <SocialButton
                                key={item.id}
                                data={data}
                                item={item}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </CreateShopLayout>
    )
}
const SecondVariant = ({data, getLangText}) => {
    const posthog = usePostHog();

    useEffect(() => {
        if (posthog) {
            posthog?.capture(`ONBOARDING - Connect your account page visit`, {variant: 2});
        }
    }, [posthog])

    return (
        <CreateShopLayout
            withAnimation={false}
            title="connectYourPageLabel"
            Img={<img width={60} className="mx-auto mb-2" src="/assets/images/svg/integration-icon.svg"
                      alt="home icon"/>}
        >
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getLangText('connectYourAccountLabel')} - Selmo</title>
            </Helmet>
            <div
                className="animate-tutorialSlideAndFadeInDelay2 opacity-0 w-full grow flex flex-col">
                <div className="max-w-[665px] mx-auto w-full sm:mb-0 mb-12">
                    <div className="grid sm:grid-cols-2 gap-4">
                        {socialOptions.map((item) => (
                            <SocialButton
                                key={item.id}
                                data={data}
                                item={item}
                                variant={2}
                            />
                        ))}
                    </div>
                    <div
                        className="sm:mt-5 mt-3.5 flex items-center border border-[rgba(95,165,250,0.2)] rounded-[10px] bg-[#F6FCFF] px-3.5 sm:px-6 sm:py-5 py-3.5">
                        <div className="sm:mr-5 mr-3.5">
                            <i className="icon-safe text-[25px] text-[#5FA5FA]"/>
                        </div>
                        <div>
                            <div className="text-[#101827] font-bold text-sm">
                                {getLangText('whyYouShouldTrustUsLabel')}
                            </div>
                            <div className="text-[#4B5563] text-sm font-medium">
                                {getLangText('whyYouShouldTrustUsSubLabel')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </CreateShopLayout>
    )
}

const ThirdVariant = ({data, getLangText}) => {
    const posthog = usePostHog();

    useEffect(() => {
        if (posthog) {
            posthog?.capture(`ONBOARDING - Connect your account page visit`, {variant: 3});
        }
    }, [posthog])

    return (
        <CreateShopLayout
            withAnimation={false}
            title="connectYourPageLabel"
            Img={<img width={60} className="mx-auto mb-2" src="/assets/images/svg/integration-icon.svg"
                      alt="home icon"/>}
            headerClassName="mt-10 sm:mb-10 mb-8"
        >
            <div
                className="animate-tutorialSlideAndFadeInDelay2 opacity-0 w-full grow flex flex-col">
                <div className="max-w-[665px] mx-auto w-full mb-10">
                    <div className="grid sm:grid-cols-2 gap-4">
                        {socialOptions.map((item) => (
                            <SocialButton
                                key={item.id}
                                data={data}
                                item={item}
                                variant={2}
                            />
                        ))}
                    </div>
                </div>
                <div
                    className="bg-[#FCFCFD] border-t border-[#F3F4F6] rounded-b-[12px] mt-auto pt-6 sm:mx-0 sm:px-0 px-6 -mx-6">
                    <div className="max-w-[665px] mx-auto w-full mb-8">
                        <div className="font-bold text-sm text-[#6B7280]">{getLangText('pricingWhyWorthyTitle')}</div>
                        <div className="grid sm:grid-cols-2 sm:gap-8 gap-5 mt-6">
                            {whyWorthItOptions.map((option) => (
                                <div
                                    className="flex"
                                    key={option.id}
                                >
                                    <div className="mr-3">
                                        <img width={20} src="/assets/images/svg/check-icon.svg"/>
                                    </div>
                                    <div>
                                        <div className="text-[#101827] font-bold text-sm">
                                            {getLangText(option.title)}
                                        </div>
                                        <div className="text-[#4B5563] text-sm font-medium">
                                            {getLangText(option.desc)}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div
                            className="mt-7 flex sm:items-center border border-[#DFE1E4] rounded-[8px] bg-[#fff] p-3.5">
                            <div className="mr-2.5">
                                <div className="w-[20px] sm:mt-0 mt-1">
                                    <img className="w-full" width={20} src="/assets/images/svg/proof.svg" alt="proof"/>
                                </div>
                            </div>
                            <div>
                                <div className="text-[#101827] text-sm"
                                     dangerouslySetInnerHTML={{__html: getLangText('whyYouShouldTrustUsStrongSubLabel')}}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </CreateShopLayout>
    )
}

const SelectedVariant = ({data, isLoading, getLangText}) => {
    const tutorialVariant = Cookies.get('tutorialSocialsVariant');

    if (isLoading) {
        return (
            <CreateShopLayout>
                <ComponentLoader className="small-loader higher-border-radius"/>
            </CreateShopLayout>
        )
    }

    if (+tutorialVariant === 2) {
        return <SecondVariant getLangText={getLangText} data={data}/>
    }
    if (+tutorialVariant === 3) {
        return <ThirdVariant getLangText={getLangText} data={data}/>
    }
    return <FirstVariant getLangText={getLangText} data={data}/>

}


const ConnectYourPage = () => {
    const {getLangText} = useLang();

    const [{data, isLoading}] = useFetchData({
        rest: services.API_TUTORIAL_SOCIALS,
        initialData: {
            facebookUrl: '',
            instagramUrl: '',
            facebookConnected: false,
            instagramConnected: false,
        },
    })

    return <SelectedVariant getLangText={getLangText} data={data} isLoading={isLoading}/>
};

export default ConnectYourPage;
