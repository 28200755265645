import React, {useContext, useEffect} from "react";
import useForm from "../../../../../src_shared/form/useForm";
import FormActions from "./FormActions";
import Loader from "../../../shared/Loader";
import Field from "../../../../../src_shared/components/form/field/Field";
import SwitchField from "../../../../../src_shared/components/form/switchField/SwitchField";
import useLang from "../../../../../src_shared/hooks/useLang";
import {useParams} from "react-router-dom";
import {CategoryContext} from "../Page";
import AsyncSelectField from "../../../../../src_shared/components/form/selectField/AsyncSelectField";
import {services} from "../../../../RestServices";
import axios from "axios";
import {selmoUrl} from "../../../../../src_shared/api/api";
import ImageUpload from "../../../../../src_shared/components/form/imageUpload/ImageUpload";
// import "react-upload-gallery/dist/style.css";
// TODO
import {useDispatch} from "react-redux";
import ConfirmModal from "../../../live/details/default/summary/export/ConfirmModal";
import {SHOP_PERMISSION} from "../../../shared/enums/SelmoPermissions";
import PermissionsWrapper from "../../../../routes/PermissionsWrapper";
import api from "../../../../../services/axios/axios";
const CategoryForm = () => {
	const {getLangText} = useLang();
	const {id} = useParams();
	const dispatch = useDispatch();
	const {loadCategoryInfo} = useContext(CategoryContext);

	const {
		data: {values, setValue, updateValues},
		form: {isWaiting, getFieldProps, deleteWithoutAsk},
		modal: {showModal, modalVisible, hideModal}
	} = useForm({
		FormActions,
		params: {
			id,
		}
	});
	const onSave = () => dispatch(FormActions.submitEditForm(values, loadCategoryInfo))

	const getCategoryOptions = async () => {
		try {
			const {data} = await api.get(`${selmoUrl}/${services.API_CATEGORIES}`)
			const name = data.items.find((i) => +i.id === +values.parent_id)?.name
			setValue('parent_name', name);

		} catch (e) {
			console.warn('Cannot get options')
		}
	}

	useEffect(() => {
		if (values.parent_id) {
			setValue('parent_id', values.parent_id)
			getCategoryOptions();
		}
	}, [])

	return (
		<div className="table-list-wrapper">
			<div className="default-shadow-box pb-4">
				<div className="settings-form p-0">
					<section>
						<div className="left-side">
							<h2 className="label">{getLangText('settings_nav_general')}</h2>
						</div>
						<div className="form-col">
							<div className="row">
								<div className="col-md-6">
									<Field
										label={getLangText('categoryNameLabel')}
										{...getFieldProps('name')}
										setValue={setValue}
									/>
									<AsyncSelectField
										label={getLangText('superiorCategoryIfContainLabel')}
										{...getFieldProps('parent_id')}
										className="default-size"
										setValue={setValue}
										rest={`${services.API_CATEGORIES}?list=${id}`}
										defaultValue={{id: values.parent_id, name: values.parent_name}}
									/>
								</div>
							</div>
						</div>
					</section>
					<section>
						<div className="left-side">
							<h2 className="label">{getLangText('categoryPhotoLabel')}</h2>
						</div>
						<div className="form-col">
							<ImageUpload
								values={values}
								updateValues={updateValues}
								FormActions={FormActions}
								imagePath="categories"
								bigPhoto
								sizes="1340x320 px"
								defaultImage="shop-logo.svg"
							/>
						</div>
					</section>
					<PermissionsWrapper
						allowedPermissions={[SHOP_PERMISSION]}
					>
						<section>
							<div className="left-side">
								<h2 className="label">{getLangText('visibilityLabel')}</h2>
							</div>
							<div className="form-col">
								<div className="mb-3 mt-3">
									<SwitchField
										{...getFieldProps('shop_active')}
										setValue={setValue}
										label={getLangText('activeInShopLabel')}
										subLabel={getLangText('activeInShopSubLabel')}
										id="shop_active"
										className="switch-form inline-label big-label light-version mb-0 mt-0"
									/>
								</div>
								{!!+values.shop_active &&
									<>
										<div className="mb-3 mt-3">
											<SwitchField
												{...getFieldProps('shop_menu_active')}
												setValue={setValue}
												label={getLangText('visibleInMenuLabel')}
												subLabel={getLangText('visibleInMenuSubLabel')}
												id="menu-active"
												className="switch-form inline-label big-label light-version mb-0 mt-0"
											/>
										</div>
										<div className="mb-3 mt-3">
											<SwitchField
												{...getFieldProps('shop_homepage_active')}
												setValue={setValue}
												label={getLangText('visibleOnMainPageLabel')}
												subLabel={getLangText('visibleOnMainPageSubLabel')}
												id="main-page-active"
												className="switch-form inline-label big-label light-version mb-0 mt-0"
											/>
										</div>
										<div className="mb-3 mt-3">
											<SwitchField
												{...getFieldProps('is_from_newest')}
												setValue={setValue}
												label={getLangText('productsDisplayFromTheNewestLabel')}
												subLabel={getLangText('productsDisplayFromTheNewestSubLabel')}
												id="is_from_newest"
												className="switch-form inline-label big-label light-version mb-0 mt-0"
											/>
										</div>
									</>
								}
							</div>
						</section>
					</PermissionsWrapper>
					<div className="d-flex pb-3">
						<button
							onClick={onSave}
							type="button"
							className={`ml-auto button primary`}
						>
							{getLangText('saveButton')}
						</button>
					</div>
					<hr/>
					<button
						type="button"
						className="button border-button big-size left-icon tooltip-parent"
						onClick={showModal}
					>
						<i className="icon-bin"/>
						{getLangText('removeCategoryLabel')}
					</button>
				</div>
			</div>
			<ConfirmModal
				title={getLangText('removeCategoryLabel')}
				SubTitle={() => <span dangerouslySetInnerHTML={{
					__html: getLangText({
						key: 'askForRemoveCategoryWithNameLabel',
						data: [values.name]
					})
				}}/>}
				saveButton={getLangText('removeButton')}
				show={modalVisible}
				isWaiting={false}
				onSubmit={deleteWithoutAsk}
				hide={hideModal}
				saveButtonClass="danger"
			/>
		</div>
	)
};

export default CategoryForm;



