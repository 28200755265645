import React, {useEffect, useState} from 'react';
import useLang from "../../../../../src_shared/hooks/useLang";
import {variants} from "../../../../../src_shared/helpers/helper";
import {animated, useSpring} from '@react-spring/web'
import CommentsListWithVirtualization from "../../../live/details/default/comments/NewList";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {getStateRoot} from "../../../live/details/default/comments/listReducer";
import LiveCommentsListActions from "../../../live/details/default/comments/ListActions";
import {SHOP_ORIGIN_US} from "../../../shared/enums/ShopOrigins";

const TopSummary = ({values, currency}) => {
    const {getLangText} = useLang();
    const dispatch = useDispatch();
    const {commentsPanelVisible} = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));
    const {userData} = useSelector((state) => state.session);
    const showCommentPanel = () => dispatch(LiveCommentsListActions.showCommentsPanel(true));

    const getPiecesLang = (count) => {
        const variantsLang = {
            singular: getLangText('pieceLabel'),
            plural: getLangText('piecesLabel'),
            genitive: getLangText('piecesGenitiveLabel')
        }

        return variants(+count, variantsLang)
    }

    const [cartTotal, setCartTotal] = useState(+values?.live_summary?.add_to_cart_total || 0);
    const [reserveListTotal, setReserveListTotal] = useState(+values?.live_summary?.reserve_list_total || 0);

    useEffect(() => {
        setCartTotal(+values?.live_summary?.add_to_cart_total || 0);
    }, [values?.live_summary?.add_to_cart_total]);

    useEffect(() => {
        setReserveListTotal(+values?.live_summary?.reserve_list_total || 0);
    }, [values?.live_summary?.reserve_list_total]);

    const totalSpring = useSpring({
        number: cartTotal,
        from: {number: 0},
        config: {duration: 1000}
    });

    const totalReserveListSpring = useSpring({
        number: reserveListTotal,
        from: {number: 0},
        config: {duration: 1000}
    });

    return (
        <div className="shadow-default rounded-[5px] bg-[white] p-6">
            <div className="flex items-center">
                <div className="font-bold text-lg sm:text-2xl">
                    {getLangText('liveSummary')}
                </div>
                <button
                    type="button"
                    onClick={showCommentPanel}
                    className="ml-auto text-sm text-desc font-bold hover:text-[black] transition-all"
                >
                    <i
                        className="icon-messenger mr-1"
                    />
                    {getLangText('commentsLabel')}
                </button>
            </div>
            <div className="grid sm:grid-cols-2 gap-5 mt-3">
                <div className="border rounded-[5px] flex items-center px-[12px] py-3 w-full">
                    <div className="">
                        <div className="text-xs text-[#067857] font-bold flex items-center">
                            <img
                                className="w-[14px] min-w-[14px] mr-1"
                                src="/assets/images/svg/circle-check.svg"
                                alt="check"
                            />
                            {getLangText('addedToBasket')}
                        </div>
                        {userData.origin === SHOP_ORIGIN_US ?
                            <animated.div className="font-bold text-2xl mt-2">
                                {totalSpring.number.to(val => `${currency}${Math.floor(val)}`)}
                            </animated.div> :
                            <animated.div className="font-bold text-2xl mt-2">
                                {totalSpring.number.to(val => `${Math.floor(val)} ${currency}`)}
                            </animated.div>
                        }
                    </div>
                    <div className="self-end text-xs ml-auto text-[#6B7280]">
                        <strong>{values?.live_summary?.add_to_cart_qty || 0}</strong>&nbsp;{getPiecesLang(values?.live_summary?.add_to_cart_qty || 0)}
                    </div>
                </div>
                <div className="border rounded-[5px] flex items-center p-2 py-3 w-full">
                    <div className="">
                        <div className="text-xs text-[#854E0F] font-bold flex items-center">
                            <img
                                className="w-[16px] min-w-[16px] mr-1"
                                src="/assets/images/svg/time.svg"
                                alt="check"
                            />
                            {getLangText('reserveListLabel')}
                        </div>
                        {userData.origin === SHOP_ORIGIN_US ?
                            <animated.div className="font-bold text-2xl mt-2">
                                {totalReserveListSpring.number.to(val => `${currency}${Math.floor(val)}`)}
                            </animated.div> :
                            <animated.div className="font-bold text-2xl mt-2">
                                {totalReserveListSpring.number.to(val => `${Math.floor(val)} ${currency}`)}
                            </animated.div>
                        }
                    </div>
                    <div className="self-end text-xs ml-auto text-[#6B7280]">
                        <strong>{values?.live_summary?.reserve_list_qty || 0}</strong>&nbsp;{getPiecesLang(values?.live_summary?.reserve_list_qty || 0)}
                    </div>
                </div>
            </div>
            {commentsPanelVisible && <CommentsListWithVirtualization canAddClient={false}/>}
        </div>
    );
};

export default TopSummary;
