import React, {useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {useDispatch, useSelector} from "react-redux";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import {setConfirmModalToggleVisibility} from "../../LiveDetailsActions.js";
import Loader from "../../../../../messenger/loader/Loader.jsx";

const PrintAllOrdersModal = ({onSave, modalType, title, desc, saveButton, saveButtonClass = 'primary'}) => {
	const dispatch = useDispatch();
	const {clientsConfirmModal, liveInfo} = useSelector((state) => state.lives.details)
	const [iframeActionType, setIframeActionType] = useState('clients_list');
	const [isLoading, setIsLoading] = useState(false);
	const {getLangText} = useLang();

	const printIframe = (id) => {
		const iframe = document.getElementById(id);
		const iframeWindow = iframe.contentWindow || iframe;

		iframe.focus();
		iframeWindow.print();

		setIsLoading(true);

		setTimeout(function(){
			setIsLoading(false);
		}, 2500);

		return false;
	};

	const handleInputChange = (type) => {
		setIframeActionType(type);
	}

	let iframeAction = `/transmisje/${liveInfo.id}/drukuj/`;

	if (iframeActionType === 'client_per_page') {
		iframeAction = `/transmisje/${liveInfo.id}/drukuj?client_per_page=1`;
	}

	return (
		<Modal
			dialogClassName="default-modal small-modal"
			show={clientsConfirmModal.modalType === modalType}
			onHide={() => dispatch(setConfirmModalToggleVisibility(null))}
		>
			<Modal.Header className="with-border">
				<Modal.Title>
					{title}
					<div className="description">{desc}</div>
				</Modal.Title>
				<button
					onClick={() => dispatch(setConfirmModalToggleVisibility(null))}
					type="button"
					className="button"
				>
					<i className="icon-cross"/>
				</button>
			</Modal.Header>
			<Modal.Body>
				<iframe
					id="orderPrint"
					src={iframeAction}
					style={{display: 'none'}}
				/>
				<div className="form-label mt-2">
					{getLangText('displayMethodLabel')}
				</div>
				<div className="data-boxes-wrapper">
					<div className="content content-shipping">
						<div className="content__top-part">
							<input type="radio" name="clients" value="1" defaultChecked={true}
								   onChange={(e) => handleInputChange('clients_list')}/>
							<span className="checkmark"/>
							<div className="flex-grow-1">
								<div className="label">{getLangText('clientsUnderneathLabel')}</div>
							</div>
						</div>
					</div>
					<div className="content content-shipping">
						<div className="content__top-part">
							<input type="radio" name="clients"
								   onChange={(e) => handleInputChange('client_per_page')}/>
							<span className="checkmark"/>
							<div className="flex-grow-1">
								<div className="label">{getLangText('clientsPerPageLabel')}</div>
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<button
					onClick={() => dispatch(setConfirmModalToggleVisibility(null))}
					type="button"
					className="button border-button"
				>
					{getLangText('cancelButton')}
				</button>
				<button
					onClick={() => printIframe('orderPrint')}
					type="button"
					className={`button small-size ${saveButtonClass} ${isLoading ? 'loading-button' : ''}`}
				>
					{(isLoading) &&
						<Loader/>
					}
					{getLangText('printButton')}
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default PrintAllOrdersModal;
