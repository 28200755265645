import createReducer from "../../../shared/helpers/createReducer.js";
import Composition from "../../../shared/helpers/Composition.js";
import FormReducer from "../../../../../src_shared/form/FormReducer.js";
import ModalPageModel from "../../../../../modules/models/shopSettings/ModalPageModel";

export const getStateRoot = (state) => state.shopSettings.pages.modalForm;
export const prefix = 'SHOP_SETTINGS_PAGES_MODAL_FORM_';

const getInitState = () => FormReducer.getInitState(ModalPageModel.getModel());

const getReduceMap = () => new Composition(
	FormReducer.getReduceMap(),
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

