import {useEffect, useState} from "react";
import {selmoUrl} from "../api/api";
import {useDispatch} from "react-redux";
import {showAlert} from "../../components/app/shared/components/alert/AlertActions";
import useDataApi from "./useDataApi";
import {useHistory} from "react-router-dom";
import api from "../../services/axios/axios";

const useFormLocal = (config) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {
        rest = '',
        model = {},
        method = 'post',
        redirectRoute = '',
        afterSubmit = (item) => {
            dispatch(showAlert())
            if (!!item.id) {
                history.push(`${redirectRoute}/${item.id}`)
            } else {
                updateValues(model.getModel())
                fetchData()
            }
        },
        onError = (e) => {
            setError(e)
            dispatch(showAlert(e.response?.data?.message || 'sthWentWrongWarning', '', 'alert-fail'))
        },
        validateErrorsConfig = false,
        initialAutoLoad = true,
    } = config;

    const [{isLoading, data, fetchData, errorMessage}] = useDataApi(rest, model.getModel(), [], initialAutoLoad);

    const [response, setResponse] = useState({});
    const [isWaiting, setIsWaiting] = useState(false);
    const [error, setError] = useState(null);
    const [isValidationActive, setIsValidationActive] = useState(false)

    const validateErrors = validateErrorsConfig || (model && model.getValidators()) || {};

    const [values, setValues] = useState(model.getModel());

    const setValue = (field, value) => setValues({...values, [field]: value})

    const setNestedValue = (parent, id, field, value) => setValues({
        ...values,
        [parent]: values[parent].map((i) => i.id === id ? {...i, [field]: value} : i)
    })

    const getFieldProps = (source) => {

        return {
            name: source,
            value: values[source],
            getError: validateErrors[source],
            setValue,
            validateErrors: isValidationActive,
            validationData: values,
        };
    };

    const updateValues = (obj) => setValues({...values, ...obj})

    const onSave = async (e) => {
        e?.preventDefault();

        const preparedData = model.buildDTO(values)

        if (!model.validateData({...values, ...preparedData})) {
            setIsValidationActive(true);
            dispatch(showAlert('fillRequireFieldWarning', '', 'alert-fail'))
            console.error('Validation active')
            return;
        }

        setIsWaiting(true);

        try {
            const {data} = await api[method](`${selmoUrl}/${rest}`, preparedData)
            setResponse(data?.item || data?.items);
            afterSubmit(data?.item || data?.items || '');

        } catch (error) {
            setError(error)
            onError(error)
        } finally {
            setIsValidationActive(false);
            setIsWaiting(false);
        }
    };

    useEffect(() => {
        const parsedData = model.parseDTO(data);
        setValues(parsedData)
    }, [isLoading])

    return [{
        response,
        isWaiting,
        setIsWaiting,
        onSave,
        setResponse,
        setValue,
        setNestedValue,
        getFieldProps,
        values,
        isLoading,
        fetchData,
        updateValues,
        setIsValidationActive,
        isValidationActive,
        error,
        GETErrorMessage: errorMessage,
    }];
};

export default useFormLocal;
