import React, {useContext} from 'react';
import SortableList from "../../../../../src_shared/components/form/sortableList/SortableList";
import {useDispatch} from "react-redux";
import useLang from "../../../../../src_shared/hooks/useLang";
import useForm from "../../../../../src_shared/form/useForm";
import FormActions from "../FormActions";
import {services} from "../../../../RestServices";
import Field from "../../../../../src_shared/components/form/field/Field";
import ConfirmRemoveModal from "../../../../../src_shared/components/modal/ConfirmRemoveModal";
import useRemoveData from "../../../../../src_shared/hooks/useRemoveData";
import BigImageUpload from "../../../../../src_shared/components/form/bigImageUpload/BigImageUpload";
import Loader from "../../../shared/Loader";
import {showAlert} from "../../../shared/components/alert/AlertActions";
import {ShopSettingsContext} from "../../../../routes/shopSettingsRoute/ShopSettingsRoute";
import SwitchBoxes from "../../../../../src_shared/components/form/switchBoxes/SwitchBoxes";
import TextArea from "../../../../../src_shared/components/form/textarea/TextArea";
import CharactersCounter from "../../../../../src_shared/components/ui/CharactersCounter";
import SwitchField from "../../../../../src_shared/components/form/switchField/SwitchField";

const Banners = () => {

	const dispatch = useDispatch();
	const {getLangText} = useLang();
	const {setIsChanging} = useContext(ShopSettingsContext);

	const {
		data: {values, setValue, setNestedValue},
		form: {isWaiting, getFieldProps},
		validation: {isActive}
	} = useForm({
		FormActions,
		params: {},
		requireParentId: false,
	});

	const afterSubmit = () => {
		dispatch(FormActions.removeVariant(itemToRemove.id))
		dispatch(showAlert('yuhuLabel', 'successfulRemovedLabel'))
	}

	const onSave = () => dispatch(FormActions.submitEditForm(values, () => setIsChanging(new Date())))

	const [{
		openModal,
		hideModal,
		confirmModalVisible,
		itemToRemove,
		removeItem,
		isDeleting,
	}] = useRemoveData({
		rest: `${services.API_STORE_BANNERS}`,
		afterSubmit,
	});

	const addNewVariant = () => dispatch(FormActions.addNewVariant());

	const updateNestedValues = (objectValues, rowId, field = 'photo') => {
		const {photo} = objectValues;
		setNestedValue('banners', rowId, field, photo);
	}

	const {shopData} = useContext(ShopSettingsContext);
	const shopLink = `https://${shopData.domain || shopData.full_url}`

	const themesOptions = [
		{id: 'light', label: 'bannerLightModeLabel'},
		{id: 'dark', label: 'bannerDarkModeLabel'},
	]

	const bannerItem = (item) => {
		return (
			<div className="category-row">
				<div className="sort-button in-banners">
					<i className="icon-drag"/>
				</div>
				<div className="flex-grow-1">
					<div className="d-flex">
						<div>
							<BigImageUpload
								imagePath="store"
								values={{photo: item.photo, id: item.id}}
								restService={services.API_STORE_MEDIA_BANNERS}
								updateValues={(objectValues) => updateNestedValues(objectValues, item.id)}
								validation={isActive}
								label="horizontalPhotoLabel"
								dimensionLabel="bannerDimensionLabel"
							/>
						</div>
						<button
							type="button"
							className="button text-only lgrey ml-auto small-icon py-1 d-sm-block d-none"
							onClick={() => openModal(item)}
						>
							<i className="icon-cross mr-1"/>
							{getLangText('removeButton')}
						</button>
					</div>
					<div className="mt-3">
						<div className="form-label d-inline-flex align-items-center">
							<div dangerouslySetInnerHTML={{__html: getLangText('targetLinkLabel')}}/>
							<div className="tooltip-parent ml-1">
								<i className="icon-info"/>
								<div className="tooltip-content light-version"
									 dangerouslySetInnerHTML={{__html: getLangText('targetLinkTooltipLabel')}}/>
							</div>
						</div>
						<Field
							optional
							showLabel={false}
							value={item.url}
							setValue={(field, value) => setNestedValue('banners', item.id, field, value)}
							name="url"
							placeholder={`${shopLink}/${getLangText('exampleProductLabel')}`}
						/>
					</div>
					<div>
						<SwitchField
							className="switch-form inline-label big-label light-version mt-3 mb-0"
							setValue={(field, value) => setNestedValue('banners', item.id, 'extraOptions', value)}
							label={getLangText('addVerticalPhotoLabel')}
							subLabel={getLangText('addExtraOptionSubLabel')}
							value={item.extraOptions}
						/>
						{!!+item.extraOptions &&
							<>
								<BigImageUpload
									className="mt-3"
									imagePath="store"
									values={{photo_mobile: item.photo_mobile, id: item.id}}
									restService={services.API_STORE_MEDIA_BANNERS_MOBILE}
									updateValues={(objectValues) => updateNestedValues({
										photo: objectValues.photo_mobile
									}, item.id, 'photo_mobile')}
									validation={false}
									field="photo_mobile"
									label="verticalPhotoLabel"
									dimensionLabel="proportionLabel"
									withRemove
								/>
								<div className="mt-3">
									<div className="characters-counter-parent">
										<Field
											name="title"
											label={getLangText('paymentTransferTitleLabel')}
											optional
											value={item.title}
											setValue={(field, value) => setNestedValue('banners', item.id, field, value)}
											extraProps={{
												maxLength: 40,
											}}
										/>
										<CharactersCounter value={item.title} max={40}/>
									</div>
									<div className="characters-counter-parent">
										<TextArea
											name="description"
											label={getLangText('input_label_package_type_description')}
											optional
											value={item.description}
											setValue={(field, value) => setNestedValue('banners', item.id, field, value)}
											rows={2}
											extraProps={{
												maxLength: 100,
											}}
										/>
										<CharactersCounter value={item.description} max={100}/>
									</div>
									<Field
										name="button_text"
										label={getLangText('buttonTextLabel')}
										optional
										value={item.button_text}
										setValue={(field, value) => setNestedValue('banners', item.id, field, value)}
									/>
									<div className="form-label mb-0">
										{getLangText('shopBannersThemeLabel')}
									</div>
									<SwitchBoxes
										value={item.theme}
										name="theme"
										className="small-padd inline"
										fieldName={`theme_${item.id}`}
										options={themesOptions}
										setValue={(field, value) => setNestedValue('banners', item.id, field, value)}
										getError={() => {
										}}
									/>
								</div>
							</>
						}
					</div>
					<button
						type="button"
						className="button border-button lgrey w-100 d-sm-none with-icon mt-4"
						onClick={() => openModal(item)}
					>
						<i className="icon-cross mr-1"/>
						{getLangText('removeButton')}
					</button>
				</div>
			</div>
		)
	}

	return (
		<section className="loader-parent">
			<Loader isLoading={isWaiting}/>
			<div className="left-side">
				<h2 className="label">{getLangText('bannerOnMainPageLabel')}</h2>
			</div>
			<div className="form-col">
				<SortableList
					emptyImg="/assets/images/default/shop-logo.svg"
					isWaiting={false}
					items={values.banners}
					updateAllItemsOnServer={(updatedItems) => setValue('banners', updatedItems)}
					ItemComponent={bannerItem}
					noResultsLabel="noBannersOnMainPageLabel"
					noResultsSubLabel="noBannersOnMainPageSubLabel"
					emptyListClassName="py-4 functionality-purchase"
				/>
				<button
					type="button"
					onClick={addNewVariant}
					className="button text-blue-button"
				>
					<i className="icon-plus"/>
					{getLangText('addAnotherPhotoLabel')}
				</button>
				<div className="d-flex mt-1 pb-3">
					<div className="d-flex flex-column ml-auto w-100">
						{/*		{!_.isEqual(values.banners, values.defaultBanners) &&*/}
						{/*			<div className="form-info-box red align-items-center small-line-height w-100 mb-2 mt-3">*/}
						{/*				<div>*/}
						{/*					<i className="icon-info-c"/>*/}
						{/*				</div>*/}
						{/*				<div className="title">*/}
						{/*					{getLangText('notSavedChangesLabel')}*/}
						{/*				</div>*/}
						{/*			</div>*/}
						{/*		}*/}
						<button
							onClick={onSave}
							type="button"
							className={`ml-sm-auto w-sm-auto w-100 mt-sm-0 mt-3 button primary`}
						>
							{getLangText('saveButton')}
						</button>
					</div>
				</div>
			</div>
			<ConfirmRemoveModal
				show={confirmModalVisible}
				subTitle={itemToRemove.name}
				dialogClassName="small-modal"
				title={getLangText('wantToRemoveBannerLabel')}
				hide={hideModal}
				confirm={removeItem}
				isWaiting={isDeleting}
			/>
		</section>
	);
};

export default Banners;
