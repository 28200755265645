import ListReducer from "../../../../../src_shared/list/ListReducer.js";
import createReducer from "../../../shared/helpers/createReducer.js";
import Composition from "../../../shared/helpers/Composition.js";
import CheckboxMultiselectReducer
	from "../../../../../src_shared/list/checkboxMultiselect/CheckboxMultiselectReducer";

const multiSelectReducer = CheckboxMultiselectReducer
	.buildReducer('selectedItems', 'selectedItems');

export const getStateRoot = (state) => state.products.lists.productsList;
export const prefix = 'PRODUCTS_LIST_';

const defaultState = {
	selectedItems: [],
}

const getInitState = () => ListReducer.getInitState(defaultState);

const getReduceMap = () =>  new Composition(
	ListReducer.getReduceMap(),
	multiSelectReducer.reduceMap,
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

