import Model, {convertObjectProperties} from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

class PaxyOptionsModel extends Model {
	getModel() {
		return {
			option: null,
			options: [],
		};
	}

	getValidators() {
		return {
			option: ValidationError.notEmpty,
			options: ValidationError.skip
		};
	}

	parseDTO(data) {
		return convertObjectProperties(
			data,
			{
			},
			{
				option: null,
			},
		);
	}

	buildDTO(data) {
		const selectedOption = data.options.find((i) => i.id === data.option);
		return {
			country_code: selectedOption?.country,
			option_id: selectedOption?.id,
		}

	}
}

export default new PaxyOptionsModel();
