const initialState = {
	isOpen: false,
	orders: [],
	isError: false,
	isLoading: true,
	noResults: true,
}
export default (state = initialState, action) => {
	switch (action.type) {
		case 'ORDER_MODAL_TOGGLE_VISIBILITY':
			return {...state, isOpen: action.toggle};
		case 'GET_ORDERS_REQUEST':
			return {...state, isLoading: true, noResults: false};
		case 'UPDATE_ORDERS':
			return {...state, orders: action.orders, isLoading: false};
		case 'GET_ORDERS_ERROR':
			return {...state, isError: true, isLoading: false};
		case 'NOT_ORDERS_FOUND':
			return {...state, noResults: true, isLoading: false};
		case 'MESSENGER_ORDERS_RESET':
			return initialState;
		default:
			return state;
	}
};
