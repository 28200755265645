import React from 'react';
import {SortableContainer, SortableElement} from "react-sortable-hoc";
import {arrayMoveImmutable as arrayMove} from 'array-move';
import EmptyList from "../../../shared/EmptyList";
import {getStatusBox} from "../../../live/details/platform/notShownList/components/table/Table";
import useLang from "../../../../../src_shared/hooks/useLang";
import {useUnitPrice} from "../../../shared/helpers/Price";
import {useHistory} from "react-router-dom";
import {RouterPaths} from "../../../../routes/RouterPath";
import ServerImage from "../../../shared/components/serverImage/ServerImage";

const SortableItem = SortableElement(({item, onSortProductEnd, getLangText, getPrice}) => {
    const history = useHistory();

    const onProductClick = () => history.push(`${RouterPaths.ProductsList}/edytuj/${item.id}`);

    return (
        <li className="sortable-tree-item no-border">
            <div className="category-row product-row">
                <div className="sort-button">
                    <i className="icon-drag"/>
                </div>
                <div className="number mr-2">
                    {item.shop_product_id}
                </div>
                <div className="text-left flex lg:items-center grow">
                    <figure>
                        {item.photo_small && item.photo_small !== '0' ?
                            <ServerImage src={item.photo_small} alt={item.name}/> :
                            <img src="/assets/images/default/product.svg" alt=""/>}
                    </figure>
                    <div className="grow">
                        <button
                            onClick={onProductClick}
                            type="button"
                            className="text-left font-semibold text-sm hover:underline">
                            {item.name}
                        </button>
                        <div className="price">
                            {getPrice(item.price)}
                        </div>
                    </div>
                </div>
                {getStatusBox(item.squ, getLangText)}
            </div>
        </li>
    )
});

const SortableList = SortableContainer(({items, onSortProductEnd, getLangText, getPrice}) => {
    return (<ul className="category-tree products-tree">
        {items.map((item, index) => (<SortableItem
            key={`item-${item.id}`}
            index={index}
            items={items}
            item={item}
            onSortProductEnd={(i) => onSortProductEnd(i, item)}
            getLangText={getLangText}
            getPrice={getPrice}
        />))}
    </ul>);
});

const ProductsSortableTree = ({items, updateAllItemsOnServer, isWaiting, showAssignProductsModal}) => {
    const {getLangText} = useLang();
    const {getPrice} = useUnitPrice();

    const onSortEnd = ({oldIndex, newIndex}) => {
        const updatedItems = arrayMove(items, oldIndex, newIndex);
        updateAllItemsOnServer(updatedItems)
    };

    const ExtraComponent = () => (<button
        onClick={showAssignProductsModal}
        type="button"
        className="button primary small-size mt-3"
    >
        {getLangText('assignProductsLabel')}
    </button>)

    return (<EmptyList
        items={items}
        isLoading={isWaiting}
        className="with-padd"
        imgSrc="/assets/images/empty/products-empty.svg"
        title="noAssignedProductsLabel"
        text="noAssignedProductsSubLabel"
        ExtraComponent={ExtraComponent}
    >
        <div className="sortable-tree-wrapper pb-3">
            <SortableList
                items={items}
                onSortEnd={onSortEnd}
                getLangText={getLangText}
                getPrice={getPrice}
            />
        </div>
    </EmptyList>);
};

export default ProductsSortableTree;
