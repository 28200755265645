import {ORDER_PACKED_STATUES} from "./PackedStatuses";

export const ORDER_INVOICE_ALL = 'all';
export const ORDER_INVOICE_GENERATED = '1';
export const ORDER_INVOICE_NULL = '0';


export const ORDER_INVOICE_STATUES = [
	{
		value: ORDER_INVOICE_ALL, label: 'allLabel', color: 'waiting',
	},
	{
		value: ORDER_INVOICE_GENERATED, label: 'generatedLabel', color: 'waiting',
	},
	{
		value: ORDER_INVOICE_NULL, label: 'noInvoiceLabel', color: 'waiting',
	},
]
export const findOrderInvoiceStatusById = (id) => ORDER_INVOICE_STATUES.find((status) => status.value === id);
