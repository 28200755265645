import React, {useState} from 'react';
import {DateRange, DefinedRange} from "react-date-range";
import * as rdrLocales from "react-date-range/dist/locale";
import {useSelector} from "react-redux";
import {addDays, endOfDay, endOfWeek, startOfDay, startOfWeek} from "date-fns";
import useLang from "../../../hooks/useLang";
import moment from "moment";
import {useHistory} from "react-router-dom";
import {filterNullValues, getGETParamsUrl} from "../../../list/ListActions";
import {convertDate} from "../../../../components/admin/components/app/shared/helpers/dateHelpers";
import {convertToUserTimeZone} from "../../../../components/app/shared/helpers/dateHelpers";

const DatePickerDropdown = ({selectionRange, setSelectionRange, setOpen}) => {
    const [selectionRangeLocal, setSelectionRangeLocal] = useState(selectionRange);
    const {lang} = useSelector((state) => state.i18n);
    const {getLangText} = useLang();
    const history = useHistory();

    const onSave = async () => {
        const params = filterNullValues({
            dateFrom: selectionRangeLocal.startDate ? convertDate(selectionRangeLocal.startDate) : null,
            dateTo: convertDate(selectionRangeLocal.endDate),
        })
        setSelectionRange(selectionRangeLocal)
        setOpen(false);
        history.replace({
            search: getGETParamsUrl(params)
        })

    }

    const defineds = {
        startOfWeek: startOfWeek(new Date()),
        endOfWeek: endOfWeek(new Date()),
        startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
        endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
        startOfToday: startOfDay(new Date()),
        startOfLastSevenDay: startOfDay(addDays(new Date(), -7)),
        startOfLastThirtyDay: startOfDay(addDays(new Date(), -30)),
        endOfToday: endOfDay(new Date()),
    };

    return (
        <div
            onClick={(e) => e.stopPropagation()}
            className="p-1.5"
        >
            <div className="sm:flex">
                <DefinedRange
                    onChange={({selection}) => setSelectionRangeLocal(selection)}
                    ranges={[selectionRange]}
                    inputRanges={[]}
                    renderStaticRangeLabel={(props) => props.label}
                    staticRanges={[
                        {
                            hasCustomRendering: true,
                            label: getLangText('allLabel'),
                            range: () => ({
                                startDate: null,
                                endDate: defineds.endOfToday,
                            }),
                            isSelected() {
                                return false;
                            },
                        },
                        {
                            hasCustomRendering: true,
                            label: getLangText('todayLabel'),
                            range: () => ({
                                startDate: defineds.startOfToday,
                                endDate: defineds.endOfToday,
                            }),
                            isSelected() {
                                return convertToUserTimeZone(defineds.startOfToday).isSame(selectionRange.startDate) && convertToUserTimeZone(defineds.endOfToday).isSame(selectionRange.endDate);
                            },
                        },
                        {
                            hasCustomRendering: true,
                            label: getLangText('lastSevenDaysLabel'),
                            range: () => ({
                                startDate: defineds.startOfLastSevenDay,
                                endDate: defineds.endOfToday,
                            }),
                            isSelected() {
                                return convertToUserTimeZone(defineds.startOfLastSevenDay).isSame(selectionRange.startDate) && convertToUserTimeZone(defineds.endOfToday).isSame(selectionRange.endDate);
                            },
                        },
                        {
                            hasCustomRendering: true,
                            label: getLangText('lastThirtyDaysLabel'),
                            range: () => ({
                                startDate: defineds.startOfLastThirtyDay,
                                endDate: defineds.endOfToday,
                            }),
                            isSelected() {
                                return convertToUserTimeZone(defineds.startOfLastThirtyDay).isSame(selectionRange.startDate) && convertToUserTimeZone(defineds.endOfToday).isSame(selectionRange.endDate);
                            },
                        },
                    ]}
                />
                <DateRange
                    onChange={({selection}) => setSelectionRangeLocal(selection)}
                    ranges={[selectionRangeLocal]}
                    disabled
                    weekStartsOn={1}
                    showDateDisplay={false}
                    locale={rdrLocales[lang]}
                    direction="horizontal"
                />
            </div>
            <div className="bottom-buttons pb-2 text-center space-x-3">
                <button
                    className="button primary small-size p-0 px-2 h-[30px]"
                    type="button"
                    onClick={onSave}
                >
                    Potwierdź
                </button>
                <button
                    className="button border-button small-size"
                    type="button"
                    onClick={() => setOpen(false)}
                >
                    Anuluj
                </button>
            </div>
        </div>
    );
};

export default DatePickerDropdown;
