import {getStateRoot} from "./shopSettingsLoaderReducer";
import {saveToLS} from "../../../../../src_shared/helpers/LS";
import moment from "../../moment";
import {convertToUserTimeZone} from "../../helpers/dateHelpers";

export class ShopSettingsLoaderActions {
	startChanges(date) {
		return (dispatch) => {
			saveToLS('changeShouldBeReady', convertToUserTimeZone(date).add(3, 'm').toDate());
			dispatch(this.changingVisibility(true));
		}
	}

	changingVisibility(date) {
		return {
			type: 'SHOP_SETTINGS_LOADER_VISIBILITY',
			date,
		};
	}
}

export const getInstance = () => new ShopSettingsLoaderActions({
	getStateRoot,
});

export default getInstance();
