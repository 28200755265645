import React from 'react';
import ClientInfo from "./info/ClientInfo";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import EditClientModal from "./form/Form";
import {useSelector} from "react-redux";
import {createSelector} from "reselect";
import {getStateRoot} from "./form/formReducer";
import {getStateRoot as getClientInfoStateRoot} from './info/dataReducer'
import {Helmet} from "react-helmet";
import useLang from "../../../../src_shared/hooks/useLang";
import ClientsTabs from "./ClientsTabs";

const ClientDetails = () => {
    const {getLangText} = useLang();

    const {modalVisible} = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));
    const {data} = useSelector(createSelector(getClientInfoStateRoot, (stateRoot) => stateRoot));

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${data.name} klient - Selmo`}</title>
            </Helmet>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <Link to={RouterPaths.ClientsList}>{getLangText('asideClientsLabel')}</Link>
                    </li>
                    <li className="active">
                        <div>{data.name}</div>
                    </li>
                </ul>
                <Link to={RouterPaths.ClientsList} className="button back-to-panels mb-lg-3 mb-0 mt-lg-0 mt-3">
                    <i className="icon-arrow-left"/>
                    {getLangText('clientsBackToClientsButton')}
                </Link>
            </div>
            <div className="client-details-row">
                <div className="orders-col">
                    <ClientsTabs/>
                </div>
                <div className="details-col">
                    <ClientInfo/>
                </div>
            </div>
            {modalVisible && <EditClientModal isModalVisible={modalVisible}/>}
        </>
    );
};

export default ClientDetails;

