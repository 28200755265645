import React from 'react';
import useLang from "../../../../src_shared/hooks/useLang";
import useList from "../../../../src_shared/list/useList";
import ListActions from "./ListActions";
import {getStateRoot} from "./listReducer";
import DateRangePicker from "../../shared/components/form/DateRangePicker";
import {getStateRoot as getFiltersStateRoot} from "../filters/filtersReducer";
import FiltersActions from "../filters/FiltersActions";
import ActivityChart from "./components/ActivityChart";
import ActivityHistory from "./components/ActivityHistory";
import {Helmet} from "react-helmet";

const activities = [
    {id: 'order_notifications', color: '#3C61EA', label: 'orderNotifications'},
    {id: 'automatic_reserve_list', color: '#FF7747', label: 'automaticReserveList'},
    {id: 'order_cancellation', color: '#FCD34C', label: 'orderCancellation'},
    {id: 'open_package_closing', color: '#04B6D5', label: 'closingOpenPackages'},
]
const MrAutomate = () => {
    const {getLangText} = useLang();

    const {
        list: {items, isWaiting},
    } = useList({
        ListActions,
        getStateRoot,
        getFiltersStateRoot,
    });

    const getActivityById = (id) => activities.find((i) => i.id === id);

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Selmo - {getLangText('panAutomacikLabel')}</title>
            </Helmet>
            <div className="grid sm:grid-cols-5 gap-4">
                <div className="sm:col-span-3">
                    <div
                        className="sm:h-[210px] h-[170px] text-[white] sm:rounded-[5px] bg-gradient-to-br from-[#2B66FF] to-[#05002E] shadow-default relative px-6 py-8">
                        <div className="flex flex-col h-full w-[65%]">
                            <div dangerouslySetInnerHTML={{__html: getLangText('panAutomacikStrongLabel')}}/>
                            <div
                                className="whitespace-pre-wrap  mt-auto text-[20px] sm:text-[34px] sm:leading-[48px] leading-[32px] sm:tracking-[-0.6px] font-bold"
                                dangerouslySetInnerHTML={{
                                    __html: getLangText({
                                        key: 'didForYouActivities',
                                        data: [isWaiting ? getLangText('alotLabel') : items.total_activities]
                                    })
                                }}/>
                        </div>
                        <div className="absolute right-0 bottom-0 w-[35%]">
                            <img src="/assets/images/robo-banner_1.webp" alt=""/>
                        </div>
                    </div>
                    {isWaiting ?
                        <div className="mt-3">
                            <div className="p-6 animate-pulse bg-gray-200 rounded-md dark:bg-gray-700">
                                <div className="animate-pulse mb-4">
                                    <div className="w-[30%] h-7 bg-gray-300 rounded-md dark:bg-gray-800 mb-1"/>
                                </div>
                                <div className="animate-pulse mb-4">
                                    <div className="w-full h-48 bg-gray-300 rounded-md dark:bg-gray-800"/>
                                </div>
                                <div className="animate-pulse">
                                    <div className="w-full h-48 bg-gray-300 rounded-md dark:bg-gray-800"/>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="shadow-default sm:rounded-[5px] bg-[white] p-6 sm:mt-3 sm:border border-[#F5A218]">
                            <div className="flex items-center">
                                <div className="text-xl font-bold">
                                    {getLangText('activitiesInSelectedRange')}
                                </div>
                                <DateRangePicker
                                    className="ml-auto"
                                    actions={ListActions}
                                    getFiltersStateRoot={getFiltersStateRoot}
                                    FiltersActions={FiltersActions}
                                />
                            </div>
                            <div className="rounded-[8px] border px-6 py-[20px] mt-4">
                                <div className="text-sm text-[#6B7280]">{getLangText('activitiesPerformed')}</div>
                                <div className="text-[#101827] text-[32px] leading-[35px] font-bold mt-1">
                                    {items.total}
                                </div>
                                <div
                                    className="h-[7px] w-full rounded-[5px] flex align-items-center overflow-hidden my-3">
                                    {Object.keys(items.activities).map((activity) => (
                                        <div
                                            className="h-[7px]"
                                            key={activity}
                                            style={{
                                                width: ((+items.activities[activity] / +items.total) * 100) + '%',
                                                background: getActivityById(activity)?.color
                                            }}
                                        />
                                    ))}
                                </div>
                                <div className="space-y-2">
                                    {Object.keys(items.activities).map((activity) => (
                                        <div
                                            key={activity}
                                            className="flex align-items-center">
                                            <div
                                                style={{background: getActivityById(activity)?.color}}
                                                className="min-w-[12px] h-[12px] rounded-[3px] mr-2"
                                            />
                                            <div className="text-xs text-[#6B7280] font-medium">
                                                <strong
                                                    className="mr-1 text-[#000]">
                                                    {items.activities[activity]}
                                                </strong> {getLangText(getActivityById(activity)?.label)}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="rounded-[8px] border px-6 py-[20px] mt-3">
                                <ActivityChart data={items.daily_activities}/>
                            </div>
                        </div>
                    }
                </div>
                <div className="sm:col-span-2">
                    <ActivityHistory/>
                </div>
            </div>
        </div>
    );
};

export default MrAutomate;
