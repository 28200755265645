import React, {useEffect} from 'react';
import Modal from "react-bootstrap/Modal";
import {useDispatch, useSelector} from "react-redux";
import ClientInput from "../../components/ClientInput.jsx";
import AssignModalActions from "./AssignModalActions.js";
import {createSelector} from "reselect";
import {getStateRoot} from "./assignModalReducer";
import NumberInput from "../../../../../shared/components/form/NumberInput";
import Field from "../../../../../shared/components/form/field/Field";
import useLang from "../../../../../../../src_shared/hooks/useLang";

const AssignModal = () => {

	const dispatch = useDispatch();
	const {getLangText} = useLang();

	const {data, modalVisible} = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));
	const updateField = (field, value) => dispatch(AssignModalActions.data.setValue(field, value))
	const {clients} = useSelector((state) => state.lives.details)

	const submit = () => dispatch(AssignModalActions.submit())
	const hideModal = () => dispatch(AssignModalActions.modal.hideModal());
	const matchedProduct = clients.find((client) => client.id === data.clientId)?.products.find((product) => product.id === data.productId);
	useEffect(() => {
		dispatch(AssignModalActions.data.restoreEmptyData());
		dispatch(AssignModalActions.data.updateValues({
			productName: matchedProduct?.name,
			productId: matchedProduct?.id,
			price: matchedProduct?.price,
			quantity: matchedProduct?.quantity,
		}))
	}, [modalVisible])

	const hasError = (value) => data.activeValidation && !value;

	return (
		<Modal
			dialogClassName="default-modal"
			show={modalVisible}
			onHide={hideModal}
		>
			<Modal.Header className="align-items-flex-start with-border">
				<Modal.Title>
					{getLangText('liveAssignProductLabel')}
					<div className="sub-title">
						{getLangText('liveAssignProductSubLabel')}
					</div>
				</Modal.Title>
				<button
					onClick={hideModal}
					type="button"
					className="button"
				>
					<i className="icon-cross"/>
				</button>
			</Modal.Header>
			<Modal.Body>
				<div className="inputs-row form-row single-client-row small-inputs">
					<div className="product-input-col">
						<div className="form-group">
							<Field
								label={getLangText('productLabel')}
								name="productName"
								value={data.productName}
								setValue={updateField}
								activeValidation={data.activeValidation}
								displayTextError={false}
								required
							/>
						</div>
					</div>
					<div>
						<div className={`form-group ${hasError(data.price) ? 'error-group' : ''}`}>
							<label className="control-label">{getLangText('priceLabel')}</label>
							<NumberInput
								onChange={(e) => updateField('price', e.target.value)}
								value={data.price}
								min={0}
								name="price"
							/>
						</div>
					</div>
					<div>
						<div className={`form-group ${hasError(data.quantity) ? 'error-group' : ''}`}>
							<label className="control-label">{getLangText('quantityLabel')}</label>
							<NumberInput
								onChange={(e) => updateField('quantity', e.target.value)}
								value={data.quantity}
								min={1}
								name="quantity"
							/>
						</div>
					</div>
				</div>
				<div className="inputs-row form-row single-client-row small-inputs">
					<div className="product-input-col with-avatar">
						<ClientInput
							updateField={updateField}
							customer={data}
							extraClass={hasError(data.name) ? 'error-group' : ''}
							customerId={data.id}
							hasCustomerError={data.activeValidation}
						/>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<button
					onClick={hideModal}
					type="button"
					className="button border-button"
				>
					{getLangText('cancelButton')}
				</button>
				<button
					onClick={submit}
					type="button"
					className="button primary"
				>
					{getLangText('assignButton')}
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default AssignModal;
