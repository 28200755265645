import {getStateRoot, prefix} from "./formReducer.js";
import {services} from "../../../../RestServices.js";
import FormActions from "../../../../../src_shared/form/FormActions";
import {getStateRoot as getEditProductStateRoot} from "../form/formReducer";
import {batch} from "react-redux";
import EditProductFormActions from "../form/FormActions";
import PackageTypesModel from "../../../../../modules/models/order/PackageTypesModel";
import {selmoUrl} from "../../../../../src_shared/api/api";
import axios from "axios";
import LangsActions from "../../../shared/langs/LangsActions";
import api from "../../../../../services/axios/axios";

export class OrderDetailsPackagingTypeFormActions extends FormActions {
	loadData(id) {
		return async (dispatch) => {
			dispatch(this.waiting.startWaiting());
			dispatch(this.onFirstInit());
			try {
				const {data} = await api.get(`${selmoUrl}/${this.restService}`)
				const parsedItem = dispatch(this.parseData(data.item));
				dispatch(this.data.setValue('packageTypes', parsedItem))
			} catch (e) {
				dispatch(this.onErrorLoad(e))
				console.error('Error loadData')
			} finally {
				dispatch(this.waiting.stopWaiting());
			}
		}
	}

	afterSubmit() {
		return (dispatch, state) => {
			const {data} = getEditProductStateRoot(state());
			batch(() => {
				dispatch(this.modal.hideModal());
				dispatch(EditProductFormActions.loadData(data.shop_order_id))
			})
		}
	}
}

export const getInstance = () => new OrderDetailsPackagingTypeFormActions({
	getStateRoot,
	prefix,
	model: PackageTypesModel,
	restService: services.API_ORDER_PACKAGE_TYPES,
});

export default getInstance();
