import React from 'react';
import useFormLocal from "../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../RestServices";
import useLang from "../../../../src_shared/hooks/useLang";
import SwitchField from "../../../../src_shared/components/form/switchField/SwitchField";
import Loader from "../../../app/shared/Loader";
import ComponentLoader from "../../../app/shared/components/componentLoader/ComponentLoader";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../routes/RouterPath";
import Field from "../../../../src_shared/components/form/field/Field";
import SettingsPaypalModel from "../../../../modules/models/settings/SettingsPaypalModel";
import SelectField from "../../../../src_shared/components/form/selectField/SelectField";
import {Helmet} from "react-helmet";
import {useSelector} from "react-redux";

const Paypal = () => {
	const {getLangText} = useLang();
	const {userData} = useSelector((state) => state.session);
	const [{
		onSave,
		isWaiting,
		getFieldProps,
		isLoading,
		setValue,
	}] = useFormLocal({
		rest: services.API_SETTINGS_PAYMENT_PAYPAL,
		model: SettingsPaypalModel,
	});

	const commissionPriceTypes = [
		{
			value: '2',
			label: '%',
		},
		{
			value: '1',
			label: userData?.currency_info?.text,
		},
	]

	if (isLoading) return <ComponentLoader/>;

	return (
		<>
			<Helmet>
				<meta charSet="utf-8"/>
				<title>{getLangText('settings_payments_paypal_payment')} - Selmo</title>
			</Helmet>
			<div className="header-bar breadcrumbs-in-react-app">
				<ul className="breadcrumbs">
					<li>
						<Link to={RouterPaths.Settings}>
							{getLangText('asideSettingsLabel')}
						</Link>
					</li>
					<li>
						<Link to={RouterPaths.SettingsPayments}>
							{getLangText('paymentsLabel')}
						</Link>
					</li>
					<li className="active">
						<div>{getLangText('settings_payments_paypal_payment')}</div>
					</li>
				</ul>
				<Link
					to={RouterPaths.SettingsPayments}
					className="button back-to-panels mb-3">
					<i className="icon-arrow-left"/>
					{getLangText('button_back_to_payment')}
				</Link>
			</div>
			<div className="settings-page sub-settings-page">
				<div className="header-wrapper">
					<h1>
						{getLangText('settings_payments_paypal_payment')}
					</h1>
					<div className="description">
						{getLangText('settings_payments_paypal_payment_subtitle')}
					</div>
				</div>
				<div className="settings-form">
					<div className="d-flex align-items-center flex-sm-nowrap flex-wrap">
						<div
							className="form-group small-width form-group-addon mr-3 hidden-arrows">
							<label className="control-label">{getLangText('settings_payments_bluemedia_payment_commission_value')}</label>
							<input
								type="number"
								className="form-control"
								{...getFieldProps('paypal_price')}
								onChange={(e) => setValue('paypal_price', e.target.value)}
							/>
							<SelectField
								{...getFieldProps('paypal_price_type')}
								className="default-size mb-0"
								showLabel={false}
								options={commissionPriceTypes}
								extraProps={{
									isSearchable: false
								}}
							/>
						</div>
						<Field
							{...getFieldProps('paypal_email')}
							label={getLangText('input_label_paypal_email')}
							className="mr-3"
							type="email"
						/>
						<SwitchField
							{...getFieldProps('paypal_active')}
							label={getLangText('active_payment')}
							className="switch-form inline-label align-items-start big-label light-version mb-0 mt-1 mr-3"

						/>
						<button
							type="button"
							onClick={onSave}
							className={`button primary w-sm-auto w-100 mt-sm-0 mt-3 ${isWaiting ? 'loading-button' : ''}`}
						>
							<Loader isLoading={isWaiting}/>
							{getLangText('saveButton')}
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default Paypal;
