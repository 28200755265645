import React, {useState} from 'react';
import Modal from "react-bootstrap/Modal";
import Loader from "../../../../../../src_shared/components/loader/Loader";
import useLang from "../../../../../../src_shared/hooks/useLang";
import {services} from "../../../../../RestServices";
import AsyncSearchSelectField
    from "../../../../../../src_shared/components/form/selectField/asyncSearchSelectField/AsyncSearchSelectField";
import {useParams} from "react-router-dom";
import useSubmitData from "../../../../../../src_shared/hooks/useSubmitData";
import Variants from "../../../../storage/scanner/card/product/components/variants/Variants";

const AssignToPlace = ({
                           modalVisible,
                           hideModal,
                           fetchData = () => {
                           },
                           values,
                       }) => {
    const {getLangText} = useLang();
    const {id, variant_id} = useParams();
    const [shelfId, setShelfId] = useState(null);
    const [selectedVariants, setSelectedVariants] = useState([]);

    const [{onSave, isWaiting}] = useSubmitData(
        {
            rest: `${services.API_WAREHOUSE_PRODUCT}/${id}`,
            values: {
                shelf_id: shelfId,
                selected_variants: selectedVariants,
                product_size_id: variant_id || null,
            },
            afterSubmit: () => {
                hideModal();
                fetchData();
                setSelectedVariants([]);
            }
        });

    return (
        <Modal
            dialogClassName="default-modal h-full"
            contentClassName="md:h-auto h-full"
            show={modalVisible}
            onHide={hideModal}
        >
            <Modal.Header>
                <Modal.Title>
                    {getLangText('assignPlace')}
                </Modal.Title>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            <form className="grow flex flex-col" onSubmit={onSave}>
                <Modal.Body className="pt-3 pb-5 grow">
                    <AsyncSearchSelectField
                        id="custom-dropdown-default"
                        className="default-size big-size"
                        name="shelf_id"
                        setValue={(field, value) => setShelfId(value)}
                        value={shelfId}
                        label={getLangText('localizationLabel')}
                        rest={`${services.API_WAREHOUSE_SHELVES}`}
                        optionsParser={(options) => options?.map((i) => ({
                            ...i,
                            name: i.shelf
                        }))}
                        floatedLabel
                        searchOnFocus
                    />
                    <Variants
                        fetchData={fetchData}
                        values={values}
                        setSelectedVariants={setSelectedVariants}
                        selectedVariants={selectedVariants}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <button
                        onClick={hideModal}
                        type="button"
                        className="button border-button"
                    >
                        {getLangText('cancelButton')}
                    </button>
                    <button
                        type="submit"
                        className={`button primary ${isWaiting ? 'loading-button' : ''}`}
                    >
                        {isWaiting && <Loader isLoading/>}
                        {getLangText('saveButton')}
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default AssignToPlace;
