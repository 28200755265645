import ListReducer from "../../../../../src_shared/list/ListReducer";
import createReducer from "../../../shared/helpers/createReducer";
import Composition from "../../../shared/helpers/Composition";

export const getStateRoot = (state) => state.products.form.removedList;
export const prefix = 'PRODUCTS_FORM_REMOVED_LIST_';

const getInitState = () => ListReducer.getInitState();

const getReduceMap = () => new Composition(
	ListReducer.getReduceMap(),
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

