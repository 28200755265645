import ListReducer from "../../../../../src_shared/list/ListReducer.js";
import createReducer from "../../../shared/helpers/createReducer.js";
import Composition from "../../../shared/helpers/Composition.js";

export const getStateRoot = (state) => state.products.lists.removedList;
export const prefix = 'PRODUCTS_REMOVED_LIST_';

const defaultState = {
	sortBy: 'date',
	sortDir: 'desc',
	totalPrice: null,
	quantity: null,
}

const getInitState = () => ListReducer.getInitState(defaultState);

const getReduceMap = () =>  new Composition(
	ListReducer.getReduceMap(),
	{
		SET_TOTAL_PRICE: (state, action) => ({...state, totalPrice: action.totalPrice})
	},
	{
		SET_QUANTITY: (state, action) => ({...state, quantity: action.quantity})
	},
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

