import React from 'react';
import {
	PAYMENT_SUBSCRIPTION_CARD,
	PAYMENT_SUBSCRIPTION_TRANSFER, PAYMENT_SUBSCRIPTION_TRANSFER_ONLINE,
	PAYMENTS_SUBSCRIPTION_TYPE, PAYMENTS_SUBSCRIPTION_TYPE_NOT_POLAND
} from "../../shared/enums/PaymentsType";
import useLang from "../../../../src_shared/hooks/useLang";
import {useSelector} from "react-redux";
import {SHOP_ORIGIN_PL} from "../../shared/enums/ShopOrigins";

const PaymentTypes = ({values, setValue, className = ''}) => {
	const {getLangText} = useLang();
	const {userData} = useSelector((state) => state.session);

	const preparedPaymentsTypes = userData.origin === SHOP_ORIGIN_PL ? PAYMENTS_SUBSCRIPTION_TYPE : PAYMENTS_SUBSCRIPTION_TYPE_NOT_POLAND

	return (
		<div className={`select-boxes-wrapper ${className}`}>
			<div className="section-label">
				{getLangText('paymentsSelectPaymentsMethodsLabel')}
			</div>
			<div className="data-boxes-wrapper small-padd separated">
				{preparedPaymentsTypes.map((item) => (
					<div
						key={item.value}
						className={`content payment-content ${item.value === values.selected_payment ? 'active' : ''} ${(item.value === PAYMENT_SUBSCRIPTION_CARD || item.value === PAYMENT_SUBSCRIPTION_TRANSFER_ONLINE) ? 'with-left-border blue-border' : ''} flex-column`}
					>
						<div className="content__top-part">
							<input
								onChange={() => setValue('selected_payment', item.value)}
								checked={values.selected_payment === item.value}
								type="radio"
								name="selected_payment"
							/>
							<span className="checkmark"/>
							<div className="flex-grow-1">
								{getLangText(item.label)}
								<div className="data">
									{(item.value === PAYMENT_SUBSCRIPTION_CARD || item.value === PAYMENT_SUBSCRIPTION_TRANSFER_ONLINE) &&
										<div>
											<div className="popular-text">
												<i className="icon-time"/>
												{getLangText('subscriptionWillBeActiveImmediately')}
											</div>
										</div>
									}
									{item.value === PAYMENT_SUBSCRIPTION_TRANSFER &&
										<div>
											<div className="popular-text grey">
												<i className="icon-time"/>
												{getLangText('subscriptionWillBeActiveInTwoDaysLabel')}
											</div>
										</div>
									}
								</div>
							</div>
							{item.img &&
								<img
									style={{height: '20px'}}
									className="mr-sm-3 payment-img"
									src={`/assets/images/payments/${item.img}.svg`}
								/>
							}
							<div className="button-place d-sm-block d-none">
								<div className="button border-button btn-change">
									{getLangText('selectButton')}
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default PaymentTypes;
