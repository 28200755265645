import React from 'react';
import useLang from "../../../../../../src_shared/hooks/useLang";
import EmptyList from "../../../../shared/EmptyList";
import CustomDropdown from "../../../../shared/components/dropdown/CustomDropdown";
import {convertToUserTimeZone} from "../../../../shared/helpers/dateHelpers";
import ClientAvatar from "../../../../live/shared/Avatar";
import ProductTypeIcon from "../../../../shared/components/productType/ProductTypeIcon";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import TimeAgo from "../../../../../../src_shared/helpers/TimeAgo";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../../routes/RouterPath";
import {useSelector} from "react-redux";
import {useUnitPrice} from "../../../../shared/helpers/Price";

const Table = ({items, isWaiting, updateSortOptions, getSortClass, data}) => {

    const {getLangText} = useLang();
    const {lang} = useSelector((state) => state.i18n);
    const {getPrice} = useUnitPrice();

    return (
        <EmptyList
            items={items}
            isLoading={isWaiting}
            className="with-padd"
            imgSrc="/assets/images/empty/products-empty.svg"
            title={getLangText('emptyHereLabel')}
            text={getLangText('changeCriteriaOfSearch')}
        >
            <div className="table-wrapper">
                <div className="table-responsive overflow-visible">
                    <table className="table live-table smaller-padd">
                        <thead>
                        <tr>
                            <th
                                name="selmo_clients.name"
                                onClick={updateSortOptions}
                                className={getSortClass('selmo_clients.name')}
                            >
							<span name="selmo_clients.name">
								{getLangText('clientLabel')}
							</span>
                            </th>
                            {data.has_sizes === '1' &&
                                <th
                                    name="sizes_size"
                                    onClick={updateSortOptions}
                                    className={getSortClass('sizes_size')}
                                >
                                    <span name="sizes_size">
                                        {getLangText('variantLabel')}
                                    </span>
                                </th>
                            }
                            <th
                                name="products.price"
                                onClick={updateSortOptions}
                                className={getSortClass('products.price')}
                            >
							<span name="products.price">
								{getLangText('priceLabel')}
							</span>
                            </th>
                            <th
                                name="quantity"
                                onClick={updateSortOptions}
                                className={getSortClass('quantity')}
                            >
							<span name="quantity">
								{getLangText('quantityLabel')}
							</span>
                            </th>
                            <th>
                                {getLangText('sourceLabel')}
                            </th>
                            <th
                                name="date"
                                onClick={updateSortOptions}
                                className={`text-right ${getSortClass('date')}`}
                            >
							<span name="date">
								{getLangText('dateLabel')}
							</span>
                            </th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {items.map((item) => (
                            <tr
                                className="not-clicked-tr"
                                key={item.id}
                            >
                                <td className="with-photo small-client full-radius w-lg-auto w-100">
                                    <div className="d-flex align-items-lg-center mt-sm-0 mt-2">
                                        <ClientAvatar
                                            className="d-lg-block d-none"
                                            photo={item.client_avatar}
                                            type={item.social_type}
                                        />
                                        <div>
                                            <div className="product-name">
                                                {item.client_name || getLangText('noNameLabel')}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                {data.has_sizes === '1' &&
                                    <td className="small-font-size w-lg-auto w-100 left-mobile-margin order-lg-0 order-2">
                                        {item.size_name ?
                                            <div className="status-bg grey variant">
                                                {item.size_name}
                                            </div> :
                                            <div className="d-none d-lg-block">{getLangText('lackLabel')}</div>
                                        }
                                    </td>
                                }
                                <td className="d-lg-table-cell d-none">
                                    {getPrice(!!+item.size_price ? item.size_price : item.price, item.currency_text)}
                                </td>
                                <td className="d-lg-table-cell d-none">
                                    {item.quantity}
                                </td>
                                <td className={`d-lg-table-cell d-flex text-center ${item.type ? '' : 'p-0'}`}>
                                    <ProductTypeIcon product={item}/>
                                </td>
                                <td
                                    data-thead={`${getLangText('addedLabel')}:`}
                                    className="text-sm-right date w-lg-auto w-100 order-lg-0 order-2 with-mobile-thead inline-thead mb-lg-0 mb-2 text-nowrap"
                                >
                                    <OverlayTrigger
                                        placement='top'
                                        overlay={
                                            <Tooltip
                                                className="small-size"
                                                id="time"
                                            >
                                                {convertToUserTimeZone(item.date).format('DD.MM.YYYY HH:mm')}
                                            </Tooltip>
                                        }
                                    >
                                        <TimeAgo
                                            datetime={convertToUserTimeZone(item.date).format('YYYY-MM-DD HH:mm')}
                                            locale={lang}
                                        />
                                    </OverlayTrigger>
                                </td>
                                <td className="text-right absolute-dropdown-dots">
                                    <CustomDropdown
                                        icon="icon-dots"
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <Link
                                            to={`${RouterPaths.OrdersList}/${item.shop_order_id}`}
                                            className="custom-option"
                                        >
                                            {getLangText('goToOrderButton')}
                                        </Link>
                                        <Link
                                            to={`${RouterPaths.ClientsList}/${item.shop_client_id}`}
                                            className="custom-option"
                                        >
                                            {getLangText('goToClientButton')}
                                        </Link>
                                    </CustomDropdown>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </EmptyList>
    );
};

export default Table;
