import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import React from "react";
import {checkRoles} from "./PermissionRoute";

const UserPermissionsWrapper = ({ children, allowedPermissions, NoPermissionComponent }) => {
	const {user_permissions, main_account_id} = useSelector((state) => state.session.userData);

	const permissionsMatched = checkRoles(user_permissions, allowedPermissions);

	if (!!+main_account_id && !permissionsMatched) {
		return NoPermissionComponent && <NoPermissionComponent />;
	}

	return children;
};

UserPermissionsWrapper.propTypes = {
	allowedPermissions: PropTypes.arrayOf(
		PropTypes.string,
	).isRequired,
	children: PropTypes.node,
};

UserPermissionsWrapper.defaultProps = {
	children: null,
	NoPermissionComponent: null,
};

export default UserPermissionsWrapper;
