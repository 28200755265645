import {batch} from "react-redux";
import axios from "axios";
import {selmoUrl} from "../../../../src_shared/api/api.js";
import {services} from "../../../RestServices.js";
import LangsActions from "../langs/LangsActions";
import {RouterPaths} from "../../../routes/RouterPath";
import {removeFromLS, saveToLS} from "../helpers/ls";
import api from "../../../../services/axios/axios";
import Cookies from 'js-cookie'
import {updateNotReadConversations} from "../aside/AsideActions";

class SessionActions {

    sessionChecked() {
        return {
            type: 'SESSION_CHECKED',
        };
    }

    sessionUnChecked() {
        return {
            type: 'SESSION_UNCHECKED',
        };
    }

    setFirstInit() {
        return {
            type: 'SESSION_SET_FIRST_INIT',
        };
    }

    startWaiting() {
        return {
            type: 'SESSION_START_WAITING',
        };
    }

    stopWaiting() {
        return {
            type: 'SESSION_STOP_WAITING',
        };
    }

    setLogged(toggle) {
        return {
            type: 'SESSION_LOGGED',
            toggle,
        };
    }

    setToken(token) {
        return {
            type: 'SESSION_SET_TOKEN',
            token
        };
    }

    setMaintenancePage(toggle) {
        return {
            type: 'SESSION_SET_MAINTENANCE_PAGE',
            toggle
        };
    }

    setErrorPage(toggle) {
        return {
            type: 'SESSION_SET_ERROR_PAGE',
            toggle
        };
    }

    setAndSaveInStorageToken(token) {
        return (dispatch) => {
            dispatch(this.setToken(token))
            Cookies.set('token', token, { secure: process.env.REACT_APP_COOKIES_SECURE, expires: 365})
        }
    }

    login(userData) {
        return {
            type: 'SESSION_LOGIN',
            userData,
        };
    }

    logout() {
        return (dispatch) => {
            batch(() => {
                dispatch(this.setUserNotLoggedIn())
                Cookies.remove('token')
            })
        }
    }

    setUserNotLoggedIn() {
        return {
            type: 'SESSION_LOGOUT',
        }
    }

    setTutorial(toggle) {
        return {
            type: 'SESSION_TUTORIAL',
            toggle,
        };
    }

    updateTutorialBoxVisibility(field, value) {
        return {
            type: 'SESSION_UPDATE_TUTORIAL_BOX_VISIBILITY',
            field,
            value,
        };
    }

    updateUserData(field, value) {
        return {
            type: 'SESSION_UPDATE_USER_DATA',
            field,
            value,
        };
    }

    updateGlobalAlert(object) {
        return {
            type: 'SESSION_UPDATE_GLOBAL_ALERT',
            object,
        };
    }

    uncheckedAndCheckSession() {
        return (dispatch) => {
            dispatch(this.sessionUnChecked());
            dispatch(this.checkSession());
        }
    }

    checkSession(history) {
        return async (dispatch, getState) => {
            const {checked} = getState().session;

            if (checked) return;

            dispatch(this.startWaiting());
            const lang = dispatch(LangsActions.getLang());

            try {
                const {data} = await api.get(`${selmoUrl}/${services.API_USER_SESSION}?lang=${lang}`);
                batch(() => {
                    dispatch(this.login(data.item))
                    dispatch(updateNotReadConversations(data.item.not_read_conversations));
                    dispatch(this.sessionChecked());
                    dispatch(this.stopWaiting())
                })

            } catch (e) {
                if (e.response?.data?.message === 'User not logged in') {
                    dispatch(this.logout())
                    return
                }
                if (e?.response?.data?.message === 'TUTORIAL') {
                    history.push(RouterPaths.Tutorial)
                }
            } finally {
                dispatch(this.stopWaiting())
            }
        };
    }
}

export const getInstance = () => new SessionActions();

export default getInstance();
