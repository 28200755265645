export const ORDER_PRODUCTS_PAID_FILTER_ALL = 'all';
export const ORDER_PRODUCTS_PAID_FILTER = '1';
export const ORDER_PRODUCTS_UNPAID_FILTER = '0';


export const ORDER_PRODUCTS_PAID_STATUES = [
	{
		value: ORDER_PRODUCTS_PAID_FILTER_ALL, label: 'allLabel', color: 'waiting',
	},
	{
		value: ORDER_PRODUCTS_PAID_FILTER, label: 'paidLabel', color: 'waiting',
	},
	{
		value: ORDER_PRODUCTS_UNPAID_FILTER, label: 'statusNotPaidLabel', color: 'waiting',
	},
]

export const findPaidStatusById = (id) => ORDER_PRODUCTS_PAID_STATUES.find((status) => status.value === id);
