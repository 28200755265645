import React, {useRef} from 'react';
import {
    getSummaryText,
    setConfirmModalToggleVisibility,
    setMoreActionsClientToggleVisibility
} from "../../../LiveDetailsActions.js";
import {CONFIRM_MODAL_TYPES} from "../../confirmModals/ConfirmModalTypes.js";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../../../../routes/RouterPath.js";
import {Dropdown} from "react-bootstrap";
import useOutsideClick from "../../../../../../messenger/hooks/useOutsideClick/useOutsideClick.js";
import {useDispatch, useSelector} from "react-redux";
import useDataApi from "../../../../../../../../src_shared/hooks/useDataApi.js";
import {services} from "../../../../../../../RestServices.js";
import useLang from "../../../../../../../../src_shared/hooks/useLang";

const MoreActionsDropdown = ({isOrderCreated, client}) => {
    const dropdownRef = useRef(null);
    const dispatch = useDispatch();
    const {getLangText} = useLang();
    const {liveInfo} = useSelector((state) => state.lives.details)

    useOutsideClick(dropdownRef, () => {
        if (dropdownRef) {
            dispatch(setMoreActionsClientToggleVisibility(null))
        }
    });

    const onOptionClick = (callback) => {
        dispatch(setMoreActionsClientToggleVisibility(null))
        callback();
    }

    const onBasketStateActionClick = (type, rest) => {
        dispatch(setConfirmModalToggleVisibility(type, client))
        dispatch(getSummaryText(rest))
    }

    const [{data}] = useDataApi(`${services.API_LIVE_CREATED_ORDER_INFO}/${client.order_created}`, {
        id: null,
    }, [], !!+client.order_created)

    return (
        <Dropdown.Menu ref={dropdownRef}>
            {(!isOrderCreated && !!+liveInfo.single_order_enabled) &&
                <button
                    onClick={() => onOptionClick(() => dispatch(setConfirmModalToggleVisibility(CONFIRM_MODAL_TYPES.CREATE_ORDER, client)))}
                    className="custom-option"
                >
                    {getLangText('createOrderButton')}
                </button>
            }
            {isOrderCreated && client.facebook_id
                &&
                (
                    <>
                        {!!+data.id &&
                            <Link
                                className="custom-option"
                                to={`${RouterPaths.OrdersList}/${data.id}`}
                                target="_blank"
                            >
                                {getLangText('goToOrderButton')}
                            </Link>
                        }
                        {client.cart_status_sent !== '1' &&
                            <button
                                className="custom-option"
                                onClick={() => onOptionClick(() => onBasketStateActionClick(CONFIRM_MODAL_TYPES.BASKET_STATE, 'cartText'))}
                            >
                                {getLangText('sendBasketStateButton')}
                            </button>
                        }
                        {client.summary_sent !== '1' &&

                            <button
                                className="custom-option"
                                onClick={() => onOptionClick(() => onBasketStateActionClick(CONFIRM_MODAL_TYPES.SUMMARY, 'summaryText'))}
                            >
                                {getLangText('sendSummaryButton')}
                            </button>
                        }
                    </>
                )}
            <button
                className="custom-option"
                onClick={() => onOptionClick(() => dispatch(setConfirmModalToggleVisibility(CONFIRM_MODAL_TYPES.REMOVE_CLIENT, client)))}
            >
                {getLangText('clientsRemoveClientTitle')}
            </button>
        </Dropdown.Menu>
    );
};

export default MoreActionsDropdown;
