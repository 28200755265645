export const showAlert = (title = 'yuhuLabel', desc = 'Pomyślnie zapisano zmiany', className = 'alert-success',) => {
	return {
		type: 'SHARED_ACTIONS_SHOW_ALERT',
		title,
		desc,
		className
	}
}

export const hideAlert = () => {
	return {
		type: 'SHARED_ACTIONS_HIDE_ALERT'
	}
}
