import React from 'react';
import ServerImage from "../../../../../../../../../shared/components/serverImage/ServerImage";
import {getStatusBox} from "../../../../../../../../../live/details/platform/notShownList/components/table/Table";
import {useUnitPrice} from "../../../../../../../../../shared/helpers/Price";
import useLang from "../../../../../../../../../../../src_shared/hooks/useLang";

const Product = ({item, setActiveProduct, activeProduct}) => {
    const {getPrice} = useUnitPrice();
    const {getLangText} = useLang();

    return (
        <button
            type="button"
            onClick={() => setActiveProduct(item)}
            key={item.id}
            className={`content cursor-pointer payment-content d-block w-full text-left hover:bg-gray-50 transition-all ${+activeProduct?.id === +item.id ? 'active' : ''}`}
        >
            <div className="content__top-part">
                <div className="flex-grow-1 d-flex align-items-center">
                    <figure className="product-avatar">
                        {item.photo_small && item.photo_small !== '0' ?
                            <ServerImage src={item.photo_small}/> :
                            <img src="/assets/images/default/product.svg"/>
                        }
                    </figure>
                    <div>
                        <div className="label">
                            {item.name}
                        </div>
                        <div className="data">
                            {getPrice(item.price)}{' • '}
                            {getLangText('codeLabel')}&nbsp;
                            <strong>{item.selmo_code}</strong>
                        </div>
                    </div>
                </div>
                {getStatusBox(item.squ, getLangText)}
            </div>
        </button>
    );
};

export default Product;
