import React from 'react'

class PageLink extends React.Component {
	changePage = () => {
		const {page, onClick} = this.props;

		onClick(page);
	}

	render() {
		const {page} = this.props;

		return (
			<button onClick={this.changePage} type="button">
				{page.number}
			</button>
		);
	}
}

export default PageLink;
