import Model, {convertObjectProperties} from "../../../../src_shared/modules/model/Model";

export class ClientInfoModel extends Model {
	getModel() {
		return {
			id: 0,
			payment_received_logs: [],
			shippingData: {},
			currency: {},
		};
	}

	parseDTO(data) {
		return convertObjectProperties(
			data,
			{},
			{
				shippingData: {},
				payment_received_logs: [],
				currency: {},
			},
		);
	}
}

export default new ClientInfoModel();
