import {prefix} from "./formReducer";
import {getStateRoot} from "./formReducer";
import FormActions from "../../../../../src_shared/form/FormActions";
import {services} from "../../../../RestServices";
import MobileReelsModel from "../../../../../modules/models/reels/MobileReelsModel";

export class ProductsMobileReelsFormActions extends FormActions {
    updateVideoStatus = (pusherData) => {
        return (dispatch, getState) => {
            const {data} = getStateRoot(getState());
            if (+data.id === +pusherData.id) {
                dispatch(this.data.updateValues({
                    is_being_transmitted: false,
                    attachment_name: pusherData.attachment_name,
                    attachment_path: pusherData.attachment_path,
                }))
            }
        }
    }
}

export const getInstance = () => new ProductsMobileReelsFormActions({
    prefix,
    getStateRoot,
    model: MobileReelsModel,
    restService: services.API_STORAGE_REELS,
});

export default getInstance();

