import React from 'react';
import SelectSizesModalActions from "../../../shared/components/modal/SelectSizesModalActions";
import {useDispatch} from "react-redux";
import useLang from "../../../../../src_shared/hooks/useLang";
import ServerImage from "../../../shared/components/serverImage/ServerImage";

export const getProductCount = (product, getLangText) => {
	if (product.sizes?.length) {
		return `${getLangText('variantsLabel')}: ${product.sizes.length}`
	}
	if (+product.squ) {
		return `${getLangText('piecesGenitiveLabel')}: ${product.squ}`
	}
	return getLangText('outOfStockLabel');
}

export const isOutOfStock = (product) => !product.sizes.length && !+product.squ;

const HintDropdown = ({
						  hintsVisible,
						  setHintsVisible,
						  productHints,
						  updateFieldsInProduct,
						  id,
						  tabIndex,
						  onKeyDown
					  }) => {

	const {getLangText} = useLang();
	const dispatch = useDispatch();

	const showModal = (product) => dispatch(SelectSizesModalActions.toggleModalVisibility(true, product, id))


	const onHintClick = (product) => {
		if (isOutOfStock(product)) {
			return;
		}
		updateFieldsInProduct({
			product_name: product.name
		})
		setHintsVisible(false)
		if (product.has_sizes === '1') {
			showModal(product)
			return;
		}
		const newProductFields = {
			product_price: +product?.price,
			product_code: product?.selmo_code,
			product_photo: product?.photo_small,
			product_id: product?.id,
			product_max_count: product?.squ
		}
		updateFieldsInProduct(newProductFields);
	}

	const onKeyPress = (e, product) => {
		if (e.code === 'Enter') {
			onHintClick(product)
		}
	}

	return (
		hintsVisible === id &&
		productHints.map((product) => (
			<div
				onKeyPress={(e) => onKeyPress(e, product)}
				onKeyDown={onKeyDown}
				tabIndex={tabIndex}
				className={`select-custom-option with-img ${isOutOfStock(product) ? 'disabled-bg' : ''}`}
				key={id}
			>
				<div
					className="d-flex align-items-center"
					onClick={() => onHintClick(product)}
				>
					<figure className="user-avatar">
						{product.photo_small && product.photo_small !== '0' ?
							<ServerImage src={product.photo_small}/> :
							<img src="/assets/images/default/product.svg"/>
						}
					</figure>
					<div className="name">
						{product.name}
						<div className="client-number">
							{getProductCount(product, getLangText)}
							{` • `}
							{getLangText('codeLabel')}: {product.selmo_code}
						</div>
					</div>
				</div>
			</div>
		))
	);
};

export default HintDropdown;
