import React, {useEffect, useState} from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import {RouterPaths} from "../RouterPath";
import {SELMO_APP_PERMISSION, SHOP_PERMISSION} from "../../app/shared/enums/SelmoPermissions";
import PermissionRoute from "../PermissionRoute";
import ShopMainPage from "../../app/shopSettings/mainPage/Form";
import ShopGeneral from "../../app/shopSettings/general/Form";
import Regulation from "../../app/shopSettings/regulation/regulation/Regulation";
import Privacy from "../../app/shopSettings/regulation/privacy/Privacy";
import ShopSettingsMessages from "../../app/shopSettings/promotions/messages/Form";
import ShopSettingsNewsletter from "../../app/shopSettings/promotions/newsletter/List";
import ShopSettingsPage from "../../app/shopSettings/pages/form/Form";
import ShopPagesList from "../../app/shopSettings/pages/list/List";
import NoShopPermissionComponent from "./NoShopPermissionComponent";
import axios from "axios";
import {selmoUrl} from "../../../src_shared/api/api";
import {services} from "../../RestServices";
import ShopDomain from "../../app/shopSettings/domain/Form";
import ShopSettingsIntegrationsWrapper from "../../app/shopSettings/integrations/Wrapper";
import PixelIntegrations from "../../app/shopSettings/integrations/pixel/PixelIntegration";
import GoogleIntegrations from "../../app/shopSettings/integrations/google/GoogleIntegration";
import {saveToLS, getFromLS} from "../../../src_shared/helpers/LS";
import moment from "../../app/shared/moment";
import Confetti from "react-confetti";
import useWindowDimensions from "../../app/messenger/hooks/useWindowDimensions/useWindowDimensions";
import {useSelector} from "react-redux";
import NoPermissionPage from "../../app/shared/NoPermissionPage";
import NoShopPermissionComponentAfterTrial from "./NoShopPermissionComponentAfterTrial";
import ShopDesktop from "../../app/shopSettings/desktop/Form";
import Layout from "../../app/Layout";
import api from "../../../services/axios/axios";
import {convertToUserTimeZone} from "../../app/shared/helpers/dateHelpers";
import TiktokIntegrations from "../../app/shopSettings/integrations/tiktok/TikTokIntegration";

export const ShopSettingsContext = React.createContext({
	shopData: {},
	getShopData: () => {
	},
	setShopData: (values) => {
	},
	isChanging: false,
	setIsChanging: () => {
	}
});

const ShopSettingsRoute = ({path}) => {

	const [shopData, setShopData] = useState({});
	const [isChanging, setIsChanging] = useState(null);
	const [confettiVisible, setConfettiVisible] = useState(false);
	const minutesToWait = 3;
	const difference = convertToUserTimeZone(getFromLS('changeShouldBeReady')).diff(convertToUserTimeZone().toDate(), 'seconds');
	const {userData} = useSelector((state) => state.session);
	const {width, height} = useWindowDimensions()

	const getShopData = async () => {
		try {
			const {data} = await api.get(`${selmoUrl}/${services.API_STORE_GENERAL}`)
			setShopData(data.item);
		} catch (e) {
			console.warn('Cannot get shop data')
		}
	}

	const startChanging = (date) => {
		setIsChanging(date)
		saveToLS('changeShouldBeReady', convertToUserTimeZone(date).add(minutesToWait, 'm').toDate());
	}

	useEffect(() => {

		if (getFromLS('changeShouldBeReady') && (convertToUserTimeZone(getFromLS('changeShouldBeReady')).toDate() > convertToUserTimeZone().toDate())) {
			setIsChanging(getFromLS('changeShouldBeReady'))
		}

		if (convertToUserTimeZone(getFromLS('changeShouldBeReady')).toDate() < convertToUserTimeZone().toDate()) {
			setIsChanging(null)
		}

		const intervalId = setTimeout(() => {
			setIsChanging(null);
		}, difference * 1000);
		return () => {
			clearInterval(intervalId);
		};
	}, [isChanging]);

	useEffect(() => {
		getShopData();
	}, [])

	return (
		<PermissionRoute
			noPermissionComponent={() => userData?.user_info?.after_store_trial ?
				<NoShopPermissionComponentAfterTrial/> :
				<NoShopPermissionComponent setConfettiVisible={setConfettiVisible}/>}
			allowedPermissions={[SHOP_PERMISSION]}
		>
			<Route path={path}>
				<Confetti
					style={{width: '100vw', height: '100vh', position: 'fixed', left: 0, top: 0}}
					run={confettiVisible}
					width={width}
					height={height}
					recycle={false}
					numberOfPieces={400}
				/>
				<ShopSettingsContext.Provider
					value={{shopData, getShopData, setShopData, isChanging, setIsChanging: startChanging}}>
					<Switch>
						<Route
							exact
							path={RouterPaths.ShopDesktop}
							component={ShopDesktop}
						/>
						<Route
							exact
							path={RouterPaths.ShopGeneral}
							component={ShopGeneral}
						/>
						<Route
							exact
							path={RouterPaths.ShopMainPage}
							component={ShopMainPage}
						/>
						<Route
							exact
							path={RouterPaths.ShopDomain}
							component={ShopDomain}
						/>
						<Route
							exact
							path={RouterPaths.ShopPromotionsMessages}
							component={ShopSettingsMessages}
						/>
						<Route
							exact
							path={RouterPaths.ShopPromotionsNewsletter}
							component={ShopSettingsNewsletter}
						/>
						<Route path={RouterPaths.ShopPages}>
							<Switch>
								<Route
									exact
									path={RouterPaths.ShopPages}
									component={ShopPagesList}
								/>
								<Route
									exact
									path={RouterPaths.ShopPage}
									component={ShopSettingsPage}
								/>
								<Redirect to={RouterPaths.ShopPages}/>
							</Switch>
						</Route>
						<Route
							exact
							path={RouterPaths.ShopRegulation}
							component={Regulation}
						/>

						<Route
							exact
							path={RouterPaths.ShopPrivacy}
							component={Privacy}
						/>
						<Route path={RouterPaths.ShopIntegrations}>
							<ShopSettingsIntegrationsWrapper>
								<Switch>
									<Route
										exact
										path={RouterPaths.ShopIntegrationsPixel}
										component={PixelIntegrations}
									/>
									<Route
										exact
										path={RouterPaths.ShopIntegrationsGoogle}
										component={GoogleIntegrations}
									/>
									<Route
										exact
										path={RouterPaths.ShopIntegrationsTiktok}
										component={TiktokIntegrations}
									/>
									<Redirect to={RouterPaths.ShopIntegrationsPixel}/>
								</Switch>
							</ShopSettingsIntegrationsWrapper>
						</Route>
						<Redirect to={RouterPaths.ShopDesktop}/>
					</Switch>
				</ShopSettingsContext.Provider>
			</Route>
		</PermissionRoute>
	);
};

export default ShopSettingsRoute;
