import React, {useContext} from 'react';
import useLang from "../../../../../src_shared/hooks/useLang";
import useSubmitData from "../../../../../src_shared/hooks/useSubmitData";
import {services} from "../../../../RestServices";
import useFormValues from "../../../../../src_shared/hooks/useFormValues";
import Loader from "../../../shared/Loader";
import useDataApi from "../../../../../src_shared/hooks/useDataApi";
import {showAlert} from "../../../shared/components/alert/AlertActions";
import {useDispatch, useSelector} from "react-redux";
import Field from "../../../../../src_shared/components/form/field/Field";
import {ShopSettingsContext} from "../../../../routes/shopSettingsRoute/ShopSettingsRoute";

const GoogleIntegrations = () => {
	const {getLangText} = useLang();
	const dispatch = useDispatch();
	const {setIsChanging} = useContext(ShopSettingsContext);
	const {lang} = useSelector((state) => state.i18n);

	const [{fetchData, isLoading, data}] = useDataApi(services.API_STORE_INTEGRATIONS, {
		integration_google_analytics: '',
	});

	const [{values, setValue}] = useFormValues(data);

	const [{onSave, isWaiting}] = useSubmitData( {
		rest: services.API_STORE_INTEGRATIONS,
		values,
		afterSubmit: () => {
			dispatch(showAlert())
			fetchData();
			setIsChanging(new Date())
		},
	});

	return (
		<div className="loader-parent">
			<Loader isLoading={isLoading}/>
			<div className="section-header">
				<h1>{getLangText('googleAnalyticsLabel')}</h1>
				<div className="desc">{getLangText('googleAnalyticsLabelDescLabel')}</div>
			</div>
			<div className="steps-wrapper">
				<div className="step">
					<div className="step-header flex-wrap">
						<div className="d-flex">
							<div className="number">1</div>
							<div className="mb-3">
								<div className="title d-inline" dangerouslySetInnerHTML={{__html: getLangText('goToGoogleAndCopyLabel')}} />
								<div className="sub-title">{getLangText('idWillBeAvailableHereLabel')}
									<a target="_blank" href="https://tagmanager.google.com/#/home/">https://tagmanager.google.com/#/home/</a>
								</div>
							</div>
						</div>
					</div>
					<img className="w-100" src={`/assets/images/multiLanguage/${lang}/google-tag.webp`} alt=""/>
				</div>
				<div className="step mt-4">
					<div className="step-header flex-wrap">
						<div className="d-flex align-items-center">
							<div className="number">2</div>
							<div className="title font-weight-bold">
								{getLangText('pasteGoogleIdHereLabel')}
							</div>
						</div>
					</div>
					<div
						style={{maxWidth: '550px'}}
						className="d-flex align-items-end mt-3">
						<Field
							className="flex-grow-1 mb-0"
							name="integration_google_analytics"
							value={values.integration_google_analytics}
							setValue={setValue}
							placeholder={getLangText('pasteGoogleCodeLabel')}
							label={getLangText('googleAnalyticsIdLabel')}
						/>
						<button
							onClick={onSave}
							type="button"
							style={{paddingTop: '15px', paddingBottom: '15px'}}
							className={`button ml-2 primary ${isWaiting ? 'loading-button' : ''}`}
						>
							{isWaiting &&
								<Loader isLoading/>
							}
							{getLangText('saveButton')}
						</button>
					</div>
				</div>
			</div>


		</div>
	);
};

export default GoogleIntegrations;
