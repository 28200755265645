import React from 'react';
import Loader from "../../../../components/app/shared/Loader";
import useLang from "../../../hooks/useLang";

const Button = ({
                    type = 'button',
                    isWaiting = false,
                    title = 'saveButton',
                    onClick = () => {
                    },
                    className = '',
                    disabled = false,
                    Icon = null,
                    getLangText,
                }) => {


    const t = useLang();

    const getT = getLangText || t.getLangText

    return (
        <button
            type={type}
            className={`${className} ${isWaiting ? 'loading-button' : ''}`}
            onClick={onClick}
            disabled={disabled}
        >
            {isWaiting && <Loader isLoading/>}
            {Icon && Icon}
            {getT(title)}
        </button>
    );
};

export default Button;
