import React from 'react';
import FiltersDropdown from "../../../../shared/components/filters/FiltersDropdown";
import useStatusFilters from "../../../../orders/list/components/statusFilter/useStatusFilters";
import useLang from "../../../../../../src_shared/hooks/useLang";

const VariantFilter = ({ListActions, FiltersActions, getFiltersStateRoot, className, values, extraProps}) => {
	const {getLangText} = useLang();

	const {
		statuses,
		selectAll,
		isSelectAll,
		saveFilters,
		handleCheckBoxClick,
		allItemsClick,
		updateLocallyStatusesState,
		getCheckboxValue,
	} = useStatusFilters(
		ListActions,
		getFiltersStateRoot,
		FiltersActions,
		'variants',
		'variantsSelectAll',
		values.length
	);

	const prepareVariants = values.map((i) => ({
		value: i.id,
		label: i.sizeName,
	}))

	const filterButtonTitle = () => {
		if (selectAll) {
			return {
				text: getLangText('variantLabel'),
				className: '',
			};
		}
		return {
			text: `${getLangText('filtersLabel')} <div class="badge"><span>${statuses.length}</span></div>`,
			className: 'filtered',
		};
	};

	return (
		<FiltersDropdown
			onClose={() => updateLocallyStatusesState(statuses, selectAll)}
			onSave={() => saveFilters(extraProps.id)}
			buttonTittle={filterButtonTitle()}
			onSecondButtonClick={() => updateLocallyStatusesState([], true)}
			className={className}
		>
			<div className="checkbox-filter-wrapper">
				<div className="form-group checkbox-form">
					<input
						className="checkbox-input form-control"
						type="checkbox"
						onChange={allItemsClick}
						checked={isSelectAll}
					/>
					<span className="checkbox-checkmark"/>
					<label className="control-label">{getLangText('allLabel')}</label>
				</div>
				{prepareVariants.map((status) => (
					<div
						key={status.value}
						className="form-group checkbox-form"
					>
						<input
							className="checkbox-input form-control"
							type="checkbox"
							onChange={() => handleCheckBoxClick(status.value)}
							checked={getCheckboxValue(status.value)}
						/>
						<span className="checkbox-checkmark"/>
						<label className="control-label">{getLangText(status.label) || status.label}</label>
					</div>
				))}
			</div>
		</FiltersDropdown>
	);
};

VariantFilter.defaultProps = {
	className: '',
	extraProps: {},
	values: [],
}

export default VariantFilter;
