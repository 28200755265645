import Model, {convertObjectProperties} from "../../../src_shared/modules/model/Model";
class OverlayModel extends Model {
    getModel() {
        return {
            active_product_info: {},
        };
    }
    parseDTO(data) {
        return convertObjectProperties(
            data,
            {
                // active_product_info
            },
            {
                active_product_info: {},
            },
        );
    }
}

export default new OverlayModel();
