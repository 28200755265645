import {getStateRoot, prefix} from "./formReducer.js";
import {services} from "../../../../RestServices.js";
import FormActions from "../../../../../src_shared/form/FormActions";
import {RouterPaths} from "../../../../routes/RouterPath";
import ModalPageModel from "../../../../../modules/models/shopSettings/ModalPageModel";
import {showAlert} from "../../../shared/components/alert/AlertActions";
import axios from "axios";
import {selmoUrl} from "../../../../../src_shared/api/api";
import api from "../../../../../services/axios/axios";

export class ModalPageFormFormActions extends FormActions {
	submitEditForm(values, history, afterSubmit) {
		return async (dispatch) => {

			dispatch(this.validation.activateValidation());

			if (!this.dataModel.validateData(values)) {
				dispatch(showAlert('fillRequireFieldWarning', '', 'alert-fail'))
				console.error('Validation failed!');
				return;
			}

			const filteredData = Object.entries(values).filter(([key]) => key !== 'id');
			const finalData = Object.fromEntries(filteredData)

			const preparedData = this.dataModel.buildDTO(finalData)

			dispatch(this.waiting.startWaiting());

			try {
				const {data} = await api.post(`${selmoUrl}/${this.restService}`, preparedData)
				this.updateRoute(data, history)
				dispatch(showAlert('yuhuLabel', 'changesWillBeVisibleInFewMinutesLabel'))
				dispatch(this.validation.deactivateValidation())
				afterSubmit()
			} catch (e) {
				if (e.response.data.message === 'URL_ALREADY_EXIST') {
					dispatch(showAlert('URL_ALREADY_EXIST', '', 'alert-fail'))
					return;
				}
				dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
				console.error('Error submitEditForm')
			} finally {
				dispatch(this.waiting.stopWaiting());
			}
		}
	}
}

export const getInstance = () => new ModalPageFormFormActions({
	getStateRoot,
	prefix,
	model: ModalPageModel,
	restService: services.API_STORE_PAGES,
	route: RouterPaths.ShopPages
});

export default getInstance();
