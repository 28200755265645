export const CUSTOMER_BASKET_DELIVERY_ADDRESS_VIEW = 'CUSTOMER_BASKET_DELIVERY_ADDRESS_VIEW';
export const CUSTOMER_BASKET_DELIVERY_METHODS_VIEW = 'CUSTOMER_BASKET_DELIVERY_METHODS_VIEW';
export const CUSTOMER_BASKET_DELIVERY_STATUS_DETAILS_VIEW = 'CUSTOMER_BASKET_DELIVERY_STATUS_DETAILS_VIEW';
export const CUSTOMER_BASKET_PAYMENT_IN_PROGRESS_VIEW = 'CUSTOMER_BASKET_PAYMENT_IN_PROGRESS_VIEW';
export const CUSTOMER_BASKET_DELIVERY_TRANSFER_VIEW = 'CUSTOMER_BASKET_DELIVERY_TRANSFER_VIEW';

const CustomerBasketViews = [
	{
		id: CUSTOMER_BASKET_DELIVERY_ADDRESS_VIEW,
		label: 'customerBasketInformationLabel',
	},
	{
		id: CUSTOMER_BASKET_DELIVERY_METHODS_VIEW,
		label: 'customerBasketDeliveryLabel',
	},
];

export default CustomerBasketViews;
