import React from 'react';
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {RouterPaths} from "../../components/routes/RouterPath";

const useRedirectToSelmoPro = () => {

    const {userData} = useSelector((state) => state.session);

    const checkSelmoPro = (callback) => {
        if (!!+userData.selmo_pro_active) {
            callback()
        } else {
            window.open(RouterPaths.SelmoPro);
        }
    }

    return {
        checkSelmoPro
    }
};

export default useRedirectToSelmoPro;
