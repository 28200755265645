import React, {useEffect, useMemo, useState} from 'react';
import useLang from "../../../src_shared/hooks/useLang";
import {useSelector} from "react-redux";
import useFetchData from "../../../src_shared/hooks/useFetchData";
import {services} from "../../RestServices";
import ComponentLoader from "../../../src_shared/components/ui/componentLoader/ComponentLoader";
import InvoiceDataModal from "./components/InvoiceDataModal";
import {useHistory, useLocation} from "react-router-dom";
import {useIntercom} from "react-use-intercom";
import {convertToUserTimeZone} from "../shared/helpers/dateHelpers";
import moment from "moment/moment";
import {useCountdown} from "../../../src_shared/hooks/useCountDown";

const whyWorthItOptions = [
    {
        id: 0,
        title: 'summerPromoBenefit1v2',
        desc: 'vacationBenefitsSubLabel1',
    },
    {
        id: 1,
        title: 'summerPromoBenefit2v2',
    },
    {
        id: 2,
        title: 'summerPromoBenefit3',
    },
]

const fullYearPrices = [
    {id: 'pln', price: '1495', shortcut: 'zł', oldPrice: '4186', single: '299', totalPrice: '2691'},
    {id: 'eur', price: '495', shortcut: '€', single: '99', oldPrice: '1386', totalPrice: '891'},
    {id: 'dollar', price: '495', shortcut: '$', single: '99', oldPrice: '1386', totalPrice: '891'},
    {id: 'dkk', price: '3495', shortcut: 'kr.', single: '699', oldPrice: '9786', totalPrice: '6291'},
]

const prices = [
    {id: 'pln', price: '598', shortcut: 'zł', oldPrice: '1794', single: '299', totalPrice: '1196'},
    {id: 'eur', price: '198', shortcut: '€', single: '99', oldPrice: '594', totalPrice: '396'},
    {id: 'dollar', price: '198', shortcut: '$', single: '99', oldPrice: '594', totalPrice: '396'},
    {id: 'dkk', price: '1398', shortcut: 'kr.', single: '699', oldPrice: '4194', totalPrice: '2796'},
]

export const getCorrectPriceCurrency = (locale, array = prices) => {
    const findPrice = (id) => array.find((i) => i.id === id);

    if (locale === 'pl') return findPrice('pln');
    if (locale === 'dk') return findPrice('dkk')
    if (locale === 'us') return findPrice('dollar');
    return findPrice('eur');
}


const Vacation = () => {

    const {origin} = useSelector((state) => state.session.userData);
    const price = useMemo(() => `${getCorrectPriceCurrency(origin).price} ${getCorrectPriceCurrency(origin).shortcut}`, [origin])
    const [showModal, setShowModal] = useState(false);
    const {getLangText} = useLang();
    const newDate = convertToUserTimeZone(moment('28.06.2024 12:00:00', 'DD.MM.YYYY HH:mm:ss').toDate()).toDate()
    const todayDate = convertToUserTimeZone(moment(new Date(), 'DD.MM.YYYY HH:mm:ss').toDate()).toDate()

    const expired = newDate <= todayDate

    const history = useHistory();
    const location = useLocation();
    const {show} = useIntercom();

    const defaultSrc = `https://www.youtube.com/embed/jT1-fsyT8G8?si=_V8P5Rt4AtuTKLRA&autoplay=0`
    const autoPlaySrc = `https://www.youtube.com/embed/jT1-fsyT8G8?si=_V8P5Rt4AtuTKLRA&autoplay=1&mute=1`

    const [src, setSrc] = useState(defaultSrc)

    const [{isLoading}] = useFetchData({
        rest: services.API_SUMMER_PROMO_LANDING,
    })

    useEffect(() => {
        const query = new URLSearchParams(location.search);

        if (!!+query.get('play')) {

            setSrc(autoPlaySrc)
            query.delete('play');

            history.replace({
                pathname: location.pathname,
                search: query.toString(),
            });
        }
    }, [location.search, history]);

    return (
        <div className="bg-[#fff] rounded-[5px] shadow-default sm:pt-12 pb-10 overflow-hidden">
            <img
                src="/assets/images/summer-banner-1.webp"
                className="sm:rounded-[8px] mx-auto max-w-[750px] w-full object-cover overflow-hidden"
                alt="summer promotion"
            />
            <div className="max-w-[475px] mx-auto sm:px-0 px-[16px]">
                <div className="flex items-cetner mt-4 mb-3">
                    <div className="text-[#5FA5FA] flex items-center font-bold">{getLangText('vacationInSelmo')}</div>
                    {expired &&
                        <div className="p-1 rounded-[5px] text-xs font-bold text-[#981B1B] ml-auto bg-[#FEF2F2]">
                            {getLangText('soldOut')}
                        </div>
                    }
                </div>
                <div
                    className="whitespace-pre-wrap big-text-vacation font-bold text-[26px] sm:text-[32px] leading-[38px] sm:leading-[46px]"
                    dangerouslySetInnerHTML={{
                        __html: getLangText({
                            key: 'payForFour',
                            data: [price]
                        })
                    }}
                />
                <div className="mb-3 mt-sm-5 mt-4">
                    <div className="font-bold text-sm">{getLangText('pricingWhyWorthyTitle')}</div>
                    <div className="space-y-4 mt-3 mb-4">
                        {whyWorthItOptions?.map((i) => (
                            <div
                                key={i.id}
                                className={`flex items-start`}
                            >
                                <div>
                                    <i className="icon-tick-c text-[18px] min-w-[18px] mr-2 text-[#5FA5FA]"/>
                                </div>
                                <div>
                                    <div
                                        className="text-[#101827] font-medium text-sm"
                                        dangerouslySetInnerHTML={{__html: getLangText({key: i.title, data: [price]})}}/>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="p-3 flex items-center flex-wrap rounded-[7px] mt-3 bg-[#F0FAFF]">
                    <div className="mr-2">
                        <i className="icon-info-c text-lg text-[#5FA5FA]"/>
                    </div>
                    <div className="font-bold text-sm text-[#101827]">{getLangText('haveAnyQuestions')}</div>
                    <button
                        type="button"
                        onClick={show}
                        className="ml-auto underline text-[#3C61EA] font-medium text-sm">{getLangText('writeToUs')}</button>
                </div>
                <div className="grid grid-cols-2 gap-4 mt-3">
                    {!expired ?
                        <div
                            style={{
                                boxShadow: '0 0 0 1px #3c61ea'
                            }}
                            className="bg-[#F7F8FE] rounded-[8px] border border-[#3C61EA] p-3">
                            <div className="font-bold text-sm text-[#3C61EA]">
                                {getLangText('halfYearPlan')}
                            </div>
                            <div className="font-bold text-[32px] mt-3 text-[#101827]">
                                {`${getCorrectPriceCurrency(origin).totalPrice} ${getCorrectPriceCurrency(origin).shortcut}`}
                            </div>
                        </div> :
                        <div className="rounded-[8px] border border-[#DFE1E4] p-3">
                            <div className="font-bold flex-wrap text-sm flex items-center text-[#B4B9C2]">
                                {getLangText('halfYearPlan')}
                                <div
                                    className="sm:ml-auto sm:w-auto w-full sm:mt-0 mt-1.5 p-1 rounded-[5px] text-xs font-bold text-[#981B1B] bg-[#FEF2F2]">
                                    {getLangText('soldOut')}
                                </div>
                            </div>
                            <div className="font-bold text-[32px] mt-3 text-[#B4B9C2]">
                                {`${getCorrectPriceCurrency(origin).totalPrice} ${getCorrectPriceCurrency(origin).shortcut}`}
                            </div>
                        </div>
                    }
                    <div className="rounded-[8px] border border-[#DFE1E4] p-3">
                        <div className="font-bold flex-wrap text-sm flex items-center text-[#B4B9C2]">
                            {getLangText('annualPlan')}
                            <div
                                className="sm:ml-auto sm:w-auto w-full sm:mt-0 mt-1.5 p-1 rounded-[5px] text-xs font-bold text-[#981B1B] bg-[#FEF2F2]">
                                {getLangText('soldOut')}
                            </div>
                        </div>
                        <div className="font-bold text-[32px] mt-3 text-[#B4B9C2]">
                            {`${getCorrectPriceCurrency(origin, fullYearPrices).totalPrice} ${getCorrectPriceCurrency(origin, fullYearPrices).shortcut}`}
                        </div>
                    </div>
                </div>
                <div className={`font-medium mb-3 mt-4 ${expired ? 'border p-3 rounded-[8px]' : ''}`}>
                    <div className={expired ? 'opacity-50' : ''}>
                        <div className="text-sm text-desc flex items-center border-b py-3">
                            <div>{getLangText('payingForFourMonths')}</div>
                            <div className="font-bold text-[#000] ml-auto">
                                4x{getCorrectPriceCurrency(origin).single}&nbsp;{getCorrectPriceCurrency(origin).shortcut}
                            </div>
                        </div>
                        <div className="text-sm text-desc flex items-center py-3 border-b">
                            <div>{getLangText('received')}</div>
                            <div className="font-bold text-[#000] ml-auto"
                                 dangerouslySetInnerHTML={{__html: getLangText('sixMonths')}}/>
                        </div>
                        <div className="text-sm text-desc flex items-center py-3">
                            <div>{getLangText('toPay')}</div>
                            <div className="font-bold text-[#000] ml-auto">
                            <span className="line-through font-medium text-desc mr-2">
                                {`${getCorrectPriceCurrency(origin).oldPrice} ${getCorrectPriceCurrency(origin).shortcut}`}
                            </span>
                                <span className="text-[16px]">
                                {`${getCorrectPriceCurrency(origin).totalPrice} ${getCorrectPriceCurrency(origin).shortcut}`}
                            </span>
                            </div>
                        </div>
                    </div>
                    {expired &&
                        <div className="form-info-box border border-red-700 align-items-start red w-100 mb-2">
                            <div>
                                <i className="icon-cross-c"/>
                            </div>
                            <div>
                                <div className="title">
                                    {getLangText('notAvailable')}
                                </div>
                                <div className="desc">{getLangText('allPackagesAreSoldOut')}</div>
                            </div>
                        </div>
                    }
                </div>
                {!expired &&
                    (isLoading ?
                        <ComponentLoader style={{minHeight: '200px'}}/> :
                        <div>
                            <button
                                type="button"
                                onClick={() => setShowModal(true)}
                                style={{fontSize: '16px', borderRadius: 8}}
                                className="button primary w-full medium-size text-center"
                            >
                                {getLangText('extendNow')}
                            </button>
                            <div className="flex justify-end items-center">
                                <img className="mr-[10px] w-[16px] brightness-0 mt-1 block"
                                     src="/assets/images/svg/arrow-black.svg" alt=""/>
                                <div
                                    style={{fontFamily: 'HateYourWriting'}}
                                    className="text-[#000] text-[28px] sm:text-[35px]"
                                    dangerouslySetInnerHTML={{
                                        __html: getLangText({
                                            key: 'saveMoney',
                                            data: [price]
                                        })
                                    }}
                                />
                            </div>
                        </div>)
                }
            </div>
            {showModal &&
                <InvoiceDataModal
                    show={showModal}
                    hideModal={() => setShowModal(false)}
                />
            }
        </div>
    );
};

export default Vacation;
