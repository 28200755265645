import React, {useState} from 'react';
import useLang from "../../../../../../../src_shared/hooks/useLang";
import {batch, useDispatch} from "react-redux";
import {useModal} from "../../../../../shared/hooks/useModal";
import api from "../../../../../../../services/axios/axios";
import {selmoUrl} from "../../../../../../../src_shared/api/api";
import {services} from "../../../../../../RestServices";
import {showAlert} from "../../../../../shared/components/alert/AlertActions";
import EditOrderFormActions from "../../../form/FormActions";
import ConfirmModal from "../../../../list/components/export/ConfirmModal";
import Stage from "./Stage";
import {getGETParamsUrl} from "../../../../../../../src_shared/list/ListActions";

const Stages = ({data}) => {
    const {getLangText} = useLang();

    const dispatch = useDispatch();
    const [isWaitingForGenerate, setIsWaitingForGenerate] = useState(false);
    const [openConfirmInvoiceModal, setOpenConfirmInvoiceModal] = useModal(false);
    const [stage, setStage] = useState(null);

    const generateDocument = async () => {
        setIsWaitingForGenerate(true);
        try {
            await api.post(`${selmoUrl}/${services.API_FAKTUROWNIA_ORDER_INVOICE}/${data.id}${getGETParamsUrl({order_stage: stage})}`)
            batch(() => {
                dispatch(showAlert('successfulInvoiceGeneratedLabel'))
                dispatch(EditOrderFormActions.loadData(data.shop_order_id))
            })
        } catch (e) {
            dispatch(EditOrderFormActions.loadData(data.shop_order_id))
            dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
            console.error('Error submitEditForm')
        } finally {
            setOpenConfirmInvoiceModal(false);
            setIsWaitingForGenerate(false);
        }
    }

    if (!data.orderProductsExtra?.length) return null;

    return (
        <div>
            <hr/>
            <div className="space-y-2">
                {data.orderProductsExtra.map((option, index) => (
                    <Stage
                        setStage={setStage}
                        setOpenConfirmInvoiceModal={setOpenConfirmInvoiceModal}
                        data={data}
                        index={index}
                    />
                ))}
            </div>
            {openConfirmInvoiceModal &&
                <ConfirmModal
                    title={getLangText('askForInvoiceGenerateLabel')}
                    saveButton={getLangText('yesButton')}
                    show={openConfirmInvoiceModal}
                    isWaiting={isWaitingForGenerate}
                    onSubmit={generateDocument}
                    hide={() => setOpenConfirmInvoiceModal(false)}
                />
            }
        </div>
    );
};

export default Stages;