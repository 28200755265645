import React from 'react';
import Collapse from "../../../../shared/components/Collapse/Collapse";
import useLang from "../../../../../../src_shared/hooks/useLang";
import cn from "clsx";
import s from '../../../../shared/components/Collapse/Collapse.module.css'

const Faq = ({questions, setActive, isActive, smallSize = false}) => {
    const {getLangText} = useLang();

    return (
        <div className="w-full">
            {questions.map((question, index) => (
                <Collapse
                    styles={smallSize ? s.smallSize : s.light}
                    key={index}
                    id={question.id}
                    setActive={setActive}
                    isActive={isActive}
                    header={(isActive) => (
                        <div
                            className={cn('flex items-center font-bold', smallSize ? 'text-sm' : 'leading-[22px] sm:text-xl  sm:leading-[28px]')}
                        >
                            {getLangText(question.title)}
                            <div className="ml-auto">
                                {isActive === question.id ?
                                    <span className={cn(smallSize ? 'text-[14px]' : 'text-[20px]')}>-</span> :
                                    <i className={cn('icon-plus text-desc -mr-1', smallSize ? 'text-[8px]' : 'text-sm')}/>
                                }
                            </div>
                        </div>
                    )}
                    defaultActive={index === 0}
                >
                    <div className={smallSize ? 'text-sm' : ''}
                         dangerouslySetInnerHTML={{__html: getLangText(question.desc)}}/>
                </Collapse>
            ))}
        </div>
    );
};

export default Faq;
