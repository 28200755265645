const initialState = {
	isOpen: false,
	isError: false,
	isLoading: false,
	successAdded: false,
	successRemoved: false,
	shouldGetOrder: false,
	orderId: null,
	backUrl: false,
}
export default (state = initialState, action) => {
	switch (action.type) {
		case 'ADD_ORDER_MODAL_TOGGLE_VISIBILITY':
			return {...state, isOpen: action.toggle, shouldGetOrder: false, backUrl: action.backUrl};
		case 'GET_ADD_ORDERS_REQUEST':
			return {...state, isLoading: true};
		case 'GET_ADD_ORDERS_ERROR':
			return {...state, isError: true, isLoading: false};
		case 'SHOW_SUCCESS_BOX':
			return {...state, successAdded: true, isLoading: false};
		case 'HIDE_SUCCESS_BOX':
			return {...state, successAdded: false};
		case 'SHOW_SUCCESS_REMOVED_BOX':
			return {...state, successRemoved: true, isLoading: false};
		case 'HIDE_SUCCESS_REMOVED_BOX':
			return {...state, successRemoved: false};
		case 'GET_ORDER':
			return {...state, shouldGetOrder: true, orderId: action.orderId};
		case 'ADD_ORDER_MODAL_RESET':
			return initialState;
		default:
			return state;
	}
};
