import React, {useEffect, useState} from 'react';
import {Modal} from "react-bootstrap";
import {WithContext as ReactTags} from "react-tag-input";
import CheckboxField from "../../../../../../src_shared/components/form/checkboxField/CheckboxField.jsx";
import useDataApi from "../../../../../../src_shared/hooks/useDataApi.js";
import {services} from "../../../../../RestServices.js";
import Loader from "../../../../shared/Loader.jsx";
import axios from "axios";
import {selmoUrl} from "../../../../../../src_shared/api/api.js";
import useLang from "../../../../../../src_shared/hooks/useLang";
import api from "../../../../../../services/axios/axios";

const DefinedVariantsModal = ({hideModal, updateValues, defaultValues}) => {
	const {getLangText} = useLang();

	const [isWaiting, setIsWaiting] = useState(false);
	const KeyCodes = {
		comma: 188,
		enter: 13,
	};

	const [values, setValues] = useState({
		sizes: defaultValues.sizes,
		colors: defaultValues.colors,
		savedTags: defaultValues.savedTags,
	})

	const delimiters = [KeyCodes.comma, KeyCodes.enter];
	const handleDelete = (i, field) => {
		const filteredTags = values[field].filter((tag, index) => index !== i);
		setValues({
			...values,
			[field]: filteredTags,
		})
	}

	const handleAddition = (newTag, field) => {
		setValues({
			...values,
			[field]: [...values[field], {...newTag, className: 'grey'}],
		})
	}

	const updateValue = (field, value) => setValues({
		...values,
		[field]: value,
	})

	const onSave = async () => {
		setIsWaiting(true)
		try {
			await api.post(`${selmoUrl}/${services.API_LIVE_TAGS}`, values)
			updateValues(values)
			hideModal();
		} catch (error) {
			console.error('Cannot save tags')
		} finally {
			setIsWaiting(false);
		}
	};

	const [{data, isLoading}] = useDataApi(`${services.API_LIVE_TAGS}`, {
		colors: '[]',
		sizes: '[]',
	}, [])


	useEffect(() => {
		const sizes = JSON.parse(data.sizes);
		const colors = JSON.parse(data.colors);

		if (!!sizes.length || !!colors.length) {
			updateValues({
				sizes: sizes,
				colors: colors,
				savedTags: '1',
			})
			setValues({
				sizes: sizes,
				colors: colors,
				savedTags: '1',
			})
		}
	}, [isLoading])

	return (
		<Modal
			animation={false}
			show={true}
			className="add-order-modal"
			dialogClassName="default-modal"
			onHide={hideModal}
		>
			<Modal.Header className="with-border">
				<Modal.Title>{getLangText('defineVariantsLabel')}</Modal.Title>
				<button
					onClick={hideModal}
					type="button"
					className="button"
				>
					<i className="icon-cross"/>
				</button>
			</Modal.Header>
			<Modal.Body className="defined-variants-modal-body">
				<Loader isLoading={isLoading}/>
				<div className="form-label">{getLangText('colorsLabel')}</div>
				<div className="react-tag-container">
					<ReactTags
						inline
						tags={values.colors}
						delimiters={delimiters}
						handleDelete={(i) => handleDelete(i, 'colors')}
						handleAddition={(i) => handleAddition(i, 'colors')}
						allowDragDrop={false}
						placeholder=""
						inputProps={{
							autoComplete: "off"
						}}
					/>
				</div>
				<div className="form-label mt-3">{getLangText('sizesLabel')}</div>
				<div className="react-tag-container mb-3">
					<ReactTags
						inline
						tags={values.sizes}
						delimiters={delimiters}
						handleDelete={(i) => handleDelete(i, 'sizes')}
						handleAddition={(i) => handleAddition(i, 'sizes')}
						allowDragDrop={false}
						placeholder=""
						autofocus={false}
						inputProps={{
							autoComplete: "off"
						}}
					/>
				</div>
				<CheckboxField
					label={getLangText('saveVariantsForFutureLivesLabel')}
					subLabel={getLangText('saveVariantsForFutureLivesSubLabel')}
					name="savedTags"
					setValue={updateValue}
					value={values.savedTags}
				/>
			</Modal.Body>
			<Modal.Footer>
				<button
					onClick={hideModal}
					type="button"
					className="button border-button"
				>
					{getLangText('cancelButton')}
				</button>
				<button
					onClick={onSave}
					type="button"
					className={`button primary ${isWaiting ? 'loading-button' : ''}`}
				>
					{isWaiting &&
						<Loader isLoading/>
					}
					{getLangText('saveButton')}
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default DefinedVariantsModal;
