import React, {useEffect} from 'react';
import Group from "../group/Group";

const Field = (props) => {

    const {
        name,
        setValue,
        value,
        disabled,
        extraProps,
        type,
        addon,
        placeholder,
        floatedLabel,
        asNumberField,
        prefix,
    } = props;

    const onKeyDown = (e) => {
        if (type === 'number' && (e.keyCode === 69 || e.keyCode === 187 || e.keyCode === 189)) {
            e.preventDefault()
            return false
        }
        if (type === 'number' && e.keyCode !== 8 && extraProps.maxLength && e.target.value?.length === extraProps.maxLength) {
            return false
        }
        if (type === 'email' && e.keyCode === 32) {
            e.preventDefault()
            return false;
        }
    }

    const removeScrollInInput = () => {
        if (document.activeElement.type === "number" && type === 'number') {
            document.activeElement.blur();
        }
    }

    const setPreparedValue = (value) => {
        if (type === 'number' && extraProps.maxLength && value.length > extraProps.maxLength) {
            return;
        }
        if (asNumberField) {
            const result = value.replace(/\D/g, '');

            setValue(name, result);
            return;
        }
        if (type === 'email') {
            const result = value.replace(/\s/g, '')
            setValue(name, result);
            return;
        }
        setValue(name, value)
    }

    useEffect(() => {
        document.addEventListener("wheel", removeScrollInInput);
        return () => {
            document.removeEventListener("wheel", removeScrollInInput);
        }
    }, [])

    const getContent = () => {
        if (!!addon || !!prefix) {
            return (
                <div className={`flex items-center relative ${prefix ? 'prefix-group' : ''}`}>
                    {!!prefix &&
                        <div className="input-prefix">
                            {prefix}
                        </div>
                    }
                    <input
                        type={type}
                        className="form-control"
                        onChange={(e) => setPreparedValue(e.target.value)}
                        value={value}
                        disabled={disabled}
                        placeholder={!floatedLabel && placeholder}
                        onKeyDown={onKeyDown}
                        {...extraProps}
                    />
                    {!!addon &&
                        <div className="input-addon">{addon}</div>
                    }
                </div>
            )
        } else {
            return (
                <input
                    type={type}
                    className="form-control"
                    onChange={(e) => setPreparedValue(e.target.value)}
                    value={value}
                    disabled={disabled}
                    placeholder={!floatedLabel && placeholder}
                    onKeyDown={onKeyDown}
                    {...extraProps}
                />
            )
        }
    }

    return (
        <Group {...props}>
            {getContent()}
        </Group>
    );
}

Field.defaultProps = {
    setValue: () => {
    },
    value: '',
    required: false,
    name: '',
    disabled: false,
    extraProps: {},
    type: 'text',
    addon: '',
    floatedLabel: false,
    asNumberField: false,
}

export default Field;
