import React, {memo, useEffect, useRef} from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import ChatList from "../app/messenger/chatList/ChatList";
import ChatBox from "../app/messenger/chatBox/ChatBox";
import EmptyState from "../app/messenger/emptyState/EmptyState";
import {RouterPaths} from "./RouterPath";
import useCSSClass from "../app/messenger/hooks/useCSSClass/useCSSClass";
import {useDispatch, useSelector} from "react-redux";
import {setFieldInChatList} from "../app/messenger/chatList/ChatListActions";
import MessengerPage from "../app/messenger/MessengerPage";
import {Helmet} from "react-helmet";
import {SELMO_APP_PERMISSION} from "../app/shared/enums/SelmoPermissions";
import PermissionRoute from "./PermissionRoute";
import useLang from "../../src_shared/hooks/useLang";
import {useIntercom} from "react-use-intercom";
import useWindowDimensions from "../app/messenger/hooks/useWindowDimensions/useWindowDimensions";
import TutorialPageBox from "../../src_shared/components/ui/TutorialPageBox/TutorialPageBox";
import {HEADER_BOX_MESSENGER} from "../app/shared/enums/TutorialVideoStatsTypes";

export const firstPageBenefits = [
    {id: 0, title: 'createOrdersInConversationsLabel', desc: 'addProductsDirectlyInMessengerLabel'},
    {id: 1, title: 'messengerBasketManageTitle', desc: 'inEveryMomentYouCanChangeProductsInBasketLabel'},
    {id: 1, title: 'sendingSummariesLabel', desc: 'sendSummariesWithPaymentLinkLabel'},
]


const MessengerRoute = () => {
    const dispatch = useDispatch();
    const {getLangText} = useLang();
    const messengerRef = useRef(null);
    const {width} = useWindowDimensions();

    const {shutdown, boot} = useIntercom();

    const {userData} = useSelector((state) => state.session);
    const {lang} = useSelector((state) => state.i18n);

    const {visibleRightSide} = useSelector((state) => state.messenger.chatBox)

    useCSSClass('full-height-page')
    useCSSClass('messeger-container')

    useEffect(() => {
        if (userData.intercom_hash) {
            shutdown();
            if (width > 767) {
                return () => boot({
                    email: userData.email,
                    userId: userData.id,
                    name: userData.name,
                    userHash: userData.intercom_hash,
                    languageOverride: lang === 'us' ? 'en' : lang,
                    avatar: {
                        type: 'avatar',
                        imageUrl: userData.logo,
                    }
                });
            }
        }
    }, [userData.intercom_hash, width])

    useEffect(() => {
        dispatch(setFieldInChatList('isLoading', true))
    }, [])

    return (
        <PermissionRoute allowedPermissions={[SELMO_APP_PERMISSION]}>
            <TutorialPageBox
                page='messenger'
                videoStatsName={HEADER_BOX_MESSENGER}
                benefits={firstPageBenefits}
                title="asideMessengerLabel"
                className="z-[2]"
            />
            <MessengerPage>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>{getLangText('messagesLabel')} - Selmo</title>
                </Helmet>
                <div
                    ref={messengerRef}
                    className={`messenger-wrapper ${visibleRightSide ? 'open' : ''}`}
                >
                    <Switch>
                        <Route
                            exact
                            path={RouterPaths.MessengerConversation}
                            render={() => (
                                <>
                                    <ChatList messengerRef={messengerRef}/>
                                    <ChatBox/>
                                </>
                            )}
                        />
                        <Route
                            exact
                            path={RouterPaths.Messenger}
                            render={() => (
                                <>
                                    <ChatList messengerRef={messengerRef}/>
                                    <div className="choose-conversation">
                                        <EmptyState/>
                                    </div>
                                </>
                            )}
                        />
                        <Redirect to={RouterPaths.Messenger}/>
                    </Switch>
                </div>
            </MessengerPage>
        </PermissionRoute>
    );
};

export default memo(MessengerRoute);
