import React from 'react';
import useDataApi from "../../../src_shared/hooks/useDataApi";
import {services} from "../../RestServices";
import useLang from "../../../src_shared/hooks/useLang";
import ComponentLoader from "../../../src_shared/components/ui/componentLoader/ComponentLoader";
import ClientAccountBar from "../shared/components/clientAccountBar/ClientAccountBar";

const RefreshToken = () => {

    const {getLangText} = useLang();

    const [{data, isLoading}] = useDataApi(`${services.API_CONNECT_FACEBOOK}`, {
        connectFacebook: '',
        facebookFanpages: [],
        createFanpage: '',
        shopName: '',
        notValid: true
    })

    return (
        <>
            <ClientAccountBar/>
            <div
                className={`bg-[#eff2f6] sm:py-0 py-6 sm:px-0 px-6 sm:min-h-auto min-h-full sm:h-[100vh] w-[100vw] flex sm:items-center sm:justify-center`}>
                <div
                    className={`w-full sm:my-0 my-auto md:px-0 px-6 rounded-[12px] shadow-default bg-white md:w-[800px] min-h-[450px] md:min-h-[600px] flex flex-col animate-tutorialSlideAndFadeIn`}>
                    {isLoading ?
                        <ComponentLoader/> :
                        <div className={`text-center animate-tutorialSlideAndFadeInDelay1 opacity-0 m-auto`}>
                            <img
                                width={30}
                                className="mx-auto mb-2"
                                src="/assets/images/svg/facebook-logo-c.svg"
                                alt="facebook"
                            />
                            <h1 className="sm:text-2xl text-xl font-bold"
                                dangerouslySetInnerHTML={{__html: getLangText('connectAgainWithFacebookLabel')}}/>
                            <h2 className="text-[#9CA3AF] text-sm font-medium mt-1"
                                dangerouslySetInnerHTML={{__html: getLangText('tokenNeedsRefreshLabel')}}/>
                            <a href={data.connectFacebook} className="button primary mt-4"
                               style={{paddingTop: 16, paddingBottom: 16}}>
                                <span className="text-sm">{getLangText('connectAgainLabel')}</span>
                            </a>
                        </div>
                    }
                </div>
            </div>
        </>
    );
};

export default RefreshToken;
