import React from 'react';
import useLang from "../../../../src_shared/hooks/useLang";
import {batch, useDispatch, useSelector} from "react-redux";
import {Helmet} from "react-helmet";
import SignupForm from "./SignupForm";
import {RouterPaths} from "../../../routes/RouterPath";
import SessionActions from "../../shared/session/SessionActions";
import {useHistory} from "react-router-dom";
import {getGETParamsUrl} from "../../../../src_shared/list/ListActions";

const RegisterPage = () => {
    const {getLangText} = useLang();
    const {lang} = useSelector((state) => state.i18n);
    const dispatch = useDispatch();
    const history = useHistory();

    const afterSubmit = (response) => {
        dispatch(SessionActions.setAndSaveInStorageToken(response.token))

        const params = {
            email: response.email,
            origin: response.origin,
            fromInstagram: response.fromInstagram,
        }

        if (!!response.shopify_hash) {
            history.push(`${RouterPaths.IntegrationsShopify}/${response.shopify_hash}`)
            return;
        }
        // dispatch(SessionActions.setLogged(true))
        if (response.isTutorialInstagram) {
            history.push(`${RouterPaths.TutorialInstagramConnect}${getGETParamsUrl(params)}`)
        } else {
            history.push(`${RouterPaths.Tutorial}${getGETParamsUrl(params)}`)
        }
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getLangText('registerForFreeLabel')} - Selmo</title>
            </Helmet>
            <img alt="selmo" width={107} src="/assets/images/logo.svg"/>
            <h1 className="text-3xl font-bold mt-6 mb-8"
                dangerouslySetInnerHTML={{__html: getLangText('testSelmoForFreeLabel')}}/>
            <SignupForm
                lang={lang}
                getLangText={getLangText}
                afterSubmit={afterSubmit}
            />
        </>
    );
};

export default RegisterPage;
