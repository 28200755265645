import createReducer from "../../../shared/helpers/createReducer.js";
import Composition from "../../../shared/helpers/Composition.js";
import FormReducer from "../../../../../../../src_shared/form/FormReducer.js";
import FunctionalitiesModel from "../../../../../modules/models/FunctionalitiesModel";

export const getStateRoot = (state) => state.admin.subscription.functionalities.form;
export const prefix = 'ADMIN_SUBSCRIPTION_FUNCTIONALITIES_FORM_';

const getInitState = () => FormReducer.getInitState(FunctionalitiesModel.getModel());

const getReduceMap = () => new Composition(
	FormReducer.getReduceMap(),
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

