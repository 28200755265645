import React, {useEffect} from 'react';
import {Link, useHistory} from "react-router-dom";
import {RouterPaths} from "../../../../routes/RouterPath.js";
import {useDispatch, useSelector, batch} from "react-redux";
import {
	addCustomersToProduct,
	getLiveDetailsInfo,
	liveDetailsAddNewCustomer, liveDetailsAddSeveralCustomers, liveDetailsRemoveCustomer,
	resetAllCustomers,
	resetClientListParams,
	resetLiveInfo,
	resetPage,
	resetProduct, updateFieldInCustomer, updateFieldInLiveDetails,
} from "./LiveDetailsActions.js";
import CustomerRow from "./components/CustomerRow.jsx";
import ProductRow from "./components/productRow/ProductRow.jsx";
import Clients from "./clients/Clients.jsx";
import moment from "../../../messenger/helpers/moment.js";
import useCSSClass from "../../../messenger/hooks/useCSSClass/useCSSClass.js";
import Summary from "./summary/Summary.jsx";
import Loader from "../../../messenger/loader/Loader.jsx";
import {Helmet} from "react-helmet";
import DefinedVariants from "./definedVariantsModal/DefinedVariants.jsx";
import ProductsInBasket from "./productsInBasket/ProductsInBasket.jsx";
import useLang from "../../../../../src_shared/hooks/useLang.js";
import LiveListsWrapper from "../Page";
import {convertToUserTimeZone} from "../../../shared/helpers/dateHelpers";

const LiveDetails = ({match}) => {

	const shopLiveId = match.params.id;
	const dispatch = useDispatch();
	const history = useHistory();
	const {getLangText} = useLang();

	useCSSClass('live-container')

	const {
		liveInfo,
		isInfoError,
		customers,
		isCustomersLoading,
		isInfoLoading,
		clientsRowsToAdd,
		product,
		customerDuplicateId,
		hasCustomerError,
		waitingForStorageProductUpdate,
	} = useSelector((state) => state.lives.details)
	const isAtLeastOneOrderExist = !isInfoLoading && +liveInfo.finished;
	const isNewProductFormVisible = !isInfoLoading && !+liveInfo.finished

	useEffect(() => {
		return () => {
			batch(() => {
				dispatch(resetAllCustomers())
				dispatch(resetProduct())
				dispatch(resetPage())
				dispatch(resetClientListParams());
				dispatch(updateFieldInLiveDetails('hasProductInCustomerError', false))
				dispatch(updateFieldInLiveDetails('hasCustomerError', false))
			})
		}
	}, [shopLiveId])

	const addNewClient = () => {
		batch(() => {
			dispatch(liveDetailsAddNewCustomer());
			dispatch(updateFieldInLiveDetails('customerDuplicateId', null));
		})
	}

	const addSeveralClientsRow = () => dispatch(liveDetailsAddSeveralCustomers());

	if (isInfoError) {
		history.push(`${RouterPaths.LiveList}`)
	}

	return (
		<LiveListsWrapper>
			<Helmet>
				<meta charSet="utf-8"/>
				<title>{getLangText('liveListTitle')} {convertToUserTimeZone(liveInfo.date).format('DD.MM.YYYY')} - Selmo</title>
			</Helmet>
			<div className="row">
				<div className="col-lg-7 left-col pr-lg-2">
					{isNewProductFormVisible &&
						<div className="product-col">
							<div className="add-product-wrapper default-shadow-box mb-3">
								<div className="product-box">
									<div className="d-flex align-items-center">
										<h1 className="title flex-grow-1 mb-0">
											{getLangText('liveDetailsNewProductLabel')}
										</h1>
										<DefinedVariants/>
									</div>
									<ProductRow/>
								</div>
								<h1 className="title smaller">
									{getLangText('buyerLabel')}
									<div className="badge">{customers.length}</div>
									{(+liveInfo.shop_id === 5 || +liveInfo.shop_id === 470 || +liveInfo.shop_id === 322 || +liveInfo.shop_id === 1923 || +liveInfo.shop_id === 1139) &&
										<div className="add-several-rows">
											<div className="form-group">
												<input
													type="number"
													className="form-control"
													name="clientsRowsToAdd"
													onChange={(e) => dispatch(updateFieldInLiveDetails('clientsRowsToAdd', e.target.value))}
													tabIndex="-1"
													value={clientsRowsToAdd}
													min={1}
												/>
											</div>
											<div className="tooltip-parent">
												{clientsRowsToAdd <= 0 &&
													<div className="tooltip-content">
														{getLangText('rowNumberGreaterThanZeroLabel')}
													</div>
												}
												<button
													type="button"
													className="button text-blue-button add-new-row no-focus"
													onClick={addSeveralClientsRow}
													tabIndex="-1"
													disabled={clientsRowsToAdd <= 0}
												>
													Dodaj wiersze
												</button>
											</div>
										</div>
									}
								</h1>
								<div className="inputs-wrapper only-first-row-labels">
									{customers.map((customer) => (
										<CustomerRow
											product={product}
											key={customer.id}
											customer={customer}
											customers={customers}
											updateField={(field, value) => dispatch(updateFieldInCustomer(field, value, customer.id))}
											removeCustomer={() => dispatch(liveDetailsRemoveCustomer(customer.id))}
											productId={product.id}
											setCustomerDuplicatedId={(value) => dispatch(updateFieldInLiveDetails('customerDuplicateId', value))}
											customerDuplicateId={customerDuplicateId}
											hasCustomerError={hasCustomerError}
											customerId={customer.id}
										/>
									))}
								</div>
								<button
									type="button"
									className="button text-blue-button add-new-row"
									onClick={addNewClient}
									tabIndex={customers[customers.length - 1].tabIndex}
								>
									<i className="icon-plus"/>
									{getLangText('addNextClientLabel')}
								</button>
								<div className="button-place">
									<button
										type="submit"
										className={`button primary the-smallest-size ${isCustomersLoading ? 'loading-button' : ''}`}
										onClick={() => dispatch(addCustomersToProduct())}
										tabIndex={customers[customers.length - 1].tabIndex}
									>
										{isCustomersLoading &&
											<Loader/>
										}
										{getLangText('addProductToClientsLabel')}
									</button>
								</div>
							</div>
						</div>
					}
					<div className="clients-col">
						<Clients
							isAtLeastOneOrderExist={isAtLeastOneOrderExist}
							id={liveInfo.id}
						/>
					</div>
				</div>
				<div className="col-lg-5 summary-col pl-lg-2">
					<Summary isAtLeastOneOrderExist={isAtLeastOneOrderExist}/>
					<ProductsInBasket
						product={product}
						customers={customers}
						isWaiting={waitingForStorageProductUpdate}
					/>
				</div>
			</div>
		</LiveListsWrapper>
	);
};

export default LiveDetails;
