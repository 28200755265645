import React, {useMemo} from 'react';
import SelectField from "../../../../../src_shared/components/form/selectField/SelectField.jsx";
import ValidationError from "../../../../../src_shared/form/validation/ValidationError.js";
import Field from "../../../../../src_shared/components/form/field/Field.jsx";
import {components} from "react-select";
import useLang from "../../../../../src_shared/hooks/useLang";
import {useUnitPrice} from "../../../shared/helpers/Price";
import {services} from "../../../../RestServices";
import AsyncSelectField from "../../../../../src_shared/components/form/selectField/AsyncSelectField";
import Group from "../../../../../src_shared/components/form/group/Group";

const VariantsSelect = ({
                            product,
                            validateErrors,
                            variantId,
                            updateField,
                            variantPrice,
                            variantName,
                            variantSqu,
                            ValueContainer,
                            label = 'variantLabel',
                            disabled = false
                        }) => {

    const {getLangText} = useLang();
    const {getPrice} = useUnitPrice();

    const preparedVariantsOptions = useMemo(() => product.sizes.map((i) => ({
        size: i.size,
        squ: i.squ,
        price: i.price,
        value: i.id,
        label: i.size
    })), [product.sizes])

    const formatOptionLabel = (value) => (
        <div className="select-custom-option">
            <div className="name">{value.size}</div>
            <div className="bottom-bar no-separator">
                {value.squ !== null &&
                    <div>{getLangText('availableLabel')}: <strong>{value.squ}</strong></div>
                }
                {value.price !== null &&
                    <div className="ml-auto">{getPrice(value.price)}</div>
                }
            </div>
        </div>
    )

    const NoOptionsMessage = props => {
        return (
            <components.NoOptionsMessage {...props}>
                <span className="extra-small-text">{getLangText('noVariantsLabel')}</span>
            </components.NoOptionsMessage>
        );
    };

    const getLoaderOption = (getLang) => {
        return {
            size: `${getLang('loadingLabel')}...`,
            value: null,
            squ: null,
            price: null,
            label: null,
        }
    }

    return (
        <div className="variant-col">
            {!!product.sizes.length ?
                <>
                    <AsyncSelectField
                        className="mb-0 hidden-error-label"
                        name="variant_id"
                        setValue={(name, value) => updateField(name, value)}
                        label={getLangText(label) || label}
                        placeholder={getLangText('searchPlaceholder')}
                        value={variantId}
                        getLoaderOption={getLoaderOption}
                        rest={`${services.API_LIVE_PRODUCT_SIZES}/${product.product_id || product.id}`}
                        defaultValue={{
                            value: product.variant_id,
                            label: product.size_name || variantName,
                            size: product.size_name || variantName,
                            squ: product.squ || variantSqu,
                            price: product.size_price || variantPrice,
                        }}
                        defaultValueParser={(defaultValue) => ({
                            value: defaultValue.value,
                            label: defaultValue.label,
                            size: defaultValue.size,
                            squ: defaultValue.squ,
                            price: defaultValue.price,
                        })}
                        formatOptionLabel={formatOptionLabel}
                        withLackOption={false}
                        // extraProps={{
                        //     isSearchable: false,
                        // }}
                        optionsParser={(options) => options?.map((i) => ({
                            size: i.size,
                            squ: i.squ,
                            price: i.price,
                            value: i.id,
                            label: i.size
                        }))}
                        disabled={disabled}
                        validateErrors={validateErrors}
                        getError={ValidationError.notEmpty}
                        extraProps={{
                            components: {
                                // isSearchable: false,
                                ValueContainer,
                                NoOptionsMessage,
                            },
                        }}
                    />
                    {/*<SelectField*/}
                    {/*    label={getLangText(label) || label}*/}
                    {/*    className="mb-0 hidden-error-label"*/}
                    {/*    name="variant_id"*/}
                    {/*    options={preparedVariantsOptions}*/}
                    {/*    setValue={(name, value) => updateField(name, value)}*/}
                    {/*    placeholder={getLangText('searchPlaceholder')}*/}
                    {/*    formatOptionLabel={formatOptionLabel}*/}
                    {/*    value={variantId}*/}
                    {/*    extraProps={{*/}
                    {/*        components: {*/}
                    {/*            ValueContainer,*/}
                    {/*            NoOptionsMessage,*/}
                    {/*        },*/}
                    {/*    }}*/}
                    {/*    disabled={disabled}*/}
                    {/*    validateErrors={validateErrors}*/}
                    {/*    getError={ValidationError.notEmpty}*/}

                    {/*/>*/}
                </>
                :
                <Field
                    className="floated-small-label"
                    label={getLangText(label) || label}
                    disabled={true}
                    value={getLangText('lackLabel')}
                    dataText={getLangText(label) || label}
                />
            }
        </div>
    );
};

VariantsSelect.defaultProps = {
    ValueContainer: ({children, ...props}) => {
        const {getValue, hasValue} = props;
        if (!hasValue) {
            return (
                <components.ValueContainer {...props}>
                    {children}
                </components.ValueContainer>
            );
        }
        return (
            <components.ValueContainer className="custom-value-container" {...props}>
                <div className="custom-value">{getValue()[0].size}</div>
                <span className="hidden-value-container">
					{children}
				</span>
            </components.ValueContainer>
        );
    }
}

export default VariantsSelect;
