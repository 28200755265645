import React, {useState} from 'react';
import useFormLocal from "../../../../src_shared/hooks/useFormLocal";
import SelmoPaczkaActivateModal from "../../../../modules/models/selmoPaczka/SelmoPaczkaActivateModal";
import {services} from "../../../RestServices";
import SelmoPaczkaDataForm from "../../orders/details/selmoPaczka/components/dataForm/SelmoPaczkaDataForm";
import Loader from "../../shared/Loader";
import useLang from "../../../../src_shared/hooks/useLang";
import SelmoPaczkaPageWrapper from "../SelmoPaczkaPage";
import SelmoPaczkaCreateShipping from "../../orders/details/selmoPaczka/components/dataForm/SelmoPaczkaCreateShipping";

const Configuration = () => {
    const {getLangText} = useLang();
    const [showCreateShippingModal, setShowCreateShippingModal] = useState(false);

    const afterSubmit = () => setShowCreateShippingModal(true)

    const [{getFieldProps, onSave, isWaiting, isLoading, values}] = useFormLocal({
        model: SelmoPaczkaActivateModal,
        rest: services.API_PACKAGE_ACTIVATE,
        afterSubmit,
    });

    return (
        <SelmoPaczkaPageWrapper>
            <div className="loader-parent mt-3">
                <Loader isLoading={isLoading}/>
                <div className="settings-form bg-[#fff] rounded-[5px]">
                    <div className="max-w-[420px] mx-auto sm:py-12">
                        <div className="font-bold text-lg">{getLangText('activateSelmoPaczka')}</div>
                        <div className="text-desc font-medium text-sm">
                            {getLangText('sendParcelCheaper')}
                        </div>
                        <div className="font-bold mb-2 mt-4">
                            {getLangText('setTransmittingData')}
                        </div>
                        <form onSubmit={onSave}>
                            <SelmoPaczkaDataForm
                                getFieldProps={getFieldProps}
                                values={values}
                            />
                            <button
                                type="submit"
                                style={{fontSize: 14, paddingTop: 16, paddingBottom: 16}}
                                className={`button small-size mt-2 w-full primary ${isWaiting ? 'loading-button' : ''}`}
                            >
                                {isWaiting && <Loader isLoading/>}
                                {getLangText('saveButton')}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            {showCreateShippingModal &&
                <SelmoPaczkaCreateShipping
                    showModal={showCreateShippingModal}
                    hide={() => setShowCreateShippingModal(false)}
                />
            }
        </SelmoPaczkaPageWrapper>
    );
};

export default Configuration;
