export const TODAY_ORDERS_VIEW = 'todayOrders';
export const ALL_ORDERS_VIEW = 'allOrders';
export const INTERVAL = 'intervalOrders';
export const LAST_SEVEN_DAYS = 'lastSevenDays';
export const LAST_MONTH = 'lastMonth';

const OrderExportTypes = [
    {
        id: TODAY_ORDERS_VIEW,
        value: 'todayLabel',
    },
    {
        id: ALL_ORDERS_VIEW,
        value: 'allOrdersLabel',
    },
    {
        id: INTERVAL,
        value: 'ownRangeLabel',
    },
    {
        id: LAST_SEVEN_DAYS,
        value: 'lastSevenDaysLabel',
    },
    {
        id: LAST_MONTH,
        value: 'lastThirtyDaysLabel',
    },
];

export default OrderExportTypes;
