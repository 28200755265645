import React from 'react';
import DefinedVariantsModal from "./Modal.jsx";
import useForm from "../../../../../../src_shared/form/useForm.js";
import {useDispatch} from "react-redux";
import DefinedVariantModalActions from "./FormActions";
import FormActions from "./FormActions";
import useLang from "../../../../../../src_shared/hooks/useLang";

const DefinedVariants = () => {
	const dispatch = useDispatch();
	const showDefinedVariantModal = () => dispatch(DefinedVariantModalActions.modal.showModal());
	const {getLangText} = useLang();

	const {
		data: {values, setValue, updateValues},
		modal,
	} = useForm({
		FormActions,
		params: {
			id: 0,
		},
		preventAutoLoad: true,
	});

	return (
		<>
			<button
				type="button"
				className="button border-button small-size"
				onClick={showDefinedVariantModal}
			>
				{getLangText('variantsLabel')}
			</button>
			{modal.modalVisible &&
				<DefinedVariantsModal
					defaultValues={values}
					updateValues={updateValues}
					hideModal={modal.hideModal}
				/>
			}
		</>
	);
};

export default DefinedVariants;
