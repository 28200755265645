import Model from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";
import {SHOP_ORIGIN_US} from "../../../components/app/shared/enums/ShopOrigins";

const getPhoneValidate = (value, data) => {
	if (data.lang === SHOP_ORIGIN_US) {
		const phoneRegExp = /^\+?1?[ \-]?[\(]?[2-9][0-9][0-9][\)]?[ \-]?[2-9][0-9][0-9][ \-]?[0-9]{4}$/;
		return !value || phoneRegExp?.test(value) ? false : 'incorrectPhoneWarning';
	}
	return ValidationError.validatePhone(value, data);
}

class CreateShopModel extends Model {
	getModel() {
		return {
			name: '',
			phone: '',
		};
	}

	getValidators() {
		return {
			name: ValidationError.notEmpty,
			phone: getPhoneValidate,
		};
	}

	buildDTO(data) {
		const {name, phone, email} = data;

		if (!!email) {
			return {
				name,
				phone,
				email,
			}
		}

		return {
			name,
			phone,
		}
	}
}

export default new CreateShopModel();
