import React, {useEffect, useState} from 'react';
import CustomDropdown from "../../../../shared/components/dropdown/CustomDropdown";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {getStateRoot} from "./formReducer";
import FormActions from "./FormActions";
import OrdersProductsExport from "../../../../shared/enums/OrdersProductsExport";
import useLang from "../../../../../../src_shared/hooks/useLang";
import {services} from "../../../../../RestServices";
import ExportModal from "../../../../../../src_shared/components/exportModal/ExportModal";
import {SHOP_BAZAR_SHOP_ID, TEST_ACCOUNT_SHOP_ID} from "../../../../../../src_shared/enums/TrustedShopsIds";

const XLS_EXPORT_MODAL = 'XLS_EXPORT_MODAL';
const PDF_EXPORT_MODAL = 'PDF_EXPORT_MODAL';
const QR_CODE_EXPORT_MODAL = 'QR_CODE_EXPORT_MODAL';

const Export = () => {
    const dispatch = useDispatch();
    const {getLangText} = useLang();
    const {
        data,
        modalVisible,
        waiting,
        productsExportVisible
    } = useSelector(createSelector(getStateRoot, (stateRoot) => stateRoot));
    const [openExportModal, setOpenExportModal] = useState('');
    const {userData} = useSelector((state) => state.session);

    const getParams = () => dispatch(FormActions.getParams());
    const setValue = (field, value) => dispatch(FormActions.data.setValue(field, value));

    useEffect(() => {
        dispatch(FormActions.data.restoreEmptyData())
    }, [modalVisible, productsExportVisible])

    return (
        <div className="ml-sm-auto mr-sm-2 order-export-wrapper">
            <CustomDropdown
                ButtonToggle={() => <span className="d-md-none">{getLangText('exportLabel')}</span>}
                icon="icon-print"
                tooltipTitle={getLangText('exportLabel')}
            >
                <button
                    type="button"
                    className="custom-option"
                    onClick={() => setOpenExportModal(XLS_EXPORT_MODAL)}
                >
                    {getLangText('printSalesStatementPDFLabel')}
                </button>
                <button
                    type="button"
                    className="custom-option"
                    onClick={() => setOpenExportModal(PDF_EXPORT_MODAL)}
                >
                    {getLangText('printOrdersPDFLabel')}
                </button>
                {(+userData.id === TEST_ACCOUNT_SHOP_ID || +userData.id === SHOP_BAZAR_SHOP_ID) &&
                    <button
                        type="button"
                        className="custom-option"
                        onClick={() => setOpenExportModal(QR_CODE_EXPORT_MODAL)}
                    >
                        {getLangText('printQRCodesOrdersPDFLabel')}
                    </button>
                }
            </CustomDropdown>
            {openExportModal === XLS_EXPORT_MODAL &&
                <ExportModal
                    title={getLangText('exportSalesStatementPDFLabel')}
                    subTitle={getLangText('receivedFileInXLSFormatLabel')}
                    saveButton={getLangText('exportLabel')}
                    rest={`${services.API_EXPORT_ALL_ORDERS_XLS}${getParams()}`}
                    fileName={getLangText('asideOrdersLabel')}
                    fileType="xlsx"
                    show={openExportModal === XLS_EXPORT_MODAL}
                    hide={() => setOpenExportModal('')}
                />
            }
            {openExportModal === PDF_EXPORT_MODAL &&
                <ExportModal
                    title={getLangText('printOrdersLabel')}
                    subTitle={getLangText('receivedFileInPDFFormatLabel')}
                    saveButton={getLangText('printPDFLabel')}
                    rest={`${services.API_EXPORT_ALL_ORDERS_PDF}${getParams()}`}
                    fileName={getLangText('asideOrdersLabel')}
                    show={openExportModal === PDF_EXPORT_MODAL}
                    hide={() => setOpenExportModal('')}
                    extraParams={{
                        displayType: data.displayType
                    }}
                >
                    <div className="form-label mt-2">
                        {getLangText('displayMethodLabel')}
                    </div>
                    <div className="data-boxes-wrapper">
                        {OrdersProductsExport.map((item) => (
                            <div
                                key={item.id}
                                className="content content-shipping">
                                <div className="content__top-part">
                                    <input
                                        type="radio"
                                        name="clients"
                                        checked={data.displayType === item.id}
                                        onChange={() => setValue('displayType', item.id)}
                                    />
                                    <span className="checkmark"/>
                                    <div className="flex-grow-1">
                                        <div className="label">{getLangText(item.label)}</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </ExportModal>
            }
            {openExportModal === QR_CODE_EXPORT_MODAL &&
                <ExportModal
                    title={getLangText('printQRCodesOrdersPDFLabel')}
                    subTitle={getLangText('receivedFileInPDFFormatLabel')}
                    saveButton={getLangText('printPDFLabel')}
                    rest={`${services.API_EXPORT_ORDERS_QR_PDF}${getParams()}`}
                    fileName={`${getLangText('asideOrdersLabel')}_QR`}
                    show={openExportModal === QR_CODE_EXPORT_MODAL}
                    hide={() => setOpenExportModal('')}
                    extraParams={{
                        displayType: data.displayType
                    }}
                >
                    <div className="form-label mt-2">
                        {getLangText('displayMethodLabel')}
                    </div>
                    <div className="data-boxes-wrapper">
                        {OrdersProductsExport.map((item) => (
                            <div
                                key={item.id}
                                className="content content-shipping">
                                <div className="content__top-part">
                                    <input
                                        type="radio"
                                        name="clients"
                                        checked={data.displayType === item.id}
                                        onChange={() => setValue('displayType', item.id)}
                                    />
                                    <span className="checkmark"/>
                                    <div className="flex-grow-1">
                                        <div className="label">{getLangText(item.label)}</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </ExportModal>
            }
        </div>
    );
};

export default Export;
