import moment from 'moment';
import 'moment-timezone';

export const convertToWarsawTimeZone = (data) => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const preparedDate = moment.tz(`${data.date} ${data.time}`, userTimeZone);

    if (userTimeZone.includes('Europe/Warsaw')) return moment(`${data.date} ${data.time}`);

    return preparedDate.tz('Europe/Warsaw');
};

export const convertToUserTimeZone = (dateTime) => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (!dateTime) {
        dateTime = new Date();
    }

    // if (userTimeZone.includes('Europe/Warsaw')) return moment(dateTime);

    // return moment.tz(dateTime, 'Europe/Warsaw').tz(userTimeZone);

    const format = (dateFormat) => {
        let preparedDateFormat = dateFormat;
        if (userTimeZone.includes('America')) {
            if (dateFormat === 'DD.MM.YYYY') {
                preparedDateFormat = 'MM.DD.YYYY'
            }

            if (dateFormat === 'DD.MM.YYYY HH:mm') {
                preparedDateFormat = 'MM.DD.YYYY hh:mm A'
            }

            if (dateFormat === 'DD.MM.YYYY HH:mm:ss') {
                preparedDateFormat = 'MM.DD.YYYY hh:mm:ss A'
            }

            if (dateFormat === 'HH:mm') {
                preparedDateFormat = 'hh:mm A'
            }

            if (dateFormat === 'HH:mm:ss') {
                preparedDateFormat = 'hh:mm:ss A'
            }

            if (dateFormat === 'ddd, HH:mm') {
                preparedDateFormat = 'ddd, hh:mm A'
            }

            if (dateFormat === 'DD MMM, HH:mm') {
                preparedDateFormat = 'MMM DD, hh:mm A'
            }

        }
        return moment.tz(dateTime, 'Europe/Warsaw').tz(userTimeZone).format(preparedDateFormat)
    }

    const toDate = () => moment.tz(dateTime, 'Europe/Warsaw').tz(userTimeZone).toDate();
    const weekday = (index) => moment.tz(dateTime, 'Europe/Warsaw').tz(userTimeZone).weekday(index);
    const isSame = (x, y) => moment.tz(dateTime, 'Europe/Warsaw').tz(userTimeZone).isSame(x, y);
    const add = (x, y) => moment.tz(dateTime, 'Europe/Warsaw').tz(userTimeZone).add(x, y);
    const diff = (x, y) => moment.tz(dateTime, 'Europe/Warsaw').tz(userTimeZone).diff(x, y);
    const unix = () => moment.tz(dateTime, 'Europe/Warsaw').tz(userTimeZone).unix();
    const month = (x) => moment.tz(dateTime, 'Europe/Warsaw').tz(userTimeZone).month(x);
    const year = (y) => moment.tz(dateTime, 'Europe/Warsaw').tz(userTimeZone).year(y);

    if (userTimeZone.includes('Europe/Warsaw')) return moment(dateTime);

    if (!userTimeZone.includes('America')) return moment.tz(dateTime, 'Europe/Warsaw').tz(userTimeZone)

    return {
        format,
        toDate,
        weekday,
        unix,
        isSame,
        add,
        month,
        year,
        diff,
    }
};

export const convertTimestampToDate = (isoStringOrTimestamp, dateFormat = 'DD/MM/YYYY', locale = 'pl') => {
    moment.locale(locale === 'us' ? 'en' : locale)
    if (!isoStringOrTimestamp) {
        return '';
    }

    return moment(isoStringOrTimestamp).format(dateFormat);
}

export const isTodayDay = (timestamp) => (!!moment(timestamp).isSame(moment(), 'day'));

export const convertDate = (date) => moment(date || new Date()).format('YYYY-MM-DD')

export const convertDateWithYear = (date, locale = 'en') => {
    moment.locale(locale === 'us' ? 'en' : locale)
    return moment(date).format('DD.MM.YYYY HH:mm')
}

