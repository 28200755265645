import React, {useEffect, useRef} from 'react';

const useTableScrollClasses = (tableColumn, isWaiting) => {

	const tableRef = useRef(null);
	const tableResponsiveRef = useRef(null);

	useEffect(() => {
		const mainElement = document.querySelector('main');
		mainElement.classList.add('table-page', 'orders-page');
		const tableWidth = tableRef.current?.clientWidth;
		const tableResponsiveWidth = tableResponsiveRef.current?.clientWidth;
		if (tableRef.current && tableResponsiveRef.current && tableWidth > tableResponsiveWidth) {
			mainElement.classList.add('full-height-page');
			return;
		}
		if (mainElement.classList.contains('full-height-page')) {
			mainElement.classList.remove('full-height-page');
		}
	}, [tableRef.current, tableResponsiveRef.current, isWaiting, tableColumn])

	useEffect(() => {
		const mainElement = document.querySelector('main');
		return () => {
			mainElement.classList.remove('full-height-page', 'table-page', 'orders-page')
		}
	}, [])

	return {
		tableRef,
		tableResponsiveRef,
	}
};

export default useTableScrollClasses;
