import React, {useMemo} from 'react';
import Select from "react-select";
import useLang from "../../../../../src_shared/hooks/useLang";

const SelectVariant = ({values, setValue}) => {
    const {getLangText} = useLang();

    const options = useMemo(() => values.productInfo?.sizes?.map((i) => ({
        value: i.id,
        label: i.size,
        squ: i.squ,
        price: i.price,
        disabled: !+i.squ,
        old_price: i.old_price,
    })), [values.productInfo?.sizes]);

    const CustomOption = ({innerRef, innerProps, label, data}) => {
        return (
            <div
                ref={innerRef} {...innerProps} className={`-option ${data.disabled ? 'disabled' : ''}`}>
                <div className="flex items-center">
                    {label}
                    <div className="text-sm ml-auto text-secondary">
                        {/*{!data.disabled ?*/}
                        {/*    getPriceCurrency(data.price, shopInfo) :*/}
                        {/*    t('soldOutLabel')*/}
                        {/*}*/}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>
            <div className="font-bold mb-3.5">{getLangText('selectVariantLabel')}</div>
            <Select
                openMenuOnFocus={true}
                className="custom-select"
                value={!!values.product_size_id ? values.product_size_id : null}
                options={options}
                onChange={(option) => setValue('product_size_id', option)}
                hideSelectedOptions={false}
                isSearchable={false}
                placeholder={getLangText('selectVariantLabel')}
                isOptionDisabled={(option) => option.disabled}
                required={true}
                components={{
                    Option: CustomOption,
                }}
            />
        </div>
    );
};

export default SelectVariant;