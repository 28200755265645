import React from 'react';
import {services} from "../../../../RestServices";
import {RouterPaths} from "../../../../routes/RouterPath";
import useFormLocal from "../../../../../src_shared/hooks/useFormLocal";
import Loader from "../../../../app/shared/Loader";
import useLang from "../../../../../src_shared/hooks/useLang";
import {Link} from "react-router-dom";
import OrlenSettingsWrapper from "../OrlenWrapper";
import SettingsOrlenDataModel from "../../../../../modules/models/settings/SettingsOrlenDataModel";
import Field from "../../../../../src_shared/components/form/field/Field";

const OrlenSenderDataForm = () => {
    const {getLangText} = useLang()

    const [{onSave, isWaiting, getFieldProps, isLoading, values}] = useFormLocal({
        rest: services.API_SETTINGS_ORLEN,
        model: SettingsOrlenDataModel,
    });

    return (
        <>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <a href={RouterPaths.Settings}>
                            {getLangText('asideSettingsLabel')}
                        </a>
                    </li>
                    <li>
                        <Link to={RouterPaths.SettingsIntegration}>{getLangText('settings_nav_integrations')}</Link>
                    </li>
                    <li className="active">
                        Orlen Paczka
                    </li>
                </ul>
            </div>
            <OrlenSettingsWrapper>
                <form
                    onSubmit={onSave}
                    id="orlen-paczka-settings-form">
                    <div className="loader-parent">
                        <Loader isLoading={isLoading}/>
                        <div className="settings-form pt-3">
                            <h2 className="mb-3">
                                {getLangText('senderDataLabel')}
                            </h2>
                            <div className="grid grid-cols-2 gap-x-5">
                                <div className="col-span-2">
                                    <Field
                                        {...getFieldProps('company')}
                                        label={getLangText('companyLabel')}
                                    />
                                </div>
                                <Field
                                    {...getFieldProps('first_name')}
                                    label={getLangText('firstNameLabel')}

                                />
                                <Field
                                    {...getFieldProps('last_name')}
                                    label={getLangText('lastNameLabel')}

                                />
                                <div className="col-span-2">
                                    <div className="grid grid-cols-4 gap-x-5">
                                        <div className="col-span-2">
                                            <Field
                                                {...getFieldProps('street')}
                                                label={getLangText('streetNumberLabel')}
                                            />
                                        </div>
                                        <Field
                                            {...getFieldProps('house_number')}
                                            label={getLangText('buildingNumberLabel')}
                                        />
                                        <Field
                                            {...getFieldProps('apartment_number')}
                                            label={getLangText('apartmentNumberLabel')}
                                        />
                                    </div>
                                </div>
                                <Field
                                    {...getFieldProps('city')}
                                    label={getLangText('cityLabel')}

                                />
                                <Field
                                    {...getFieldProps('zip_code')}
                                    label={getLangText('zipCodeLabel')}

                                />
                                <Field
                                    {...getFieldProps('phone')}
                                    label={getLangText('phoneLabel')}
                                />
                                <Field
                                    {...getFieldProps('email')}
                                    label={getLangText('emailLabel')}
                                />
                            </div>
                            <div className="button-place mt-3">
                                <button
                                    type="submit"
                                    className={`button primary ${isWaiting ? 'loading-button' : ''}`}
                                >
                                    {isWaiting && <Loader isLoading/>}
                                    {getLangText('saveButton')}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </OrlenSettingsWrapper>
        </>
    );
};

export default OrlenSenderDataForm;
