import React, {useState} from 'react';
import useLang from "../../../../src_shared/hooks/useLang";

const Benefit = ({item, isOpenDefault = false}) => {
    const {getLangText} = useLang();
    const [isOpen, setIsOpen] = useState(isOpenDefault)

    return (
        <button
            type="button"
            onClick={() => setIsOpen(!isOpen)}
            className="flex grow group w-full"
            key={item.id}
        >
            <div className="mr-[10px] py-[14px]">
                <img className="w-[24px] min-w-[24px]"
                     src="/assets/images/svg/check.svg" alt=""/>
            </div>
            <div
                className="text-[#C0C0C0] text-left border-b py-[14px] border-[#39393B] w-full group-last:border-0"
            >
                <div className="flex items-center">
                    <div dangerouslySetInnerHTML={{__html: getLangText(item.title)}}/>
                    <div className="ml-auto">
                        <i className="icon-arrows text-[10px] text-[#9CA3AF]" />
                    </div>
                </div>
                {isOpen &&
                    <div className="animate-[slideAndShow_.3s_ease_forwards] font-medium text-[#D1D5DB] text-sm leading-[26px] mt-2">
                        {getLangText(item.desc)}
                    </div>
                }
            </div>
        </button>
    );
};

export default Benefit;
