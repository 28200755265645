import React, {useState} from 'react';
import ConfirmRemoveModal from "../../../src_shared/components/modal/ConfirmRemoveModal";
import useLang from "../../../src_shared/hooks/useLang";
import axios from "axios";
import {selmoUrl} from "../../../src_shared/api/api";
import {showAlert} from "../../app/shared/components/alert/AlertActions";
import {batch, useDispatch, useSelector} from "react-redux";
import {services} from "../../RestServices";
import SessionActions from "../../app/shared/session/SessionActions";
import {useHistory} from "react-router-dom";
import {RouterPaths} from "../RouterPath";
import api from "../../../services/axios/axios";

const NoShopPermissionComponent = ({setConfettiVisible}) => {
	const {lang} = useSelector((state) => state.i18n);
	const [openModal, setOpenModal] = useState(false);
	const {getLangText} = useLang();
	const dispatch = useDispatch();
	const [waiting, setWaiting] = useState(false);
	const history = useHistory();
	const startTrial = async () => {
		setWaiting(true)
		try {
			await api.post(`${selmoUrl}/${services.API_STORE_START_TRIAL}`)
			batch(() => {
				setOpenModal(false)
				history.push(RouterPaths.ShopDesktop)
				dispatch(showAlert())
				setConfettiVisible(true)
				dispatch(SessionActions.uncheckedAndCheckSession());
			})
		} catch (e) {
			dispatch(showAlert('sthWentWrongWarning', '', 'alert-fail'))
			console.error('Fail start trial')
		} finally {
			setWaiting(false)
		}
	}

	return (
		<div className="shop-landing-wrapper">
			<section className="intro-section relative">
				<img
					alt="intro"
					className="z-[-1] absolute top-0 left-0 right-0 bottom-0 w-full h-full object-cover"
					src="/assets/images/shop-landing/intro-bg.svg"
				/>
				<h1 className="section-title mb-1"
					dangerouslySetInnerHTML={{__html: getLangText('createYourFirstShopLabel')}}/>
				<p className="desc">
					{getLangText('tryForFreeForSevenDaysLabel')}
				</p>
				<button
					type="button"
					onClick={() => setOpenModal(true)}
					className="button primary mt-3 mb-4"
				>
					{getLangText('createShopLabel')}
				</button>
				<img src={`/assets/images/multiLanguage/${lang}/intro.webp`} alt=""/>
			</section>
			<section className="padd-section">
				<div className="row">
					<div className="col-md-6">
						<div className="section-sub-title">
							{getLangText('onlineShopLabel')}
						</div>
						<div className="section-title mb-1"
							 dangerouslySetInnerHTML={{__html: getLangText('getToKnowShopBenefitsLabel')}}/>
						<p className="desc mb-0">
							{getLangText('seeItIsReallyWorthItLabel')}
						</p>
						<button
							type="button"
							onClick={() => setOpenModal(true)}
							className="button primary mt-4"
						>
							{getLangText('createShopLabel')}
						</button>
					</div>
					<div className="col-md-6 mt-md-0 mt-5 mb-md-0 mb-4">
						<div className="benefits">
							<li>
								<figure>
									<img src="/assets/images/shop-landing/cart.svg" alt=""/>
								</figure>
								<div className="content">
									<div className="title">{getLangText('oneSummaryLabel')}</div>
									<p>{getLangText('oneSummaryDescriptionLabel')}</p>
								</div>
							</li>
							<li>
								<figure>
									<img src="/assets/images/shop-landing/clock.svg" alt=""/>
								</figure>
								<div className="content">
									<div className="title">{getLangText('sellingTwentyFourHoursLabel')}</div>
									<p>{getLangText('sellingTwentyFourHoursDescriptionLabel')}</p>
								</div>
							</li>
							<li>
								<figure>
									<img src="/assets/images/shop-landing/tshirt.svg" alt=""/>
								</figure>
								<div className="content">
									<div className="title">{getLangText('easeSellReturnedProductsLabel')}</div>
									<p>{getLangText('easeSellReturnedProductsDescriptionLabel')}</p>
								</div>
							</li>
						</div>
					</div>
				</div>
			</section>
			<section className="intro-section light-bg">
				<div className="section-sub-title">
					{getLangText('onlineShopLabel')}
				</div>
				<h1 className="section-title"
					dangerouslySetInnerHTML={{__html: getLangText('oneOrderFewPlatformsLabel')}}/>
				<p className="desc">
					{getLangText('oneOrderFewPlatformsDescriptionLabel')}
				</p>
				<button
					type="button"
					onClick={() => setOpenModal(true)}
					className="button primary mt-4 mb-4"
				>
					{getLangText('createShopLabel')}
				</button>
				<div className="bg-icons mt-5">
					<img width={788} src={`/assets/images/multiLanguage/${lang}/icons.webp`} alt=""/>
				</div>
			</section>
			<section className="padd-section">
				<div className="row pt-md-5">
					<div className="col-md-5 mb-sm-0 mb-3">
						<div className="section-sub-title">
							{getLangText('onlineShopLabel')}
						</div>
						<div className="section-title small mb-3"
							 dangerouslySetInnerHTML={{__html: getLangText('browseYourAssortmentByClientsLabel')}}/>
						<p className="desc">
							{getLangText('clientsCanSearchProductsByCodesLabel')}
						</p>
						<button
							type="button"
							onClick={() => setOpenModal(true)}
							className="button primary mt-3"
						>
							{getLangText('createShopLabel')}
						</button>
					</div>
					<div className="col-md-7 pl-md-5 search-img-col">
						<img src={`/assets/images/multiLanguage/${lang}/search.webp`} alt=""/>
					</div>
				</div>
			</section>
			<section className="padd-section pt-4">
				<div className="row pt-md-5">
					<div className="col-md-7 pr-md-5 mb-sm-0 mb-3">
						<img src={`/assets/images/multiLanguage/${lang}/products.webp`} alt=""/>
					</div>
					<div className="col-md-5">
						<div className="section-sub-title">
							{getLangText('onlineShopLabel')}
						</div>
						<div className="section-title small mb-3"
							 dangerouslySetInnerHTML={{__html: getLangText('easeMethodToSellReturnedProductsLabel')}}/>
						<p className="desc">
							{getLangText('canSearchByCodeLabel')}
						</p>
						<button
							type="button"
							onClick={() => setOpenModal(true)}
							className="button primary mt-3"
						>
							{getLangText('createShopLabel')}
						</button>
					</div>
				</div>
			</section>
			<section className="intro-section dark-bg padd-section relative">
				<img
					alt="intro"
					className="z-[-1] absolute top-0 left-0 right-0 bottom-0 w-full h-full object-cover"
					src="/assets/images/shop-landing/dark-bg.svg"
				/>
				<div className="section-sub-title">
					{getLangText('onlineShopLabel')}
				</div>
				<h1 className="section-title">
					<strong>
						{getLangText('tryForFreeButton')}
					</strong>
				</h1>
				<p>{getLangText('createYourOwnShopLabel')}</p>
				<button
					type="button"
					onClick={() => setOpenModal(true)}
					className="button primary special mt-4"
				>
					{getLangText('createShopLabel')}
				</button>
			</section>
			{openModal &&
				<ConfirmRemoveModal
					isWaiting={waiting}
					dialogClassName="small-modal w-420"
					show={openModal}
					title={getLangText('testShopModalTitleLabel')}
					subTitle={getLangText('testShopModalSubTitleLabel')}
					hide={() => setOpenModal(false)}
					confirm={startTrial}
					confirmButton={getLangText('startLabel')}
					confirmButtonClassName="primary small-size"
				>
					<div className="benefits-point-list">
						<div className="d-flex align-items-center">
							<i className="icon-tick-c"/>
							<div dangerouslySetInnerHTML={{__html: getLangText('extraSellingChannelLabel')}}/>
						</div>
						<div className="d-flex align-items-center">
							<i className="icon-tick-c"/>
							<div dangerouslySetInnerHTML={{__html: getLangText('mergedBasketsPostAndLiveLabel')}}/>
						</div>
						<div className="d-flex align-items-center">
							<i className="icon-tick-c"/>
							<div dangerouslySetInnerHTML={{__html: getLangText('easySellReturnedProductsLabel')}}/>
						</div>
					</div>
				</ConfirmRemoveModal>
			}
		</div>
	)
}

export default NoShopPermissionComponent;
