import React from "react";
import Modal from "react-bootstrap/Modal";
import useLang from "../../../../../src_shared/hooks/useLang";
import Loader from "../../../shared/Loader";
import Field from "../../../../../src_shared/components/form/field/Field";
import useFormLocal from "../../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../../RestServices";
import Model from "../../../../../src_shared/modules/model/Model";
import {RouterPaths} from "../../../../routes/RouterPath";
import {useParams} from "react-router-dom";

const ManufacturerFormModal = ({hideModal, modalVisible}) => {
    const {getLangText} = useLang();

    const [{onSave, isWaiting, getFieldProps}] = useFormLocal({
        rest: services.API_STORAGE_MANUFACTURERS,
        model: new Model(),
        initialAutoLoad: false,
        redirectRoute: RouterPaths.ManufacturersList
    });

    return (
        <Modal
            show={modalVisible}
            className="add-order-modal"
            dialogClassName="default-modal w-450"
            onHide={hideModal}
        >
            <Modal.Header className="with-border">
                <Modal.Title>{getLangText('addManufacturerLabel')}</Modal.Title>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            <Modal.Body className="add-product-modal-body">
                <Field
                    {...getFieldProps('name')}
                    label={getLangText('nameLabel')}
                />
            </Modal.Body>
            <Modal.Footer>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button border-button"
                >
                    {getLangText('cancelButton')}
                </button>
                <button
                    type="button"
                    className={`button primary ${isWaiting ? 'loading-button' : ''}`}
                    onClick={onSave}
                >
                    {isWaiting && <Loader isLoading/>}
                    {getLangText('addButton')}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default ManufacturerFormModal;



