import Model from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

export class PageModel extends Model {
	getModel() {
		return {
			id: 0,
			name: '',
			url: '',
		};
	}

	getValidators() {
		return {
			name: ValidationError.notEmpty,
			url: ValidationError.notEmpty,
		};
	}
}

export default new PageModel();
