import React from 'react';
import useLang from "../../../../../../../src_shared/hooks/useLang";
import useWindowDimensions from "../../../../../messenger/hooks/useWindowDimensions/useWindowDimensions";

const banks = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10']
const AutoPayBanks = () => {
    const {getLangText} = useLang();
    const {width} = useWindowDimensions();
    const preparedBanks = () => {
        if (width < 380) return banks.slice(0, 4);
        if (width < 480) return banks.slice(0, 5);
        if (width < 767) return banks.slice(0, 6);
        if (width < 991) return banks.slice(0, 10);
        if (width > 991) return banks.slice(0, 7);
    }

    return (
        <div className="pb-[15px] -mt-[15px]">
            <div className="flex items-center justify-between space-x-2 pl-[50px] px-[20px]">
                {preparedBanks()?.map((bank) => (
                    <img
                        className="h-[24px] sm:h-[26px]"
                        src={`/assets/images/svg/banks/logo-${bank}.svg`} alt=""/>
                ))}
            </div>
            <div
                className="text-desc text-center text-xs mt-[6px]"
                 dangerouslySetInnerHTML={{
                     __html: getLangText({
                         key: 'otherBanks',
                         data: [21]
                     })
                 }}
            />
        </div>
    );
};

export default AutoPayBanks;
