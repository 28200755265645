import React, {Fragment, useRef} from 'react';
import {useSelector} from "react-redux";
import {
    convertTimestampToDate,
    convertToUserTimeZone,
    isTodayDay
} from "../../../../components/app/shared/helpers/dateHelpers";
import useLang from "../../../hooks/useLang";
import {Transition} from "@headlessui/react";
import DatePickerDropdown from "./DatePickerDropdown";
import useDropdown from "../../../../components/app/shared/hooks/useDropdown";
import moment from "moment";

const DateRangePicker = ({
                             setSelectionRange, selectionRange
                         }) => {
    const {lang} = useSelector((state) => state.i18n);
    const {getLangText} = useLang();
    const buttonRef = useRef(null)
    const dropdownRef = useRef(null)

    const hideDropdown = () => setOpen(false)

    const {open, setOpen} = useDropdown(buttonRef, dropdownRef, hideDropdown);

    const handleDropdownButton = (e) => {
        e.stopPropagation();
        setOpen(!open)
    }

    const DropdownButton = () => {
        const {startDate, endDate} = selectionRange;
        const convertedFromDate = !!startDate ? convertTimestampToDate(startDate, 'DD MMM', lang) : null;
        const convertedToDate = convertTimestampToDate(endDate, 'DD MMM', lang);
        const FromDateContent = () => {
            if (isTodayDay(startDate)) {
                return (
                    <div>
                        {getLangText('todayLabel')}
                        <span className="small-date ml-1">
                            ({convertedFromDate})
                        </span>
                    </div>
                );
            }
            return (
                <div>
                    {convertToUserTimeZone(startDate).format('DD MMMM')}
                </div>
            );
        };

        const Content = () => {
            if (!startDate) {
                return getLangText('allLabel');
            }
            return `${convertedFromDate} - ${convertedToDate}`
        }

        return (
            <button
                ref={buttonRef}
                type="button"
                onClick={handleDropdownButton}
                className="min-w-[130px] flex items-center border border-[#DFE1E4] bg-[white] rounded-[5px] pl-2.5 pr-0 text-xs text-[#6B7280]">
                <div className="mr-1">
                    <i className="icon-calendar text-[#CFD5DD]"/>
                </div>
                <div className="grow text-left">
                    {convertedToDate === convertedFromDate ?
                        <FromDateContent/> :
                        <Content/>
                    }
                </div>
                <div className="w-[30px] border-l py-2 ml-2">
                    <i className="icon-arrows text-[8px] text-[#9CA3AF]"/>
                </div>
            </button>
        );
    };

    return (
        <div className="relative">
            <DropdownButton/>
            <Transition
                as={Fragment}
                show={open}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <div
                    ref={dropdownRef}
                    className={`absolute top-[100%] left-0 sm:left-auto sm:right-0 z-10 mt-2 origin-top-right rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none daterange-wrapper`}>
                    <DatePickerDropdown
                        setOpen={setOpen}
                        setSelectionRange={setSelectionRange}
                        selectionRange={selectionRange}
                    />
                </div>
            </Transition>
        </div>
    );
};

export default DateRangePicker;
