import React, {useEffect, useState} from 'react';
import useLang from "../../../../../../../src_shared/hooks/useLang";

const CountInput = ({product, onChange}) => {
	const [countInputError, setCountInputError] = useState(null);
	const {getLangText} = useLang();

	useEffect(() => {
		if (+product?.product_max_count <= +product.quantity) {
			setCountInputError(product.id);
		}
		const maxCountErrorTimeout = setTimeout(() => {
			setCountInputError(null);
		}, 3000)
		return () => {
			clearTimeout(maxCountErrorTimeout)
		}
	}, [product.quantity])

	return (
		<div className="form-group">
			{product.product_id && countInputError === product?.id &&
				<div className="tooltip-error">
					{getLangText('maxValueLabel')}: {product?.product_max_count}
				</div>
			}
			<label className="control-label">{getLangText('quantityLabel')}</label>
			<input
				tabIndex={product.tabIndex}
				type="number"
				className="form-control"
				name="quantity"
				onChange={(e) => onChange(e.target.value, product)}
				value={product.quantity}
				min={1}
				max={product?.product_max_count}
				required
			/>
		</div>
	);
};

export default CountInput;
