import React from 'react';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import axios from "axios";
import {removeItemInMessagesList} from "../../chatList/ChatListActions";
import {useDispatch, useSelector} from "react-redux";
import { useHistory } from "react-router-dom";
import {selmoUrl} from "../../../../../src_shared/api/api";
import PropTypes from 'prop-types';
import useWindowDimensions from "../../../shared/hooks/useWindowDimensions";
import api from "../../../../../services/axios/axios";

const SetConversationStatusButton = ({tooltipLang, icon, statusNumber, withRedirect, setVisibleRightSide}) => {
    const {activeConversation} = useSelector((state) => state.messenger.chatBox)
    const history = useHistory();
    const dispatch = useDispatch();
	const {width} = useWindowDimensions();

	const setStatus = () => {
		const conversationId = activeConversation.id;
        api.post(`${selmoUrl}/api/status`, {
            conversation_id: conversationId,
            status: statusNumber,
        })
            .then((res) => {
				dispatch(removeItemInMessagesList(conversationId))
				if (withRedirect) {
					history.push('/wiadomosci')
					{width < 991 && setVisibleRightSide(false)}
				}
            })
            .catch((e) => {
                console.log(e.message)
            })
    }

    return (
        <OverlayTrigger
            placement='bottom'
            overlay={
                <Tooltip id="full-screen">
                    {tooltipLang}
                </Tooltip>
            }
        >
            <button
                type="button"
                className="orders-number"
                onClick={setStatus}
            >
                <i className={icon}/>
                <div className="lg:hidden text-xs font-bold ml-2">{tooltipLang}</div>
            </button>
        </OverlayTrigger>
    );
};

SetConversationStatusButton.propTypes = {
	tooltipLang: PropTypes.string.isRequired,
	icon: PropTypes.string.isRequired,
	statusNumber: PropTypes.string.isRequired,
	withRedirect: PropTypes.bool,
	setVisibleRightSide: PropTypes.func,
};

SetConversationStatusButton.defaultProps = {
	withRedirect: false,
	setVisibleRightSide: () => {},
};


export default SetConversationStatusButton;
