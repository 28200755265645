import React from 'react';
import Select from 'react-select'

const CustomSelect = ({onChange, options, value, id, extraProps}) => {

	return (
		<Select
			id={id}
			options={options}
			value={options.filter((option) => option.value === value)}
			onChange={(value) => onChange(value)}
			isSearchable={false}
			{...extraProps}
		/>
	);
};

export default CustomSelect;
