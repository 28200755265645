import React from 'react';
import MultiRowListTemplates from "./MultiRowListTemplates";
import useLang from "../../../../src_shared/hooks/useLang";
import useFormLocal from "../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../RestServices";
import {showAlert} from "../../../app/shared/components/alert/AlertActions";
import {useDispatch} from "react-redux";
import SettingsProductWrongCodeTemplatesModel
    from "../../../../modules/models/settings/SettingsProductWrongCodeTemplatesModel";

const ProductWrongCodeRows = ({values}) => {
    const {getLangText} = useLang();
    const dispatch = useDispatch();

    const [{
        onSave,
        isWaiting,
        getFieldProps,
        setValue,
        updateValues
    }] = useFormLocal({
        rest: services.API_SETTINGS_MESSENGER_TEMPLATES_WRONG_CODE,
        model: SettingsProductWrongCodeTemplatesModel,
        initialAutoLoad: false,
        afterSubmit: () => {
            dispatch(showAlert())
        }
    });

    return (
        <section id="product-wrong-code">
            <div className="left-side">
                <h2 className="label">{getLangText('input_label_wrong_code')}</h2>
            </div>
            <MultiRowListTemplates
                getFieldProps={getFieldProps}
                onSave={onSave}
                isWaiting={isWaiting}
                setValue={setValue}
                field="wrong_code"
                label="inputLabelWrongCode"
                updateValues={updateValues}
                data={values}
            />
        </section>
    );
};

export default ProductWrongCodeRows;
