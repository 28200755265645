import React from 'react';
import {Link} from "react-router-dom";
import {RouterPaths} from "../../../../routes/RouterPath";
import SettingsMondialRelayModel from "../../../../../modules/models/settings/SettingsMondialRelayModel";
import {services} from "../../../../RestServices";
import useLang from "../../../../../src_shared/hooks/useLang";
import useFormLocal from "../../../../../src_shared/hooks/useFormLocal";
import SwitchField from "../../../../../src_shared/components/form/switchField/SwitchField";
import Loader from "../../../../app/shared/Loader";
import MondialRelaySettingsWrapper from "../MondialRelayWrapper";
import {MONDIAL_RELAY_COLLECTIONS} from "../../../../app/shared/enums/MondialRelayCollectionsEnumts";
import SwitchBoxes from "../../../../../src_shared/components/form/switchBoxes/SwitchBoxes";
import Field from "../../../../../src_shared/components/form/field/Field";

const MondialRelayConfiguration = () => {
	const {getLangText} = useLang()

	const [{onSave, isWaiting, getFieldProps, isLoading, values}] = useFormLocal({
		rest: services.API_SETTINGS_MONDIAL,
		method: 'post',
		model: SettingsMondialRelayModel,
	});

	return (
		<>
			<div className="header-bar breadcrumbs-in-react-app">
				<ul className="breadcrumbs">
					<li>
						<Link to={RouterPaths.Settings}>
							{getLangText('asideSettingsLabel')}
						</Link>
					</li>
					<li>
						<Link to={RouterPaths.SettingsIntegration}>{getLangText('settings_nav_integrations')}</Link>
					</li>
					<li className="active">
						Mondial Relay
					</li>
				</ul>
			</div>
			<MondialRelaySettingsWrapper>
				<div className="loader-parent">
					<Loader isLoading={isLoading}/>
					<div className="settings-form pt-3">
						<div className="steps-wrapper">
							<div className="step border-step">
								<div className="step-header">
									<div className="number">
										1
									</div>
									<div className="title d-block">
										<a href="https://www.mondialrelay.fr/connexion-inscription/?returnUrl=https://www.mondialrelay.fr/"
										   target="_blank">
											<img style={{marginLeft: 0, height: '25px', width: 'auto'}}
												 src="/assets/images/svg/logomondial-relay.svg" alt="shippypro"/>
										</a>
										<div>
											{getLangText('settings_integrations_furgonetka_login_1')}
										</div>
									</div>
									<a target="_blank"
									   href="https://www.mondialrelay.fr/connexion-inscription/?returnUrl=https://www.mondialrelay.fr/"
									   className="button primary small-size ml-auto text-center">
										{getLangText('settings_integrations_fakturownia_description_4')}
									</a>
								</div>
							</div>
							<div className="step border-step mt-3 pb-lg-4 pb-3">
								<div className="step-header align-items-start">
									<div className="number">
										2
									</div>
									<div className="title d-block flex-grow-1 mt-2">
										<div>{getLangText('pasteKeysInFieldsBelowLabel')}</div>
										<a href="https://www.mondialrelay.fr/faq-pro/votre-compte/ou-trouver-mes-parametres-code-enseigne-code-marque-cle-privee-pour-configurer-mon-module/"
										   className="link-info mb-3"
										   target="_blank">{getLangText('settings_integrations_inpost_description_3')}
										</a>
										<Field
											{...getFieldProps('merchant_key')}
											label={getLangText('merchantKeyLabel')}
										/>
										<Field
											{...getFieldProps('private_key')}
											label={getLangText('privateKeyLabel')}
										/>
										<Field
											{...getFieldProps('api_customer_id')}
											label="Brand ID API"
										/>
										<Field
											{...getFieldProps('api_login')}
											label="Login API"
										/>
										<Field
											{...getFieldProps('api_pwd')}
											label="Mot de passe API"
										/>
									</div>
								</div>
							</div>
							<div className="step border-step mt-3 pb-lg-4 pb-3">
								<div className="step-header align-items-start">
									<div className="number">
										3
									</div>
									<div className="title d-block flex-grow-1 mt-2">
										<div className="mb-3">{getLangText('selectCollectionMethodLabel')}</div>
										<SwitchBoxes
											{...getFieldProps('mode_col')}
											options={MONDIAL_RELAY_COLLECTIONS}
											className="separated small-padd"
										/>
										{values.mode_col === 'REL' &&
											<Field
												{...getFieldProps('col_rel')}
												placeholder={getLangText('mondialRelayCollectionRELPointPlaceholderLabel')}
												label={getLangText('mondialRelayCollectionRELPointLabel')}
											/>
										}
									</div>
								</div>
							</div>
							<div className="d-flex align-items-center mt-2">
								<SwitchField
									{...getFieldProps('active')}
									label={getLangText('active')}
									className="switch-form inline-label align-items-start big-label light-version mb-0"
								/>
								<div className="button-place ml-auto">
									<button
										type="button"
										onClick={onSave}
										className={`button primary ${isWaiting ? 'loading-button' : ''}`}
									>
										<Loader isLoading={isWaiting}/>
										{getLangText('saveButton')}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</MondialRelaySettingsWrapper>
		</>
	);
};

export default MondialRelayConfiguration;
