import {useEffect} from 'react';
import {useLocation, withRouter} from 'react-router-dom';

function ScrollToTop() {
	const {pathname} = useLocation();

	useEffect(() => {
		setTimeout(() => {
			window.scrollTo(0, 0);
		}, 110)
	}, [pathname]);

	return null;
}

export default withRouter(ScrollToTop);
