import React, {useCallback, useEffect, useRef, useState} from 'react';
import axios from "axios";
import debounce from "lodash.debounce";
import optimizedMemo from "../../../../helpers/optimizedMemo";
import {selmoUrl} from "../../../../api/api";
import AsyncDropdown from "./AsyncDropdown";
import Group from "../../group/Group";
import Tooltip from "react-bootstrap/Tooltip";
import {OverlayTrigger} from "react-bootstrap";
import {getGETParamsUrl} from "../../../../list/ListActions";
import api from "../../../../../services/axios/axios";
import useLang from "../../../../hooks/useLang";

const AsyncSearchSelectField = ({
                                    setValue,
                                    name,
                                    rest,
                                    label,
                                    disabled,
                                    formatOptionLabel,
                                    formatOptionValue,
                                    className,
                                    defaultValue,
                                    value,
                                    validateErrors,
                                    validationData,
                                    getError,
                                    required,
                                    showLabel,
                                    floatedLabel,
                                    placeholder,
                                    setExtraValues,
                                    isSearchable,
                                    optionsParser,
                                    selectedOption,
                                    searchOnFocus,
                                    withSearchInput,
                                    setSearchValue,
                                    id = '',
                                }) => {
        const inputRef = useRef(null);
        const [isOpenDropdown, setIsOpenDropdown] = useState(false);
        const [waitingForOptions, setWaitingForOptions] = useState(false);
        const {getLangText} = useLang();
        const [inputValue, setInputValue] = useState({
            name: defaultValue.name,
        });

        const [options, setOptions] = useState([]);

        const hintDropdownFocus = (e) => {
            if (document.querySelector('.custom-dropdown .select-custom-option:first-child') && e.keyCode === 40) {
                document.querySelector('.custom-dropdown .select-custom-option:first-child').focus()
            }
        }

        const searchOptions = async (superSearch = '') => {

            const params = {}

            if (isOpenDropdown) {
                return;
            }

            if (superSearch?.length && isSearchable) {
                params.superSearch = superSearch;
            }

            if (superSearch?.length > 0 || !isSearchable || searchOnFocus) {
                setWaitingForOptions(true)
                setIsOpenDropdown(true)

                try {
                    const {data} = await api.get(`${selmoUrl}/${rest}${getGETParamsUrl(params)}`)
                    setOptions(data.items)

                } catch (e) {
                    setOptions([])
                    console.warn('Cannot get options')
                } finally {
                    setWaitingForOptions(false);
                }
            }
        }

        const remove = () => {
            setInputValue({
                name: '',
            })
            setValue(name, null)
            setTimeout(() => {
                inputRef?.current?.focus()

            },)
        }

        const debouncedSave = useCallback(
            debounce((superSearch) => searchOptions(superSearch), 500),
            []
        );

        const onChange = (e) => {
            if (!e.target.value?.length) {
                setWaitingForOptions(false)
                setIsOpenDropdown(false)
            }
            setInputValue({
                ...inputValue,
                name: e.target.value,
            })
            setSearchValue(e.target.value)
            debouncedSave(e.target.value)
        }

        let clicked = 0

        const onSelectClick = () => {
            clicked = clicked + 1;
            if (isOpenDropdown && clicked !== 1) {
                setIsOpenDropdown(false)
                document.activeElement.blur()
                clicked = 0;
            }
        }

        const onFocus = () => {
            if (!searchOnFocus) {
                return;
            }
            if (isOpenDropdown) {
                setIsOpenDropdown(false)
            } else {
                searchOptions();
            }
        }

        const isSelectedValue = inputValue.name?.length;

        const asyncSearchClassName = () => {
            const floatedLabelClass = floatedLabel ? 'floated-label-select' : '';
            const isOpen = isOpenDropdown ? 'open' : '';
            return `custom-async-search ${className} ${floatedLabelClass} ${isOpen}`
        }

        const preparedOptions = optionsParser(options);

        useEffect(() => {
            setInputValue({
                id: defaultValue.id,
                name: defaultValue.name,
            })
        }, [defaultValue.name])


        return (
            <div
                id={id}
                className={asyncSearchClassName()}>
                <Group
                    validateErrors={validateErrors}
                    validationData={validationData}
                    getError={getError}
                    required={required}
                    showLabel={!floatedLabel && showLabel}
                    label={label}
                    value={value}
                    className={value ? 'selected-disabled' : ''}
                >
                    {floatedLabel &&
                        <span className={`floated-label ${isSelectedValue ? 'selected' : ''}`}>{label}</span>
                    }
                    {isSearchable ?
                        <input
                            ref={inputRef}
                            onChange={onChange}
                            onKeyDown={hintDropdownFocus}
                            onFocus={onFocus}
                            type="text"
                            value={inputValue.name}
                            className="form-control"
                            autoComplete="off"
                            disabled={disabled || value}
                            placeholder={!floatedLabel && placeholder}
                            required={required}
                        /> :
                        <div
                            ref={inputRef}
                            style={{display: inputValue.id ? 'flex' : 'block'}}
                            className="form-control selected-value"
                            onClick={onSelectClick}
                            onFocus={searchOptions}
                        >
                            {inputValue.id ?
                                <div
                                    tabIndex="0"
                                    className="select-custom-option"
                                >
                                    {selectedOption(inputValue)}
                                </div> :
                                <div
                                    tabIndex="0"
                                    className="placeholder"
                                >
                                    {placeholder}
                                </div>
                            }
                            {!isSearchable &&
                                <div className="remove-product" style={{pointerEvents: 'none'}}>
                                    <i className="icon-arrows"/>
                                </div>
                            }
                        </div>
                    }
                    {!!value && isSearchable &&
                        <OverlayTrigger
                            placement='top'
                            overlay={
                                <Tooltip id={`remove-${name}`}>
                                    {getLangText('removeButton')}
                                </Tooltip>
                            }
                        >
                            <button
                                type="button"
                                className="remove-product"
                                onClick={remove}
                            >
                                <i className="icon-cross"/>
                            </button>
                        </OverlayTrigger>
                    }
                    {formatOptionValue(inputValue)}
                    <AsyncDropdown
                        setInputValue={setInputValue}
                        setValue={setValue}
                        options={preparedOptions}
                        setOptions={setOptions}
                        name={name}
                        formatOptionLabel={formatOptionLabel}
                        setExtraValues={setExtraValues}
                        inputRef={inputRef}
                        isOpenDropdown={isOpenDropdown}
                        setIsOpenDropdown={setIsOpenDropdown}
                        waitingForOptions={waitingForOptions}
                        withSearchInput={withSearchInput}
                    />
                </Group>
            </div>
        );
    }
;

AsyncSearchSelectField.defaultProps = {
    disabled: false,
    formatOptionValue: (inputValue) => (<></>),
    selectedOption: (inputValue) => (<>{inputValue.name}</>),
    optionsParser: (options) => options?.map((i) => i),
    className: '',
    defaultValue: {},
    rest: '',
    name: '',
    setValue: () => {
    },
    setSearchValue: () => {
    },
    floatedLabel: false,
    isSearchable: true,
    searchOnFocus: false,
    withSearchInput: false,
}

export default AsyncSearchSelectField;
