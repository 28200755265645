import React from 'react';
import Loader from "../../../../app/shared/Loader";
import Field from "../../../../../src_shared/components/form/field/Field";
import SwitchField from "../../../../../src_shared/components/form/switchField/SwitchField";
import useLang from "../../../../../src_shared/hooks/useLang";
import {useDispatch} from "react-redux";
import {services} from "../../../../RestServices";
import useFormLocal from "../../../../../src_shared/hooks/useFormLocal";
import {Link, useHistory, useParams} from "react-router-dom";
import {RouterPaths} from "../../../../routes/RouterPath";
import useRemoveData from "../../../../../src_shared/hooks/useRemoveData";
import ConfirmModal from "../../../../app/live/details/default/summary/export/ConfirmModal";
import {showAlert} from "../../../../app/shared/components/alert/AlertActions";
import DpdTemplateModel from "../../../../../modules/models/settings/DpdTemplateModel";

const DpdTemplateForm = () => {
    const {getLangText} = useLang();
    const {id} = useParams();
    const dispatch = useDispatch();
    const rest = !!+id ? `${services.API_SETTINGS_DPD_SK_TEMPLATE}/${id}` : services.API_SETTINGS_DPD_SK_TEMPLATE;
    const history = useHistory();

    const [{
        onSave,
        isWaiting,
        getFieldProps,
        isLoading,
        values
    }] = useFormLocal({
        rest,
        method: !!+id ? 'put' : 'post',
        model: DpdTemplateModel,
        initialAutoLoad: !!+id,
        redirectRoute: `${RouterPaths.SettingsIntegrationDpdTemplates}`
    });

    const afterDelete = () => {
        history.push(RouterPaths.SettingsIntegrationDpdTemplates);
        dispatch(showAlert('yuhuLabel', 'successfulRemovedLabel'))
    }

    const [{
        openModal: openRemoveModal,
        hideModal: hideRemoveModal,
        confirmModalVisible,
        itemToRemove,
        isDeleting,
        removeItem,
    }] = useRemoveData({
        rest: services.API_SETTINGS_DPD_SK_TEMPLATE,
        afterSubmit: afterDelete,
    });

    return (
        <>
            <div className="header-bar breadcrumbs-in-react-app">
                <ul className="breadcrumbs">
                    <li>
                        <a href={RouterPaths.Settings}>
                            {getLangText('asideSettingsLabel')}
                        </a>
                    </li>
                    <li>
                        <Link to={RouterPaths.SettingsIntegration}>{getLangText('settings_nav_integrations')}</Link>
                    </li>
                    <li>
                        <Link to={RouterPaths.SettingsIntegrationDpdTemplates}>{getLangText('templatesLabel')}</Link>
                    </li>
                    <li className="active">
                        <div>{getLangText('editionLabel')}</div>
                    </li>
                </ul>
                <Link
                    to={RouterPaths.SettingsIntegrationDpdTemplates}
                    className="button back-to-panels mb-3">
                    <i className="icon-arrow-left"/>
                    {getLangText('backToTemplatesLabel')}
                </Link>
            </div>
            <div className="settings-page sub-settings-page export-sub-settings">
                <div className="header-wrapper no-border pb-0">
                    <img
                        style={{width: 'auto', height: '30px'}}
                        src="/assets/images/svg/dpd-logo.svg"
                        alt="dpd"
                    />
                </div>
                <div className="loader-parent">
                    <Loader isLoading={isLoading}/>
                    <div className="settings-form pt-3">
                        <div className="row small-padd-row">
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-md-12">
                                        <Field
                                            {...getFieldProps('name')}
                                            label={getLangText('nameLabel')}
                                            type="text"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <Field
                                            {...getFieldProps('length')}
                                            label={getLangText('longLabel')}
                                            addon="CM"
                                            type="number"
                                            placeholder="0"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <Field
                                            {...getFieldProps('width')}
                                            label={getLangText('widthLabel')}
                                            addon="CM"
                                            type="number"
                                            placeholder="0"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <Field
                                            {...getFieldProps('height')}
                                            label={getLangText('heightLabel')}
                                            addon="CM"
                                            type="number"
                                            placeholder="0"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <Field
                                            {...getFieldProps('weight')}
                                            label={getLangText('weightLabel')}
                                            addon="KG"
                                            type="number"
                                            placeholder="0"
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <SwitchField
                                            {...getFieldProps('cod')}
                                            className="switch-form inline-label mt-3 mb-2"
                                            label={getLangText('codLabel')}
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <SwitchField
                                            {...getFieldProps('active')}
                                            className="switch-form inline-label mt-3 mb-2"
                                            label={getLangText('activeLabel')}
                                        />
                                    </div>
                                </div>
                                <button
                                    type="button"
                                    className={`button primary mt-3 ${isWaiting ? 'loading-button' : ''}`}
                                    onClick={onSave}
                                >
                                    {isWaiting && <Loader isLoading/>}
                                    {getLangText('saveButton')}
                                </button>
                            </div>
                        </div>
                        <div className="remove-delivery-section">
                            <button
                                type="button"
                                onClick={() => openRemoveModal(values)}
                                className="button border-button left-icon big-size"
                            >
                                <i className="icon-bin"/>
                                {getLangText('removeThisTemplateLabel')}
                            </button>
                        </div>
                    </div>
                </div>
                {confirmModalVisible &&
                    <ConfirmModal
                        dialogClassName="w-380"
                        title={getLangText('removeTemplateLabel')}
                        SubTitle={() => <span dangerouslySetInnerHTML={{
                            __html: getLangText({
                                key: 'wantToRemoveTemplateLabel',
                                data: [itemToRemove.name]
                            })
                        }}/>}
                        saveButton={getLangText('removeButton')}
                        show={confirmModalVisible}
                        isWaiting={isDeleting}
                        onSubmit={removeItem}
                        hide={hideRemoveModal}
                        saveButtonClass="danger"
                    />
                }
            </div>
        </>
    );
};

export default DpdTemplateForm;
