import ListReducer from "../../../../src_shared/list/ListReducer";
import Composition from "../../shared/helpers/Composition";
import createReducer from "../../shared/helpers/createReducer";

export const getStateRoot = (state) => state.notifications.submitted;
export const prefix = 'NOTIFICATIONS_SUBMITTED_LIST_';

const getInitState = () => ListReducer.getInitState();

const getReduceMap = () => new Composition(
	ListReducer.getReduceMap(),
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

