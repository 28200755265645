import React from 'react';
import PaymentMethodsForm from "../../../shared/components/paymentMethods/PaymentMethodsForm";
import useLang from "../../../../../src_shared/hooks/useLang";

const PaymentMethods = ({values, updateValues, updatePriceAndValues}) => {
    const {getLangText} = useLang();

    return (
        <div>
            <div className="font-bold mb-3.5">{getLangText('paymentsSelectPaymentsMethodsLabel')}</div>
            <PaymentMethodsForm
                shopInfo={values.shopInfo}
                values={values || {}}
                items={values?.paymentMethods || []}
                onPaymentChange={updatePriceAndValues}
                updateValues={updateValues}
                hasError={false}
                setPaymentMethodsValidation={() => {}}
                shopPayment={values.overall}
                withPopularBox={false}
                className="popular-black-border"
            />
        </div>

    );
};

export default PaymentMethods;
