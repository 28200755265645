import React, {useState} from 'react';
import Modal from "react-bootstrap/Modal";
import useLang from "../../../../../src_shared/hooks/useLang";
import ManualRow from "./components/manual/ManualRow";
import AutomaticRow from "./components/automatic/AutomaticRow";

const AUTOMATIC_VIEW = 'AUTOMATIC_VIEW';
const MANUAL_VIEW = 'MANUAL_VIEW';
const ShelvesForm = ({modalVisible, hideModal, loadData}) => {
    const {getLangText} = useLang();
    const [activeView, setActiveView] = useState(MANUAL_VIEW);

    const afterSubmit = () => {
        hideModal();
        loadData();
    }

    return (
        <Modal
            dialogClassName="default-modal"
            show={modalVisible}
            onHide={hideModal}
        >
            <Modal.Header className="with-tabs">
                <Modal.Title>
                    {getLangText('addPlacesLabel')}
                </Modal.Title>
                <div className="tabs-wrapper">
                    <button
                        type="button"
                        className={activeView === MANUAL_VIEW ? 'active' : ''}
                        onClick={() => setActiveView(MANUAL_VIEW)}
                    >
                        Manualnie
                    </button>
                    <button
                        type="button"
                        className={activeView === AUTOMATIC_VIEW ? 'active' : ''}
                        onClick={() => setActiveView(AUTOMATIC_VIEW)}
                    >
                        Automatycznie
                    </button>
                </div>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button"
                >
                    <i className="icon-cross"/>
                </button>
            </Modal.Header>
            {activeView === MANUAL_VIEW ?
                <ManualRow afterSubmit={afterSubmit} hideModal={hideModal} />:
                <AutomaticRow afterSubmit={afterSubmit} hideModal={hideModal} />
            }
        </Modal>
    );
};

export default ShelvesForm;