import createReducer from "../../../../shared/helpers/createReducer.js";
import Composition from "../../../../shared/helpers/Composition.js";
import ListReducer from "../../../../../../../../src_shared/list/ListReducer.js";

export const getStateRoot = (state) => state.admin.users.userOrders.list;
export const prefix = 'ADMIN_USER_ORDERS_LIST_';

const getInitState = () => ListReducer.getInitState();

const getReduceMap = () =>  new Composition(
	ListReducer.getReduceMap(),
)

export const getInstance = () => createReducer(
	getInitState(),
	getReduceMap(),
	prefix,
);

export default getInstance();

