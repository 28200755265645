import Model from "../../../src_shared/modules/model/Model";
import ValidationError from "../../../src_shared/form/validation/ValidationError";

class SettingsOrlenDataModel extends Model {
	getModel() {
		return {
			partner_id: '',
			partner_key: '',
			active: '0',
		};
	}

	getValidators() {
		return {
			partner_id: ValidationError.notEmpty,
			partner_key: ValidationError.notEmpty,
			active: ValidationError.skip,
		};
	}
}

export default new SettingsOrlenDataModel();
