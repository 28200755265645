import React, {useState} from 'react';
import useList from "../../../../../src_shared/list/useList.js";
import ListActions from "./ListActions.js";
import {getStateRoot} from "./listReducer.js";
import SearchInput from "../../../shared/components/form/SearchInput.jsx";
import {useDispatch, useSelector} from "react-redux";
import ModalFormActions from "../../modalForm/FormActions.js";
import FiltersActions from "../../filters/FiltersActions.js";
import {getStateRoot as getFiltersStateRoot} from '../../filters/filtersReducer.js'
import useWindowDimensions from "../../../messenger/hooks/useWindowDimensions/useWindowDimensions.js";
import ProductsTable from "./components/table/Table.jsx";
import Pagination from "../../../shared/components/pagination/Pagination.jsx";
import SingleSelectFiltersStatus
    from "../../../../../src_shared/components/filters/singleSelectFiltersStatus/SingleSelectFiltersStatus.jsx";
import {PRODUCTS_TYPES} from "../../../shared/enums/ProductsType.js";
import {PRODUCTS_ACTIVE_TYPES} from "../../../shared/enums/ProductsActiveType.js";
import StorageWrapper from "../../Page";
import CustomDropdown from "../../../shared/components/dropdown/CustomDropdown";
import {useModal} from "../../../shared/hooks/useModal";
import useLang from "../../../../../src_shared/hooks/useLang";
import {services} from "../../../../RestServices";
import AsyncFilter from "../../../../../src_shared/components/filters/asyncFilter/AsyncFilter";
import {PRODUCTS_SHOP_VISIBILITY_TYPES} from "../../../shared/enums/ProductsShopVisibilityType";
import {SHOP_PERMISSION} from "../../../shared/enums/SelmoPermissions";
import PermissionsWrapper from "../../../../routes/PermissionsWrapper";
import ExportModal from "../../../../../src_shared/components/exportModal/ExportModal";
import FirstStepPage from "../../../../../src_shared/components/ui/FirstStepPage/FirstStepPage";
import ComponentLoader from "../../../shared/components/componentLoader/ComponentLoader";
import {FIRST_PAGE_PRODUCTS} from "../../../shared/enums/TutorialVideoStatsTypes";
import {
    BUTIK_MADAME_SHOP_ID,
    PIANO_FASHION_SHOP_ID,
    TEST_ACCOUNT_SHOP_ID, TIM_SHOP_ID
} from "../../../../../src_shared/enums/TrustedShopsIds";
import GeneratePZButton from "./components/GeneratePZButton";
import MobileSort from "./components/MobileSort";
import {RouterPaths} from "../../../../routes/RouterPath";
import {SHOP_ORIGIN_DA} from "../../../shared/enums/ShopOrigins";
import PrintAllProductCard from "./components/PrintAllProductCards";
import {PRODUCTS_MOBILE_APP_VISIBILITY_TYPES} from "../../../shared/enums/ProductsMobileAppVisibilityType";
import {
    USER_PERMISSIONS_ORDERS_STATISTICS_VISIBILITY,
    USER_PERMISSIONS_PRODUCTS_ADD
} from "../../../shared/enums/UserPermissionsTypes";
import UserPermissionsWrapper from "../../../../routes/UserPermissionsWrapper";
import {checkRoles} from "../../../../routes/PermissionRoute";

export const firstPageBenefits = [
    {id: 0, title: 'manageInventoryLabel', desc: 'manageInventorySubLabel'},
    {id: 1, title: 'automateSalesViaCodesLabel', desc: 'automateSalesViaCodesSubLabel'},
    {id: 1, title: 'monitorReserveListAndResultsLabel', desc: 'monitorReserveListAndResultsSubLabel'},
]
const ProductsList = () => {
    const dispatch = useDispatch();
    const [isFiltersVisible, setFiltersVisible] = useState(false);
    const {width} = useWindowDimensions();
    const {getLangText} = useLang();
    const {first_step_products, user_permissions, main_account_id} = useSelector((state) => state.session.userData);
    const {userData} = useSelector((state) => state.session);

    const canAddProductPermissionMatched = checkRoles(user_permissions, [USER_PERMISSIONS_PRODUCTS_ADD]);

    const [openExportProductModal, setOpenExportProductModal] = useModal(false);

    const getParams = () => dispatch(ListActions.getParams());

    const showExportProductModal = () => setOpenExportProductModal(true);

    const beforeDestroy = () => {
        const nextPathName = window.location.pathname;
        const preparedPathName = nextPathName.replace(/(\d+)/, ':id');
        if (preparedPathName !== RouterPaths.Product) {
            reset();
        }
    };

    const {
        pagination: {
            changePage,
            getPagination,
            itemsPerPage,
            nextPage,
            prevPage,
            totalItems,
            updateItemsPerPage
        },
        list: {items, isWaiting, isFirstStepPage, reset},
        search,
        sort: {updateSortOptions, getSortClass, sortBy, sortDir},
    } = useList({
        ListActions,
        getStateRoot,
        FiltersActions,
        beforeDestroy,
    });

    const showModal = () => dispatch(ModalFormActions.modal.showModal());

    if (isWaiting && first_step_products) return <ComponentLoader/>

    return (
        isFirstStepPage ?
            <FirstStepPage
                title="asideProductsLabel"
                shortDesc="manageProductsEditPricesLabel"
                icon="products-icon"
                fullDesc="manageProductsEditPricesFullLabel"
                buttonClick={showModal}
                headerButtonTitle="addProductLabel"
                mainButtonTitle={(!!+main_account_id && !canAddProductPermissionMatched) ? null : "addFirstProductLabel"}
                previewSrc="/assets/images/furgonetka02.jpg"
                benefits={firstPageBenefits}
                videoStatsName={FIRST_PAGE_PRODUCTS}
            /> :
            <StorageWrapper helmetTitle={`${getLangText('asideProductsLabel')} - Selmo`}>
                <div className="table-list-wrapper">
                    <div className="default-shadow-box pb-0">
                        <div className="table-header">
                            <div
                                className={`top-part ${(userData.origin === SHOP_ORIGIN_DA || +userData.id === TEST_ACCOUNT_SHOP_ID) ? 'flex-wrap' : ''}`}>
                                <h1 className="title grow">
                                    {getLangText('asideProductsLabel')}
                                    {totalItems > 0 &&
                                        <div className="badge">{totalItems}</div>
                                    }
                                </h1>
                                <button
                                    type="button"
                                    className='toggle-filters mr-2'
                                    onClick={() => setFiltersVisible(!isFiltersVisible)}
                                >
                                    <i className='icon-filter'/>
                                </button>
                                {(+userData.id === TEST_ACCOUNT_SHOP_ID || +userData.id === PIANO_FASHION_SHOP_ID || +userData.id === TIM_SHOP_ID) && width < 767 &&
                                    <MobileSort
                                        sortDir={sortDir}
                                        sortBy={sortBy}
                                    />
                                }
                                <CustomDropdown
                                    icon="icon-print"
                                    tooltipTitle={getLangText('exportLabel')}
                                    className="ml-sm-auto d-md-none"
                                >
                                    <button
                                        type="button"
                                        className="custom-option"
                                        onClick={showExportProductModal}
                                    >
                                        {getLangText('printProductsXLSLabel')}
                                    </button>
                                </CustomDropdown>
                                {(+userData.id === TEST_ACCOUNT_SHOP_ID || userData.origin === SHOP_ORIGIN_DA) &&
                                    <>
                                        {/*<ArchivedProducts/>*/}
                                        <PrintAllProductCard/>
                                    </>
                                }
                                <UserPermissionsWrapper allowedPermissions={[USER_PERMISSIONS_PRODUCTS_ADD]}>
                                    <button
                                        type="button"
                                        onClick={showModal}
                                        className="button primary small-size ml-sm-auto d-lg-inline-block d-none"
                                    >
                                        {getLangText('addProductLabel')}
                                    </button>
                                </UserPermissionsWrapper>
                                <UserPermissionsWrapper allowedPermissions={[USER_PERMISSIONS_PRODUCTS_ADD]}>
                                    <button
                                        type="button"
                                        onClick={showModal}
                                        className="button border-button small-size ml-sm-auto d-lg-none left-icon"
                                    >
                                        <i className="icon-plus"/>
                                        {getLangText('addButton')}
                                    </button>
                                </UserPermissionsWrapper>
                                {(+userData.id === TEST_ACCOUNT_SHOP_ID || +userData.id === BUTIK_MADAME_SHOP_ID) &&
                                    <GeneratePZButton/>
                                }
                            </div>
                            {(width > 767 || isFiltersVisible) &&
                                <div className="filters-part mt-sm-3">
                                    <SingleSelectFiltersStatus
                                        ListActions={ListActions}
                                        FiltersActions={FiltersActions}
                                        getFiltersStateRoot={getFiltersStateRoot}
                                        options={PRODUCTS_TYPES}
                                        name="type"
                                        defaultSelectedLabel={getLangText('stateLabel')}
                                    />
                                    <SingleSelectFiltersStatus
                                        ListActions={ListActions}
                                        FiltersActions={FiltersActions}
                                        getFiltersStateRoot={getFiltersStateRoot}
                                        options={PRODUCTS_ACTIVE_TYPES}
                                        name="activeType"
                                        className="ml-sm-2"
                                        defaultSelectedLabel={getLangText('activityEmojiLabel')}
                                    />
                                    <AsyncFilter
                                        ListActions={ListActions}
                                        FiltersActions={FiltersActions}
                                        getFiltersStateRoot={getFiltersStateRoot}
                                        name="categories"
                                        selectAllName="categoriesSelectAll"
                                        defaultSelectedLabel={getLangText('categoriesListLabel')}
                                        className="ml-sm-2"
                                        rest={services.API_SEARCH_CATEGORIES}
                                    />
                                    {(+userData.id === TEST_ACCOUNT_SHOP_ID || +userData.id === PIANO_FASHION_SHOP_ID || +userData.id === TIM_SHOP_ID || +userData.id === BUTIK_MADAME_SHOP_ID) &&
                                        <AsyncFilter
                                            ListActions={ListActions}
                                            FiltersActions={FiltersActions}
                                            getFiltersStateRoot={getFiltersStateRoot}
                                            name="manufacturers"
                                            selectAllName="manufacturersSelectAll"
                                            defaultSelectedLabel={getLangText('manufacturersLabel')}
                                            className="ml-sm-2"
                                            rest={`${services.API_STORAGE_SEARCH_MANUFACTURERS}?filter=1`}
                                        />
                                    }
                                    <PermissionsWrapper
                                        allowedPermissions={[SHOP_PERMISSION]}
                                    >
                                        <SingleSelectFiltersStatus
                                            ListActions={ListActions}
                                            FiltersActions={FiltersActions}
                                            getFiltersStateRoot={getFiltersStateRoot}
                                            options={PRODUCTS_SHOP_VISIBILITY_TYPES}
                                            name="shopActive"
                                            className="ml-sm-2"
                                            defaultSelectedLabel={getLangText('shopVisibilityTypeLabel')}
                                        />
                                    </PermissionsWrapper>
                                    <SingleSelectFiltersStatus
                                        ListActions={ListActions}
                                        FiltersActions={FiltersActions}
                                        getFiltersStateRoot={getFiltersStateRoot}
                                        options={PRODUCTS_MOBILE_APP_VISIBILITY_TYPES}
                                        name="mobileAppActive"
                                        className="ml-sm-2"
                                        defaultSelectedLabel={getLangText('visibilityInApplication')}
                                    />
                                    <CustomDropdown
                                        icon="icon-print"
                                        tooltipTitle={getLangText('exportLabel')}
                                        className="ml-sm-auto d-md-block d-none"
                                    >
                                        <button
                                            type="button"
                                            className="custom-option"
                                            onClick={showExportProductModal}
                                        >
                                            {getLangText('printProductsXLSLabel')}
                                        </button>
                                    </CustomDropdown>
                                    <SearchInput
                                        placeholder={getLangText('searchPlaceholder')}
                                        className="ml-sm-3 mr-0"
                                        search={search}
                                    />
                                </div>
                            }
                        </div>
                        <ProductsTable
                            getSortClass={getSortClass}
                            updateSortOptions={updateSortOptions}
                            items={items}
                            isWaiting={isWaiting}
                            totalItems={totalItems}
                        />
                    </div>
                    <Pagination
                        getLangText={getLangText}
                        pages={getPagination()}
                        items={items}
                        itemsPerPage={itemsPerPage}
                        totalItems={totalItems}
                        onPrevPage={prevPage}
                        onChangePage={changePage}
                        onNextPage={nextPage}
                        onChangeItemsPerPage={updateItemsPerPage}
                        classes={{
                            prevIcon: 'icon-chevron left',
                            nextIcon: 'icon-chevron right',
                        }}
                        showItemsPerPageSelect
                    />
                </div>
                {openExportProductModal &&
                    <ExportModal
                        title={getLangText('printProductsXLSLabel')}
                        subTitle={getLangText('receivedFileInXLSFormatLabel')}
                        saveButton={getLangText('exportLabel')}
                        rest={`${services.API_EXPORT_PRODUCTS_LIST_XLS}${getParams()}`}
                        fileName={getLangText('asideProductsLabel')}
                        fileType='xlsx'
                        show={openExportProductModal}
                        hide={() => setOpenExportProductModal(false)}
                    />
                }
            </StorageWrapper>
    );
};

export default ProductsList;
