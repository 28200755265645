import {combineReducers} from "redux";
import storageListReducer from './storageList/listReducer.js'
import activeListReducer from './activeList/listReducer.js'
import notShownListReducer from './notShownList/listReducer.js'
import shownListReducer from './shownList/listReducer.js'

export default combineReducers({
	storageList: storageListReducer,
	activeList: activeListReducer,
	notShownList: notShownListReducer,
	shownList: shownListReducer,
});
