import {getStateRoot} from "./countDownNotificationReducer";

class CountDownNotificationActions {

	setNotificationVisibility(toggle, notificationType, data) {
		return {
			type: 'SET_NOTIFICATION_VISIBILITY',
			toggle,
			notificationType,
			data,
		};
	}
}

export const getInstance = () => new CountDownNotificationActions({
	getStateRoot,
});

export default getInstance();
