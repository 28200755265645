import React, {useEffect, useRef, useState} from 'react';
import {Link, useHistory} from "react-router-dom";

const SearchList = ({items, search, hideModal}) => {

    const [selectedIndex, setSelectedIndex] = useState(0);
    const history = useHistory();
    const listRef = useRef(null);
    const itemRefs = useRef([]);
    const textWithSelectedPhrase = (value = '', inDescription = false) => {
        const letters = ['ą', 'ć', 'ę', 'ł', 'ń', 'ó', 'ś', 'ź', 'ż'];
        const replacement = ['a', 'c', 'e', 'l', 'n', 'o', 's', 'z', 'z'];
        let preparedValue = value || '';
        let preparedLettersSearch = search.searchText;

        for (let i = 0; i < letters.length; ++i) {
            preparedValue = preparedValue.replaceAll(letters[i], replacement[i]);
            preparedLettersSearch = preparedLettersSearch.replaceAll(letters[i], replacement[i]);
        }

        const textToReplace = preparedValue.toString().toLowerCase();
        const preparedSearch = preparedLettersSearch.replace(/[.*+?^${}()|[\]\\]/g, '\\$&').toString().toLowerCase();
        const match = textToReplace.match(preparedSearch);


        const startIndex = match && match.index;
        const matchLength = match && match[0]?.length;
        const valueToReplace = value?.substr(startIndex, matchLength);

        if (inDescription && match) {
            const splitString = value?.split(' ')
            const first2 = splitString.indexOf(splitString.find((i) => i.includes(match[0])));
            if (first2 === -1) {
                return value?.replace(valueToReplace, `<span class="bg-[#fff8de] font-bold">${valueToReplace}</span>`)
            }
            if (first2 > 8) {
                return value?.replace(valueToReplace, `<span class="bg-[#fff8de] font-bold">${valueToReplace}</span>`).split(' ').slice(first2 - 8, first2 + 8).join(' ')
            } else {
                return value?.replace(valueToReplace, `<span class="bg-[#fff8de] font-bold">${valueToReplace}</span>`).split(' ').slice(0, first2 + 14).join(' ')
            }
        }
        return value?.replace(valueToReplace, `<span class="bg-[#fff8de] font-bold">${valueToReplace}</span>`);
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            switch (event.key) {
                case 'ArrowUp':
                    setSelectedIndex((prevIndex) =>
                        prevIndex > 0 ? prevIndex - 1 : prevIndex
                    );
                    break;
                case 'ArrowDown':
                    setSelectedIndex((prevIndex) =>
                        prevIndex < items.length - 1 ? prevIndex + 1 : prevIndex
                    );
                    break;
                case 'Enter':
                    if (items[selectedIndex]) {
                        history.push(items[selectedIndex]?.path)
                        hideModal();
                    }
                    break;
                default:
                    break;
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [items, selectedIndex]);

    useEffect(() => {
        if (itemRefs.current[selectedIndex]) {
            itemRefs.current[selectedIndex].scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
            });
        }
    }, [selectedIndex]);

    return (
        <div
            ref={listRef}
            className="space-y-1"
                >
            {items.map((item, index) => (
                <Link
                    ref={(el) => (itemRefs.current[index] = el)}
                    onClick={hideModal}
                    key={index}
                    to={item.path}
                    className={`hover:bg-[#eeeeee] overflow-hidden transition-all hover:no-underline flex items-center rounded-[5px] p-3.5 -mx-3.5 ${index === selectedIndex ? 'bg-[#F5F5F5]' : ''}`}
                >
                    {item?.routes.map((i, index) => (
                        <div className="font-medium text-[#4B5563] flex items-center text-sm overflow-hidden">
                            <div
                                className="whitespace-nowrap text-ellipsis overflow-hidden"
                                dangerouslySetInnerHTML={{__html: textWithSelectedPhrase(i)}}
                            />
                            {index !== item?.routes?.length - 1 && (
                                <i className="icon-arrows inline-block text-[8px] mx-3.5 text-desc -rotate-90"/>
                            )}
                        </div>
                    ))}
                </Link>
            ))}
        </div>
    );
};

export default SearchList;
