import React from 'react';
import useFormLocal from "../../../src_shared/hooks/useFormLocal";
import {services} from "../../RestServices";
import useLang from "../../../src_shared/hooks/useLang";
import Loader from "../../app/shared/Loader";
import ComponentLoader from "../../app/shared/components/componentLoader/ComponentLoader";
import {Link} from "react-router-dom";
import {RouterPaths} from "../../routes/RouterPath";
import Ckeditor from "../../../src_shared/components/form/ckeditor/Ckeditor";
import SettingsTermsModel from "../../../modules/models/settings/SettingsTermsModel";
import {Helmet} from "react-helmet";

const custom_config = {
	toolbar: {
		items: [
			'heading',
			'|',
			'bold',
			'italic',
			'|',
			'bulletedList',
			'numberedList',
			'|',
			'undo',
			'redo',
		]
	},
}
const Terms = () => {
	const {getLangText} = useLang();
	const [{
		onSave,
		isWaiting,
		getFieldProps,
		isLoading,
	}] = useFormLocal({
		rest: services.API_SETTINGS_TERMS,
		model: SettingsTermsModel,
	});

	if (isLoading) return <ComponentLoader/>;

	return (
		<>
			<Helmet>
				<meta charSet="utf-8"/>
				<title>{getLangText('setting_terms_title')} - Selmo</title>
			</Helmet>
			<div className="header-bar breadcrumbs-in-react-app">
				<ul className="breadcrumbs">
					<li>
						<Link to={RouterPaths.Settings}>{getLangText('asideSettingsLabel')}</Link>
					</li>
					<li>
						<Link to={RouterPaths.SettingsShop}>{getLangText('settings_nav_shop_settings')}</Link>
					</li>
					<li className="active">
						<div>{getLangText('setting_terms_title')}</div>
					</li>
				</ul>
			</div>
			<div className="settings-page sub-settings-page">
				<div className="header-wrapper">
					<h1>
						<img src="/assets/images/settings/waybills.svg" alt="waybills"/>
						{getLangText('setting_terms_title')}
					</h1>
					<div className="description">{getLangText('setting_terms_title_description')}</div>
				</div>
				<div className="settings-form">
					<div className="form-info-box w-100 mb-3">
						<div>
							<i className="icon-info-c"/>
						</div>
						<div>
							<div className="title">{getLangText('setting_terms_shop_terms')}</div>
							<div className="desc">
								{getLangText('setting_terms_shop_terms_description')}
							</div>
						</div>
					</div>
					<Ckeditor
						{...getFieldProps('terms')}
						className="big-min-height"
						customConfig={custom_config}
					/>
					<br/>
					<div className="form-info-box w-100 mb-3">
						<div>
							<i className="icon-info-c"/>
						</div>
						<div>
							<div className="title">{getLangText('setting_terms_shop_policy')}</div>
							<div className="desc">
								{getLangText('setting_terms_shop_policy_description')}
							</div>
						</div>
					</div>
					<Ckeditor
						{...getFieldProps('policy')}
						className="big-min-height"
						customConfig={custom_config}
					/>
					<div className="button-place">
						<button
							type="button"
							onClick={onSave}
							className={`button primary ${isWaiting ? 'loading-button' : ''}`}
						>
							<Loader isLoading={isWaiting}/>
							{getLangText('saveButton')}
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default Terms;
