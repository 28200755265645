import Model from "../../../src_shared/modules/model/Model";
export class TeamMemberModel extends Model {
    getModel() {
        return {
            permissionGroups: [],
            selectedOptions: [],
            userInfo: {},
        };
    }

    buildDTO(data) {
        let newArray = [];

        data.selectedOptions.forEach((item) => {
            item.permissions.forEach(permission => {
                if (permission.id) {
                    newArray.push(permission.id)
                }
            })
        });

        return newArray;
    }
}

export default new TeamMemberModel();
