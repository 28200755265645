import React from 'react';
import moment from "moment/moment";
import useLang from "../../../../../../src_shared/hooks/useLang";
import useRemoveData from "../../../../../../src_shared/hooks/useRemoveData";
import ConfirmModal from "../../../../live/details/default/summary/export/ConfirmModal";
import {useDispatch} from "react-redux";
import {showAlert} from "../../../../shared/components/alert/AlertActions";
import {services} from "../../../../../RestServices";
import {convertToUserTimeZone} from "../../../../shared/helpers/dateHelpers";

const OpenCartSurcharges = ({items, getOrderPrice, loadData, values}) => {
    const {getLangText} = useLang();
    const dispatch = useDispatch();

    const afterSubmit = () => {
        dispatch(showAlert('yuhuLabel', 'successfulRemovedLabel'))
        loadData();
    }

    const [{
        openModal,
        hideModal: hideRemoveModal,
        confirmModalVisible,
        isDeleting,
        removeItem,
        itemToRemove
    }] = useRemoveData({
        rest: services.API_ARCHIVE_OPEN_CART_SURCHARGE,
        afterSubmit,
    });

    return (
        <div className="border rounded-[8px] p-3 mt-2">
            <div className="font-bold text-sm">{getLangText('openCartSurchargeLabel')}</div>
            {items.map((item) => (
                <div
                    key={item.id}
                    className="flex items-center border-b last:border-b-0 py-1.5">
                    <div className="text-desc font-medium text-sm">
                        {convertToUserTimeZone(item.date).format('DD.MM.YYYY HH:mm')}
                    </div>
                    <div className="value ml-auto text-sm font-bold">
                        {getOrderPrice(item.value)}
                    </div>
                    <button
                        onClick={() => openModal(item)}
                        style={{padding: 7}}
                        className="button border-button icon-button lgrey ml-2"
                    >
                        <i className="icon-bin ml-0"/>
                    </button>
                </div>
            ))}
            <div
                className="flex items-center border-b last:border-b-0 py-1.5">
                <div className="font-bold text-sm">
                    Łącznie
                </div>
                <div className="value ml-auto text-sm font-bold">
                    {getOrderPrice(values.open_cart_surcharges)}
                </div>
                <div className="w-[28px] ml-2" />
            </div>
            {confirmModalVisible &&
                <ConfirmModal
                    dialogClassName="w-380"
                    title={`Czy na pewno chcesz usunać dopłatę z dnia: ${convertToUserTimeZone(itemToRemove.date).format('DD.MM.YYYY HH:mm')}?`}
                    saveButton="Usuń"
                    show={confirmModalVisible}
                    isWaiting={isDeleting}
                    onSubmit={removeItem}
                    hide={hideRemoveModal}
                    saveButtonClass="danger"
                />
            }
        </div>
    );
};

export default OpenCartSurcharges;
