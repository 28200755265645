import React, {useMemo} from 'react';
import Modal from "react-bootstrap/Modal";
import Loader from "../../../../../../../src_shared/components/loader/Loader";
import useFormLocal from "../../../../../../../src_shared/hooks/useFormLocal";
import {services} from "../../../../../../RestServices";
import useLang from "../../../../../../../src_shared/hooks/useLang";
import AutomaticShelvesModel from "../../../../../../../modules/models/warehouse/AutomaticShelvesModel";
import SelectField from "../../../../../../../src_shared/components/form/selectField/SelectField";
import {generateAlphabetArray, generateNumberArray} from "../manual/Row";

const AutomaticRow = ({afterSubmit, hideModal}) => {
    const {getLangText} = useLang();

    const [{
        onSave,
        getFieldProps,
        isWaiting,
    }] = useFormLocal({
        rest: services.API_WAREHOUSE_SHELVES_AUTOMATIC,
        model: AutomaticShelvesModel,
        afterSubmit,
        initialAutoLoad: false,
    });

    const options = useMemo(() => {
        const alphabetArray = generateAlphabetArray();
        const numberArray = generateNumberArray();
        return alphabetArray.concat(numberArray);
    }, [generateAlphabetArray, generateNumberArray]);


    return (
        <form onSubmit={onSave}>
            <Modal.Body className="pt-3 pb-5 space-y-8">
                <div>
                    <div className="form-label">
                        {getLangText('selectRangeOfFirstLocation')}
                    </div>
                    <div className="grid grid-cols-2 gap-3">
                        <SelectField
                            {...getFieldProps('shelf_1_from')}
                            className="default-size mb-0"
                            placeholder={getLangText('fromLabel')}
                            options={options}
                            showLabel={false}
                            required
                        />
                        <SelectField
                            {...getFieldProps('shelf_1_to')}
                            className="default-size mb-0"
                            placeholder={getLangText('toLabel')}
                            options={options}
                            showLabel={false}
                            required
                        />
                    </div>
                </div>
                <div>
                    <div
                        className="form-label">{getLangText('selectRangeOfSecondLocation')} {getLangText('optionalLabel')}</div>
                    <div className="grid grid-cols-2 gap-3">
                        <SelectField
                            {...getFieldProps('shelf_2_from')}
                            className="default-size mb-0"
                            placeholder={getLangText('fromLabel')}
                            options={options}
                            showLabel={false}
                        />
                        <SelectField
                            {...getFieldProps('shelf_2_to')}
                            className="default-size mb-0"
                            placeholder={getLangText('toLabel')}
                            options={options}
                            showLabel={false}
                        />
                    </div>
                </div>
                <div>
                    <div
                        className="form-label">{getLangText('selectRangeOfThirdLocation')} {getLangText('optionalLabel')})
                    </div>
                    <div className="grid grid-cols-2 gap-3">
                        <SelectField
                            {...getFieldProps('shelf_3_from')}
                            className="default-size mb-0"
                            placeholder={getLangText('fromLabel')}
                            showLabel={false}
                            options={options}
                        />
                        <SelectField
                            {...getFieldProps('shelf_3_to')}
                            className="default-size mb-0"
                            placeholder={getLangText('toLabel')}
                            options={options}
                            showLabel={false}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    onClick={hideModal}
                    type="button"
                    className="button border-button"
                >
                    {getLangText('cancelButton')}
                </button>
                <button
                    type="submit"
                    className={`button primary ${isWaiting ? 'loading-button' : ''}`}
                >
                    {isWaiting && <Loader isLoading/>}
                    {getLangText('generateLabel')}
                </button>
            </Modal.Footer>
        </form>
    );
};

export default AutomaticRow;
