export const CLIENT_PERIOD_ANNUAL = 'annual'
export const CLIENT_PERIOD_MONTHLY = 'monthly'
export const CLIENT_PERIOD_SEMI_ANNUAL = 'semi-annual'

export const clientPeriodTypes = [
	{
		value: CLIENT_PERIOD_ANNUAL, label: 'Roczny'
	},
	{
		value: CLIENT_PERIOD_SEMI_ANNUAL, label: 'Pół roczny'
	},
	{
		value: CLIENT_PERIOD_MONTHLY, label: 'Miesięczny'
	},
]

