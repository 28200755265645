import React from 'react';

const Skeleton = () => {
    return (
        <div className="animate-pulse relative">
            <div className="h-[270px] bg-gray-200 rounded-[5px] dark:bg-gray-700 w-full mb-3 mt-3"/>
            <div className="h-[270px] bg-gray-200 rounded-[5px] dark:bg-gray-700 w-full mb-3"/>
        </div>
    );
};

export default Skeleton;
